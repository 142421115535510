<template>
    <div class="integrate-district-select flex flex-align flex-center" v-if="!loading">
        <div class="integrate-district-select-list integrate-state">
            <h1>Select a District to Continue</h1>
            <transition-group name="slide-in" tag="div" class="integration-options">
                <div class="integration-option flex flex-align" v-for="district of sorted" :key="district.id" @click="() => select(district)">
                    <span class="icon iconoir-city block"></span>
                    <div class="option-name text-overflow">{{district.name}}</div>
                    <div class="caret"></div>
                </div>
                <div class="integration-option flex flex-align" key="create-source" @click="send('CREATE_DISTRICT')">
                    <span class="icon iconoir-plus block"></span>
                    <div class="option-name">Create a new district</div>
                    <div class="caret"></div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { ROBLOX_APPLICATION_IDS } from '@/constants';
    
    export default {
        name: 'IntegrateDistrictSelect',
        props: {
            state: Object,
            send: Function
        },
        data(){
            return {
                loading: true
            };
        },
        computed: {
            sorted(){
                return this.$store.state.teams.all.slice()
                .filter(t => t.type === 'district')
                .sort((a, b) => a.name.localeCompare(b.name));
            },
            onboarding(){
                return this.$store.state.integrate.onboarding;
            },
            teams(){
                return this.$store.state.teams.all;
            }
        },
        async created(){
            // If this is an onboarding, we need some logic before asking them to blindly select a team.
            if(this.onboarding?.team?.id){
                // Try to see if they are a member of this team.
                const team = this.teams.find(team => team.id === this.onboarding?.team?.id);

                // If so, automatically select the team.
                if(team){
                    return this.select(team);
                }

                // If not, we should just send the user directly to the CREATE_DISTRCT state.
                // The rest of the logic can be handled from there.
                return this.send('CREATE_DISTRICT');
            }

            this.loading = false;
        },
        methods: {
            async select(district){
                try {
                    // Check to see if we need to mark the onboarding as "started".
                    if(this.onboarding?.state === 'created'){
                        await this.$http.post(`/teams/${district.id}/onboardings/${this.onboarding.id}/claim`);
                    }

                    const { $data: sources } = await this.$http.get(`/teams/${district.id}/sources`);

                    this.$parent.sources = sources.filter(source => {
                        return this.$parent.state.context.application.valid_source_providers.includes(source.provider.id) && source.status !== 'destroyed';
                    });

                    this.send({ type: 'CONFIRM_DISTRICT', data: district });

                    const license_key = district.properties?.roblox?.license_key;

                    // If integrating with Roblox we want to collect the license key
                    if (ROBLOX_APPLICATION_IDS.includes(this.state.context.application.id) && _.isNil(license_key)) {
                        return this.send('LICENSE');
                    }

                    if(this.$parent.sources.length < 1) {
                        this.send('CREATE_SOURCE');
                    } else {
                        this.send('SELECT_SOURCE');
                    }
                } catch (error) {
                    // TODO
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-district-select.integrate-state
    {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        padding: 80px 0;
        max-width: none;

        h1
        {
            line-height: 36px;
        }

        .integration-options
        {
            margin-top: 10px;
        }

        .integration-option
        {
            &:last-child
            {
                color: @grey;
            }
        }
    }
</style>