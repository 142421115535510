<template>
    <chip color="blue" title="2FA" v-if="enabled">
        <template #icon>
           <FingerprintLockCircle class="icon" width="14" height="14" stroke-width="2" />
        </template>
    </chip>
</template>

<script>
    import { FingerprintLockCircle } from '@epiphany/iconoir';

    export default {
        name: 'TwoFAEnabled',
        components: {
            FingerprintLockCircle
        },
        props: {
            enabled: Boolean
        },
        data(){
            return {};
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
