<template>
    <div class="custom-admin-terms flex flex-column flex-align">
        <h1>Please Review Our Terms of Service</h1>
        <div class="helptext">
            We take our committment to protect student privacy very seriously.
            Please take some time to understand our data privacy practices.
        </div>
        <div class="terms">
            <div class="term flex flex-align">
                <span class="icon iconoir-cancel block"></span>
                <div class="ff">We <span class="emphasis">never</span> share data without your explicit permission.</div>
            </div>
            <div class="term flex flex-align">
                <span class="icon iconoir-cancel block"></span>
                <div class="ff">We <span class="emphasis">never</span> sell data or use it for advertising or marketing.</div>
            </div>
            <div class="term flex flex-align">
                <span class="icon iconoir-check block"></span>
                <div class="ff">We <span class="emphasis">always</span> encrypt data in transit and at rest.</div>
            </div>
        </div>
        <a target="_blank" href="https://ed.link/docs/legal/terms" class="flex flex-align text-button external">
            <span class="icon iconoir-open-in-window"></span>
            <div>Edlink Terms of Service</div>
        </a>
        <a target="_blank" href="https://ed.link/docs/legal/privacy" class="flex flex-align text-button external">
            <span class="icon iconoir-open-in-window"></span>
            Edlink Privacy Policy
        </a>
        <div class="options flex" key="email">
            <div class="button" kbd="Enter ⏎" @click="send('NEXT')">I Accept</div>
        </div>
        <!-- <div class="button" kbd="Enter ⏎" @click="send('NEXT')">Continue</div> -->
    </div>
</template>

<script>
    export default {
        name: 'CustomAdminTerms',
        props: {
            state: Object,
            send: Function
        },
        created(){
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .custom-admin-terms {
        h1 {
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .text-button
    {
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;

        .icon
        {
            margin-right: 6px;
            font-size: 18px;
        }
    }
    
    .terms
    {
        margin: 10px 0;
    }

    .helptext {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        text-align: center;
    }

    .term
    {
        font-size: 14px;
        color: @grey;
        line-height: 40px;

        .icon
        {
            font-size: 22px;
            margin: 0 10px 0 0;
        }

        .emphasis
        {
            &::after
            {
                content: "";
                position: absolute;
                bottom: -2px;
                height: 1px;
                left: 0;
                right: 0;
                background-color: @grey;
                opacity: 0.5;
            }
        }
    }
</style>
