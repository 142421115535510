<template>
    <div class="integrate-source-provider-veracross">
        <integrate-source-editor-step name="Please select your timezone" icon="iconoir-timezone" :complete="completed.includes('timezone')">
            <div class="helptext">
                This is the timezone that your school is in.
            </div>
            <div class="flex flex-align">
                <select class="medium" :value="source.configuration.timezone" @input="e => send('UPDATE_SOURCE', {path: 'configuration.timezone', value: e.target.value})">
                    <option value="">Select a Timezone</option>
                    <option v-for="timezone in timezones" :value="timezone" :key="timezone">{{ timezone }}</option>
                </select>
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Enter Your Veracross URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers and students go to sign into Veracross.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://accounts.veracross.com/school-route/axiom/login" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Generate Veracross API Credentials" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating OAuth Keys" icon="iconoir-info-empty">
                <div class="image-label flex flex-align">
                    <label>1. Navigate to <code>Identity & Access Management</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('media/images/veracross/onboarding/navigate-to-iam.png', { title: 'Click on the IAM Link' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>2. Click <code>Add new Integration Partner</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('media/images/veracross/onboarding/add-integration.png', { title: 'Add Integration' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>3. Enter <code>Edlink</code> for the partner name.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('media/images/veracross/onboarding/add-edlink.png', { title: 'Find Edlink' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>4. Enter <code>accounts@ed.link</code> for the contact email.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>5. Create the partner.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>6. Click <code>Start Integration</code>.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>7. Click create <code>Oauth Application</code> in the top right.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>8. Click <code>Scopes</code> in left sidebar.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>
                        9. Add the following <a href="#" class="configuration-link text mini" @click="show_scopes">scopes</a> to the application.
                    </label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('media/images/veracross/onboarding/add-rm-scopes.png', { title: 'Add relevant scopes.' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>10. Click <code>Update</code> in the top right.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>11. Copy the <code>Client ID</code> and <code>Client Secret</code></label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('media/images/veracross/onboarding/edlink-app-credentials.png', { title: 'Copy these credentials' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter Your Veracross API Credentials" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <div class="helptext">
                Enter the values you copied from Veracross.
            </div>
            <label class="key-label">Key</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_id', value: e.target.value})" />
            <label class="key-label">Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_secret', value: e.target.value})" />
            <!-- <label class="key-label">School Route</label>
            <input class="medium client-secret" type="text" placeholder="School Route" :value="source.configuration.school_route" @input="e => send('UPDATE_SOURCE', {path: 'configuration.school_route', value: e.target.value})" /> -->
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import moment from 'moment-timezone';
    import showdown from 'showdown';
    import Help from '@/components/modals/Help.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';

    export default {
        name: 'IntegrateSourceProviderVeracross',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url?.match(url)){
                    return [];
                }

                if(!this.remote){
                    return ['url'];
                }

                const properties = ['client_id', 'client_secret'];

                for(const property of properties){
                    if(!this.source.configuration?.[property]){
                        return ['url', 'remote'];
                    }
                }

                return ['url', 'remote', 'credentials'];
            },
            /**
             * @see https://momentjs.com/timezone/docs/#/using-timezones/getting-country-zones/
             */
            timezones() {
                return _.uniq(['AU', 'CA', 'US', 'HU'].map(country => moment.tz.zonesForCountry(country).map(zone => zone)).flat()).sort((a, b) => a.localeCompare(b));
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                remote: false
            };
        },
        methods: {
            show_scopes() {
                const url = process.env.NODE_ENV === 'production' ? 'https://edlink.github.io/docs' : 'http://localhost:8082';
                this.$http
                    .get(`${url}/snippets/guide/team/sources/providers/veracross-scopes.md`)
                    .then(response => {
                        const converter = new showdown.Converter({ tables: true, customizedHeaderId: true });
                        const doc = document.createElement('div');

                        doc.innerHTML = converter.makeHtml(response);

                        const title = doc.querySelector('h1').innerText;
                        const description = doc.querySelector('p').innerText;

                        const html = [
                            `<ul>`
                        ]

                        // We want the middle column of the table.
                        const rows = doc.querySelectorAll('table tr');

                        for (const row of Array.from(rows)) {
                            const columns = Array.from(row.querySelectorAll('td'));

                            if (columns.length === 3) {
                                html.push(`<li style="color: black; margin-bottom: 2px;">${columns[1].innerText}</li>`);
                            }
                        }

                        html.push(`</ul>`);

                        this.$modal.show(
                            Help,
                            { title, description, html: html.join('') },
                            { width: 500, height: 'auto', classes: 'modal', reset: true }
                        );

                    })
            },
            image(src, config = { title: null, width: 700, height: 'auto' }){
                const url = process.env.NODE_ENV === 'production' ? 'https://edlink.github.io/docs' : 'http://localhost:8082';
                this.$modal.show(
                    HelpImage,
                    { src: `${url}/${src}`, title: config.title },
                    { width: 1200, height: config.height ?? 'auto', classes: 'modal', reset: true }
                );
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
