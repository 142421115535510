<template>
    <div class="integrate-source-editor" :class="{ clever: isCustomOnboarding }">
        <div class="integrate-source-editor-view flex flex-column bc">
            <div class="flex flex-align integrate-video" @click="$store.commit('integrate/video', `setting-up-${provider.application}`)" v-if="hasHelpVideo(provider.application)">
                <span class="icon iconoir-media-video-list"></span> Watch: Setting Up {{provider.name}}
            </div>
            <h1>Configure Your {{provider.name}} Source</h1>
            <div class="helptext">
                Follow the steps below to get connected.
            </div>
            <component class="integrate-source-editor-provider" :state="state" :send="send" :is="providers[provider.application]" :complete.sync="complete" :type="type" />
            <integrate-source-editor-validate :state="state" :send="send" :disabled="!complete" :type="type" :next-step="nextStep" />
        </div>
    </div>
</template>

<script>
    // General components.
    import IntegrateSourceEditorValidate from '@/components/integrate/source/editor/IntegrateSourceEditorValidate.vue';

    // Provider components.
    import IntegrateSourceProviderSchoology from '@/components/integrate/source/providers/IntegrateSourceProviderSchoology.vue';
    import IntegrateSourceProviderBlackboard from '@/components/integrate/source/providers/IntegrateSourceProviderBlackboard.vue';
    import IntegrateSourceProviderMicrosoft from '@/components/integrate/source/providers/IntegrateSourceProviderMicrosoft.vue';
    import IntegrateSourceProviderSFTP from '@/components/integrate/source/providers/IntegrateSourceProviderSFTP.vue';
    import IntegrateSourceProviderCanvas from '@/components/integrate/source/providers/IntegrateSourceProviderCanvas.vue';
    import IntegrateSourceProviderFacts from '@/components/integrate/source/providers/IntegrateSourceProviderFacts.vue';
    import IntegrateSourceProviderGoogle from '@/components/integrate/source/providers/IntegrateSourceProviderGoogle.vue';
    import IntegrateSourceProviderBrightspace from '@/components/integrate/source/providers/IntegrateSourceProviderBrightspace.vue';
    import IntegrateSourceProviderMoodle from '@/components/integrate/source/providers/IntegrateSourceProviderMoodle.vue';
    import IntegrateSourceProviderAeries from '@/components/integrate/source/providers/IntegrateSourceProviderAeries.vue';
    import IntegrateSourceProviderAspen from '@/components/integrate/source/providers/IntegrateSourceProviderAspen.vue';
    import IntegrateSourceProviderBlackbaud from '@/components/integrate/source/providers/IntegrateSourceProviderBlackbaud.vue';
    import IntegrateSourceProviderBromcom from '@/components/integrate/source/providers/IntegrateSourceProviderBromcom.vue';
    import IntegrateSourceProviderClassGather from '@/components/integrate/source/providers/IntegrateSourceProviderClassGather.vue';
    import IntegrateSourceProviderClasslink from '@/components/integrate/source/providers/IntegrateSourceProviderClasslink.vue';
    import IntegrateSourceProviderClever from '@/components/integrate/source/providers/IntegrateSourceProviderClever.vue';
    import IntegrateSourceProviderCSV from '@/components/integrate/source/providers/IntegrateSourceProviderCSV.vue';
    import IntegrateSourceProviderIlluminate from '@/components/integrate/source/providers/IntegrateSourceProviderIlluminate.vue';
    import IntegrateSourceProviderInfiniteCampus from '@/components/integrate/source/providers/IntegrateSourceProviderInfiniteCampus.vue';
    import IntegrateSourceProviderOneRoster from '@/components/integrate/source/providers/IntegrateSourceProviderOneRoster.vue';
    import IntegrateSourceProviderRapidIdentity from '@/components/integrate/source/providers/IntegrateSourceProviderRapidIdentity.vue';
    import IntegrateSourceProviderPowerschool from '@/components/integrate/source/providers/IntegrateSourceProviderPowerschool.vue';
    import IntegrateSourceProviderSkyward from '@/components/integrate/source/providers/IntegrateSourceProviderSkyward.vue';
    import IntegrateSourceProviderLTI from '@/components/integrate/source/providers/IntegrateSourceProviderLTI.vue';
    import IntegrateSourceProviderQmlativ from '@/components/integrate/source/providers/IntegrateSourceProviderQmlativ.vue';
    import IntegrateSourceProviderSynergy from '@/components/integrate/source/providers/IntegrateSourceProviderSynergy.vue';
    import IntegrateSourceProviderVeracross from '@/components/integrate/source/providers/IntegrateSourceProviderVeracross.vue';
    import IntegrateSourceProviderOneLogin from '@/components/integrate/source/providers/IntegrateSourceProviderOneLogin.vue';
    import IntegrateSourceProviderSAML from '@/components/integrate/source/providers/IntegrateSourceProviderSAML.vue';
    import IntegrateSourceProviderOIDC from '@/components/integrate/source/providers/IntegrateSourceProviderOIDC.vue';
    import IntegrateSourceProviderEdFi from '@/components/integrate/source/providers/IntegrateSourceProviderEdFi.vue';
    import IntegrateSourceProviderGenesis from '@/components/integrate/source/providers/IntegrateSourceProviderGenesis.vue';
    import IntegrateSourceProviderSapphire from '@/components/integrate/source/providers/IntegrateSourceProviderSapphire.vue';
    import IntegrateSourceProviderToddle from '@/components/integrate/source/providers/IntegrateSourceProviderToddle.vue';

    export default {
        name: 'IntegrateSourceEditor',
        props: {
            nextStep: String,
            state: Object,
            send: Function,
            type: String
        },
        components: {
            'integrate-source-editor-validate': IntegrateSourceEditorValidate
        },
        data(){
            return {
                complete: false,
                providers: {
                    blackboard: IntegrateSourceProviderBlackboard,
                    schoology: IntegrateSourceProviderSchoology,
                    canvas: IntegrateSourceProviderCanvas,
                    facts: IntegrateSourceProviderFacts,
                    microsoft: IntegrateSourceProviderMicrosoft,
                    google: IntegrateSourceProviderGoogle,
                    brightspace: IntegrateSourceProviderBrightspace,
                    lti: IntegrateSourceProviderLTI,
                    moodle: IntegrateSourceProviderMoodle,
                    aeries: IntegrateSourceProviderAeries,
                    aspen: IntegrateSourceProviderAspen,
                    blackbaud: IntegrateSourceProviderBlackbaud,
                    bromcom: IntegrateSourceProviderBromcom,
                    classgather: IntegrateSourceProviderClassGather,
                    classlink: IntegrateSourceProviderClasslink,
                    clever: IntegrateSourceProviderClever,
                    csv: IntegrateSourceProviderCSV,
                    illuminate: IntegrateSourceProviderIlluminate,
                    infinitecampus: IntegrateSourceProviderInfiniteCampus,
                    oneroster: IntegrateSourceProviderOneRoster,
                    rapididentity: IntegrateSourceProviderRapidIdentity,
                    powerschool: IntegrateSourceProviderPowerschool,
                    skyward: IntegrateSourceProviderSkyward,
                    sftp: IntegrateSourceProviderSFTP,
                    qmlativ: IntegrateSourceProviderQmlativ,
                    synergy: IntegrateSourceProviderSynergy,
                    veracross: IntegrateSourceProviderVeracross,
                    onelogin: IntegrateSourceProviderOneLogin,
                    saml: IntegrateSourceProviderSAML,
                    oidc: IntegrateSourceProviderOIDC,
                    edfi: IntegrateSourceProviderEdFi,
                    genesis: IntegrateSourceProviderGenesis,
                    sapphire: IntegrateSourceProviderSapphire,
                    toddle: IntegrateSourceProviderToddle
                }
            };
        },
        computed: {
            provider(){
                return this.type === 'destination' ? this.state.context.destination_provider : this.state.context.provider;
            },
            isCustomOnboarding(){
                return this.state.context?.custom ?? false;
            }
        },
        methods: {
            hasHelpVideo(provider){
                if (this.state.context?.custom) { return false; }
                return ['moodle', 'blackboard', 'microsoft', 'google', 'canvas', 'schoology'].includes(provider);
            }
        }
    }
</script>

<style lang="less">
@import "~@/assets/less/variables";

.helptext {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: @grey;
    max-width: 550px;
    margin-bottom: 10px;
}
</style>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-state.integrate-source-editor
    {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        max-width: none;

        .integrate-source-editor-view
        {
            padding: 200px 0 80px;
            align-items: flex-start;
            max-width: 600px;
        }

        .helptext
        {
            margin-bottom: 50px;
        }

        .integrate-source-editor-provider
        {
            width: 100%;
        }

        &.clever
        {
            .integrate-source-editor-view
            {
                padding: 60px 0 60px 70px;
                align-items: flex-start;
                max-width: 600px;
            }
        }
    }
</style>
