<template>
    <div class="integrate-account-two-factor-setup">
        <h1>Enable Two-Factor Authentication</h1>
        <div class="helptext">
            Two-factor authentication (2FA) helps protect student data by making it significantly harder for malicious users to gain access to your account.
            2FA uses your mobile device to provide an additional layer of security.
        </div>
        <div class="options flex flex-align">
            <div class="button" @click="send('ENABLE')">Enable 2FA</div>
            <div class="text-button" @click="next">Skip This Step</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateAccountTwoFactorSetup',
        props: {
            state: Object,
            send: Function
        },
        computed: {
            invitations(){
                return this.$store.getters.user?.invitations;
            }
        },
        created(){
            if(this.$store.getters.user?.two_factor_enabled){
                this.next();
            }
        },
        methods: {
            next(){
                if(this.$store.getters.team){
                    if(this.invitations?.length){
                        this.send('INVITES');
                    } else {
                        this.send('SELECT_DISTRICT');
                    }
                }else{
                    this.send('TERMS');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .button
    {
        margin-right: 20px;
    }
</style>
