<template>
    <div class="entity-value flex">
        <div v-if="editing" class="ff" key="editing">
            <date-picker :open.sync="open" v-model="override" v-bind="{
                type: 'date',
                format: 'MM-DD-YYYY',
                'input-class': 'block editable-input'
            }">
                <!-- This is dirty i know, im sorry ;( -->
                <template v-slot:icon-calendar>
                    <span></span>
                </template>
            </date-picker>
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable">
            <template v-if="value">{{value | pretty('long')}}</template>
            <template v-else>
                <div v-if="placeholder" class="placeholder">{{placeholder}}</div>
                <div v-else class="empty">&mdash;</div>
            </template>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueTimestamp',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                open: false
            }
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return this.override ? this.override : this.original;
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                    const date = new Date(value);
                    if (value && date instanceof Date && !isNaN(date)) {
                        return date;
                    } else {
                        return null;
                    }
                },
                set(value){
                    // I know this seems odd, but it is intentional.
                    // We do not want to persist changes to the $store on each keystroke.
                    // Instead, we're waiting for the user to hit enter and we will then:
                    // 1. Parse the date they entered.
                    // 2. Save the override to the $store.
                    this.open = false;
                    if (value instanceof Date && !isNaN(value)) {
                        this.$store.commit('entities/override', {property: this.property, value: value.toISOString()});
                    } else {
                        this.$store.commit('entities/override', {property: this.property, value: null});
                    }
                    return;
                }
            }
        },
        methods: {
            edit(){
                if(this.editable){
                    if(this.value){
                        // Set the default override value to the current property value.
                        this.$store.commit('entities/override', {property: this.property, value: this.value});
                    }

                    this.$emit('edit');
                    this.open = true;
                }
            },
            save(e){
                // const date = new Date(e.target.value);
                // if (date instanceof Date && !isNaN(date)) {
                //     this.$store.commit('entities/override', {property: this.property, value: date.toISOString()});
                // } else {
                //     this.$store.commit('entities/override', {property: this.property, value: null});
                // }
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    input.editable-input
    {
        border-radius: 0;
        width: 100%;
        margin: 0;
        height: 22px;
        line-height: 22px;
        padding: 0;
        border: 0;
        min-width: 150px;
        font-size: 14px;
        color: @base;

        &:focus
        {
            box-shadow: none;
        }
    }
</style>