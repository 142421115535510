<template>
    <div class="integrate-source-provider-sftp">
        <integrate-source-editor-step name="Push or Pull Data" icon="iconoir-data-transfer-both" :complete="completed.includes('mode')">
            <div class="helptext">
                This will determine the flow of data and influence the rest of the setup process.
            </div>
            <div class="flex flex-column sftp-options">
                <div class="card integrate-radio flex flex-align" :class="{selected: mode === 'pull'}" @click="pull">
                    <span class="block icon iconoir-download"></span>
                    <div class="ff">
                        I want Edlink to pull data from my school's SFTP server
                    </div>
                </div>
                <div class="card integrate-radio flex flex-align" :class="{selected: mode === 'push'}" @click="push">
                    <span class="block icon iconoir-upload"></span>
                    <div class="ff">
                        I want to push data to an Edlink SFTP server
                    </div>
                </div>
            </div>
        </integrate-source-editor-step>
        <template v-if="!mode">
            <integrate-source-editor-step :loading="true" />
        </template>
        <template v-if="mode === 'pull'">
            <integrate-source-editor-step name="Enter Your SFTP Host" icon="iconoir-www" :complete="completed.includes('host')">
                <div class="helptext">
                    This is the URL where your SFTP lives. You do not need to include the protocol or port in this field.
                </div>
                <input class="medium" type="text" placeholder="server.school.edu" :value="source.configuration.host" @input="e => send('UPDATE_SOURCE', {path: 'configuration.host', value: e.target.value})" />
            </integrate-source-editor-step>
            <integrate-source-editor-step name="SFTP Port" icon="iconoir-server-connection" :complete="completed.includes('port')">
                <div class="helptext">
                    Typically, this is port 22.
                </div>
                <input class="micro" type="text" placeholder="22" :value="source.configuration.port" @input="e => send('UPDATE_SOURCE', {path: 'configuration.port', value: e.target.value})" />
            </integrate-source-editor-step>
            <integrate-source-editor-step name="SFTP Credentials" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
                <div class="helptext">
                    The username and password we should use to connect.
                </div>
                <input class="medium" type="text" placeholder="Username" :value="source.configuration.username" @input="e => send('UPDATE_SOURCE', {path: 'configuration.username', value: e.target.value})" />
                <input class="medium sftp-password" type="text" placeholder="Password" :value="source.configuration.password" @input="e => send('UPDATE_SOURCE', {path: 'configuration.password', value: e.target.value})" />
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Folder Path" icon="iconoir-folder" :complete="completed.includes('path')">
                <div class="helptext">
                    The path to the folder where your uploaded files are located. Use a `/` (slash) if your files are in the root of your SFTP server.
                </div>
                <input class="medium" type="text" placeholder="/path/to/your/folder" :value="source.configuration.path" @input="e => send('UPDATE_SOURCE', {path: 'configuration.path', value: e.target.value})" />
            </integrate-source-editor-step>
        </template>
        <template v-if="mode === 'push'">
            <integrate-source-editor-step name="Configure Your Source System" icon="iconoir-input-field" :complete="completed.includes('remote')">
                <div class="helptext">
                    Add the Edlink client ID to your SFTP instance by going to the Rest API Connections page and clicking the "Add Application" button.
                </div>
                <integrate-source-editor-configuration title="Server Connection Details" icon="iconoir-server-connection">
                    <label>Host</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => 'sftp.ed.link'" v-clipboard:success="() => $toasted.info('Copied')">sftp.ed.link</div>
                    </div>
                    <label>Port</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => '2022'" v-clipboard:success="() => $toasted.info('Copied')">2022</div>
                    </div>
                    <label>Username</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => source.configuration.username" v-clipboard:success="() => $toasted.info('Copied')">{{source.configuration.username}}</div>
                    </div>
                    <label>Password</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => source.configuration.password" v-clipboard:success="() => $toasted.info('Copied')">{{source.configuration.password}}</div>
                    </div>
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Mark as Complete" :checked.sync="remote" />
                </div>
            </integrate-source-editor-step>
        </template>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderSFTP',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            team(){
                return this.state.context.team;
            },
            completed(){
                // We are going to calculate which steps are completed.
                // If there is no source for some reason, just return an empty array.
                if(!this.mode){
                    return [];
                }

                if(this.mode === 'push'){
                    return this.remote ? ['mode', 'remote'] : ['mode'];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.host.includes('.')){
                    return ['mode'];
                }

                // 2. Validate that application ID has been configured remotely.
                if(!this.source.configuration.port){
                    return ['mode', 'host'];
                }

                // 3. Validate that the token has been acquired.
                if(!this.source.configuration.username || !this.source.configuration.password){
                    return ['mode', 'host', 'port'];
                }

                if(!this.source.configuration.path){
                    return ['mode', 'host', 'port', 'credentials'];
                }

                return ['mode', 'host', 'port', 'credentials', 'path'];
            }
        },
        watch: {
            completed(){
                if(this.mode === 'push'){
                    return this.$emit('update:complete', this.remote);
                }

                this.$emit('update:complete', this.completed.length === 5);
            }
        },
        methods: {
            push(){
                this.mode = 'push';

                if(this.credentials){
                    return this.send('UPDATE_SOURCE', {
                        path: 'configuration',
                        value: {
                            host: 'sftp.ed.link',
                            path: '',
                            port: '2022',
                            username: this.credentials.username,
                            password: this.credentials.password
                        }
                    });
                }
                
                this.$http.post(`/teams/${this.team.id}/sources/sftp/users`, {})
                    .then(response => {
                        // Save the credentials in case they flip to pull and back to push.
                        this.credentials = response.$data;

                        this.send('UPDATE_SOURCE', {
                            path: 'configuration',
                            value: {
                                host: 'sftp.ed.link',
                                path: '',
                                port: '2022',
                                username: response.$data.username,
                                password: response.$data.password
                            }
                        });
                    })
                    .catch(error => {
                        this.$toasted.error('There was an error generating SFTP keys.');
                    });
            },
            pull(){
                this.mode = 'pull';
                
                this.send('UPDATE_SOURCE', {
                    path: 'configuration',
                    value: {
                        host: '',
                        path: '',
                        port: '',
                        username: '',
                        password: ''
                    }
                });
            }
        },
        data(){
            return {
                mode: null,
                remote: false,
                credentials: null
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .sftp-options
    {
        align-items: flex-start;
        margin-top: 20px;

        label.checkbox
        {
            margin-top: 10px;
        }
    }

    .sftp-password
    {
        margin-top: 10px;
    }
</style>
