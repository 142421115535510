<template>
    <article class="document">
        <h1>Edlink Documentation</h1>
        <p>
            Welcome to the Edlink docs. Here you will find helpful information on a variety of different functions of the Edlink platform.
            The documentation is split into seven sections:
        </p>
        <ol>
            <li>
                <p>
                    <router-link to="/docs/api/v2.0/introduction">Graph API</router-link>
                </p>
                <p>
                    This section contains technical documentation on Edlink school rostering data. The Graph API is designed
                    for developers who need access to school data at an administrative level. The Graph API is designed to be used in conjunction with the
                    User Data API, but this is not a requirement.
                </p>
            </li>
            <li>
                <p>
                    <router-link to="/docs/api/v2.0/introduction">User Data API</router-link>
                </p>
                <p>
                    This section contains technical documentation on interacting with specific user data. This includes everything from single sign on (SSO), to
                    retrieving courses for a specific user, to sending grades back to a learning management system.
                </p>
            </li>
            <li>
                <p>
                    <router-link to="/docs/dashboard/introduction">Edlink Dashboard</router-link>
                </p>
                <p>
                    This section contains non-technical documentation for Edlink partners on the how to use the Edlink Dashboard. The Dashboard is the application
                    that contains functionality for managing applications, data, and integrations. In fact, you're interacting with the Dashboard at this very moment.
                </p>
            </li>
            <li>
                <p>
                    <router-link to="/docs/schools/introduction">Administrators</router-link>
                </p>
                <p>
                    This section contains non-technical guides for how to interact with Edlink as an administrator. These guides detail the integration process for each
                    system that we connect with, as well as how to perform basic tasks on the Edlink Dashboard.
                </p>
            </li>
            <li>
                <p>
                    <router-link to="/docs/schools/introduction">Teachers</router-link>
                </p>
                <p>
                    This section contains non-technical guides for how to interact with Edlink as a teacher. These guides detail the login process for each
                    system that we connect with, as well as how to troubleshoot login issues.
                </p>
            </li>
            <li>
                <p>
                    <router-link to="/docs/changelog">Changelogs</router-link>
                </p>
                <p>
                    This section contains information about changes made to our API and Dashboard (which includes our SSO screens). Each time a new version of either
                    codebase is released, we publish details about new features, changes, and bug fixes.
                </p>
            </li>
            <li>
                <p>
                    <router-link to="/docs/legal/terms">Legal Documents</router-link>
                </p>
                <p>
                    This section contains legal documentation, including our Terms of Service, Privacy Policy, Developer Terms, School Terms, and special terms for California residents.
                </p>
            </li>
        </ol>
        <p>
            In addition, you may also find a lot of helpful guides and tutorials at the <a href="https://ed.link/help" target="_blank">Edlink Knowledge Base</a>. We publish frequently on a variety of
            technical and non-technical topics. If at any time you require further support, <router-link to="/support">please contact us directly</router-link>.
        </p>
    </article>
</template>

<script>
    export default {
        name: 'DocumentationIntroduction',
        data(){
            return {
                
            };
        },
        created(){

        },
        computed: {
            sections(){
                return this.$store.state.documentation.sections;
            },
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.state.user;
            },
            application(){
                return this.$store.state.documentation.application;
            }
        },
        watch: {

        },
        methods: {

        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>