<template>
    <div class="source-settings full scroll" v-if="source">
        <section class="flex">
            <div class="section-content ff">
                <h2>Basic Configuration</h2>
                <form class="source-configuration" @submit.prevent="save">
                    <div class="form-field">
                        <label>Source Nickname</label>
                        <input type="text" v-model="updated.name" class="medium" />
                    </div>
                    <div class="form-field" v-for="(configuration, property) of updated.provider.configuration" :key="property">
                        <label>{{ configuration.title }}</label>
                        <div class="form-helptext" v-if="configuration.description">{{ configuration.description }}</div>
                        <template v-if="configuration.size !== 'hidden'">
                            <template v-if="configuration.type === 'string'">
                                <select v-if="configuration.enum" :class="configuration.size" v-model="updated.configuration[property]">
                                    <option v-for="option of configuration.enum" :key="option.value" :value="option.value">{{ option.title }}</option>
                                </select>
                                <template v-else-if="property === 'timezone'">
                                    <select :class="configuration.size" v-model="updated.configuration[property]">
                                        <option v-for="timezone of timezones" :key="timezone" :value="timezone">{{ timezone }}</option>
                                    </select>
                                </template>
                                <template v-else>
                                    <textarea v-if="configuration.size === 'textarea'" v-model="updated.configuration[property]" :disabled="configuration.disabled"></textarea>
                                    <input v-else type="text" :class="configuration.size" v-model="updated.configuration[property]" :disabled="configuration.disabled" />
                                </template>
                            </template>
                            <template v-if="configuration.type === 'number'">
                                <input type="number" :class="configuration.size" v-model="updated.configuration[property]" :disabled="configuration.disabled" />
                            </template>
                            <template v-else-if="configuration.type === 'boolean'">
                                <div class="flex">
                                    <checkbox :label="configuration.label" :checked.sync="updated.configuration[property]" />
                                </div>
                            </template>
                            <template v-else-if="configuration.type === 'file'">
                                <div class="flex">
                                    <upload class="image-upload" :url.sync="updated.configuration[property]" type="zip" />
                                </div>
                            </template>
                        </template>
                    </div>
                    <div class="form-field" v-if="updated.provider.requires_administrator_consent">
                        <label>Administrator Consent</label>
                        <div class="form-helptext">Please click the link below to provide administrative content to Edlink.</div>
                        <consent :configuration="updated.configuration" :provider="updated.provider" :value.sync="updated.configuration.tenant_id" />
                    </div>
                    <div class="form-field" v-if="updated.provider.requires_administrator_login">
                        <label>Administrator Account</label>
                        <div class="form-helptext">To complete setup, log in with an administrator account.</div>
                        <token :write="destination" :configuration="updated.configuration" :provider="updated.provider" :value.sync="updated.token" />
                    </div>
                    <div class="form-options flex flex-align">
                        <button class="save-configuration" type="submit">Save Configuration</button>
                        <div class="button white" @click="validate" :class="{ disabled: validating }">Validate Configuration</div>
                        <spinner v-if="validating" />
                    </div>
                    <div class="validation" v-if="validation.length">
                        <div class="validation-step flex flex-align" v-for="(step, index) of validation" :key="index">
                            <span class="icon block" :class="{ 'iconoir-info-empty': step.state === 'info', 'iconoir-warning-triangle-outline': step.state === 'warning', 'iconoir-delete-circled-outline': step.state === 'failed', 'iconoir-check-circled-outline': step.state === 'passed' }"></span>
                            <div class="ff">
                                <div class="validation-description">{{ step.description }}</div>
                                <div class="validation-message" v-if="step.message">{{ step.message }}</div>
                                <div class="validation-message" v-else-if="step.state === 'warning'">Something is not quite right.</div>
                                <div class="validation-message" v-else-if="step.state === 'failed'">This seems to be broken.</div>
                                <div class="validation-message" v-else-if="step.state === 'info'">Please ensure that this is correct.</div>
                                <div class="validation-message" v-else>Everything is good here.</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <section class="flex">
            <div class="section-content ff enrichment">
                <h2>Data Enrichment Configuration</h2>
                <div class="enrichment-overview">
                    Edlink can seamlessly combine data from multiple sources to create a single, unified view of the data.
                    To layer additional sources on top of <span>{{ source.name }}</span>, you can add enrichment sources below.
                </div>
                <div class="source-links">
                    <source-links :sources="sources" :links="links" :source="source" :team="team" :create="true" :loading="loading" @create="link" />
                </div>
            </div>
        </section>
        <section class="flex">
            <div class="section-content ff">
                <h2 class="danger-zone flex flex-align">
                    <div>Danger Zone</div>
                    <span class="icon iconoir-shield-alert"></span>
                </h2>
                <h4>Permanently disconnect this source.</h4>
                <div class="disconnect-warning">
                    This process is irreversible.
                    We will disconnect this source and delete any associated backups that we are storing.
                    All data from this source will be scrubbed from our database and any integrated applications will no longer have access.
                    If instead you are looking to temporarily deactivate this source to prevent any new information from syncing, use the toggle switch in the header.
                </div>
                <div class="form-options flex">
                    <div class="red button" @click="disconnect">Disconnect Source</div>
                </div>
            </div>
        </section>
        <section class="flex" v-if="admin">
            <div class="section-content ff sync">
                <h2>Sync Configuration</h2>
                <div class="sync-overview">
                    Edlink admins can fine tune how data is synced from <span>{{ source.name }}</span> into Edlink.
                </div>
                <div class="form-options flex flex-align">
                    <div class="button" @click="sync_settings">Open Sync Settings</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Options from '@/components/modals/Options.vue';
    import SelectSource from '@/components/modals/SelectSource.vue';
    import SyncConfiguration from '@/components/drawers/SyncConfiguration.vue';
    import moment from 'moment';

    export default {
        name: 'SourceSettings',
        data() {
            return {
                updated: null,
                validating: false,
                destination: false,
                loading: true,
                validation: [],
                sources: [],
                links: []
            };
        },
        created() {
            if (this.$store.getters.source) {
                this.$set(this, 'updated', JSON.parse(JSON.stringify(this.$store.getters.source)));
            }

            this.reload_links();

            // Let's make a call to the backend to see if there are any write permissions necessary for this source.
            // TODO This is a pretty inefficient way to handle this issue and it will not scale if the school has dozens or hundreds of integrations.
            this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`)
                .then(response => {
                    // We are looking for integrations where this source is the destination_id.
                    this.destination = response.$data.some(integration => integration.destination?.id === this.source.id);
                })
                .catch(error => {
                    this.$toasted.error(`There was an error loading your integrations.`)
                });
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            source() {
                return this.$store.getters.source;
            },
            admin() {
                return this.$store.getters.user?.admin;
            },
            timezones() {
                return _.uniq(['AU', 'CA', 'US', 'HU'].map(country => moment.tz.zonesForCountry(country).map(zone => zone)).flat()).sort((a, b) => a.localeCompare(b));
            }
        },
        methods: {
            sync_settings() {
                this.$store.dispatch('drawer/open', {
                    key: `sync_configuration/${this.source.id}`,
                    width: 800,
                    component: SyncConfiguration,
                    props: {}
                });
            },
            validate() {
                this.validating = true;
                this.validation = [];

                this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/validate`, {
                    baseURL: '/api/v2',
                    headers: {
                        authorization: `Bearer ${this.$store.state.user.token}`
                    }
                })
                    .then(response => this.validation = response.$data.steps)
                    .catch(error => this.$toasted.error('There was an error validating the source.'))
                    .finally(() => this.validating = false);
            },
            save() {
                // We have to commit our changes to Vuex first.
                this.$store.commit('sources/update', this.updated);

                this.$store.dispatch('sources/update')
                    .then(() => {
                        if (this.$store.getters.source) {
                            this.$set(this, 'updated', JSON.parse(JSON.stringify(this.$store.getters.source)));
                        }
                    })
                    .catch(error => this.$toasted.error('There was an error updating the source.'));
            },
            disconnect() {
                const _this = this;

                this.$modal.show(Options, {
                    title: 'Destroy Source',
                    description: 'Are you sure you want to destroy this source? This will remove all associated integrations and permanently delete any data that Edlink has stored.',
                    options: [
                        {
                            title: `Destroy This Source`,
                            color: 'red',
                            fn() {
                                _this.$store.dispatch('sources/destroy', _this.source)
                                    .then(() => _this.$toasted.success('Source has been marked for removal.'))
                                    .catch(error => _this.$toasted.error('There was an error marking the source for removal.'));
                            }
                        },
                        {
                            title: `Keep This Source`,
                            color: 'blue',
                            fn() { }
                        }
                    ]
                }, { width: 300, height: 'auto', classes: 'modal' });
            },
            reload_links(){
                return Promise.all([
                    this.$http.get(`/teams/${this.team.id}/sources`),
                    this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/links`)
                ])
                    .then(([sources, links]) => {
                        this.sources = sources.$data;
                        this.links = links.$data;

                        for (const link of this.links) {
                            link.name = this.sources.find(source => source.id === link.secondary.source_id)?.name;
                        }

                        this.loading = false;
                    })
                    .catch(error => {
                        this.$toasted.error(`There was an error loading this source's enrichment sources.`);
                    });
            },
            link() {
                const _this = this;

                this.$modal.show(SelectSource, {
                    title: 'Select an Enrichment Source',
                    filter: source => !_this.links.some(link => link.secondary.source_id === source.id),
                    select(enrichment) {
                        _this.$http.post(`/teams/${_this.team.id}/sources/${_this.source.id}/links`, {
                            link_priority: 1,
                            link_properties: {},
                            primary_source_id: _this.source.id,
                            secondary_source_id: enrichment.id
                        })
                            .then(created => {
                                _this.reload_links();
                                _this.$toasted.success('Link added.');
                            })
                            .catch(error => {
                                this.$toasted.error('There was an error adding the linked source.');
                                console.log(error);
                            });
                    }
                }, {width: 400, height: 'auto', classes: 'modal'});
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        .section-options
        {
            padding-top: 20px;
        }

        &:first-child
        {
            border-top: 0;
        }
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    .add-backup-account
    {
        margin-top: 10px;
    }

    .nothing
    {
        padding: 30px;

        .button
        {
            margin-top: 20px;
        }
    }

    .account
    {
        border: 1px solid @e4;
        border-radius: 3px;
        width: 100%;
        max-width: 320px;
        padding: 10px 15px 10px 10px;
        height: 54px;

        .account-icon
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: @e;
            margin-right: 10px;
        }

        .account-name
        {
            color: @black;
            font-size: 14px;
            font-weight: 500;
        }
    }

    textarea
    {
        min-height: 200px;
        resize: vertical;
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    .disconnect-warning,
    .enrichment-overview,
    .sync-overview
    {
        font-size: 13px;
        line-height: 18px;
        color: @grey;
        max-width: 700px;

        span
        {
            color: @black;
        }
    }

    .validation-step
    {
        margin-bottom: 10px;

        &:last-child
        {
            margin-bottom: 0;
        }

        .icon
        {
            margin-right: 10px;
            font-size: 20px;
            height: 20px;
            width: 20px;
        }

        .iconoir-warning-triangle-outline
        {
            color: @yellow;
        }

        .iconoir-delete-circled-outline
        {
            color: @red;
        }

        .iconoir-check-circled-outline
        {
            color: @green;
        }

        .validation-description
        {
            color: @grey;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 2px;
        }

        .validation-message
        {
            color: @black;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .enrichment
    {
        h2
        {
            margin-bottom: 10px;
        }

        .enrichment-overview
        {
            margin-bottom: 20px;
        }

        .source-links
        {
            max-width: 700px;
        }
    }

    .sync
    {
        h2
        {
            margin-bottom: 10px;
        }

        .sync-overview
        {

            // margin-bottom: 20px;
            .text-button
            {
                color: @base;
            }
        }
    }
</style>
