<template>
    <div class="admin-pipeline-failures flex">
        <div class="phase ff" v-for="phase of Object.keys(failures)" :key="phase">
            <div class="flex column-header">
                <template v-if="phase === 'syncs'" >
                    <div class="column-heading-label ff">
                        <h4>Failing Syncs ({{failures.syncs.length}})</h4>
                        <h5>Sources that have not synced in the past {{sync_window}} day{{sync_window === 1 ? '' : 's'}}.</h5>
                    </div>
                    <div class="column-heading-actions flex flex-align">
                        <button class="button white" :class="{selected: sync_window === 1}" @click="switch_sync_window(1, $event)">1 day</button>
                        <button class="button white" :class="{selected: sync_window === 3}" @click="switch_sync_window(3, $event)">3 days</button>
                        <button class="button white" :class="{selected: sync_window === 7}" @click="switch_sync_window(7, $event)">7 days</button>
                    </div>
                </template> 

                <template v-else-if="phase === 'enrichments'" >
                    <div class="column-heading-label ff">
                        <h4>Failing Enrichments ({{failures.enrichments.length}})</h4>
                        <h5>Sources that have not enriched successfully in the past {{enrichment_window}} day{{enrichment_window === 1 ? '' : 's'}}.</h5>
                    </div>
                    <div class="column-heading-actions flex flex-align">
                        <button class="button white" :class="{selected: enrichment_window === 1}" @click="switch_enrichment_window(1, $event)">1 day</button>
                        <button class="button white" :class="{selected: enrichment_window === 3}" @click="switch_enrichment_window(3, $event)">3 days</button>
                        <button class="button white" :class="{selected: enrichment_window === 7}" @click="switch_enrichment_window(7, $event)">7 days</button>
                    </div>
                </template>

                <template v-else-if="phase === 'materializations'" >
                    <div class="column-heading-label ff">
                        <h4>Failing Materializations ({{failures.materializations.length}})</h4>
                        <h5>Integrations that have not materialized successfully in the past {{materialization_window}} day{{materialization_window === 1 ? '' : 's'}}.</h5>
                    </div>
                    <div class="column-heading-actions flex flex-align">
                        <button class="button white" :class="{selected: materialization_window === 1}" @click="switch_materialization_window(1, $event)">1 day</button>
                        <button class="button white" :class="{selected: materialization_window === 3}" @click="switch_materialization_window(3, $event)">3 days</button>
                        <button class="button white" :class="{selected: materialization_window === 7}" @click="switch_materialization_window(7, $event)">7 days</button>
                    </div>
                </template>
            </div>
            <div class="flex ff flex-align flex-center" v-if="loading[phase]">
                <spinner/>
            </div>
            <div class="card" v-else-if="failures[phase].length">
                <template v-if="phase === 'materializations'">
                    <router-link class="pipeline-card pointer block" v-for="integration of failures[phase]" :key="integration.id" :to="{name: 'team.integrations.integration', params: { team: integration.team.alias, integration: integration.id }}">
                        <div class="flex flex-align">
                            <div class="source-title text-overflow ff">{{integration.application.name}} ({{integration.team.name}})</div>
                            <CheckCircle class="icon validation-status success" width="16" height="16" stroke-width="2" v-if="integration.source.validation?.passed" />
                            <DeleteCircle class="icon validation-status error" width="16" height="16" stroke-width="2" v-else />
                            <Provider :id="integration.provider.id"/>
                        </div>
                        <div class="pipeline-stats flex flex-align">
                            <div class="retries">{{integration.materialization.failed_count}} Consecutive Failure{{integration.materialization.failed_count === 1 ? '' : 's'}}</div>
                            <div class="ff"></div>
                            <div class="last-completed-date" v-if="integration.materialization.last_completed_date">Last Completed {{ integration.materialization.last_completed_date | pretty('medium') }}</div>
                            <div class="last-completed-date" v-else>Never Materialized</div>
                        </div>
                    </router-link>
                </template>
                <template v-else-if="phase === 'enrichments'">
                    <router-link class="pipeline-card pointer block" v-for="source of failures[phase]" :key="source.id" :to="{name: 'team.sources.source', params: { team: source.team.alias, source: source.id }}">
                        <div class="flex flex-align">
                            <div class="source-title text-overflow ff">{{source.team.name}} ({{source.name}})</div>
                            <CheckCircle class="icon validation-status success" width="16" height="16" stroke-width="2" v-if="source.validation?.passed" />
                            <DeleteCircle class="icon validation-status error" width="16" height="16" stroke-width="2" v-else />
                            <Provider :id="source.provider.id"/>
                        </div>
                        <div class="pipeline-stats flex flex-align">
                            <div class="retries">{{source.enrichment.failed_count}} Consecutive Failure{{source.enrichment.failed_count === 1 ? '' : 's'}}</div>
                            <div class="ff"></div>
                            <div class="last-completed-date" v-if="source.enrichment.last_completed_date">Last Completed {{ source.enrichment.last_completed_date | pretty('medium') }}</div>
                            <div class="last-completed-date" v-else>Never Enriched</div>
                        </div>
                    </router-link>
                </template>
                <template v-else>
                    <router-link class="pipeline-card pointer block" v-for="source of failures[phase]" :key="source.id" :to="{name: 'team.sources.source', params: { team: source.team.alias, source: source.id }}">
                        <div class="flex flex-align">
                            <div class="source-title text-overflow ff">{{source.team.name}} ({{source.name}})</div>
                            <CheckCircle class="icon validation-status success" width="16" height="16" stroke-width="2" v-if="source.validation?.passed" />
                            <DeleteCircle class="icon validation-status error" width="16" height="16" stroke-width="2" v-else />
                            <Provider :id="source.provider.id"/>
                        </div>
                        <div class="pipeline-stats flex flex-align">
                            <div class="retries">{{source.sync.failed_count}} Consecutive Failure{{source.sync.failed_count === 1 ? '' : 's'}}</div>
                            <div class="ff"></div>
                            <div class="last-completed-date" v-if="source.sync.last_completed_date">Last Completed {{ source.sync.last_completed_date | pretty('medium') }}</div>
                            <div class="last-completed-date" v-else>Never Synced</div>
                        </div>
                    </router-link>
                </template>
            </div>
            <div class="nothing" v-else>
                Things are all good in {{ phase }}.
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Provider from '@/components/chips/Provider.vue';
    import State from '@/components/chips/State.vue';
    import { CloudError, DatabaseBackup, Sparks, CheckCircle, DeleteCircle } from '@epiphany/iconoir';

    const default_window = 7;

    export default {
        name: 'AdminPipelineFailures',
        components: {
            CloudError,
            DatabaseBackup,
            Provider,
            State,
            Sparks,
            CheckCircle,
            DeleteCircle
        },
        data(){
            return {
                loading: {
                    enrichments: true,
                    materializations: true,
                    syncs: true
                },
                failures: {
                    syncs: [],
                    enrichments: [],
                    materializations: []
                },
                enrichment_window: default_window,
                materialization_window: default_window,
                sync_window: default_window,
            }
        },
        created(){
            this.loadAll();
        },
        destroyed(){
        },
        methods: {
            format_last_completed_dates(failures) {
                failures.forEach(failure => {
                    failure.formatted_last_completed_date = failure.last_completed_date ? this.$options.filters.pretty(failure.last_completed_date, 'medium') : 'Never completed';
                });
            },
            get_query_filter(number_of_days) {
                const from_date = moment().subtract(number_of_days, 'days').format('YYYY-MM-DD');
                return JSON.stringify({
                    created_date: [{
                        operator: 'lte',
                        value: from_date
                    }]
                });
            },
            go_to_source_page(source) {
                // this.$router.push();
            },
            loadAll() {
                this.loadEnrichmentFailures();
                this.loadMaterializationFailures();
                this.loadSyncFailures();
            },
            loadEnrichmentFailures() {
                this.$http.get('/admin/pipeline/enrichments/failures', {
                    params: {
                        $filter: this.get_query_filter(this.enrichment_window)
                    }
                }).then((response) => {
                    this.failures.enrichments.push.apply(this.failures.enrichments, response.$data);
                    this.format_last_completed_dates(this.failures.enrichments);
                    this.loading.enrichments = false;
                });
            },
            loadMaterializationFailures() {
                this.$http.get('/admin/pipeline/materializations/failures', {
                    params: {
                        $filter: this.get_query_filter(this.materialization_window)
                    }
                }).then((response) => {
                    this.failures.materializations.push.apply(this.failures.materializations, response.$data);
                    this.format_last_completed_dates(this.failures.materializations);
                    this.loading.materializations = false;
                });
            },
            loadSyncFailures() {
                this.$http.get('/admin/pipeline/syncs/failures', {
                    params: {
                        $filter: this.get_query_filter(this.sync_window)
                    }
                }).then((response) => {
                    this.failures.syncs.push.apply(this.failures.syncs, response.$data);
                    this.format_last_completed_dates(this.failures.syncs);
                    this.loading.syncs = false;
                });
            },
            switch_enrichment_window(selected_window, evt) {
                this.enrichment_window = selected_window || default_window;
                this.failures.enrichments.length = 0;
                this.loading.enrichments = true;
                this.loadEnrichmentFailures();
            },
            switch_materialization_window(selected_window, evt) {
                this.materialization_window = selected_window || default_window;
                this.failures.materializations.length = 0;
                this.loading.materializations = true;
                this.loadMaterializationFailures();
            },
            switch_sync_window(selected_window, evt) {
                this.sync_window = selected_window || default_window;
                this.failures.syncs.length = 0;
                this.loading.syncs = true;
                this.loadSyncFailures();
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .admin-pipeline-failures
    {
        padding: @section-padding / 2;
    }

    .phase {
        margin: @section-padding / 2;

        .column-header
        {
            margin-bottom: @section-padding;
        }

        .column-heading-label
        {
            .icon
            {
                margin-right: 6px;
            }

            h4
            {
                color: @black;
                margin-bottom: 6px;
                font-size: 13px;
            }

            h5
            {
                font-size: 12px;
                color: @grey;
            }
        }

        .column-heading-actions {
            .button
            {
                margin-left: 6px;
                // padding: 0;

                &.selected { 
                    border-color: @base;
                    background-color: fade(@base, 10);
                    color: @base;
                }
            }
        }

        .pipeline-metadata {
            padding: 8px 10px;
            border-bottom: 1px solid @e4;
        }

        .card {
            height: 500px;
            overflow-y: auto;
        }
    }

    .pipeline-card
    {
        padding: @single-padding;
        border-bottom: 1px solid @e4;

        .pipeline-stats
        {
            margin-top: 6px;
            font-size: 12px;
            color: @grey;
        }

        .validation-status
        {
            margin-right: 6px;
            font-size: 12px;

            &.success
            {
                color: @green;
            }

            &.error
            {
                color: @red;
            }
        }

        &:hover, &:active
        {
            background: @f8;
        }

        &:last-child
        {
            border-bottom: 0;
        }
    }

    .source-title {
        color: @black;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
        line-height: 18px;
    }
</style>