<template>
    <div class="open-ai flex flex-column">
        <div class="instructions">
            <p>
                This block enables you to describe the changes you want in plain english.
                Edlink will then use AI to transform the data into your desired format.
                To configure this block, enter a prompt that describes the changes you want to make to the data.
            </p>
            <h3>Example Prompts</h3>
            <ul>
                <li>Change all students to teachers</li>
                <li>Remove all parents from the dataset</li>
                <li>Remove any punctuation or emojis from class or courses titles</li>
                <li>Change any emails that end with "gmail.com" to end with "myschool.com"</li>
            </ul>
            <h3>Tips For Better Results</h3>
            <ul>
                <li>Be as specific as possible with your instructions.</li>
                <li>To perform multiple changes you can use multiple AI blocks.</li>
                <li>Try rewording your prompt if the AI fails.</li>
            </ul>
            <label>Enter a prompt describing the functionality of this block</label>
            <textarea v-model="prompt" :disabled="loading"></textarea>
            <div class="options flex flex-align">
                <div class="button generate" :disabled="loading" @click="generate">Transform</div>
                <checkbox label="Show code" :borderless="true" :checked.sync="show_code" />
            </div>
        </div>
        <div class="result ff" v-if="show_code">
            <editor :value="transformation.configuration.function" @input="edit" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TransformOpenAI',
        props: {
            transformation: Object
        },
        data(){
            return {
                prompt: '',
                loading: false,
                show_code: false,
                edit_timeout: null
            }
        },
        computed: {
            source() {
                return this.$store.getters.source;
            },
            integration() {
                return this.$store.getters.integration;
            },
            parent() {
                return this.source ?? this.integration;
            },
            provider(){
                return this.parent.provider;
            },
            team() {
                return this.$store.getters.team;
            }
        },
        mounted(){
            if (this.transformation?.configuration) {
                this.prompt = this.transformation.configuration.prompt;
            }
        },
        methods: {
            generate(){
                this.loading = true;

                this.$http.post(`/teams/${this.team.id}/blocks/generate`, { prompt: this.prompt })
                    .then(({$data}) => {
                        this.transformation.configuration = {
                            prompt: this.prompt,
                            function: $data.function
                        };
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            edit(value){
                this.transformation.configuration['function'] = value;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .open-ai
    {
        font-size: 13px;
        color: @black;
        height: 100%;
    }

    .config {
        padding: 0 25px;
    }

    .options {
        margin-top: 10px;
    }

    .generate {
        margin-right: 10px;
    }
    
    .result {
        min-height: 225px;
    }

    .edit-transformation-function
    {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

.preview-instructions
{
    .preview-transformation-header
    {
        padding-bottom: 0;
        margin-bottom: 20px;
    }
}

.instructions
{
    color: @black;
    font-size: 15px;
    padding: 0 25px;
    max-width: 600px;
    line-height: 22px;
    margin-bottom: 25px;

    p
    {
        margin: 20px 0;

        &:first-child
        {
            margin-top: 0;
        }
    }

    ul {
        list-style-type: none;
        margin: 20px 0 0;
        text-align: left;

        li {
            list-style-type: '\2013';
            margin-left: 20px;
            padding-left: 5px;
        }
    }

    h3
    {
        margin-top: 30px;
    }

    h3 + p, h3 + ul
    {
        margin-top: 10px;
    }

    del {
        color: @red;
    }

    .base {
        color: @base;
    }
}
</style>