<template>
    <div class="command-palette-team flex flex-align">
        <span class="block icon iconoir-group"></span>
        <div class="ff">
            <div class="result-title flex flex-align">
                {{data.name}}
                <div class="verified" v-if="data.verified"></div>
            </div>
            <div class="result-description flex flex-align capitalize">{{description}}</div>
        </div>
        <div class="kbd" v-if="selected">Enter ⏎</div>
    </div>
</template>

<script>
    export default {
        name: 'CommandPaletteTeam',
        props: {
            index: Number,
            data: Object,
            selected: Boolean
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            description(){
                let descriptor = this.data.type === 'developer' ? 'developer' : 'institution';
                let location = '';

                if(this.data.city){
                    location += ` in ${this.data.city}`;

                    if(this.data.state){
                        location += `, ${this.data.state}`;
                    }

                    if(this.data.country && this.data.country !== 'United States'){
                        location += `, ${this.data.country}`;
                    }
                }else if(this.data.state){
                    location += ` in ${this.data.state}`;

                    if(this.data.country && this.data.country !== 'United States'){
                        location += `, ${this.data.country}`;
                    }
                }else if(this.data.country && this.data.country !== 'United States'){
                    location += ` in ${this.data.country}`;
                }

                if(this.data.descriptor){
                    descriptor = this.data.descriptor;
                }

                // Uppercase the first character of the descriptor.
                descriptor = descriptor.charAt(0).toUpperCase() + descriptor.slice(1);

                return `${descriptor}${location}`;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .verified
    {
        background: url('~@/assets/icons/white/verified.svg') no-repeat;
        background-position: left 1px top 1px;
        background-size: 14px auto;
        margin-left: 4px;
        width: 16px;
        height: 16px;
    }
</style>
