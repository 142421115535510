<template>
    <div class="preview-entity-enrollment preview-entity" :class="state">
        <div class="entity-data-row flex flex-align">
            <div class="entity-name text-overflow" :class="{empty: !entity.person_id}">
                {{ entity.person ? (entity.person.display_name ? entity.person.display_name : `${entity.person.first_name} ${entity.person.last_name}`) : 'No Person' }}
            </div>
            <div class="entity-badges flex">
                <div class="entity-badge capitalize">
                    {{ entity.role }}
                </div>
            </div>
            <div class="ff"></div>
            <div class="entity-property primary">
                {{ entity.class ? entity.class.name : 'No Class' }}
            </div>
        </div>
        <div class="entity-data-row flex flex-align">
            <div class="entity-property text-overflow">
                <!-- <template v-if="debug">{{ debug }}</template>
                <template v-else-if="deletion">Deleted by this block</template>
                <template v-else-if="modifications.length > 3">{{ modifications.length }} properties modified by this block</template>
                <template v-else-if="modifications.length">{{ modification_text }} modified by this block</template>
                <template v-else-if="diff">Modified by this block</template>
                <template v-else>Not modified by this block</template> -->
            </div>
            <div class="ff"></div>
            <div class="entity-property">
                {{ !entity.identifiers || entity.identifiers.length === 0 ? 'No' : entity.identifiers.length}} Identifier{{ entity.identifiers && entity.identifiers.length === 1 ? '' : 's' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreviewEntityEnrollment',
    props: {
        entity: Object,
        diff: Boolean,
        deletion: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            properties: {
                class_id: 'Class',
                person_id: 'Person',
                role: 'Role',
                identifiers: 'Identifiers',
                start_date: 'Start Date',
                end_date: 'End Date'
            },
            exclude: [
                'integration_id',
                'source_id',
                'materialization_id',
                'enrichment_id',
                'staged_change_created_date',
                'sync_id'
            ]
        };
    },
    computed: {
        current_transformation() {
            return this.$store.state.blink.transformation;
        },
        debug(){
            // Is this the sharing rules block? Ok then do we have a shared reason from the debug object?
            return this.current_transformation && this.current_transformation.block.id === 'd1df7a33-c5f1-49d7-850d-d03c7ecb335f' ? _.get(this.entity, 'debug.shared_reason', false) : false;
        },
        state(){
            // TODO Show the proper state for items that were inserted or deleted
            return {
                updated: this.modifications.length > 0,
                deleted: this.deletion
            };
        },
        modification_text(){
            const names = [];

            // BUG: If an array is modified this is the path to the array index that changed
            for(const path of this.modifications){
                names.push(
                    _.get(this.properties, path, path)
                );
            }

            if(names.length === 1){
                return names[0];
            }

            return names.slice(0, -1).join(', ') + (names.length > 2 ? ', and ' : ' and ') + names[names.length - 1];
        },
        modifications(){
            const set = new Set();
            
            if(Array.isArray(this.diff)){
                for(const { path } of this.diff){
                    if(this.exclude.includes(path.join('.'))){
                        continue;
                    }

                    set.add(path.join('.'));
                }
            }

            return Array.from(set);
        }
    }
}
</script>

<style>

</style>