<template>
    <div class="summary-value copyable noshift" v-clipboard="() => secret" v-clipboard:success="() => $toasted.info('Copied')">
        {{secret | password}}
    </div>
</template>

<script>
    export default {
        name: 'PasswordText',
        props: {
            secret: String
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .summary-value.copyable
    {
        line-height: 22px;
        height: 22px;
    }
</style>
