import { render, staticRenderFns } from "./SwipeTwoFingersDownGesture.vue?vue&type=template&id=797b303c"
import script from "./SwipeTwoFingersDownGesture.vue?vue&type=script&lang=js"
export * from "./SwipeTwoFingersDownGesture.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports