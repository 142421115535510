<template>
    <div class="remove-icon" @click="e => $emit('click', {row, column})">
        <DeleteCircle class="block" width="16" height="16" stroke-width="1.5" stroke="#006aff" />
    </div>
</template>

<script>
    import { DeleteCircle } from '@epiphany/iconoir';

    export default {
        name: 'RemoveIcon',
        components: {
            DeleteCircle
        },
        props: {
            column: Object,
            row: Object
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .remove-icon
    {
        color: @red;
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
</style>
