<template>
    <div class="entity flex flex-column">
        <header class="drawer-header">
            <div class="flex flex-align">
                <div class="entity-icon flex flex-align flex-center">
                    <span class="icon block iconoir-add-hexagon"></span>
                </div>
                <h2 class="ff drawer-title">Override</h2>
                <div class="button red" @click="delete_override">Delete</div>
                <div class="button capitalize" @click="go_to">Go To {{entity.type}}</div>
                <div class="button white" @click="close">Close</div>
            </div>
            <nav class="flex">
                <div class="destination" :class="{active: tab === 'details'}" @click="tab = 'details'">Details</div>
                <div class="destination" :class="{active: tab === 'json'}" @click="tab = 'json'">JSON</div>
                <template v-if="type === 'people'">
                    <div class="destination" :class="{active: tab === 'enrollments'}" @click="tab = 'enrollments'">Enrollments</div>
                    <!-- <div class="destination" :class="{active: tab === 'requests'}" @click="tab = 'requests'">Requests</div>
                    <div class="destination" :class="{active: tab === 'logins'}" @click="tab = 'logins'">Logins</div> -->
                </template>
                <div class="ff"></div>
                <!-- <div class="destination right" :class="{active: tab === 'events'}" @click="tab = 'events'">Events</div> -->
            </nav>
        </header>
        <div class="ff drawer-scroll">
            <component :is="tabs[tab]" />
        </div>
    </div>
</template>

<script>
    import OverrideDetails from '@/components/drawers/override/OverrideDetails.vue';
    import EntityJSON from '@/components/drawers/entity/EntityJSON.vue';
    import Entity from '@/components/drawers/Entity.vue';

    const plural_map = {
        'agent': 'agents',
        'class': 'classes',
        'course': 'courses',
        'district': 'districts',
        'enrollment': 'enrollments',
        'person': 'people',
        'school': 'schools',
        'section': 'sections',
        'session': 'sessions'
    }

    export default {
        name: 'Override',
        data(){
            return {
                tab: 'details',
                tabs: {
                    details: OverrideDetails,
                    json: EntityJSON
                },
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            },
            go_to(){
                this.$store.dispatch('drawer/loading');
                this.$store.dispatch('drawer/replace', {component: Entity, width: 800});
                this.$store.dispatch('entities/activate', {
                    id: this.entity.entity_id,
                    type: plural_map[this.entity.type],
                    integration: this.integration
                })
                .then(() => {
                    this.$store.dispatch('drawer/ready');
                });
                // this.$router.push({name: 'team.integrations.integration.dataset', params: {integration: this.integration.id, type: plural_map[this.entity.type]}});
            },
            delete_override(){
                this.$store.dispatch('drawer/loading');
                this.$http.delete(`/teams/${this.team.id}/integrations/${this.integration.id}/overrides/${this.entity.id}`)
                .then(() => {
                    this.$store.dispatch('drawer/close');
                    this.$toasted.success('Override deleted.');
                }).catch(err => {
                    this.$store.dispatch('drawer/ready');
                    this.$toasted.error('Error deleting override.');
                });
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity
    {
        height: 100%;
    }

    .drawer-container
    {
        .drawer-header
        {
            padding-bottom: 0;
            
            .button
            {
                margin-left: 15px;
            }
        }
    }
    
    nav
    {
        border-bottom: 1px solid @e4;
        margin-top: 10px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px;
    }

    .entity-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);
        margin-right: 15px;

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }
</style>
