<template>
    <div class="create-product">
        <div class="flex flex-align drawer-header">
            <h2 class="ff drawer-title text-overflow">{{drawer_title}}</h2>
            <!-- <div class="button white red delete" v-if="props.product" @click="delete_product">Delete Product</div> -->
            <div class="button white base" @click="close">Close</div>
        </div>
        <div class="drawer-content drawer-scroll">
            <div class="drawer-section">
                <form @submit.prevent="save" autocomplete="off">
                    <label for="from">Product Name</label>
                    <div class="form-helptext">This is the human-readable name of your product. It will help if this name is distinct from other products you create.</div>
                    <input type="text" class="block" v-model="product.name" name="product_name" />
                    <label for="from">Product Code</label>
                    <div class="form-helptext">This code should be an internal identifier or SKU used to programmatically identify products within your system.</div>
                    <input type="text" class="block" v-model="product.code" name="product_code" />
                    <label for="from">State</label>
                    <div class="form-helptext">The state your product is in the product lifecycle.</div>
                    <select class="block" v-model="product.state" name="product_state">
                        <option value="active">Active</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="inactive">Inactive</option>
                        <option value="sunsetting">Sunsetting</option>
                        <option value="deprecated">Deprecated</option>
                    </select>
                    <label for="from">Duration</label>
                    <div class="form-helptext">This is the duration in days that your product should be licensed for.</div>
                    <input type="number" class="block" v-model="product.license_duration" name="product_license_duration" />
                    <label for="from">Tags</label>
                    <div class="form-helptext">Tags associated with your product.</div>
                    <custom-select class="block" :values.sync="product.tags" :paste="true" :array="true" ref="input" />
                    <label for="from">Product Description</label>
                    <div class="form-helptext">An optional description of the product.</div>
                    <textarea class="block" v-model="product.description" name="product_description" />
                    <label>Product Icon</label>
                    <div class="form-helptext">A square icon that is 200 x 200 pixels is ideal.</div>
                    <upload class="image-upload" :url.sync="product.picture_url" type="image" />
                    <div class="flex form-options">
                        <button type="submit" class="button">{{drawer_button_text}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CustomSelect from '@/components/CustomSelect.vue';

    export default {
        name: 'ProductDrawer',
        components: {
            'custom-select': CustomSelect,
        },
        data(){
            return {
                product: {
                    id: '',
                    name: '',
                    code: '',
                    description: '',
                    picture_url: '',
                    tags: [],
                    license_duration: '',
                    state: 'inactive'
                }
            };
        },
        mounted() {
            if(this.props.product) {
                this.product =  _.cloneDeep(this.props.product);
            }
        },
        methods: {
            save(){
                if(this.product.name.length === 0) {
                    return this.$toasted.error('Please input a product name.')
                }
                else if(this.product.code.length === 0) {
                    return this.$toasted.error('Please input a product code.')
                }

                if(this.creating) {
                    this.$http.post(`/teams/${this.team.id}/products`, this.product, { baseURL: '/api/v2' })
                        .then(response => {
                            // Close the drawer.
                            this.close();
                            // Refresh the list of products
                            this.props.refreshParent();
                        })
                        .catch(error => this.$toasted.error('There was an error creating your new product.'));
                } else {
                    this.$http.patch(`/teams/${this.team.id}/products/${this.product.id}`, this.product, { baseURL: '/api/v2' })
                        .then(response => {
                            // Close the drawer.
                            this.close();
                            // Refresh the list of products
                            this.props.refreshParent();
                        })
                        .catch(error => this.$toasted.error('There was an error updating your product.'));
                }
            },
            update(){
                this.$store.dispatch('products/update');
            },
            delete_product() {
                this.$http.delete(`/teams/${this.team.id}/products/${this.product.id}`, { baseURL: '/api/v2' })
                    .then(response => {
                        // Close the drawer.
                        this.close();
                        // Refresh the list of products
                        this.props.refreshParent();
                    })
                    .catch(error => this.$toasted.error('There was an error deleting your product.'));
            },
            close(){
                this.$store.dispatch('drawer/close');
            }
        },
        computed: {
            creating(){
                return this.props.is_create;
            },
            team(){
                return this.$store.getters.team;
            },
            drawer_button_text() {
                if(this.creating) {
                    return 'Create Product'
                } else {
                    return 'Save Product'
                }
            },
            drawer_title() {
                if(this.creating) {
                    return 'Create a Product'
                } else {
                    return 'Edit Product'
                }
            },
            props(){
                return this.$store.state.drawer.props;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-product
    {
        height: 100%;
    }
    
    header
    {
        border-bottom: 1px solid @e4;

        .button
        {
            margin-left: 20px;
        }
    }

    .drawer-scroll
    {
        overflow: auto;

        .drawer-section:last-child
        {
            padding: @double-padding;

            .form-options
            {
                padding-bottom: 0;
            }
        }
    }

    ::v-deep .autocomplete .autocomplete-search {
        border-radius: 6px;
    }
</style>
