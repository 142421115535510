<template>
    <div class="login flex">
        <div class="login-pane ff flex flex-align">
            <a class="wordmark block" href="https://ed.link"></a>
            <div class="token-container bc" v-if="loading"></div>
            <div class="token-container bc" v-else-if="token">
                <h1>Signing you in</h1>
                <h3>Hang tight while we load your teams</h3>
                <spinner />
            </div>
            <div class="token-container bc" v-else-if="unhandled">
                <h1>Error signing in</h1>
                <h3>We're not quite sure what went wrong</h3>
            </div>
            <div class="two-factor-container bc" v-else-if="two">
                <form @submit.prevent="activate">
                    <h1>Two-factor authentication</h1>
                    <h3>Enter the six digit code generated by your app</h3>
                    <div class="flex flex-align two-factor-inputs" ref="digits">
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="one" v-model="digits.one" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="two" v-model="digits.two" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="three" v-model="digits.three" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="four" v-model="digits.four" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="five" v-model="digits.five" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="six" v-model="digits.six" type="text" :class="{error: error.two}" />
                    </div>
                    <!-- <div class="form-error" v-if="error.two">There was an error with your two factor authentication code. Please contact us if you need assistance.</div> -->
                    <button type="submit" class="large" :class="{loading: attempting}">Confirm 2FA Code</button>
                </form>
                <div class="form-disclaimer">
                    Looking for something else? <br />
                    <router-link class="dashed-button" :to="{name: 'forgot'}">Forgot Password</router-link> or
                    <router-link class="dashed-button" :to="{name: 'register'}">Create Account</router-link>
                </div>
            </div>
            <div class="login-container bc" v-else>
                <h1>Welcome back</h1>
                <h3>Sign into your account</h3>
                <div class="sso-options">
                    <div class="sso-option google white large button" @click="google">
                        Sign In With Google
                    </div>
                    <!-- <div class="sso-option microsoft white large button" @click="microsoft">
                        Sign In With Microsoft
                    </div> -->
                </div>
                <div class="or flex flex-align flex-center">
                    <div class="or-text">or</div>
                </div>
                <form @submit.prevent="login">
                    <label for="email">Email</label>
                    <input type="text" v-model="user.email" name="email" :class="{error: error.email}" />
                    <label for="password">Password</label>
                    <input type="password" v-model="user.password" name="password" :class="{error: error.password}" />
                    <div class="form-error" v-if="error.message">{{error.message}}</div>
                    <button type="submit" class="large" :class="{loading: attempting}">Sign In</button>
                </form>
                <div class="form-disclaimer">
                    Looking for something else? <br />
                    <router-link class="dashed-button" :to="{name: 'forgot'}">Forgot Password</router-link> or
                    <router-link class="dashed-button" :to="{name: 'register'}">Create Account</router-link>
                </div>
            </div>
        </div>
        <div class="login-background"></div>
    </div>
</template>

<script>
    import querystring from 'querystring';
    import axios from 'axios';

    export default {
        name: 'Login',
        data(){
            return {
                loading: true,
                token: false,
                unhandled: false,
                error: {
                    message: '',
                    email: false,
                    password: false,
                    two: false
                },
                attempting: false,
                two: false,
                user: {
                    email: '',
                    password: ''
                },
                digits: {
                    one: '',
                    two: '',
                    three: '',
                    four: '',
                    five: '',
                    six: ''
                }
            };
        },
        computed: {
            offset(){
                return this.$store.getters.banners.reduce((total, banner) => {
                    return total + banner.height;
                }, 0);
            },
            code(){
                return this.digits.one + this.digits.two + this.digits.three + this.digits.four + this.digits.five + this.digits.six;
            }
        },
        async created(){
            if(this.$route.params.token){
                this.token = true;

                // Set the token in the store.
                this.$store.dispatch('user/token', this.$route.params.token);

                try {
                    // Try to get the user profile.
                    // If this call fails, we probably need to 2FA.
                    await axios.get('/me');

                    // We're setting an artificial timeout here to allow the user to read the text.
                    setTimeout(() => {
                        this.$store.dispatch('user/login', this.$route.params.token)
                        .then(() => {
                            // BUG: This code does not get hit when the user is logging in from a popup.
                            // Prob caught buy a middleware before we get here?


                            // If we are a popup/new tab, we need to send a message to the parent window.
                            if (window.opener && window.opener !== window) {
                                window.opener.postMessage({type: 'login', success: true }, process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080');
                                window.close();
                            } else {
                                if (this.$store.getters.team) {
                                    this.$router.push({name: 'team.dashboard', params: {team: this.$store.getters.team.alias}});
                                } else {
                                    this.$router.push('/onboard');
                                }
                            }
                        });
                    }, 5000);
                }catch(error){
                    // It's possible that we'll have more token activation conditions in the future so we should observe the response.
                    if(error?.$details?.includes('mfa_required')){
                        this.two = true;
                    }else{
                        this.unhandled = true;
                    }

                    this.token = false;
                }
            }

            this.loading = false;
        },
        methods: {
            paste(e){
                const value = e.clipboardData.getData('text')?.trim();

                if(value.length === 6){
                    const digits = value.split('');

                    this.digits.one = digits[0];
                    this.digits.two = digits[1];
                    this.digits.three = digits[2];
                    this.digits.four = digits[3];
                    this.digits.five = digits[4];
                    this.digits.six = digits[5];
                    
                    // Attempt the login.
                    this.activate();
                }
            },
            keydown(e){
                const digit = e.target.getAttribute('digit');

                if(e.keyCode === 39){
                    if(e.target.nextElementSibling){
                        e.target.nextElementSibling.focus();
                    }

                    e.preventDefault();
                }else if(e.keyCode === 37){
                    if(e.target.previousElementSibling){
                        e.target.previousElementSibling.focus();
                    }

                    e.preventDefault();
                }else if(e.keyCode === 8){
                    if(e.target.value.length === 0){
                        if(e.target.previousElementSibling){
                            e.target.previousElementSibling.focus();

                            const previous = e.target.previousElementSibling.getAttribute('digit');

                            // Clear the previous digit because this one is already cleared.
                            this.digits[previous] = '';
                        }
                    }else{
                        this.digits[digit] = '';
                    }

                    e.preventDefault();
                }else if('0123456789'.includes(e.key)){
                    if(e.target.value.length === 0){
                        this.digits[digit] = e.key;
                    }

                    if(e.target.nextElementSibling){
                        e.target.nextElementSibling.focus();
                    }else if(this.code.length === 6){
                        this.activate();
                    }

                    e.preventDefault();
                }else{
                    // This was just a regular keypress.
                    // The only keypresses we want to block are non-modified ones.
                    if(!e.metaKey && !e.ctrlKey && !e.altKey){
                        e.preventDefault();
                    }
                }
            },
            login(){
                this.error.email = false;
                this.error.password = false;
                this.error.message = '';

                if(!this.user.email){
                    return this.error.email = true;
                }

                if(!this.user.password){
                    return this.error.password = true;
                }

                this.attempting = true;

                this.$http.post('/login', this.user)
                .then(response => {
                    this.attempting = false;

                    // Test the token to see if we need to activate it.
                    if(response.$data.state === 'active'){
                        return this.$store.dispatch('user/login', response.$data.token)
                        .then(() => {
                            // If we are a popup/new tab, we need to send a message to the parent window.
                            if (window.opener && window.opener !== window) {
                                window.opener.postMessage({type: 'login', success: true }, process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080');
                                window.close();
                            } else {
                                if (this.$store.getters.team) {
                                    this.$router.push({name: 'team.dashboard', params: {team: this.$store.getters.team.alias}});
                                } else {
                                    this.$router.push('/onboard');
                                }
                            }
                        });
                    }

                    // TODO Eventually, we should check the activation_conditions because there may be more than just `mfa_required`.
                    // We need to activate the token via two-factor.
                    this.two = true;

                    // Save the token though.
                    this.$store.dispatch('user/token', response.$data.token);

                    this.$nextTick(() => {
                        this.$refs.digits.firstChild.focus();
                    });
                })
                .catch(({ $error }) => {
                    this.attempting = false;
                    this.error.password = true;
                    this.error.message = $error ? $error : 'There was an error signing you in.';
                });
            },
            activate(){
                this.error.two = false;
                this.error.message = '';
                this.attempting = true;

                this.$http.post('/challenge', {code: this.code})
                .then(response => {
                    this.$store.dispatch('user/login')
                    .then(() => {
                            // If we are a popup/new tab, we need to send a message to the parent window.
                            if (window.opener && window.opener !== window) {
                                window.opener.postMessage({type: 'login', success: true }, process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080');
                                window.close();
                            } else {
                                if (this.$store.getters.team) {
                                    this.$router.push({name: 'team.dashboard', params: {team: this.$store.getters.team.alias}});
                                } else {
                                    this.$router.push('/onboard');
                                }
                            }
                    });
                })
                .catch(({ $error }) => {
                    this.attempting = false;

                    if(this.two){
                        //Going to assume there was a problem with their two-factor code
                        this.error.two = true;

                        // Focus the last input field.
                        this.$nextTick(() => {
                            this.$refs.digits.lastChild.focus();
                        });
                    }else{
                        this.error.password = true;
                        this.error.message = $error ? $error : 'There was an error signing you in.';
                    }
                });
            },
            google(){
                const properties = {
                    state: Math.random().toString(36).slice(2),
                    redirect_uri: process.env.NODE_ENV === 'production' ? 'https://ed.link/api/v1/sso/google' : 'http://localhost:9900/api/v1/sso/google',
                    client_id: '563820043496-o65vgllud5rrstbf8tg0rltlm5pbg868.apps.googleusercontent.com',
                    scope: 'email profile',
                    response_type: 'code',
                    prompt: 'none'
                };
                
                window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${querystring.stringify(properties)}`;
            },
            microsoft(){

            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .login
    {
        min-height: 100vh;
    }

    .login-pane
    {
        background: @f;
        min-width: 400px;
        border-right: 1px solid @e;

        h1
        {
            font-size: 32px;
            line-height: 40px;
        }

        h3
        {
            margin-top: 5px;
            font-weight: 400;
        }

        .login-container, .two-factor-container, .token-container
        {
            width: 100%;
            max-width: 400px;
        }
    }

    .login-background
    {
        background: @f8;
        flex: 1.5;
    }

    .wordmark
    {
        height: 25px;
        background: url('~@/assets/edlink-diamonds-black.svg') 0 0 no-repeat;
        background-size: 120px auto;
        color: transparent;
        width: 120px;
        position: absolute;
        top: 30px;
        left: 30px;
    }

    .sso-options
    {
        margin-top: 40px;
    }
    
    .sso-option.white.large.button
    {
        height: 44px;
        border-radius: 5px;
        line-height: 44px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: @black;
        padding: 0;
        text-align: center;
        margin-bottom: 15px;

        &.google
        {
            background-image: url('~@/assets/icons/source/google.svg');
            background-position: 12px 11px;
            background-repeat: no-repeat;
            background-size: 21px auto;
        }

        &.microsoft
        {
            background-image: url('~@/assets/icons/source/microsoft.svg');
            background-position: -10px 11px;
            background-repeat: no-repeat;
            background-size: 62px auto;
        }
    }

    .or
    {
        height: 15px;
        margin: 25px 0;

        &::after
        {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: @black;
            opacity: 0.1;
            position: absolute;
            top: 8px;
        }

        .or-text
        {
            color: @grey;
            font-size: 12px;
            background: @f;
            padding: 0 6px;
            line-height: 15px;
            z-index: 2;
        }
    }

    form
    {
        input
        {
            margin-bottom: 10px;
        }

        button.large
        {
            width: 100%;
            margin-top: 30px;
            height: 44px;
            line-height: 42px;
        }
    }

    .forgot
    {
        margin-top: 20px;
        font-size: 13px;
    }

    .error-message
    {
        font-size: 12px;
        color: @red;
        line-height: 16px;
    }

    .two-factor-inputs
    {
        justify-content: space-between;
        margin-top: 40px;

        input
        {
            width: 54px;
            height: 64px;
            text-align: center;
            font-size: 36px;
            line-height: 28px;
            padding: 10px 8px;
        }
    }

    .token-container
    {
        .spinner
        {
            margin-top: 40px;
        }
    }

    .form-disclaimer
    {
        padding-bottom: 0;
    }
</style>