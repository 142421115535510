<template>
    <div class="sync-configuration scroll">
        <header class="drawer-header">
            <div class="flex flex-align">
                <h2 class="ff drawer-title">Sync Configuration</h2>
                <div class="button" @click="save">Save Changes</div>
                <div class="button white" @click="close">Close</div>
            </div>
        </header>
        <div class="drawer-contents">
            <div class="helptext">
                Here you can control what is synced from <span>{{ source.name }}</span> into Edlink.
                For details on how to use Lynx, check out the
                <a href="https://ed.link/docs/guides/v2.0/csvs/lynx-reference" target="_blank">
                    Lynx Reference.
                </a>
            </div>

            <h3>{{ source.provider.name }} Settings</h3>
            <div class="sync-configuration-global">
                <template v-if="provider_settings.length">
                    <div class="sync-configuration-option" v-for="setting of provider_settings" :class="{ [setting.size ? setting.size : '']: true }">
                        <h4>{{ setting.name }}</h4>
                        <div class="sync-option-description" v-if="setting.description">
                            {{ setting.description }}
                        </div>
                        <div class="sync-option-input flex flex-align">
                            <input v-if="['string', 'text'].includes(setting.type)" :value="getProviderSetting(setting)" @input="(v) => setProviderSetting(setting, v.target.value)" />
                            <input v-else-if="setting.type === 'number'" :value="getProviderSetting(setting)" @input="(v) => setProviderSetting(setting, Number.parseInt(v.target.value))" />
                            <checkbox v-else-if="setting.type === 'boolean'" :label="'Enabled'" :checked="getProviderSetting(setting)" @input="(v) => setProviderSetting(setting, v)" />
                            <mapping v-else-if="setting.type === 'map'" :enum="getEnum(setting)" :lhs="{ placeholder: `${source.provider.name} value..` }" :rhs="{ placeholder: 'Edlink value..' }" :value="getProviderSetting(setting)" @input="(v) => setProviderSetting(setting, v)" />
                            <select v-else-if="setting.type === 'dropdown'" @input="(v) => setProviderSetting(setting, v.target.value)">
                                <option hidden :value="null">Select a value...</option>
                                <option v-for="(value, key) of setting.enum" :value="key" :selected="getProviderSetting(setting) === key">{{ value }}</option>
                            </select>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="nothing">There are no sync settings for this provider</div>
                </template>
            </div>

            <h3>Source Settings</h3>
            <section class="sync-configuration-source">
                <div class="section-content ff">
                    <form @submit.prevent="save">
                        <div class="form-field">
                            <label>Select Log Level</label>
                            <select class="small" v-model="logging.config.level" @input="update_logging_config">
                                <option v-for="log_level in log_level_enum" :key="log_level.priority" :value=log_level.level>
                                    {{ log_level_labels[log_level.priority] }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </section>

            <h3>Admin Settings</h3>
            <div class="sync-configuration-global">
                <form @submit.prevent="save">
                    <div class="form-field">
                        <label>Sync Type</label>
                    </div>
                    <div class="disconnect-warning">
                        Use partial syncs when pulling data from this source
                    </div>
                    <div class="flex form-options">
                        <div class="button" @click="change_sync_type(true)">Enable Partial Syncs</div>
                        <div class="button red" @click="change_sync_type(false)">Disable Partial Syncs</div>
                    </div>
                    <div class="form-field">
                        <label>Sync Frequency</label>
                        <div class="flex">
                            <select class="small" v-model="updated.sync_interval" @input="change_sync_interval">
                                <option value="3600">1 hours</option>
                                <option value="14400">4 hours</option>
                                <option value="21600">6 hours</option>
                                <option value="86400">24 hours</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>

            <h3>Entity Settings</h3>
            <div class="sync-configuration-entity" v-for="(settings, entity) of entities" :key="entity">
                <div class="sync-configuration-header flex flex-align pointer no-select" @click="expand(entity)">
                    <span class="icon nav" :class="{ 'iconoir-nav-arrow-right': !expanded(entity), 'iconoir-nav-arrow-down': expanded(entity) }"></span>
                    <div class="sync-title capitalize">{{ entity }}</div>
                    <span class="icon warn iconoir-keyframes-couple" v-if="condition(entity)" v-tooltip.top="'This entity type is shared based on a condition.'"></span>
                    <!-- <info class="tooltip-top" v-if="true" :tooltip="'This entity type is shared based on a condition.'" /> -->
                    <div class="ff line"></div>
                    <div class="toggle-group" @click.stop="() => { }">
                        <div class="flex flex-align">
                            <toggle :active="enabled(entity)" @change="toggle(entity)" />
                        </div>
                    </div>
                </div>
                <div class="sync-configuration-options" v-if="expanded(entity)">
                    <div class="sync-configuration-option large">
                        <h4>Sync Condition</h4>
                        <div class="sync-option-description">
                            A Lynx function that returns a Boolean determining whether or not to sync each {{ singular[entity] }}.
                        </div>
                        <input :value="condition(entity)" @input="(v) => setCondition(entity, v.target.value)" />
                    </div>
                    <div class="sync-configuration-option" v-for="setting of settings" :class="{ [setting.size ? setting.size : '']: true }">
                        <h4>{{ setting.name }}</h4>
                        <div class="sync-option-description" v-if="setting.description">
                            {{ setting.description }}
                        </div>
                        <div class="sync-option-input flex flex-align">
                            <input v-if="setting.type === 'text'" :value="getSetting(entity, setting)" @input="(v) => setSetting(entity, setting, v.target.value)" />
                            <input v-else-if="setting.type === 'number'" :value="getSetting(entity, setting)" @input="(v) => setSetting(entity, setting, Number.parseInt(v.target.value))" />
                            <checkbox v-else-if="setting.type === 'boolean'" :label="'Enabled'" :checked="getSetting(entity, setting)" @input="(v) => setSetting(entity, setting, v)" />
                            <mapping v-else-if="setting.type === 'map'" :enum="getEnum(setting)" :lhs="{ placeholder: `${source.provider.name} value..` }" :rhs="{ placeholder: 'Edlink value..' }" :value="getSetting(entity, setting)" @input="(v) => setSetting(entity, setting, v)" />
                            <select v-else-if="setting.type === 'dropdown'" :value="getSetting(entity, setting)" @input="(v) => setSetting(entity, setting, v.target.value)">
                                <option hidden :value="null">Select a value...</option>
                                <option v-for="(value, key) of setting.enum" :value="key">{{ value }}</option>
                            </select>
                            <div class="ff"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { set, get } from 'lodash';
    import {
        attendance_flags,
        day_flags,
        gender_identities,
        grade_levels,
        races,
        relationship_types,
        role,
        session_types,
        timezone_identifiers
    } from '@/enums';

    const log_level_enum = [
        {
            "priority": 3,
            "level": 'info',
        },
        {
            "priority": 4,
            "level": 'debug',
        },
        {
            "priority": 5,
            "level": 'trace',
        },
        {
            "priority": 6,
            "level": 'silly',
        }
    ]

    const log_level_labels = {
        3: 'Info',
        4: 'Debug',
        5: 'Trace',
        6: 'Silly'
    }

    export default {
        name: 'SyncConfiguration',
        data() {
            return {
                updated: null,
                expanded_values: {},
                providers: {
                    aeries: [
                        {
                            name: 'Attendance Type',
                            description: 'The type of attendance to sync.',
                            path: 'attendance_type',
                            type: 'dropdown',
                            enum: {
                                'daily': 'Daily',
                                'period': 'Period'
                            },
                            size: 'small',
                            default: 'daily'
                        }
                    ],
                    aspen: [
                        {
                            name: 'Disable HTTPS Validation',
                            description: 'Ignore SSL certificate errors for this source (this is unsafe).',
                            path: 'disable_https_validation',
                            type: 'boolean',
                            default: false
                        }
                    ],
                    blackboard: [
                        {
                            name: 'Ultra Instance',
                            description: 'Is this a Blackboard Ultra instance?',
                            path: 'ultra',
                            type: 'boolean',
                            default: false
                        }
                    ],
                    canvas: [
                        {
                            name: 'Arity',
                            description: 'How far down the Canvas account hierarchy to sync.',
                            path: 'arity',
                            type: 'number',
                            size: 'small',
                            default: 0
                        },
                        {
                            name: 'Root Account ID',
                            description: 'The Canvas account ID to sync from if it is anything other than \'1\'.',
                            path: 'root_account_id',
                            type: 'string',
                            default: '1'
                        },
                        {
                            name: 'Extended Enrollment Properties',
                            description: 'Sync extended properties for Canvas enrollments. These will appear in the properties field of the enrollment.',
                            path: 'sync_extended_enrollment_properties',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Split Sections',
                            description: 'Split sections.',
                            path: 'split_sections',
                            type: 'boolean',
                            default: false
                        }
                    ],
                    edfi: [
                        {
                            name: 'Attendance Types',
                            description: 'Mapping of Attendance Event Category Descriptors (namespace uri + code value) to Edlink Attendance States',
                            path: 'attendance_type_map',
                            type: 'map',
                            enum: 'attendance_flags',
                            size: 'large'
                        },
                        {
                            name: 'Calendar Date Types',
                            description: 'Mapping of Calendar Event Descriptors (namespace uri + code value) to Edlink Calendar Days',
                            path: 'day_flag_map',
                            type: 'map',
                            enum: 'day_flags',
                            size: 'large'
                        },
                        {
                            name: 'Gender Identities',
                            description: 'Mapping of Gender Descriptors (namespace uri + code value) to Edlink Gender Identities',
                            path: 'gender_identities',
                            type: 'map',
                            enum: 'gender_identities',
                            size: 'large'
                        },
                        {
                            name: 'Grade Levels',
                            description: 'Mapping of Grade Level Descriptors (namespace uri + code value) to Edlink Grade Levels',
                            path: 'grade_levels',
                            type: 'map',
                            enum: 'grade_levels',
                            size: 'large'
                        },
                        {
                            name: 'Local Education Agency ID',
                            description: 'The ID for the local education agency you would like to sync',
                            path: 'district_id',
                            size: 'large',
                            type: 'text',
                            default: ''
                        },
                        {
                            name: 'Races',
                            description: 'Mapping of Race Descriptors (namespace uri + code value) to Edlink Races',
                            path: 'races',
                            type: 'map',
                            enum: 'races',
                            size: 'large'
                        },
                        {
                            name: 'Relationship Types',
                            description: 'Mapping of Relationship Descriptors (namespace uri + code value) to Edlink Relationship Types',
                            path: 'relationship_types',
                            type: 'map',
                            enum: 'relationship_types',
                            size: 'large'
                        },
                        {
                            name: 'Session Types',
                            description: 'Mapping of Term Descriptors (namespace uri + code value) to Edlink Session Types',
                            path: 'session_types',
                            type: 'map',
                            enum: 'session_types',
                            size: 'large'
                        },
                        {
                            name: 'Staff Roles',
                            description: 'Mapping of Staff Classification Descriptors (namespace uri + code value) to Edlink Person Roles.',
                            path: 'roles',
                            type: 'map',
                            enum: 'role',
                            size: 'large'
                        },
                        {
                            name: 'Timezone',
                            description: '',
                            path: 'timezone',
                            type: 'dropdown',
                            enum: timezone_identifiers,
                            size: 'large',
                            default: 'Etc/UTC'
                        }
                    ],
                    google: [
                        {
                            name: 'Sync Google Classroom',
                            description: 'Should we sync Google Classroom, or only Google Workspace?',
                            path: 'sync_google_classroom',
                            type: 'boolean',
                            default: true
                        },
                        {
                            name: 'Sync Course Aliases',
                            description: 'Should Edlink sync additional course identifiers from Google Classroom?',
                            path: 'sync_course_aliases',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Register For Push Notifications',
                            description: 'Should Edlink register for push notifications from Google?',
                            path: 'register_push_notifications',
                            type: 'boolean',
                            default: false
                        }
                    ],
                    powerschool: [
                        {
                            name: 'Merge Staff',
                            description: 'Should we merge staff members by Powerschool users dc_id?',
                            path: 'merge_staff',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Sync Guardians',
                            description: 'Should we sync guardians from Powerschool?',
                            path: 'sync_guardians',
                            type: 'boolean',
                            default: true
                        },
                        {
                            name: 'Remove Graduated and Inactive Students',
                            description: 'Should we remove graduated and inactive students from Powerschool?',
                            path: 'remove_graduated_and_inactive_students',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Remove Inactive Staff',
                            description: 'Should we remove inactive staff from Powerschool?',
                            path: 'remove_inactive_staff',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Remove Classes Without Enrollments',
                            description: 'Should we remove classes without enrollments from Powerschool?',
                            path: 'remove_classes_without_enrollments',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Remove Courses Without Enrollments',
                            description: 'Should we remove courses without enrollments from Powerschool?',
                            path: 'remove_courses_without_enrollments',
                            type: 'boolean',
                            default: false
                        },
                        {
                            name: 'Sync GPAs',
                            description: 'Should we sync GPAs from Powerschool?',
                            path: 'sync_gpas',
                            type: 'boolean',
                            default: true
                        }                        
                    ],
                    veracross: [
                        {
                            name: 'Custom Role Map',
                            description: 'A map of Veracross roles to Edlink roles.',
                            path: 'role_map',
                            type: 'map',
                            enum: 'role',
                            size: 'large'
                        }
                    ],
                    schoology: [
                        {
                            name: 'Split Sections',
                            description: 'Split sections.',
                            path: 'split_sections',
                            type: 'boolean',
                            default: false
                        }
                    ],
                    brightspace: [
                        {
                            name: 'Custom Role Map',
                            description: 'A map of Brightspace roles to Edlink roles.',
                            path: 'role_map',
                            type: 'map',
                            enum: 'role',
                            size: 'large'
                        }
                    ]
                },
                enums: {
                    attendance_flags: attendance_flags,
                    day_flags: day_flags,
                    gender_identities: gender_identities,
                    grade_levels: grade_levels,
                    races: races,
                    relationship_types: relationship_types,
                    role: role,
                    session_types: session_types
                },
                entities: {
                    schools: [],
                    sessions: [],
                    calendars: [],
                    days: [],
                    stops: [],
                    departments: [],
                    facilities: [],
                    rooms: [],
                    courses: [],
                    classes: [
                        {
                            name: 'Sync Inactive Classes',
                            description: 'Sync classes that have been marked as inactive in the source system.',
                            path: 'sync_inactive',
                            type: 'boolean',
                            default: false
                        }
                    ],
                    sections: [],
                    people: [],
                    incidents: [],
                    enrollments: [],
                    agents: [],
                    meetings: [],
                    attendance: [{
                        name: 'Realtime Sync',
                        description: 'Sync Attendance every 15 minutes.',
                        path: 'realtime',
                        type: 'boolean',
                        default: false
                    }],
                    assets: [],
                    vehicles: [],
                    routes: [],
                    fees: []
                },
                required: {
                    schools: [],
                    sessions: [],
                    calendars: ['schools'],
                    days: ['calendars'],
                    stops: [],
                    departments: [],
                    facilities: [],
                    rooms: ['facilities'],
                    courses: [],
                    classes: [],
                    sections: [],
                    people: [],
                    incidents: [],
                    enrollments: ['classes', 'people'],
                    agents: ['people'],
                    meetings: ['days'],
                    attendance: ['meetings', 'people'],
                    assets: [],
                    vehicles: [],
                    routes: ['vehicles', 'stops'],
                    fees: []
                },
                singular: {
                    schools: 'school',
                    sessions: 'session',
                    calendars: 'calendar',
                    days: 'day',
                    stops: 'stop',
                    departments: 'department',
                    facilities: 'facility',
                    rooms: 'room',
                    courses: 'course',
                    classes: 'class',
                    sections: 'section',
                    people: 'person',
                    incidents: 'incident',
                    enrollments: 'enrollment',
                    agents: 'agent',
                    meetings: 'meeting',
                    attendance: 'attendance',
                    assets: 'asset',
                    vehicles: 'vehicle',
                    routes: 'route',
                    fees: 'fee'
                }
            };
        },
        created() {
            this.log_level_enum = log_level_enum;
            this.log_level_labels = log_level_labels;

            if (this.source) {
                this.$set(this, 'updated', JSON.parse(JSON.stringify(this.source.properties)));
            }
        },
        methods: {
            getEnum(setting) {
                if (typeof setting.enum === 'string') {
                    return this.enums[setting.enum];
                } else {
                    return setting.enum;
                }
            },
            close() {
                this.$store.dispatch('drawer/close');
            },
            save() {
                // We have to commit our changes to Vuex first.
                this.$store.commit('sources/update', { properties: this.updated });

                this.$store.dispatch('sources/update')
                    .catch(error => this.$toasted.error('There was an error updating the source.'));
            },
            expanded(entity) {
                return this.expanded_values[entity] ?? false
            },
            expand(entity) {
                this.$set(this.expanded_values, entity, !this.expanded(entity));
                this.refresh('expanded_values');
            },
            enabled(entity) {
                return get(this.updated, `sync.${entity}.enabled`, 'all') !== 'none';
            },
            condition(entity) {
                return get(this.updated, `sync.${entity}.condition`, null);
            },
            setCondition(entity, condition) {
                set(this.updated, `sync.${entity}.condition`, condition);
                if (this.enabled(entity)) {
                    this.toggle(entity, true);
                }
                this.refresh();
            },
            getSetting(entity, setting) {
                return get(this.updated, `sync.${entity}.settings.${setting.path}`, setting.default ?? null);
            },
            setSetting(entity, setting, value) {
                set(this.updated, `sync.${entity}.settings.${setting.path}`, value);
                this.refresh();
            },
            getProviderSetting(setting) {
                return get(this.updated, `sync.${this.source.provider.application}.${setting.path}`, setting.default ?? null);
            },
            setProviderSetting(setting, value) {
                set(this.updated, `sync.${this.source.provider.application}.${setting.path}`, value);
                this.refresh();
            },
            toggle(entity, override = null) {
                // Look for condition
                const condition = get(this.updated, `sync.${entity}.condition`, null);
                // If we are enabled set to none, if we have a condition set it to condition, otherwise set it to all.
                let state = this.enabled(entity) ? 'none' : condition ? 'condition' : 'all';
                // Sometimes we want to override this behavior.
                if (override !== null) {
                    state = override ? condition ? 'condition' : 'all' : 'none';
                }
                set(this.updated, `sync.${entity}.enabled`, state);
                if (state !== 'none') {
                    for (const req of this.required[entity]) {
                        this.toggle(req, true);
                    }
                } else {
                    for (const [key, entity_reqs] of Object.entries(this.required)) {
                        if (entity_reqs.includes(entity)) {
                            this.toggle(key, false);
                        }
                    }
                }
                this.refresh();
            },
            refresh(root = 'updated') {
                // This is a hack to force Vue to watch newly added keys in an object.
                this.$set(this, root, JSON.parse(JSON.stringify(this[root])));
            },
            change_sync_type(type){
                this.$http.put(`/admin/sources/${this.source.id}/sync_type`, { sync_type: type })
                .then(() => this.$toasted.success('Sync type updated.'))
                .catch(error => this.$toasted.error('There was an error updating the source.'));
            },
            change_sync_interval(){
                this.save();
            },
            update_logging_config() {
                if (!this.logging) {
                    return;
                }

                this.$http.put(`/teams/${this.team.id}/sources/${this.source.id}`, {
                    name: this.source.name,
                    internal: {
                        logging: {
                            source_id: this.source.id,
                            config: {
                                level: this.logging.level,
                                priority: this.logging.value,
                            }
                        }
                    }
                }, {
                    headers: {
                        authorization: `Bearer ${this.$store.state.user.token}`
                    }
                })
                .then(response => {
                    this.$toasted.success('Logging level updated.');
                    this.$store.commit('sources/update', response.$data);
                })
                .catch(error => this.$toasted.error('There was an error validating the source.'));
            }
        },
        computed: {
            provider_settings() {
                return this.providers[this.source?.provider?.application] ?? [];
            },
            team() {
                return this.$store.getters.team;
            },
            source() {
                return this.$store.getters.source;
            },
            logging() {
                return this.source?.internal?.logging ?? { config: { level: 0 } };
            }
        },
        watch: {
            logging(new_val, old_val) {
                if (new_val !== old_val) {
                    this.update_logging_config();
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .line
    {
        height: 1px;
        margin: 0 25px;
        border-bottom: 1px solid fade(@e4, 50%);
    }

    h3
    {
        margin-bottom: 15px;
    }

    .drawer-container
    {
        .drawer-header
        {
            padding: 0 0 30px;
            border-bottom: 1px solid @e4;

            .button
            {
                margin-left: 15px;
            }
        }
    }


    .helptext
    {
        margin: 25px 0 35px;
        font-size: 14px;
        color: @grey;
        line-height: 20px;

        span
        {
            font-weight: 500;
        }

        a
        {
            color: @base;
        }
    }

    .sync-configuration
    {
        // margin: 25px 0;
        // padding: 20px;
        // max-width: 700px;
        height: 100%;
        padding: 30px;
    }

    .sync-configuration-global
    {
        margin-bottom: 35px;
        // padding: 0 30px;

        .nothing
        {

            padding: 40px 80px;
        }
    }

    .sync-configuration-source
    {
        display: flex;
        margin-bottom: 4rem;
    }

    .sync-configuration-entity
    {
        padding: 15px 0;
        // border-bottom: 1px solid @e4;

        &:first-of-type,
        &:first-child
        {
            padding-top: 0;
        }

        &:last-child
        {
            padding-bottom: 0;
            border-bottom: none
        }
    }

    .sync-configuration-header
    {
        .sync-title
        {
            line-height: 22px;
            font-weight: 500;
            font-size: 15px;
            color: @black;
        }

        .nav
        {
            background: fade(@base, 10%);
            // background: darken(@f4, 5%);
            border-radius: 4px;
            color: @base;
            font-size: 19px;
            width: 19px;
            height: 19px;
            line-height: 21px;
            display: block;
            margin: 0 10px 0 0;
        }

        .warn
        {
            color: @grey;
            margin-left: 6px;
            margin-right: -15px;
        }
    }

    .sync-configuration-options
    {
        padding: 0px 65px 0px 30px;
        // border-left: 1px solid @e4;
        margin: 25px 0 0 0;
        // margin: 15px 0 -10px 9px;

        h4
        {
            margin: 20px 0 5px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: @grey;
            letter-spacing: -0.03rem;

            &:first-child
            {
                margin-top: 0;
            }
        }

    }

    .sync-configuration-option
    {
        padding-bottom: 15px;
        max-width: 500px;

        &.small
        {
            max-width: 350px;
        }

        &.large
        {
            max-width: unset;
        }
    }

    .sync-option-description
{
    font-size: 13px;
    line-height: 18px;
    color: @grey;
    max-width: 700px;
    margin-bottom: 10px;
}</style>
