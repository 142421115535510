<template>
    <div class="switch-team">
        <h4 class="center">Select A Team</h4>
        <div class="teams" v-if="teams">
            <div class="team flex flex-align" @click="activate(team)" v-for="team of teams" :key="team.id">
                <div class="team-name ff text-overflow">{{team.name}}</div>
                <!-- <div class="team-membership">{{team.membership.type}}</div> -->
                <div class="team-check" v-if="team.id === active.id"></div>
            </div>
            <div class="team create-team flex flex-align" @click="create">
                <div class="team-name ff">Create New Team</div>
                <div class="team-arrow"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateTeam from '@/components/modals/CreateTeam.vue';

    export default {
        name: 'SwitchTeam',
        props: {
            redirect: Boolean
        },
        methods: {
            activate(team){
                if(team.id !== this.active.id){
                    this.$store.dispatch('teams/activate', team)
                    .then(() => {
                        this.$store.dispatch('blink/kill');
                        if(this.redirect){
                            this.$router.push({
                                name: team.type === 'developer' ? 'team.dashboard' : 'team.sources',
                                params: {
                                    team: team.alias
                                }
                            });
                        }
                    });
                }

                this.$emit('close');
            },
            create(){
                this.$emit('close');
                this.$modal.show(CreateTeam, {redirect: true}, {width: 400, height: 'auto', classes: 'modal'});
            }
        },
        computed: {
            teams(){
                return this.$store.state.teams.all.slice().sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            },
            active(){
                return this.$store.getters.team;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 400;
        padding: 10px;
        font-size: 13px;
        color: @grey;
    }

    .team
    {
        border-top: 1px solid @e;
        padding: 15px;
        cursor: pointer;
        transition: background-color ease 0.2s;

        &:hover
        {
            background: @f8;
        }
    }

    .team-name
    {
        font-size: 15px;
        color: @black;
        line-height: 18px;
    }

    .team-membership
    {
        color: @grey;
        line-height: 20px;
        text-transform: capitalize;
    }

    .team-check, .team-arrow
    {
        margin-left: 15px;
        background: url('~@/assets/icons/white/check.svg') 1px 3px no-repeat @base;
        background-size: 18px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .create-team
    {
        .team-name
        {
            color: @base;
        }

        .team-arrow
        {
            background: url('~@/assets/icons/base/plus.svg') center center no-repeat transparent;
            background-size: 20px auto;
            border-radius: 0;
        }
    }
</style>
