<template>
    <div class="token">
        <div class="flex flex-align">
            <div class="source-icon" :class="{connected: value}">
                <img :src="provider.icon_url" />
            </div>
            <div class="ff source-name">{{value ? 'Account Connected' : 'Connect Account'}}</div>
            <div class="text-button red" v-if="value" @click="remove">Remove</div>
            <div class="text-button" v-else @click="connect">Connect</div>
        </div>
        <div class="flex flex-align admin-write-override" v-if="admin">
            <div class="ff">Admin Write Override</div>
            <input type="checkbox" v-model="admin_write_override" />
        </div>
    </div>
</template>

<script>
    const sso_url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

    export default {
        name: 'Token',
        props: {
            configuration: Object,
            provider: Object,
            value: String,
            write: Boolean
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            admin(){
                return this.user.admin;
            }
        },
        data(){
            return {
                admin_write_override: false
            };
        },
        methods: {
            serialize(object){
                const str = [];

                for(const [key, value] of Object.entries(object)){
                    str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
                }

                return str.join('&');
            },
            connect(){
                //These are the parameters we need to send to the SSO server, depending on which LMS we're authorizing
                const required_source_parameters = {
                    canvas: ['application_id', 'url'],
                    microsoft: ['tenant_id'],
                    blackboard: ['url'],
                    brightspace: ['application_id', 'url'],
                    moodle: ['application_id', 'url']
                };

                const optional_source_parameters = {
                    schoology: ['url'],
                    canvas: ['scopes']
                };

                if(!this.provider.requires_administrator_login){
                    return this.$toasted.error('This provider does not require an administrator login.');
                }

                //Start by opening the popup window with a state variable and the required parameters
                const parameters = {
                    state: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                };

                if(this.write || this.admin_write_override){
                    parameters.include_write_permissions = true;
                }

                if(required_source_parameters.hasOwnProperty(this.provider.application)){
                    for(const parameter of required_source_parameters[this.provider.application]){
                        if(this.configuration[parameter]){
                            parameters[parameter] = this.configuration[parameter];
                        }else{
                            return this.$toasted.error('You must fill out the other fields before connecting an account.');
                        }
                    }
                }

                if(optional_source_parameters.hasOwnProperty(this.provider.application)){
                    for(const parameter of optional_source_parameters[this.provider.application]){
                        if(this.configuration[parameter]){
                            parameters[parameter] = this.configuration[parameter];
                        }
                    }
                }

                if(this.provider.application === 'google') {
                    if(this.configuration.use_service_account === true) {
                        parameters.use_service_account = 1;
                    }
                }

                const popup = window.open(`${sso_url}/api/authentication/administrator/${this.provider.application}?${this.serialize(parameters)}`);

                const listener = event => {
                    // console.log('ORIGIN', event.origin, window.origin);
                    // console.log('STATE', event.data.state, parameters.state);
                    // console.log('CODE', event.data.code);

                    if(event.origin !== window.origin && process.env.NODE_ENV === 'production'){
                        //Just fail silently, I guess. Some systems like Schoology send random messages
                        //and we don't want to show errors on every single one of them.
                        return;
                    }

                    if(event.data.state !== parameters.state){
                        return;
                        // return this.$toasted.error('Invalid origin or state parameter.');
                    }

                    popup.close();
                    window.removeEventListener('message', listener);
                    this.$emit('update:value', event.data.code);
                    this.$emit('input', event.data.code);
                };

                //TODO Eventually make sure this listener is removed when the component is destroyed
                window.addEventListener('message', listener);
            },
            remove(){
                this.$emit('update:value', '');
                this.$emit('input', '');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .token
    {
        border: 1px solid @d;
        border-radius: 6px;
        width: 100%;
        max-width: 325px;
        padding: 10px 15px 10px 10px;
        min-height: 54px;
    }

    .source-icon
    {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: @e;
        margin-right: 10px;

        &::before
        {
            width: 32px;
            height: 32px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        &.connected
        {
            &::after
            {
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: url('~@/assets/icons/white/check.svg') 1px 2px no-repeat @green;
                background-size: 12px auto;
                position: absolute;
                content: "";
                right: 0;
                bottom: 0;
                z-index: 3;
                box-shadow: 0 0 0 1px @f;
            }
        }

        img
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    .source-name
    {
        color: @black;
        font-size: 14px;
    }

    .admin-write-override
    {
        border-top: 1px solid @e4;
        color: @grey;
        font-size: 12px;
        margin-top: 10px;
        padding-top: 10px;

        input
        {
            margin: 0;
        }
    }
</style>
