<template>
    <form class="add-application-secret" @submit.prevent="send">
        <label>Enter Secret Name</label>
        <div class="form-helptext">Enter a name that will help you identify this secret.</div>
        <input v-model="name" ref="secret_name_input"/>
        <button type="submit">Generate Secret</button>
    </form>
</template>

<script>
    export default {
        name: 'AddApplicationSecret',
        data(){
            return {
                name: ''
            };
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            team(){
                return this.$store.getters.team;
            }
        },
        methods: {
            send(){
                if(!this.name.trim()){
                    return this.$toasted.error('Please enter a name for the secret.');
                }

                this.$http.post(`/teams/${this.team.id}/applications/${this.application.id}/credentials`, {
                    name: this.name
                })
                .then(rsp => {
                    this.$toasted.success('Application secret added.');
                    this.$emit('close');
                    this.$store.dispatch('applications/add_secret', rsp.$data);
                })
                .catch(error => {
                    this.$toasted.error('There was an error creating the requested secret.');
                });
            }
        },
        async mounted() {
            await this.$nextTick();
            this.$refs.secret_name_input.focus();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .add-application-secret
    {
        padding: 30px;

        label:first-child
        {
            margin-top: 0;
        }

        button
        {
            width: 100%;
            margin-top: 30px;
        }
    }
</style>