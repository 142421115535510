<template>
    <div class="reconnect-step instructions">
        <div class="hero flex flex-align">
            <div class="provider-logo" v-if="parent.provider.icon_url">
                <img :src="parent.provider.icon_url" />
            </div>
            <template v-if="parent.application">
                <div class="dots"></div>
                <div class="application-logo" v-if="parent.application.icon_url">
                    <img :src="parent.application.icon_url" />
                </div>
            </template>
        </div>
        <h1 v-if="parent.application">The connection between {{ parent.provider.name }} and {{ parent.application.name }} is currently broken.</h1>
        <h1 v-else>Our connection to {{ parent.provider.name }} is currently broken.</h1>
        <div class="reconnect-helptext helptext">
            This is typically no big deal and connections can break for any number of reasons. These quick steps will get you back up and running in no time.
        </div>
        <div class="integration-features">
            <div class="integration-feature flex flex-align">
                <span class="icon iconoir-people-rounded block"></span>
                <div class="ff">You must be an administrator.</div>
            </div>
            <div class="integration-feature flex flex-align">
                <span class="icon iconoir-timer block"></span>
                <div class="ff">This should take about 2 minutes.</div>
            </div>
        </div>
        <div class="options flex">
            <div class="button" @click="next" kbd="Enter ⏎">Continue</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReconnectInstructions',
        data() {
            return {}
        },
        computed: {
            parent(){
                return this.$store.state.reconnect.parent;
            },
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            user(){
                return this.$store.getters.user;
            },
            source(){
                return this.$store.state.reconnect.source;
            },
            application(){
                return this.$store.state.reconnect.application;
            }
        },
        methods: {
            next(){
                this.$store.dispatch('reconnect/next');
            }
        },
        created(){
            
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .provider-logo, .application-logo
    {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 30px -2px rgba(0, 0, 0, 0.3);
        background: @f;

        img
        {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
    }

    .dots
    {
        width: 60px;
        height: 3px;
        background: url('~@/assets/icons/black/dot.svg') 6px 0 repeat;
        background-size: 15px 15px;
        opacity: 0.5;
    }
    
    .hero {
        margin-bottom: 40px;
    }

    .flow
    {
        h1
        {
            // font-size: 32px;
            // font-weight: 500;
            // letter-spacing: -0.03rem;
            line-height: 30px;
            margin-bottom: 10px;
        }

        .helptext.reconnect-helptext
        {
            // font-size: 16px;
            // color: @grey;
            line-height: 22px;
        }
    }

    .integration-features
    {
        margin: 25px 0 30px;
    }

    .integration-feature
    {
        font-size: 14px;
        color: @grey;
        line-height: 40px;

        .icon
        {
            font-size: 22px;
            margin: 0 10px 0 0;
        }
    }
</style>
