<template>
    <div class="integrate-source-provider-aeries">
        <integrate-source-editor-step name="Enter Your Aeries URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your Aeries server is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.aeries.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Generating a Certificate" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <integrate-source-editor-configuration title="How to create a Certificate" icon="iconoir-info-empty">
                <div class="image-label flex flex-align flex-wrap">
                    <div v-tooltip.top="!completed.includes('url') ? 'Enter your Aeries URL' : ''">
                        1. Go to the <a class="configuration-link inline-block text-button mini" :class="{disabled: !completed.includes('url')}" :href="settings_url" target="_blank">API Security page</a>
                    </div>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/api-security.png', { title: 'API Security Page', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Click <code>Add New Record</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/add-record.png', { title: 'Add Record', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Enter <code>Edlink</code> for <code>Product Name</code> and leave <code>Type</code> blank.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/product-info.png', { title: 'Product Information', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>4. Click <code>Insert</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/product-insert.png', { title: 'Insert Record', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>5. Check all required items under <code>Permissions</code>.</label>
                    <!-- <div class="integrate-input-help text-button mini has-icon" @click="image('#', { title: 'Permissions', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div> -->
                </div>
                <div class="aeries-details">
                    <div class="flex flex-align">
                        <span class="block icon iconoir-lock"></span>
                        <h2 class="ff">Required Permissions</h2>
                    </div>
                    <ul v-if="essential">
                        <li>Student Data</li>
                        <li class="nested">
                            <ul>
                                <li>Student Data</li>
                                <li>Student Programs</li>
                                <li class="nested">
                                    <ul>
                                        <li>Free and Reduced</li>
                                    </ul>
                                </li>
                                <li>Class Schedules/History</li>
                            </ul>   
                        </li>
                        <li>Gradebook</li>
                        <li class="nested">
                            <ul>
                                <li>Gradebook Data (Read & Update)</li>
                                <li>Gradebook Scores (Read & Update)</li>
                                <li>Gradebook Category (Read & Update)</li>
                                <li>Gradebook Assignment (Read & Update)</li>
                            </ul>   
                        </li>
                        <li>Scheduling</li>
                        <li class="nested">
                            <ul>
                                <li>Teacher Data</li>
                                <li>Staff Data</li>
                                <li>Course Data</li>
                                <li>Classes</li>
                            </ul>   
                        </li>
                        <li>School Information</li>
                        <li class="nested">
                            <ul>
                                <li>Schools</li>
                                <li>Terms</li>
                                <li>Users</li>
                            </ul>   
                        </li>
                    </ul>
                    <ul v-else>
                        <li>Student Data</li>
                        <li class="nested">
                            <ul>
                                <li>Student Data</li>
                                <li>Student Programs</li>
                                <li class="nested">
                                    <ul>
                                        <li>Free and Reduced</li>
                                    </ul>
                                </li>
                                <li>Class Schedules/History</li>
                                <li>Attendance</li>
                                <li class="nested">
                                    <ul>
                                        <li>Absence Code Table</li>
                                        <li>Absence</li>
                                        <li>Absence History</li>
                                        <li>Bell Schedule</li>
                                        <li>Calendar</li>
                                        <li>Enrollment History</li>
                                    </ul>
                                </li>
                                <li>Counseling</li>
                                <li class="nested">
                                    <ul>
                                        <li>Assertive Discipline</li>
                                    </ul>
                                </li>
                                <li>Other</li>
                                <li class="nested">
                                    <ul>
                                        <li>Fees and Fines</li>
                                    </ul>
                                </li>
                            </ul>   
                        </li>
                        <li>Gradebook</li>
                        <li class="nested">
                            <ul>
                                <li>Gradebook Data (Read & Update)</li>
                                <li>Gradebook Scores (Read & Update)</li>
                                <li>Gradebook Category (Read & Update)</li>
                                <li>Gradebook Assignment (Read & Update)</li>
                            </ul>   
                        </li>
                        <li>Scheduling</li>
                        <li class="nested">
                            <ul>
                                <li>Teacher Data</li>
                                <li>Staff Data</li>
                                <li>Master Schedule</li>
                                <li>Course Data</li>
                                <li>Classes</li>
                                <li>Scheduling Master Schedule</li>
                                <li>Class Calendars</li>
                            </ul>   
                        </li>
                        <li>School Information</li>
                        <li class="nested">
                            <ul>
                                <li>Schools</li>
                                <li>Terms</li>
                                <li>Users</li>
                            </ul>   
                        </li>
                    </ul>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>6. Check <code>Display Certificate Details</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/display-details.png', { title: 'Display Certificate Details', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>7. Copy the value labeled <code>Certificate</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/cert-value.png', { title: 'Certificate Value', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Input the Certificate You Generated" icon="iconoir-password-cursor" :complete="completed.includes('certificate')">
            <div class="helptext">
                Paste the value you copied from Aeries into the field below.
            </div>
            <input class="medium" type="text" placeholder="Certificate" :value="source.configuration.certificate" @input="e => send('UPDATE_SOURCE', {path: 'configuration.certificate', value: e.target.value})" />
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter Your OneRoster Keys" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <integrate-source-editor-configuration title="How to find your one roster keys" icon="iconoir-info-empty">
                <div class="image-label flex flex-align flex-wrap">
                    <div v-tooltip.top="!completed.includes('url') ? 'Enter your Aeries URL' : ''">
                        1. Go to the <a class="configuration-link inline-block text-button mini" :class="{disabled: !completed.includes('url')}" :href="settings_url" target="_blank">API Security page</a>
                    </div>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/api-security.png', { title: 'API Security Page', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Click <code>Add New Record</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/add-record.png', { title: 'Add Record', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Enter <code>Edlink OneRoster</code> for <code>Product Name</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/oneroster-product-name.png', { title: 'Product Information', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>4. Select <code>OneRoster</code> for <code>Type</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/oneroster-product-type.png', { title: 'Product Information', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>5. Check all permissions under the <code>Permissions</code> table.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/oneroster-permissions.png', { title: 'Certificate Value', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>6. Check <code>Display Consumer ID & Secret Keys For OneRoster</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/oneroster-details.png', { title: 'Add Record', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>7. Copy the value labeled <code>Consumer ID</code> and paste it below.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/oneroster-consumer.png', { title: 'Certificate Value', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <!-- <label class="key-label">Consumer ID</label> -->
                <input class="medium" type="text" placeholder="Consumer ID" :value="source.configuration.oneroster_client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_id', value: e.target.value})" />

                <div class="image-label flex flex-align flex-wrap">
                    <label>8. Copy the value labeled <code>Consumer Secret Key</code> and paste it below.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/aeries/onboarding/oneroster-secret.png', { title: 'Certificate Value', width: 900 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <!-- <label class="key-label">Consumer Secret Key</label> -->
                <input class="medium" type="text" placeholder="Consumer Secret Key" :value="source.configuration.oneroster_client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_secret', value: e.target.value})" />

            </integrate-source-editor-configuration>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Select Timezone" icon="iconoir-clock-outline" :complete="completed.includes('timezone')">
            <div class="helptext">
                Select the timezone that your district is in.
            </div>
            <select class="medium" :value="source.configuration.timezone" @input="e => send('UPDATE_SOURCE', { path: 'configuration.timezone', value: e.target.value })">
                <option :value="undefined" hidden>Select a timezone</option>
                <option :value="null" hidden>Select a timezone</option>
                <option v-for="(key, value) of timezones" :value="key" :key="key">{{ value }}</option>
            </select>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';
    import { timezone_identifiers } from '@/enums';

    export default {
        name: 'IntegrateSourceProviderAeries',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            essential(){
                return this.state.context.essential;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(this.url && !this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Confirm remote configuration is completed.
                if(!this.remote){
                    return ['url'];
                }

                // 3. Confirm certificate is set.
                if(!this.source.configuration.certificate?.trim().length){
                    return ['url', 'remote'];
                }

                const properties = ['oneroster_client_id', 'oneroster_client_secret'];

                for(const property of properties){
                    if(!this.source.configuration?.[property]){
                        return ['url', 'remote', 'certificate'];
                    }
                }

                if (!this.source.configuration.timezone) {
                    return ['url', 'remote','certificate', 'credentials'];
                }

                return ['url', 'remote', 'certificate', 'credentials', 'timezone'];
            },
            settings_url(){
                return this.completed.includes('url') ? new URL('/aeries/SecurityForAPI.aspx', this.source.configuration.url).href : false;
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 5);
            }
        },
        mounted(){
            if (this.essential) {
                this.send('UPDATE_SOURCE', {path: 'properties.sync', value: {
                    assets: {
                        enabled: "none"
                    },
                    fees: {
                        enabled: "none"
                    },
                    routes: {
                        enabled: "none"
                    },
                    vehicles: {
                        enabled: "none"
                    },
                    attendance: {
                        enabled: "none"
                    },
                    meetings: {
                        enabled: "none"
                    },
                    agents: {
                        enabled: "none"
                    },
                    incidents: {
                        enabled: "none"
                    },
                    rooms: {
                        enabled: "none"
                    },
                    facilities: {
                        enabled: "none"
                    },
                    departments: {
                        enabled: "none"
                    },
                    stops: {
                        enabled: "none"
                    },
                    days: {
                        enabled: "none"
                    },
                    calendars: {
                        enabled: "none"
                    }
                }})
            }
        },
        data(){
            return {
                url: true,
                remote: false,
                timezones: timezone_identifiers
            };
        },
        methods: {
            image(src, config = { title: null, width: 700, height: 'auto' }){
                this.$modal.show(
                    HelpImage,
                    { src, title: config.title },
                    { width: config.width ?? 700, height: config.height ?? 'auto', classes: 'modal', reset: true }
                );
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .aeries-details
    {
        background: @e;
        border-radius: 6px;
        padding: 15px;
        width: 100%;

        ul {
            color: @black;
            font-size: 13px;
            margin: 5px 0 5px 5px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        li {
            margin-bottom: 5px;

            & > ul {
                margin-left: 15px;
            }
            
            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: '-';
                margin-right: 5px;
            }

            &.nested {
                &::before {
                    content: '';
                    margin-right: 0;
                }
            }
        }

        .icon
        {
            margin-right: 10px;
            color: @black;
            font-size: 18px;
        }

        h2
        {
            font-size: 13px;
            color: @black;
            font-weight: 400;
        }

        label
        {
            margin: 15px 0 4px;
            font-size: 12px;
            letter-spacing: 0;
            color: @grey;
        }

        .configuration-link
        {
            font-weight: 400;
            font-size: 14px;
            justify-content: flex-start;
            line-height: 16px;
            height: 16px;
            padding-right: 18px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -1px top 1px;
            background-size: 14px auto;

            &.disabled
            {
                background-image: url('~@/assets/icons/grey/external.svg');
            }
        }

        .configuration-value
        {
            color: @black;
            font-size: 14px;
            line-height: 16px;

            &.monospace
            {
                font-size: 13px;
            }

            &.copyable
            {
                &.noshift
                {
                    &::after
                    {
                        height: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
</style>
