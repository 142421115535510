<template>
    <div class="search-select flex">
        <div class="ff" key="editing">
            <autocomplete :class="{compact}" ref="input" class="block" :borderless="borderless" :values.sync="values_copy" :timeout="1" :source="search ? search : source" :format="format ? format : default_format" @escape="cancel" @enter="save" :max="max" :placeholder="placeholder" :disabled="disabled" />
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'SearchSelect',
        props: {
            property: String,
            placeholder: String,
            borderless: Boolean,
            array: Boolean,
            editing: Boolean,
            values: Array,
            search: Function,
            format: Function,
            enum: Object,
            compact: Boolean,
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                values_copy: []
            };
        },
        mounted() {
            this.values_copy = this.values.slice();

            // Watch for changes to the values copy and emit them back to the parent
            // Applied after copying the values so that we don't emit the initial values
            this.$watch('values_copy', (updated) => {
                this.$emit('update:values', updated);
                this.$emit('input', updated);
            });
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                if(value){
                    return Promise.resolve(Object.keys(this.enum).filter(item => item.toLowerCase().indexOf(value.toLowerCase()) > -1).sort((a, b) => a > b ? 1 : -1));
                } else {
                    return Promise.resolve(Object.keys(this.enum).sort((a, b) => a > b ? 1 : -1));
                }
            },
            default_format(value){
                return this.enum[value];
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .search-select
    {
        .autocomplete
        {
            .autocomplete-search
            {
                border: 0;
            }
        }

        input.autocomplete-input
        {
            margin-left: 0;
            padding-left: 0;
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
