<template>
    <div class="normalize-phone-numbers center">
        There's nothing to configure for this component.
    </div>
</template>

<script>
    export default {
        name: 'TransformNormalizePhoneNumbers',
        props: {
            transformation: Object,
            source: Object,
            integration: Object,
            configuration: Object
        },
        computed: {
            provider(){
                return this.source ? this.source.provider : this.integration.provider;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .normalize-phone-numbers
    {
        padding: 50px;
        font-size: 13px;
        color: @black;
    }
</style>