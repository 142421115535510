<template>
    <div class="full scroll" v-if="integration">
        <information class="integration-validation full scroll" :class="{unsaved}">
            <template v-slot:title>Data Validation</template>
            <template v-slot:description>
                This page allows you to view the status of data that is shared with {{ integration.application.name }}.
                Edlink performs automated checks on the dataset by testing it against validation rules.
                These rules are typically defined by the developer in order to prevent errors in their system.
                If you add a validation rule this page, it will not affect other integrations.
            </template>
            <template v-slot:details>
                <nav class="flex flex-align">
                    <div class="pill" :class="{active: tab === 'report'}" @click="tab = 'report'">
                        <DatabaseStats class="icon" width="16" height="16" stroke-width="2" />
                        Validation Report
                    </div>
                    <div class="pill" :class="{active: tab === 'rules'}" @click="tab = 'rules'">
                        <DbCheck class="icon" width="16" height="16" stroke-width="2" />
                        Validation Rules
                    </div>
                    <div class="ff"></div>
                    <div class="button base has-icon" @click="create">
                        <PlaylistAdd class="icon" width="16" height="16" stroke-width="2" />
                        Create a Rule
                    </div>
                </nav>
                <datatable v-if="tab === 'report'" class="validation-report card" :columns="report.columns" :rows="report.rows" :loading="report.loading" :header="true" :clickable="false" :min-height="report.rows.length ? 'auto' : '200px'">
                    <template v-slot:empty-message>There are no validation results for this integration.</template>
                </datatable>
                <div class="validation-rules" v-if="tab === 'rules'">
                    <template v-if="application.properties?.validation?.length || modified.length">
                        <!-- These have to be done differently because application validation rules cannot be modified here. -->
                        <ValidationRule v-for="rule of application.properties?.validation" :key="rule.id" :rule="rule" />

                        <!-- Integration validation rules can be modified here, so we're looping through `modified` instead. -->
                        <ValidationRule v-for="rule of modified" :key="rule.id" :rule="rule" />
                    </template>
                    <div v-else class="card nothing flex flex-align flex-center flex-column">
                        <PeaceHand class="icon" width="24" height="24" stroke-width="1.5" />
                        <slot name="empty-message">You have not created any validation rules for this integration.</slot>
                    </div>
                </div>
            </template>
        </information>
        <div class="validation-unsaved-changes banner flex flex-align warning" v-if="unsaved">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">You have unsaved changes that will be lost if you navigate away from this page.</div>
            <div class="button" @click="save">Save Changes</div>
            <div class="button" @click="revert">Revert</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ValidationRule from '@/components/ValidationRule';
    import { DatabaseStats, InfoCircle, PlaylistAdd, DbCheck, PeaceHand } from '@epiphany/iconoir';
    import uuid from 'uuid';

    const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

    export default {
        name: 'IntegrationValidation',
        components: {
            ValidationRule,
            DatabaseStats,
            InfoCircle,
            PlaylistAdd,
            DbCheck,
            PeaceHand
        },
        data(){
            const columns = ['Entity', 'Property', 'Definition', 'Type', 'Info', 'Error', 'Valid', 'Warning'].map(column => {
                return {
                    id: column.toLowerCase(),
                    name: column,
                    width: '10%',
                    value: row => {
                        return row[column.toLowerCase()];
                    }
                }
            });

            return {
                tab: 'report',
                original: [],
                modified: [],
                report: {
                    loading: true,
                    materialization: null,
                    columns,
                    rows: []
                }
            };
        },
        computed: {
            unsaved(){
                return !_.isEqual(this.original, this.modified);
            },
            saving(){
                return this.$store.state.save.actions.includes('privacy/update');
            },
            integration() {
                return this.$store.getters.integration;
            },
            application() {
                return this.$store.getters.integration.application;
            },
            team() {
                return this.$store.getters.team;
            },
            rules(){
                return _.concat(
                    _.get(this.application, 'properties.validation', []),
                    _.get(this.integration, 'properties.validation', [])
                );
            },
            output(){
                return this.report.materialization?.output?.validation;
            }
        },
        async created(){
            this.original = _.cloneDeep(this.integration.properties?.validation ?? []);
            this.modified = _.cloneDeep(this.integration.properties?.validation ?? []);

            try {
                const { $data: materializations } = await this.$http.get(`${url}/api/v2/graph/materializations`, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`,
                    },
                    params: {
                        $last: 1,
                        $filter: {
                            state: [{ operator: 'equals', value: 'complete' }]
                        }
                    }
                });

                this.report.materialization = _.last(materializations);

                if (_.isEmpty(this.output)) {
                    return;
                }

                if(this.rules?.length === 0){
                    return;
                }

                const validation_rules_by_id = _.keyBy(this.rules, 'id');

                this.report.rows = Object.entries(this.output.result).map(([rule_id, result]) => {
                    const rule = validation_rules_by_id[rule_id];

                    return {
                        entity: rule ? rule.entity : 'Unknown',
                        property: rule ? rule.property : 'Unknown',
                        definition: rule ? rule.definition : 'Unknown',
                        type: rule ? rule.type : 'Unknown',
                        info: result.summary.info,
                        error: result.summary.error,
                        valid: result.summary.valid,
                        warning: result.summary.warning
                    };
                });
            } catch (error) {
                console.log(error);
                this.$toasted.error('There was a problem fetching the latest materialization. Please try again later.');
            } finally {
                this.report.loading = false;
            }
        },
        methods: {
            revert(){
                this.modified = _.cloneDeep(this.original);
            },
            create(){
                this.tab = 'rules';

                this.modified.push({
                    id: uuid.v4(),
                    entity: null,
                    property: null,
                    definition: null,
                    type: null,
                    remove: false,
                    default_value: null,
                    function: null
                });
            },
            async save(){
                try {
                    // Set the saving flag.
                    await this.$store.dispatch('save/save', 'validation/update');

                    // Create a clone of the properties object.
                    const properties = _.cloneDeep(this.integration.properties);

                    // Update the validation rules.
                    properties.validation = _.cloneDeep(this.modified);

                    console.log(this.team);
                    console.log(this.integration);

                    // Update the integration in the DB.
                    await this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}`, { properties });

                    // Update the integration locally.
                    this.$store.commit('integrations/update', { properties });

                    // Finally, overwrite the original properties with the modified properties.
                    this.original = _.cloneDeep(this.modified);

                    // Set the saved flag.
                    this.$store.dispatch('save/saved', 'validation/update');
                } catch(error){
                    console.log(error);
                    this.$store.dispatch('save/error', 'validation/update');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .integration-validation
    {
        &.unsaved
        {
            bottom: @banner-height;
        }
    }

    .validation-unsaved-changes
    {
        .absolute(auto, 0, 0, 0);
        height: @banner-height;
    }

    .validation-report
    {
        margin-top: @double-padding;
    }

    .validation-rules
    {
        margin-top: @double-padding;
    }

    .nothing
    {
        min-height: 200px;

        .icon
        {
            margin-bottom: @double-padding;
        }
    }
</style>
