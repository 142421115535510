<template>
    <div class="hide-classes-inactive-sessions center">
        There's nothing to configure for this component.
    </div>
</template>

<script>
    export default {
        name: 'TransformHideClassesInactiveSessions',
        props: {
            transformation: Object,
            source: Object,
            integration: Object,
            configuration: Object
        },
        computed: {
            provider(){
                return this.source ? this.source.provider : this.integration.provider;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .hide-classes-inactive-sessions
    {
        padding: 50px;
        font-size: 13px;
        color: @black;
    }
</style>