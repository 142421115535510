<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                    Sources
                </div>
            </template>
            <template v-slot:actions>
                <input class="filter search large" type="text" placeholder="Search by Source Name, Source ID, or Team" v-model="search" />
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="states" :active="state" @activate="switch_state" label="State">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown> 
            <filter-dropdown :items="dates" :active="updated_date" @activate="switch_updated_date" label="Updated Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="dates" :active="created_date" @activate="switch_created_date" label="Created Date">
                <template v-slot:icon>
                    <CalendarPlus class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="providers" :active="provider" @activate="switch_provider" label="Providers">
                <template v-slot:icon>
                    <MacOsWindow class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <div class="thumbnail">
                                <img class="block" :src="item.picture" />
                            </div>                            
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <div class="ff"></div>
        </filters>
        <div class="admin-sources full">
            <datatable :columns="columns" :rows="results" :selectable="true" :header="true" @clicked="source => open(source)" :clickable="true" />
        </div>
        <!-- <table v-if="sources.length">
            <thead v-if="sources[0].sync">
                <tr>
                    <th class="application-icon"></th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Team</th>
                    <th class="text-right">Failed</th>
                </tr>
            </thead>
            <thead v-else>
                <tr>
                    <th class="application-icon"></th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Team</th>
                    <th class="text-right">Updated</th>
                </tr>
            </thead>
            <tbody>
                <router-link v-for="source of results" :key="source.id" tag="tr" class="selectable" :to="{name: 'team.sources.source', params: {team: source.team.alias, source: source.id}}">
                    <td class="application-icon icon">
                        <img :src="source.provider.icon_url" />
                    </td>
                    <td>
                        <div class="source-name primary-value flex flex-align">{{source.name}}</div>
                    </td>
                    <td class="capitalize">
                        <div class="flex flex-align">
                            <div class="standalone icon-status" :class="status(source)"></div>
                            <div>{{source.status}}</div>
                        </div>
                    </td>
                    <td class="source-team">
                        <div class="team-name" v-if="source.team">{{source.team.name}}</div>
                        <div class="unknown" v-else>&ndash;</div>
                    </td>
                    <td class="text-right">{{source.updated_date | pretty(source.sync ? 'long' : 'short')}}</td>
                </router-link>
            </tbody>
        </table>
        <div class="nothing" v-else>No sources to show.</div> -->
    </main>
</template>

<script>
    import { MacOsWindow, AlignLeft, Calendar, Wrench, WhiteFlag, CalendarPlus, CloudSync } from '@epiphany/iconoir';
    import moment from 'moment';

    export default {
        name: 'AdminSources',
        components: {
            MacOsWindow,
            Calendar,
            CalendarPlus,
            WhiteFlag,
            Wrench,
            CloudSync
        },
        data(){
            return {
                loading: true,
                sources: [],               
                search: '',
                page: 'all',                
                state: null,
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '50%',
                        value: row => row.name
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '10%',
                        value: row => row.status
                    },
                    {
                        id: 'team',
                        name: 'Team',
                        icon: Wrench,
                        width: '50%',
                        value: row => row.team.name
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '20%'
                    }
                ],
                states: [
                    {
                        id: 'active',
                        name: 'Active',
                        icon: 'green'
                    },
                    {
                        id: 'inactive',
                        name: 'Inactive',
                        icon: 'grey'                        
                    },
                    {
                        id: 'disabled',
                        name: 'Disabled',
                        icon: 'yellow'                        
                    },
                    {
                        id: 'error',
                        name: 'Error',
                        icon: 'red'                        
                    },
                    {
                        id: 'destroyed',
                        name: 'Destroyed',
                        icon: 'red'                        
                    }                                        
                ],
                created_date: null,
                updated_date: null,
                dates: [
                    {
                        id: 'last-hour',
                        name: 'Last Hour',
                        icon: 'iconoir-wristwatch'
                    },
                    {
                        id: 'last-day',
                        name: 'Last Day',
                        icon: 'iconoir-sun-light'
                    },
                    {
                        id: 'last-week',
                        name: 'Last Week',
                        icon: 'iconoir-send-mail'
                    },
                    {
                        id: 'last-month',
                        name: 'Last Month',
                        icon: 'iconoir-calendar'
                    },
                    {
                        id: 'last-year',
                        name: 'Last Year',
                        icon: 'iconoir-cinema-old'
                    }
                ],
                provider: null,           
                providers: [
                    {
                        id: '97f551b3-6095-4eb7-88c3-780f17236429',
                        name: 'Aeries',
                        picture: '/source/aeries.png'
                    },
                    {
                        id: '68cc46aa-8463-4189-b8d3-fa2cc6748e92',
                        name: 'Aspen',
                        picture: '/source/aspen.png'
                    },
                    {
                        id: 'b836c842-7d5f-4763-b7e4-2c69f6a981d6',
                        name: 'Blackbaud',
                        picture: '/source/blackbaud.png'
                    },
                    {
                        id: 'b7e1526e-f627-4d86-99c2-465f81a0f0f8',
                        name: 'Blackboard',
                        picture: '/source/blackboard.png'
                    },
                    {
                        id: '5ab3b051-2976-442a-a60c-4cbee43968ca',
                        name: 'Brightspace',
                        picture: '/source/brightspace.png'
                    },
                    {
                        id: '0e8c78a5-29eb-43e6-9c61-1f53b3cd5f53',
                        name: 'Bromcom',
                        picture: '/source/bromcom.png'
                    },    
                    {
                        id: '07de3f01-6b1f-4ba2-9fc0-7d983fa53c9a',
                        name: 'CSV',
                        picture: '/source/csv.png'
                    },
                    {
                        id: '035d6dd6-1569-47b2-8ed8-63435be58078',
                        name: 'Canvas',
                        picture: '/source/canvas.png'
                    },         
                    {
                        id: '28f3beb9-a2af-46f6-ad31-1f10db7a7366',
                        name: 'Classlink',
                        picture: '/source/classlink.png'
                    },
                    {
                        id: 'f78ddf8d-ace1-4b24-bb14-2e5c6704c76b',
                        name: 'Clever',
                        picture: '/source/clever.png'
                    },        
                    {
                        id: '0ee5e450-4a9e-4b7d-bd30-62b33cdd5456',
                        name: 'Google Classroom',
                        picture: '/source/google.png'
                    },
                    {
                        id: 'b69fb425-e42c-4f63-8d41-840ac4b4e03f',
                        name: 'HelloID',
                        picture: '/source/helloid.png'
                    },
                    {
                        id: 'b187e934-4297-4ea6-86c3-bb81583b5009',
                        name: 'Illuminate',
                        picture: '/source/illuminate.png'
                    },
                    {
                        id: 'a9cbd664-f12c-4627-a520-7582064f0ca4',
                        name: 'Infinite Campus',
                        picture: '/source/infinitecampus.png'
                    },
                    {
                        id: '746abfcf-dab2-4079-a899-72433b0d7afb',
                        name: 'LTI 1.3',
                        picture: '/source/oneroster.png'
                    },
                    {
                        id: '5fe38a52-5c3a-4323-8f91-29327ae7c3ca',
                        name: 'Microsoft Teams',
                        picture: '/source/microsoft.png'
                    },
                    {
                        id: '050da476-d03a-41d9-8d6c-3462910d93ca',
                        name: 'Moodle',
                        picture: '/source/moodle.png'
                    },
                    {
                        id: 'd07c5f8c-e88c-4f70-b073-0f150b488db0',
                        name: 'OneRoster',
                        picture: '/source/oneroster.png'
                    },
                    {
                        id: 'f969915a-41cb-4875-8f9b-da6370e5a1b6',
                        name: 'Powerschool',
                        picture: '/source/powerschool.png'
                    },
                    {
                        id: '55c78d6f-504b-4529-a655-b157f23c86f0',
                        name: 'RapidIdentity',
                        picture: '/source/rapididentity.png'
                    },
                    {
                        id: 'ff2988d2-e83c-44c7-aa46-07e889c03e62',
                        name: 'SFTP',
                        picture: '/source/sftp.png'
                    },
                    {
                        id: '47eda4bf-7440-4cd8-9ee7-44cafb98486b',
                        name: 'Schoology',
                        picture: '/source/schoology.png'
                    },                                    
                    {
                        id: '121480b2-65bc-4628-a535-843d70fe78dd',
                        name: 'Skyward',
                        picture: '/source/skyward.png'
                    },
                    {
                        id: '135d8091-9773-4f21-acf6-43ed1a51647d',
                        name: 'Veracross',
                        picture: '/source/veracross.png',
                    }
                ]
            };
        },
        created(){
            this.load();
        },
        computed: {
            results(){
                const search_val = this.search?.toLowerCase();
                const reg_exp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                const is_uuid = reg_exp.test(search_val);
                
                return this.sources.filter(source => {
                    // Doing a bunch of checks to see if this source "passes" the filters.
                    if(this.search){
                        if(is_uuid){
                            if(source.team.id !== search_val && source.id !== search_val){
                                return false;
                            }
                        } else {        
                            const team_name = source.team.name?.toLowerCase();
                            const source_name = source.name?.toLowerCase();                    
                            if(!team_name.includes(search_val) && !source_name.includes(search_val)){
                                return false;
                            }
                        }
                    }

                    if(this.state){                             
                        if(source.status !== this.state.id){
                            //delete the source from the map if the states dont match
                            return false;
                        }                                                         
                    }

                    if(this.updated_date){                                 
                        const adjusted_time_updated = moment();                                                                                     
                        if(this.updated_date.id === 'last-hour'){                                                   
                            adjusted_time_updated.subtract(1, 'hour');                           
                        } else if(this.updated_date.id === 'last-day'){               
                            adjusted_time_updated.subtract(1, 'day');                          
                        } else if(this.updated_date.id === 'last-week'){              
                            adjusted_time_updated.subtract(1, 'week');                           
                        } else if(this.updated_date.id === 'last-month'){                   
                            adjusted_time_updated.subtract(1, 'month');                            
                        } else if(this.updated_date.id === 'last-year'){                            
                            adjusted_time_updated.subtract(1, 'year');
                        }                                               
                        if(moment(source.updated_date) < adjusted_time_updated){                        
                            return false;
                        }                                            
                    }

                    if(this.created_date){       
                        const adjusted_time_created = moment();              
                        if(this.created_date.id === 'last-hour'){
                            adjusted_time_created.subtract(1, 'hour');
                        } else if(this.created_date.id === 'last-day'){                        
                            adjusted_time_created.subtract(1, 'day');
                        } else if(this.created_date.id === 'last-week'){
                            adjusted_time_created.subtract(1, 'week');
                        } else if(this.created_date.id === 'last-month'){
                            adjusted_time_created.subtract(1, 'month');
                        } else if(this.created_date.id === 'last-year'){
                            adjusted_time_created.subtract(1, 'year');
                        }                        
                        if(moment(source.created_date) < adjusted_time_created){
                            return false;
                        }                                            
                    }

                    if(this.provider){                                                        
                        if(this.provider.id !== source.provider.id){
                            return false;
                        }                   
                    }

                    return true;
                }).slice(0, 100);             
            }
        },
        methods: {        
            open(source){
                this.$router.push({name: 'team.sources.source', params: {team: source.team.alias, source: source.id}});
            },                
            switch_state(state){                
                this.state = state;             
            },
            switch_updated_date(updated_date){                
                this.updated_date = updated_date;           
            },
            switch_created_date(created_date){                
                this.created_date = created_date;              
            },
            switch_provider(provider){                
                this.provider = provider;              
            },
            status(source){
                return {
                    red: ['error', 'destroyed'].includes(source.status),
                    yellow: ['disabled'].includes(source.status),
                    green: ['active'].includes(source.status),
                    grey: ['inactive'].includes(source.status)
                };
            },
            load(){
                const params = {                                        
                    //the total (maximum) amount of sources loaded
                    $first: 10000
                };

                this.$http.get('/admin/sources', {params})
                .then(response => this.sources = response.$data.sort((a, b) => a.created_date < b.created_date ? 1 : -1))                                
                .catch(error => this.$toasted.error('There was an error loading sources.'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .admin-sources
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .filters
    {
        top: @breadcrumbs-height;
    }

    .source-icon
    {
        width: 40px;
        padding-right: 0;

        img
        {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: @d;
            display: block;
            margin: 8px auto;
        }
    }

    .thumbnail
    {
        width: 16px;
        height: 16px;
        img
        {
            border-radius: 50%;
        }
        &::after
        {
            width: 16px;
            height: 16px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;

        &.red
        {
            background: @red;
        }

        &.blue
        {
            background: @base;
        }

        &.purple
        {
            background: @purple;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.dark-red
        {
            background: @hover;
        }

        &.grey
        {
            background: @c;
        }
    }

    .icon-status
    {
        margin-right: 8px;
    }

    .source-name
    {
        line-height: 16px;
    }

    .enrichment-status
    {
        margin-top: 4px;
    }

    .source-team
    {
        .team-name
        {
            color: @black;
        }

        .unknown
        {
            color: @grey;
        }
    }
</style>