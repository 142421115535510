<template>
    <div class="admin-people">

        <section class="flex">
            <div class="section-content ff">
                <h2>Person Change Information</h2>

                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Id</div>
                        <div class="summary-value">{{ person.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person District Id</div>
                        <div class="summary-value">{{ person.district.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person School Ids</div>
                        <div class="summary-value">{{ person.school.ids }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Sync Id</div>
                        <div class="summary-value">{{ person.sync.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Source Id</div>
                        <div class="summary-value">{{ person.source.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person External Id</div>
                        <div class="summary-value">{{ person.external.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Object Version</div>
                        <div class="summary-value">{{ person.object.version }}</div>
                    </div>

                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Hash</div>
                        <div class="summary-value">{{ person.hash }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Created Date</div>
                        <div class="summary-value">{{ person.created_date }}</div>
                    </div>                   
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Updated Date</div>
                        <div class="summary-value">{{ person.updated_date }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person First Name</div>
                        <div class="summary-value">{{ person.first_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Middle Name</div>
                        <div class="summary-value">{{ person.middle_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Last Name</div>
                        <div class="summary-value">{{ person.last_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Display Name</div>
                        <div class="summary-value">{{ person.display_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Picture URL</div>
                        <div class="summary-value">{{ person.picture_url }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Birthday</div>
                        <div class="summary-value">{{ person.birthday }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Gender</div>
                        <div class="summary-value">{{ person.gender }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Roles</div>
                        <div class="summary-value">{{ person.roles }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Email</div>
                        <div class="summary-value">{{ person.email }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Phone</div>
                        <div class="summary-value">{{ person.phone }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Locale</div>
                        <div class="summary-value">{{ person.locale }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Time Zone</div>
                        <div class="summary-value">{{ person.time_zone }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Graduation Year</div>
                        <div class="summary-value">{{ person.graduation_year }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Grade Levels</div>
                        <div class="summary-value">{{ person.grade_levels }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Residence Status</div>
                        <div class="summary-value">{{ person.residence_status }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person English Language Learner</div>
                        <div class="summary-value">{{ person.english_language_learner }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Country of Birth</div>
                        <div class="summary-value">{{ person.country_of_birth }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person State of Birth</div>
                        <div class="summary-value">{{ person.state_of_birth }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person City of Birth</div>
                        <div class="summary-value">{{ person.city_of_birth }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Hispanic or Latino ethnicity</div>
                        <div class="summary-value">{{ person.hispanic_or_latino_ethnicity }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Races</div>
                        <div class="summary-value">{{ person.races }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Identifiers</div>
                        <div class="summary-value"> {{ person.identifiers }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Internal</div>
                        <div class="summary-value">{{ person.internal }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Homeless</div>
                        <div class="summary-value">{{ person.homeless }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Disability</div>
                        <div class="summary-value">{{ person.disability }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Gifted Talented</div>
                        <div class="summary-value">{{ person.gifted_talented }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Food Service Program eligibility</div>
                        <div class="summary-value">{{ person.food_service_program_eligibility }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Economically Disadvantaged</div>
                        <div class="summary-value">{{ person.economically_disadvantaged }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Migrant</div>
                        <div class="summary-value">{{ person.migrant }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Public Assistance</div>
                        <div class="summary-value">{{ person.public_assistance }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Rural Residency</div>
                        <div class="summary-value">{{ person.rural_residency }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Individualized Education Plan</div>
                        <div class="summary-value">{{ person.individualized_education_plan }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Primary Language</div>
                        <div class="summary-value">{{ person.primary_language }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Special Accommodations</div>
                        <div class="summary-value">{{ person.special_accommodations }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Military Connected</div>
                        <div class="summary-value">{{ person.military_connected }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Street Address</div>
                        <div class="summary-value">{{ person.street }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Unit Number</div>
                        <div class="summary-value">{{ person.unit }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person City</div>
                        <div class="summary-value">{{ person.city }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person State</div>
                        <div class="summary-value">{{ person.state }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Postal Code</div>
                        <div class="summary-value">{{ person.postal_code }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Country</div>
                        <div class="summary-value">{{ person.country }}</div>
                    </div>
                    <div class="summary-field flex">
                        <div class="summary-key">Person Properties</div>
                        <div class="summary-value">{{ person.properties }}</div>
                    </div>
                </div>
            </div>
        </section>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
export default {
    name: 'AdminPerson',
    data() {
        return {
            person: [],
            search: ''

        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}`)
            .then((response) => {                
                this.person = response.$data;

            })
            .catch((error) => {
                this.$toasted.error(
                    'Failed to load person'
                );
            });
    },
    methods: {

    },
    computed: {

    },
};
</script>



<style scoped lang="less">


@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;
      
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;

       
    }
}

.ff {
    padding: 15px;
}

</style>
