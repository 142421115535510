<template>
    <div class="filters flex flex-align">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Filters',
        props: {
            
        },
        data(){
            return {};
        },
        computed: {
            
        },
        methods: {
            
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        padding: ((@filters-height - @button-height - 1) / 2) 10px;
        height: @filters-height;
        border-bottom: 1px solid @e;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background: @f;

        .filter
        {
            margin-left: 10px;
            max-width: 400px;

            &:first-child
            {
                margin-left: 0;
            }
            
            .filter-badge
            {
                border-radius: @border-radius;
                padding: 5px 8px;
                line-height: 20px;
                font-size: 13px;
                font-weight: 500;
                color: @grey;
                cursor: pointer;
                user-select: none;
                background: @f;

                .icon
                {
                    margin-right: 6px;
                }

                &:hover, &:active
                {
                    background: @e;
                    color: @black;
                }

                .filter-action
                {
                    width: 16px;
                    height: 16px;
                    margin: 0 0 0 6px;
                }

                .clear-filter, .required-filter
                {
                    cursor: pointer;
                    
                    &:hover
                    {
                        background: @base;
                        border-radius: @border-radius - 2px;
                        color: @f;
                    }
                }
            }

            .filter-item
            {
                padding: 6px 10px;
                color: @black;

                .filter-item-icon
                {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    margin-left: -4px;

                    .thumbnail
                    {
                        width: 16px;
                        height: 16px;
                        border-radius: @border-radius / 2;
                        overflow: hidden;
                    }

                    img
                    {
                        width: 16px;
                        height: 16px;
                    }
                }

                .filter-item-name
                {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                }
            }
        }
    }
</style>
