<template>
    <div class="integrate-source-provider-facts">
        <integrate-source-editor-step name="Generating an API key" icon="iconoir-key-alt-plus" :complete="completed.includes('generate_apikey')">
            <integrate-source-editor-configuration title="Navigate to the API Keys Page" icon="iconoir-info-empty">
                <div class="image-label flex flex-align flex-wrap">
                    <label>1. From FACTS SIS, click on the &#x2630; in the top left corner.</label>
                    <!-- <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/choose-file.png', { title: 'Plugin Installation Page', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div> -->
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Click <code>System</code>, then select <code>Configuration</code>.</label>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Click <code>API Configuration</code>, then click <code>API Keys</code>.</label>
                </div>
            </integrate-source-editor-configuration>
            <integrate-source-editor-configuration title="Create a new API key" icon="iconoir-add-circled-outline">
                <div class="image-label flex flex-align flex-wrap">
                    <label>1. Click Add Key.</label>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Copy and paste the Edlink's Subscription ID in the <code>Subscription ID</code> field.</label>
                </div>
                <div class="facts-details">
                    <div class="flex flex-align">
                        <span class="block icon iconoir-database-settings"></span>
                        <h2 class="ff">Edlink FACTS Details</h2>
                    </div>
                    <label>Subscription ID</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => '3adb9888c1d24f709c33e31a26f09703'" v-clipboard:success="() => $toasted.info('Copied')">3adb9888c1d24f709c33e31a26f09703</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Type Edlink in the <code>API Key Name</code>.</label>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>4. Select Read and Write for <code>Academics</code>, <code>Gradebook</code>, <code>People</code> and <code>Admissions</code>.</label>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>5. Click Generate. The key is then generated and displayed in a pop-up window.</label>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>6. Click Copy. You will need to paste this key into Edlink.</label>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="generate_apikey" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Input the API Key You Generated" icon="iconoir-password-cursor" :complete="completed.includes('provide_apikey')">
            <div class="helptext">
                Paste the value you copied from Facts SIS into the field below.
            </div>
            <input class="medium" type="text" placeholder="API Key" :value="source.configuration.sis_api_key" @input="e => send('UPDATE_SOURCE', {path: 'configuration.sis_api_key', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderFacts',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(!this.generate_apikey){
                    return [];
                }

                if(!this.source.configuration?.sis_api_key){
                    return ['generate_apikey'];
                }

                return ['generate_apikey', 'provide_apikey'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 2);
            }
        },
        data(){
            return {
                generate_apikey: false,
                
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
    
    .facts-details
    {
        background: @e;
        border-radius: 6px;
        padding: 15px;
        width: 100%;

        .icon
        {
            margin-right: 10px;
            color: @black;
            font-size: 18px;
        }

        h2
        {
            font-size: 13px;
            color: @black;
            font-weight: 400;
        }

        label
        {
            margin: 15px 0 4px;
            font-size: 12px;
            letter-spacing: 0;
            color: @grey;
        }

        .configuration-link
        {
            font-weight: 400;
            font-size: 14px;
            justify-content: flex-start;
            line-height: 16px;
            height: 16px;
            padding-right: 18px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -1px top 1px;
            background-size: 14px auto;

            &.disabled
            {
                background-image: url('~@/assets/icons/grey/external.svg');
            }
        }

        .configuration-value
        {
            color: @black;
            font-size: 14px;
            line-height: 16px;

            &.monospace
            {
                font-size: 13px;
            }

            &.copyable
            {
                &.noshift
                {
                    &::after
                    {
                        height: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
</style>
