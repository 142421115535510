<template>
    <div class="source-sync-details" v-if="job">
        <div class="parent-warning-banner yellow flex flex-align" v-if="job.parent?.id">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">This is a child sync that belongs to a larger parent sync.</div>
            <router-link class="button yellow" :to="{ params: { sync: job.parent.id } }">Go to Parent</router-link>
        </div>
        <section>
            <h2>Sync Details</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Sync ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => job.id" v-clipboard:success="() => $toasted.info('Copied')">{{ job.id }}</div>
                </div>
                <div class="summary-field flex flex-align" v-if="job.parent?.id">
                    <div class="summary-key">Parent Sync ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => job.parent.id" v-clipboard:success="() => $toasted.info('Copied')">{{ job.parent.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => job.source.id" v-clipboard:success="() => $toasted.info('Copied')">{{ job.source.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Sync Status</div>
                    <div class="summary-value capitalize">{{ job.status }}</div>
                </div>
                <template v-if="job.parent?.id">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Phase</div>
                        <div class="summary-value capitalize">{{ job.internal?.partial?.phase ?? 'Unknown' }}</div>
                    </div>
                </template>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Initiated</div>
                    <div class="summary-value">{{ job.created_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Completed</div>
                    <div class="summary-value" v-if="['queued', 'working'].includes(job.status)">&ndash;</div>
                    <div class="summary-value" v-else>{{ job.updated_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source</div>
                    <div class="summary-value">
                        <router-link v-if="job.source" :to="{ name: 'team.sources.source', params: { team: this.$store.getters.team.alias, source: job.source.id } }" class="text-button">{{ job.source.name }}</router-link>
                    </div>
                </div>
                <template v-if="admin">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Staged Changes</div>
                        <div class="summary-value">
                            <div class="text-button red" @click="$emit('delete_staged')" :class="{ disabled: canceling }">Delete Staged Changes</div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="['queued', 'working', 'pending'].includes(job.status)">
                        <div class="summary-key">Cancel Sync</div>
                        <div class="summary-value">
                            <div class="text-button red" @click="$emit('cancel')" :class="{ disabled: canceling }">Cancel Sync</div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="['pending'].includes(job.status) && !children.length">
                        <div class="summary-key">Flush Sync</div>
                        <div class="summary-value">
                            <div class="text-button red" @click="$emit('flush')" :class="{ disabled: canceling }">Force Flush Sync</div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">
                            Drawer
                        </div>
                        <div class="summary-value" v-if="job">
                            <div class="text-button" @click="open_drawer(job)">Open Drawer</div>
                        </div>
                    </div>
                </template>
            </div>
        </section>
        <section v-if="children.length">
            <h2>Child Syncs</h2>
            <datatable class="card" :columns="columns" :rows="children" :header="true" :clickable="true" @clicked="open" />
        </section>
    </div>
</template>

<script>
    import { WhiteFlag, CodeBracketsSquare, CalendarPlus, Calendar } from '@epiphany/iconoir';
    import SyncDrawer from '@/components/drawers/Sync.vue';
    import State from '@/components/chips/State.vue';

    export default {
        name: 'SyncDetails',
        props: {
            job: Object,
            children: Array,
            canceling: Boolean
        },
        data() {
            return {
                logs: [],
                result: '',
                columns: [
                    {
                        id: 'id',
                        name: 'Sync ID',
                        icon: CodeBracketsSquare,
                        width: '20%',
                        value: row => row.id
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        component: State,
                        width: '160px',
                        value: row => ({ state: row.status })
                    },
                    {
                        id: 'phase',
                        name: 'Phase',
                        icon: CodeBracketsSquare,
                        value: row => row.internal?.partial?.phase,
                        width: '160px'
                    },
                    {
                        id: 'parallelizable',
                        name: 'Parallelizable',
                        icon: CodeBracketsSquare,
                        value: row => row.internal?.partial?.parallel,
                        width: '160px'
                    },
                    {
                        id: 'created',
                        name: 'Created',
                        icon: CalendarPlus,
                        align: 'right',
                        width: '20%',
                        value: row => row.created_date
                    },
                    {
                        id: 'updated',
                        name: 'Updated',
                        icon: Calendar,
                        align: 'right',
                        width: '20%',
                        value: row => row.updated_date
                    }
                ]
            };
        },
        computed: {
            admin() {
                return this.$store.getters.user.admin;
            },
            user() {
                return this.$store.getters.user;
            },
        },
        methods: {
            open(child){
                this.$router.push({ params: { sync: child.id } });
            },
            open_drawer(job) {
                this.$store.dispatch('drawer/open', {
                    key: `entity/${job.id}`,
                    width: 800,
                    props: { details: job, type: 'source_sync' },
                    component: SyncDrawer
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: @double-padding;
    }

    section
    {
        padding: @double-padding;

        &:first-child
        {
            border-top: 0;
        }
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    .sync-status
    {
        width: 120px;
    }

    .sync-status-badge
    {
        line-height: 18px;
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        text-transform: uppercase;

        &.deleted,
        &.error
        {
            color: @red;
        }

        &.updated,
        &.working
        {
            color: @base;
        }

        &.created,
        &.complete
        {
            color: @green;
        }

        &.staged
        {
            color: @purple;
        }

        &.planned
        {
            color: @grey;
        }
    }

    .sync-log-options
    {
        padding: 20px 0 0;
        user-select: none;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .sync-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .summary
    {
        font-size: 13px;

        .summary-field
        {
            height: 22px;
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }

            &.multiline
            {
                height: auto;
                align-items: flex-start;

                .summary-values
                {
                    align-items: flex-start;
                }
            }
        }

        .summary-key
        {
            color: @grey;
            width: 180px;
            flex-basis: 180px;
            flex-shrink: 0;
            line-height: 22px;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            &.monospace
            {
                line-height: 22px;
                font-size: 13px;
            }

            .flag-icon
            {
                width: 12px;
                height: 12px;
                margin-right: 8px;
            }
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    .staged-changes
    {
        nav
        {
            padding: 0 20px;
            border-bottom: 1px solid @e4;
        }

        .nothing
        {
            font-size: 13px;
            border: 0;
        }
    }

    .parent-warning-banner
    {
        height: 50px;
        font-size: 13px;
        padding: 0 10px 0 20px;
        margin-top: 0;
        border-radius: 0;
        font-weight: 500;
        background: @yellow;
        color: darken(@yellow, 25%);

        .button
        {
            background: darken(@yellow, 5%);

            &:hover, &:active
            {
                background: darken(@yellow, 15%);
            }
        }
    }
</style>
