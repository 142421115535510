import axios from 'axios';
import _ from 'lodash';

const state = {
    // The active preview object, if there is one.
    preview: null,

    // Our global loading state.
    loading: false,

    // Local loading state.
    // If we know we have made a config change on the front-end that has not been saved to the server, this should be true.
    // Once the config has been saved to the server, this should be false.
    locked: false,

    // If the store is initialized, this will be true.
    initialized: false,

    heartbeating: false,

    throttle: null,

    debug: null
};

const mutations = {
    preview(state, preview) {
        // Set the preview object.
        state.preview = preview;

        // Save it to localStorage.
        localStorage.setItem(
            'blink-preview',
            JSON.stringify({
                team_id: preview.team.id,
                integration_id: preview.integration.id,
                id: preview.id
            })
        );
    },
    clear(state) {
        // Clear the preview object.
        state.preview = null;

        // Remove it from localStorage.
        localStorage.removeItem('blink-preview');
    },
    state(state, value) {
        state.preview.state = value;
    },
    patch(state, preview) {
        state.preview.state = preview.state;
        state.preview.configuration = preview.configuration;
        state.preview.rule_id = preview.rule_id;
        state.preview.transformation_id = preview.transformation_id;
    },
    heartbeating(state, value) {
        state.heartbeating = value;
    },
    throttle(state, timeout) {
        if (state.throttle) {
            clearTimeout(state.throttle);
        }

        state.throttle = timeout;
    },
    debug(state, debug) {
        state.debug = debug;
    }
};

const actions = {
    // When the page loads, check our localStorage for a preview, just in case.
    async initialize({ commit, dispatch }) {
        const stored = localStorage.getItem('blink-preview');

        if (stored) {
            try {
                const parsed = JSON.parse(stored);

                // Fetch the preview from the database.
                const { $data: preview } = await axios.get(`/teams/${parsed.team_id}/integrations/${parsed.integration_id}/previews/${parsed.id}`);

                // If it's still in a valid state, use it.
                if (['error', 'killed'].includes(preview.state)) {
                    commit('clear');
                } else {
                    commit('preview', preview);
                }

                // Schedule a heartbeat.
                setTimeout(() => dispatch('heartbeat'), ['queued', 'working'].includes(preview.state) ? 1000 : 5000);
            } catch (error) {
                commit('clear');
            }
        }
    },

    // Start a new preview job for a particular integration.
    async preview({ commit, rootGetters, dispatch, state }, configuration) {
        if (state.preview) {
            await dispatch('kill');
        }

        const { $data: preview } = await axios.post(`/teams/${rootGetters.team.id}/integrations/${rootGetters.integration.id}/previews`, configuration);

        // Add it to our state.
        commit('preview', preview);

        // Schedule a heartbeat.
        setTimeout(() => dispatch('heartbeat'), 1000);
    },

    // Requeue a dead job.
    async requeue({ commit, state, dispatch }) {
        const { $data: preview } = await axios.post(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews`, {
            rule_id: state.preview.rule.id,
            transformation_id: state.preview.transformation.id,
            configuration: state.preview.configuration
        });

        // Add it to our state.
        commit('preview', preview);

        // Schedule a heartbeat.
        setTimeout(() => dispatch('heartbeat'), 1000);
    },

    // Patch the preview job with new configuration.
    async patch({ commit, state, dispatch }, properties) {
        if (state.throttle) {
            commit('throttle');
        }

        if (state.preview) {
            const throttle = setTimeout(async () => {
                try {
                    const { $data: preview } = await axios.put(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews/${state.preview.id}`, properties);

                    // Update our state.
                    commit('patch', preview);

                    // Schedule a heartbeat.
                    setTimeout(() => dispatch('heartbeat'), 1000);
                } catch (error) {
                    if (error?.response?.status === 403) {
                        const { $data: preview } = await axios.post(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews`, {
                            rule_id: state.preview.rule.id,
                            transformation_id: state.preview.transformation.id,
                            configuration: state.preview.configuration
                        });

                        // Add it to our state.
                        commit('preview', preview);

                        // Schedule a heartbeat.
                        setTimeout(() => dispatch('heartbeat'), 1000);
                    }
                } finally {
                    if (state.throttle) {
                        commit('throttle');
                    }
                }
            }, 2000);

            commit('throttle', throttle);
        }
    },

    // Check on the status of the preview job, if there is one.
    async heartbeat({ commit, dispatch, state }) {
        if (state.heartbeating) {
            return;
        }

        commit('heartbeating', true);

        // We basically NEVER want to do more than 1 heartbeat per second.
        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (state.preview) {
            try {
                // Fetch the preview from the database.
                const { $data: preview } = await axios.get(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews/${state.preview.id}`);

                // Apply any changes to the active preview.
                commit('state', preview.state);

                if ((preview.state === 'ready') | (preview.state === 'error')) {
                    dispatch('debug');
                }
            } catch (error) {
                // TODO: Handle this error.
            }

            if (state.preview.state === 'killed') {
                commit('clear');
            } else {
                setTimeout(() => dispatch('heartbeat'), ['queued', 'working'].includes(state.preview.state) ? 1000 : 5000);
            }
        }

        commit('heartbeating', false);
    },

    // Kill the active preview job.
    async kill({ commit, state }) {
        // If the preview is not in the killed or error state, make the API call to kill it.
        if (state.preview) {
            // Make the API call to actually delete the preview.
            await axios.delete(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews/${state.preview.id}`);

            // Remove it from localStorage.
            localStorage.removeItem('blink-preview');

            commit('clear');
        }
    },

    // Search the dataset.
    async search({ state }, { type, params }) {
        // If the preview is not in the killed or error state, make the API call to kill it.
        if (state.preview) {
            return axios.get(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews/${state.preview.id}/${type}`, { params });
        }
    },

    async debug({ commit, state }) {
        // If we have a preview .
        if (state.preview) {
            const { $data: debug } = await axios.get(`/teams/${state.preview.team.id}/integrations/${state.preview.integration.id}/previews/${state.preview.id}/debug`);
            console.log(debug);
            commit('debug', debug);
            return debug;
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
