<template>
    <div class="full scroll" v-if="integration">
        <information class="integration-privacy full scroll" :class="{unsaved}">
            <template v-slot:title>Data Privacy</template>
            <template v-slot:description>
                This page allows you to specify the types of data that {{ integration.application.name }} will receive.
                In the interest of protecting student data privacy, we recommend that you share only the data that {{ integration.application.name }} requires.
                If you change the settings on this page, it will not affect other integrated applications.
            <!-- <div class="banner flex flex-align grey" v-if="!synced">
                <span class="banner-icon icon block iconoir-no-lock"></span>
                Looks like the permissions for this integration are out of sync with those requested by the application.
                <div class="ff"></div>
            </div> -->
            </template>
            <template v-slot:details>
                <div class="flex card">
                    <div class="ff column">
                        <h3>Basic Options</h3>
                        <div class="toggle-group">
                            <div class="flex flex-align">
                                <toggle :active="has('796d4633-c262-49d4-8e5c-b801a6a1c053')" @change="() => toggle('796d4633-c262-49d4-8e5c-b801a6a1c053')" />
                                <div class="ff toggle-title">Modify Coursework &amp; Grades</div>
                            </div>
                            <div class="toggle-description">This application will be able to write coursework, assignments, and grades to the LMS or SIS.</div>
                        </div>
                        <div class="toggle-group">
                            <div class="flex flex-align">
                                <toggle :active="has('0a2ff39e-ca16-458f-98c4-a58a6766c356')" @change="toggle('0a2ff39e-ca16-458f-98c4-a58a6766c356')" />
                                <div class="ff toggle-title">Share Parents &amp; Guardians</div>
                            </div>
                            <div class="toggle-description">If the district provides data about parents or guardians, it will be shared with your application.</div>
                        </div>
                        <div class="toggle-group">
                            <div class="flex flex-align">
                                <toggle :active="has('29f0dc61-9bf4-4a4e-b0a8-ee7c72b468d5')" @change="toggle('29f0dc61-9bf4-4a4e-b0a8-ee7c72b468d5')" />
                                <div class="ff toggle-title">Share Extended Roster Data</div>
                            </div>
                            <div class="toggle-description">If the district provides extended roster data, it will be shared with your application.</div>
                        </div>
                        <div class="toggle-group">
                            <div class="flex flex-align">
                                <toggle :active="has('3282fba3-3f3f-44d2-9b91-e742f2d19c22')" @change="toggle('3282fba3-3f3f-44d2-9b91-e742f2d19c22')" />
                                <div class="ff toggle-title">Share Attendance Data</div>
                            </div>
                            <div class="toggle-description">If the district provides data about attendance, it will be shared with your application.</div>
                        </div>
                        <div class="toggle-group">
                            <div class="flex flex-align">
                                <toggle :active="has('426a9f5a-2efe-4b46-83d2-84c7b9ac05e2')" @change="toggle('426a9f5a-2efe-4b46-83d2-84c7b9ac05e2')" />
                                <div class="ff toggle-title">Share Behavior Data</div>
                            </div>
                            <div class="toggle-description">If the district provides data about incidents, it will be shared with your application.</div>
                        </div>
                        <div class="toggle-group">
                            <div class="flex flex-align">
                                <toggle :active="has('d05ce65f-65e0-4857-93d3-7dae938cfa6d')" @change="toggle('d05ce65f-65e0-4857-93d3-7dae938cfa6d')" />
                                <div class="ff toggle-title">Share Transportation Data</div>
                            </div>
                            <div class="toggle-description">If the district provides data about bus routes, it will be shared with your application.</div>
                        </div>
                    </div>
                    <div class="ff column">
                        <h3>Personal Data</h3>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('b6355c7b-56bb-48a4-a5be-0712cc0f35ea')" @input="toggle('b6355c7b-56bb-48a4-a5be-0712cc0f35ea')" />
                            <div class="checkbox-title">First and Last Name</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('3b16fb3b-23f4-47f0-b4df-a04a3b8eeb8d')" @input="toggle('3b16fb3b-23f4-47f0-b4df-a04a3b8eeb8d')" />
                            <div class="checkbox-title">Truncate Last Names</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('4db0b9f7-bdd1-4908-9d6f-7b6d94155bd3')" @input="toggle('4db0b9f7-bdd1-4908-9d6f-7b6d94155bd3')" />
                            <div class="checkbox-title">Generate Random Names</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('8aa73a24-c279-4a45-9123-9cda31cc316b')" @input="toggle('8aa73a24-c279-4a45-9123-9cda31cc316b')" />
                            <div class="checkbox-title">Email Address</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('739d6139-abcf-4217-b0a9-bc11764c3ead')" @input="toggle('739d6139-abcf-4217-b0a9-bc11764c3ead')" />
                            <div class="checkbox-title">Phone Number</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('9e1ed7ac-a871-44c4-a355-0517b4217ecb')" @input="toggle('9e1ed7ac-a871-44c4-a355-0517b4217ecb')" />
                            <div class="checkbox-title">Profile Picture</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('a4517daf-facc-4baf-8172-50ef5b550107')" @input="toggle('a4517daf-facc-4baf-8172-50ef5b550107')" />
                            <div class="checkbox-title">Generate Random Pictures</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('fe75b1b2-2c56-4c4b-a7a1-80048da6b24f')" @input="toggle('fe75b1b2-2c56-4c4b-a7a1-80048da6b24f')" />
                            <div class="checkbox-title">Institution Roles</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('958b78e3-07d0-4bd8-b3de-74824cc11328')" @input="toggle('958b78e3-07d0-4bd8-b3de-74824cc11328')" />
                            <div class="checkbox-title">Simplified Role Set</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('1240bc10-4d5b-48c5-b967-6e7e778dcc48')" @input="toggle('1240bc10-4d5b-48c5-b967-6e7e778dcc48')" />
                            <div class="checkbox-title">Grade Levels</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('0e9ad589-6678-482a-b740-b2ce697ff8fb')" @input="toggle('0e9ad589-6678-482a-b740-b2ce697ff8fb')" />
                            <div class="checkbox-title">Graduation Year</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('7d6ea160-04ad-4c9c-92d2-ce961a982d68')" @input="toggle('7d6ea160-04ad-4c9c-92d2-ce961a982d68')" />
                            <div class="checkbox-title">School Identifiers</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('59bacbc2-39f0-49ed-89ea-c49b9857e49c')" @input="toggle('59bacbc2-39f0-49ed-89ea-c49b9857e49c')" />
                            <div class="checkbox-title">GPA Numeric</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('189a1c22-2faf-4b80-81df-6e698d3cb077')" @input="toggle('189a1c22-2faf-4b80-81df-6e698d3cb077')" />
                            <div class="checkbox-title">GPA Letter Grade</div>
                        </label>                                        
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has(address_permissions)" disabled />
                            <div class="checkbox-title">Address</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('a02e63a8-653c-433b-bccb-0478d89a45e1')" @input="toggle('a02e63a8-653c-433b-bccb-0478d89a45e1')" />
                            <div class="checkbox-title">Street</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('bdea9bf0-7889-4d82-b220-67e116d39395')" @input="toggle('bdea9bf0-7889-4d82-b220-67e116d39395')" />
                            <div class="checkbox-title">Unit</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('3ff9ce50-8a12-4800-b2d5-ec09c228a8b7')" @input="toggle('3ff9ce50-8a12-4800-b2d5-ec09c228a8b7')" />
                            <div class="checkbox-title">City</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('8f5457ec-bd1d-4b7c-9d56-d7b8b9ef3822')" @input="toggle('8f5457ec-bd1d-4b7c-9d56-d7b8b9ef3822')" />
                            <div class="checkbox-title">State</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('1bdcca92-58aa-433a-8545-2867ab2d1375')" @input="toggle('1bdcca92-58aa-433a-8545-2867ab2d1375')" />
                            <div class="checkbox-title">PostalCode</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('bab7db1c-a908-49f1-be0c-544939cf3faa')" @input="toggle('bab7db1c-a908-49f1-be0c-544939cf3faa')" />
                            <div class="checkbox-title">Country</div>
                        </label>
                    </div>
                    <div class="ff column">
                        <h3>Demographics</h3>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('045646ca-2d44-4216-a548-19a6e72b35a0')" @input="toggle('045646ca-2d44-4216-a548-19a6e72b35a0')" />
                            <div class="checkbox-title">Birthday</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('4c89756d-7794-465b-9e96-8439be8304e7')" @input="toggle('4c89756d-7794-465b-9e96-8439be8304e7')" />
                            <div class="checkbox-title">Country of Birth</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('4260b47f-78a6-4616-8ebd-35f884aa6a01')" @input="toggle('4260b47f-78a6-4616-8ebd-35f884aa6a01')" />
                            <div class="checkbox-title">State of Birth</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('5f767a1e-8bf7-4ffc-8a45-6a8ffa63f130')" @input="toggle('5f767a1e-8bf7-4ffc-8a45-6a8ffa63f130')" />
                            <div class="checkbox-title">City of Birth</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('7abe0353-34b7-48f7-84c9-7ae6c92d67e8')" @input="toggle('7abe0353-34b7-48f7-84c9-7ae6c92d67e8')" />
                            <div class="checkbox-title">Gender Identity</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('89619c30-9712-42aa-80f9-67d26408f9f5')" @input="toggle('89619c30-9712-42aa-80f9-67d26408f9f5')" />
                            <div class="checkbox-title">Race</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('2b109f34-debb-4394-9d3f-4029ac1d0cbb')" @input="toggle('2b109f34-debb-4394-9d3f-4029ac1d0cbb')" />
                            <div class="checkbox-title">Hispanic or Latino Ethnicity</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('1e5607b6-20ad-4b51-9aec-03ca23419b1b')" @input="toggle('1e5607b6-20ad-4b51-9aec-03ca23419b1b')" />
                            <div class="checkbox-title">English Language Learner</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('98f24bda-60a0-41fb-84c8-befcccd1f8d8')" @input="toggle('98f24bda-60a0-41fb-84c8-befcccd1f8d8')" />
                            <div class="checkbox-title">Individualized Education Plan</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('202a32ef-868e-49da-b793-551716e88c85')" @input="toggle('202a32ef-868e-49da-b793-551716e88c85')" />
                            <div class="checkbox-title">Food Service Program Eligibility</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('d3521f56-2dfa-4760-9677-872d663ae5eb')" @input="toggle('d3521f56-2dfa-4760-9677-872d663ae5eb')" />
                            <div class="checkbox-title">Residence Status</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('9cc7d7d5-f20d-4af6-b644-1df6d456ce5d')" @input="toggle('9cc7d7d5-f20d-4af6-b644-1df6d456ce5d')" />
                            <div class="checkbox-title">Rural Residency</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('14b1db59-dbff-4cb5-b2d2-bf8614f09cbe')" @input="toggle('14b1db59-dbff-4cb5-b2d2-bf8614f09cbe')" />
                            <div class="checkbox-title">Homeless</div>
                        </label>
                        <label class="checkbox-group flex flex-align indent">
                            <input type="checkbox" class="block checkbox-input" :checked="has('117a8ccf-b822-415e-b3cc-ceeb1b6edce9')" @input="toggle('117a8ccf-b822-415e-b3cc-ceeb1b6edce9')" />
                            <div class="checkbox-title">Migrant Status</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('bceb0443-fc5b-4d4f-bfda-e382f813cb97')" @input="toggle('bceb0443-fc5b-4d4f-bfda-e382f813cb97')" />
                            <div class="checkbox-title">Disability</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('078df3a3-7198-4090-821a-d11d73e04ef6')" @input="toggle('078df3a3-7198-4090-821a-d11d73e04ef6')" />
                            <div class="checkbox-title">Gifted or Talented</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('13c6bab8-ded4-4662-8332-69bba47f6e5b')" @input="toggle('13c6bab8-ded4-4662-8332-69bba47f6e5b')" />
                            <div class="checkbox-title">Economically Disadvantaged</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('d4f6d025-a6ae-4135-8618-1a373751f47d')" @input="toggle('d4f6d025-a6ae-4135-8618-1a373751f47d')" />
                            <div class="checkbox-title">Public Assistance</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('1b6732ee-dc68-4526-9fdb-af7de4bec717')" @input="toggle('1b6732ee-dc68-4526-9fdb-af7de4bec717')" />
                            <div class="checkbox-title">Primary Language</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('a53dddab-c984-4e30-8cc1-31cd7a7980dc')" @input="toggle('a53dddab-c984-4e30-8cc1-31cd7a7980dc')" />
                            <div class="checkbox-title">Special Accommodations</div>
                        </label>
                        <label class="checkbox-group flex flex-align">
                            <input type="checkbox" class="block checkbox-input" :checked="has('671b4d8b-1d73-4e26-9dd6-10f5ae3b3dc9')" @input="toggle('671b4d8b-1d73-4e26-9dd6-10f5ae3b3dc9')" />
                            <div class="checkbox-title">Military Connected</div>
                        </label>
                    </div>
                </div>
            </template>
        </information>
        <div class="privacy-unsaved-changes banner flex flex-align warning" v-if="unsaved">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">You have unsaved changes that will be lost if you navigate away from this page.</div>
            <div class="button" @click="save">Save Changes</div>
            <div class="button" @click="revert">Revert</div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { InfoCircle } from '@epiphany/iconoir';

export default {
    name: 'IntegrationPrivacy',
    components: {
        InfoCircle
    },
    data() {
        return {
            original: [],
            modified: [],
            permissions: [],
            address_permissions: ['a02e63a8-653c-433b-bccb-0478d89a45e1', 'bdea9bf0-7889-4d82-b220-67e116d39395', '3ff9ce50-8a12-4800-b2d5-ec09c228a8b7', '8f5457ec-bd1d-4b7c-9d56-d7b8b9ef3822', '1bdcca92-58aa-433a-8545-2867ab2d1375', 'bab7db1c-a908-49f1-be0c-544939cf3faa']
        }
    },
    created(){
        this.original = _.cloneDeep(this.integration.permissions);
        this.modified = _.cloneDeep(this.integration.permissions);
    },
    methods: {
        revert(){
            this.modified = _.cloneDeep(this.original);
        },
        toggle(permission){
            if (this.modified.includes(permission)) {
                this.modified.splice(this.modified.indexOf(permission), 1);
            } else {
                this.modified.push(permission);
            }
        },
        has(permission){
            if (_.isArray(permission)) {
                return _.intersection(permission, this.modified).length > 0;
            }

            return this.modified.includes(permission);
        },
        async save(){
            try {
                // Set the saving flag.
                await this.$store.dispatch('save/save', 'privacy/update');

                // Update the integration in the DB.
                await this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}`, { permissions: this.modified.slice() });

                // Update the integration locally.
                this.$store.commit('integrations/update', { permissions: this.modified.slice() });

                // Finally, overwrite the original privacy options with the modified privacy options.
                this.original = _.cloneDeep(this.modified);

                // Set the saved flag.
                this.$store.dispatch('save/saved', 'privacy/update');
            } catch(error){
                this.$store.dispatch('save/error', 'privacy/update');
            }
        }
    },
    computed: {
        unsaved(){
            return !_.isEqual(this.original, this.modified);
        },
        saving(){
            return this.$store.state.save.actions.includes('privacy/update');
        },
        source() {
            return this.$store.getters.source;
        },
        integration() {
            return this.$store.getters.integration;
        },
        application() {
            return this.$store.getters.integration.application;
        },
        team() {
            return this.$store.getters.team;
        },
        synced() {
            return _.intersection(this.application.permissions, this.integration.permissions).length === this.application.permissions.length;
        }
    },
}
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .integration-privacy
    {
        &.unsaved
        {
            bottom: @banner-height;
        }
    }

    .privacy-unsaved-changes
    {
        .absolute(auto, 0, 0, 0);
        height: @banner-height;
    }

    section {
        margin-bottom: 20px;
    }
    
    .permissions
    {
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .card
    {
        h3
        {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            margin-bottom: 25px;
            border-bottom: 1px solid @e4;
            padding-bottom: 20px;
        }
    }
    
    .column
    {
        border-left: 1px solid @e4;
        padding: 20px;

        &:first-child
        {
            border-left: 0;
        }

        .hr
        {
            border-top: 1px solid @e4;
            margin: 25px 0;
        }
    }

    .toggle-group
    {
        margin-bottom: 25px;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .toggle-title
    {
        font-size: 14px;
        color: @black;
        margin-left: 10px;
    }

    .toggle-description
    {
        font-size: 12px;
        color: @grey;
        padding-top: 8px;
        line-height: 16px;
    }

    label.checkbox-group
    {
        margin-bottom: 20px;
        margin-top: 0;

        &.indent
        {
            padding-left: 28px;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .checkbox-input
    {
        margin: 0;
    }

    .checkbox-title
    {
        font-size: 14px;
        color: @black;
        margin-left: 10px;
        font-weight: 400;
    }
</style>