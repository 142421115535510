<template>
    <div class="options">
        <div class="options-title" :class="{description}">{{title ? title : 'Enter a Value'}}</div>
        <div class="options-description" v-if="description">{{description}}</div>
        <div class="options-input">
            <input type="text" v-model="input" @keyup.enter="confirm" @keyup.esc="$emit('close')" :placeholder="placeholder" ref="input" />
        </div>
        <div class="options-list flex">
            <a class="option ff" @click="confirm">{{done ? done : 'Done'}}</a>
            <a class="option ff grey" @click="$emit('close')">{{cancel ? cancel : 'Cancel'}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Prompt',
        props: {
            title: String,
            description: String,
            value: String,
            done: String,
            cancel: String,
            select: Function,
            placeholder: String
        },
        data(){
            return {
                input: ''
            };
        },
        created(){
            this.input = this.value;

            setTimeout(() => {
                this.$refs.input.focus();
            }, 30);
        },
        methods: {
            confirm(){
                if(this.select){
                    this.select(this.input);
                }

                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    @option_height: 44px;

    .options
    {
        width: 300px;
    }

    .options-title
    {
        font-size: 14px;
        color: @grey;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        text-align: center;

        &.description
        {
            line-height: 24px;
            padding-top: 15px;
            height: 39px;
        }
    }

    .options-description
    {
        font-size: 13px;
        color: @black;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        padding: 6px 15px 0;
    }

    .option
    {
        display: block;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        border-top: 1px solid @e;
        border-right: 1px solid @e;
        text-align: center;
        font-size: 16px;
        color: @base;
        transition: background-color ease 0.2s;
        cursor: pointer;
        user-select: none;

        &.grey
        {
            color: @grey;
        }

        &.red
        {
            color: @red;
        }

        &:hover, &:active
        {
            background: @f8;
        }

        &:last-child
        {
            border-right: none;
        }
    }

    .options-input
    {
        padding: 15px;
    }
</style>
