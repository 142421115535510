<template>
    <datatable class="enrichment-breakdown full" :columns="columns" :rows="rows" :header="true" />
</template>

<script>
    import { WhiteFlag, Link, DatabaseStats, CandlestickChart } from '@epiphany/iconoir';

    export default {
        name: 'EnrichmentBreakdown',
        data(){
            return {
                columns: [
                    {
                        id: 'type',
                        name: 'Type',
                        icon: WhiteFlag,
                        width: '95px',
                        value: row => row.type.slice(0, 1).toUpperCase() + row.type.slice(1)
                    },
                    {
                        id: 'join',
                        name: 'Join',
                        icon: Link,
                        width: '80px',
                        value: row => row.output.properties.join
                    },
                    {
                        id: 'primary',
                        name: 'Primary Values',
                        icon: DatabaseStats,
                        value: row => `${row.output.primary.count} total, ${row.output.primary.unique} unique, ${row.output.primary.invalid} invalid`
                    },
                    {
                        id: 'secondary',
                        name: 'Secondary Values',
                        icon: DatabaseStats,
                        value: row => `${row.output.secondary.count} total, ${row.output.secondary.unique} unique, ${row.output.secondary.invalid} invalid`
                    },
                    {
                        id: 'matches',
                        name: 'Match Statistics',
                        icon: CandlestickChart,
                        value: row => `${row.output.matches.count} matches ( +${row.output.matches.inserted}, -${row.output.matches.deleted} )`
                    },
                ]
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            enrichment() {
                return this.$store.state.drawer.props;
            },
            rows(){
                const links = _.get(this.enrichment, 'output.enrich.links');

                if(links){
                    return _.flatten(Object.values(links).map(link => {
                        return Object.entries(link).map(([type, output]) => {
                            return {
                                type,
                                output
                            };
                        });
                    }));
                }

                return [];
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }
    
    nav
    {
        border-bottom: 1px solid @e4;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }

    .enrichment-details
    {
        h2
        {
            color: @black;
            font-size: 16px;
            padding-top: 20px;
            border-top: 1px solid @e4;
            margin-top: 20px;
            margin-bottom: 20px;

            &:first-child
            {
                margin-top: 0;
                border-top: 0;
                padding-top: 0;
            }
        }

        .summary
        {
            font-size: 13px;

            .summary-field
            {
                height: 22px;
                margin-bottom: 10px;

                &:last-child
                {
                    margin-bottom: 0;
                }
            }

            .summary-key
            {
                color: @grey;
                width: 160px;
                flex-basis: 160px;
                flex-shrink: 0;
            }

            .summary-value
            {
                color: @black;
                line-height: 22px;

                &.monospace
                {
                    line-height: 22px;
                    font-size: 13px;
                }

                .icon-status
                {
                    margin-right: 6px;
                }

                .text-button
                {
                    & .active
                    {
                        color: @base;
                        border-color: @base;
                    }

                }
            }
        }
    }
</style>
