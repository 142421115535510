const state = {
    dismissed: [],
    all: [
        // {
        //     id: 'changelog-28-aug-2023',
        //     theme: 'base',
        //     type: 'changelog',
        //     height: 48,
        //     description: `Bug Fixes & Quality of Life Improvements`,
        //     icon: 'iconoir-arrow-up-circled',
        //     visibility: {
        //         start: '2023-08-28T12:30:00.000Z'
        //     },
        //     buttons: [
        //         {
        //             text: 'Read Full Update',
        //             icon: 'iconoir-antenna-signal',
        //             props: {
        //                 href: 'https://ed.link/community/bugfixes-quality-of-life-improvements/',
        //                 target: '_blank',
        //                 class: 'button white has-icon'
        //             }
        //         }
        //     ]
        // }
        {
            id: 'bts-2024',
            theme: 'base',
            type: 'changelog',
            height: 48,
            description: `Get a detailed look at our back to school 2024 roadmap`,
            icon: 'iconoir-arrow-up-circled',
            visibility: {
                // start: '2023-08-28T12:30:00.000Z'
            },
            buttons: [
                {
                    text: 'Read More',
                    icon: 'iconoir-antenna-signal',
                    props: {
                        href: 'https://ed.link/community/back-to-school-2024-roadmap/',
                        target: '_blank',
                        class: 'button white has-icon'
                    }
                }
            ]
        }
    ]
};

const mutations = {
    dismissed(state, dismissed) {
        state.dismissed = dismissed;
    },
    dismiss(state, id) {
        if (!state.dismissed.includes(id)) {
            state.dismissed.push(id);
        }
    },
    add(state, banner) {
        state.all.push(banner);
    },
    replace(state, banner) {
        const index = state.all.findIndex((b) => b.id === banner.id);

        if (index !== -1) {
            state.all.splice(index, 1, banner);
        }
    }
};

const actions = {
    initialize({ commit, dispatch }) {
        try {
            const ls = localStorage.getItem('edlink-dismissed-banners');
            const dismissed = JSON.parse(ls);

            if (Array.isArray(dismissed)) {
                commit('dismissed', dismissed);
            } else {
                dispatch('reset');
            }
        } catch (error) {
            // Invalid LS state, just reset it.
            dispatch('reset');
        }
    },
    dismiss({ commit, state }, id) {
        const banner = state.all.find((b) => b.id === id);

        if (banner) {
            if (banner.type === 'system') {
                return;
            }

            commit('dismiss', id);

            // Save the new state to local storage.
            localStorage.setItem('edlink-dismissed-banners', JSON.stringify(state.dismissed));
        }
    },
    show({ commit, state }, banner) {
        const existing = state.all.find((b) => b.id === id);

        if (existing) {
            commit('replace', banner);
        } else {
            commit('add', banner);
        }
    },
    reset() {
        localStorage.setItem('edlink-dismissed-banners', JSON.stringify([]));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
