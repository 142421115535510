<template>
    <div class="team">
        <!-- To future us: There used to be a :key="team.id" value here to force the component to re-render when the team changed.
        I'm not sure why this was and it was causing unintended behavior so I removed it. If you're reading this and
        wondering what that behavior was, it was causing the router-view component to go through another full lifecycle
        before the user was finally redirected to the dashboard / sources page for their new team. -->
        <router-view />
    </div>
</template>

<script>
    import SwitchTeam from '@/components/modals/SwitchTeam.vue';
    import AccountSettings from '@/components/modals/AccountSettings.vue';

    export default {
        name: 'Team',
        data(){
            return {

            };
        },
        methods: {
            change(){
                this.$modal.show(SwitchTeam, {redirect: true}, {width: 320, height: 'auto', classes: 'modal', scrollable: true});
            },
            account(){
                this.$modal.show(AccountSettings, {}, {width: 700, height: 'auto', classes: 'modal', scrollable: true});
            }
        },
        computed: {
            changes(){
                return this.$store.getters.banners.find(banner => banner.type === 'changelog');
            },
            guide(){
                return this.$store.state.guide.expanded;
            },
            team(){
                return this.$store.getters.team;
            },
            verified(){
                return this.$store.state.user ? this.$store.state.user.email_verified : false;
            },
            user(){
                return this.$store.state.user;
            },
            name(){
                if(this.user.first_name){
                    return this.user.last_name ? this.user.first_name + ' ' + this.user.last_name : this.user.first_name;
                }else if(this.user.last_name){
                    return this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.last_name;
                }else{
                    return 'Anonymous User';
                }
            },
            invitations(){
                return this.user.invitations;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
