<template>
    <div class="create-application">
        <header class="drawer-header flex flex-align">
            <div class="ff drawer-title flex flex-align">
                <Svg3dSelectSolid class="icon" width="20" height="20" stroke-width="2" />
                Create Application
            </div>
            <div class="button white" @click="$store.dispatch('drawer/close')">Close</div>
        </header>
        <div class="drawer-content drawer-scroll">
            <form @submit.prevent="create">
                <div class="form-field">
                    <label>Name</label>
                    <input type="text" v-model="application.name" />
                </div>
                <div class="form-field">
                    <label>Description</label>
                    <textarea type="text" v-model="application.description"></textarea>
                </div>
                <div class="form-field">
                    <label>Valid Redirect URIs</label>
                    <div class="form-helptext">A list of valid URIs to redirect to after receiving an oAuth 2.0 token request (one per line).</div>
                    <textarea type="text" v-model="application.origin"></textarea>
                </div>
                <button class="create-application-button" type="submit">Create Application</button>
            </form>
        </div>
    </div>
</template>

<script>
    import CreateSampleIntegration from '@/components/drawers/CreateSampleIntegration';
    import { Svg3dSelectSolid } from '@epiphany/iconoir';

    export default {
        name: 'CreateApplication',
        components: {
            Svg3dSelectSolid
        },
        data() {
            return {
                application: {
                    type: 'consumer'
                }
            };
        },
        computed: {
            team() {
                return this.$store.getters.team;
            }
        },
        methods: {
            select(type) {
                this.application.type = type;
            },
            async create() {
                if (!this.application.name){
                    // TODO: probalby should use a UI error pattern
                    // Example: https://material.io/archive/guidelines/patterns/errors.html#
                    return this.$toasted.error('You must enter an application name.'); 
                }
                let response;
                try {
                    response = await this.$http.post(`/teams/${this.$store.getters.team.id}/applications`, this.application);
                } catch (error) {
                    return this.$toasted.error('There was an error creating your new application.');
                }
                this.$router.push({ name: 'team.applications.application', params: { application: response.$data.id } });
                this.$store.dispatch('drawer/loading');
                this.$store.dispatch('drawer/replace', {
                    key: 'create-sample-integration',
                    component: CreateSampleIntegration,
                    width: 450,
                    props: {
                        application: response.$data
                    }
                });
                await this.$store.dispatch('applications/activate', { application: response.$data.id, team: this.team.id })
                this.$store.dispatch('drawer/ready');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-application-button
    {
        margin-top: 30px;
    }

    .create-application-instructions
    {
        padding-top: 30px;
        width: 400px;
        min-height: 100px;

        h4
        {
            font-size: 18px;
            font-weight: 500;
        }

        p
        {
            line-height: 20px;
            margin: 20px 0;
            font-size: 15px;
        }

        h4+p
        {
            margin-top: 10px;
        }

        a
        {
            padding-right: 24px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -2px top 1px;
            background-size: 19px auto;
        }
    }

    .create-application
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;
        }
    }

    .drawer-scroll.drawer-content
    {
        top: 51px;
        overflow: auto;
    }
</style>
