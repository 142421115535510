<template>
    <div class="pages flex flex-align">
        <div class="page">
            <template v-if="all.length">
                Results <span>{{page * count + 1}}</span> &ndash; <span>{{page * count + rows.length}}</span> of
                <template v-if="total">
                    <span>{{total | commas}}</span>
                </template>
                <template v-else>
                    <span v-if="more">Many</span>
                    <span v-else>{{all.length | commas}}</span>
                </template>
            </template>
            <template v-else>
                No Results
            </template>
        </div>
        <a class="arrow previous flex flex-align flex-center" @click="$emit('previous')" :class="{ active: previous }">
            <span class="icon iconoir-nav-arrow-left"></span>
        </a>
        <a class="arrow next flex flex-align flex-center" @click="$emit('next')" :class="{ active: next }">
            <span class="icon iconoir-nav-arrow-right"></span>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'Pages',
        props: {
            all: Array,
            page: Number,
            count: Number,
            total: Number,
            more: Boolean,
            rows: Array
        },
        computed: {
            next(){
                return (this.page + 1) * this.count < this.all.length || this.more;
            },
            previous(){
                return this.page > 0;
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        padding: 10px 0 20px;

        .filter
        {
            margin-left: 10px;

            &:first-child
            {
                margin-left: 0;
            }
        }
    }
</style>
