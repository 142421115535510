<template>
    <div class="integrate-source-provider-schoology">
        <integrate-source-editor-step name="Enter Your Illuminate URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers go to sign into Illuminate.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.illuminateed.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Generate API Keys" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    <ol>
                        <li>Log into your Illuminate account.</li>
                        <li>Navigate to <strong>Admin > User Management</strong>.</li>
                        <li>Create a user named <strong>Edlink</strong>.</li>
                        <li>Navigate to <strong>Admin > Other > API Management</strong>.</li>
                        <li>Click <strong>Add New Access Key</strong>, select accessor type `user` and select the new Edlink account you just created.</li>
                        <li>Navigate to <strong>Manage API Consumers</strong> and find Edlink on the list. Here you should find the `Client ID` and `Client Secret` that you'll need below.</li>
                    </ol>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <div class="helptext">
                Enter the API keys you generated in the previous step.
            </div>
            <label class="key-label">Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_id', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_secret', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderIlluminate',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url || !this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Confirm remote configuration is completed.
                if(!this.remote){
                    return ['url'];
                }

                // 3. Confirm that keys are set
                if(!this.source.configuration.client_id?.trim().length || !this.source.configuration.client_secret?.trim().length){
                    return ['url', 'remote'];
                }

                return ['url', 'remote', 'keys'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }

    ol
    {
        list-style: decimal;
        margin-left: 15px;
        margin-top: 15px;

        li
        {
            margin-bottom: 10px;
            line-height: 18px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }
</style>
