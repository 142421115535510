<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <Coins class="icon" width="16" height="16" stroke-width="2" />
                    Billing
                </div>
            </template>
        </breadcrumbs>
        <div class="billing">
            <div class="usage">
                <graph :configuration="usage" />
            </div>
            <div class="history flex">
                <div class="invoices">
                    <h2>Invoices</h2>
                    <datatable class="card" :columns="columns" :rows="invoices" :header="true" :clickable="true" @clicked="invoice => details(invoice)" :min-height="invoices.length ? 'auto' : '150px'">
                        <template v-slot:empty-message>Your team has no invoices.</template>
                    </datatable>
                </div>
                <div class="subscriptions ff">
                    <h2>Contract Plan</h2>
                    <template v-if="primary.length">
                        <div class="plan card pointer" v-for="subscription of primary" :key="subscription.id" @click="plan(subscription)">
                            <div class="plan-state">
                                <SubscriptionState :state="subscription.state" :start_date="subscription.start_date" :end_date="subscription.end_date" />
                            </div>
                            <div class="flex plan-description">
                                <div class="plan-icon flex flex-align flex-center">
                                    <Coins class="icon" width="24" height="24" stroke-width="1.5" />
                                </div>
                                <div class="plan-details flex flex-column ff flex-center">
                                    <h3 class="text-overflow">{{ subscription.plan.name }}</h3>
                                    <h4>{{ subscription.plan.description ? subscription.plan.description : 'No plan description' }}</h4>
                                </div>
                            </div>
                            <div class="secondaries" v-if="secondaries(subscription).length">
                                <div class="secondary flex flex-align" v-for="secondary of secondaries(subscription)" :key="secondary.id">
                                    <SendDollars class="icon" width="16" height="16" stroke-width="2" />
                                    <div class="secondary-name">Billing Includes {{ secondary.team.name }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="nothing">
                        Your team is not subscribed to a plan.
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import * as d3 from 'd3';
    import CreditCard from '@/components/modals/CreditCard.vue';
    import { Coins, Bank, SendDollars } from '@epiphany/iconoir';
    import SubscriptionState from '@/components/chips/SubscriptionState.vue';

    const now = new Date();

    // Time bounds for our graph.
    const first_day_of_month = new Date(now.getFullYear(), now.getMonth(), 1);
    const last_day_of_month = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    export default {
        name: 'Billing',
        components: {
            Coins,
            Bank,
            SendDollars,
            SubscriptionState
        },
        created(){
            this.$http.get(`/teams/${this.$store.getters.team.id}/invoices?$last=1000`, { baseURL: '/api/v2' })
            .then(response => this.invoices = response.$data)
            .catch(() => this.$toasted.error(`There was an error loading your invoices.`))
            .finally(() => this.loading.invoices = false);

            this.$http.get(`/teams/${this.$store.getters.team.id}/subscriptions?$last=1000`, { baseURL: '/api/v2' })
            .then(response => this.subscriptions = response.$data)
            .catch(() => this.$toasted.error(`There was an error loading your subscriptions.`))
            .finally(() => this.loading.subscriptions = false);
        },
        computed: {
            primary(){
                // Returns the primary subscriptions. Secondary subscriptions are owned by child teams and will be grouped under the primary.
                return this.subscriptions.filter(subscription => subscription.billing_team_id === subscription.team_id).sort((a, b) => {
                    return new Date(b.start_date) - new Date(a.start_date);
                });
            }
        },
        data(){
            return {
                loading: {
                    invoices: true,
                    subscriptions: true
                },
                methods: [],
                invoices: [],
                subscriptions: [],
                usage: {
                    height: 300,
                    description: 'The total number people shared across your production integrations',
                    ticks: d3.timeDay.every(1),
                    granularity: d3.timeDay,
                    start: first_day_of_month,
                    end: last_day_of_month,
                    timestamp: {
                        visible: 'always',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'sometimes'
                    },
                    series: [{
                        title: 'Shared People',
                        id: 'requests-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'sum',
                        data: []
                    },
                    {
                        title: 'Previous Period',
                        id: 'previous-requests-total',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                columns: [
                {
                        id: 'number',
                        name: 'Invoice Number',
                        icon: Bank,
                        width: '20%',
                        value: row => row.number,
                        classes: 'monospace'
                    },
                    {
                        id: 'total',
                        name: 'Amount',
                        icon: Bank,
                        width: '20%',
                        value: row => '$' + this.$options.filters.commas(row.total, false, 2),
                        classes: 'monospace'
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: Bank,
                        width: '20%',
                        value: row => row.state,
                        classes: 'capitalize'
                    },
                    {
                        id: 'invoice_period',
                        name: 'Billing Period',
                        icon: Bank,
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.period_start_date, 'date') + ' to ' + this.$options.filters.pretty(row.period_end_date, 'date')
                    },
                    {
                        id: 'due_date',
                        name: 'Due Date',
                        icon: Bank,
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.due_date, 'date')
                    }//,
                    // {
                    //     id: 'plan_id',
                    //     name: 'Plan',
                    //     icon: Bank,
                    //     width: '20%',
                    //     value: row => row.plan.name
                    // }
                ]
            };
        },
        methods: {
            add(){
                this.$modal.show(CreditCard, {
                    number: '',
                    name: '',
                    month: '',
                    year: '',
                    cvv: ''
                }, {width: 500, height: 'auto', classes: ['credit-card-modal']});
            },
            details(){
                console.log('Invoice Details');
            },
            plan(){
                console.log('Plan Details');
            },
            secondaries(primary){
                // Returns the secondary subscriptions. Secondary subscriptions are owned by child teams and will be grouped under the primary.
                return this.subscriptions.filter(subscription => {
                    return subscription.plan_id === primary.plan_id && subscription.billing_team_id === primary.team_id && subscription.team_id !== primary.team_id;
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2 {
        color: @black;
        font-size: 16px;
        margin-bottom: @double-padding - 2px;
    }

    .billing
    {
        top: @breadcrumbs-height;
    }

    .usage
    {
        height: 300px;
        padding: @double-padding;

        .graph
        {
            height: 100%;
        }
    }

    .history
    {
        padding: @double-padding;
        border-top: 1px solid @e4;
    }

    .invoices
    {
        flex: 3;
    }

    .subscriptions
    {
        min-width: 300px;
        min-height: 200px;
        margin-left: @double-padding;
    }

    .plan
    {
        margin-top: @single-padding;

        &:hover, &:active
        {
            background: @f8;
        }

        .plan-icon
        {
            background: @black;
            color: @base;
            border-radius: @border-radius - 2px;
            width: 44px;
            height: 44px;
        }

        .plan-state
        {
            border-bottom: 1px solid @e4;
            padding: 0 @single-padding;
            line-height: 30px;
        }

        .plan-description
        {
            padding: @single-padding;
        }

        .plan-details
        {
            margin-left: @single-padding;

            h3
            {
                font-size: 16px;
                color: @black;
                margin-bottom: 4px;
                line-height: 18px;
            }

            h4
            {
                font-size: 13px;
                color: @grey;
                line-height: 16px;
                margin-top: 0;
                font-weight: 400;
            }
        }

        .secondaries
        {
            border-top: 1px solid @e4;
            padding: @single-padding;
        }

        .secondary
        {
            margin-top: 6px;
            color: @grey;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;

            .icon
            {
                margin-right: 6px;
            }

            &:first-child
            {
                margin-top: 0;
            }
        }
    }
</style>