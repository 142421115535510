<template>
    <div class="application-flags full scroll" v-if="application">
        <section>
            <h2>Feature Flags</h2>
            <div class="application-configuration">
                <div class="form-field">
                    <label>SSO Error Behavior</label>
                    <div class="form-helptext">
                        If a user encounters an error during the SSO process, by default they will land on an Edlink error page.
                        When this setting is selected, they will instead be redirected to your specified redirect_uri with an error code.
                    </div>
                    <div class="flex">
                        <checkbox label="Redirect on Error" :checked="redirect_on_error_behavior" @input="redirect_on_error"/>
                    </div>
                </div>
                <div class="form-field">
                    <label>Missing Assignee Behavior</label>
                    <div class="form-helptext">
                        If you send a request with an assignee that cannot be resolved Edlink will, by default, return an error.
                        This setting allows you to change that behavior to instead return a warning.
                    </div>
                    <div class="flex">
                        <checkbox label="Return Warning" :checked="unresolved_uuid_behavior" @input="update_unresolved_uuid"/>
                    </div>
                </div>
                <div class="form-field">
                    <label>Disable SSO Cookies</label>
                    <div class="form-helptext">
                        By default, Edlink will attempt to remember a user that signs in via SSO using a cookie.
                        If you do not want Edlink to set any cookies, you can disable this behavior.
                    </div>
                    <div class="flex">
                        <checkbox label="Disable Cookies" :checked="disable_sso_cookies" @input="update_disable_sso_cookies"/>
                    </div>
                </div>
                <div class="form-field">
                    <label>Auto-Calculate Display Date on Assignments</label>
                    <div class="form-helptext">
                        If you create an assigment without a display date, by default Edlink will attempt to use the current date as the display date.
                        This may be earlier than the class start date based on the session that it was assigned.
                        This setting will automatically use the earliest session date of a class for the display date when the display date is not passed.
                    </div>
                    <div class="flex">
                        <checkbox label="Auto-Calculate Assignment Display Date" :checked="auto_calculate_assignment_display_date" @input="update_auto_calculate_assignment_display_date"/>
                    </div>
                </div>
                <div v-for="feature_flag of permission_based_feature_flags" class="form-field" :key="feature_flag.id">
                    <label>{{ feature_flag.title }}</label>
                    <div class="form-helptext">{{ feature_flag.description }}</div>
                    <div class="flex">
                        <checkbox :label=feature_flag.label :checked="permissions.includes(feature_flag.id)" @input="(val) => update_flag_value(val, feature_flag.id)"/>
                    </div>
                </div>
                <div class="form-options flex">
                    <div class="button" @click="update">Save Configuration</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'ApplicationFlags',
        data(){
            return {
                permission_based_feature_flags: [
                    {
                        enum_name: 'SchoologyV2FixSubmissionType',
                        title: 'User API V2 - autofix submission type (Schoology)',
                        label: 'Enable autofix submission type',
                        description: 'Automatically correct submission requests which include an invalid submission type.',
                        id: 'e5de0a4c-a8b1-46f6-847c-564369e5e35a'
                    },
                    {
                        enum_name: 'SchoologyV2FillAssignmentSession',
                        title: 'User API V2 - autofill assignment session (Schoology)',
                        label: 'Enable autofill assignment session',
                        description: 'Automatically fill in a session value when it is missing from assignment creation requests.',
                        id: 'e7cc7557-b9cf-44b1-acf6-f11712d68060'
                    },
                    {
                        enum_name: 'SchoologyV2FillAssignmentCategory',
                        title: 'User API V2 - autofill assignment category (Schoology)',
                        label: 'Enable autofill assignment category',
                        description: 'Automatically fill in a category value when it is missing from assignment creation requests.',
                        id: '643f4f3d-06ac-4a14-8517-4e70e334ee33'
                    },
                    {
                        enum_name: 'SchoologyV1FillAssignmentGradingPeriod',
                        title: 'User API V1 - autofill assignment grading period (Schoology)',
                        label: 'Enable autofill assignment grading period',
                        description: 'Automatically fill in a grading period value when it is missing from assignment creation requests.',
                        id: '4f77e7f3-685d-4935-8d7a-ab5132352ff4'
                    },
                    {
                        enum_name: 'SchoologyV1FillAssignmentGradingCategory',
                        title: 'User API V1 - autofill assignment grading category (Schoology)',
                        label: 'Enable autofill assignment grading category',
                        description: 'Automatically fill in a category value when it is missing from assignment creation requests.',
                        id: 'd737b7c3-0660-478c-b759-0107633111aa'
                    },
                    {
                        enum_name: 'BrightspaceV2FixAssignmentTitle',
                        title: 'User API V2 - autofix assignment title (Brightspace)',
                        label: 'Enable autofix assignment title',
                        description: 'Automatically remove invalid characters from assignment titles that Brightspace does not allow.',
                        id: '41d8b0ed-46f2-4883-aedb-0b682416b3e0'
                    },
                    {
                        enum_name: 'BrightspaceV1FixAssignmentTitle',
                        title: 'User API V1 - autofix assignment title (Brightspace)',
                        label: 'Enable autofix assignment title',
                        description: 'Automatically remove invalid characters from assignment titles that Brightspace does not allow.',
                        id: '67e0cd2d-6d57-4c74-aee6-f5e60c70654b'
                    },
                    {
                        enum_name: 'VeracrossV2SetAssignmentCategory',
                        title: 'Graph API V2 - set assignment category (Veracross)',
                        label: 'Enable set assignment category',
                        description: 'Set the category of an assignment when it is created.',
                        id: 'e8af888d-01aa-43e0-a915-958f9fda1891'
                    },
                    {
                        enum_name: 'VeracrossV2SetAssignmentWeight',
                        title: 'Graph API V2 - set assignment weight (Veracross)',
                        label: 'Enable set assignment weight',
                        description: 'Set the weight of an assignment when it is created.',
                        id: '4c254767-0982-48aa-8d8d-da91109bcd21'
                    }
                ]
            };
        },
        methods: {
            update(){
                this.$store.dispatch('applications/update');
            },
            update_unresolved_uuid(value){
                this.unresolved_uuid_behavior = value;
            },
            update_disable_sso_cookies(value){
                this.disable_sso_cookies = value;
            },
            redirect_on_error(value){
                this.redirect_on_error_behavior = value;
            },
            update_auto_calculate_assignment_display_date(value){
                this.auto_calculate_assignment_display_date = value;
            },
            update_flag_value(enabled, id) {
                if(enabled && !this.permissions.includes(id)) {
                    this.permissions = [...this.permissions, id];
                } else if(!enabled) {
                    this.permissions = this.permissions.filter(i => i !== id);
                }
            }
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            permissions: {
                get() {
                    return this.application.permissions;
                },
                set(permissions) {
                    this.$store.commit('applications/update', { permissions });
                }
            },
            unresolved_uuid_behavior: {
                get(){
                    return this.application?.properties?.unresolved_uuid_behavior ?? false;
                },
                set(value){
                    this.$store.commit('applications/update', { 'properties.unresolved_uuid_behavior': value });
                }
            },
            disable_sso_cookies: {
                get(){
                    return this.application?.properties?.disable_sso_cookies ?? false;
                },
                set(value){
                    this.$store.commit('applications/update', { 'properties.disable_sso_cookies': value });
                }
            },
            auto_calculate_assignment_display_date: {
                get(){
                    return this.application?.properties?.auto_calculate_assignment_display_date ?? false;
                },
                set(value){
                    this.$store.commit('applications/update', { 'properties.auto_calculate_assignment_display_date': value });
                }
            },
            redirect_on_error_behavior: {
                get(){
                    return this.application?.properties?.redirect_on_error ?? false;
                },
                set(value){
                    this.$store.commit('applications/update', { 'properties.redirect_on_error': value });
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .form-helptext
    {
        max-width: 600px;
    }
</style>
