<template>
    <div class="custom-admin-error flex flex-column flex-align">
        <h1>Oops, sorry...we encountered an error</h1>
        <div class="helptext">
            <p>Something went wrong while trying to onboard your system. Please try again later.</p>
        </div>
        <div class="error-details">{{ state.context.error }}</div>
        <div class="options flex" key="email">
            <div class="button" @click="cancel">Close</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomAdminError',
        props: {
            state: Object,
            send: Function
        },
        created(){
            window.parent.postMessage({ type: 'onboarding.error', data: { error: this.state.context.error } }, '*');
        },
        methods: {
            cancel(){
                window.parent.postMessage({ type: 'onboarding.cancel' }, '*');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .custom-admin-error {
        padding: 0 100px;

        h1 {
            margin-bottom: 10px;
            text-align: center;
        }

        .options {
            margin-top: 20px;
        }
    }
    
    .helptext {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        text-align: center;
    }

    .error-details {
        font-size: 13px;
        color: @grey;
        background: @e;
        border-radius: 3px;
        font-weight: 400;
        padding: 0 4px;
        line-height: 20px;
    }
</style>
