<template>
    <div class="integrate-source-provider-blackboard">
        <integrate-source-editor-step name="Enter Your Blackboard URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers and student go to sign into Blackboard.
            </div>
            <input class="medium" type="text" placeholder="https://blackboard.example.edu" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Add the Edlink Application to Blackboard" icon="iconoir-download-square-outline" :complete="completed.includes('remote')">
            <div class="helptext">
                Add the Edlink client ID to your Blackboard instance by going to the Rest API Connections page and clicking the "Add Application" button.
            </div>
            <integrate-source-editor-configuration title="How to Configure Blackboard" icon="iconoir-info-empty">
                <label>Open Settings Page</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/webapps/api-gateway/integrations/create'">
                        Rest API Connections
                    </a>
                </div>
                <label>Application ID</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift monospace" v-clipboard="() => 'db1de065-8d3a-4641-9b2b-e3d8b5fbd92a'" v-clipboard:success="() => $toasted.info('Copied')">db1de065-8d3a-4641-9b2b-e3d8b5fbd92a</div>
                </div>
                <label>Learn User</label>
                <div class="configuration-value">Select Any User</div>
                <label>End User Access</label>
                <div class="configuration-value">Yes</div>
                <label>Authorized To Act As User</label>
                <div class="configuration-value">Yes</div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Connect Your Blackboard Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
            <div class="helptext">
                Sign into your Blackboard account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <token :write="destination" :configuration="source.configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderBlackboard',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Validate that application ID has been configured remotely.
                if(!this.remote){
                    return ['url'];
                }

                // 3. Validate that the token has been acquired.
                if(!this.source.token && !this.source.id){
                    return ['url', 'remote'];
                }

                return ['url', 'remote', 'token'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        methods: {
            
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }
</style>
