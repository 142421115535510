<template>
    <div class="entity-value entity-value-subject flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="max" />
            <!-- <input type="text" class="block editable-input" v-model="override" @keyup.enter="save" @keyup.esc="cancel" ref="input" /> -->
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value">
                <template v-if="array">
                    <template v-if="value.length">
                        <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{subjects[item]}}</div>
                    </template>
                    <div v-else class="empty">&mdash;</div>
                </template>
                <div v-else class="badge text-overflow">{{subjects[value]}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueSubjects',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                subjects: {
                    'CEDS.01': 'Language Arts',
                    'CEDS.02': 'Mathematics',
                    'CEDS.03': 'Life and Physical Sciences',
                    'CEDS.04': 'Social Sciences and History',
                    'CEDS.05': 'Visual and Performing Arts',
                    'CEDS.07': 'Religious Education and Theology',
                    'CEDS.08': 'Physical Health and Safety Education',
                    'CEDS.09': 'Military Science',
                    'CEDS.10': 'Information Technology',
                    'CEDS.11': 'Communication and Audio Visual Technology',
                    'CEDS.12': 'Business and Marketing',
                    'CEDS.13': 'Manufacturing',
                    'CEDS.14': 'Health Care Sciences',
                    'CEDS.15': 'Public, Protective, and Government Service',
                    'CEDS.16': 'Hospitality and Tourism',
                    'CEDS.17': 'Architecture and Construction',
                    'CEDS.18': 'Agriculture, Food, and Natural Resources',
                    'CEDS.19': 'Human Services',
                    'CEDS.20': 'Transportation, Distribution, and Logistics',
                    'CEDS.21': 'Engineering and Technology',
                    'CEDS.22': 'Miscellaneous',
                    'CEDS.23': 'Non Subject Specific',
                    'CEDS.24': 'World Languages',
                    'EL.01': 'Special Education',
                    'EL.02': 'Professional Development'
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return this.override ? this.override : this.original;
            },
            override: {
                get(){
                    return _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                },
                set(value){
                    this.$store.commit('entities/override', {property: this.property, value});
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                return Promise.resolve(Object.entries(this.subjects).filter(([code, subject]) => {
                    return value ? subject.toLowerCase().indexOf(value.toLowerCase()) > -1 : true;
                }))
                .then(subjects => subjects.map(([code]) => code).sort());
            },
            format(value){
                return this.subjects[value];
            },
            edit(){
                if(this.editable){
                    if(this.original && !this.override){
                        // Set the default override value to the current property value.
                        this.$store.commit('entities/override', {property: this.property, value: this.original});
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-subject
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
