import axios from 'axios';
import Vue from 'vue';

const state = {
    active: null,
    updating: false
};

const mutations = {
    activate(state, source) {
        state.active = source;
    },
    deactivate() {
        state.active = null;
    },
    entity(state, entity) {
        if (state.active) {
            Vue.set(state.active, 'entity', entity);
        }
    },
    update(state, updated) {
        const valid_fields = ['verified'];

        for (const field of valid_fields) {
            if (updated.hasOwnProperty(field)) {
                state.active[field] = updated[field];
            }
        }
    }
};

const actions = {
    activate({ commit }, { organization }) {
        return axios.get(`/organizations/${organization}`).then((response) => commit('activate', response.$data));
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit }, updates) {
        if (state.active) {
            return axios.put(`/organizations/${state.active.id}`, updates).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active organization.');
    },
    entity({ commit }, entity) {
        commit('entity', entity);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
