<template>
    <div class="preview-entity-person">
        person
    </div>
</template>

<script>
export default {
    name: 'PreviewEntityPerson',
    props: {
        entity: Object
    }
}
</script>

<style>

</style>