<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <Bolt class="icon" width="16" height="16" stroke-width="2" />
                    Applications
                </div>
            </template>
            <template v-slot:actions>
                <input class="filter search large" type="text" placeholder="Search by ID, Team Name, or Application Name" v-model="search" />
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="dates" :active="updated_date" @activate="switch_updated_date" label="Updated Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="dates" :active="created_date" @activate="switch_created_date" label="Created Date">
                <template v-slot:icon>
                    <CalendarPlus class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <div class="ff"></div>
        </filters>
        <datatable class="admin-applications full" :columns="columns" :rows="results" :selectable="true" :header="true" @clicked="application => open(application)" :clickable="true" :loading="loading" />
    </main>
</template>

<script>
    import moment from 'moment';
    import { Bolt, AlignLeft, WhiteFlag, Calendar, CloudSync, CalendarPlus, MacOsWindow } from '@epiphany/iconoir';

    export default {
        name: 'AdminApplications',
        components: {
            Bolt,
            AlignLeft,
            WhiteFlag,
            Calendar,
            CloudSync,
            CalendarPlus,
            MacOsWindow
        },
        data(){
            return {
                all: [],
                search: '',
                applications: [],
                status: null,
                loading: true,
                columns: [
                    {
                        id: 'name',
                        name: 'Application Name',
                        icon: AlignLeft,
                        width: '40%',
                        value: row => row.name
                    },
                    {
                        id: 'team',
                        name: 'Developer',
                        icon: WhiteFlag,
                        width: '40%',
                        value: row => row.team.name
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: CalendarPlus,
                        align: 'right',
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    }
                ],
                statuses: [
                    {
                        id: 'approved',
                        name: 'Approved',
                        icon: 'green'
                    },                    
                    {
                        id: 'released',
                        name: 'Released',
                        icon: 'green'
                    },                    
                    {
                        id: 'development',
                        name: 'Development',
                        icon: 'purple'
                    },
                    {
                        id: 'awaiting_approval',
                        name: 'Awaiting Approval',
                        icon: 'yellow'
                    },
                    {
                        id: 'sunsetting',
                        name: 'Sunsetting',
                        icon: 'yellow'
                    },
                    {
                        id: 'destroyed',
                        name: 'Destroyed',
                        icon: 'dark-red'
                    }
                ],
                updated_date: null,
                created_date: null,
                dates: [
                    {
                        id: 'last-hour',
                        name: 'Last Hour',
                        icon: 'iconoir-wristwatch'
                    },
                    {
                        id: 'last-day',
                        name: 'Last Day',
                        icon: 'iconoir-sun-light'
                    },
                    {
                        id: 'last-week',
                        name: 'Last Week',
                        icon: 'iconoir-send-mail'
                    },
                    {
                        id: 'last-month',
                        name: 'Last Month',
                        icon: 'iconoir-calendar'
                    },
                    {
                        id: 'last-year',
                        name: 'Last Year',
                        icon: 'iconoir-cinema-old'
                    }
                ]
            };
        },
        created(){
            //Reset the page number and filters.
            this.page = 0;
            this.all = [];
            this.params = {};
            this.active = null;

            this.filter = {
                visible: false,
                property: 'id',
                operator: 'equals',
                value: '',
                applied: false
            };

            if(this.$route.query.filter){
                try {
                    const query = JSON.parse(this.$route.query.filter);

                    this.filter.property = query.property;
                    this.filter.operator = query.operator;
                    this.filter.value = query.value;
                    this.filter.applied = true;
                }catch(error){
                    this.$toasted.error('Invalid filter.');
                }
            }
            
            this.load();
        },
        computed: {
            results(){
                const search_val = this.search?.toLowerCase();
                const reg_exp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                const is_uuid = reg_exp.test(search_val);

                return this.applications.filter(application => {                      
                    if(this.status){
                        if(this.status.id !== application.status){
                            return false;
                        }
                    }

                    if(this.search){
                        if(is_uuid){
                            if(application.id !== search_val && application.team.id !== search_val){
                                return false;
                            }
                        } else {        
                            const team_name = application.team.name?.toLowerCase();                            
                            const application_name = application.name?.toLowerCase();                    
                            if(!team_name.includes(search_val) && !application_name.includes(search_val)){
                                return false;
                            }
                        }
                    }

                    if(this.updated_date){                                 
                        const adjusted_time_updated = moment();                                                                                     
                        if(this.updated_date.id === 'last-hour'){                                                   
                            adjusted_time_updated.subtract(1, 'hour');                           
                        } else if(this.updated_date.id === 'last-day'){               
                            adjusted_time_updated.subtract(1, 'day');                          
                        } else if(this.updated_date.id === 'last-week'){              
                            adjusted_time_updated.subtract(1, 'week');                           
                        } else if(this.updated_date.id === 'last-month'){                   
                            adjusted_time_updated.subtract(1, 'month');                            
                        } else if(this.updated_date.id === 'last-year'){                            
                            adjusted_time_updated.subtract(1, 'year');
                        }                                               
                        if(moment(application.updated_date) < adjusted_time_updated){                        
                            return false;
                        }                                            
                    }

                    if(this.created_date){       
                        const adjusted_time_created = moment();              
                        if(this.created_date.id === 'last-hour'){
                            adjusted_time_created.subtract(1, 'hour');
                        } else if(this.created_date.id === 'last-day'){                        
                            adjusted_time_created.subtract(1, 'day');
                        } else if(this.created_date.id === 'last-week'){
                            adjusted_time_created.subtract(1, 'week');
                        } else if(this.created_date.id === 'last-month'){
                            adjusted_time_created.subtract(1, 'month');
                        } else if(this.created_date.id === 'last-year'){
                            adjusted_time_created.subtract(1, 'year');
                        }                        
                        if(moment(application.created_date) < adjusted_time_created){
                            return false;
                        }                                            
                    }

                    return true;
                }).slice(0,100);
            }
        },
        methods: {
            open(application){
                this.$router.push({name: 'team.applications.application', params: {team: application.team.alias, application: application.id}});
            },
            switch_status(status){                
                this.status = status;               
            },           
            switch_updated_date(updated_date){                
                this.updated_date = updated_date;               
            },
            switch_created_date(created_date){                
                this.created_date = created_date;               
            },
            load(){
                const params = {
                    //the total (maximum) amount of integrations loaded                    
                    $first: 10000
                };               

                this.$http.get('/admin/applications', {params: params})
                .then(results => this.applications = results.$data)
                .catch(error => this.$toasted.error('There was an error loading results.'))
                .finally(() => this.loading = false);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }
    
    .admin-applications
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .tools
    {
        border-bottom: 0;
    }

    .verified
    {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: url('~@/assets/icons/white/check.svg') 1px 2px no-repeat @base;
        background-size: 10px auto;
        margin-left: 8px;
    }

    .application-type
    {
        width: 160px;
        margin-right: 15px;
    }

    .no-results
    {
        border: 0;
        padding: 50px;
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;
        &.red
        {
            background: @red;
        }
        &.blue
        {
            background: @base;
        }
        &.purple
        {
            background: @purple;
        }
        &.yellow
        {
            background: @yellow;
        }
        &.dark-red
        {
            background: @hover;
        }
        &.grey
        {
            background: @c;
        }
    }

    .filter-preset
    {
        border-color: #eee;
        color: @darkgrey;
        margin-left: 8px;

        &:first-of-type{
            margin-left: 10px;
        }
    }
</style>
