<template>
    <div class="application">
        <banners v-if="banners" />
        <div v-if="navigation" class="portal" :style="{top: offset + 'px'}">
            <navigation />
            <router-view class="content" :class="{expanded: guide}" />
            <guide v-if="guide" />
        </div>
        <router-view v-else class="full" :style="{top: offset + 'px'}" />
        <drawer />
        <command-palette v-if="user.admin" />
        <div class="save-container">
            <div class="save flex flex-align" :class="{active: saving, saving: actions.length, complete, contracted}">
                <spinner :classes="{complete, error}" />
                <div class="save-message ff" :class="{complete, contracted, error}">
                    <transition name="fade">
                        <span v-if="actions.length">Saving</span>
                        <span v-else-if="error">Error Saving</span>
                        <span v-else>All Changes Saved</span>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Application',
        created(){
            this.$store.dispatch('banners/initialize');
        },
        computed: {
            actions(){
                return this.$store.state.save.actions;
            },
            offset(){
                if (this.banners) {
                    return this.$store.getters.banners.reduce((total, banner) => {
                        return total + banner.height;
                    }, 0);
                } else {
                    return 0;
                }
            },
            saving(){
                return this.$store.state.save.visible;
            },
            error(){
                return this.$store.state.save.error;
            },
            guide(){
                return this.$store.state.guide.expanded;
            },
            contracted(){
                return this.$store.state.save.contracted;
            },
            complete(){
                return this.actions.length === 0 && !this.error;
            },
            user(){
                return this.$store.getters.user;
            },
            navigation(){
                if (['demo', 'custom.onboard'].some(it => this.$route.name?.startsWith(it))) {
                    return false;
                }
                
                if(['reconnect', 'integrate', 'login', 'login.token', 'register', 'forgot', 'onboard', 'integrate.onboarding'].includes(this.$route.name)){
                    return false;
                }

                // If no matched routes the router has not been initialized yet.
                // This prevents the sidebar from flashing briefly on the screen.
                if(this.$route.matched.length === 0){
                    return false;
                }

                return true;
            },
            banners(){
                if (['demo', 'custom.onboard'].some(it => this.$route.name?.startsWith(it))) {
                    return false;
                }

                if (['reconnect', 'integrate', 'login', 'login.token', 'onboard', 'integrate.onboarding'].includes(this.$route.name)) {
                    return false;
                }

                // If no matched routes the router has not finished initializing yet.
                // This prevents the banner from flashing briefly on the screen.
                if(this.$route.matched.length === 0){
                    return false;
                }

                return true;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/global";

    .application
    {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background: @f;
        z-index: 1;

        .portal
        {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            z-index: 1;

            .content
            {
                left: @navigation-width;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;

                &.expanded
                {
                    right: @guide-width;
                }
            }
        }
    }

    .save-container
    {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background: @e;
        z-index: 10000;

        .save
        {
            position: absolute;
            right: 30px;
            bottom: -50px;
            height: 32px;
            border-radius: 20px;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 10px -2px rgba(0, 0, 0, 0.15);
            font-size: 12px;
            font-weight: 500;
            color: @black;
            padding: 6px;
            background: @f;
            transition: all 0.2s ease;

            .save-message
            {
                padding-left: 6px;
                transition: all 0.2s ease;
                width: 50px;
                white-space: nowrap;
                text-overflow: clip;
                overflow: hidden;
                line-height: 14px;

                &.complete
                {
                    width: 120px;
                }

                &.error
                {
                    width: 85px;
                }

                &.complete.contracted, &.error.contracted
                {
                    width: 50px;
                }

                .fade-enter-active,
                .fade-leave-active
                {
                    transition: opacity 0.25s ease;
                }

                .fade-enter-from,
                .fade-leave-to
                {
                    opacity: 0;
                }
            }

            &.active
            {
                bottom: 30px;
            }        
        }
    }
</style>
