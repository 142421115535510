<template>
    <div class="create-subscription">
        <div class="flex flex-align drawer-header">
            <h2 class="ff drawer-title text-overflow">{{ drawer_title }}</h2>
            <!-- <div class="button white red delete" v-if="props.product" @click="delete_product">Delete Product</div> -->
            <div class="button base"  :class="{ loading: saving }" @click="save">
                <template v-if="saving">
                    <div class="spinner-container">
                        <spinner />
                    </div>
                </template>
                {{ drawer_button_text }}
            </div>
            <div class="button white base" @click="close">Close</div>
        </div>
        <div class="drawer-content drawer-scroll" :class="{ loading }">
            <div class="spinner-container" v-if="loading">
                <spinner />
            </div>
            <div class="drawer-section" v-else>
                <form autocomplete="off">
                    <label for="from">Billing Team</label>
                    <div class="form-helptext">The team who is billed for the contract.</div>
                    <search-select :disabled="!creating" :values.sync="subscription.billing_team_id" :search="team_source" :format="team_format" :array="false" :placeholder="'Billing Team...'" />

                    <label for="from">Contracted Team</label>
                    <div class="form-helptext">The team who recieves access to Edlink.</div>
                    <search-select :disabled="!creating" :values.sync="subscription.team_id" :search="team_source" :format="team_format" :array="false" :placeholder="'Contracted Team...'" />
                    
                    <label for="from">Plan</label>
                    <div class="form-helptext">The plan for this contract.</div>
                    <search-select :values.sync="subscription.plan_id" :search="plan_source" :format="plan_format" :array="false" :placeholder="'Plan...'" />

                    <label for="from">State</label>
                    <div class="form-helptext">The state of this contract in its lifecycle.</div>
                    <select class="block" v-model="subscription.state" name="subscription_state">
                        <option value="inactive">Inactive</option>
                        <option value="active">Active</option>
                        <option value="ended">Ended</option>
                        <option value="paused">Paused</option>
                        <option value="canceled">Canceled</option>
                    </select>
                    
                    <label for="from">Start Date</label>
                    <div class="form-helptext">The date that the contract starts.</div>
                    <date-picker v-model="subscription.start_date"
                        v-bind="{
                            type: 'date',
                            format: 'MM-DD-YYYY',
                            clearable: false,
                            placeholder: 'Start Date'
                        }">
                        <!-- <template v-slot:icon-calendar>
                            <span></span>
                        </template> -->
                    </date-picker>
                    
                    <label for="from">End Date</label>
                    <div class="form-helptext">The date that the contract ends.</div>
                    <div class="flex flex-row flex-align">
                        <date-picker class="flex flex-align" v-model="subscription.end_date"
                            v-bind="{
                                type: 'date',
                                format: 'MM-DD-YYYY',
                                clearable: false,
                                placeholder: 'End Date'
                            }">
                        </date-picker>
                        <checkbox :checked="subscription.end_date === null" @input="(v) => v === true ? subscription.end_date = null : null" label="No End Date">No End Date</checkbox>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import SearchSelect from '@/components/SearchSelect.vue';
    import { Trash } from '@epiphany/iconoir';

    export default {
        name: 'ContractDrawer',
        components: {
            'search-select': SearchSelect,
            Trash
        },
        data(){
            return {
                loading: true,
                saving: false,
                teams: {
                    all: [],
                    items: null,
                    promise: null,
                    query: ''
                },
                plans: {
                    all: [],
                    items: null,
                    promise: null,
                    query: ''
                },
                subscription: {
                    id: null,
                    state: 'active',
                    team_id: [],
                    billing_team_id: [],
                    plan_id: [],
                    start_date: null,
                    end_date: null,
                },
            };
        },
        async mounted() {
            if(this.props.subscription) {
                const raw =  _.cloneDeep(this.props.subscription);
                this.subscription = {
                    id: raw.id,
                    state: raw.state,
                    team_id: [raw.team_id],
                    billing_team_id: [raw.billing_team_id],
                    plan_id: [raw.plan_id],
                    start_date: raw.start_date ? new Date(raw.start_date) : null,
                    end_date: raw.end_date ? new Date(raw.end_date) : null,
                };

                await this.team_source();
                await this.plan_source();
            }

            this.loading = false;
        },
        methods: {
            save(){
                const new_subscription = _.cloneDeep(this.subscription);
                new_subscription.team_id = new_subscription.team_id[0];
                new_subscription.billing_team_id = new_subscription.billing_team_id[0];
                new_subscription.plan_id = new_subscription.plan_id[0];

                if (!new_subscription.team_id) {
                    this.$toasted.error('Please select a team to contract.');
                    return;
                } else if (!new_subscription.billing_team_id) {
                    this.$toasted.error('Please select a team to bill.');
                    return;
                } else if (!new_subscription.plan_id) {
                    this.$toasted.error('Please select a plan.');
                    return;
                } else if (!new_subscription.start_date) {
                    this.$toasted.error('Please select a start date.');
                    return;
                }

                this.saving = true;

                setTimeout(() => {
                    if(this.creating) {
                        this.$http.post(`/admin/billing/subscriptions`, new_subscription, { baseURL: '/api/v1' })
                            .then(_ => {
                                // Close the drawer.
                                this.close();
                                // Refresh the list of products
                                if (this.props.refreshParent) {
                                    this.props.refreshParent();
                                }
                                this.$toasted.success('Contract successfully created')
                            })
                            .catch(error => this.$toasted.error(error?.$error ? error.$error : 'There was an error creating your contract.'))
                            .finally(() => this.saving = false);
                    } else {
                        this.$http.patch(`/admin/billing/subscriptions/${this.subscription.id}`, new_subscription, { baseURL: '/api/v1' })
                            .then(_ => {
                                // Close the drawer.
                                this.close();
                                // Refresh the list of products
                                if (this.props.refreshParent) {
                                    this.props.refreshParent();
                                }
                                this.$toasted.success('Contract successfully updated')
                            })
                            .catch(error => this.$toasted.error(error?.$error ? error.$error : 'There was an error updating your contract.'))
                            .finally(() => this.saving = false);
                    }
                }, 500);
            },
            close(){
                this.$store.dispatch('drawer/close');
            },
            plan_source(query){
                this.plans.query = query;

                if(!this.plans.promise){
                    this.plans.promise = this.$http.get(`/admin/billing/plans`).then(response => {
                        this.plans.all = response.$data.map(plan => {
                            return {
                                id: plan.id,
                                name: plan.name,
                                picture: null
                            };
                        })
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
                
                return this.plans.promise.then(() => {
                    if(this.plans.query){
                        this.plans.items = this.plans.all.filter(plan => {
                            return plan.name.toLowerCase().indexOf(this.plans.query.toLowerCase()) > -1;
                        }).slice(0, 50);
                    }else{
                        this.plans.items = this.plans.all.slice(0, 50);
                    }

                    return this.plans.items.map(plan => plan.id);
                });
            },
            plan_format(id) {
                const plan = this.plans.all.find(plan => plan.id === id);
                return plan ? plan.name : 'Unknown Plan';
            },
            team_source(query){
                this.teams.query = query;

                if(!this.teams.promise){
                    this.teams.promise = this.$http.get(`/admin/teams`, {
                        params: {
                            $filter: {
                                type: [{
                                    operator: 'equals',
                                    value: 'developer'
                                }]
                            },
                            $first: 10000
                        }
                    }).then(response => {
                        this.teams.all = response.$data.map(team => {
                            return {
                                id: team.id,
                                name: `${team.name} ${team.verified ? '✓' : ''}`,
                                picture: null
                            };
                        })
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
                
                return this.teams.promise.then(() => {
                    if(this.teams.query){
                        this.teams.items = this.teams.all.filter(team => {
                            return team.name.toLowerCase().indexOf(this.teams.query.toLowerCase()) > -1;
                        }).slice(0, 50);
                    }else{
                        this.teams.items = this.teams.all.slice(0, 50);
                    }

                    return this.teams.items.map(team => team.id);
                });
            },
            team_format(id) {
                const team = this.teams.all.find(team => team.id === id);
                return team ? team.name : 'Unknown Team';
            }
        },
        computed: {
            creating(){
                return this.props.subscription === null || this.props.subscription === undefined;
            },
            team(){
                return this.$store.getters.team;
            },
            drawer_button_text() {
                if(this.creating) {
                    return 'Create Contract'
                } else {
                    return 'Save Contract'
                }
            },
            drawer_title() {
                if(this.creating) {
                    return 'Create a Contract'
                } else {
                    return 'Edit Contract'
                }
            },
            props(){
                return this.$store.state.drawer.props;
            },
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    .create-subscription
    {
        height: 100%;
    }

    .drawer-header {
        .button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    header
    {
        border-bottom: 1px solid @e4;

        .button
        {
            margin-left: 20px;
        }
    }

    .drawer-content
    {
        .spinner-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    .drawer-scroll
    {
        overflow: auto;

        .drawer-section:last-child
        {
            padding: @double-padding;

            .form-options
            {
                padding-bottom: 0;
            }
        }

        &.loading {
            height: 100%;
        }
    }

    .button {
        &.loading {
            pointer-events: none;
            color: transparent;
        }

        .spinner-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            ::v-deep .spinner {
                color: @f8;

                .rotation {
                    .path {
                        stroke: @f8;
                    }
                }
            }
        }
    }

    ::v-deep .autocomplete .autocomplete-search {
        border-radius: 6px;
    }
</style>
