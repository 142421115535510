<template>
    <div class="integrate-source-provider-oneroster">
        <integrate-source-editor-step name="Enter Your OIDC Configuration URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                The URL to your well-known JSON configuration.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://schools.example.com" :value="source.configuration.oidc_configuration_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oidc_configuration_url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <!-- <integrate-source-editor-step name="Generate OneRoster Keys" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                    click <a class="configuration-link inline-block text-button mini" target="_blank" href="https://ed.link/docs/schools/connect/oneroster">here</a>.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step> -->
        <integrate-source-editor-step name="Enter Your OAuth Keys" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <div class="helptext">
                Enter the OAuth keys for your OneLogin instance.
            </div>
            <label class="key-label">Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_id', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_secret', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderOneLogin',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(!this.source.configuration?.oidc_configuration_url?.match(url)){
                    return [];
                }

                // if(!this.remote){
                //     return ['url'];
                // }

                const properties = ['client_id', 'client_secret'];

                for(const property of properties){
                    if(!this.source.configuration?.[property]){
                        return ['url', 'remote'];
                    }
                }

                return ['url', 'remote', 'credentials'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
