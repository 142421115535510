<template>
    <div class="invoice flex flex-column">
        <header class="drawer-header flex flex-align">
            <div class="flex flex-align">
                <div class="invoice-icon flex flex-align flex-center">
                    <!-- <span class="icon block iconoir-page-flip"></span> -->
                    <ReceiveDollars class="icon" width="18" height="18" stroke-width="2" />
                </div>
                <h2 class="ff drawer-title text-overflow">{{ title }}</h2>
            </div>

            <div class="ff"></div>

            <template v-if="invoice">
                <div class="text-button pointer" style="margin-right: 8px;" v-if="invoice.xero_id" @click="openInXero">Open in Xero</div>
                <div class="option button mini" @click="downloadPDF">Download PDF</div>
            </template>

            <div class="text-button border" @click="close">Close</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{ active: tab === 'details' }" @click="changeTab('details')">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Details
            </div>
            <div class="pill" :class="{ active: tab === 'snapshots' }" @click="changeTab('snapshots')" v-if="admin">
                <GraphUp class="icon" width="16" height="16" stroke-width="2" />
                Usage
            </div>
            <div class="pill" :class="{ active: tab === 'notes' }" @click="changeTab('notes')" v-if="admin">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Notes
            </div>
            <!-- <div class="pill" :class="{active: tab === 'json'}" @click="changeTab('json')">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                Raw JSON
            </div> -->

            <div class="ff"></div>
            <template v-if="invoice && tab === 'details'">
                <div class="option button grey" v-if="invoice.state !== 'draft' && valid_states.includes('draft')" @click="setInvoiceState('draft')">Make Draft</div>
                <div class="option button red" v-if="invoice.state !== 'voided' && valid_states.includes('voided')" @click="setInvoiceState('voided')">Void</div>
                <div class="option" v-if="valid_states.length > 0">
                    <div class="button white" @click="() => state_dropdown = true">
                        Set State
                    </div>
                    <dropdown :open="state_dropdown" height="unset" width="135px" padding="4px" ref="set-state">
                        <dropdown-item v-for="state of valid_states" :key="state.id" :muted="true" @click.native="setInvoiceState(state.id)">
                            <template v-slot:icon>
                                <div class="status-icon" :class="{ [state.icon]: true }"></div>
                            </template>
                            <template v-slot:title>
                                {{ state.name }}
                            </template>
                        </dropdown-item>
                    </dropdown>
                </div>
                <div class="option button warning" @click="resyncInvoice" v-if="canResync">Force Update in Xero</div>
                <div class="option button" @click="syncInvoice" v-else-if="!invoice.xero_id">Send to Xero</div>
                <div class="option button red" @click="deleteInvoice" v-if="canDelete">Delete</div>
                <div class="option button red" @click="voidInvoice" v-else-if="canVoid">Void</div>
            </template>
            <template v-if="invoice && tab === 'snapshots'">
                <div class="option button base" :class="{ loading: !invoice.id }" @click="downloadCSV">Download Raw Data</div>
            </template>
        </nav>
        <KeepAlive>
            <div class="ff drawer-content flex flex-align flex-center" v-if="drawer_loading">
                <spinner />
            </div>
            <InvoiceDetails v-else-if="tab === 'details'" :invoice.sync="invoice" />

            <div v-else-if="tab === 'notes'" class="drawer-content drawer-scroll notes">
                <textarea class="textarea" placeholder="Notes..." v-model="invoice.properties.notes" rows="50"></textarea>
                <div class="actions flex flex-row flex-align">
                    <div class="button" @click="saveInvoice">Save</div>
                </div>
            </div>

            <InvoiceSnapshots v-else-if="tab === 'snapshots'" :invoice="invoice"  />
        </KeepAlive>
    </div>
</template>

<script>
    import Vue from 'vue';
    import * as d3 from 'd3';
    import Decimal from 'decimal.js';
    import InvoiceDetails from '@/components/drawers/invoice/InvoiceDetails.vue';
    import InvoiceSnapshots from '@/components/drawers/invoice/InvoiceSnapshots.vue';
    import InvoiceState from '@/components/chips/InvoiceState.vue';
    import Dropdown from '@/components/Dropdown.vue';
    import { BILLING_APPROVED_USERS } from '@/constants'
    
    import { InfoEmpty, AlignLeft, DatabaseExport, CodeBracketsSquare, ReceiveDollars, GraphUp } from '@epiphany/iconoir';
    import _ from 'lodash';

    export default {
        name: 'Invoice',
        components: {
            InfoEmpty,
            AlignLeft,
            DatabaseExport,
            CodeBracketsSquare,
            ReceiveDollars,
            GraphUp,
            InvoiceDetails,
            InvoiceSnapshots
        },
        data(){
            return {
                tab: 'details',
                drawer_loading: true,
                loading: true,
                integrations: {},
                integration_snapshots: {},
                invoice: null,
                state_dropdown: false,
                states: [
                    {
                        id: 'voided',
                        name: 'Voided',
                        icon: 'grey'
                    },
                    {
                        id: 'draft',
                        name: 'Draft',
                        icon: 'yellow'
                    },
                    {
                        id: 'submitted',
                        name: 'Submitted',
                        icon: 'blue'
                    },
                    {
                        id: 'sent',
                        name: 'Sent',
                        icon: 'blue'
                    },
                    {
                        id: 'paid',
                        name: 'Paid',
                        icon: 'green'
                    },
                    {
                        id: 'overdue',
                        name: 'Overdue',
                        icon: 'red'
                    },
                    {
                        id: 'refunded',
                        name: 'Refunded',
                        icon: 'purple'
                    }
                ],
                metadata_columns: [
                    {
                        id: 'property',
                        width: '15%',
                        value: row => row.property
                    },
                    {
                        id: 'value',
                        width: '85%',
                        value: row => row.value
                    }
                ]
            };
        },
        computed: {
            metadata() {
                return [
                    { property: 'Number', value: this.invoice.number },
                    { property: 'Issued Date', value: this.invoice.issued_date ? d3.utcFormat('%m/%d/%Y')(new Date(this.invoice.issued_date)) : 'N/A' },
                    { property: 'Due Date', value: this.invoice.due_date ? d3.utcFormat('%m/%d/%Y')(new Date(this.invoice.due_date)) : 'N/A' },
                    { property: 'To', value: this.invoice.team.name },
                    { property: 'Period', value: this.billing_period },
                    { property: 'Xero', value: this.invoice.xero_id ? 'Synced to Xero' : 'Not in Xero' }
                ]
            },
            title() {
                // if (this.invoice) {
                //     return `Invoice #${this.invoice.number}`;
                // }

                return 'Invoice';
            },
            modified() {
                if (_.has(this.invoice, 'overrides') && !_.isEmpty(this.invoice.overrides)) {
                    return true;
                } else {
                    return false;
                }
            },
            billing_period() {
                return `${d3.utcFormat('%m/%d/%Y')(new Date(this.invoice.period_start_date))} - ${d3.utcFormat('%m/%d/%Y')(new Date(this.invoice.period_end_date))}`
            },
            valid_states() {
                if (!this.invoice.xero_id) {
                    return this.states.filter(state => state.id !== this.invoice.state);
                } else {
                    if (this.invoice.state === 'voided') {
                        return [];
                    }

                    return this.states.filter(state => ['sent', 'submitted', 'paid', 'overdue', 'voided', 'refunded'].includes(state.id) && state.id !== this.invoice.state);
                }
            },
            canDelete() {
                if (!BILLING_APPROVED_USERS.includes(this.$store.getters.user.id)) {
                    return false;
                }

                if (!['draft', 'voided'].includes(this.invoice.state)) {
                    return false;
                }

                if (this.invoice.xero_id) {
                    return false;
                }

                return true;
            },
            canVoid() {
                if (!['draft', 'submitted'].includes(this.invoice.state)) {
                    return false;
                }

                return true;
            },
            canResync() {
                if (!BILLING_APPROVED_USERS.includes(this.$store.getters.user.id)) {
                    return false;
                }

                if (!this.invoice.xero_id) {
                    return false;
                }

                return true;
            },
            admin() {
                return this.$store.getters.user.admin;
            },
            props() {
                return this.$store.state.drawer.props;
            }
        },
        mounted() {
            window.addEventListener('mousedown', this.click_outside);
            window.addEventListener('keydown', this.escape);
        },
        destroyed() {
            window.removeEventListener('mousedown', this.click_outside);
            window.removeEventListener('keydown', this.escape);
        },
        methods: {
            close() {
                this.$store.dispatch('drawer/close');

                if (window.location.hash === `#invoices/${this.props.invoice_id}`) {
                    // clear window hash if present
                    window.location.hash = '';
                }
            },
            openInXero() {
                window.open(`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${this.invoice.xero_id}`, '_blank');
                // this.$http.get(`/admin/billing/invoices/${this.invoice.id}/xero`)
                //     .then(response => {
                //         window.open(response.$data.url, '_blank');
                //     })
                //     .catch(error => this.$toasted.error('There was an error opening this invoice in Xero.'));
            },
            downloadPDF(){
                this.$store.dispatch('save/save', 'invoice_pdf');
                this.$http.get(`/admin/billing/invoices/${this.invoice.id}/pdf`, { 
                    responseType: 'blob'
                }).then(response => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(response);
                    link.download = `invoice-${this.invoice.number}.pdf`;
                    link.click();
                    link.remove();
                    this.$store.dispatch('save/saved', 'invoice_pdf');
                }).catch(error => this.$store.dispatch('save/error', 'invoice_pdf'));
            },
            changeTab(tab) {
                Vue.set(this, 'tab', tab);
            },
            click_outside(e) {
                if (this.state_dropdown) {
                if (this.$refs['set-state'] && !this.$refs['set-state'].$el.contains(e.target)) {
                        this.state_dropdown = false;
                    }
                }
            },
            escape(e) {
                if (e.key === 'Escape') {
                    this.state_dropdown = false;
                }
            },
            load() {
                this.$http.get(`/admin/billing/invoices/${this.props.invoice_id}`).then(response => {
                    Vue.set(this, 'invoice', response.$data);

                    this.drawer_loading = false;
                    this.loading = false;
                }).catch(error => {
                    console.log(error);
                    this.$toasted.error('There was an error loading this invoice.');
                    // this.loading = false;
                });
            },
            setInvoiceState(state){
                this.$store.dispatch('save/save', 'invoice_state');
                this.$http.patch(`/admin/billing/invoices/${this.invoice.id}`, { state })
                    .then(response => {
                        Vue.set(this.invoice, 'state', response.$data.state);
                        this.$store.dispatch('save/saved', 'invoice_state');
                    })
                    .catch(error => {
                        if (error?.$error === 'Invalid Invoice state transition') {
                            // Notify user of error if its a known error
                            this.$toasted.error(`Invalid Invoice state transition. Invoices in Xero must follow Xero's lifecycle.`);
                        } else if (_.isString(error?.$error)) {
                            this.$toasted.error(error?.$error);
                        } else {
                            this.$toasted.error('There was an error setting the invoice state.');
                        }
                        // Notify save module of error
                        this.$store.dispatch('save/error', 'invoice_state')
                    });
            },
            saveInvoice() {
                this.$store.dispatch('save/save', 'invoice');
                this.editing = false;

                let updates = _.cloneDeep(_.omit(this.invoice, ['snapshot_ids']));

                if (updates?.overrides?.line_items) {
                    for (const item of this.invoice.overrides.line_items) {
                        item.quantity = new Decimal(item.quantity.toString().replace(/,/g, '')).toDecimalPlaces(4).toNumber();
                        item.unit_cost = new Decimal(item.unit_cost.toString().replace(/,/g, '')).toDecimalPlaces(4).toNumber();
                    }
                }

                if (!BILLING_APPROVED_USERS.includes(this.$store.getters.user.id)) {
                    if (updates.state === 'draft') {
                        updates = _.pick(updates, ['state', 'overrides', 'properties']);
                    } else {
                        updates = _.pick(updates, ['state', 'properties']);
                    }
                }

                this.$http.patch(`/admin/billing/invoices/${this.invoice.id}`, updates)
                    .then(response => {
                        response.$data.team = this.invoice.team;
                        Vue.set(this, 'invoice', response.$data);
                        this.$store.dispatch('save/saved', 'invoice');
                    })
                    .catch(error => {
                        if (_.isString(error.$error)) {
                            this.$toasted.error(error.$error);
                        } else {
                            this.$toasted.error('There was an error saving this invoice.')
                        }
                        this.$store.dispatch('save/error', 'invoice');
                    });
            },
            syncInvoice(){
                this.$store.dispatch('save/save', 'invoice_sync');
                this.$http.post(`/admin/billing/invoices/${this.invoice.id}/xero`, {})
                    .then(response => {
                        Vue.set(this.invoice, 'xero_id', response.$data.xero_id);
                        this.$store.dispatch('save/saved', 'invoice_sync');
                    })
                    .catch(error => {
                        if (error?.$error) {
                            this.$toasted.error(error?.$error);
                        }

                        this.$store.dispatch('save/error', 'invoice_sync')
                    });
            },
            resyncInvoice(){
                this.$store.dispatch('save/save', 'invoice_resync');
                this.$http.patch(`/admin/billing/invoices/${this.invoice.id}/xero`, {})
                    .then(response => {
                        this.$store.dispatch('save/saved', 'invoice_resync');
                    })
                    .catch(error => this.$store.dispatch('save/error', 'invoice_resync'));
            },
            deleteInvoice() {
                this.$store.dispatch('save/save', 'invoice_delete');
                this.$http.delete(`/admin/billing/invoices/${this.invoice.id}`)
                    .then(response => {
                        this.$store.dispatch('save/saved', 'invoice_delete');
                        this.$router.push({ name: 'admin.billing.invoices' });
                    })
                    .catch(error => {
                        // Notify save module of error
                        this.$store.dispatch('save/error', 'invoice_delete');
                        // Display error to user
                        if (_.isString(error.$error)) {
                            this.$toasted.error(error.$error);
                        } else {
                            this.$toasted.error('There was an error saving this invoice.')
                        }
                    });
            },
            voidInvoice() {
                this.$store.dispatch('save/save', 'invoice_void');
                this.$http.patch(`/admin/billing/invoices/${this.invoice.id}`, { state: 'voided' })
                    .then(response => {
                        Vue.set(this.invoice, 'state', response.$data.state);
                        this.$store.dispatch('save/saved', 'invoice_void');
                    })
                    .catch(error => {
                        // Notify save module of error
                        this.$store.dispatch('save/error', 'invoice_delete');
                        // Display error to user
                        if (_.isString(error.$error)) {
                            this.$toasted.error(error.$error);
                        } else {
                            this.$toasted.error('There was an error saving this invoice.')
                        }
                    });
            },
            // =================== Snapshots Functions ===================
            savecsv(data, filename, ext){
                const blob = new Blob([data], { type: ext })
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                document.body.appendChild(a);
                a.href = url;
                a.download = filename;
                a.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 0);
            },
            downloadCSV(){
                this.$http.get(`/admin/billing/invoices/${this.invoice.id}/xlsx`, { responseType: 'blob' })
                    .then(response => {
                        // Format dates for file name
                        const date_format = d3.utcFormat('%m-%d-%Y');
                        const period_start_text = date_format(new Date(this.invoice.period_start_date));
                        const period_end_text = date_format(new Date(this.invoice.period_end_date));

                        this.savecsv(response, `${this.invoice.team.name} Invoice #${this.invoice.number} (${period_start_text} to ${period_end_text}) snapshots.xlsx`, response.type);
                    })
                    .catch(error => this.$toasted.error('There was an error downloading the CSV.'));
            },
        },
        created(){
            this.load();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .drawer-container
    {
        .drawer-header
        {
            // padding-bottom: 0px;
            justify-content: space-between;
            z-index: 12;

            .button
            {
                // margin-right: 15px;
            }
        }
    }

    nav
    {
        border-bottom: 1px solid @e4;
        margin-top: 10px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px;
    }

    .invoice
    {
        height: 100%;
    }

    .drawer-container .drawer-navigation + .drawer-content
    {
        top: @drawer-navigation-height + @drawer-header-height;
    }

    .invoice-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }

    // ==== Invoice Styles ====
    .notes {
        // height: 100%;
        padding: 20px;

        textarea {
            min-height: 300px;
        }

        .actions {
            margin-top: 10px;
        }
    }

    .option {
        margin-right: 8px;

        .button {
            margin-right: 0;
        }

        &.button {
            margin-right: 8px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;
        margin-right: 10px;

        &.red
        {
            background: @red;
        }

        &.blue
        {
            background: @base;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.purple
        {
            background: @purple;
        }

        &.grey
        {
            background: @grey;
        }
    }
</style>
