<template>
    <div class="integrate-account-two-factor-confirmation">
        <h1>Confirm Two-Factor Authentication</h1>
        <div class="helptext">
            Scan the QR code below to enable 2FA on your account. We recommend
            <a target="_blank" href="https://authy.com/">Authy</a> or
            <a target="_blank" href="https://www.google.com/authenticator/">Google Authenticator</a>,
            but any RFC 4226 compatible application will work.
            Once you're done, enter the code generated by your application below.
        </div>
        <div class="flex flex-align">
            <canvas class="qr-code block" ref="qr" />
            <div class="ff qr-code-entry">
                <label>Enter the 6-digit code from your device</label>
                <input ref="code" class="small" type="text" placeholder="123456" autocomplete="false" v-model="code" @keydown.enter="confirm" />
            </div>
        </div>
        <div class="options flex flex-align">
            <div class="button" @click="confirm">Enable 2FA</div>
            <div class="text-button" @click="send('TERMS')">Skip This Step</div>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcode';

    export default {
        name: 'IntegrateAccountTwoFactorConfirmation',
        props: {
            state: Object,
            send: Function
        },
        data(){
            return {
                code: ''
            };
        },
        computed: {
            invitations(){
                return this.$store.getters.user?.invitations;
            }
        },
        mounted(){
            if(this.$store.getters.user){
                if(this.$store.getters.user.two_factor_enabled){
                    if(this.$store.getters.team){
                        if(this.invitations?.length){
                            this.send('INVITES');
                        } else {
                            this.send('SELECT_DISTRICT');
                        }
                    }else{
                        this.send('TERMS');
                    }
                }else{
                    this.$http.get('/two_factor')
                    .then(response => {
                        QRCode.toCanvas(this.$refs.qr, response.$data.url, { errorCorrectionLevel: 'H', width: 160, height: 160 });
                    })
                    .catch(error => {
                        this.$toasted.error('There was an error initializing 2FA.');
                    });
                }
            }
        },
        methods: {
            confirm(){
                // Check to see if the user has any open invitations
                this.$http.post('/two_factor', { code: this.code })
                .then(() => {
                    if(this.$store.getters.team){
                        if(this.invitations?.length){
                            this.send('INVITES');
                        } else {
                            this.send('SELECT_DISTRICT');
                        }
                    }else{
                        this.send('TERMS');
                    }
                })
                .catch(error => this.$toasted.error('There was an error saving your 2FA configuration. Please check the code.'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext
    {
        a
        {
            color: @base;
        }
    }
    
    .button
    {
        margin-right: 20px;
    }

    .qr-code
    {
        width: 180px;
        height: 180px;
        background: @f;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 2px 25px -4px rgba(0, 0, 0, 0.2);
        margin: 20px 0 10px;
        padding: 10px;
    }

    .qr-code-entry
    {
        padding-left: 40px;

        label
        {
            margin: 10px 0 15px;
        }
    }
</style>
