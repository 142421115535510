import { VALID_DRAWER_ENTITIES } from '@/constants';

const state = {
    active: false,
    key: null,
    width: 400,
    component: null,
    props: null,
    loading: false
};

const mutations = {
    open(state, { component, props, key }) {
        state.active = true;
        state.component = component;
        state.props = props;
        state.key = key;
    },
    close(state) {
        state.active = false;
    },
    clear(state) {
        state.width = 400;
        state.component = null;
        state.key = null;
    },
    component(state, component) {
        state.component = component;
    },
    width(state, width = 400) {
        state.width = width;
    },
    props(state, props) {
        state.props = props;
    },
    key(state, key) {
        state.key = key;
    },
    loading(state, loading) {
        state.loading = loading;
    },
    setPropsField(state, propsUpdateFunc) {
        propsUpdateFunc(state.props);
    }
};

const actions = {
    open({ commit, dispatch }, { width, component, props, key }) {
        // Throw an error if there is no key.
        if (!key) {
            throw new Error('Key is required to open a drawer.');
        }

        // First change the width because our transition class will be applied during the `open` phase.
        dispatch('width', width).then(() => commit('open', { props, component, key }));

        const [type, entity_id] = key.split('/');

        if (VALID_DRAWER_ENTITIES[type]) {
            window.location.hash = `#${VALID_DRAWER_ENTITIES[type]}/${entity_id}`;
        }
    },
    close({ commit }) {
        commit('close');

        // We don't need to wait to adjust the hash.
        window.location.hash = '';

        // Wait until it's closed to adjust the width (because we want to wait for the animation to play out).
        setTimeout(() => commit('clear'), 350);
    },
    replace({ commit }, { component, width, props, key }) {
        // We DO want the transition class to be active here because we want to see the resize transition occur.
        commit('width', width);
        commit('component', component);
        commit('props', props);
        commit('key', key);

        const [type, entity_id] = key.split('/');

        if (VALID_DRAWER_ENTITIES[type]) {
            window.location.hash = `#${VALID_DRAWER_ENTITIES[type]}/${entity_id}`;
        }
    },
    width({ commit }, width) {
        commit('width', width);
    },
    loading({ commit }) {
        commit('loading', true);
    },
    ready({ commit }) {
        commit('loading', false);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
