<template>
    <main class="admin-people flex flex-column">
        <header class="flex flex-align">
            <h1>Person</h1>
            <div class="ff"></div>
            <!-- <input class="filter search medium" type="text" placeholder="Search People" v-model="search" @keydown.enter="go" /> 
            <input class="filter search medium" type="text" placeholder="Search People by Id or Email" v-model="search"
                @keydown.enter="load" />
                -->
        </header>

        <nav class="flex flex-align">
            <router-link class="destination" :to="{ name: 'admin.person.overview' }">Overview</router-link>
            <router-link class="destination" :to="{ name: 'admin.person.integrations' }">Integrations</router-link>
            <router-link class="destination" :to="{ name: 'admin.person.logins' }">Logins</router-link>
            <router-link class="destination" :to="{ name: 'admin.person.changes' }">Changes</router-link>
            <router-link class="destination" :to="{ name: 'admin.person.requests' }">Requests</router-link>
        </nav>

        <router-view />
    </main>
</template>

<script>
export default {
    name: 'AdminPerson',
    data() {
        return {
            person:
                'place holder text just so this is defined, soon to be overwritten. If you see this on the webpage something went wrong or it needs another second to load.',            
            search: ''
        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}`)
            .then((response) => {
                //  this.$toasted.show('person found??'); //this line works just is useless
                console.log("ams 2 created", Date.now(), response.$data);
                this.person = response.$data;
            })
            .catch((error) => {
                this.$toasted.error(
                    "Failed to load person."
                );
            });
    },
    methods: {
        //the email giorgiana-90@example.com returns multiple people (for testing purposes)
        load() {
            const params = {};

            if (this.search) {
                params.$filter = {
                    team_name: [{ operator: 'contains', value: this.search }],
                };
            } else {
                params.$last = 100;
            }
            const search_input = params.$filter.team_name[0].value;    

            if (search_input.includes('@')) {
                this.$http
                    .post(`/admin/people/`, {email: search_input})
                    .then((response) => {
                        console.log('res data', response.$data);
                        this.person = response.$data;
                    })
                    .catch((error) => {
                        this.$toasted.error("Failed to load person.");
                    });
            } else if (search_input.length === 36) {
               this.$router.push(`/admin/people/${search_input}`); 
            } else {
                (error) => {
                    this.$toasted.error('Bad input');
                };
            }
        },
    },
    computed: {
        name() {            
            return this.user;
        }
    },
};
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

header {
    border-bottom: 1px solid @e4;
    height: 70px;
}

.data-list {
    //color: red;
    font-size: 1.25em;
}

.list-item {
    //padding: 5px;
    margin-top: 0.5em;
}

.button-list {
    //color: aqua;
    display: inline-block;
    margin-top: 20px;
    float: left;
}

.button-item {
    margin-top: 1em;
    margin-left: 0.5em;
    display: inline-block;
}

nav {
    border-bottom: 1px solid @e4;
    margin-top: 5px;
}

.ff {
    padding: 15px;
}
</style>
