<template>
    <div class="demo login-container constrain bc flex flex-align flex-column">
        <spinner />
    </div>
</template>

<script>
const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';
export default {
    name: 'DemoAuth',
    data() {
        return {
            client_id: '36ad8864-d789-45b6-9df8-334c2f32445b',
            client_secret: 'pT0wYC9rgaUdxgUw8PlFEP1ALeejZbJIemZ9u5VYTreyvEw0uDLevmCR3M9Zqhww',
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state?.user.admin;
        },
        integration() {
            return this.$store.state.integrations.active;
        }
    },
    async mounted() {
        if (this.$route.query.code) {
            // Exchange code for token
            const token_set = await this.$demo.post(`${base_url}/authentication/token`, {
                code: this.$route.query.code,
                client_id: this.client_id,
                client_secret: this.client_secret,
                redirect_uri: window.location.href.split('?')[0],
                grant_type: "authorization_code"
            }).then(res => res.$data);

            // Build our token_set
            // Set expiration
            const t = new Date();
            t.setSeconds(t.getSeconds() + 10);
            // t.setSeconds(t.getSeconds() + token_set.expires_in);
            token_set.expires_at = t.toISOString();
            
            const profile = await this.$demo.get(`${base_url}/v2/my/profile`, {
                headers: {
                    authorization: `Bearer ${token_set.access_token}`
                }
            }).then(res => res.$data);

            // Set the person id on token_set to keep track of it
            token_set.person_id = profile.id;
            token_set.person_display_name = profile.display_name;
            token_set.person_first_name = profile.first_name;
            token_set.person_last_name = profile.last_name;
            token_set.person_picture_url = profile.picture_url;
            token_set.person_roles = profile.roles;

            const token_sets = JSON.parse(localStorage.getItem('edlink-demo-app-token-sets')) ?? [];
            const search = token_sets.findIndex(it => it.person_id === profile.id);
            console.log(search)
            if (search !== -1) {
                token_sets[search] = token_set;
            } else {
                token_sets.push(token_set);
            }

            localStorage.setItem('edlink-demo-app-token-sets', JSON.stringify(token_sets));
            localStorage.setItem('edlink-demo-app-token-set', JSON.stringify(token_set));

            if (token_set.context) {
                this.$root.$data.lti_launch = true;
                if (token_set.context.assignment) {
                    this.$router.push(`/demo/classes/${token_set.context.class.id}/assignments/${token_set.context.assignment.id}`);
                } else if (token_set.context.class) {
                    this.$router.push(`/demo/classes/${token_set.context.class.id}`);
                }
            } else {
                this.$router.push('/demo');
            }

        }
    },
    methods: {
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.demo {
    min-height: 100vh;
    justify-content: center;
}

.login-container {
    padding: 80px 50px;
}
</style>
