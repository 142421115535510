<template>
    <div class="create-person-entity">
        <div class="drawer-section">
            <h4>Person Details</h4>
            <div class="summary">
                <entity-field v-for="column of properties.person" :key="column.property" v-bind="column" />
            </div>
        </div>
        <div class="drawer-divider"></div>
        <div class="drawer-section">
            <h4>Address</h4>
            <div class="summary">
                <entity-field v-for="column of properties.address" :key="column.property" v-bind="column" />
            </div>
        </div>
        <div class="drawer-divider"></div>
        <div class="drawer-section">
            <h4>Demographics</h4>
            <div class="summary">
                <entity-field v-for="column of properties.demographics" :key="column.property" v-bind="column" />
            </div>
        </div>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';

    export default {
        name: 'CreatePersonEntity',
        components: {
            'entity-field': EntityField
        },
        data(){
            return {
                properties: {
                    person: [
                        {title: 'First Name', property: 'first_name', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Last Name', property: 'last_name', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Display Name', property: 'display_name', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Roles', property: 'roles', type: 'role', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'Email Address', property: 'email', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Phone Number', property: 'phone', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Graduation Year', property: 'graduation_year', type: 'number', editable: true, placeholder: 'Click to Set'},
                        {title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'Schools', property: 'school_ids', type: 'school', array: true, editable: true, placeholder: 'Click to Set'}
                    ],
                    demographics: [
                        {title: 'Birthday', property: 'birthday', type: 'date', editable: true, placeholder: 'Click to Set'},
                        {title: 'Country of Birth', property: 'country_of_birth', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'State of Birth', property: 'state_of_birth', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'City of Birth', property: 'city_of_birth', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Gender Identity', property: 'gender', type: 'gender', editable: true, placeholder: 'Click to Set'},
                        {title: 'Races', property: 'races', type: 'race', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'Hispanic or Latino', property: 'hispanic_or_latino_ethnicity', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'English Language Learner', property: 'english_language_learner', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Individualized Education Plan', property: 'individualized_education_plan', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Primary Language', property: 'primary_language', type: 'primary_language', editable: true, placeholder: 'Click to Set'},
                        {title: 'Special Accommodations', property: 'special_accommodations', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Military Connected', property: 'military_connected', type: 'military_connected_status', editable: true, placeholder: 'Click to Set'},
                        {title: 'Food Service Program Eligibility', property: 'food_service_program_eligibility', type: 'food_service_program', editable: true, placeholder: 'Click to Set'},
                        {title: 'Residence Status', property: 'residence_status', type: 'residence_status', editable: true, placeholder: 'Click to Set'},
                        {title: 'Homeless', property: 'homeless', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Migrant Status', property: 'migrant', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Disability', property: 'disability', type: 'disability', editable: true, placeholder: 'Click to Set'},
                        {title: 'Gifted or Talented', property: 'gifted_talented', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Economically Disadvantaged', property: 'economically_disadvantaged', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Public Assistance', property: 'public_assistance', type: 'boolean', editable: true, placeholder: 'Click to Set'},
                        {title: 'Rural Residency', property: 'rural_residency', type: 'boolean', editable: true, placeholder: 'Click to Set'}
                    ],
                    address: [
                        {title: 'Street Address', property: 'street', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Unit Number', property: 'unit', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'City', property: 'city', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'State', property: 'state', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Postal Code', property: 'postal_code', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Country', property: 'country', type: 'string', editable: true, placeholder: 'Click to Set'}
                    ]
                }
            };
        },
        created(){
            this.$store.commit('entities/create', {
                type: 'people',
                source: this.source,
                integration: this.integration
            });
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            props(){
                return this.$store.state.drawer.props;
            },
            entity(){
                return this.props.entity;
            },
            integration(){
                return this.$store.getters.integration;
            },
            source(){
                return this.$store.getters.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
        padding: 0 25px;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }
</style>
