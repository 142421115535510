<template>
    <div class="entity flex flex-column">
        <header class="drawer-header flex">
            <div class="ff flex flex-align">
                <div class="entity-icon flex flex-align flex-center">
                    <SeaWaves class="icon" width="16" height="16" stroke-width="2" />
                </div>
                <h2 class="ff drawer-title">Flow</h2>
                <div class="text-button border" @click="close">Close</div>
            </div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{ active: tab === 'details' }" @click="tab = 'details'">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Details
            </div>
            <div class="pill" :class="{active: tab === 'json'}" @click="tab = 'json'">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                JSON
            </div>
            <template v-if="type === 'people'">
                <div class="destination" :class="{active: tab === 'enrollments'}" @click="tab = 'enrollments'">Enrollments</div>
                <!-- <div class="destination" :class="{active: tab === 'requests'}" @click="tab = 'requests'">Requests</div>
                <div class="destination" :class="{active: tab === 'logins'}" @click="tab = 'logins'">Logins</div> -->
            </template>
            <div class="pill" :class="{active: tab === 'logs'}" @click="tab = 'logs'">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Logs
            </div>
            <div class="ff"></div>
            <!-- <div class="destination right" :class="{active: tab === 'events'}" @click="tab = 'events'">Events</div> -->
        </nav>
        <div class="drawer-content drawer-scroll">
            <component :is="tabs[tab]" />
        </div>
    </div>
</template>

<script>
    import EntityDetails from '@/components/drawers/entity/EntityDetails.vue';
    import EntityJSON from '@/components/drawers/entity/EntityJSON.vue';
    import EntityLogs from '@/components/drawers/entity/EntityLogs.vue';
    import { AlignLeft, InfoEmpty, CodeBracketsSquare, SeaWaves } from '@epiphany/iconoir';

    export default {
        name: 'Flow',
        components: {
            AlignLeft,
            InfoEmpty,
            SeaWaves,
            CodeBracketsSquare
        },
        data(){
            return {
                tab: 'details',
                tabs: {
                    details: EntityDetails,
                    json: EntityJSON,
                    logs: EntityLogs
                }
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity
    {
        height: 100%;
    }

    .drawer-container
    {
        .drawer-header
        {
            padding-bottom: 0;
            .drawer-title
            {
                margin: 0;
            }
            .button
            {
                margin-right: 15px;
            }
        }
    }

    nav
    {
        border-bottom: 1px solid @e4;
        margin-top: 10px;
    }

    .drawer-scroll
    {
        overflow: auto;
        padding: 20px;
    }

    .drawer-section
    {
        padding: 25px;
    }

    .entity-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);
        margin-right: 8px;

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }

    .job-details
    {
        padding: 0 20px 20px;
        overflow: auto;
    }
</style>
