<template>
    <div class="reconnect-step">
        <template v-if="error">
            <h1>Oh no!</h1>
            <div class="helptext">
                Something went wrong while we were trying to update your {{ source.provider.name }} source. Please contact our <a class="text-button" href="https://ed.link/support">support team</a>?
            </div>
            <!-- <div class="options flex">
                <div class="button" @click="close()">Close this window</div>
            </div> -->
        </template>
        <template v-else>
            <h1>Success!</h1>
            <div class="helptext">
                Thank you{{ name ? ` ${name}` : '' }}! You have successfully reconnected your {{ source.provider.name }} account. You can now close this window.
            </div>
            <!-- <div class="options flex">
                <div class="button" @click="close()">Close this window</div>
            </div> -->
        </template>
    </div>
</template>

<script>
    import JSConfetti from 'js-confetti';

    const jsConfetti = new JSConfetti();

    export default {
        name: 'Reconnect',
        components: {
        },
        data() {
            return {}
        },
        computed: {
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            user(){
                return this.$store.getters.user;
            },
            source(){
                return this.$store.state.reconnect.source;
            },
            name(){
                if (this.user) {
                    if (this.user.first_name) {
                        return this.user.first_name;
                    }
                }
                return null;
            },
        },
        methods: {
            next(){
                this.$store.dispatch('reconnect/next');
            },
            close(){
                window.close();
            }
        },
        mounted() {
            if (!this.error) {
                jsConfetti.addConfetti({
                    confettiColors: [
                        '#006AFF', '#F1C40F', '#2ED573', '#8E44AD', '#EB2F06'
                    ]
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
    
    .reconnect
    {
        width: 100vw;
        min-height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
    }

    .reconnect-loading {
        width: 100vw;
        min-height: 100vh;
    }

    .reconnect-step {
        width: 100%;
        max-width: 600px;
    }

    .configuration {
        width: 100%;
        max-width: 600px;
        padding: 200px 0 80px;
    }

    .scroll {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        max-width: none;
    }

    .edit-toggle {
        position: absolute;
        right: 20px;
        top: 15px;     
    }

    .helptext
    {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        margin-bottom: 15px;
    }

    .title-helptext
    {
        margin-bottom: 50px;
    }

    .options {
        margin-top: 25px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    header
    {
        z-index: 20;
        padding: 10px 15px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        pointer-events: none;

        .wordmark {
            height: 30px;
            pointer-events: all;

            .diamonds {
                background: url('~@/assets/edlink-diamonds.svg') center center no-repeat;
                background-size: 30px auto;
                color: transparent;
                width: 30px;
                height: 30px;
                margin-right: 12px;
            }

            .letters {
                background: url('~@/assets/edlink.svg') left center no-repeat;
                background-size: 75px auto;
                color: transparent;
                width: 150px;
                height: 30px;
            }
        }

        .text-button {
            margin-left: 20px;
        }

        .integrate-video {
            pointer-events: all;
        }
    }

    .integrate-video
    {
        font-weight: 400;
        font-size: 12px;
        color: @base;
        line-height: 24px;
        background: fade(@base, 20%);
        height: 24px;
        padding: 0 6px 0 10px;
        border-radius: 12px;
        transition: background-color ease 0.2s;
        cursor: pointer;

        .icon
        {
            font-size: 16px;
            margin-right: 4px;
        }

        &::after
        {
            width: 14px;
            height: 14px;
            background: url('~@/assets/icons/base/chevron-right.svg') -2px -1px no-repeat;
            background-size: 16px auto;
            display: block;
            content: "";
            margin-left: 5px;
            transition: all ease 0.2s;
        }

        &:hover, &:active
        {
            background: fade(@base, 30%);

            &::after
            {
                .translate3d(4px, 0, 0);
            }
        }
    }

    .steps {
        position: fixed;
        pointer-events: none;
        top: 0;
        bottom: 0;
        left: 25px;
        z-index: 10;

        .step {
            font-size: 12px;
            color: transparent;
            line-height: 26px;
            cursor: pointer;
            pointer-events: all;
            border-left: 2px solid lighten(@grey, 20%);
            margin-bottom: 10px;
            width: 120px;
            transition: all ease 0.2s;
            padding-left: 5px;

            &:hover,
            &:active {
                padding-left: 10px;
                color: @grey;
            }

            &.active,
            &.router-link-active {
                color: @black;
                padding-left: 10px;
                border-left-color: @base;
            }
        }

        &.done {
            .step {
                border-left-color: @grey;
            }
        }
    }
</style>
