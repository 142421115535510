<template>
    <div class="integrate-source-provider-csv">
        <integrate-source-editor-step name="Generate Your CSV Files" icon="iconoir-table-2-columns" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating a CSV" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    This step will vary by system.
                    <!-- This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                    click <a class="configuration-link inline-block text-button mini" target="_blank">here</a>. -->
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Upload Your Zipped Files" icon="iconoir-upload-square-outline" :complete="completed.includes('file')">
            <div class="helptext">
                Upload your CSV to complete the connection process.
            </div>
            <upload class="image-upload" :url="source.configuration.file" @input="value => send('UPDATE_SOURCE', {path: 'configuration.file', value})" type="zip" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderCSV',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Has the remote generation step been completed?
                if(!this.remote){
                    return [];
                }

                // 2. Validate that the token has been acquired.
                if(!this.source.configuration.file){
                    return ['remote'];
                }

                return ['remote', 'file'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 2);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }
</style>
