<template>
    <div class="entity-value flex">
        <div v-if="editing" class="ff" key="editing">
            <date-picker :open.sync="open" v-model="override" v-bind="{
                format: 'hh:mm a',
                type: 'time',
                'input-class': 'block editable-input'
            }">
                <template v-slot:icon-calendar>
                    <span></span>
                </template>
            </date-picker>
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable">
            <template v-if="value">{{ value | pretty('short_time') }}</template>
            <template v-else>
                <div v-if="placeholder" class="placeholder">{{placeholder}}</div>
                <div v-else class="empty">&mdash;</div>
            </template>
        </div>
    </div>
</template>

<script>
    import { DataTransferDown } from '@epiphany/iconoir';
import _ from 'lodash';

    export default {
        name: 'EntityValueTime',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                open: false
            }
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                if (this.override) {
                    return this.override;
                } else {
                    const utc_date = new Date();
                    const value = new Date(this.original);
                    utc_date.setHours(value.getUTCHours(), value.getUTCMinutes());
                    return utc_date;
                }
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property);
                    if (typeof value === 'string') {
                        const date = new Date(value);
                        const utc_date = new Date();
                        utc_date.setHours(date.getUTCHours(), date.getUTCMinutes());
                        return utc_date;
                    } else {
                        if (value instanceof Date) {
                            return value;
                        }
                    }
                    return null;
                },
                set(value){
                    if (value instanceof Date && !isNaN(value)) {
                        const utc_datetime =  new Date(new Date(0).setUTCHours(value.getHours(), value.getMinutes(), 0));
                        this.$store.commit('entities/override', {property: this.property, value: utc_datetime.toISOString() });
                    } else {
                        this.$store.commit('entities/override', {property: this.property, value: null});
                    }
                    return;
                }
            }
        },
        methods: {
            edit(){
                if (this.editable) {
                    if (this.value) {
                        this.$store.commit('entities/override', { property: this.property, value: this.value });
                    }
                    this.$emit('edit');
                    this.open = true;
                }
            },
            save(e){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    input.editable-input {
        border-radius: 0;
        width: 100%;
        margin: 0;
        height: 22px;
        line-height: 22px;
        padding: 0;
        border: 0;
        min-width: 150px;
        font-size: 14px;
        color: @base;

        &:focus {
            box-shadow: none;
        }
    }
</style>
