<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Applications
                </div>
            </template>
            <template v-slot:actions>
                <button class="button" @click="create_application">Create Application</button>
            </template>
        </breadcrumbs>
        <datatable class="applications full" :columns="columns" :rows="applications" :selectable="true" :header="true" @clicked="open" :loading="loading" :clickable="true">
            <template v-slot:empty-message>You have not yet created any applications.</template>
        </datatable>
    </main>
</template>

<script>
    import CreateApplication from '@/components/drawers/CreateApplication';
    import { Svg3dSelectSolid, AlignLeft, Calendar, WhiteFlag } from '@epiphany/iconoir';
    
    export default {
        name: 'Applications',
        components: {
            Svg3dSelectSolid,
            AlignLeft,
            Calendar,
            WhiteFlag
        },
        data(){
            return {
                loading: true,
                applications: [],
                statuses: {
                    development: 'Development',
                    awaiting_approval: 'Awaiting Approval',
                    approved: 'Approved',
                    released: 'Released',
                    sunsetting: 'Sunsetting',
                    destroyed: 'Destroyed'
                },
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '70%',
                        value: row => row.name
                    },
                    {
                        id: 'status',
                        name: 'State',
                        icon: WhiteFlag,
                        value: row => row.status,
                        width: '120px'
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '40%',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    }
                ]
            };
        },
        created(){
            this.$http.get(`/teams/${this.$store.getters.team.id}/applications`)
            .then(response => this.applications = response.$data)
            .then(() => this.loading = false)
            .catch(error => this.$toasted.error('There was an error loading your team\'s applications.'));
        },
        methods: {
            open(application){
                this.$router.push({name: 'team.applications.application', params: {application: application.id}});
            },
            create_application(){
                this.$store.dispatch('drawer/open', {
                    key: 'create-application',
                    width: 450,
                    component: CreateApplication
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .applications
    {
        top: @breadcrumbs-height;
        overflow-y: auto;
    }
</style>