<template>
    <chip :color="states[state].color" :title="states[state].name" />
</template>

<script>
    export default {
        name: 'InvoiceState',
        props: {
            state: String
        },
        data(){
            return {
                states: {
                    'draft': { color: 'yellow', name: 'Draft' },
                    'submitted': { color: 'blue', name: 'Submitted' },
                    'sent': { color: 'blue', name: 'Sent' },
                    'paid': { color: 'green', name: 'Paid' },
                    'error': { color: 'red', name: 'Error' },
                    'overdue': { color: 'red', name: 'Overdue' },
                    'voided': { color: 'grey', name: 'Voided' },
                    'refunded': { color: 'purple', name: 'Refunded' }
                }
            };
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
