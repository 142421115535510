<template>
    <div class="preview drawer-content drawer-scroll flex flex-column" v-if="entity">
        <nav class="sub-nav flex flex-align">
            <div class="pill" :class="{active: tab === 'json'}" @click="tab = 'json'">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                JSON
            </div>
            <div class="pill" :class="{active: tab === 'share'}" @click="tab = 'share'" v-if="entity.share_path && licensing">
                <ClipboardCheck class="icon" width="16" height="16" stroke-width="2" />
                Passed Rules
            </div>
        </nav>
        <template v-if="tab === 'json'">
            <pre class="entity-preview card ff monospace" v-html="output" v-if="output"></pre>
        </template>
        <template v-else-if="tab === 'share'">
            <div class="entity-preview card rule-path flex flex-column" v-for="(path, rule_id) of entity.share_path" :set="rule = getRule(rule_id)">
                <div class="rule-name flex" :class="{ 'capitalize': !rule.name }">
                    <!-- <div v-tooltip.bottom="{
                        content: conditions(rule),
                        theme: 'info-tooltip'
                    }">
                    </div> -->
                    <div>
                        {{ rule.name ? rule.name : `${rule.target} Rule` }}
                    </div>
                    <!-- <info class="rule-info" :tooltip="conditions(rule)" /> -->
                    <div class="ff"></div>
                    <div class="type capitalize">{{ conditions(rule) }}</div>
                </div>
                <template v-for="child of path">
                    <template v-if="entity.id === child.id">
                        <div class="child self flex flex-column flex-center" :key="child.id">
                            <div class="name">Shared {{ child.name ? child.name : child.entity_type }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="child flex flex-column" :key="child.id">
                            <div class="header flex flex-row flex-align">
                                <template v-if="child.entity_type === 'enrollment'">
                                    <div class="name">{{ child.entity.person.display_name }} in {{ child.entity.class.name }}</div>
                                </template>
                                <template v-else-if="child.entity_type === 'agent'">
                                    <div class="name">{{ child.entity.observer.display_name ?? `${child.entity.observer.first_name} ${child.entity.observer.last_name}`.trim() }} {{ child.entity.relationship }} of {{ child.entity.target.display_name ?? `${child.entity.target.first_name} ${child.entity.target.last_name}`.trim() }}</div>
                                </template>
                                <template v-else>
                                    <div class="name">{{ child.entity.name ? child.entity.name : child.entity.display_name ? child.entity.display_name : '&#8212;' }}</div>
                                </template>
                                <div class="ff"></div>
                                <div class="type capitalize">{{ child.entity_type }}</div>
                            </div>
                            <!-- <div class="id">{{ child.id }}</div> -->
                            <div class="id copyable">{{ child.id }}</div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
    <div class="drawer-content drawer-scroll flex flex-column" v-else>
        <div class="nothing full flex flex-center flex-align">
            Select an entity in the preview tab to see its details.
        </div>
    </div>
</template>

<script>
    import Prism from 'prismjs';
    import { licensing } from '@/constants';
    import { CodeBracketsSquare, EyeEmpty, ClipboardCheck, ArrowLeft } from '@epiphany/iconoir';
    import { isNil, omit } from 'lodash';

    export default {
        name: 'EntityPreview',
        components: {
            ArrowLeft,
            CodeBracketsSquare,
            ClipboardCheck,
            EyeEmpty
        },
        data(){
            return {
                output: '',
                tab: 'json',
                plural: {
                    'class': 'classes',
                    'school': 'schools',
                    people: 'people',
                    teacher: 'teachers',
                }
            }
        },
        created(){
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            getRule(id){
                if(this.rules) {
                    const rule = this.rules.find(rule => rule.id === id);
                    if(rule) {
                        return rule;
                    }
                    return null;
                }
                return null;
            },
            conditions(rule){
                if (isNil(rule.conditions) || rule.conditions.length === 0) {
                    return `No conditions, all ${rule.target} pass.`;
                }
                const props = rule.conditions.map(it => licensing.properties[rule.target]?.find(prop => prop.value === it.property)?.name ?? it.property);
                return `Licensed by ${props.join(', ')}`;
            }
        },
        watch: {
            entity: {
                handler() {
                    if(this.entity) {
                        try {
                            //Color and format the JSON that represents this user.
                            this.output = this.entity ? Prism.highlight(JSON.stringify(omit(this.entity, ['share_path']), null, 4), Prism.languages.json, 'json') : null;
                        } catch(error) {
                            console.log(error);
                        }
                    }
                },
                immediate: true
            }
        },
        props: {
            licensing: {
                type: Boolean,
                default: false
            },
            entity: Object
        },
        computed: {
            rules(){
                return this.$store.getters.integration.rules;
            },
            name(){
                return this.entity ? this.entity.display_name ? this.entity.display_name : this.entity.name ? this.entity.name : 'No Name' : 'No Name';
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .preview {
        top: 0;
    }

    .rule-info {
        margin-left: 5px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-content
    {
        padding: 15px;
    }

    .sub-nav {
        padding: ((@filters-height - @button-height - 1) / 2) 10px;
        height: @filters-height;
        border-bottom: 1px solid @e;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background: @f;
    }

    .entity-preview {
        margin-top: 50px;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 6px;
        background: @f4;
        padding: 10px;
    }
    
    .rule-path {
        padding: 20px 20px 15px 40px;
        margin-bottom: 15px;

        .rule-name, .child {
            height: 41px;
            margin-bottom: 10px;

            .header {
                margin-bottom: 4px;
            }

            .name {
                font-size: 14px;
                font-weight: 500;
                color: @black;
            }

            .id {
                font-size: 12px;
                color: @grey;
            }

            .type {
                font-size: 12px;
                color: @grey;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: "";
                position: absolute;
                height: 5px;
                width: 5px;
                border-radius: 50%;
                left: -23px;
                top: 8px;
                background: @c;
                display: block;
            }

            &.self {
                height: 21px;
                .name {
                    color: @base;
                }

                &::before {
                    background: @base;
                }
            }
        }
        .rule-name {
            font-size: 14px;
            font-weight: 500;
            height: 14px;
            margin-bottom: 20px;

            &::before {
                content: "";
                position: absolute;
                height: 5px;
                width: 5px;
                border-radius: 50%;
                left: -23px;
                top: 5px;
                background: @c;
                display: block;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 25px;
            bottom: 26px;
            left: 15px + 4px;
            width: 1px;
            border-left: 1px solid @c;
            display: block;
            z-index: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
