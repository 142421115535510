<template>
    <information class="integration-overrides full scroll" v-if="integration">
        <template v-slot:title>Data Overrides</template>
        <template v-slot:description>
            <div>
                Data overrides can be used to modify data before it is shared with {{ integration.application.name }}.
                You can use overrides to insert, update, or delete data.
                Overrides are applied each time data syncs from {{ integration.source.name }}, so your changes will not be overwritten every day.
                Changes to overrides may take several minutes to apply to the shared data.
            </div>
        </template>
        <template v-slot:placards>
            <placard>
                <template v-slot:title>Learn about overrides</template>
                <template v-slot:description>Read more about how overrides work</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" href="https://ed.link/docs/guides/v2.0/integration-management/modifying-overrides-via-api" target="_blank">
                        Learn More
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
            <placard>
                <template v-slot:title>Modify overrides via API</template>
                <template v-slot:description>Use the Edlink API to update overrides</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" href="https://ed.link/docs/guides/v2.0/integration-management/modifying-overrides-via-api" target="_blank">
                        Read the Docs
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
        </template>
        <template v-slot:details>
            <datatable class="card" :columns="columns" :rows="overrides" :selectable="true" :header="true" @clicked="open" :loading="loading" :clickable="true" :min-height="!loading && overrides.length ? '0px' : '160px'">
                <template v-slot:empty-message>You have not created any overrides for this integration</template>
            </datatable>
        </template>
    </information>
</template>

<script>
import Vue from 'vue';
import { AlignLeft, TransitionRight, Calendar, ArrowUpRight } from '@epiphany/iconoir';
import Options from '@/components/modals/Options.vue';

const plural_map = {
    'district': 'districts',
    'agent': 'agents',
    'class': 'classes',
    'course': 'courses',
    'enrollment': 'enrollments',
    'person': 'people',
    'school': 'schools',
    'section': 'sections',
    'session': 'sessions',
    'calendar': 'calendars',
    'day': 'days',
    'stop': 'stops',
    'department': 'departments',
    'subject': 'subjects',
    'period': 'periods',
    'facility': 'facilities',
    'room': 'rooms',
    'incident': 'incidents',
    'asset': 'assets',
    'vehicle': 'vehicles',
    'route': 'routes',
    'fee': 'fees'
};

export default {
    name: 'TransformSharedOverrides',
    props: {
        transformation: Object
    },
    components: {
        AlignLeft,
        TransitionRight,
        Calendar,
        ArrowUpRight
    },
    data(){
        return {
            loading: true,
            overrides: [],
            entities: {},
            columns: [
                {
                    id: 'action',
                    name: 'Action',
                    icon: TransitionRight,
                    width: '150px',
                    classes: 'capitalize',
                    value: row => `${row.action} ${row.type}`
                },
                {
                    id: 'description',
                    name: 'Description',
                    icon: AlignLeft,
                    width: '85%',
                    value: row => {
                        const entity = this.entities[row.entity_id];
                        const properties = Object.keys(row.properties);
                        const action = row.action[0].toUpperCase() + row.action.slice(1);
                        const target = row.type[0].toUpperCase() + row.type.slice(1);

                        // If the row action is `created`, we will never have an entity since we're loading those from the filtered schema.
                        if(row.action === 'created'){
                            return `Created ${row.type === 'person' ? row.properties.display_name : row.properties.name} in the data set`;
                        }

                        if(entity){
                            if(row.action === 'updated'){
                                return `${action} ${properties.length} propert${properties.length === 1 ? 'y' : 'ies'} on ${row.type === 'person' ? entity.display_name : entity.name}`;
                            }

                            return `Deleted ${row.type === 'person' ? entity.display_name : entity.name} from the data set`;
                        }
                        
                        return `${action} Unknown ${target}`;
                    }
                },
                {
                    id: 'created_date',
                    name: 'Created Date',
                    icon: Calendar,
                    align: 'left',
                    width: '15%',
                    value: row => this.$options.filters.pretty(row.created_date, 'short')
                }
            ]
        };
    },
    async created() {
        try {
            this.overrides = await this.$http.get(`/teams/${this.team.id}/integrations/${this.integration.id}/overrides`).then(response => response.$data);
            
            // Group the overrides by type.
            const groups = _.groupBy(this.overrides, override => override.type);

            for (const [type, values] of Object.entries(groups)) {
                const { $data: entities } = await this.$http
                .get(`/teams/${this.team.id}/integrations/${this.integration.id}/${plural_map[type]}`, {
                    params: {
                        $filter: {
                            id: [{
                                operator: 'in',
                                value: values.map(it => it.entity_id).join(',')
                            }]
                        },
                        $fields: type === 'person' ? 'id,display_name' : 'id,name',
                        $schema: 'filtered'
                    },
                    baseURL: '/api/v2'
                });

                for (const entity of entities) {
                    Vue.set(this.entities, entity.id, entity);
                }
            }
        }catch(error){
            this.$toasted.error('Failed to load overrides.');
        }finally{
            this.loading = false;
        }
    },
    methods: {
        open(row){
            const entity = this.entities[row.entity_id];
            const plural = plural_map[row.type];
            const that = this;

            const options = [
                {
                    title: 'Delete Override',
                    color: 'red',
                    fn() {
                        that.$http.delete(`/teams/${that.team.id}/integrations/${that.integration.id}/overrides/${row.id}`)
                        .then(() => {
                            that.overrides.splice(that.overrides.indexOf(row), 1);
                        })
                        .catch(() => {
                            that.$toasted.error('Failed to delete override.');
                        });
                    }
                }
            ];

            if(row.action !== 'deleted'){
                options.unshift({
                    title: 'View Entity',
                    color: 'blue',
                    fn() {
                        that.$store.dispatch('entities/activate', {
                            id: entity.id,
                            type: plural,
                            integration: that.integration
                        })
                        .then(() => {
                            that.$store.dispatch('drawer/open', {
                                key: `${plural}/${entity.id}`,
                                width: 800,
                                component: 'entity'
                            });
                        });
                    }
                });
            }

            this.$modal.show(Options, {
                title: `Override Options`,
                options
            }, { width: 300, height: 'auto', classes: 'modal' });
        }
    },
    computed: {
        source() {
            return this.$store.getters.source;
        },
        integration() {
            return this.$store.getters.integration;
        },
        team() {
            return this.$store.getters.team;
        }
    }
}
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .nothing {
        font-size: 13px;
        text-align: center;
        border: 1px dashed @e4;
        border-radius: 6px;
        padding: 25px;
        color: @lightgrey;
    }

    .overrides {
        padding: 0 25px;
    }

    .override-title {
        color: @black;
        font-size: 14px;
        font-weight: 500;
        max-width: 250px;
        line-height: 16px;
    }

    .override-property, .override-action {
        color: @grey;
        font-size: 13px;
        line-height: 14px;
        white-space: nowrap;

        &.primary {
            color: @black;
        }
    }

    .override-action {
        text-transform: capitalize;
    }

    .override-badge {
        height: 18px;
        line-height: 18px;
        font-size: 11px;
        font-weight: 500;
        color: @grey;
        background-color: @f4;
        border-radius: 4px;
        padding: 0 4px;
        margin-left: 5px;
    }

    .override {
        padding: 10px 0;
        border-bottom: 1px solid @e4;
        
        &:first-child {
            border-top: 1px solid @e4;
        }
    }
</style>