<template>
    <article class="document" @click="capture">
        <v-runtime-template :template="template" />
    </article>
</template>

<script>
import showdown from 'showdown';
import Prism from 'prismjs';
import VRuntimeTemplate from 'v-runtime-template';

export default {
    name: 'Document',
    components: {
        VRuntimeTemplate
    },
    data() {
        return {
            title: '',
            template: ''
        };
    },
    computed: {
        team(){
            return this.$store.getters.team;
        }
    },
    methods: {
        capture(e) {
            if (e.target.tagName === 'A') {
                e.preventDefault();

                const url = e.target.getAttribute('href');

                if (['http', 'mailto:'].some(it => url.startsWith(it))) {
                    window.open(url, '_blank');
                } else {
                    this.$router.push(url);
                }
            }
        }
    },
    created() {
        const { path } = this.$route.params;

        const url = process.env.NODE_ENV === 'production' ? 'https://edlink.github.io/docs' : 'http://localhost:8082';

        this.$http.get(`${url}/${path}.md`)
            .then(response => {
                const container = document.createElement('section');
                const converter = new showdown.Converter({ tables: true, customizedHeaderId: true });

                try {
                    if(process.env.NODE_ENV !== 'production'){
                        response = response.replace(/]\(https:\/\/edlink.github.io\/docs\//gm, '/]\(http://localhost:8082/');
                    }

                    container.innerHTML = converter.makeHtml(response);

                    for (const block of container.querySelectorAll('pre')) {
                        block.classList.add('line-numbers');
                    }

                    Prism.highlightAllUnder(container);
                    this.template = container.outerHTML;

                    this.$nextTick(() => {
                        if (window.location.hash) {
                            const element = document.querySelector(window.location.hash);

                            if (element) {
                                element.scrollIntoView();
                            }
                        }
                    });
                } catch (error) {
                    this.$toasted.error('This page could not be loaded.');
                }
            })
            .catch(error => {
                //TODO 404
                // console.log(error);
                // return this.$router.push('/');
            });
    }
}
</script>

<style lang="less">
@import "~@/assets/less/variables";
@import "~@/assets/less/mixins";

@font-size: 16px;
@line-height: 26px;

.document {
    color: @black;
    line-height: @line-height;
    font-size: @font-size;
    max-width: 860px;
    padding-bottom: 30px;

    pre[class*="language-"] {
        margin: 30px 0;
        border-radius: @border-radius;
        background: @f;
        line-height: 22px;
        padding: 0 0 0 55px;
        border: 1px solid @e;

        .line-numbers-rows {
            border-right-color: @e;
            padding: 10px 0;
            line-height: 22px;
            font-size: 14px;
            background: @f8;
            left: -55px;
            width: 45px;

            ::before {
                color: @grey;
            }
        }

        code[class*="language-"] {
            line-height: 22px;
            font-size: 14px;
            display: block;
            background: transparent;
            padding: 10px 0;
        }
    }

    h1 {
        margin-top: @section-padding * 4;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;

        &:first-child {
            margin-top: @section-padding;
        }
    }

    h2 {
        color: @black;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.03rem;
        margin-top: @section-padding * 2;
    }

    h3 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 30px;
    }

    h1 + h2
    {
        margin-top: @section-padding;
    }

    h1+h3 {
        margin-top: 20px;
    }

    h2+h3, h1+h3 {
        margin-top: 10px;
        font-size: 15px;
        color: @base;
        line-height: 24px;
        font-weight: 400;
        font-family: @monospace;
        letter-spacing: -0.05rem;
        margin-bottom: 30px;

        em {
            font-style: normal;
            background: @base;
            color: @f;
            border-radius: 3px;
            padding: 0 6px;
            font-size: 14px;
            font-weight: 400;
            display: inline-block;
            line-height: 24px;
            letter-spacing: 0.03rem;
        }
    }

    h4 {
        margin-top: 40px;
        font-weight: 500;
        font-size: 18px;
        color: @black;
    }

    h3+h4 {
        font-size: 16px;
        line-height: @line-height;
        color: @grey;
        margin-top: 10px;
        font-weight: 400;
    }

    h5 {
        font-weight: 400;
        font-size: 12px;
        color: @grey;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid @e4;
    }

    h5+h2 {
        border-top: 0;
        margin-top: 4px;
        padding-top: 0;
        font-size: 26px;
    }

    p,
    ul,
    ol {
        padding: 0;
        margin: 20px 0;
        color: @black;
        font-size: @font-size;
        line-height: @line-height;
    }

    h4+p,
    h4+pre[class*="language-"],
    h4+ul,
    h4+ol,
    h4+.card,
    h4+table,
    h2+table,
    h2+pre[class*="language-"] {
        margin-top: 15px;
    }

    h3+table {
        margin-top: 10px;
    }

    h3+p {
        margin-top: 10px;
    }

    ul,
    ol {
        padding-left: 30px;

        li {
            margin-bottom: 6px;
            list-style: disc;
        }

        p {
            margin: 10px 0;
        }
    }

    ol {
        li {
            list-style: decimal;
        }
    }

    li {

        ul,
        ol {
            margin: 6px 0;
        }
    }

    p,
    ol,
    ul,
    table {
        code {
            display: inline-block;
            font-size: 13px;
            color: @black;
            background: @e;
            border-radius: 3px;
            font-weight: 400;
            padding: 0 4px;
            line-height: 20px;
        }
    }

    b {
        font-weight: 600;
    }

    a {
        // border-bottom: 1px dashed @base;
        color: @base;
        display: inline-block;

        code {
            color: @base;
        }

        &:hover,
        &:active {
            border-bottom-style: solid;

            code {
                color: @f;
                background-color: @base;
            }
        }
    }

    img {
        max-width: 100%;
        border: 1px solid @e4;
        border-radius: 3px;
        padding: 20px;
        margin: 30px auto;

        &.framed {
            padding: 0;
        }
    }

    table {
        border: 1px solid @e4;
        border-radius: 3px;
        border-collapse: separate;
        margin: 30px 0;

        tr {
            border: 0;
        }

        th {
            border-bottom: 0;
            font-size: 12px;
            color: @grey;
            font-weight: 500;
            text-align: left;
        }

        td {
            border-top: 1px solid @e4;
            border-bottom: 0;
            padding: 10px;
            line-height: 20px;
        }

        td,
        th {
            padding: 10px;

            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }
        }

        th {
            padding: 0 10px;
        }
    }

    .card {
        padding: 0 30px;
        margin: 30px 0;

        &.notice {
            font-weight: 500;
        }

        &.alert {
            border-color: @base;
            background: fade(@base, 10%);
            padding: 20px;

            p {
                font-size: 15px;
                color: @base;
                margin: 0;
                line-height: 20px;
            }
        }
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: 600;
    }

    blockquote {
        border: 1px solid fade(@base, 30%);
        border-radius: 3px;
        background: fade(@base, 12%);
        padding: 0 20px;
    }

    h1+blockquote {
        border: 1px solid @base;
        border-radius: 3px;
        background: fade(@base, 12%);
        padding: 0 15px;
        text-align: center;
        margin-top: 20px;

        p {
            color: @base;
            font-size: 13px;
            margin: 15px 0;
            font-weight: 500;

            a
            {
                text-decoration: underline;
            }
        }
    }

    .code-snippet {
        background: @f8;
        padding: 20px;
        font-size: 15px;
    }

    .selectable-fields {
        padding: 70px 30px 30px;
        border-color: @base;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            content: 'This content is selectable. You can copy and paste it as needed.';
            color: @base;
            background: fade(@base, 15%);
            border-bottom: 1px solid @base;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            border-radius: 3px 3px 0 0;
            padding: 10px;
            display: block;
        }
    }

    .download-link {
        border-color: @base;
        padding: 15px;
        font-size: 15px;
        color: @base;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        border-style: dashed;

        &:hover,
        &:active {
            border-style: solid;
        }
    }

    .download-icon {
        width: 24px;
        height: 24px;
        background: url('~@/assets/icons/base/archive.svg') center center no-repeat;
        background-size: 24px auto;
        margin-right: 12px;
    }
}

@media only screen and (max-width: 600px) {
    .document {
        h1 {
            font-size: 32px;
            line-height: 36px;
        }
    }
}
</style>