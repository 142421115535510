<template>
    <div class="datatable-input" :class="{ overriden, money, 'can-focus': canFocus }">
        <input type="text" :readonly="readonly" :value="value" @input="e => $emit('input', { event: e, value: e.target.value, $index: row.$index})" />
    </div>
</template>

<script>
    export default {
        name: 'InvoiceTextInput',
        props: {
            value: String | Number,
            readonly: Boolean,
            overriden: Boolean,
            money: Boolean,
            $index: Number,
            row: Object
        },
        computed: {
            canFocus(){
                return this.readonly ? false : true;
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .datatable-input {
        width: 100%;

        input {
            height: @datatable-cell-height - 1px;
            line-height: @datatable-cell-height - 1px;
            border-bottom: 0;
            font-size: 12px;
            padding: 0 10px;

            // reset input styles
            border: none;
            outline: none;
            box-shadow: none;
            margin: 0;
            border-radius: 0;
            cursor: default;

            &:focus, &:focus-visible {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }

        &.can-focus {
            input {
                cursor: text;
            }
        }
    
        &.overriden {
            background: fade(@base, 10%);
            color: @base;
    
            &::after
            {
                width: 16px;
                height: 16px;
                background: url('~@/assets/icons/base/pin.svg') 4px 3px no-repeat;
                background-size: 8px auto;
                position: absolute;
                top: 10px;
                right: 5px;
                bottom: auto;
                left: auto;
                content: "";
            }
        }

        &.money {
            text-align: right;

            input
            {
                text-align: right;

            }

            &.overriden {
                input {
                    padding-right: 26px;
                }
            }

            // add a fake $ sign far left of the input
            &::before {
                content: "$";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                color: currentColor;
            }
        }
    }

</style>
