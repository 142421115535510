<template>
    <div class="team-navigation">
        <div class="sidebar-group">
            <div class="sidebar-header">Configuration</div>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.overview'}">
                <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Overview</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.sharing'}">
                <MagicWand class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Data Sharing</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.sso'}">
                <PeopleTag class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Single Sign On</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.providers'}">
                <UnionAlt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Providers</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.customization'}">
                <DesignPencil class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Customization</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.validation'}">
                <HealthShield class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Data Validation</div>
            </router-link>
        </div>
        <div class="sidebar-group">
            <div class="sidebar-header">Advanced</div>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.flags'}">
                <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Feature Flags</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.webhooks'}" v-if="admin">
                <Extrude class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Webhooks</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.feeds'}">
                <AntennaSignal class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Data Feeds</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.applications.application.settings'}">
                <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Settings</div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { Svg3dSelectSolid, SettingsAlt, Extrude, DesignPencil, UnionAlt, MagicWand, WhiteFlag, HealthShield, PeopleTag, AntennaSignal } from '@epiphany/iconoir';

    export default {
        name: 'ApplicationNavigation',
        components: {
            Extrude,
            DesignPencil,
            UnionAlt,
            MagicWand,
            WhiteFlag,
            Svg3dSelectSolid,
            SettingsAlt,
            PeopleTag,
            HealthShield,
            AntennaSignal
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            admin() {
                return this.$store.getters.user?.admin;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .application-navigation
    {
        width: 100%;
    }
</style>
