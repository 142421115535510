<template>
    <div class="integrate-source-create-provider flex flex-column">
        <h1 v-if="type === 'destination'">Select Your Destination</h1>
        <h1 v-else>Select Your Data Source</h1>
        <div class="helptext">
            Select the source you want to connect to {{state.context.application.name}}. You can connect more sources later if needed.
            Different sources may support different functionality.
        </div>
        <nav class="flex flex-align">
            <div class="destination" @click="filter('lms')" :class="{active: category === 'lms'}">LMS</div>
            <div class="destination" @click="filter('sis')" :class="{active: category === 'sis'}">SIS</div>
            <div class="destination" @click="filter('sso')" :class="{active: category === 'sso'}">SSO</div>
        </nav>
        <div class="flex flex-wrap source-options" v-if="filtered.length">
            <div class="card source-option flex flex-column flex-center" v-for="p of filtered" :key="p.id" :class="{selected: selected(p.id)}" @click="() => change_selected_provider(p.id)">
                <div class="source-icon flex flex-align flex-center">
                    <div class="source-icon-wrapper">
                        <img :src="p.icon_url" />
                    </div>
                </div>
                <div class="source-name text-overflow center">{{p.name}}</div>
            </div>
            <div class="source-option placeholder none" v-for="i of [1, 2, 3, 4, 5]" :key="i"></div>
        </div>
        <div v-else class="nothing">
            There are no providers available in this category.
        </div>
        <div class="options flex">
            <div :class="{disabled: !provider}" class="button" kbd="Enter ⏎" @click="next">Continue</div>
        </div>  
    </div>
</template>

<script>
    export default {
        name: 'IntegrateSourceCreateProvider',
        props: {
            state: Object,
            send: Function,
            type: String
        },
        data(){
            return {
                category: 'lms'
            };
        },
        mounted(){
            window.addEventListener('keydown', this.keydown);
        },
        destroyed(){
            window.removeEventListener('keydown', this.keydown);
        },
        computed: {
            filtered(){
                return this.$parent.providers.filter(provider => {
                    return provider.categories.includes(this.category);
                })
                .sort((a, b) => a.name.localeCompare(b.name));
            },
            provider(){
                return this.type === 'destination' ? this.state.context.destination_provider : this.state.context.provider;
            }
        },
        methods: {
            change_selected_provider(provider_id) {
                const provider = this.$parent.providers.find(it => it.id === provider_id);
                const configuration = {};
                /*
                    Set default values to provider config
                */
                for (const [k, v] of Object.entries(provider.configuration)) {
                    if (v.default) {
                        configuration[k] = v.default;
                    } else {
                        switch(v.type) {
                            case 'boolean':
                                configuration[k] = false;
                                break;
                            case 'file':
                                configuration[k] = '';
                                break;
                            case 'number':
                                configuration[k] = 0;
                                break;
                            case 'string':
                                configuration[k] = '';
                                break;
                            default:
                                configuration[k] = null;
                        }  
                    }
                }

                this.send('SET_PROVIDER', {
                    data: {
                        source: {
                            provider,
                            provider_id: provider.id,
                            region_id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                            token: '',
                            configuration
                        }
                    }
                });
            },
            filter(category){
                this.category = category;
            },
            next(){
                if (this.provider) {
                    if (this.type === 'destination') {
                        this.send('UPDATE_SOURCE', {path: 'region_id', value: this.state.context.source.region_id || this.state.context.source.region?.id});
                        this.send('EDIT_CONFIG');
                    } else {
                        this.send('NEXT_STEP');
                    }
                }
            },
            keydown(e){
                if(e.code === 'Enter'){
                    this.next();
                }
            },
            selected(id){
                return this.provider ? this.provider.id === id : false;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-source-create-provider
    {
        align-items: flex-start;
    }

    nav
    {
        border-bottom: 1px solid @e4;
        margin-bottom: 20px;
        width: 100%;
    }

    .source-options
    {
        justify-content: space-between;
    }

    .source-option
    {
        margin: 0 0 15px 0;
        width: 108px;
        height: 108px;
        cursor: pointer;
        user-select: none;
		border-color: fade(@black, 15%);
		border-bottom-color: fade(@black, 20%);
		box-shadow: 0 1px 4px 0 fade(@black, 8%);
        overflow: hidden;
        transition: all ease 0.2s;

        &::after
        {
            content: "";
            position: absolute;
            top: 6px;
            left: 7px;
            width: 11px;
            height: 11px;
            background: url('~@/assets/icons/base/check.svg');
            background-size: 15px auto;
            background-position: -2px 0;
            background-repeat: no-repeat;
            transition: all ease 0.15s;
            display: block;
            transform: scale(0);
            z-index: 2;
        }

        &.selected
        {
            color: @base;
            border-color: @base;
            border-bottom-color: darken(@base, 5%);
            box-shadow: 0 2px 8px -1px fade(@base, 25%);

            .source-name
            {
                color: @base;
            }

            &::after
            {
                transform: scale(1);
            }

            img
            {
                box-shadow: 0 2px 8px -1px fade(@black, 25%);
            }
        }

        &.placeholder
        {
            margin: 0;
            height: 0;
        }
    }

    .source-icon
    {
        // pointer-events: none;

        img
        {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            transition: all ease 0.2s;
        }

        .source-icon-wrapper
        {
            width: 44px;
            height: 64px;
            padding: 8px 0 12px;

            &::after
            {
                width: 44px;
                height: 44px;
                box-sizing: border-box;
                position: absolute;
                z-index: 2;
                content: "";
                top: 8px;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
    }

    .source-name
    {
        height: 18px;
        transition: all ease 0.2s;
        color: @black;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        padding: 0 10px;
    }

    .nothing
    {
        width: 100%;
        font-size: 13px;
        padding: 30px;
    }
</style>
