<template>
    <section class="source-staged-changes" v-if="job">
        <div class="section-content ff">
            <h2>Staged Changes</h2>
            <div class="card staged-changes">
                <nav class="flex flex-align">
                    <a class="destination" @click="$emit('change_type', 'people')" :class="{active: staged_changes_type === 'people'}">People</a>
                    <a class="destination" @click="$emit('change_type', 'schools')" :class="{active: staged_changes_type === 'schools'}">Schools</a>
                    <a class="destination" @click="$emit('change_type', 'classes')" :class="{active: staged_changes_type === 'classes'}">Classes</a>
                    <a class="destination" @click="$emit('change_type', 'sections')" :class="{active: staged_changes_type === 'sections'}">Sections</a>
                    <a class="destination" @click="$emit('change_type', 'courses')" :class="{active: staged_changes_type === 'courses'}">Courses</a>
                    <a class="destination" @click="$emit('change_type', 'sessions')" :class="{active: staged_changes_type === 'sessions'}">Sessions</a>
                    <a class="destination" @click="$emit('change_type', 'enrollments')" :class="{active: staged_changes_type === 'enrollments'}">Enrollments</a>
                    <a class="destination" @click="$emit('change_type', 'agents')" :class="{active: staged_changes_type === 'agents'}">Agents</a>
                    <a class="destination" @click="$emit('change_type', 'meetings')" :class="{active: staged_changes_type === 'meetings'}">Meetings</a>
                    <a class="destination" @click="$emit('change_type', 'attendance')" :class="{active: staged_changes_type === 'attendance'}">Attendance</a>
                    <a class="destination" @click="$emit('change_type', 'departments')" :class="{active: staged_changes_type === 'departments'}">Departments</a>
                    <a class="destination" @click="$emit('change_type', 'subjects')" :class="{active: staged_changes_type === 'subjects'}">Subjects</a>
                    <a class="destination" @click="$emit('change_type', 'periods')" :class="{active: staged_changes_type === 'periods'}">Periods</a>
                    <a class="destination" @click="$emit('change_type', 'incidents')" :class="{active: staged_changes_type === 'incidents'}">Incidents</a>
                    <a class="destination" @click="$emit('change_type', 'facilities')" :class="{active: staged_changes_type === 'facilities'}">Facilities</a>
                    <a class="destination" @click="$emit('change_type', 'rooms')" :class="{active: staged_changes_type === 'rooms'}">Rooms</a>
                    <a class="destination" @click="$emit('change_type', 'assets')" :class="{active: staged_changes_type === 'assets'}">Assets</a>
                    <a class="destination" @click="$emit('change_type', 'vehicles')" :class="{active: staged_changes_type === 'vehicles'}">Vehicles</a>
                    <a class="destination" @click="$emit('change_type', 'routes')" :class="{active: staged_changes_type === 'routes'}">Routes</a>
                    <a class="destination" @click="$emit('change_type', 'stops')" :class="{active: staged_changes_type === 'stops'}">Stops</a>
                    <a class="destination" @click="$emit('change_type', 'fees')" :class="{active: staged_changes_type === 'fees'}">Fees</a>
                    <a class="destination" @click="$emit('change_type', 'calendars')" :class="{active: staged_changes_type === 'calendars'}">Calendars</a>
                    <a class="destination" @click="$emit('change_type', 'days')" :class="{active: staged_changes_type === 'days'}">Days</a>
                </nav>
                <datatable v-if="staged.length" class="no-last-border" :columns="columns" :rows="staged" :selectable="true" :header="true" :clickable="false">
                    <template v-slot:empty-message>You have not yet created any sources.</template>
                </datatable>
                <div class="nothing" v-else-if="['working', 'pending', 'staged'].includes(job.status)">
                    No {{staged_changes_type}} were changed during this sync.
                </div>
                <div class="nothing" v-else>
                    All staged changes have already been applied or discarded.
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {
        Fingerprint,
        Text,
        CursorPointer
    } from '@epiphany/iconoir';

    export default {
        name: 'SyncStagedChanges',
        props:{
            staged_changes_type: String,
            job: Object,
            staged: Array,
        },
        data () {
            return {
                columns: [
                    {
                        id: 'action',
                        name: 'Action',
                        icon: CursorPointer,
                        value: row => row.staged.change_type, 
                        classes: 'capitalize'
                    },
                    {
                        id: 'name',
                        name: 'Name',
                        icon: Text,
                        value: row => row.display_name || row.name || 'N/A'
                    },
                    {
                        id: 'hash',
                        name: 'Hash',
                        icon: Fingerprint,
                        value: row => row.hash
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .nothing
    {
        padding: 50px;
        text-align: center;
    }

    .staged-changes
    {
        nav
        {
            padding: 0 20px;
            border-bottom: 1px solid @e4;
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 1px;
        }

        .nothing
        {
            font-size: 13px;
            border: 0;
        }
    }

</style>