<template>
    <div>
        <div class="help-title" :class="{description}">{{title ? title : 'Help'}}</div>
        <div class="help-description">
            <slot>
                <template v-if="Array.isArray(description)">
                    <ul>
                        <li v-for="item in description">
                            {{item}}
                        </li>
                    </ul>
                </template>
                <template v-else-if="description">{{description}}</template>
            </slot>
        </div>
        <template v-if="html">
            <section class="html-content" v-html="html"></section>
        </template>
        <div class="options-list">
            <a class="option" v-for="(option, index) of options" :key="index" @click="select(option)" :class="option.color">{{option.title}}</a>
            <a class="option grey" @click="$emit('close')">Close</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Help',
        props: {
            title: String,
            description: String | Array,
            options: Array,
            html: String | HTMLElement
        },
        methods: {
            select(option){
                option.fn();
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    @option_height: 44px;

    ul
    {
        list-style-type: none;
        margin: 0;
        text-align: left;

        & > li
        {
            margin-bottom: 5px;

            &::before
            {
                content: "\2013";
                margin-right: 8px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .help-title
    {
        font-size: 14px;
        color: @base;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        text-align: center;

        &.description
        {
            line-height: 24px;
            padding-top: 15px;
            height: 39px;
        }
    }

    .help-description
    {
        font-size: 13px;
        color: @black;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        padding: 8px 25px 15px;
    }

    .option
    {
        display: block;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        border-top: 1px solid @e;
        text-align: center;
        font-size: 16px;
        color: @base;
        transition: background-color ease 0.2s;
        cursor: pointer;
        user-select: none;

        &.grey
        {
            color: @grey;
        }

        &.red
        {
            color: @red;
        }

        &:hover, &:active
        {
            background: @f8;
        }
    }

    .html-content
    {
        padding: 15px;
        border-top: 1px solid @e;
        overflow-y: scroll;
        max-height: 400px;
    }
</style>
