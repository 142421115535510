<template>
    <div class="application-settings full scroll" v-if="application">
        <section>
            <h2>Technical Configuration</h2>
            <div class="application-configuration">
                <div class="form-field">
                    <label>Application Name</label>
                    <input type="text" v-model="name" class="medium" />
                </div>
                <div class="form-field">
                    <label>Description</label>
                    <div class="form-helptext">Describe your application for school administrators.</div>
                    <textarea type="text" class="large" v-model="description"></textarea>
                </div>
                <div class="form-field">
                    <label>LTI Context Version</label>
                    <div class="form-helptext">
                        When a user LTI launches into your application, Edlink will provide additional details about the LTI launch context such
                        as the user's class and the assignment they launched into. What version of the Edlink data model does your application expect?
                    </div>
                    <select v-model="lti_context_version" class="small">
                        <option :value="undefined" disabled>Select a version</option>
                        <option value="1">Edlink API v1.0</option>
                        <option value="2">Edlink API v2.0</option>
                    </select>
                </div>
                <div class="form-field">
                    <label>Default Transformations</label>
                    <div class="form-helptext">
                        When a new district connects to your application, these blocks will automatically be applied as custom transformations.
                    </div>
                    <datatable :columns="columns" :rows="application.default_block_ids" :header="true" class="card default-block-ids">
                        <template v-slot:empty>
                            <div class="no-block-ids-message">Your application does not have default transformations.</div>
                        </template>
                    </datatable>
                    <div class="flex flex-align">
                        <div class="button has-icon white flex flex-align" @click="add_default_block">
                            <GridPlus width="16" height="16" stroke-width="2" />
                            <div class="ff">Add Transformation</div>
                        </div>
                    </div>
                </div>
                <div class="form-field">
                    <label>Setup Redirect URI</label>
                    <div class="form-helptext">
                        This is where we will redirect administrators after they complete the onboarding process. <br />
                        If this is left blank, they will see our default success page.
                    </div>
                    <input type="text" v-model="setup_redirect_uri" class="large" />
                </div>
                <div class="flex form-options">
                    <div class="button" @click="save">Save Configuration</div>
                </div>
            </div>
        </section>
        <section>
            <h2 class="danger-zone flex flex-align">
                <div>Danger Zone</div>
                <span class="icon iconoir-shield-alert"></span>
            </h2>
            <h4>Destroy Application</h4>
            <div class="disconnect-warning">
                This process is irreversible.
                We will destroy this application and all delete any associated integrations.
                All data from this application will be scrubbed from our database and any integrated schools will no longer have access.
            </div>
            <div class="form-options flex">
                <div class="red button" @click="destroy" v-if="application.status !== 'destroyed'">Destroy Application</div>
                <div class="button disabled" v-else>Application Destroyed</div>
            </div>
        </section>
        <section v-if="admin">
            <form @submit.prevent="admin_save">
                <h2 class="admin-settings flex flex-align">
                    <div>Admin Settings</div>
                    <span class="icon iconoir-tower"></span>
                </h2>
                <div class="form-field">
                    <label>Default Integration Billing Category</label>
                    <div class="form-helptext">The default billing category used when new integrations are created with this application.</div>
                    <div class="flex">
                        <select class="small" v-model="default_billing_category">
                            <option :value="undefined" hidden>Select...</option>
                            <option :value="null" hidden>Select...</option>
                            <option value="development">Development</option>
                            <option value="standard">Standard</option>
                        </select>
                    </div>
                </div>
                <div class="form-field">
                    <label>Application Status</label>
                    <div class="form-helptext">The status of this application.</div>
                    <div class="flex">
                        <select class="small" v-model="status">
                            <option value="development">Development</option>
                            <option value="awaiting_approval">Awaiting Approval</option>
                            <option value="approved">Approved</option>
                            <option value="released">Released</option>
                            <option value="sunsetting">Sunsetting</option>
                            <option value="destryorf">Destroyed</option>
                        </select>
                    </div>
                </div>
                <div class="flex form-options">
                    <button type="submit">Save Changes</button>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Options from '@/components/modals/Options.vue';
    import { GridPlus, AlignLeft, DeleteCircle } from '@epiphany/iconoir';
    import CreateTransformation from '@/components/drawers/CreateTransformation';
    import RemoveIcon from '@/components/RemoveIcon';

    export default {
        name: 'ApplicationSettings',
        components: {
            GridPlus,
            AlignLeft,
            DeleteCircle
        },
        data() {
            return {
                blocks: [],
                columns: [
                    {
                        id: 'name',
                        icon: AlignLeft,
                        name: 'Block Name',
                        value: row => {
                            return this.blocks.find(item => item.id === row)?.title ?? row;
                        }
                    },
                    {
                        id: 'remove_action',
                        name: '',
                        align: 'center',
                        width: '36px',
                        on: {
                            click: ({row}) => {
                                this.$store.commit('applications/update', {
                                    default_block_ids: this.application.default_block_ids.filter(block => block !== row)
                                });
                            }
                        },
                        component: RemoveIcon
                    }
                ]
            };
        },
        created(){
            // Load existing blocks so we have their titles.
            this.$http.get(`/teams/${this.team.id}/blocks`)
            .then(response => this.blocks = response.$data)
            .catch(() => this.$toasted.error('There was an error loading your blocks.'));
        },
        methods: {
            add_default_block(){
                const that = this;
            
                this.$store.dispatch('drawer/open', {
                    key: 'create-transformation',
                    width: 450,
                    component: CreateTransformation,
                    props: {
                        application: this.application,
                        create: (block) => {
                            if(block){
                                if(that.application.default_block_ids.includes(block.id)){
                                    return that.$toasted.info('This block is already a default transformation.');
                                }

                                this.$store.commit('applications/update', {
                                    default_block_ids: [...that.application.default_block_ids, block.id]
                                });
                            }
                        }
                    }
                });
            },
            destroy(){
                const _this = this;

                this.$modal.show(
                    Options,
                    {
                        title: 'Destroy Application',
                        description: 'Are you sure you want to destroy this application? This will remove all any data stored about your application as well as destroy any active integrations connected to this application.',
                        options: [
                            {
                                title: `Destroy This Application`,
                                color: 'red',
                                fn() {
                                    _this.$store
                                        .dispatch('applications/destroy')
                                        .then(() => _this.$toasted.success('Application has been marked for removal.'))
                                        .catch((error) => _this.$toasted.error('There was an error marking the application for removal.'));
                                }
                            },
                            {
                                title: `Keep This Application`,
                                color: 'blue',
                                fn() {}
                            }
                        ]
                    },
                    { width: 300, height: 'auto', classes: 'modal' }
                );
            },
            save(){
                this.$store.dispatch('applications/update');
            },
            admin_save(){
                this.$store.dispatch('applications/admin_update');
            }
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            team(){
                return this.$store.getters.team;
            },
            admin(){
                return this.$store.getters.user?.admin;
            },
            status: {
                get(){
                    return this.application.status;
                },
                set(status){
                    this.$store.commit('applications/update', { status });
                }
            },
            name: {
                get(){
                    return this.application.name;
                },
                set(name){
                    this.$store.commit('applications/update', { name });
                }
            },
            description: {
                get(){
                    return this.application.description;
                },
                set(description){
                    this.$store.commit('applications/update', { description });
                }
            },
            setup_redirect_uri: {
                get(){
                    return this.application.setup_redirect_uri;
                },
                set(setup_redirect_uri){
                    this.$store.commit('applications/update', { setup_redirect_uri });
                }
            },
            lti_context_version: {
                get(){
                    return this.application?.properties?.lti_context_version;
                },
                set(lti_context_version){
                    this.$store.commit('applications/update', { 'properties.lti_context_version': parseInt(lti_context_version) });
                }
            },
            default_billing_category: {
                get(){
                    return this.application?.properties?.default_billing_category;
                },
                set(default_billing_category){
                    this.$store.commit('applications/update', { 'properties.default_billing_category': default_billing_category });
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .form-fields
    {
        padding: 0;
        margin-top: 30px;

        .form-field {
            margin: 0 30px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .disconnect-warning
    {
        font-size: 13px;
        line-height: 18px;
        color: @grey;
        max-width: 700px;
    }

    .nothing
    {
        padding: 30px;

        .button
        {
            margin-top: 20px;
        }
    }

    .form-helptext
    {
        max-width: 700px;
    }

    .form-options
    {
        padding-bottom: 0;
    }

    .default-block-ids
    {
        max-width: 450px;
        margin-bottom: 10px;

        .no-block-ids-message
        {
            padding: 20px;
            text-align: center;
            color: @grey;
            font-size: 14px;
        }
    }
</style>
