<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <TerminalCircled class="icon" width="16" height="16" stroke-width="2" />
                    Request Logs
                </div>
            </template>
            <template v-slot:actions>
                <div class="button white has-icon" title="Export Logs" @click="save()">
                    <span class="icon iconoir-database-export"></span>
                    Download
                </div>
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="regions" :active="region" @activate="switch_region" :required="true" label="Region">
                <template v-slot:icon>
                    <PinAlt class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <img class="block" :src="item.picture" />
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="integrations.items" label="Integration" :active="integration" @search="integration_source" :searchable="true" @activate="switch_integration">
                <template v-slot:icon>
                    <Bolt class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="thumbnail">
                                <img class="block" :src="item.picture" />
                            </div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="methods" :active="method" @activate="switch_method" label="Method">
                <template v-slot:icon>
                    <DataTransferBoth class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <span class="block icon" :class="item.icon"></span>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="categories" :active="category" @activate="switch_category" label="Category">
                <template v-slot:icon>
                    <KanbanBoard class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <span class="block icon" :class="item.icon"></span>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="statuses" :active="status" @activate="switch_status" label="Status">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <!-- <filter-dropdown :items="[]" label="Person">
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown> -->

            <filter-dropdown custom borderless :active="active_date" @activate="change_date" label="Date Range">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:custom="{ activate }">
                    <date-picker @input="activate" v-model="date" v-bind="{
                        type: 'date',
                        format: 'MM-DD-YYYY',
                        range: true,
                        inline: true,
                        'range-separator': '-',
                        shortcuts
                    }">
                    </date-picker>
                </template>
            </filter-dropdown>

            <!-- <filter-dropdown custom borderless :active="active_time" label="Time Range">
                <template v-slot:custom>
                    <date-picker @input="reset()" v-model="time" v-bind="{
                        type: 'time',
                        format: 'H:mm',
                        range: true,
                        'show-time-header': true,
                        'time-title-format': 'H:mm',
                        inline: true }">
                    </date-picker>
                </template>
            </filter-dropdown> -->

            <div class="ff"></div>
            <pages :all="all" :rows="requests" :more="more" :count="count" :page="page" @next="next" @previous="previous" />
        </filters>
        <datatable class="logs full" :columns="columns" :rows="requests" :selectable="true" :header="true" @clicked="row => open(row)" :loading="loading" :clickable="true" />
    </main>
</template>

<script>
    import { TerminalCircled, DataTransferBoth, Bolt, KanbanBoard, Calendar, PinAlt, WhiteFlag, GitFork } from '@epiphany/iconoir';
    import parser from 'papaparse';
    import Options from '@/components/modals/Options.vue';
    import TableRequestStatus from '@/components/table/TableRequestStatus.vue';
    import _ from 'lodash';

    export default {
        name: 'Logs',
        components: {
            TerminalCircled,
            DataTransferBoth,
            Bolt,
            KanbanBoard,
            Calendar,
            PinAlt,
            WhiteFlag
        },
        data(){
            return {
                page: 0,
                count: 50,
                all: [],
                more: null,
                loading: false,
                date: null,
                time: null,
                region: null,
                shortcuts: [
                    { text: 'Today', onClick: () => {
                            const date = new Date();
                            date.setHours(0);
                            date.setMinutes(0);
                            return [date, new Date()]
                        }
                    },
                    {
                        text: 'Last 24 hrs',
                        onClick: () => {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Week',
                        onClick: () => {
                            const date = new Date();
                            date.setDate(date.getDate() - 7);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Month',
                        onClick: () => {
                            const date = new Date();
                            date.setMonth(date.getMonth() - 1);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Year',
                        onClick: () => {
                            const date = new Date();
                            date.setFullYear(date.getFullYear() - 1);
                            return [date, new Date()];
                        },
                    }
                ],
                regions: [
                    {
                        spacer: true,
                        header: 'Regional Requests'
                    },
                    {
                        id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                        name: 'United States',
                        picture: '/flags/us.svg'
                    },
                    {
                        id: '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd',
                        name: 'Canada',
                        picture: '/flags/ca.svg'
                    },
                    {
                        id: '2572d321-6d8b-458b-8024-8e5ab6bad7b6',
                        name: 'Germany',
                        picture: '/flags/de.svg'
                    },
                    {
                        id: '2104f649-fe39-4fa1-96be-8682e9840dcf',
                        name: 'Australia',
                        picture: '/flags/au.svg'
                    },
                    {
                        spacer: true,
                        header: 'Meta Requests'
                    },
                    {
                        id: '4dc3afaa-f810-469e-8605-777af943b984',
                        name: 'Dashboard',
                        picture: '/flags/edlink.svg'
                    }
                ],
                category: null,
                categories: [
                    {
                        id: 'sso',
                        name: 'Single Sign On',
                        icon: 'iconoir-people-rounded'
                    },
                    {
                        id: 'rostering',
                        name: 'Rostering',
                        icon: 'iconoir-database-backup'
                    },
                    {
                        id: 'coursework',
                        name: 'Assignments',
                        icon: 'iconoir-journal'
                    },
                    {
                        id: 'grading',
                        name: 'Grades',
                        icon: 'iconoir-cloud-sync'
                    }
                ],
                method: null,
                methods: [
                    {
                        id: 'GET',
                        name: 'Get',
                        icon: 'iconoir-arrow-down'
                    },
                    {
                        id: 'POST',
                        name: 'Post',
                        icon: 'iconoir-arrow-up'
                    },
                    {
                        id: 'PUT',
                        name: 'Put',
                        icon: 'iconoir-arrow-right'
                    },
                    {
                        id: 'PATCH',
                        name: 'Patch',
                        icon: 'iconoir-combine'
                    },
                    {
                        id: 'DELETE',
                        name: 'Delete',
                        icon: 'iconoir-cancel'
                    }
                ],
                status: null,
                statuses: [
                    {
                        id: 'succeeded',
                        name: 'All Successful Requests',
                        icon: 'blue'
                    },
                    {
                        id: 'failed',
                        name: 'All Failed Requests',
                        icon: 'blue'
                    },
                    {
                        spacer: true,
                        header: 'Success Statuses'
                    },
                    {
                        id: '200',
                        name: '200 OK',
                        icon: 'green'
                    },
                    {
                        id: '201',
                        name: '201 Created',
                        icon: 'green'
                    },
                    {
                        id: '204',
                        name: '204 No Content',
                        icon: 'green'
                    },
                    {
                        spacer: true,
                        header: 'Failure Statuses'
                    },
                    {
                        id: '400',
                        name: '400 Bad Request',
                        icon: 'red'
                    },
                    {
                        id: '401',
                        name: '401 Unauthorized',
                        icon: 'red'
                    },
                    {
                        id: '403',
                        name: '403 Forbidden',
                        icon: 'red'
                    },
                    {
                        id: '404',
                        name: '404 Not Found',
                        icon: 'red'
                    },
                    {
                        id: '500',
                        name: '500 Internal Server Error',
                        icon: 'red'
                    },
                    {
                        id: '502',
                        name: '502 Bad Gateway',
                        icon: 'red'
                    },
                    {
                        id: '503',
                        name: '503 Service Unavailable',
                        icon: 'red'
                    }
                ],
                integration: null,
                integrations: {
                    all: [],
                    items: null,
                    promise: null,
                    query: ''
                },
                columns: [
                    {
                        id: 'method',
                        name: 'Method',
                        icon: DataTransferBoth,
                        width: '120px'
                    },
                    {
                        id: 'path',
                        name: 'Path',
                        icon: GitFork,
                        width: '70%'
                    },
                    {
                        id: 'status',
                        name: 'Status',
                        icon: WhiteFlag,
                        component: TableRequestStatus,
                        classes: 'raw',
                        width: '120px'
                    },
                    {
                        id: 'date',
                        name: 'Date',
                        icon: Calendar,
                        align: 'right',
                        width: '30%',
                        value: row => this.$options.filters.pretty(row.date, 'long')
                    }
                ]
            };
        },
        computed: {
            requests(){
                return this.all.slice(this.page * this.count, this.page * this.count + this.count);
            },
            active_date(){
                if (this.date && Array.isArray(this.date) && this.date.length) {
                    const [start, end] = this.date;
                    return {
                        name: `${this.$options.filters.pretty(start, 'short')} - ${this.$options.filters.pretty(end, 'short')}`
                    };
                } else {
                    return null;
                }
            },
            active_time(){
                if (this.time && this.time.length) {
                    return {
                        name: 'Range Selected'
                    };
                } else {
                    return null;
                }
            }
        },
        methods: {
            open(request){
                this.$router.push({name: 'team.logs.request', params: {request: request.id}});
            },
            save() {
                const _this = this;

                this.$modal.show(Options, {
                    title: `Download Logs`,
                    description: 'These logs may contain sensitive student data. Please ensure you are downloading them to a secure location and handling them in accordance with your data protection policy.',
                    options: [
                        {
                            title: `I Understand, Download Logs`,
                            color: 'red',
                            async fn() {
                                const data = await _this.export_load();
                                const date = new Date().toJSON().slice(0,10);
                                const filename = date + ' Request Logs';

                                //csv columns -> id,method,path,date,status,start_date,end_date,output,input,integration_id
                                const rows = [
                                    ['date', 'id', 'method', 'path', 'status', 'start_date', 'end_date', 'output', 'input', 'integration_id']
                                ];
                                    
                                for (const log of data.$data) {
                                    rows.push([
                                        log.date,
                                        log.id,
                                        log.method,
                                        log.path,
                                        log.status,
                                        log.start_date,
                                        log.end_date,
                                        JSON.stringify(log.output),
                                        JSON.stringify(log.input),
                                        log.integration_id
                                    ]);                    
                                }

                                const parsed_csv = parser.unparse(rows);
                            
                                const blob = new Blob([parsed_csv], { type: 'text/csv' });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');

                                document.body.appendChild(a);
                                a.href = url;
                                a.download = filename;
                                a.click();

                                setTimeout(() => {
                                    window.URL.revokeObjectURL(url);
                                    document.body.removeChild(a);
                                }, 0);
                            }
                        }
                    ]
                }, { width: 300, height: 'auto', classes: 'modal' });
            },
            integration_source(query){
                this.integrations.query = query;

                if(!this.integrations.promise){
                    this.integrations.promise = this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`).then(response => {
                        this.integrations.all = response.$data.map(integration => {
                            return {
                                id: integration.id,
                                name: integration.team.name,
                                picture: integration.provider.icon_url
                            };
                        })
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
                
                return this.integrations.promise.then(() => {
                    if(this.integrations.query){
                        this.integrations.items = this.integrations.all.filter(integration => {
                            return integration.name.toLowerCase().indexOf(this.integrations.query.toLowerCase()) > -1;
                        }).slice(0, 50);
                    }else{
                        this.integrations.items = this.integrations.all.slice(0, 50);
                    }
                });
            },
            switch_integration(integration){
                this.integration = integration;
                this.reset();
            },
            switch_method(method){
                this.method = method;
                this.reset();
            },
            switch_region(region){
                this.region = region;
                this.reset();
            },
            switch_category(category){
                this.category = category;
                this.reset();
            },
            switch_status(status){
                this.status = status;
                this.reset();
            },
            change_date(date){
                this.date = date;
                this.reset();
            },
            previous(){
                if(this.loading){
                    return;
                }

                this.page = this.page ? this.page - 1 : 0;
            },
            next(){
                if(this.loading){
                    return;
                }

                if(this.all.length <= (this.page + 1) * this.count){
                    // Wait until the page is done loading to increment the page number.
                    this.load().then(() => this.page += 1);
                }else{
                    this.page += 1;
                }
            },
            reset(){
                this.all = [];
                this.more = false;
                this.page = 0;
                this.load();
            },
            load(){
                if(this.loading){
                    return;
                }

                this.loading = true;

                const params = {
                    $last: this.count,
                    $fields: 'id,method,path,date,status',
                    $filter: {
                        region_id: [{
                            operator: 'equals',
                            value: this.region.id
                        }]
                    }
                };

                if(this.method){
                    params.$filter.method = [{
                        operator: 'equals',
                        value: this.method.id
                    }];
                }

                if(this.category){
                    params.$filter.categories = [{
                        operator: 'in',
                        value: this.category.id
                    }];
                }

                if(this.integration){
                    params.$filter.integration_id = [{
                        operator: 'equals',
                        value: this.integration.id
                    }];
                }

                if(this.status){
                    const values = [];

                    if(this.status.id === 'succeeded'){
                        values.push(200, 201, 204);
                    }else if(this.status.id === 'failed'){
                        values.push(400, 401, 403, 404, 500, 502, 503);
                    }else{
                        values.push(this.status.id);
                    }

                    params.$filter.status = [{
                        operator: 'in',
                        value: values.join(',')
                    }];
                }

                if(this.all.length){
                    params.$before = this.all[this.all.length - 1].id;
                }

                if(this.date && Array.isArray(this.date)) {
                    this.date = this.date.filter(el => el);

                    if (this.date.length) {
                        params.$filter.date = [
                            {
                                operator: 'gte',
                                value: this.date[0].toISOString()
                            },
                            {
                                operator: 'lte',
                                value: this.date[1].toISOString()
                            }
                        ];
                    }
                } else {
                    // TODO: Maybe filter by singular date gt/lt?
                }

                // Push the state to the browser history.
                let $filter = {};

                try {
                    $filter = JSON.parse(this.$route.query.$filter);
                }catch(error){
                    // Do nothing.
                }

                if(!_.isEqual($filter, params.$filter)){
                    this.$router.replace({
                        query: {
                            $filter: JSON.stringify(params.$filter)
                        }
                    });
                }

                return this.$http.get(`/teams/${this.$store.getters.team.id}/requests`, {
                    params,
                    baseURL: '/api/v2'
                })
                .then(response => {
                    // Add these new requests to the list of all requests.
                    this.all.push(...response.$data);

                    // We don't really care about the cursor right now, we just want to know if there are more results.
                    this.more = true; //response.$cursor;
                })
                .catch(error => this.$toasted.error('There was an error loading the requests for your applications.'))
                .finally(() => this.loading = false);
            },
            export_load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;                

                const params = {
                    $last: this.count,
                    $fields: 'id,method,path,date,status,start_date,end_date,output,input,integration_id',
                    $filter: {
                        region_id: [
                            {
                                operator: 'equals',
                                value: this.region.id
                            }
                        ]
                    }
                };

                if (this.method) {
                    params.$filter.method = [
                        {
                            operator: 'equals',
                            value: this.method.id
                        }
                    ];
                }

                if(this.category){
                    params.$filter.categories = [
                        {
                            operator: 'in',
                            value: this.category.id
                        }
                    ];
                }

                if (this.integration) {
                    params.$filter.integration_id = [
                        {
                            operator: 'equals',
                            value: this.integration.id
                        }
                    ];
                }

                if (this.status) {
                    const values = [];

                    if (this.status.id === 'succeeded') {
                        values.push(200, 201, 204);
                    } else if (this.status.id === 'failed') {
                        values.push(400, 401, 403, 404, 500, 502, 503);
                    } else {
                        values.push(this.status.id);
                    }

                    params.$filter.status = [
                        {
                            operator: 'in',
                            value: values.join(',')
                        }
                    ];
                }

                params.$last = 500; //the amount of logs we want to let them download

                return this.$http
                    .get(`/teams/${this.$store.getters.team.id}/requests`, {
                        params,
                        baseURL: '/api/v2'
                    })
                    .then((response) => {
                        //returns all 500 (or less) rows
                        return response;
                    })
                    .catch((error) => this.$toasted.error('There was an error loading the requests for your applications.'))
                    .finally(() => (this.loading = false));
            }
        },
        async created(){
            // Check to see if there are query parameters set before we load().
            // If there is an integration ID present, we should load those first.
            if(this.$route.query.$filter){
                const params = JSON.parse(this.$route.query.$filter);

                if(params.region_id){
                    this.region = this.regions.find(region => region.id === params.region_id[0].value);
                }

                if(params.method){
                    this.method = this.methods.find(method => method.id === params.method[0].value);
                }

                if(params.category){
                    this.category = this.categories.find(category => category.id === params.category[0].value);
                }

                if(params.status){
                    // Try to parse into an array of statuses.
                    const statuses = params.status[0].value.split(',');
                    // If there are multiple statuses, we should check to see if they are all success or all failure.
                    if (statuses.length > 1) {
                        if(_.difference(statuses, ['200', '201', '204']).length === 0){
                            // If the statuses are all success codes, we should set the status to succeeded.
                            this.status = this.statuses.find(status => status.id === 'succeeded');
                        } else if (_.difference(statuses, ['400', '401', '403', '404', '500', '502', '503']).length === 0) {
                            // If the statuses are all failure codes, we should set the status to failed.
                            this.status = this.statuses.find(status => status.id === 'failed');
                        }
                    } else {
                        // Otherwise we just have one status code so set it to filter by that
                        this.status = this.statuses.find(status => status.id === params.status[0].value);
                    }
                }

                if(params.date){
                    const gte = new Date(params.date.find(date => date.operator === 'gte')?.value);
                    const lte = new Date(params.date.find(date => date.operator === 'lte')?.value);

                    // TODO Support for single dates.
                    this.date = [gte, lte];
                }

                if(params.integration_id){
                    await this.integration_source();

                    const integration = this.integrations.all.find(integration => integration.id === params.integration_id[0].value);

                    if(integration){
                        this.integration = integration;
                    }
                }
            }

            if(!this.region){
                // Set the default region to United States.
                this.region = this.regions.find(region => region.id === '8c5475f1-32d3-479d-8a6a-3c6b6e524f49');
            }

            this.load();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }

    .logs.full
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .request
    {
        user-select: none;
        cursor: pointer;
    }

    .request-status
    {
        width: 90px;
    }

    .request-date
    {
        width: 300px;
        font-size: 12px;
    }

    .request-path
    {
        font-weight: 400;
        font-size: 12px;
        max-width: 400px;
        color: @grey;

        span
        {
            color: @black;
            font-weight: 500;
        }
    }

    .request-status-badge
    {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        color: @green;
        font-family: @monospace;

        &.error
        {
            color: @red;
        }
    }

    .request-log-options
    {
        padding: 20px 0 0;
        user-select: none;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .request-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;

        &.red
        {
            background: @red;
        }

        &.blue
        {
            background: @base;
        }
    }

    .thumbnail
    {
        width: 16px;
        height: 16px;

        img
        {
            border-radius: 50%;
        }

        &::after
        {
            width: 16px;
            height: 16px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }

    .requests-loading
    {
        height: 200px;
    }

    .nothing
    {
        margin-top: 20px;
    }
</style>