<template>
    <div class="admin-people">
       

 <section class="flex">
            <div class="section-content ff">
                <h2>Person Integration Information</h2>



                <div v-for="(integration, index) of integrations" class="summary">
                   
                   <h2>Integration number {{ index + 1}}</h2>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Id</div>
                        <div  class="summary-value">{{ integration.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Created Date</div>
                        <div class="summary-value">{{ integration.created_date }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Updated Date</div>
                        <div class="summary-value">{{ integration.updated_date }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Start Date</div>
                        <div class="summary-value">{{ integration.start_date }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration End Date</div>
                        <div class="summary-value">{{ integration.end_date }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Scope</div>
                        <div class="summary-value">{{ integration.scope }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration State</div>
                        <div class="summary-value">{{ integration.state }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Access Token</div>
                        <div class="summary-value">{{ integration.access_token }}</div>
                    </div>
                    <hr>
                    <br>
                                        
                </div>
                
            </div>
        </section>  
            
    </div>
</template>

<script>
export default {
    name: 'AdminPerson',
    data() {
        return {
            integrations: [],
            search: ''

        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/integrations`)  
            .then((response) => {
              
                this.integrations = response.$data;  
               
            })
            .catch((error) => {
                this.$toasted.error(
                    'Failed to load integrations'
                );
            });
    },
    methods: {

    },
    computed: {

    },
};
</script>

<style scoped lang="less">


@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;
      
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;

       
    }
}

.ff {
    padding: 15px;
}


</style>
