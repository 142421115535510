<template>
    <div class="application-validation full scroll" v-if="application">
        <section class="validation-rules">
            <div class="header flex flex-align">
                <h2>Configure Validation</h2>
                <div class="ff"></div>
                <div class="button" @click="update">
                    Save
                </div>
            </div>
            <div class="rule-instructions">
                <p>
                    From this screen, you can create rules that will validate the data Edlink syncs before it reaches your application.
                    Each rule allows you to specify an entity, a property, and a strategy for validation.
                </p>
                <!-- <div class="divider"></div> -->
            </div>
            <div class="rules" v-if="rules.length">
                <ValidationRule v-for="(rule, $index) of rules" :rule="rule" @input="v => updateRule($index, v)" @remove="removeRule($index)" :key="rule.id"/>
            </div>
            <div class="flex flex-align add-rule-container">
                <div class="button" @click="addRule">
                    Add rule
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Vue from 'vue';
    import uuid from 'uuid';
    import _ from 'lodash';
    import { entities, entity_properties } from '@/constants';
    import ValidationRule from '@/components/ValidationRule';

    export default {
        name: 'ApplicationValidation',
        components: {
            ValidationRule
        },
        data(){
            return {
                rules: [],
                definitions: {
                    'Not Null': 'not_null',
                    'Email': 'email',
                    'Lynx': 'lynx',
                    'Custom': 'custom'
                },
                entities: entities,
                properties: entity_properties
            };
        },
        methods: {
            update(){
                const required_fields = ['entity', 'property', 'definition', 'type', 'remove'];
                for (const rule of this.rules) {
                    for (const field of required_fields) {
                        if (!_.has(rule, field) || _.get(rule, field) === null) {
                            this.$toasted.error(`At least one rule is missing the \`${field}\` field`);
                            return;
                        }
                    }
                
                }
                this.$store.commit('applications/update', { properties: { validation: this.rules }});
                this.$store.dispatch('applications/update');
            },
            getProperties(entity) {
                const excluded_properties = ['id', 'created_date', 'updated_date'];
                return this.properties[entity]?.filter((p) => !excluded_properties.includes(p.property)) ?? [];
            },
            addRule(){
                this.rules.push({
                    id: uuid.v4(),
                    entity: null,
                    property: null,
                    definition: null,
                    type: null,
                    remove: false,
                    default_value: null,
                    function: null
                });
            },
            removeRule(index){
                this.rules.splice(index, 1);
            },
            updateRule(index, updated) {
                this.rules.splice(index, 1, updated);
            }
        },
        mounted(){
            Vue.set(this, 'rules', _.cloneDeep(_.get(this.application, 'properties.validation', [])));
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            admin(){
                return this.$store.getters.user?.admin;
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    @rules-max-width: 1000px;

    .header {
        margin-bottom: 25px;
    }
    
    h2
    {
        color: @black;
        font-size: 16px;
        // margin-bottom: 25px;
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .validation-rules {
        max-width: @rules-max-width;
    }

    .rule-instructions {
        color: @black;
        font-size: 15px;
        line-height: 22px;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }
        }

        .divider {
            margin: 25px 0 15px;
            height: 6px;
            background: url('~@/assets/zigzag.svg') top left repeat-x;
            background-size: auto 6px;
            opacity: 0.2;
        }

        .rule-back {
            svg {
                display: block;
                margin-right: 4px;
            }
        }
    }
</style>
