<template>
    <chip :color="states[state].color" :title="states[state].name" />
</template>

<script>
    export default {
        name: 'State',
        props: {
            state: String
        },
        data(){
            return {
                states: {
                    'requested': { color: 'purple', name: 'Requested' },
                    'active': { color: 'green', name: 'Active' },
                    'inactive': { color: 'purple', name: 'Inactive' },
                    'paused': { color: 'yellow', name: 'Paused' },
                    'upcoming': { color: 'purple', name: 'Upcoming' },
                    'disabled': { color: 'red', name: 'Disabled' },
                    'error': { color: 'red', name: 'Error' },
                    'killed': { color: 'red', name: 'Killed' },
                    'destroyed': { color: 'grey', name: 'Destroyed' },
                    'queued': { color: 'grey', name: 'Queued' },
                    'pending': { color: 'orange', name: 'Pending' },
                    // Task States
                    'skipped': { color: 'blue', name: 'Skipped' },
                    'complete': { color: 'green', name: 'Complete' },
                    'working': { color: 'purple', name: 'Working' },
                    'canceled': { color: 'yellow', name: 'Canceled' },
                    // Onboarding States
                    'created': { color: 'purple', name: 'Created' },
                    'started': { color: 'yellow', name: 'Started' },
                    'completed': { color: 'green', name: 'Completed' },
                    'expired': { color: 'red', name: 'Expired' },
                    // Sharing Rule States
                    'draft': { color: 'yellow', name: 'Draft' }
                }
            };
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
