<template>
    <div class="transformation-configuration" v-if="transformation">
        <component v-if="known" :is="blocks[transformation.block.id]" :transformation="transformation" @input="value => edit(value)" />
        <template v-else>
            <div class="transformation-editor flex flex-column">
                <div class="transformation-configuration-container ff">
                    <div class="transformation-configuration-function" ref="editor"></div>
                </div>
                <div class="transformation-configuration-console" :class="{ open: console_open }">
                    <div class="console-open flex flex-align pointer" :class="{ open: console_open }" @click="toggleConsole">
                        <div class="caret" :class="{ closed: !console_open }"></div>
                        <h4>Debug Console</h4>
                        <div class="ff"></div>
                        <div class="badge" v-if="logLength > 0">{{ logLength }}</div>
                    </div>
                    <div class="terminal-container" :class="{ show: console_open }">
                        <div class="xterm-wrapper" ref="terminal"></div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import _ from 'lodash';

    import { Terminal } from 'xterm';
    import { FitAddon } from 'xterm-addon-fit';
    import 'xterm/css/xterm.css';
    import * as monaco from 'monaco-editor';
    import { common_types } from '@/common_types';
    import { KnownBlock } from '@/constants';

    const EdlinkTypesLib = `
        declare type UUID = string;

        declare enum ContextType {
            Materialization = 'materialization',
            Enrichment = 'enrichment',
            Preview = 'preview'
        }

        declare enum ContextState {
            Queued = 'queued',
            Working = 'working',
            Complete = 'complete',
            Error = 'error'
        }

        declare type Context = {
            type: ContextType;
            state?: ContextState;
            priority?: number;

            transformation_configurations?: TransformationConfiguration;

            source_id: string;
            // Only present in Materializations
            integration_id: string;

            source?: Source;
            // Only present in Materializations
            integration?: Integration;
        }

        declare type Data = {
            district: Map<UUID, District>;
            schools: Map<UUID, School>;
            sessions: Map<UUID, Session>;
            courses: Map<UUID, Course>;
            people: Map<UUID, Person>;
            classes: Map<UUID, Class>;
            sections: Map<UUID, Section>;
            enrollments: Map<UUID, Enrollment>;
            agents: Map<UUID, Agent>;
        };
    `;

    export default {
        name: 'TransformationConfiguration',
        props: {
            transformation: Object
        },
        computed: {
            transform_configs(){
                return this.$store.state.blink.preview?.configuration ?? {};
            },
            admin() {
                return this.$store.state.user.admin;
            },
            source() {
                return this.$store.getters.source;
            },
            integration() {
                return this.$store.getters.integration;
            },
            team() {
                return this.$store.getters.team;
            },
            parent() {
                return this.source ?? this.integration;
            },
            known(){
                return this.blocks.hasOwnProperty(this.transformation.block.id);
            },
            debug(){
                return this.$store.state.blink.debug;
            },
            stdout() {
                return this.debug?.stdout?.join('').replaceAll('\n', '\r\n');
            },
            stdoutLength() {
                return this.stdout ? this.stdout.split('\r\n').length - 1 : 0;
            },
            stderr() {
                return this.debug?.stderr?.join('').replaceAll('\n', '\r\n');
            },
            stderrLength() {
                return this.stderr ? this.stderr.split('\r\n').length - 1 : 0;
            },
            logLength() {
                return this.stdoutLength + this.stderrLength;
            },
            log() {
                let log = '';

                if (this.stdout) {
                    log += this.stdout;
                }

                if (this.stderr) {
                    log += '\x1b[31;1mError:\x1b[0m ' + this.stderr;
                }

                return log;
            }
        },
        data(){
            return {
                editor: null,
                terminal: null,
                fitAddon: null,
                console_open: false,
                terminal_mounted: false,
                blocks: {
                    [KnownBlock.MapCustomRoles]: 'transform-map-custom-roles',
                    [KnownBlock.ClassStateBySession]: 'transform-class-state-by-session',
                    [KnownBlock.InferRoles]: 'transform-nothing',
                    [KnownBlock.InactiveSession]: 'transform-nothing',
                    [KnownBlock.KebabCase]: 'transform-nothing',
                    [KnownBlock.PhoneNumberFormatter]: 'transform-nothing',
                    [KnownBlock.NormalizeStateNames]: 'transform-nothing',
                    [KnownBlock.ConvertToUKGradeLevels]: 'transform-nothing',
                    [KnownBlock.SplitSectionsIntoClasses]: 'transform-nothing',
                    [KnownBlock.OpenAI]: 'transform-openai'
                }
            };
        },
        destroyed(){
            if (this.editor) {
                this.editor.dispose();
            }

            if (this.terminal) {
                this.terminal.dispose();
            }
        },
        mounted(){
            // If this is a known block (e.g. OpenAI), we don't need to do anything here.
            if(this.known){
                return;
            }

            // Initialize some Monaco editor settings.
            monaco.languages.typescript.typescriptDefaults.addExtraLib(EdlinkTypesLib);
            monaco.languages.typescript.typescriptDefaults.addExtraLib(unescape(common_types));

            if(this.transformation.block){
                this.terminal = new Terminal({
                    disableStdin: true,
                    cursorBlink: true,
                    fontSize: 11,
                    fontFamily: 'Roboto Mono, Inconsolata, Courier, monospace',
                    theme: {
                        background: '#000000',
                        cursorAccent: '#000000',
                    }
                });

                this.fitAddon = new FitAddon();
                // const webglAddon = new WebglAddon();
                // webglAddon.onContextLoss(e => {
                //     webglAddon.dispose();
                // });
                // this.terminal.loadAddon(webglAddon);
                this.terminal.loadAddon(this.fitAddon);
                
                // Format function
                // const default_editor_value = `import { Dataset } from 'index';\n\nfunction transform($ctx: Context, $config: Record<string, any>, $input: Dataset): Dataset {\n${this.transformation.block.function}\n}`;

                // Leaving a note here for posterity: at the moment, you can't disable the right-hand gutter in the editor.
                // Monaco calls this gutter the decoration overview ruler, and it's not possible to disable it.
                // The only way to hide it is to set it to display none in CSS.
                // I have done this in the global.less file.
                this.editor = monaco.editor.create(this.$refs.editor, {
                    minimap: {
                        enabled: false
                    },
                    scrollbar: {
                        vertical: 'hidden',
                        horizontal: 'hidden'
                    },
                    fixedOverflowWidgets: true,
                    value: this.transformation.block.function,
                    language: 'typescript',
                    automaticLayout: true,
                    autoIndent: true
                });

                this.editor.onDidChangeModelContent(() => {
                    // const editor_value = this.editor.getValue().split('\n');
                    // const function_signature_index = editor_value.indexOf('function transform($ctx: Context, $config: Record<string, any>, $input: Dataset): Dataset {');
                    // const formatted_function = editor_value.slice(function_signature_index + 1, editor_value.length - 1).join('\n');

                    // Update the block function.
                    this.transformation.block.function = this.editor.getValue();

                    this.$emit('input', this.transformation);
                });
            }
        },
        methods: {
            edit(updated){
                this.$emit('input', updated)
            },
            toggleConsole(){
                this.console_open = !this.console_open;
                if (this.console_open && !this.terminal_mounted) {
                    this.terminal_mounted = true;
                    setTimeout(async () => {
                        this.terminal.open(this.$refs.terminal);
                        this.fitAddon.fit();

                        this.logToTerminal(this.log);
                    }, 150);
                }
            },
            logToTerminal(logs){
                this.terminal.clear();
                
                for (const line of logs) {
                    try {
                        if (typeof line === 'string') {
                            // this.terminal.writeln(line.replaceAll('\n', '\r\n'));
                            this.terminal.write(line.replaceAll('\n', '\r\n'));
                        } else {
                            this.terminal.writeln(JSON.stringify(line, null, 4).replaceAll('\n', '\r\n'));
                            // this.terminal.write(JSON.stringify(line, null, 4));
                        }
                    } catch (error) {
                        console.log('error printing log to terminal:', error);
                    }
                }
            }
        },
        watch: {
            transformation(){
                if(this.known && this.editor) {
                    this.editor.dispose();
                }
            },
            log(){
                if (this.console_open) {
                    this.logToTerminal(this.log);
                }
            }
        }
    }
</script>

<style lang="less">
    .terminal-container
    {
        max-height: 185px;
        height: 185px;
        border-radius: 6px;
        padding: 15px;
        background-color: #000000;
        display: none;
        overflow: hidden;

        &.show {
            display: block;
        }
    }

    .xterm-wrapper {
        max-height: 155px;
    }

    .xterm-viewport {

        &::-webkit-scrollbar {
            display: none;
        }
    }
</style>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .transformation-configuration
    {
        .button
        {
            margin-left: 20px;
        }
    }

    .banner {
        margin: 0 25px 25px 25px;
    }

    .caret
    {
        width: 18px;
        height: 18px;
        background-size: contain;
        background-image: url('~@/assets/icons/grey/caret-down.svg');

        &.closed {
            transform: rotate(-90deg)
        }
    }

    .console-open
    {
        height: @button-height;

        h4
        {
            padding: 5px 8px;
        }

        &.open
        {
            margin-bottom: 10px;
        }
    }

    .transformation-configuration-console
    {
        max-height: 400px;
        padding: @single-padding @double-padding;
        border-top: 1px solid @e;
        height: @filters-height;

        &.open
        {
            height: auto;
        }
    }

    .transformation-editor
    {
        height: 100%;
    }

    .transformation-configuration-function
    {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .transformation-configuration-output
    {
        font-size: 13px;
        margin-top: 15px;
        color: @black;
        white-space: pre-wrap;
        max-height: 185px;
        overflow-y: auto;
        padding: 0 15px;

        .console-log
        {
            line-height: 16px;
        }
    }
</style>
