<template>
    <div class="drawer-scroll create-sandbox-integration">
        <h2 class="ff drawer-title">Create Sandbox Integrations</h2>
        <p>
            To make the development process easier, Edlink maintains a number of different sandbox LMS and SIS environments.
            These environments are available for use by any developer who needs to test their integration with Edlink.
            Alternatively, if you already have a LMS / SIS instance that you'd like to use, you can click the button below to learn more about how to connect it.
        </p>
        <p>
            Please be respectful as the sandbox environments are shared between developers. Do not use them for any purposes other than development
            and definitely do not send any real student data to them.
        </p>
        <p>
            You may connect up to 2 sandbox integrations. If you need more, please contact support.
        </p>
        <div class="card">
            <div class="flex flex-align sandbox-option" v-for="sample_source in this.sample_source_list">
                <div class="ff toggle-title"> {{ sample_source.name}}</div>
                <div class="button white mini" :class="{disabled: has(sample_source.id)}" @click="create_sample_integration(sample_source.id)">{{has(sample_source.id) ? 'Connected' : 'Add Integration'}}</div>
            </div>                                         
        </div>
        <div class="flex flex-align create-sandbox-options">
            <div @click="close_drawer" class="button">Done</div>
            <router-link class="button white has-icon has-icon-right" to="/docs/dashboard/dev-integrations">
                Connect Your Own
                <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { sampleSourceId } from '@/constants';

    export default {
        name: 'CreateSampleIntegration',
        props: {
            application: Object
        },
        data() {
            return {
                loading: false,
                integrations: [],
                sample_source_list: [
                    { id: 'blackboard', name: 'Blackboard' },
                    { id: 'brightspace', name: 'Brightspace' },
                    { id: 'canvas', name: 'Canvas' },
                    { id: 'google', name: 'Google' },
                    { id: 'moodle', name: 'Moodle' },
                    { id: 'rapididentity', name: 'RapidIdentity' },
                    { id: 'schoology', name: 'Schoology' },
                    { id: 'skyward', name: 'Skyward' },
                    { id: 'microsoft', name: 'Microsoft' },
                    { id: 'aeries', name: 'Aeries' },
                    { id: 'aspen', name: 'Aspen' },
                    { id: 'bromcom', name: 'Bromcom' },
                    { id: 'powerschool', name: 'Powerschool' },
                ]
            }
        },
        computed: {
            admin(){
                return this.$store.getters.user.admin;
            },
            sample_integration_count(){
                const sampleIds = Object.values(sampleSourceId);
                return this.integrations.filter(integration => sampleIds.includes(integration.source.id)).length;
            }
        },
        created(){
            this.refresh_integrations();
        },
        methods: {
            close_drawer(){
                this.$store.dispatch('drawer/close');
            },
            refresh_integrations() {
                if (this.$store.getters.team.id) {
                    this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`)
                        .then(response => this.integrations = response.$data.filter(i => {
                            if(this.application){
                                return i.application.id === this.application.id;
                            }

                            return true;
                        }))
                        .catch(error => this.$toasted.error('There was an error loading your integrations.'));
                }
            },
            create_sample_integration(provider_application) {
                if (this.has(provider_application)) {
                    return;
                }
                
                if(this.sample_integration_count >= 2 && !this.admin){
                    return this.$toasted.error('You may connect up to 2 sandbox integrations. If you need more, please contact support.');
                }

                this.$http.post(`/teams/${this.$store.getters.team.id}/create_sample_integrations`, {
                    application_id: this.application.id,
                    provider_application
                })
                    .then(response => response.$data)
                    .then(integration => {
                        this.integration = integration;
                        this.integrations.push(integration);
                    })
                    .then(() => {
                        this.$toasted.success('You have successfully created a sandbox integration.');
                    })
                    .catch(error => {
                        this.$toasted.error('There was an error creating a sandbox integration.');
                    });
            },
            has(sourceName) {
                return !!(this.integrations.filter(integration => integration.source.id === sampleSourceId[sourceName]).length);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .drawer-scroll.create-sandbox-integration
    {
        padding: 30px;
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }

    h2
    {
        margin-bottom: 20px;
    }

    p
    {
        font-size: 14px;
        line-height: 20px;
        color: @grey;
        margin-bottom: 20px;
    }

    .card
    {
        margin: 30px 0;
    }

    .sandbox-option
    {
        border-top: 1px solid @e4;
        padding: 10px 10px 10px 15px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .create-sandbox-options
    {
        .button
        {
            margin-right: 15px;
        }
    }

    .toggle-group
    {
        margin: 30px 0px;
    }

    .toggle-title
    {
        font-size: 14px;
        font-weight: 500;
        color: @black;
    }

    .toggle-description
    {
        font-size: 12px;
        color: @grey;
        padding-top: 8px;
        line-height: 16px;
    }
</style>
