<template>
    <div class="entity-details">
        <div class="drawer-section">
            <h4>Event Details</h4>
            <div class="summary">
                <entity-field v-for="column of properties" :key="column.property" v-bind="column" />
            </div>
        </div>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';

    export default {
        name: 'EventDetails',
        components: {
            'entity-field': EntityField
        },
        data(){
            return {
                properties: [
                    {title: 'Event ID', property: 'id', type: 'string', monospace: true, copyable: true},
                    {title: 'Integration ID', property: 'integration_id', type: 'string', monospace: true, copyable: true},
                    {title: 'Materialization ID', property: 'materialization_id', type: 'string', monospace: true, copyable: true},
                    {title: 'Created Date', property: 'date', type: 'date'},
                    {title: 'Event Type', property: 'type', type: 'string'},
                    {title: 'Event Target', property: 'target', type: 'string'},
                    {title: 'Target ID', property: 'target_id', type: 'string', copyable: true}
                ],
                entities: {
                    people: 'Person',
                    classes: 'Class',
                    sections: 'Section',
                    courses: 'Course',
                    schools: 'School',
                    districts: 'District',
                    enrollments: 'Enrollment',
                    agents: 'Agent',
                    sessions: 'Session',
                    resources: 'Resource'
                }
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            },
            title(){
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }

    .drawer-section
    {
        padding: 20px 0;

        &:first-child
        {
            padding-top: 0;
        }
    }
</style>
