<template>
    <information class="integration-licensing full scroll" v-if="integration">
        <template v-slot:title>Product Licensing</template>
        <template v-slot:description>
            <div>
                These rules are used to grant licenses to products shared with {{ integration.application.name }}.
                Rules are dynamic and the data shared will be updated automatically.
                Licensing rules are always applied. Access to creating licenses for {{ integration.team.name }} can be changed below.
            </div>
        </template>
        <template v-slot:placards>
            <placard v-if="developer">
                <template v-slot:title>Share Products with this District</template>
                <template v-slot:description>Select products and set usage limits</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" @click="() => { tab = 'products'; openProductSearch(); }">
                        Share a Product
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
            <placard v-else>
                <template v-slot:title>View Shared Products</template>
                <template v-slot:description>View the products you can access</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" @click="tab = 'products'">
                        View Shared Products
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
            <placard v-if="developer || integration.district_licensing_enabled">
                <template v-slot:title>Set Licensing Rules</template>
                <template v-slot:description>Grant access to products using rules</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" @click="tab = 'rules'">
                        View Licensing Rules
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
            <placard v-else>
                <template v-slot:title>View Licensing Rules</template>
                <template v-slot:description>See how products have been licensed</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" @click="tab = 'rules'">
                        View Licensing Rules
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
            <placard>
                <template v-slot:title>About Product Licensing</template>
                <template v-slot:description>Check the docs for useful information</template>
                <template v-slot:actions>
                    <a class="text-button has-icon-right" href="https://ed.link/docs/guides/v2.0/licensing/overview" target="_blank">
                        Get More Info
                        <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                    </a>
                </template>
            </placard>
        </template>
        <template v-slot:details>
            <nav class="flex flex-align">
                <div class="pill" :class="{active: tab === 'products'}" @click="tab = 'products'">
                    <ShieldCheck class="icon" width="16" height="16" stroke-width="2" />
                    Shared Products
                </div>
                <div class="pill" :class="{active: tab === 'rules'}" @click="tab = 'rules'">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Licensing Rules
                </div>
                <!-- <div v-if="developer" class="pill" :class="{active: tab === 'developer'}" @click="tab = 'developer'">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Developer Rules
                </div>
                <div class="pill" :class="{active: tab === 'district'}" @click="tab = 'district'">
                    <City class="icon" width="16" height="16" stroke-width="2" />
                    District Rules
                </div>
                <div v-if="district" class="pill" :class="{active: tab === 'developer'}" @click="tab = 'developer'">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Developer Rules
                </div> -->
                <div class="ff"></div>
                <div class="button" key="share-products" @click="openProductSearch()" v-if="tab === 'products' && team.type === 'developer'">Share Products</div>
                <template v-else>
                    <div class="sharing-settings flex flex-align">
                        <template v-if="district">
                            <div class="sharing-setting flex flex-align tooltip tooltip-top" tooltip="Only developers can change this setting" :class="{enabled: integration.district_licensing_enabled}">
                                <Check v-if="integration.district_licensing_enabled" class="icon" width="16" height="16" stroke-width="2" />
                                <Cancel v-else class="icon" width="16" height="16" stroke-width="2" />
                                The application developer has {{ integration.district_licensing_enabled  ? 'enabled' : 'disabled' }} licensing access
                            </div>
                        </template>
                        <template v-else>
                            <checkbox :borderless="true" label="Allow District to Modify Licensing Rules" :checked="integration.district_licensing_enabled" @click.native.prevent="toggle" :disabled="team.membership.type === 'read' || loading.state" />
                        </template>
                    </div>
                    <div class="create-rule">
                        <div class="button base has-icon has-icon-right flex flex-align" :class="{ 'disabled': district && !integration.district_licensing_enabled }" @click="creating = !creating">
                            Create a Rule
                            <NavArrowDown class="icon" width="16" height="16" stroke-width="2" />
                        </div>
                        <dropdown :open="creating" left="auto" right="-4px" width="340px" height="auto" padding="4px 0" ref="creating">
                            <dropdown-item @click.native="add('classes')">
                                <template v-slot:icon>
                                    <Apple class="icon" width="16" height="16" stroke-width="2" />
                                </template>
                                <template v-slot:title>License By Class</template>
                                <template v-slot:description>
                                    This rule will license selected classes along with the courses, sessions, teachers, and students associated with those classes.
                                </template>
                            </dropdown-item>
                            <dropdown-item @click.native="add('schools')">
                                <template v-slot:icon>
                                    <Building class="icon" width="16" height="16" stroke-width="2" />
                                </template>
                                <template v-slot:title>License By School</template>
                                <template v-slot:description>
                                    This rule will license selected schools, along with all of the people, classes, courses, and sessions associated with those schools.
                                </template>
                            </dropdown-item>
                            <dropdown-item @click.native="add('people')">
                                <template v-slot:icon>
                                    <Group class="icon" width="16" height="16" stroke-width="2" />
                                </template>
                                <template v-slot:title>License By Person</template>
                                <template v-slot:description>
                                    This rule will license individual people and optionally, their associated classes. It will not license other people in those classes.
                                </template>
                            </dropdown-item>
                        </dropdown>
                    </div>
                </template>
            </nav>
            <template v-if="tab === 'products'">
                <div class="products">
                    <div class="flex flex-align flex-center nothing" v-if="loading.products">
                        <spinner />
                    </div>
                    <template v-else-if="sorted.length > 0">
                        <div class="product flex" v-for="product of sorted" :key="product.id">
                            <div class="product-icon" :style="{backgroundImage: `url(${product.picture_url})`}"></div>
                            <div class="product-info flex flex-column ff">
                                <div class="product-header flex flex-align">
                                    <div class="product-title text-overflow">
                                        {{ product.name }}
                                    </div>
                                    <div class="product-state">
                                        <ProductState :state="product.state" />
                                    </div>
                                    <div class="ff"></div>

                                    <!-- <div class="assigned-total flex flex-align" v-if="licenses[product.id]">
                                        {{ licenses[product.id].school_count + licenses[product.id].class_count + licenses[product.id].person_count }} / ∞
                                    </div>
                                    <div class="assigned-total flex flex-align" v-else>
                                        0 / ∞
                                    </div> -->
                                </div>
                                <div class="product-subtext flex flex-align">
                                    <!-- <div class="product-tags">
                                        {{ product.tags.join(', ') }}
                                    </div> -->
                                    <div class="product-counts" v-if="licenses[product.id]">
                                        Schools: {{ licenses[product.id].school_count }}
                                        Classes: {{ licenses[product.id].class_count }}
                                        People: {{ licenses[product.id].person_count }}
                                    </div>
                                    <div class="product-counts empty" v-else>
                                        No licenses assigned
                                    </div>
                                    <!-- <div class="product-description">
                                        {{ product.description }}
                                    </div> -->
                                </div>
                            </div>
                            <template v-if="team.type === 'developer'">
                                <div class="product-caps flex flex-align">
                                    <div class="flex flex-align flex-row">
                                        <label>Soft cap:</label>
                                        <input v-model.number="product.soft_cap" type="number" placeholder="Soft cap" />
                                    </div>
                                    <div class="flex flex-align flex-row">
                                        <label>Hard cap:</label>
                                        <input v-model.number="product.hard_cap" type="number" placeholder="Hard cap" />
                                    </div>
                                </div>
                                <div class="flex flex-align locked" v-if="isUnsharing(product.id)">
                                    <spinner />
                                </div>
                                <div class="flex flex-align locked" v-else-if="hasChanged(product.id)" @click="saveProduct(product.id)">
                                    <Check class="icon" width="18" height="18" stroke-width="2" color="#2ed573" />
                                </div>
                                <div class="flex flex-align locked" v-else-if="licenses[product.id]">
                                    <Lock class="icon" width="18" height="18" stroke-width="2" v-tooltip.top="'This product is in use and cannot be unshared. Try deprecating it instead.'" />
                                </div>
                                <div class="flex flex-align remove" @click="unshareProduct(product.id)" v-else>
                                    <Cancel class="icon" width="18" height="18" stroke-width="2" />
                                </div>
                            </template>
                            <div class="undo" v-if="!isUnsharing(product.id) && hasChanged(product.id)" @click="resetProduct(product.id)">
                                <Undo class="icon" width="18" height="18" stroke-width="2" color="#eb2f06" />
                            </div>
                        </div>
                    </template>
                    <div class="flex flex-align flex-center nothing" v-else>
                        <div class="flex flex-center flex-align nothing">No products have been shared with this integration.</div>
                    </div>
                </div>
            </template>
            <integration-rules v-else-if="['developer', 'district', 'rules'].includes(tab)" :counts="counts" :integration="integration" :rules="rules" :licensing="true" :empty="`There are no ${tab} licensing rules created for this integration.`" :owner="team.type" />
        </template>
    </information>
</template>

<script>
import _ from 'lodash';
import { Cancel, Lock, Check, Undo, ArrowUpRight, City, Svg3dSelectSolid, Filter, ShieldCheck, NavArrowDown, Apple, Building, Group } from '@epiphany/iconoir';
import ProductState from '@/components/chips/ProductState.vue';
import Vue from 'vue';
import ShareProducts from '@/components/drawers/products/ShareProducts.vue';
import Options from '@/components/modals/Options.vue';

const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

export default {
    name: 'IntegrationLicensing',
    components: {
        ProductState,
        Cancel,
        Lock,
        Check,
        Undo,
        ArrowUpRight,
        City,
        Svg3dSelectSolid,
        ShieldCheck,
        NavArrowDown,
        Apple,
        Building,
        Group,
        'FilterIcon': Filter
    },
    mounted() {
        this.loadSharedProducts();
        this.loadLicenses();
    },
    data() {
        return {
            tab: 'products',
            search: '',
            loading: {
                rules: true,
                state: false,
                products: true
            },
            materialization: null,
            creating: false,
            unsharing: [],
            licenses: {},
            products_copy: {},
            products: [],
            results: [],
            selected: [],
        };
    },
    created(){
        this.$http.get(`${url}/api/v2/graph/materializations`, {
            headers: {
                Authorization: `Bearer ${this.integration.access_token}`,
            },
            params: {
                $last: 1,
                $filter: {
                    state: [{ operator: 'equals', value: 'complete' }]
                }
            }
        })
        .then(response => this.materialization = response.$data?.pop())
        .catch(error => this.$toasted.error('There was an error fetching the latest materialization.'));
        
        window.addEventListener('mousedown', this.click_outside);
        window.addEventListener('keydown', this.escape);
    },
    destroyed() {
        window.removeEventListener('mousedown', this.click_outside);
        window.removeEventListener('keydown', this.escape);
    },
    computed: {
        developer(){
            return this.team.type === 'developer';
        },
        district(){
            return this.team.type === 'district';
        },
        team(){
            return this.$store.getters.team;
        },
        type(){
            return this.$store.getters.team.type;
        },
        integration(){
            return this.$store.getters.integration;
        },
        admin(){
            return this.$store.getters.user.admin;
        },
        sorted(){
            return this.products.sort((a, b) => {
                if (this.licenses[a.id]) {
                    return -1;
                } else {
                    return 1;
                }
            });
        },
        counts(){
            return _.get(this.materialization, `output.licensing.counts.rules`);
        },
        rules(){
            return this.integration.rules.filter(rule => rule.type === 'licensing' && (this.tab === 'rules' || rule.owner === this.tab));
        }
    },
    methods: {
        click_outside(e) {
            if (this.creating) {
                if (this.$refs.creating && !this.$refs.creating.$el.contains(e.target)) {
                    this.creating = false;
                }
            }
        },
        escape(e) {
            if (e.key === 'Escape') {
                this.creating = false;
            }
        },
        toggle(){
            const _this = this;
            const action = this.integration.district_licensing_enabled === true ? 'Disable' : 'Enable';
            const recourse = this.integration.district_licensing_enabled === true ? 'no longer be able to' : 'be able to';

            this.$modal.show(Options, {
                title: `${action} District Licensing`,
                description: `Districts will ${recourse} edit licensing rules for this integration.`,
                options: [
                    {
                        title: `Confirm Change`,
                        color: 'base',
                        fn(){
                            _this.save(_this.integration.district_licensing_enabled === true ? false : true);
                        }
                    }
                ]
            }, {width: 300, height: 'auto', classes: 'modal'});
        },
        add(target){
            this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/rules`, {
                state: 'draft',
                target,
                conditions: [],
                type: 'licensing'
            })
            .then(rule => {
                this.$store.dispatch('integrations/did_create_rule', rule.$data)
                this.$toasted.success('New licensing rule created.');

                this.creating = false;

                const clone = _.cloneDeep(rule.$data);
                clone.state = 'active'

                this.$store.dispatch('drawer/open', {
                    key: 'edit-rule',
                    width: 800,
                    component: 'edit-rule',
                    props: {
                        rule: clone,
                        licensing: true
                    }
                });
            })
            .catch(error => {
                this.$toasted.error('There was an error creating a new licensing rule.');
            });
        },
        save(value){
            const patch = { district_licensing_enabled: value };

            // Update the local variable.
            this.loading.state = true;

            // Update the store.
            this.$store.dispatch('save/save', 'integrations/licensing/state');

            // Actually make the request.
            this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}`, patch)
            .then((response) => {
                this.$store.commit('integrations/update', patch);

                // Show a success message.
                this.$toasted.success(`District Licensing access ${value ? 'enabled' : 'disabled'}`);
            })
            .catch(error => {
                this.$toasted.error('There was an error updating the integration.');
            })
            .finally(() => {
                // Update the local variable.
                this.loading.state = false;

                // Update the store.
                this.$store.dispatch('save/saved', 'integrations/licensing/state');
            });
        },
        loadSharedProducts(){
            this.loading.products = true;
            this.products = [];
            this.products_copy = {};
            return this.fetchSharedProducts();
        },
        fetchSharedProducts(params = {}){
            this.loading.products = true;
            params.$first = 1000;

            return this.$http.get(`/teams/${this.team.id}/integrations/${this.integration.id}/products`, { params, baseURL: '/api/v2' })
                .then(response => {
                    // Set the products.
                    this.products = this.products.concat(response.$data);
                    // Make a copy of the products so we can compare them later.
                    for(const product of _.cloneDeep(response.$data)) {
                        this.products_copy[product.id] = product;
                    }

                    const last = response.$data[response.$data.length - 1];

                    if (response.$next && last) {
                        params.$after = last.id;
                        return this.fetchSharedProducts(params);
                    }
                })
                .catch(error => {
                    this.$toasted.error('There was an error loading your products.');
                })
                .finally(() => {
                    this.loading.products = false;
                });
        },
        loadProducts(query) {
            this.loading.products = true;
            const params = {
                $filter: {
                    id: [{ operator: 'not in', value: this.products.map(p => p.id).join(',') }],
                },
            };

            if (query) {
                params.$filter.name = [{ operator: 'contains', value: query }];
            }

            return this.$http.get(`/teams/${this.team.id}/products`, {
                params,
                baseURL: '/api/v2'
            })
                .then(response => {
                    this.results = response.$data.map(p => {
                        return {
                            id: p.id,
                            title: p.name,
                            subtitle: p.code,
                            extra: p.state
                        }
                    });
                })
                .catch(error => {
                    this.$toasted.error('There was an error loading your products.');
                })
                .finally(() => {
                    this.loading.products = false;
                });
        },
        openProductSearch(){
            this.selected = [];
            this.$store.dispatch('drawer/open', {
                component: ShareProducts,
                key: 'product-search',
                width: 500,
                props: {
                    products: this.products,
                    refreshParent: this.loadSharedProducts.bind(this)
                }
            });
        },
        unshareProduct(product_id){
            this.willUnshare(product_id);
            this.$http.delete(`/teams/${this.team.id}/integrations/${this.integration.id}/products/${product_id}`,  { baseURL: '/api/v2' })
                .then(response => {
                    setTimeout(() => {
                        this.products = this.products.filter(p => p.id !== product_id);
                        this.$toasted.success('Product unshared');
                        this.didUnshare(product_id);
                    }, 500);
                })
                .catch(error => {
                    this.didUnshare(product_id);
                    if (error?.$errors?.some(it => it.code === 'IN_USE')) {
                        return this.$toasted.error('This product is in use and cannot be unshared. Try deprecating it instead.');
                    } else {
                        return this.$toasted.error('There was an error unsharing your product.');   
                    }
                });
        },
        saveProduct(product_id){
            this.willUnshare(product_id);
            const updated = this.products.find(p => p.id === product_id);
            this.$http.patch(`/teams/${this.team.id}/integrations/${this.integration.id}/products/${product_id}`, updated, { baseURL: '/api/v2' })
                .then(response => {
                    setTimeout(() => {
                        this.products_copy[product_id] = _.cloneDeep(updated);
                        this.$toasted.success('Product updated');
                        this.didUnshare(product_id);
                    }, 500);
                })
                .catch(error => {
                    this.didUnshare(product_id);
                    return this.$toasted.error('There was an error updating your product.');
                });
        },
        resetProduct(product_id){
            this.products = this.products.map(p => {
                if(p.id === product_id) {
                    return _.cloneDeep(this.products_copy[product_id]);
                }
                return p;
            });
        },
        loadLicenses() {
            this.$http.get('/graph/licenses', {
                baseURL: '/api/v2',
                params: {
                    $expand: 'product'
                },
                headers: {
                    authorization: `Bearer ${this.integration.access_token}`
                }
            }).then(response => {
                for (const license of response.$data) {
                    Vue.set(this.licenses, license.product_id, license)
                }
            });
        },
        isUnsharing(product_id) {
            return this.unsharing.includes(product_id);
        },
        willUnshare(product_id) {
            this.unsharing.push(product_id);
        },
        didUnshare(product_id) {
            this.unsharing = this.unsharing.filter(p => p !== product_id);
        },
        hasChanged(product_id) {
            return !_.isEqual(this.products_copy[product_id], this.products.find(p => p.id === product_id));
        }
    }
}
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 4px;
    }

    .sharing-settings
    {
        .checkbox.borderless::v-deep
        {
            padding: 0;
            height: @button-height;
            line-height: @button-height;

            .checkbox-label
            {
                font-size: 13px;
            }

            &.disabled
            {
                pointer-events: none;

                input
                {
                    background-color: @lightgrey;
                    border-color: @lightgrey;
                }

                .checkbox-label
                {
                    color: @lightgrey;
                }
            }
        }

        .sharing-setting
        {
            font-size: 15px;
            cursor: default;
            user-select: none;

            .icon
            {
                margin-right: 6px;
            }

            &.enabled
            {
                color: @base;
            }
        }
    }

    .integration-rules, .products
    {
        margin-top: @double-padding;
    }

    .create-rule {
        margin-left: @double-padding;
    }

    .create-rule::v-deep
    {
        .dropdown-item
        {
            padding: @single-padding @double-padding @single-padding @single-padding;
        }

        .dropdown-item-default-template.flex.flex-align
        {
            .dropdown-icon
            {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                background-color: fade(@base, 10%);
                margin-right: @single-padding + 2px;

                .icon
                {
                    color: @base;
                }
            }

            .dropdown-title
            {
                line-height: 16px;
                margin-bottom: 2px;
            }
        }
    }

    .remove, .locked {
        cursor: pointer;
        margin-right: 5px;
        width: 20px;
    }

    .remove {
        &:hover {
            color: @red;
        }
    }

    h2 {
        color: @black;
        font-size: 16px;
        margin-bottom: 10px;
    }

    h4 {
        color: @grey;
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 400;
        line-height: 20px;
    }

    section {
        padding: 20px;
        max-width: 800px;
    }


    .products {
        border: 1px solid @e4;
        border-radius: 6px;
        // max-width: 750px;

        .nothing {
            padding: 20px;
        }
    }

    .product {
        border-bottom: 1px solid @e4;
        padding: 10px 10px;

        .undo {
            position: absolute;
            right: -30px;
            top: 0;
            bottom: 0;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .product-info {
            margin-right: 10px;
        }

        .product-header {
            margin-bottom: 5px;
        }

        .product-title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: @black;
            justify-content: space-between;
            // white-space: nowrap;
        }

        .product-caps {
            margin: 0 15px;

            & > div {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }

            label {
                font-size: 11px;
                line-height: 11px;
                color: @grey;
                font-weight: 400;
                margin: 0 5px 0 0;
            }

            input {
                height: 30px;
                font-size: 12px;
                line-height: 28px;
                max-width: 100px;
            }
        }

        .assigned-total {
            font-size: 12px;
            color: @grey;
            font-weight: 400;

            .icon {
                height: 12px;
                width: 12px;
                font-size: 12px;
                margin-left: 3px;
            }
        }

        .product-tags {
            margin-right: 8px;

            .chip {
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .product-counts {
            font-size: 10px;
            font-weight: 500;
            color: @base;
            background: fade(@base, 10%);
            padding: 4px;
            border-radius: 4px;

            &.empty {
                color: @grey;
                background-color: @f4;
            }
        }

        .product-icon {
            height: 45px;
            width: 45px;
            border-radius: 4px;
            background-position: center;
            background-size: cover;
            margin-right: 10px;
            background-color: @lightgrey;
        }

        .product-description {

        }

        &:last-child {
            border-bottom: none;
        }
    }

    .selection {
        header {
            padding: 15px 15px;
            height: auto;
            background: @f8;
            border-bottom: 1px solid @e4;

            .button {
                margin-left: 8px;
            }

            h4 {
                font-size: 14px;
                font-weight: 500;
                flex: 0 0 auto;
                margin: 0 15px 0 0;
            }

            select {
                width: 170px;
                border-radius: 17px;
                padding: 0 12px;
                font-size: 14px;
            }
        }

        .selection-search {
            padding: 15px;

            .search {
                width: 100%;
                background-color: @f;
            }
        }

        .selection-result {
            padding: 0 15px;
            cursor: pointer;

            .checkbox-input {
                margin: 0 15px 0 0;
            }

            .result-details {
                padding: 10px 0;
                border-bottom: 1px solid @e4;
                margin-bottom: -1px;
            }

            .result-name,
            .result-state {
                font-size: 13px;
                color: @black;
                line-height: 16px;
            }

            .result-id {
                font-size: 11px;
                color: @grey;
                line-height: 14px;
                margin-top: 2px;
            }

            &:last-child {
                .result-details {
                    border-bottom: 0;
                }
            }
        }
    }
</style>
