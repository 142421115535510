<template>
    <div class="entity-value entity-value-product flex" :style="{ height }" v-if="initialized">
        <autocomplete ref="input" class="fw" :values.sync="values_copy" :timeout="1" :source="search" :format="format" :max="max" :placeholder="placeholder" :inline="true" />
    </div>
</template>

<script>
    import _ from 'lodash';
    import Vue from 'vue';

    export default {
        name: 'PreviewValueProduct',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            values: Array,
            height: {
                type: String,
                default: 'auto'
            }
        },
        mounted(){
            this.load_entities();

            this.values_copy = this.values ? this.values.slice() : [];

            // Attach a watcher to the values_copy so that we can emit the update
            // But only after the initial load so that we don't emit the initial update
            this.$watch('values_copy', (updated) => {
                // If a product is selected that has a duration
                if (this.values_copy.some(v => this.products[v]?.license_duration)) {
                    // Get that duration
                    const product_id = this.values_copy.find(v => this.products[v]?.license_duration);
                    const duration = this.products[product_id].license_duration;
                    // Return only products with that duration
                    updated = updated.filter(v => this.products[v]?.license_duration === duration);
                }
                this.$emit('update:values', updated);
                this.$emit('input', updated);
            });
        },
        destroyed(){
            if (this.load_timeout) {
                clearTimeout(this.load_timeout);
            }
        },
        data(){
            return {
                initialized: false,
                products: {},
                values_copy: [],
                load_timeout: null,
                load_promise: null
            };
        },
        computed: {
            max(){
                return this.array ? Infinity : 1;
            },
            team(){
                return this.$store.getters.team;
            },
            integration(){
                return this.$store.getters.integration;
            },
            blink_session() {
                return this.$store.state.blink.session;
            },
            reloading() {
                return this.$store.state.blink.reloading;
            },
            user() {
                return this.$store.getters.user;
            }
        },
        methods: {
            load_entities() {
                this.products = {};
                this.load_promise = this.fetch_entities();
                this.initialized = true;
                this.$nextTick(() => {
                    this.$refs.input.$refs.input.focus();
                });
                return this.load_promise;
            },
            fetch_entities(params = {}){
                // params.$filter = { id: [{ operator: 'in', value: this.values }] };
                params.$fields = 'id,name,license_duration,state';
                params.$first = 1000;

                return this.$http.get(`/teams/${this.team.id}/integrations/${this.integration.id}/products`, {
                    params,
                    headers: {
                        authorization: `Bearer ${this.user.token}`
                    },
                    baseURL: '/api/v2'
                })
                .then((response) => {
                    const products = response.$data;

                    for(const product of products){
                        Vue.set(this.products, product.id, product);
                    }

                    const last = products[products.length - 1];

                    if (response.$next && last) {
                        params.$after = last.id;
                        return this.fetch_entities(params);
                    }
                })
            },
            search(input){
                return this.load_promise.then(() => {
                    return new Promise((resolve, reject) => {
                        let valid = Object.values(this.products);
                        
                        if (input && input.trim().length > 0) {
                            valid = valid.filter(p => p.name.toLowerCase().includes(input.toLowerCase()));
                        }

                        // If a product is selected that has a duration
                        if (this.values_copy.some(v => this.products[v]?.license_duration)) {
                            // Get that duration
                            const product_id = this.values_copy.find(v => this.products[v]?.license_duration);
                            const duration = this.products[product_id].license_duration;
                            // Return only products with that duration or no duration
                            valid = Object.values(this.products).filter(p => p.license_duration === duration);
                        } else if (this.values_copy.length > 0) {
                            // Return only products with no duration
                            valid = Object.values(this.products).filter(p => !p.license_duration);
                        }

                        return resolve(valid.map(it => it.id));
                    });
                })
            },
            format(value){
                return this.products[value]?.name;
            }
        },
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .fw {
        width: 100%
    }

    .entity-value-product
    {   
        // box-shadow: 0 2px 12px -2px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        input.autocomplete-input
        {
            margin-left: 0;
            padding-left: 0;
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>