<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <StatsUpSquare class="icon" width="16" height="16" stroke-width="2" />
                    Dashboard
                </div>
            </template>
        </breadcrumbs>
        <div class="dashboard full scroll">
            <div class="getting-started bc" v-if="!team.verified">
                <div class="guide-header-columns flex">
                    <div class="guide-header-column">
                        <h1 class="guide-page-header">Get Started With Edlink</h1>
                        <div class="guide-page-subheader">
                            Follow these steps to get started building your first application with Edlink.
                        </div>
                        <div class="guide-steps">
                            <div class="guide-step" :class="{complete: steps.includes('application')}">
                                <div class="guide-step-track">
                                    <div class="guide-step-icon flex flex-align flex-center">
                                        <span class="block icon iconoir-box-iso"></span>
                                    </div>
                                </div>
                                <div class="guide-step-name">Create an Application</div>
                                <div class="guide-step-body">
                                    Applications identify your product to Edlink and allow you to develop SSO, rostering, assignment, and gradebook integrations.
                                    If you have more than one product, you can create multiple applications.
                                    <div class="guide-step-actions flex flex-align">
                                        <div class="button" :class="{disabled: applications.length}" @click="this.create_application">Create Application</div>
                                    </div>
                                </div>
                            </div>
                            <div class="guide-step" :class="{complete: steps.includes('integration')}">
                                <div class="guide-step-track">
                                    <div class="guide-step-icon flex flex-align flex-center">
                                        <span class="block icon iconoir-codepen"></span>
                                    </div>
                                </div>
                                <div class="guide-step-name">Request Access to Developer Sandboxes</div>
                                <div class="guide-step-body">
                                    We maintain sandbox instances of most of the popular LMS and SIS systems
                                    to make it easy for you to develop and test your application.
                                    <div class="guide-step-actions flex flex-align">
                                        <div class="button" @click="create_sandbox_integration" :class="{disabled: !applications.length || integrations.length}">Request Sandbox Access</div>
                                        <router-link class="button white has-icon has-icon-right" to="/docs/dashboard/dev-integrations">
                                            Connect Your Own
                                            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="guide-step" :class="{complete: steps.includes('verification')}">
                                <div class="guide-step-track">
                                    <div class="guide-step-icon flex flex-align flex-center">
                                        <span class="block icon iconoir-code"></span>
                                    </div>
                                </div>
                                <div class="guide-step-name">Build Your Integration</div>
                                <div class="guide-step-body">
                                    Time to plan and build your actual integration.
                                    We provide plenty of developer guides and code samples to help you get started.
                                    <div class="guide-step-actions flex flex-align">
                                        <router-link class="button has-icon has-icon-right" to="/docs/guides/v2.0/quickstart">
                                            Quickstart Guide
                                            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </router-link>
                                        <router-link class="button white has-icon has-icon-right" to="/docs/api/v2.0/introduction">
                                            API Reference
                                            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="guide-step" :class="{complete: steps.includes('verification')}">
                                <div class="guide-step-track">
                                    <div class="guide-step-icon flex flex-align flex-center">
                                        <span class="block icon iconoir-twitter-verified-badge"></span>
                                    </div>
                                </div>
                                <div class="guide-step-name">Get Your Team Verified</div>
                                <div class="guide-step-body">
                                    Get verified to make sure your application is ready for primetime.
                                    Once you are verified, you'll be able to integrate with real districts.
                                    <div class="guide-step-actions flex flex-align">
                                        <a class="button white has-icon has-icon-right" target="_blank" href="https://ed.link/help/team-verification/">
                                            About Verification
                                            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="guide-step" :class="{complete: steps.includes('production')}">
                                <div class="guide-step-track">
                                    <div class="guide-step-icon flex flex-align flex-center">
                                        <span class="block icon iconoir-city"></span>
                                    </div>
                                </div>
                                <div class="guide-step-name">Invite Your First School District</div>
                                <div class="guide-step-body">
                                    Once your integration is complete, you can begin onboarding real districts.
                                    Edlink provides an out-of-the-box onboarding experience to make it quick and easy for districts to get connected.
                                    <div class="guide-step-actions flex flex-align">
                                        <div class="button has-icon base flex flex-align" :class="{disabled: !this.verified_applications.length}" @click="request_integration">
                                            <span class="icon iconoir-copy"></span>
                                            <div class="ff">Copy Integration Link</div>
                                        </div>
                                        <a class="button white has-icon has-icon-right" target="_blank" href="https://ed.link/community/what-to-know-when-connecting-a-schools-lms-to-edlink/">
                                            About District Onboarding
                                            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="guides-list ff">
                        <div class="guide-header flex flex-align">
                            <h2 class="ff">Developer Guides</h2>
                            <a class="button white has-icon has-icon-right" href="https://ed.link/docs/guides/v2.0/introduction" target="_blank">
                                All Developer Guides
                                <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                        <div class="guide-links flex flex-column">
                            <router-link class="block guide-link" to="/docs/guides/v2.0/getting-started/postman-collection">Getting Started with Postman</router-link>
                            <!-- <router-link class="block guide-link" to="1">Setting up a Development Environment</router-link> -->
                            <router-link class="block guide-link" to="/docs/guides/v2.0/getting-started/graph-api-vs-user-api">Graph API vs. User API</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/getting-started/api-request-logs">API Request Logs</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/getting-started/authorization">Request Authorization</router-link>
                            <!-- <router-link class="block guide-link" to="">How to enable Classlink integrations</router-link>
                            <router-link class="block guide-link" to="">How to enable Clever integrations</router-link> -->
                        </div>
                        <div class="guide-header flex flex-align">
                            <h2 class="ff">SDKs &amp; Code Samples</h2>
                            <a class="button white has-icon has-icon-right" href="https://github.com/edlink/examples" target="_blank">
                                All Code Samples
                                <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                        <div class="guide-links flex flex-column">                        
                            <a class="block guide-link" href="https://ed.link/docs/api/v2.0/introduction" target="_blank">API Reference</a>
                            <a class="block guide-link" href="https://ed.link/docs/api/v2.0/sdk/typescript/get-started" target="_blank">Typescript SDK</a>
                            <a class="block guide-link" href="https://github.com/edlink/examples" target="_blank">Edlink Examples on Github</a>
                            <a class="block guide-link" href="https://api.postman.com/collections/14522809-fc237174-e824-49bf-9a92-dc304b4bc8f6?access_key=PMAT-01H0JQQWC9R59RHYQYS835DQW3" target="_blank">Postman Collection</a>
                        </div>
                        <div class="guide-header flex flex-align">
                            <h2 class="ff">Advanced Guides</h2>
                            <a class="button white has-icon has-icon-right" href="https://ed.link/docs/guides/v2.0/introduction" target="_blank">
                                All Developer Guides
                                <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                        <div class="guide-links flex flex-column">
                            <!-- <router-link class="block guide-link" to="">Complex SSO Use Cases</router-link> -->
                            <!-- <router-link class="block guide-link" to="">Differences Between Source Systems</router-link> -->
                            <router-link class="block guide-link" to="/docs/guides/v2.0/integration-management/writing-transformations">How to write a custom transformation</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/data-model/how-data-enrichment-works">What is data enrichment and how does it work</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/data-model/events">Using the events API to sync</router-link>
                            <!-- <router-link class="block guide-link" to="">Working with LTI 1.3</router-link> -->
                        </div>
                        <div class="guide-header flex flex-align">
                            <h2 class="ff">Useful Resources</h2>
                            <a class="button white has-icon has-icon-right" href="https://ed.link/help" target="_blank">
                                Knowledge Base
                                <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                        <div class="guide-links flex flex-column">
                            <router-link class="block guide-link" to="/docs/guides/v2.0/integration-management/how-transformations-work">How transformations work.</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/integration-management/modifying-overrides-via-api">What are overrides</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/integration-management/setting-sharing-rules-via-api">What are sharing rules</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/getting-started/accessing-sandbox-sources">Getting Access to Sandbox Sources</router-link>
                            <router-link class="block guide-link" to="/docs/guides/v2.0/getting-started/api-request-logs">How to use API logs to help troubleshoot issues</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <template v-else>
                <div class="analytics">
                    <div class="components">
                        <div class="component activity">
                            <div class="flex">
                                <div class="ff metric flex flex-column">
                                    <graph class="ff" :configuration="requests" :hover.sync="primary" />
                                </div>
                                <div class="ff flex flex-column metrics">
                                    <div class="ff metric">
                                        <div class="flex flex-align">
                                            <div class="metric-title ff">Logins</div>
                                            <router-link :to="{name: 'team.logs', query: {$filter: JSON.stringify({categories: [{operator: 'in', value: 'sso'}]})}}" class="text-button flex flex-align">
                                                View
                                            </router-link>
                                        </div>
                                        <div class="metric-value">{{total_logins}}</div>
                                        <div class="metric-description">Tokens issued today</div>
                                    </div>
                                    <div class="ff metric">
                                        <div class="flex flex-align">
                                            <div class="metric-title ff">Failed Requests</div>
                                            <router-link :to="{name: 'team.logs', query: {$filter: JSON.stringify({status: [{operator: 'equals', value: 'failed'}]})}}" class="text-button flex flex-align">
                                                View
                                            </router-link>
                                        </div>
                                        <div class="metric-value">{{failure_rate}}</div>
                                        <div class="metric-description">Of total API requests</div>
                                    </div>
                                    <div class="ff metric">
                                        <div class="flex flex-align">
                                            <div class="metric-title ff">Latency</div>
                                            <router-link :to="{name: 'team.logs'}" class="text-button flex flex-align">
                                                View
                                            </router-link>
                                        </div>
                                        <div class="metric-value">{{latency}}</div>
                                        <div class="metric-description">Average response time</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reporting">
                    <!-- <filters class="flex flex-align">
                        <filter-dropdown :items="reports.regions" :active="reports.region" @activate="switch_region" :required="true" label="Region">
                            <template v-slot:item="item">
                                <div class="flex flex-align">
                                    <div class="filter-item-icon">
                                        <img class="block" :src="item.picture" />
                                    </div>
                                    <div class="filter-item-name">{{item.name}}</div>
                                </div>
                            </template>
                        </filter-dropdown>
                        <filter-dropdown :items="reports.integrations.items" label="Integration" :active="reports.integration" @search="integration_source" :searchable="true" @activate="switch_integration">
                            <template v-slot:item="item">
                                <div class="flex flex-align">
                                    <div class="filter-item-icon flex flex-align flex-center">
                                        <div class="thumbnail">
                                            <img class="block" :src="item.picture" />
                                        </div>
                                    </div>
                                    <div class="filter-item-name">{{item.name}}</div>
                                </div>
                            </template>
                        </filter-dropdown>
                        <filter-dropdown :items="[]" label="Last 28 Days">
                            <template v-slot:item="item">
                                <div class="flex flex-align">
                                    <div class="filter-item-icon flex flex-align flex-center">
                                        <div class="status-icon" :class="item.icon"></div>
                                    </div>
                                    <div class="filter-item-name">{{item.name}}</div>
                                </div>
                            </template>
                        </filter-dropdown>
                        <div class="ff"></div>
                        <div class="button white has-icon">
                            <span class="icon iconoir-database-export"></span>
                            Export
                        </div>
                    </filters> -->
                    <div class="components flex">
                        <div class="component ff metric flex flex-column">
                            <graph class="ff" :configuration="integrations" :hover.sync="secondaries" />
                        </div>
                        <div class="component ff metric flex flex-column">
                            <graph class="ff" :configuration="shared" :hover.sync="secondaries" />
                        </div>
                        <div class="component ff metric flex flex-column">
                            <graph class="ff" :configuration="logins" :hover.sync="secondaries" />
                        </div>
                        <!-- <div class="component ff metric flex flex-column">
                            <graph class="ff" :configuration="activity" :hover.sync="secondaries" />
                        </div>
                        <div class="component ff metric flex flex-column">
                            <graph class="ff" :configuration="assignments" :hover.sync="secondaries" />
                        </div>
                        <div class="component ff metric flex flex-column">
                            <graph class="ff" :configuration="grades" :hover.sync="secondaries" />
                        </div> -->
                    </div>
                </div>
            </template>
        </div>
    </main>
</template>

<script>
    import * as d3 from 'd3';
    import { StatsUpSquare } from '@epiphany/iconoir';
    
    import CreateApplication from '@/components/drawers/CreateApplication';
    import CreateSampleIntegration from '@/components/drawers/CreateSampleIntegration';
    import InviteMembers from '@/components/drawers/InviteMembers';
    import SelectApplication from '@/components/modals/SelectApplication';
    import { sampleSourceId } from '@/constants';

    const filterDevelopment = application => application.status === 'development';
    const real_integrationsFilter = integration => !Object.values(sampleSourceId).includes(integration.source.id);

    // Calculate our start time for charts.
    const midnight = new Date();

    // Set the time to midnight (last night) local time.
    midnight.setHours(0, 0, 0, 0);

    // One month ago.
    const four_weeks_ago = new Date(midnight.getTime() - 28 * 24 * 60 * 60 * 1000);
    const eight_weeks_ago = new Date(midnight.getTime() - 56 * 24 * 60 * 60 * 1000);

    export default {
        name: 'Dashboard',
        components: {
            StatsUpSquare
        },
        data(){
            return {
                primary: null,
                secondaries: null,
                today: [],
                requests: {
                    description: 'The total number of logged API requests made across your applications',
                    ticks: d3.timeHour.every(1),
                    granularity: d3.timeHour,
                    start: midnight,
                    end: new Date(midnight.getTime() + 86400000),
                    timestamp: {
                        visible: 'always',
                        format: 'LT',
                    },
                    delta: {
                        visible: 'sometimes'
                    },
                    series: [{
                        title: 'API Requests',
                        id: 'requests-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'sum',
                        data: []
                    },
                    {
                        title: 'Previous Period',
                        id: 'previous-requests-total',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                integrations: {
                    description: 'The total number of active or paused integrations across your applications',
                    ticks: false,
                    granularity: d3.timeDay,
                    start: four_weeks_ago,
                    end: midnight,
                    timestamp: {
                        visible: 'sometimes',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'always'
                    },
                    series: [{
                        title: 'Integrations',
                        id: 'integrations-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'last',
                        data: []
                    },
                    {
                        title: 'Previous Integrations',
                        id: 'integrations-previous',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                shared: {
                    description: 'The total number of shared people between all of your development and production integrations',
                    ticks: false,
                    granularity: d3.timeDay,
                    start: four_weeks_ago,
                    end: midnight,
                    timestamp: {
                        visible: 'sometimes',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'always'
                    },
                    series: [{
                        title: 'Shared People',
                        id: 'shared-users-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'last',
                        data: []
                    },
                    {
                        title: 'Previous Shared People',
                        id: 'shared-users-previous',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                logins: {
                    description: 'The total number of new logins across your applications',
                    ticks: false,
                    granularity: d3.timeDay,
                    start: four_weeks_ago,
                    end: midnight,
                    timestamp: {
                        visible: 'sometimes',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'always'
                    },
                    series: [{
                        title: 'New Logins',
                        id: 'logins-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'last',
                        data: []
                    },
                    {
                        title: 'Previous Logins',
                        id: 'logins-previous',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                activity: {
                    description: 'The number of unique users who made an API request or logged into your application',
                    ticks: false,
                    granularity: d3.timeDay,
                    start: four_weeks_ago,
                    end: midnight,
                    timestamp: {
                        visible: 'sometimes',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'always'
                    },
                    series: [{
                        title: 'Active Users',
                        id: 'active-users-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'last',
                        data: []
                    },
                    {
                        title: 'Previous Active Users',
                        id: 'active-users-previous',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                assignments: {
                    description: 'The number of new assignments created across your applications',
                    ticks: false,
                    granularity: d3.timeDay,
                    start: four_weeks_ago,
                    end: midnight,
                    timestamp: {
                        visible: 'sometimes',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'always'
                    },
                    series: [{
                        title: 'Assignments Created',
                        id: 'assignments-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'last',
                        data: []
                    },
                    {
                        title: 'Previous Shared Users',
                        id: 'shared-users-previous',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                grades: {
                    description: 'The number of grades returned to the LMS across your applications',
                    ticks: false,
                    granularity: d3.timeDay,
                    start: four_weeks_ago,
                    end: midnight,
                    timestamp: {
                        visible: 'sometimes',
                        format: 'MMM D',
                    },
                    delta: {
                        visible: 'always'
                    },
                    series: [{
                        title: 'Grades Submitted',
                        id: 'grades-total',
                        plot: 'line',
                        color: '#006aff',
                        legend: 'last',
                        data: []
                    },
                    {
                        title: 'Previous Grades',
                        id: 'grades-previous',
                        plot: 'line',
                        color: '#BBB',
                        style: 'dashed',
                        legend: 'none',
                        data: []
                    }]
                },
                applications: [],
                all_integrations: [],
                metrics: [],
                reports: {
                    region: null,
                    regions: [
                        {
                            id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                            name: 'United States',
                            picture: '/flags/us.svg'
                        },
                        {
                            id: '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd',
                            name: 'Canada',
                            picture: '/flags/ca.svg'
                        },
                        {
                            id: '2572d321-6d8b-458b-8024-8e5ab6bad7b6',
                            name: 'Germany',
                            picture: '/flags/de.svg'
                        },
                        {
                            id: '2104f649-fe39-4fa1-96be-8682e9840dcf',
                            name: 'Australia',
                            picture: '/flags/au.svg'
                        }
                    ],
                    integration: null,
                    integrations: {
                        all: [],
                        items: null,
                        promise: null,
                        query: ''
                    }
                }
            };
        },
        created(){
            if(this.team?.type === 'developer'){
                this.load_applications();

                this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`)
                    .then(response => this.all_integrations = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading your integrations.'));

                this.load_request_metrics();
            }
        },
        computed: {
            latency(){
                if(this.today.length === 0){
                    return '—';
                }

                if(this.primary){
                    // We're looking at data for a specific hour.
                    const hour = this.today.find(d => d.start_date === this.primary.toISOString());

                    // Return the value for this bar, or nothing.
                    return hour ? hour.requests_latency_average.toFixed(2) + ' ms' : '—';
                }

                // Just return the average latency over the 24 hour period we're looking at.
                return d3.mean(this.today, m => m.requests_latency_average).toFixed(2) + ' ms';
            },
            total_logins(){
                if(this.today.length === 0){
                    return '—';
                }

                if(this.primary){
                    // We're looking at data for a specific hour.
                    const hour = this.today.find(d => d.start_date === this.primary.toISOString());

                    // Return the value for this bar, or nothing.
                    return hour ? hour.logins_completed.toLocaleString() : '—';
                }

                // Just return the sum of the total completed logins over the 24 hour period we're looking at.
                return d3.sum(this.today, m => m.logins_completed).toLocaleString();
            },
            failure_rate(){
                if(this.today.length === 0){
                    return '—';
                }

                if(this.primary){
                    // We're looking at data for a specific hour.
                    const hour = this.today.find(d => d.start_date === this.primary.toISOString());

                    // Return the value for this bar, or nothing.
                    if(hour?.requests_total){
                        return (hour.requests_failed / hour.requests_total * 100).toFixed(2) + '%';
                    }else{
                        return '—';
                    }
                }

                // Just return the sum of the total completed logins over the 24 hour period we're looking at.
                const total = d3.sum(this.today, m => m.requests_total);
                const failed = d3.sum(this.today, m => m.requests_failed);

                if(total){
                    return (failed / total * 100).toFixed(2) + '%';
                }else{
                    return '—';
                }
            },
            team() {
                return this.$store.getters.team;
            },
            development_applications() {
                return this.applications ? this.applications.filter(filterDevelopment) : [];
            },
            verifiableApplications() {
                return this.applications.length && this.development_applications.length === 0
            },
            verified_applications() {
                return this.applications ? this.applications.filter(application => ['released', 'approved'].includes(application.status)) : [];
            },
            pending_applications() {
                return this.applications ? this.applications.filter(application => application.status === 'awaiting_approval') : [];
            },
            real_integrations() {
                return this.all_integrations ? this.all_integrations.filter(real_integrationsFilter) : [];
            },
            user(){
                return this.$store.state.user;
            },
            steps() {
                if(!this.applications.length){
                    return [];
                }

                if(!this.all_integrations.length){
                    return ['application'];
                }

                if(!this.team.verified){
                    return ['application', 'integration'];
                }

                if(!this.real_integrations.length){
                    return ['application', 'integration', 'verification'];
                }

                return ['application', 'integration', 'verification', 'production'];
            }
        },
        watch: {
            team: {
                immediate: true,
                handler(){
                    
                }
            }
        },
        methods: {
            switch_region(region){
                this.reports.region = region;
                this.reset();
            },
            switch_integration(integration){
                this.reports.integration = integration;
                this.reset();
            },
            reset(){
                // this.all = [];
                // this.more = false;
                // this.page = 0;
                // this.load();
            },
            integration_source(query){
                this.reports.integrations.query = query;

                if(!this.reports.integrations.promise){
                    this.reports.integrations.promise = this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`).then(response => {
                        this.reports.integrations.all = response.$data.map(integration => {
                            return {
                                id: integration.id,
                                name: integration.team.name,
                                picture: integration.provider.icon_url
                            };
                        })
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
                
                return this.reports.integrations.promise.then(() => {
                    if(this.reports.integrations.query){
                        this.reports.integrations.items = this.reports.integrations.all.filter(integration => {
                            return integration.name.toLowerCase().indexOf(this.integrations.query.toLowerCase()) > -1;
                        }).slice(0, 50);
                    }else{
                        this.reports.integrations.items = this.reports.integrations.all.slice(0, 50);
                    }
                });
            },
            load_applications() {
                this.$http.get(`/teams/${this.$store.getters.team.id}/applications`)
                    .then(response => this.applications = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading your team\'s applications.'));
            },
            invite_members() {
                this.$store.dispatch('drawer/open', {
                    key: 'invite-members',
                    width: 450,
                    component: InviteMembers
                });
            },
            create_application() {
                this.$store.dispatch('drawer/open', {
                    key: 'create-application',
                    width: 450,
                    component: CreateApplication
                });
            },
            async create_sandbox_integration() {
                if (this.applications.length > 1) {
                    this.$modal.show(SelectApplication, {
                        title: 'Select An Application',
                        filter: application => application,
                        async select(application) {
                            this.$store.dispatch('drawer/open', {
                                key: 'create-sample-integration',
                                width: 450,
                                component: CreateSampleIntegration,
                                props: {
                                    application
                                }
                            });
                        },
                    }, { width: 320, height: 'auto', classes: 'modal', scrollable: true });
                } else {
                    this.$store.dispatch('drawer/open', {
                        key: 'create-sample-integration',
                        width: 450,
                        component: CreateSampleIntegration,
                        props: {
                            application: this.applications[0]
                        }
                    });
                }
            },
            async request_integration(){
                if (this.applications.length > 1) {
                    this.$modal.show(SelectApplication, {
                        title: 'Select An Application',
                        filter: application => application,
                        select(application) {
                            this.$clipboard(`https://ed.link/integrate/${application.id}`);
                            this.$toasted.success('Copied integration link to your clipboard');
                        },
                    }, { width: 320, height: 'auto', classes: 'modal', scrollable: true });
                } else {
                    this.$clipboard(`https://ed.link/integrate/${this.applications[0].id}`);
                    this.$toasted.success('Copied integration link to your clipboard');
                }
            },
            collate(data, granularity = d3.timeDay){
                const buckets = new Map();

                for(const datum of data){
                    const bucket = granularity.floor(new Date(datum.date)).toISOString();

                    if(!buckets.has(bucket)){
                        buckets.set(bucket, {
                            date: bucket,
                            value: 0
                        });
                    }

                    buckets.get(bucket).value += datum.value;
                }

                return Array.from(buckets.values());
            },
            last(data, granularity = d3.timeDay){
                const buckets = new Map();

                for(const datum of data){
                    const date = new Date(datum.date);
                    const bucket = granularity.floor(date).toISOString();

                    if(!buckets.has(bucket)){
                        buckets.set(bucket, {
                            date: bucket,
                            last: date,
                            value: 0
                        });
                    }

                    const current = buckets.get(bucket);

                    if(current.last <= date){
                        // I'm making the conscious choice to skip over zero values.
                        // This does introduce the possibility of someone intentionally reverting to zero
                        // integrations / shared users but this will only remain a problem until the next day.
                        if(datum.value === 0){
                            continue;
                        }

                        current.value = datum.value;
                        current.last = date;
                    }
                }

                return Array.from(buckets.values());
            },
            async load_request_metrics(){
                const yesterday = new Date(midnight);

                // Roll the time back a day (accounting for time changes).
                yesterday.setDate(yesterday.getDate() - 1);

                const [requests_current, requests_previous] = this.requests.series;
                const [integrations_current, integrations_previous] = this.integrations.series;
                const [shared_current, shared_previous] = this.shared.series;
                const [logins_current, logins_previous] = this.logins.series;
                const [activity_current, activity_previous] = this.activity.series;
                const [assignments_current, assignments_previous] = this.assignments.series;
                const [grades_current, grades_previous] = this.grades.series;

                this.$http.get(`/teams/${this.$store.getters.team.id}/metrics`, {
                    baseURL: '/api/v2',
                    params: {
                        $first: 24,
                        $fields: 'start_date,requests_total,requests_failed,requests_latency_average,logins_completed',
                        $filter: {
                            start_date: [
                                { operator: 'gte', value: midnight },
                                { operator: 'lte', value: d3.timeHour.floor(new Date()) }
                            ]
                        }
                    }
                })
                .then(response => {
                    requests_current.data = response.$data.map(m => {
                        return {
                            date: m.start_date,
                            value: m.requests_total
                        };
                    });

                    // We can use this same result to populate latency, logins, and failed request percentage.
                    this.today = response.$data;
                });
                
                // This needs 25 bars because it includes midnight "today" as well as midnight "yesterday".
                this.$http.get(`/teams/${this.$store.getters.team.id}/metrics`, {
                    baseURL: '/api/v2',
                    params: {
                        $first: 25,
                        $fields: 'start_date,requests_total',
                        $filter: {
                            start_date: [
                                { operator: 'gte', value: yesterday },
                                { operator: 'lte', value: midnight }
                            ]
                        }
                    }
                })
                .then(response => {
                    requests_previous.data = response.$data.map(m => {
                        const date = new Date(m.start_date);

                        // Shift the date forward in time by 1 day.
                        date.setDate(date.getDate() + 1);

                        return {
                            date: date.toISOString(),
                            value: m.requests_total
                        };
                    });
                });

                // Loading the monthlies is a bit tougher. We need to collate the bars into months.
                this.$http.get(`/teams/${this.$store.getters.team.id}/metrics`, {
                    baseURL: '/api/v2',
                    params: {
                        $first: 700,
                        $fields: 'start_date,integrations_active,integrations_paused,shared_people,logins_completed,requests_category_grades,requests_category_assignments,active_people',
                        $filter: {
                            start_date: [
                                { operator: 'gte', value: four_weeks_ago },
                                { operator: 'lte', value: new Date().toISOString() }
                            ]
                        }
                    }
                })
                .then(response => {
                    integrations_current.data = this.last(
                        response.$data.map(m => {
                            return {
                                date: m.start_date,
                                value: m.integrations_active + m.integrations_paused
                            };
                        })
                    );

                    shared_current.data = this.last(
                        response.$data.map(m => {
                            return {
                                date: m.start_date,
                                value: m.shared_people
                            };
                        })
                    );

                    logins_current.data = this.collate(
                        response.$data.map(m => {
                            return {
                                date: m.start_date,
                                value: m.logins_completed
                            };
                        })
                    );

                    activity_current.data = this.collate(
                        response.$data.map(m => {
                            return {
                                date: m.start_date,
                                value: m.active_people
                            };
                        })
                    );

                    assignments_current.data = this.collate(
                        response.$data.map(m => {
                            return {
                                date: m.start_date,
                                value: m.requests_category_assignments
                            };
                        })
                    );

                    grades_current.data = this.collate(
                        response.$data.map(m => {
                            return {
                                date: m.start_date,
                                value: m.requests_category_grades
                            };
                        })
                    );
                });

                // Loading the monthlies is a bit tougher. We need to collate the bars into months.
                this.$http.get(`/teams/${this.$store.getters.team.id}/metrics`, {
                    baseURL: '/api/v2',
                    params: {
                        $first: 700,
                        $fields: 'start_date,integrations_active,integrations_paused,shared_people,logins_completed,requests_category_grades,requests_category_assignments,active_people',
                        $filter: {
                            start_date: [
                                { operator: 'gte', value: eight_weeks_ago },
                                { operator: 'lte', value: four_weeks_ago }
                            ]
                        }
                    }
                })
                .then(response => {
                    integrations_previous.data = this.last(
                        response.$data.map(m => {
                            const date = new Date(m.start_date);

                            // Shift the date forward in time by 28 days.
                            date.setDate(date.getDate() + 28);
                            
                            return {
                                date: date.toISOString(),
                                value: m.integrations_active + m.integrations_paused
                            };
                        })
                    );

                    shared_previous.data = this.last(
                        response.$data.map(m => {
                            const date = new Date(m.start_date);

                            // Shift the date forward in time by 28 days.
                            date.setDate(date.getDate() + 28);
                            
                            return {
                                date: date.toISOString(),
                                value: m.shared_people
                            };
                        })
                    );

                    logins_previous.data = this.collate(
                        response.$data.map(m => {
                            const date = new Date(m.start_date);

                            // Shift the date forward in time by 28 days.
                            date.setDate(date.getDate() + 28);
                            
                            return {
                                date: date.toISOString(),
                                value: m.logins_completed
                            };
                        })
                    );

                    activity_previous.data = this.collate(
                        response.$data.map(m => {
                            const date = new Date(m.start_date);

                            // Shift the date forward in time by 28 days.
                            date.setDate(date.getDate() + 28);
                            
                            return {
                                date: date.toISOString(),
                                value: m.active_people
                            };
                        })
                    );

                    assignments_previous.data = this.collate(
                        response.$data.map(m => {
                            const date = new Date(m.start_date);

                            // Shift the date forward in time by 28 days.
                            date.setDate(date.getDate() + 28);
                            
                            return {
                                date: date.toISOString(),
                                value: m.requests_category_assignments
                            };
                        })
                    );

                    grades_previous.data = this.collate(
                        response.$data.map(m => {
                            const date = new Date(m.start_date);

                            // Shift the date forward in time by 28 days.
                            date.setDate(date.getDate() + 28);
                            
                            return {
                                date: date.toISOString(),
                                value: m.requests_category_grades
                            };
                        })
                    );
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .dashboard
    {
        top: @breadcrumbs-height;
    }

    h4
    {
        font-size: 14px;
        font-weight: 500;
        color: @black;
        letter-spacing: -0.02rem;
    }

    .icon
    {
        display: block;
        width: 18px;
        height: 18px;
        font-size: 18px;
        margin-right: 8px;
    }

    .extras
    {
        min-width: 220px;
        padding-left: 20px;

        .extra
        {
            margin-top: 20px;
        }
    }

    .pipeline-card
    {
        .child-arrow
        {
            display: none;
        }

        &.child
        {
            padding-left: 20px;

            .child-arrow
            {
                display: block;
                font-size: 20px;
                padding-right: 12px;
                color: @grey;
            }

            .source-icon
            {
                display: none;
            }
        }
    }

    .getting-started
    {
        padding: 80px 30px;
        max-width: 1400px;

        .guide-header-columns
        {
            align-items: flex-start;
        }
        
        .guide-header-column
        {
            flex: 1.5;
            margin-right: 50px;
        }    

        .guide-page-header
        {
            font-size: 48px;
            line-height: 54px;
            font-weight: 500;
            letter-spacing: -0.05rem;
        }

        .guide-page-subheader
        {
            color: #012649;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            margin: 20px 0px;
            max-width: 500px;
        }

        .guide-step-actions
        {
            margin-top: 10px;

            .button
            {
                margin-right: 15px;
            }
        }

        .guide-steps
        {
            padding-top: 30px;
        }

        .guide-step
        {
            padding-bottom: 50px;
            padding-left: 50px;

            &:last-child
            {
                padding-bottom: 0;

                .guide-step-track::after
                {
                    display: none;
                }
            }

            &.validation
            {
                .guide-step-track
                {
                    &::after
                    {
                        display: none;
                    }
                }
            }

            &.complete
            {
                .guide-step-track
                {
                    &::after
                    {
                        border-color: @base;
                    }

                    &::before
                    {
                        transform: scale(1);
                    }
                }
                
                .guide-step-icon
                {
                    background: @base;
                    color: @f;
                }
            }

            &.loading
            {
                padding-bottom: 131px;
            }
        }

        .guide-step-track
        {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 31px;

            &::after
            {
                content: "";
                position: absolute;
                top: 41px;
                bottom: 10px;
                left: 15px;
                width: 0px;
                border-left: 1px dashed @e4;
                // background: @e4;
                transition: all ease 0.2s;
            }

            &::before
            {
                content: "";
                position: absolute;
                top: 10px;
                left: -20px;
                width: 11px;
                height: 11px;
                background: url('~@/assets/icons/base/check.svg');
                background-size: 15px auto;
                background-position: -2px 0;
                background-repeat: no-repeat;
                transition: all ease 0.2s;
                display: block;
                transform: scale(0);
            }
        }
        
        .guide-step-icon
        {
            width: 31px;
            height: 31px;
            background: @e;
            border-radius: 50%;
            color: @grey;
            font-size: 17px;
            transition: all ease 0.2s;

            .icon
            {
                margin: 0;
            }

            .spinner.slow.step-loading
            {
                transform: scale(0.8);

                .path
                {
                    stroke: @grey;
                }
            }
        }
        
        .guide-step-name
        {
            line-height: 31px;
            font-size: 15px;
            font-weight: 500;
            color: @black;
        }

        .guide-step-body
        {
            margin: 2px 0;
            line-height: 20px;
            font-size: 14px;
            color: @grey;
            max-width: 500px;
        }
    }

    .guides-list
    {
        border-radius: 6px;
        background: @f8;
        padding: 30px;

        .guide-header
        {
            margin-top: 20px;
            margin-bottom: 10px;
            padding-top: 20px;
            border-top: 1px solid @e;

            h2
            {
                font-size: 16px;
                color: @black;
                font-weight: 500;
            }

            &:first-child
            {
                margin-top: 0;
                border-top: 0;
                padding-top: 0;
            }
        }

        .guide-links
        {
            align-items: flex-start;
        }

        .guide-link
        {
            line-height: 28px;
            font-size: 14px;
            color: @base;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::after
            {
                content: "";
                position: absolute;
                border-bottom: 1px solid @base;
                top: 0;
                left: 0;
                bottom: 2px;
                pointer-events: none;
                width: 0;
                transition: width .3s ease;
                opacity: .8;
            }

            &:hover, &:active
            {
                &::after
                {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 1400px) {
        .getting-started
        {
            padding: 50px 20px;

            .guide-page-header
            {
                font-size: 36px;
                line-height: 42px;
            }

            .guide-page-subheader
            {
                font-size: 16px;
                line-height: 22px;
            }
        
            .guide-header-column
            {
                margin-right: 80px;
            }

            .guides-list
            {
                .button
                {
                    height: 26px;
                    line-height: 24px;
                    padding: 0 8px;
                    font-size: 12px;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .getting-started
        {
            padding: 50px 20px;

            .guide-page-header
            {
                font-size: 36px;
                line-height: 42px;
            }

            .guide-page-subheader
            {
                font-size: 16px;
                line-height: 22px;
            }
        
            .guide-header-column
            {
                margin-right: 50px;
            }

            .guides-list
            {
                padding: 20px;
                
                .button
                {
                    display: none;
                }

                .guide-header
                {
                    h2
                    {
                        font-size: 15px;
                    }
                }

                .guide-link
                {
                    line-height: 24px;
                    font-size: 13px;
                }
            }

            .guide-step-body
            {
                font-size: 13px;
                line-height: 18px;
                margin: 0;
            }
        }
    }

    .analytics
    {
        .metric
        {
            padding: @section-padding;
        }
    }

    .components
    {
        h1
        {
            font-size: 20px;
            line-height: 34px;
            font-weight: 500;
            letter-spacing: -0.05rem;
        }

        .component
        {
            border-bottom: 1px solid @e;
        }

        .component-header
        {
            border-bottom: 1px solid @e;
            padding-bottom: 10px;
        }

        .metrics
        {
            flex: 0.4;
            max-width: 420px;
            border-left: 1px solid @e;

            .metric
            {
                border-bottom: 1px solid @e;
                padding: @section-padding;

                &:last-child
                {
                    border-bottom: 0;
                }

                .metric-title
                {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    color: @darkgrey;
                }

                .metric-value
                {
                    color: @black;
                    font-size: 20px;
                    line-height: 24px;
                    margin: 4px 0;
                }

                .metric-description
                {
                    font-size: 13px;
                    line-height: 16px;
                    color: @grey;
                }
            }
        }
    }

    .reporting
    {
        header
        {
            padding: 0;
            height: 60px;
        }

        .filters
        {
            // padding: 0 20px;
        }

        .component
        {
            height: 220px;
            padding: @section-padding;
            border-right: 1px solid @e;

            &:nth-child(3n)
            {
                border-right: 0;
            }
        }
    }

    .product-updates
    {
        border-top: 1px solid @e;
        padding: 25px 0;
    }
</style>