<template>
    <information class="integration-sharing full scroll" v-if="integration">
        <template v-slot:title>Sharing Rules</template>
        <template v-slot:description>
            <div>
                These rules are used to determine what is shared from {{ integration.team.name }} to {{ integration.application.name }}.
                Rules are dynamic and the data shared will be updated automatically.
                For example, if a new student joins a shared class, they will be automatically shared with {{ integration.application.name }}.
            </div>
            <div class="sharing-settings flex flex-align">
                <template v-if="district">
                    <checkbox label="Enable District Sharing Rules" :checked="integration.district_sharing === 'rules'" @click.native.prevent="toggle" :disabled="team.membership.type === 'read' || loading.state" />
                    <div class="sharing-setting flex flex-align tooltip tooltip-top" tooltip="Only developers can change this setting" :class="{enabled: integration.developer_sharing === 'rules'}">
                        <Check v-if="integration.developer_sharing === 'rules'" class="icon" width="16" height="16" stroke-width="2" />
                        <Cancel v-else class="icon" width="16" height="16" stroke-width="2" />
                        Developer Rules are {{ integration.developer_sharing === 'rules' ? 'Enabled' : 'Disabled' }}
                    </div>
                </template>
                <template v-else>
                    <checkbox label="Enable Developer Sharing Rules" :checked="integration.developer_sharing === 'rules'" @click.native.prevent="toggle" :disabled="team.membership.type === 'read' || loading.state" />
                    <div class="sharing-setting flex flex-align tooltip tooltip-top" tooltip="Only districts can change this setting" :class="{enabled: integration.district_sharing === 'rules'}">
                        <Check v-if="integration.district_sharing === 'rules'" class="icon" width="16" height="16" stroke-width="2" />
                        <Cancel v-else class="icon" width="16" height="16" stroke-width="2" />
                        District Rules are {{ integration.district_sharing === 'rules' ? 'Enabled' : 'Disabled' }}
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:details>
            <nav class="flex flex-align">
                <div v-if="developer" class="pill" :class="{active: tab === 'developer'}" @click="tab = 'developer'">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Developer Rules
                </div>
                <div class="pill" :class="{active: tab === 'district'}" @click="tab = 'district'">
                    <City class="icon" width="16" height="16" stroke-width="2" />
                    District Rules
                </div>
                <div v-if="district" class="pill" :class="{active: tab === 'developer'}" @click="tab = 'developer'">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Developer Rules
                </div>
                <div class="ff"></div>
                <div class="sharing-rules-summary text-overflow">{{ summary }}</div>
                <div class="create-rule">
                    <div class="button base has-icon has-icon-right flex flex-align" :class="{ 'disabled': tab !== team.type }" @click="creating = !creating">
                        Create a Rule
                        <NavArrowDown class="icon" width="16" height="16" stroke-width="2" />
                    </div>
                    <dropdown :open="creating" left="auto" right="-4px" width="340px" height="auto" padding="4px 0" ref="creating">
                        <dropdown-item @click.native="add('classes')">
                            <template v-slot:icon>
                                <Apple class="icon" width="16" height="16" stroke-width="2" />
                            </template>
                            <template v-slot:title>Share By Class</template>
                            <template v-slot:description>
                                This rule will share selected classes along with the courses, sessions, teachers, and students associated with those classes.
                            </template>
                        </dropdown-item>
                        <dropdown-item @click.native="add('schools')">
                            <template v-slot:icon>
                                <Building class="icon" width="16" height="16" stroke-width="2" />
                            </template>
                            <template v-slot:title>Share By School</template>
                            <template v-slot:description>
                                This rule will share selected schools, along with all of the people, classes, courses, and sessions associated with those schools.
                            </template>
                        </dropdown-item>
                        <dropdown-item @click.native="add('people')">
                            <template v-slot:icon>
                                <Group class="icon" width="16" height="16" stroke-width="2" />
                            </template>
                            <template v-slot:title>Share By Person</template>
                            <template v-slot:description>
                                This rule will share individual people and optionally, their associated classes. It will not share other people's enrollments.
                            </template>
                        </dropdown-item>
                    </dropdown>
                </div>
            </nav>
            <integration-rules :integration="integration" :rules="rules" :counts="counts" :empty="`There are no ${tab} sharing rules created for this integration.`" :owner="team.type" />
        </template>
    </information>
</template>

<script>
    import Options from '@/components/modals/Options.vue';
    import { ArrowUpRight, City, Svg3dSelectSolid, Filter, Check, Cancel, Apple, Building, Group, NavArrowDown } from '@epiphany/iconoir';
    const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

    export default {
        name: 'IntegrationSharing',
        components: {
            ArrowUpRight,
            City,
            Svg3dSelectSolid,
            FilterIcon: Filter,
            Check,
            Cancel,
            Apple,
            Building,
            Group,
            NavArrowDown
        },
        data(){
            return {
                tab: 'developer',
                applications: [],
                all: [],
                search: '',
                loading: {
                    rules: true,
                    state: false
                },
                materialization: null,
                creating: false
            };
        },
        created(){
            this.tab = this.team.type;

            this.$http.get(`${url}/api/v2/graph/materializations`, {
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`,
                },
                params: {
                    $last: 1,
                    $filter: {
                        state: [{ operator: 'equals', value: 'complete' }]
                    }
                }
            })
            .then(response => this.materialization = response.$data?.pop())
            .catch(error => this.$toasted.error('There was an error fetching the latest materialization.'));
            
            window.addEventListener('mousedown', this.click_outside);
            window.addEventListener('keydown', this.escape);
        },
        destroyed() {
            window.removeEventListener('mousedown', this.click_outside);
            window.removeEventListener('keydown', this.escape);
        },
        computed: {
            developer(){
                return this.team.type === 'developer';
            },
            district(){
                return this.team.type === 'district';
            },
            integration(){
                return this.$store.getters.integration;
            },
            team(){
                return this.$store.getters.team;
            },
            summary(){
                let summary = '';

                if(this.tab === 'district' && this.integration.district_sharing === 'all'){
                    return 'District rules are currently disabled';
                }

                if(this.tab === 'developer' && this.integration.developer_sharing === 'all'){
                    return 'Developer rules are currently disabled';
                }

                if(this.tab === 'developer'){
                    summary += `${this.$options.filters.commas(this.rules.length)} developer rule${this.rules.length === 1 ? '' : 's'}`;
                }else{
                    summary += `${this.$options.filters.commas(this.rules.length)} district rule${this.rules.length === 1 ? '' : 's'}`;
                }

                const counts = _.get(this.materialization, `output.rules.${this.tab}.counts.total`);

                if(counts){
                    summary += ` ${this.rules.length === 1 ? 'is' : 'are'} sharing ${this.$options.filters.commas(counts.people)} ${counts.people === 1 ? 'person' : 'people'} and ${this.$options.filters.commas(counts.classes)} class${counts.classes === 1 ? '' : 'es'}`;
                }

                return summary;
            },
            counts(){
                return _.get(this.materialization, `output.rules.${this.tab}.counts.rules`);
            },
            rules(){
                return this.integration.rules.filter(rule => rule.type === 'sharing').filter(rule => rule.owner === this.tab);
            }
        },
        methods: {
            click_outside(e) {
                if (this.creating) {
                    if (this.$refs.creating && !this.$refs.creating.$el.contains(e.target)) {
                        this.creating = false;
                    }
                }
            },
            escape(e) {
                if (e.key === 'Escape') {
                    this.creating = false;
                }
            },
            add(target){
                this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/rules`, {
                    state: 'active',
                    target,
                    conditions: []
                })
                .then(rule => {
                    this.$store.dispatch('integrations/did_create_rule', rule.$data)
                    this.$toasted.success('New sharing rule created.');

                    this.creating = false;

                    const clone = _.cloneDeep(rule.$data);
                    clone.state = 'active'

                    this.$store.dispatch('drawer/open', {
                        key: 'edit-rule',
                        width: 800,
                        component: 'edit-rule',
                        props: {
                            rule: clone,
                            licensing: false
                        }
                    });
                })
                .catch(error => {
                    this.$toasted.error('There was an error creating a new sharing rule.');
                });
            },
            toggle(){
                const _this = this;
                const scope = this.developer ? this.integration.developer_sharing : this.integration.district_sharing;
                const toggled = scope === 'all' ? 'rules' : 'all';

                this.$modal.show(Options, {
                    title: 'Switch Sharing Type',
                    description: 'Are you sure you want to change the data sharing type? Changes will take effect immediately and may disrupt live users.',
                    options: [
                        {
                            title: `Switch to ${toggled === 'all' ? 'Share All Data' : 'Share By Rules'}`,
                            color: 'base',
                            fn(){
                                _this.save(toggled);
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            save(scope){
                const patch = this.developer ? { developer_sharing: scope } : { district_sharing: scope };

                // Update the local variable.
                this.loading.state = true;

                // Update the store.
                this.$store.dispatch('save/save', 'integrations/rules/state');

                // Actually make the request.
                this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}`, patch)
                .then((response) => {
                    this.$store.commit('integrations/update', patch);

                    // Show a success message.
                    this.$toasted.success(scope === 'all' ? 'Now sharing all data.' : 'Now sharing by rules.')
                })
                .catch(error => {
                    this.$toasted.error('There was an error updating the integration.');
                })
                .finally(() => {
                    // Update the local variable.
                    this.loading.state = false;

                    // Update the store.
                    this.$store.dispatch('save/saved', 'integrations/rules/state');
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 4px;

        .sharing-settings
        {
            margin-top: @double-padding;

            .checkbox::v-deep
            {
                &.checked
                {
                    border-color: fade(@base, 30%);
                    background-color: fade(@base, 20%);

                    .checkbox-label
                    {
                        color: @base;
                    }

                    &:active
                    {
                        border-color: @base;
                    }
                }

                &.disabled
                {
                    background-color: fade(@grey, 10%);
                    border-color: fade(@grey, 10%);
                    pointer-events: none;

                    input
                    {
                        background-color: @lightgrey;
                        border-color: @lightgrey;
                    }

                    .checkbox-label
                    {
                        color: @lightgrey;
                    }
                }
            }

            .sharing-setting
            {
                margin-left : @double-padding;
                font-size: 15px;
                cursor: default;
                user-select: none;

                .icon
                {
                    margin-right: 6px;
                }

                &.enabled
                {
                    color: @base;
                }
            }
        }
    }

    .sharing-rules-summary
    {
        font-size: 14px;
        color: @grey;
        line-height: 16px;
    }

    .integration-rules
    {
        margin-top: @double-padding;
    }

    .create-rule {
        margin-left: @double-padding;
    }

    .create-rule::v-deep
    {
        .dropdown-item
        {
            padding: @single-padding @double-padding @single-padding @single-padding;
        }

        .dropdown-item-default-template.flex.flex-align
        {
            .dropdown-icon
            {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                background-color: fade(@base, 10%);
                margin-right: @single-padding + 2px;

                .icon
                {
                    color: @base;
                }
            }

            .dropdown-title
            {
                line-height: 16px;
                margin-bottom: 2px;
            }
        }
    }

    .rule-type
    {
        padding: 20px 0;
        border-top: 1px solid @e4;
        cursor: pointer;

        .icon
        {
            font-size: 18px;
            width: 18px;
            height: 18px;
            margin-right: 6px;
            color: @base;

            &.iconoir-arrow-right
            {
                font-size: 22px;
                width: 22px;
                height: 22px;
                margin-right: 0;
                color: @grey;
                margin-left: 20px;
                transition: all ease 0.2s;
            }
        }

        .rule-type-title
        {
            font-size: 13px;
            color: @base;
            font-weight: 500;
        }

        .rule-type-description
        {
            margin-top: 10px;
            font-size: 13px;
            color: @black;
            line-height: 16px;
        }

        &:hover, &:active
        {
            .iconoir-arrow-right
            {
                transform: translate(8px, 0);
            }
        }
    }
</style>