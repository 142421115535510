<template>
    <div class="integrate-source-provider-skyward">
        <!-- <integrate-source-editor-step name="Enter Your Skyward URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers and students go to sign into Skyward.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.skyward.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step> -->
        <template v-if="!essential">
            <integrate-source-editor-step name="Skyward Interface File" icon="iconoir-download" :complete="completed.includes('xml')">
                <div class="helptext">
                    Download the Skybuild interface from Edlink. You will upload this file to Skyward later.
                </div>
                <div class="flex">
                    <div class="button has-icon" @click="download()">
                        <Download class="icon" width="16" height="16" stroke-width="2" /> Download Skybuild Interface
                    </div>
                </div>
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Create Export Interface" icon="iconoir-add-database-script" :complete="completed.includes('job')">

                <integrate-source-editor-configuration title="How to add an Export Interface" icon="iconoir-info-empty">
                    <!-- <div class="image-label flex flex-align">
                        <div v-tooltip.top="!completed.includes('url') ? 'Enter your Skyward URL' : ''">
                            1. Open <a class="configuration-link inline-block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="settings_url">
                                Skyward WSM
                            </a>
                        </div>
                    </div> -->
                    <div class="image-label flex flex-align">
                        <label>1. Open Skyward SkyPort (Web Student Management)</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/skyport.png', { title: 'Navigate to SkyPort' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>2. Navigate to <code>Administration</code> -> <code>Exports - EX</code>.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/admin-exports.png', { title: 'Navigate to Skybuild Exports' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>3. Select Export <code>File Builder - EF</code>.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/file-builder.png', { title: 'Select File Builder' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>4. Click the <code>Load Interfaces from File</code> button.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/add-export.png', { title: 'Select Load Interfaces From File' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>5. Click choose file.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/choose-file.png', { title: 'Choose File' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>6. Select the <code>edlink.txt</code> file you downloaded in the previous step.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/upload-xml.png', { title: 'Choose File' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label >7. Click import.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/import-xml.png', { title: 'Import The File' })">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Mark as Complete" :checked.sync="job" />
                </div>
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Configure The Export Location" icon="iconoir-input-field" :complete="completed.includes('remote')">
                <integrate-source-editor-configuration title="How to configure the Export Location" icon="iconoir-info-empty">
                    <div class="image-label flex flex-align flex-wrap">
                        <label>1. Select the export you just created and click the <code>Edit</code> button.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/edit-export.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>2. Verify <code>FTP Files</code> is checked and <code>SFTP (with SSH)</code> is selected.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/sftp-configure.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>3. Enter the connection details into Skyward.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/sftp-configure.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>4. Run the Export</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/export.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>                
                    <div class="sftp-details">
                        <div class="flex flex-align">
                            <span class="block icon iconoir-server-connection"></span>
                            <h2 class="ff">Server Connection Details</h2>
                        </div>
                        <label>Host</label>
                        <div class="flex">
                            <div class="configuration-value copyable noshift" v-clipboard="() => 'sftp.ed.link'" v-clipboard:success="() => $toasted.info('Copied')">sftp.ed.link</div>
                        </div>
                        <label>Port</label>
                        <div class="flex">
                            <div class="configuration-value copyable noshift" v-clipboard="() => '2022'" v-clipboard:success="() => $toasted.info('Copied')">2022</div>
                        </div>
                        <label>Username</label>
                        <div class="flex">
                            <div class="configuration-value copyable noshift" v-clipboard="() => source.configuration.username" v-clipboard:success="() => $toasted.info('Copied')">{{source.configuration.username}}</div>
                        </div>
                        <label>Password</label>
                        <div class="flex">
                            <div class="configuration-value copyable noshift" v-clipboard="() => source.configuration.password" v-clipboard:success="() => $toasted.info('Copied')">{{source.configuration.password}}</div>
                        </div>
                    </div>
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Mark as Complete" :checked.sync="remote" />
                </div>
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Schedule Task" icon="iconoir-timer" :complete="completed.includes('schedule')">
                <integrate-source-editor-configuration title="How to schedule the files to be sent to Edlink on a regular basis." icon="iconoir-info-empty">
                    <div class="image-label flex flex-align flex-wrap">
                        <label>1. Click the <code>My Print Queue</code> button.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/tasks/skyward-print-queue.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>2. Click the <code>Tasks</code> button.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/tasks/skyward-tasks.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>3. Click the <code>Schedule This</code> button.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/tasks/skyward-schedule-this.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>
                    <div class="image-label flex flex-align flex-wrap">
                        <label>4. Enter your desired export schedule and then click the <code>Save</code> button.</label>
                        <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/skyward/onboarding/tasks/skyward-save.png')">
                            <span class="icon iconoir-info-empty block"></span>
                            <div>See a Picture</div>
                        </div>
                    </div>                
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Mark as Complete" :checked.sync="schedule" />
                </div>
            </integrate-source-editor-step>   
        </template>
        <!-- Start of Essential Onboarding -->
        <integrate-source-editor-step name="Enter Your Skyward One Roster Server URL" icon="iconoir-www" :complete="completed.includes('oneroster_server_url')">
            <div class="helptext">
                This is the URL where your One Roster server for Skyward is hosted.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.skyward.com/api" :value="source.configuration.oneroster_server_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_server_url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>   
        <integrate-source-editor-step name="Generate OneRoster Keys" icon="iconoir-key-alt-plus" :complete="completed.includes('oneroster')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    You can find instructions for generating these keys in Skyward 
                    <a class="configuration-link inline-block text-button mini" target="_blank" href="https://support.skyward.com/DeptDocs/Corporate/Documentation/Public%20Website/Tutorials/Software/4402515_API_Quick_Start_Guide.pdf">here</a>.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="oneroster" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <!-- <div class="helptext">
                The Client ID can be found in the "Details" column and typically starts with the number 1 followed by a bunch of zeros.
                The Secret Key can be found in the same column by clicking the "Show Key" button.
            </div> -->
            <label class="key-label">Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.oneroster_client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_id', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.oneroster_client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_secret', value: e.target.value})" />
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Select Timezone" icon="iconoir-clock-outline" :complete="completed.includes('timezone')">
            <div class="helptext">
                Select the timezone that your district is in.
            </div>
            <select class="medium" :value="source.configuration.timezone" @input="e => send('UPDATE_SOURCE', { path: 'configuration.timezone', value: e.target.value })">
                <option :value="undefined" hidden>Select a timezone</option>
                <option :value="null" hidden>Select a timezone</option>
                <option v-for="(key, value) of timezones" :value="key" :key="key">{{ value }}</option>
            </select>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';
    import { Download } from '@epiphany/iconoir'
    import { timezone_identifiers } from '@/enums';

    export default {
        name: 'IntegrateSourceProviderSkyward',
        components: {
            Download,
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            team(){
                return this.state.context.team;
            },
            destination(){
                return this.type === 'destination';
            },
            essential(){
                return this.state.context.essential;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                // if(!this.source.configuration.url?.match(url)){
                //     return [];
                // }

                if (!this.essential) {
                    if(!this.xml){
                        return ['url'];
                    }

                    if(!this.job){
                        return ['url', 'xml'];
                    }
                    
                    // 2. Confirm remote configuration is completed.
                    if(!this.remote){
                        return ['url', 'xml', 'job'];
                    }

                    if(!this.schedule){
                        return ['url', 'xml', 'job', 'remote'];
                    }
                }

                if(!this.source.configuration.oneroster_server_url?.match(url)){
                    return ['url', 'xml', 'job', 'remote', 'schedule'];
                }
                
                if(!this.oneroster){
                    return ['url', 'xml', 'job', 'remote', 'schedule', 'oneroster_server_url'];
                }

                // 3. Confirm that keys are set
                if(!this.source.configuration.oneroster_client_id?.trim().length || !this.source.configuration.oneroster_client_secret?.trim().length){
                    return ['url', 'xml', 'job', 'remote', 'schedule', 'oneroster_server_url', 'oneroster'];
                }

                if (!this.source.configuration.timezone) {
                    return ['url', 'xml', 'job', 'remote', 'schedule', 'oneroster_server_url', 'oneroster', 'keys'];
                }

                return ['url', 'xml', 'job', 'remote', 'schedule', 'oneroster_server_url', 'oneroster', 'keys', 'timezone'];
            },
            settings_url(){
                return this.completed.includes('url') ? new URL('/scripts/wsisa.dll/WService=wsSky/skyport.w', this.source.configuration.url).href : false;
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 9);
            }
        },
        data(){
            return {
                remote: false,
                schedule: false,
                oneroster: false,
                xml: false,
                job: false,
                timezones: timezone_identifiers
            };
        },
        mounted(){
            if (!this.essential) {
                this.createLogin();
            }
        },
        methods: {
            image(src, config = { title: null, width: 700, height: 'auto' }){
                this.$modal.show(HelpImage, { src, title: config.title }, { width: config.width ?? 700, height: config.height ?? 'auto', classes: 'modal' });
            },
            download(){
                window.open('https://edlink.github.io/docs/media/plugins/skyward/edlink.txt', '_blank');
                this.xml = true;
            },
            createLogin(){
                this.$http.post(`/teams/${this.team.id}/sources/sftp/users`, {})
                    .then(response => {
                        this.send('UPDATE_SOURCE', {
                            path: 'configuration',
                            value: {
                                host: 'sftp.ed.link',
                                path: 'skyward',
                                port: '2022',
                                username: response.$data.username,
                                password: response.$data.password
                            }
                        });
                    })
                    .catch(error => {
                        this.$toasted.error('There was an error generating SFTP keys.');
                    });
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .configuration-value {
        img {
            width: 100%;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .image-label {
        margin: 15px 0;

        .iconoir-info-empty {
            color: @base;
        }

        label, a {
            font-size: 14px;
            margin: 0;
        }

        label {
            color: @black;
        }

        code {
            display: inline-block;
            font-size: 13px;
            color: @black;
            background: @e;
            border-radius: 3px;
            font-weight: 400;
            padding: 0 4px;
            line-height: 20px;
        }
    }


    
    .sftp-details
    {
        background: @e;
        border-radius: 6px;
        padding: 15px;
        width: 100%;

        .icon
        {
            margin-right: 10px;
            color: @black;
            font-size: 18px;
        }

        h2
        {
            font-size: 13px;
            color: @black;
            font-weight: 400;
        }

        label
        {
            margin: 15px 0 4px;
            font-size: 12px;
            letter-spacing: 0;
            color: @grey;
        }

        .configuration-link
        {
            font-weight: 400;
            font-size: 14px;
            justify-content: flex-start;
            line-height: 16px;
            height: 16px;
            padding-right: 18px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -1px top 1px;
            background-size: 14px auto;

            &.disabled
            {
                background-image: url('~@/assets/icons/grey/external.svg');
            }
        }

        .configuration-value
        {
            color: @black;
            font-size: 14px;
            line-height: 16px;

            &.monospace
            {
                font-size: 13px;
            }

            &.copyable
            {
                &.noshift
                {
                    &::after
                    {
                        height: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
</style>
