<template>
    <div class="options">
        <div class="options-title description">Generate Invoice</div>
        <div class="options-description">Enter the period you would like to generate an invoice for.</div>
        <div class="options-input">

            <div class="option-fields flex flex-row">
                <div class="option-field flex flex-column">
                    <label class="ff" for="period_start_date">Period Month</label>
                    <date-picker v-model="period_month" v-bind="{
                        type: 'month',
                        format: 'MM'
                    }" ref="input">
                    </date-picker>
                </div>

                <div class="option-field flex flex-column">
                    <label class="ff" for="period_start_date">Period Year</label>
                    <date-picker v-model="period_year" v-bind="{
                        type: 'year',
                        format: 'YYYY'
                    }" ref="input">
                    </date-picker>
                </div>
            </div>

            <!-- <div class="option-field flex flex-column">
                <label class="ff" for="period_start_date">Period Start</label>
                <date-picker v-model="period_start" v-bind="{
                    type: 'date',
                    format: 'MM/DD/YYYY',
                    range: false
                }">
                </date-picker>
            </div> -->

            <!-- <div class="option-field flex flex-column">
                <label class="ff" for="period_start_date">Period End</label>
                <date-picker v-model="period_end" v-bind="{
                    type: 'date',
                    format: 'MM/DD/YYYY',
                    range: false
                }">
                </date-picker>
            </div> -->
        </div>
        <div class="options-list flex">
            <template v-if="loading">
                <div class="option ff loading">
                    <spinner />
                </div>
            </template>
            <template v-else>
                <a class="option ff base" @click="confirm">Generate</a>
                <a class="option ff grey" @click="$emit('close')">Cancel</a>
            </template>
        </div>
    </div>
</template>

<script>
    // TODO: Support end date.

    export default {
        name: 'GenerateInvoice',
        props: {
            start: Date,
            end: Date,
            generate: Function
        },
        data(){
            return {
                loading: false,
                period_start: null,
                period_end: null,
                period_month: null,
                period_year: null
            };
        },
        created(){
            const today = new Date();

            // this.period_start = this.start ?? new Date(today.getFullYear(), today.getMonth(), 1);
            // this.period_end = this.end ?? new Date(today.getFullYear(), today.getMonth() + 1, 0);

            this.period_month = new Date();
            this.period_year = new Date();

            setTimeout(() => {
                // annoying because date picker fully opens when focused.
                // this.$refs.input?.focus();
            }, 30);

            window.addEventListener('keydown', this.keydown);
        },
        destroyed(){
            window.removeEventListener('keydown', this.keydown);
        },
        methods: {
            async confirm(){
                this.loading = true;
                await this.generate(new Date(this.period_year.getFullYear(), this.period_month.getMonth(), 1));
                this.$emit('close');
            },
            keydown(e){
                if (e.key === 'Enter') {
                    this.confirm();
                } else if (e.key === 'Escape') {
                    this.$emit('close');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    @option_height: 44px;

    .options
    {
        // width: 500px;
    }

    .options-title
    {
        font-size: 14px;
        color: @grey;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        text-align: center;

        &.description
        {
            line-height: 24px;
            padding-top: 15px;
            height: 39px;
        }
    }

    .options-description
    {
        font-size: 13px;
        color: @black;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        padding: 6px 15px 0;
    }

    .option
    {
        display: block;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        border-top: 1px solid @e;
        border-right: 1px solid @e;
        text-align: center;
        font-size: 16px;
        color: @base;
        transition: background-color ease 0.2s;
        cursor: pointer;
        user-select: none;

        &.grey
        {
            color: @grey;
        }

        &.red
        {
            color: @red;
        }

        &:hover, &:active
        {
            background: @f8;
        }

        &:last-child
        {
            border-right: none;
        }

        &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .options-input
    {
        padding: 15px;

        input {
            margin-bottom: 15px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }

    .option-fields {
        margin-bottom: 15px;

        .option-field {
            margin-bottom: 0;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .option-field {
        margin-bottom: 15px;

        .mx-datepicker {
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
