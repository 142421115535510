<template>
    <div class="ff drawer-content snapshots full">
        <datatable class="full" :columns="snapshots_columns" :rows="snapshots_rows" :loading="loading" sort="multi" :header="true" :clickable="false" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import Decimal from 'decimal.js';
    import _ from 'lodash';
    import * as d3Time from 'd3-time';

    export default {
        name: 'InvoiceSnapshots',
        props: {
            invoice: Object
        },
        data(){
            return {
                drawer_loading: true,
                loading: true,
                integrations: {},
                integration_totals: {},
                integration_snapshots: {},
                snapshots_columns: [
                    {
                        id: 'application',
                        name: 'Application',
                        width: '15%',
                        sort: (a, b) => this.integrations[a.integration_id].application.name.localeCompare(this.integrations[b.integration_id].application.name),
                        value: row => this.integrations[row.integration_id].application.name
                    },
                    {
                        id: 'integration',
                        name: 'Integration',
                        width: '15%',
                        sort: (a, b) => this.integrations[a.integration_id].source.team.name.localeCompare(this.integrations[b.integration_id].source.team.name),
                        value: row => this.integrations[row.integration_id].source.team.name
                    },
                    {
                        id: 'billing_category',
                        name: 'Billable',
                        width: '10%',
                        sort: (a, b) => {
                            const a_billing_category = this.integrations[a.integration_id].billing_category;
                            const b_billing_category = this.integrations[b.integration_id].billing_category;

                            if (a_billing_category === 'standard' && b_billing_category !== 'standard') {
                                return -1;
                            } else if (a_billing_category !== 'standard' && b_billing_category === 'standard') {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                        value: row => this.integrations[row.integration_id].billing_category === 'standard' ? 'Standard' : 'Development'
                    },
                    {
                        id: 'people',
                        name: 'People',
                        width: '12%',
                        sort: (a, b) => {
                            const a_total = a.people.total;
                            const b_total = b.people.total;

                            if (a_total.gt(b_total)) {
                                return 1;
                            } else if (a_total.lt(b_total)) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        value: row => row.people.total.toFixed(2)
                    },
                    {
                        id: 'demographics',
                        name: 'Demographics',
                        width: '12%',
                        sort: (a, b) => {
                            const a_demographics = a.people.demographics;
                            const b_demographics = b.people.demographics;

                            if (a_demographics.gt(b_demographics)) {
                                return 1;
                            } else if (a_demographics.lt(b_demographics)) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        value: row => row.people.demographics.toFixed(2)
                    },
                    {
                        id: 'daily_attendance',
                        name: 'Daily Attendance',
                        width: '12%',
                        sort: (a, b) => {
                            const a_daily_attendance = a.people.daily_attendance;
                            const b_daily_attendance = b.people.daily_attendance;

                            if (a_daily_attendance.gt(b_daily_attendance)) {
                                return 1;
                            } else if (a_daily_attendance.lt(b_daily_attendance)) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        value: row => row.people.daily_attendance.toFixed(2)
                    },
                    {
                        id: 'period_attendance',
                        name: 'Period Attendance',
                        width: '12%',
                        sort: (a, b) => {
                            const a_period_attendance = a.people.period_attendance;
                            const b_period_attendance = b.people.period_attendance;

                            if (a_period_attendance.gt(b_period_attendance)) {
                                return 1;
                            } else if (a_period_attendance.lt(b_period_attendance)) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        value: row => row.people.period_attendance.toFixed(2)
                    },
                    {
                        id: 'coursework',
                        name: 'Coursework',
                        width: '12%',
                        sort: (a, b) => {
                            const a_coursework = a.people.coursework;
                            const b_coursework = b.people.coursework;

                            if (a_coursework.gt(b_coursework)) {
                                return 1;
                            } else if (a_coursework.lt(b_coursework)) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        value: row => row.people.coursework.toFixed(2)
                    }
                ]
            };
        },
        computed: {
            snapshots_rows() {
                return Object.values(this.integration_totals)
                // Sorts by standard integrations but this conflicts with the other sorts
                // .sort((a, b) => {
                //     if (this.integrations[a.integration_id].billing_category === 'standard') {
                //         return -1;
                //     } else {
                //         return 1;
                //     } 
                // })
                // .sort((a, b) => {
                //     return this.integrations[a.integration_id].source.team.name.localeCompare(this.integrations[b.integration_id].source.team.name);
                // })
                // .sort((a, b) => {
                //     return this.integrations[a.integration_id].application.name.localeCompare(this.integrations[b.integration_id].application.name);
                // });
            },
            admin() {
                return this.$store.getters.user.admin;
            },
            props() {
                return this.$store.state.drawer.props;
            }
        },
        methods: {
            loadSnapshots(){
                this.loading = true;

                this.$http.get(`/admin/billing/invoices/${this.invoice.id}/snapshots`)
                    .then(response => {
                        const { integration_snapshots, integrations } = response.$data;
                        Vue.set(this, 'integration_snapshots', integration_snapshots);
                        Vue.set(this, 'integrations', integrations);

                        const start_adjusted = new Date(this.invoice.period_start_date);
                        start_adjusted.setDate(start_adjusted.getDate() - 1);
                        const days = d3Time.timeDay.count(start_adjusted, new Date(this.invoice.period_end_date));

                        for (const [id, _] of Object.entries(integrations)) {
                            // Total up and average out the snapshots
                            if (!integration_snapshots[id]) {
                                continue;
                            }

                            const totals = {
                                integration_id: id,
                                people: {
                                    total: new Decimal(0),
                                    demographics: new Decimal(0),
                                    daily_attendance: new Decimal(0),
                                    period_attendance: new Decimal(0),
                                    coursework: new Decimal(0)
                                }
                            };

                            for (const [key, snapshot] of Object.entries(integration_snapshots[id])) {
                                if (snapshot.integration_development) {
                                    continue;
                                } else if (!['active', 'paused'].includes(snapshot.integration_state)) {
                                    continue;
                                }

                                const payload = snapshot.payload.people;

                                totals.people.total = totals.people.total.add(payload.total ?? 0);
                                totals.people.demographics = totals.people.demographics.add(payload.demographics ?? 0);
                                totals.people.daily_attendance = totals.people.daily_attendance.add(payload.daily_attendance ?? 0);
                                totals.people.period_attendance = totals.people.period_attendance.add(payload.period_attendance ?? 0);
                                totals.people.coursework = totals.people.coursework.add(payload.coursework ?? 0);
                            }

                            totals.people.total = totals.people.total.div(days);
                            totals.people.demographics = totals.people.demographics.div(days);
                            totals.people.daily_attendance = totals.people.daily_attendance.div(days);
                            totals.people.period_attendance = totals.people.period_attendance.div(days);
                            totals.people.coursework = totals.people.coursework.div(days);

                            Vue.set(this.integration_totals, id, totals);
                        }
                    })
                    .catch(error => this.$toasted.error('There was an error loading snapshots.'))
                    .finally(() => this.loading = false);
            },
        },
        created(){
            this.loadSnapshots();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .drawer-container
    {
        .drawer-header
        {
            // padding-bottom: 0px;
            justify-content: space-between;

            .button
            {
                margin-right: 15px;
            }
        }
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-container .drawer-navigation + .drawer-content
    {
        top: @drawer-navigation-height + @drawer-header-height;
    }

    .snapshots {
        margin-top: -1px;
    }
</style>
