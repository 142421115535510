<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
                    Team Settings
                </div>
            </template>
        </breadcrumbs>
        <div class="full settings">
            <section class="team-settings">
                <h2>Team Details</h2>
                <div class="form-field">
                    <label>Team ID</label>
                    <div class="form-helptext">The id of your team on Edlink.</div>
                    <input type="text" style="display: inline;margin-right: 15px;" v-model="team.id" class="medium" readonly />
                </div>
                <div class="form-field">
                    <label>Team Name</label>
                    <div class="form-helptext">The name of your school, university, or company.</div>
                    <input type="text" v-model="updates.name" class="medium" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="form-field">
                    <label>Team Alias</label>
                    <div class="form-helptext">A word or acronym such as "LAUSD" or "UTexas".</div>
                    <input type="text" v-model="updates.alias" class="medium" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="form-field">
                    <label>Website</label>
                    <div class="form-helptext">Your school or company website.</div>
                    <input type="text" v-model="updates.website" class="large" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="form-field">
                    <label>Support Website</label>
                    <div class="form-helptext">A specific website or page where users can find additional help.</div>
                    <input type="text" v-model="updates.support_website" class="large" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="form-field">
                    <label>Support Text</label>
                    <div class="form-helptext">The text to display for the link to your support website.</div>
                    <input type="text" v-model="updates.properties.support_text" class="large" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="form-field">
                    <label>Technical Contacts</label>
                    <div class="form-helptext">These email addresses will receive any technical notifications.</div>
                    <tokens :values.sync="updates.technical_contacts" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="form-field">
                    <label>Support Contacts</label>
                    <div class="form-helptext">These email addresses will be displayed when a user encounters an error.</div>
                    <tokens :values.sync="updates.support_contacts" :disabled="team.membership.type === 'read'" />
                </div>
                <div class="flex form-options">
                    <div class="button" @click="save" :class="{disabled: team.membership.type === 'read'}">Save Settings</div>
                </div>
            </section>
            <section class="team-members">
                <h2>Team Members</h2>
                <datatable class="card" :columns="columns" :rows="members" :header="true" :clickable="true" @clicked="person => member_options(person)" :min-height="members.length ? 'auto' : '150px'">
                    <template v-slot:empty-message>Your team has no members.</template>
                </datatable>
                <template v-if="invitations.length">
                    <h2>Team Invitations</h2>
                    <datatable class="card" :columns="invitation_columns" :rows="invitations" :header="true" :clickable="true" @clicked="invitation => invite_options(invitation)" />
                </template>
                <div class="flex team-member-options">
                    <div class="button" @click="invite" v-if="team.membership.type !== 'read'">Invite Team Member</div>
                    <div class="button white red" @click="leave">Leave Team</div>
                </div>
            </section>
            <section class="advanced-settings" v-if="team.type === 'developer'">
                <h2>Advanced Settings</h2>
                <div class="form-field">
                    <label>SSO Landing Page</label>
                    <div class="form-helptext">Show an intermediate landing page upon SSO launches.</div>
                    <div class="flex">
                        <checkbox label="Enabled" :checked.sync="updates.properties.sso_landing_page_enabled" />
                    </div>
                </div>
                <div class="form-field">
                    <label>SSO Edlink Brand</label>
                    <div class="form-helptext">Show the Edlink brand on the SSO page.</div>
                    <div class="flex">
                        <checkbox label="Enabled" :checked.sync="updates.properties.sso_brand_enabled" />
                    </div>
                </div>
                <div class="flex form-options">
                    <div class="button" @click="save" :class="{disabled: team.membership.type === 'read'}">Save Settings</div>
                </div>
            </section>
            <section class="idp-settings" v-if="admin">
                <h2>SSO Identity Providers</h2>
                <datatable class="card" :columns="idp_columns" :rows="idps" :header="true" :clickable="true" @clicked="idp => idp_options(idp)" :min-height="idps.length ? 'auto' : '150px'">
                    <template v-slot:empty-message>Your team has no connected SSO platforms.</template>
                </datatable>
                <div class="flex team-member-options">
                    <div class="button" @click="create_idp">Add Identity Provider</div>
                </div>
                <modal name="create-idp" :width="400" height="auto" classes="modal">
                    <form @submit.prevent="save_idp" class="create-idp">
                        <div class="form-field">
                            <label>IDP Name</label>
                            <div class="form-helptext">Enter a human-readable name.</div>
                            <input type="text" v-model="idp.name" class="medium" />
                        </div>
                        <div class="form-field">
                            <label>IDP Type</label>
                            <div class="form-helptext">Select the type of IDP that you are trying to create.</div>
                            <select v-model="idp.type" class="small">
                                <option value="clever">Clever</option>
                                <option value="classlink">Classlink</option>
                            </select>
                        </div>
                        <div class="form-field">
                            <label>IDP Key</label>
                            <div class="form-helptext">A Clever district ID or Classlink tenant ID.</div>
                            <input type="text" v-model="idp.key" class="medium" />
                        </div>
                        <div class="flex form-options">
                            <button type="submit">Create IDP</button>
                        </div>
                    </form>
                </modal>
            </section>
            <section class="advanced-settings" v-if="admin">
                <form @submit.prevent="save" v-if="updates">
                    <h2>Edlink Admin Settings</h2>
                    <div class="form-field">
                        <label>Merge Teams</label>
                        <div class="form-helptext">Merge a different team into this one to combine members, applications, sources, etc.</div>
                        <div class="flex">
                            <div class="button black" @click="merge">Select a Team</div>
                        </div>
                    </div>
                    <div class="form-field">
                        <label>Matched Entity</label>
                        <entity-details :entity="updates.entity" :verified="updates.verified" @selected="save_entity" />
                    </div>
                    <div class="form-field">
                        <label>Verification Status</label>
                        <div class="flex">
                            <checkbox label="Team Verified" :checked.sync="updates.verified" />
                        </div>
                    </div>
                    <div class="form-field">
                        <label>Team State</label>
                        <div class="flex">
                            <select v-model="updates.status" class="small">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                <option value="destroyed">Destroyed</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-field">
                        <label>Billing</label>
                        <div class="form-helptext">Should the automated billing system produce invoices for this team.</div>
                        <div class="flex">
                            <checkbox label="Enabled" :checked.sync="updates.billing_enabled" />
                        </div>
                    </div>
                    <div class="form-field">
                        <label>Consolidate Contracts</label>
                        <div class="form-helptext">This will consolidate all child contracts with the same plan as the parent so they are billed as one team.</div>
                        <div class="flex">
                            <checkbox label="Enabled" :checked.sync="updates.properties.consolidated_subscriptions" />
                        </div>
                    </div>
                    <div class="form-field">
                        <label>Invoice Number Prefix</label>
                        <div class="form-helptext">Enter a custom prefix for invoice numbers. (e.g. #LINK0001)</div>
                        <input type="text" v-model="updates.properties.invoice_number_prefix" class="small" />
                    </div>
                    <div class="form-field">
                        <label>Xero Contact</label>
                        <div class="form-helptext">Select the contact in Xero that invoices should be created for.</div>
                        <div class="flex flex-align">
                            <search-select :values.sync="xero_contact_id" :search="contact_source" :format="contact_format" :array="false" :placeholder="'Contact...'" />
                        </div>
                    </div>
                    <div class="flex form-options">
                        <button type="submit">Save Changes</button>
                    </div>
                </form>
            </section>
            <section class="advanced-settings" v-if="admin">
                <div class="team-identifiers">
                    <div class="identifiers-header flex flex-align">
                        <h2>Team Identifiers</h2>
                        <div class="ff"></div>
                        <div class="add flex flex-align text-button mini" @click="add_identifier">
                            <Plus class="icon" width="18" height="18" stroke-width="2" />
                            <div>Add Identifier</div>
                        </div>
                    </div>
                    <div class="summary identifiers" v-if="identifiers.length">
                        <!-- Identifier mapping -->
                        <div v-for="(identifier, $index) of identifiers" :key="identifier.key" class="identifier card flex flex-align">
                            <FingerprintLockCircle class="icon" width="20" height="20" stroke-width="1.5" />
                            <div class="identifier-type">
                                <SearchSelect :compact="true" v-if="editing" :values="identifier.type ? [identifier.type] : []" @input="(v) => update_identifier($index, 'type', v[0])" :borderless="true" :enum="identifer_type" :array="false" placeholder="Identifier Type" />
                                <template v-else>{{identifer_type[identifier.type]}}</template>
                            </div>
                            <div class="ff">
                                <input v-if="editing" class="borderless" placeholder="Identifier Value" :value="identifier.value" @input="(v) => update_identifier($index, 'value', v.target.value)" />
                                <div v-else class="copyable identifier-value" v-clipboard="() => identifier.value" v-clipboard:success="() => $toasted.info('Copied')">{{identifier.value}}</div>
                            </div>
                            <div class="delete flex flex-align text-button red" v-if="editing" @click="delete_identifier($index)">
                                Delete
                            </div>
                        </div>
                    </div>
                    <div class="nothing" v-else>None.</div>
                </div>
            </section>
            <section class="advanced-settings" v-if="admin">
                <form @submit.prevent="save" v-if="updates">
                    <h2>Team Details</h2>
                    <div class="form-field">
                        <label>Street Address</label>
                        <input type="text" v-model="updates.street_address" class="medium" />
                    </div>
                    <div class="form-field">
                        <label>Unit Number</label>
                        <input type="text" v-model="updates.unit_number" class="small" />
                    </div>
                    <div class="form-field">
                        <label>City</label>
                        <input type="text" v-model="updates.city" class="small" />
                    </div>
                    <div class="form-field">
                        <label>State / Province</label>
                        <input type="text" v-model="updates.state" class="small" />
                    </div>
                    <div class="form-field">
                        <label>ZIP Code / Postal Code</label>
                        <input type="text" v-model="updates.zip" class="small" />
                    </div>
                    <div class="form-field">
                        <label>Country</label>
                        <input type="text" v-model="updates.country" class="small" />
                    </div>
                    <div class="form-field">
                        <label>Descriptor</label>
                        <div class="form-helptext">This is something like "district", "school", or "college" for human-readability.</div>
                        <input type="text" v-model="updates.descriptor" class="small" />
                    </div>
                    <div class="flex form-options">
                        <button type="submit">Save Changes</button>
                    </div>
                </form>
            </section>
        </div>
    </main>
</template>

<script>
    import { SettingsAlt, Bank, Svg3dAddHole, SendMail, ProfileCircle, Plus, WhiteFlag } from '@epiphany/iconoir';
    import InviteMembers from '@/components/modals/InviteMembers';
    import Options from '@/components/modals/Options.vue';
    import TeamMemberName from '@/components/TeamMemberName.vue';
    import SearchSelect from '@/components/SearchSelect.vue';
    import State from '@/components/chips/State.vue';
    import Vue from 'vue';
    import { IDENTIFIER_TYPES } from '@/constants';

    const valid_fields = [
        'name',
        'alias',
        'description',
        'icon_url',
        'banner_url',
        'website',
        'support_website',
        'street_address',
        'unit_number',
        'city',
        'state',
        'zip',
        'country',
        'descriptor',
        'identifiers',
        'updated_date',
        'technical_contacts',
        'support_contacts',
        'properties',
        'verified',
        'xero_contact_id',
        'entity',
        'billing_enabled',
        'status'
    ];

    export default {
        name: 'Settings',
        components: {
            SettingsAlt,
            TeamMemberName,
            'search-select': SearchSelect,
            Plus
        },
        data(){
            return {
                editing: false,
                identifer_type: IDENTIFIER_TYPES,
                members: [],
                invitations: [],
                idps: [],
                idp: {
                    type: 'clever',
                    name: '',
                    key: ''
                },
                contacts: {
                    all: [],
                    items: null,
                    query: '',
                    timeout: null
                },
                roles: {
                    owner: 'Owner',
                    read: 'View Only',
                    readwrite: 'View and Edit',
                    invited: 'Invited'
                },
                updates: {},
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: ProfileCircle,
                        width: '40%',
                        component: TeamMemberName,
                        value: row => row.user
                    },
                    {
                        id: 'email',
                        name: 'Email',
                        icon: SendMail,
                        width: '40%',
                        value: row => row.user.email
                    },
                    {
                        id: 'role',
                        name: 'Role',
                        icon: Svg3dAddHole,
                        width: '20%',
                        value: row => this.roles[row.type]
                    }
                ],
                invitation_columns: [
                    {
                        id: 'email',
                        name: 'Email Address',
                        icon: Bank,
                        width: '70%',
                        value: row => row.email
                    },
                    {
                        id: 'role',
                        name: 'Role',
                        icon: Svg3dAddHole,
                        width: '30%',
                        value: () => 'Invited'
                    }
                ],
                idp_columns: [
                    {
                        id: 'name',
                        name: 'IDP Name',
                        icon: Bank,
                        width: '70%',
                        value: row => row.name
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        component: State,
                        value: row => ({ state: row.state }),
                        width: '120px'
                    },
                    {
                        id: 'type',
                        name: 'IDP Type',
                        width: '30%',
                        value: row => row.type
                    },
                    {
                        id: 'key',
                        name: 'IDP District ID',
                        icon: Svg3dAddHole,
                        width: '30%',
                        value: row => row.key
                    }
                ]
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            admin(){
                return this.$store.getters.user?.admin;
            },
            xero_contact_id: {
                get(){
                    return this.updates.xero_contact_id ? [this.updates.xero_contact_id] : [];
                },
                set(val){
                    this.updates.xero_contact_id = val[0] ?? null;
                }
            },
            identifiers(){
                return [];
            }
        },
        watch: {
            team: {
                immediate: true,
                handler(){
                    for (const field of valid_fields) {
                        Vue.set(this.updates, field, _.cloneDeep(this.team[field]) ?? null);
                    }

                    // Make sure properties gets set to a valid object.
                    this.updates.properties = this.updates.properties ?? {};
                }
            }
        },
        created(){
            this.$http.get(`/teams/${this.team.id}/members`)
            .then(response => this.members = response.$data)
            .catch(error => this.$toasted.error('There was an error loading your team members.'));

            this.$http.get(`/teams/${this.team.id}/invitations`)
            .then(response => this.invitations = response.$data)
            .catch(error => this.$toasted.error('There was an error loading your pending invitations.'));

            this.$http.get(`/teams/${this.team.id}/idps`)
            .then(response => this.idps = response.$data)
            .catch(error => this.$toasted.error('There was an error loading your identity providers.'));
        },
        methods: {
            merge(){
                this.$store.dispatch('palette/open', {
                    tooltip: 'Currently Merging Teams',
                    placeholder: 'Select a Team...',
                    didSelectItem: item => {
                        this.$modal.show(Options, {
                            title: 'Merge the Teams',
                            description: 'This will combine all members, applications, sources, etc. from the selected team into this one. This action cannot be undone.',
                            options: [
                                {
                                    title: 'Confirm Merge',
                                    color: 'red',
                                    fn: () => {
                                        this.$http.post(`/admin/teams/${this.team.id}/merge`, {
                                            team_id: item.id
                                        })
                                        .then(() => this.$toasted.success('Merged teams.'))
                                        .catch(error => this.$toasted.error('There was an error merging the teams.'));
                                    }
                                }
                            ]
                        }, {width: 300, height: 'auto', classes: 'modal'});
                    }
                });
            },
            contact_source(query){
                this.contacts.query = query;

                if (this.contacts.timeout) {
                    clearTimeout(this.contacts.timeout);
                }

                if (this.contacts.timeout) {
                    clearTimeout(this.contacts.timeout);
                    this.contacts.timeout = null;
                }

                return new Promise((resolve, reject) => {
                    this.contacts.timeout = setTimeout(() => {
                        return this.$http.get(`/admin/xero/contacts`, {
                            params: {
                                search: query
                            }
                        }).then(response => {
                            this.contacts.all = response.$data.map(contact => {
                                return {
                                    id: contact.id,
                                    name: contact.name,
                                    picture: null
                                };
                            })
                            .sort((a, b) => {
                                return a.name.localeCompare(b.name);
                            });

                            return resolve(this.contacts.all.map(contact => contact.id));
                        });
                    }, 500);
                });
            },
            contact_format(contact_id){
                return this.contacts.all.find(contact => contact.id === contact_id)?.name ?? `Unknown Contact (${contact_id})`;
            },
            save(){
                // I really want to .trim() updates.properties.support_text but its nested so
                // in lieu of some special handling I'm just gonna let them set it to a blank string
                this.$store.dispatch('teams/update', this.updates)
                .then(() => this.$toasted.success('Saved changes.'))
                .catch(error => this.$toasted.error('There was an error saving your changes.'));
            },
            save_entity(entity){
                this.$http.put(`/admin/teams/${this.team.id}/entity`, entity)
                .then(() => this.$toasted.success('Saved changes to entity.'))
                .catch(() => this.$toasted.error('Failed to save changes.'));
            },
            leave(){
                const _this = this;

                this.$modal.show(Options, {
                    options: [
                        {
                            title: 'Yes, Leave This Team',
                            color: 'red',
                            fn(){
                                _this.$http.delete(`/teams/${_this.team.id}/members/${_this.team.membership.id}`)
                                .then(() => _this.$store.dispatch('teams/remove'))
                                .then(() => _this.$toasted.success('You are no longer a member of this team.'))
                                .then(() => _this.$router.push({name: 'team.dashboard', params: {team: _this.team.alias}}))
                                .catch(error => _this.$toasted.error('There was an error leaving this team.'));
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            invite(){
                //TODO The invitations list needs to update after a new invitation is sent.
                //The vue-js-modal library really sucks for passing back data though.
                this.$modal.show(InviteMembers,
                    {},
                    {
                        width: 400,
                        height: 'auto',
                        classes: 'modal'
                    }
                );
            },
            invite_options(invite){
                const _this = this;

                this.$modal.show(Options, {
                    options: [
                        {
                            title: 'Delete Invitation',
                            color: 'red',
                            fn(){
                                _this.$http.delete(`/teams/${_this.team.id}/invitations/${invite.id}`)
                                .then(response => {
                                    _this.$toasted.success('Invitation deleted.');
                                    _this.invitations.splice(_this.invitations.indexOf(invite), 1);
                                })
                                .catch(error => _this.$toasted.error('There was an error deleting this invitation.'));
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            change(member, permission){
                this.$http.put(`/teams/${this.team.id}/members/${member.id}`, {type: permission})
                // .then(() => _this.$store.dispatch('teams/remove'))
                .then(() => this.$toasted.success('Role updated.'))
                .catch(error => this.$toasted.error('There was an error changing roles.'));
            },
            change_permissions(member){
                const _this = this;

                const options = [
                    {
                        title: 'View and Edit',
                        fn(){
                            _this.change(member, 'readwrite');
                        }
                    },
                    {
                        title: 'View Only',
                        fn(){
                            _this.change(member, 'read');
                        }
                    }
                ];

                if(this.team.membership.type === 'owner' || this.admin){
                    options.unshift({
                        title: 'Owner',
                        fn(){
                            _this.change(member, 'owner');
                        }
                    });
                }

                this.$modal.show(Options, {options}, {width: 300, height: 'auto', classes: 'modal'});
            },
            member_options(member){
                const _this = this;

                this.$modal.show(Options, {
                    options: [
                        {
                            title: 'Change Permissions',
                            fn(){
                                _this.change_permissions(member);
                            }
                        },
                        {
                            title: 'Kick Member',
                            color: 'red',
                            fn(){
                                _this.$http.delete(`/teams/${_this.team.id}/members/${member.id}`)
                                .then(() => _this.$toasted.success('This member was successfully removed from the team.'))
                                .catch(error => _this.$toasted.error('There was an error remove the member from the team.'));
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            idp_options(idp){
                const _this = this;

                this.$modal.show(Options, {
                    options: [
                        {
                            title: 'Delete IDP',
                            color: 'red',
                            fn(){
                                _this.$http.delete(`/teams/${_this.team.id}/idps/${idp.id}`)
                                .then(() => _this.idps.splice(_this.idps.indexOf(idp), 1))
                                .then(() => _this.$toasted.success('This IDP was successfully removed from the team.'))
                                .catch(error => _this.$toasted.error('There was an error remove the IDP from the team.'));
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            create_idp(){
                this.$modal.show('create-idp', {}, {width: 300, height: 'auto', classes: 'modal'});
            },
            save_idp(){
                const _this = this;

                this.$http.post(`/teams/${this.team.id}/idps`, this.idp)
                .then(() => {
                    _this.$toasted.success('IDP added.');
                    _this.idps.push(_this.idp);
                    _this.idp = {
                        type: 'clever',
                        name: '',
                        key: ''
                    };
                    _this.$modal.hide('create-idp');
                })
                .catch(error => _this.$toasted.error('There was an error adding the IDP.'));
            },
            add_identifier(){},
            update_identifier(){},
            delete_identifier(){}
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .settings
    {
        top: @breadcrumbs-height;
        overflow-y: auto;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        .section-options {
            padding-top: 20px;
        }

        &:first-child {
            border-top: 0;
        }
    }

    h2 {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    .card, .nothing
    {
        margin: 20px 0;
    }

    .form-options
    {
        padding-bottom: 0;
    }

    .profile-picture
    {
        width: 40px;
        padding-right: 0;
    }

    .team-settings
    {
        padding-top: 25px;
        
        .card
        {
            margin-bottom: 0;
            padding: 30px;
        }
    }

    .team-members
    {
    }

    .team-member-options
    {
        .text-button, .button
        {
            margin-right: 15px;
        }
    }

    .tokens
    {
        width: 500px;
    }

    .two-factor
    {
        color: @base;
        font-size: 10px;
        margin-left: 10px;

        .icon
        {
            width: 14px;
            height: 14px;
            font-size: 14px;
        }
    }

    .create-idp
    {
        padding: 20px;
    }


    .team-identifiers
    {
        .identifiers-header
        {
            margin-bottom: @double-padding;
            height: 18px;
            
            h4 {
                color: @black;
                margin-bottom: 0;
                padding: 0;
            }

            .override-pin
            {
                width: 16px;
                height: 16px;
                background: url('~@/assets/icons/base/pin.svg') 4px 3px no-repeat;
                background-size: 7px auto;
                background-position: center;
                margin-top: 1px;
                display: none;
                margin-left: 2px;
            }

            .edit
            {
                // color: @base;
                opacity: 0;
            }

            .edit, .option
            {
                color: @grey;
                font-size: 12px;
                line-height: 16px;
                transition: opacity ease 0.2s;
                margin-left: @single-padding;

                &:last-child
                {
                    margin-right: 0;
                }

                &:hover
                {
                    color: @base;
                }
            }

            .option
            {
                font-weight: 500;
            }

            &.override
            {
                h4
                {
                    color: @base;
                }

                .override-pin
                {
                    display: block;
                }
            }
        }

        &:hover {
            .identifiers-header .edit
            {
                opacity: 1;
                cursor: pointer;
            }
        }
        
        .identifiers
        {
            .identifier
            {
                margin-bottom: @single-padding;
                padding: 0 @single-padding;
                height: 48px;
                box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1);
                border-bottom-color: @d;

                .icon {
                    margin-right: @single-padding - 2px;
                    color: @grey;
                }

                .borderless {
                    border: 0;
                    outline: 0;
                }

                .identifier-type {
                    color: @grey;
                    font-size: 14px;
                    line-height: 18px;
                    width: 201px;
                }

                .identifier-value
                {
                    font-size: 14px;
                    line-height: 42px;
                    color: @black;
                }

                input {
                    font-size: 14px;

                    &:focus, :focus-visible, &:focus-within {
                        outline: 0;
                        box-shadow: none;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .autocomplete.compact .autocomplete-search input.autocomplete-input
                {
                    min-width: 20px;
                }

                .copyable
                {
                    &::after
                    {
                        bottom: 0;
                    }

                    &:hover
                    {
                        transform: translate3d(0, -7px, 0);

                        &::after
                        {
                            bottom: -1px;
                        }
                    }
                }
            }
        }

        .add {
            .icon {
                margin-right: 5px;
            }
        }

        .delete {
            color: @grey;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
            margin-left: 5px;

            .icon {
            }

            &:hover {
                color: @red;
            }
        }
    }
</style>