<template>
    <table v-if="syncs.length">
        <thead>
            <tr class="sync">
                <th class="sync-status">Status</th>
                <th>Sync Date</th>
                <th class="center">Changes</th>
                <th class="center">Requests</th>
            </tr>
        </thead>
        <tbody>
            <router-link v-for="i of syncs" :key="i.id" tag="tr" class="selectable" :to="{name: link_dest, params: {sync: i.id}}">
                <td class="sync-status">
                    <div class="flex">
                        <div class="sync-status-badge" :class="i.status">{{i.status}}</div>
                    </div>
                </td>
                <td class="sync-date">{{i.created_date | pretty('short')}}</td>
                <td class="monospace center" :class="{disabled: i.events_count === 0}">{{i.events_count}}</td>
                <td class="monospace center" :class="{disabled: i.requests_count === 0}">{{i.requests_count}}</td>
            </router-link>
            <tr v-if="next_page_available">
                <td colspan="4" class="table-load">
                    <div class="text-button" @click="$emit('load')" :class="{disabled: loading}">Load More</div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="nothing" v-else>
        There are no syncs to show for this source.
    </div>
</template>

<script>
    export default {
        name: 'SyncList',
        props: {
            loading: Boolean,
            next_page_available: Boolean,
            syncs: Array,
            link_dest: String
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    thead tr
    {
        border-top: 0;
    }

    .subheader
    {
        margin: 30px 0 20px;

        h3
        {
            margin: 0;
        }

        &:first-child
        {
            margin-top: 0;
        }
    }

    .sync
    {
        user-select: none;
        cursor: pointer;
    }

    .sync-status
    {
        width: 150px;
    }

    .sync-status-badge
    {
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;

        &.error, &.killed
        {
            color: @red;
        }

        &.canceled
        {
            color: @yellow;
        }

        &.working
        {
            color: @base;
        }

        &.queued
        {
            color: @grey;
        }

        &.staged
        {
            color: @purple;
        }

        &.complete
        {
            color: @green;
        }
    }

    .sync-log-options
    {
        padding: 20px 0 0;
        user-select: none;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .sync-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .monospace
    {
        font-size: 14px;
    }

    .nothing
    {
        margin: 25px 0 0;
    }
</style>