<template>
    <div class="entity-value entity-value-role flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="max">
                <template v-slot:default="properties">
                    Slotted item {{properties.item}}
                </template>
            </autocomplete>
            <!-- <input type="text" class="block editable-input" v-model="override" @keyup.enter="save" @keyup.esc="cancel" ref="input" /> -->
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value">
                <template v-if="array">
                    <template v-if="value.length">
                        <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{roles[item]}}</div>
                    </template>
                    <div v-else class="empty">&mdash;</div>
                </template>
                <div v-else class="badge text-overflow">{{roles[value]}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueRole',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                roles: {
                    'student': 'Student',
                    'district-administrator': 'District Administrator',
                    'administrator': 'Administrator',
                    'teacher': 'Teacher',
                    'ta': 'Teaching Assistant',
                    'aide': 'Aide',
                    'observer': 'Observer',
                    'parent': 'Parent',
                    'guardian': 'Guardian',
                    'designer': 'Designer',
                    'member': 'Member',
                    'staff': 'Staff'
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                const value = _.get(this.$store.state.entities.overrides, this.property);

                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return value ? this.override : this.original;
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property);
                    
                    if (this.array) {
                        return value;
                    } else {
                        return value ? [value] : [];
                    }
                },
                set(value){
                    if(value.length){
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : this.array ? value : value.pop()
                        });
                    } else {
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : null
                        });
                    }
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                return Promise.resolve(Object.keys(this.roles).filter(role => role.toLowerCase().indexOf(value.toLowerCase()) > -1));
            },
            format(value){
                return this.roles[value];
            },
            edit(){
                if(this.editable){
                    if(!this.override){
                        if(this.original){
                            // Set the default override value to the current property value.
                            this.$store.commit('entities/override', {property: this.property, value: this.original});
                        }else if(this.array){
                            // Set the default override value to the current property value.
                            this.$store.commit('entities/override', {property: this.property, value: []});
                        }
                    } 

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-role
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
