<template>
    <div class="object-prop-edit-item" @mouseenter="hide_modals">
        <div class="prop-item flex">
            <div class="flex flex-align" :class="{'prop-item-label': true, flex: true, expandable: has_children}" @click="toggle_node">
                <template v-if="has_children">
                    <NavArrowRight class="icon caret" width="16" height="16" stroke-width="2" v-if="!expanded" />
                    <NavArrowDown class="icon caret" width="16" height="16" stroke-width="2" v-if="expanded" />
                </template>
                <template v-else>
                    <SwitchOn v-if="type === 'boolean'" class="icon" width="16" height="16" stroke-width="2" />
                    <Number1Square v-else-if="type === 'number'" class="icon" width="16" height="16" stroke-width="2" />
                    <NumberedListLeft v-else-if="type === 'array'" class="icon" width="16" height="16" stroke-width="2" />
                    <CodeBracketsSquare v-else-if="type === 'object'" class="icon" width="16" height="16" stroke-width="2" />
                    <TextAlt v-else-if="type === 'string'" class="icon" width="16" height="16" stroke-width="2" />
                    <HelpSquare v-else class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <div>{{ format(label) }}</div>
            </div>
            <div class="prop-item-value flex ff flex-align">
                <div class="ff">
                    <template v-if="type === 'array'">
                        Array ({{value.length}})
                    </template>
                    <template v-else-if="type === 'object'">
                        Object ({{Object.keys(value).length}})
                    </template>
                    <template v-else>
                        {{ value }}
                    </template>
                </div>
                <div class="action-menu">
                    <div class="action-menu-item text-button mini" @click="toggle_prop_form('add')" v-if="has_children">
                        Add
                    </div>
                    <div class="action-menu-item text-button mini" @click="toggle_prop_form('edit')" v-else>
                        Edit
                    </div>
                    <div class="action-menu-item text-button mini red" @click="toggle_confirm_remove">
                        Remove
                    </div>
                    <div class="modal prop-form" v-if="show_prop_form" @click.stop="">
                        <div v-if="type === 'object'">
                            <label for="propname">Property Name</label>
                            <input type="text" id="propname" v-model="prop_name">
                        </div>
                        <div class="prop-type-select">
                            <label>Property Type</label>
                            <div
                                :class="{ button: true, mini: true, white: selected_type !== 'boolean' }" 
                                @click="set_prop_type('boolean')">
                                Boolean
                            </div>
                            <div
                                :class="{ button: true, mini: true, white: selected_type !== 'number' }" 
                                @click="set_prop_type('number')">
                                Number
                            </div>
                            <div
                                :class="{ button: true, mini: true, white: selected_type !== 'string' }" 
                                @click="set_prop_type('string')">
                                String
                            </div>
                            <div 
                                :class="{ button: true, mini: true, white: selected_type !== 'array' }" 
                                @click="set_prop_type('array')">
                                Array
                            </div>
                            <div 
                                :class="{ button: true, mini: true, white: selected_type !== 'object' }" 
                                @click="set_prop_type('object')">
                                Object
                            </div>
                        </div>
                        <div v-if="selected_type && selected_type !== 'array' && selected_type !== 'object'">
                            <div>
                                <label for="propvalue">
                                    <label for="propvalue">
                                        <span v-if="selected_type === 'number'">Enter Number</span>
                                        <span v-else-if="selected_type === 'string'">Enter String</span>
                                        <span v-else>Select Value</span>
                                    </label>
                                </label>
                                <div class="flex bool-select" v-if="selected_type === 'boolean'">
                                    <div
                                        :class="{ button: true, ff: true, mini: true, white: prop_value !== true }"
                                        @click="set_bool_value(true)">
                                        True
                                    </div>
                                    <div 
                                        :class="{ button: true, ff: true, mini: true, white: prop_value !== false }"
                                        @click="set_bool_value(false)">
                                        False
                                    </div>
                                </div>
                                <input type="text" id="propvalue" ref="propValueInput" v-model.trim="prop_value" v-else>
                            </div>
                        </div>
                        <div class="flex flex-end">
                            <button class="button mini" @click="form_mode === 'add' ? add_prop() : update_prop()" :disabled="!is_valid">{{ prop_form_button_label }}</button>
                        </div>
                    </div>
                    <div class="modal confirm-remove" v-if="show_confirm_remove" @click.stop="">
                        <label>Are you sure?</label>
                        <div class="flex flex-end">
                            <button class="button mini white" @click="show_confirm_remove=false">No</button>
                            <button class="button mini" @click="remove_prop">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="child-nodes" v-if="expanded">
            <div class="object-prop-edit-node">
                <object-prop-edit
                    v-for="(val, i) in value" 
                    :key="i" 
                    @add-prop="$emit('add-prop', $event)" 
                    @remove-prop="$emit('remove-prop', $event)" 
                    @update-prop="$emit('update-prop', $event)"
                    :label="i"
                    :level="level+1"
                    :parent-type="type"
                    :node-path="path"
                    :value="val">
                </object-prop-edit>
            </div>
        </div>
    </div> 
</template>

<script>
    import { AddCircle, NavArrowRight, NavArrowDown, SwitchOn, CodeBracketsSquare, NumberedListLeft, Number1Square, TextAlt, HelpSquare } from '@epiphany/iconoir';

    export default {
        name: 'ObjectPropEdit',
        props: {
            editing: Object,
            label: String | Number,
            level: Number,
            parentType: String,
            nodePath: Array,
            value: Object | String | Number
        },
        components: {
            AddCircle,
            NavArrowDown,
            NavArrowRight,
            SwitchOn,
            CodeBracketsSquare,
            NumberedListLeft,
            Number1Square,
            HelpSquare,
            TextAlt
        },
        data() {
            return {
                expanded: false,
                form_mode: 'add',
                prop_name: '',
                prop_value: '',
                selected_type: null,
                show_prop_form: false,
                show_confirm_remove: false,
                show_edit_form: false
            }
        },
        computed: {
            editing_label() {
                return false;
            },
            editing_value() {
                return false;
            },
            has_children() {
                if (!this.value) {
                    return false;
                } else {
                    return (
                        this.value.constructor === Object ||
                        Array.isArray(this.value)
                    );
                }
            },
            is_valid() {
                if (this.type === 'object' && !this.prop_name) {
                    return false;
                }

                if (!this.selected_type) {
                    return false;
                }

                if (this.selected_type === 'boolean') {
                    if (typeof this.prop_value !== 'boolean') {
                        return false;
                    }
                }

                if (this.selected_type === 'number') {
                    if (isNaN(parseFloat(this.prop_value))) {
                        return false;
                    }
                }

                if (this.selected_type === 'string' && !this.prop_value) {
                    return false
                }

                return true;
            },
            path() {
                return [...this.nodePath, this.label];
            },
            prop_form_button_label() {
                return this.form_mode === 'add' ? 'Add Property' : 'Update Property';
            },
            type() {
                if(_.isBoolean(this.value)) {
                    return 'boolean';
                }

                if (_.isArray(this.value)) {
                    return 'array';
                }

                if(_.isString(this.value)) {
                    return 'string';
                }

                if (_.isNumber(this.value)) {
                    return 'number';
                }

                if(_.isObject(this.value)) {
                    return 'object';
                }

                return 'other';
            }
        },
        methods: {
            add_prop() {
                let prop_value = this.prop_value;

                if (this.selected_type === 'number') {
                    prop_value = parseFloat(this.prop_value);
                }
                this.$emit('add-prop', {
                    prop_name: this.prop_name,
                    prop_value: this.selected_type === 'object' ? {} : (this.selected_type === 'array' ? [] : prop_value),
                    parent_node: this.value
                });
                this.expanded = true;
                this.reset_form();
                this.show_prop_form = false;
            },
            format(label) {
                return this.parentType === 'array' ? `${parseInt(label)+1}.` : label;
            },
            hide_modals() {
                this.show_prop_form = false;
                this.show_confirm_remove = false;
            },
            remove_prop() {
                this.$emit('remove-prop', {
                    index: this.label,
                    parentPath: this.nodePath,
                    parentType: this.parentType
                });

                this.show_confirm_remove = false;
            },
            reset_form() {
                this.prop_name = '';
                this.prop_value = '';
                this.selected_type = null;
            },
            set_bool_value(val) {
                this.prop_value = val;
            },
            set_prop_type(type) {
                this.selected_type = type;

                if (this.form_mode === 'edit') {
                    const current_prop_type = typeof this.value;

                    if (type === current_prop_type) {
                        this.prop_value = this.value;
                        if (current_prop_type === 'number' || current_prop_type === 'string') {
                            this.$nextTick(() => {
                                this.$refs.propValueInput.focus();
                                this.$refs.propValueInput.select();
                            });
                        }
                    } else {
                        this.prop_value = '';
                    }
                } else {
                    this.prop_value = '';
                }
            },
            toggle_node() {
                if (this.has_children) {
                    this.expanded = !this.expanded;
                }
            },
            toggle_prop_form(mode) {
                this.hide_modals();
                this.form_mode = mode;
                this.show_prop_form = !this.show_prop_form;

                if (mode === 'edit' && this.show_prop_form) {
                    const prop_type = typeof this.value;
                    let do_focus = false;

                    if (prop_type === 'boolean') {
                        this.selected_type = 'boolean';
                        this.set_bool_value(this.value);
                    }

                    if (prop_type === 'number') {
                        this.selected_type = 'number';
                        this.prop_value = this.value;
                        do_focus = true;
                    }

                    if (prop_type === 'string') {
                        this.selected_type = 'string';
                        this.prop_value = this.value;
                        do_focus = true;
                    }

                    if (do_focus) {
                        this.$nextTick(() => {
                            this.$refs.propValueInput.focus();
                            this.$refs.propValueInput.select();
                        });
                    }
                }
            },
            toggle_confirm_remove() {
                this.hide_modals();
                this.show_confirm_remove = !this.show_confirm_remove;
            },
            update_prop() {
                let prop_value = this.prop_value;
                if (this.selected_type === 'number') {
                    prop_value = parseFloat(this.prop_value);
                }
                this.$emit('update-prop', {
                    index: this.label,
                    parentPath: this.nodePath,
                    prop_value: this.selected_type === 'object' ? {} : (this.selected_type === 'array' ? [] : prop_value)
                });
                this.reset_form();
                this.show_prop_form = false;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .object-prop-edit-item {
        > div.prop-item {
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin-bottom: 10px;
            width: 100%;

            .icon
            {
                margin-top: 1px;
                margin-right: @single-padding;
                color: lighten(@grey, 15%);

                &.caret
                {
                    color: @grey;
                }
            }

            > div.prop-item-label {
                color: @grey;
                width: 240px;

                &.expandable {
                    cursor: pointer;
                }
            }

            > div.prop-item-value {
                color: @black;
                width: 50%;

                > div:first-child {
                    .text-overflow;
                }

                .action-menu {
                    display: none;

                    &-item {
                        .pointer;
                        font-size: 12px;
                        margin: 0 5px;
                        color: @base;
                        border-radius: @chip-border-radius;
                        text-align: center;
                    }
                }
            }

            &:hover {
                > div.prop-item-value .action-menu {
                    display: flex;
                }
            }
        }

        .modal {
            background: @f;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.1);
            position: absolute;
            overflow: hidden;
            padding: 10px;
            text-align: left;
            top: 22px;
            z-index: 15;

            label, input {
                font-size: 13px;
            }

            input[type="text"] {
                height: 24px;
                line-height: 24px;
                margin-bottom: 15px;
                padding: 8px 4px;
            }

            .button {
                margin-bottom: 5px;
            }
        }

        .prop-form {
            left: -132px;
            min-width: 210px;

            > div:last-child {
                .button {
                    margin-bottom: 0;
                }
            }

            .bool-select {
                gap: 6px;
                margin-bottom: 8px;
            }

            .prop-type-select {
                > div:last-child {
                    margin-bottom: 15px;
                }
            }
        }

        .confirm-remove {
            left: -52px;
            min-width: 140px;

            label {
                margin-bottom: 15px;
            }

            button {
                margin-left: 5px;
            }

        }

        .child-nodes
        {
            margin-left: 8px;
            padding-left: 17px;
            border-left: 1px dotted lighten(@grey, 15%);
        }
    }
</style>
