<template>
    <!-- Sections
    - Integration summary
        - Relevant IDs
        - Integration state
        - Sharing status
    - Statistics
        - Numbers of each item shared
        - Usage statistics
            - Number of logins
            - Number of requests
    - Source details
        - Enrichment status
        - Sync status
        - Sync history of some sort
        - Technical contacts for both teams
    - LTI connection information
    - Privacy
        - What fields get shared
        - What permissions this application has
    - Danger zone
        - Delete integration -->
    <div class="integration-overview full scroll">
        <div class="integration-state-banner purple flex flex-align" v-if="integration.status === 'requested'">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">This integration has been requested by {{ integration.team.name }} but must be accepted by {{ integration.developer.name }} before it becomes active.</div>
            <div class="button purple" @click="$modal.show('integration-status')" v-if="developer">Activate Integration</div>
        </div>
        <div class="integration-state-banner yellow flex flex-align" v-else-if="integration.status === 'paused'">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">This integration has been paused by {{ integration.developer.name }}. Roster data will not be updated but user can still sign in via SSO.</div>
        </div>
        <div class="integration-state-banner red flex flex-align" v-else-if="integration.status === 'disabled'">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">This integration has been disabled by {{ integration.team.name }}. No data is available to {{ integration.application.name }} and users cannot sign in via SSO.</div>
        </div>
        <div class="integration-state-banner purple flex flex-align" v-else-if="integration.status === 'inactive'">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">This integration has been accepted by {{ integration.developer.name }} and is scheduled to begin on {{ integration.start_date | pretty('medium') }}.</div>
        </div>
        <section>
            <h2>Integration Summary</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Integration ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => integration.id" v-clipboard:success="() => $toasted.info('Copied')">{{ integration.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Application</div>
                    <div class="summary-value" v-if="user.admin">
                        <router-link :to="{ name: 'team.applications.application.overview', params: { team: integration.developer.alias, application: integration.application.id } }" class="text-button external flex flex-align">
                            {{ integration.application.name }}
                        </router-link>
                    </div>
                    <div class="summary-value" v-else>{{ integration.application.name }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Status</div>
                    <div class="summary-value">
                        <div class="flex flex-align">
                            <div class="standalone icon-status" :class="status(integration)"></div>
                            <div class="capitalize">{{ integration.status }}</div>
                        </div>
                    </div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Created Date</div>
                    <div class="summary-value">{{ integration.created_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Updated Date</div>
                    <div class="summary-value">{{ integration.updated_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Start Date</div>
                    <div class="summary-value">{{ integration.start_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">End Date</div>
                    <div class="summary-value" v-if="integration.end_date">{{ integration.end_date | pretty('long') }}</div>
                    <div class="summary-value" v-else>Until Disabled</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Access Token</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => integration.access_token" v-clipboard:success="() => $toasted.info('Copied')">{{ integration.access_token | password }}</div>
                </div>
                <div class="summary-field flex flex-align" v-if="!integration.destination">
                    <div class="summary-key">Quick Login URL</div>
                    <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/integrations/${integration.id}/launch`" v-clipboard:success="() => $toasted.info('Copied')">
                        https://ed.link/api/authentication/integrations/{{ integration.id }}/launch
                    </div>
                </div>
                <div class="summary-field flex flex-align" v-if="developer">
                    <div class="summary-key">Request Logs</div>
                    <div class="summary-value">
                        <router-link
                            :to="{ name: 'team.logs', query: { $filter: JSON.stringify({ region_id: [{ operator: 'equals', value: integration.region.id }], integration_id: [{ operator: 'equals', value: integration.id }] }) } }"
                            class="text-button external flex flex-align"
                        >
                            View Logs
                            <ArrowUpRight width="13" height="13" stroke-width="2.5" />
                        </router-link>
                    </div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Region</div>
                    <div class="summary-value flex flex-align">
                        <template v-if="integration.region.id === '8c5475f1-32d3-479d-8a6a-3c6b6e524f49'">
                            <img class="block flag-icon" src="/flags/us.svg" />
                            <div>United States</div>
                        </template>
                        <template v-else-if="integration.region.id === '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd'">
                            <img class="block flag-icon" src="/flags/ca.svg" />
                            <div>Canada</div>
                        </template>
                        <template v-else-if="integration.region.id === '2572d321-6d8b-458b-8024-8e5ab6bad7b6'">
                            <img class="block flag-icon" src="/flags/de.svg" />
                            <div>Germany</div>
                        </template>
                        <template v-else-if="integration.region.id === '2104f649-fe39-4fa1-96be-8682e9840dcf'">
                            <img class="block flag-icon" src="/flags/au.svg" />
                            <div>Australia</div>
                        </template>
                        <template v-else>
                            <div>Unknown</div>
                        </template>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="integration.destination">
            <h2>Flow Configuration</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Send Summary Emails</div>
                    <div class="summary-value">{{ integration.properties.send_summary_emails ? 'Yes' : 'No' }}</div>
                </div>
                <div class="summary-field flex flex-align multiline">
                    <div class="summary-key">Entity Settings</div>
                    <div class="summary-values flex flex-column">
                        <div class="summary-value">Create {{ create_entity_permission }} Entities</div>
                        <div class="summary-value">Update {{ update_entity_permission }} Entities</div>
                        <div class="summary-value">Delete {{ delete_entity_permission }} Entities</div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else>
            <template v-if="integration.provider.application === 'lti'">
                <h2>LTI Configuration</h2>
                <div class="summary" key="lti-configuration">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">LTI Version</div>
                        <div class="summary-value">1.3</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Configuration JSON</div>
                        <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/lti/${integration.application.id}`" v-clipboard:success="() => $toasted.info('Copied')">
                            <div class="text-overflow">https://ed.link/api/authentication/lti/{{ integration.application.id }}</div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Keyset URL</div>
                        <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/lti/${integration.application.id}/jwk`" v-clipboard:success="() => $toasted.info('Copied')">
                            <div class="text-overflow">https://ed.link/api/authentication/lti/{{ integration.application.id }}/jwk</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <h2>LTI Configuration</h2>
                <div class="summary" key="lti-api-configuration">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">LTI Version</div>
                        <div class="summary-value">
                            <nav class="lti-versions flex flex-align">
                                <div class="pill" :class="{active: lti_version === '1.1'}" @click="lti_version = '1.1'">LTI 1.1</div>
                                <div class="pill" :class="{active: lti_version === '1.3'}" @click="lti_version = '1.3'">LTI 1.3</div>
                            </nav>
                        </div>
                    </div>
                    <template v-if="lti_version === '1.1'">
                        <div class="summary-field flex flex-align" key="lti-1.1-launch-url">
                            <div class="summary-key">Launch URL</div>
                            <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/lti/${integration.application.id}`" v-clipboard:success="() => $toasted.info('Copied')">
                                <div class="text-overflow">https://ed.link/api/authentication/lti/{{ integration.application.id }}</div>
                            </div>
                        </div>
                        <div class="summary-field flex flex-align" key="lti-1.1-configuration-xml">
                            <div class="summary-key">Configuration XML</div>
                            <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/lti/${integration.application.id}/xml`" v-clipboard:success="() => $toasted.info('Copied')">
                                <div class="text-overflow">https://ed.link/api/authentication/lti/{{ integration.application.id }}/xml</div>
                            </div>
                        </div>
                        <div class="summary-field flex flex-align" key="lti-1.1-consumer-key">
                            <div class="summary-key">Consumer Key</div>
                            <div class="summary-value monospace copyable" v-clipboard="() => integration.lti_consumer_key" v-clipboard:success="() => $toasted.info('Copied')">{{ integration.lti_consumer_key }}</div>
                        </div>
                        <div class="summary-field flex flex-align" key="lti-1.1-shared-secret">
                            <div class="summary-key">Shared Secret</div>
                            <div class="summary-value monospace copyable" v-clipboard="() => integration.lti_shared_secret" v-clipboard:success="() => $toasted.info('Copied')">{{ integration.lti_shared_secret | password }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="summary-field flex flex-align" key="lti-1.3-configuration-json">
                            <div class="summary-key">Configuration JSON</div>
                            <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/integrations/${integration.id}/lti`" v-clipboard:success="() => $toasted.info('Copied')">
                                <div class="text-overflow">https://ed.link/api/authentication/integrations/{{ integration.id }}/lti</div>
                            </div>
                        </div>
                        <div class="summary-field flex flex-align" key="lti-1.3-keyset-url">
                            <div class="summary-key">Keyset URL</div>
                            <div class="summary-value copyable" v-clipboard="() => `https://ed.link/api/authentication/integrations/${integration.id}/jwk`" v-clipboard:success="() => $toasted.info('Copied')">
                                <div class="text-overflow">https://ed.link/api/authentication/integrations/{{ integration.id }}/jwk</div>
                            </div>
                        </div>
                        <div class="summary-field flex flex-align multiline">
                            <div class="summary-key">Client ID</div>
                            <div class="summary-value">
                                <div class="card lti-client-id">
                                    <div>Enter the Client ID you generated in {{ integration.provider.name }}</div>
                                    <input type="text" v-model="lti_client_id" class="small block" />
                                    <div class="flex">
                                        <div class="button mini flex flex-align has-icon" :class="{disabled: lti_client_id === (integration.properties?.lti?.client_id ?? '')}" @click="save_lti_client_id">
                                            <ArrowUpCircle class="icon" width="13" height="13" stroke-width="2.5" />
                                            Update
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </section>
        <section>
            <h2>Source Details</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => integration.source.id" v-clipboard:success="() => $toasted.info('Copied')">{{ integration.source.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source Name</div>
                    <div class="summary-value flex flex-align" v-if="user.admin">
                        <router-link :to="{ name: 'team.sources.source.overview', params: { team: integration.team.alias, source: integration.source.id } }" class="text-button flex flex-align">
                            {{ integration.source.name }}
                        </router-link>
                        <div class="source-application badge" v-if="integration.source.application_id">{{ integration.application.name }}</div>
                    </div>
                    <div class="summary-value" v-else>
                        {{ integration.source.name }}
                        <div class="source-application badge" v-if="integration.source.application_id">{{ integration.application.name }}</div>
                    </div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source Provider</div>
                    <div class="summary-value">{{ integration.provider.name }}</div>
                </div>
                <template v-if="integration.provider.allows_data_sync">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Sync Status</div>
                        <div class="summary-value">
                            <div class="flex flex-align">
                                <div class="standalone icon-status" :class="status(integration.source)"></div>
                                <div class="capitalize">{{ integration.source.status }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Last Sync</div>
                        <div class="summary-value">{{ integration.source.last_sync_date | pretty('long', 'Never') }}</div>
                    </div>
                </template>
                <template v-if="!integration.destination">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Team Name</div>
                        <div class="summary-value" v-if="user.admin">
                            <router-link :to="{ name: 'team.sources', params: { team: integration.team.alias } }" class="text-button external flex flex-align">
                                {{ integration.team.name }}
                            </router-link>
                        </div>
                        <div class="summary-value" v-else>{{ integration.team.name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Team Entity ID</div>
                        <div class="summary-value monospace" v-if="integration.entity">{{ integration.entity.id }}</div>
                        <div class="summary-value monospace" v-else>Entity ID Not Set</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Technical Contacts</div>
                        <div class="flex flex-wrap" v-if="integration.team.technical_contacts.length">
                            <div class="summary-value copyable" v-for="contact of integration.team.technical_contacts" :key="contact" v-clipboard="() => contact" v-clipboard:success="() => $toasted.info('Copied')">
                                {{ contact }}
                            </div>
                        </div>
                        <div class="summary-value monospace" v-else>None Set</div>
                    </div>
                </template>
            </div>
        </section>
        <section v-if="integration.destination">
            <h2>Destination Details</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Destination ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => integration.destination.id" v-clipboard:success="() => $toasted.info('Copied')">{{ integration.destination.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Destination Name</div>
                    <div class="summary-value" v-if="user.admin">
                        <router-link :to="{ name: 'team.sources.source.overview', params: { team: integration.team.alias, source: integration.destination.id } }" class="text-button flex flex-align">
                            {{ integration.destination.name }}
                        </router-link>
                    </div>
                    <div class="summary-value" v-else>{{ integration.destination.name }}</div>
                    <!-- I don't currently believe that the destination can have a unique source_application_id (the source definitely can, even for Flow) -->
                    <!-- <div class="source-application badge" v-if="integration.source.application_id">{{integration.application.name}}</div> -->
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Destination Provider</div>
                    <div class="summary-value">{{ integration.destination.provider_name }}</div>
                </div>
            </div>
        </section>
        <section>
            <h2 class="danger-zone flex flex-align">
                <div>Danger Zone</div>
                <span class="icon iconoir-shield-alert"></span>
            </h2>
            <h4>Permanently disconnect this integration.</h4>
            <div class="disconnect-warning">
                This process is irreversible. We will disconnect this integration and delete any logs that we are storing. This application will no longer have access to your data, unless you set up another integration.
                <!-- If instead you are looking to temporarily deactivate this integration, use the toggle switch in the header. -->
            </div>
            <div class="form-options flex">
                <div class="red button" @click="disconnect">Disconnect Integration</div>
            </div>
        </section>
    </div>
</template>

<script>
import Options from '@/components/modals/Options.vue';
import { InfoCircle, ArrowUpRight, ArrowUpCircle } from '@epiphany/iconoir';

export default {
    name: 'IntegrationOverview',
    components: {
        InfoCircle,
        ArrowUpRight,
        ArrowUpCircle
    },
    data(){
        return {
            lti_version: '1.1',
            lti_client_id: ''
        };
    },
    created(){
        this.lti_client_id = this.integration.properties?.lti?.client_id?.trim() ?? '';

        if (this.lti_client_id) {
            this.lti_version = '1.3';
        }
    },
    computed: {
        team() {
            return this.$store.getters.team;
        },
        developer() {
            return this.team.id === this.integration.developer.id;
        },
        integration() {
            return this.$store.getters.integration;
        },
        create_entity_permission() {
            return _.intersection(this.integration.permissions, [
                '4206a4ae-3df2-4beb-882f-00db524db669',
                '8de4ce24-43a1-4df3-9516-c0788982bee3',
                'ad4021ea-ad66-4d1b-9abd-8d11c28b3efe',
                'af762240-a1d6-43e1-bd91-99a0d55325cc',
                '8425c9e0-76ed-4aa2-8a62-2f73be16038d',
                '2b0357e9-7655-416f-85b3-d0a7b74f20b6',
                'c715c033-13c1-427b-977e-713f84f7f343',
                '075c7fc6-0621-48a9-972a-15e7460b08fe',
                '992c6c7b-fc75-40d4-8afa-9c1c4d641e99',
                '93d8f393-9ba8-4de4-a215-2ec07503146a',
                '25391849-e898-46f2-bb11-1a727aa3c231'
            ]).length;
        },
        update_entity_permission() {
            return _.intersection(this.integration.permissions, [
                '95482b7f-cc2f-4773-9554-8cf9a3ff0f8e',
                'a5d4e3e6-aae2-44c5-8cee-40a73a2ca2f6',
                '9e93b2b4-1d38-42d2-bd3d-79a507fe992a',
                'e39411fb-6302-4ac1-896b-b1a5d94ff53d',
                '4c96a858-134a-4b16-99ec-808580f2f1ed',
                '55ad27a5-53a7-406c-b121-e67fae258bda',
                'd9d9b7fa-0f4d-42cb-bdbf-52002b3000f8',
                'd1f8e5f0-42c8-403a-bdc7-c7adb7d26735',
                '7f6e85b5-7ad0-41ee-9f28-86d184ea9742',
                'a4fcb528-2642-4c7b-8a83-46bc4348713d',
                'eece0ca0-2559-42c8-ae07-ffdf0a6bef38'
            ]).length;
        },
        delete_entity_permission() {
            return _.intersection(this.integration.permissions, [
                '76106e22-2d19-41b7-a8b5-4d14afcde0e7',
                '111f092f-7b71-42c1-a11d-1f6c2dfbc000',
                '8d39df68-a041-4149-bc68-de0c58bfbd3d',
                'df4b4236-878d-4423-b9f6-9857a416e954',
                'e3aa0959-b7a1-413b-8243-8a96df3483f8',
                '294b2d4c-2830-479e-8c76-53c603ab5181',
                '64504ab4-618c-4dde-a80c-f8dd5e6ac626',
                '5e64a975-a8af-4c31-ae47-513bce3f3953',
                '48fd22af-521d-44b8-aa44-c1f77fd9f584',
                'b5d4dd88-03b9-43ca-ab33-1ecb3377311b',
                'f7a292c8-1ee5-41b2-a71d-fdae1fe9028b'
            ]).length;
        },
        user() {
            return this.$store.getters.user;
        }
    },
    methods: {
        save_lti_client_id(){
            this.$store.commit('integrations/update', { properties: Object.assign({}, this.integration.properties, { lti: { client_id: this.lti_client_id } }) });

            this.$store.dispatch('integrations/update')
                .then(() => this.$toasted.success('Client ID has been updated.'))
                .catch((error) => this.$toasted.error('There was an error updating the client ID.'));
        },
        status(integration) {
            return {
                red: ['error', 'disabled', 'destroyed'].includes(integration.status),
                yellow: ['paused'].includes(integration.status),
                green: ['active'].includes(integration.status),
                grey: ['requested'].includes(integration.status),
                purple: ['inactive', 'scheduled', 'pending', 'requested'].includes(integration.status)
            };
        },
        disconnect() {
            const _this = this;

            this.$modal.show(
                Options,
                {
                    title: 'Destroy Integration',
                    description: 'Are you sure you want to destroy this integration? This will remove all access that the developer has to your school data and destroy any data that Edlink stores about the integration.',
                    options: [
                        {
                            title: `Destroy This Integration`,
                            color: 'red',
                            fn() {
                                _this.$store
                                    .dispatch('integrations/destroy')
                                    .then(() => _this.$toasted.success('Integration has been marked for removal.'))
                                    .catch((error) => _this.$toasted.error('There was an error marking the integration for removal.'));
                            }
                        },
                        {
                            title: `Keep This Integration`,
                            color: 'blue',
                            fn() {}
                        }
                    ]
                },
                { width: 300, height: 'auto', classes: 'modal' }
            );
        }
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    border-top: 1px solid @e;
    padding: 20px;

    .section-options {
        padding-top: 20px;
    }

    &:first-child {
        border-top: 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        &.multiline {
            height: auto;
            align-items: flex-start;

            .summary-values {
                align-items: flex-start;
            }
        }
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
        line-height: 22px;
    }

    .summary-value {
        color: @black;
        line-height: 22px;

        &.monospace {
            line-height: 22px;
            font-size: 13px;
        }

        .flag-icon {
            width: 12px;
            height: 12px;
            margin-right: 8px;
        }

        .text-button {
            font-size: 13px;

            svg {
                margin-left: 4px;
            }
        }
    }

    .lti-versions
    {
        .pill
        {
            height: 22px;
            line-height: 22px;
            padding: 0 6px;
            margin-right: 2px;
            font-size: 13px;

            &.active
            {
                background-color: fade(@base, 15%);
                color: @base;
            }
        }
    }
}

h4 {
    color: @black;
    font-size: 15px;
    margin-bottom: 6px;
}

.disconnect-warning {
    font-size: 13px;
    line-height: 18px;
    color: @grey;
    max-width: 700px;
}

.launch-url {
    font-weight: 400;
}

.icon-status {
    margin-right: 8px;
}

.source-application.badge {
    margin-left: 8px;
    padding-left: 23px;
    background-image: url('~@/assets/icons/base/link.svg');
    background-size: auto 22px;
    background-position: -6px 2px;
    background-repeat: no-repeat;
    font-weight: 400;
}

.integration-state-banner
{
    height: 50px;
    font-size: 13px;
    padding: 0 10px 0 20px;
    margin-top: 0;
    border-radius: 0;
    font-weight: 500;

    .icon
    {
        margin-right: 6px;
    }

    &.purple
    {
        background: @purple;
        color: lighten(@purple, 40%);
        
        .button.purple
        {
            background: darken(@purple, 10%);

            &:hover, &:active
            {
                background: darken(@purple, 20%);
            }
        }
    }

    &.yellow
    {
        background: @yellow;
        color: darken(@yellow, 25%);
    }

    &.red
    {
        background: @red;
        color: lighten(@red, 40%);
    }

    &.grey
    {
        background: @f4;
        color: @grey;
    }

    + section
    {
        border-top: 0;
    }
}

.lti-client-id
{
    padding: @single-padding;
    // width: 400px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;

    input
    {
        height: 30px;
        font-size: 13px;
        padding: 4px 8px;
        margin-top: 8px;
        width: 350px;
    }

    .button
    {
        margin-top: @single-padding;
    }
}
</style>
