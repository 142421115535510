<template>
    <div class="classes">
        <h2>Welcome back {{name}}</h2>
        <div class="class-list">
            <div class="card" v-for="_class in classes" @click="$router.push({ name: 'demo.class', params: { class: _class.id }})">
                <div class="bg" :style="{backgroundColor: pastel_colour(_class.name)}"></div>
                <div class="body flex flex-align">
                    <div>
                        <h3>{{_class.name}}</h3>
                        <!-- <p v-if="_class.description">{{_class.description}}</p> -->
                    </div>
                    <div class="flex flex-align" :tooltip="`This is fetched from ${source.provider.name}`" >
                        <img class="source-icon" :src="source.provider.icon_url" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemoClasses',
    props: {
        edlink_widget: Object,
        profile: Object,
        token_set: Object,
        integration: Object,
        source: Object,
    },
    data() {
        return {
            classes: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state?.user.admin;
        },
        // integration() {
        //     return this.$store.state.integrations.active;
        // },
        name() {
            if (this.profile) {
                if (this.profile.first_name) {
                    return this.profile.last_name ? this.profile.first_name + ' ' + this.profile.last_name : this.profile.first_name;
                } else if (this.profile.last_name) {
                    return this.profile.first_name ? this.profile.first_name + ' ' + this.profile.last_name : this.profile.last_name;
                } else if (this.profile.display_name) {
                    return this.profile.display_name.trim();
                } else {
                    return 'Anonymous User';
                }
            } else {
                return 'Anonymous User';
            }
        }
    },
    async mounted() {
        const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';
        this.$demo.get(`${base_url}/v2/my/classes`).then(res => {
            this.classes = res.$data;
        });
    },
    methods: {
        color() {
            // const colors = ['#335C67', '#9E2A2B', '#F4E76E', '#628395', '#C8ADC0', '#CAFFD0', '#38686A', '#187795'];
            // const color = colors[Math.round(Math.random() * (colors.length - 1))];
            // return color;
            return `hsla(${~~(360 * Math.random())},70%,70%,0.8)`
        },
        pastel_colour(input_str) {

            //TODO: adjust base colour values below based on theme
            var baseRed = 128;
            var baseGreen = 128;
            var baseBlue = 128;

            //lazy seeded random hack to get values from 0 - 256
            //for seed just take bitwise XOR of first two chars
            var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
            var rand_1 = Math.abs((Math.sin(seed++) * 10000)) % 256;
            var rand_2 = Math.abs((Math.sin(seed++) * 10000)) % 256;
            var rand_3 = Math.abs((Math.sin(seed++) * 10000)) % 256;

            //build colour
            var red = Math.round((rand_1 + baseRed) / 2);
            var green = Math.round((rand_2 + baseGreen) / 2);
            var blue = Math.round((rand_3 + baseBlue) / 2);

            return `rgb(${red}, ${green}, ${blue})`;
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.classes {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.class-list {
    width: 100%;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(300px);
    grid-gap: 25px;

    .card {
        .bg {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 150px;
            width: 100%;
        }

        .body {
            padding: 20px 25px 20px;
            justify-content: space-between;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.demo {
    min-height: 100vh;
}

nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    border-bottom: 2px solid @f8;
    margin-bottom: 30px;

    .links {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .text-button {
            margin-left: 15px;

            .icon {
                margin-left: 5px;
            }
        }
    }
}

.source-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: @e;
    // margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    &-mini {
        width: 18px;
        height: 18px;


        &::before {
            width: 18px;
            height: 18px;
        }

        img {
            width: 18px;
            height: 18px;
        }
    }
}
</style>
