<template>
    <div class="system-status">
        <header class="flex flex-align">
            <Activity class="icon" width="16" height="16" stroke-width="2" />
            <h2>System Status</h2>
            <div class="ff"></div>
            <div class="button has-icon" @click="refresh">
                <Refresh class="icon" width="16" height="16" stroke-width="2" />
                Refresh
            </div>
            <div class="button white" @click="$emit('close')">Close</div>
        </header>
        <div class="system-services flex flex-column flex-center">
            <template v-if="system.loading">
                <spinner />
            </template>
            <template v-else-if="system.data">
                <div class="system-service flex flex-align" v-for="service of services" :key="service.id">
                    <div class="system-service-status" :class="status(system.data[service.id].oldest_in_queue)">
                        <CheckCircle width="16" height="16" stroke-width="2" v-if="status(system.data[service.id].oldest_in_queue) === 'success'" />
                        <WarningCircle width="16" height="16" stroke-width="2" v-else-if="status(system.data[service.id].oldest_in_queue) === 'warning'" />
                        <WarningTriangle width="16" height="16" stroke-width="2" v-else-if="status(system.data[service.id].oldest_in_queue) === 'error'" />
                        <HelpCircle width="16" height="16" stroke-width="2" v-else />
                    </div>
                    <div class="ff">
                        <div class="system-service-name">{{service.name}}</div>
                        <div class="system-service-description">
                            <template v-if="status(system.data[service.id].oldest_in_queue) === 'success'">Service running normally</template>
                            <template v-else-if="status(system.data[service.id].oldest_in_queue) === 'warning'">Service experiencing minor delays</template>
                            <template v-else-if="status(system.data[service.id].oldest_in_queue) === 'error'">Service experiencing significant delays</template>
                            <template v-else>Service status unknown</template>
                        </div>
                    </div>
                    <chip class="system-service-badge" color="grey" :title="(system.data[service.id].queued_count) | commas" v-tooltip="'The number of queued jobs'">
                        <template #icon>
                            <MenuScale class="icon" width="14" height="14" stroke-width="2" />
                        </template>
                    </chip>
                    <chip class="system-service-badge" color="grey" :title="age(system.data[service.id].oldest_in_queue)" v-tooltip="'The approximate wait time of the queue'">
                        <template #icon>
                            <Timer class="icon" width="14" height="14" stroke-width="2" />
                        </template>
                    </chip>
                </div>
            </template>
            <template v-else>
                Nothing to show here.
            </template>
        </div>
    </div>
</template>

<script>
    import { Timer, Tower, MenuScale, Activity, CheckCircle, WarningCircle, WarningTriangle, HelpCircle, Refresh } from '@epiphany/iconoir';
    import moment from 'moment';

    export default {
        name: 'SystemStatus',
        components: {
            Activity,
            Timer,
            Tower,
            MenuScale,
            CheckCircle,
            WarningCircle,
            WarningTriangle,
            HelpCircle,
            Refresh
        },
        computed: {
            system(){
                return this.$store.state.system;
            }
        },
        methods: {
            refresh(){
                this.$store.dispatch('system/load');
            },
            age(timestamp){
                if(!timestamp){
                    return '0 min';
                }

                const now = moment();
                const then = moment(timestamp);
                const difference = now.diff(then);

                return moment.duration(difference).humanize();
            },
            status(date){
                if(!date){
                    return 'success';
                }

                const timestamp = new Date().getTime();
                const thirty_minutes_ago = new Date(timestamp - 30 * 60 * 1000);
                const two_hours_ago = new Date(timestamp - 2 * 60 * 60 * 1000);
                
                if (date < two_hours_ago) {
                    return 'error';
                } else if (date < thirty_minutes_ago) {
                    return 'warning';
                }

                return 'success';
            }
        },
        data(){
            return {
                services: [
                    {
                        id: 'syncs',
                        name: 'Data Sync'
                    },
                    {
                        id: 'enrichments',
                        name: 'Data Enrichment'
                    },
                    {
                        id: 'materializations',
                        name: 'Data Materialization'
                    },
                    {
                        id: 'previews',
                        name: 'Data Preview'
                    },
                    {
                        id: 'jobs',
                        name: 'Asynchronous Job Queue'
                    },
                    {
                        id: 'flows',
                        name: 'Flow'
                    }
                ]
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    header
    {
        color: @black;
        padding: @single-padding;
        border-bottom: 1px solid @e4;

        .icon
        {
            margin-right: 15px;
            margin-left: 5px;
        }

        .button
        {
            margin-left: @single-padding;
        }
    }
    
    h2
    {
        font-size: 15px;
        color: @black;
    }

    .system-services
    {
        padding: 5px 0;
    }

    .system-service
    {
        padding: @single-padding;

        .system-service-status
        {
            margin-right: 15px;
            margin-left: 5px;

            &.success
            {
                color: @green;
            }

            &.warning
            {
                color: @yellow;
            }

            &.error
            {
                color: @red;
            }
        }

        .system-service-name
        {
            font-size: 13px;
            font-weight: 500;
            color: @black;
        }

        .system-service-description
        {
            font-size: 11px;
            color: @grey;
            margin-top: 4px;
        }
    }
</style>
