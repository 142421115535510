<template>
    <div class="integrate-source-editor-configuration">
        <div class="flex flex-align" v-if="title">
            <span class="block icon" :class="icon" v-if="icon"></span>
            <h2 class="ff">{{title}}</h2>
            <div class="text-button grey mini" v-if="collapsable" @click="toggle">See More</div>
        </div>
        <template v-if="expanded">
            <slot />
        </template>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateSourceEditorConfiguration',
        props: {
            title: String,
            icon: String,
            collapsable: Boolean
        },
        mounted(){
            
        },
        destroyed(){

        },
        computed: {
            expanded(){
                if(this.collapsable){
                    return this.open;
                }

                return true;
            }
        },
        data(){
            return {
                open: false
            };
        },
        methods: {
            toggle(){
                this.open = !this.open;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .integrate-source-editor-configuration
    {
        background: @f8;
        border-radius: 6px;
        padding: 15px;
        width: 100%;

        input {
            background: @f;
        }

        .icon
        {
            margin-right: 10px;
            color: @black;
            font-size: 18px;
        }

        h2
        {
            font-size: 13px;
            color: @black;
            font-weight: 400;
        }

        label
        {
            margin: 15px 0 4px;
            font-size: 12px;
            letter-spacing: 0;
            color: @grey;

            &:first-child
            {
                margin-top: 0;
            }
        }

        .configuration-link
        {
            font-weight: 400;
            font-size: 14px;
            justify-content: flex-start;
            line-height: 16px;
            height: 16px;
            padding-right: 18px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -1px top 1px;
            background-size: 14px auto;

            &.disabled
            {
                background-image: url('~@/assets/icons/grey/external.svg');
            }
        }

        .configuration-value
        {
            color: @black;
            font-size: 14px;
            line-height: 16px;

            code {
                display: inline-block;
                font-size: 13px;
                color: @black;
                background: @e;
                border-radius: 3px;
                font-weight: 400;
                padding: 0 4px;
                line-height: 20px;
            }

            &.monospace
            {
                font-size: 13px;
            }

            &.copyable
            {
                &.noshift
                {
                    &::after
                    {
                        height: 16px;
                        line-height: 16px;
                    }
                }
            }
        }

        .configuration-table
        {
            border: 1px solid @d;
            margin-top: 10px;

            td
            {
                padding: 4px 6px;
                border: 1px solid @d;
            }
        }

        .image-label {
            margin: 15px 0;
            font-size: 14px;
            color: @black;
            line-height: 22px;

            &.labeled {
                margin: 0;
            }

            .iconoir-info-empty {
                color: @base;
            }

            label, a {
                font-size: 14px;
                margin: 0;
            }

            label {
                color: @black;
            }

            code {
                display: inline-block;
                font-size: 13px;
                color: @black;
                background: @e;
                border-radius: 3px;
                font-weight: 400;
                padding: 0 4px;
                line-height: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    
        .extra-details
        {
            background: @e;
            border-radius: 6px;
            padding: 15px;
            width: 100%;

            .icon
            {
                margin-right: 10px;
                color: @black;
                font-size: 18px;
            }

            h2
            {
                font-size: 13px;
                color: @black;
                font-weight: 400;
            }

            label
            {
                margin: 15px 0 4px;
                font-size: 12px;
                letter-spacing: 0;
                color: @grey;
            }

            .configuration-link
            {
                font-weight: 400;
                font-size: 14px;
                justify-content: flex-start;
                line-height: 16px;
                height: 16px;
                padding-right: 18px;
                background: url('~@/assets/icons/base/external.svg') no-repeat;
                background-position: right -1px top 1px;
                background-size: 14px auto;

                &.disabled
                {
                    background-image: url('~@/assets/icons/grey/external.svg');
                }
            }

            .configuration-value
            {
                color: @black;
                font-size: 14px;
                line-height: 16px;

                &.monospace
                {
                    font-size: 13px;
                }

                &.copyable
                {
                    &.noshift
                    {
                        &::after
                        {
                            height: 16px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
</style>