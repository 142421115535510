<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <BookmarkBook class="icon" width="16" height="16" stroke-width="2" />
                    Documentation
                </div>
            </template>
            <template v-slot:actions>
                <div class="flex flex-align interactivity-options">
                    <div class="interactive-title">Version</div>
                    <div class="button white has-icon" @click="switch_version">
                        <Terminal class="icon" width="16" height="16" stroke-width="2" />
                        API {{version}}
                    </div>
                    <template v-if="team">
                        <div class="interactive-title">Team</div>
                        <div class="button white has-icon" @click="change">
                            <Group class="icon" width="16" height="16" stroke-width="2" />
                            {{team ? team.name : 'No Team Selected'}}
                        </div>
                        <div class="interactive-title">Application</div>
                        <div class="button white has-icon" @click="switch_application">
                            <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                            {{application ? application.name : 'No Application Selected'}}
                        </div>
                    </template>
                </div>
            </template>
        </breadcrumbs>
        <div class="documentation full" ref="documentation">
            <div class="flex">
                <nav class="vertical-navigation documentation-navigation" v-if="tree">
                    <documentation-tree v-if="manifest" :tree="manifest.documents" :search="search" :path="manifest.slug" />
                </nav>
                <div class="ff">
                    <router-view :key="$route.fullPath" />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { BookmarkBook, Terminal, Svg3dSelectSolid, Group } from '@epiphany/iconoir';

    import SwitchTeam from '@/components/modals/SwitchTeam.vue';
    import Select from '@/components/modals/Select.vue';
    import AccountSettings from '@/components/modals/AccountSettings.vue';
    import Options from '@/components/modals/Options.vue';
    import axios from 'axios';

    export default {
        name: 'Documentation',
        components: {
            BookmarkBook,
            Terminal,
            Svg3dSelectSolid,
            Group
        },
        data(){
            return {
                applications: [],
                area: null,
                version: 'v2.0',
                section: null,
                page: null,
                manifest: null,
                search: '',
                loading: true
            };
        },
        created(){
            this.load();

            if(this.$route.params.path){
                if(this.$route.params.path.split('/').includes('v2.0')){
                    this.version = 'v2.0';
                }else{
                    this.version = 'v1.0';
                }
            }
        },
        computed: {
            tree(){
                return !['docs.introduction', 'docs.changelog'].includes(this.$route.name);
            },
            sections(){
                return this.$store.state.documentation.sections;
            },
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.state.user;
            },
            application(){
                return this.$store.state.documentation.application;
            }
        },
        watch: {
            team(){
                this.load();
            },
            '$route.params.path': {
                immediate: true,
                handler(){
                    if(this.$refs.documentation){
                        this.$refs.documentation.scrollTop = 0;
                    }
                    
                    if(this.$route.params.path){
                        this.loading = true;

                        const [area, version, ...segments] = this.$route.params.path.split('/');

                        if(['api', 'guides'].includes(area)){
                            if(segments.length === 0){
                                segments.push('introduction');
                                segments.unshift(version ? version : this.version);
                                segments.unshift(area);

                                this.$router.push(`/docs/${segments.join('/')}`);
                            }
                        }else{
                            if(version){
                                segments.unshift(version);
                            }
                            
                            if(segments.length === 0){
                                segments.push('introduction');
                                segments.unshift(area);

                                this.$router.push(`/docs/${segments.join('/')}`);
                            }
                        }

                        return this.load_manifest_for_active_section()
                        .then(response => response.$data)
                        .then(manifest => {
                            this.manifest = manifest;
                            this.loading = false;

                            //TODO Change the page title.
                            //TODO Expand the current section if it is not already expanded.
                        })
                        .catch(error => this.$toasted.error('There was an error loading this section.'));
                    }

                    this.loading = false;
                }
            }
        },
        methods: {
            load(){
                if(this.team){
                    this.$http.get(`/teams/${this.$store.getters.team.id}/applications`)
                    .then(response => {
                        this.applications = response.$data;

                        if(this.applications.length){
                            this.$store.dispatch('documentation/application', this.applications.slice().pop());
                        }
                    })
                    .catch(error => this.$toasted.error('There was an error loading your team\'s applications.'));
                }
            },
            load_manifest_for_active_section(){
                //Load the manifest for the request documentation area.
                const [area, version = this.version] = this.$route.params.path.split('/');

                if(area === this.area && version === this.version){
                    return Promise.resolve();
                }

                const url = process.env.NODE_ENV === 'production' ? 'https://edlink.github.io/docs' : 'http://localhost:8082';

                if(['api', 'guides'].includes(area)){
                    return axios.get(`${url}/${area}/${version}/manifest.json`);
                }else{
                    return axios.get(`${url}/${area}/manifest.json`);
                }
            },
            switch_version(){
                const _this = this;

                this.$modal.show(Options, {
                    title: 'Select A Version',
                    options: [
                        {
                            title: 'API v1.0',
                            fn(){
                                if(_this.version === 'v1.0'){
                                    return;
                                }

                                _this.version = 'v1.0';

                                //TODO Only redirect if we are on a page where this matters.
                                _this.$router.push(`/docs/api/${_this.version}/introduction`);
                            }
                        },
                        {
                            title: 'API v2.0',
                            fn(){
                                if(_this.version === 'v2.0'){
                                    return;
                                }

                                _this.version = 'v2.0';

                                //TODO Only redirect if we are on a page where this matters.
                                _this.$router.push(`/docs/api/${_this.version}/introduction`);
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            change(){
                this.$modal.show(SwitchTeam, {redirect: true}, {width: 320, height: 'auto', classes: 'modal', scrollable: true});
            },
            account(){
                this.$modal.show(AccountSettings, {}, {width: 700, height: 'auto', classes: 'modal', scrollable: true});
            },
            switch_application(){
                this.$modal.show(Select, {
                    title: 'Select An Application',
                    options: this.applications,
                    selected: this.application,
                    select(option){
                        this.$store.dispatch('documentation/application', option);
                    }
                }, {width: 320, height: 'auto', classes: 'modal'});
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .documentation
    {
        top: @breadcrumbs-height;
        overflow-y: auto;
        padding: @section-padding;
    }

    .interactivity-options
    {
        margin-right: 20px;

        .interactive-title
        {
            font-size: 14px;
            color: @black;
            margin-left: 40px;
        }
    }

    nav.documentation-navigation
    {
        width: 280px;
        margin-right: 40px;
    }

    @media only screen and (max-width: 600px)
    {
        header
        {
            padding-bottom: 0;
        }

        main.constrain
        {
            padding: 30px;
        }

        .interactivity-options
        {
            padding-top: 20px;
            display: block;

            .interactive-title
            {
                margin-right: 0;
                margin-bottom: 6px;
            }

            .button.white
            {
                margin-right: 0;
                text-align: center;
                margin-bottom: 20px;

                &:last-child
                {
                    margin-bottom: 0;
                }
            }
        }

        .vertical-navigation
        {
            padding-bottom: 10px;
            border-bottom: 1px solid @e4;
            padding-left: 0;
            width: 100%;
            margin-bottom: 30px;
        }
    }
</style>