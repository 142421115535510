<template>
    <div class="reconnect-step scroll">
        <div class="configuration flex flex-column bc">
            <h1>Reconnect Your {{source.provider.name}} Source</h1>
            <div class="helptext title-helptext">
                Follow the steps below to get reconnected.
            </div>
            <integrate-source-editor-step v-if="Object.keys(source.provider.configuration).length > 0" name="Current Configuration" icon="iconoir-double-check" :complete="verify">
                <div class="helptext">
                    You shouldn't have to change anything here unless it looks obviously incorrect.
                    If you need to make any changes click "Edit". Otherwise just click the "Everything Looks OK" checkbox to continue to the next step.
                </div>
                <integrate-source-editor-configuration title="Source Configuration" icon="iconoir-code-brackets-square">
                    <div class="edit-toggle text-button" :class="{disabled: verify}" @click="() => editing = !editing">{{ editing ? 'Done' : 'Edit' }}</div>
                    <!-- Config property loop -->
                    <template v-for="(configuration, property) of source.provider.configuration">
                        <!-- Editing -->
                        <template v-if="editing && !verify">
                            <div :key="'editing:' + property">
                                <label>{{configuration.title}}</label>
                                <div class="form-helptext" v-if="configuration.description">{{configuration.description}}</div>
                                <template v-if="configuration.size !== 'hidden'">
                                    <template v-if="configuration.type === 'string'">
                                        <select v-if="configuration.enum" :class="configuration.size" @input="(v) => update(property, v.target.value)" :value="source.configuration[property]">
                                            <option v-for="option of configuration.enum" :key="option.value" :value="option.value">{{option.title}}</option>
                                        </select>
                                        <template v-else>
                                            <textarea v-if="configuration.size === 'textarea'" :value="source.configuration[property]" @input="(v) => update(property, v.target.value)" :disabled="configuration.disabled"></textarea>
                                            <input v-else type="text" :class="configuration.size" :value="source.configuration[property]" @input="(v) => update(property, v.target.value)" :disabled="configuration.disabled" />
                                        </template>
                                    </template>
                                    <template v-if="configuration.type === 'number'">
                                        <input type="number" :class="configuration.size" :value="source.configuration[property]" @input="(v) => update(property, v.target.value)" :disabled="configuration.disabled" />
                                    </template>
                                    <template v-else-if="configuration.type === 'boolean'">
                                        <div class="flex">
                                            <checkbox :label="configuration.label" :checked="source.configuration[property]" @input="(v) => update(property, v)" />
                                        </div>
                                    </template>
                                    <template v-else-if="configuration.type === 'file'">
                                        <div class="flex">
                                            <upload class="image-upload" :url="source.configuration[property]" @input="(v) => update(property, v)" type="zip" />
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </template>
                        <!-- Read-only -->
                        <template v-else>
                            <label :key="'label:' + property">{{ configuration.title }}</label>
                            <div class="flex" :key="'value:' + property">
                                <div class="configuration-value" v-if="configuration.type === 'boolean'">{{ source.configuration[property] ? 'Enabled' : 'Disabled' }}</div>
                                <div class="configuration-value" v-else>{{ source.configuration[property] ? source.configuration[property] : 'No Value Set' }}</div>
                            </div>
                        </template>
                    </template>
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Everything Looks OK" :checked.sync="verify" />
                </div>
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Reconnect Your Account" icon="iconoir-people-rounded" :complete="!!token" v-if="source.provider.requires_administrator_consent || source.provider.requires_administrator_login">
                <div class="helptext">
                    Please reconnect your {{ source.provider.name }} account. You will be redirected to the {{ source.provider.name }} login page. Once you have logged in you will be redirected back to Edlink.
                </div>
                <consent v-if="source.provider.requires_administrator_consent" :write="false" :configuration="source.configuration" :provider="source.provider" :value="token"  @input="value => auth('consent', value)" />
                <token v-else-if="source.provider.requires_administrator_login" :write="false" :configuration="source.configuration" :provider="source.provider" :value="token" @input="value => auth('token', value)" />
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Complete Reconnection" icon="iconoir-group" class="validation" :complete="!!token">
                <div class="helptext">Save your changes here and finalize the reconnection.</div>
                <div class="options flex">
                    <div class="button" :disabled="!complete" @click="next" kbd="Enter ⏎">Reconnect</div>
                </div>
            </integrate-source-editor-step>
        </div>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'ReconnectConfiguration',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration,
        },
        data() {
            return {
                verify: false,
                token: null,
                editing: false,
            }
        },
        computed: {
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            user(){
                return this.$store.getters.user;
            },
            source(){
                return this.$store.state.reconnect.source;
            },
            name(){
                if (this.user) {
                    if (this.user.first_name) {
                        return this.user.first_name;
                    }
                }
                return null;
            },
            complete(){
                if (!this.verify && Object.keys(this.source.provider.configuration).length > 0) {
                    return false;
                } else if (this.source.provider.requires_administrator_consent || this.source.provider.requires_administrator_login) {
                    if (!this.token && !this.source.configuration.tenant_id) {
                        return false;
                    }
                }
                return true;
            }
        },
        methods: {
            send(page){
                if (this.loading) { return };
                this.$store.commit('reconnect/page', page);
            },
            next(){
                this.$store.dispatch('reconnect/next');
            },
            auth(type, value){
                if (type === 'token') {
                    this.$store.dispatch('reconnect/update', { path: 'token', value});
                } else if (type === 'consent') {
                    this.$store.dispatch('reconnect/update', { path: 'configuration.tenant_id', value});
                } else {
                    return;
                }

                this.token = value;
            },
            update(property, value){
                this.$store.dispatch('reconnect/update', { path: `configuration.${property}`, value})
            },
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .configuration {
        width: 100%;
        max-width: 600px;
        padding: 200px 0 80px;
    }

    .edit-toggle {
        position: absolute;
        right: 20px;
        top: 15px;     
    }
</style>
