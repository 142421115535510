<template>
    <div class="full flex flex-column">
        <filters>
            <div class="tool-container">
                <!-- <div class="tool filter flex flex-align" @click="filter.visible = !filter.visible" :class="{active: filter.applied}">
                    <span class="icon iconoir-filter"></span>
                    <div class="ff">Filter</div>
                </div> -->
                <!-- <form class="tool-dropdown filter-dropdown" v-if="filter.visible" @submit.prevent="apply">
                    <h3>Filter Dataset</h3>
                    <select v-model="filter.property" @change="filter.operator = 'equals'">
                        <option v-for="field of filterable" :value="field.property" :key="field.property">{{field.name}}</option>
                    </select>
                    <select v-model="filter.operator">
                        <option :value="operator" v-for="operator of filters[property.type]" :key="operator">{{operators[operator]}}</option>
                    </select>
                    <input type="text" v-model="filter.value" />
                    <div class="flex flex-align">
                        <button type="submit" class="button center">Apply Filter</button>
                        <div class="ff"></div>
                        <div class="text-button clear-filter red" @click="clear" :class="{disabled: filter.applied === false}">Clear Filter</div>
                    </div>
                </form> -->
            </div>
            <!-- <div class="tool sort flex flex-align">
                <span class="icon iconoir-sort-down"></span>
                <div class="ff">Sort</div>
            </div> -->
            <div class="button white has-icon flex flex-align">
                <span class="icon iconoir-refresh"></span>
                <div class="ff" @click="load">Refresh</div>
            </div>
            <div class="ff"></div>
            <pages :all="tasks" :rows="rows" :more="next_page_available" :count="count" :page="page" @next="next" @previous="previous" />
        </filters>
        <datatable class="job-tasks" :columns="columns" :rows="rows" :loading="loading" :header="true" :clickable="true" @clicked="task => focus(task)">
            <template v-slot:empty-message>This job has no scheduled tasks.</template>
        </datatable>

        <!-- <div class="primary-value flex flex-align">
            <div>
                <span class="capitalize">{{task.event_type}}</span>
                {{task.entity_type}}
                <span class="task-entity" v-if="task.data.name">{{task.data.name}}</span>
            </div>
        </div> -->
    </div>
</template>

<script>
    const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

    import { WhiteFlag, Sparks, Calendar } from '@epiphany/iconoir';
    import Task from '@/components/drawers/Task.vue';
    import State from '@/components/chips/State.vue';

    export default {
        name: 'JobTasks',
        props: {
            progress: Object
        },
        data(){
            return {
                tasks: [],
                page: 0,
                count: 100,
                next_page_available: true,
                loading: false,
                filter: {
                    visible: false,
                    applied: false,
                    property: '',
                    operator: 'equals',
                    value: ''
                },
                params: {
                    $first: 100,
                    $after: undefined,
                    $fields: ['id', 'state', 'created_date', 'updated_date', 'attempts', 'entity_type', 'event_type', 'data'].join(','),
                    $filter: {}
                },
                properties: [
                    {title: 'Job ID', property: 'id', type: 'string', monospace: true, copyable: true},
                    {title: 'Created Date', property: 'created_date', type: 'date'},
                    {title: 'Updated Date', property: 'updated_date', type: 'date'},
                    {title: 'Relationship', property: 'relationship_type', type: 'string'},
                    {title: 'Observer', property: 'observer_id', type: 'string'},
                    {title: 'Target', property: 'target_id', type: 'string'}
                ],
                columns: [
                    {
                        id: 'status',
                        name: 'State',
                        icon: WhiteFlag,
                        component: State,
                        width: '10%',
                        value: row => row
                    },
                    {
                        id: 'description',
                        name: 'Description',
                        icon: Sparks,
                        width: '40%',
                        value: row => `${row.event_type} ${row.entity_type} ${row.data.name ? row.data.name : ''}`.trim()
                    },
                    {
                        id: 'date',
                        name: 'Updated Date',
                        icon: Calendar,
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.updated_date, 'medium')
                    }
                ]
            };
        },
        created(){
            this.load();
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            },
            status(task){
                return {
                    red: ['error'].includes(task.state),
                    yellow: ['canceled'].includes(task.state),
                    green: ['complete'].includes(task.state),
                    grey: ['queued'].includes(task.state),
                    blue: ['skipped'].includes(task.state),
                    purple: ['working'].includes(task.state)
                };
            },
            previous(){
                //We definitely have these results already.
                if(this.page){
                    this.page -= 1;
                }
            },
            next(){
                if(this.page * 100 >= this.total){
                    return;
                }

                this.page += 1;

                //If we already have these results, we can just swap them in.
                //Otherwise, we need to fetch from the server.
                if(this.tasks.length <= this.count * (this.page + 1)){
                    this.params.$after = this.tasks[this.tasks.length - 1].id;
                    this.load();
                }
            },
            load(){
                const params = _.clone(this.params);

                this.$http.get(`${base}/api/v2/graph/jobs/${this.entity.id}/tasks`, {
                    params,
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                })
                .then(response => {
                    // We want to splice in the current page. Most of the time it will just be appended to the array, but
                    // if the user has clicked "refresh" we may swap out a page.
                    this.tasks.splice(this.page * this.count, this.count, ...response.$data);
                })
                .catch(error => this.$toasted.error('There was an error loading the tasks for this job.'));
            },
            focus(task){
                // Enter loading state.
                this.$store.dispatch('drawer/loading');

                // Swap drawer component.
                this.$store.dispatch('drawer/replace', {
                    key: `entity/${task.id}`,
                    width: 800,
                    component: Task,
                    props: {
                        integration: this.integration,
                        entity: task,
                        type: 'tasks'
                    }
                });

                // Activate this entity.
                this.$store.dispatch('entities/activate', {
                    id: task.id,
                    type: 'tasks',
                    integration: this.integration,
                    extras: {
                        parent:{
                            id: this.entity.id,
                            type: 'jobs'
                        }
                    }
                })
                .then(() => {
                    // Exit loading state.
                    this.$store.dispatch('drawer/ready')
                });
            }
        },
        computed: {
            rows(){
                return this.tasks.slice(this.page * this.count, this.page * this.count + this.count);
            },
            team(){
                return this.$store.getters.team;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            },
            title(){
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            },
            total(){
                let total = 0;

                for(const key of ['canceled', 'complete', 'error', 'queued', 'skipped', 'working']){
                    total += this.progress[key];
                }

                return total;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .job-tasks
    {
        top: @filters-height;
    }
</style>
