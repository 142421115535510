<template>
    <div class="integrate-account-email">
        <label>Sign in With Google</label>
        <div class="helptext">
            It looks like you previously signed into Edlink with your Google account.
            If you believe this is a mistake, feel free to contact us and we'll help you restore access to your account.
        </div>
        <div class="options flex">
            <div class="sso google text-button border" @click="google">Continue With Google</div>
            <!-- <div class="sso microsoft text-button border">Sign In With Microsoft</div> -->
        </div>
    </div>
</template>

<script>
    import querystring from 'querystring';

    export default {
        name: 'IntegrateAccountSSO',
        props: {
            state: Object,
            send: Function
        },
        computed: {
            invitations(){
                return this.$store.getters.user?.invitations;
            }
        },
        methods: {
            login_event(event) {
                if(event.origin !== window.origin && process.env.NODE_ENV === 'production'){
                    return;
                }

                if (event.data.type === 'login' && event.data.success) {
                    // Remove the listener
                    window.removeEventListener('message', this.login_event);
                    
                    // Attempt to fetch the user - we should be authenticated now
                    this.$store.dispatch('user/login', localStorage.getItem('edlink-user-token')).then(() => {
                        this.send({ type: 'SET_USER', data: this.$store.getters.user });
                        
                        if(this.$store.getters.team){
                            if(this.invitations?.length){
                                this.send('INVITES');
                            } else {
                                this.send('SELECT_DISTRICT');
                            }
                        }else{
                            this.send('TERMS');
                        }
                    });   
                } else {
                    // TODO: error occurred
                }
            },
            google(){
                const properties = {
                    state: Math.random().toString(36).slice(2),
                    redirect_uri: process.env.NODE_ENV === 'production' ? 'https://ed.link/api/v1/sso/google' : 'http://localhost:9900/api/v1/sso/google',
                    client_id: '563820043496-o65vgllud5rrstbf8tg0rltlm5pbg868.apps.googleusercontent.com',
                    scope: 'email profile',
                    response_type: 'code'
                };

                window.addEventListener('message', this.login_event);
                
                window.open(`https://accounts.google.com/o/oauth2/v2/auth?${querystring.stringify(properties)}`, '_blank');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-account-email
    {
        .sso
        {
            background-repeat: no-repeat;
            margin-right: 10px;

            &.google
            {
                background-image: url('~@/assets/icons/source/google.svg');
                background-size: 18px;
                background-position: 8px 5px;
                padding-left: 34px;
            }

            &.microsoft
            {
                background-image: url('~@/assets/icons/source/microsoft.svg');
                background-size: 57px;
                background-position: -9px 7px;
                padding-left: 36px;
            }
        }
    }
</style>
