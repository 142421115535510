<template>
    <div class="integrate-enrich-enable flex flex-column">
        <div class="flex flex-align integrate-video">
            <span class="icon iconoir-media-video-list"></span> Watch: What is Enrichment?
        </div>
        <h1>Enable Data Enrichment</h1>
        <div class="helptext">
            Sometimes, the data vendors need can be spread across multiple different systems within your school.
            The process of combining that data into a single view can be challenging - that's where data enrichment comes in.
            We handle the combination process, even when there aren't any common identifiers between systems.
        </div>
        <source-links :team="team" :source="source" :create="true" @create="create" />
        <div class="flex flex-align">
            <div class="button" @click="create">Add Enrichment Source</div>
            <div class="text-button" @click="next">Skip This Step</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateEnrichPrompt',
        props: {
            state: Object,
            send: Function
        },
        computed: {
            team(){
                return this.state.context.district;
            },
            source(){
                return this.state.context.primary_source;
            }
        },
        methods: {
            next(){
                if(this.state.context.application.id === '979dde54-832a-4814-ad50-4b30d23f23c7'){
                    this.send('FLOW');
                } else {
                    this.send('INTEGRATION');
                }
            },
            create(){

            }
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    .integrate-enrich-enable
    {
        align-items: flex-start;

        .helptext
        {
            p
            {
                margin-bottom: 10px;
            }
        }
    }

    .button
    {
        margin-right: 20px;
    }

    .source-links
    {
        width: 600px;
        margin-bottom: 20px;
    }
</style>