<template>
    <div class="integration card">
        <router-link tag="div" class="integration-details" :to="{name: 'team.integrations.integration', params: {integration: integration.id}}">
            <div class="flex integration-status">
                <div class="integration-status-badge" :class="integration.state">{{integration.state}}</div>
            </div>
            <div class="integration-name text-overflow">{{integration.application.name}}</div>
            <div class="integration-description text-overflow">{{integration.application.description}}</div>
            <div class="integration-stats flex flex-align">
                <div class="integration-stat">
                    <div class="integration-stat-value">&ndash;</div>
                    <div class="integration-stat-title">Users</div>
                </div>
                <div class="integration-stat">
                    <div class="integration-stat-value">&ndash;</div>
                    <div class="integration-stat-title">Requests</div>
                </div>
            </div>
        </router-link>
        <router-link class="integration-source flex flex-align" :to="{name: 'team.sources.source', params: {source: integration.source.id}}">
            <div class="integration-icon">
                <img :src="integration.provider.icon_url" />
            </div>
            <div class="ff">
                <div class="integration-name text-overflow">{{integration.source.name}}</div>
            </div>
            <div class="caret"></div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'IntegrationSummary',
        props: {
            integration: Object
        },
        computed: {
            providers(){
                return this.$store.state.providers.all;
            }
        },
        methods: {
            icon(source){
                return this.providers[source.provider_id] ? this.providers[source.provider_id].icon_url : '';
            },
            provider(id){
                return this.$store.getters.provider(id);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integration-icon
    {
        margin-right: 10px;

        img
        {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: @e;
            display: block;
        }

        &::after
        {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
            content: "";
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .integration-name
    {
        font-size: 16px;
        color: @black;
        font-weight: 500;
        line-height: 22px;
    }

    .integration-description
    {
        color: @grey;
        font-size: 13px;
        line-height: 18px;
        margin: 4px 0 2px;
    }

    .integration
    {
        margin-bottom: 30px;
    }

    .integration-application
    {
        padding: 20px;
    }

    .integration-source
    {
        border-top: 1px solid @e4;
        padding: 10px 12px;

        &:hover, &:active
        {
            background: @f8;
        }
    }

    .integration-status
    {
        margin-bottom: 10px;
    }

    .integration-details
    {
        padding: 15px;
        cursor: pointer;

        &:hover, &:active
        {
            background: @f8;
        }
    }

    .integration-status-badge
    {
        padding: 2px 8px;
        height: 22px;
        line-height: 18px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        background: fade(@green, 20%);
        color: @green;
        text-transform: uppercase;

        &.error
        {
            background: fade(@red, 20%);
            color: @red;
        }
    }

    .integration-stats
    {
        margin-top: 30px;
    }

    .integration-stat
    {
        width: 80px;
        margin-right: 15px;

        .integration-stat-value
        {
            font-size: 16px;
            color: @black;
            margin-bottom: 5px;
            font-weight: 600;
        }

        .integration-stat-title
        {
            font-size: 13px;
            color: @grey;
        }
    }

    .caret
    {
        margin-left: 20px;
        width: 20px;
        height: 10px;
        background: url('~@/assets/icons/black/caret-down.svg') no-repeat;
        background-position: -1px -5px;
        background-size: 21px auto;
        transform: rotate(-90deg);
    }
</style>