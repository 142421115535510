<template>
    <div class="application-feeds full scroll" v-if="application">
        <datatable class="subscribers full" :columns="columns" :rows="subscribers" :selectable="true" :header="true" @clicked="row => openSubscriberDrawer(row)" :loading="loading" :clickable="true" />
    </div>
</template>

<script>
import { TwitterVerifiedBadge, Calendar, WhiteFlag, DesignNib, AddCircle } from '@epiphany/iconoir';
import SubscriberDrawer from '@/components/drawers/Subscriber';

export default {
    name: 'ApplicationFeeds',
    components: {
        DesignNib,
        AddCircle
    },
    data() {
        return {
            subscribers: [],
            loading: false,
            columns: [
                {
                    id: 'id',
                    name: 'Subscriber ID',
                    icon: DesignNib,
                    width: '70px'
                },
                {
                    id: 'name',
                    name: 'Name',
                    icon: DesignNib,
                    width: '80px'
                },
                {
                    id: 'state',
                    name: 'State',
                    icon: DesignNib,
                    width: '50px'
                },
                {
                    id: 'type',
                    name: 'Type',
                    icon: DesignNib,
                    width: '50px'
                },
                {
                    id: 'events',
                    name: 'events',
                    icon: DesignNib,
                    width: '120px',
                    value: row => row.events.join(', ')
                },
            ]
        };
    },
    created() {
        this.fetchSubscribers();
    },
    computed: {
        team() {
            return this.$store.getters.team;
        },
        application() {
            return this.$store.getters.application;
        }
    },
    methods: {
        fetchSubscribers() {
            this.loading = true;
            this.$http.get(`/teams/${this.team.id}/applications/${this.application.id}/subscribers`)
                .then(response => {
                    this.subscribers = response.$data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openSubscriberDrawer(subscriber) {
            this.$store.dispatch('drawer/open', {
                key: 'subscriber-create-edit',
                width: 550,
                component: SubscriberDrawer,
                props: {
                    subscriber,
                    refreshParent: this.fetchSubscribers
                }
            });
        }
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

</style>
