<template>
    <div class="class-list" v-if="found_class">
        <div class="class-info">
            <h3>{{found_class.name}}</h3>
            <h4>{{found_class.time_zone}}</h4>
            <!-- <button id="create" v-if="teacher" @click="assign($route.params.class)">Create Assignment</button> -->

            <div class="students section" v-if="students.length && teacher">
                <div class="flex flex-align header">
                    <h2>Students</h2>
                    <div class="source-tooltip flex flex-align" :tooltip="`This is fetched from ${source.provider.name}`" >
                        <img class="source-icon-mini" :src="source.provider.icon_url" />
                    </div>
                </div>
                <div class="student text-overflow" v-for="student of students" :key="student.id">
                    <strong>{{student.display_name}}</strong> ({{student.email}})
                </div>
            </div>
        </div>
        <div>
            <div class="section" v-if="teacher">
                <div class="flex flex-align header">
                    <h2>Curriculum</h2>
                    <div class="source-tooltip flex flex-align" :tooltip="`This is example content`" >
                        <span class="icon iconoir-info-empty"></span>
                    </div>
                </div>
                <div class="chapter" v-for="(_value, key) of curriculum" :key="key">
                    <h1>{{key}}</h1>
                    <ul>
                        <li v-for="(value, key) of _value">
                            <div class="unit" v-if="key !== '0'">{{key}}</div>
                            <div class="text-overflow">{{value}}</div>
                            <div class="assign" @click="assign($route.params.class, value, 'This is a demo assignment')">Assign</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="section">
                <div class="header flex flex-align">
                    <h2>Assignments</h2>
                    <div class="source-tooltip flex flex-align" :tooltip="`This is fetched from ${source.provider.name}`" >
                        <img class="source-icon-mini" :src="source.provider.icon_url" />
                    </div>
                </div>
                <router-link
                    tag="div" :to="{name: 'demo.class.assignment', params: {class: $route.params.class, assignment: assignment.id}}"
                    class="card assignment" v-for="assignment in assignments" :key="assignment.id">
                    <h3>{{assignment.title}}</h3>
                    <p v-if="assignment.due_date">Due: {{assignment.due_date | pretty('long')}}</p>
                    <p v-else>No Due Date</p>
                    <h4 v-html="assignment.description"></h4>
                </router-link>
            </div>
        </div>
    </div>
    <div v-else class="loading">
        <spinner />
    </div>
</template>

<script>
const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';
export default {
    name: 'DemoClass',
    props: {
        edlink_widget: Object,
        profile: Object,
        token_set: Object,
        lti_launch: Boolean,
        source: Object
    },
    data() {
        return {
            found_class: null,
            students: [],
            assignments: [],
            curriculum: {
                'Chapter 1 - Prerequisites': {
                    '0': 'Introduction to Prerequisites',
                    '1.1': 'Real Numbers: Algebra',
                    '1.2': 'Exponents and Scientific Notation',
                    '1.3': 'Radicals and Rational Exponents',
                    '1.4': 'Polynomials',
                    '1.5': 'Factoring Polynomials'
                },
                'Chapter 2 - Equations and Inequalities': {
                    '0': 'Introduction to Equations and Inequalities',
                    '2.1': 'The Rectangular Coordinate Systems and Graphs',
                    '2.2': 'Linear Equations in One Variable',
                    '2.3': 'Models and Applications',
                    '2.4': 'Complex Numbers',
                    '2.5': 'Quadratic Equations',
                    '2.6': 'Other Types of Equations',
                    '2.7': 'Linear Inequalities and Absolute Value Inequalities'
                }
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state?.user.admin;
        },
        integration() {
            return this.$store.state.integrations.active;
        },
        student() {
            return this.profile && this.profile.roles.includes('student');
        },
        teacher() {
            return this.profile && this.profile.roles.includes('teacher');
        },
        iframe () {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }
    },
    async mounted() {
        this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}`).then(res => {
            this.found_class = res.$data;
        });
        this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}/students`).then(res => {
            this.students = res.$data;
        });
        this.load_assignments();
    },
    destroyed(){
        // this.edlink_widget.destroy();
        // this.edlink_widget = null;
    },
    methods: {
        load_assignments() {
            this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments`).then(res => {
                this.assignments = res.$data;
            });
        },
        assign(class_id, title, description) {
            if(this.iframe){
                // Assume this is an LTI launch and use postMessage instead.
                window.parent.postMessage({
                    type: 'lti',
                    title,
                    thumbnail: null,
                    custom: {
                        my_custom_property_1: 'my_custom_value_1',
                        my_custom_property_2: 'my_custom_value_2'
                    },
                    external_id: '123456789',
                    points_possible: 100,
                    display_date: '2023-12-14T00:00:00Z',
                    start_date: '2023-12-14T00:00:00Z',
                    due_date: '2023-12-21T00:00:00Z',
                    end_date: '2023-12-21T100:00:00Z'
                }, 'https://ed.link');

                return;
            }

            this.edlink_widget.activatePlugin({
                name: 'assignment_builder',
                options: {
                    fields: {
                        class_id: {
                            default: class_id,
                            required: true,
                            mutable: true,
                            hidden: true
                        },
                        title: {
                            default: title ? `Demo App - ${title}` : false,
                            required: true,
                            mutable: title ? false : true,
                            hidden: title ? true : false
                        },
                        description: {
                            default: description ?? false,
                            required: true,
                            mutable: description ? false : true,
                            hidden: description ? true : false
                        },
                        grading_type: {
                            default: 'points',
                            required: true,
                            mutable: true,
                            hidden: false,
                            options: ['points', 'percent', 'letter_grade', 'pass_fail']
                        },
                        submission_types: {
                            default: 'text'
                        },
                        assignee_mode: {
                            default: 'all',
                            required: true,
                            mutable: true,
                            hidden: false,
                            options: ['all', 'individuals']
                        },
                        category_id: {
                            required: false,
                            mutable: true,
                            hidden: false
                        },
                        session_id: {
                            required: false,
                            mutable: true,
                            hidden: false
                        },
                        due_date: {
                            required: true,
                            mutable: true,
                            hidden: false
                        }
                    }
                }
            });
            this.edlink_widget.show();
            this.edlink_widget.on('assignment.success', (data) => {
                this.load_assignments();
            });
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.tooltip {
    display: inline-block;
    margin-left: 5px;
}

.section {
    .header {
        margin-bottom: 20px;
    }
    
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }
}

.assignment {
    padding: 10px 15px;
    margin-bottom: 15px;
    cursor: pointer;


    &:hover {
        h3 {
            cursor: pointer;
            color: @demo-base;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.chapter {
    margin-bottom: 40px;

    h1 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    list-style: none;
    // padding-inline-start: 40px;

    li {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding: 5px;

        &:nth-child(odd) {
            background-color: @f8;
        }

        .unit {
            display: inline-block;
            width: 50px
        }

        .assign {
            opacity: 0;
            position: absolute;
            right: 5px;
            color: @white;
            background-color: @demo-base;
            font-size: 12px;
            line-height: 16px;
            padding: 2px 5px;
            border-radius: 6px;
        }

        &:hover .assign {
            opacity: 1;
            cursor: pointer;
            // color: @demo-base;
        }
    }
}

.class-info {
    max-width: 350px;
}

.class-list {
    width: 100%;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    // grid-template-rows: repeat(300px);
    grid-gap: 25px;
}

.students {
    margin-top: 50px;
}

.student {
    padding: 10px 15px 10px 0;
}

button#create {
    margin-top: 15px;
}

.demo {
    min-height: 100vh;
}

strong {
    color: @black;
}
.source-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: @e;
    // margin-left: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    &-mini {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: @e;
        // margin-left: 7px;
        border: 1px solid rgba(0, 0, 0, 0.15);


        &::before {
            width: 18px;
            height: 18px;
        }

        img {
            width: 18px;
            height: 18px;
        }
    }
}

.source-tooltip {
    margin-left: 7px;
}

// Demo Reset
button {
    background: @demo-base;
    border-color: darken(@demo-base, 10%);

    &:hover {
        background: darken(@demo-base, 10%);   
    }
}

input {
    background: @white;

    &:focus, &:focus-within {
        border-color: @demo-base;
        box-shadow: 0 0 0 3px fade(@demo-base, 30%);
    }
}
</style>
