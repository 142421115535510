<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <City class="icon" width="16" height="16" stroke-width="2" />
                    Onboard Schools
                </div>
            </template>
            <template v-slot:actions>
                <div class="button" @click="create">Invite a School</div>
            </template>
        </breadcrumbs>
        <information class="onboardings full scroll">
            <template v-slot:title>Onboard Schools</template>
            <template v-slot:description>Edlink offers three main ways to get schools connected to your application. You can choose the option or options that work best for your team.</template>
            <template v-slot:placards>
                <placard>
                    <template v-slot:title>Invite a specific district or university</template>
                    <template v-slot:description>Search by name or NCES ID</template>
                    <template v-slot:actions>
                        <a class="text-button has-icon-right" @click="create">
                            Invite a School
                            <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                        </a>
                    </template>
                </placard>
                <placard>
                    <template v-slot:title>Copy your generic integration link</template>
                    <template v-slot:description>Share this link with IT admins</template>
                    <template v-slot:actions>
                        <div class="text-button has-icon-right" @click="copy" :class="{disabled: applications.length === 0}">
                            Copy Link
                            <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                        </div>
                    </template>
                </placard>
                <placard>
                    <template v-slot:title>Develop a custom onboarding flow</template>
                    <template v-slot:description>Build onboarding into your app</template>
                    <template v-slot:actions>
                        <a class="text-button has-icon-right" href="https://ed.link/docs/guides/v2.0/onboarding-schools/custom-onboarding-flows" target="_blank">
                            Read the Docs
                            <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                        </a>
                    </template>
                </placard>
            </template>
            <template v-slot:details>
                <nav class="flex flex-align">
                    <div class="pill" :class="{active: tab === 'invited'}" @click="tab = 'invited'">
                        <SendMail class="icon" width="16" height="16" stroke-width="2" />
                        Invited Schools
                    </div>
                    <div class="pill" :class="{active: tab === 'completed'}" @click="tab = 'completed'">
                        <CheckCircle class="icon" width="16" height="16" stroke-width="2" />
                        Successful Onboardings
                    </div>
                    <div class="pill" :class="{active: tab === 'expired'}" @click="tab = 'expired'">
                        <Timer class="icon" width="16" height="16" stroke-width="2" />
                        Expired Invitations
                    </div>
                    <div class="ff"></div>
                    <input class="search" type="text" v-model="search" placeholder="Filter onboardings..." />
                </nav>
                <datatable class="card" :columns="columns" :rows="onboardings" :selectable="true" :header="true" @clicked="open" :loading="loading" :clickable="true" :min-height="onboardings.length ? '0px' : '160px'">
                    <template v-if="search" v-slot:empty-message>No onboardings match your search criteria</template>
                    <template v-else-if="tab === 'invited'" v-slot:empty-message>You have no invited schools</template>
                    <template v-else-if="tab === 'completed'" v-slot:empty-message>You have no completed onboardings</template>
                    <template v-else-if="tab === 'expired'" v-slot:empty-message>You have no expired onboardings</template>
                </datatable>
            </template>
        </information>
    </main>
</template>

<script>
    import { AlignLeft, SendMail, Calendar, CheckCircle, ScanBarcode, WhiteFlag, Timer, City, ArrowUpRight, Code } from '@epiphany/iconoir';
    import State from '@/components/chips/State.vue';
    import SelectApplication from '@/components/modals/SelectApplication';

    export default {
        name: 'Products',
        components: {
            City,
            CheckCircle,
            Timer,
            SendMail,
            ArrowUpRight,
            Code
        },
        data(){
            return {
                tab: 'invited',
                applications: [],
                all: [],
                search: '',
                loading: true,
                columns: [
                    {
                        id: 'name',
                        name: 'School Name',
                        icon: AlignLeft,
                        width: '30%',
                        value: row => {
                            if(row.team.name){
                                return row.team.name;
                            }
                            
                            return 'Unknown School (Created via API)';
                        }
                    },
                    {
                        id: 'application',
                        name: 'Application',
                        icon: ScanBarcode,
                        width: '30%',
                        value: row => row.application.name
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '10%',
                        component: State,
                        value: row => ({ state: row.state })
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'left',
                        width: '15%',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    },
                    {
                        id: 'expiration_date',
                        name: 'Expiration Date',
                        icon: Calendar,
                        align: 'left',
                        width: '15%',
                        value: row => {
                            if(row.expiration_date){
                                return this.$options.filters.pretty(row.created_date, 'short');
                            }
                            
                            return 'No Expiration';
                        }
                    }
                ]
            };
        },
        methods: {
            copy(){
                if(this.applications.length === 0){
                    return this.$toasted.error('You have no applications.');
                }

                if(this.applications.length === 1){
                    this.$clipboard(`https://ed.link/integrate/${this.applications[0].id}`);
                    return this.$toasted.success('Copied integration link to your clipboard');
                }

                this.$modal.show(SelectApplication, {
                    title: 'Select An Application',
                    filter: application => application,
                    select(application) {
                        this.$clipboard(`https://ed.link/integrate/${application.id}`);
                        this.$toasted.success('Copied integration link to your clipboard');
                    },
                }, { width: 320, height: 'auto', classes: 'modal', scrollable: true });
            },
            open(onboarding){
                this.$store.dispatch('drawer/open', {
                    key: `onboarding/${onboarding.id}`,
                    width: 800,
                    component: 'onboarding',
                    props: {
                        onboarding
                    }
                });
            },
            create(){
                this.$store.dispatch('drawer/open', {
                    key: 'create-onboarding',
                    width: 800,
                    component: 'create-onboarding',
                    props: {
                        created: this.insert.bind(this)
                    }
                });
            },
            insert(onboarding){
                this.all.unshift(onboarding);
            },
            refresh() {
                this.loading = true;

                return this.$http.get(`/teams/${this.team.id}/onboardings`)
                .then(response => {
                    this.all = response.$data.map(onboarding => {
                        // Set the two dates for ease of use later on.
                        onboarding.created_date = new Date(onboarding.created_date);
                        onboarding.expiration_date = onboarding.expiration_date ? new Date(onboarding.expiration_date) : null;

                        return onboarding;
                    }).sort((a, b) => {
                        return a.created_date < b.created_date ? 1 : -1;
                    });

                    this.loading = false;
                })
                .catch(error => {
                    this.$toasted.error(`There was an error loading your onboardings.`);
                });
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            admin() {
                return this.$store.getters.user?.admin;
            },
            verified() {
                return this.team?.verified;
            },
            onboardings(){
                return this.all.filter(onboarding => {
                    if(this.search){
                        if(!onboarding.team.name){
                            return false;
                        }

                        if(onboarding.team.name.toLowerCase().indexOf(this.search.toLowerCase()) === -1){
                            return false;
                        }
                    }

                    if(this.tab === 'invited'){
                        if(!['created', 'started'].includes(onboarding.state)){
                            return false;
                        }

                        if(onboarding.expiration_date && onboarding.expiration_date < new Date()){
                            return false;
                        }
                    }

                    if(this.tab === 'completed'){
                        if(onboarding.state !== 'completed'){
                            return false;
                        }
                    }

                    if(this.tab === 'expired'){
                        if(onboarding.state === 'completed'){
                            return false;
                        }

                        if(!onboarding.expiration_date){
                            return false;
                        }

                        if(onboarding.expiration_date > new Date()){
                            return false;
                        }
                    }

                    return true;
                });
            }
        },
        created() {
            this.refresh();

            // Load the team's applications so we can use them to copy the integration links.
            this.$http.get(`/teams/${this.team.id}/applications`)
            .then(response => this.applications = response.$data)
            .catch(error => this.$toasted.error(`There was an error loading your team's applications.`));
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .onboardings.full
    {
        top: 51px;

        nav
        {
            margin-bottom: @double-padding;
        }
    }
</style>