<template>
    <div class="import-csv full scroll">
        <div class="import-csv-wrapper">
            <div class="title flex flex-align">
                <div>
                    <h3>CSV Mapping</h3>
                    <div class="helptext">
                        Here you can map the columns in your CSV file into Edlink entities. This will allow you to use the data in your CSV file to create and update entities in Edlink.
                    </div>
                </div>
                <div class="ff"></div>
                <div class="button" @click="save">Save Changes</div>
            </div>

            <div class="files flex flex-align">
                <div class="file badge pointer" :class="{ active: file === selected_file, missing: is_missing(file) }"
                    v-for="file of files" @click="select_file(file)" :key="file">
                    <div class="file-name">
                        {{ file }}
                    </div>
                    <div class="file-icon" @click.stop="remove_file(file)">
                        <Cancel class="icon" width="13" height="13" stroke-width="2" />
                    </div>
                </div>
            </div>

            <!-- <div class="preview-row" v-if="csvs[selected_file] && csvs[selected_file].row">
                <div class="table">
                    <table>
                        <thead>
                            <tr class="row row-header">
                                <th v-for="(value, key) in csvs[selected_file].row">
                                    <div class="cell">{{ key }}</div>
                                </th>
                            </tr>
                        </thead>
        
                        <tbody>
                            <tr class="row" v-for="row in csvs[selected_file].rows">
                                <td v-for="(value, key) in row">
                                    <div class="cell">{{ value }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->

            <datatable v-if="csvs[selected_file] && csvs[selected_file].rows" class="card" :columns="preview_columns" :rows="csvs[selected_file].rows" :header="true" :clickable="false" :editable="false" :tooltips="true" />

            <!-- <div class="headers flex flex-align" v-if="selected_file && csvs[selected_file] && csvs[selected_file].headers && !load_timeout">
                <div class="title"> File Columns: </div>
                <div class="header pointer" v-for="header in csvs[selected_file].headers" :key="header" v-clipboard="() => header" v-clipboard:success="() => $toasted.info('Copied')">
                    {{ header }}
                </div>
            </div> -->

            <div class="banner flex flex-align grey" v-if="selected_file && is_missing(selected_file)">
                <span class="banner-icon icon block iconoir-warning-triangle-outline"></span>
                This file no longer exists in the source. You can still view the rules for it but they wont take effect.
                <div class="ff"></div>
            </div>

            <div class="rules flex flex-column flex-align" v-if="selected_file">
                <template v-if="rules[selected_file].length">
                    <csv-formatting-rule v-for="(rule, $index) of rules[selected_file]"
                        @input="changed => rule_changed($index, changed)" @duplicate="duplicate_rule($index)" @remove="remove_rule($index)" :rule="rule"
                        :csv_headers="csvs[selected_file].headers" :key="$index + selected_file + JSON.stringify(rule)" :failing="false" />
                </template>
                <div class="nothing" v-else>
                    No formatting rules have been added yet.
                </div>
                <div class="button white base" @click="create_rule">
                    Add Formatting Rule
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CSVFormattingRule from '@/components/CSVFormattingRule.vue';
import Vue from 'vue';
import { cloneDeep, update } from 'lodash';
import CreateFormattingRule from '@/components/drawers/CreateFormattingRule';
import { Cancel } from '@epiphany/iconoir'
import Options from '@/components/modals/Options.vue';

export default {
    name: 'Source Mapping',
    props: {
        transformation: Object
    },
    components: {
        'csv-formatting-rule': CSVFormattingRule,
        Cancel
    },
    data() {
        return {
            load_timeout: null,
            csvs: {},
            selected_file: null,
            rules: {},
            existing: [],
            initialized: false
        }
    },
    computed: {
        source() {
            return this.$store.getters.source;
        },
        integration() {
            return this.$store.getters.integration;
        },
        parent() {
            return this.source ?? this.integration;
        },
        parent_type() {
            return this.$store.state.blink.type;
        },
        provider() {
            return this.parent.provider;
        },
        team() {
            return this.$store.getters.team;
        },
        admin() {
            return this.$store.getters.user.admin;
        },
        preview() {
            return this.$store.state.blink.preview;
        },
        files() {
            return Object.keys(this.csvs).sort();
        },
        blink() {
            return this.$store.state.blink;
        },
        preview_columns() {
            if (this.csvs[this.selected_file]?.rows?.[0]) {
                return Object.keys(this.csvs[this.selected_file].rows[0]).map(key => {
                    return {
                        id: key,
                        name: key,
                        width: '120px',
                        action: {
                            handler: () => this.$clipboard(key) && this.$toasted.info('Copied'),
                            header: true,
                            cell: false
                        }
                    }
                });
            } else {
                return [];
            }
        }
        // trace() {
        //     const debug = this.blink.debug[this.transformation.id];
            
        //     if (debug?.extra?.trace) {
        //         return debug.extra.trace;
        //     } else {
        //         return null;
        //     }
        // }
    },
    mounted() {
        if (this.source?.configuration?.formatting_rules) {
            Vue.set(this, 'rules', cloneDeep(this.source.configuration.formatting_rules));

            const files = Object.entries(this.rules)

            for (const [file, rules] of files) {
                if (!this.csvs[file] && rules.length) {
                    Vue.set(this.csvs, file, { headers: null, row: null })
                }
            }
        }

        this.initialize();
    },
    destroyed() {
        if (this.load_timeout) {
            clearTimeout(this.load_timeout);
        }
    },
    methods: {
        is_missing(file) {
            return !this.existing.includes(file) && this.initialized;
        },
        // is_failing(index) {
        //     if (this.trace) {
        //         return this.trace.split('.')[0] === `[${index}]`
        //     } else {
        //         return false;
        //     }
        // },
        select_file(file) {
            this.selected_file = file;
        },
        create_rule() {
            this.$store.dispatch('drawer/open', {
                key: 'create-formatting-rule',
                width: 450,
                component: CreateFormattingRule,
                props: {
                    create: this.add_rule.bind(this)
                }
            });
        },
        add_rule(type) {
            const properties = {};

            this.rules[this.selected_file].push({
                entity_type: type,
                properties
            });
        },
        remove_rule(index) {
            this.rules[this.selected_file].splice(index, 1);
        },
        duplicate_rule(index) {
            const new_rule = cloneDeep(this.rules[this.selected_file][index]);
            if (new_rule.name) {
                new_rule.name += ' (copy)';
            }
            this.rules[this.selected_file].splice(index + 1, 0, new_rule);
        },
        rule_changed(index, changed) {
            this.rules[this.selected_file][index] = changed;
            this.$emit('input', { formatting_rules: cloneDeep(this.rules) });
        },
        remove_file(file) {
            const _this = this;

            this.$modal.show(Options, {
                title: `${this.is_missing(file) ? 'Remove' : 'Clear'} Rule`,
                description: `Are you sure you want to ${this.is_missing(file) ? 'remove' : 'clear the rules for'} this file?`,
                options: [
                    {
                        title: `${this.is_missing(file) ? 'Remove' : 'Clear'} File`,
                        color: 'red',
                        fn(){
                            if (_this.is_missing(file)) {
                                Vue.delete(_this.rules, file);
                                Vue.delete(_this.csvs, file);
                            } else {
                                _this.rules[file] = [];
                            }
                        }
                    }
                ]
            }, {width: 300, height: 'auto', classes: 'modal'});
        },
        load_headers() {
            return this.$http
                .get(
                    `/teams/${this.team.id}/sources/${this.source.id}/csv/headers`
                )
                .then(response => {
                    const files = Object.entries(response.$data)

                    for (const [file, content] of files) {
                        this.existing.push(file);
                        Vue.set(this.csvs, file, content);

                        if (!this.rules[file]) {
                            Vue.set(this.rules, file, []);
                        }
                    }

                    return this.csvs;
                });
        },
        initialize() {
            this.load_headers().then(() => {
                this.initialized = true;
            });
        },
        save() {
            // We have to clone the source configuration so we can update it.
            const updated = cloneDeep(this.source.configuration);

            // We update the formatting rules with the new rules.
            updated.formatting_rules = cloneDeep(this.rules);

            // We have to commit our changes to Vuex first.
            this.$store.commit('sources/update', { configuration: updated });

            // We then dispatch the update to the server.
            this.$store.dispatch('sources/update')
                .catch(error => this.$toasted.error('There was an error updating the source.'));
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.preview-row {
    margin-top: 15px;

    overflow-x: auto;
    overflow-y: hidden;

    table {
        border: 1px solid @e4;
        border-radius: 6px;
        border-spacing: 0;
        border-collapse: separate;
    }

    .row {
        &.row-header {
            .cell {
                color: @grey;
                background-color: @f4;
                font-weight: 500;
            }
        }
    }

    .cell {
        text-align: left;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        padding: 0 4px;
    }

    tr {
        border-bottom: 1px solid @e4;

        &:last-child {
            border-bottom: 0;
        }
    }

    th {
        &:first-child {
            .cell {
                border-top-left-radius: 6px;
            }
        }

        &:last-child {
            .cell {
                border-top-right-radius: 6px;
            }
        }
    }

    th, td {
        white-space: nowrap;
        border-right: 1px solid @e4;

        &:last-child {
            border-right: 0;
        }
    }
}

.title {
    margin: 0 0 25px 0;
    font-size: 16px;
    font-weight: 600;
    color: @black;

    .helptext {
        font-size: 12px;
        font-weight: 500;
        color: @grey;
        margin: 0;
    }

    h3 {
        margin: 0 0 10px 0;
    }
}

.caret {
    width: 18px;
    height: 18px;
    background-size: contain;
    background-image: url('~@/assets/icons/grey/caret-down.svg');

    &.closed {
        transform: rotate(-90deg)
    }
}

@rule-padding: 40px;

.rule {
    width: 100%;
    color: @black;
    border-radius: 6px;
    border: 1px solid @e4;
    margin-bottom: 25px;
    padding: 10px 15px;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 10px;
    //     bottom: 20px;
    //     left: @rule-padding + 4px;
    //     width: 1px;
    //     border-left: 1px dashed @c;
    //     z-index: 0;
    // }
}

.rule-header {
    padding: 0;
    z-index: 5;

    h3 {
        line-height: 36px;
        height: 36px;
    }
}

.rule-metadata {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}

.rule-and {
    line-height: 18px;
    width: 41px;
    height: 18px;
    margin: 7px 18px 7px -16px;
    z-index: 5;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    color: @grey;
    font-weight: 500;
    background: @f;
}

.dash {
    color: @grey;
    margin: 0 10px;
    text-transform: capitalize;
    font-size: 12px;
    color: @grey;
    font-weight: 500;

    &:first-child {
        margin-left: 0;
    }
}

select {
    width: fit-content;
    padding: 5px;
    height: 25px;
    line-height: 12px;
    font-size: 12px;
    padding-right: 20px;
    background-position: right 3px top 4px;
    background-size: 16px auto;
}

.edlink-prop {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: @grey;
    background-color: @f4;
    border-radius: 4px;
    padding: 0 4px;

}

.import-csv-wrapper {
    padding: 25px;
    max-width: 1400px;
}

.files {
    flex-wrap: wrap;
    margin: 0 -5px 25px -5px;
}

.headers {
    flex-wrap: wrap;
    margin-top: 10px;

    .title {
        margin: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        color: @black;
    }
}

.banner {
    margin: 20px 25px 0;
}

.header {
    margin: 5px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: @grey;
    background-color: @f4;
    border-radius: 4px;
    padding: 0 4px;

    &:hover {
        color: @base;
        background: fade(@base, 20%);
    }
}

.file {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.badge {
        color: @grey;
        background: @e;
        &.missing {
            color: @red;
            background: fade(@red, 20%);

            .file-icon {
                background-color: fade(@red, 20%);

                &:hover {
                    background-color: fade(@red, 80%);
                    color: @white;
                }
            }
        }
        
        &.active {
            color: @base;
            background: fade(@base, 20%);
            
            .file-icon {
                background-color: fade(@base, 20%);

                &:hover {
                    background-color: fade(@base, 80%);
                    color: @white;
                }
            }
        }
    }
}

.file-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1px;
    border-radius: 100%;
    background-color: @c;
    margin-left: 4px;
    margin-right: -4px;
}

.missing {
}

.rules {
    margin-top: 20px;

    .nothing {
        margin: 0 0 25px 0;
        padding: 40px 80px;
        font-size: 13px;
        text-align: center;
        border: 1px dashed @lightgrey;
        border-radius: 6px;
        // padding: 25px;
        color: @lightgrey;
    }
}

// .rule {
//     width: 100%;
//     margin-bottom: 25px;
//     color: @black;

//     .rule-header {
//         font-size: 16px;
//         // font-weight: 600;
//         margin-bottom: 10px;
//         color: @black;
//     }

//     select {
//         padding: 5px;
//         height: 30px;
//         line-height: 15px;
//         font-size: 12px;
//         padding-right: 20px;
//         background-position: right 4px top 7px;
//         background-size: 16px auto;
//     }

//     .row
//     {
//         margin-bottom: 5px;
//         // border-bottom: 1px solid @e4;
//         // padding: 10px 0px;

//         &:last-child
//         {
//             margin-bottom: 0;
//         }

//         &:hover
//         {
//             .icon-button.muted
//             {
//                 color: @red
//             }
//         }
//     }
// }

// .becomes
// {
//     color: @grey;
//     font-size: 13px;
//     line-height: 18px;
//     width: 50px;

//     .icon
//     {
//         font-size: 22px;
//         color: @black;
//     }
// }
</style>