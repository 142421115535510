<template>
    <div class="enrichment-logs">
        <div class="loading-container flex flex-center" v-if="loading">
            <spinner />
        </div>
        <editor v-else :value="result" :readonly="true" :block="null" />
    </div>
</template>

<script>
    import { entities } from '@/constants';
    
    export const LEVEL_MAP = {
        0: 'FATAL',
        1: 'ERROR',
        2: 'WARN',
        3: 'INFO',
        4: 'DEBUG',
        5: 'TRACE',
        6: 'SILLY'
    };

    export default {
        name: 'EnrichmentLogs',
        data() {
            return {
                entities,
                result: '',
                loading: true
            };
        },
        created() {
            const url = `/admin/sources/${this.enrichment.source.id}/enrichments/${this.enrichment.id}/logs`;

            this.$http.get(url).
                then(response => {
                    this.logs = response.$data;

                    if (this.logs.length > 0) {
                        this.result = response.$data.map(log => {
                            const date = new Date(log.log_date);
                            return (
                                `[${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}] ${LEVEL_MAP[log.log_level].padEnd(8, ' ')} ${log.log_message}`
                            )
                        }).join('\n');
                    } else {
                        this.result = 'No logs found.';
                    }
                }).catch(_err => {
                    this.result = 'Unable to retrieve logs.';
                    this.$toasted.error(`Unable to retrieve logs.`);
                }).finally(() => {
                    this.loading = false;
                });
        },
        methods: {

        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            enrichment() {
                return this.$store.state.drawer.props;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .enrichment-logs
    {
        color: @grey;
        font-size: 12px;
        height: 100%;
    }

    .drawer-section
    {
        padding: 25px;

        &:first-child
        {
            padding-top: 0;
        }
    }

    .loading-container
    {
        padding: 50px 0;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 0;
    }

    .editor
    {
        position: absolute;
        top: 20px;
    }
</style>
