<template>
    <div class="integrate-complete">
        <!-- <div class="integration-icons flex flex-align">
            <div class="integration-icon" v-if="source">
                <img :src="source.provider.icon_url ? source.provider.icon_url : '/source/default.png'" />
            </div>
            <div class="integration-connection"></div>
            <div class="integration-icon" v-if="state.context.application">
                <img :src="state.context.application.icon_url ? state.context.application.icon_url : '/source/default.png'" />
            </div>
        </div> -->
        <h1>Your new integration is complete.</h1>
        <template v-if="redirect">
            <div class="integration-description">
                Please wait while we redirect you back to {{application.name}}.
            </div>
            <spinner />
        </template>
        <template v-else>
            <div class="integration-description">
                You can manage this integration and view important information on your Edlink dashboard.
                For now, there's nothing else that needs to be done on your end.
                <!-- <template v-if="show_launch_url">
                    <integrate-source-editor-configuration title="Application Launch URL">
                        <label>Launch URL</label>
                        <div class="flex">
                            <div class="configuration-value copyable noshift" v-clipboard="() => launch_url" v-clipboard:success="() => $toasted.info('Copied')">{{ launch_url }}</div>
                        </div>
                    </integrate-source-editor-configuration>
                </template> -->
            </div>
            <div class="flex">
                <a v-if="application.setup_redirect_uri" class="button" :href="redirect">Return to {{application.name}}</a>
                <template v-else>
                    <a class="button" @click="close">Close This Window</a>
                    <router-link class="button white" :to="{name: 'team.sources', params: {team: $parent.district?.alias}}">View Edlink Dashboard</router-link>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import JSConfetti from 'js-confetti';

    const jsConfetti = new JSConfetti();

    export default {
        name: 'IntegrateComplete',
        components: {
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function
        },
        created(){
            jsConfetti.addConfetti({
                confettiColors: [
                    '#006AFF', '#F1C40F', '#2ED573', '#8E44AD', '#EB2F06'
                ]
            });

            if(this.redirect){
                setTimeout(() => {
                    window.location.href = this.redirect;
                }, 4000);
            }
        },
        computed:{
            redirect(){
                if(this.onboarding?.redirect_uri){
                    return this.onboarding.redirect_uri;
                }

                if(this.application.setup_redirect_uri && this.integration){
                    const redirect = new URL(this.application.setup_redirect_uri);
                    redirect.searchParams.set('integration_id', this.integration.id);
                    return redirect.href;
                }

                return this.application.setup_redirect_uri;
            },
            provider(){
                return this.state.context.provider ?? this.state.context.primary_provider;
            },
            source(){
                return this.state.context.source;
            },
            application(){
                return this.state.context.application;
            },
            integration(){
                return this.state.context.integration;
            },
            launch_url() {
                return `https://ed.link/api/authentication/integrations/${this.integration?.id}/launch`;
            },
            onboarding(){
                return this.$store.state.integrate.onboarding;
            }
        },
        methods: {
            close(){
                window.close();
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integration-summary
    {
        margin-bottom: 22px;
    }

    .integration-icons
    {
        padding: 30px 0 20px;
    }

    .integration-icon
    {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.3);//, 0 0 2px rgba(0, 0, 0, 0.1);//, 0 0 0 1px rgba(0, 0, 0, 0.08);
        background: @f;

        img
        {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
    }

    .integration-connection
    {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        background: url('~@/assets/icons/black/graph.svg') 0 0 no-repeat;
        background-size: 30px auto;
    }

    .integration-description
    {
        font-size: 14px;
        color: @grey;
        line-height: 18px;
        margin: 0 0 20px;

        label {
            margin-top: 20px;
            // font-size: 14px;
            font-weight: 500;
        }
    }

    .button
    {
        margin-right: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-top: 15px;
    }
</style>
