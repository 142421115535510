<template>
    <div class="entity-details">
        <div class="drawer-section">
            <h4>Request Logs</h4>
            <div class="ff flex flex-align flex-center" v-if="loading">
                <spinner />
            </div>
            <pre class="card ff monospace" v-html="output" v-if="output"></pre>
        </div>      
    </div>
</template>

<script>
    import Prism from 'prismjs';
    const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

    export default {
        name: 'Logs',
        data(){
            return {
                requests: [],
                loading: true,
                output: ''
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        },
        methods: {
            load(){
                this.loading = true;
                this.$http.get(`${base}/api/v2/graph/tasks/${this.entity.id}/logs`, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                })
                .then(response => {
                    this.requests.push(...response.$data);
                    this.output = Prism.highlight(JSON.stringify(response.$data, null, 4), Prism.languages.json, 'json');
                })
                .catch(error => this.$toasted.error('There was an error loading the requests for your task.'))
                .finally(() => this.loading = false);
            }
        },
        created(){
            this.requests = [];
            this.load();
        }
    }
</script>


<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
    }

    .drawer-section
    {
        padding: 0;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 0;
    }
</style>
