<template>
    <div class="enrichment-json">
        <div class="drawer-section">
            <pre class="card ff monospace" v-html="output" v-if="output"></pre>
        </div>
    </div>
</template>

<script>
    import Prism from 'prismjs';

    export default {
        name: 'EnrichmentJSON',
        data(){
            return {
                output: ''
            };
        },
        created(){
            try {
                //Color and format the JSON that represents this user.
                this.output = this.enrichment ? Prism.highlight(JSON.stringify(this.enrichment, null, 4), Prism.languages.json, 'json') : null;
            } catch(error) {
                console.log(error);
            }
        },
        methods: {

        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            enrichment() {
                return this.$store.state.drawer.props;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .drawer-section
    {
        padding: 25px;

        &:first-child
        {
            padding-top: 0;
        }
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 0;
    }
</style>
