<template>
    <div class="flow-configuration full scroll">
        <header class="flex flex-align">
            <div class="flex flex-column">
                <h2>Flow Configuration</h2>
                <h4>
                    Configure settings for flows between your primary SIS source
                    and your secondary LMS source.
                </h4>
            </div>
            <div class="ff"></div>
            <div class="button" @click="save">Save Changes</div>
            <div class="button" @click="validate" :class="{ disabled: validating }">Validate Configuration</div>
            <spinner v-if="validating" />
        </header>


        <div v-if="validation.length">
            <section class="flow-validation-results">
                <div class="helptext">
                    These are the results of the validation process.
                </div>
                <div class="validation">
                    <div class="validation-step flex flex-align" v-for="(step, index) of validation" :key="index">
                        <span class="icon block" :class="{ 'iconoir-info-empty': step.state === 'info', 'iconoir-warning-triangle-outline': step.state === 'warning', 'iconoir-delete-circled-outline': step.state === 'failed', 'iconoir-check-circled-outline': step.state === 'passed' }"></span>
                        <div class="ff">
                            <div class="validation-description">{{ step.description }}</div>
                            <div class="validation-message" v-if="step.message">{{ step.message }}</div>
                            <div class="validation-message" v-else-if="step.state === 'warning'">Something is not quite right.</div>
                            <div class="validation-message" v-else-if="step.state === 'failed'">This seems to be broken.</div>
                            <div class="validation-message" v-else-if="step.state === 'info'">Please ensure that this is correct.</div>
                            <div class="validation-message" v-else>Everything is good here.</div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="divider"></div>
        </div>

        <section class="flow-configuration-options">
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <toggle :active="has_config('send_summary_emails')" @change="() => toggle_config('send_summary_emails')" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Send Summary Emails</div>
                    <div class="flow-configuration-option-description">
                        Whenever a new set of changes is calculated, send a summary email to the technical contacts of your team.
                        This email will contain the number of created, updated, and deleted entities.
                    </div>
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <toggle :active="has_config('modify_external_entities')" @change="() => toggle_config('modify_external_entities')" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Allow Modification of Non-Edlink Entities</div>
                    <div class="flow-configuration-option-description">
                        Select this option if you want Edlink to be able to update or delete entities that it did not initially create.
                        For example, you may want to allow Edlink to delete a class or enrollment that was created manually or by another system.
                    </div>
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <toggle :active="has_config('transfer_unowned_classes')" @change="() => toggle_config('transfer_unowned_classes')" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Transfer Unowned Classes to Administrator</div>
                    <div class="flow-configuration-option-description">
                        Some systems do not allow classes to exist without at least one teacher.
                        This can be problematic if the primary teacher is no longer enrolled in the class.
                        By default, Edlink will not modify the class if the primary teacher leaves.
                        This option will transfer class ownership to the Google Workspace administrator.
                    </div>
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <toggle :active="has_config('skip_uncategorized_assignments')" @change="() => toggle_config('skip_uncategorized_assignments')" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Skip Uncategorized Assignments</div>
                    <div class="flow-configuration-option-description">
                        Some systems do not allow assignments to exist without a category.
                        By default, Edlink will assign a default category to these assignments.
                        This option will skip uncategorized assignments.
                    </div>
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <toggle :active="has_config('set_default_assignment_category')" @change="() => toggle_config('set_default_assignment_category')" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Set Default Assignment Category</div>
                    <div class="flow-configuration-option-description">
                        Some systems do not allow assignments to exist without a category.
                        Here you can specify the default category that will be assigned to uncategorized assignments.
                    </div>
                    <input v-if="has_config('set_default_assignment_category')" v-model="properties.default_assignment_category" type="text" class="medium" placeholder="Classwork" />
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <toggle :active="has_config('set_default_assignment_due_date')" @change="() => toggle_config('set_default_assignment_due_date')" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Set Default Assignment Due Date</div>
                    <div class="flow-configuration-option-description">
                        Some systems do not allow assignments to exist without a due date.
                        Here you can specify the default due date that will be assigned to assignments without a due date.
                    </div>
                    <select class="medium" v-if="has_config('set_default_assignment_due_date')" v-model="properties.default_assignment_due_date">
                        <option :value="'today'">Today</option>
                        <option :value="'tomorrow'">Tomorrow</option>
                        <option :value="'next_week'">Next Week</option>
                        <option :value="'next_month'">Next Month</option>
                    </select>
                </div>
            </div>

            <div class="divider"></div>

            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <input v-model="properties.flow.thresholds.create" type="text" class="small" placeholder="0" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Entity Create Threshold</div>
                    <div class="flow-configuration-option-description">
                        This is the number percentage of newly created items (relative to the number of existing items) that will trigger a job to be placed into the pending state.
                        You can use this to prevent Edlink from accidentally creating unnecessary items in the event of a data issue upstream.
                    </div>
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <input v-model="properties.flow.thresholds.update" type="text" class="small" placeholder="10" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Entity Update Threshold</div>
                    <div class="flow-configuration-option-description">
                        This is the number percentage of updated items (relative to the number of existing items) that will trigger a job to be placed into the pending state.
                        You can use this to prevent Edlink from accidentally updating items in the event of a data issue upstream.
                    </div>
                </div>
            </div>
            <div class="flow-configuration-option flex flex-align">
                <div class="flow-configuration-option-input">
                    <input v-model="properties.flow.thresholds.delete" type="text" class="small" placeholder="10" />
                </div>
                <div class="ff">
                    <div class="flow-configuration-option-title">Entity Delete Threshold</div>
                    <div class="flow-configuration-option-description">
                        This is the number percentage of deleted items (relative to the number of existing items) that will trigger a job to be placed into the pending state.
                        You can use this to prevent Edlink from deleting items in the event of a data issue upstream.
                    </div>
                </div>
            </div>
            <div v-for="method of ['create', 'update', 'delete']" :key="method">
                <div class="divider"></div>
                <div class="flow-configuration-option flex flex-align" v-for="(entity, index) of entities" :key="index">
                    <div class="flow-configuration-option-input">
                        <toggle :active="has(entity.permissions[method])" @change="() => toggle(entity.permissions[method])" />
                    </div>
                    <div class="ff">
                        <div class="flow-configuration-option-title capitalize">{{ method }} {{ entity.name }}</div>
                        <div class="flow-configuration-option-description">Whenever a new set of changes is calculated, send a summary email to the technical contacts of your team.</div>
                        <div class="flow-configuration-fields" v-if="entity.expanded[method]">
                            <autocomplete ref="input" class="block compact flow-autocomplete" :values.sync="properties.flow.masks[method][entity.property]" :source="value => fields(value, entity)" :format="value => format(entity, value)" />
                        </div>
                    </div>
                    <div class="button white" v-if="method !== 'delete'" @click="entity.expanded[method] = !entity.expanded[method]">Edit Fields</div>
                </div>
            </div>
        </section>

        <div class="divider"></div>

        <section class="flow-configuration-matching">
            <div class="helptext">
                These options will allow you to tune the data matching algorithm.
            </div>
            <div v-for="(configuration, entity) of properties.flow.entities" class="entity-configuration" :key="entity">
                <div class="entity-configuration-header flex flex-align pointer no-select" @click="expand(entity)">
                    <span class="icon" :class="{ 'iconoir-nav-arrow-right': !expanded[entity], 'iconoir-nav-arrow-down': expanded[entity] }"></span>
                    <div class="entity-title capitalize">{{ entity }}</div>
                </div>
                <div class="entity-configuration-options" v-if="expanded[entity]">
                    <div class="entity-configuration-option">
                        <h4>Join Strategy</h4>
                        <div class="flex flex-align join-strategies">
                            <div class="pointer no-select card join-strategy center left-join" @click="join(configuration, 'left')" :class="{ selected: configuration.join === 'left' }">Left</div>
                            <div class="pointer no-select card join-strategy center inner-join" @click="join(configuration, 'inner')" :class="{ selected: configuration.join === 'inner' }">Inner</div>
                            <div class="pointer no-select card join-strategy center right-join" @click="join(configuration, 'right')" :class="{ selected: configuration.join === 'right' }">Right</div>
                            <div class="pointer no-select card join-strategy center full-join" @click="join(configuration, 'full')" :class="{ selected: configuration.join === 'full' }">Full</div>
                            <div class="pointer no-select card join-strategy center no-join" @click="join(configuration, 'none')" :class="{ selected: configuration.join === 'none' }">None</div>
                        </div>
                    </div>
                    <h4>Join Condition</h4>
                    <div class="join-conditions" v-for="(condition, index) of configuration.conditions" :key="index">
                        <div class="join-condition">
                            <div class="join-condition-name monospace text-overflow">primary</div>
                            <input type="text" class="join-condition-value monospace" v-model="condition.primary" />
                        </div>
                        <div class="equals">=</div>
                        <div class="join-condition">
                            <div class="join-condition-name monospace text-overflow">secondary</div>
                            <input type="text" class="join-condition-value monospace" v-model="condition.secondary" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import { SeaWaves, SettingsAlt, Combine } from '@epiphany/iconoir';

    export default {
        name: 'FlowConfiguration',
        components: {
            SeaWaves,
            SettingsAlt,
            Combine
        },
        data() {
            return {
                validating: false,
                validation: [],
                permissions: [],
                properties: {
                    flow: {
                        thresholds: {
                            create: undefined,
                            update: undefined,
                            delete: undefined
                        }
                    }
                },
                expanded: {
                    people: false,
                    districts: false,
                    schools: false,
                    sessions: false,
                    classes: false,
                    courses: false,
                    sections: false,
                    enrollments: false,
                    agents: false,
                    meetings: false,
                    attendance: false,
                    departments: false,
                    subjects: false,
                    periods: false,
                    incidents: false,
                    facilities: false,
                    rooms: false,
                    assets: false,
                    fees: false,
                    stops: false,
                    routes: false,
                    vehicles: false,
                    calendars: false,
                    days: false,

                    // These types are for scheduling tasks.
                    assignments: false,
                    resources: false,
                    submissions: false
                },
                entities: [
                    {
                        property: 'districts',
                        name: 'District',
                        permissions: {
                            create: 'af762240-a1d6-43e1-bd91-99a0d55325cc',
                            update: 'e39411fb-6302-4ac1-896b-b1a5d94ff53d',
                            delete: 'df4b4236-878d-4423-b9f6-9857a416e954'
                        },
                        fields: {
                            'id': 'District ID',
                            'name': 'Name',
                            'picture_url': 'Picture URL',
                            'locale': 'Locale',
                            'location': 'Location',
                            'time_zone': 'Time Zone'
                        },
                        defaults: {
                            create: ['id', 'name', 'picture_url', 'locale', 'location', 'time_zone'],
                            update: ['id', 'name', 'picture_url', 'locale', 'location', 'time_zone']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'schools',
                        name: 'Schools',
                        permissions: {
                            create: '8425c9e0-76ed-4aa2-8a62-2f73be16038d',
                            update: '4c96a858-134a-4b16-99ec-808580f2f1ed',
                            delete: 'e3aa0959-b7a1-413b-8243-8a96df3483f8'
                        },
                        fields: {
                            'id': 'School ID',
                            'name': 'Name',
                            'picture_url': 'Picture URL',
                            'locale': 'Locale',
                            'location': 'Location',
                            'time_zone': 'Time Zone',
                            'grade_levels': 'Grade Levels',
                            'district_id': 'District ID'
                        },
                        defaults: {
                            create: ['id', 'name', 'picture_url', 'locale', 'location', 'time_zone', 'grade_levels', 'district_id'],
                            update: ['id', 'name', 'picture_url', 'locale', 'location', 'time_zone', 'grade_levels']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'classes',
                        name: 'Classes',
                        permissions: {
                            create: '8de4ce24-43a1-4df3-9516-c0788982bee3',
                            update: 'a5d4e3e6-aae2-44c5-8cee-40a73a2ca2f6',
                            delete: '111f092f-7b71-42c1-a11d-1f6c2dfbc000'
                        },
                        fields: {
                            'id': 'Class ID',
                            'name': 'Name',
                            'description': 'Description',
                            'picture_url': 'Picture URL',
                            'locale': 'Locale',
                            'time_zone': 'Time Zone',
                            'subjects': 'Subjects',
                            'grade_levels': 'Grade Levels',
                            'periods': 'Periods',
                            'state': 'State',
                            'session_ids': 'Session ID',
                            'course_id': 'Course ID',
                            'school_id': 'School ID'
                        },
                        defaults: {
                            create: ['id', 'name', 'description', 'picture_url', 'locale', 'time_zone', 'subjects', 'grade_levels', 'periods', 'state', 'session_ids', 'course_id', 'school_id'],
                            update: ['id', 'name', 'description', 'picture_url', 'locale', 'time_zone', 'subjects', 'grade_levels', 'periods', 'state', 'session_ids', 'course_id', 'school_id']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'people',
                        name: 'People',
                        permissions: {
                            create: 'c715c033-13c1-427b-977e-713f84f7f343',
                            update: 'd9d9b7fa-0f4d-42cb-bdbf-52002b3000f8',
                            delete: '64504ab4-618c-4dde-a80c-f8dd5e6ac626'
                        },
                        fields: {
                            'id': 'Person ID',
                            'first_name': 'First Name',
                            'middle_name': 'Middle Name',
                            'last_name': 'Last Name',
                            'display_name': 'Display Name',
                            'picture_url': 'Picture URL',
                            'roles': 'Roles',
                            'email': 'Email',
                            'phone': 'Phone',
                            'locale': 'Locale',
                            'time_zone': 'Time Zone',
                            'graduation_year': 'Graduation Year',
                            'grade_levels': 'Grade Levels',
                            'demographics': 'Demographics',
                            'district_id': 'District ID',
                            'school_ids': 'School IDs'
                        },
                        defaults: {
                            create: ['id', 'first_name', 'middle_name', 'last_name', 'display_name', 'picture_url', 'roles', 'email', 'phone', 'locale', 'time_zone', 'graduation_year', 'grade_levels', 'demographics', 'district_id', 'school_ids'],
                            update: ['id', 'first_name', 'middle_name', 'last_name', 'display_name', 'picture_url', 'phone', 'locale', 'time_zone', 'graduation_year', 'grade_levels', 'demographics', 'school_ids']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'courses',
                        name: 'Courses',
                        permissions: {
                            create: 'ad4021ea-ad66-4d1b-9abd-8d11c28b3efe',
                            update: '9e93b2b4-1d38-42d2-bd3d-79a507fe992a',
                            delete: '8d39df68-a041-4149-bc68-de0c58bfbd3d'
                        },
                        fields: {
                            'id': 'Course ID',
                            'name': 'Name',
                            'code': 'Code',
                            'subjects': 'Subjects',
                            'grade_levels': 'Grade Levels',
                            'district_id': 'District ID',
                            'school_id': 'School ID',
                            'session_id': 'Session ID'
                        },
                        defaults: {
                            create: ['id', 'name', 'code', 'subjects', 'grade_levels', 'district_id', 'school_id', 'session_id'],
                            update: ['id', 'name', 'code', 'subjects', 'grade_levels', 'school_id', 'session_id']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'sections',
                        name: 'Sections',
                        permissions: {
                            create: '075c7fc6-0621-48a9-972a-15e7460b08fe',
                            update: 'd1f8e5f0-42c8-403a-bdc7-c7adb7d26735',
                            delete: '5e64a975-a8af-4c31-ae47-513bce3f3953'
                        },
                        fields: {
                            'id': 'Section ID',
                            'name': 'Name',
                            'picture_url': 'Picture URL',
                            'locale': 'Locale',
                            'time_zone': 'Time Zone',
                            'state': 'State',
                            'description': 'Description',
                            'periods': 'Periods',
                            'class_id': 'Class ID'
                        },
                        defaults: {
                            create: ['id', 'name', 'picture_url', 'locale', 'time_zone', 'state', 'description', 'periods', 'class_id'],
                            update: ['id', 'name', 'picture_url', 'locale', 'time_zone', 'state', 'description', 'periods', 'class_id']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'enrollments',
                        name: 'Enrollments',
                        permissions: {
                            create: '2b0357e9-7655-416f-85b3-d0a7b74f20b6',
                            update: '55ad27a5-53a7-406c-b121-e67fae258bda',
                            delete: '294b2d4c-2830-479e-8c76-53c603ab5181'
                        },
                        fields: {
                            'id': 'Enrollment ID',
                            'state': 'State',
                            'start_date': 'Start Date',
                            'end_date': 'End Date',
                            'primary': 'primary',
                            'person_id': 'Person ID',
                            'class_id': 'Class ID',
                            'section_id': 'Section ID'
                        },
                        defaults: {
                            create: ['id', 'state', 'role', 'start_date', 'end_date', 'primary', 'person_id', 'class_id', 'section_id'],
                            update: ['id', 'state', 'role', 'start_date', 'end_date', 'primary', 'person_id', 'class_id', 'section_id']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'sessions',
                        name: 'Sessions',
                        permissions: {
                            create: '992c6c7b-fc75-40d4-8afa-9c1c4d641e99',
                            update: '7f6e85b5-7ad0-41ee-9f28-86d184ea9742',
                            delete: '48fd22af-521d-44b8-aa44-c1f77fd9f584'
                        },
                        fields: {
                            'id': 'Session ID',
                            'name': 'Name',
                            'state': 'State',
                            'start_date': 'Start Date',
                            'end_date': 'End Date',
                            'school_id': 'School ID',
                            'district_id': 'District ID'
                        },
                        defaults: {
                            create: ['id', 'name', 'type', 'state', 'start_date', 'end_date', 'school_id', 'district_id'],
                            update: ['id', 'name', 'type', 'state', 'start_date', 'end_date', 'school_id']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'agents',
                        name: 'Agents',
                        permissions: {
                            create: '4206a4ae-3df2-4beb-882f-00db524db669',
                            update: '95482b7f-cc2f-4773-9554-8cf9a3ff0f8e',
                            delete: '76106e22-2d19-41b7-a8b5-4d14afcde0e7'
                        },
                        fields: {
                            'id': 'Agent ID',
                            'observer_id': 'Observer ID',
                            'target_id': 'Target ID',
                            'relationship': 'Relationship',
                        },
                        defaults: {
                            create: ['id', 'observer_id', 'target_id', 'relationship'],
                            update: ['id', 'observer_id', 'target_id', 'relationship']
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'assignments',
                        name: 'Assignments',
                        permissions: {
                            create: '93d8f393-9ba8-4de4-a215-2ec07503146a',
                            update: 'a4fcb528-2642-4c7b-8a83-46bc4348713d',
                            delete: 'f7a292c8-1ee5-41b2-a71d-fdae1fe9028b'
                        },
                        fields: {

                        },
                        defaults: {
                            create: [],
                            update: []
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    },
                    {
                        property: 'submissions',
                        name: 'Submissions',
                        permissions: {
                            create: '25391849-e898-46f2-bb11-1a727aa3c231',
                            update: 'eece0ca0-2559-42c8-ae07-ffdf0a6bef38',
                            delete: 'f7a292c8-1ee5-41b2-a71d-fdae1fe9028b'
                        },
                        fields: {

                        },
                        defaults: {
                            create: [],
                            update: []
                        },
                        expanded: {
                            create: false,
                            update: false
                        }
                    }
                ]
            };
        },
        created() {
            const base = {
                flow: {
                    masks: {
                        create: {},
                        update: {}
                    },
                    entities: {},
                    foreign: {
                        update: [],
                        delete: []
                    },
                    thresholds: {
                        create: 0,
                        update: 10,
                        delete: 10
                    }
                }
            };

            // Set the entity merge conditions programmatically.
            for (const { property, defaults } of this.entities) {
                // Set the default masks.
                _.set(base, `flow.masks.create.${property}`, defaults.create);
                _.set(base, `flow.masks.update.${property}`, defaults.update);

                // Set the default join conditions.
                _.set(base, `flow.entities.${property}`, {
                    join: 'none',
                    conditions: [{
                        strategy: 'hash',
                        primary: '',
                        secondary: ''
                    }],
                    merge: {}
                });
            }

            // Create a copy of the permissions object so we can modify it.
            this.permissions = _.clone(this.integration.permissions);

            // Create a copy of the configuration object so we can modify it and set some defaults in case this is our first time viewing it.
            this.properties = _.defaultsDeep(
                _.cloneDeep(this.integration.properties),
                base
            );
        },
        methods: {
            save() {
                this.$store.dispatch('save/save', 'integrations/update', { root: true });

                this.$http.put(`/teams/${this.$store.state.teams.active.id}/integrations/${this.integration.id}`, {
                    properties: this.properties,
                    permissions: this.permissions
                })
                    .then(response => {
                        this.$store.commit('integrations/update', response.$data);
                    })
                    .finally(() => this.$store.dispatch('save/saved', 'integrations/update', { root: true }));
            },
            validate() {
                this.validating = true;
                this.validation = [];

                this.$http.get(`/teams/${this.$store.state.teams.active.id}/integrations/${this.integration.id}/validate`, {
                    baseURL: '/api/v2',
                    headers: {
                        authorization: `Bearer ${this.$store.state.user.token}`
                    }
                })
                    .then(response => this.validation = response.$data.steps)
                    .catch(error => this.$toasted.error('There was an error validating the source.'))
                    .finally(() => this.validating = false);
            },
            join(configuration, join) {
                configuration.join = join;
            },
            expand(entity) {
                this.expanded[entity] = !this.expanded[entity];
            },
            has_config(option) {
                return this.properties[option] ?? false;
            },
            has(permission) {
                return this.permissions.includes(permission);
            },
            toggle_config(option) {
                if (this.properties.hasOwnProperty(option)) {
                    Vue.set(this.properties, option, !this.properties[option]);
                } else {
                    Vue.set(this.properties, option, true);
                }
            },
            toggle(permission) {
                if (this.has(permission)) {
                    this.permissions = this.permissions.filter(p => p !== permission);
                } else {
                    this.permissions.push(permission);
                }
            },
            fields(value, entity) {
                return Promise.resolve(Object.entries(entity.fields)
                    .filter(([key, value]) => {
                        return true;
                    })
                    .map(([key, value]) => key));
            },
            format(entity, token) {
                return entity.fields[token];
            }
        },
        computed: {
            integration() {
                return this.$store.getters.integration;
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .flow-configuration
    {
        height: 100%;
        width: 1200px;
        padding: 20px;

        .flow-configuration-options
        {
            padding: 20px;
        }

        .flow-configuration-matching
        {
            padding: 0 20px;
        }
    }
    
    .flow-validation-results
    {
        height: 100%;
        width: 1200px;
        padding: 20px;

        .validation-step
        {
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }

            .icon
            {
                margin-right: 10px;
                font-size: 20px;
                height: 20px;
                width: 20px;
            }

            .iconoir-warning-triangle-outline
            {
                color: @yellow;
            }

            .iconoir-delete-circled-outline
            {
                color: @red;
            }

            .iconoir-check-circled-outline
            {
                color: @green;
            }

            .validation-description
            {
                color: @grey;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 2px;
            }

            .validation-message
            {
                color: @black;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    header
    {
        border-bottom: 1px solid @e4;
        padding-bottom: 20px;


        .button
        {
            margin-left: 20px;
        }

        h2
        {
            color: @black;
            font-size: 16px;
        }

        h4
        {
            color: @grey;
            font-size: 14px;
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .divider
    {
        height: 1px;
        background: @e4;
        margin: 30px 25px;
    }

    .entity-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);
        margin-right: 15px;

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }

    .flow-configuration-option
    {
        margin: 30px 0;

        &:first-child
        {
            margin-top: 0;
        }

        &:last-child
        {
            margin-bottom: 0;
        }

        .flow-configuration-option-input
        {
            width: 40px;
            margin-right: 20px;

            input.small
            {
                width: 40px;
                height: 29px;
                padding: 2px 6px;
                text-align: center;
                line-height: 23px;
                font-size: 13px;
            }
        }

        .flow-configuration-option-title
        {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
            color: @black;
            line-height: 18px;
        }

        .flow-configuration-option-description
        {
            font-size: 12px;
            color: @grey;
            line-height: 16px;
            max-width: 500px;
        }
    }

    .flow-configuration-fields
    {
        margin-top: 10px;
    }

    .flow-autocomplete
    {
        max-width: 500px;
    }

    .flow-configuration
    {
        height: 100%;
    }

    .entity-configuration
    {
        margin: 25px 0;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .entity-configuration-header
    {
        .entity-title
        {
            line-height: 22px;
            font-weight: 500;
            font-size: 15px;
            color: @black;
        }

        .icon
        {
            background: fade(@base, 10%);
            // background: darken(@f4, 5%);
            border-radius: 4px;
            color: @base;
            font-size: 21px;
            width: 21px;
            height: 21px;
            line-height: 21px;
            display: block;
            margin: 0 10px 0 0;
        }
    }

    .entity-configuration-options
    {
        padding: 0px 0 0px 20px;
        border-left: 1px solid @e4;
        margin: 25px 0 0 10px;

        h4
        {
            margin: 20px 0 10px;

            &:first-child
            {
                margin-top: 0;
            }
        }
    }

    .button
    {
        margin-left: 10px;
    }

    .helptext
    {
        font-size: 14px;
        color: @grey;
        line-height: 20px;
        max-width: 800px;
        margin-top: 5px;

        a
        {
            color: @base;
        }
    }

    .join-strategy
    {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        border: 1px solid @d;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 39px 0 3px;
        margin: 0 10px 0 0;
        color: @black;
        background-position: 10px 10px;
        background-size: auto 24px;
        background-repeat: no-repeat;

        &.inner-join
        {
            background-image: url('~@/assets/icons/joins/inner-join.svg');
        }

        &.left-join
        {
            background-image: url('~@/assets/icons/joins/left-join.svg');
        }

        &.right-join
        {
            background-image: url('~@/assets/icons/joins/right-join.svg');
        }

        &.full-join
        {
            background-image: url('~@/assets/icons/joins/full-join.svg');
        }

        &.no-join
        {
            background-image: url('~@/assets/icons/joins/no-join.svg');
        }

        &.selected
        {
            border-color: @base;
            box-shadow: 0 0 0 2px fade(@base, 30%);
            color: @base;

            &.inner-join
            {
                background-image: url('~@/assets/icons/joins/inner-join-selected.svg');
            }

            &.left-join
            {
                background-image: url('~@/assets/icons/joins/left-join-selected.svg');
            }

            &.right-join
            {
                background-image: url('~@/assets/icons/joins/right-join-selected.svg');
            }

            &.full-join
            {
                background-image: url('~@/assets/icons/joins/full-join-selected.svg');
            }

            &.no-join
            {
                background-image: url('~@/assets/icons/joins/no-join-selected.svg');
            }
        }
    }

    .merge-settings,
    .join-conditions
    {
        width: 750px;

        .merge-setting-name,
        .join-condition-name
        {
            width: 180px;
            color: @grey;
            font-size: 13px;
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 10;
            height: 16px;
            line-height: 16px;
            pointer-events: none;
            padding-right: 20px;

            &::after
            {
                content: '=';
                position: absolute;
                right: 0;
                top: 0;
                width: 10px;
                height: 14px;
                line-height: 14px;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .merge-setting,
        .join-condition
        {

            .merge-setting-value,
            .join-condition-value
            {
                padding-left: 200px;
                font-size: 13px;
                color: @black;
                font-weight: 500;
                margin-bottom: -1px;
                border-radius: 0;

                &:focus
                {
                    z-index: 2;
                }
            }

            &:first-child .merge-setting-value,
            &:first-child .join-condition-value
            {
                border-radius: 6px 6px 0 0;
            }

            &:last-child .merge-setting-value,
            &:last-child .join-condition-value
            {
                border-radius: 0 0 6px 6px;
                margin-bottom: 0;
            }
        }
    }

    .join-conditions
    {
        width: 500px;

        .equals
        {
            width: 19px;
            height: 19px;
            line-height: 19px;
            border-radius: 4px;
            position: absolute;
            top: 30px;
            left: -9px;
            z-index: 4;
            background: @white;
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            color: @grey;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1);
        }

        .join-condition-name
        {
            height: 24px;
            border-radius: 4px;
            background-color: fade(@base, 10%);
            padding: 0 6px;
            color: @base;
            right: 8px;
            left: auto;
            top: 8px;
            line-height: 24px;
            width: auto;

            &::after
            {
                display: none;
            }
        }

        .join-condition
        {
            .join-condition-value
            {
                padding-left: 15px;
                padding-right: 120px;
            }

            &:focus
            {
                z-index: 2;
            }
        }
    }
</style>
