import axios from 'axios';

const state = {
    active: null,
    updating: false
};

let throttle = null;

const mutations = {
    activate(state, integration) {
        state.active = integration;
    },
    deactivate() {
        state.active = null;
    },
    update(state, updated) {
        //TODO Update these fields
        const valid_fields = ['name', 'properties', 'permissions', 'status', 'scope', 'transformations', 'transformation_ids', 'billing_category', 'district_sharing', 'developer_sharing', 'district_licensing_enabled'];

        for (const field of valid_fields) {
            if (updated.hasOwnProperty(field)) {
                state.active[field] = updated[field];
            }
        }
    },
    did_create_rule(state, rule) {
        state.active.rules.push(rule);
    },
    did_update_rule(state, rule) {
        const existing = state.active.rules.find((r) => r.id === rule.id);

        if (existing) {
            state.active.rules.splice(state.active.rules.indexOf(existing), 1, rule);
        }
    },
    did_delete_rule(state, rule) {
        const existing = state.active.rules.find((r) => r.id === rule.id);

        if (existing) {
            state.active.rules.splice(state.active.rules.indexOf(existing), 1);
        }
    },
    splice(state, transformation) {
        if (state.active) {
            // If this transformation is active, remove it from the config.
            const configuration = state.active.transformations.find((config) => config.id === transformation.id);

            if (configuration) {
                // Remove the transformation from the config array.
                state.active.transformations.splice(state.active.transformations.indexOf(configuration), 1);

                // Find any transformations that are dependent on this one and update their respective inputs.
                for (const t of state.active.transformations) {
                    if (t.input === configuration.id) {
                        t.input = configuration.input;
                    }
                }
            }
        }
    },
    after(state, { transformation, after }) {
        if (state.active) {
            // Remove from current location in array
            const current = state.active.transformation_ids.findIndex((it) => it === transformation);

            state.active.transformation_ids.splice(current, 1);
            // Insert at desired location
            const index = state.active.transformation_ids.findIndex((it) => it === after);
            state.active.transformation_ids.splice(index + 1, 0, transformation);
        }
    },
    before(state, { transformation, before }) {
        if (state.active) {
            // Remove from current location in array
            const current = state.active.transformation_ids.findIndex((it) => it === transformation);

            state.active.transformation_ids.splice(current, 1);
            // Insert at desired location
            const index = state.active.transformation_ids.findIndex((it) => it === before);
            state.active.transformation_ids.splice(index, 0, transformation);
        }
    }
};

const actions = {
    activate({ commit }, { team, integration }) {
        //Clear the previous integration so there isn't a flicker.
        commit('deactivate');

        //Load the requested integration.
        return axios.get(`/teams/${team}/integrations/${integration}?$expand=links`).then((response) => commit('activate', response.$data));
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit, state, dispatch, rootState }) {
        if (state.active) {
            if (throttle) {
                clearTimeout(throttle);
            }

            // Dispatch a change to our save service.
            dispatch('save/save', 'integrations/update', { root: true });

            throttle = setTimeout(() => {
                throttle = null;

                axios
                    .put(`/teams/${rootState.teams.active.id}/integrations/${state.active.id}`, state.active)
                    .then((response) => commit('update', response.$data))
                    .finally(() => dispatch('save/saved', 'integrations/update', { root: true }));
            }, 3000);
        } else {
            return Promise.reject('There is no active integration.');
        }
    },
    destroy({ commit, rootState }) {
        if (state.active) {
            return axios.delete(`/teams/${rootState.teams.active.id}/integrations/${state.active.id}`).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active integration.');
    },
    status({ commit, rootState }, status) {
        if (state.active) {
            //We have to reference global state to find the active team. In this case, state.active.team refers to the team
            //who built the application that is being integrated - not the school who "owns" the integration.
            return axios.put(`/teams/${rootState.teams.active.id}/integrations/${state.active.id}/status`, { status }).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active integration.');
    },
    did_create_rule({ commit }, rule) {
        if (state.active) {
            return commit('did_create_rule', rule);
        }

        return Promise.reject('There is no active integration.');
    },
    did_update_rule({ commit }, rule) {
        if (state.active) {
            return commit('did_update_rule', rule);
        }

        return Promise.reject('There is no active integration.');
    },
    did_delete_rule({ commit }, rule) {
        if (state.active) {
            return commit('did_delete_rule', rule);
        }

        return Promise.reject('There is no active integration.');
    },
    reload({ commit, rootState }) {
        if (state.active) {
            return axios.get(`/teams/${rootState.teams.active.id}/integrations/${state.active.id}?$expand=links`).then((response) => commit('activate', response.$data));
        }

        return Promise.reject('There is no active integration.');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
