<template>
    <div class="radio flex flex-align" :class="{disabled, error, borderless}">
        <label class="radio-option flex flex-align" v-for="option of options" :key="option.value" :class="{checked: option.value === value}">
            <input class="radio-input" :name="name" type="radio" :disabled="disabled" :checked="option.value === value" @input="update" :value="option.value" />
            <div class="radio-label" v-if="option.label">{{option.label}}</div>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'Radio',
        props: {
            name: {
                type: String,
                required: true
            },
            type: String,
            options: Array,
            error: Boolean,
            value: String | Boolean | Number,
            disabled: Boolean,
            borderless: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            update($event){
                let value = $event.target.value;

                if(this.type === 'boolean'){
                    if(['true', 'false'].includes(value)){
                        value = ( value === 'true' );
                    }else{
                        value = undefined;
                    }
                }

                if(this.type === 'integer'){
                    value = parseInt(value);
                }

                this.$emit('update:value', value);
                this.$emit('input', value);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .radio
    {
        &.disabled
        {
            pointer-events: none;
            
            .radio-label
            {
                color: @grey;
            }

            input:checked
            {
                background-color: @grey;
                border-color: @grey;
            }
        }
    }

    label.radio-option
    {
        border: 1px solid @d;
        padding: 4px 10px;
        height: 40px;
        line-height: 30px;
        border-radius: 6px;
        text-transform: none;
        margin: 0;
        cursor: pointer;
        margin-right: 10px;

        &.borderless {
            border: none;
            padding: 4px;

            &:focus-within, &:active
            {
                border-color: none;
                box-shadow: none;
            }
        }

        input
        {
            margin: 0;
        }

        &:focus-within, &:active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;
        }

        .radio-label
        {
            margin: 0 0 0 10px;
            user-select: none;
            white-space: nowrap;
            font-size: 14px;
            color: @black;
        }
    }
</style>
