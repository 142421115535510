import { render, staticRenderFns } from "./Subscriber.vue?vue&type=template&id=2a36e73f&scoped=true"
import script from "./Subscriber.vue?vue&type=script&lang=js"
export * from "./Subscriber.vue?vue&type=script&lang=js"
import style0 from "./Subscriber.vue?vue&type=style&index=0&id=2a36e73f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a36e73f",
  null
  
)

export default component.exports