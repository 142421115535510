<template>
    <div class="team-navigation">
        <div class="sidebar-group">
            <div class="sidebar-header">Manage</div>
            <template v-if="team.type === 'developer'">
                <router-link class="destination flex flex-align" :to="{name: 'team.dashboard', params: { team: team.alias }}">
                    <StatsUpSquare class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Dashboard</div>
                </router-link>
                <router-link class="destination flex flex-align" :to="{name: 'team.applications', params: { team: team.alias }}">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Applications</div>
                </router-link>
            </template>
            <template v-else>
                <router-link class="destination flex flex-align" :to="{name: 'team.sources', params: { team: team.alias }}">
                    <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Sources</div>
                </router-link>
            </template>
            <router-link class="destination flex flex-align" :to="{name: 'team.integrations', params: { team: team.alias }}">
                <Bolt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Integrations</div>
            </router-link>
            <template v-if="team.type === 'developer'">
                <router-link class="destination flex flex-align" :to="{name: 'team.products', params: { team: team.alias }}">
                    <ShopAlt class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Products</div>
                </router-link>
                <router-link class="destination flex flex-align" :to="{name: 'team.onboardings'}">
                    <GridAdd class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Onboardings</div>
                </router-link>
            </template>
            <template v-if="team.type === 'district'">
                <router-link class="destination flex flex-align" :to="{name: 'team.privacy', params: { team: team.alias }}" v-if="admin">
                    <FingerprintCircle class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Data Privacy</div>
                </router-link>
                <router-link class="destination flex flex-align" :to="{name: 'team.settings', params: { team: team.alias }}">
                    <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Settings</div>
                </router-link>
            </template>
        </div>
        <div class="sidebar-group" v-if="team.type === 'developer'">
            <div class="sidebar-header">Develop</div>
            <router-link class="destination flex flex-align" :to="{name: 'team.logs', params: { team: team.alias }}">
                <TerminalCircled class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">API Logs</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/docs">
                <Code class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Documentation</div>
            </router-link>
        </div>
        <div class="sidebar-group" v-if="team.type === 'developer'">
            <div class="sidebar-header">Team</div>
            <router-link class="destination flex flex-align" :to="{name: 'team.settings', params: { team: team.alias }}">
                <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Settings</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.billing', params: { team: team.alias }}" v-if="admin">
                <Coins class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Billing</div>
            </router-link>
        </div>
        <div class="sidebar-group">
            <div class="sidebar-header">Get Help</div>
            <a class="destination flex flex-align" href="https://ed.link/help" target="_blank">
                <BookmarkBook class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Knowledge Base</div>
                <ArrowUpRight width="10" height="10" stroke-width="3" />
            </a>
            <a class="destination flex flex-align csm" href="mailto:support@ed.link" target="_blank" v-if="csm">
                <div class="csm-picture">
                    <img :src="csm.user.picture_url ? csm.user.picture_url : '/source/default.png'" />
                </div>
                <div class="ff">Get Help From {{csm.user.first_name}}</div>
                <ArrowUpRight width="10" height="10" stroke-width="3" />
            </a>
        </div>
    </div>
</template>

<script>
    import { FingerprintCircle, StatsUpSquare, GridAdd, ShopAlt, MapsArrowDiagonal, Svg3dSelectSolid, Bolt, SettingsAlt, TerminalCircled, GitMerge, Code, CloudSync, ArrowUpRight, BookmarkBook, Coins } from '@epiphany/iconoir';

    export default {
        name: 'TeamNavigation',
        components: {
            CloudSync,
            StatsUpSquare,
            Bolt,
            Svg3dSelectSolid,
            ShopAlt,
            MapsArrowDiagonal,
            SettingsAlt,
            TerminalCircled,
            GitMerge,
            Code,
            ArrowUpRight,
            BookmarkBook,
            Coins,
            GridAdd,
            FingerprintCircle
        },
        methods: {
            
        },
        computed: {
            verified() {
                return this.$store.state.user ? this.$store.state.user.email_verified : false;
            },
            admin() {
                return this.$store.getters.user?.admin;
            },
            csm(){
                if(this.team?.type === 'developer'){
                    if(this.verified){
                        if(this.team.members){
                            return this.team.members
                            .filter(member => member.user.admin)
                            .sort((a, b) => {
                                return new Date(b.created_date) - new Date(a.created_date);
                            })
                            .shift();
                        }
                    }
                }
            },
            team() {
                return this.$store.getters.team;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .team-navigation
    {
        width: 100%;
    }

    .csm-picture
    {
        width: 20px;
        height: 20px;
        margin-left: -2px;
        margin-right: 10px;

        img
        {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            display: block;
        }

        &::after
        {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            z-index: 2;
        }
    }
</style>
