<template>
    <div class="integrate-overview flex flex-column">
        <div class="integrate-overview-header">
            <div class="logo" v-if="state.context.application.icon_url">
                <img :src="state.context.application.icon_url" />
            </div>
        </div>
        <div class="ff flex flex-align flex-column integrate-overview-body">
            <h1 class="center">
                <span class="application-title">{{state.context.application.name}}</span>
                by {{state.context.application.team.name}}
                would like to integrate with your school.
            </h1>
            <div class="integration-features flex flex-column flex-align">
                <div class="integration-feature flex flex-align">
                    <span class="icon iconoir-people-rounded block"></span>
                    <div class="ff">You must be an administrator.</div>
                </div>
                <div class="integration-feature flex flex-align">
                    <span class="icon iconoir-cursor-pointer block"></span>
                    <div class="ff">There are helpful video guides in case you get stuck.</div>
                </div>
                <div class="integration-feature flex flex-align">
                    <span class="icon iconoir-timer block"></span>
                    <div class="ff">Takes about 5 minutes from start to finish.</div>
                </div>
            </div>
            <div class="flex flex-center">
                <div class="button" @click="next" kbd="Enter ⏎">Get Started</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateApplication',
        props: {
            state: Object,
            send: Function
        },
        mounted(){
            window.addEventListener('keydown', this.keydown);
        },
        destroyed(){
            window.removeEventListener('keydown', this.keydown);
        },
        methods: {
            next(){
                this.send('ACCOUNT');
            },
            keydown(e){
                if(e.code === 'Enter'){
                    this.next();
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-overview.integrate-state
    {
        position: fixed;
        width: 100vw;
        height: 100vh;
        max-width: none;

        .integrate-overview-header
        {
            height: 25%;
            min-height: 200px;
            background: @f4;
        }

        .integrate-overview-body
        {
            padding-top: 110px;
        }

        .explanation
        {
            max-width: 500px;
            padding: 35px 30px 30px;
            margin-bottom: 30px;

            &.pad
            {
                padding: 80px 30px 30px;
            }
        }

        .logo
        {
            width: 120px;
            height: 120px;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 30px -2px rgba(0, 0, 0, 0.3);//, 0 0 2px rgba(0, 0, 0, 0.1);//, 0 0 0 1px rgba(0, 0, 0, 0.08);
            background: @f;
            position: absolute;
            bottom: -60px;
            left: 0;
            right: 0;
            margin: 0 auto;

            img
            {
                width: 120px;
                height: 120px;
                object-fit: cover;
            }
        }
        
        h1
        {
            font-size: 22px;
            line-height: 38px;
            letter-spacing: -0.02rem;
            padding: 0 20px;
            max-width: 600px;

            .application-title
            {
                color: @base;
                padding: 0 10px;
                border-radius: 3px;
                background: @f4;
                display: inline-block;
            }
        
            .verified
            {
                background: url('~@/assets/icons/black/verified.svg') no-repeat;
                background-position: right 0 top 9px;
                background-size: 18px auto;
                height: 34px;
                display: inline-block;
                padding-right: 24px;
            }
        }

        .integration-features
        {
            margin: 30px 0 40px;
        }

        .integration-feature
        {
            font-size: 14px;
            color: @grey;
            line-height: 40px;

            .icon
            {
                font-size: 22px;
                margin: 0 10px 0 0;
            }
        }
    }
</style>
