<template>
    <div class="custom-admin-terms flex flex-column flex-align">
        <template v-if="phase === 'password'">
            <div class="password-container bc">
                <form @submit.stop.prevent="activate">
                    <h1>Enter your password</h1>
                    <p>Looks like you already have an Edlink account. Enter your Edlink password to connect it with your Clever identity.</p>
                    <input class="password-input" ref="password" type="password" placeholder="Enter your password..." autocomplete="false" v-model="password" @keydown.stop="() => {}" />
                    <div class="form-error" v-if="error">{{error}}</div>
                </form>
                <div class="form-options flex flex-align">
                    <div class="button" kbd="Enter ⏎" :class="{loading: attempting}" @click="activate">Confirm</div>
                </div>
            </div>
        </template>
        <template v-if="phase === 'mfa'">
            <div class="two-factor-container bc">
                <form @submit.stop.prevent="activate">
                    <!-- <h1>Welcome back {{ user.first_name }}</h1> -->
                    <h1>Two-factor authentication</h1>
                    <p>Looks like you have an account already with two-factor enabled. Enter the six digit code generated by your authenticator.</p>
                    <!-- <h3>Enter the six digit code generated by your app</h3> -->
                    <div class="flex flex-align two-factor-inputs" ref="digits">
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="one" v-model="digits.one" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="two" v-model="digits.two" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="three" v-model="digits.three" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="four" v-model="digits.four" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="five" v-model="digits.five" type="text" :class="{error: error.two}" />
                        <input :disabled="attempting" @paste.prevent="paste" @keydown="keydown" digit="six" v-model="digits.six" type="text" :class="{error: error.two}" />
                    </div>
                    <!-- <div class="form-error" v-if="error.two">There was an error with your two factor authentication code. Please contact us if you need assistance.</div> -->
                </form>
                <div class="form-options flex flex-align">
                    <div class="button" kbd="Enter ⏎" :class="{loading: attempting}" @click="activate">Confirm</div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
    export default {
        name: 'CustomAdminChallenge',
        props: {
            state: Object,
            send: Function
        },
        data() {
            return {
                phase: null,
                password: '',
                digits: {
                    one: '',
                    two: '',
                    three: '',
                    four: '',
                    five: '',
                    six: ''
                },
                error: '',
                attempting: false
            }
        },
        computed: {
            code(){
                return this.digits.one + this.digits.two + this.digits.three + this.digits.four + this.digits.five + this.digits.six;
            },
            user(){
                return this.$store.getters.user;
            },
            conditions(){
                return this.state.context.token_activation_conditions ?? [];
            }
        },
        created(){
            if(this.conditions.includes('login_required')){
                // Show the password phase.
                this.phase = 'password';
            }else if(this.conditions.includes('mfa_required')){
                // Show the MFA phase.
                this.phase = 'mfa';
            }else{
                // This should never happen at the moment.
            }
        },
        methods: {
            activate() {
                this.attempting = true;

                if(this.phase === 'password'){
                    if(this.conditions.includes('mfa_required')){
                        this.phase = 'mfa';
                        return;
                    }
                }

                setTimeout(async () => {
                    await this.$http.post('/challenge', {
                        password: this.password,
                        code: this.code
                    })
                    .then(async () => {
                        this.$store.dispatch('user/login');

                        await this.$http.get(`/teams/${this.state.context.team_id}`).then(response => {
                            this.send({type: 'TEAM', data: response.$data});
                        });

                        await this.$http.get(`/teams/${this.state.context.team.id}/sources`).then(response => {
                            this.send({type: 'SOURCES', data: response.$data});
                        });

                        this.send('NEXT');
                    })
                    .catch(({ $error }) => {
                        this.attempting = false;

                        if(this.phase === 'password'){
                            // Focus the last password field.
                            this.$nextTick(() => {
                                this.$refs.password.focus();
                            });
                        }else if(this.phase === 'mfa'){
                            // Focus the last input field.
                            this.$nextTick(() => {
                                this.$refs.digits.lastChild.focus();
                            });
                        }

                        this.error = $error ? $error : 'There was an error logging you in.';
                    });
                }, 150);
            },
            paste(e){
                const value = e.clipboardData.getData('text')?.trim();

                if(value.length === 6){
                    const digits = value.split('');

                    this.digits.one = digits[0];
                    this.digits.two = digits[1];
                    this.digits.three = digits[2];
                    this.digits.four = digits[3];
                    this.digits.five = digits[4];
                    this.digits.six = digits[5];
                    
                    // Attempt the login.
                    this.activate();
                }
            },
            keydown(e){
                const digit = e.target.getAttribute('digit');

                if(e.keyCode === 39){
                    if(e.target.nextElementSibling){
                        e.target.nextElementSibling.focus();
                    }

                    e.preventDefault();
                }else if(e.keyCode === 37){
                    if(e.target.previousElementSibling){
                        e.target.previousElementSibling.focus();
                    }

                    e.preventDefault();
                }else if(e.keyCode === 8){
                    if(e.target.value.length === 0){
                        if(e.target.previousElementSibling){
                            e.target.previousElementSibling.focus();

                            const previous = e.target.previousElementSibling.getAttribute('digit');

                            // Clear the previous digit because this one is already cleared.
                            this.digits[previous] = '';
                        }
                    }else{
                        this.digits[digit] = '';
                    }

                    e.preventDefault();
                }else if('0123456789'.includes(e.key)){
                    if(e.target.value.length === 0){
                        this.digits[digit] = e.key;
                    }

                    if(e.target.nextElementSibling){
                        e.target.nextElementSibling.focus();
                    }else if(this.code.length === 6){
                        this.activate();
                    }

                    e.preventDefault();
                }else{
                    // This was just a regular keypress.
                    // The only keypresses we want to block are non-modified ones.
                    if(!e.metaKey && !e.ctrlKey && !e.altKey){
                        e.preventDefault();
                    }
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .two-factor-container, .password-container
    {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;

        p {
            font-size: 14px;
            font-weight: 400;
            color: @grey;
            line-height: 18px;
        }
    }

    .password-input
    {
        margin: @double-padding 0 0;
    }

    .two-factor-inputs
    {
        justify-content: space-between;
        margin-top: 40px;

        input
        {
            width: 54px;
            height: 64px;
            text-align: center;
            font-size: 36px;
            line-height: 28px;
            padding: 10px 8px;
        }
    }
</style>
