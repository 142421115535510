<template>
    <div class="invitations">
        <div class="card invitation flex flex-align" v-for="invitation of invites" :key="invitation.id">
            <div class="team-profile flex flex-align flex-center">
                <span class="icon iconoir-group block"></span>
            </div>
            <div class="ff">
                <div class="team-title text-overflow">{{invitation.team.name}}</div>
                <div class="team-description text-overflow">
                    Invited by {{invitation.user.first_name}} {{invitation.user.last_name}}
                </div>
            </div>
            <spinner v-if="invitation.attempting" />
            <template v-else>
                <div v-if="invitation.accepted" class="text-button disabled">Joined</div>
                <div v-else-if="invitation.rejected" class="text-button disabled">Ignored</div>
                <template v-else>
                    <a class="text-button" @click="accept(invitation)">Accept Invitation</a>
                    <a class="text-button red" @click="ignore(invitation)">Reject</a>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Invitations',
    props: {
        invites: Array
    },
    methods: {
        accept(invitation){
            invitation.attempting = true;

            this.$http.post(`/invitations/${invitation.id}/accept`)
            .then(() => {
                invitation.attempting = false;
                invitation.accepted = true;

                this.$toasted.success('Accepted invitation.');
                this.$store.dispatch('user/invitations');

                this.$http.get(`/teams/${invitation.team.id}`)
                .then(response => this.$store.dispatch('teams/add', response.$data))
                .catch(error => this.$toasted.error('There was an error retrieving your newly joined team.'));
            })
            .catch(() => {
                invitation.attempting = false;
                this.$toasted.error('There was an error accepting this invitation.');
            });
        },
        ignore(invitation){
            invitation.attempting = true;

            this.$http.post(`/invitations/${invitation.id}/reject`)
            .then(() => {
                invitation.attempting = false;
                invitation.rejected = true;
                this.$store.dispatch('user/invitations');
            })
            .catch(() => {
                invitation.attempting = false;
                this.$toasted.error('There was an error ignoring this invitation.');
            });
        }
    }
}
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .invitation
    {
        padding: 15px;
        margin: 15px 0;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .invitation-profile
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: @e;
        margin-right: 15px;

        .spinner
        {
            margin-right: 10px;
        }
    }

    .invitation-title
    {
        line-height: 16px;
        font-size: 14px;
        color: @black;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .invitation-description
    {
        font-size: 12px;
        line-height: 14px;
    }

    .team-profile
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: fade(@base, 20%);
        color: @base;
        font-size: 18px;
        margin-right: 15px;
    }

    .team-title
    {
        line-height: 16px;
        font-size: 14px;
        color: @black;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .team-description
    {
        font-size: 12px;
        line-height: 14px;
    }

    .spinner
    {
        margin-right: 10px;
    }

    .text-button
    {
        margin-left: 20px;
    }
</style>
