<template>
    <div class="application-overview scroll full" v-if="application">
        <section>
            <h2>Application Overview</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Name</div>
                    <div class="summary-value">{{application.name}}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">SSO Protocol</div>
                    <div class="summary-value">{{protocol}}</div>
                </div>
                <div class="summary-field flex flex-align multiline">
                    <div class="summary-key">Providers</div>
                    <div class="summary-values flex flex-column">
                        <div class="summary-value">
                            {{application.valid_source_providers.length}} Data Provider{{application.valid_source_providers.length === 1 ? '' : 's'}} Configured
                        </div>
                        <router-link class="text-button mini" :to="{name: 'team.applications.application.providers'}">Provider Settings</router-link>
                    </div>
                </div>
                <div class="summary-field flex flex-align multiline">
                    <div class="summary-key">Sharing</div>
                    <div class="summary-values flex flex-column">
                        <div class="summary-value">
                            {{application.permissions.length}} Permission{{application.permissions.length === 1 ? '' : 's'}} Configured
                        </div>
                        <router-link class="text-button mini" :to="{name: 'team.applications.application.sharing'}">Data Sharing Settings</router-link>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <h2>Application Keys</h2>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Application ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => application.id" v-clipboard:success="() => $toasted.info('Copied')">{{application.id}}</div>
                </div>
                <div class="summary-field flex flex-align multiline">
                    <div class="summary-key">Valid Redirect URIs</div>
                    <div class="summary-values flex flex-column">
                        <template v-if="origins.length">
                            <div v-for="(origin, index) of origins" :key="index" class="summary-value copyable noshift" v-clipboard="() => origin" v-clipboard:success="() => $toasted.info('Copied')">
                                {{origin}}
                            </div>
                        </template>
                        <div v-else class="summary-value">No Redirect URIs Configured</div>
                        <router-link class="text-button mini" :to="{name: 'team.applications.application.sso'}">Redirect URI Settings</router-link>
                    </div>
                </div>
                <div class="summary-field application-keys flex">
                    <div class="summary-key">Application Secrets</div>
                    <div class="summary-values">
                        <datatable :columns="columns" :rows="application.credentials" :header="true" class="card">
                            <template v-slot:empty>
                                <div class="no-keys-message">Your application does not have application secrets.</div>
                            </template>
                        </datatable>
                        <div class="flex flex-align">
                            <div class="button has-icon base flex flex-align" @click="add_secret">
                                <KeyAltPlus width="16" height="16" stroke-width="2" />
                                <div class="ff">Create Secret Key</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { AlignLeft, Calendar, DeleteCircle, KeyAlt, KeyAltPlus } from '@epiphany/iconoir';
    import AddApplicationSecret from '@/components/modals/AddApplicationSecret';
    import DeleteApplicationSecret from '@/components/modals/DeleteApplicationSecret';
    import PasswordText from '@/components/PasswordText';
    import RemoveIcon from '@/components/RemoveIcon';

    const strategies = {
        oauth: 'OAuth 2.0',
        oidc: 'OpenID Connect',
        lti: 'LTI 1.3'
    };

    export default {
        name: 'ApplicationOverview',
        components: {
            KeyAltPlus
        },
        data(){
            return {
                columns: [
                    {
                        id: 'secret',
                        icon: KeyAlt,
                        name: 'Secret Key',
                        component: PasswordText,
                        value: row => row
                    },
                    {
                        id: 'name',
                        icon: AlignLeft,
                        name: 'Secret Name',
                        value: row => row.name,
                        width: '160px'
                    },
                    {
                        id: 'created_date',
                        name: 'Created On',
                        icon: Calendar,
                        width: '160px',
                        value: row => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'remove',
                        name: '',
                        align: 'center',
                        width: '36px',
                        on: {
                            click: ({row}) => {
                                this.$modal.show(DeleteApplicationSecret,
                                    {
                                        credential: this.$store.getters.application.credentials.find(item => item.id === row.id)
                                    },
                                    {
                                        width: 400,
                                        height: 'auto',
                                        classes: 'modal'
                                    }
                                );
                            }
                        },
                        component: RemoveIcon
                    }
                ],
                statuses: {
                    development: 'Development',
                    awaiting_approval: 'Awaiting Approval',
                    approved: 'Approved',
                    released: 'Released',
                    sunsetting: 'Sunsetting',
                    destroyed: 'Destroyed'
                }
            };
        },
        methods: {
            add_secret() {
                this.$modal.show(AddApplicationSecret,
                    {},
                    {
                        width: 400,
                        height: 'auto',
                        classes: 'modal'
                    }
                );
            },
            toggle(permission){
                this.$store.dispatch('applications/toggle_permission', permission.id);
            },
            status(application){
                return {
                    red: ['sunsetting', 'destroyed'].includes(application.status),
                    yellow: ['awaiting_approval'].includes(application.status),
                    green: ['released'].includes(application.status),
                    grey: ['development'].includes(application.status),
                    purple: ['approved'].includes(application.status)
                };
            },
            save(){
                // this.$store.dispatch('save/save', 'application/overview');
                // setTimeout(() => {
                //     this.$store.dispatch('save/saved', 'application/overview');
                // }, 3000);
            }
        },
        computed: {
            protocol(){
                if(!this.application){
                    return 'Unknown';
                }

                return strategies[this.application.sso_protocol];
            },
            origins(){
                return this.application.origin.split('\n').filter(origin => origin.trim().length);
            },
            application(){
                return this.$store.getters.application;
            },
            permissions(){
                return this.$store.getters.permissions.slice().sort((a, b) => {
                    if(a.group === b.group){
                        return a.readonly ? -1 : 1;
                    }

                    return a.group < b.group ? -1 : 1;
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .summary
    {
        font-size: 13px;

        .summary-field
        {
            height: 22px;
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }

            &.multiline
            {
                height: auto;
                align-items: flex-start;

                .summary-values
                {
                    align-items: flex-start;
                }
            }
        }

        .summary-key
        {
            color: @grey;
            width: 180px;
            flex-basis: 180px;
            flex-shrink: 0;
            line-height: 22px;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            &.monospace
            {
                line-height: 22px;
                font-size: 13px;
            }
        }

        .application-keys
        {
            height: auto;
            margin-top: 15px;

            .summary-values
            {
                max-width: 800px;
            }

            .button.has-icon
            {
                margin-top: 15px;
            }
        }

        .text-button
        {
            font-weight: 400;
            margin-top: 4px;
        }

        .icon-status
        {
            margin-right: 8px;
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    .disconnect-warning
    {
        font-size: 13px;
        line-height: 18px;
        color: @grey;
        max-width: 700px;
    }
</style>