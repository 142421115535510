<template>
    <div class="entity-logs">
        <spinner v-if="loading_logs" />
        <editor v-else :value="result" :readonly="true" :block="null" />
    </div>
</template>

<script>
    import { entities } from '@/constants';

    const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';
    
    export const LEVEL_MAP = {
        0: 'FATAL',
        1: 'ERROR',
        2: 'WARN',
        3: 'INFO',
        4: 'DEBUG',
        5: 'TRACE',
        6: 'SILLY'
    };

    export default {
        name: 'EntityLogs',
        data() {
            return {
                entities,
                result: '',
                loading_logs: true
            };
        },
        created() {
            this.$http.get(`${url}/api/v2/graph/${this.type}/${this.entity.id}/logs`, {
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                }
            }).then(response => {
                this.logs = response.$data;

                if (this.logs.length > 0) {
                    this.result = this.logs.map(log => {
                        const date = new Date(log.log_date);
                        return `[${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}] ${LEVEL_MAP[log.log_level].padEnd(8, ' ')} ${log.log_message}`
                    }).join('\n')
                } else {
                    this.result = 'No logs available.'
                }
            }).catch(_err => {
                this.result = 'Unable to retrieve logs.';
                this.$toasted.error(`Unable to retrieve logs.`);
            }).finally(() => {
                this.loading_logs = false;
            });
        },
        methods: {

        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            loading() {
                return this.$store.state.entities.loading;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity-logs
    {
        color: @grey;
        font-size: 12px;
        height: 100%;
    }

    .drawer-section
    {
        padding: 25px;

        &:first-child
        {
            padding-top: 0;
        }
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 0;
    }

    .editor
    {
        position: absolute;
        top: @drawer-navigation-height;
    }
</style>
