<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <PageFlip class="icon" width="16" height="16" stroke-width="2" />
                    Invoices
                </div>
            </template>
            <template v-slot:actions>
                <input class="filter search large" type="text" placeholder="Search by Invoice Number" v-model="search" />
                <div>
                    <Button title="Generate Invoice" :loading="saving" @click="() => generate_dropdown = true">
                        <template v-slot:icon>
                            <Dollar class="icon" width="16" height="16" stroke-width="2" />
                        </template>
                        <template v-slot:text>
                            Generate Invoice
                        </template>
                    </Button>
                    <dropdown :open="generate_dropdown" :searchable="true" @search="team_source" height="unset" width="250px" ref="generate-invoice">
                        <div class="dropdown-scroll">
                            <dropdown-item v-for="(team, $index) of teams.items" :key="team.id" :active="$index === teams.focused" :muted="true" @click.native="generateInvoice(team.id)">
                                <template v-slot:title>
                                    <div class="flex flex-align">
                                        <div>{{team.name}}</div>
                                        <Verified :verified="team.verified" />
                                    </div>
                                </template>
                            </dropdown-item>
                        </div>
                    </dropdown>
                </div>
                <!-- <div class="button white has-icon" title="Export Invoices" @click="save()">
                    <span class="icon iconoir-database-export"></span>
                    Download
                </div> -->
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="teams.items" label="Team" :active="team" @search="team_source" :searchable="true" @activate="switch_team">
                <template v-slot:icon>
                    <TwitterVerifiedBadge class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-name">{{item.name}}</div>
                        <Verified :verified="item.verified" />
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="states" :active="state" @activate="switch_state" label="State">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>

            <filter-dropdown :items="in_xero_enums" :active="in_xero" @activate="switch_in_xero" label="In Xero">
                <template v-slot:icon>
                    <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <!-- <filter-dropdown custom borderless :active="active_date" @activate="change_date" label="Date Range">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:custom="{ activate }">
                    <date-picker @input="activate" v-model="date" v-bind="{
                        type: 'date',
                        format: 'MM-DD-YYYY',
                        range: true,
                        inline: true,
                        'range-separator': '-',
                        shortcuts
                    }">
                    </date-picker>
                </template>
            </filter-dropdown> -->

            <filter-dropdown custom borderless :active="issued_date" @activate="change_issued" label="Issued Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:custom="{ activate }">
                    <date-picker @input="activate" v-model="issued" v-bind="{
                        type: 'date',
                        format: 'MM-DD-YYYY',
                        range: true,
                        inline: true
                    }">
                    </date-picker>
                </template>
            </filter-dropdown>

            <filter-dropdown custom borderless :active="due_date" @activate="change_due" label="Due Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:custom="{ activate }">
                    <date-picker @input="activate" v-model="due" v-bind="{
                        type: 'date',
                        format: 'MM-DD-YYYY',
                        range: false,
                        inline: true
                    }">
                    </date-picker>
                </template>
            </filter-dropdown>

            <!-- <filter-dropdown custom borderless :active="active_time" label="Time Range">
                <template v-slot:custom>
                    <date-picker @input="reset()" v-model="time" v-bind="{
                        type: 'time',
                        format: 'H:mm',
                        range: true,
                        'show-time-header': true,
                        'time-title-format': 'H:mm',
                        inline: true }">
                    </date-picker>
                </template>
            </filter-dropdown> -->

            <div class="ff"></div>
            <pages :all="all" :rows="invoices" :more="more" :count="count" :page="page" @next="next" @previous="previous" />
        </filters>
        <datatable class="invoices full" :columns="columns" :rows="invoices" :selectable="true" :header="true" @clicked="row => open(row.id)" :loading="loading" :clickable="true" />
    </main>
</template>

<script>
    import { TwitterVerifiedBadge, Calendar, WhiteFlag, PageFlip, Dollar, Mail, CloudSync } from '@epiphany/iconoir';
    import parser from 'papaparse';
    import Options from '@/components/modals/Options.vue';
    import Chip from '@/components/chips/Chip.vue';
    import InvoiceState from '@/components/chips/InvoiceState.vue';
    import Verified from '@/components/chips/Verified.vue';
    import { Decimal } from 'decimal.js';
    import _ from 'lodash';
    import GenerateInvoice from '@/components/modals/GenerateInvoice.vue';
    import Button from '@/components/Button.vue';
    import * as d3 from 'd3';
    import Invoice from '@/components/drawers/Invoice';

    export default {
        name: 'AdminInvoices',
        components: {
            Calendar,
            WhiteFlag,
            PageFlip,
            TwitterVerifiedBadge,
            Dollar,
            Button,
            Verified,
            CloudSync
        },
        data(){
            return {
                page: 0,
                count: 50,
                all: [],
                more: null,
                loading: false,
                saving: false,
                generate_dropdown: false,
                search: '',
                search_timeout: null,
                date: null,
                issued: null,
                due: null,
                time: null,
                region: null,
                shortcuts: [
                    { text: 'Today', onClick: () => {
                            const date = new Date();
                            date.setHours(0);
                            date.setMinutes(0);
                            return [date, new Date()]
                        }
                    },
                    {
                        text: 'Last 24 hrs',
                        onClick: () => {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Week',
                        onClick: () => {
                            const date = new Date();
                            date.setDate(date.getDate() - 7);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Month',
                        onClick: () => {
                            const date = new Date();
                            date.setMonth(date.getMonth() - 1);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Year',
                        onClick: () => {
                            const date = new Date();
                            date.setFullYear(date.getFullYear() - 1);
                            return [date, new Date()];
                        },
                    }
                ],
                state: null,
                states: [
                    {
                        id: 'draft',
                        name: 'Draft',
                        icon: 'yellow'
                    },
                    {
                        id: 'submitted',
                        name: 'Submitted',
                        icon: 'blue'
                    },
                    {
                        id: 'sent',
                        name: 'Sent',
                        icon: 'blue'
                    },
                    {
                        id: 'paid',
                        name: 'Paid',
                        icon: 'green'
                    },
                    {
                        id: 'overdue',
                        name: 'Overdue',
                        icon: 'red'
                    },
                    {
                        id: 'voided',
                        name: 'Voided',
                        icon: 'grey'
                    },
                    {
                        id: 'refunded',
                        name: 'Refunded',
                        icon: 'purple'
                    }
                ],
                in_xero: null,
                in_xero_enums: [
                    {
                        id: 'is known',
                        name: 'Yes',
                        icon: 'green'
                    },
                    {
                        id: 'is unknown',
                        name: 'No',
                        icon: 'grey'
                    }
                ],
                team: null,
                teams: {
                    all: [],
                    items: null,
                    promise: null,
                    query: '',
                    focused: null
                },
                columns: [
                    {
                        id: 'team.name',
                        name: 'Team',
                        icon: TwitterVerifiedBadge,
                        width: '120px'
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        component: InvoiceState,
                        width: '50px',
                        value: row => ({ state: row.state })
                    },
                    {
                        id: 'number',
                        name: 'Number',
                        classes: 'monospace',
                        icon: Mail,
                        width: '50px'
                    },
                    {
                        id: 'xero_id',
                        name: 'In Xero',
                        classes: 'monospace',
                        icon: CloudSync,
                        component: Chip,
                        width: '35px',
                        value: row => (row.xero_id ? { title: 'Yes', color: 'green' } : { title: 'No', color: 'grey' })
                    },
                    {
                        id: 'total',
                        name: 'Total',
                        classes: 'monospace',
                        align: 'right',
                        icon: Dollar,
                        width: '50px',
                        value: (row) => {
                            if (row?.overrides?.line_items) {
                                const total = row.overrides.line_items.reduce((acc, item) => {
                                    const quantity = new Decimal(item.quantity);
                                    const unit_cost = new Decimal(item.unit_cost);
                                    return acc.plus(quantity.times(unit_cost));
                                }, new Decimal(0));

                                return this.$options.filters.money(total.toDecimalPlaces(2).toNumber());
                            } else if (row.total) {
                                return this.$options.filters.money(new Decimal(row.total).toDecimalPlaces(2).toNumber());
                            } else {
                                const total = row.line_items.reduce((acc, item) => {
                                    const quantity = new Decimal(item.quantity);
                                    const unit_cost = new Decimal(item.unit_cost);
                                    return acc.plus(quantity.times(unit_cost));
                                }, new Decimal(0));

                                return this.$options.filters.money(total.toDecimalPlaces(2).toNumber());
                            }
                        }
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '50px',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    },
                    {
                        id: 'period_start_date',
                        name: 'Period Start',
                        icon: Calendar,
                        align: 'right',
                        width: '50px',
                        value: row => this.$options.filters.pretty(row.period_start_date, 'date')
                    },
                    {
                        id: 'period_end_date',
                        name: 'Period End',
                        icon: Calendar,
                        align: 'right',
                        width: '50px',
                        value: row => this.$options.filters.pretty(row.period_end_date, 'date')
                    },
                    {
                        id: 'issued_date',
                        name: 'Issued Date',
                        icon: Calendar,
                        align: 'right',
                        width: '15%',
                        value: row => this.$options.filters.pretty(row.issued_date, 'date')
                    },
                    {
                        id: 'due_date',
                        name: 'Due Date',
                        icon: Calendar,
                        align: 'right',
                        width: '15%',
                        value: row => this.$options.filters.pretty(row.due_date, 'date')
                    },
                ]
            };
        },
        computed: {
            invoices(){
                return this.all.slice(this.page * this.count, this.page * this.count + this.count);
            },
            active_date(){
                if (this.date && Array.isArray(this.date) && this.date.length) {
                    const [start, end] = this.date;
                    return {
                        name: `${this.$options.filters.pretty(start, 'short')} - ${this.$options.filters.pretty(end, 'short')}`
                    };
                } else {
                    return null;
                }
            },
            issued_date(){
                if (this.issued) {
                    return {
                        name: `${this.$options.filters.pretty(this.issued[0], 'short')} - ${this.$options.filters.pretty(this.issued[1], 'short')}`
                    };
                } else {
                    return null;
                }
            },
            due_date(){
                if (this.due) {
                    return {
                        name: `${this.$options.filters.pretty(this.due, 'short')}`
                    };
                } else {
                    return null;
                }
            },
            active_time(){
                if (this.time && this.time.length) {
                    return {
                        name: 'Range Selected'
                    };
                } else {
                    return null;
                }
            },
            user(){
                return this.$store.getters.user;
            }
        },
        created(){
            this.team_source();
        },
        mounted(){
            window.addEventListener('mousedown', this.click_outside);
            window.addEventListener('keydown', this.keydown);
            
            // Check to see if there are query parameters set before we load().
            if(this.$route.query.$filter){
                const params = JSON.parse(this.$route.query.$filter);

                this.teams.promise.then(() => {
                    if (params.team_id) {
                        this.team = this.teams.all.find(team => team.id === params.team_id[0].value);
                    }
                });

                if(params.state){
                    this.state = this.states.find(state => state.id === params.state[0].value);
                }

                if(params.issued_date){
                    const gte = new Date(params.issued_date.find(date => date.operator === 'gte')?.value);
                    const lte = new Date(params.issued_date.find(date => date.operator === 'lte')?.value);

                    // TODO Support for single dates.
                    this.issued = [gte, lte];
                }

                if (params.due_date) {
                    // BUG: conversion to date produces incorrect date.
                    this.due = new Date(params.due_date[0].value);
                }

                if (params.xero_id) {
                    this.in_xero = this.in_xero_enums.find(value => value.id === params.xero_id[0].operator);
                }
            }

            if(window.location.hash){
                const hash = window.location.hash.replace('#', '');
                if (hash.startsWith('invoice/')) {
                    const id = hash.replace('invoice/', '');
                    this.open(id);
                }
            }

            this.load();
        },
        destroyed() {
            window.removeEventListener('mousedown', this.click_outside);
            window.removeEventListener('keydown', this.keydown);
        },
        methods: {
            click_outside(e) {
                if (this.generate_dropdown) {
                    if (this.$refs['generate-invoice'] && !this.$refs['generate-invoice'].$el.contains(e.target)) {
                        this.generate_dropdown = false;
                    }
                }
            },
            keydown(e) {
                if (this.generate_dropdown) {
                    if (e.key === 'Escape') {
                        this.generate_dropdown = false;
                    } else if (e.key === 'ArrowDown') {
                        e.preventDefault();
                        if (this.teams.items.length === 1) {
                            this.teams.focused = 0;
                        } else if (this.teams.focused < this.teams.items.length) {
                            this.teams.focused = this.teams.focused === null ? 0 : this.teams.focused + 1;
                        }
                    } else if (e.key === 'ArrowUp') {
                        e.preventDefault();
                        if (this.teams.focused > 0) {
                            this.teams.focused = this.teams.focused === null ? 0 : this.teams.focused - 1;
                        }
                    } else if (e.key === 'Enter') {
                        const team = this.teams.items[this.teams.focused];
                        if (team) {
                            this.generate_dropdown = false;
                            this.generateInvoice(team.id);
                        }
                    }
                }
            },
            open(invoice_id){
                window.location.hash = `#invoice/${invoice_id}`;

                this.$store.dispatch('drawer/open', {
                    key: 'invoice',
                    width: 1200,
                    component: Invoice,
                    props: {
                        invoice_id
                    }
                });
            },
            save() {
                const _this = this;

                this.$modal.show(Options, {
                    title: `Download Invoices`,
                    description: 'Some info here.',
                    options: [
                        {
                            title: `Download`,
                            color: 'base',
                            async fn() {
                                const data = await _this.export_load();
                                const date = new Date().toJSON().slice(0,10);
                                const filename = date + ' Invoices.csv';

                                //csv columns -> id,method,path,date,status,start_date,end_date,output,input,integration_id
                                const rows = [
                                    ['date', 'id', 'method', 'path', 'status', 'start_date', 'end_date', 'output', 'input', 'integration_id']
                                ];
                                    
                                for (const log of data.$data) {
                                    rows.push([
                                        log.date,
                                        log.id,
                                        log.method,
                                        log.path,
                                        log.status,
                                        log.start_date,
                                        log.end_date,
                                        JSON.stringify(log.output),
                                        JSON.stringify(log.input),
                                        log.integration_id
                                    ]);                    
                                }

                                const parsed_csv = parser.unparse(rows);
                            
                                const blob = new Blob([parsed_csv], { type: 'text/csv' });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');

                                document.body.appendChild(a);
                                a.href = url;
                                a.download = filename;
                                a.click();

                                setTimeout(() => {
                                    window.URL.revokeObjectURL(url);
                                    document.body.removeChild(a);
                                }, 0);
                            }
                        }
                    ]
                }, { width: 300, height: 'auto', classes: 'modal' });
            },
            team_source(query){
                this.teams.query = query;
                this.teams.focused = null;
                
                if(!this.teams.promise){
                    this.teams.promise = this.$http.get(`/admin/teams`, {
                        params: {
                            $filter: {
                                type: [{
                                    operator: 'equals',
                                    value: 'developer'
                                }],
                                status: [{
                                    operator: 'equals',
                                    value: 'active'
                                }]
                            },
                            $first: 10000
                        }
                    }).then(response => {
                        this.teams.all = response.$data.map(team => {
                            return {
                                id: team.id,
                                name: team.name,
                                verified: team.verified,
                                picture: null
                            };
                        })
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
                
                return this.teams.promise.then(() => {
                    if(this.teams.query){
                        this.teams.items = this.teams.all.filter(team => {
                            return team.name.toLowerCase().indexOf(this.teams.query.toLowerCase()) > -1;
                        }).slice(0, 50);
                    }else{
                        this.teams.items = this.teams.all.slice(0, 50);
                    }
                });
            },
            switch_team(team){
                this.team = team;
                this.reset();
            },
            switch_state(state){
                this.state = state;
                this.reset();
            },
            switch_in_xero(value){
                this.in_xero = value;
                this.reset();
            },
            change_date(date){
                this.date = date;
                this.reset();
            },
            change_issued(date){
                this.issued = date;
                this.reset();
            },
            change_due(date){
                this.due = date;
                this.reset();
            },
            previous(){
                if(this.loading){
                    return;
                }

                this.page = this.page ? this.page - 1 : 0;
            },
            next(){
                if(this.loading){
                    return;
                }

                if(this.all.length <= (this.page + 1) * this.count){
                    // Wait until the page is done loading to increment the page number.
                    this.load().then(() => this.page += 1);
                }else{
                    this.page += 1;
                }
            },
            reset(){
                this.all = [];
                this.more = false;
                this.page = 0;
                this.load();
            },
            load(){
                if(this.loading){
                    return;
                }

                this.loading = true;

                return this.teams.promise.then(() => {
                    const params = {
                        $last: this.count,
                        // $fields: 'id',
                        $filter: {
                        }
                    };
                    
                    if (this.search) {
                        // params.$filter.id = [{
                        //     operator: 'equals',
                        //     value: this.search
                        // }];
                        params.$filter.number = [{
                            operator: 'contains',
                            value: this.search
                        }];
                    }

                    if(this.team){
                        params.$filter.team_id = [{
                            operator: 'equals',
                            value: this.team.id
                        }];
                    }

                    if(this.state){
                        params.$filter.state = [{
                            operator: 'equals',
                            value: this.state.id
                        }];
                    }

                    if (this.in_xero) {
                        params.$filter.xero_id = [{
                            operator: this.in_xero.id
                        }];
                    }

                    if(this.all.length){
                        params.$before = this.all[this.all.length - 1].id;
                    }

                    if (this.issued && Array.isArray(this.issued)) {
                        if (this.issued.length) {
                            params.$filter.issued_date = [
                                {
                                    operator: 'gte',
                                    value: d3.utcDay.floor(this.issued[0]).toISOString()
                                },
                                {
                                    operator: 'lte',
                                    value: d3.utcDay.ceil(this.issued[1]).toISOString()
                                }
                            ];
                        }
                    } else if (this.issued) {
                        const start = d3.utcDay.floor(new Date(this.issued));
                        const end = d3.utcDay.ceil(start);

                        params.$filter.issued_date = [
                            {
                                operator: 'gte',
                                value: start.toISOString()
                            },
                            {
                                operator: 'lte',
                                value: end.toISOString()
                            }
                        ];
                    }

                    if (this.due && Array.isArray(this.due)) {
                        this.due = this.due.filter(el => el);

                        if (this.due.length) {
                            params.$filter.due_date = [
                                {
                                    operator: 'gte',
                                    value: d3.utcDay.floor(this.due[0]).toISOString()
                                },
                                {
                                    operator: 'lte',
                                    value: d3.utcDay.ceil(this.due[1]).toISOString()
                                }
                            ];
                        }
                    } else if (this.due) {
                        const start = d3.utcDay.floor(new Date(this.due));
                        const end = d3.utcDay.ceil(start);

                        params.$filter.due_date = [
                            {
                                operator: 'gte',
                                value: start.toISOString()
                            },
                            {
                                operator: 'lte',
                                value: end.toISOString()
                            }
                        ];
                    }

                    if(this.date && Array.isArray(this.date)) {
                        this.date = this.date.filter(el => el);

                        if (this.date.length) {
                            params.$filter.created_date = [
                                {
                                    operator: 'gte',
                                    value: this.date[0].toISOString()
                                },
                                {
                                    operator: 'lte',
                                    value: this.date[1].toISOString()
                                }
                            ];
                        }
                    } else {
                        // TODO: Maybe filter by singular date gt/lt?
                    }

                    // Push the state to the browser history.
                    let $filter = {};

                    try {
                        $filter = JSON.parse(this.$route.query.$filter);
                    }catch(error){
                        // Do nothing.
                    }

                    if(!_.isEqual($filter, params.$filter)){
                        this.$router.replace({
                            query: {
                                $filter: JSON.stringify(params.$filter)
                            }
                        });
                    }

                    return this.$http.get(`/admin/billing/invoices`, {
                        params
                    })
                    .then(response => {
                        // Add these new invoices to the list of all invoices.
                        this.all.push(...response.$data);

                        // We don't really care about the cursor right now, we just want to know if there are more results.
                        if (response.$data.length < this.count) {
                            this.more = false;
                        } else {
                            this.more = true;
                        }
                    })
                    .catch(error => console.log(error))
                    // .catch(error => this.$toasted.error('There was an error loading the invoices for your applications.'))
                    .finally(() => this.loading = false);
                });
            },
            // export_load() {
            //     if (this.loading) {
            //         return;
            //     }

            //     this.loading = true;                

            //     const params = {
            //         $last: this.count,
            //         $fields: 'id',
            //         $filter: {
            //         }
            //     };

            //     params.$last = 500; //the amount of logs we want to let them download

            //     return this.$http
            //         .get(`/teams/${this.$store.getters.team.id}/requests`, {
            //             params,
            //             baseURL: '/api/v2'
            //         })
            //         .then((response) => {
            //             //returns all 500 (or less) rows
            //             return response;
            //         })
            //         .catch((error) => this.$toasted.error('There was an error loading the requests for your applications.'))
            //         .finally(() => (this.loading = false));
            // },
            generateInvoice(team_id) {
                const _this = this;
                this.generate_dropdown = false;
                this.teams.query = '';

                this.$modal.show(GenerateInvoice, {
                    generate(start, end){
                        // TODO: Support end date.

                        if (!_.isDate(start)) {
                            _this.$toasted.error('Invalid date.');
                            return;
                        }

                        const month = start.getMonth() + 1;
                        const year = start.getFullYear();

                        if(isNaN(month) || isNaN(year)){
                            _this.$toasted.error('Invalid month/year.');
                            return;
                        }

                        _this.saving = true;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                _this.$http.post(`/admin/billing/invoices/generate`, {
                                    team_id,
                                    month,
                                    year
                                }).then(response => {
                                    _this.$toasted.success('Successfully requested invoice generation.');
                                    resolve(response);
                                }).catch(error => {
                                    _this.$toasted.error('There was an error requesting generation of the invoice.');
                                    reject(error);
                                }).finally(() => {
                                    _this.saving = false;
                                });
                            }, 500);
                        });
                    }
                }, {width: 300, height: 'auto', classes: 'modal'});                
            }
        },
        watch: {
            search(){
                this.loading = true;
                if (this.search_timeout) {
                    clearTimeout(this.search_timeout);
                }
                this.search_timeout = setTimeout(() => {
                    this.loading = false;
                    this.reset()
                }, 500);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .button {
        &.loading {
            pointer-events: none;
            color: transparent;
        }

        .spinner-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            ::v-deep .spinner {
                color: @f8;

                .rotation {
                    .path {
                        stroke: @f8;
                    }
                }
            }
        }
    }

    .dropdown-scroll {
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .filters
    {
        top: @breadcrumbs-height;
    }

    .invoices.full
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .request
    {
        user-select: none;
        cursor: pointer;
    }

    .request-status
    {
        width: 90px;
    }

    .request-date
    {
        width: 300px;
        font-size: 12px;
    }

    .request-path
    {
        font-weight: 400;
        font-size: 12px;
        max-width: 400px;
        color: @grey;

        span
        {
            color: @black;
            font-weight: 500;
        }
    }

    .request-status-badge
    {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        color: @green;
        font-family: @monospace;

        &.error
        {
            color: @red;
        }
    }

    .request-log-options
    {
        padding: 20px 0 0;
        user-select: none;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .request-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;

        &.red
        {
            background: @red;
        }

        &.blue
        {
            background: @base;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.purple
        {
            background: @purple;
        }

        &.grey
        {
            background: @grey;
        }
    }

    .thumbnail
    {
        width: 16px;
        height: 16px;

        img
        {
            border-radius: 50%;
        }        

        &.verified {
            background: url('~@/assets/icons/black/verified.svg') no-repeat;
            background-position: right 1px top 5px;
            background-size: 14px auto;
            background-position: left 1px top 1px;

            img {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        &::after
        {
            width: 16px;
            height: 16px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }

    .invoices-loading
    {
        height: 200px;
    }

    .nothing
    {
        margin-top: 20px;
    }
</style>