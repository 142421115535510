module.exports = (number, abbreviate) => {
    if (number) {
        if (abbreviate) {
            if (number >= 1_000_000_000) {
                return '$' + (number / 1_000_000_000).toFixed(0).replace(/\.0$/, '') + 'B';
            }

            if (number >= 1_000_000) {
                return '$' + (number / 1_000_000).toFixed(0).replace(/\.0$/, '') + 'M';
            }

            if (number >= 100_000) {
                return '$' + (number / 1_000).toFixed(0).replace(/\.0$/, '') + 'K';
            }
        }

        const [int, dec] = number.toFixed(2).split('.');

        return '$' + int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (dec ? `.${dec}` : '');
    }

    return 0;
};
