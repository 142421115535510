<template>
    <div class="guide flex flex-column">
        <div class="guide-header flex flex-align">
            <img class="user-icon" src="https://avatars.githubusercontent.com/u/79727417?v=4"/>
            <h4>Eddie Lynx</h4>
            <div class="ff"></div>
            <div class="erase flex flex-align flex-center" @click="clear">
                <Erase class="icon" width="16" height="16" stroke-width="2" />
            </div>
            <div class="button white square flex flex-align flex-center" @click="hide">
                <Cancel class="icon" width="16" height="16" stroke-width="2" />
            </div>
        </div>
        <div class="guide-warning center">
            Eddie Lynx is currently in beta. If you receive any strange or incorrect answers, please let us know.
            Do not use any code that Eddie writes without thoroughly reviewing it.
        </div>
        <div class="guide-body ff flex flex-column" ref="scroll" id="scroll">
            <template v-if="history.length">
                <div class="message flex" v-for="(msg, $index) in history" :class="{ [msg.type]: true }" :key="$index">
                    <template>
                        <img v-if="first($index) && msg.type === 'other'" class="user-icon" src="https://avatars.githubusercontent.com/u/79727417?v=4"/>
                    </template>
                    <div class="chat-bubble first" v-if="msg.message.trim() === ''">
                        <div class="loading">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                    <div v-else :class="{ 'first': first($index) }" class="chat-bubble" :ref="msg.id" v-html="html(msg.message)"></div>
                </div>
            </template>
            <template v-else>
                <div class="full nothing flex flex-align flex-center flex-column">
                    <SeaAndSun class="icon" width="40" height="40" stroke-width="1" />
                    <slot name="empty-message">A clean slate.</slot>
                </div>
            </template>
            <div class="message flex other" v-if="loading">
                <img v-if="history[history.length - 1].type !== 'other'" class="user-icon" src="https://avatars.githubusercontent.com/u/79727417?v=4"/>
                <div class="chat-bubble" :class="{ 'first': history[history.length - 1].type !== 'other' }">
                    <div class="loading">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-to-container flex flex-align flex-center" :class="{ shown: more }">
            <div class="scroll-to pointer flex flex-align" @click="scroll(true)">
                <div>Scroll To Bottom</div>
                <ArrowDown class="icon" width="12" height="12" stroke-width="2" />
            </div>
        </div>
        <div class="chat-input flex flex-align flex-center" :class="{ disabled: loading || typing }">
            <input class="ff" placeholder="Ask Eddie anything..." v-model="message" @keydown.enter="send()" ref="question" :disabled="loading || typing" />
            <spinner v-if="loading || typing" :class="{slow: loading}" />
            <div class="button square send-button flex flex-align flex-center" @click="send()" v-else>
                <SendDiagonal class="icon" width="16" height="16" stroke-width="2" />
            </div>
        </div>
    </div>
</template>

<script>
    import showdown from 'showdown';
    import Prism from 'prismjs';
    import { SendDiagonal, Cancel, Erase, ArrowDown, SeaAndSun } from '@epiphany/iconoir';

    // Initialize html converter
    const converter = new showdown.Converter({tables: true, customizedHeaderId: true});

    export default {
        name: 'Guide',
        data(){
            return {
                template: '',
                message: '',
                history: [
                    { id: '0', type: 'other', message: 'Hey! I\'m Eddie the support bot around here. You can treat this chat like texting a friend.'},
                    { id: '1', type: 'other', message: 'I know almost everything there is to know about Edlink and I\'m here to help you! Ask me anything and I\'ll do my best to give you an answer. I can even write code for you!'}
                ],
                log: [],
                typing: false,
                loading: true,
                more: false,
                locked: true,
                socket: null
            };
        },
        components: {
            SendDiagonal,
            Erase,
            Cancel,
            ArrowDown,
            SeaAndSun
        },
        mounted(){
            this.initialize();
            this.focus();

            this.$refs.scroll.addEventListener('scroll', this.calculateScroll);
        },
        unmounted(){
            this.$refs.scroll.removeEventListener('scroll', this.calculateScroll);
        },
        destroyed(){
            this.socket.onclose = () => {};
            this.socket.close();
        },
        methods: {
            html(message){
                return converter.makeHtml(message);
            },
            focus(){
                this.$nextTick(() => {
                    this.$refs?.question.focus();
                });
            },
            scroll(override = false){
                if (this.locked || override) {
                    this.$nextTick(() => {
                        if (this.$refs?.scroll) {
                            this.$refs?.scroll?.scrollTo(0, this.$refs?.scroll?.scrollHeight);
                        }
                    });
                }
            },
            clear(){
                this.history = [];
                this.log = [];
            },
            calculateScroll(){
                const gap = this.$refs.scroll.scrollHeight - this.$refs.scroll.clientHeight - this.$refs.scroll.scrollTop;
                if (gap <= 10) {
                    // If the gap is less than or equal to 10px, lock scroll to bottom
                    this.locked = true;
                } else {
                    // Otherwise, unlock scroll
                    this.locked = false;
                }

                if (gap >= 100) {
                    // If the gap is greater than 100px, show scroll to bottom button
                    this.more = true;
                } else {
                    // Otherwise, hide scroll to bottom button
                    this.more = false;
                }
            },
            initialize(){
                // Reset state
                this.loading = false;
                // Mark as typing to temporarily disable input
                this.typing = true;
                // Create socket
                const socket_url = process.env.NODE_ENV === 'production' ? 'wss://ed.link/ws/v1/eddie' : 'ws://localhost:9900/ws/v1/eddie';
                this.socket = new WebSocket(socket_url);

                this.socket.onclose = (event) => {
                    this.initialize();
                };

                this.socket.onerror = (event) => {
                    this.loading = false;
                    this.typing = false;
                };
                this.socket.onopen = (event) => {
                    // Send a ping to the server to make sure the connection is still alive
                    this.socket.send(JSON.stringify({
                        event: "auth",
                        data: {
                            token: this.$store.getters.user.token
                        }
                    }));
                };

                // Handle incoming messages
                this.socket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    // Grab last message
                    const entry = this.history[this.history.length - 1];
                    if (data.event === 'message') {
                        // Handle message type
                        if (data.type === 'start') {
                            // This is the start of the message. Push it to the history
                            this.history.push({type: 'other', message: data.message});
                            // Mark the bot as typing and not loading
                            this.typing = true;
                            this.loading = false;
                        } else if (data.type === 'stream') {
                            // If this is a stream message, we need to append it to the last message
                            entry.message += data.message;
                            // Scroll to bottom
                            this.scroll();
                        } else if (data.type === 'end') {
                            // If this is an end message
                            const { id, history } = JSON.parse(data.message);
                            if (id) {
                                // We need to update the id of the last message
                                entry.id = id;
                            }
                            if (history) {
                                // And add the history to the log
                                this.log.push(...history);
                            }
                            // Then clear the typing bit
                            this.typing = false;
                            // And focus the input
                            this.focus();
                            // Highlight any code blocks
                            this.$nextTick(() => {
                                const container = this.$refs[entry.id]?.[0];
                                if (container) {
                                    // Add line numbers to code blocks
                                    container.querySelectorAll('pre').forEach((block) => {
                                        block.classList.add('line-numbers');
                                    });
                                    // Highlight code blocks
                                    Prism.highlightAllUnder(container);
                                    // Convert all links to open in a new tab
                                    container.querySelectorAll('a').forEach((link) => {
                                        link.setAttribute('target', '_blank');
                                    });
                                }
                            });
                        } else if (data.type === 'error') {
                            // If this is an error message, we need to append it to the message we added to the history
                            entry.message += data.message;
                            // Scroll to bottom
                            this.scroll();
                            // Mark the bot as not loading and not typing
                            this.loading = false;
                            this.typing = false;
                        }
                    } else if (data.event === 'ready') {
                        this.typing = false;
                    }
                };
            },
            hide(){
                this.$store.dispatch('guide/toggle');
            },
            send(){
                const question = this.message?.trim();
                // If there's no question, don't do anything
                if (!question || question === ''){ return; }
                // Clear input
                this.message = '';
                // Mark as loading
                this.loading = true;
                // Push question to history
                this.history.push({type: 'self', message: question});
                // Scroll to bottom
                this.scroll();
                // Send question to server
                setTimeout(() => {
                    this.socket.send(JSON.stringify({
                        event: "ask",
                        data: {
                            question,
                            history: this.log
                        }
                    }));
                }, 500);
            },
            first(index){
                if (index === 0){ return true; }
                else { return this.history[index - 1].type !== this.history[index].type; }
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    @scroll-to-height: 25px;
    @chat-input-height: 51px;

    .guide {

        .loading {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            // width: 40px;
            border-radius: @border-radius;
            padding: 6px 10px;
            background: @e;

            .dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: darken(@e, 25%);
                margin: 0 2px;
                animation: pulse 1s ease-in-out infinite;

                &:nth-child(1) {
                    animation-delay: 0s;
                }

                &:nth-child(2) {
                    animation-delay: 0.2s;
                }

                &:nth-child(3) {
                    animation-delay: 0.4s;
                }
            }
        }

    }

    .scroll-to-container {
        position: absolute;
        bottom: @chat-input-height - (@scroll-to-height + 5px);
        left: 0;
        right: 0;
        transition: all ease 0.2s;

        &.shown {
            bottom: 52px + 10px;
        }
    }

    .scroll-to {
        // display: none;
        height: @scroll-to-height;
        background: @base;
        color: @white;
        font-size: 12px;
        line-height: @scroll-to-height;
        border-radius: 9999px;
        z-index: 9;
        padding: 0 10px;
        transition: all ease 0.2s;

        .icon {
            margin-left: 3px;
        }

        &:hover {
            background: darken(@base, 5%);
        }
    }

    .erase {
        width: 30px;
        flex-basis: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: @border-radius;
        margin-left: 2px;
        cursor: pointer;
        color: @black;

	    &:hover, &:active
	    {
			background: @e;
	    }
    }

    @keyframes pulse {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }

    .user-icon
    {
        width: 22px;
        height: 22px;
        border-radius: @border-radius - 2px;
        background: @black;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: @f;
        text-align: center;
        line-height: 22px;
    }

    .message {
        margin: 0px 16px 0px;
        max-width: 85%;
        flex-direction: row;
        align-items: flex-start;

        .user-icon {
            margin-right: 6px;
            flex-basis: 22px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .chat-component {
            font-size: 13px;
            line-height: 18px;
            width: 100%;
            margin-left: 28px;
        }

        .chat-bubble {
            // background: @base;
            color: @f;
            border-radius: @border-radius;
            // padding: 6px 10px;
            font-size: 13px;
            line-height: 18px;
            width: 100%;
            margin-left: 28px;
            // margin-right: 50px;

            &.code {
                white-space: pre-wrap;
            }

            &.first {
                margin-left: 0;
            }

            > p,> ul,> ol,> table {
                background: @base;
                border-radius: @border-radius;
                padding: 6px 10px;
                margin-bottom: 2px;
            }

            ul,
            ol {
                padding-left: 20px;

                li {
                    margin-bottom: 6px;
                    list-style: disc;
                }

                p {
                    margin: 10px 0;
                }
            }

            ol {
                li {
                    list-style: decimal;
                }
            }

            li {

                ul,
                ol {
                    margin: 6px 0;
                }
            }
        }
        
        &.self {
            align-self: flex-end;
            flex-direction: row-reverse;

            .chat-bubble {
                // margin-left: 50px;
                margin-right: 30px;

                &.first {
                    margin-right: 0;
                }
            }

            .user-icon {
                margin-right: 0;
                margin-left: 7px;
            }

            & + .other {
                margin-top: 10px;
            }
        }

        &.other {
            align-self: flex-start;

            .chat-bubble {
                // background: @e;
                // color: @black;

                > p,> ul,> ol,> table {
                    background: @e;
                    color: @black;
                }
            }

            & + .self {
                margin-top: 10px;
            }
        }
    }

    .chat-input {
        background: @white;
        border-top: 1px solid @e;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 0 @single-padding 0 16px;

        &.disabled {
            background-color: #f8f8f8;
        }

        input {
            border: none;
            outline: none;
            border-radius: 0;
            height: 51px;
            padding: 0;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .spinner
        {
            margin: 0 5px;
        }
    }

    .guide
    {
        position: fixed;
        top: 0;
        right: 0;
        left: auto;
        bottom: 0;
        border-left: 1px solid @e4;
        width: @guide-width;
        // padding: 25px;

        .guide-header
        {
            padding: 10px 10px 10px 16px;
            height: @breadcrumbs-height;
            border-bottom: 1px solid @e;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
            background: @f;

            h4 {
                font-size: 13px;
            }

            .button, .erase {
                margin-right: 6px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .guide-warning
        {
            height: 82px;
            top: @breadcrumbs-height;
            background: @f8;
            border-bottom: 1px solid @e;
            position: absolute;
            left: 0;
            right: 0;
            padding: 15px @single-padding;
            line-height: 17px;
            font-size: 12px;
            color: @base;
        }

        .guide-body {
            top: @breadcrumbs-height + 82px;
            bottom: 51px;
            overflow-y: auto;
            position: absolute;
            left: 0;
            right: 0;
            padding: 16px 0;

            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
                background: transparent;
            }
        }

        .collapse.text-button.mini
        {
            color: @black;
            display: flex;
            padding-right: 6px;

            .icon
            {
                font-size: 18px;
                height: 18px;
                width: 18px;
                margin-left: 2px;
            }
        }

        h4
        {
            font-weight: 500;
            color: @black;
        }

        .alert
        {
            padding: 20px 15px 15px;
            margin-top: 20px;
            font-size: 12px;
            line-height: 16px;

            .learn-more
            {
                margin-top: 10px;
                font-size: 12px;
                padding-right: 20px;
                background: url('~@/assets/icons/base/external.svg') no-repeat;
                background-position: right -1px top 2px;
                background-size: 16px auto;
            }
        }

        .document
        {
            overflow-y: auto;

            h1
            {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.03rem;
                line-height: 24px;
            }

            h2
            {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0;
                border: 0;
                padding: 0;
                color: @grey;
                margin-top: 20px;
            }

            p
            {
                line-height: 16px;
                margin: 20px 0;
                font-size: 12px;
            }

            li
            {
                line-height: 16px;
                font-size: 12px;
            }

            h1 + p, h2 + p
            {
                margin-top: 6px;
            }
            

            p, ol, ul, div
            {
                transition: all ease 0.2s;
            }

            &[focus]
            {
                p, ol, ul, div
                {
                    color: @lightgrey;
                }
            }

            &[focus="nickname"] .nickname,
            &[focus="application_id"] .application_id,
            &[focus="application_secret"] .application_secret,
            &[focus="url"] .url
            {
                color: @black;
            }
        }

        .nothing
        {
            font-size: 12px;
            border: 0;
            border-radius: 0;
            padding: 30px;
        }
    }

    .chat-bubble {
        p,
        ol,
        ul,
        table {
            code {
                font-size: 12px;
                color: @black;
                background: @d;
                border-radius: 3px;
                font-weight: 400;
                padding: 0 4px;
                line-height: 20px;
                max-width: 100%;
                overflow-x: auto;
                display: inline-block;
                vertical-align: middle;
            }
        }

        strong, b {
            font-weight: 600;
        }

        a {
            // border-bottom: 1px dashed @base;
            color: @base;
        }

        pre, pre[class*="language-"] {
            border-radius: @border-radius;
            background: @f4;
            line-height: 22px;
            padding: 0 0 0 55px;
            margin: 0 0 2px 0;
            overflow-x: auto;

            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
                background: transparent;
            }

            &.first {
                margin-left: 0;
            }

            .line-numbers-rows {
                // top: -1px;
                border-right-color: transparent;
                padding: 10px 0;
                // line-height: 22px;
                // font-size: 14px;
                line-height: 18px;
                font-size: 13px;
                background: darken(@f4, 3%);
                left: -55px;
                width: 45px;

                ::before {
                    color: @grey;
                }
            }

            code[class*="language-"] {
                // line-height: 22px;
                // font-size: 14px;
                line-height: 18px;
                font-size: 13px;
                display: block;
                background: transparent;
                padding: 10px 0;
            }
        }
    }

    .nothing
    {            
        .icon
        {
            margin-bottom: 20px;
        }
    }
</style>