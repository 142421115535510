<template>
    <div class="options">
        <div class="options-title description">Reset Password</div>
        <div class="options-description">Enter a new password for this person. Edlink will not send this password to the person directly so it will be up to you to notify them of the change!</div>
        <div class="options-input">
            <input type="password" v-model="input" @keyup.enter="confirm" @keyup.esc="$emit('close')" ref="input" placeholder="Enter new password" />
            <input type="password" v-model="matching" @keyup.enter="confirm" @keyup.esc="$emit('close')" ref="matching" placeholder="Confirm new password" />
        </div>
        <div class="options-list flex">
            <a class="option ff base" @click="confirm">Reset Password</a>
            <a class="option ff grey" @click="$emit('close')">Cancel</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ResetPasswordEntity',
        props: {
            value: String,
            reset: Function
        },
        data(){
            return {
                input: '',
                matching: ''
            };
        },
        created(){
            this.input = this.value;

            setTimeout(() => {
                this.$refs.input.focus();
            }, 30);
        },
        methods: {
            confirm(){
                if (this.input !== this.matching) {
                    this.$toasted.error('Passwords do not match');
                    return;
                }
                this.reset(this.input);
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    @option_height: 44px;

    .options
    {
        // width: 500px;
    }

    .options-title
    {
        font-size: 14px;
        color: @grey;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        text-align: center;

        &.description
        {
            line-height: 24px;
            padding-top: 15px;
            height: 39px;
        }
    }

    .options-description
    {
        font-size: 13px;
        color: @black;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        padding: 6px 15px 0;
    }

    .option
    {
        display: block;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        border-top: 1px solid @e;
        border-right: 1px solid @e;
        text-align: center;
        font-size: 16px;
        color: @base;
        transition: background-color ease 0.2s;
        cursor: pointer;
        user-select: none;

        &.grey
        {
            color: @grey;
        }

        &.red
        {
            color: @red;
        }

        &:hover, &:active
        {
            background: @f8;
        }

        &:last-child
        {
            border-right: none;
        }
    }

    .options-input
    {
        padding: 15px;

        input {
            margin-bottom: 15px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }
</style>
