<template>
    <div class="entity-value entity-value-grade-level flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="max" />
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value">
                <template v-if="array">
                    <template v-if="value.length">
                        <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{grade_levels[item]}}</div>
                    </template>
                    <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
                    <div v-else class="empty">&mdash;</div>
                </template>
                <div v-else-if="item" class="badge text-overflow">{{grade_levels[item]}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueGradeLevel',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                grade_levels: {
                    'Birth': 'Birth',
                    'Prenatal': 'Prenatal',
                    'IT': 'Infant / Toddler',
                    'PR': 'Preschool',
                    'PK': 'Prekindergarten',
                    'TK': 'Transitional Kindergarten',
                    'KG': 'Kindergarten',
                    '01': 'Grade 1',
                    '02': 'Grade 2',
                    '03': 'Grade 3',
                    '04': 'Grade 4',
                    '05': 'Grade 5',
                    '06': 'Grade 6',
                    '07': 'Grade 7',
                    '08': 'Grade 8',
                    '09': 'Grade 9',
                    '10': 'Grade 10',
                    '11': 'Grade 11',
                    '12': 'Grade 12',
                    '13': 'Grade 13',
                    'PS': 'Postsecondary',
                    'UG': 'Ungraded',
                    'Other': 'Other'
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return this.override ? this.override : this.original;
            },
            override: {
                get(){
                    return _.get(this.$store.state.entities.overrides, this.property);
                },
                set(value){
                    this.$store.commit('entities/override', {property: this.property, value});
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                return Promise.resolve(Object.entries(this.grade_levels).filter(([num, english]) => {
                    return value ? english.toLowerCase().indexOf(value.toLowerCase()) > -1 : true;
                }))
                .then(grade_levels => grade_levels.map(([num]) => num).sort());
            },
            format(value){
                return this.grade_levels[value];
            },
            edit(){
                if(this.editable){
                    if(this.original && !this.override){
                        // Set the default override value to the current property value.
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value: this.original
                        });
                    }else if(this.array && !this.override){
                        // We need to initialize an empty array in order for our autocomplete to work.
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value: []
                        });
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-grade-level
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
