<template>
    <header class="breadcrumbs flex flex-align">
        <slot name="crumbs" />
        <slot name="badges" />
        <div class="ff"></div>
        <slot name="actions" />
        <div class="button white has-icon guide-button tooltip tooltip-bottom" title="Chat With Us" @click="help" :class="{base: hubspot.expanded, disabled: hubspot.loading}" :tooltip="hubspot.expanded ? 'Click to close the live chat window' : 'Chat with someone from Edlink'">
            <spinner class="mini lightgrey" v-if="hubspot.loading" />
            <MultiBubble v-else class="icon" width="16" height="16" stroke-width="2" />
            Help
        </div>
        <template v-if="user.id">
            <div class="button black has-icon guide-button" title="Ask the AI" v-if="!$store.state.guide.expanded" @click="$store.dispatch('guide/toggle')">
                <Atom class="icon" width="16" height="16" stroke-width="2" />
                Ask AI
            </div>
        </template>
        <template v-else>
            <router-link class="button" title="Sign In" :to="{name: 'login'}">Sign In</router-link>
            <router-link class="button" title="Register" :to="{name: 'register'}">Register</router-link>
        </template>
    </header>
</template>

<script>
    import { Atom, Terminal, MultiBubble } from '@epiphany/iconoir';

    export default {
        name: 'Breadcrumbs',
        components: {
            Atom,
            Terminal,
            MultiBubble
        },
        data(){
            return {

            };
        },
        methods: {
            help(){
                this.$store.dispatch('hubspot/toggle');
            }
        },
        created(){
            if(localStorage.getItem('edlink-gdpr')){
                this.gdpr = false;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.state.user;
            },
            admin(){
                if (this.user && this.user.admin) {
                    return true;
                }
                
                return false;
            },
            hubspot(){
                return this.$store.state.hubspot;
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .breadcrumbs
    {
        padding: 10px;
        height: @breadcrumbs-height;
        border-bottom: 1px solid @e;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background: @f;

        .thumbnail
        {
            width: 22px;
            height: 22px;
            border-radius: 4px;
            box-shadow: 0 0 0 2px @f;
            margin-right: 10px;

            &::before
            {
                width: 22px;
                height: 22px;
                position: absolute;
                z-index: 2;
                content: "";
                top: 0;
                left: 0;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }

            img
            {
                width: 22px;
                height: 22px;
                border-radius: 4px;
                display: block;
            }
        }

        .breadcrumb
        {
            font-size: 13px;
            font-weight: 500;
            color: @black;
            display: flex;
            height: 30px;
            line-height: 30px;
            align-items: center;
            padding: 0 10px;

            .icon
            {
                margin-right: 6px;
            }
        }

        a.breadcrumb
        {
            color: @grey;
            border-radius: @border-radius;
            padding: 0 10px;

            &:hover
            {
                color: @black;
                background: @e;
            }
        }

        .breadcrumb + .breadcrumb
        {
            margin-left: @breadcrumb-margin;

            &::before
            {
                content: "";
                width: @breadcrumb-caret-size;
                height: @breadcrumb-caret-size;
                position: absolute;
                left: -1 * ( @breadcrumb-margin - @breadcrumb-caret-size ) / 2 - @breadcrumb-caret-size;
                top: ( @button-height - @breadcrumb-caret-size ) / 2;
                background: url('~@/assets/icons/grey/caret-right.svg') 0 0 no-repeat;
                background-size: @breadcrumb-caret-size @breadcrumb-caret-size;
            }
        }

        .button
        {
            margin-left: 10px;
        }
    }
</style>
