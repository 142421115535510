<template>
    <div class="onboard flex">
        <div class="onboard-sidebar ff">
            <div class="flex flex-align wordmark block"></div>
        </div>
        <div class="onboard-flow flex flex-align">
            <transition appear name="slide" :duration="400">
                <div class="onboard-step" v-if="step === 'profile'" key="profile">
                    <h1>Let's Get Started</h1>
                    <div class="onboarding-description">
                        Thanks for creating an Edlink account.
                        Tell us a little bit about yourself to get started.
                        This process should only take a moment.
                    </div>
                    <form class="personal-details" @submit.prevent="confirm_details" autocomplete="off">
                        <label for="first_name">First Name</label>
                        <input type="text" class="medium" :value="user.first_name" @input="$event => $store.dispatch('user/update', {field: 'first_name', value: $event.target.value})" name="first_name" />
                        <label for="last_name">Last Name</label>
                        <input type="text" class="medium" :value="user.last_name" @input="$event => $store.dispatch('user/update', {field: 'last_name', value: $event.target.value})" name="last_name" />
                        <!-- <label for="last_name">Profile Picture</label>
                        <upload :url="user.picture_url" @input="value => $store.dispatch('user/update', {field: 'picture_url', value})" type="image" /> -->
                        <div class="onboard-options flex flex-align">
                            <button type="submit" class="large">Continue</button>
                        </div>
                    </form>
                </div>
                <div class="onboard-step" v-if="step === 'invitations'" key="invitations">
                    <h1>Invitations</h1>
                    <div class="onboarding-description">
                        It looks like you already have {{invitations.length === 1 ? 'an invitation' : 'some invitations'}}.
                        If you do not accept any invites, you will be prompted to create a new organization.
                    </div>
                    <div class="invitation-container">
                        <invitations :invites="invitations" />
                        <button class="large" @click="done_with_invitations">Continue</button>
                    </div>
                </div>
                <div class="onboard-step" v-if="step === 'choose'" key="choose">
                    <h1>Create a Team</h1>
                    <div class="onboarding-description">
                        Create a team to get started - you'll be able to add additional members later.
                        If you are trying to join an existing organization, you should have an existing member <a class="dashed-button" href="https://ed.link/docs/dashboard/inviting-team-members" target="_blank">send you an invite</a>.
                    </div>
                    <form @submit.prevent="create_organization" autocomplete="off">
                        <label>Select a Type</label>
                        <div class="team-types flex flex-align">
                            <div class="team-type ff" :class="{checked: organization.type === 'developer'}" @click="organization.type = 'developer'">
                                <div class="flex flex-align">
                                    <span class="block icon iconoir-code-brackets-square"></span>
                                    <div class="team-title">Software Developer</div>
                                </div>
                                <div class="team-description">
                                    For publishers and technology companies who want to integrate with schools.
                                </div>
                            </div>
                            <div class="team-type ff" :class="{checked: organization.type === 'district'}" @click="organization.type = 'district'">
                                <div class="flex flex-align">
                                    <span class="block icon iconoir-city"></span>
                                    <div class="team-title">School or University</div>
                                </div>
                                <div class="team-description">
                                    For schools or universities who want to share LMS or SIS data with vendors.
                                </div>
                            </div>
                        </div>
                        <label>Name Your Team</label>
                        <input type="text" v-model="organization.name" name="organization_name" class="large" />
                        <div class="onboard-options flex flex-align">
                            <button type="submit" class="large">Create Team</button>
                        </div>
                    </form>
                </div>
                <div class="onboard-step" v-if="step === 'create-sample-application'" key="create-sample-application">
                    <h1>Create an Application</h1>
                    <div class="onboarding-description">
                        You will need an application in order to connect to a source and create an integration.
                        If you are trying to join an existing organization, you should have an existing member <a class="dashed-button" href="https://ed.link/docs/dashboard/inviting-team-members" target="_blank">send you an invite</a>.
                    </div>
                    <form @submit.prevent="create_sample_application" autocomplete="off">
                        <div class="form-field">
                            <label>Name</label>
                            <input type="text" v-model="application_name" name="application_name" class="medium"  />
                        </div>
                        <div class="form-field">
                            <label>Description</label>
                            <textarea type="text" v-model="application_description" name="application_description"></textarea>
                        </div>                
                        <div class="onboard-options flex flex-align">
                            <button type="submit" class="large">Create Application</button>
                            <div class="button large white" @click="skip_create_sample">Skip This Step</div>
                        </div>
                    </form>
                </div>
                <div class="onboard-step" v-if="step === 'create-sample-integration'" key="create-sample-integration">
                    <h1>Developer Sandboxes</h1>
                    <div class="onboarding-description">
                        You will need an application in order to connect to a source and create an integration.
                        If you would like to connect your own LMS/SIS instance you can skip this step.
                        But we would recommend you work with a sandbox integration if you are just getting started.
                    </div>
                    <div class="card sandbox-options">
                        <div class="flex flex-align sandbox-option">
                            <div class="ff toggle-title">Canvas</div>
                            <div class="button white mini" :class="{disabled: has('canvas')}" @click="create_sample_integration('canvas')">{{has('canvas') ? 'Connected' : 'Request'}}</div>
                        </div>
                        <div class="flex flex-align sandbox-option">
                            <div class="ff toggle-title">Google Classroom</div>
                            <div class="button white mini" :class="{disabled: has('google')}" @click="create_sample_integration('google')">{{has('google') ? 'Connected' : 'Request'}}</div>
                        </div>
                        <div class="flex flex-align sandbox-option">
                            <div class="ff toggle-title">Schoology</div>
                            <div class="button white mini" :class="{disabled: has('schoology')}" @click="create_sample_integration('schoology')">{{has('schoology') ? 'Connected' : 'Request'}}</div>
                        </div>
                    </div>
                    <div class="onboard-options flex flex-align">
                        <div class="large button" @click="skip_create_sample">Continue</div>
                        <a class="button large white has-icon has-icon-right" href="/docs/dashboard/dev-integrations" target="_blank">
                            Connect Your Own
                            <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="onboard-step" v-if="step === 'developer-next-steps'" key="developer-next-steps">
                    <h1>You're off to the races!</h1>
                    <div class="onboarding-description">
                        You are all ready to go. Head to the Edlink Dashboard to find tons of resources that help you get start with development.
                        Whenever you're ready, you can starting onboarding real school districts and universities.
                    </div>
                    <div class="onboard-options flex flex-align">
                        <router-link class="button large" :to="{ name: 'team.dashboard', params: { team: $store.getters.team.alias } }">Continue to Dashboard</router-link>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { sampleSourceId } from '@/constants';
    import JSConfetti from 'js-confetti';

    const jsConfetti = new JSConfetti();

    export default {
        name: 'Onboard',
        computed: {
            user() {
                return this.$store.state.user;
            },
            teams() {
                return this.$store.state.teams.all;
            }
        },
        data() {
            return {
                step: '',
                error: '',
                invitations: [],
                integrations: [],
                organization: {
                    type: 'developer',
                    name: ''
                },
                application_name: null,
                application_description: null,
                application: null,
                integration: null,
                timeout: null
            };
        },
        created() {
            this.refresh_integrations();
            
            if(this.$store.getters.user?.admin){
                return this.step = 'profile';
            }

            if (this.teams.length && false) {
                if (this.$store.getters.team) {
                    this.$router.push({ name: 'team.dashboard', params: { team: this.$store.getters.team.alias } });
                } else {
                    this.$router.push('/');
                }
            } else {
                this.step = 'profile';
            }
        },
        methods: {
            refresh_integrations() {
                if (this.$store.getters.team?.id) {
                    this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`)
                        .then(response => this.integrations = response.$data)
                        .catch(error => this.$toasted.error('There was an error loading your integrations.'));
                }
            },
            confirm_details() {
                //Check to see if the user has any open invitations
                this.$http.put('/me', this.user)
                    .then(() => {
                        this.$http.get('/invitations')
                            .then(response => {
                                if (response.$data.length) {
                                    for (const invite of response.$data) {
                                        this.invitations.push(Object.assign({ attempting: false, accepted: false, rejected: false }, invite));
                                    }

                                    this.step = 'invitations';
                                } else {
                                    this.step = 'choose';
                                }
                            })
                            .catch(error => {
                                console.log(error);
                                //Don't bother retrying right now, just skip to creation if there's an error
                                this.step = 'choose';
                                this.$toasted.error('There was an error retrieving your pending invitations.');
                            });
                    })
                    .catch(error => this.$toasted.error('There was an error saving your information.'));
            },
            done_with_invitations() {
                if (this.teams.length) {
                    // TODO: Currently we are just choosing the first team to deal with the bug.
                    // if there are multiple teams we should probably show a picker that allows you to pick the team
                    this.$store.dispatch('teams/activate', this.teams[0]);
                    this.$router.push({ name: 'team.dashboard', params: { team: this.$store.getters.team.alias } });
                } else {
                    this.step = 'choose';
                }
            },
            select_type(type) {
                this.organization.type = type;
            },
            create_organization() {
                this.$http.post('/teams', this.organization)
                    .then(response => response.$data)
                    .then(team => {
                        //Basically just add this organization into the vuex store and send them to the dashboard
                        this.$store.dispatch('teams/add', team)
                            .then(() => this.$store.dispatch('teams/activate', team))
                            .then(() => {
                                this.$toasted.success('You have successfully created a team.');

                                if (this.organization.type === 'developer') {
                                    this.step = 'create-sample-application';
                                } else {
                                    this.$router.push({ name: 'team.dashboard', params: { team: team.alias } });
                                }
                            });
                    })
                    .catch(error => {
                        if (error.$error === 'There is already a team with this alias.') {
                            return this.$toasted.error('There is already a team with this alias. Please choose another alias.')
                        }

                        return this.$toasted.error(error.response.$data ? error.response.$data.details : 'There was an error creating your organization.')
                    });
            },
            create_sample_application() {
                // TODO: The onboarding flow should generally happen once. But you can always go back to it by typing in the URL
                // So that means you can't assume that these steps haven't been gone through before.
                // Does that mean check if you the app has already been created? or just create a new sample each time
                // Actually probably better to just show all your applications and then have an option to create new
                if (!this.application_name) {
                    return this.$toasted.error('You have to give your application a name');
                }

                this.$http.post(`/teams/${this.$store.getters.team.id}/create_sample_application`, {
                    name: this.application_name,
                    description: this.application_description
                })
                    .then(response => response.$data)
                    .then(application => this.application = application)
                    .then(() => {
                        this.$toasted.success('You have successfully created a sample application.');
                        this.step = 'create-sample-integration';
                    })
                    .catch(error => this.$toasted.error('There was an error creating a sample application.'));
            },
            create_sample_integration(provider_application) {
                if (this.has(provider_application)) {
                    return;
                }

                this.$http.post(`/teams/${this.$store.getters.team.id}/create_sample_integrations`, {
                    application_id: this.application.id,
                    provider_application
                })
                    .then(response => response.$data)
                    .then(integration => {
                        this.integration = integration;
                        this.integrations.push(integration);
                    })
                    .then(() => {
                        this.$toasted.success('You have successfully created a sandbox integration.');
                    })
                    .catch(error => this.$toasted.error('There was an error creating a sandbox integration.'));
            },
            skip_create_sample() {
                jsConfetti.addConfetti({
                    confettiColors: [
                        '#006AFF', '#F1C40F', '#2ED573', '#8E44AD', '#EB2F06'
                    ]
                });
                
                this.step = 'developer-next-steps';
            },
            update_user(field, value) {
                this.$store.dispatch('user/update', { field, value });
            },
            has(sourceName) {
                if (this.application) {
                    return !!(this.integrations.filter(integration => integration.source.id === sampleSourceId[sourceName] && integration.application.id === this.application.id).length);
                }

                return !!(this.integrations.filter(integration => integration.source.id === sampleSourceId[sourceName]).length);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .onboard
    {
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        h1
        {
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -0.03em;
            color: @black;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .onboarding-description
        {
            font-size: 16px;
            line-height: 22px;
            color: @black;
            margin: 20px 0 30px;
        }

        .onboard-options
        {
            margin-top: 40px;

            button, .button
            {
                margin-right: 20px;
            }
        }

        form
        {
            padding: 0;
            margin: 0;

            label
            {
                margin-top: 20px;
            }

            input
            {
                margin-bottom: 10px;
                padding: 6px 15px;
                height: 44px;
            }
        }

        .onboard-hr
        {
            height: 1px;
            background: @e4;
            margin: 30px 0;
        }
    }

    .onboard-sidebar
    {
        background: @f8;
        border-right: 1px solid @e;

        .wordmark
        {
            height: 25px;
            background: url('~@/assets/edlink-diamonds-black.svg') 0 0 no-repeat;
            background-size: 120px auto;
            color: transparent;
            width: 120px;
            position: absolute;
            top: 30px;
            left: 30px;
        }
    }

    .onboard-flow
    {
        flex: 3;
    }

    .register
    {
        min-height: 100vh;
    }

    .onboard-step
    {
        width: 100%;
        max-width: 700px;
        padding: 100px;
    }

    .team-types
    {
        max-width: 600px;
        margin-bottom: 30px;
    }

    .team-type
    {
        border: 1px solid @d;
        padding: 15px;
        border-radius: 3px;
        text-transform: none;
        cursor: pointer;
        margin-left: 20px;
        margin-top: 5px;
        // max-width: 250px;

        .icon
        {
            color: @black;
            width: 20px;
            height: 20px;
            font-size: 20px;
        }

        &:first-child
        {
            margin-left: 0;
        }

        &.checked
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;

            .team-title, .icon
            {
                color: @base;
            }
        }
    }

    .team-profile
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: @e;
        margin-right: 15px;
    }

    .team-title
    {
        line-height: 16px;
        font-size: 14px;
        color: @black;
        font-weight: 500;
        margin-left: 6px;
    }

    .team-description
    {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: @black;
        margin: 6px 0 0;
    }

    .invitation-container
    {
        width: 100%;
        max-width: 700px;
        margin-top: 30px;

        button
        {
            margin-top: 20px;
            width: 100%;
        }
    }

    .sandbox-option
    {
        border-top: 1px solid @e4;
        padding: 10px 10px 10px 15px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .create-sandbox-options
    {
        .button
        {
            margin-right: 15px;
        }
    }

    .sandbox-options
    {
        max-width: 450px;
    }

    .toggle-title
    {
        font-size: 14px;
        font-weight: 500;
        color: @black;
    }

    .toggle-description
    {
        font-size: 12px;
        color: @grey;
        padding-top: 8px;
        line-height: 16px;
    }
</style>