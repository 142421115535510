import axios from 'axios';

const state = {
    initialize: null,
    all: {}
};

const mutations = {
    initialize(state, promise) {
        state.initialize = promise;
    },
    load(state, providers) {
        for (const provider of providers) {
            state.all[provider.id] = provider;
        }
    }
};

const actions = {
    load({ commit }) {
        if (state.initialize) {
            return state.initialize;
        }

        //Actually load the providers.
        const promise = axios.get('/providers').then((response) => commit('load', response.$data));

        //Commit this promise for later use.
        commit('initialize', promise);

        return promise;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
