<template>
    <div class="source-syncs full scroll" v-if="source">        
        <sync-details @cancel="cancel" @flush="flush" @delete_staged="delete_staged_changes" class="block" :job="job" :children="children" :canceling="canceling"/>
        <sync-staged-totals v-if="admin" class="block" :job="job" :totals="staged_totals"/>
        <sync-staged-changes @change_type="view_staged_changes" class="block" :job="job" :staged_changes_type="staged_changes_type" :staged="staged"/>
    </div>
</template>

<script>
    export default {
        name: 'SourceSync',
        data(){
            return {
                more: false,
                events: [],
                staged: [],
                staged_totals: null,
                children: [],
                staged_changes_type: 'people',
                job: null,
                canceling: false
            };
        },
        watch: {
            source: {
                immediate: true,
                handler(){
                    this.load();
                    this.load_events();
                    this.load_staged_changes();
                    this.load_staged_totals();
                    this.load_child_syncs();
                }
            },
            '$route.params.sync': {
                handler(){
                    this.load();
                    this.load_events();
                    this.load_staged_changes();
                    this.load_staged_totals();
                    this.load_child_syncs();
                }
            }
        },
        methods: {
            load(){
                if(this.source){
                    this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/syncs/${this.$route.params.sync}`)
                    .then(response => this.job = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading the sync.'));
                }
            },
            load_events(){
                if(this.source){
                    this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/syncs/${this.$route.params.sync}/changes`)
                    .then(response => this.events = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading the events for this sync.'));
                }
            },
            load_staged_changes(){
                if(this.source){
                    this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/syncs/${this.$route.params.sync}/${this.staged_changes_type}`)
                    .then(response => this.staged = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading the staged changes for this sync.'));
                }
            },
            load_staged_totals(){
                if(this.source && this.admin){
                    this.$http.get(`/admin/sources/${this.source.id}/syncs/${this.$route.params.sync}/diff`)
                    .then(response => this.staged_totals = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading the staged totals for this sync.'));
                }
            },
            load_child_syncs(){
                if(this.source){
                    this.children = [];

                    this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/syncs/${this.$route.params.sync}/children`, {
                        params: {
                            $first: 10000
                        }
                    })
                    .then(response => this.children = response.$data)
                    .catch(error => this.$toasted.error('There was an error loading the child syncs for this sync.'));
                }
            },
            cancel(){
                if(this.source){
                    this.canceling = true;

                    this.$http.post(`/teams/${this.team.id}/sources/${this.source.id}/syncs/${this.$route.params.sync}/cancel`)
                    .then(response => this.job.status = 'killed')
                    .catch(error => this.$toasted.error('There was an error canceling the sync.'))
                    .finally(() => this.canceling = false);
                }
            },
            flush(){
                if(this.source){
                    this.canceling = true;

                    this.$http.post(`/admin/sources/${this.source.id}/syncs/${this.$route.params.sync}/flush`)
                    .then(response => this.job.status = 'staged')
                    .catch(error => this.$toasted.error('There was an error flushing the sync.'))
                    .finally(() => this.canceling = false);
                }
            },
            view_staged_changes(type){
                //TODO reset the paging back to zero.
                this.staged_changes_type = type;
                this.load_staged_changes();
            },
            delete_staged_changes(){
                if(this.source){
                    this.canceling = true;

                    this.$http.delete(`/admin/sources/${this.source.id}/syncs/${this.$route.params.sync}/staged`)
                    .then(res => this.$toasted.info(`Staged changes for ${this.source.name} (Sync: ${this.$route.params.sync}) deleted.`))
                    .catch(error => this.$toasted.error('There was an error deleting the staged changes.'))
                    .finally(() => this.canceling = false);
                }
            }
        },
        computed: {
            admin(){
                return this.$store.getters.user.admin;
            },
            source(){
                return this.$store.getters.source;
            },
            team(){
                return this.$store.getters.team;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        .section-options
        {
            padding-top: 20px;
        }

        &:first-child
        {
            border-top: 0;
        }
    }
</style>