<template>
    <div class="create-transformation flex flex-column">
        <div class="flex flex-align drawer-header">
            <h2 class="ff drawer-title">Create a Formatting Rule</h2>
            <div class="text-button" @click="close">Cancel</div>
        </div>
        <div class="drawer-divider"></div>
        <div class="ff drawer-scroll">
            <div class="drawer-section">
                <div class="flex drawer-section-header">
                    <h3>Select a Type</h3>
                </div>
                <div v-for="entity in this.entities" class="transformation-block flex flex-align" @click="create(entity.type)">
                    <div class="transformation-block-icon flex flex-align flex-center">
                        <span class="icon block" :class="{[entity.icon]: true}"></span>
                    </div>
                    <div class="ff">
                        <div class="block-title">
                            <template v-if="entity.title">
                                {{ entity.title }}
                            </template>
                            <template v-else>
                                Format Rows Into {{ entity.name }}
                            </template>
                        </div>
                        <div class="block-description">
                            <template v-if="entity.description">
                                {{ entity.description }}
                            </template>
                            <template v-else>
                                This rule type will convert rows into {{ entity.name }} and allow you to map columns to the Edlink {{ entity.singular }} properties.
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CreateFormattingRule',
        data(){
            return {
                entities: [
                    // {name: 'Entities', singular: 'Entity', type: 'entities', icon: 'iconoir-group', title: '', description: ''},
                    {name: 'Districts', singular: 'District', type: 'districts', icon: 'iconoir-city'},
                    {name: 'Schools', singular: 'School', type: 'schools', icon: 'iconoir-building'},
                    {name: 'Sessions', singular: 'Session', type: 'sessions', icon: 'iconoir-clock-outline'},
                    {name: 'Courses', singular: 'Course', type: 'courses', icon: 'iconoir-bookmark-book'},
                    {name: 'Classes', singular: 'Class', type: 'classes', icon: 'iconoir-apple'},
                    {name: 'Sections', singular: 'Section', type: 'sections', icon: 'iconoir-paste-clipboard'},
                    {name: 'People', singular: 'Person', type: 'people', icon: 'iconoir-group'},
                    {name: 'Enrollments', singular: 'Enrollment', type: 'enrollments', icon: 'iconoir-archive'},
                    {name: 'Agents', singular: 'Agent', type: 'agents', icon: 'iconoir-network'},
                    {name: 'Meetings', singular: 'Meeting', type: 'meetings', icon: 'iconoir-calendar'},
                    {name: 'Attendance', singular: 'Attendance', type: 'attendance', icon: 'iconoir-calendar'},
                    {name: 'Departments', singular: 'Department', type: 'departments', icon: 'iconoir-graduation-cap'},
                    {name: 'Subjects', singular: 'Subject', type: 'subjects', icon: 'iconoir-book-stack'},
                    {name: 'Periods', singular: 'Period', type: 'periods', icon: 'iconoir-bell'},
                    {name: 'Incidents', singular: 'Incident', type: 'incidents', icon: 'iconoir-warning-triangle-outline'},
                    {name: 'Facilities', singular: 'Facility', type: 'facilities', icon: 'iconoir-garage'},
                    {name: 'Rooms', singular: 'Room', type: 'rooms', icon: 'iconoir-light-bulb'},
                    {name: 'Assets', singular: 'Asset', type: 'assets', icon: 'iconoir-box-iso'},
                    {name: 'Fees', singular: 'Fee', type: 'fees', icon: 'iconoir-lot-of-cash'},
                    {name: 'Stops', singular: 'Stop', type: 'stops', icon: 'iconoir-bus-stop'},
                    {name: 'Routes', singular: 'Route', type: 'routes', icon: 'iconoir-path-arrow'},
                    {name: 'Vehicles', singular: 'Vehicle', type: 'vehicles', icon: 'iconoir-bus-outline'},
                    {name: 'Calendars', singular: 'Calendar', type: 'calendars', icon: 'iconoir-calendar'},
                    {name: 'Days', singular: 'Day', type: 'days', icon: 'iconoir-calendar'}
                ]
            }
        },
        methods: {
            create(type){
                if(this.props.create){
                    this.props.create(type);
                }

                this.close();
            },
            close(){
                this.$store.dispatch('drawer/close');
            }
        },
        computed: {
            fns(){
                return this.$store.state.drawer.props;
            },
            props(){
                return this.$store.state.drawer.props;
            },
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.getters.user;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-transformation
    {
        height: 100%;
    }

    h3
    {
        font-size: 14px;
        color: @black;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        height: 16px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px 0 10px;

        &:first-child
        {
            margin-top: 51px;
            padding-top: 20px;
        }

        &:last-child
        {
            border-bottom: none;
        }
    }

    .drawer-section-header
    {
        padding: 0 25px 10px;
    }
    
    .transformation-block
    {
        color: @black;
        cursor: pointer;
        padding: 10px;
        margin: 5px 15px;
        border-radius: 4px;
        // transition: background-color ease 0.1s;

        .icon
        {
            margin-right: 15px;
            font-size: 20px;
            width: 20px;
            height: 20px;
        }

        .block-title
        {
            font-size: 13px;
            font-weight: 500;
            color: @black;
            // transition: color ease 0.1s;
        }

        .block-description
        {
            font-size: 12px;
            color: @grey;
            margin-top: 4px;
            line-height: 16px;
            // transition: color ease 0.1s;
        }

        .transformation-block-icon
        {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background: fade(@base, 20%);
            margin-right: 15px;

            .icon
            {
                height: 18px;
                width: 18px;
                font-size: 18px;
                margin: 0;
                color: @base;
            }
        }

        &:hover, &:active
        {
            background: fade(@base, 15%);

            .block-title, .block-description
            {
                color: @base;
            }
        }
    }
</style>
