<template>
    <div class="entity flex flex-column">
        <header class="drawer-header flex flex-align">
            <div class="ff drawer-title flex flex-align">
                <component :is="icon" class="icon" width="20" height="20" stroke-width="2" />
                <div class="ff text-overflow">{{ title }}</div>
            </div>
            <div class="button impersonate" @click="impersonate" v-if="type === 'people' && integration && !integration.destination">Impersonate User</div>
            <div class="button purple reset-password" @click="openResetPassword" v-if="type === 'people' && can_reset_password">Reset Password</div>
            <div class="button white" @click="close">Close</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{active: tab === 'details'}" @click="tab = 'details'">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Details
            </div>
            <div class="pill" :class="{active: tab === 'json'}" @click="tab = 'json'">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                JSON
            </div>
            <template v-if="type === 'people'">
                <div class="pill" :class="{active: tab === 'enrollments'}" @click="tab = 'enrollments'">
                    <Desk class="icon" width="16" height="16" stroke-width="2" />
                    Enrollments
                </div>
                <div class="pill" :class="{active: tab === 'requests'}" @click="tab = 'requests'">
                    <DataTransferUp class="icon" width="16" height="16" stroke-width="2" />
                    Requests
                </div>
                <!-- <div class="pill" :class="{active: tab === 'logins'}" @click="tab = 'logins'">
                    <UnionAlt class="icon" width="16" height="16" stroke-width="2" />
                    Logins
                </div> -->
            </template>
            <template v-if="type === 'classes'">
                <div class="pill" :class="{active: tab === 'enrollments'}" @click="tab = 'enrollments'">
                    <Desk class="icon" width="16" height="16" stroke-width="2" />
                    Enrollments
                </div>
            </template>
            <template v-if="integration?.application.id === '979dde54-832a-4814-ad50-4b30d23f23c7'">
                <div class="pill" :class="{active: tab === 'flow'}" @click="tab = 'flow'">
                    <SeaWaves class="icon" width="16" height="16" stroke-width="2" />
                    Flow
                </div>
            </template>
            <template v-if="matches_supported">
                <div class="pill" :class="{active: tab === 'matches'}" @click="tab = 'matches'">
                    <UnionAlt class="icon" width="16" height="16" stroke-width="2" />
                    {{ flow_application ? 'Links' : 'Matches' }}
                </div>
            </template>
            <div class="ff"></div>
            <div class="pill" :class="{active: tab === 'events'}" @click="tab = 'events'">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Events
            </div>
        </nav>
        <div class="drawer-content drawer-scroll">
            <component :is="tabs[tab]" />
        </div>
    </div>
</template>

<script>
    import EntityDetails from '@/components/drawers/entity/EntityDetails.vue';
    import EntityEnrollments from '@/components/drawers/entity/EntityEnrollments.vue';
    import EntityEvents from '@/components/drawers/entity/EntityEvents.vue';
    import EntityJSON from '@/components/drawers/entity/EntityJSON.vue';
    import EntityMatches from '@/components/drawers/entity/EntityMatches.vue';
    import EntityRequests from '@/components/drawers/entity/EntityRequests.vue';
    import EntityFlow from '@/components/drawers/entity/EntityFlow.vue';
    import ResetPasswordEntity from '@/components/modals/ResetPasswordEntity.vue';

    import { MATCH_TYPES_SUPPORTED, FLOW_APPLICATION_ID } from '@/constants';

    const API_URL = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

    import {
        Bolt,
        AlignLeft,
        UnionAlt,
        DataTransferUp,
        CodeBracketsSquare,
        InfoEmpty,
        SettingsAlt,
        UserLove,
        Packages,
        ListSelect,
        Calendar,
        Apple,
        Calculator,
        BookmarkCircle,
        DesignPencil,
        Bank,
        Desk,
        Swimming,
        Coin,
        WarningCircle,
        Presentation,
        Group,
        Clock,
        MathBook,
        Map,
        City,
        Asana,
        CalendarPlus,
        PinAlt,
        SineWave,
        Truck,
        SeaWaves
    } from '@epiphany/iconoir';

    export default {
        name: 'Entity',
        components: {
            Bolt,
            AlignLeft,
            UnionAlt,
            DataTransferUp,
            CodeBracketsSquare,
            InfoEmpty,
            SettingsAlt,
            UserLove,
            Packages,
            ListSelect,
            Calendar,
            Apple,
            Calculator,
            BookmarkCircle,
            DesignPencil,
            Bank,
            Desk,
            Swimming,
            Coin,
            WarningCircle,
            Presentation,
            Group,
            Clock,
            MathBook,
            Map,
            City,
            Asana,
            CalendarPlus,
            PinAlt,
            SineWave,
            Truck,
            SeaWaves
        },
        data() {
            return {
                tab: 'details',
                can_reset_password: false,
                tabs: {
                    details: EntityDetails,
                    enrollments: EntityEnrollments,
                    events: EntityEvents,
                    json: EntityJSON,
                    matches: EntityMatches,
                    requests: EntityRequests,
                    flow: EntityFlow
                },
                icons: {
                    'agents': UserLove,
                    'assets': Packages,
                    'attendance': ListSelect,
                    'calendars': Calendar,
                    'classes': Apple,
                    'courses': Calculator,
                    'days': BookmarkCircle,
                    'departments': DesignPencil,
                    'districts': Bank,
                    'enrollments': Desk,
                    'facilities': Swimming,
                    'fees': Coin,
                    'incidents': WarningCircle,
                    'meetings': Presentation,
                    'people': Group,
                    'periods': Clock,
                    'rooms': MathBook,
                    'routes': Map,
                    'schools': City,
                    'sections': Asana,
                    'sessions': CalendarPlus,
                    'stops': PinAlt,
                    'subjects': SineWave,
                    'vehicles': Truck
                }
            };
        },
        mounted() {
            const team_id = this.$store.getters.team.id;
            const source_id = this.parent.source ? this.parent.source.id : this.parent.id;

            // If the team_id is not the same as the integration team id, we don't need to check for password overrides
            // because the source is not owned by this team and therefore the password cannot be reset by this user.
            if(this.integration){
                if(this.integration.team.id !== team_id){
                    return;
                }
            }

            this.$http.get(`/teams/${team_id}/sources/${source_id}`)
            .then(({ $data }) => {
                if ($data.configuration?.passwords_enabled === true) {
                    this.can_reset_password = true;
                }
            })
            .catch(() => {
                // The source probably isn't owned by this team so we no-op
            });
        },
        methods: {
            impersonate() {
                const params = {};

                if(!this.integration){
                    return;
                }

                this.$http.post(`${API_URL}/api/v1/graph/people/${this.entity.id}/impersonate`, {}, {
                    params,
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                })
                .then(response => window.open(response.$data))
                .catch(() => this.$toasted.error('There was an error generating a launch URL for this user.'));
            },
            close() {
                this.$store.dispatch('drawer/close');
            },
            name(integration) {
                return integration.team.name;
            },
            openResetPassword() {
                const _this = this;

                this.$modal.show(ResetPasswordEntity, {
                    reset(new_password) {
                        _this.resetPassword(new_password);
                    }
                }, {width: 360, height: 'auto', classes: 'modal'});
            },
            resetPassword(new_password){
                const team_id = this.$store.getters.team.id;
                const source_id = this.parent.source ? this.parent.source.id : this.parent.id;

                // Make api request to reset password
                this.$http.post(`/teams/${team_id}/sources/${source_id}/overrides`, {
                    password: new_password,
                    entity_id: this.entity.id,
                    source_id: source_id,
                    type: 'person',
                    action: 'updated',
                    properties: {}
                })
                .then(() => {
                    this.$toasted.success('Password reset successfully.');
                })
                .catch(() => {
                    this.$http.put(`/teams/${team_id}/sources/${source_id}/overrides/${this.entity.id}`, {
                        password: new_password,
                        entity_id: this.entity.id,
                        source_id: source_id,
                        type: 'person',
                        action: 'updated',
                        properties: {}
                    })
                    .then(() => {
                        this.$toasted.success('Password reset successfully.');
                    })
                    .catch(() => {
                        this.$toasted.error('There was an error resetting this password.');
                    });
                });
            }
        },
        computed: {
            icon() {
                if(this.icons.hasOwnProperty(this.type)){
                    return this.icons[this.type];
                }

                return SettingsAlt;
            },
            team() {
                return this.$store.getters.team;
            },
            loading() {
                return this.$store.state.entities.loading;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            user() {
                return this.$store.getters.user;
            },
            title() {
                if(this.type === 'enrollments'){
                    return 'Enrollment';
                }

                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            },
            matches_supported() {
                return MATCH_TYPES_SUPPORTED.includes(this.type);
            },
            flow_application(){
                return FLOW_APPLICATION_ID === this.integration?.application?.id;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;
        }

        .drawer-title
        {
            margin-right: 40px;
        }

        .button
        {
            margin-left: @single-padding;
        }
    }

    .drawer-scroll
    {
        overflow: auto;
    }
</style>
