import axios from 'axios';

const state = {
    active: null,
    updating: false
};

const mutations = {
    activate(state, source) {
        state.active = source;
    },
    deactivate() {
        state.active = null;
    },
    update(state, updated) {
        const valid_fields = ['name', 'import_interval', 'configuration', 'status'];

        for (const field of valid_fields) {
            if (updated.hasOwnProperty(field)) {
                state.active[field] = updated[field];
            }
        }
    }
};

const actions = {
    activate({ commit }, { entity }) {
        return axios.get(`/entities/${entity}`).then((response) => commit('activate', response.$data));
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit }, updates) {
        if (state.active) {
            return axios.put(`/entities/${state.active.id}`, updates).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active location.');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
