<template>
    <div class="integrate-account-invitations">
        <h1>You've been invited to join {{invitations.length === 1 ? 'a' : invitations.length}} team{{invitations.length === 1 ? '' : 's'}}.</h1>
        <div class="helptext">
            If you do not want to accept the invitation{{invitations.length === 1 ? '' : 's'}}, you can skip this step by clicking the "Continue" button below
            and proceed to select or create a different team.
        </div>
        <div class="invitations">
            <div v-if="loading">
                <spinner />
            </div>
            <div class="invitation-container" v-else-if="invitations.length">
                <invitations :invites="invitations"/>
            </div>
            <div class="nothing" v-else>You have no pending invitations.</div>
        </div>
        <div class="options flex" key="email">
            <div class="button" kbd="Enter ⏎" @click="next">Continue</div>
        </div>
    </div>
</template>

<script>
    export default {
    name: 'IntegrateAccountInvitations',
    props: {
        state: Object,
        send: Function
    },
    data() {
        return {
            loading: false
        };
    },
    mounted() {
        window.addEventListener('keydown', this.keydown);
    },
    destroyed() {
        window.removeEventListener('keydown', this.keydown);
    },
    computed: {
        invitations(){
            return this.user.invitations;
        },
        user(){
            return this.$store.getters.user;
        },
        onboarding(){
            return this.$store.state.integrate.onboarding;
        }
    },
    methods: {
        keydown(e) {
            if (e.code === 'Enter') {
                this.next();
            }
        },
        next() {
            this.send('DISTRICT');
        }
    }
}
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext
    {
        margin-bottom: 20px;
    }

    .text-button
    {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;

        .icon
        {
            margin-right: 6px;
            font-size: 18px;
        }
    }

    .invitations
    {
        .nothing, .invitation-container
        {
            margin-top: 25px;
        }
    }
</style>
