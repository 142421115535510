import { render, staticRenderFns } from "./DemoClass.vue?vue&type=template&id=6bf9663b&scoped=true"
import script from "./DemoClass.vue?vue&type=script&lang=js"
export * from "./DemoClass.vue?vue&type=script&lang=js"
import style0 from "./DemoClass.vue?vue&type=style&index=0&id=6bf9663b&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf9663b",
  null
  
)

export default component.exports