<template>
    <div class="banners" v-if="banners.length">
        <div v-for="banner of banners" :key="banner.id" class="banner flex flex-align" :class="banner.theme">
            <span class="banner-icon icon block" :class="banner.icon" v-if="banner.icon"></span>
            <div class="ff">{{banner.description}}</div>
            <template v-for="(button, index) of banner.buttons">
                <a v-if="button.action" :key="index" v-bind="button.props" @click="button.action">
                    <span class="icon" :class="button.icon" v-if="button.icon"></span>
                    {{button.text}}
                </a>
                <a v-else :key="index" v-bind="button.props">
                    <span class="icon" :class="button.icon" v-if="button.icon"></span>
                    {{button.text}}
                </a>
            </template>
            <a class="text-button grey border" @click="dismiss(banner)" v-if="banner.type !== 'system'">Dismiss</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Banners',
        methods: {
            dismiss(banner){
                this.$store.dispatch('banners/dismiss', banner.id);
            }
        },
        computed: {
            banners(){
                return this.$store.getters.banners;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .banners
    {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }

    .banner
    {
        height: 48px;
        background: @black;
        color: @f;
        font-size: 13px;
        padding: 0 10px 0 15px;
        margin-top: 0;
        border-radius: 0;

        .constrain
        {
            height: 100%;
        }

        .banner-icon
        {
            font-size: 18px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            margin-right: 8px;
        }

        .button
        {
            border: 0;
            box-shadow: none;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 4px;
        }

        .text-button.border
        {
            font-size: 12px;
            padding: 2px 10px;
            margin-left: 10px;
            border-radius: 4px;

            &:hover, &:active
            {
                background: transparent;
            }
        }

        &.base
        {
            background: @base;

            .text-button.border
            {
                color: @f;
                border-color: fade(@f, 60%);
            }
        }

        &.light
        {
            background: @f;
            color: @black;

            .text-button.border
            {
                color: @grey;
                border-color: fade(@grey, 60%);
            }

            &::after
            {
                content: '';
                display: block;
                height: 1px;
                background: rgba(0, 0, 0, 0.1);
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
            }
        }
    }
</style>
