import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';

const state = {
    active: null
};

let throttle = null;

const valid_fields = [
    'name',
    'description',
    'copyright',
    'icon_url',
    'banner_url',
    'origin',
    'key',
    'origin',
    'sso_enabled',
    'sso_protocol',
    'sso_jwk_source',
    'sso_jwk_json',
    'sso_jwks_uri',
    'sso_initiation_url',
    'firewall_enabled',
    'firewall_rules',
    'default_block_ids',
    'status',
    'updated_date',
    'semver',
    'permissions',
    'valid_source_providers',
    'valid_anonymous_providers',
    'webhook_endpoint',
    'webhook_events',
    'setup_redirect_uri',
    'properties.lti_context_version',
    'properties.disable_sso_cookies',
    'properties.unresolved_uuid_behavior',
    'properties.redirect_on_error',
    'properties.auto_calculate_assignment_display_date',
    'clever_enabled',
    'classlink_enabled',
    'clever_client_id',
    'clever_client_secret',
    'classlink_client_id',
    'classlink_client_secret',
    'classlink_oneroster_key',
    'properties.theme_color_primary',
    'properties.login.layout',
    'properties.login.info_text',
    'properties.login.background.type',
    'properties.login.background.pattern',
    'properties.login.background.image',
    'properties.login.background.size',
    'properties.lti.canvas.account_navigation',
    'properties.lti.canvas.link_selection',
    'properties.lti.canvas.user_navigation',
    'properties.lti.canvas.course_navigation',
    'properties.lti.canvas.global_application',
    'properties.lti.canvas.new_window',
    'properties.clever.default_api_version',
    'properties.validation',
    'properties.default_billing_category'
];

const mutations = {
    activate(state, application) {
        state.active = application;
    },
    deactivate(state) {
        state.active = null;
    },
    toggle_permission(state, permission) {
        if (state.active.permissions.includes(permission)) {
            state.active.permissions.splice(state.active.permissions.indexOf(permission), 1);
        } else {
            state.active.permissions.push(permission);
        }
    },
    toggle_source_provider(state, provider) {
        if (state.active.valid_source_providers.includes(provider)) {
            state.active.valid_source_providers.splice(state.active.valid_source_providers.indexOf(provider), 1);
        } else {
            state.active.valid_source_providers.push(provider);
        }
    },
    toggle_anonymous_provider(state, provider) {
        if (state.active.valid_anonymous_providers.includes(provider.id)) {
            state.active.valid_anonymous_providers.splice(state.active.valid_anonymous_providers.indexOf(provider.id), 1);
        } else {
            state.active.valid_anonymous_providers.push(provider.id);
        }
    },
    toggle_external_provider(state, provider) {
        if (provider === 'clever') {
            state.active.clever_enabled = !state.active.clever_enabled;
        } else if (provider === 'classlink') {
            state.active.classlink_enabled = !state.active.classlink_enabled;
        }
    },
    update(state, updated) {
        // Vue.set doesn't like to handle nested properties, which is annoying.
        // We can't just overwrite an entire properties hash, so we have to go segment by segment.
        for (const field of valid_fields) {
            if (_.has(updated, field)) {
                const segments = field.split('.');
                let current = state.active;

                for (const [index, segment] of segments.entries()) {
                    const value = updated[field] ? updated[field] : _.get(updated, field);
                    if (index === segments.length - 1) {
                        Vue.set(current, segment, value);
                    } else if (!_.has(current, segment)) {
                        Vue.set(current, segment, {});
                    }

                    // Move down the line.
                    current = current[segment];
                }
            }
        }
    },
    add_secret(state, credential) {
        state.active.credentials.push(credential);
    },
    remove_secret(state, credential) {
        const index = state.active.credentials.findIndex((item) => item.id === credential.id);
        state.active.credentials.splice(index, 1);
    }
};

const actions = {
    async activate({ commit }, { team, application }) {
        const requests = [];
        requests.push(axios.get(`/teams/${team}/applications/${application}/credentials`).then((rsp) => rsp.$data));
        requests.push(axios.get(`/teams/${team}/applications/${application}`).then((rsp) => rsp.$data));
        await Promise.all(requests).then((responses) => {
            commit(
                'activate',
                Object.assign(responses[1], {
                    credentials: responses[0]
                })
            );
        });
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit, state, dispatch }) {
        if (state.active) {
            if (throttle) {
                clearTimeout(throttle);
            }

            // Dispatch a change to our save service.
            dispatch('save/save', 'applications/update', { root: true });

            throttle = setTimeout(() => {
                throttle = null;

                axios
                    .put(`/teams/${state.active.team.id}/applications/${state.active.id}`, state.active)
                    .then((response) => {
                        commit('update', response.$data);
                        dispatch('save/saved', 'applications/update', { root: true });
                    })
                    .catch(() => dispatch('save/error', 'applications/update', { root: true }));
            }, 1000);
        } else {
            return Promise.reject('There is no active application.');
        }
    },
    admin_update({ commit, dispatch }, updates) {
        // Submit this application for verification
        if (state.active) {
            // Dispatch a change to our save service.
            dispatch('save/save', 'applications/update', { root: true });

            return axios
                .put(`admin/applications/${state.active.id}`, state.active)
                .then((response) => commit('update', response.$data))
                .finally(() => dispatch('save/saved', 'applications/update', { root: true }))
                .catch(() => Promise.reject('There was an error updating the application.'));
        }

        return Promise.reject('There is no active application.');
    },
    destroy({ commit, dispatch }) {
        if (state.active) {
            // Dispatch a change to our save service.
            dispatch('save/save', 'applications/destroy', { root: true });

            return axios
                .delete(`/teams/${state.active.team.id}/applications/${state.active.id}`)
                .then(() => commit('update', { status: 'destroyed' }))
                .finally(() => dispatch('save/saved', 'applications/destroy', { root: true }));
        }

        return Promise.reject('There is no active application.');
    },
    add_secret({ commit }, credential) {
        commit('add_secret', credential);
    },
    remove_secret({ commit }, credential) {
        commit('remove_secret', credential);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
