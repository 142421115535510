<template>
    <div class="dropdown" v-if="open" :class="{scrollable, searchable}" :style="{top, left, bottom, right, width, height, maxHeight, padding}">
        <div class="dropdown-search flex flex-align" v-if="searchable">
            <Search class="dropdown-search-icon icon block" width="16" height="16" stroke-width="2" />
            <input type="text" class="dropdown-search-input ff block" placeholder="Search" v-model="query" @input="search" ref="search">
        </div>
        <slot />
    </div>
</template>

<script>
import { Search } from '@epiphany/iconoir';

export default {
    name: 'Dropdown',
    components: {
        Search
    },
    props: {
        open: Boolean,
        scrollable: {
            type: Boolean,
            default: true
        },
        searchable: Boolean,
        top: {
            type: String,
            default: '36px'
        },
        left: {
            type: String,
            default: '0px'
        },
        bottom: {
            type: String,
            default: 'auto'
        },
        right: {
            type: String,
            default: 'auto'
        },
        width: {
            type: String,
            default: '220px'
        },
        height: {
            type: String,
            default: '200px'
        },
        maxHeight: {
            type: String,
            default: 'unset'
        },
        padding: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            query: ''
        };
    },
    created(){
        // window.addEventListener('mousedown', this.blur);
        // window.addEventListener('focusout', this.blur);
    },
    destroyed(){
        // window.removeEventListener('mousedown', this.blur);
        // window.removeEventListener('focusout', this.blur);
    },
    methods: {
        search(){
            this.$emit('search', this.query);
        }
    },
    watch: {
        open(){
            if(this.open){
                this.$nextTick(() => {
                    this.$refs.search?.focus();
                });
            }
        }
    }
}
</script>

<style lang="less">
@import "~@/assets/less/variables";

.dropdown
{
    position: absolute;
    background: @f;
    border-radius: @border-radius;
    z-index: 15;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &.scrollable
    {
        overflow-y: auto;
    }

    .dropdown-search
    {
        height: 40px;
    }

    .dropdown-search
    {
        padding: 10px 14px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .dropdown-search-icon
    {
        color: @grey;
        margin-right: 10px;
    }

    .dropdown-items-empty
    {
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 12px;
        color: @grey;
    }

    .dropdown-search-input
    {
        width: auto;
        border: none;
        outline: none;
        background: transparent;
        color: @black;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        padding: 0;
        border-radius: 0;

        &:focus
        {
            box-shadow: none;
        }
    }

    .dropdown-spacer
    {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin: 4px 0;

        &.dropdown-header
        {
            font-size: 11px;
            color: @grey;
            font-weight: 500;
            line-height: 12px;
            padding: @single-padding @single-padding 2px;
        }

        &:first-child
        {
            margin-top: 0;
            border-top: 0;
            padding-top: 10px;
        }
    }
}
</style>