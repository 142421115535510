<template>
    <div class="integrate-source-provider-oneroster">
        <integrate-source-editor-step name="Request OneRoster Credentials" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <!-- TODO: Genesis specific instructions -->
                <div class="configuration-value keys">
                    You may need to request your OneRoster credentials directly from Genesis. This means you must contact the Customer Relationship Manager for your district to enable the OneRoster export. This will provide credentials that include an <code>API URL</code>, <code>Client ID</code>, and <code>Client Secret</code>.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter Your Genesis OneRoster API URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your Genesis OneRoster server is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://genesis.forge-tech.com/genesis" :value="source.configuration.oneroster_server_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_server_url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <div class="helptext">
                Enter the OneRoster keys Genesis provided to you.
            </div>
            <label class="key-label">Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.oneroster_client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_id', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.oneroster_client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_secret', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderGenesis',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(!this.remote){
                    return [];
                }

                if(!this.source.configuration?.oneroster_server_url?.match(url)){
                    return ['remote'];
                }

                const properties = ['oneroster_client_id', 'oneroster_client_secret'];

                for(const property of properties){
                    if(!this.source.configuration?.[property]){
                        return ['url', 'remote'];
                    }
                }

                return ['url', 'remote', 'credentials'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
