<template>
    <div class="entity-details">
        <template v-if="total">
            <div class="drawer-section progress">
                <h4>Progress</h4>
                <div class="progress-bar oh flex">
                    <div class="progress-segment skipped" v-if="progress.skipped" :style="{flex: progress.skipped}">{{progress.skipped.toLocaleString()}}</div>
                    <div class="progress-segment complete" v-if="progress.complete" :style="{flex: progress.complete}">{{progress.complete.toLocaleString()}}</div>
                    <div class="progress-segment working" v-if="progress.working" :style="{flex: progress.working}">{{progress.working.toLocaleString()}}</div>
                    <div class="progress-segment canceled" v-if="progress.canceled" :style="{flex: progress.canceled}">{{progress.canceled.toLocaleString()}}</div>
                    <div class="progress-segment error" v-if="progress.error" :style="{flex: progress.error}">{{progress.error.toLocaleString()}}</div>
                    <div class="progress-segment queued" v-if="progress.queued" :style="{flex: progress.queued}">{{progress.queued.toLocaleString()}}</div>
                </div>
                <div class="progress-legend flex">
                    <div class="progress-label skipped" v-if="progress.skipped">
                        Skipped
                    </div>
                    <div class="progress-label complete" v-if="progress.complete">
                        Complete
                    </div>
                    <div class="progress-label working" v-if="progress.working">
                        Working
                    </div>
                    <div class="progress-label canceled" v-if="progress.canceled">
                        Canceled
                    </div>
                    <div class="progress-label error" v-if="progress.error">
                        Error
                    </div>
                    <div class="progress-label queued" v-if="progress.queued">
                        Queued
                    </div>
                </div>
            </div>
        </template>
        <div class="drawer-section">
            <h4>Details</h4>
            <div class="summary">
                <entity-field v-for="column of properties" :key="column.property" v-bind="column" />
            </div>
        </div>
        <div class="drawer-section progress">
            <h4>Task Summary</h4>
            <div class="datatable oh job-summary card">
                <div class="row header flex">
                    <div class="cell ff">
                        <div class="value text-overflow">Entity Type</div>
                    </div>
                    <div class="cell ff">
                        <div class="value text-overflow">Created</div>
                    </div>
                    <div class="cell ff">
                        <div class="value text-overflow">Updated</div>
                    </div>
                    <div class="cell ff">
                        <div class="value text-overflow">Deleted</div>
                    </div>
                </div>
                <div class="body ff" ref="table">
                    <!-- <div v-if="loading" class="flex flex-center flex-align loading">
                        <spinner />
                    </div> -->
                    <div class="row flex" v-for="entity of rows" :key="entity">
                        <div class="cell ff">
                            <div class="value text-overflow flex flex-align capitalize">{{entity}}</div>
                            <!-- <div class="value empty text-overflow" v-else>&mdash;</div> -->
                        </div>
                        <div class="cell ff" v-for="event of ['created', 'updated', 'deleted']" :key="event">
                            <div class="value text-overflow flex flex-align" v-if="summary[entity][event]">{{summary[entity][event]}}</div>
                            <div class="value empty text-overflow" v-else>&mdash;</div>
                        </div>
                    </div>
                    <!-- <div v-else class="flex flex-align flex-center no-results">
                        <div>No results.</div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';

    export default {
        name: 'JobDetails',
        components: {
            'entity-field': EntityField
        },
        props: {
            progress: Object,
            summary: Object
        },
        data(){
            return {
                properties: [
                    {title: 'Job ID', property: 'id', type: 'string', monospace: true, copyable: true},
                    {title: 'Created Date', property: 'created_date', type: 'date'},
                    {title: 'Updated Date', property: 'updated_date', type: 'date'},
                    {title: 'State', property: 'state', type: 'string'}
                ]
            };
        },
        created(){

        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            },
            title(){
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            },
            total(){
                let total = 0;

                for(const key of ['canceled', 'complete', 'error', 'queued', 'skipped', 'working']){
                    total += this.progress[key];
                }

                return total;
            },
            rows(){
                return this.summary ? Object.keys(this.summary) : [];
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }
    
    nav
    {
        border-bottom: 1px solid @e4;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }

    .progress
    {
        h4
        {
            margin-bottom: 15px;
        }
    }

    .progress-bar
    {
        height: 24px;
        border-radius: 6px;
    }

    .progress-segment
    {
        margin-right: 2px;
        min-width: 24px;
        line-height: 24px;
        color: @f;
        font-size: 11px;
        font-weight: 500;
        padding: 0 4px;
        text-align: center;

        &:first-child
        {
            border-top-left-radius: @border-radius;
            border-bottom-left-radius: @border-radius;
        }

        &:last-child
        {
            margin-right: 0;
            border-top-right-radius: @border-radius;
            border-bottom-right-radius: @border-radius;
        }

        &.skipped
        {
            background-color: @blue;
        }

        &.complete
        {
            background-color: @green;
        }

        &.canceled
        {
            background-color: @yellow;
        }

        &.error
        {
            background-color: @red;
        }

        &.working
        {
            background-color: @purple;
        }

        &.queued
        {
            background-color: @grey;
        }
    }

    .progress-legend
    {
        margin-top: 15px;

        .progress-label
        {
            margin-right: 25px;
            font-size: 12px;
            color: @black;
            padding-left: 18px;

            &::after
            {
                content: "";
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background: @grey;
                position: absolute;
                top: 0;
                left: 0;
            }

            &.skipped::after
            {
                background-color: @blue;
            }

            &.complete::after
            {
                background-color: @green;
            }

            &.canceled::after
            {
                background-color: @yellow;
            }

            &.error::after
            {
                background-color: @red;
            }

            &.working::after
            {
                background-color: @purple;
            }
        }
    }

    .datatable
    {
        .row
        {
            border-top: 1px solid @e4;

            .cell
            {
                padding: 15px 10px;
                font-size: 14px;
                color: @black;
            }

            &.header
            {
                border-top: 0;

                .cell
                {
                    padding: 6px 10px;
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 14px;
                }
            }
        }
    }
</style>
