<template>
    <div class="create-transformation">
        <div class="flex flex-align drawer-header">
            <h2 class="ff drawer-title">{{ application ? 'Select' : 'Create' }} a Transformation</h2>
            <div class="button white" @click="close">Cancel</div>
        </div>
        <div class="drawer-content drawer-scroll">
            <div class="drawer-section">
                <div class="flex drawer-section-header">
                    <h3>Your Blocks</h3>
                </div>
                <div class="transformation-block flex flex-align" @click="create()" v-if="!application">
                    <div class="transformation-block-icon flex flex-align flex-center">
                        <span class="icon iconoir-add-circled-outline block"></span>
                    </div>
                    <div class="ff">
                        <div class="block-title">Create a Custom Script</div>
                        <div class="block-description text-overflow">Write a custom transformation function using TypeScript.</div>
                    </div>
                </div>
                <div class="transformation-block flex flex-align" @click="create(block)" v-for="block of yours" :key="block.id">
                    <div class="transformation-block-icon flex flex-align flex-center">
                        <span class="icon iconoir-page-flip block"></span>
                    </div>
                    <div class="ff">
                        <div class="block-title">{{block.title}}</div>
                        <div class="block-description text-overflow">{{block.description || 'No description.'}}</div>
                    </div>
                </div>
            </div>
            <div class="drawer-section">
                <div class="flex drawer-section-header">
                    <h3>Standard Blocks</h3>
                </div>
                <div class="transformation-block flex flex-align" @click="create(block)" v-for="block of standard" :key="block.id">
                    <div class="transformation-block-icon flex flex-align flex-center">
                        <span class="icon block" :class="icon(block)"></span>
                    </div>
                    <div class="ff">
                        <div class="block-title">{{block.title}}</div>
                        <div class="block-description text-overflow">{{block.description || 'No description.'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CreateTransformation',
        data(){
            return {
                blocks: [],
                icons: {
                    '50060470-b7bd-439c-a40f-819b3d6fa9cf': 'iconoir-verified-badge', //transform-map-custom-roles
                    '8f8a3e02-3ae2-4e27-95f4-40136450f696': 'iconoir-clock-outline', //transform-class-state-by-session
                    '20c46eca-d261-4acc-a65a-a464fdf84e1f': 'iconoir-group', //transform-infer-roles-from-enrollments
                    '595259cf-552a-4103-8dea-ffb3a6234037': 'iconoir-pound', // UK Grade Levels
                }
            };
        },
        created(){
            // Fetch the existing transforms from the server.
            this.$http.get(`/teams/${this.team.id}/blocks`)
                .then(response => this.blocks = response.$data)
                .catch(error => this.$toasted.error('There was an error loading available code blocks.'));
        },
        methods: {
            create(block){
                if(this.props.create){
                    this.props.create(block);
                }

                this.close();
            },
            close(){
                this.$store.dispatch('drawer/close');
            },
            icon(block){
                if(this.icons.hasOwnProperty(block.id)){
                    return this.icons[block.id];
                }else{
                    return 'iconoir-page-flip';
                }
            }
        },
        computed: {
            yours(){
                return this.blocks.filter(b => b.team.id === this.team.id);
            },
            standard(){
                return this.blocks.filter(b => b.team.id !== this.team.id)
            },
            type(){
                return this.source ? 'sources' : 'integrations';
            },
            fns(){
                return this.$store.state.drawer.props;
            },
            props(){
                return this.$store.state.drawer.props;
            },
            source(){
                return this.props.source;
            },
            integration(){
                return this.props.integration;
            },
            application(){
                return this.props.application;
            },
            parent(){
                return this.source ? this.source : this.integration;
            },
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.getters.user;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-transformation
    {
        height: 100%;

        .drawer-section, .drawer-section:first-child
        {
            padding: 20px;
        }

        .drawer-section-header
        {
            padding-bottom: 10px;
        }
    }

    h3
    {
        font-size: 14px;
        color: @black;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        height: 16px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px 0 10px;
        &:last-child
        {
            border-bottom: none;
        }
    }

    .transformation-block
    {
        color: @black;
        cursor: pointer;
        padding: 10px;
        margin: 5px -10px;
        border-radius: 4px;
        // transition: background-color ease 0.1s;

        .icon
        {
            margin-right: 15px;
            font-size: 20px;
            width: 20px;
            height: 20px;
        }

        .block-title
        {
            font-size: 13px;
            font-weight: 500;
            color: @black;
            // transition: color ease 0.1s;
        }

        .block-description
        {
            font-size: 12px;
            color: @grey;
            margin-top: 4px;
            line-height: 16px;
            // transition: color ease 0.1s;
        }

        .transformation-block-icon
        {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background: fade(@base, 20%);
            margin-right: 15px;

            .icon
            {
                height: 18px;
                width: 18px;
                font-size: 18px;
                margin: 0;
                color: @base;
            }
        }

        &:hover, &:active
        {
            background: fade(@base, 15%);

            .block-title, .block-description
            {
                color: @base;
            }
        }
    }
</style>
