<template>
    <div class="entity-json">
        <div class="drawer-section">
            <pre class="card ff monospace" v-html="output" v-if="output"></pre>
        </div>
    </div>
</template>

<script>
    import Prism from 'prismjs';

    export default {
        name: 'EventJSON',
        data(){
            return {
                output: ''
            };
        },
        created(){
            try {
                //Color and format the JSON that represents this user.
                this.output = this.entity ? Prism.highlight(JSON.stringify(this.entity, null, 4), Prism.languages.json, 'json') : null;
            }catch(error){
                console.log(error);
            }
        },
        methods: {
            
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .drawer-section
    {
        padding: 25px;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 0;
    }
</style>
