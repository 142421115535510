<template>
    <div class="mapping">
        <!-- {{ mapping }} -->
        <div>
            <div class="map" v-for="(map, index) in mapping" :key="map[2]">
                <div class="ff"><input :placeholder="lhs.placeholder" v-model="map[0]"/></div>
                <div class="becomes flex flex-align flex-center">
                    <span class="icon iconoir-arrow-right"></span>
                </div>
                <div class="ff">
                    <search-select :values.sync="map[1]" :borderless="true" :enum="enum_map" :array="true" :placeholder="rhs.placeholder"/>
                </div>
                <div class="icon-button muted small icon iconoir-trash" @click="remove(index)"></div>
            </div>
        </div>
        <div class="flex flex-align options">
            <div class="button" @click="add()">{{ button_text }}</div>
        </div>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue';

export default {
    name: 'Mapping',
    components: {
        'search-select': SearchSelect
    },
    props: {
        enum: {
            type: Object,
            default: () => {return {}}
        },
        lhs: {
            type: Object,
            default: () => {
                return {
                    placeholder: 'Select a value',
                    array: false,
                    max: 1
                }
            }
        },
        rhs: {
            type: Object,
            default: () => {
                return {
                    placeholder: 'Select a value',
                    array: false,
                    max: 1
                }
            }
        },
        value: {
            type: Object,
            default: () => {return { '': [] }}
        },
        button_text: {
            type: String,
            default: 'Add another'
        }
    },
    data() {
        return {
            mapping: []
        }
    },
    created(){
        if (this.value && Object.keys(this.value).length > 0) {
            // Copy the initial value
            for (const [key, value] of Object.entries(this.value)) {
                this.mapping.push([key, value, this.generate_key()]);
            }
        } else {
            this.mapping.push(['', [], this.generate_key()]);
        }
        // Apply watcher after the initial value is set
        this.$watch('mapping', () => {
            const formatted = {};
            for (const [key, value] of this.mapping) {
                if (key !== '' && value) {
                    formatted[key] = value;
                }
            }
            this.$emit('update:value', formatted);
            this.$emit('input', formatted);
        }, {deep: true});
    },
    computed: {
        // Dirty hack to let me recieve 'enum' but pass it to the search-select as 'enum_map'
        // This is because 'enum' is a reserved keyword and vue gets mad otherwise
        enum_map() {
            return this.enum;
        }
    },
    methods: {
        generate_key(len) {
            var arr = new Uint8Array((len || 40) / 2);
            window.crypto.getRandomValues(arr);
            return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join('');
        },
        get_rhs(index) {
            return this.mapping[index][1] ? [this.mapping[index][1]] : [];
        },
        update_rhs(index, value) {
            this.$set(this.mapping[index], 1, value ?? []);
        },
        add() {
            this.mapping.push(['', [], this.generate_key()]);
        },
        remove(index) {
            if (this.mapping.length > 1) {
                this.mapping.splice(index, 1);
            } else {
                this.$set(this.mapping[index], 0, '');
                this.$set(this.mapping[index], 1, []);
                this.$set(this.mapping[index], 2, this.generate_key());
            }
        }
    },
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.mapping {
    display: flex;
    flex-direction: column;
    width: 100%;
    .map {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid fade(@e4, 50%);

        input, select {
            border: none;
            background: transparent;
            outline: none;
            // padding: 4px 0;
        }

        &:hover
        {
            .icon-button.muted
            {
                color: @red
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .becomes
    {
        color: @e4;
        font-size: 13px;
        line-height: 18px;
        width: 30px;

        .icon
        {
            font-size: 17px;
            color: @e4;
        }
    }

}

.options {
    margin-top: 15px;
}

.icon-button
{
    font-size: 28px;
    color: @base;
    cursor: pointer;
    transition: color linear 0.1s;

    &.small
    {
        font-size: 20px;
    }

    &.red
    {
        color: @red;

        &:hover
        {
            color: fade(@red, 70%);
        }
    }

    &:hover
    {
        color: fade(@base, 70%);
    }

    &.floating
    {
        position: absolute;
        right: -40px;
        top: 8px;
    }

    &.muted
    {
        color: @c;
        padding: 10px;
        transition: color linear 0.1s;
        &:hover
        {
            color: @red
        }
    }
}
</style>