import store from '../store';

export default ({ next, router, to }) => {
    const team = store.state.teams.all.find((team) => team.alias === to.params.team);

    if (team) {
        return store.dispatch('teams/activate', team).then(() => next());
    } else if (store.getters.user.admin) {
        // The admin may not have the team downloaded to their local store.
        // We may be able to proceed anyway.
        if (to.params.team) {
            return store.dispatch('teams/alias', to.params.team).then(() => next());
        }
    }

    // If there is no active team, we must be new here.
    if (store.state.teams.active) {
        if (store.state.teams.active.type === 'developer') {
            router.push({ name: 'team.dashboard', params: { team: store.state.teams.active.alias } });
        } else {
            router.push({ name: 'team.sources', params: { team: store.state.teams.active.alias } });
        }
    } else {
        router.push('/onboard');
    }
};
