import { createMachine, assign } from 'xstate';

function isLoggedIn(ctx) {
    return !!ctx.user?.id;
}

function teamSelected(ctx) {
    return !!ctx.team;
}

function hasIntegration(ctx) {
    return !!ctx.integration;
}

function sourceProvided(ctx) {
    return !!ctx.source;
}

export const integrate = createMachine({
    id: 'integrate',
    initial: 'initializing',
    context: {
        // If there's some error message that we want to display, we can set it here.
        error: null,

        // Is this an 'essensial' onboarding
        essential: false,

        // This item is always necessary.
        application: null,

        // For dealing with our account states.
        name: '',
        email: '',
        password: '',
        two: '',
        user: null,
        sso: null,

        // For dealing with our district states.
        district: '',
        team: null,

        source: null,
        provider: null,

        // Destination details.
        destination_source_required: false,
        destination_provider: null,
        destination_source: null,

        sources: [],

        // For building an integration.
        primary_provider: null,
        primary_source: null,
        integration: null,

        // Enrichment details.
        enrichment_provider: null,
        enrichment_source: null,

        // SSO source details.
        sso_provider: null,
        sso_source: null
    },
    on: {
        /*
            Side menu tabs
        */
        ERROR: {
            target: 'error',
            actions: assign({
                error: (ctx, event) => event.data
            })
        },
        APPLICATION: 'application',
        ACCOUNT: 'account',
        DISTRICT: { target: 'district', cond: isLoggedIn },
        SOURCE: {
            target: 'source',
            cond: teamSelected,
            actions: assign({
                source: (ctx, event) => null,
                provider: (ctx, event) => null
            })
        },
        DESTINATION_SOURCE: {
            target: 'destination_source',
            cond: sourceProvided,
            actions: assign({
                destination_source: (ctx, event) => null,
                destination_provider: (ctx, event) => null
            })
        },
        INTEGRATION: { target: 'integration', cond: hasIntegration },
        COMPLETE: {
            target: 'complete',
            cond: hasIntegration,
            actions: assign({
                integration: (ctx, event) => event.data
            })
        },

        SELECT_DISTRICT: 'district.select',
        CREATE_DISTRICT: 'district.create',
        CLAIMED_DISTRICT: {
            target: 'district.claimed',
            actions: assign({
                team: (ctx, event) => event.data,
                source: () => null
            })
        },
        CONFIRM_DISTRICT: {
            actions: assign({
                team: (ctx, event) => event.data,
                source: () => null
            })
        },
        LICENSE: 'license',
        SET_USER: {
            actions: assign({
                user: (ctx, event) => event.data
            })
        },

        CREATE_SOURCE: {
            target: 'source.create',
            actions: assign({
                source: (ctx, event) => null,
                provider: (ctx, event) => null
            })
        },
        SELECT_SOURCE: {
            target: 'source.select',
            actions: assign({
                source: (ctx, event) => null,
                provider: (ctx, event) => null
            })
        },

        READY_FOR_INTEGRATION: {
            target: 'integration',
            actions: assign({
                integration: (ctx, event) => event.data
            })
        }
    },
    states: {
        account: {
            on: {
                PREVIOUS: 'application',
                TWO: 'account.two',
                TERMS: 'account.terms',
                INVITES: 'account.invitations'
            },
            initial: 'email',
            states: {
                email: {
                    on: {
                        EMAIL: {
                            actions: assign({
                                email: (ctx, event) => event.data
                            })
                        },
                        PASSWORD: {
                            target: 'login',
                            actions: assign({
                                name: (ctx, event) => event.data
                            })
                        },
                        SSO: {
                            target: 'sso',
                            actions: assign({
                                sso: (ctx, event) => event.data
                            })
                        },
                        REGISTER: 'register'
                    }
                },
                sso: {
                    on: {
                        // TODO Handle the Google SSO response. Exchange the token and log the user in.
                    }
                },
                register: {
                    initial: 'password',
                    on: {
                        PASSWORD: {
                            actions: assign({
                                password: (ctx, event) => event.data
                            })
                        }
                    },
                    states: {
                        password: {}
                    }
                },
                login: {
                    initial: 'password',
                    states: {
                        password: {
                            on: {
                                PASSWORD: {
                                    actions: assign({
                                        password: (ctx, event) => event.data
                                    })
                                },
                                TWO: 'two'
                            }
                        },
                        two: {
                            on: {
                                TWO: {
                                    actions: assign({
                                        two: (ctx, event) => event.data
                                    })
                                }
                            }
                        }
                    }
                },
                two: {
                    initial: 'setup',
                    states: {
                        setup: {
                            on: {
                                ENABLE: 'confirmation'
                            }
                        },
                        confirmation: {
                            on: {}
                        }
                    }
                },
                terms: {
                    on: {}
                },
                invitations: {}
            }
        },
        application: {},
        complete: {},
        district: {
            initial: 'select',
            states: {
                select: {},
                create: {},
                claimed: {}
            }
        },
        license: {},
        destination_source: {
            initial: 'select',
            on: {
                UPDATE_SOURCE: {
                    actions: assign({
                        destination_source: (ctx, event) => {
                            return _.set({ ...ctx.destination_source }, event.path, event.value);
                        }
                    })
                }
            },
            states: {
                select: {
                    on: {
                        CREATE_SOURCE: {
                            target: 'create',
                            actions: assign({
                                destination_source: (ctx, event) => null,
                                destination_provider: (ctx, event) => null
                            })
                        },
                        SET_DESTINATION_SOURCE: {
                            actions: assign({
                                destination_provider: (ctx, event) => {
                                    return event.data.source.provider;
                                },
                                destination_source: (ctx, event) => {
                                    return event.data.source;
                                },
                                integration: (ctx, event) => {
                                    return event.data.integration;
                                }
                            })
                        }
                    }
                },
                create: {
                    initial: 'set_provider',
                    states: {
                        set_provider: {
                            on: {
                                SET_PROVIDER: {
                                    actions: assign({
                                        destination_provider: (ctx, event) => event.data.source.provider,
                                        destination_source: (ctx, event) => event.data.source
                                    })
                                },
                                NEXT_STEP: 'set_region',
                                EDIT_CONFIG: 'edit_config'
                            }
                        },
                        set_region: {
                            on: {
                                NEXT_STEP: 'edit_config'
                            }
                        },
                        edit_config: {}
                    }
                }
            }
        },
        error: {},
        initializing: {
            on: {
                APPLICATION: {
                    target: 'application',
                    actions: assign({
                        application: (ctx, event) => event.data,
                        destination_source_required: (ctx, event) => ['979dde54-832a-4814-ad50-4b30d23f23c7'].includes(event.data.id)
                    })
                }
            }
        },
        integration: {
            id: 'integrate.integration',
            initial: 'sharing',
            on: {
                UPDATE_INTEGRATION: {
                    actions: assign({
                        integration: (ctx, event) => {
                            return _.set({ ...ctx.integration }, event.path, event.value);
                        }
                    })
                }
            },
            states: {
                // Set integration scope and sharing rules, if applicable.
                sharing: {
                    initial: 'enable',
                    on: {
                        SCHEDULE: 'schedule'
                    },
                    states: {
                        // Does the user want to enable rules
                        enable: {},

                        // Configure rules?
                        rules: {}
                    }
                },

                // Decide if our integration is going to start now, or later.
                schedule: {},

                // Decide if we're going to set up LTI apps (if this is an LTI-capable system).
                lti: {
                    states: {
                        // Does the user want to deal with LTI right now, or not?
                        enabled: {},

                        // Configure this LTI application automatically (Canvas).
                        automatic: {},

                        // Configure this LTI application manually.
                        manual: {}
                    }
                }
            }
        },
        source: {
            initial: 'select',
            on: {
                ENRICH_SOURCE: 'source.enrich',
                ENRICH_SOURCE_SELECTION: 'source.enrich.select',
                SELECT_ENRICH_SOURCE: {
                    actions: assign({
                        enrichment_source: (ctx, event) => event.data.source
                    })
                },
                ENRICH_SOURCE_SELECTED: 'source.enrich.selected',
                UPDATE_SOURCE: {
                    actions: assign({
                        source: (ctx, event) => {
                            return _.set({ ...ctx.source }, event.path, event.value);
                        }
                    })
                }
            },
            states: {
                select: {
                    on: {
                        SET_PRIMARY_SOURCE: {
                            actions: assign({
                                primary_provider: (ctx, event) => {
                                    return event.data.source.provider;
                                },
                                primary_source: (ctx, event) => {
                                    return event.data.source;
                                },
                                integration: (ctx, event) => {
                                    return event.data.integration;
                                },
                                source: (ctx, event) => {
                                    return event.data.source;
                                }
                            })
                        }
                    }
                },
                create: {
                    initial: 'set_provider',
                    // on: {
                    //     VALIDATE: 'create.validate',
                    //     CONFIGURE_REMOTE: 'create.configure.remote',
                    //     CONFIGURE_URL: 'create.configure.url',
                    //     CONFIGURE_KEYS: 'create.configure.keys',
                    //     CONFIGURE_SECRET: 'create.configure.secret',
                    //     CONFIGURE_CONSENT: 'create.configure.consent',
                    //     CONFIGURE_LOGIN: 'create.configure.login',
                    //     CONFIGURE_REGION: 'create.configure.region',
                    //     CONFIGURE_SFTP: 'create.configure.sftp.prompt'
                    // },
                    states: {
                        set_provider: {
                            on: {
                                SET_PROVIDER: {
                                    actions: assign({
                                        provider: (ctx, event) => event.data.source.provider,
                                        source: (ctx, event) => event.data.source
                                    })
                                },
                                NEXT_STEP: 'set_region'
                            }
                        },
                        set_region: {
                            on: {
                                NEXT_STEP: 'edit_config'
                            }
                        },
                        edit_config: {},
                        enrich: {
                            // Select an existing source, if you have any.
                            select: {},

                            // Create a new source from scratch.
                            create: {
                                initial: 'provider',
                                on: {
                                    CREATING_SOURCE: 'creating'
                                },
                                states: {
                                    // Select a source provider.
                                    provider: {
                                        on: {
                                            SELECT_PROVIDER: {
                                                actions: assign({
                                                    provider: (ctx, event) => event.data.provider,
                                                    source: (ctx, event) => event.data.source
                                                })
                                            },
                                            CONFIGURE_REMOTE: 'configure.remote',
                                            CONFIGURE_URL: 'configure.url'
                                        }
                                    },

                                    // This is where we actually configure our new source.

                                    configure: {
                                        initial: 'url',
                                        states: {
                                            // If this source allows for custom URLs, ask that first.
                                            url: {
                                                on: {
                                                    REMOTE: 'remote',
                                                    KEYS: 'keys',
                                                    CONSENT: 'consent'
                                                }
                                            },

                                            // Any remote configuration that is required.
                                            remote: {
                                                on: {
                                                    KEYS: 'keys',
                                                    CONSENT: 'consent'
                                                }
                                            },

                                            // If the source requires specific keys to be pasted in, do that here.
                                            keys: {
                                                on: {
                                                    CONSENT: 'consent'
                                                }
                                            },

                                            // This will cover the admin hookup process (including Microsoft's grant phase).
                                            consent: {}
                                        }
                                    },

                                    // Create the new source in the backend.
                                    creating: {
                                        on: {
                                            CREATED: 'validate'
                                        }
                                    },

                                    // Check to make sure our new source has been configured correctly.
                                    validate: {},

                                    // Allow the user to specify an enrichment source if this is an LMS provider.
                                    enrich: {},

                                    // Hook up an SSO provider to an SIS provider without SSO.
                                    sso: {}
                                }
                            }
                        }
                    }
                },
                /*
                    Select an enrichment source, if you have any
                */
                enrich: {
                    initial: 'prompt',
                    states: {
                        prompt: {},
                        select: {},
                        selected: {}
                    }
                }
            }
        }
    }
});
