<template>
    <section class="source-staged-changes">
        <div class="section-content ff">
            <h2>Staged Changes</h2>
            <div class="card" v-if="events.length">
                <table>
                    <thead>
                        <tr class="sync">
                            <th class="sync-status">Action</th>
                            <th>Object ID</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="sync" v-for="event of events" :key="event.id">
                            <td class="sync-status">
                                <div class="flex">
                                    <div class="sync-status-badge" :class="event.type">{{event.type}}</div>
                                </div>
                            </td>
                            <td>{{event.target.id}}</td>
                            <td class="capitalize">{{event.target.type}}</td>
                        </tr>
                        <tr v-if="more">
                            <td colspan="3" class="table-load">
                                <div class="text-button" @click="$emit('load')">Load More</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="nothing" v-else>
                Nothing was changed during this sync.
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'SyncStagedChanges',
        props:{
            events: Array,
            more: Boolean
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }
    
    section
    {
        .section-content
        {
            border-top: 1px solid @e4;
            padding: 25px 0;
        }

        &:first-child
        {
            .section-content
            {
                border-top: 0;
            }
        }
    }

    .sync-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .summary
    {
        padding: 20px;
        font-size: 14px;

        .summary-field
        {
            height: 22px;
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }

        .summary-key
        {
            color: @grey;
            width: 180px;
        }

        .summary-value
        {
            color: @black;
        }
    }

    .staged-changes
    {
        nav
        {
            padding: 0 20px;
            border-bottom: 1px solid @e4;
        }

        .nothing
        {
            font-size: 13px;
            border: 0;
        }
    }

    table
    {
        td, th
        {
            padding: 8px 20px;

            &:first-child, &:last-child
            {
                padding: 8px 20px;
            }
        }

        thead
        {
            tr
            {
                border-top: 0;
            }
        }
    }
</style>