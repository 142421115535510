<template>
    <div class="admin">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'Admin',
        computed: {
            
        },
        methods: {

        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .admin
    {
        min-height: 100vh;
    }
</style>
