<template>
    <div class="integrate-account-register-password">
        <h1>Create a Password For Your Account</h1>
        <div class="helptext">It's important that your password is strong so nobody is able to sign into your account (except you). Be sure not to share your password with anyone else.</div>
        <input ref="password" type="password" class="medium" placeholder="Create a password..." autocomplete="false" @input="event => send({type: 'PASSWORD', data: event.target.value})" @keydown.enter="register" />
        <transition name="fade">
            <!-- TODO This is where we'll put a magic link button -->
            <div class="options flex" key="email">
                <div class="button" kbd="Enter ⏎" @click="register">Register</div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateAccountRegisterPassword',
        props: {
            state: Object,
            send: Function
        },
        created(){
            this.$nextTick(() => this.$refs.password.focus());
        },
        methods: {
            register(){
                //TODO Password security check.
                if(this.state.context.password.trim().length < 6){
                    return this.$toasted.error('Your password must be longer than 6 characters.');
                }

                this.$http.post('/register', {
                    email: this.state.context.email,
                    password: this.state.context.password
                })
                .then(response => this.$store.dispatch('user/login', response.$data.token))
                .then(() => this.send('TERMS'))
                .catch(error => this.$toasted.error(error.response.$data ? error.response.$data.details : 'There was an error creating your account.'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate
    {
        .helptext
        {
            margin-bottom: 20px;
        }
    }
</style>
