<template>
    <div class="entity-details">
        <datatable class="card" :columns="columns" :rows="events" :loading="loading" :header="true" :clickable="true" @clicked="event => focus(event)">
            <template v-slot:empty-message>This entity was not modified in the past 30 days.</template>
        </datatable>
    </div>
</template>

<script>
    const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

    import Event from '@/components/drawers/Event.vue';
    import { AlignLeft, Sparks, Calendar } from '@epiphany/iconoir';

    export default {
        name: 'EntityEvents',
        data(){
            return {
                loading: true,
                events: [],
                cursor: '',
                columns: [
                    {
                        id: 'type',
                        name: 'Event Type',
                        icon: AlignLeft,
                        width: '40%',
                        value: row => row.type + ' ' + row.target
                    },
                    {
                        id: 'materialization',
                        name: 'Materialization',
                        icon: Sparks,
                        width: '30%',
                        value: row => row.materialization_id
                    },
                    {
                        id: 'date',
                        name: 'Event Date',
                        icon: Calendar,
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.date, 'short')
                    }
                ]
            };
        },
        created(){
            this.$http.get(`${base}/api/v2/graph/events`, {
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                },
                params: {
                    $last: 100,
                    // $expand: 'class,section',
                    $fields: 'id,date,type,target,materialization_id',
                    $filter: {
                        target_id: [{ operator: 'equals', value: this.entity.id }]
                    }
                }
            })
            .then(response => {
                this.events = response.$data;
                this.loading = false;
            })
            .catch(() => this.$toasted.error('There was an error loading the events for this entity.'));
        },
        methods: {
            focus(event){
                // Enter loading state.
                this.$store.dispatch('drawer/loading');
                
                // Swap drawer component.
                this.$store.dispatch('drawer/replace', {
                    key: `entity/${event.id}`,
                    width: 800,
                    component: Event,
                    props: {
                        integration: this.integration,
                        entity: event,
                        type: 'events'
                    }
                });

                // Activate this entity.
                this.$store.dispatch('entities/activate', {
                    id: event.id,
                    type: 'events',
                    integration: this.integration
                })
                .then(() => {
                    // Exit loading state.
                    this.$store.dispatch('drawer/ready')
                });
            },
            status(enrollment){
                return {
                    red: ['dropped'].includes(enrollment.state),
                    yellow: ['upcoming'].includes(enrollment.state),
                    green: ['active'].includes(enrollment.state),
                    grey: ['pending', 'inactive'].includes(enrollment.state),
                    purple: ['completed'].includes(enrollment.state)
                };
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            integration(){
                return this.$store.state.entities.integration;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
