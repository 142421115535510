<template>
    <div class="integrate-source-provider-infinitecampus">
        <integrate-source-editor-step name="Navigate to Infinite Campus Learning Interoperability" icon="iconoir-info-empty" :complete="completed.includes('remote')">
            <integrate-source-editor-configuration title="Locate Infinite Campus Settings" icon="iconoir-info-empty">
                <div class="image-label">
                    1. Click <code>Instruction</code> in the side bar.
                </div>
                <div class="image-label">
                    2. Under <code>Settings</code> select <code>Digital Learning Application Configuration</code>.
                </div>
                <div class="image-label">
                    3. Select <code>Other OneRoster Provisioning Credentials</code>
                </div>
                <div class="image-label">
                    4. Click <code>Generate New OneRoster Connection</code>
                </div>
                <div class="image-label">
                    5. Select OneRoster 1.2
                </div>
                <div class="image-label">
                    6. Click Generate
                </div>
            </integrate-source-editor-configuration>

            <!-- <integrate-source-editor-configuration title="OAuth Version" icon="iconoir-info-empty">
                <div class="image-label">
                    You now have two options: <code>OneRoster 1.1</code> with <code>OAuth 1.0</code> or with <code>OAuth 2.0</code>.
                    We recommend selecting <code>OAuth 2.0</code>. Whichever you choose, select the corresponding option below.
                </div>
            </integrate-source-editor-configuration> -->

            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Enter Your Infinite Campus OneRoster API URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the Infinite Campus OneRoster connection Base URL.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://sis.infinitecampus.org/api/oneroster/v1p2" :value="source.configuration.oneroster_server_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_server_url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        
        <integrate-source-editor-step name="Enter Your Infinite Campus OneRoster Token Endpoint" icon="iconoir-www" :complete="completed.includes('token')">
            <div class="helptext">
                This is the Infinite Campus OneRoster connection Token URL.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://sis.infinitecampus.org/oauth2/token" :value="source.configuration.oauth_token_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oauth_token_url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <div class="helptext">
                Enter the OneRoster keys Infinite Campus provided to you.
            </div>
            <label class="key-label">Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.oneroster_client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_id', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.oneroster_client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_secret', value: e.target.value})" />
        </integrate-source-editor-step>

        <!-- <one-roster-oauth-steps :completed="completed" :state="state" :send="send" /> -->
        
        <!-- <integrate-source-editor-step name="Input the Key Expiration Date" icon="iconoir-calendar" :complete="completed.includes('date')">
            <div class="helptext">
                The date at which the OneRoster keys will expire in the format of (MM/DD/YYYY).
            </div>
            <label class="key-label">Key Expiration Date</label>
            <input class="medium client-secret" type="text" placeholder="(e.g. 01/01/1970)" :value="source.configuration.expiration_date" @input="e => send('UPDATE_SOURCE', {path: 'configuration.expiration_date', value: e.target.value})" />
        </integrate-source-editor-step> -->
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import OneRosterOAuthSteps from './components/OneRosterOAuthSteps.vue';
    import { timezone_identifiers } from '@/enums';

    export default {
        name: 'IntegrateSourceProviderInfiniteCampus',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration,
            'one-roster-oauth-steps': OneRosterOAuthSteps
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            version(){
                return this.source.configuration.version;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if (!this.remote) {
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.oneroster_server_url || !this.source.configuration.oneroster_server_url.match(url)){
                    return ['remote'];
                }

                if(!this.source.configuration.oauth_token_url || !this.source.configuration.oauth_token_url.match(url)){
                    return ['remote', 'url'];
                }

                // 3. Confirm that keys are set
                if(!this.source.configuration.oneroster_client_id?.trim().length || !this.source.configuration.oneroster_client_secret?.trim().length){
                    return ['remote', 'url', 'token'];
                }

                return ['remote', 'url', 'token', 'credentials'];
            }
        },
        methods: {
            // auth(mode){
            //     if(this.version === '1.0'){
            //         this.send('UPDATE_SOURCE', {path: 'configuration.oauth_token_url', value: ''});
            //     }

            //     this.send('UPDATE_SOURCE', {path: 'configuration.version', value: mode});
            // }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        data(){
            return {
                remote: false,
                timezones: timezone_identifiers
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
