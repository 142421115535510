<template>
    <div class="edit-transformation flex flex-column" v-if="transformation">
        <header class="flex flex-align">
            <div class="ff" v-if="known">
                <div class="transformation-title">{{transformation.block.title}}</div>
                <div class="transformation-description">
                    {{transformation.block.description}}
                    <template v-if="transformation.block.id === '91822395-5809-4ea2-96f6-c853341cbcc9'">
                        Enrichment is currently <span class="emphasis">{{source.enrichment ? 'Enabled' : 'Disabled'}}</span>.
                    </template>
                </div>
            </div>
            <div v-else class="ff">
                <input class="transformation-title edit-transformation-title" :value="block.title" placeholder="Untitled Transformation" @input="e => commit({title: e.target.value})" />
                <textarea class="transformation-description edit-transformation-description" :value="block.description" placeholder="No Description" @input="e => commit({description: e.target.value})"></textarea>
            </div>
            <div class="button" @click="save()">Save Changes</div>
            <div class="button white red" @click="$store.dispatch('drawer/close')">Cancel</div>
        </header>
        <div class="edit-transformation-body ff">
            <component v-if="known" :is="blocks[this.transformation.block.id]" :source="source" :integration="integration" :transformation="transformation" @input="value => edit(value)" />
            <div class="edit-transformation-function" ref="editor" v-else></div>
        </div>
    </div>
</template>

<script>
    import { EditorState, EditorView, basicSetup } from '@codemirror/basic-setup';
    import { javascript } from '@codemirror/lang-javascript';
    import _ from 'lodash';

    export default {
        name: 'EditTransformation',
        computed: {
            transformation() {
                return this.$store.state.drawer.props.transformation;
            },
            integration() {
                return this.$store.state.drawer.props.integration;
            },
            source() {
                return this.$store.state.drawer.props.source;
            },
            known(){
                return this.blocks.hasOwnProperty(this.transformation.block.id);
            },
            block(){
                return this.$store.state.blocks.active;
            }
        },
        data(){
            return {
                editor: null,
                blocks: {
                    '12be6249-5407-4e50-a331-ac305b70d108': 'transform-source-data-overrides',
                    '91822395-5809-4ea2-96f6-c853341cbcc9': 'transform-data-enrichment',
                    'dfc54590-b8b3-4a35-9c26-b6ef3e3d45b5': 'transform-data-validation',
                    '50060470-b7bd-439c-a40f-819b3d6fa9cf': 'transform-map-custom-roles',
                    'd1df7a33-c5f1-49d7-850d-d03c7ecb335f': 'transform-sharing-rules',
                    'fcf52502-89da-4fd3-bb67-10afba97f29c': 'transform-shared-data-overrides',
                    'dbced7ec-f955-46b0-b4fa-607a5eead3af': 'transform-privacy-mask',
                    '8f8a3e02-3ae2-4e27-95f4-40136450f696': 'transform-class-state-by-session',
                    '20c46eca-d261-4acc-a65a-a464fdf84e1f': 'transform-infer-roles-from-enrollments',
                    '09082509-62b3-46da-b6a7-9a48cba0a880': 'transform-construct-email-addresses',
                    'c489379d-a3c0-4158-98a4-665eed1aa717': 'transform-hide-classes-inactive-sessions',
                    'a6c5b3df-c684-4575-adc3-bb19cc9b3ae3': 'transform-kebab-case',
                    '6ab5c8a0-23cc-46f1-8173-292e50a46218': 'transform-normalize-phone-numbers',
                    '578bda1a-f540-4dd2-9819-5fdf509e9638': 'transform-normalize-state-names'
                }
            };
        },
        created(){
            if(this.known){
                // Activate the transformation.
                this.$store.dispatch('transformations/activate', this.transformation);
            }else{
                // Activate the block.
                this.$store.dispatch('blocks/activate', this.transformation.block);
            }
        },
        mounted(){
            if(this.block){
                const fixedHeightEditor = EditorView.theme({
                    // '&': {
                    //     height: '450px',
                    //     position: 'static'
                    // },
                    '& *': {
                        position: 'static'
                    },
                    '&.cm-editor.cm-focused': {
                        outline: 'none'
                    },
                    '.cm-scroller': {
                        overflow: 'auto'
                    },
                    '.cm-gutters': {
                        border: 0,
                        background: 'none'
                    }
                });

                this.editor = new EditorView({
                    state: EditorState.create({
                        extensions: [
                            basicSetup,
                            javascript(),
                            fixedHeightEditor,
                            EditorView.updateListener.of(update => {
                                this.$store.commit('blocks/update', { function: this.editor.state.doc.toString() })
                            })
                        ],
                        doc: this.transformation.block.function
                    }),
                    parent: this.$refs.editor
                });
            }
        },
        destroyed(){
            if(this.editor){
                this.editor.destroy();
            }
        },
        methods: {
            commit(changes){
                this.$store.commit('blocks/update', changes);
            },
            save(){
                // Close the drawer.
                this.$store.dispatch('drawer/close')

                if(this.known){
                    // We only need to update the transformation configuration.
                    this.$store.dispatch('transformations/update');
                }else{
                    // We need to actually update the block itself.
                    this.$store.dispatch('blocks/update');
                }
            },
            edit(updated){
                this.$store.commit('transformations/update', {configuration: _.cloneDeep(updated)});
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .edit-transformation
    {
        height: 100%;

        header
        {
            // margin-bottom: 4px;
            border-bottom: 1px solid @e4;
            padding: 15px 20px;
            align-items: center;
        }

        .edit-transformation-body
        {
            height: 100%;
            padding: 0px 20px;
            overflow: hidden auto;
        }

        .button
        {
            margin-left: 20px;
        }
    }

    .edit-transformation-function
    {
        font-family: @monospace;
        font-size: 13px;
        height: 100%;
    }

    .transformation-title
    {
        color: @black;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 6px;
    }

    .transformation-description
    {
        color: @grey;
        font-size: 13px;
        line-height: 18px;
        max-width: 450px;
    }

    .edit-transformation-title, .edit-transformation-description
    {
        padding: 0;
        border: 0;
        border-radius: 0;

        &:focus
        {
            outline: 0;
            box-shadow: none;
        }
    }

    .edit-transformation-title
    {
        height: 20px;
        width: 400px;
    }

    .edit-transformation-description
    {
        margin-bottom: 0;
        height: 18px;
        min-height: 0;
    }
</style>