<template>
    <chip color="blue" title="Edlink" v-if="enabled">
        <template #icon>
           <Crown class="icon" width="14" height="14" stroke-width="2" />
        </template>
    </chip>
</template>

<script>
    import { Crown } from '@epiphany/iconoir';

    export default {
        name: 'EdlinkStaff',
        components: {
            Crown
        },
        props: {
            enabled: Boolean
        },
        data(){
            return {};
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
