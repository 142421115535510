<template>
    <div class="integrate-account-password">
        <label>Two-Factor Authentication</label>
        <input ref="two" type="text" placeholder="2FA Code..." autocomplete="false" @input="event => send({type: 'TWO', data: event.target.value})" @keydown.enter="login" />
        <div class="options flex" key="email">
            <div class="button" kbd="Enter ⏎" @click="login">Confirm 2FA Code</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateAccountLoginTwoFactor',
        props: {
            state: Object,
            send: Function
        },
        created(){
            this.$nextTick(() => this.$refs.two.focus());
        },
        computed: {
            invitations(){
                return this.$store.getters.user?.invitations;
            }
        },
        methods: {
            login(){
                this.$http.post('/challenge', {code: this.state.context.two})
                .then(response => {
                    this.$store.dispatch('user/login')
                    .then(() => {
                        this.send({ type: 'SET_USER', data: this.$store.getters.user });
                        
                        if(this.$store.getters.team){
                            if(this.invitations?.length){
                                this.send('INVITES');
                            } else {
                                this.send('SELECT_DISTRICT');
                            }
                        }else{
                            this.send('TERMS');
                        }
                    });
                })
                .catch(({ $error }) => {
                    this.$toasted.error($error ? $error : 'There was an error logging you in.');
                });
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-account-email
    {
        .sso
        {
            background-repeat: no-repeat;
            margin-right: 10px;

            &.google
            {
                background-image: url('~@/assets/icons/source/google.svg');
                background-size: 18px;
                background-position: 9px 7px;
                padding-left: 34px;
            }

            &.microsoft
            {
                background-image: url('~@/assets/icons/source/microsoft.svg');
                background-size: 57px;
                background-position: -9px 7px;
                padding-left: 36px;
            }
        }
    }
</style>
