<template>
    <div class="integrate-sharing-rules-enable flex flex-column">
        <div>
            <h1 class="title">Creating Your Integration</h1>
            <div class="subtext">
                Hang tight...
            </div>
        </div>
        <spinner />
        <!-- <div class="flex flex-align integrate-video">
            <span class="icon iconoir-media-video-list"></span> Watch: Using Sharing Rules
        </div> -->
        <!-- <h1>Choose How You Want to Share</h1>
        <div class="helptext">
            Edlink allows you to set controls on the data that you want to share with {{application.name}}.
            Sharing Rules can limit risk to your school as well as help ensure that only the right people have access to {{application.team.name}}.
        </div>
        <div class="flex sharing-types">
            <div class="sharing-type ff flex flex-column" :class="{active: scope === 'all'}" @click="toggle('all')">
                <div class="sharing-icon flex">
                    <span class="icon iconoir-database-backup"></span>
                </div>
                <div class="sharing-type-title">Share All Data</div>
                <div class="sharing-type-description">
                    Shares all data from throughout your school district. This includes all people,
                    schools, and classes.
                </div>
            </div>
            <div class="sharing-type ff flex flex-column" :class="{active: scope === 'rules'}" @click="toggle('rules')">
                <div class="sharing-icon flex">
                    <span class="icon iconoir-filter"></span>
                </div>
                <div class="sharing-type-title">Share By Rules</div>
                <div class="sharing-type-description">
                    Define rules about which data you'd like to share. Rules are dynamic and automatically
                    update the shared data.
                </div>
            </div>
        </div> -->
        <!-- <div class="flex flex-align">
            <div class="button" @click="next" kbd="Enter ⏎">Confirm</div>
        </div> -->
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'IntegrateLicensing',
        props: {
            state: Object,
            send: Function
        },
        mounted() {
            // window.addEventListener('keydown', this.keydown);
            this.next();
        },
        destroyed() {
            // window.removeEventListener('keydown', this.keydown);
        },
        computed: {
            scope() {
                return this.state.context.integration.scope;
            },
            source() {
                return this.state.context.primary_source;
            },
            application() {
                return this.state.context.application;
            },
            team() {
                return this.state.context.team;
            },
            integration() {
                return this.state.context.integration;
            },
            onboarding(){
                return this.$store.state.integrate.onboarding;
            }
        },
        methods: {
            toggle(type) {
                this.send('UPDATE_INTEGRATION', {
                    path: 'scope',
                    value: type
                });
            },
            next() {
                setTimeout(() => {
                    const integration = _.cloneDeep(this.integration);

                    if(this.onboarding){
                        integration.onboarding_id = this.onboarding.id;
                    }

                    this.$http.post(`/teams/${this.team.id}/integrations`, integration)
                        .then(response => setTimeout(this.send({
                            type: 'COMPLETE',
                            data: response.$data
                        }), 500))
                        .catch(error => {
                            if (error.response?.status === 406) {
                                this.$toasted.error('The source(s) you have selected are not compatible with this integration. Please try again or contact support.');
                            } else {
                                this.$toasted.error('There was an error creating your integration.');
                            }

                            this.send({
                                type: 'SELECT_SOURCE'
                            });
                        });
                }, 2000);
            },
            keydown(e) {
                if (e.code === 'Enter') {
                    this.next();
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .subtext
    {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        margin-bottom: 50px;
    }

    .integrate-sharing-rules-enable
    {
        align-items: flex-start;
    }

    .sharing-types
    {
        margin: 20px 0 30px;
    }

    .sharing-type
    {
        border: 1px solid @d;
        border-radius: 3px;
        text-transform: none;
        margin: 0;
        cursor: pointer;
        margin-right: 20px;
        padding: 15px;
        max-width: 250px;

        .sharing-type-title
        {
            font-size: 14px;
            font-weight: 500;
            color: @black;
            margin-bottom: 6px;
        }

        .sharing-type-description
        {
            color: @black;
            font-size: 12px;
            line-height: 16px;
        }

        &.active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;

            .sharing-icon
            {
                color: @base;
            }

            .sharing-type-header
            {
                border-right: 1px solid @base;
                background-color: fade(@base, 10%);
            }

            .sharing-type-title
            {
                color: @base;
            }
        }

        .sharing-icon
        {
            margin-bottom: 10px;
            font-size: 24px;
            color: @black;
        }

        &:last-child
        {
            margin-right: 0;
        }
    }
</style>
