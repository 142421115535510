<template>
    <div class="admin-people">
        <section class="flex">
            <div class="section-content ff" style="cursor:pointer" @click="redirect(person.id, 'person')">
                <h2>Person Data - Click for more detail on person</h2>
                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Person Id</div>
                        <div class="summary-value">{{ person.id }}</div>
                    </div>

                    <div class="summary-field flex flex-align">
                        <div class="summary-key">First Name</div>
                        <div class="summary-value">{{ person.first_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Last Name</div>
                        <div class="summary-value">{{ person.last_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Display Name</div>
                        <div class="summary-value">{{ person.display_name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Email</div>
                        <div class="summary-value">{{ person.email }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Birthday</div>
                        <div class="summary-value">{{ person.birthday }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Gender</div>
                        <div class="summary-value">{{ person.gender }}</div>
                    </div>

                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Created Date</div>
                        <div class="summary-value">
                            {{ person.created_date | pretty("long") }}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <hr>
        <br>
        <h2>Source Data</h2>
        <div class="summary" style="cursor:pointer" @click="redirect(source.id, 'source', team.alias)">
            <div class="summary-field flex flex-align">
                <div class="summary-key">Source Id</div>
                <div class="summary-value">{{ source.id }}</div>
            </div>

            <div class="summary-field flex flex-align">
                <div class="summary-key">Source Name</div>
                <div class="summary-value">{{ source.name }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Status</div>
                <div class="summary-value">{{ source.status }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Created Date</div>
                <div class="summary-value">{{ source.created_date }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Updated Date</div>
                <div class="summary-value">{{ source.updated_date }}</div>
            </div>
        </div>

        <br />
        <hr />

        <br />
        <h2>Sync Data</h2>
        <div class="summary" style="cursor:pointer"
            @click="redirect(syncs.source.id, 'sync', syncs.id, team.alias)">
            <div class="summary-field flex flex-align">
                <div class="summary-key">Sync Id</div>
                <div class="summary">
                    <div class="summary-value">{{ syncs.id }}</div>
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Sync Status</div>
                <div class="summary">
                    <div class="summary-value">{{ syncs.status }}</div>
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Sync Type</div>
                <div class="summary">
                    <div class="summary-value">{{ syncs.type }}</div>
                </div>
            </div>

            <div class="summary-field flex flex-align">
                <div class="summary-key">Sync Sequence</div>
                <div class="summary">
                    <div class="summary-value">{{ syncs.sequence }}</div>
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Sync Created Date</div>
                <div class="summary">
                    <div class="summary-value">{{ syncs.created_date }}</div>
                </div>
            </div>

            <div class="summary-field flex flex-align">
                <div class="summary-key">Sync Updated Date</div>
                <div class="summary">
                    <div class="summary-value">{{ syncs.updated_date }}</div>
                </div>
            </div>

        </div>

        <br />
        <hr />
        <br />

        <h2>District Data</h2>

        <div class="summary">
            <div class="summary-field flex flex-align">
                <div class="summary-key">District Id</div>
                <div class="summary-value">{{ district.id }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">District Name</div>
                <div class="summary-value">{{ district.name }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">District Sequence</div>
                <div class="summary-value">{{ district.sequence }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">District Created Date</div>
                <div class="summary-value">{{ district.created_date }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">District Updated Date</div>
                <div class="summary-value">{{ district.updated_date }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">District External Id</div>
                <div class="summary-value">{{ district.external.id }}</div>
            </div>
            <div class="summary-field flex">
                <div class="summary-key">District Properties JSON</div>
                <div class="summary-value">{{ district.properties }}</div>
            </div>
            <br>
            <br>
            
        </div>
        <br>
        <hr>
        <br>

        <h2>Team Data</h2>
        <div class="summary" style="cursor:pointer" @click="redirect(team.alias, 'team')" v-if="team">
            <div class="summary-field flex flex-align">
                <div class="summary-key">Team Id</div>
                <div class="summary-value">{{ team.id }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Team Name</div>
                <div class="summary-value">{{ team.name }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Team Alias</div>
                <div class="summary-value">{{ team.alias }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Team Type</div>
                <div class="summary-value">{{ team.type }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Team Created Date</div>
                <div class="summary-value">{{ team.created_date }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Team Updated Date</div>
                <div class="summary-value">{{ team.updated_date }}</div>
            </div>

        </div>
        <br />
        <hr />
        <br />

        <h2 v-if="match">Match Data</h2>
        <div class="summary" v-if="match">
            <div class="summary-field flex flex-align">
                <div class="summary-key">Match Id</div>
                <div v-for="match in matches" class="summary-value">{{ match.id }}</div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Match Sequence</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.sequence }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Match Created Date</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.created_date }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Match State</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.state }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Match Confidence</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.confidence }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Primary Id</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.primary_id }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Primary Source Id</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.primary_source_id }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Secondary Id</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.secondary_id }}
                </div>
            </div>
            <div class="summary-field flex flex-align">
                <div class="summary-key">Secondary Source Id</div>
                <div v-for="match in matches" class="summary-value">
                    {{ match.secondary_source_id }}
                </div>
            </div>
        </div>
        <br />
    </div>
</template>

<script>
export default {
    name: 'AdminPerson',
    data() {        
        return {
            person: [],
            source: [],
            syncs: [],
            matches: [],
            district: [],
            team: null,
            search: '',
        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}`)
            .then((response) => {
                
                this.person = response.$data;
            })
            .catch((error) => {
                this.$toasted.error('Failed to load person.');
            });

        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/source`)
            .then((response) => {
               
                this.source = response.$data;
                this.$http
                    .get(`/admin/teams/${this.source.team.id}`)
                    .then((response) => {
                        this.team = response.$data;
                    })
                    .catch((error) => {
                        this.$toasted.error('Failed to load Teams.');
                    });
            })
            .catch((error) => {
                this.$toasted.error('Failed to load source.');
            });

        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/sync`)
            .then((response) => {
                
                this.syncs = response.$data;
            })
            .catch((error) => {
                this.$toasted.error('Failed to load Sync.');
            });

        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/matches`)
            .then((response) => {
                
                this.matches = response.$data;
            })
            .catch((error) => {
                this.$toasted.error('Failed to load Matches.');
            });

        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/district`)
            .then((response) => {
                
                this.district = response.$data;
            })
            .catch((error) => {
                this.$toasted.error('Failed to load Districts.');
            });
    },
    methods: {
        redirect(primary_id, location, secondary_id = 0, tertiary_id = 0) {
            if (location === 'person') {
                this.$router.push(`/admin/people/${primary_id}/detail`);
            } else if (location === 'source') {
                this.$router.push(`/${secondary_id}/sources/${primary_id}/overview`);
            } else if (location === 'sync') {
                this.$router.push(
                    `/${tertiary_id}/sources/${primary_id}/syncs/${secondary_id}`
                );
            } else if (location === 'team') {
                this.$router.push(`/${primary_id}/sources`)
            }
        },
    },
    computed: {
        name() {            
            return this.user;
        },
    },
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;
      
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;

       
    }
}

.ff {
    padding: 15px;
}


</style>
