<template>
    <div class="transformation pointer flex flex-start" :class="{active}">
        <PageFlip class="icon" width="16" height="16" stroke-width="2" />
        <div class="ff">
            <div class="transformation-name text-overflow">{{ transformation.block.title }}</div>
            <div class="transformation-description oh">{{ transformation.block.description }}</div>
        </div>
        <state :state="transformation.state" />
    </div>
</template>

<script>
// import EditTransformation from '@/components/modals/EditTransformation.vue';
import EditTransformation from '@/components/drawers/EditTransformation.vue';
import Options from '@/components/modals/Options.vue';
import Prism from 'prismjs';
import { PageFlip } from '@epiphany/iconoir';

export default {
    name: 'TransformationCard',
    props: {
        transformation: Object,
        source: Object,
        integration: Object,
        active: Boolean
    },
    components: {
        PageFlip
    },
    computed: {
        configurable() {
            return this.transformation.mutable || Object.keys(this.transformation.block.configuration).length > 0;
        },
        hidden() {
            if (this.transformation.block.function) {
                return Math.max(this.transformation.block.function.split('\n').length - 6, 0)
            } else {
                return 0;
            }
        }
    },
    data() {
        return {
            code: '',
            known: [
                '12be6249-5407-4e50-a331-ac305b70d108', // Source Data Overrides
                '91822395-5809-4ea2-96f6-c853341cbcc9', // Data Enrichment
                '50060470-b7bd-439c-a40f-819b3d6fa9cf', // Map Custom Roles
                'd1df7a33-c5f1-49d7-850d-d03c7ecb335f', // Sharing Rules
                'fcf52502-89da-4fd3-bb67-10afba97f29c', // Shared Data Overrides
                'dbced7ec-f955-46b0-b4fa-607a5eead3af', // Privacy Mask
                '8f8a3e02-3ae2-4e27-95f4-40136450f696', // Class State By Session
                '20c46eca-d261-4acc-a65a-a464fdf84e1f', // Infer Roles From Enrollments
                '09082509-62b3-46da-b6a7-9a48cba0a880', // Construct Email Addresses
                'c489379d-a3c0-4158-98a4-665eed1aa717', // Hide Classes in Inactive Sessions
                'a6c5b3df-c684-4575-adc3-bb19cc9b3ae3', // Kebab Case
                '6ab5c8a0-23cc-46f1-8173-292e50a46218', // Normalize Phone Numbers
                '578bda1a-f540-4dd2-9819-5fdf509e9638', // Normalize State Names
                '448974ff-7238-4e0c-a429-ba69e91c7ccf', // AI Prompt
                'b2b9952a-b793-4e4c-ba3d-93d478617258', // Import CSV
                'dfc54590-b8b3-4a35-9c26-b6ef3e3d45b5' // Data Validation
            ]
        };
    },
    methods: {
        edit() {
            // this.$modal.show(
            //     EditTransformation,
            //     {
            //         transformation: this.transformation,
            //         integration: this.integration,
            //         source: this.source
            //     },
            //     {
            //         width: 800,
            //         height: 'auto',
            //         classes: 'modal overflow-show',
            //         scrollable: false
            //     }
            // );
            this.$store.dispatch('drawer/open', {
                key: 'edit-transformation',
                width: 1000,
                component: EditTransformation,
                props: {
                    transformation: this.transformation,
                    source: this.source,
                    integration: this.integration
                }
            });
        },
        preview() {
            this.$emit('preview', this.transformation);
            // this.$store.dispatch('drawer/open', {
            //     key: 'preview',
            //     width: 'full',
            //     component: Preview,
            //     props: {
            //         transformation: this.transformation,
            //         integration: this.integration,
            //         source: this.source
            //     }
            // });
        },
        _delete(transformation) {
            const _this = this;
            this.$modal.show(Options, {
                title: `Delete ${transformation.block?.title ?? 'Transformation'}`,
                description: 'Are you sure you want to delete this transformation?',
                options: [
                    {
                        title: `Delete Transformation`,
                        color: 'red',
                        fn() {
                            _this.$emit('delete', transformation)
                        }
                    }
                ]
            }, { width: 300, height: 'auto', classes: 'modal' });
        }
    },
    watch: {
        transformation: {
            immediate: true,
            handler() {
                if (this.transformation) {
                    try {
                        this.code = Prism.highlight(this.transformation.block.function, Prism.languages.javascript, 'javascript');;
                    } catch (error) {
                        this.$toasted.error('This block could not be loaded.');
                    }
                }
            }
        },
        'transformation.block.function': {
            handler() {
                if (this.transformation.block.function) {
                    try {
                        this.code = Prism.highlight(this.transformation.block.function, Prism.languages.javascript, 'javascript');;
                    } catch (error) {
                        this.$toasted.error('This block could not be loaded.');
                    }
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
@import "../~@/assets/less/variables";
@import "../~@/assets/less/mixins";

.transformation
{
    height: 74px;
    z-index: 2;
    user-select: none;
    border-top: 1px solid @e;
    border-bottom: 1px solid transparent;
    padding: @single-padding;

    &.active
    {
        background: @e;
        z-index: 3;
        border-color: transparent;
        border-radius: @border-radius;

        + .transformation
        {
            border-top-color: transparent;
        }
    }

    .icon
    {
        color: @black;
        margin-right: @single-padding;
        margin-top: 2px;
    }

    .transformation-name
    {
        color: @black;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        margin-top: 2px;
    }

    .transformation-description {
        font-size: 13px;
        color: @grey;
        line-height: 16px;
        margin-top: 2px;
        height: 32px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
    }
}
</style>
