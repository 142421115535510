<template>
    <div v-if="loading">
        <span>
            <Spinner />
        </span>
    </div>
    <div class="drawer-section" v-else-if="!reactivatable_class">
        <h4>Reactivate Class</h4>
        <div class="">
            This will reactivate the class in Google Classroom if it was declined or archived by the teacher.
            This change may take a few minutes to reflect in Google Classroom.
        </div>
        <div class="form-options flex">
            <div class="button" @click="reactivate_class">Reactivate Class</div>
        </div>
    </div>
</template>

<script>
    import Spinner from '@/components/Spinner.vue';

    export default {
        name: 'EntityFlow',
        data() {
            return {};
        },
        components: {
            Spinner
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            loading() {
                return this.$store.state.entities.loading;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            admin() {
                return this.$store.getters.user.admin;
            },
            source() {
                return this.$store.state.entities.source || this.$store.state.entities.integration.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            reactivatable_class() {
                return this.type === 'classes' && this.integration.source.provider_id === '0ee5e450-4a9e-4b7d-bd30-62b33cdd5456' && this.admin;
            },
            base() {
                return process.env.NODE_ENV === 'development' ? 'http://localhost:9900' : 'https://ed.link';
            }
        },
        methods: {
            async reactivate_class() {
                try {
                    const { $data: links } = await this.$http.get(`${this.base}/api/v2/graph/${this.type}/${this.entity.id}/links`, {
                        headers: {
                            authorization: `Bearer ${this.integration.access_token}`
                        }
                    });

                    const link = links.find(
                        link => link.secondary_source_id === this.integration.destination_id
                    );

                    if (link) {
                        // Draft a new job to reactivate this class.
                        const { $data: job } = await this.$http.post(`${this.base}/api/v2/graph/jobs`, {}, {
                            headers: {
                                Authorization: `Bearer ${this.integration.access_token}`
                            }
                        });

                        // Add the task to our newly created job.
                        await this.$http.post(`${this.base}/api/v2/graph/jobs/${job.id}/tasks`, [{
                            type: 'class',
                            event_type: 'updated',
                            data: {
                                id: link.secondary_id,
                                state: 'active'
                            }
                        }], {
                            headers: {
                                Authorization: `Bearer ${this.integration.access_token}`
                            }
                        });

                        // Queue the job.
                        this.$toasted.success('The class will be reactivated shortly.');
                    }
                }catch(error){
                    this.$toasted.error('There was an error scheduling the class to be reactivated.');
                }
            }
        }
    }
</script>

<style lang="less">
    .drawer-section
    {
        padding: 20px 0;

        h4
        {
            margin: 0 0 20px;
            padding: 0;
        }

        &:first-child
        {
            padding-top: 0;
        }
    }
</style>
