<template>
    <div class="job flex flex-column">
        <header class="drawer-header flex flex-align">
            <div class="drawer-title flex flex-align">
                <Packages class="icon" width="16" height="16" stroke-width="2" />
                Job
            </div>
            <div class="flex flex-align state pointer job-progress-meter" @click="change_state">
                <div class="standalone icon-status" :class="status"></div>
                <div class="job-state">{{job_state}}</div>
                <div class="job-progress">{{complete}} of {{total}} Complete</div>
            </div>
            <div class="ff"></div>
            <div class="button" v-if="job.state === 'draft'" @click="queue_job">Finalize Draft</div>
            <div class="button" v-if="job.state === 'pending'" @click="queue_job">Approve Tasks</div>
            <div class="button white" @click="close">Close</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{active: tab === 'details'}" @click="tab = 'details'">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Details
            </div>
            <div class="pill" :class="{active: tab === 'tasks'}" @click="tab = 'tasks'">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Tasks
            </div>
            <div class="pill" :class="{active: tab === 'json'}" @click="tab = 'json'">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                JSON
            </div>
            <div class="pill" :class="{active: tab === 'logs'}" @click="tab = 'logs'">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Logs
            </div>
        </nav>
        <div class="drawer-content" :class="{'drawer-scroll': tab !== 'tasks'}">
            <component :is="tabs[tab]" :progress="progress" :summary="summary" />
        </div>
    </div>
</template>

<script>
    import JobDetails from '@/components/drawers/job/JobDetails.vue';
    import JobTasks from '@/components/drawers/job/JobTasks.vue';
    import EntityJSON from '@/components/drawers/entity/EntityJSON.vue';
    import EntityLogs from '@/components/drawers/entity/EntityLogs.vue';
    import { AlignLeft, CodeBracketsSquare, DataTransferUp, Desk, InfoEmpty, Packages, UnionAlt } from '@epiphany/iconoir';

    export default {
        name: 'Job',
        components: {
            AlignLeft,
            CodeBracketsSquare,
            DataTransferUp,
            Desk,
            InfoEmpty,
            Packages,
            UnionAlt
        },
        data(){
            return {
                progress: {
                    canceled: 0,
                    complete: 0,
                    error: 0,
                    queued: 0,
                    skipped: 0,
                    working: 0
                },
                summary: {},
                tab: 'details',
                tabs: {
                    details: JobDetails,
                    tasks: JobTasks,
                    json: EntityJSON,
                    logs: EntityLogs
                }
            };
        },
        created(){
            const base = process.env.NODE_ENV === 'development' ? 'http://localhost:9900' : 'https://ed.link';

            this.$http.get(`${base}/api/v2/graph/jobs/${this.job.id}/progress`, {
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                }
            })
            .then(response => {
                this.progress = response.$data;
            });

            this.$http.get(`${base}/api/v2/graph/jobs/${this.job.id}/summary`, {
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                }
            })
            .then(response => {
                this.summary = response.$data;
            });
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            },
            change_state(){

            },
            queue_job(){
                const base = process.env.NODE_ENV === 'development' ? 'http://localhost:9900' : 'https://ed.link';
                this.$http.post(`${base}/api/v2/graph/jobs/${this.job.id}/activate`, {}, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                }).then(() => {
                    this.$store.dispatch('entities/activate', {
                        id: this.job.id,
                        type: 'jobs',
                        integration: this.integration
                    });

                    this.$toasted.success('Job queued');
                }).catch(() => {
                    this.$toasted.error('Job could not be queued');
                });
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            job(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            job_state() {
                if (this.job.state === 'complete') {
                    if (this.progress.error > 0) {
                        return 'warning';
                    }
                }

                return this.job.state;
            },
            status(){
                return {
                    red: ['error'].includes(this.job_state),
                    yellow: ['canceled'].includes(this.job_state),
                    blue: ['working'].includes(this.job_state),
                    green: ['complete'].includes(this.job_state),
                    grey: ['queued'].includes(this.job_state),
                    purple: ['draft'].includes(this.job_state),
                    orange: ['warning'].includes(this.job_state)
                };
            },
            parent(){
                return this.integration ? this.integration : this.source;
            },
            complete(){
                return this.progress.complete + this.progress.skipped;
            },
            total(){
                let total = 0;

                for(const key of ['canceled', 'complete', 'error', 'queued', 'skipped', 'working']){
                    total += this.progress[key];
                }

                return total;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .job
    {
        height: 100%;

        .drawer-content
        {
            padding: 0;

            &.drawer-scroll
            {
                padding: 20px;
            }
        }
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .job-progress-meter
    {
        margin-left: @section-padding;
    }

    .job-state
    {
        margin: 4px 8px 4px 0;
        line-height: 14px;
        padding-right: 8px;
        border-right: 1px solid @d;
    }

    .job-progress
    {
        text-transform: none;
    }

    .drawer-header
    {
        .button
        {
            margin-left: @single-padding;
        }
    }
</style>
