<template>
    <div class="chip flex flex-align" :class="color">
        <img v-if="thumbnail" :src="thumbnail" class="chip-thumbnail" />
        <slot name="icon" />
        <div class="chip-title">
            <template v-if="title">{{ title }}</template>
            <slot v-else name="title" />
        </div>
        <slot name="actions" />
    </div>
</template>

<script>
    export default {
        name: 'Chip',
        props: {
            color: String,
            thumbnail: String,
            title: String
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    
    .chip
    {
        padding: 10px;
        height: @chip-height;
        background: fade(@base, 20%);
        color: @base;
        padding: @chip-padding;
        border-radius: @chip-border-radius;

        .chip-thumbnail
        {
            width: @chip-height - 2 * @chip-padding;
            height: @chip-height - 2 * @chip-padding;
            border-radius: @chip-border-radius - 1px;
            display: block;
        }

        .chip-title
        {
            padding: 0 @chip-padding;
            line-height: @chip-height - 2 * @chip-padding;
            font-size: 10px;
            font-weight: 500;
        }

        .chip-thumbnail + .chip-title
        {
            padding-left: 5px;
        }
        
        &.black
        {
            background: fade(@black, 20%);
            color: @black;
        }

        &.white
        {
            background: fade(@white, 20%);
            color: @white;
        }

        &.blue
        {
            background: fade(@blue, 20%);
            color: @blue;
        }

        &.red
        {
            background: fade(@red, 20%);
            color: @red;
        }

        &.green
        {
            background: fade(@green, 20%);
            color: darken(@green, 15%);
        }

        &.orange
        {
            background: fade(#ff7302, 20%);
            color: #ff7302;
        }

        &.purple
        {
            background: fade(@purple, 20%);
            color: @purple;
        }

        &.yellow
        {
            background: fade(@yellow, 30%);
            color: darken(@yellow, 15%);
        }

        &.grey
        {
            background: fade(@grey, 20%);
            color: @grey;
        }

        &.lightblue
        {
            background: fade(#00a2ed, 20%);
            color: #00a2ed;
        }

        &.darkblue
        {
            background: fade(#0037c3, 20%);
            color: #0037c3;
        }

        &.darkgrey
        {
            background: fade(@darkgrey, 20%);
            color: @darkgrey;
        }

        &.lightgrey
        {
            background: @e;
            color: #888;
        }
    }

    .datatable
    {
        .datatable-cell
        {
            .chip:first-child
            {
                margin: 0 -3px;
            }
        }
    }
</style>
