import { createMachine, assign } from 'xstate';

export const custom_admin = createMachine({
    id: 'custom_admin',
    initial: 'initializing',
    context: {
        error: null,

        application: null,
        user: null,
        team_id: null,
        team: null,
        gcri: false,

        token_activation_conditions: [],

        provider: null,
        source: null,

        sources: [],

        integration: null,

        // flag for components
        custom: true
    },
    on: {
        ERROR: {
            actions: assign({
                error: (ctx, event) => event.data
            }),
            target: 'error'
        },
        PROVIDER: {
            actions: assign({
                provider: (ctx, event) => event.data
            })
        },
        SOURCE: {
            actions: assign({
                source: (ctx, event) => event.data
            })
        },
        APPLICATION: {
            actions: assign({
                application: (ctx, event) => event.data
            })
        },
        TEAM_ID: {
            actions: assign({
                team_id: (ctx, event) => event.data
            })
        },
        GCRI: {
            actions: assign({
                gcri: (ctx, event) => event.data
            })
        },
        TEAM: {
            actions: assign({
                team: (ctx, event) => event.data
            })
        },
        INTEGRATION: {
            actions: assign({
                integration: (ctx, event) => event.data
            })
        },
        SOURCES: {
            actions: assign({
                sources: (ctx, event) => event.data
            })
        }
    },
    actions: {
        checkSources: ({ context, event }) => {}
    },
    states: {
        error: {},
        initializing: {
            on: {
                CHALLENGE: {
                    target: 'challenge',
                    actions: assign({
                        token_activation_conditions: (ctx, event) => event.data
                    })
                },
                NEXT: 'lms'
            }
        },
        // If Edlink needs to challenge the user for their password or MFA to activate the token.
        challenge: {
            on: {
                NEXT: 'lms'
            }
        },
        lms: {
            initial: 'start',
            on: {
                BACK: 'lms.start',
                NEXT: 'complete',
                UPDATE_SOURCE: {
                    actions: assign({
                        source: (ctx, event) => {
                            return _.set({ ...ctx.source }, event.path, event.value);
                        }
                    })
                }
            },
            states: {
                start: {
                    always: [
                        {
                            target: 'select',
                            cond: (context) => {
                                if (context.team.membership.type === 'read') {
                                    return false;
                                }

                                const filtered = context.sources.filter((source) => {
                                    if (source.provider.application !== context.provider.application) {
                                        return false;
                                    }

                                    if (!context.application.valid_source_providers.includes(source.provider.id)) {
                                        return false;
                                    }

                                    if (source.status === 'destroyed') {
                                        return false;
                                    }

                                    return true;
                                });

                                if (filtered.length === 0) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                        {
                            target: 'editor',
                            cond: (context) => {
                                if (context.team.membership.type === 'read') {
                                    return false;
                                }

                                return true;
                            }
                        },
                        {
                            target: 'request'
                        }
                    ]
                },
                select: {
                    on: {
                        CREATE_SOURCE: 'editor'
                    }
                },
                editor: {
                    on: {
                        UPDATE_SOURCE: {
                            actions: assign({
                                source: (ctx, event) => {
                                    return _.set({ ...ctx.source }, event.path, event.value);
                                }
                            })
                        }
                    }
                },
                request: {
                    on: {
                        REQUESTED: 'loading'
                    }
                },
                loading: {
                    on: {
                        REQUESTED: 'requested'
                    }
                },
                requested: {}
            }
        },
        complete: {}
    }
});
