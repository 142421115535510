import axios from 'axios';

const state = {
    active: null,
    updating: false
};

const mutations = {
    activate(state, source) {
        state.active = source;
    },
    deactivate() {
        state.active = null;
    }
};

const actions = {
    activate({ commit }, { team, migration }) {
        return axios.get(`/teams/${team}/migrations/${migration}`).then((response) => commit('activate', response.$data));
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    destroy({ commit }) {
        if (state.active) {
            return axios.delete(`/teams/${state.active.team.id}/migrations/${state.active.id}`).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active migration.');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
