<template>
    <div class="custom-admin-complete flex flex-column flex-align">
        <template v-if="loading">
            <spinner />
        </template>
        <template v-else>
            <h1>Congrats! You successfully connected your <span class="emphasis">{{ state.context.provider.name }}</span> to <span class="emphasis">{{ state.context.application.name }}</span></h1>
            <div class="helptext">
                <p>Click the button below to close this window and start using <span class="emphasis">{{ state.context.application.name }}</span>.</p>
            </div>
            <div class="options flex" key="email">
                <div class="button" @click="close">Close</div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'CustomAdminComplete',
        props: {
            state: Object,
            send: Function
        },
        data(){
            return {
                loading: true,
                error: null
            };
        },
        created(){
            const integration = {
                start_date: null,
                permissions: this.state.context.application.permissions.slice(),
                scope: 'all',
                application_id: this.state.context.application.id,
                source_id: this.state.context.source.id
            };

            setTimeout(() => {
                this.$http.post(`/teams/${this.state.context.team.id}/integrations`, integration).then(response => {
                    this.send({type: 'INTEGRATION', data: response.$data});
                    window.parent.postMessage({ event: 'onboarding.complete', data: { application_id: this.state.context.application.id, integration_id: response.$data.id } }, '*');
                })
                .catch(error => {
                    console.error(error);
                    this.send('ERROR', { data: 'INTEGRATION_CREATION_ERROR' })
                    window.parent.postMessage({ event: 'onboarding.error', data: { error } }, '*');
                }).finally(() => {
                    // this.loading = false;
                })
            }, 2500);
        },
        methods: {
            close(){
                window.parent.postMessage({ event: 'onboarding.close' }, '*');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .custom-admin-complete {
        padding: 0 100px;
        h1 {
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
        }
    }
    
    .helptext {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        text-align: center;
    }

    .emphasis {
        font-weight: 600;
    }
</style>
