<template>
    <div class="integration-drawer">
        <template v-if="selected">
            <h2>{{selected.display_name ? selected.display_name : selected.name}}</h2>
            <h4 class="capitalize">{{types[type]}}</h4>
            <pre class="card ff monospace" v-html="output" v-if="output"></pre>
            <div class="nothing" v-else>None.</div>
            <template v-if="type === 'people'">
                <h3>Impersonate User</h3>
                <div class="card impersonate">
                    <div class="flex flex-align">
                        <div class="ff">
                            <!-- <div class="title">Impersonate User</div> -->
                            <div class="date">Access {{integration.application.name}} as {{selected.display_name}}.</div>
                        </div>
                    </div>
                    <div class="button center" @click="impersonate">Impersonate</div>
                </div>
            </template>
            <h3>Additional Properties</h3>
            <template v-if="selected.properties && Object.keys(selected.properties).length">
                <div class="form-field" v-for="(value, property) of selected.properties" :key="property">
                    <label>{{property}}</label>
                    <input readonly :value="value" />
                </div>
            </template>
            <div class="nothing" v-else>None.</div>
            <template v-if="['people', 'classes', 'sections'].includes(type)">
                <h3>Enrollments</h3>
                <div class="card" v-if="enrollments.length">
                    <div class="enrollment flex flex-align" v-for="enrollment of enrollments" :key="enrollment.id">
                        <div class="ff">
                            <div class="enrollment-title text-overflow text-button" @click="open(enrollment)">{{type === 'people' ? enrollment.name : enrollment.display_name}}</div>
                            <div class="enrollment-target text-overflow">{{enrollment.id}}</div>
                        </div>
                        <div class="enrollment-type capitalize">{{enrollment.enrollments.map(e => e.type).join(', ')}}</div>
                    </div>
                </div>
                <div class="nothing" v-else>None.</div>
            </template>
            <!-- <template v-if="['courses'].includes(type)">
                <h3>Sections</h3>
                <div class="card" v-if="children.length">
                    <div class="enrollment flex flex-align" v-for="organization of children" :key="organization.id">
                        <div class="ff">
                            <div class="enrollment-title text-overflow">Dakota Gordon</div>
                            <div class="enrollment-target text-overflow">Target</div>
                        </div>
                        <div class="enrollment-type">Student</div>
                    </div>
                </div>
                <div class="nothing" v-else>None.</div>
            </template> -->
        </template>
        <div v-else class="nothing-selected flex flex-align flex-center">
            Nothing selected.
        </div>
    </div>
</template>

<script>
    import Prism from 'prismjs';

    const url = process.env.NODE_ENV === 'production' || true ? 'https://ed.link' : 'http://localhost:9900';

    export default {
        name: 'IntegrationDrawer',
        data(){
            return {
                syncing: false,
                enrollments: [],
                children: [],
                output: '',
                types: {
                    people: 'Person',
                    enrollments: 'Enrollment',
                    schools: 'School',
                    course: 'Course',
                    section: 'Section',
                    terms: 'Term'
                }
            };
        },
        props: {
            selected: Object,
            type: String
        },
        computed: {
            code(){
                return this.selected ? JSON.stringify(this.selected, null, 4) : '';
            },
            integration(){
                return this.$store.getters.integration;
            }
        },
        watch: {
            selected(){
                this.enrollments = [];
                this.children = [];

                if(!this.selected){
                    return;
                }

                try {
                    //Color and format the JSON that represents this user.
                    this.output = this.selected ? Prism.highlight(JSON.stringify(this.selected, null, 4), Prism.languages.json, 'json') : null;
                }catch(error){
                    console.log(error);
                }

                if(!this.selected){
                    return;
                }

                if(this.type === 'people'){
                    //Load the courses for this user to show in the sidebar.
                    this.$http.get(`${url}/api/v1/graph/people/${this.selected.id}/courses`, {
                        headers: {
                            Authorization: `Bearer ${this.integration.access_token}`
                        },
                        params: {
                            $first: 1000
                        }
                    })
                    .then(response => {
                        this.enrollments = response.$data;
                    })
                    .catch(() => this.$toasted.error('There was an error loading the enrollments for this user.'));
                }

                if(['classes', 'sections'].includes(this.type)){
                    //Load the organization children and enrollments to show in the sidebar.
                    this.$http.get(`${url}/api/v1/graph/${this.type === 'classes' ? 'courses' : this.type}/${this.selected.id}/enrollments`, {
                        headers: {
                            Authorization: `Bearer ${this.integration.access_token}`
                        },
                        params: {
                            $first: 1000
                        }
                    })
                    .then(response => {
                        this.enrollments = response.$data;
                    })
                    .catch(() => this.$toasted.error('There was an error loading the enrollments for this organization.'));
                }

                if(this.type === 'courses'){
                    //TODO Get sections that are children of this course.
                    //This is complex to do through the API and really ought to have another endpoint created for it.

                    // this.$http.get(`${url}/api/v1/graph/organizations/${this.selected.id}`, {
                    //     headers: {
                    //         Authorization: `Bearer ${this.integration.access_token}`
                    //     },
                    //     params: {
                    //         $expand: 'sections'
                    //     }
                    // })
                    // .then(sections => {
                    //     console.log(sections)
                    //     // this.children = sections.$data;
                    // })
                    // .catch(() => this.$toasted.error('There was an error loading the sections for this course.'));
                }
            }
        },
        methods: {
            impersonate(){
                this.$http.post(`${url}/api/v1/graph/people/${this.selected.id}/impersonate`, {}, {
                    params: this.params,
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                })
                .then(response => window.open(response.$data))
                .catch(() => this.$toasted.error('There was an error generating a launch URL for this user.'));
            },
            open(object){
                this.$router.push({
                    name: 'team.integrations.integration.dataset',
                    params: {
                        type: this.type === 'people' ? 'classes' : 'people'
                    },
                    query: {
                        filter: JSON.stringify({
                            property: 'id',
                            operator: 'equals',
                            value: object.id
                        })
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integration-drawer
    {
        padding: 20px;
        overflow-y: auto;

        .nothing
        {
            padding: 20px;
            font-size: 13px;
            font-weight: 500;
        }
    }

    .impersonate
    {
        padding: 12px;

        .title
        {
            color: @grey;
            font-size: 12px;
        }

        .date
        {
            color: @black;
            font-size: 13px;
            text-align: center;
            padding: 10px 0;
        }

        .button
        {
            margin-top: 10px;
        }
    }

    .nothing-selected
    {
        color: @grey;
        font-size: 13px;
        width: 100%;
        height: 100%;
    }

    h2
    {
        font-size: 26px;
        line-height: 32px;
        font-weight: 600;
        color: @black;
        letter-spacing: -0.03rem;
    }

    h3
    {
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 10px;
        border-top: 1px solid @e4;
        padding-top: 30px;
        line-height: 20px;
    }

    h4
    {
        color: @grey;
        font-size: 16px;
        font-weight: 500;
    }

    pre
    {
        font-size: 12px;
        border-radius: 3px;
        overflow: auto;
        padding: 10px;
        margin-top: 15px;
        line-height: 16px;
    }

    .enrollment
    {
        border-bottom: 1px solid @e4;
        padding: 8px 10px;

        &:last-child
        {
            border-bottom: 0;
        }

        .enrollment-title
        {
            font-size: 14px;
            margin-bottom: 4px;
        }

        .enrollment-target
        {
            font-size: 12px;
            color: @grey;
        }

        .enrollment-type
        {
            font-size: 13px;
            color: @black;
            margin-left: 20px;
            font-weight: 500;
        }
    }
</style>
