<template>
    <div class="onboarding flex flex-column">
        <header class="drawer-header">
            <div class="flex flex-align">
                <div class="ff drawer-title flex flex-align">
                    <GridAdd class="icon" width="20" height="20" stroke-width="2" />
                    {{ onboarding.team?.id ? 'Onboard ' + onboarding.team.name : 'Onboarding' }}
                </div>
                <div class="button has-icon white flex flex-align" @click="() => $store.dispatch('drawer/close')">
                    Close Window
                </div>
            </div>
        </header>
        <div class="ff drawer-scroll drawer-content">
            <div class="drawer-section">
                <h4>Onboarding Details</h4>
                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Onboarding ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => onboarding.id" v-clipboard:success="() => $toasted.info('Copied')">{{ onboarding.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">State</div>
                        <div class="summary-value flex flex-align">
                            <state :state="onboarding.state" />
                            <router-link v-if="onboarding.integration?.id" class="text-button has-icon has-icon-right flex flex-align" :to="{name: 'team.integrations.integration', params: {integration: onboarding.integration.id}}" @click.native="() => $store.dispatch('drawer/close')">
                                Go to Integration
                                <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                            </router-link>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Created Date</div>
                        <div class="summary-value">{{ onboarding.created_date | pretty('long') }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Updated Date</div>
                        <div class="summary-value">{{ onboarding.updated_date | pretty('long') }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Expiration Date</div>
                        <div class="summary-value" v-if="onboarding.expiration_date">{{ onboarding.expiration_date | pretty('long') }}</div>
                        <div class="summary-value" v-else>Does Not Expire</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Created By</div>
                        <div class="summary-value" v-if="onboarding.user?.id">
                            <team-member-name :first_name="onboarding.user.first_name" :last_name="onboarding.user.last_name" />
                        </div>
                        <div class="summary-value" v-else>Created via API</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Redirect URI</div>
                        <div class="summary-value copyable" v-if="onboarding.redirect_uri" v-clipboard="() => onboarding.redirect_uri" v-clipboard:success="() => $toasted.info('Copied')">{{ onboarding.redirect_uri }}</div>
                        <div class="summary-value" v-else>None Set</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Provider</div>
                        <div class="summary-value" v-if="onboarding.provider?.id">
                            <provider :id="onboarding.provider.id" />
                        </div>
                        <div class="summary-value" v-else>None Set</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Application</div>
                        <div class="summary-value">
                            <router-link class="text-button has-icon has-icon-right flex flex-align" :to="{name: 'team.applications.application', params: {application: onboarding.application.id}}" @click.native="() => $store.dispatch('drawer/close')">
                                {{ onboarding.application.name }}
                                <ArrowUpRight class="icon" width="16" height="16" stroke-width="2" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="drawer-section">
                <h4>Share This Onboarding Link</h4>
                <div class="helptext">
                    Share this link with an IT administrator at {{ onboarding.team?.name ?? 'the school' }} to get started with the onboarding process.
                    The Edlink admin onboarding flow will guide them through the process of setting up the integration.
                </div>
                <div class="flex flex-align">
                    <div class="button has-icon base flex flex-align" v-clipboard="`https://ed.link/onboard/${onboarding.id}`" v-clipboard:success="() => $toasted.success('Copied onboarding link to clipboard')">
                        <Copy class="icon" width="20" height="20" stroke-width="2" />
                        <div class="ff">Copy Onboarding Link</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { GridAdd, Svg3dSelectSolid, Search, City, Check, Cancel, Clock, Timer, SendMail, ArrowUpRight, Copy } from '@epiphany/iconoir';
    import moment from 'moment';
    import _ from 'lodash';

    export default {
        name: 'Onboarding',
        components: {
            GridAdd,
            Svg3dSelectSolid,
            Search,
            City,
            Check,
            Cancel,
            Clock,
            Timer,
            SendMail,
            ArrowUpRight,
            Copy
        },
        props: {
            onboarding: Object
        },
        data() {
            return {
                applications: [],
                application: null,
                districts: [],
                district: null,
                expirations: [
                    { label: 'Does Not Expire', value: 'never', timestamp: null },
                    { label: 'Expires In a Day', value: 'day', timestamp: null },
                    { label: 'Expires In a Week', value: 'week', timestamp: null },
                    { label: 'Expires In a Month', value: 'month', timestamp: null }
                ],
                expiration: null,
                loading: {
                    districts: false,
                    applications: false
                },
                throttle: {
                    districts: null
                },
                query: '',
                entities: []
            }
        },
        created() {
            
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            admin(){
                return this.$store.getters.user.admin;
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .onboarding
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;
        }
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .chip
    {
        margin: 0;

        + .text-button
        {
            margin-left: @single-padding;
        }
    }

    h4
    {
        color: @black;
    }

    .helptext
    {
        margin-bottom: @double-padding;
    }

    .summary {
        font-size: 14px;

        .text-button.has-icon.has-icon-right
        {
            font-size: 14px;

            .icon
            {
                width: 16px;
                height: 16px;
            }
        }

        .summary-field {
            height: 22px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            &.multiline {
                height: auto;
                align-items: flex-start;

                .summary-values {
                    align-items: flex-start;
                }
            }
        }

        .summary-key {
            color: @grey;
            width: 180px;
            flex-basis: 180px;
            flex-shrink: 0;
            line-height: 22px;
        }

        .summary-value {
            color: @black;
            line-height: 22px;

            &.monospace {
                line-height: 22px;
                font-size: 13px;
            }

            .flag-icon {
                width: 12px;
                height: 12px;
                margin-right: 8px;
            }

            .text-button {
                font-size: 13px;

                svg {
                    margin-left: 4px;
                }
            }
        }
    }
</style>
