<template>
    <div class="integrate-source-select flex flex-align flex-center">
        <div class="integrate-source-select-list integrate-state">
            <h1>
                <template v-if="type === 'primary'">Select or Create a Source</template>
                <template v-else-if="type === 'enrich'">Select an Enrichment Source</template>
                <template v-else-if="type === 'destination'">Select or Create a Destination</template>
            </h1>
            <div class="integration-options">
                <transition-group name="slide-in" tag="div" class="integration-options">
                    <div class="integration-option flex flex-align" v-for="source of filtered" :key="source.id" @click="() => $parent.set_source(source)">
                        <div class="option-icon">
                            <img :src="source.provider.icon_url" />
                        </div>
                        <div :class="{'option-name': true, 'text-overflow': true, disabled: is_not_same_region(source)}">{{ source.name }}</div>
                        <div class="caret" v-if="!is_not_same_region(source)"></div>
                    </div>
                    <div class="integration-option flex flex-align" key="create-source" @click="send('CREATE_SOURCE')">
                        <span class="icon iconoir-plus block"></span>
                        <div class="option-name">Create a new source</div>
                        <div class="caret"></div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        SUPPORTED_FLOW_DESTINATIONS,
        SUPPORTED_FLOW_SOURCES,
        FLOW_APPLICATION_ID
    } from '@/constants'

    export default {
        name: 'IntegrateSourceSelect',
        props: {
            state: Object,
            send: Function,
            type: String
        },
        mounted() {
            this.$parent.source_type = this.type;

            if (this.filtered.length < 1) {
                this.send('CREATE_SOURCE');
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            application() {
                return this.state.context.application;
            },
            filtered() {
                if (this.application.id === FLOW_APPLICATION_ID) {
                    return this.$parent.sources.filter(source => {
                        if (this.type === 'primary') {
                            return SUPPORTED_FLOW_SOURCES.includes(source.provider.application);
                        } else if (this.type === 'destination') {
                            return SUPPORTED_FLOW_DESTINATIONS.includes(source.provider.application);
                        } else {
                            return false;
                        }
                    })
                } else {
                    return this.$parent.sources.filter(source => {
                        if (['lti', 'clever', 'classlink'].includes(source.provider.application)) {
                            return this.user.admin ? true : false;
                        }

                        if (this.state.context.source?.id) {
                            if (source.id === this.state.context.source.id) {
                                return false;
                            }
                        }

                        return true;
                    })
                }
            }
        },
        methods: {
            is_not_same_region(source) {
                if (
                    this.application.id === FLOW_APPLICATION_ID &&
                    this.type === 'destination' &&
                    source.region.id !== this.state.context.source.region?.id
                ) {
                    return true;
                }
                return false;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-source-select.integrate-state
    {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        padding: 80px 0;
        max-width: none;

        h1
        {
            line-height: 36px;
        }

        .integration-options
        {
            margin-top: 6px;
        }

        .integration-option
        {
            &:last-child
            {
                color: @grey;
            }
        }

        .disabled {
            color: @lightgrey;
            cursor: auto;
        }
    }
</style>
