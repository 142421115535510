import axios from 'axios';

const state = {
    video: null,
    onboarding: null,
    application: null,
    team: null,
    source: null,
    integration: {
        id: null,
        start_date: null,
        permissions: [],
        scope: 'all',
        targets: [],
        application_id: null,
        source_id: null,
        target_id: null
    }
};

const videos = {
    'what-is-edlink': 'https://www.youtube.com/embed/_MHcVtpMM_4?autoplay=1&rel=0',
    'how-to-select-provider': 'https://www.youtube.com/embed/N1XtH_B59Fw?autoplay=1&rel=0',
    'data-sovereignty': 'https://www.youtube.com/embed/qIVir-1ocno?autoplay=1&rel=0',
    'setting-up-blackboard': 'https://www.youtube.com/embed/56yZGknlaoQ?autoplay=1&rel=0',
    'setting-up-moodle': 'https://www.youtube.com/embed/qeNlSiQ_t50?autoplay=1&rel=0',
    'setting-up-canvas': 'https://www.youtube.com/embed/CvPhw8Mwdc4?autoplay=1&rel=0',
    'setting-up-google': 'https://www.youtube.com/embed/0wxaIU_-6ec?autoplay=1&rel=0',
    'setting-up-microsoft': 'https://www.youtube.com/embed/cOGwYuiIZv0?autoplay=1&rel=0',
    'setting-up-brightspace': 'https://www.youtube.com/embed/8QhmIiJFI-Q?autoplay=1&rel=0',
    'setting-up-schoology': 'https://www.youtube.com/embed/1QtcsUb38bY?autoplay=1&rel=0'
};

//TODO Applications that require a target_id

const mutations = {
    team(state, team) {
        state.team = team;
    },
    application(state, application) {
        state.application = application;
        state.integration.application_id = application.id;

        //Set all of the permissions to default to `true`.
        state.integration.permissions = application.permissions.slice();
    },
    onboarding(state, onboarding) {
        state.onboarding = onboarding;
        state.application = onboarding.application;
        state.integration.application_id = onboarding.application.id;

        // Set all of the permissions to default to `true`.
        state.integration.permissions = onboarding.application.permissions.slice();
    },
    source(state, source) {
        state.source = source;
        state.integration.source_id = source.id;
    },
    permissions(state, payload) {
        state.integration.permissions = payload;
    },
    scope(state, scope) {
        state.integration.scope = scope;
    },
    id(state, id) {
        state.integration.id = id;
    },
    video(state, video) {
        if (videos.hasOwnProperty(video)) {
            state.video = videos[video];
        } else {
            state.video = null;
        }
    }
};

const actions = {
    application({ commit }, application_id) {
        return axios.get(`/marketplace/${application_id}`).then((response) => commit('application', response.$data));
    },
    onboarding({ commit }, onboarding_id) {
        return axios.get(`/onboardings/${onboarding_id}`).then((response) => commit('onboarding', response.$data));
    },
    team({ commit }, team) {
        commit('team', team);
    },
    source({ commit }, source) {
        commit('source', source);
    },
    permissions({ commit }, payload) {
        commit('permissions', payload);
    },
    scope({ commit }, payload) {
        commit('scope', payload);
    },
    id({ commit }, id) {
        commit('id', id);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
