<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <TwitterVerifiedBadge class="icon" width="16" height="16" stroke-width="2" />
                    Teams
                </div>
            </template>
            <template v-slot:actions>
                <input class="filter search large" type="text" placeholder="Search by ID, Name, or Tech Contact" @input="searched" v-model="search" />
                <a class="button" @click="create">Create Team</a>
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="types" :active="type" @activate="switch_type" label="Type">
                <template v-slot:icon>
                    <Wrench class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>   
            <filter-dropdown :items="states" :active="state" @activate="switch_state" label="State">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>                           
            <filter-dropdown :items="dates" :active="updated_date" @activate="switch_updated_date" label="Updated Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="dates" :active="created_date" @activate="switch_created_date" label="Created Date">
                <template v-slot:icon>
                    <CalendarPlus class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
        </filters>
        <div class="admin-teams full">
            <datatable :columns="columns" :rows="teams" :selectable="true" :header="true" @clicked="team => open(team)" :clickable="true" />
        </div>
    </main>
</template>

<script>
    import { Community, AlignLeft, Calendar, Wrench, TwitterVerifiedBadge, WhiteFlag, CalendarPlus, PinAlt, PeopleTag } from '@epiphany/iconoir';
    import CreateTeam from '@/components/modals/CreateTeam.vue';
    import TeamName from '@/components/TeamName.vue';
    import State from '@/components/chips/State.vue';
    import moment from 'moment';

    export default {
        name: 'Teams',
        components: {
            Community,
            Calendar,
            CalendarPlus,
            WhiteFlag,
            Wrench,
            TeamName,
            TwitterVerifiedBadge
        },
        data(){
            return {
                all: [],
                teams: [],
                type: null,
                throttle: null,
                types: [
                    {
                        id: 'developer',
                        name: 'Developer',
                        icon: 'iconoir-linux'
                    },
                    {
                        id: 'district',
                        name: 'District',
                        icon: 'iconoir-bank'
                    }
                ],
                state: null,
                states: [                
                    {
                        id: 'active',
                        name: 'Active',
                        icon: 'green'
                    },                                       
                    {
                        id: 'inactive',
                        name: 'Inactive',
                        icon: 'grey'
                    },                                       
                    {
                        id: 'destroyed',
                        name: 'Destroyed',
                        icon: 'red'
                    }
                ],        
                updated_date: null,
                created_date: null,
                dates: [
                    {
                        id: 'last-hour',
                        name: 'Last Hour',
                        icon: 'iconoir-wristwatch'
                    },
                    {
                        id: 'last-day',
                        name: 'Last Day',
                        icon: 'iconoir-sun-light'
                    },
                    {
                        id: 'last-week',
                        name: 'Last Week',
                        icon: 'iconoir-send-mail'
                    },
                    {
                        id: 'last-month',
                        name: 'Last Month',
                        icon: 'iconoir-calendar'
                    },
                    {
                        id: 'last-year',
                        name: 'Last Year',
                        icon: 'iconoir-cinema-old'
                    }
                ],    
                search: '',
                query: '',
                loading: false,
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '50%',
                        component: TeamName,
                        value: row => row
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '140px',
                        component: State,
                        value: row => ({state: row.status})
                    },
                    {
                        id: 'type',
                        name: 'Type',
                        icon: Wrench,
                        width: '140px',
                        value: row => row.type
                    },
                    {
                        id: 'descriptor',
                        name: 'Descriptor',
                        icon: PeopleTag,
                        width: '20%',
                        value: row => row.descriptor
                    },
                    {
                        id: 'location',
                        name: 'Location',
                        icon: PinAlt,
                        width: '20%',
                        value: row => {
                            let location = '';

                            if(row.city){
                                location += row.city;

                                if(row.state){
                                    location += `, ${row.state}`;
                                }

                                if(row.country && row.country !== 'United States'){
                                    location += `, ${row.country}`;
                                }
                            }else if(row.state){
                                location += row.state;

                                if(row.country && row.country !== 'United States'){
                                    location += `, ${row.country}`;
                                }
                            }else if(row.country && row.country !== 'United States'){
                                location += row.country;
                            }

                            return location;
                        }
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '30%'
                    }
                ]
            };
        },
        created(){
            this.load();
        },
        methods: {
            open(team){
                this.$router.push({name: 'team.dashboard', params: {team: team.alias}});
            },
            switch_type(type){
                this.type = type;
                this.load();
            },
            switch_state(state){
                this.state = state;
                this.load();
            },
            switch_updated_date(updated_date){
                this.updated_date = updated_date;
                this.load();
            },
            switch_created_date(created_date){
                this.created_date = created_date;
                this.load();
            },
            create(){
                const _this = this;

                this.$modal.show(CreateTeam, {
                    redirect: true,
                    created(){
                        _this.load();
                    }
                }, {width: 400, height: 'auto', classes: 'modal'});
            },
            searched(){
                if(this.throttle){
                    clearTimeout(this.throttle);
                }

                this.throttle = setTimeout(() => {
                    this.load();
                }, 250);
            },
            load(){
                const params = {
                    $first: 100,
                    $filter: {
                        id: [],
                        type: [],
                        name: [],
                        status: [],
                        created_date: [],
                        updated_date: []
                    }
                };

                if(this.type){
                    params.$filter.type.push({ operator: 'equals', value: this.type.id });
                }

                if(this.search){
                    const reg_exp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                    
                    if(reg_exp.test(this.search)){
                        params.$filter.id.push({ operator: 'equals', value: this.search });
                    }else{
                        params.$filter.name.push({ operator: 'contains', value: this.search });
                    }
                }

                if(this.state){
                    params.$filter.status.push({ operator: 'equals', value: this.state.id });
                }else{
                    // By default, we want to only show active and inactive teams.
                    params.$filter.status.push({ operator: 'in', value: 'active,inactive' });
                }

                if(this.updated_date){
                    const adjusted_time_updated = moment();

                    if(this.updated_date.id === 'last-hour'){
                        adjusted_time_updated.subtract(1, 'hour');
                    } else if(this.updated_date.id === 'last-day'){
                        adjusted_time_updated.subtract(1, 'day');
                    } else if(this.updated_date.id === 'last-week'){
                        adjusted_time_updated.subtract(1, 'week');
                    } else if(this.updated_date.id === 'last-month'){
                        adjusted_time_updated.subtract(1, 'month');
                    } else if(this.updated_date.id === 'last-year'){
                        adjusted_time_updated.subtract(1, 'year');
                    }
                    
                    params.$filter.updated_date.push({ operator: 'gte', value: adjusted_time_updated.format() });
                }

                if(this.created_date){
                    const adjusted_time_created = moment();

                    if(this.created_date.id === 'last-hour'){
                        adjusted_time_created.subtract(1, 'hour');
                    } else if(this.created_date.id === 'last-day'){
                        adjusted_time_created.subtract(1, 'day');
                    } else if(this.created_date.id === 'last-week'){
                        adjusted_time_created.subtract(1, 'week');
                    } else if(this.created_date.id === 'last-month'){
                        adjusted_time_created.subtract(1, 'month');
                    } else if(this.created_date.id === 'last-year'){
                        adjusted_time_created.subtract(1, 'year');
                    }
                    
                    params.$filter.created_date.push({ operator: 'gte', value: adjusted_time_created.format() });
                }

                this.$http.get('/admin/teams', { params })
                .then(results => this.teams = results.$data)
                .catch(() => this.$toasted.error('There was an error loading results.'))
                .finally(() => this.loading = false);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .admin-teams
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .filters
    {
        top: @breadcrumbs-height;
    }

    .team-type
    {
        width: 160px;
        margin-right: 15px;
    }

    .no-results
    {
        border: 0;
        padding: 50px;
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;
        
        &.red
        {
            background: @red;
        }
        &.blue
        {
            background: @base;
        }
        &.purple
        {
            background: @purple;
        }
        &.yellow
        {
            background: @yellow;
        }
        &.dark-red
        {
            background: @hover;
        }
        &.grey
        {
            background: @c;
        }
    }

</style>
