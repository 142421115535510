<template>
    <div class="create-entity flex flex-column">
        <header class="drawer-header flex flex-align">
            <div class="ff drawer-title flex flex-align">
                <component :is="icon" class="icon" width="20" height="20" stroke-width="2" />
                Create {{ an.includes(type) ? 'an' : 'a' }} {{ entities[type] }}
            </div>
            <div class="button" @click="create">Create {{ entities[type] }}</div>
            <div class="button white" @click="close">Close</div>
        </header>
        <div class="drawer-content drawer-scroll">
            <component :is="component" />
        </div>
    </div>
</template>

<script>
    import CreatePersonEntity from '@/components/drawers/entity/create/CreatePersonEntity.vue';
    import CreateSchoolEntity from '@/components/drawers/entity/create/CreateSchoolEntity.vue';
    import CreateClassEntity from '@/components/drawers/entity/create/CreateClassEntity.vue';
    import CreateCourseEntity from '@/components/drawers/entity/create/CreateCourseEntity.vue';
    import CreateSessionEntity from '@/components/drawers/entity/create/CreateSessionEntity.vue';
    import CreateEnrollmentEntity from '@/components/drawers/entity/create/CreateEnrollmentEntity.vue';
    import CreateSectionEntity from '@/components/drawers/entity/create/CreateSectionEntity.vue';
    import CreateAgentEntity from '@/components/drawers/entity/create/CreateAgentEntity.vue';

    import {
        SettingsAlt,
        UserLove,
        Packages,
        ListSelect,
        Calendar,
        Apple,
        Calculator,
        BookmarkCircle,
        DesignPencil,
        Bank,
        Desk,
        Swimming,
        Coin,
        WarningCircle,
        Presentation,
        Group,
        Clock,
        MathBook,
        Map,
        City,
        Asana,
        CalendarPlus,
        PinAlt,
        SineWave,
        Truck
    } from '@epiphany/iconoir';

    export default {
        name: 'CreateEntity',
        data(){
            return {
                an: [ 'agents', 'assets', 'attendance', 'enrollments', 'incidents' ],
                icons: {
                    'agents': UserLove,
                    'assets': Packages,
                    'attendance': ListSelect,
                    'calendars': Calendar,
                    'classes': Apple,
                    'courses': Calculator,
                    'days': BookmarkCircle,
                    'departments': DesignPencil,
                    'districts': Bank,
                    'enrollments': Desk,
                    'facilities': Swimming,
                    'fees': Coin,
                    'incidents': WarningCircle,
                    'meetings': Presentation,
                    'people': Group,
                    'periods': Clock,
                    'rooms': MathBook,
                    'routes': Map,
                    'schools': City,
                    'sections': Asana,
                    'sessions': CalendarPlus,
                    'stops': PinAlt,
                    'subjects': SineWave,
                    'vehicles': Truck
                },
                entities: {
                    agents: 'Agent',
                    assets: 'Asset',
                    attendance: 'Attendance Record',
                    calendars: 'Calendar',
                    classes: 'Class',
                    courses: 'Course',
                    days: 'Day',
                    departments: 'Department',
                    districts: 'District',
                    enrollments: 'Enrollment',
                    facilities: 'Facility',
                    fees: 'Fee',
                    incidents: 'Incident',
                    meetings: 'Meeting',
                    people: 'Person',
                    periods: 'Period',
                    rooms: 'Room',
                    routes: 'Route',
                    schools: 'School',
                    sections: 'Section',
                    sessions: 'Session',
                    stops: 'Stop',
                    subjects: 'Subject',
                    vehicles: 'Vehicle'
                },
                components: {
                    people: CreatePersonEntity,
                    schools: CreateSchoolEntity,
                    classes: CreateClassEntity,
                    courses: CreateCourseEntity,
                    sessions: CreateSessionEntity,
                    sections: CreateSectionEntity,
                    agent: CreateAgentEntity,
                    enrollments: CreateEnrollmentEntity
                }
            };
        },
        methods: {
            icon(){
                return this.icons[this.type];
            },
            create(){
                this.$store.dispatch('entities/create')
                .then(() => this.$store.commit('entities/deactivate'))
                .then(() => this.close())
                .then(() => this.$toasted.success(`Created ${this.entities[this.type]} successfully.`))
                .catch(() => this.$toasted.error(`Failed to create the ${this.entities[this.type]}.`));
            },
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            icon() {
                if(this.icons.hasOwnProperty(this.type)){
                    return this.icons[this.type];
                }

                return SettingsAlt;
            },
            team(){
                return this.$store.getters.team;
            },
            props(){
                return this.$store.state.drawer.props;
            },
            entity(){
                return this.props.entity;
            },
            type(){
                return this.props.type;
            },
            component(){
                return this.components[this.type];
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-entity
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;
        }
    }

    .button
    {
        margin-left: 15px;
    }
</style>
