import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';

const state = {
    active: null,
    overrides: null,
    loading: false,
    id: null,
    type: null,
    source: null,
    integration: null,
    schema: null
};

const expansion = {
    people: ['schools'],
    enrollments: ['person', 'class', 'section'],
    sessions: ['schools'],
    agents: ['observer', 'target']
};

const types = {
    people: 'person',
    periods: 'period',
    districts: 'district',
    schools: 'school',
    classes: 'class',
    courses: 'course',
    sections: 'section',
    sessions: 'session',
    agents: 'agent',
    enrollments: 'enrollment',
    assets: 'asset',
    attendance: 'attendance',
    calendars: 'calendar',
    days: 'day',
    departments: 'department',
    facilities: 'facility',
    fees: 'fee',
    incidents: 'incident',
    meetings: 'meeting',
    rooms: 'room',
    routes: 'route',
    stops: 'stop',
    subjects: 'subject',
    vehicles: 'vehicle'
};

let throttle = null;

const mutations = {
    activate(state, { id, type, source, integration, schema }) {
        state.loading = true;
        state.id = id;
        state.type = type;
        state.source = source;
        state.integration = integration;
        state.schema = schema;
    },
    load(state, { id, active }) {
        // This prevents a potential race condition where something loads slowly and clobbers a different value.
        if (id === state.id) {
            state.active = active;
            state.loading = false;

            if (active.overrides) {
                state.overrides = _.cloneDeep(active.overrides.properties);
            } else {
                state.overrides = {};
            }
        }
    },
    create(state, { type, source, integration }) {
        state.active = null;
        state.overrides = {};
        state.id = null;
        state.type = type;
        state.source = source;
        state.integration = integration;
        state.loading = false;
    },
    deactivate() {
        state.active = null;
        state.overrides = null;
        state.id = null;
        state.type = null;
        state.source = null;
        state.integration = null;
        state.loading = false;
    },
    override(state, { property, value }) {
        let prop = property;

        if (property.startsWith('demographics.')) {
            prop = property.slice(13);
        } else if (property.startsWith('address.')) {
            prop = property.slice(8);
        }

        if (state.overrides) {
            Vue.set(state.overrides, prop, value);
        }
    },
    reset(state, property) {
        let prop = property;

        if (property.startsWith('demographics.')) {
            prop = property.slice(13);
        } else if (property.startsWith('address.')) {
            prop = property.slice(8);
        }

        const last = state.active?.overrides ? _.get(state.active.overrides.properties, prop) : null;

        if (last) {
            Vue.set(state.overrides, prop, last);
        } else {
            Vue.delete(state.overrides, prop);
        }
    },
    clear(state, property) {
        let prop = property;

        if (property.startsWith('demographics.')) {
            prop = property.slice(13);
        } else if (property.startsWith('address.')) {
            prop = property.slice(8);
        }

        if (state.overrides) {
            Vue.delete(state.overrides, prop);
        }
    },
    update(state, updated) {
        state.overrides = updated.properties;
        if (state.active) {
            state.active.overrides = updated;
        }
    }
};

const actions = {
    async activate({ commit, rootGetters }, { id, type, source, integration, extras, schema }) {
        commit('activate', { id, type, source, integration, schema });

        const parent = state.integration ? `integrations/${state.integration.id}` : `sources/${state.source.id}`;

        if (type === 'overrides') {
            // This type is a little different from normal entities as it is not available through the standard Graph API.
            return axios.get(`/teams/${rootGetters.team.id}/${parent}/overrides/${id}`).then((response) => commit('load', { active: response.$data, id }));
        }

        const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

        if (['tasks'].includes(type) && extras && extras.parent) {
            return axios
                .get(`${base}/api/v2/graph/${extras.parent.type}/${extras.parent.id}/${type}/${id}`, {
                    headers: {
                        authorization: `Bearer ${rootGetters.integration.access_token}`
                    }
                })
                .then((response) => commit('load', { active: response.$data, id }));
        }

        // TODO Eventually when we're happy with the new APIs with filtering, expanding, overrides, etc. we can remove this.
        // I just really don't want to break things in production right now.
        if(state.integration){
            return axios
            .get(`${base}/api/v2/graph/${type}/${id}`, {
                headers: {
                    authorization: `Bearer ${rootGetters.integration.access_token}`
                },
                params: {
                    $expand: ['overrides'].concat(expansion[type]).join(',')
                }
            })
            .then((response) => {
                commit('load', { active: response.$data, id });
            });
        }

        const params = {};
        const expand = [];

        // We don't really care about overrides for other schemas because they are not applied at that point anyway.
        if(['enriched', 'shared', 'transformed'].includes(schema)){
            expand.push('overrides');
        }

        if(expansion[type]){
            // expand.push(...expansion[type]);
        }

        if(expand.length){
            params.$expand = expand.join(',');
        }

        if(schema){
            params.$schema = schema;
        }
        
        const { $data } = await axios.get(`/teams/${rootGetters.team.id}/${parent}/${type}/${id}`, { baseURL: '/api/v2', params });

        commit('load', { active: $data, id });
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit, state, rootGetters, dispatch }) {
        if (state.active) {
            if (throttle) {
                clearTimeout(throttle);
            }

            // Dispatch a change to our save service.
            dispatch('save/save', 'overrides/update', { root: true });

            throttle = setTimeout(() => {
                throttle = null;

                const parent = state.integration ? `integrations/${state.integration.id}` : `sources/${state.source.id}`;

                // If we don't have an override ID, we need to create a new one.
                // If we do have an ID, we need to update the existing one.
                if (state.active.overrides) {
                    const clone = _.clone(state.active.overrides);

                    // Set the properties of the clone to be our updated properties.
                    clone.properties = state.overrides;

                    return axios
                        .put(`/teams/${rootGetters.team.id}/${parent}/overrides/${state.active.overrides.id}`, clone)
                        .then((response) => commit('update', response.$data))
                        .finally(() => dispatch('save/saved', 'overrides/update', { root: true }));
                } else {
                    return axios
                        .post(`/teams/${rootGetters.team.id}/${parent}/overrides`, {
                            action: 'updated',
                            type: types[state.type],
                            entity_id: state.active.id,
                            properties: state.overrides
                        })
                        .then((response) => commit('update', response.$data))
                        .finally(() => dispatch('save/saved', 'overrides/update', { root: true }));
                }
            }, 250);
        }
    },
    create({ state, rootGetters, dispatch }) {
        const parent = state.integration ? `integrations/${state.integration.id}` : `sources/${state.source.id}`;

        // Dispatch a change to our save service.
        dispatch('save/save', 'overrides/create', { root: true });

        // We don't have an override ID, we need to create a new one.
        return axios
            .post(`/teams/${rootGetters.team.id}/${parent}/overrides`, {
                action: 'created',
                type: types[state.type],
                properties: state.overrides
            })
            .finally(() => dispatch('save/saved', 'overrides/create', { root: true }));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
