<template>
    <div class="integrate-source-provider-moodle">
        <integrate-source-editor-step name="Enter Your Moodle URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers and student go to sign into Moodle.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.instructure.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Confirm that Web Services is Enabled" icon="iconoir-safari" :complete="completed.includes('web')">
            <div class="helptext">
                Web services is already enabled on most Moodle instances, but let's check.
            </div>
            <integrate-source-editor-configuration>
                <label>Open Moodle Settings</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/admin/settings.php?section=webservicesoverview'">
                        Open Web Services Settings Page
                    </a>
                </div>
                <label>Confirm Settings</label>
                <div class="flex">
                    <div class="configuration-value">
                        There are two sets of settings on the linked page, you may have to scroll down.
                        Please make sure that the following settings are enabled in both sections.
                    </div>
                </div>
                <table class="configuration-table">
                    <tr>
                        <td class="configuration-value">Enable web services</td>
                        <td class="configuration-value">Yes</td>
                    </tr>
                    <tr>
                        <td class="configuration-value">Enable protocols</td>
                        <td class="configuration-value">REST</td>
                    </tr>
                </table>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="web" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Install the Edlink Plugin" icon="iconoir-code" :complete="completed.includes('remote')">
            <div class="helptext">
                Download and install the Edlink plugin to connect to Moodle.
            </div>
            <integrate-source-editor-configuration title="Installing the Edlink Moodle Plugin" icon="iconoir-info-empty">
                <label>Download the Plugin</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" href="https://edlink.github.io/docs/media/plugins/moodle/edlink.zip">
                        Download Moodle Plugin
                    </a>
                </div>
                <label>Install the Plugin</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/admin/tool/installaddon/index.php'">
                        Open Plugins Settings Page
                    </a>
                </div>
                <label>Configure the Plugin</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/local/edlink/index.php'">
                        Open Edlink Plugin Settings
                    </a>
                </div>
                <label>Generate Edlink API Keys</label>
                <div class="flex">
                    <div class="configuration-value">
                        Click the `Generate Edlink API Keys` button towards the top-left of the screen.
                        A new row will be automatically added to the table below this button.
                    </div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Add the Client ID and Secret Key From Moodle" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <div class="helptext">
                The Client ID can be found in the "Client Identifier" column.
                The Secret Key can be found in the "Client Secret Key" column.
                Both values are found in the Edlink Plugin Settings page from the previous step.
            </div>
            <label class="key-label">Client ID</label>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.application_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_id', value: e.target.value})" />
                <!-- <div class="integrate-input-help text-button mini has-icon">
                    <span class="icon iconoir-info-empty block"></span>
                    <div>See a Picture</div>
                </div> -->
            </div>
            <label class="key-label">Secret Key</label>
            <div class="flex flex-align">
                <input class="medium client-secret" type="text" placeholder="Secret Key" :value="source.configuration.application_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_secret', value: e.target.value})" />
                <!-- <div class="integrate-input-help text-button mini has-icon">
                    <span class="icon iconoir-info-empty block"></span>
                    <div>See a Picture</div>
                </div> -->
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Connect Your Moodle Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
            <div class="helptext">
                Sign into your Moodle account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <token :write="destination" :configuration="source.configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderMoodle',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // Validate the URL.
                if(!this.source.configuration.url.match(url)){
                    return [];
                }

                // Is the web services config done?
                if(!this.web){
                    return ['url'];
                }

                // Is the remote configuration done?
                if(!this.remote){
                    return ['url', 'web'];
                }

                // Is the remote configuration done?
                if(!this.source.configuration.application_id || !this.source.configuration.application_secret){
                    return ['url', 'web', 'remote'];
                }

                // Validate that the token has been acquired.
                if(!this.source.token && !this.source.id){
                    return ['url', 'web', 'remote', 'keys'];
                }
                
                return ['url', 'web', 'remote', 'keys', 'token'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 5);
            }
        },
        data(){
            return {
                remote: false,
                web: false,
                uris: [
                    'https://ed.link/api/authentication/moodle',
                    'https://ed.link/sso/administrator'
                ]
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .uri
    {
        margin-bottom: 4px;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .configuration-value.scopes
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
