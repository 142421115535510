import axios from 'axios';
import Vue from 'vue';
import store from '..';

const state = {
    all: [],
    active: null,
    initialize: null,
    loaded: false
};

const mutations = {
    members(state, members) {
        Vue.set(state.active, 'members', members);
    },
    initialize(state, promise) {
        state.initialize = promise;
    },
    load(state, teams) {
        while (state.all.length) {
            state.all.pop();
        }

        for (const team of teams) {
            state.all.push(team);
        }
    },
    add(state, team) {
        state.all.push(team);
    },
    remove(state, team) {
        state.all.splice(state.all.indexOf(team), 1);

        if (state.active === team) {
            state.active = null;
        }
    },
    activate(state, team) {
        if (team) {
            state.active = team;
        } else {
            state.active = state.all.length ? state.all[0] : null;
        }
    },
    entity(state, entity) {
        Vue.set(state.active, 'entity', entity);
    },
    clear(state) {
        state.all = [];
        state.active = null;
        state.initialize = null;
    },
    update(state, updated) {
        const valid_fields = [
            'name',
            'alias',
            'description',
            'icon_url',
            'banner_url',
            'website',
            'support_website',
            'street_address',
            'unit_number',
            'city',
            'state',
            'zip',
            'country',
            'descriptor',
            'identifiers',
            'updated_date',
            'technical_contacts',
            'support_contacts',
            'properties',
            'verified',
            'xero_contact_id'
        ];

        for (const field of valid_fields) {
            if (updated.hasOwnProperty(field)) {
                state.active[field] = updated[field];
            }
        }
    }
};

const actions = {
    load({ commit, state, dispatch }) {
        if (state.initialize) {
            return state.initialize;
        }

        const token = localStorage.getItem('edlink-user-token');

        if (token) {
            const promise = axios
                .get('/teams')
                .then((response) => commit('load', response.$data))
                .then(() => commit('activate'))
                .then(() => dispatch('members'))
                .then(() => {
                    if (store.state.user.admin) {
                        return get_admin_team(state.active.id);
                    } else {
                        return null;
                    }
                })
                .then((team) => {
                    if (team) {
                        commit('entity', team.entity);
                    }
                });

            commit('initialize', promise);

            return promise;
        }
    },
    add({ commit }, team) {
        commit('add', team);
    },
    remove({ commit, state }, team) {
        if (!team) {
            if (state.active) {
                team = state.active;
            } else {
                return Promise.reject('There is no active team.');
            }
        }

        commit('remove', team);
        commit('activate');
    },
    async members({ commit, state }) {
        // Load a list of team members. This is really only to see if they have any CSMs.
        if (!state.active) {
            return;
        }

        if (!Array.isArray(state.active.members)) {
            await axios
                .get(`/teams/${state.active.id}/members`)
                .then((response) => commit('members', response.$data))
                .catch(() => {});
        }
    },
    async activate({ commit, state, dispatch }, team) {
        if (state.active && state.active.id === team.id) {
            return;
        }

        commit('activate', team);

        // Load the members for this team.
        await dispatch('members');

        if (store.state.user.admin) {
            return get_admin_team(team.id)
                .then((team) => commit('entity', team.entity))
                .catch((error) => Promise.reject('There was an error activating the team as an admin.'));
        }
    },
    clear({ commit }) {
        commit('clear');
    },
    update({ commit, state }, updates) {
        if (state.active) {
            return axios.put(`/teams/${state.active.id}`, updates).then((response) => {
                commit('update', response.$data);

                if (response.$data.alias !== state.active.alias) {
                    //TODO We need to move the user over to the new alias.
                }
            });
        }

        return Promise.reject('There is no active team.');
    },
    alias({ commit, state, dispatch }, alias) {
        if (state.active.alias === alias) {
            return;
        }

        return axios
            .get(`/admin/teams/alias/${alias}`)
            .then((response) => commit('activate', response.$data))
            .then(() => dispatch('members'))
            .then(() => get_admin_team(state.active.id))
            .then((team) => commit('entity', team.entity))
            .catch((error) => console.log(error));
    }
};

function get_admin_team(team_id) {
    return axios.get(`/admin/teams/${team_id}`).then((response) => response.$data);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
