<template>
    <div class="entity-drawer flex flex-column">
        <header class="drawer-header">
            <div class="flex flex-align">
                <h2 class="ff drawer-title">Invite Members</h2>
                <div class="button has-icon white base flex flex-align" @click="$store.dispatch('drawer/close')">
                    Close
                </div>
            </div>
        </header>
        <form class="invite-members" @submit.prevent="send">
            <label>Invite Member By Email</label>
            <div class="form-helptext">Enter an email address for the new team member.</div>
            <input v-model="email" />
            <label>Permissions</label>
            <div class="form-helptext">Control the level of access for this new member.</div>
            <select v-model="type">
                <option default value="readwrite">View and Edit</option>
                <option value="read">View Only</option>
            </select>
            <button type="submit">Send Invitation</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'InviteMembers',
        data(){
            return {
                email: '',
                type: 'readwrite'
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            }
        },
        methods: {
            send(option){
                if(!this.email){
                    return this.$toasted.error('Please enter an email address.');
                }

                this.$http.post(`/teams/${this.team.id}/invitations`, {email: this.email, type: this.type})
                .then(response => {
                    this.$toasted.success('Sent invitation.');
                    this.$emit('close');
                })
                .catch(error => {
                    console.log(error);
                    this.$toasted.error('There was an error sending your invitation.');
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .invite-members
    {
        padding: 30px;

        label
        {
            // margin-top: 20px;

            &:first-child
            {
                margin-top: 0;
            }
        }

        button
        {
            width: 100%;
            margin-top: 30px;
        }
    }
</style>
