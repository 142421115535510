<template>
    <div class="entity-details">
        <template v-if="matchObj">
            <div class="header-section flex flex-align">
                <div class="ff flex flex-align">
                    <div class="secondary-source-name">{{link.source.name}}</div>
                    <div class="badge capitalize">{{matchType}}</div>
                </div>
                <div v-if="matchObj.secondary_id !== null" @click="$emit('toggle-expand')" class="button white">{{ expanded ? 'Collapse Data' : 'Expand Data' }}</div>
                <div v-if="!matchObj.secondary_id" @click="deleteMatch" class="reenable-match-button button white">Re-enable matching</div>
                <div v-else-if="matchObj.state !== 'manual'" @click="breakMatch" class="break-match-button white red button">Break Match</div>
                <div v-else @click="deleteMatch" class="break-match-button red button">Delete match</div>
            </div>
            <entity-json v-if="matchObj.secondary_id" :input_obj="matchObj[entities[type].toLowerCase()]" />
            <div v-else class="nothing">Matching has been disabled for this object / source pair.</div>
        </template>
        <template v-else>
            <div class="header-section">
                <h4>Create a new match for secondary source {{link.source.name}}:</h4>
            </div>
            <div class="input-section">
                <input :placeholder="newMatchInputPlaceholder" :value="newMatchID" @input="event => newMatchID = event.target.value" class="new-match-id-input medium" />
            </div>
            <div class="flex">
                <div @click="createEntityMatch" class="button">Create Match</div>
            </div>
        </template>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';
    import EntityJSONWithProps from '@/components/drawers/entity/EntityJSONWithProps.vue';

    export default {
        name: 'EntityMatch',
        components: {
            'entity-field': EntityField,
            'entity-json': EntityJSONWithProps
        },
        props: {
            type: String,
            matches: Array,
            expanded: Boolean,
            link: Object,
            reloadMatches: Function
        },
        data() {
            return {
                properties: {
                    people: [
                        {title: 'Person ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'First Name', property: 'first_name', type: 'string', editable: true},
                        {title: 'Last Name', property: 'last_name', type: 'string', editable: true},
                        {title: 'Display Name', property: 'display_name', type: 'string', editable: true},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true},
                        {title: 'Roles', property: 'roles', type: 'role', array: true, editable: true},
                        {title: 'Email Address', property: 'email', type: 'string', editable: true},
                        {title: 'Phone Number', property: 'phone', type: 'string', editable: true},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true},
                        {title: 'Graduation Year', property: 'graduation_year', type: 'number', editable: true},
                        {title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true},
                        {title: 'Schools', property: 'school_ids', type: 'school', array: true, editable: true}
                    ],
                    demographics: [
                        {title: 'Birthday', property: 'demographics.birthday', type: 'date', editable: true},
                        {title: 'Country of Birth', property: 'demographics.country_of_birth', type: 'string', editable: true},
                        {title: 'State of Birth', property: 'demographics.state_of_birth', type: 'string', editable: true},
                        {title: 'City of Birth', property: 'demographics.city_of_birth', type: 'string', editable: true},
                        {title: 'Gender Identity', property: 'demographics.gender', type: 'gender', editable: true},
                        {title: 'Races', property: 'demographics.races', type: 'race', array: true, editable: true},
                        {title: 'Hispanic or Latino', property: 'demographics.hispanic_or_latino_ethnicity', type: 'boolean', editable: true},
                        {title: 'English Language Learner', property: 'demographics.english_language_learner', type: 'boolean', editable: true},
                        {title: 'Individualized Education Plan', property: 'demographics.individualized_education_plan', type: 'boolean', editable: true},
                        {title: 'Primary Language', property: 'demographics.primary_language', type: 'primary_language', editable: true},
                        {title: 'Special Accommodations', property: 'demographics.special_accommodations', type: 'boolean', editable: true},
                        {title: 'Military Connected', property: 'demographics.military_connected', type: 'military_connected_status', editable: true},
                        {title: 'Food Service Program Eligibility', property: 'demographics.food_service_program_eligibility', type: 'food_service_program', editable: true},
                        {title: 'Residence Status', property: 'demographics.residence_status', type: 'residence_status', editable: true},
                        {title: 'Homeless', property: 'demographics.homeless', type: 'boolean', editable: true},
                        {title: 'Migrant Status', property: 'demographics.migrant', type: 'boolean', editable: true},
                        {title: 'Disability', property: 'demographics.disability', type: 'disability', editable: true},
                        {title: 'Gifted or Talented', property: 'demographics.gifted_talented', type: 'boolean', editable: true},
                        {title: 'Economically Disadvantaged', property: 'demographics.economically_disadvantaged', type: 'boolean', editable: true},
                        {title: 'Public Assistance', property: 'demographics.public_assistance', type: 'boolean', editable: true},
                        {title: 'Rural Residency', property: 'demographics.rural_residency', type: 'boolean', editable: true}
                    ],
                    enrollments: [
                        {title: 'Enrollment ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Person ID', property: 'person_id', type: 'string', monospace: true, editable: true},
                        {title: 'Class ID', property: 'class_id', type: 'string', monospace: true, editable: true},
                        {title: 'Section ID', property: 'section_id', type: 'string', monospace: true, editable: true},
                        {title: 'Role', property: 'role', type: 'role', editable: true},
                        {title: 'Primary', property: 'primary', type: 'boolean', editable: true},
                        {title: 'State', property: 'state', type: 'string', editable: true},
                        {title: 'Start Date', property: 'start_date', type: 'date', editable: true},
                        {title: 'End Date', property: 'end_date', type: 'date', editable: true}
                    ],
                    schools: [
                        {title: 'School ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Name', property: 'name', type: 'string', editable: true},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true},
                        {title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true},
                        {title: 'Address', property: 'location', type: 'address', monospace: true}
                    ],
                    districts: [
                        {title: 'District ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Name', property: 'name', type: 'string', editable: true},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true},
                        {title: 'Address', property: 'location', type: 'address', monospace: true}
                    ],
                    classes: [
                        {title: 'Class ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Name', property: 'name', type: 'string', editable: true},
                        {title: 'Description', property: 'description', type: 'string', editable: true},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true},
                        {title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true},
                        {title: 'Subjects', property: 'subjects', type: 'subject', array: true, editable: true},
                        {title: 'Periods', property: 'periods', type: 'string', array: true, editable: true},
                        {title: 'State', property: 'state', type: 'string', editable: true},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true},
                        {title: 'Sessions', property: 'session_ids', type: 'string', array: true, editable: true},
                        {title: 'Course', property: 'course_id', type: 'string', editable: true},
                        {title: 'School', property: 'school_id', type: 'string', editable: true}
                    ],
                    sections: [
                        {title: 'Section ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Name', property: 'name', type: 'string', editable: true},
                        {title: 'Description', property: 'description', type: 'string', editable: true},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true},
                        {title: 'Periods', property: 'periods', type: 'string', array: true, editable: true},
                        {title: 'State', property: 'state', type: 'string', editable: true},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true},
                        {title: 'Class', property: 'class_id', type: 'string', editable: true}
                    ],
                    courses: [
                        {title: 'Course ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Name', property: 'name', type: 'string', editable: true},
                        {title: 'Code', property: 'code', type: 'string', editable: true},
                        {title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true},
                        {title: 'Subjects', property: 'subjects', type: 'subject', array: true, editable: true},
                        {title: 'Session', property: 'session_id', type: 'string', editable: true},
                        {title: 'School', property: 'school_id', type: 'string', editable: true}
                    ],
                    sessions: [
                        {title: 'Session ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Name', property: 'name', type: 'string', editable: true},
                        {title: 'Start Date', property: 'start_date', type: 'date', editable: true},
                        {title: 'End Date', property: 'end_date', type: 'date', editable: true},
                        {title: 'State', property: 'state', type: 'string', editable: true},
                        {title: 'Type', property: 'type', type: 'string', editable: true},
                        {title: 'School', property: 'school_id', type: 'string', editable: true}
                    ],
                    agents: [
                        {title: 'Agent ID', property: 'id', type: 'string', monospace: true, copyable: true},
                        {title: 'Created Date', property: 'created_date', type: 'date'},
                        {title: 'Updated Date', property: 'updated_date', type: 'date'},
                        {title: 'Relationship', property: 'relationship_type', type: 'string', editable: true},
                        {title: 'Observer', property: 'observer_id', type: 'string', editable: true},
                        {title: 'Target', property: 'target_id', type: 'string', editable: true}
                    ]
                },
                entities: {
                    people: 'Person',
                    classes: 'Class',
                    sections: 'Section',
                    courses: 'Course',
                    schools: 'School',
                    districts: 'District',
                    enrollments: 'Enrollment',
                    agents: 'Agent',
                    sessions: 'Session',
                    resources: 'Resource'
                },
                newMatchID: ""
            };
        },
        created() {},
        methods: {
            impersonate(){},
            close() {
                this.$store.dispatch('drawer/close');
            },
            name(integration) {
                return integration.team.name;
            },
            matchedSecondaryObj() {
                return this.matchObj[this.entities[this.type].toLowerCase()];
            },
            breakMatch() {
                // overwrite the match with a manual one, with an empty secondary ID.
                this.createMatch(null);
            },
            deleteMatch() {
                this.$http.delete(`/teams/${this.team.id}/sources/${this.sourceID}/matches/${this.type}/${this.matchObj.link.id}`)
                    .then(delete_response => {
                        console.log("delete response:")
                        console.log(delete_response);
                        this.reloadMatches();
                    }).catch(err => {
                        console.log(err);
                        this.$toasted.error(`Unable to delete match.`);
                    });
            },
            createMatch(new_match_id) {
                const new_match = {
                     primary_source_id: this.link.primary.source_id,
                     secondary_source_id: this.link.secondary.source_id,
                     primary_id: this.entity.id,
                     secondary_id: new_match_id
                };

                // TODO: might want to use graph token instead of user token (bc client doesnt own source)?
                this.$http.post(`/teams/${this.team.id}/sources/${this.sourceID}/matches/${this.type}`, new_match)
                    .then(links_response => {
                        this.newMatchID = '';
                        this.reloadMatches();
                    }).catch(err => {
                        this.$toasted.error(`Unable to retrieve matches.`);
                    });
            },
            createEntityMatch() {
                this.createMatch(this.newMatchID);
            }
        },
        computed: {
            newMatchInputPlaceholder() {
                return `Input ${this.entities[this.type]} ID...`;
            },
            team() {
                return this.$store.getters.team;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            sourceID() {
                return this.integration ? this.integration.source.id : this.source.id;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            matchType() {
                if(!this.matchObj.secondary_id) {
                    return 'Manually Disabled';
                }

                return `${this.matchObj.state} Match`;
            },
            matchObj() {
                return this.matches.find(match =>
                    match.secondary_source_id === this.link.secondary.source_id
                    || match.link.secondary_source_id === this.link.secondary.source_id
                ) ?? null;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            title() {
                return this.type === 'people' ? this.matchObj.display_name : this.matchObj.name;
            },
            matchParametersExist() {
                return (this.matchObj.parameters?.length ?? 0) > 0;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity-details
    {
        padding: 15px;
    }

    .secondary-source-name
    {
        font-size: 15px;
        font-weight: 500;
        color: @black;
    }

    .badge
    {
        margin-left: 15px;
    }

    .text-button {
        height: 34px !important;
        margin-left: auto;
    }

    .header-section {
        display: flex;
        flex-direction: row;

        .button
        {
            margin-left: 15px;
        }
    }

    .input-section {
        display: flex;
        flex-direction: row;
        margin: 15px 0;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }
</style>
