<template>
    <div class="dataset full">
        <filters>
            <filter-custom :applied="filters" @apply="apply" :filterable="filterable" />
            <div class="ff"></div>
            <pages :all="all" :rows="rows" :more="next_page_available" :count="count" :page="page" @next="next" @previous="previous" />
        </filters>
        <datatable class="dataset full" :columns="types[type]" :rows="rows" :selectable="true" :header="true" :loading="loading" :clickable="true" @clicked="(row) => focus(row)" />
    </div>
</template>

<script>
import _ from 'lodash';
import { Filter as FilterIcon, AddCircle, Cancel, FingerprintSquare, Svg3dSelectSolid, Calendar, CodeBracketsSquare } from '@epiphany/iconoir';

import Entity from '@/components/drawers/Entity.vue';
import Options from '@/components/modals/Options.vue';

import parser from 'papaparse';
import { flatten } from 'flat';

import { EXTERNAL_OBJECT_TYPES } from '@/constants';

const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

export default {
    name: 'SourceDataset',
    components: {
        FilterIcon,
        AddCircle,
        Cancel
    },
    computed: {
        team() {
            return this.$store.getters.team;
        },
        source() {
            return this.$store.getters.source;
        },
        admin() {
            return this.$store.getters.user.admin;
        },
        type() {
            if (EXTERNAL_OBJECT_TYPES.includes(this.$route.params.type)) {
                return this.$route.params.type;
            }

            return null;
        },
        rows() {
            return this.all.slice(this.page * this.count, this.page * this.count + this.count);
        },
        filterable() {
            return this.type ? this.types[this.type].filter(property => property.filter) : [];
        },
        property() {
            return this.types[this.type].find(type => type.property === this.filter.property);
        }
    },
    watch: {
        type: {
            immediate: true,
            handler() {
                //Reset the page number and filters.
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filters = [];

                if (this.$route.query.filters) {
                    try {
                        const query = JSON.parse(this.$route.query.filters);

                        if(_.isArray(query)){
                            this.filters = query;
                        }
                    } catch (error) {
                        this.$toasted.error('Invalid filter.');
                    }
                }

                if (this.source) {
                    this.load();
                }
            }
        },
        source: {
            immediate: true,
            handler() {
                //Reset the page number and filters.
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filters = [];

                if (this.$route.query.filters) {
                    try {
                        const query = JSON.parse(this.$route.query.filters);

                        if(_.isArray(query)){
                            this.filters = query;
                        }
                    } catch (error) {
                        this.$toasted.error('Invalid filter.');
                    }
                }

                if (this.type) {
                    this.load();
                }
            }
        },
        page: {
            immediate: true,
            handler(new_page, old_page) {
                if (new_page === old_page) {
                    return;
                }

                if (_.isNil(this.rows) || _.isEmpty(this.rows)) {
                    return;
                }

                this.$router.push({
                    query: {
                        filters: JSON.stringify(this.filters),
                        before: _.get(_.last(this.rows), 'id'),
                        after: _.get(_.first(this.rows), 'id')
                    }
                });
            }
        }
    },
    methods: {
        overridden(row, field) {
            return _.has(row, `overrides.properties.${field.property}`);
        },
        load() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            // Apply our filter to the params array.
            this.params.$filter = {};

            for(const { value, property, operator } of this.filters) {
                if (value || ['is known', 'is unknown'].includes(operator)) {
                    this.params.$filter[property] = [{ operator, value }];
                }
            }

            const params = _.clone(this.params);

            // TODO DJG We can't do expansions yet with the new ORM.
            // if (this.type === 'people') {
            //     params.$expand = 'overrides,schools';
            // }else{
            //     params.$expand = 'overrides';
            // }

            return this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/${this.type}`, { params, baseURL: '/api/v2' })
            .then(results => {
                this.all.push(...results.$data);
                if(results.$next){
                    this.next_page_available = true;
                }else{
                    this.next_page_available = false;
                }
            })
            .catch(error => this.$toasted.error('There was an error loading results.'))
            .finally(() => this.loading = false);
        },
        focus(entity) {
            this.$store.dispatch('entities/activate', {
                id: entity.id,
                type: this.type,
                source: this.source
            })
            .then(() => {
                this.$store.dispatch('drawer/open', {
                    key: `${this.type}/${entity.id}`,
                    width: 800,
                    component: 'entity'
                });
            });
        },
        previous() {
            //We definitely have these results already.
            if (this.page) {
                this.page -= 1;
            }
        },
        next() {
            if((this.page + 1) * this.count < this.all.length || this.next_page_available){
                //If we already have these results, we can just swap them in.
                //Otherwise, we need to fetch from the server.
                if(this.all.length <= this.count * (this.page + 1)){
                    if (['requests', 'events', 'materializations', 'flows', 'jobs'].includes(this.type)) {
                        this.params.$before = this.all[this.all.length - 1].id;
                    } else {
                        this.params.$after = this.all[this.all.length - 1].id;
                    }
                    this.load().then(() => this.page += 1);
                }else{
                    this.page += 1;
                }
            }
        },
        apply(filters) {
            this.page = 0;
            this.all = [];
            this.params = {};
            this.filters = filters;

            if (this.filters.length) {
                this.$router.push({ query: { filters: JSON.stringify(this.filters) } });
            } else {
                this.$router.push({ query: null });
            }

            if (this.source) {
                this.load();
            }
        },
        clear() {
            this.page = 0;
            this.all = [];
            this.params = {};
            this.filters = [];

            if (this.$route.query.filters) {
                this.$router.push({ query: null, before: null, after: null });
            }

            if (this.source) {
                this.load();
            }
        },
        open(property, row) {
            // This method takes an object and opens the correct dataset with a filter applied.
            const types = {
                school: 'schools',
                course: 'courses',
                section: 'sections',
                district: 'districts'
            };

            this.$router.push({
                params: {
                    type: property === 'person' ? 'people' : types[row.organization.type]
                },
                query: {
                    filters: JSON.stringify([{
                        property: 'id',
                        operator: 'equals',
                        value: property === 'person' ? row.person.id : row.organization.id
                    }])
                }
            });
        }
    },
    data() {
        return {
            loading: false,
            all: [],
            visible: [],
            filters: [],
            page: 0,
            count: 100,
            next_page_available: false,
            params: {},
            offset: 0,
            operators: {
                'equals': 'Equals',
                'in': 'Is Any Of',
                'not in': 'Is None Of',
                'starts with': 'Starts With',
                'contains': 'Contains',
                'is known': 'Is Known',
                'is unknown': 'Is Unknown',
                'gt': 'Greater Than',
                'gte': 'Greater Than Or Equal To',
                'lt': 'Less Than',
                'lte': 'Less Than Or Equal To'
            },
            filter_options: {
                uuid: ['equals', 'in', 'not in'],
                string: ['equals', 'starts with', 'contains', 'in', 'not in', 'is known', 'is unknown'],
                date: ['equals', 'gt', 'lt', 'gte', 'lte'],
                enum: ['equals', 'in', 'not in'],
                array: ['equals', 'in', 'not in', 'is known', 'is unknown']
            },
            types: {
                people: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Person ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'first_name',
                        property: 'first_name',
                        name: 'First Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.first_name ? row.overrides.properties.first_name : row.first_name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'last_name',
                        property: 'last_name',
                        name: 'Last Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.last_name ? row.overrides.properties.last_name : row.last_name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'roles',
                        property: 'roles',
                        name: 'Roles',
                        type: 'array',
                        width: '140px',
                        sort: true,
                        filter: true,
                        array: true,
                        value: (row) => row.overrides?.properties?.roles ? row.overrides?.properties?.roles.join(', ') : row.roles.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'email',
                        property: 'email',
                        name: 'Email',
                        type: 'string',
                        width: '260px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.email ? row.overrides.properties.email : row.email,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                sessions: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Session ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Type',
                        type: 'session_type',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'start_date',
                        property: 'start_date',
                        name: 'Start Date',
                        type: 'date',
                        width: '140px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.start_date ? this.$options.filters.pretty(row.overrides.properties.start_date, 'date') : this.$options.filters.pretty(row.start_date, 'date'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'end_date',
                        property: 'end_date',
                        name: 'End Date',
                        type: 'date',
                        width: '140px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.end_date ? this.$options.filters.pretty(row.overrides.properties.end_date, 'date'): this.$options.filters.pretty(row.end_date, 'date'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                districts: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'District ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                schools: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'School ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                classes: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Class ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'enum',
                        width: '140px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'grade_levels',
                        property: 'grade_levels',
                        name: 'Grade Levels',
                        type: 'array',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.grade_levels ? row.overrides?.properties?.grade_levels.join(', ') : row.grade_levels.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'update_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                sections: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Section ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'enum',
                        width: '140px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                courses: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Course ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Code',
                        type: 'string',
                        width: '200px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'grade_levels',
                        property: 'grade_levels',
                        name: 'Grade Levels',
                        type: 'array',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row) => row.overrides?.properties?.grade_levels ? row.overrides?.properties?.grade_levels.join(', ') : row.grade_levels.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                enrollments: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Enrollment ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'person_id',
                        property: 'person_id',
                        name: 'Person ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'class_id',
                        property: 'class_id',
                        name: 'Class ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: false,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'start_date',
                        property: 'start_date',
                        name: 'Start Date',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: false,
                        value: (row, column) => row.overrides?.properties?.start_date ? this.$options.filters.pretty(row.overrides?.properties?.start_date, 'medium') : this.$options.filters.pretty(row.start_date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'end_date',
                        property: 'end_date',
                        name: 'End Date',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: false,
                        value: (row, column) => row.overrides?.properties?.end_date ? this.$options.filters.pretty(row.overrides?.properties?.end_date, 'medium') : this.$options.filters.pretty(row.end_date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                agents: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Agent ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'relationship',
                        property: 'relationship',
                        name: 'Relationship',
                        type: 'enum',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.relationship ? row.overrides.properties.relationship : row.relationship,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'flags',
                        property: 'flags',
                        name: 'Flags',
                        type: 'array',
                        width: '260px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.flags ? row.overrides.properties.flags.join(', ') : row.flags.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                assets: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Asset ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                attendance: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Attendance ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'Attendance State',
                        type: 'uuid',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'flags',
                        property: 'flags',
                        name: 'Attendance Flags',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true,
                        value: (row, column) => row.overrides?.properties?.flags ? row.overrides?.properties?.flags.join(', ') : row.flags.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                calendars: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Calendar ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: 220,
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                days: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Day ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'date',
                        property: 'date',
                        name: 'Day Date',
                        type: 'date',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.date ? this.$options.filters.pretty(row.overrides.properties.date, 'medium') : this.$options.filters.pretty(row.date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                departments: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Department ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                facilities: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Facility ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                fees: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Fee ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'amount',
                        property: 'amount',
                        name: 'Amount',
                        type: 'string',
                        width: '100px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.amount ? row.overrides.properties.amount : row.amount,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                incidents: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Incident ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'title',
                        property: 'title',
                        name: 'Title',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.title ? row.overrides.properties.title : row.title,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'date',
                        property: 'date',
                        name: 'Incident Date',
                        type: 'date',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.date ? this.$options.filters.pretty(row.overrides.properties.date, 'medium') : this.$options.filters.pretty(row.date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                meetings: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Meeting ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'class_id',
                        property: 'class_id',
                        name: 'Class ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'period_id',
                        property: 'period_id',
                        name: 'Period ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                periods: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Period ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Period Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Period Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Period Type',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'start_time',
                        property: 'start_time',
                        name: 'Start Time',
                        type: 'string',
                        width: '140px',
                        sort: true,
                        filter: true,
                        value: (row, column) => row.overrides?.properties?.start_time ? this.$options.filters.pretty(row.overrides.properties.start_time, 'short_time') : this.$options.filters.pretty(row.start_time, 'short_time'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'end_time',
                        property: 'end_time',
                        name: 'End Time',
                        type: 'string',
                        width: '140px',
                        sort: true,
                        filter: true,
                        value: (row, column) => row.overrides?.properties?.end_time ? this.$options.filters.pretty(row.overrides.properties.end_time, 'short_time') : this.$options.filters.pretty(row.end_time, 'short_time'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: 220,
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                rooms: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Room ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'number',
                        property: 'number',
                        name: 'Room Number',
                        type: 'string',
                        width: '120px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.number ? row.overrides.properties.number : row.number,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'capacity',
                        property: 'capacity',
                        name: 'Room Capacity',
                        type: 'string',
                        width: '120px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.capacity ? row.overrides.properties.capacity : row.capacity,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'category',
                        property: 'category',
                        name: 'Category',
                        type: 'string',
                        width: 200,
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.category ? row.overrides.properties.category : row.category,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                routes: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Route ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Route Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'mileage',
                        property: 'mileage',
                        name: 'Mileage',
                        type: 'string',
                        width: '100px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.mileage ? row.overrides.properties.mileage : row.mileage,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                stops: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Stop ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Stop Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Stop Type',
                        type: 'string',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                subjects: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Subject ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Subject Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Subject Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                vehicles: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Vehicle ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Vehicle Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Type',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: true,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                events: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Event ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true,
                        icon: FingerprintSquare
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Type',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        icon: CodeBracketsSquare
                    },
                    {
                        id: 'target',
                        property: 'target',
                        name: 'Target',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        icon: Svg3dSelectSolid
                    },
                    {
                        id: 'date',
                        property: 'date',
                        name: 'Event Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        icon: Calendar,
                        value: (row, column) => this.$options.filters.pretty(row.date, 'long')
                    }
                ],
                materializations: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Materialization ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'created_date',
                        property: 'created_date',
                        name: 'Created Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                flows: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Flow ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'created_date',
                        property: 'created_date',
                        name: 'Created Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                jobs: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Job ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'created_date',
                        property: 'created_date',
                        name: 'Created Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ]
            }
        };
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.dataset
{
    padding-top: @filters-height;
}

::v-deep
{
    .datatable-cell.override
    {
        padding-left: 24px;
        background: url('~@/assets/icons/base/pin.svg') 10px 15px no-repeat;
        background-size: 6px auto;
        color: @base;
    }
}

.filters
{
    .button
    {
        margin-left: 10px;
    }
}

.custom-filters
{
    width: 500px;
    min-height: 100px;
    background: @f8;
}

.filter-form
{
    width: 300px;
    height: 300px;
    padding: 20px;

    select, input
    {
        margin-top: 10px;
    }

    .button
    {
        margin: 10px 10px 0 0;
    }
}
</style>
