<template>
    <div class="entity-details">
        <div class="drawer-section">
            <h4>Task Details</h4>
            <div class="summary">
                <entity-field v-for="column of properties" :key="column.property" v-bind="column" />
            </div>
        </div>
        <!-- Task Data -->
        <div class="drawer-section" v-if="entity.data">
            <h4>Task Data</h4>
            <div class="summary">
                <div class="entity-field summary-field flex" v-for="col of target_properties[entity.entity_type]" :key="col.property">
                    <div class="summary-key">{{ col.title }}</div>
                    <div class="summary-value flex-grow-1">
                        <entity-value-string :property="col.property" :data="entity.data" :array="col.array" :monospace="col.monospace" :copyable="col.copyable" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Task Errors -->
        <div class="drawer-section" v-if="errors" v-for="error in errors" :key="`${error.code}-${error.message}`">
            <h4>Errors</h4>
            <div class="summary">
                <div class="entity-field summary-field flex">
                    <div class="summary-key">Code</div>
                    <div class="summary-value flex-grow-1">
                        <span>{{ error.code }}</span>
                    </div>
                </div>
                <div class="entity-field summary-field flex">
                    <div class="summary-key">Message</div>
                    <div class="summary-value flex-grow-1">
                        <span>{{ error.message }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Task Warnings -->
        <div class="drawer-section" v-if="warnings" v-for="warning in warnings" :key="`${error.code}-${error.message}`">
            <h4>Warnings</h4>
            <div class="summary">
                <div class="entity-field summary-field flex">
                    <div class="summary-key">Code</div>
                    <div class="summary-value flex-grow-1">
                        <span>{{ warning.code }}</span>
                    </div>
                </div>
                <div class="entity-field summary-field flex">
                    <div class="summary-key">Message</div>
                    <div class="summary-value flex-grow-1">
                        <span>{{ warning.message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';
    import EntityValueString from '@/components/drawers/entity/values/EntityValueString.vue';

    export default {
        name: 'TaskDetails',
        components: {
            'entity-field': EntityField,
            'entity-value-string': EntityValueString,
        },
        mounted() {
            this.load_errors();
        },
        data() {
            return {
                errors: [],
                warnings: [],
                properties: [
                    { title: 'Event ID', property: 'id', type: 'string', monospace: true, copyable: true },
                    { title: 'Integration ID', property: 'integration_id', type: 'string', monospace: true, copyable: true },
                    { title: 'Materialization ID', property: 'materialization_id', type: 'string', monospace: true, copyable: true },
                    { title: 'Created Date', property: 'created_date', type: 'date' },
                    { title: 'Event Type', property: 'event_type', type: 'string' },
                    { title: 'Event Target Type', property: 'entity_type', type: 'string' },
                    { title: 'Event Target ID', property: 'data.id', type: 'string', copyable: true }
                ],
                entities: {
                    people: 'Person',
                    classes: 'Class',
                    sections: 'Section',
                    courses: 'Course',
                    schools: 'School',
                    districts: 'District',
                    enrollments: 'Enrollment',
                    agents: 'Agent',
                    sessions: 'Session',
                    resources: 'Resource',
                    assignments: 'Assignment',
                    submissions: 'Submission',
                },
                target_properties: {
                    submission: [
                        { title: 'Submission ID', property: 'id', type: 'string', monospace: true, copyable: true },
                        { title: 'Person ID', property: 'person_id', type: 'string', monospace: true, copyable: true },
                        { title: 'Assignment ID', property: 'assignment_id', type: 'string', monospace: true, copyable: true },
                        { title: 'State', property: 'state', type: 'string' },
                    ],
                    assignment: [
                        { title: 'Assignment ID', property: 'id', type: 'string', monospace: true, copyable: true },
                        { title: 'Title', property: 'title', type: 'string' },
                        { title: 'State', property: 'state', type: 'string' },
                        { title: 'Category ID', property: 'category_id', type: 'string', monospace: true, copyable: true },
                        { title: 'Submission Types', property: 'submission_types', type: 'string' },
                        { title: 'Attachments', property: 'attachments', type: 'array' },
                        { title: 'Assignee Mode', property: 'assignee_mode', type: 'string' },
                        { title: 'Grading Type', property: 'grading_type', type: 'string' },
                        { title: 'Max Attempts', property: 'max_attempts', type: 'number' },
                    ],
                    enrollment: [
                        { title: 'Enrollment ID', property: 'id', type: 'string', monospace: true, copyable: true },
                        { title: 'Person ID', property: 'person_id', type: 'string', monospace: true, copyable: true },
                        { title: 'Class ID', property: 'class_id', type: 'string', monospace: true, copyable: true },
                        { title: 'State', property: 'state', type: 'string' },
                        { title: 'Role', property: 'role', type: 'string' }
                    ],
                    class: [
                        { title: 'Class ID', property: 'id', type: 'string', monospace: true, copyable: true },
                        { title: 'Title', property: 'title', type: 'string' },
                        { title: 'State', property: 'state', type: 'string' },
                        { title: 'School ID', property: 'school_id', type: 'string', monospace: true, copyable: true },
                        { title: 'Term ID', property: 'term_id', type: 'string', monospace: true, copyable: true },
                        { title: 'Start Date', property: 'start_date', type: 'date' },
                        { title: 'End Date', property: 'end_date', type: 'date' },
                        { title: 'Course ID', property: 'course_id', type: 'string', monospace: true, copyable: true },
                    ],
                    person: [
                        { title: 'Person ID', property: 'id', type: 'string', monospace: true, copyable: true },
                        { title: 'First Name', property: 'first_name', type: 'string' },
                        { title: 'Last Name', property: 'last_name', type: 'string' },
                        { title: 'Email', property: 'email', type: 'string' },
                        { title: 'State', property: 'state', type: 'string' },
                        { title: 'Role', property: 'role', type: 'string' },
                        { title: 'District ID', property: 'district_id', type: 'string', monospace: true, copyable: true },
                        { title: 'School ID', property: 'school_id', type: 'string', monospace: true, copyable: true },
                    ]
                },
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            },
            status(task){
                return {
                    red: ['dropped'].includes(enrollment.state),
                    yellow: ['upcoming'].includes(enrollment.state),
                    green: ['active'].includes(enrollment.state),
                    grey: ['pending', 'inactive'].includes(enrollment.state),
                    purple: ['completed'].includes(enrollment.state)
                };
            },
            load_errors() {
                if (!this.entity) {
                    return;
                }

                const base = process.env.NODE_ENV === 'development' ? 'http://localhost:9900' : 'https://ed.link';

                if (this.entity.state === 'error') {
                    this.$http.get(`${base}/api/v2/graph/jobs/${this.entity.job_id}/tasks/${this.entity.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.integration.access_token}`
                        }
                    }).
                        then(response => {
                            this.errors = response.$data.errors ? response.$data.errors : [];
                            this.warnings = response.$data.warnings ? response.$data.warnings : [];
                        }).catch(() => {
                            /** Skip */
                        });
                }
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            },
            title(){
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
    }

    .drawer-section
    {
        padding: 20px 0;

        &:first-child
        {
            padding-top: 0;
        }
    }
</style>
