<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <ShopAlt class="icon" width="16" height="16" stroke-width="2" />
                    Products
                </div>
            </template>
            <template v-slot:actions>
                <div class="button" @click="create">Create Product</div>
            </template>
        </breadcrumbs>
        <filters>
            <filter-custom :applied="filters" @apply="apply" :filterable="filterable" />
            <div class="ff"></div>
            <div class="page">
                <template v-if="all.length">
                    Results <span>{{page * count + 1}}</span> &ndash; <span>{{page * count + rows.length}}</span> of
                    <template v-if="filters.length">
                        <span v-if="next_page_available">Many</span>
                        <span v-else>{{all.length | commas}}</span>
                    </template>
                    <template v-else>
                        <span>{{total | commas}}</span>
                    </template>
                </template>
                <template v-else>
                    No Results
                </template>
            </div>
            <a class="arrow previous flex flex-align flex-center" @click="previous" :class="{ active: page }">
                <span class="icon iconoir-nav-arrow-left"></span>
            </a>
            <a class="arrow next flex flex-align flex-center" @click="next" :class="{active: (page + 1) * count < all.length || next_page_available}">
                <span class="icon iconoir-nav-arrow-right"></span>
            </a>
        </filters>
        <datatable class="products full" :columns="columns" :rows="rows" :selectable="true" :header="true" @clicked="edit" :loading="loading" :clickable="true">
            <template v-slot:empty-message>You have not yet created any products.</template>
        </datatable>
    </main>
</template>

<script>
    import ProductDrawer from '@/components/drawers/Product';
    import { AlignLeft, ShopAlt, BookmarkBook, Calendar, GitCommand, SeaAndSun, Clock, ScanBarcode, WhiteFlag } from '@epiphany/iconoir';
    import Tags from '@/components/chips/Tags.vue';
    import ProductState from '@/components/chips/ProductState.vue';

    export default {
        name: 'Products',
        components: {
            ShopAlt,
            SeaAndSun
        },
        data(){
            return {
                all: [],
                suggestions: [],
                search: '',
                loading: true,
                filters: [],
                page: 0,
                count: 100,
                next_page_available: false,
                params: {},
                columns: [
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '10%',
                        component: ProductState,
                        value: row => ({ state: row.state })
                    },
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '30%',
                        value: row => row.name
                    },
                    {
                        id: 'code',
                        name: 'Code',
                        icon: ScanBarcode,
                        width: '15%',
                        value: row => row.code
                    },
                    {
                        id: 'tags',
                        name: 'Tags',
                        icon: GitCommand,
                        width: '25%',
                        component: Tags,
                        value: row => ({ tags: row.tags })
                    },
                    {
                        id: 'description',
                        name: 'Description',
                        icon: BookmarkBook,
                        value: row => row.description,
                        width: '60%'
                    },
                    {
                        id: 'duration',
                        name: 'Duration',
                        icon: Clock,
                        width: '10%',
                        value: row => row.license_duration ? row.license_duration + ' days' : null
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '20%'
                    }
                ],
                filterable: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Product ID',
                        type: 'uuid',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Code',
                        type: 'string',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'tags',
                        property: 'tags',
                        name: 'Tags',
                        type: 'array',
                        sort: true,
                        filter: true,
                        array: true,
                    },
                ],
                type: null,
                types: [
                    {
                        id: 'development',
                        name: 'Development',
                        icon: 'iconoir-code-brackets-square'
                    },
                    {
                        id: 'production',
                        name: 'Production',
                        icon: 'iconoir-city'
                    }
                ],
            };
        },
        methods: {
            open(integration){
                this.$router.push({name: 'team.integrations.integration', params: {integration: integration.id}});
            },
            create(){
                this.$store.dispatch('drawer/open', {
                    key: 'create-product',
                    width: 450,
                    component: ProductDrawer,
                    props: { is_create: true, refreshParent: this.reset }
                });
            },
            edit(product){
                this.$store.dispatch('drawer/open', {
                    key: 'edit-product',
                    width: 450,
                    component: ProductDrawer,
                    props: { product, is_create: false, refreshParent: this.reset }
                });
            },
            reset(){
                this.apply([]);
            },
            refresh() {
                this.loading = true;

                // Apply our filter to the params array.
                this.params.$filter = {};

                for(const { value, property, operator } of this.filters) {
                    if (value) {
                        this.params.$filter[property] = [{ operator, value }];
                    }
                }

                return this.$http.get(`/teams/${this.$store.getters.team.id}/products`, { baseURL: '/api/v2', params: this.params })
                .then(response => {
                    this.all = this.all.concat(response.$data);

                    if (response.$next) {
                        this.next_page_available = true;
                    } else {
                        this.next_page_available = false;
                    }

                    this.loading = false;
                })
                .catch(error => {
                    this.$toasted.error(`There was an error loading your products.`)
                });
            },
            switch_type(type){
                this.type = type;
            },
            apply(filters) {
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filters = filters;

                this.refresh();
            },
            previous() {
                //We definitely have these results already.
                if (this.page) {
                    this.page -= 1;
                }
            },
            next() {
                if((this.page + 1) * this.count < this.all.length || this.next_page_available){
                    //If we already have these results, we can just swap them in.
                    //Otherwise, we need to fetch from the server.
                    if(this.all.length <= this.count * (this.page + 1)){
                        this.params.$after = this.all[this.all.length - 1].id;
                        this.refresh().then(() => this.page += 1);
                    }else{
                        this.page += 1;
                    }
                }
            },
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            products(){
                if(this.search){
                    return this.all.filter(product => product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
                }

                return this.all;
            },
            rows() {
                return this.all.slice(this.page * this.count, this.page * this.count + this.count);
            },
            total() {
                return this.all.length;
            }
        },
        created() {
            this.refresh();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .products
    {
        top: @breadcrumbs-height + @filters-height;
    }

    .filters
    {
        top: @breadcrumbs-height;
    }
</style>
