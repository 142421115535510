<template>
    <div class="entity-value entity-value-grade-level flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="autocomplete" :timeout="1" :source="search" :format="format" @escape="cancel" @enter="save" :max="max" />
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value">
                <template v-if="array">
                    <template v-if="value.length">
                        <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{sections[item] ?? 'Unknown Section'}}</div>
                    </template>
                    <div v-else class="empty">&mdash;</div>
                </template>
                <div v-else class="badge text-overflow">{{sections[value] ?? 'Unknown Section'}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Vue from 'vue';

    export default {
        name: 'EntityValueSection',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        created(){
            // Upon creation, we want to load the sections names from the server.
            // We can't exactly trust that all the sections will be $expanded.
            // There's a moment in time between when we create an override and when the data has remateriailized.
            if(this.entity){
                const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';
                const params = {
                    $fields: 'id,name'
                };
                // We can decide which property to look at depending on if this is an array or single value.
                const value = this.array ? this.entity.sections_ids.join(',') : (this.entity.section_id || '');
                if (value) {
                    params.$filter = {
                        id: [{ operator: 'in', value }]
                    };
                }

                this.$http.get(`${url}/api/v2/graph/sections`, {
                    params,
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                })
                .then(({ $data: sections }) => {
                    for(const section of sections){
                        Vue.set(this.sections, section.id, section.name);
                    }
                })
                .catch(() => this.$toasted.error('There was an error loading sections.'));
            }
        },
        data(){
            return {
                sections: {}
            };
        },
        computed: {
            // We basically need to translate stuff in and out of an array form for the autocomplete component to work.
            // When props.array === true, this is not a problem, but when we're dealing with a single value, we need to convert it to an array.
            autocomplete: {
                get(){
                    if(this.array){
                        return this.override;
                    }else if(this.override){
                        return [this.override];
                    }else{
                        return [];
                    }
                },
                set(value){
                    if(this.array){
                        this.$store.commit('entities/override', {property: this.property, value});
                    }else{
                        if(value.length){
                            this.$store.commit('entities/override', {property: this.property, value: value[0]});
                        }else{
                            this.$store.commit('entities/override', {property: this.property, value: null});
                        }
                    }
                }
            },
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return this.override ? this.override : this.original;
            },
            override: {
                get(){
                    return _.get(this.$store.state.entities.overrides, this.property);
                },
                set(value){
                    this.$store.commit('entities/override', {property: this.property, value});
                }
            },
            max(){
                return this.array ? Infinity : 1;
            },
            source(){
                return this.$store.state.entities.source;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        },
        methods: {
            search(input, values){
                const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

                return this.$http.get(`${url}/api/v2/graph/sections`, {
                    params: {
                        $filter: {
                            id: [{ operator: 'not in', value: values.join(',') }],
                            name: [{ operator: 'contains', value: input }]
                        },
                        $fields: 'id,name'
                    },
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    }
                })
                .then(({ $data: sections }) => {
                    for(const section of sections){
                        Vue.set(this.sections, section.id, section.name);
                    }

                    return sections.map(s => s.id);
                })
                .catch(() => {
                    this.$toasted.error('There was an error loading sections.');

                    // If we have an error, just return an empty array to be safe.
                    return [];
                });
            },
            format(value){
                return this.sections[value];
            },
            edit(){
                if(this.editable){
                    if(this.original && !this.override){
                        // Set the default override value to the current property value.
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value: this.original
                        });
                    }else if(this.array && !this.override){
                        // We need to initialize an empty array in order for our autocomplete to work.
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value: []
                        });
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-grade-level
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
