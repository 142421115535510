<template>
    <!--
        - Numbers of people / courses / classes / schools
        - Number of applications integrated with this source
        - Any configuration issues and how to resolve them
        - Enrichment status
        - Sync status
    -->
    <div class="source-overview full scroll">
        <section class="flex">
            <div class="section-content ff">
                <h2>Source Details</h2>
                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Source ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => source.id" v-clipboard:success="() => $toasted.info('Copied')">{{source.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Source Name</div>
                        <div class="summary-value">{{source.name}}</div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="source.application.id">
                        <div class="summary-key">Application</div>
                        <div class="summary-value source-application">{{source.application.name}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Created Date</div>
                        <div class="summary-value">{{source.created_date | pretty('long')}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Updated Date</div>
                        <div class="summary-value">{{source.updated_date | pretty('long')}}</div>
                    </div>
                    <!-- <div class="summary-field flex flex-align">
                        <div class="summary-key">Data Enrichment</div>
                        <div class="summary-value">
                            <router-link class="enrichment-status block" v-if="source.enrichment_provider" :to="{params: {source: source.enrichment.id}}">
                                <template v-if="source.provider.categories.includes('lms')">
                                    Enriched by {{source.enrichment.name}}
                                </template>
                                <template v-if="source.provider.categories.includes('sis')">
                                    SSO with {{source.enrichment.name}}
                                </template>
                            </router-link>
                            <template v-else>Not Enabled</template>
                        </div>
                    </div> -->
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Region</div>
                        <div class="summary-value flex flex-align">
                            <template v-if="source.region.id === '8c5475f1-32d3-479d-8a6a-3c6b6e524f49'">
                                <img class="block flag-icon" src="/flags/us.svg" />
                                <div>United States</div>
                            </template>
                            <template v-else-if="source.region.id === '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd'">
                                <img class="block flag-icon" src="/flags/ca.svg" />
                                <div>Canada</div>
                            </template>
                            <template v-else-if="source.region.id === '2572d321-6d8b-458b-8024-8e5ab6bad7b6'">
                                <img class="block flag-icon" src="/flags/de.svg" />
                                <div>Germany</div>
                            </template>
                            <template v-else-if="source.region.id === '2104f649-fe39-4fa1-96be-8682e9840dcf'">
                                <img class="block flag-icon" src="/flags/au.svg" />
                                <div>Australia</div>
                            </template>
                            <template v-else>
                                <div>Unknown</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="flex">
            <div class="section-content ff">
                <h2>Sync Details</h2>
                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Provider</div>
                        <div class="summary-value">{{source.provider.name}}</div>
                    </div>
                    <template v-if="source.provider.allows_data_sync">
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Sync Status</div>
                            <div class="summary-value">
                                <div class="flex flex-align">
                                    <div class="standalone icon-status" :class="status()"></div>
                                    <div class="capitalize">{{source.status}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Last Sync</div>
                            <div class="summary-value">{{source.last_sync_date | pretty('long', 'Never')}}</div>
                        </div>
                    </template>
                    <!-- <template v-if="source.team">
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Team Name</div>
                            <div class="summary-value">{{source.team.name}}</div>
                        </div>
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Team Entity ID</div>
                            <div class="summary-value monospace" v-if="source.entity">{{source.entity.id}}</div>
                            <div class="summary-value monospace" v-else>Entity ID Not Set</div>
                        </div>
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Technical Contacts</div>
                            <div class="flex flex-wrap" v-if="source.team.technical_contacts.length">
                                <div class="summary-value copyable" v-for="(contact) of source.team.technical_contacts" :key="contact" v-clipboard="() => contact" v-clipboard:success="() => $toasted.info('Copied')">
                                    {{contact}}
                                </div>
                            </div>
                            <div class="summary-value monospace" v-else>None Set</div>
                        </div>
                    </template> -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import {Line} from 'monster-chart';

    export default {
        name: 'SourceOverview',
        data(){
            return {
                loading: true
            }
        },
        computed: {
            source(){
                return this.$store.getters.source;
            },
            team(){
                return this.$store.getters.team;
            }
        },
        methods: {
            status(){
                return {
                    red: ['error', 'destroyed'].includes(this.source.status),
                    yellow: ['disabled'].includes(this.source.status),
                    green: ['active'].includes(this.source.status),
                    grey: ['requested'].includes(this.source.status),
                    purple: ['inactive', 'scheduled', 'pending'].includes(this.source.status)
                };
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h3
    {
        margin: 30px 0 0;
        padding-top: 30px;
        border-top: 1px solid @e4;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    h4
    {
        margin-top: 4px;
    }

    .chart-container
    {
        padding: 50px 0;
        border-top: 1px solid @e4;
    }

    .tile-container
    {
        margin: 0 0 30px;
    }

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }
    
    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        .section-options
        {
            padding-top: 20px;
        }

        &:first-child
        {
            border-top: 0;
        }
    }

    .summary
    {
        font-size: 13px;

        .summary-field
        {
            height: 22px;
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }

        .summary-key
        {
            color: @grey;
            width: 180px;
            flex-basis: 180px;
            flex-shrink: 0;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            &.monospace
            {
                line-height: 22px;
                font-size: 13px;
            }

            .flag-icon
            {
                width: 12px;
                height: 12px;
                margin-right: 8px;
            }
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    .disconnect-warning
    {
        font-size: 13px;
        line-height: 18px;
        color: @grey;
        max-width: 700px;
    }

    .launch-url
    {
        font-weight: 400;
    }

    .icon-status
    {
        margin-right: 8px;
    }

    .source-application.summary-value
    {
        padding-left: 20px;
        background-image: url('~@/assets/icons/base/link.svg');
        background-size: auto 24px;
        background-position: -14px 1px;
        background-repeat: no-repeat;
        color: @base;
    }
</style>