<template>
    <div class="entity flex flex-column">
        <header class="drawer-header flex">
            <div class="flex">
                <div class="entity-icon flex flex-align flex-center">
                    <span class="icon block iconoir-paste-clipboard"></span>
                </div>
                <h2 class="ff drawer-title text-overflow">{{ title }}</h2>
            </div>
            <div class="text-button border" @click="close">Close</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{ active: tab === 'details' }" @click="tab = 'details'">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Details
            </div>
            <div class="pill" :class="{ active: tab === 'logs' }" @click="tab = 'logs'" v-if="admin">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Logs
            </div>
        </nav>
        <div v-if="details && tab === 'details'" class="ff drawer-scroll job-details">
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Sync ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => details.id" v-clipboard:success="() => $toasted.info('Copied')">{{ details.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => details.source.id" v-clipboard:success="() => $toasted.info('Copied')">{{ details.source.id }}</div>
                </div>
                <div class="summary-field flex flex-align" v-if="details.integration">
                    <div class="summary-key">Integration ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => details.integration.id" v-clipboard:success="() => $toasted.info('Copied')">{{ details.integration.id }}</div>
                </div>
                <div class="summary-field flex flex-align" v-if="details.application">
                    <div class="summary-key">Application Name</div>
                    <div class="summary-value monospace">{{ details.application.name }}</div>
                </div>
                <div class="summary-field flex flex-align" v-if="details.application">
                    <div class="summary-key">Application ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => details.application.id" v-clipboard:success="() => $toasted.info('Copied')">{{ details.application.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Team Name</div>
                    <div class="summary-value">{{ details.team.name }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Team ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => details.team.id" v-clipboard:success="() => $toasted.info('Copied')">{{ details.team.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Elapsed Time</div>
                    <div class="summary-value">{{ elapsed(details) }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Created Date</div>
                    <div class="summary-value">{{ details.created_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Updated Date</div>
                    <div class="summary-value">{{ details.updated_date | pretty('long') }}</div>
                </div>

                <template v-if="details.internal">
                    <h2>Debug Info</h2>
                    <pre class="card ff monospace scroll" v-if="details.internal && details.internal.error" v-html="details.internal.error"></pre>
                    <pre class="card ff monospace scroll" v-html="format(details.internal)"></pre>
                </template>

                <h2>Actions</h2>
                <div class="summary-field flex flex-align action" v-if="details.integration">
                    <div class="summary-key">View Integration</div>
                    <div class="summary-value flex flex-align">
                        <div @click="() => external_link({ name: 'team.integrations.integration', params: { team: details.team.alias, integration: details.integration.id } })" class="text-button">Go To Integration</div>
                    </div>
                </div>
                <div class="summary-field flex flex-align action">
                    <div class="summary-key">View Source</div>
                    <div class="summary-value flex flex-align">
                        <div @click="() => external_link({ name: 'team.sources.source', params: { team: details.team.alias, source: details.source.id } })" class="text-button">Go To Source</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tab === 'logs'" class="drawer-content drawer-scroll" :class="{ 'entity-logs': tab === 'logs' }">
            <spinner v-if="loading_logs" />
            <editor v-else :value="result" :readonly="true" :block="null" />
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Prism from 'prismjs';
    import { InfoEmpty, AlignLeft } from '@epiphany/iconoir';
    import { LEVEL_MAP } from '@/components/drawers/entity/EntityLogs.vue';
    import _ from 'lodash';

    export default {
        name: 'Sync',
        components: {
            InfoEmpty,
            AlignLeft
        },
        data() {
            return {
                tab: 'details',
                logs: [],
                result: '',
                loading_logs: true,
            };
        },
        created() {
            const url = `/admin/${this.parent_type}/${this.parent_id}/${this.type}/${this.details.id}/logs`;

            this.$http.get(url).
                then(response => {
                    this.logs = response.$data;

                    if (this.logs.length > 0) {
                        this.result = response.$data.map(log => {
                            const date = new Date(log.log_date);
                            return (
                                `[${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}] ${LEVEL_MAP[log.log_level].padEnd(8, ' ')} ${log.log_message}`
                            )
                        }).join('\n');
                    } else {
                        this.result = 'No logs found.';
                    }
                }).catch(_err => {
                    this.result = 'Unable to retrieve logs.';
                    this.$toasted.error(`Unable to retrieve logs.`);
                }).finally(() => {
                    this.loading_logs = false;
                });
        },
        methods: {
            close() {
                this.$store.dispatch('drawer/close');
            },
            elapsed(job) {
                return moment.duration(moment().diff(job.created_date)).format('hh:mm:ss', { stopTrim: 'm' });
            },
            format(data) {
                try {
                    //Color and format the JSON that represents this user.
                    return Prism.highlight(JSON.stringify(data, null, 4), Prism.languages.json, 'json');
                } catch (error) {
                    console.log(error);
                }
            },
            external_link(url_params) {
                this.close();
                this.$router.push(url_params)
            }
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            details() {
                return this.$store.state.drawer.props?.details;
            },
            type() {
                const type = this.$store.state.drawer.props?.type;

                if (type === 'source_sync') {
                    return 'syncs';

                } else {
                    // append s to the end of the type with lodash
                    return `${type}s`;
                }
            },
            /**
             * Because we're using this component to show materializations and enrichments from the admin failures view,
             * we need to format the type of job we're showing.
             */
            title() {
                if (this.type === 'source_sync') {
                    return 'Sync';
                }

                return _.capitalize(this.type.replace('_', ' '));
            },
            integration() {
                return this.$store.getters.integration;
            },
            source() {
                return this.$store.getters.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            parent_type() {
                if (this.type === 'syncs' || this.type === 'source_syncs' || this.type === 'enrichments') {
                    return 'sources';
                } else if (this.type === 'materializations') {
                    return 'integrations';
                } else {
                    return null;
                }
            },
            parent_id() {
                if (this.parent_type === 'sources') {
                    return this.details?.source.id;
                } else if (this.parent_type === 'integrations') {
                    return this.details?.integration.id;
                } else {
                    return null;
                }
            },
            admin() {
                return this.$store.getters.user?.admin;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity-logs
    {
        color: @grey;
        font-size: 12px;
        height: 100%;
        overflow: auto;
        margin-top: 1rem;
    }

    pre
    {
        margin-bottom: 15px;

        &:last-of-type
        {
            margin-bottom: 0;
        }
    }

    .scroll
    {
        overflow: auto;
        padding: 2px;
    }

    .job-details
    {
        padding: 0 20px 20px;
        overflow: auto;

        h2
        {
            color: @black;
            font-size: 16px;
            padding-top: 20px;
            border-top: 1px solid @e4;
            margin-top: 20px;
            margin-bottom: 20px;

            &:first-child
            {
                margin-top: 0;
                border-top: 0;
                padding-top: 0;
            }
        }

        .summary
        {
            font-size: 13px;

            .summary-field
            {
                height: 22px;
                margin-bottom: 10px;

                &:last-child
                {
                    margin-bottom: 0;
                }
            }

            .summary-key
            {
                color: @grey;
                width: 160px;
                flex-basis: 160px;
                flex-shrink: 0;
            }

            .summary-value
            {
                color: @black;
                line-height: 22px;

                &.monospace
                {
                    line-height: 22px;
                    font-size: 13px;
                }

                .icon-status
                {
                    margin-right: 6px;
                }

                .text-button
                {
                    & .active
                    {
                        color: @base;
                        border-color: @base;
                    }

                }
            }
        }
    }

    .drawer-container
    {
        .drawer-header
        {
            // padding-bottom: 0px;
            justify-content: space-between;

            .button
            {
                margin-right: 15px;
            }
        }
    }

    nav
    {
        border-bottom: 1px solid @e4;
        margin-top: 10px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px;
    }

    .entity
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;

            &.drawer-logs
            {
                padding: 0;
            }
        }
    }

    .summary
    {
        margin-top: @drawer-navigation-height + @drawer-header-height;
    }

    .entity-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);
        margin-right: 15px;

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }
</style>
