<template>
    <div class="entity-value flex">
        <div v-if="copyable" class="copyable noshift" v-clipboard="() => value" v-clipboard:success="() => $toasted.info('Copied')">{{value}}</div>
        <template v-else>
            <div v-if="editing" class="ff" key="editing">
                <template v-if="array">
                    <custom-select class="block" :values.sync="override" :paste="true" :array="true" ref="input" />
                </template>
                <template v-else>
                    <input type="text" class="block editable-input" v-model="override" @keyup.enter="save" @keyup.esc="cancel" ref="input" />
                </template>
            </div>
            <div v-else :class="{editable}" @click="edit" key="editable">
                <template v-if="hasValue">
                    <template v-if="array">
                        <template v-if="value.length">
                            <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{item}}</div>
                        </template>
                    </template>
                    <div class="text-overflow" :class="{capitalize: capitalized}" v-else>{{value}}</div>
                </template>
                <template v-else>
                    <div v-if="placeholder" class="placeholder">{{placeholder}}</div>
                    <div v-else class="empty">&mdash;</div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CustomSelect from '@/components/CustomSelect.vue';

    export default {
        name: 'EntityValueString',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean,
            data: Object
        },
        components: {
            'custom-select': CustomSelect,
        },
        computed: {
            entity(){
                if (this.$props.data) {
                    return this.$props.data;
                } else {
                    return this.$store.getters.entity;
                }
            },
            hasValue() {
                if(this.array) {
                    return this.value?.length ? true : false;
                }
                return !!(this.value);
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                return this.override ? this.override : this.original;
            },
            override: {
                get(){
                    return _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                },
                set(value){
                    this.$store.commit('entities/override', {
                        property: this.property,
                        value
                    });
                }
            },
            capitalized() {
                return [
                    'event_type',
                    'entity_type',
                    'state',
                    'role',
                ].includes(this.$props.property);
            }
        },
        methods: {
            edit(){
                if(this.editable){
                    if(!this.override){
                        if(this.original){
                            this.$store.commit('entities/override', {property: this.property, value: this.original});
                        }else if(this.array){
                            this.$store.commit('entities/override', {property: this.property, value: []});
                        }
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    input.editable-input
    {
        border-radius: 0;
        width: 100%;
        margin: 0;
        height: 22px;
        line-height: 22px;
        padding: 0;
        border: 0;
        min-width: 150px;
        font-size: 14px;
        color: @base;

        &:focus
        {
            box-shadow: none;
        }
    }
</style>
