<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <Bolt class="icon" width="16" height="16" stroke-width="2" />
                    Integrations
                </div>
            </template>
            <template v-slot:actions>
                <input class="filter search medium" type="text" placeholder="Search Integrations" v-model="search" />
                <!-- <router-link class="button" :to="{name: 'team.applications.create'}">Create Application</router-link> -->
                <!-- <div class="button" title="Request Integration" v-if="admin && team.type === 'developer'" @click="save(exported, `${team.name} integrations`)">
                    Request Integration
                </div> -->
                <div class="button white has-icon" title="Export Integrations" v-if="team.type === 'developer'" @click="save(exported, `${team.name} integrations`)">
                    <span class="icon iconoir-database-export"></span>
                    Download
                </div>
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="types" :active="type" @activate="switch_type" label="Type" :required="true" v-if="admin">
                <template v-slot:icon>
                    <ShopAlt class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <span class="block icon" :class="item.icon"></span>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="applications" :active="application" @activate="switch_application" label="Application" v-if="team.type === 'developer'">
                <template v-slot:icon>
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon application-icon">
                            <img class="block" :src="item.picture" />
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="statuses" :active="status" @activate="switch_status" label="Status">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="regions" :active="region" @activate="switch_region" label="Region">
                <template v-slot:icon>
                    <PinAlt class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <img class="block" :src="item.picture" />
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="providers" :active="provider" @activate="switch_provider" label="Providers" :searchable="true" @search="provider_source">
                <template v-slot:icon>
                    <MacOsWindow class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <div class="thumbnail">
                                <img class="block" :src="item.icon_url" />
                            </div>                            
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <!-- <filter-dropdown custom borderless :active="active_date" @activate="change_date" label="Creation Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:custom="{ activate }">
                    <date-picker @input="activate" v-model="date" v-bind="{
                        type: 'date',
                        format: 'MM-DD-YYYY',
                        range: true,
                        inline: true,
                        'range-separator': '-',
                        shortcuts
                    }">
                    </date-picker>
                </template>
            </filter-dropdown> -->
        </filters>
        <datatable class="integrations full" :columns="columns" :rows="integrations" :selectable="true" :header="true" @clicked="row => open(row)" :loading="loading" :clickable="true">
            <template v-slot:empty-message>You do not have any integrated districts or universities.</template>
        </datatable>
    </main>
</template>

<script>
    import { Bolt, Bank, Svg3dSelectSolid, MacOsWindow, WhiteFlag, PinAlt, Calendar, Group, ShopAlt, Dollar, CloudSync } from '@epiphany/iconoir';
    import Provider from '@/components/chips/Provider.vue';
    import Region from '@/components/chips/Region.vue';
    import State from '@/components/chips/State.vue';
    import IntegrationBillingCategory from '@/components/chips/IntegrationBillingCategory.vue';

    export default {
        name: 'Integrations',
        components: {
            Bolt,
            Svg3dSelectSolid,
            ShopAlt,
            WhiteFlag,
            Calendar,
            PinAlt,
            MacOsWindow,
            CloudSync
        },
        data(){
            return {
                all: [],
                suggestions: [],
                search: '',
                loading: true,
                application: null,
                applications: [],
                date: null,
                time: null,
                region: null,
                shortcuts: [
                    { text: 'Today', onClick: () => {
                            const date = new Date();
                            date.setHours(0);
                            date.setMinutes(0);
                            return [date, new Date()]
                        }
                    },
                    {
                        text: 'Last 24 hrs',
                        onClick: () => {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Week',
                        onClick: () => {
                            const date = new Date();
                            date.setDate(date.getDate() - 7);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Month',
                        onClick: () => {
                            const date = new Date();
                            date.setMonth(date.getMonth() - 1);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Year',
                        onClick: () => {
                            const date = new Date();
                            date.setFullYear(date.getFullYear() - 1);
                            return [date, new Date()];
                        },
                    }
                ],
                regions: [
                    {
                        id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                        name: 'United States',
                        picture: '/flags/us.svg'
                    },
                    {
                        id: '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd',
                        name: 'Canada',
                        picture: '/flags/ca.svg'
                    },
                    {
                        id: '2572d321-6d8b-458b-8024-8e5ab6bad7b6',
                        name: 'Germany',
                        picture: '/flags/de.svg'
                    },
                    {
                        id: '2104f649-fe39-4fa1-96be-8682e9840dcf',
                        name: 'Australia',
                        picture: '/flags/au.svg'
                    }
                ],
                provider: null,
                provider_query: '',
                type: null,
                types: [
                    {
                        id: 'all',
                        name: 'All',
                        icon: 'iconoir-keyframes-couple'
                    },
                    {
                        id: 'development',
                        name: 'Development',
                        icon: 'iconoir-code-brackets-square'
                    },
                    {
                        id: 'production',
                        name: 'Production',
                        icon: 'iconoir-city'
                    }
                ],
                status: null,
                statuses: [
                    {
                        id: 'requested',
                        name: 'Requested',
                        icon: 'purple'
                    },
                    {
                        id: 'active',
                        name: 'Active',
                        icon: 'green'
                    },
                    {
                        id: 'paused',
                        name: 'Paused',
                        icon: 'yellow'
                    },
                    {
                        id: 'disabled',
                        name: 'Disabled',
                        icon: 'red'
                    },
                    {
                        spacer: true
                    },
                    {
                        id: 'upcoming',
                        name: 'Upcoming',
                        icon: 'purple'
                    },
                    {
                        id: 'destroyed',
                        name: 'Destroyed',
                        icon: 'grey'
                    }
                ],
                columns: [
                    {
                        id: 'district',
                        name: 'District',
                        icon: Bank,
                        width: '40%',
                        value: row => row.team ? row.team.name : row.source.name
                    },
                    {
                        id: 'application',
                        name: 'Application',
                        icon: Svg3dSelectSolid,
                        width: '20%',
                        value: row => row.application.name
                    },
                    {
                        id: 'source',
                        name: 'Source',
                        icon: CloudSync,
                        width: '20%',
                        value: row => row.source.name
                    },
                    {
                        id: 'status',
                        name: 'State',
                        icon: WhiteFlag,
                        component: State,
                        value: row => ({ state: row.status }),
                        width: '120px'
                    },
                    {
                        id: 'provider',
                        name: 'Provider',
                        icon: MacOsWindow,
                        width: '140px',
                        component: Provider,
                        value: row => row.provider
                    },
                    {
                        id: 'region',
                        name: 'Region',
                        icon: PinAlt,
                        component: Region,
                        value: row => row.region,
                        width: '140px'
                    },
                    {
                        id: 'people',
                        name: 'Shared People',
                        icon: Group,
                        value: row => this.$options.filters.commas(row.total?.people ?? 0),
                        width: '130px',
                        align: 'right'
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '130px',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    }
                ]
            };
        },
        methods: {
            open(integration){
                this.$router.push({name: 'team.integrations.integration', params: {integration: integration.id}});
            },
            save(data, filename){
                const blob = new Blob([data], {type: 'text/csv'})
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                document.body.appendChild(a);
                a.href = url;
                a.download = filename + '.csv';
                a.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 0);
            },
            switch_region(region){
                this.region = region;
            },
            switch_type(type){
                this.type = type;
            },
            switch_status(status){
                this.status = status;
            },
            switch_provider(provider){        
                // Clear provider query (the search bar).
                this.provider_query = '';        
                this.provider = provider;              
            },
            switch_application(application){
                this.application = application;
            },
            provider_source(query){
                this.provider_query = query;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            admin(){
                return this.$store.getters.user.admin;
            },
            exported(){
                const rows = this.integrations.map(integration => {
                    const team_name = integration.team.name.replace(/"/g, '""');
                    const app_name = integration.application.name.replace(/"/g, '""');
                    const source_name = integration.source.name.replace(/"/g, '""');

                    return [
                        integration.id,
                        `"${team_name}"`,
                        `"${app_name}"`,
                        `"${source_name}"`,
                        integration.provider.name,
                        integration.status,
                        integration.total ? integration.total.people : 0,
                        integration.created_date
                    ]
                });

                // Add CSV header row.
                rows.unshift(['Integration ID', 'District', 'Application', 'Source', 'Provider', 'State', 'People', 'Created Date']);

                return rows.map(e => e.join(',')).join('\n');
            },
            integrations(){
                return this.all.filter(integration => {
                    if(this.search && integration.search_name.indexOf(this.search.toLowerCase()) === -1){
                        return false;
                    }

                    if(this.region && integration.region.id !== this.region.id){
                        return false;
                    }

                    if (this.type && this.type.id !== 'all') {
                        const type = integration.billing_category === 'standard' ? 'production' : 'development';

                        if (type !== this.type.id) {
                            return false;
                        }
                    }

                    // if(this.type && integration.type !== this.type.id){
                        // return false;
                    // }

                    if(this.status && integration.status !== this.status.id){
                        return false;
                    }

                    if(this.application && integration.application.id !== this.application.id){
                        return false;
                    }

                    if(this.provider){                                                        
                        if(this.provider.id !== integration.provider.id){
                            return false;
                        }                   
                    }

                    return true;
                });
            },
            providers(){
                return this.$store.getters.providers
                    .filter(provider => {
                        if(this.provider_query){
                            return provider.name.toLowerCase().indexOf(this.provider_query.toLowerCase()) > -1;
                        }

                        return true;
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        },
        async created(){
            this.loading = true;

            if (this.admin) {
                this.columns.splice(3, 0, {
                    id: 'billing_category',
                    name: 'Billing',
                    icon: Dollar,
                    component: IntegrationBillingCategory,
                    value: row => ({ category: row.billing_category }),
                    width: '120px'
                });
            }

            // TODO:? Set the default type to either development or production, depending on the account status.
            this.type = this.types.find(t => t.id === 'all');

            this.$http.get(`/teams/${this.$store.getters.team.id}/integrations`)
            .then(response => {
                this.all = response.$data.sort((a, b) => {
                    const a_name = a.team ? a.team.name : a.source.name;
                    const b_name = b.team ? b.team.name : b.source.name;

                    return a_name.localeCompare(b_name);
                });

                for(const integration of this.all){
                    if(this.team.type === 'developer'){
                        integration.search_name = integration.team ? integration.team.name.toLowerCase() : integration.source.name.toLowerCase();
                    }else{
                        integration.search_name = integration.application.name.toLowerCase();
                    }
                }

                this.loading = false;
            })
            .catch(error => {
                this.$toasted.error(`There was an error loading your integrations.`)
            });

            if(this.team.type === 'developer'){
                const { $data: applications } = await this.$http.get(`/teams/${this.$store.getters.team.id}/applications`);
                    
                this.applications = applications.sort((a, b) => a.name.localeCompare(b.name))
                .map(application => {
                    return {
                        id: application.id,
                        name: application.name,
                        picture: application.icon_url ? application.icon_url : '/source/default.png'
                    };
                });
            }

            // Check to see if there are query parameters set before we load().
            // If there is an integration ID present, we should load those first.
            if(this.$route.query.$filter){
                const params = JSON.parse(this.$route.query.$filter);

                if(params.region_id){
                    this.region = this.regions.find(region => region.id === params.region_id[0].value);
                }

                if(params.status){
                    this.status = this.statuses.find(status => status.id === params.status[0].value);
                }

                if(params.date){
                    const gte = new Date(params.date.find(date => date.operator === 'gte')?.value);
                    const lte = new Date(params.date.find(date => date.operator === 'lte')?.value);

                    // TODO Support for single dates.
                    this.date = [gte, lte];
                }

                if(params.application_id){
                    const application = this.applications.find(a => a.id === params.application_id[0].value);

                    if(application){
                        this.application = application;
                    }
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }

    .integrations.full
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;

        &.red
        {
            background: @red;
        }

        &.purple
        {
            background: @purple;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.grey
        {
            background: @grey;
        }
    }

    .application-icon
    {
        img
        {
            border-radius: 3px;
            overflow: hidden;
        }

        &::after
        {
            border-radius: 3px;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
</style>