<template>
    <div class="password-strength">
        <input :placeholder="placeholder" type="password" :value="value" name="password" autocomplete="off" :class="{error}" :disabled="disabled" @input="$emit('input', $event.target.value)" />
        <div class="bars flex flex-align flex-center">
            <div class="bar one ff" :class="{active: value}"></div>
            <div class="bar two ff" :class="{active: strength >= 1}"></div>
            <div class="bar three ff" :class="{active: strength >= 2}"></div>
            <div class="bar four ff" :class="{active: strength >= 2.5}"></div>
            <div class="bar five ff" :class="{active: strength >= 3}"></div>
            <div class="bar six ff" :class="{active: strength >= 3.5}"></div>
            <div class="bar seven ff" :class="{active: strength >= 4}"></div>
        </div>
        <!-- <div class="helptext">{{message}}</div> -->
    </div>
</template>

<script>
    import strength from 'strength';

    export default {
        name: 'PasswordStrength',
        props: {
            error: Boolean,
            value: String,
            placeholder: String,
            disabled: Boolean
        },
        computed: {
            strength(){
                return this.value ? strength(this.value) : 0;
            },
            message(){
                if(!this.value){
                    return 'Password Strength';
                }

                if(this.strength >= 4){
                    return 'What are these, the nuclear launch codes?';
                }else if(this.strength >= 3.5){
                    return 'I hope you have a password manager for that!';
                }else if(this.strength >= 3){
                    return `Arnold strength. Now we're talking.`;
                }else if(this.strength >= 2.5){
                    return `We have the technology.`;
                }else if(this.strength >= 2){
                    return `Pretty good, keep going.`;
                }else if(this.strength >= 1){
                    return 'Weak. Maybe OK for Netflix, but not for us.';
                }else{
                    return 'Very weak, you can do better.';
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .bars
    {
        padding-top: 8px; 
    }

    .bar
    {
        height: 3px;
        background: fade(@grey, 30%);
        margin-right: 6px;
        max-width: 30px;

        &:last-child
        {
            margin-right: 0;
        }

        &.one.active    { background: #8cb3ee; }
        &.two.active    { background: #689be8; }
        &.three.active  { background: #4584e3; }
        &.four.active   { background: #266ed9; }
        &.five.active   { background: #1c5bba; }
        &.six.active    { background: #174a97; }
        &.seven.active  { background: #113873; }
    }

    .helptext
    {
        margin-top: 10px;
        font-size: 12px;
        color: @grey;
    }
</style>