const state = {
    all: [
        {
            title: 'Sources',
            route: 'team.sources',
            icon: 'iconoir-cloud-sync',
            description: 'View and manage your connected data sources',
            type: ['district']
        },
        {
            title: 'Applications',
            route: 'team.applications',
            icon: 'iconoir-box-iso',
            description: 'View and manage your developer applications',
            type: ['developer']
        },
        {
            title: 'Integrations',
            route: 'team.integrations',
            icon: 'iconoir-combine',
            description: 'View and manage your integrations',
            type: ['district', 'developer']
        },
        {
            title: 'Settings',
            route: 'team.settings.general',
            icon: 'iconoir-settings',
            description: 'Manage the global settings for your team',
            type: ['district', 'developer']
        },
        {
            title: 'Documentation',
            route: 'docs.introduction',
            icon: 'iconoir-page-search',
            description: 'Browse the Edlink API documentation',
            type: ['district', 'developer']
        },
        {
            title: 'Changelog',
            route: 'docs.document',
            icon: 'iconoir-git-merge',
            description: 'View recent changes to our API and Dashboard',
            type: ['district', 'developer']
        },
        {
            title: 'Logs',
            route: 'team.logs',
            icon: 'iconoir-activity',
            description: 'View API request logs produced by your applications',
            type: ['developer']
        }
    ]
};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
