<template>
    <div class="integrate-flow-enable">
    
        <label class="center">Would you like to select a destination to sync this source to?</label>

        <div class="flex flex-center">
            <div class="button" @click="send('DESTINATION_SELECT')">Yes</div>
            <div class="button" @click="next" kbd="Enter ⏎">No</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateFlowPrompt',
        props: {
            state: Object,
            send: Function
        },
        mounted(){
            window.addEventListener('keydown', this.keydown);
        },
        destroyed(){
            window.removeEventListener('keydown', this.keydown);
        },
        methods: {
            next(){
                this.send('INTEGRATION')
            },
            keydown(e){
                if(e.code === 'Enter'){
                    this.next();
                }
            }
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    .button{
        margin-right: 10px;
    }

    .button:last-child{
        margin-right: 0;
    }
</style>