<template>
    <div class="custom-admin-confirm flex flex-column flex-align">
        <h1>Are you the {{ state.context.provider.name }} Admin for your district?</h1>
        <div class="helptext">
            Please make sure that the person who is the {{ state.context.provider.name }} admin for your district is present for setting up this integration.
            You will need admin permissions to create the connection.
        </div>
        <div class="options flex" key="email">
            <div class="button grey" @click="send('NO')">No am not</div>
            <div class="button" @click="send('NEXT')">Yes I am</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomAdminConfirm',
        props: {
            state: Object,
            send: Function
        },
        created(){
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .custom-admin-confirm {
        h1 {
            margin-bottom: 10px;
        text-align: center;
        }
    }

    .helptext {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        text-align: center;
    }

    .options {
        .button {
            margin-right: 10px;
        }
    }
</style>
