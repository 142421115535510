<template>
    <div class="select-entity">
        <h4 class="center">Select An Entity</h4>
        <div class="entity-search">
            <input type="text" class="search" v-model="query" placeholder="Search..." @input="search" />
        </div>
        <div class="entity-list">
            <div class="entity flex flex-align" v-for="entity of entities">
                <div class="ff">
                    <div class="entity-name flex flex-align">
                        <div class="badge">{{ entity.type }}</div>
                        {{entity.name}}
                    </div>
                    <div class="entity-details">
                        {{entity.street_address}}, {{entity.city}}, {{entity.state}}, {{entity.zip}}
                    </div>
                </div>
                <div class="text-button" @click="select(entity)">Select</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelectEntity',
        props: {
            confirm: Function
        },
        data(){
            return {
                query: '',
                entities: [],
                throttle: null
            };
        },
        methods: {
            search(){
                if(this.throttle){
                    clearTimeout(this.throttle);
                }

                this.throttle = setTimeout(() => {
                    const params = {};

                    if(this.query){
                        params.search = this.query;
                    }

                    this.throttle = null;

                    this.$http.get('/entities', {params})
                    .then(response => this.entities = response.$data)
                    .catch(error => this.$toasted.error('Could not load entities.'));
                }, 1000);
            },
            select(entity){
                this.confirm(entity);
                this.$emit('close');
            }
        },
        created(){
            this.search();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .select-entity
    {
        background: @f8;

        h4
        {
            font-weight: 500;
            padding: 10px;
            font-size: 14px;
            color: @grey;
        }
    }

    .entity-list
    {
        border-top: 1px solid @e4;
        height: 500px;
        background: @f;
        overflow-y: auto;
    }

    .entity
    {
        padding: 12px 15px;
        border-bottom: 1px solid @e4;

        .entity-name
        {
            font-size: 14px;
            color: @black;
            font-weight: 500;
            margin-bottom: 5px;

            .badge
            {
                margin-right: 4px;
                height: 18px;
                font-size: 11px;
                border-radius: 4px;
                padding: 0 5px;
                line-height: 18px;
            }
        }

        .entity-details
        {
            font-size: 13px;
            color: @grey;
        }

        &:last-child
        {
            border-bottom: 0;
        }
    }

    .entity-search
    {
        padding: 0 15px 15px;
    }

    input.search
    {
        background-color: @f;
    }
</style>
