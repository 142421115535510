<template>
    <chip :color="states[state].color" :title="states[state].name" />
</template>

<script>
    export default {
        name: 'InvoiceXeroState',
        props: {
            state: String
        },
        data(){
            return {
                states: {
                    'yes': { color: 'green', name: 'Synced to Xero' },
                    'no': { color: 'grey', name: 'Not in Xero' },
                }
            };
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
