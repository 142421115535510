<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <SeaWaves class="icon" width="16" height="16" stroke-width="2" />
                    Flow
                </div>
            </template>
        </breadcrumbs>
        <div class="admin-flow full flex">
            <div class="extra running-jobs ff" v-for="state in Object.keys(all)">
                <h4 class="flex flex-align">
                    <template v-if="state === 'queued'" >
                    <span class="icon iconoir-clock-outline"></span>
                        Queued Jobs
                    </template>
                    <template v-else-if="state === 'running'" >
                        <span class="icon iconoir-refresh-circular"></span>
                        Running Jobs
                    </template>
                    <template v-else-if="state === 'killed'" >
                        <span class="icon iconoir-warning-triangle-outline"></span>
                        Recently Killed Jobs
                    </template>
                </h4>
                <div class="nothing" v-if="all[state].length <= 0">
                    No recent jobs in this state.
                </div>
                <div class="card" v-else>
                    <div class="pipeline-card flex flex-align" v-for="job in all[state]">
                        <div class="ff pipeline-metadata flex flex-align">
                            <div class="ff pointer" @click="show_job_details(job)">
                                <!-- <div class="job-title text-overflow">{{job.id}}</div> -->
                                <div class="job-title text-overflow">{{job.source.name}} - {{job.team.name}} </div>
                                <div class="job-title text-overflow sub-text">({{job.id}})</div>

                                <div class="job-district text-overflow">
                                    <span class="job-tag capitalize" :class="job.state">{{job.state}}</span>
                                    <span class="job-tag development-sync capitalize" v-if="job.internal && job.internal.development" title="Developer Job">{{job.internal.development}}</span>
                                    <span>{{elapsed(job)}}</span>
                                </div>
                            </div>
                            <div class="options flex flex-align">
                                <span class="icon iconoir-cancel" title="Mark this flow as killed" @click.prevent="change_state('canceled', job.id)"></span>
                                <span class="icon iconoir-refresh-double" title="Re-queue this flow" @click.prevent="change_state('queued', job.id)"></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <modal name="job-details" :width="560" :height="660" classes="modal flex flex-column">
            <div v-if="details" class="job-details">
                <h2>Flow Summary</h2>
                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Flow ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.id" v-clipboard:success="() => $toasted.info('Copied')">{{details.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Source ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.source.id" v-clipboard:success="() => $toasted.info('Copied')">{{details.source.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Destination ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.source.id" v-clipboard:success="() => $toasted.info('Copied')">{{details.destination.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.integration.id" v-clipboard:success="() => $toasted.info('Copied')">{{details.integration.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">State</div>
                        <div class="summary-value flex flex-align">
                            <div class="button-group flex flex-align">
                                <a class="text-button" :class="{active: details.state === 'queued'}" @click="change_state('queued')">Queued</a>
                                <a class="text-button disabled" :class="{active: details.state === 'working'}">Working</a>
                                <a class="text-button orange" :class="{active: details.state === 'error'}" @click="change_state('error')">Error</a>
                                <a class="text-button orange" :class="{active: details.state === 'canceled'}" @click="change_state('canceled')">Canceled</a>
                                <a class="text-button green" :class="{active: details.state === 'complete'}" @click="change_state('complete')">Complete</a>
                            </div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Team ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.team.id" v-clipboard:success="() => $toasted.info('Copied')">{{details.team.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Team Name</div>
                        <div class="summary-value">{{details.team.name}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Elapsed Time</div>
                        <div class="summary-value">{{elapsed(details)}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Created Date</div>
                        <div class="summary-value">{{details.created_date | pretty('long')}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Updated Date</div>
                        <div class="summary-value">{{details.updated_date | pretty('long')}}</div>
                    </div>
                    <!-- <div class="summary-field flex flex-align action">
                        <div class="summary-key">Priority</div>
                        <div class="summary-value flex flex-align">
                            <div class="button-group flex flex-align">
                                <a class="text-button" :class="{active: details.priority === null}" @click="change_priority(null)">Default</a>
                                <a class="text-button" :class="{active: details.priority === 1}" @click="change_priority(1)">1</a>
                                <a class="text-button" :class="{active: details.priority === 10}" @click="change_priority(10)">10</a>
                                <a class="text-button" :class="{active: details.priority === 100}" @click="change_priority(100)">100</a>
                            </div>
                        </div>
                    </div> -->

                    <template v-if="details.internal && show_json">
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Debug Info</div>
                            <div class="text-button" @click="show_json = false">Hide JSON</div>
                        </div>
                        <pre class="card ff monospace" v-html="format(details)" ></pre>
                    </template>

                    <div class="summary-field flex flex-align" v-else-if="details.internal">
                        <div class="summary-key">Debug Info</div>
                        <div class="text-button" @click="show_json = true">Show JSON</div>
                    </div>

                    <h2>Actions</h2>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">View Integration</div>
                        <div class="summary-value flex flex-align">
                            <router-link class="text-button" :to="{name: 'team.integrations.integration', params: {team: details.team.alias, integration: details.integration.id}}" >Go To Integration</router-link>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">View Source</div>
                        <div class="summary-value flex flex-align">
                            <router-link class="text-button" :to="{name: 'team.sources.source', params: {team: details.team.alias, source: details.source.id}}" >Go To Source</router-link>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">View Destination</div>
                        <div class="summary-value flex flex-align">
                            <router-link class="text-button" :to="{name: 'team.sources.source', params: {team: details.team.alias, source: details.destination.id}}" >Go To Destination</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

    </main>
</template>

<script>
    import { SeaWaves } from '@epiphany/iconoir';
    import moment from 'moment';
    import mdf from 'moment-duration-format';
    import Prism from 'prismjs';

    mdf(moment);

    export default {
        name: 'AdminFlow',
        components: {
            SeaWaves
        },
        data(){
            return {
                details: null,
                all: {},
                search: '',
                results: [],
                show_json: true,
                requests: {
                    title: 'API Requests',
                    height: 250,
                    series: []
                },
                interval: null
            };
        },
        created(){
            this.load();

            this.interval = setInterval(() => this.load(), 4000);
        },
        destroyed(){
            clearInterval(this.interval);
        },
        methods: {
            load() {
                this.$http.get('/admin/flows')
                .then((response) => {
                    this.all = response.$data
                })
            },
            elapsed(job){
                return moment.duration(moment().diff(job.created_date)).format('hh:mm:ss', {stopTrim: 'm'});
            },
            format(data){
                try {
                    //Color and format the JSON that represents this user.
                    return Prism.highlight(JSON.stringify(data, null, 4), Prism.languages.json, 'json');
                }catch(error){
                    console.log(error);
                }
            },
            show_job_details(job){
                this.details = job;
                this.show_json = false;
                this.$modal.show('job-details');
            },
            hide_job_details(){
                this.details = null;
                this.$modal.hide('job-details');
            },
            change_state(state, job_id){
                this.$http.put(`/admin/flows/${job_id ?? this.details.id}`, {flow_state: state})
                    .then(response => {
                        this.details ? this.details.state = state : null;
                        this.load();
                    })
                    .catch(error => {
                        this.$toasted.error('There was an error changing the state.');
                        console.error(error);
                    });
            },
        },
        computed: {
            verified(){
                return this.$store.state.user ? this.$store.state.user.email_verified : false;
            },
            user(){
                return this.$store.state.user;
            },
            name(){
                if(this.user.first_name){
                    return this.user.last_name ? this.user.first_name + ' ' + this.user.last_name : this.user.first_name;
                }else if(this.user.last_name){
                    return this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.last_name;
                }else{
                    return 'Anonymous User';
                }
            }
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .admin-flow
    {
        top: @breadcrumbs-height;
        overflow-y: auto;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
    }

    .metrics
    {
        flex: 2;
    }

    .extra
    {
        margin: 20px 10px 0px 10px;

        h4
        {
            margin-bottom: 10px;
        }
    }

    .graph
    {
        border-bottom: 1px solid @e4;
        padding-bottom: 20px;
    }

    .pipeline-card:last-child
    {
        .pipeline-metadata
        {
            border-bottom: none;
        }
    }

    .pipeline-metadata
    {
        padding: 8px 10px;
        border-bottom: 1px solid @e4;
    }

    .job-title
    {
        color: @black;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
        line-height: 18px;

    }

    .job-district
    {
        color: @grey;
        font-size: 12px;
        line-height: 14px;
    }

    .options
    {
        font-size: 16px;
        cursor: pointer;
        margin-left: 10px;

        .icon
        {
            vertical-align: middle;
            color: @grey;
            margin-left: 6px;

            &:hover{
                opacity: 0.7;
            }
        }
    }

    .job-tag
    {
        margin-right: 5px;
        display: inline-block;
        border-radius: 3px;
        background: @grey;
        padding: 0 3px;
        line-height: 14px;
        font-size: 10px;
        color: @f;
        font-weight: 500;

        &.error
        {
            background: @red;
        }

        &.complete
        {
            background: @green;
        }

        &.queued
        {
            background: @base;
        }

        &.matching, &.staged, &.flowing
        {
            background: @purple;
        }

        &.pending, &.canceled
        {
            background: @orange;
        }
    }

    .sub-text
    {
        color: @lightgrey;
        font-size: 12px;
    }

    .job-details
    {
        padding: 20px;
        overflow: auto;

        h2
        {
            color: @black;
            font-size: 16px;
            padding-top: 20px;
            border-top: 1px solid @e4;
            margin-top: 20px;
            margin-bottom: 20px;

            &:first-child
            {
                margin-top: 0;
                border-top: 0;
                padding-top: 0;
            }
        }

        .summary
        {
            font-size: 13px;

            .summary-field
            {
                height: 22px;
                margin-bottom: 10px;

                &:last-child
                {
                    margin-bottom: 0;
                }
            }

            .summary-key
            {
                color: @grey;
                width: 160px;
                flex-basis: 160px;
                flex-shrink: 0;
            }

            .summary-value
            {
                color: @black;
                line-height: 22px;

                &.monospace
                {
                    line-height: 22px;
                    font-size: 13px;
                }

                .icon-status
                {
                    margin-right: 6px;
                }

                .text-button
                {
                    & .active{
                        color: @base;
                        border-color: @base;
                    }
                    
                }
            }
        }
    }
</style>
