import { render, staticRenderFns } from "./OverrideDetails.vue?vue&type=template&id=337b3810&scoped=true"
import script from "./OverrideDetails.vue?vue&type=script&lang=js"
export * from "./OverrideDetails.vue?vue&type=script&lang=js"
import style0 from "./OverrideDetails.vue?vue&type=style&index=0&id=337b3810&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "337b3810",
  null
  
)

export default component.exports