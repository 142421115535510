<template>
    <div class="reconnect-step login">
        <h1>Please Enter Your Edlink Password</h1>
        <input class="large" ref="password" type="password" placeholder="Enter your password..." autocomplete="false" v-model="password" @keydown.enter="login" />
        <!-- TODO This is where we'll put a magic link button -->
        <div class="options flex" key="email">
            <div class="button" kbd="Enter ⏎" @click="login">Sign In</div>
            <div class="ff"></div>
        </div>
        <a class="text-button mini block" href="https://ed.link/forgot" target="_blank">Forgot your password?</a>
    </div>
</template>

<script>
    export default {
        name: 'ReconnectEmail',
        data() {
            return {}
        },
        computed: {
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            email() {
                return this.$store.state.reconnect.login.email;
            },
            password: {
                get(){
                    return this.$store.state.reconnect.login.password;
                },
                set(value){
                    this.$store.commit('reconnect/state', { path: 'login.password', value })
                }
            }
        },
        methods: {
            send(page){
                this.$store.commit('reconnect/page', page);
            },
            next(){
                this.$store.dispatch('reconnect/next');
            },
            login(){
                this.$store.commit('reconnect/loading', true);
                
                this.$http.post('/login', {
                    email: this.email,
                    password: this.password,
                    two: null
                })
                .then(response => this.$store.dispatch('user/token', response.$data.token))

                // Try to get the user profile.
                // If this call fails, we probably need to 2FA.
                .then(() => this.$http.get('/me'))
                .then(() => this.$store.dispatch('user/login'))
                .then(() => {
                    this.$store.dispatch('reconnect/login', this.$route.params.parent);
                    this.send('configuration');
                })
                .catch(response => {
                    // It's possible that we'll have more token activation conditions in the future so we should observe the response.
                    if(response?.$details?.includes('mfa_required')){
                        this.send('two');
                    }else{
                        this.$toasted.error(response.$error ? response.$error : 'There was an error logging you in.');
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.$store.commit('reconnect/loading', false);
                    }, 500);
                });
            },
        },
        mounted(){
            this.$refs.password.focus();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    h1
    {
        line-height: 50px;
    }

    .text-button.mini
    {
        margin-top: 20px;
    }
</style>