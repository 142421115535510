<template>
    <div class="tokens flex flex-align flex-wrap" :class="{disabled, error}" @click="focus">
        <div class="token flex flex-align" v-for="(token, index) of values" :key="index" :class="{selected: token === selected}" @click="select(token, $event)">
            <div class="token-name">{{token}}</div>
            <div class="token-remove" @click="remove(token)"></div>
        </div>
        <input class="tokens-input ff" type="text" :disabled="disabled" v-model="value" @keydown="keydown" ref="input" @blur="blur" />
    </div>
</template>

<script>
    export default {
        name: 'Tokens',
        props: {
            error: Boolean,
            values: Array,
            disabled: Boolean
        },
        data(){
            return {
                value: '',
                selected: ''
            };
        },
        methods: {
            keydown($event){
                if($event.which === 13){ //Enter
                    $event.preventDefault();
                    $event.stopPropagation();
                    this.selected = '';

                    if(this.value){
                        if(!this.values.includes(this.value)){
                            const duplicate = this.values.slice();
                            duplicate.push(this.value);
                            this.$emit('update:values', duplicate);
                            this.value = '';
                        }
                    }
                }else if($event.which === 8 || $event.which === 46){ //Backspace or Delete
                    if(this.value){
                        return;
                    }

                    if(this.selected){
                        this.remove(this.selected);
                    }else if(this.values.length && $event.which === 8){
                        //NOTE DJG Don't run this interaction if the user pressed delete, only backspace
                        this.selected = this.values[this.values.length - 1];
                    }

                    this.focus();
                }
            },
            remove(token){
                const duplicate = this.values.slice();
                duplicate.splice(duplicate.indexOf(token), 1);
                this.selected = '';
                this.$emit('update:values', duplicate);
            },
            select(token, $event){
                $event.preventDefault();
                $event.stopPropagation();

                this.selected = token;
                this.focus();
            },
            focus(){
                this.$refs.input.focus();
            },
            blur(){
                if(this.selected){
                    this.selected = '';
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../~@/assets/less/variables";
    @import "../~@/assets/less/mixins";

    .tokens
    {
        border: 1px solid @d;
        padding: 2px;
        min-height: 40px;
        line-height: 30px;
        font-size: 16px;
        color: @black;
        border-radius: 3px;
        text-transform: none;
        margin: 0;
        cursor: pointer;

        &:focus-within, &:active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;
        }

        .token
        {
            background: @e4;
            color: @black;
            padding: 0 0 0 8px;
            font-size: 15px;
            border-radius: 3px;
            margin: 3px;
            line-height: 28px;
            height: 28px;
            white-space: nowrap;

            &.selected
            {
                background: @base;
                // color: rgba(0, 0, 0, 0.5);
                color: @f;

                .token-remove
                {
                    background-image: url('~@/assets/icons/white/x-round.svg');
                    opacity: 1;

                    &:hover, &:active
                    {
                        opacity: 1;
                    }
                }
            }
        }

        .token-remove
        {
            width: 28px;
            height: 28px;
            background: url('~@/assets/icons/black/x-round.svg') 5px 7px no-repeat;
            background-size: 18px auto;
            opacity: 0.3;
            transition: opacity ease 0.2s;

            &:hover, &:active
            {
                opacity: 0.7;
            }
        }

        input.tokens-input
        {
            border-radius: 0;
            width: auto;
            margin: 3px;
            height: 28px;
            line-height: 28px;
            padding: 0 3px;
            border: 0;
            min-width: 150px;

            &:focus
            {
                box-shadow: none;
            }
        }

        &.compact
        {
            padding: 2px;
            min-height: 34px;
            line-height: 28px;

            .token
            {
                margin: 2px;
                height: 24px;
                line-height: 24px;
                padding: 0 0 0 6px;
                font-size: 13px;
            }

            input.tokens-input
            {
                height: 24px;
                line-height: 24px;
                margin: 2px;
                font-size: 13px;
            }

            .token-remove
            {
                width: 24px;
                height: 24px;
                background-position: 3px 5px;
            }
        }
    }
</style>
