<template>
    <div class="navigation flex flex-column">
        <div class="team flex flex-align" v-if="team" :class="team.status">
            <div class="team-icon flex flex-align flex-center" v-tooltip.right="{disabled: team.status !== 'destroyed', content: 'This team is destroyed'}">
                <template v-if="team.status === 'destroyed'">
                    <WarningCircle class="icon" width="14" height="14" stroke-width="2" />
                </template>
                <template v-else>{{ team.name ? team.name.slice(0, 1) : 'E' }}</template>
            </div>
            <router-link class="team-name text-overflow" :class="{ verified: team.verified }" :to="{ name: 'team.dashboard', params: { team: team.alias } }">{{ team.name }}</router-link>
            <div class="ff"></div>
            <div class="team-switch" @click="change"></div>
        </div>
        <div v-else class="wordmark"></div>
        <nav class="vertical-navigation flex flex-column ff">
            <transition name="fade" mode="out-in">
                <component :is="navigation" />
            </transition>
        </nav>
        <nav class="system-health vertical-navigation flex flex-column" v-if="user">
            <div class="sidebar-group">
                <a class="destination block flex flex-align" @click="status">
                    <AntennaSignal class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">System Status</div>
                    <spinner v-if="system.loading" />
                    <template v-else>
                        <div class="notifications badge flex flex-align" v-if="system.warnings === 0 && system.errors === 0" v-tooltip.top="'All Systems Operational'">
                            <Check class="icon" stroke-width="2.5" />
                            <div class="badge-content">OK</div>
                        </div>
                        <div class="notifications badge flex flex-align error" v-if="system.errors" v-tooltip.top="'Some Systems Significantly Delayed'">
                            <Activity class="icon" stroke-width="2.5" />
                            <div class="badge-content">{{system.errors + system.warnings}}</div>
                        </div>
                        <div class="notifications badge flex flex-align warning" v-else-if="system.warnings" v-tooltip.top="'Some Systems Delayed'">
                            <Activity class="icon" stroke-width="2.5" />
                            <div class="badge-content">{{system.warnings}}</div>
                        </div>
                    </template>
                </a>
            </div>
        </nav>
        <div class="user flex flex-align" v-if="user.id">
            <div class="user-icon flex flex-center flex-align pointer" @click="account" :class="{picture: user.picture_url}">
                <div class="user-notifications" v-if="invitations.length">{{ invitations.length }}</div>
                <div class="user-profile-picture" v-if="user.picture_url">
                    <img :src="user.picture_url" />
                </div>
                <User v-else class="icon" width="16" height="16" stroke-width="2.5" />
            </div>
            <div class="user-name text-overflow">{{ name }}</div>
            <div class="ff"></div>
            <router-link class="user-action flex flex-center flex-align tooltip tooltip-top" tooltip="Admin Panel"
                :to="{ name: 'admin' }" v-if="user.admin">
                <Tower class="icon" width="16" height="16" stroke-width="2" />
            </router-link>
            <div class="user-action flex flex-center flex-align tooltip tooltip-top" tooltip="Account Settings"
                @click="account">
                <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
            </div>
        </div>
    </div>
</template>

<script>
import { User, Tower, SettingsAlt, OpenInWindow, WarningCircle, AntennaSignal, Check, Activity } from '@epiphany/iconoir';

import SwitchTeam from '@/components/modals/SwitchTeam.vue';
import AccountSettings from '@/components/modals/AccountSettings.vue';
import SystemStatus from '@/components/modals/SystemStatus.vue';

import TeamNavigation from './navigation/TeamNavigation.vue';
import IntegrationNavigation from './navigation/IntegrationNavigation.vue';
import DocumentationNavigation from './navigation/DocumentationNavigation.vue';
import SourceNavigation from './navigation/SourceNavigation.vue';
import ApplicationNavigation from './navigation/ApplicationNavigation.vue';
import AdminNavigation from './navigation/AdminNavigation.vue';

export default {
    name: 'Navigation',
    components: {
        User,
        Tower,
        SettingsAlt,
        OpenInWindow,
        WarningCircle,
        AntennaSignal,
        Check,
        Activity
    },
    data() {
        return {};
    },
    methods: {
        change() {
            this.$modal.show(SwitchTeam, { redirect: true }, { width: 320, height: 'auto', classes: 'modal', scrollable: true });
        },
        account() {
            this.$modal.show(AccountSettings, {}, { width: 700, height: 'auto', classes: 'modal', scrollable: true });
        },
        status(){
            this.$modal.show(SystemStatus, {}, {width: 500, height: 'auto', classes: 'modal'});
        }
    },
    computed: {
        system(){
            return this.$store.state.system;
        },
        navigation() {
            // Precendence:
            // 1. Admin Navigation
            // 2. Integration Navigation
            // 3. Documentation Navigation
            // 4. Team Navigation
            // 5. Source Navigation
            // 6. Application Navigation

            if (this.$route.name?.startsWith('admin.')) {
                return AdminNavigation;
            }

            if (this.$route.name === 'team.dashboard') {
                return this.team?.id ? TeamNavigation : 'div';
            }

            if (this.$store.getters.integration) {
                return IntegrationNavigation;
            }

            if (this.$store.getters.application) {
                return ApplicationNavigation;
            }

            if (this.$store.getters.source) {
                return SourceNavigation;
            }

            if (this.$route.name?.startsWith('docs.')) {
                return DocumentationNavigation;
            }

            return this.team?.id ? TeamNavigation : 'div';
        },
        changes() {
            return this.$store.getters.banners.find((banner) => banner.type === 'changelog');
        },
        team() {
            return this.$store.getters.team;
        },
        verified() {
            return this.$store.state.user ? this.$store.state.user.email_verified : false;
        },
        user() {
            return this.$store.state.user;
        },
        name() {
            if (this.user.first_name) {
                return this.user.last_name ? this.user.first_name + ' ' + this.user.last_name : this.user.first_name;
            } else if (this.user.last_name) {
                return this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.last_name;
            } else {
                return 'Anonymous User';
            }
        },
        invitations() {
            return this.user.invitations;
        }
    }
};
</script>

<style lang="less">
@import '~@/assets/less/variables';

.navigation {
    position: absolute;
    right: auto;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    background: @f8;
    z-index: 10;
    border-right: 1px solid @e;
    padding: 0;

    .wordmark
    {
        height: 51px;
        background: url('~@/assets/edlink.svg') 18px 19px no-repeat;
        background-size: 82px auto;
        color: transparent;
        border-bottom: 1px solid @e;
    }

    .username {
        line-height: 18px;
        margin-bottom: 2px;
    }

    .email {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
    }

    .sidebar-group {
        border-top: 1px solid @e;
        padding: 8px 10px;

        &:first-child {
            border-top: 0;
        }
    }

    .sidebar-header {
        font-size: 11px;
        font-weight: 500;
        color: @grey;
        padding: 8px;
    }

    nav {
        padding: 0;

        &.vertical-navigation {

            a,
            .router-link {
                margin-bottom: 2px;
                padding: 5px 8px;
                height: 30px;
                border-radius: 5px;
                font-weight: 500;
                font-size: 12px;
                cursor: pointer;
                color: @grey;

                &:hover {
                    color: @black;
                }

                &.router-link-active,
                &.active {
                    color: @black;
                    background-color: @e4;
                }
            }
        }
    }

    .team {
        height: 51px;
        padding: 10px 16px;
        border-bottom: 1px solid @e;

        .team-icon {
            width: 22px;
            height: 22px;
            min-width: 22px;
            border-radius: @border-radius - 2px;
            background: @black;
            margin-right: 10px;
            font-size: 14px;
            font-weight: 500;
            color: @f;
            text-align: center;
            line-height: 22px;
        }

        .team-name {
            line-height: 24px;
            font-size: 13px;
            font-weight: 500;
            color: @black;

            &.verified {
                background: url('~@/assets/icons/black/verified.svg') no-repeat;
                background-position: right 1px top 5px;
                background-size: 14px auto;
                padding-right: 20px;
            }
        }

        .team-switch {
            width: 16px;
            flex-basis: 16px;
            min-width: 16px;
            height: 24px;
            border-radius: @border-radius - 2px;
            margin-left: 8px;
            background: url('~@/assets/icons/black/select.svg') 0px 5px no-repeat;
            background-size: 16px auto;
            cursor: pointer;

            &:hover,
            &:active {
                background-color: @e4;
            }
        }

        &.destroyed {
            .team-icon
            {
                background: @red;
                color: @f;
            }

            .team-name
            {
                color: @red;
                text-decoration: line-through;
            }
        }

        &.inactive {
            .team-icon
            {
                background: @grey;
                color: @f;
            }

            .team-name
            {
                color: @grey;
            }
        }
    }

    nav.system-health
    {
        background: @f8;

        .destination
        {
            margin: 0;
        }
    }

    .user
    {
        height: 51px;
        padding: 10px 8px 10px 14px;
        border-top: 1px solid @e;
        background: @f8;
    }

    .user-icon {
        width: 22px;
        height: 22px;
        border-radius: @border-radius - 2px;
        background: @black;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: @f;
        text-align: center;
        line-height: 22px;
        min-width: 22px;

        .user-profile-picture
        {
            img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: @border-radius - 2px;
                display: block;
            }
            
            &::after
            {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: @border-radius - 2px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                z-index: 2;
            }
        }

        &.picture
        {
            background: none;
        }
    }

    .user-name {
        line-height: 24px;
        font-size: 13px;
        font-weight: 500;
        color: @black;

        &.verified {
            background: url('~@/assets/icons/black/verified.svg') no-repeat;
            background-position: right 1px top 5px;
            background-size: 14px auto;
            padding-right: 20px;
        }
    }

    .user-action {
        width: 30px;
        flex-basis: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: @border-radius - 2px;
        margin-left: 2px;
        cursor: pointer;
        color: @black;

        &:hover,
        &:active {
            background-color: @e4;
        }
    }

    .user-notifications {
        padding: 0 4px;
        min-width: 13px;
        height: 13px;
        line-height: 13px;
        position: absolute;
        background: @red;
        font-size: 8px;
        font-weight: 600;
        text-align: center;
        top: -5px;
        right: -5px;
        z-index: 10;
        border-radius: @border-radius;
        color: @f;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
}</style>
