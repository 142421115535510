<template>
    <section class="source-staged-changes" v-if="job">
        <div class="section-content ff">
            <h2>Staged Totals</h2>
            <datatable v-if="totals" class="card staged-changes" :columns="columns" :rows="rows" :selectable="true" :header="true" :clickable="false">
                <template v-slot:empty-message>You have not yet created any sources.</template>
            </datatable>
            <div class="nothing" v-else-if="['working', 'pending', 'staged'].includes(job.status)">
                No entities were changed during this sync.
            </div>
            <div class="nothing" v-else>
                All staged changes have already been applied or discarded.
            </div>
        </div>
    </section>
</template>

<script>
    import {
        UploadSquare,
        Plus,
        Minus,
        CloudSync,
        ViewGrid
    } from '@epiphany/iconoir';

    const entities = ['people', 'districts', 'schools', 'classes', 'sections', 'courses', 'agents', 'sessions', 'enrollments', 'meetings', 'attendance', 'departments', 'subjects', 'periods', 'incidents', 'facilities', 'rooms', 'assets', 'vehicles', 'routes', 'stops', 'fees', 'calendars', 'days'];
    export default {
        name: 'SyncStagedChanges',
        props: {
            job: Object,
            totals: Object
        },
        data() {
            return {
                entities,
                columns: [
                    {
                        id: 'type',
                        name: 'Type',
                        icon: ViewGrid,
                        value: row => row.type, 
                        classes: 'capitalize'
                    },
                    {
                        id: 'Source',
                        name: 'Source',
                        icon: CloudSync,
                        value: row => this.$options.filters.commas(row.totals)
                    },
                    {
                        id: 'created',
                        name: 'Created',
                        icon: Plus,
                        value: row => this.$options.filters.commas(row.created)
                    },
                    {
                        id: 'updated',
                        name: 'Updated',
                        icon: UploadSquare,
                        value: row => this.$options.filters.commas(row.updated)
                    },
                    {
                        id: 'deleted',
                        name: 'Deleted',
                        icon: Minus,
                        value: row => this.$options.filters.commas(row.deleted)
                    }
                ]
            }
        },
        computed: {
            rows() {
                if (!this.totals) {
                    return [];
                }
                return entities.map(entity => ({
                    type: entity,
                    totals: this.totals[`total_${entity}`],
                    updated: this.totals[`staged_updated_${entity}`],
                    created: this.totals[`staged_created_${entity}`],
                    deleted: this.totals[`staged_deleted_${entity}`]
                }))
            }
        }
    }

</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    .staged-changes
    {
        nav
        {
            padding: 0 20px;
            border-bottom: 1px solid @e4;
        }

        .nothing
        {
            font-size: 13px;
            border: 0;
        }
    }
</style>