<template>
    <div class="graph flex flex-column" :style="{width}">
        <div class="graph-header flex" v-if="configuration.header?.visible !== false">
            <div class="graph-series" v-for="series of legends" :key="series.id">
                <div class="graph-title flex flex-align">
                    <h4>{{series.title}}</h4>
                    <info class="tooltip-top" v-if="configuration.description" :tooltip="configuration.description" />
                    <div class="change flex flex-align" :class="{positive: change > 0, negative: change < 0, neutral: change === 0, visible: hover || configuration.delta?.visible === 'always'}">
                        <span class="icon" :class="{'iconoir-arrow-up': change > 0, 'iconoir-arrow-down': change < 0}"></span>
                        {{change_formatted}}
                    </div>
                </div>
                <div class="graph-values flex flex-align">
                    <div class="value">{{value(series)}}</div>
                </div>
                <div class="graph-timestamps flex flex-align">
                    <div class="timestamp" :class="{visible: hover || configuration.timestamp?.visible === 'always'}">{{timestamp(series) | pretty(configuration.timestamp.format)}}</div>
                </div>
            </div>
        </div>
        <div class="graph-canvas ff" :style="{height}" ref="canvas" @mousemove="mousemove" @mouseover="mouseover" @mouseout="mouseout">
            <svg class="graph-svg block" :style="{width, height}" ref="svg"></svg>
        </div>
        <div class="timeline flex">
            <div class="timeline-start">{{configuration.start | pretty(configuration.timestamp.format)}}</div>
            <div class="ff"></div>
            <div class="timeline-end">{{configuration.end | pretty(configuration.timestamp.format)}}</div>
        </div>
    </div>
</template>

<script>
    import * as d3 from 'd3';
    import _ from 'lodash';

    export default {
        name: 'Graph',
        props: {
            hover: Date,
            configuration: Object
        },
        data(){
            return {
                resize: null,
                actual: {
                    width: 1,
                    height: 1
                },
                elements: {
                    series: null,
                    ticks: null
                },
                scale: {
                    x: null,
                    y: null
                },
                crosshairs: {
                    container: null,
                    x: null,
                    y: null
                },
                ticks: null,
                default: {
                    granularity: 'day',
                    title: 'Untitled Graph'
                }
            };
        },
        computed: {
            width(){
                return this.configuration.width ?? this.actual.width;
            },
            height(){
                return this.configuration.height ?? this.actual.height;
            },
            title(){
                return this.configuration.title ?? this.default.title;
            },
            legends(){
                return this.configuration.series.filter(series => series.legend !== 'none');
            },
            change(){
                if(this.configuration.series.length > 1){
                    const [first, second] = this.configuration.series;

                    if(this.hover){
                        const iso = this.hover.toISOString();

                        const current = first.data.find(point => point.date === iso);
                        const previous = second.data.find(point => point.date === iso);

                        if(current && previous){
                            if(previous.value === 0){
                                return current.value ? 100 : 0;
                            }

                            return Math.round(current.value / previous.value * 100 - 100);
                        }
                    }
                }

                return 0;
            },
            change_formatted(){
                return Math.abs(this.change).toLocaleString() + '%';
            }
        },
        mounted(){
            // Set our actual width and height values.
            this.actual.width = this.$refs.canvas.clientWidth;
            this.actual.height = this.$refs.canvas.clientHeight;

            // Select our SVG element for later use.
            const svg = d3.select(this.$refs.svg);

            this.scale.x = d3.scaleTime().domain([this.configuration.start, this.configuration.end]);
            this.scale.y = d3.scaleLinear().domain([0, 100]);

            if(this.configuration.ticks){
                // Create our ticks container, if necessary.
                this.elements.ticks = svg.append('g').attr('class', 'ticks');

                // Create a D3 axis for the ticks based on our X scale.
                this.ticks = d3.axisBottom(this.scale.x).tickSizeOuter(0).ticks(this.configuration.ticks);
            }

            // Set up a group to eventually hold our series.
            this.elements.series = svg.append('g').attr('class', 'series');

            // Set the range for our scales.
            this.scale.x.range([2, this.width - 2]);
            this.scale.y.range([this.height - 2, 2]);

            // Set up our crosshairs.
            this.crosshairs.container = svg.append('g').attr('class', 'crosshairs').attr('visibility', 'hidden');
            
            // Add a line and points for each series.
            this.crosshairs.x = this.crosshairs.container.append('path')
                .attr('class', 'x')
                .attr('d', `M0,0 L0,${this.height}`)
                .attr('stroke-width', 1)
                .attr('stroke-dasharray', '2,2')
                .attr('fill', 'none');

            for(const series of this.configuration.series){
                this.crosshairs[series.id] = this.crosshairs.container.append('circle')
                    .attr('class', `dot ${series.id}`)
                    .attr('r', 3)
                    .attr('fill', series.color);
            }

            // Mount our auto-resize.
            this.resize = new ResizeObserver(this.resized.bind(this));
            this.resize.observe(this.$refs.canvas);

            this.rescale();
            this.draw();
        },
        destroyed(){
            this.resize.disconnect();
        },
        methods: {
            mouseover(){
                // Show the crosshairs.
                // this.crosshairs.container.attr('visibility', 'visible');
            },
            mouseout(){
                // Hide the crosshairs and reset the value back to the sum.
                // this.crosshairs.container.attr('visibility', 'hidden');

                // Set the hover back to null.
                this.$emit('update:hover', null);
            },
            mousemove($event){
                const date = this.configuration.granularity.round(this.scale.x.invert($event.offsetX));

                // Set the current tick we're hovering over.
                this.$emit('update:hover', date);
            },
            resized(){
                // The graph element has changed an we need to recalculate the scale ranges.
                this.actual.width = this.$refs.canvas.clientWidth;
                this.actual.height = this.$refs.canvas.clientHeight;

                // Set the range for our scales.
                this.scale.x.range([2, this.width - 2]);
                this.scale.y.range([this.height - 2, 2]);

                this.draw();
            },
            rescale(){
                let y_min = 0;
                let y_max = 0;

                for(const series of this.configuration.series){
                    if(series.data){
                        for(const datum of series.data){
                            y_max = Math.max(y_max, datum.value);
                        }
                    }
                }

                // If there is literally any y_max, we should set y_min to 0.
                // We only want y_min to be -1 when y_max is effectively unset.
                if(y_min === y_max){
                    y_min = -1;
                    y_max = 1;
                }

                this.scale.x.domain([this.configuration.start, this.configuration.end]);
                this.scale.y.domain([y_min, y_max]);

                this.draw();
            },
            draw(){
                const svg = d3.select(this.$refs.svg);

                // Draw the vertical ticks.
                if(this.configuration.ticks){
                    this.elements.ticks.call(this.ticks);

                    // Make the Y axis ticks span the whole length of the graph.
                    this.elements.ticks.selectAll('line')
                        .attr('y2', 0)
                        .attr('y1', d => this.actual.height);

                    // Move the axis to the bottom.
                    this.elements.ticks.select('path.domain')
                        .attr('transform', `translate(0, ${this.actual.height})`);
                }

                // Remove the old plot container group.
                this.elements.series.selectAll('g').remove();

                // Draw each series.
                for(const series of this.configuration.series.slice().reverse()){
                    if(series.data.length === 0){
                        continue;
                    }

                    // Recreate the plot container group.
                    const element = this.elements.series.append('g').attr('class', `plot ${series.plot} ${series.id}`);

                    this[series.plot](series, element);
                }

                // Update our crosshairs (we only need to worry about the height here).
                this.crosshairs.x.attr('transform', `translate(100, 0)`);
            },
            timestamp(series){
                if(this.hover){
                    return this.hover;
                }

                return new Date();
            },
            value(series){
                // Calculates the last value for a given series.
                // If the user is currently hovering over a chart, it will show the value at that point.
                if(this.hover){
                    const iso = this.hover.toISOString();

                    // Find the datapoint for this x value.
                    const point = series.data.find(point => point.date === iso);

                    if(point){
                        return point.value.toLocaleString();
                    }
                }

                if(series.legend === 'last'){
                    if(series.data.length > 0){
                        return series.data[series.data.length - 1].value.toLocaleString();
                    }

                    return 0;
                }

                return series.data.reduce((sum, point) => sum + point.value, 0).toLocaleString();
            },
            line(series, element){
                // element.selectAll('path').remove();
                const points = series.data.map(({date, value}) => {
                    return `${Math.round(this.scale.x(new Date(date)))} ${this.scale.y(value)}`;
                }).join(' L ');

                element.append('path')
                    .classed('stroke', true)
                    .attr('stroke', series.color)
                    .attr('stroke-width', 2)
                    .attr('stroke-linejoin', 'round')
                    .attr('fill', 'none')
                    .attr('stroke-dasharray', series.style === 'dashed' ? '2 2' : null)
                    .attr('d', `M ${points}`);
            },
            mountain(series, element){
                const gradient = element.append('linearGradient')
                    .attr('class', `gradient ${series.id}`)
                    .attr('x1', 0)
                    .attr('x2', 0)
                    .attr('y1', 1)
                    .attr('y2', 0)
                    .attr('id', series.id);
                
                gradient.append('stop').attr('offset', '0%').attr('stop-color', 'rgba(0, 106, 255, 0)');
                gradient.append('stop').attr('offset', '100%').attr('stop-color', 'rgba(0, 106, 255, 0.3)');

                const [start] = series.data;
                const [_, bottom] = this.scale.y.range();

                const points = series.data.map(({date, value}) => {
                    return `${this.scale.x(new Date(date))} ${this.scale.y(value)}`;
                }).join(' L ');

                element.append('path').classed('stroke', true).attr('d', `M ${points}`);
                element.append('path').classed('fill', true).attr('fill', `url(#${series.id})`).attr('d', `M ${points} V ${bottom} H ${this.scale.x(new Date(start.date))} Z`);
            },
            bar(series, element){
                const width = 6;
                const half = width / 2;
                const bars = element.selectAll('rect').data(series.data);

                bars.enter()
                    .append('rect')
                    .merge(bars)
                        .attr('x', ({date}) => this.scale.x(new Date(date)))
                        .attr('y', ({value}) => Math.round(this.scale.y(Math.max(0, value))))
                        .attr('width', () => width)
                        .attr('height', ({value}) => Math.max(1, Math.abs(this.scale.y(0) - this.scale.y(value))))
                        .attr('fill', series.fill ? series.fill : '#006aff');

                bars.exit().remove();
            }
        },
        watch: {
            configuration: {
                deep: true,
                handler(){
                    this.rescale();
                }
            },
            hover(){
                if(this.hover){
                    const position = Math.min(Math.max(Math.floor(this.scale.x(this.hover)), 1), this.width - 1);

                    // Update the dashed line.
                    this.crosshairs.x.attr('transform', `translate(${position}, 0)`);

                    const iso = this.hover.toISOString();

                    // Update the dots for each series.
                    for(const series of this.configuration.series){
                        // Find the datapoint for this x value.
                        const point = series.data.find(point => point.date === iso);

                        if(point){
                            this.crosshairs.container.select(`circle.${series.id}`)
                                .attr('transform', `translate(${position}, ${this.scale.y(point.value)})`)
                                .attr('visibility', null)
                                .attr('fill', series.color);
                        }else{
                            this.crosshairs.container.select(`circle.${series.id}`).attr('visibility', 'hidden');
                        }
                    }

                    this.crosshairs.container.attr('visibility', 'visible');

                    // TODO Update the value as well.
                }else{
                    this.crosshairs.container.attr('visibility', 'hidden');
                }
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    
    .graph
    {
        .graph-title
        {
            color: @darkgrey;

            .info
            {
                margin-top: 1px;
                margin-left: 4px;
            }
        }

        h4
        {
            font-size: 14px;
            font-weight: 500;
            color: @darkgrey;
            letter-spacing: -0.02rem;
            line-height: 20px;
        }

        .graph-svg
        {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        path.domain
        {
            stroke: @c;
        }

        .tick
        {
            text
            {
                display: none;
            }

            line
            {
                stroke: @f4;
                stroke-width: 1;
            }
        }

        .value
        {
            color: @black;
            font-size: 20px;
            line-height: 24px;
            margin: 4px 0;
        }

        .timestamp
        {
            color: @grey;
            font-size: 12px;
            line-height: 16px;
            opacity: 0;

            &.visible
            {
                opacity: 1;
            }
        }

        .change
        {
            margin-left: 10px;
            font-weight: 500;
            letter-spacing: -0.02rem;
            font-size: 14px;
            line-height: 20px;
            opacity: 0;

            &.positive
            {
                color: @green;
            }

            &.negative
            {
                color: @red;
            }

            &.visible
            {
                opacity: 1;
            }

            .icon
            {
                font-size: 16px;
                width: 16px;
                height: 16px;
                line-height: 16px;
                display: block;
            }
        }

        .crosshairs
        {
            line
            {
                stroke: @base;
                stroke-dasharray: 5;
                stroke-width: 1;
            }

            path.x
            {
                stroke: @grey;
            }
        }

        .graph-header
        {
            padding-bottom: 10px;
        }

        .graph-canvas
        {
            svg
            {
                z-index: 2;
                pointer-events: none;
            }
            
            &:hover
            {
                g.crosshairs
                {
                    visibility: visible;
                }
            }
        }

        .timeline
        {
            padding-top: 6px;
            font-size: 12px;
            line-height: 16px;
            color: @grey;
        }
    }
</style>
