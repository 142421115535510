<template>
    <div class="custom-admin-onboarding flex flex-column">
        <div v-if="loading" class="integrate-loading flex flex-align flex-center ff">
            <spinner />
        </div>
        <template v-else>
            <header class="flex flex-align">
                <a class="wordmark" href="https://ed.link" target="_blank"></a>
                <div class="button white custom-integrate-button flex flex-align flex-center" v-if="noBackScreens.every(it => !state.matches(it))" @click="send('BACK')">
                    <ArrowLeft class="icon" width="18" height="18" stroke-width="2" />
                </div>
                <div class="ff"></div>
                <div class="button white custom-integrate-button flex flex-align flex-center" @click="cancel">
                    <Cancel class="icon" width="24" height="24" stroke-width="1.6" />
                </div>
            </header>
            <main class="flex flex-column flex-align flex-center ff enhanced">
                <custom-admin-initializing class="integrate-state" v-if="state.matches('initializing')" :send="send" :state="state" />
                <transition appear name="slide" :duration="400" v-else>
                    <custom-admin-error class="integrate-state" v-if="state.matches('error')" :send="send" :state="state" />
                    <custom-admin-challenge class="integrate-state" v-else-if="state.matches('challenge')" :send="send" :state="state" />
                    <!-- <custom-admin-terms class="integrate-state" v-else-if="state.matches('terms')" :send="send" :state="state" /> -->
                    <!-- <custom-admin-confirm class="integrate-state" v-else-if="state.matches('confirmation.ask')" :send="send" :state="state" /> -->
                    <custom-admin-complete class="integrate-state" v-else-if="state.matches('complete')" :send="send" :state="state" />
                    <custom-admin-request class="integrate-state" v-else-if="state.matches('lms.request')" :send="send" :state="state" />
                    <custom-admin-requested class="integrate-state" v-else-if="state.matches('lms.requested')" :send="send" :state="state" />
                    <custom-admin-source-select class="integrate-state" v-else-if="state.matches('lms.select')" :send="send" :state="state" />
                    <integrate-source-editor class="integrate-state" key="source-editor" v-else-if="state.matches('lms.editor')" :send="send" :state="state" next-step="custom_admin" type="primary" />
                    <spinner v-else />
                </transition>
            </main>
        </template>
    </div>
</template>

<script>
import { useMachine } from 'xstate-vue2';
import { custom_admin } from '@/machines/custom_admin';

import { ArrowLeft, Cancel } from '@epiphany/iconoir';

import CustomAdminError from '@/views/custom/CustomAdminError.vue';
import CustomAdminInitializing from '@/views/custom/CustomAdminInitializing.vue';
import CustomAdminChallenge from '@/views/custom/CustomAdminChallenge.vue';
import CustomAdminTerms from '@/views/custom/CustomAdminTerms.vue';
import CustomAdminConfirm from '@/views/custom/CustomAdminConfirm.vue';
import CustomAdminComplete from '@/views/custom/CustomAdminComplete.vue';
import CustomAdminRequest from '@/views/custom/CustomAdminRequest.vue';
import CustomAdminRequested from '@/views/custom/CustomAdminRequested.vue';
import CustomAdminSourceSelect from '@/views/custom/CustomAdminSourceSelect.vue';

import IntegrateDistrictCreate from '@/components/integrate/district/IntegrateDistrictCreate.vue';
import IntegrateDistrictSelect from '@/components/integrate/district/IntegrateDistrictSelect.vue';
import IntegrateSourceCreateConfigureRegion from '@/components/integrate/source/IntegrateSourceCreateConfigureRegion.vue';
import IntegrateSourceEditor from '@/components/integrate/source/IntegrateSourceEditor.vue';

export default {
    name: 'CustomAdminOnboarding',
    components: {
        Cancel,
        ArrowLeft,

        'custom-admin-error': CustomAdminError,
        'custom-admin-initializing': CustomAdminInitializing,
        'custom-admin-challenge': CustomAdminChallenge,
        'custom-admin-terms': CustomAdminTerms,
        'custom-admin-confirm': CustomAdminConfirm,
        'custom-admin-request': CustomAdminRequest,
        'custom-admin-requested': CustomAdminRequested,
        'custom-admin-source-select': CustomAdminSourceSelect,
        'custom-admin-complete': CustomAdminComplete,

        'integrate-district-select': IntegrateDistrictSelect,
        'integrate-district-create': IntegrateDistrictCreate,
        'integrate-source-create-configure-region': IntegrateSourceCreateConfigureRegion,
        'integrate-source-editor': IntegrateSourceEditor,
    },
    setup() {
        const { state, send } = useMachine(custom_admin, {
            actions: {

            },
            services: {

            }
        });

        return {
            state,
            send
        };
    },
    created(){
        this.$store.dispatch('providers/load').then(() => {
            // Get the provider from the route.
            const provider = this.providers.find(provider => provider.application === this.$route.params.provider);

            const configuration = {};
            /*
                Set default values to provider config
            */
            for (const [k, v] of Object.entries(provider.configuration)) {
                if (v.default) {
                    configuration[k] = v.default;
                } else {
                    switch(v.type) {
                        case 'boolean':
                            configuration[k] = false;
                            break;
                        case 'file':
                            configuration[k] = '';
                            break;
                        case 'number':
                            configuration[k] = 0;
                            break;
                        case 'string':
                            configuration[k] = '';
                            break;
                        default:
                            configuration[k] = null;
                    }  
                }
            }

            // Set the provider and initial configuration.
            this.send('PROVIDER', { data: provider });
            this.send('SOURCE', {
                data: {
                    provider_id: provider.id,
                    region_id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                    token: '',
                    configuration
                }
            });
        });

        window.addEventListener('keydown', this.keydown);

        this.loading = false;
    },
    destroyed(){
        window.removeEventListener('keydown', this.keydown);
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        providers() {
            return this.$store.getters.providers?.filter(provider => {
                if (!this.state.context.application) {
                    return true;
                }

                // Remove internal providers.
                if (['clever', 'classlink'].includes(provider.application)) {
                    return false;
                }

                return true;
            })
            .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
        },
        noBackScreens() {
            const screens = ['initializing', 'challenge', 'terms', 'complete', 'error', 'lms.select', 'lms.request', 'lms.requested'];

            const filtered = this.state.context.sources.filter((source) => {
                if (source.provider.application !== this.state.context.provider.application) {
                    return false;
                }

                if (!this.state.context.application.valid_source_providers.includes(source.provider.id)) {
                    return false;
                }

                if (source.status === 'destroyed') {
                    return false;
                }

                return true;
            });

            if (filtered.length === 0) {
                screens.push('lms.editor');
            }

            return screens;
        }
    },
    methods: {
        keydown(e) {
            if (e.key === 'Enter') {
                this.send('NEXT');
            }
        },
        cancel(){
            window.parent.postMessage({ event: 'onboarding.cancel' }, '*');
        }
    }
}
</script>

<style lang="less">
@import "~@/assets/less/variables";
@import "~@/assets/less/mixins";

</style>

<style scoped lang="less">
@import "~@/assets/less/variables";
@import "~@/assets/less/mixins";

::v-deep main
{
    padding: 0;
    z-index: 2;
    margin: 0 10px;
    background: @f;
    position: relative;
    border-radius: @border-radius @border-radius 0 0;
    box-shadow: 0 0 1px 0 rgba(0, 0, 140, 0.1), 0 0 8px -1px rgba(0, 0, 140, 0.1);

    label {
        font-size: 16px;
        font-weight: 400;
        color: @black;
        letter-spacing: -0.01rem;
    }

    select {
        margin-top: 15px;
    }

    // input {
    //     font-size: 22px;
    //     font-weight: 400;
    //     color: @base;
    //     letter-spacing: -0.02rem;
    //     border: 0;
    //     padding: 0;
    //     outline: 0;

    //     &::placeholder {
    //         color: @c;
    //         opacity: 1;
    //     }

    //     &:-ms-input-placeholder {
    //         color: @c;
    //     }

    //     &::-ms-input-placeholder {
    //         color: @c;
    //     }

    //     &:focus {
    //         box-shadow: none;
    //     }
    // }

    .options {
        margin-top: 25px;
    }

    .integrate-radio, .integrate-radio-row
    {
        padding: 10px 12px;
        cursor: pointer;
        user-select: none;
        margin-right: 15px;
        font-size: 13px;
        font-weight: 500;
        color: @black;
        margin-bottom: 10px;

        background: @f;
        color: @black;
        border-color: fade(@black, 15%);
        border-bottom-color: fade(@black, 20%);
        box-shadow: 0 1px 4px 0 fade(@black, 8%);

        &:last-child
        {
            margin-bottom: 0;
        }

        &-row {
            margin-bottom: 0;
        }

        .icon
        {
            font-size: 20px;
            margin-right: 10px;
        }

        &.selected
        {
            // border-color: @base;
            // box-shadow: 0 0 0 1px @base;
            color: @base;
            border-color: @base;
            border-bottom-color: darken(@base, 5%);
            box-shadow: 0 2px 5px -1px fade(@base, 25%);
        }

        &.placeholder
        {
            margin: 0;
            height: 0;
        }
    }

    .integrate-input-help
    {
        margin-left: 10px;
    }
}

::v-deep {
    .config-help {
        position: fixed;
        pointer-events: all;
        top: 0;
        bottom: 0;
        right: 25px;
        z-index: 10;
        max-width: 30%;
    }

    .custom-admin-onboarding
    {
        // width: 100vw;
        // min-height: 100vh;

        .integrate-loading {
            width: 100vw;
            min-height: 100vh;
        }

        .integrate-state {
            width: 100%;
            // max-width: 600px;
        }

        .integration-options {
            margin-top: 10px;
        }

        .integration-option {
            padding: 10px 0;
            cursor: pointer;
            font-size: 14px;
            color: @base;
            line-height: 18px;

            .icon
            {
                font-size: 20px;
                margin: 0 10px 0 0;
            }

            &:hover,
            &:active {
                .caret {
                    .translate3d(6px, 0, 0);
                }
            }

            .caret {
                width: 14px;
                height: 14px;
                background: url('~@/assets/icons/grey/chevron-right.svg') -2px -1px no-repeat;
                background-size: 18px auto;
                display: block;
                content: "";
                margin-left: 20px;
                transition: all ease 0.2s;
            }

            .option-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin: -4px 10px -4px 0;

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

                &::before {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    z-index: 2;
                    content: "";
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                }

                .icon {
                    color: @grey;
                    font-size: 24px;
                    display: inline-block;
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .helptext {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: @grey;
            max-width: 550px;
        }

        .integrate-video
        {
            font-weight: 400;
            font-size: 12px;
            color: @base;
            line-height: 24px;
            background: fade(@base, 20%);
            height: 24px;
            padding: 0 6px 0 10px;
            border-radius: 12px;
            transition: background-color ease 0.2s;
            cursor: pointer;

            .icon
            {
                font-size: 16px;
                margin-right: 4px;
            }

            &::after
            {
                width: 14px;
                height: 14px;
                background: url('~@/assets/icons/base/chevron-right.svg') -2px -1px no-repeat;
                background-size: 16px auto;
                display: block;
                content: "";
                margin-left: 5px;
                transition: all ease 0.2s;
            }

            &:hover, &:active
            {
                background: fade(@base, 30%);

                &::after
                {
                    .translate3d(4px, 0, 0);
                }
            }
        }
    }

    .integration-configuration-video
    {
        position: fixed;
        bottom: 40px;
        right: 40px;
        border-radius: 8px;
        background: @f;
        width: 360px;
        height: 243px;
        z-index: 100;
        box-shadow: 0 2px 20px -2px rgba(0, 0, 0, 0.3);
        overflow: hidden;

        .video-title
        {
            font-size: 14px;
            font-weight: 500;
            color: @black;
        }

        .video-options
        {
            height: 40px;
            z-index: 2;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
            padding: 0 8px 0 10px;
            margin: 0 1px;

            .text-button
            {
                margin-left: 8px;
            }
        }

        iframe
        {
            border: 0;
        }

        &::after
        {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 3;
            content: "";
            top: 0;
            left: 0;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            pointer-events: none;
        }
    }
}

.custom-admin-onboarding
{
    width: 100vw;
    height: 100vh;
    background: #f6f6fa;
    overflow: hidden;

    header
    {
        z-index: 20;
        padding: @single-padding;
        height: 50px;

        .wordmark {
            background: url('~@/assets/edlink-diamonds-remastered.svg') left top no-repeat;
            background-size: auto 20px;
            color: transparent;
            width: 100px;
            height: 20px;
            position: absolute;
            top: 15px;
            left: 50%;
            margin-left: -52px;
            z-index: 10;
        }
        
        .custom-integrate-button
        {
            width: 30px;
            padding: 0;
            background: transparent;

            &:hover, &:active
            {
                background: darken(#f6f6fa, 5%);
            }
        }
    }
}
</style>


