<template>
    <div class="entity-value entity-value-status flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="max" />
            <!-- <input type="text" class="block editable-input" v-model="override" @keyup.enter="save" @keyup.esc="cancel" ref="input" /> -->
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value">
                <template v-if="array">
                    <template v-if="value.length">
                        <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{statuses[item]}}</div>
                    </template>
                    <div v-else class="empty">&mdash;</div>
                </template>
                <div v-else class="badge text-overflow">{{statuses[value]}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueMilitaryConnectedStatus',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                statuses: {
                    'NotMilitaryConnected': 'Not Military Connected',
                    'ActiveDuty': 'Active Duty',
                    'NationalGuardOrReserve': 'National Guard or Reserve'
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);

                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return value ? this.override : this.original;
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                    
                    if (this.array) {
                        return value;
                    } else {
                        return value ? [value] : [];
                    }
                },
                set(value){
                    if(value.length){
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : this.array ? value : value.pop()
                        });
                    } else {
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : null
                        });
                    }
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                return Promise.resolve(Object.entries(this.statuses).filter(([code, status]) => {
                    return value ? status.toLowerCase().indexOf(value.toLowerCase()) > -1 : true;
                }))
                .then(statuses => statuses.map(([code]) => code).sort());
            },
            format(value){
                return this.statuses[value];
            },
            edit(){
                if(this.editable){
                    if(this.original && !this.override){
                        // Set the default override value to the current property value.
                        this.$store.commit('entities/override', {property: this.property, value: this.original});
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-status
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
