<template>
    <div class="rule" v-if="rule_copy">
        <div class="rule-header flex flex-align pointer" :class="{ capitalize: !rule_copy.name }" @click="() => expanded = !expanded">
            <div class="caret" :class="{ closed: !expanded }"></div>
            <input @blur="toggleEdit(false)" @keydown.esc="toggleEdit(false)" @click.stop="() => {}" v-if="editable" class="rule-name" v-model="rule_copy.name" placeholder="Rule Nickname" ref="rule-name" />
            <template v-else>
                <h3 class="pointer tooltip-top">{{ rule_copy.name ? rule_copy.name : `Format Rows Into ${rule_copy.entity_type}`}}</h3>
                <div class="flex flex-algin flex-center edit-icon" @click.stop="toggleEdit(true)" tooltip="Rename">
                    <EditPencil class="icon edit" width="16" height="16" stroke-width="2" />
                </div>
            </template>
            <div class="ff"></div>
            <div tooltip="Failing function found">
                <WarningTriangle class="icon error" width="16" height="16" stroke-width="2" color="#ffcb30" v-if="failing" />
            </div>
            <div class="icon-button muted small icon iconoir-copy" @click.stop="$emit('duplicate', rule)" tooltip="Duplicate"></div>
            <div class="icon-button muted small red icon iconoir-trash" @click.stop="$emit('remove', rule)"></div>
        </div>

        <div class="props" v-if="expanded">
            <div class="condition flex flex-align">
                <h3>If</h3>
                <input class="csv-function" v-model="rule_copy.condition">
            </div>
            <div class="condition flex flex-align">
                <h3>For</h3>
                <input class="csv-function" placeholder="key" style="max-width: 150px; margin-right: 10px;" v-model="rule_copy.lambda.key">
                <h3>In</h3>
                <input class="csv-function" placeholder="values" v-model="rule_copy.lambda.function">
            </div>
            <div class="rule-metadata flex flex-align" v-for="prop in properties[rule_copy.entity_type]" :set="value = rule_copy.properties[prop]">
                <div class="merge icon iconoir-git-merge" :class="{ active: value && value.strategy === 'merge' }" @click="toggleMerge(prop)"></div>
                <div class="edlink-prop monospace">{{ prop }}</div>
                <div class="dash">=</div>
                <input class="csv-function" :class="{ failing: isFailing(`properties.${prop}`) }" @input="(e) => updateFunction(prop, e.target.value)" :value="value ? value.function : ''">
            </div>
            <div class="rule-metadata custom-property flex flex-align" v-for="prop in rule_copy.custom_properties">
                <div class="merge icon iconoir-git-merge" :class="{ active: prop[0] === 'merge' }" @click="toggleCustomMerge(prop)"></div>
                <div class="edlink-prop monospace">properties.custom.<input v-model="prop[1]"></div>
                <div class="dash">=</div>
                <input class="csv-function" :class="{ failing: isFailing(`properties.properties.custom.${prop[1]}`) }" v-model="prop[2]">
                <div class="delete icon iconoir-trash" @click="deleteCustomProp(prop)"></div>
            </div>
            <div class="rule-metadata identifier flex flex-align" v-for="(identifier, $index) of rule_copy.identifiers">
                <div class="edlink-prop monospace">identifiers.<input v-model="identifier.type"></div>
                <div class="dash">=</div>
                <input class="csv-function" :class="{ failing: isFailing(`identifiers[${$index}]`) }" v-model="identifier.value">
                <div class="delete icon iconoir-trash" @click="deleteIdentifier(identifier)"></div>
            </div>
            <div class="add-custom-prop" @click="addIdentifier">
                <svg class="block" width="14px" height="14px" stroke-width="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M6 12h6m6 0h-6m0 0V6m0 6v6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <div>
                    Add identifier
                </div>
            </div>
            <div class="add-custom-prop" @click="addCustomProp">
                <svg class="block" width="14px" height="14px" stroke-width="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M6 12h6m6 0h-6m0 0V6m0 6v6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <div>
                    Add custom property
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Vue from 'vue';
    import { EditPencil, WarningTriangle } from '@epiphany/iconoir';

    export default {
        name: 'CSVFormattingRule',
        props: {
            rule: Object,
            csv_headers: Array,
            failing: {
                type: Boolean,
                default: false
            }
        },
        components: {
            EditPencil,
            WarningTriangle
        },
        data(){
            return {
                expanded: false,
                editable: false,
                rule_copy: null,
                properties: {
                    districts: ['name', 'picture_url', 'locale', 'time_zone', 'address.street', 'address.unit', 'address.postal_code', 'address.city', 'address.state', 'address.country', 'address.phone', 'address.latitude', 'address.longitude'],
                    schools: ['external_id', 'name', 'picture_url', 'locale', 'time_zone', 'grade_levels', 'address.street', 'address.unit', 'address.postal_code', 'address.city', 'address.state', 'address.country', 'address.phone', 'address.latitude', 'address.longitude'],
                    sessions: ['external_id', 'name', 'type', 'state', 'start_date', 'end_date'],
                    courses: ['external_id', 'name', 'code', 'subject_external_id', 'grade_levels'],
                    classes: ['external_id', 'name', 'description', 'picture_url', 'locale', 'time_zone', 'grade_levels', 'periods', 'state', 'subjects', 'period_external_ids', 'session_external_ids', 'course_external_id', 'subject_external_id', 'school_external_id'],
                    sections: ['external_id', 'name', 'picture_url', 'locale', 'time_zone', 'state', 'description', 'periods', 'class_external_id'],
                    people: ['external_id', 'first_name', 'middle_name', 'last_name', 'display_name', 'picture_url', 'roles', 'email', 'phone', 'locale', 'time_zone', 'graduation_year', 'grade_levels', 'address.street', 'address.unit', 'address.postal_code', 'address.city', 'address.state', 'address.country', 'address.phone', 'address.latitude', 'address.longitude', 'demographics.birthday', 'demographics.gender', 'demographics.residence_status', 'demographics.english_language_learner', 'demographics.country_of_birth', 'demographics.state_of_birth', 'demographics.city_of_birth', 'demographics.hispanic_or_latino_ethnicity', 'demographics.races', 'demographics.homeless', 'demographics.disability', 'demographics.gifted_talented', 'demographics.food_service_program_eligibility', 'demographics.economically_disadvantaged', 'demographics.migrant', 'demographics.public_assistance', 'demographics.rural_residency', 'demographics.individualized_education_plan', 'demographics.primary_language', 'demographics.special_accommodations', 'demographics.military_connected', 'department_external_ids', 'school_external_ids', 'password'],
                    enrollments: ['external_id', 'state', 'role', 'start_date', 'end_date', 'primary', 'person_external_id', 'class_external_id', 'section_external_id'],
                    agents: ['external_id', 'observer_external_id', 'target_external_id', 'relationship', 'flags'],
                    meetings: ['external_id', 'class_external_id', 'day_external_id', 'period_external_id'],
                    attendance: ['external_id', 'state', 'flags', 'comments', 'person_external_id', 'meeting_external_id'],
                    departments: ['external_id', 'name', 'description', 'school_external_ids'],
                    subjects: ['external_id', 'name', 'code', 'sceds_code', 'description', 'department_external_id'],
                    periods: ['external_id', 'name', 'description', 'schedule_expression', 'start_time', 'end_time', 'type', 'flags', 'school_external_ids', 'session_external_ids'],
                    incidents: ['external_id', 'title', 'description', 'updates', 'date', 'cost', 'person_external_ids', 'reporter_external_id', 'school_external_id'],
                    facilities: ['external_id', 'name', 'description', 'type', 'flags', 'address.street', 'address.unit', 'address.postal_code', 'address.city', 'address.state', 'address.country', 'address.phone', 'address.latitude', 'address.longitude', 'school_external_ids', 'department_external_ids'],
                    rooms: ['external_id', 'number', 'name', 'description', 'capacity', 'category', 'flags', 'facility_external_id', 'department_external_ids'],
                    assets: ['external_id', 'name', 'description', 'make', 'model', 'manufacturer', 'serial_number', 'purchase_date', 'expiration_date', 'cost', 'currency', 'invoice_number', 'life_span_in_days', 'comments', 'priority', 'category', 'flags', 'school_external_ids', 'department_external_ids', 'facility_external_ids', 'subject_external_ids', 'course_external_ids', 'session_external_ids', 'person_external_ids'],
                    fees: ['external_id', 'amount', 'balance', 'currency', 'amount_charged', 'amount_paid', 'original_amount', 'adjustment_amount', 'prorated', 'payment_schedule', 'comment', 'description', 'creation_date', 'charged_date', 'due_date', 'priority', 'category', 'type', 'flags', 'school_external_ids', 'department_external_ids', 'subject_external_ids', 'course_external_ids', 'session_external_ids', 'person_external_ids'],
                    stops: ['external_id', 'name', 'description', 'comments', 'type', 'flags', 'address.street', 'address.unit', 'address.postal_code', 'address.city', 'address.state', 'address.country', 'address.phone', 'address.latitude', 'address.longitude'],
                    routes: ['external_id', 'code', 'description', 'state', 'schedule_expressions', 'schedule', 'mileage', 'flags', 'stop_external_ids', 'vehicle_external_id', 'driver_external_id'],
                    vehicles: ['external_id', 'name', 'type', 'state', 'capacity', 'fuel_type', 'code', 'flags', 'comments', 'vehicle_identification_number', 'license_number', 'last_operation_date', 'last_safety_inspection_date', 'safety_inspection_state', 'asset_external_id'],
                    calendars: ['external_id', 'name', 'code', 'start_date', 'end_date', 'state', 'flags', 'type', 'school_external_ids'],
                    days: ['external_id', 'date', 'start_time', 'end_time', 'flags', 'comments', 'calendar_external_id']
                }
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            integration(){
                return this.$store.getters.integration;
            },
            blink_session() {
                return this.$store.state.blink.session;
            },
            reloading() {
                return this.$store.state.blink.reloading;
            },
            blink() {
                return this.$store.state.blink;
            },
            transformation() {
                return this.blink.transformation;
            },
            trace() {
                const debug = this.blink.debug[this.transformation.id];
                
                if (debug?.extra?.trace) {
                    return debug.extra.trace;
                } else {
                    return null;
                }
            }
        },
        mounted(){
            Vue.set(this, 'rule_copy', _.cloneDeep(this.rule));
        
            // Get all the custom properties
            Vue.set(this.rule_copy, 'custom_properties', []);

            if (!this.rule_copy.identifiers) {
                Vue.set(this.rule_copy, 'identifiers', []);
            }

            if (!this.rule_copy.lambda) {
                Vue.set(this.rule_copy, 'lambda', {
                    key: null,
                    function: null
                });
            }

            // Deconstruct custom properties
            for (const prop in this.rule_copy.properties) {
                if (prop.startsWith('properties.custom')) {
                    const mapping = this.rule_copy.properties[prop];
                    this.rule_copy.custom_properties.push([mapping.strategy, prop.slice(18), mapping.function]);
                }
            }

            this.$watch('rule_copy', () => {
                const rule = _.cloneDeep(this.rule_copy);
                const new_rule = {
                    name: rule.name,
                    entity_type: rule.entity_type,
                    condition: rule.condition,
                    properties: rule.properties,
                    identifiers: rule.identifiers,
                    lambda: rule.lambda,
                };

                if (new_rule.lambda.key === '') {
                    new_rule.lambda.key = null;
                }

                if (new_rule.lambda.function === '') {
                    new_rule.lambda.function = null;
                }
                
                // Add custom properties 
                for (const prop of rule.custom_properties) {
                    new_rule.properties[`properties.custom.${prop[1]}`] = {
                        strategy: prop[0],
                        function: prop[2]
                    };
                }

                this.$emit('update:rule', new_rule);
                this.$emit('input', new_rule);
            }, { deep: true });
        },
        methods: {
            isFailing(prop){
                if (this.failing) {
                    return this.trace.split('.').slice(1).join('.') === prop;
                } else {
                    return false;
                }
            },
            toggleEdit(value = !this.editable){
                this.editable = value;
                if (value) {
                    this.$nextTick(() => {
                        this.$refs['rule-name']?.focus?.();
                    })
                }
            },
            updateFunction(prop, value){
                if (!this.rule_copy.properties[prop]) {
                    Vue.set(this.rule_copy.properties, prop, { strategy: 'overwrite' });
                }
                // Set function
                Vue.set(this.rule_copy.properties[prop], 'function', value);
            },
            addCustomProp(){
                this.rule_copy.custom_properties.push(['overwrite', `${this.rule_copy.custom_properties.length}`, null]);
            },
            deleteCustomProp(prop){
                const index = this.rule_copy.custom_properties.indexOf(prop);
                this.rule_copy.custom_properties.splice(index, 1);
            },
            addIdentifier(){
                this.rule_copy.identifiers.push({
                    type: null,
                    value: null
                })
            },
            deleteIdentifier(identifier){
                const index = this.rule_copy.identifiers.indexOf(identifier);
                this.rule_copy.identifiers.splice(index, 1);
            },
            toggleMerge(prop){
                if (this.rule_copy.properties[prop].strategy === 'merge') {
                    Vue.set(this.rule_copy.properties[prop], 'strategy', 'overwrite');
                } else {
                    Vue.set(this.rule_copy.properties[prop], 'strategy', 'merge');
                }
            },
            toggleCustomMerge(prop){
                const index = this.rule_copy.custom_properties.indexOf(prop);
                if (prop[0] === 'merge') {
                    Vue.set(this.rule_copy.custom_properties[index], 0, 'overwrite');
                } else {
                    Vue.set(this.rule_copy.custom_properties[index], 0, 'merge');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .icon.error {
        margin: 0 2px;
    }

    .caret
    {
        width: 18px;
        height: 18px;
        background-size: contain;
        background-image: url('~@/assets/icons/grey/caret-down.svg');

        &.closed {
            transform: rotate(-90deg)
        }
    }

    .rule {
        width: 100%;
        color: @black;
        border-radius: 6px;
        border: 1px solid @e4;
        margin-bottom: 25px;
        padding: 10px 15px;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     top: 10px;
        //     bottom: 20px;
        //     left: @rule-padding + 4px;
        //     width: 1px;
        //     border-left: 1px dashed @c;
        //     z-index: 0;
        // }
    }

    .rule-header {
        padding: 0;
        z-index: 5;
        height: 40px;
        
        .edit-icon {
            color: @lightgrey;
            margin-left: 5px;
            opacity: 0;
        }

        input {
            height: 20px;
            font-size: 16px;
            font-weight: 500;
            color: @black;
            background-color: transparent;
            padding: 0;
            margin: 0 0 0 10px;
            border: none;
            background: transparent;
            outline: none;
            box-shadow: none;
            width: 90%;
        }

        h3 {
            // line-height: 36px;
            // height: 36px;
            margin-left: 10px;
        }

        &:hover {
            .edit-icon {
                opacity: 1;
            }
        }
    }

    .props {
        margin-top: 10px;
    }

    .condition {
        h3 {
            margin: 0;
            margin-right: 10px;
        }

        margin-bottom: 20px;
    }

    .csv-function {
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        // color: @grey;
        // background-color: @f4;
        border-radius: 4px;
        padding: 0 4px;
        font-family: @monospace;

        &.failing {
            border-color: @red;
            background-color: fade(@red, 10%);
        }
    }

    .add-custom-prop {
        margin-top: 10px;
        color: @blue;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .rule-metadata {
        margin-bottom: 5px;

        &.identifier {
            margin-left: 27px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .merge, .delete {
        color: @grey;
        margin: 0 7px;
        text-transform: capitalize;
        font-size: 12px;
        color: @grey;
        font-weight: 500;
        border-radius: 100%;
        padding: 4px;
        cursor: pointer;

        &:hover, &.active {
            color: @base;
            background: fade(@base, 10%);
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .delete {
        &:hover, &.active {
            color: @red;
            background: fade(@red, 10%);
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .dash {
        color: @grey;
        margin: 0 10px;
        text-transform: capitalize;
        font-size: 12px;
        color: @grey;
        font-weight: 500;

        &:first-child {
            margin-left: 0;
        }
    }

    select {
        width: fit-content;
        padding: 5px;
        height: 25px;
        line-height: 12px;
        font-size: 12px;
        padding-right: 20px;
        background-position: right 3px top 4px;
        background-size: 16px auto;
    }

    .edlink-prop {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        color: @grey;
        background-color: @f4;
        border-radius: 4px;
        padding: 0 4px;

        display: flex;
        align-items: center;

        input {
            background: none;
            font-size: 12px;
            font-weight: 500;
            color: @grey;
            padding: 0;
            margin: 0;
            min-width: 100px;
            height: 100%;
            line-height: 20px;
            font-family: @monospace;
        }

    }

    .icon-button
    {
        // font-size: 28px;
        color: @base;
        cursor: pointer;
        transition: color linear 0.1s;
        margin-left: 5px;

        &.small
        {
            font-size: 20px;
        }

        &.red
        {
            color: @red;

            &:hover
            {
                color: fade(@red, 70%);
            }
        }

        &:hover
        {
            color: fade(@base, 70%);
        }

        &.muted
        {
            color: @c;
            transition: color linear 0.1s;
            &:hover
            {
                color: @base
            }
        }

        &.red
        {
            &:hover
            {
                color: @red
            }
        }
    }
</style>
