<template>
    <div class="upload" :class="{disabled, error: error || (type === 'image' && !valid_image), over}" @dragover.prevent="dragover" @drop.prevent="drop">
        <div class="upload-progress" ref="progress" :class="{uploading, complete: url, progress}"></div>
        <div class="select-file flex flex-align flex-center" v-if="!url">
            <div v-if="!progress">
                Drag &amp; Drop or <a class="text-button" @click="choose">Select a File</a>
            </div>
            <spinner v-if="progress" />
            <div class="hidden-file-input">
                <input class="file-input" accept="*/*" type="file" @change="choose" ref="input" />
            </div>
        </div>
        <div class="file-selected flex flex-align" v-if="url">
            <template v-if="type === 'image'">
                <div class="upload-thumbnail-container flex flex-align flex-center">
                    <img v-if="valid_image" class="upload-thumbnail" :src="thumbnail" />
                    <WarningTriangle v-else class="icon" width="16" height="16" stroke-width="2" color="#606066" />
                </div>
            </template>
            <div class="ff">
                <div class="upload-title">Uploaded File</div>
                <div class="upload-status text-overflow">{{url}}</div>
            </div>
            <a class="text-button red" @click="remove">Remove</a>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { WarningTriangle } from '@epiphany/iconoir';

    export default {
        name: 'Upload',
        components: {
            WarningTriangle
        },
        props: {
            error: Boolean,
            disabled: Boolean,
            convert: Boolean,
            url: String,
            type: String
        },
        computed: {
            status(){
                if(this.uploading){
                    return 'Uploading';
                }else{
                    return 'Done';
                }
            },
            thumbnail(){
                return this.url;
            }
        },
        data(){
            return {
                uploading: false,
                progress: false,
                over: false,
                valid_image: true,
            };
        },
        mounted(){
            if(this.url && this.type === 'image'){
                this.validateImage(this.url).then(valid => this.valid_image = valid);
            }
        },
        methods: {
            remove(){
                this.$emit('update:url', '');
                this.$emit('input', '');
            },
            choose(){
                if(this.$refs.input.files.length){
                    this.upload(this.$refs.input.files[0]);
                }
            },
            dragover($event){
                $event.stopPropagation();

                if(this.timeout){
                    clearTimeout(this.timeout);
                }

                this.over = true;

                this.timeout = setTimeout(() => {
                    this.over = false;
                    this.timeout = null;
                }, 100);
            },
            drop($event){
                $event.stopPropagation();

                const files = [];

                for(const file of $event.dataTransfer.files){
                    if(file.size){
                        files.push(file);
                    }
                }

                if(files.length){
                    this.upload(files[0]);
                }
            },
            async validateImage(url) {
                if (!url) {
                    return false;
                }

                const img = new Image();
                img.src = url;

                return new Promise((resolve, reject) => {
                    img.onload = () => resolve(true);
                    img.onerror = () => resolve(false);
                });
            },
            upload(file){
                this.progress = true;
                this.uploading = true;
                this.valid_image = true;

                const form = new FormData();

                form.append('filename', file.name);
                form.append('file', file);

                if (this.type === 'image'){
                    form.append('access', 'public');
                }

                // const url = process.env.NODE_ENV === 'production' ? 'https://upload.ed.link/upload' : 'http://localhost:3000/upload';
                const url = 'https://upload.ed.link/upload';

                axios.post(url, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (this.type === 'image') {
                        this.validateImage(response.$data).then(valid => {
                            this.valid_image = valid;
                            this.uploading = false;
                        });
                    } else {
                        this.uploading = false;
                    }

                    this.$emit('update:url', response.$data);
                    this.$emit('input', response.$data);


                    setTimeout(() => this.progress = false, 1000);
                })
                .catch(error => {
                    this.progress = false;
                    setTimeout(() => this.uploading = false, 500);
                    console.log(error);
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .upload
    {
        border: 1px solid @d;
        border-radius: 3px;
        overflow: hidden;
        max-width: 325px;
        width: 100%;

        &.error
        {
            border-color: @red;
        }

        &.over
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;
        }
    }

    .upload-progress
    {
        height: 2px;
        background: @base;
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        transition: all ease 0.3s;
        opacity: 0;
        pointer-events: none;

        &.uploading
        {
            width: 50%;
        }

        &.complete
        {
            width: 100%;
        }

        &.progress
        {
            opacity: 1;
        }
    }

    .select-file
    {
        height: 68px;
        padding: 20px;
        color: @darkgrey;
        font-size: 14px;
        font-weight: 600;
    }

    .file-selected
    {
        padding: 15px;
    }

    .hidden-file-input
    {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        bottom: 0;
        right: 0;
        overflow: hidden;
        opacity: 0.01;

        .file-input
        {
            cursor: pointer;
            width: 999px;
            height: 999px;
            line-height: 999px;
            font-size: 999px;
        }
    }

    .upload-thumbnail-container
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: @e;
        margin-right: 15px;

        &::after
        {
            .absolute(0, 0, 0, 0);
            content: "";
            z-index: 2;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 50%;
        }
    }

    .upload-thumbnail
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }

    .upload-title
    {
        line-height: 18px;
        font-size: 14px;
        color: @black;
        font-weight: 500;
        margin-bottom: 4px;
    }

    .upload-status
    {
        font-size: 13px;
        line-height: 16px;
        color: @grey;
    }

    .text-button.red
    {
        margin-left: 15px;
    }
</style>
