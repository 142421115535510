<template>
    <integrate-source-editor-step name="Validate Your Configuration" icon="iconoir-group" class="validation" :complete="!!source.id">
        <div class="helptext">Edlink will try to automatically validate the information you entered.</div>
        <div class="validation-results card" v-if="validation.length">
            <div class="validation-results-header">
                Validation Results
            </div>
            <div class="validation-results-list">
                <template v-if="validation.length">
                    <div class="validation-step flex flex-align" v-for="(step, index) of validation" :key="index">
                        <span class="icon block" :class="{'iconoir-info-empty': step.state === 'info', 'iconoir-warning-triangle-outline': step.state === 'warning', 'iconoir-delete-circled-outline': step.state === 'failed', 'iconoir-check-circled-outline': step.state === 'passed'}"></span>
                        <div class="ff">
                            <div class="validation-description">{{step.description}}</div>
                            <div class="validation-message" v-if="step.message">{{step.message}}</div>
                        </div>
                    </div>
                </template>
                <div v-else class="validation-step flex flex-align">
                    <div class="ff">
                        <div class="validation-description">Click the button below to validate.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="options flex flex-align">
            <div :class="{disabled}" class="button" @click="validate" v-if="!validated || errors">Validate Configuration</div>
            <div class="button" @click="next" v-else>Continue Setup</div>
            <spinner v-if="validating" />
            <div v-else-if="validated && errors" class="button white" @click="next">Continue Anyway</div>
        </div>
    </integrate-source-editor-step>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';

    export default {
        name: 'IntegrateSourceEditorValidate',
        props: {
            state: Object,
            send: Function,
            nextStep: String,
            disabled: Boolean,
            type: String
        },
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep
        },
        data(){
            return {
                validation: [],
                validating: false
            };
        },
        mounted(){

        },
        destroyed(){

        },
        computed: {
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            team(){
                return this.state.context.team;
            },
            provider(){
                return this.type === 'destination' ? this.state.context.destination_provider : this.state.context.provider;
            },
            application(){
                return this.state.context.application;
            },
            validated(){
                return this.source.id ? true : false;
            },
            errors(){
                if(this.validation){
                    return this.validation.find(v => v.state === 'failed');
                }
            }
        },
        methods: {
            async validate(){
                this.validating = true;

                try {
                    if(this.source.id) {
                        // If this source has an ID, update it and revalidate.
                        await this.$http.put(`/teams/${this.team.id}/sources/${this.source.id}`, this.source);
                    } else {
                        // If this source does not have an ID, create the source.
                        const { $data } = await this.$http.post(`/teams/${this.team.id}/sources`, Object.assign({
                            application_id: this.application.id,
                            name: this.provider.name,
                            import_interval: 86400
                        }, this.source));

                        await this.send('UPDATE_SOURCE', { path: 'id', value: $data.id });
                        await this.send('UPDATE_SOURCE', { path: 'name', value: $data.name });
                        await this.send('UPDATE_SOURCE', { path: 'provider', value: $data.provider });
                        await this.send('UPDATE_SOURCE', { path: 'region', value: $data.region });
                        if (this.source.token) {
                            await this.send('UPDATE_SOURCE', { path: 'token', value: null });
                        }
                    }
                } catch (error) {
                    console.log(error)
                    this.$toasted.error('There was an error updating the source.');
                    this.validating = false;

                    // Don't bother trying to validate, the source wasn't created/updated.
                    return;
                }

                try {
                    // After creating/updating the source, run validation.
                    const { $data } = await this.$http.get(`/teams/${this.team.id}/sources/${this.source.id}/validate`, {
                        headers: {
                            authorization: `Bearer ${this.$store.state.user.token}`
                        },
                        baseURL: '/api/v2'
                    });

                    this.validation = $data.steps;

                    // Set the loading state back to `false`.
                    this.validating = false;
                } catch(error) {
                    console.log(error)
                    this.$toasted.error('There was an error validating the source.')
                    this.validating = false;
                }
            },
            next(){
                if (this.nextStep === 'integration') {
                    this.send('READY_FOR_INTEGRATION', {
                        data: {
                            id: null,
                            start_date: null,
                            permissions: this.application.permissions.slice(),
                            scope: 'all',
                            application_id: this.state.context.application.id,
                            source_id: this.state.context.source.id,
                            destination_id: this.state.context.destination_source?.id,
                            target_id: null,
                            targets: []
                        }
                    });
                } else if (this.nextStep === 'destination_source') {
                    this.send('DESTINATION_SOURCE');
                } else if (this.nextStep === 'custom_admin') {
                    this.send('NEXT');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    .options
    {
        margin-top: 15px;

        .button
        {
            margin-right: 15px;
        }
    }

    .validation-step
    {
        margin-bottom: 10px;

        &:last-child
        {
            margin-bottom: 0;
        }

        .icon
        {
            margin-right: 8px;
            font-size: 18px;
            height: 18px;
            width: 18px;
            color: @black;
        }

        .iconoir-warning-triangle-outline
        {
            color: @yellow;
        }

        .iconoir-delete-circled-outline
        {
            color: @red;
        }

        .iconoir-check-circled-outline
        {
            color: @green;
        }

        .validation-message
        {
            color: @grey;
            font-size: 12px;
            line-height: 16px;
            margin-top: 2px;
        }

        .validation-description
        {
            color: @black;
            font-size: 13px;
            line-height: 16px;
        }
    }

    .validation-results-list
    {
        padding-top: 10px;
    }

    .validation-results
    {
        width: 100%;
        max-width: 325px;
        padding: 10px;

        .validation-results-header
        {
            font-size: 12px;
            color: @grey;
            padding-bottom: 8px;
            border-bottom: 1px solid @e4;
        }
    }
</style>
