<template>
    <div class="class-list" v-if="found_assignment">
        <div class="assignment-info">
            <h3>{{found_assignment.title}}</h3>
            <p v-if="found_assignment.due_date">Due: {{found_assignment.due_date | pretty('short')}}</p>
            <p v-else>Posted: {{found_assignment.created_date | pretty('short')}}</p>
            <br />
            <div v-html="found_assignment.description"></div>
        </div>
        <div v-if="student">
            <h2>Answer Some Questions</h2>
            <div class="form-field">
                <label>What is 9 + 10?</label>
                <input v-model="answer" type="text" />
            </div>
            <button @click="submit(answer)" id="submit">Submit</button>
        </div>
        <div v-else-if="teacher">
            <h2>Submissions</h2>
            <router-link :to="{name: 'demo.class.assignment.submission', params: { submission: submission.id }}" tag="div" class="card submission" v-for="submission in submissions" :key="submission.id">
                <div>
                    <h3>{{submission.person.display_name}}</h3>
                    <p v-if="submission.updated_date">Last Updated {{submission.updated_date | pretty('short')}}</p>
                    <p class="status">{{submission.state}}</p>
                </div>
                <div class="grade">
                    <label>Grade</label>
                    <div class="flex flex-align">
                        <input v-model="submission.grade_points" type="number" />
                        <button @click="grade(submission.id, submission.grade_points)" id="grade">Submit</button>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <div v-else class="loading">
        <spinner />
    </div>
</template>

<script>
const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';

export default {
    name: 'DemoAssignment',
    props: {
        edlink_widget: Object,
        profile: Object,
        token_set: Object,
        lti_launch: Boolean
    },
    data() {
        return {
            found_assignment: null,
            answer: '',
            submission_grade: null,
            submissions: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state?.user.admin;
        },
        integration() {
            return this.$store.state.integrations.active;
        },
        student() {
            return this.profile.roles.includes('student');
        },
        teacher() {
            return this.profile.roles.includes('teacher');
        }
    },
    async mounted() {
        this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}`).then(res => {
            this.found_assignment = res.$data;
        });
        if (this.teacher) {
            this.fetch_submissions().then(submissions => this.submissions = submissions);
        }
    },
    methods: {
        submit(answer) {
            this.$demo.post(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submit`, {
                attachments: [
                    {
                        type: "text",
                        text: answer
                    }
                ]
            }).then(graded_submission => {
                console.log(graded_submission)
                this.$toasted.success('Successfully submitted');
            }).catch(() => {
                this.$toasted.error('Error submitting')
            });
        },
        grade(submission_id, grade_points) {
            this.$demo.patch(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submissions/${submission_id}`, {
                grade_points: parseInt(grade_points)
            }).then(graded_submission => {
                console.log(graded_submission)
                this.$toasted.success('Successfully graded');
                this.$demo.post(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submissions/${submission_id}/return`).then(() => {
                    this.$toasted.success('Successfully returned');
                }).catch((error) => {
                    const unsupported = error.response.data.$errors.find(it => it.code === 'UNSUPPORTED_OPERATION');
                    if (unsupported) {
                        this.$toasted.info(`Provider does not require submissions to be returned`)
                    } else {
                        this.$toasted.error('Error returning submission')
                    }
                })
            }).catch(() => {
                this.$toasted.error('Error grading submission')
            });
        },
        fetch_submissions() {
            return this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submissions`, {
                params: {
                    $expand: 'person'
                }
            }).then(res => {
                return res.$data;
            });
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

h2 {
    margin-bottom: 15px;
}

.class-list {
    width: 100%;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    // grid-template-rows: repeat(300px);
    grid-gap: 25px;
}

.submission {
    padding: 10px 15px;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .status {
        text-transform: capitalize;
    }

    .grade {
        display: flex;
        flex-direction: column;
    }
    
    &:hover {
        h3 {
            cursor: pointer;
            color: @demo-base;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.demo {
    min-height: 100vh;
}

strong {
    color: @black;
}

#submit {
    margin-top: 15px;
}

#grade {
    margin-left: 10px;
}

// Demo Reset
button {
    background: @demo-base;
    border-color: darken(@demo-base, 10%);

    &:hover {
        background: darken(@demo-base, 10%);   
    }
}

input {
    background: @white;

    &:focus, &:focus-within {
        border-color: @demo-base;
        box-shadow: 0 0 0 3px fade(@demo-base, 30%);
    }
}
</style>
