<template>
    <div class="integrate-source-provider-lti">
        <integrate-source-editor-step name="Select Your LMS" icon="iconoir-select-window" :complete="completed.includes('lms')">
            <div class="helptext">
                Please select your LMS, or choose `other` if you do not see it on the list.
            </div>
            <div class="flex lms-options flex-wrap">
                <div class="card integrate-radio flex flex-align" :class="{selected: source.configuration.lms === lms}" v-for="lms of options" :key="lms" @click="select_lms(lms)">
                    <div class="thumbnail lms-icon">
                        <img :src="lms === 'other' ? `/source/default.png` : `/source/${lms}.png`" />
                    </div>
                    <div class="ff">{{lms === 'other' ? 'Other' : names[lms]}}</div>
                </div>
            </div>
        </integrate-source-editor-step>
        <template v-if="!source.configuration.lms">
            <integrate-source-editor-step :loading="true" />
        </template>
        <template v-else>
            <integrate-source-editor-step :name="`Enter Your ${names[source.configuration.lms]} URL`" icon="iconoir-www" :complete="completed.includes('url')">
                <div class="helptext">
                    This is the URL where your teachers and students go to access {{names[source.configuration.lms]}}.
                </div>
                <input class="medium" type="text" placeholder="https://example.com" :value="source.configuration.url" @input="update_lms_url" />
            </integrate-source-editor-step>
            <integrate-source-editor-step :name="`Configure the LTI Application in ${names[source.configuration.lms]}`" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
                <!-- <div class="helptext">
                    For specific instructions on how to do this, please click <a class="configuration-link inline-block text-button" target="_blank">here</a>.
                </div> -->
                <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-select-window">
                    <label>Open Settings Page</label>
                    <div class="flex">
                        <a v-if="source.configuration.lms === 'canvas'" class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/accounts/1/developer_keys'">
                            Open Canvas Developer Keys
                        </a>
                        <div v-else class="configuration-value">Varies By LMS</div>
                    </div>
                    <label>LTI Configuration JSON</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => `https://ed.link/api/authentication/lti/${application.id}`" v-clipboard:success="() => $toasted.info('Copied')">
                            https://ed.link/api/authentication/lti/{{application.id}}
                        </div>
                    </div>
                    <label>Keyset URL</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => `https://ed.link/api/authentication/lti/${application.id}/jwks`" v-clipboard:success="() => $toasted.info('Copied')">
                            https://ed.link/api/authentication/lti/{{application.id}}/jwks
                        </div>
                    </div>
                    <template v-if="source.configuration.lms === 'canvas'">
                        <label>Key State</label>
                        <div class="flex">
                            <div class="configuration-value">
                                Set the key state to <span class="badge">On</span>.
                            </div>
                        </div>
                    </template>
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Mark as Complete" :checked.sync="remote" />
                </div>
            </integrate-source-editor-step>
            <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('configuration')">
                <label class="key-label">Client ID</label>
                <div class="helptext">This is an ID generated by your LMS after you install the LTI 1.3 application.</div>
                <input class="large client-secret" type="text" placeholder="Client ID" :value="source.configuration.client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_id', value: e.target.value})" />
                <label class="key-label">Keyset URL</label>
                <div class="helptext">The JWT keyset URL for your LMS provider.</div>
                <input :disabled="disabled[source.configuration.lms].includes('keyset_url')" class="large client-secret" type="text" placeholder="Keyset URL" :value="source.configuration.keyset_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: e.target.value})" />
                <label class="key-label">OIDC Authentication URL</label>
                <div class="helptext">The OIDC Authentication URL for your LMS provider.</div>
                <input :disabled="disabled[source.configuration.lms].includes('oidc_authentication_endpoint')" class="large client-secret" type="text" placeholder="OIDC Authentication URL" :value="source.configuration.oidc_authentication_endpoint" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: e.target.value})" />
                <label class="key-label">Access Token URL</label>
                <div class="helptext">The access token URL for your LMS provider.</div>
                <input :disabled="disabled[source.configuration.lms].includes('access_token_url')" class="large client-secret" type="text" placeholder="Access Token URL" :value="source.configuration.access_token_url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: e.target.value})" />
                <label class="key-label">Audience</label>
                <div class="helptext">The OAuth 2.0 Audience Value.</div>
                <input :disabled="disabled[source.configuration.lms].includes('audience')" class="large client-secret" type="text" placeholder="Audience" :value="source.configuration.audience" @input="e => send('UPDATE_SOURCE', {path: 'configuration.audience', value: e.target.value})" />
                <label class="key-label">Issuer</label>
                <div class="helptext">This is an optional field if you have a non-standard LMS.</div>
                <input :disabled="disabled[source.configuration.lms].includes('issuer')" class="large client-secret" type="text" placeholder="Issuer" :value="source.configuration.issuer" @input="e => send('UPDATE_SOURCE', {path: 'configuration.issuer', value: e.target.value})" />
                <label class="key-label">Deployment ID</label>
                <div class="helptext">Use this field if you are adding multiple deployments with the same client ID.</div>
                <input class="large client-secret" type="text" placeholder="Deployment ID" :value="source.configuration.deployment_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.deployment_id', value: e.target.value})" />
            </integrate-source-editor-step>
        </template>
    </div>
</template>

<script>
    import join from 'url-join';
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

    export default {
        name: 'IntegrateSourceProviderOneRoster',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            application(){
                return this.state.context.application;
            },
            completed(){
                // We are going to calculate which steps are completed.

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the LMS selected.
                if(!this.source.configuration.lms){
                    return [];
                }

                // 2. Validate the URL.
                if(!this.source.configuration.url?.match(url)){
                    return ['lms'];
                }

                // 3. Remote done?
                if(!this.remote){
                    return ['lms', 'url'];
                }

                // 4. Validate that the correct config options are set.
                const required = ['client_id', 'keyset_url', 'oidc_authentication_endpoint', 'access_token_url', 'issuer', 'audience'];

                for(const param of required){
                    if(!this.source.configuration[param]){
                        return ['lms', 'url', 'remote'];
                    }
                }

                return ['lms', 'url', 'remote', 'configuration'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        methods: {
            update_lms_url(e){
                this.send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value});

                if(this.source.configuration.lms && this.source.configuration.lms !== 'other'){
                    this.select_lms(this.source.configuration.lms);
                }
            },
            select_lms(lms){
                this.send('UPDATE_SOURCE', {path: 'configuration.lms', value: lms});

                // Depending on the LMS, we can help fill in some values.
                this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: ''});
                this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: ''});
                this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: ''});
                this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: ''});
                this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: ''});
                
                if(lms === 'canvas'){
                    this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: 'https://canvas.instructure.com'});

                    if(this.source.configuration.url?.match(url)){
                        this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: join(this.source.configuration.url, '/api/lti/security/jwks')});
                        this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: join(this.source.configuration.url, '/api/lti/authorize_redirect')});
                        this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: join(this.source.configuration.url, '/login/oauth2/token')});
                        this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: join(this.source.configuration.url, '/login/oauth2/token')});
                    }
                }else if(lms === 'blackboard'){
                    this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: 'https://developer.blackboard.com/api/v1/management/applications/1d76ac9e-1ca3-443b-8af2-bc57488dd0b1/jwks.json'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: 'https://developer.blackboard.com/api/v1/gateway/oidcauth'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: 'https://developer.blackboard.com/api/v1/gateway/oauth2/jwttoken'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: 'https://blackboard.com'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: 'https://blackboard.com'});
                }else if(lms === 'brightspace'){
                    this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: 'https://auth.brightspace.com/core/connect/token'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: 'https://api.brightspace.com/auth/token'});
                }else if(lms === 'coursera'){
                    this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: 'https://api.coursera.org/api/lti/keyset'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: 'https://api.coursera.org/api/lti/auth/token'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: 'https://www.coursera.org/'});
                }else if(lms === 'moodle'){
                    if(this.source.configuration.url?.match(url)){
                        this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: join(this.source.configuration.url, '/mod/lti/certs.php')});
                        this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: join(this.source.configuration.url, '/mod/lti/auth.php')});
                        this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: join(this.source.configuration.url, '/mod/lti/token.php')});
                        this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: this.source.configuration.url});
                        this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: this.source.configuration.url});
                    }
                }else if(lms === 'schoology'){
                    this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: 'https://lti-service.svc.schoology.com/lti-service/.well-known/jwks'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: 'https://lti-service.svc.schoology.com/lti-service/authorize-redirect'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: 'https://lti-service.svc.schoology.com/lti-service/access-token'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: 'https://lti-service.svc.schoology.com/lti-service/access-token'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: 'https://schoology.schoology.com'});
                }else if(lms === 'vitalsource'){
                    this.send('UPDATE_SOURCE', {path: 'configuration.url', value: 'https://bc.vitalsource.com/books'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.keyset_url', value: 'https://bc.vitalsource.com/ltia/security/jwks'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.oidc_authentication_endpoint', value: 'https://bc.vitalsource.com/api/v4/lti1-3/authenticate'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.access_token_url', value: 'https://bc.vitalsource.com/api/v4/courseware/ltia/tokens'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.audience', value: 'https://bc.vitalsource.com/api/v4/courseware/ltia/tokens'});
                    this.send('UPDATE_SOURCE', {path: 'configuration.issuer', value: 'https://bc.vitalsource.com'});
                }
            }
        },
        data(){
            return {
                remote: false,
                disabled: {
                    canvas: ['keyset_url', 'oidc_authentication_endpoint', 'access_token_url', 'audience', 'issuer'],
                    schoology: ['keyset_url', 'oidc_authentication_endpoint', 'access_token_url', 'audience', 'issuer'],
                    blackboard: ['keyset_url', 'oidc_authentication_endpoint', 'access_token_url', 'audience', 'issuer'],
                    brightspace: ['access_token_url', 'audience'],
                    coursera: ['keyset_url', 'oidc_authentication_endpoint', 'issuer'],
                    moodle: ['keyset_url', 'oidc_authentication_endpoint', 'access_token_url', 'audience', 'issuer'],
                    vitalsource: ['keyset_url', 'oidc_authentication_endpoint', 'access_token_url', 'audience', 'issuer'],
                    other: []
                },
                options: [
                    'canvas',
                    'schoology',
                    'blackboard',
                    'brightspace',
                    'coursera',
                    'moodle',
                    'vitalsource',
                    'other'
                ],
                names: {
                    canvas: 'Canvas',
                    schoology: 'Schoology',
                    blackboard: 'Blackboard',
                    brightspace: 'Brightspace',
                    coursera: 'Coursera',
                    moodle: 'Moodle',
                    vitalsource: 'VitalSource',
                    other: 'LMS'
                }
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 2px;
    }

    .key-label + .helptext
    {
        font-size: 11px;
        margin-bottom: 5px;
    }

    .helptext + input
    {
        margin-top: 10px;
    }

    .lms-options
    {
        margin-top: 15px;
        
        .integrate-radio
        {
            margin-bottom: 15px;
            padding: 8px 10px 8px 8px;

            &:last-child
            {
                margin-bottom: 15px;
            }
        }
    }

    .thumbnail
    {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        box-shadow: 0 0 0 2px @f;
        margin-right: 8px;

        &::before
        {
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        img
        {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: block;
        }
    }

    .badge
    {
        border-radius: 3px;
        padding: 2px 4px;
        color: @black;
        background: fade(@black, 10%);
        font-weight: 400;
    }
</style>
