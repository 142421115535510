<template>
    <div class="custom-admin-request flex flex-column flex-align">
        <h1>Request sent!</h1>
        <div class="helptext">
            <p>
                We have sent an email to the team admin requesting that they connect the existing source to {{ state.context.application.name }}.
                You can now close this window.
            </p>
        </div>
        <div class="options flex" key="email">
            <div class="button" @click="cancel">Close</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomAdminRequest',
        props: {
            state: Object,
            send: Function
        },
        methods: {
            cancel(){
                window.parent.postMessage({ type: 'onboarding.cancel', data: { reason: 'INTEGRATION_REQUEST_SENT' } }, '*');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .custom-admin-request {
        padding: 0 100px;

        h1 {
            margin-bottom: 10px;
            text-align: center;
        }
    }
    
    .helptext {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        text-align: center;
    }

    .options {
        .button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>
