<template>
    <div class="enrichment-details">
        <div class="drawer-section">
            <h4>Details</h4>
            <div class="summary">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Enrichment ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => enrichment.id" v-clipboard:success="() => $toasted.info('Copied')">{{ enrichment.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Source ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => enrichment.source.id" v-clipboard:success="() => $toasted.info('Copied')">{{ enrichment.source.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Created Date</div>
                    <div class="summary-value">{{ enrichment.created_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Updated Date</div>
                    <div class="summary-value">{{ enrichment.updated_date | pretty('long') }}</div>
                </div>
                <h2>Actions</h2>
                <div class="summary-field flex flex-align action" v-if="enrichment.integration">
                    <div class="summary-key">View Integration</div>
                    <div class="summary-value flex flex-align">
                        <div @click="() => external_link({ name: 'team.integrations.integration', params: { team: enrichment.team.alias, integration: enrichment.integration.id } })" class="text-button">Go To Integration</div>
                    </div>
                </div>
                <div class="summary-field flex flex-align action">
                    <div class="summary-key">View Source</div>
                    <div class="summary-value flex flex-align">
                        <div @click="() => external_link({ name: 'team.sources.source', params: { team: enrichment.team.alias, source: enrichment.source.id } })" class="text-button">Go To Source</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EnrichmentDetails',
        data(){
            return {
                properties: [
                    {title: 'Enrichment ID', property: 'id', type: 'string', monospace: true, copyable: true},
                    {title: 'Created Date', property: 'created_date', type: 'date'},
                    {title: 'Updated Date', property: 'updated_date', type: 'date'},
                    {title: 'State', property: 'state', type: 'string'}
                ]
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            enrichment() {
                return this.$store.state.drawer.props;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }
    
    nav
    {
        border-bottom: 1px solid @e4;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }

    .enrichment-details
    {
        h2
        {
            color: @black;
            font-size: 16px;
            padding-top: 20px;
            border-top: 1px solid @e4;
            margin-top: 20px;
            margin-bottom: 20px;

            &:first-child
            {
                margin-top: 0;
                border-top: 0;
                padding-top: 0;
            }
        }

        .summary
        {
            font-size: 13px;

            .summary-field
            {
                height: 22px;
                margin-bottom: 10px;

                &:last-child
                {
                    margin-bottom: 0;
                }
            }

            .summary-key
            {
                color: @grey;
                width: 160px;
                flex-basis: 160px;
                flex-shrink: 0;
            }

            .summary-value
            {
                color: @black;
                line-height: 22px;

                &.monospace
                {
                    line-height: 22px;
                    font-size: 13px;
                }

                .icon-status
                {
                    margin-right: 6px;
                }

                .text-button
                {
                    & .active
                    {
                        color: @base;
                        border-color: @base;
                    }

                }
            }
        }
    }
</style>
