import { render, staticRenderFns } from "./PreviewValueProduct.vue?vue&type=template&id=19ef6bf0"
import script from "./PreviewValueProduct.vue?vue&type=script&lang=js"
export * from "./PreviewValueProduct.vue?vue&type=script&lang=js"
import style0 from "./PreviewValueProduct.vue?vue&type=style&index=0&id=19ef6bf0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports