<template>
    <div class="two-factor-setup">
        <header class="flex flex-align">
            <h2>Setup 2FA</h2>
            <div class="ff"></div>
            <div class="text-button red border" @click="$emit('close')">Cancel</div>
        </header>
        <div class="helptext">
            Scan the QR code below to enable 2FA on your account. We recommend
            <a target="_blank" href="https://authy.com/">Authy</a> or
            <a target="_blank" href="https://www.google.com/authenticator/">Google Authenticator</a>,
            but any RFC 4226 compatible application will work.
            Once you're done, enter the code generated by your application below.
        </div>
        <div class="flex flex-align flex-center">
            <canvas class="qr-code block" ref="qr" />
        </div>
        <input type="text" class="two-factor-input" v-model="code" placeholder="Enter code" />
        <div class="button center" @click="confirm_code">Enable 2FA</div>
    </div>
</template>

<script>
    import QRCode from 'qrcode';

    export default {
        name: 'TwoFactorSetup',
        computed: {
            user(){
                return this.$store.state.user;
            },
            teams(){
                return this.$store.state.teams;
            }
        },
        data(){
            return {
                code: ''
            };
        },
        mounted(){
            this.$http.get('/two_factor')
            .then(response => {
                QRCode.toCanvas(this.$refs.qr, response.$data.url, { errorCorrectionLevel: 'H', width: 220, height: 220 });
            })
            .catch(error => {
                this.$toasted.error('There was an error initializing 2FA.');
            });
        },
        methods: {
            confirm_code(){
                //Check to see if the user has any open invitations
                this.$http.post('/two_factor', { code: this.code })
                .then(() => {
                    this.$toasted.success('2FA was enabled successfully.');
                    this.$emit('close');
                })
                .catch(error => this.$toasted.error('There was an error saving your 2FA configuration. Please check the code.'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .two-factor-setup
    {
        padding: 20px;
    }

    h2
    {
        color: @black;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }

    .helptext
    {
        color: @grey;
        line-height: 18px;
        font-size: 14px;
        margin-top: 10px;

        a
        {
            color: @base;
        }
    }

    .qr-code
    {
        width: 220px;
        height: 220px;
        background: @d;
        margin: 20px 0;
    }

    .two-factor-input
    {
        margin: 0 0 20px;
    }
</style>