<template>
    <div class="entity-matches">
        <div class="drawer-loading flex flex-center" v-if="loading">
            <spinner />
        </div>
        <div class="nothing center" v-else-if="!matches_supported">We do not support displaying {{ flow_application ? 'links' : 'matches' }} for this type.</div>
        <div class="matches" v-else-if="matches.length">
            <div class="card match summary" v-for="match of matches" :key="match.id">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Match ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => match.id" v-clipboard:success="() => $toasted.info('Copied')">{{ match.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Created Date</div>
                    <div class="summary-value">{{ match.created_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Updated Date</div>
                    <div class="summary-value">{{ match.updated_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Primary ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => match.primary_id" v-clipboard:success="() => $toasted.info('Copied')">{{ match.primary_id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Secondary ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => match.secondary_id" v-clipboard:success="() => $toasted.info('Copied')">{{ match.secondary_id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Secondary Source</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => match.id" v-clipboard:success="() => $toasted.info('Copied')">{{ match.secondary_source_id }}</div>
                </div>
            </div>
        </div>
        <div class="matches" v-else-if="links.length && flow_application">
            <h4>Link Details</h4>
            <div class="card match summary" v-for="link of links" :key="links.id">
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Link ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => link.id" v-clipboard:success="() => $toasted.info('Copied')">{{ link.id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Created Date</div>
                    <div class="summary-value">{{ link.created_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Updated Date</div>
                    <div class="summary-value">{{ link.updated_date | pretty('long') }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Primary ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => link.primary_id" v-clipboard:success="() => $toasted.info('Copied')">{{ link.primary_id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Secondary ID</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => link.secondary_id" v-clipboard:success="() => $toasted.info('Copied')">{{ link.secondary_id }}</div>
                </div>
                <div class="summary-field flex flex-align">
                    <div class="summary-key">Secondary Source</div>
                    <div class="summary-value monospace copyable" v-clipboard="() => link.id" v-clipboard:success="() => $toasted.info('Copied')">{{ link.secondary_source_id }}</div>
                </div>
            </div>
            <spinner v-if="secondary_loading" />
            <div v-else-if="secondary_entity">
                <h4 @click="()=>focus(secondary_entity.id)">Linked {{entities[type]}} Details From Secondary Source</h4>
                <!-- <router-link :to="{ name: 'team.sources.source.dataset', params: { team: integration.team.alias, source: integration.destination.id, type: type }, hash: `#${entities[type].toLowerCase()}/${secondary_entity.id}` }" class="text-button flex flex-align">link test</router-link> -->
                <div class="card match summary">
                    <template v-for="column of properties[type]">
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">{{ column.title }} </div>
                            <div v-if="column.copyable" class="copyable noshift summary-value" v-clipboard="() => value" v-clipboard:success="() => $toasted.info('Copied')">{{ secondary_entity[column.property]}}</div>
                            <div v-else-if="column.type === 'date' || column.type === 'timestamp'" class="summary-value">{{secondary_entity[column.property] | pretty('long')}}</div>
                            <div v-else class="summary-value">{{ format_value(secondary_entity[column.property]) }}</div>
                        </div>
                    </template>
                </div>
                <tempate v-if="secondary_entity?.identifiers?.length">
                    <h4>Linked {{entities[type]}} External Identifiers</h4>
                    <div classs="identifiers entity-identifiers">
                        <div v-for="(identifier, $index) of secondary_entity?.identifiers" :key="identifier.key" class="identifier card flex flex-align">
                            <FingerprintLockCircle class="icon" width="20" height="20" stroke-width="1.5" />
                            <div class="identifier-type">{{identifer_type[identifier.type]}}</div>
                            <div class="ff">
                                <div class="copyable identifier-value" v-clipboard="() => identifier.value" v-clipboard:success="() => $toasted.info('Copied')">{{identifier.value}}</div>
                            </div>
                        </div>
                    </div>
                </tempate>
            </div>
        </div>
        <!-- <entity-match v-else-if="links.length" v-for="link of links" :key="link.id" :class="{ 'small-card': !link.expanded }" class="card" @toggle-expand="() => toggleExpand(link)" :expanded="link.expanded" :type="type" :matches="matches" :reloadMatches="getMatches" :link="link" /> -->
        <div class="nothing center" v-else>This entity has no {{ flow_application ? 'links' : 'matches' }}.</div>
    </div>
</template>

<script>
import EntityMatch from '@/components/drawers/entity/EntityMatch.vue';
import EntityValueString from '@/components/drawers/entity/values/EntityValueString.vue';
import EntityCustomProps from '@/components/drawers/entity/values/EntityValueCustomProps.vue';
import EntityField from '@/components/drawers/entity/EntityField.vue';
import EntityAddress from '@/components/drawers/entity/EntityAddress.vue';
import EntityIdentifiers from '@/components/drawers/entity/EntityIdentifiers.vue';
import { MATCH_TYPES_SUPPORTED, FLOW_APPLICATION_ID, IDENTIFIER_TYPES, ENTITY_PROPERTIES, ENTITIES } from '@/constants';
import { FingerprintLockCircle } from '@epiphany/iconoir';

const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

export default {
    name: 'EntityMatches',
    components: {
        'entity-match': EntityMatch,
        'entity-custom-props': EntityCustomProps,
        'entity-field': EntityField,
        'entity-address': EntityAddress,
        'entity-identifiers': EntityIdentifiers,
        'entity-value-string': EntityValueString,
        FingerprintLockCircle
    },
    data() {
        return {
            identifer_type: IDENTIFIER_TYPES,
            loading: true,
            secondary_loading: true,
            matches: [],
            links: [],
            secondary_entity: null,
            properties: ENTITY_PROPERTIES,
            entities: ENTITIES
        }
    },
    created() {
        if (this.matches_supported) {
            Promise.all([
                this.$http.get(`${base}/api/v2/graph/${this.type}/${this.entity.id}/matches`, {
                    headers: {
                        authorization: `Bearer ${this.integration.access_token}`
                    }
                }),
                this.$http.get(`${base}/api/v2/graph/${this.type}/${this.entity.id}/links`, {
                    headers: {
                        authorization: `Bearer ${this.integration.access_token}`
                    }
                })
            ])
                .then(([matches, links]) => {
                    this.matches = matches.$data;
                    this.links = links.$data;

                    // get the value from the first link

                    if (this.links.length > 0) {
                        const link = this.links[0];
                        this.$http.get(`/teams/${this.team.id}/sources/${link.secondary_source_id}/${this.type}/${link.secondary_id}`, { baseURL: '/api/v2' })
                            .then(result => {
                                this.secondary_entity = result.$data;
                                this.secondary_loading = false;
                            })
                            .catch(error => this.$toasted.error('The secondary ' + this.type + 'could not be loaded.'))
                            .finally(() => this.secondary_loading = false);
                    }else{
                        this.secondary_loading = false;
                    }
                })
                .catch(() => {
                    this.$toasted.error('Failed to load matches.');
                })
                .finally(() => {
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        methods: {
            close() {
                this.$store.dispatch('drawer/close');
            },
            name(integration) {
                return integration.team.name;
            },
            focus(entity_id) {

                // TODO figure out linking to the source entity

                // this.$store.dispatch('drawer/close');
                // this.$store.dispatch('sources/activate', {
                //     source: this.integration.destination.id,
                //     team: this.$store.getters.team.id
                // })
                // this.$router.push({ name: 'team.sources.source.dataset', params: { team: this.team.alias, source: this.integration.destination.id, type: this.type }, hash: `#${this.entities[this.type].toLowerCase()}/${entity_id}` });
                // this.$store.dispatch('entities/activate', {
                //     id: entity_id,
                //     type: this.type,
                //     source: this.source
                // })
                //     .then(() => {
                //         this.$store.dispatch('drawer/open', {
                //             key: `${this.type}/${entity_id}`,
                //             width: 800,
                //             component: 'entity'
                //         });
                //     });

                // this.$store.dispatch('sources/activate', {
                //     source: this.integration.destination.id,
                //     team: this.$store.getters.team.id
                // })
                //     .then(() => {
                //         this.$store.dispatch('entities/activate', {
                //             id: entity_id,
                //             type: this.type,
                //             source: this.source
                //         })
                //             .then(() => {
                //                 const entity = this.$store.state.entities.active;
                //                 this.$store.dispatch('drawer/open', {
                //                     key: `${this.type}/${entity_id}`,
                //                     width: 800,
                //                     component: 'entity'
                //                 });
                //             });
                //     });
            },
            format_value(value) {
                if (value === null || value === undefined) {
                    return '—';
                } else if (Array.isArray(value)) {
                    if (value.length === 0) {
                        return '-';
                    }
                    return value.join(', ');
                } else {
                    return value;
                }
            }
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            title() {
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            },
            matches_supported() {
                return MATCH_TYPES_SUPPORTED.includes(this.type);
            },
            flow_application(){
                return FLOW_APPLICATION_ID === this.integration?.application?.id;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";



        .identifier
        {
            margin-bottom: @single-padding;
            padding: 0 @single-padding;
            height: 48px;
            box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1);
            border-bottom-color: @d;

            .icon {
                margin-right: @single-padding - 2px;
                color: @grey;
            }

            .borderless {
                border: 0;
                outline: 0;
            }

            .identifier-type {
                color: @grey;
                font-size: 14px;
                line-height: 18px;
                width: 201px;
            }

            .identifier-value
            {
                font-size: 14px;
                line-height: 42px;
                color: @black;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .autocomplete.compact .autocomplete-search input.autocomplete-input
            {
                min-width: 20px;
            }

            .copyable
            {
                &::after
                {
                    bottom: 0;
                }

                &:hover
                {
                    transform: translate3d(0, -7px, 0);

                    &::after
                    {
                        bottom: -1px;
                    }
                }
            }
        }


    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
        padding: 0 0px;
    }

    .small-card {
        max-height: 200px;
        overflow: hidden;

        // &::after
        // {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     height: 120px;
        //     background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        // }
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }
    .no-matches-found {
        padding: 25px;
    }

    .card {
        margin-bottom: 20px;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 25px;
        padding: 25px;
    }

    .summary.match {
        font-size: 13px;
        padding: @double-padding;

        .summary-field {
            height: 22px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .summary-key {
            color: @grey;
            width: 180px;
            flex-basis: 180px;
            flex-shrink: 0;
            line-height: 22px;
        }

        .summary-value {
            color: @black;
            line-height: 22px;

            &.monospace {
                line-height: 22px;
                font-size: 13px;
            }
        }
    }
</style>
