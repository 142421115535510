<template>
    <div class="syncs full" v-if="source">
        <!-- <div class="row tools flex flex-align">
            <div class="tool-container">
                <div class="tool filter flex flex-align" @click="filter.visible = !filter.visible" :class="{active: filter.applied}">
                    <span class="icon iconoir-filter"></span>
                    <div class="ff">Filter</div>
                </div>
                <form class="tool-dropdown filter-dropdown" v-if="filter.visible" @submit.prevent="apply">
                    <h3>Filter Dataset</h3>
                    <select v-model="filter.property" @change="filter.operator = 'equals'">
                        <option v-for="field of filterable" :value="field.property" :key="field.property">{{field.name}}</option>
                    </select>
                    <select v-model="filter.operator">
                        <option :value="operator" v-for="operator of filters[property.type]" :key="operator">{{operators[operator]}}</option>
                    </select>
                    <input type="text" v-model="filter.value" />
                    <div class="flex flex-align">
                        <button type="submit" class="button center">Apply Filter</button>
                        <div class="ff"></div>
                        <div class="text-button clear-filter red" @click="clear" :class="{disabled: filter.applied === false}">Clear Filter</div>
                    </div>
                </form>
            </div>
            <div class="tool sort flex flex-align disabled">
                <span class="icon iconoir-sort-down"></span>
                <div class="ff">Sort</div>
            </div>
            <div class="ff"></div>
            <div class="page">
                Results <span>{{page * count + 1}}</span> &ndash; <span>{{page * count + syncs.length}}</span>
                of <span>{{total | commas}}</span>
            </div>
            <a class="arrow previous flex flex-align flex-center" @click="previous" :class="{active: page}">
                <span class="icon iconoir-nav-arrow-left"></span>
            </a>
            <a class="arrow next flex flex-align flex-center" @click="next" :class="{active: page * 100 < total}">
                <span class="icon iconoir-nav-arrow-right"></span>
            </a>
            <a class="button mini" @click="run" v-if="source.provider.allows_data_sync">Run Sync</a>
        </div> -->
        <filters>
            <div class="ff"></div>
            <div class="page">
                <template v-if="all.length">
                    Results <span>{{page * count + 1}}</span> &ndash; <span>{{page * count + rows.length}}</span> of
                    <span v-if="next_page_available">Many</span>
                    <span v-else>{{all.length | commas}}</span>
                </template>
                <template v-else>
                    No Results
                </template>
            </div>
            <a class="arrow previous flex flex-align flex-center" @click="previous" :class="{ active: page }">
                <span class="icon iconoir-nav-arrow-left"></span>
            </a>
            <a class="arrow next flex flex-align flex-center" @click="next" :class="{active: (page + 1) * count < all.length || next_page_available}">
                <span class="icon iconoir-nav-arrow-right"></span>
            </a>
            <div class="button" @click="run" v-if="source.provider.allows_data_sync">Run Sync</div>
        </filters>
        <datatable class="syncs full" :columns="columns" :rows="rows" :selectable="true" :header="true" @clicked="row => open(row)" :loading="loading" :clickable="true">
            <template v-slot:empty-message>There are no syncs to show for this source.</template>
        </datatable>
    </div>
</template>

<script>
    import { WhiteFlag, Calendar, CalendarPlus, CodeBracketsSquare } from '@epiphany/iconoir';
    import State from '@/components/chips/State.vue';

    export default {
        name: 'SourceSyncs',
        components: {
            WhiteFlag,
            Calendar,
            CalendarPlus,
            CodeBracketsSquare
        },
        data(){
            return {
                loading: true,
                all: [],
                visible: [],
                filter: {
                    visible: false,
                    property: 'id',
                    operator: 'equals',
                    value: ''
                },
                page: 0,
                count: 100,
                next_page_available: true,
                params: {},
                offset: 0,
                total: 0,
                columns: [
                    {
                        id: 'id',
                        name: 'Sync ID',
                        icon: CodeBracketsSquare,
                        width: '40%'
                    },
                    {
                        id: 'status',
                        name: 'State',
                        icon: WhiteFlag,
                        component: State,
                        width: '160px',
                        value: row => ({ state: row.status })
                    },
                    {
                        id: 'type',
                        name: 'Type',
                        icon: CodeBracketsSquare,
                        value: row => row.type,
                        width: '160px'
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: CalendarPlus,
                        align: 'right',
                        width: '30%',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    },
                    {
                        id: 'updated_date',
                        name: 'Updated Date',
                        icon: Calendar,
                        align: 'right',
                        width: '30%',
                        value: row => this.$options.filters.pretty(row.updated_date, 'short')
                    }
                ]
            };
        },
        watch: {
            source: {
                immediate: true,
                handler(){
                    this.page = 0;
                    this.all = [];
                    this.params = {};
                    this.next_page_available = true;
                    if (this.source) {
                        this.load();
                    }
                }
            }
        },
        methods: {
            open(sync){
                this.$router.push({name: 'team.sources.source.sync', params: {sync: sync.id}});
            },
            run(){
                this.$http.post(`/teams/${this.$store.getters.team.id}/sources/${this.source.id}/syncs`)
                .then((response) => {
                    this.all.unshift(response.$data);
                    this.$toasted.success('Sync Queued');
                })
                .catch(error => {
                    if(error?.$error === 'There was an error initiating your full sync. There may be one already queued or running.') {
                        return this.$toasted.error('There was an error initiating your full sync. There may be one already queued or running.')
                    }
                    return this.$toasted.error('There was an error starting a new sync.')
                });
            },
            load(){
                if(this.source){
                    this.loading = true;
                    const params = Object.assign({
                        $last: this.count,
                        $filter: {
                            parent_id: [{
                                operator: 'is unknown'
                            }]
                        }
                    }, _.clone(this.params));
                    return this.$http.get(`/teams/${this.$store.getters.team.id}/sources/${this.source.id}/syncs`, {params})
                    .then(response => {
                        if (response.$data.length) {
                            this.all.push(...response.$data);
                            if (response.$data.length < this.count) {
                                this.next_page_available = false;
                            }
                        } else {
                            this.next_page_available = false;
                        }
                    })
                    .catch(error => this.$toasted.error('There was an error loading the syncs for this source.'))
                    .finally(() => this.loading = false);
                }
            },
            previous(){
                if(this.page){
                    this.page -= 1;
                }
            },
            next(){
                if((this.page + 1) * this.count < this.all.length || this.next_page_available){
                    if(this.all.length <= this.count * (this.page + 1)){
                        this.params.$before = this.all[this.all.length - 1].id;
                        this.load().then(() => this.page += 1);
                    }else{
                        this.page += 1;
                    }
                }
            },
            apply(){
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filter.visible = false;
                this.filter.applied = true;
                this.active = null;
                if(this.source){
                    this.load();
                }
            },
            clear(){
                this.page = 0;
                this.all = [];
                this.params = {};
                this.active = null;

                this.filter = {
                    visible: false,
                    property: 'id',
                    operator: 'equals',
                    value: '',
                    applied: false
                };

                if(this.$route.query.filter){
                    this.$router.push({query: null});
                }

                if(this.source){
                    this.load();
                }
            }
        },
        computed: {
            rows() {
                return this.all.slice(this.page * this.count, this.page * this.count + this.count);
            },
            source(){
                return this.$store.getters.source;
            },
            user(){
                return this.$store.getters.user;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .syncs {
        padding-top: @filters-height;
    }
    .filters {
        .button {
            margin-left: 10px;
        }
    }

    thead tr
    {
        border-top: 0;
    }

    .subheader
    {
        margin: 30px 0 20px;

        h3
        {
            margin: 0;
        }

        &:first-child
        {
            margin-top: 0;
        }
    }

    .sync
    {
        user-select: none;
        cursor: pointer;
    }

    .sync-status
    {
        width: 150px;
    }

    .sync-status-badge
    {
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;

        &.error, &.killed
        {
            color: @red;
        }

        &.canceled
        {
            color: @yellow;
        }

        &.working
        {
            color: @base;
        }

        &.queued
        {
            color: @grey;
        }

        &.staged
        {
            color: @purple;
        }

        &.complete
        {
            color: @green;
        }
    }

    .sync-log-options
    {
        padding: 20px 0 0;
        user-select: none;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .sync-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .monospace
    {
        font-size: 14px;
    }

    .nothing
    {
        margin: 25px 0 0;
    }
</style>
