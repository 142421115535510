<template>
    <div class="map-custom-roles">
        <div class="headers flex flex-align">
            <div class="ff">Role In {{provider.name}}</div>
            <div class="becomes"></div>
            <div class="ff">Role In Edlink</div>
        </div>
        <div class="mapping flex flex-align" v-for="(map, index) of configuration.map" :key="index">
            <div class="ff">
                <input type="text" v-model="map.source_role" />
            </div>
            <div class="becomes flex flex-align flex-center">
                <span class="icon iconoir-arrow-right"></span>
            </div>
            <div class="ff">
                <input type="text" v-model="map.edlink_role" />
            </div>
        </div>
        <div class="flex flex-align flex-center options">
            <div class="button mini" @click="add()">Add Another</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TransformConstructEmailAddresses',
        props: {
            transformation: Object,
            source: Object,
            integration: Object,
            configuration: Object
        },
        computed: {
            provider(){
                return this.source ? this.source.provider : this.integration.provider;
            }
        },
        data(){
            return {};
        },
        methods: {
            add(){
                this.configuration.map.push({
                    source_role: '',
                    edlink_role: ''
                });
            }
        },
        watch: {
            transformation: {
                immediate: true,
                handler(){
                    if(this.transformation.configuration.hasOwnProperty('map')){
                        this.configuration = this.transformation.configuration;
                    }else{
                        this.configuration = {
                            map: [
                                {
                                    source_role: 'asdf',
                                    edlink_role: 'asdf'
                                }
                            ]
                        };
                    }
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .map-custom-roles
    {
        padding: 50px;
    }

    .headers
    {
        padding: 15px 15px 0;
        font-size: 14px;
        color: @black;
        line-height: 18px;
        text-align: center;
        font-weight: 500;
    }

    .mapping
    {
        padding: 15px 15px 0;
    }

    .options
    {
        padding-top: 30px;
    }

    .becomes
    {
        color: @grey;
        font-size: 13px;
        line-height: 18px;
        width: 120px;

        .icon
        {
            font-size: 22px;
            color: @black;
        }
    }
</style>