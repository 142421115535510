<template>
    <div class="application-sso full scroll" v-if="application">
        <section>
            <h2>SSO Configuration</h2>
            <div class="application-configuration">
                <div class="form-field">
                    <label>Enable SSO</label>
                    <div class="form-helptext">Set this to `No` only if your application does not intend to support SSO.</div>
                    <radio :value.sync="sso_enabled" type="boolean" :options="[{label: 'Enabled', value: true}, {label: 'Disabled', value: false}]" name="sso_enabled" />
                </div>
                <template v-if="sso_enabled">
                    <div class="form-field">
                        <label>SSO Protocol</label>
                        <div class="form-helptext">This setting defines the protocol to use when authenticating with Edlink.</div>
                        <radio :value.sync="sso_protocol" :options="strategies" name="sso_protocol" />
                    </div>
                    <template v-if="['oidc', 'lti'].includes(sso_protocol)">
                        <div class="form-field">
                            <label>Edlink Configuration Details</label>
                            <div class="form-helptext">You'll need these in order to implement {{ sso_protocol === 'oidc' ? 'OIDC' : 'LTI 1.3' }} SSO with Edlink.</div>
                            <div class="flex oidc-options">
                                <a class="button black has-icon has-icon-right" href="https://ed.link/api/authentication/oidc/.well-known/openid-configuration" target="_blank">
                                    Edlink Configuration JSON
                                    <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </a>
                                <router-link class="button white has-icon has-icon-right" to="/docs/dashboard/dev-integrations">
                                    OIDC Setup Guide
                                    <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 21h12.4a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6V16M3.5 20.5L12 12m0 0v4m0-4H8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <div class="form-field">
                        <label>Valid Redirect URIs</label>
                        <div class="form-helptext">A list of valid redirect URIs for your application's SSO flow, one per line.</div>
                        <textarea type="text" class="large redirect-uris" v-model="origin"></textarea>
                    </div>
                    <template v-if="['oidc', 'lti'].includes(sso_protocol)">
                        <div class="form-field">
                            <label>Login Initiation URL</label>
                            <div class="form-helptext">The URL that Edlink will use to initiate the OIDC flow.</div>
                            <input type="text" class="large" v-model="sso_initiation_url" />
                        </div>
                        <!-- <div class="form-field">
                            <label>JWK Method</label>
                            <div class="form-helptext">The URL that Edlink will use to initiate the OIDC flow. Consider using a Keyset URL if you plan to regularly rotate your keys.</div>
                            <radio :value.sync="sso_jwk_source" :options="[{label: 'Keyset URL', value: 'uri'}, {label: 'Paste JWK', value: 'json'}]" name="sso_jwk_source" />
                        </div>
                        <div class="form-field" v-if="sso_jwk_source === 'uri'">
                            <label>Keyset URI</label>
                            <div class="form-helptext">A URL that returns your public keys.</div>
                            <input type="text" class="large" v-model="sso_jwks_uri" />
                        </div>
                        <div class="form-field" v-else>
                            <label>Paste JWK</label>
                            <div class="form-helptext">Paste your public key into into the field below.</div>
                            <textarea type="text" class="large" v-model="sso_jwk_json"></textarea>
                        </div> -->
                    </template>
                </template>
                <div class="form-options flex">
                    <div class="button" @click="update">Save Configuration</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Options from '@/components/modals/Options.vue';

    export default {
        name: 'ApplicationSSO',
        data() {
            return {
                clone: null
            };
        },
        methods: {
            destroy(){
                const _this = this;

                this.$modal.show(
                    Options,
                    {
                        title: 'Destroy Application',
                        description: 'Are you sure you want to destroy this application? This will remove all any data stored about your application as well as destroy any active integrations connected to this application.',
                        options: [
                            {
                                title: `Destroy This Application`,
                                color: 'red',
                                fn() {
                                    _this.$store
                                        .dispatch('applications/destroy')
                                        .then(() => _this.$toasted.success('Application has been marked for removal.'))
                                        .catch((error) => _this.$toasted.error('There was an error marking the application for removal.'));
                                }
                            },
                            {
                                title: `Keep This Application`,
                                color: 'blue',
                                fn() {}
                            }
                        ]
                    },
                    { width: 300, height: 'auto', classes: 'modal' }
                );
            },
            update(){
                this.$store.dispatch('applications/update');
            }
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            admin(){
                return this.$store.getters.user?.admin;
            },
            strategies(){
                return [{label: 'OAuth 2.0', value: 'oauth'}, {label: 'OpenID Connect', value: 'oidc'}, {label: 'LTI 1.3', value: 'lti'}];
            },
            sso_enabled: {
                get(){
                    return this.application.sso_enabled;
                },
                set(sso_enabled){
                    this.$store.commit('applications/update', { sso_enabled });
                }
            },
            origin: {
                get(){
                    return this.application.origin;
                },
                set(origin){
                    this.$store.commit('applications/update', { origin });
                }
            },
            sso_protocol: {
                get(){
                    return this.application.sso_protocol;
                },
                set(sso_protocol){
                    this.$store.commit('applications/update', { sso_protocol });
                }
            },
            sso_jwk_source: {
                get(){
                    return this.application.sso_jwk_source;
                },
                set(sso_jwk_source){
                    this.$store.commit('applications/update', { sso_jwk_source });
                }
            },
            sso_jwk_json: {
                get(){
                    return this.application.sso_jwk_json;
                },
                set(sso_jwk_json){
                    this.$store.commit('applications/update', { sso_jwk_json });
                }
            },
            sso_jwks_uri: {
                get(){
                    return this.application.sso_jwks_uri;
                },
                set(sso_jwks_uri){
                    this.$store.commit('applications/update', { sso_jwks_uri });
                }
            },
            sso_initiation_url: {
                get(){
                    return this.application.sso_initiation_url;
                },
                set(sso_initiation_url){
                    this.$store.commit('applications/update', { sso_initiation_url });
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .form-fields
    {
        padding: 0;
        margin-top: 30px;

        .form-field {
            margin: 0 30px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .form-helptext
    {
        max-width: 700px;
    }

    .oidc-options
    {
        margin-top: 10px;

        .button
        {
            margin-right: 10px;
        }
    }

    .summary
    {
        font-size: 13px;
        border: 0;
        background: @f8;
        padding: 20px;
        max-width: 580px;

        .summary-field
        {
            height: 22px;
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }

            &.multiline
            {
                height: auto;
                align-items: flex-start;

                .summary-values
                {
                    align-items: flex-start;
                }
            }
        }

        .summary-key
        {
            color: @grey;
            width: 180px;
            flex-basis: 180px;
            flex-shrink: 0;
            line-height: 22px;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            &.monospace
            {
                line-height: 22px;
                font-size: 12px;
            }
        }

        .text-button
        {
            font-weight: 400;
            margin-top: 4px;
        }

        .icon-status
        {
            margin-right: 8px;
        }
    }

    .redirect-uris
    {
        resize: both;
    }
</style>
