import { render, staticRenderFns } from "./IntegrateSourceProviderBrightspace.vue?vue&type=template&id=05db57be&scoped=true"
import script from "./IntegrateSourceProviderBrightspace.vue?vue&type=script&lang=js"
export * from "./IntegrateSourceProviderBrightspace.vue?vue&type=script&lang=js"
import style0 from "./IntegrateSourceProviderBrightspace.vue?vue&type=style&index=0&id=05db57be&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05db57be",
  null
  
)

export default component.exports