<template>
    <div class="entity card">
        <template v-if="entity">
            <div class="flex flex-align">
                <div class="entity-icon" :class="{verified}">
                    <img :src="entity.icon_url ? entity.icon_url : '/source/default.png'" />
                </div>
                <div class="ff">
                    <div class="entity-name capitalize text-overflow">{{entity.name}}</div>
                    <div class="entity-details capitalize text-overflow">{{entity.type}}</div>
                </div>
                <div class="text-button red" @click="remove">Remove</div>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-align">
                <div class="entity-icon">
                    <img src="/source/default.png" />
                </div>
                <div class="ff entity-name">No Entity Details</div>
                <div class="text-button" @click="select">Select Entity</div>
            </div>
        </template>
    </div>
</template>

<script>
    import SelectEntity from '@/components/modals/SelectEntity.vue';

    export default {
        name: 'EntityDetails',
        props: {
            entity: Object,
            verified: Boolean
        },
        methods: {
            select(){
                const _this = this;

                this.$modal.show(SelectEntity, {
                    confirm(entity){
                        _this.$emit('selected', entity);
                    }
                }, {width: 500, height: 'auto', classes: 'modal'});
            },
            remove(){
                this.$emit('selected', null);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity
    {
        border: 1px solid @d;
        border-radius: 3px;
        width: 100%;
        max-width: 450px;
        padding: 10px 15px 10px 10px;
        height: 54px;
    }

    .entity-icon
    {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: @e;
        margin-right: 10px;

        &::before
        {
            width: 32px;
            height: 32px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        &.verified
        {
            &::after
            {
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: url('~@/assets/icons/white/check.svg') 1px 2px no-repeat @base;
                background-size: 12px auto;
                position: absolute;
                content: "";
                right: 0;
                bottom: 0;
                z-index: 3;
                box-shadow: 0 0 0 1px @f;
            }
        }

        img
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    .entity-name
    {
        color: @black;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 2px;
    }

    .entity-details
    {
        color: @grey;
        font-size: 12px;
    }

    .text-button
    {
        margin-left: 20px;
    }
</style>
