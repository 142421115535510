const moment = require('moment');

module.exports = (iso, format, placeholder = '') => {
    const date = moment(iso);

    if (!iso) {
        return placeholder;
    }

    if (format === 'short_time') {
        return date.format('hh:mm a');
    }

    if (format === 'short') {
        const now = moment();

        if (now.year() === date.year()) {
            if (now.date() === date.date() && now.month() === date.month()) {
                //Just return the time
                return date.format('LT');
            }

            //Return the Month and Date
            return date.format('MMM D');
        }

        return date.format('L');
    }

    if (format === 'medium') {
        return date.format('MMMM Do, YYYY');
    }

    if (format === 'long') {
        return date.format('D MMM YYYY, h:mm:ss a');
    }
    /*
        Return date w/o any time + TZ adjustment
    */
    if (format === 'date') {
        return moment.parseZone(iso).format('l');
    }

    return date.format(format);
};
