<template>
    <div class="transform-nothing center">
        There's nothing to configure for this component.
    </div>
</template>

<script>
    export default {
        name: 'TransformNothing'
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .transform-nothing
    {
        padding: 50px;
        font-size: 13px;
        color: @black;
    }
</style>