import { render, staticRenderFns } from "./UndoAction.vue?vue&type=template&id=47e1942b"
import script from "./UndoAction.vue?vue&type=script&lang=js"
export * from "./UndoAction.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports