<template>
    <div class="reconnect flex flex-column">
        <header class="flex flex-align">
            <a class="flex flex-align wordmark" href="https://ed.link" target="_blank">
                <div class="diamonds"></div>
                <div class="letters"></div>
            </a>
            <div class="ff"></div>
            <!-- <div class="flex flex-align integrate-video" @click="$store.commit('integrate/video', 'what-is-edlink')">
                <span class="icon iconoir-media-video-list"></span> What is Edlink?
            </div> -->
        </header>
        <div class="steps flex flex-column flex-center">
            <div class="step" @click="send('instructions')" :class="{ active: page === 'instructions' }">Welcome</div>
            <div class="step" v-if="!user.id" @click="send('login')" :class="{ active: ['login', 'email', 'password', 'two'].includes(page) }">Authentication</div>
            <div class="step" @click="send('configuration')" :class="{ active: page === 'configuration' }">Configure</div>
            <div class="step" :class="{ active: page === 'complete' }">Finished</div>
        </div>
        <transition appear name="fade" :duration="400">
            <div v-if="loading" key="loading" class="reconnect-loading flex flex-align flex-center ff">
                <spinner />
            </div>
            <main v-else key="flow" class="flex flex-column flex-align flex-center ff flow">
                <transition appear name="slide" mode="out-in" :duration="400">
                    <ReconnectInstructions v-if="page === 'instructions'" key="instructions" />
                    <ReconnectEmail v-else-if="page === 'login'" key="login" />
                    <ReconnectPassword v-else-if="page === 'password'" key="password" />
                    <ReconnectTwo v-else-if="page === 'two'" key="two" />
                    <ReconnectConfiguration v-else-if="page === 'configuration'" key="configuration" />
                    <ReconnectComplete v-else-if="page === 'complete'" key="complete" />
                </transition>
            </main>
        </transition>
    </div>
</template>

<script>
    import ReconnectInstructions from '@/components/reconnect/ReconnectInstructions.vue';
    import ReconnectEmail from '@/components/reconnect/ReconnectEmail.vue';
    import ReconnectPassword from '@/components/reconnect/ReconnectPassword.vue';
    import ReconnectTwo from '@/components/reconnect/ReconnectTwo.vue';
    import ReconnectConfiguration from '@/components/reconnect/ReconnectConfiguration.vue';
    import ReconnectComplete from '@/components/reconnect/ReconnectComplete.vue';

    import JSConfetti from 'js-confetti';
    const jsConfetti = new JSConfetti();

    export default {
        name: 'Reconnect',
        components: {
            ReconnectInstructions,
            ReconnectEmail,
            ReconnectPassword,
            ReconnectTwo,
            ReconnectConfiguration,
            ReconnectComplete
        },
        data() {
            return {}
        },
        computed: {
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            user(){
                return this.$store.getters.user;
            },
            source(){
                return this.$store.state.reconnect.source;
            },
            name(){
                if (this.user) {
                    if (this.user.first_name) {
                        return this.user.first_name;
                    }
                }
                return null;
            },
        },
        methods: {
            send(page){
                if (this.loading || !this.user.id) { return };
                this.$store.commit('reconnect/page', page);
            },
            next(){
                this.$store.dispatch('reconnect/next');
            },
            keydown(e){
                if (this.loading) { return };
                if (e.repeat) { return };

                // console.log('keydown', e.code);

                if(e.code === 'Enter'){
                    this.next();
                }
            },
            auth(type, value){
                if (type === 'token') {
                    this.$store.dispatch('reconnect/update', { path: 'token', value});
                } else if (type === 'consent') {
                    this.$store.dispatch('reconnect/update', { path: 'configuration.tenant_id', value});
                } else {
                    return;
                }

                this.token = value;
            },
            update(property, value){
                this.$store.dispatch('reconnect/update', { path: `configuration.${property}`, value})
            },
            reconnect(){
                this.$store.dispatch('reconnect/reconnect').then(() => {
                    jsConfetti.addConfetti({
                        confettiColors: [
                            '#006AFF', '#F1C40F', '#2ED573', '#8E44AD', '#EB2F06'
                        ]
                    });
                });
            },
            close(){
                window.close();
            }
        },
        destroyed(){
            window.removeEventListener('keydown', this.keydown);
        },
        created(){
            this.$store.dispatch('reconnect/initialize', this.$route.params.parent);

            window.addEventListener('keydown', this.keydown);
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .flow::v-deep {
        .reconnect-step {
            width: 100%;
            max-width: 600px;
        }

        .helptext
        {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: @grey;
            max-width: 550px;
            margin-bottom: 15px;
        }

        .scroll {
            width: 100vw;
            height: 100vh;
            overflow-y: scroll;
            max-width: none;
        }

        .title-helptext
        {
            margin-bottom: 50px;
        }

        .options {
            margin-top: 25px;
        }

        .integrate-source-editor-configuration
        {
            margin-bottom: 15px;
        }
    }
    
    .reconnect
    {
        width: 100vw;
        min-height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
    }

    .reconnect-loading {
        width: 100vw;
        min-height: 100vh;
    }

    header
    {
        z-index: 20;
        padding: 10px 15px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        pointer-events: none;

        .wordmark {
            height: 30px;
            pointer-events: all;

            .diamonds {
                background: url('~@/assets/edlink-diamonds.svg') center center no-repeat;
                background-size: 30px auto;
                color: transparent;
                width: 30px;
                height: 30px;
                margin-right: 12px;
            }

            .letters {
                background: url('~@/assets/edlink.svg') left center no-repeat;
                background-size: 75px auto;
                color: transparent;
                width: 150px;
                height: 30px;
            }
        }

        .text-button {
            margin-left: 20px;
        }

        .integrate-video {
            pointer-events: all;
        }
    }

    .integrate-video
    {
        font-weight: 400;
        font-size: 12px;
        color: @base;
        line-height: 24px;
        background: fade(@base, 20%);
        height: 24px;
        padding: 0 6px 0 10px;
        border-radius: 12px;
        transition: background-color ease 0.2s;
        cursor: pointer;

        .icon
        {
            font-size: 16px;
            margin-right: 4px;
        }

        &::after
        {
            width: 14px;
            height: 14px;
            background: url('~@/assets/icons/base/chevron-right.svg') -2px -1px no-repeat;
            background-size: 16px auto;
            display: block;
            content: "";
            margin-left: 5px;
            transition: all ease 0.2s;
        }

        &:hover, &:active
        {
            background: fade(@base, 30%);

            &::after
            {
                .translate3d(4px, 0, 0);
            }
        }
    }

    .steps {
        position: fixed;
        pointer-events: none;
        top: 0;
        bottom: 0;
        left: 25px;
        z-index: 10;

        .step {
            font-size: 12px;
            color: transparent;
            line-height: 26px;
            cursor: pointer;
            pointer-events: all;
            border-left: 2px solid lighten(@grey, 20%);
            margin-bottom: 10px;
            width: 120px;
            transition: all ease 0.2s;
            padding-left: 5px;

            &:hover,
            &:active {
                padding-left: 10px;
                color: @grey;
            }

            &.active,
            &.router-link-active {
                color: @black;
                padding-left: 10px;
                border-left-color: @base;
            }
        }

        &.done {
            .step {
                border-left-color: @grey;
            }
        }
    }
</style>
