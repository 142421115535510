<template>
    <div class="entity-details">
        <div class="drawer-section">
            <h4>Override Details</h4>
            <div class="summary">
                <entity-field v-for="column of properties" :key="column.property" v-bind="column" />
            </div>
        </div>
        <template v-if="type === 'people'">
            <div class="drawer-divider"></div>
            <div class="drawer-section">
                <h4>Demographics</h4>
                <div class="summary">
                    <entity-field v-for="column of properties.demographics" :key="column.property" v-bind="column" />
                </div>
            </div>
        </template>
        <div class="drawer-divider"></div>
        <div class="drawer-section">
            <h4>Overridden Properties</h4>
            <div class="summary" v-if="entity.properties && Object.keys(entity.properties).length > 0">
                <entity-field v-for="(value, property) of entity.properties" :key="property" :editable="false" :title="property" :array="false" :property="'properties.' + property" />
            </div>
            <div class="nothing" v-else>None.</div>
        </div>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';

    export default {
        name: 'OverrideDetails',
        components: {
            'entity-field': EntityField
        },
        data(){
            return {
                properties: [
                    {title: 'Override ID', property: 'id', type: 'string', monospace: true, copyable: true},
                    {title: 'Created Date', property: 'created_date', type: 'date'},
                    {title: 'Updated Date', property: 'updated_date', type: 'date'},
                    {title: 'Override Action', property: 'action', type: 'string'},
                    {title: 'Override Type', property: 'type', type: 'string'},
                ]
            };
        },
        created(){
            console.log(this.entity)
        },
        methods: {
            impersonate(){},
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            loading() {
                return this.$store.state.entities.loading;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            title() {
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
        padding: 0 25px;
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }

    nav
    {
        border-bottom: 1px solid @e4;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }
</style>
