<template>
    <div class="account-settings">
        <header class="flex flex-align">
            <h2>Account Settings</h2>
            <div class="ff"></div>
            <div class="text-button red border" @click="logout">Sign Out</div>
        </header>
        <nav class="flex">
            <div class="destination" :class="{active: tab === 'general'}" @click="tab = 'general'">General</div>
            <div class="destination" :class="{active: tab === 'security'}" @click="tab = 'security'">Security</div>
            <div class="destination" :class="{active: tab === 'invitations'}" @click="tab = 'invitations'">Invitations</div>
        </nav>
        <div class="tab" v-if="tab === 'general'">
            <form @submit.prevent="confirm_details" autocomplete="off">
                <label for="first_name">First Name</label>
                <input type="text" class="medium" :value="user.first_name" @input="$event => $store.dispatch('user/update', {field: 'first_name', value: $event.target.value})" name="first_name" />
                <label for="last_name">Last Name</label>
                <input type="text" class="medium" :value="user.last_name" @input="$event => $store.dispatch('user/update', {field: 'last_name', value: $event.target.value})" name="last_name" />
                <label for="email">Email Address</label>
                <input type="text" class="medium" :value="user.email" name="email" disabled />
                <label for="last_name">Profile Picture</label>
                <upload :url="user.picture_url" @input="value => $store.dispatch('user/update', {field: 'picture_url', value})" type="image" />
            </form>
            <div class="flex form-options">
                <div class="button" @click="confirm_details">Save Account Details</div>
            </div>
        </div>
        <div class="tab" v-if="tab === 'security'">
            <!-- - Sign out of all accounts
            - Enable two factor authentication
            - Change your password -->
            <section>
                <div class="two-factor flex flex-align">
                    <h2>Two-Factor Authentication</h2>
                    <div class="flex flex-align two-factor-status">
                        <div class="standalone icon-status" :class="{red: !user.two_factor_enabled, green: user.two_factor_enabled}"></div>
                        <div class="capitalize">{{user.two_factor_enabled ? 'Enabled' : 'Disabled'}}</div>
                    </div>
                </div>
                <div class="signout-warning">
                    Two-factor authentication (2FA) adds an additional layer of security to your account.
                    If your password is ever compromised, 2FA provides an additional barrier of protection.
                    It is highly recommended that you enable 2FA as you are likely interacting with sensitive student data.
                </div>
                <div class="form-options flex flex-align">
                    <template v-if="user.two_factor_enabled">
                        <div class="button disabled">Disable 2FA</div>
                        <div class="helptext">Please contact us to remove 2FA.</div>
                    </template>
                    <div class="button" @click="enable_two_factor" v-else>Enable 2FA</div>
                </div>
            </section>
            <section>
                <h2>Sign Out</h2>
                <div class="signout-warning">
                    Click the button below to sign out of your account. Clicking "Sign Out On All Devices" will automatically log your account out
                    on any device where it is being used. You should really only use this option if you believe that your account has become compromised.
                </div>
                <div class="flex form-options">
                    <div class="red button" @click="logout">Sign Out</div>
                    <div class="red text-button border" @click="logout_everywhere">Sign Out On All Devices</div>
                </div>
            </section>
        </div>
        <div class="tab" v-if="tab === 'invitations'">
            <div class="invitation-container" v-if="invitations.length">
                <invitations :invites="invitations" />
            </div>
            <div class="nothing" v-else>You have no pending invitations.</div>
        </div>
    </div>
</template>

<script>
    import TwoFactorSetup from '@/components/modals/TwoFactorSetup.vue';

    export default {
        name: 'AccountSettings',
        computed: {
            user(){
                return this.$store.state.user;
            },
            teams(){
                return this.$store.state.teams;
            }
        },
        data(){
            return {
                tab: 'general',
                invitations: []
            };
        },
        created(){
            this.$http.get('/invitations')
            .then(response => this.invitations = response.$data.map(invite => Object.assign({attempting: false, accepted: false, rejected: false}, invite)))
            .catch(error => {
                //Don't bother retrying right now, just skip to creation if there's an error
                this.$toasted.error('There was an error retrieving your pending invitations.');
            });
        },
        methods: {
            confirm_details(){
                //Check to see if the user has any open invitations
                this.$http.put('/me', this.user)
                .then(() => this.$toasted.success('Your account details were saved successfully.'))
                .catch(error => this.$toasted.error('There was an error saving your account details.'));
            },
            update_user(field, value){
                this.$store.dispatch('user/update', {field, value});
            },
            logout(){
                this.$emit('close');
                
                this.$store.dispatch('user/logout')
                .then(() => this.$router.push('/login'));
            },
            logout_everywhere(){
                this.$http.post('/logout', {})
                .then(() => this.$toasted.success('You have been logged out from all devices.'))
                .then(() => this.logout())
                .catch(error => this.$toasted.error('There was an error logging you out of all devices.'));
            },
            enable_two_factor(){
                this.$emit('close');
                this.$modal.show(TwoFactorSetup, {}, {width: 360, height: 'auto', classes: 'modal'});
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .account-settings
    {
        padding: 25px;

        header
        {
            margin-bottom: 10px;
        }

        h2
        {
            color: @black;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }
    }
    
    nav
    {
        border-bottom: 1px solid @e4;
    }
    
    .tab
    {
        min-height: 450px;

        section
        {
            border-bottom: 1px solid @e4;
            padding: 25px 0;

            &:last-child
            {
                margin-bottom: 0;
                border-bottom: 0;
            }

            .form-options
            {
                padding-bottom: 0;
            }
        }
    }

    .invitations
    {
        .nothing, .invitation-container
        {
            margin-top: 20px;
        }
    }

    form
    {
        margin: 20px 0 0;

        input
        {
            margin-bottom: 10px;
        }
    }

    h3
    {
        margin-top: 30px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    .signout-warning
    {
        color: @grey;
        line-height: 18px;
        font-size: 14px;
        margin-top: 10px;
    }

    .two-factor-status
    {
        font-size: 13px;
        color: @black;
        margin-left: 15px;

        .icon-status
        {
            margin-right: 6px;
        }
    }

    .helptext
    {
        color: @grey;
        font-size: 13px;
        line-height: 18px;
    }
</style>