<template>
    <div class="datatable" :style="{minHeight, minWidth}" :class="{ editable }">
        <slot name="loading" v-if="loading">
            <div class="full flex flex-center flex-align">
                <spinner />
            </div>
        </slot>
        <table v-else-if="rows.length">
            <thead v-if="header">
                <tr>
                    <!-- <th v-if="selectable" class="datatable-checkbox">
                        <div class="datatable-header full flex flex-align flex-center">
                            <input type="checkbox" :checked="all_selected" @change="all($event)" />
                        </div>
                    </th> -->
                    <th v-for="column of columns" :key="column.id" :class="{ clickable: is_clickable_header(column) }" :style="{ width: column.width ? column.width : 'auto' }" @click="header_click(column)">
                        <div class="datatable-header full flex flex-align">
                            <component v-if="column.icon" :is="column.icon" width="14" height="14" stroke-width="2" class="datatable-header-icon" />
                            {{ column.name }}
                            <div class="ff"></div>
                            <template v-if="is_sorted(column)">
                                <SortUp v-if="is_sorted(column).direction === 'asc'" class="icon filter-direction" width="16" height="16" stroke-width="1" />
                                <SortDown v-else-if="is_sorted(column).direction === 'desc'" class="icon filter-direction" width="16" height="16" stroke-width="1" />
                                <div @click.stop="unsort(column)">
                                    <Cancel class="icon clear-filter" width="16" height="16" stroke-width="1" />
                                </div>
                            </template>
                            <Sort v-else-if="!!column.sort && sorts.length < 2" class="icon add-filter" width="16" height="16" stroke-width="1" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) of sorted" :key="row.id" @click="() => row_clickable(row, index) && $emit('clicked', row)" :class="{clickable: row_clickable(row, index) }">
                    <!-- <td v-if="selectable" class="datatable-checkbox">
                        <div class="datatable-cell full flex flex-align flex-center text-overflow" @click.stop="() => {}">
                            <input type="checkbox" :checked="row_selected(row)" @change="select($event, row)" />
                        </div>
                    </td> -->
                    <td v-for="column of columns" :key="column.id" :class="{ focusable: column_focusable(column, row, index), clickable: is_clickable_cell(row, column) }" :style="{ width: column.width ? column.width : 'auto' }" @click="cell_click(row, column)">
                        <div class="datatable-cell full flex flex-align" :class="classes(row, column)">
                            <component v-if="component(row, column)" :is="component(row, column)" v-bind="bind(row, column)" v-on="column.on" :array="column.array" />
                            <div class="datatable-overflow text-overflow" :style="{ textAlign: column.align ? column.align : 'left' }" v-tooltip.top="tooltips ? value(row, column) : null" v-else>
                                {{ value(row, column) }}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="total">
                    <td v-for="column of total">
                        <template v-if="column">
                            <div class="datatable-cell full flex flex-align" :class="classes(null, column)">
                                <component v-if="column.component" :is="column.component" v-bind="bind(null, column)" v-on="column.on" :array="column.array" />
                                <div class="datatable-overflow text-overflow" :style="{ textAlign: column.align ? column.align : 'left' }" v-else>
                                    {{ total_value(column) }}
                                </div>
                            </div>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
        <slot name="empty" v-else>
            <div class="ff"></div>
            <div class="full nothing flex flex-align flex-center flex-column">
                <PeaceHand class="icon" width="24" height="24" stroke-width="1.5" />
                <slot name="empty-message">Nothing to see here.</slot>
            </div>
        </slot>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { Sort, SortUp, SortDown, Cancel, PeaceHand } from '@epiphany/iconoir';

    export default {
        name: 'Datatable',
        props: {
            placeholder: String,
            editable: {
                type: Boolean,
                default: false
            },
            selectable: Boolean,
            clickable: Boolean | Function,
            sort: String,
            tooltips: {
                type: Boolean,
                default: false
            },
            rows: Array,
            total: Array,
            selected: Array,
            columns: Array,
            header: Boolean,
            loading: Boolean,
            minHeight: {
                type: String,
                default: 'auto'
            },
            minWidth: {
                type: String,
                default: 'auto'
            }
        },
        components: {
            PeaceHand,
            Sort,
            SortUp,
            SortDown,
            Cancel
        },
        data(){
            return {
                sorts: []
            };
        },
        computed: {
            all_selected(){
                if(!_.isArray(this.rows)){
                    return false;
                }

                if(this.rows?.length === 0){
                    return false;
                }
                
                return this.rows?.every(row => this.row_selected(row));
            },
            row_selected(){
                return row => this.selected?.includes(row);
            },
            sorted() {
                if(this.sorts.length === 0){
                    return this.rows;
                }

                const final = this.rows.slice();

                // Order sorts by columns in reverse order
                const ordered_sorts = this.sorts.slice().sort((a,b) => this.columns.findIndex(c => c.id === a.id) - this.columns.findIndex(c => c.id === b.id)).reverse();

                for (const column of ordered_sorts) {
                    if (_.isFunction(column.sort)) {
                        final.sort((a, b) => column.sort(a, b) * (column.direction === 'asc' ? 1 : -1));
                    } else {
                        // Sort alphabetically/numerically
                        final.sort((a, b) => {
                            const valueA = _.get(a, column.sort);
                            const valueB = _.get(b, column.sort);

                            if (valueA < valueB) {
                                return column.direction === 'asc' ? -1 : 1;
                            }

                            if (valueA > valueB) {
                                return column.direction === 'asc' ? 1 : -1;
                            }

                            return 0;
                        });
                    }
                }

                return final;
            }
        },
        methods: {
            row_clickable(row, index){
                if(_.isBoolean(this.clickable)){
                    return this.clickable;
                }

                if(_.isFunction(this.clickable)){
                    return this.clickable({
                        $row: row,
                        $index: index,
                        $first: index === 0,
                        $last: index === this.sorted.length - 1
                    });
                }

                return false;
            },
            column_focusable(column, row, index){
                if(_.isBoolean(column.focusable)){
                    return column.focusable;
                }

                if(_.isFunction(column.focusable)){
                    return column.focusable({
                        $column: column,
                        $row: row,
                        $index: index,
                        $first: index === 0,
                        $last: index === this.sorted.length - 1
                    });
                }

                return false;
            },
            is_sorted(column){
                return this.sorts.find(s => s.id === column.id);
            },
            is_clickable_header(column){
                return this.is_sorted(column) || !!column.sort && this.sorts.length < 2 || (!_.isNil(column.action) && column.action.header);
            },
            is_clickable_cell(row, column){
                return !_.isNil(column.action) && column.action.cell;
            },
            header_click(column) {
                if(!_.isNil(column.sort)){
                    if (_.isNil(this.sort)) {
                        return;
                    }

                    if (this.sorts.find(s => s.id === column.id)) {
                        const sort = this.sorts.find(s => s.id === column.id);
                        sort.direction = sort.direction === 'asc' ? 'desc' : 'asc';
                    } else {
                        if (this.sorts.length === 2) {
                            return;
                        }

                        if (this.sort === 'single') {
                            this.sorts = [];
                        }

                        this.sorts.push({
                            id: column.id,
                            sort: column.sort,
                            direction: 'asc'
                        });
                    }
                } else if (!_.isNil(column.action)) {
                    if (column.action.header) {
                        column.action.handler(null, null);
                    }
                }
            },
            cell_click(row, column) {
                if(!_.isNil(column.action)){
                    if(column.action.cell){
                        column.action.handler(row, this.value(row, column));
                    }
                }
            },
            toggle_sort(column){
                if (_.isNil(this.sort)) {
                    return;
                }

            },
            unsort(column){
                this.sorts = this.sorts.filter(s => s.id !== column.id);
            },
            classes(row, column){
                if(_.isString(column.classes)){
                    return column.classes;
                }

                if(_.isFunction(column.classes)){
                    return column.classes(row, column);
                }
            },
            component(row, column){
                if(_.isFunction(column.component)){
                    return column.component(row, column);
                }

                return column.component;
            },
            select($event, row){
                this.$emit($event.target.checked ? 'select' : 'unselect', row);
            },
            all($event, row){
                if($event.target.checked){
                    // Unselect everything.
                    // We don't really have to check if the row is already selected because the only way we should end up here is if they are all selected.
                    for(const row of this.sorted){
                        this.$emit('unselect', row);
                    }
                }else{
                    // Emit select events for non-selected rows.
                    for(const row of this.sorted){
                        if(!this.row_selected(row)){
                            this.$emit('select', row);
                        }
                    }
                }
            },
            value(row, column){
                if(_.isFunction(column.value)){
                    return column.value(row);
                }

                return _.get(row, column.id);
            },
            bind(row, column){
                if(_.isFunction(column.value)){
                    return column.value(row);
                }

                return { row, column };
            },
            total_value(column){
                if(_.isFunction(column.value)){
                    return column.value();
                }

                return column.value;
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/mixins";
    @import "~@/assets/less/variables";

    .datatable
    {
        // TOOD: why?
        overflow-y: auto;

        &.editable {
            overflow: initial;

            table {
                thead {
                    z-index: 2;
                }
            }
        }

        &.card
        {

            table
            {
                border-bottom: 0;
            }
        }
        
        table
        {
            border-spacing: 0;
            table-layout: fixed;
            border-bottom: 1px solid @e;
            width: 100%;

            thead
            {
                // Allows for a scrollable sticky header
                z-index: 3;

                tr
                {
                    &:first-child {
                        th
                        {
                            &:first-child {
                                border-top-left-radius: 5px;
                            }

                            &:last-child {
                                border-top-right-radius: 5px;
                            }
                        }
                    }
                }

                // if the table has a header the dont apply border radius to the first row
                // turns out the sibling selector is more specific than the child selector
                & + tbody
                {
                    tr:first-child
                    {
                        td
                        {
                            &:first-child {
                                border-top-left-radius: 0;
                            }

                            &:last-child {
                                border-top-right-radius: 0;
                            }
                        }
                    }
                }
            }

            tbody
            {
                color: @black;
                font-size: 13px;
                z-index: 2;

                tr
                {
                    &:first-child {
                        td
                        {
                            &:first-child {
                                border-top-left-radius: 5px;
                            }

                            &:last-child {
                                border-top-right-radius: 5px;
                            }
                        }
                    }

                    &:last-child
                    {
                        td
                        {
                            height: @datatable-cell-height - 1px;

                            .datatable-cell
                            {
                                height: @datatable-cell-height - 1px;
                                line-height: @datatable-cell-height - 1px;
                                border-bottom: 0;
                            }

                            &:first-child {
                                border-bottom-left-radius: 5px;
                            }

                            &:last-child {
                                border-bottom-right-radius: 5px;
                            }
                        }
                    }

                    &.clickable
                    {
                        &:hover, &:active
                        {
                            th, td
                            {
                                background: @f4;
                                cursor: pointer;
                                user-select: none;
                            }
                        }
                    }
                }
            }

            th, td
            {
                background: @f;
                color: @black;
                font-size: 12px;
                height: @datatable-cell-height;
                border: 0;
                padding: 0;
                margin: 0;

                &:last-child
                {
                    .datatable-header, .datatable-cell
                    {
                        border-right: 0;
                    }
                }

                &.focusable
                {
                    &:focus, &:focus-visible, &:focus-within {
                        outline: 1px solid @base;
                        z-index: 4;
                    }
                }

                &.clickable
                {
                    cursor: pointer;

                    &:hover
                    {
                        background: @f4;
                    }
                }
            }

            th
            {
                position: sticky;
                top: 0;
                font-weight: 500;
                height: @datatable-header-height;
            }

            .datatable-header
            {
                padding: 0 10px;
                height: @datatable-header-height;
                line-height: @datatable-header-height - 1px;
                border-bottom: 1px solid @e;
                border-right: 1px solid @e;
                color: @grey;

                .datatable-header-icon
                {
                    margin-right: 6px;
                }

                .clear-filter {
                    display: none;

                    &:hover {
                        color: @base;
                        background: fade(@base, 10%);
                        border-radius: 5px;
                    }
                }

                .add-filter {
                    display: none;
                }

                &:hover {
                    .filter-direction {
                        display: none;
                    }

                    .clear-filter, .add-filter {
                        display: block;
                    }
                }
            }

            .datatable-cell
            {
                padding: 0 10px;
                height: @datatable-cell-height;
                line-height: @datatable-cell-height - 1px;
                border-bottom: 1px solid @e;
                border-right: 1px solid @e;
                min-width: @datatable-cell-height;

                &.raw
                {
                    padding: 0;
                }

                &.bold {
                    font-weight: 600;

                    input {
                        font-weight: 600;
                    }
                }

                .datatable-overflow
                {
                    max-width: 100%;
                    width: 100%;
                }
            }

            .datatable-checkbox
            {
                width: @datatable-cell-height;
                
                input
                {
                    margin: 0;
                }
            }
        }

        .nothing
        {            
            .icon
            {
                margin-bottom: 20px;
            }
        }

        &.card
        {
            table
            {
                border-bottom: 0;
            }
        }
    }
</style>
