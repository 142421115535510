<template>
    <div class="application-integrations full scroll" v-if="application">
        <section>
            <h2>Customize Appearance</h2>
            <div class="application-configuration">
                <div class="form-field">
                    <label>Primary Theme Color</label>
                    <div class="form-helptext">Your application's primary theme color (a valid CSS value).</div>
                    <div class="flex flex-align">
                        <input type="text" v-model="theme_color_primary" class="medium" :class="{'error': !validateColor(theme_color_primary)}" placeholder="Theme Color" />
                        <template v-if="theme_color_primary">
                            <div v-if="validateColor(theme_color_primary)" class="color-preview" :style="{ backgroundColor: theme_color_primary }"></div>
                            <div v-else class="invalid-color flex flex-align flex-center">
                                <WarningTriangle class="icon" width="16" height="16" stroke-width="2" color="#eb2f06" />
                            </div>
                        </template>
                    </div>
                </div>
                <label>Application Icon</label>
                <div class="form-helptext">A square icon that is 200 x 200 pixels is ideal.</div>
                <div class="flex flex-align">
                    <upload class="image-upload" :url.sync="icon_url" type="image" />
                </div>
                <label>Application Banner</label>
                <div class="form-helptext">A banner that is 600 x 1200 pixels is ideal.</div>
                <upload class="image-upload" :url.sync="banner_url" type="image" />
            </div>
        </section>
        <section>
            <h2>LTI Behavior</h2>
            <div class="form-helptext">There is some LTI integration behavior that is specific to certain providers. You can customize this behavior here.</div>
            <div class="permissions-tree card">
                <div class="tree-root" v-for="(settings, provider) of properties" :key="provider" :class="{expanded: expanded[provider]}">
                    <div class="tree-title flex flex-align pointer" @click="expand(provider)">
                        <span class="icon" :class="{'iconoir-nav-arrow-right': !expanded[provider], 'iconoir-nav-arrow-down': expanded[provider]}"></span>
                        <div class="ff capitalize">{{ provider }}</div>
                    </div>
                    <div class="tree-entries" v-if="expanded[provider]">
                        <div class="tree-entry" v-for="setting of settings" :key="provider + setting.id">
                            <label class="checkbox-group flex flex-align" @click.stop="() => {}">
                                <input type="checkbox" class="block checkbox-input" :checked="get_lti_setting(provider, setting)" @input="(v) => set_lti_setting(provider, setting, !get_lti_setting(provider, setting))" />
                                <div class="flex flex-column">
                                    <div class="checkbox-title pointer">{{ setting.name }}</div>
                                    <div class="checkbox-description pointer">{{ setting.description }}</div>
                                </div>
                            </label>
                        </div>
                        <!-- <div class="tree-entry" v-for="(permissions, entity) of entities" @click="expand(`${provider}.${entity}`)" :key="entity">
                            <div class="tree-title flex flex-align pointer">
                                <span class="icon" :class="{'iconoir-nav-arrow-right': !expanded[`${provider}.${entity}`], 'iconoir-nav-arrow-down': expanded[`${provider}.${entity}`]}"></span>
                                <div class="ff capitalize">{{ entity }}</div>
                            </div>
                            <div class="tree-entries" v-if="expanded[`${provider}.${entity}`]">
                                <div class="tree-entry" v-for="permission of permissions" :key="permission.id">
                                    <label class="checkbox-group flex flex-align" @click.stop="() => {}">
                                        <input type="checkbox" class="block checkbox-input" :checked="has(permission.id)" @input="toggle(permission.id)" />
                                        <div class="checkbox-title pointer">{{ permission.name }}</div>
                                    </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
        <section v-if="admin">
            <h2>Customize SSO Page</h2>
            <div class="form-field">
                <label>Layout</label>
                <div class="form-helptext">The layout of the login page for your application.</div>
                <div class="enum flex flex-align">
                    <div class="enum-value" @click="update_login('login_page_layout', 'left-align')" :class="{ selected: login_page_layout === 'left-align' }">
                        Left Align
                    </div>
                    <div class="enum-value" @click="update_login('login_page_layout', 'center-align')" :class="{ selected: ['center-align', 'default'].includes(login_page_layout) || !login_page_layout }">
                        Center Align
                    </div>
                    <div class="enum-value" @click="update_login('login_page_layout', 'right-align')" :class="{ selected: login_page_layout === 'right-align' }">
                        Right Align
                    </div>
                </div>
            </div>
            <div class="form-field">
                <label>Background Type</label>
                <div class="form-helptext">The type of background displayed on the login page.</div>
                <div class="enum flex flex-align">
                    <div class="enum-value" @click="update_login('login_background_type', 'default')" :class="{ selected: login_background_type === 'default' || !login_background_type }">
                        Default
                    </div>
                    <div class="enum-value" @click="update_login('login_background_type', 'color')" :class="{ selected: login_background_type === 'color' }">
                        Color
                    </div>
                    <div class="enum-value" @click="update_login('login_background_type', 'pattern')" :class="{ selected: login_background_type === 'pattern' }">
                        Pattern
                    </div>
                    <div class="enum-value" @click="update_login('login_background_type', 'image')" :class="{ selected: login_background_type === 'image' }">
                        Image
                    </div>
                </div>
            </div>

            <div class="form-field" v-if="login_background_type === 'pattern'">
                <label>Pattern</label>
                <div class="form-helptext">The pattern for the background.</div>
                <select v-model="login_background_pattern" class="small">
                    <option :value="undefined" hidden>Select a pattern...</option>
                    <option :value="null" hidden>Select a pattern...</option>
                    <option value="topography">Topography</option>
                    <option value="moving-lines">Moving Lines</option>
                    <option value="hexagons">Hexagons</option>
                    <option value="clouds">Clouds</option>
                    <option value="autumn">Autumn</option>
                </select>
            </div>

            <div class="form-field" v-if="login_background_type === 'pattern'">
                <label>Login Page Text</label>
                <div class="form-helptext">The help text displayed when a user is prompted to login.</div>
                <textarea type="text" v-model="login_page_text" class="large"></textarea>
            </div>
            <template v-else-if="login_background_type === 'image'">
                <div class="form-field">
                    <label>Image</label>
                    <div class="form-helptext">A URL to the hosted image for the background.</div>
                    <upload :url="login_background_image" @input="value => update_login('login_background_image', value)" type="image" />
                </div>
                <div class="form-field">
                    <label>Background Size</label>
                    <div class="form-helptext">The type of background displayed on the login page.</div>
                    <div class="enum flex flex-align">
                        <div class="enum-value" @click="update_login('login_background_size', 'cover')" :class="{ selected: login_background_size === 'cover' || !login_background_size }">
                            Cover
                        </div>
                        <div class="enum-value" @click="update_login('login_background_size', 'repeat')" :class="{ selected: login_background_size === 'repeat' }">
                            Repeat
                        </div>
                        <div class="enum-value" @click="update_login('login_background_size', 'contain')" :class="{ selected: login_background_size === 'contain' }">
                            Contain
                        </div>
                    </div>
                </div>
                <!-- <div class="form-field">
                    <label>Blur</label>
                    <div class="form-helptext">Enable/disable blur for your custom background.</div>
                    <div class="flex">
                        <checkbox label="Enabled" :checked="unresolved_uuid_behavior" @input="update_unresolved_uuid"/>
                    </div>
                </div> -->
            </template>
        </section>
        <div class="flex save-form">
            <button type="submit" @click="update">Save Changes</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { has, get, set, isNumber } from 'lodash';
    import { WarningTriangle } from '@epiphany/iconoir';
    import validate_color from 'validate-color';

    export default {
        name: 'ApplicationCustomization',
        components: {
            WarningTriangle
        },
        data(){
            return {
                integrations: [],
                properties: {
                    canvas: {
                        account_navigation: {
                            name: 'Account Navigation Placement',
                            description: 'This displays your application under Account Navigation.',
                            id: 'account_navigation',
                            default: true
                        },
                        link_selection: {
                            name: 'Link Selection Placement',
                            description: 'This displays your application under Link Selection.',
                            id: 'link_selection',
                            default: true
                        },
                        user_navigation: {
                            name: 'User Navigation Placement',
                            description: 'This displays your application under User Navigation.',
                            id: 'user_navigation',
                            default: true
                        },
                        course_navigation: {
                            name: 'Course Navigation Placement',
                            description: 'This displays your application under Course Navigation.',
                            id: 'course_navigation',
                            default: true
                        },
                        global_application: {
                            name: 'Global Application',
                            description: 'This displays your application in all courses by default.',
                            id: 'global_application',
                            default: true
                        },
                        new_window: {
                            name: 'Open in New Window',
                            description: 'This makes your application open in a new window instead of an iframe.',
                            id: 'new_window',
                            default: true
                        },
                    }
                },
                expanded: {}
            };
        },
        methods: {
            update(){
                this.$store.dispatch('applications/update');
            },
            update_login(path, value){
                this[path] = value;
                this.update();
            },
            expand(slug){
                Vue.set(this.expanded, slug, !this.expanded[slug]);
            },
            get_lti_setting(provider, prop){
                return get(this.application, `properties.lti.${provider}.${prop.id}`, prop.default);
            },
            set_lti_setting(provider, prop, value){
                this.$store.commit('applications/update', { [`properties.lti.${provider}.${prop.id}`]: value });
                this.update();
            },
            validateColor(string) {
                if (!string) {
                    return true;
                }

                return validate_color(string);
            }
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            admin(){
                return this.$store.getters.user?.admin;
            },
            theme_color_primary: {
                get(){
                    return this.application.properties.theme_color_primary;
                },
                set(theme_color_primary){
                    this.$store.commit('applications/update', { 'properties.theme_color_primary': theme_color_primary });
                }
            },
            login_page_text: {
                get(){
                    return this.application.properties.login?.info_text;
                },
                set(login_page_text){
                    this.$store.commit('applications/update', { 'properties.login.info_text': login_page_text?.trim() });
                }
            },
            login_page_layout: {
                get(){
                    if (this.application.properties.login && this.application.properties.login.layout) {
                        return this.application.properties.login.layout;
                    } else {
                        return 'default';
                    }
                },
                set(layout){
                    this.$store.commit('applications/update', { 'properties.login.layout': layout });
                }
            },
            login_background_type: {
                get(){
                    if (this.application.properties.login && this.application.properties.login.background) {
                        return this.application.properties.login.background.type;
                    } else {
                        return 'default';
                    }
                },
                set(type){
                    this.$store.commit('applications/update', { 'properties.login.background.type': type });
                }
            },
            login_background_size: {
                get(){
                    if (this.application.properties.login && this.application.properties.login.background) {
                        return this.application.properties.login.background.size;
                    } else {
                        return 'cover';
                    }
                },
                set(size){
                    this.$store.commit('applications/update', { 'properties.login.background.size': size });
                }
            },
            login_background_pattern: {
                get(){
                    if (this.application.properties.login && this.application.properties.login.background && this.application.properties.login.background.type === 'pattern') {
                        return this.application.properties.login.background.pattern;
                    } else {
                        return null;
                    }
                },
                set(type){
                    this.$store.commit('applications/update', { 'properties.login.background.pattern': type });
                }
            },
            login_background_image: {
                get(){
                    if (this.application.properties.login && this.application.properties.login.background && this.application.properties.login.background.type === 'image') {
                        return this.application.properties.login.background.image;
                    } else {
                        return null;
                    }
                },
                set(type){
                    this.$store.commit('applications/update', { 'properties.login.background.image': type });
                }
            },
            icon_url: {
                get(){
                    return this.application.icon_url;
                },
                set(icon_url){
                    this.$store.commit('applications/update', { icon_url });
                }
            },
            banner_url: {
                get(){
                    return this.application.banner_url;
                },
                set(banner_url){
                    this.$store.commit('applications/update', { banner_url });
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .color-preview, .invalid-color {
        width: 25px;
        height: 25px;
        border-radius: 6px;
        margin-left: 10px;
        border: 1px solid @d;
    }

    .invalid-color {
        border: none;
    }

    .icon-preview {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        border-radius: 6px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .save-form {
        padding-left: 20px;
        padding-bottom: 20px;
    }
    
    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    h3
    {
        margin: 30px 0 20px;
        font-size: 20px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    h4
    {
        color: @black;
        font-size: 15px;
        margin-bottom: 6px;
    }

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    .enum-value
    {
        border: 1px solid fade(@black, 15%);
        // border: 1px solid @black;
        border-radius: 6px;
        margin-right: 10px;
        padding: 5px 8px;
        font-size: 14px;
        color: fade(@black, 75%);
        cursor: pointer;
        margin-top: 7px;
        margin-bottom: 7px;

        &.selected {
            border: 1px solid fade(@base, 75%);
            color: @base;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .image-upload
    {
        max-width: 315px;

        &.error {
            border-color: @red;
        }
    }

    .checkbox-description {
        color: @grey;
        font-weight: 400;
    }

    .permissions-tree
    {
        font-size: 13px;
        color: @black;
        font-weight: 400;
        line-height: 24px;
        max-width: 1000px;

        .tree-root
        {
            padding: 15px 20px;
            border-bottom: 1px solid @e4;

            &:last-child
            {
                border-bottom: 0;
            }
        }

        .tree-title
        {
            font-weight: 500;
            transform: opacity ease 0.2s;
            font-size: 14px;

            &:hover, &:active
            {
                opacity: 0.7;
            }
            
            .icon
            {
                font-size: 16px;
                width: 16px;
                height: 16px;
                line-height: 16px;
                display: block;
                margin: 0 2px 0 -4px;
            }
        }

        .tree-entry
        {
            margin: 10px 0 0;
            transform: opacity ease 0.2s;
        }

        .tree-entries
        {
            padding-left: 20px;

            .tree-entry
            {
                .tree-title
                {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        .tree-spacer
        {
            font-size: 11px;
            color: @grey;
            margin-top: 10px;
        }
    }
</style>
