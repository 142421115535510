import axios from 'axios';
import _ from 'lodash';

const state = {
    loading: true,
    parent: null,
    source: null,
    error: null,
    page: 'instructions',
    first_name: null,
    login: {
        email: '',
        password: '',
        two: '',
        error: {
            email: false,
            password: false,
            two: false
        }
    },
    application: null
};

const pages = ['instructions', 'login', 'password', 'two', 'configuration', 'complete'];

const mutations = {
    loading(state, loading) {
        state.loading = loading;
    },
    error(state, error) {
        state.error = error;
    },
    parent(state, parent) {
        state.parent = parent;
    },
    source(state, source) {
        state.source = source;
    },
    page(state, page) {
        state.page = page;
    },
    update(state, { path, value }) {
        if (state.source) {
            _.set(state.source, path, value);
        }
    },
    state(state, { path, value }) {
        _.set(state, path, value);
    },
    application(state, application) {
        state.application = application;
    }
};

const actions = {
    async initialize({ commit, state, rootState, dispatch }, parent_id) {
        commit('loading', true);

        // Check if the user is logged in.
        await rootState.user.initialize;

        // Load the parently available info.
        const response = await axios.get(`/reconnect/${parent_id}`);

        commit('parent', response.$data);

        if (rootState.user.id) {
            await dispatch('login', parent_id);
        }

        commit('loading', false);

        return state.source;
    },
    async login({ commit, state, rootState }, parent_id) {
        if (!rootState.user.id) {
            return;
        }

        if (!state.parent) {
            return;
        }

        commit('loading', true);

        await axios.get(`/teams/${state.parent.team.id}/sources/${state.parent.source ? state.parent.source.id : state.parent.id}`).then((response) => {
            // This is for Microsoft so it doesn't show that the admin account is already connected.
            _.unset(response.$data, 'configuration.tenant_id');

            // Commit the source.
            commit('source', response.$data);
        });

        if (!state.source) {
            return Promise.reject('Source not found.');
        }

        commit('loading', false);

        return state.source;
    },
    async next({ commit, state, dispatch, rootState }) {
        const index = pages.indexOf(state.page);

        if (index < pages.length - 1) {
            if (state.page === 'instructions') {
                if (rootState.user.id) {
                    commit('page', 'configuration');
                } else {
                    commit('page', 'login');
                }
                return;
            } else if (state.page === 'login') {
                if (!rootState.user.id) {
                    return;
                }
            } else if (state.page === 'configuration') {
                if (state.source.provider.requires_administrator_consent || state.source.provider.requires_administrator_login) {
                    if (!state.source.token && !state.source.tenant_id) {
                        return;
                    }
                }

                await dispatch('reconnect');
            }

            commit('page', pages[index + 1]);
        }
    },
    update({ commit, state, dispatch }, { path, value }) {
        commit('update', { path, value });
    },
    async reconnect({ commit, state, dispatch }) {
        if (state.source) {
            if (this.loading) {
                return;
            }

            commit('loading', true);

            // Dispatch a change to our save service.
            dispatch('save/save', 'reconnect/reconnect', { root: true });

            return (
                axios
                    .put(`/teams/${state.team_id}/sources/${state.source.id}`, state.source)
                    // .then((response) => commit('source', response.$data))
                    .then((response) => {
                        commit('loading', false);
                        dispatch('save/saved', 'reconnect/reconnect', { root: true });
                        return response.$data;
                    })
                    .catch((error) => {
                        dispatch('save/error', {}, { root: true });
                        commit('loading', false);
                        commit('error', error);
                        return Promise.reject(error);
                    })
            );
        } else {
            return Promise.reject('There is no active source.');
        }
    }
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
