import { render, staticRenderFns } from "./TransformationCard.vue?vue&type=template&id=5ef39282&scoped=true"
import script from "./TransformationCard.vue?vue&type=script&lang=js"
export * from "./TransformationCard.vue?vue&type=script&lang=js"
import style0 from "./TransformationCard.vue?vue&type=style&index=0&id=5ef39282&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef39282",
  null
  
)

export default component.exports