<template>
    <form class="delete-application-secret" @submit.prevent="send">
        <label>Confirm Secret Deletion</label>
        <div class="form-helptext">Enter <span>{{this.credential.name ? this.credential.name : 'delete'}}</span> to delete this secret.</div>
        <input v-model="secret_name" @input="check_name" ref="secret_name_input"/>
        <button type="submit" :disabled="disabled" :class="{ red: !disabled }">Delete Secret</button>
    </form>
</template>

<script>
    export default {
        name: 'DeleteApplicationSecret',
        props: {
            credential: Object
        },
        data(){
            return {
                disabled: true,
                secret_name: ''
            };
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            team(){
                return this.$store.getters.team;
            }
        },
        methods: {
            check_name(){
                this.disabled = !(this.secret_name.trim() === this.credential.name);
            },
            send(){
                this.$http.delete(`/teams/${this.team.id}/applications/${this.application.id}/credentials/${this.credential.id}`)
                .then(() => {
                    this.$toasted.success('Application secret removed.');
                    this.$emit('close');
                    this.$store.dispatch('applications/remove_secret', this.credential);
                })
                .catch(error => {
                    this.$toasted.error('There was an error removing the secret.');
                });
            }
        },
        async mounted() {
            await this.$nextTick();
            this.$refs.secret_name_input.focus();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .delete-application-secret
    {
        padding: 30px;

        label:first-child
        {
            margin-top: 0;
        }

        button
        {
            width: 100%;
            margin-top: 30px;
        }

        .form-helptext
        {
            span
            {
                font-weight: 500;
                background: fade(@black, 15%);
                color: @black;
                padding: 1px 4px;
                border-radius: 2px;
                display: inline-block;
            }
        }
    }
</style>