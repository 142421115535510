<template>
    <div class="profile flex flex-center flex-align">
        <img v-if="user && user.profile_image_url" :src="user.profile_image_url" />
        <div class="placeholder" v-else></div>
    </div>
</template>

<script>
    export default {
        name: 'Profile',
        props: {
            user: Object
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .profile
    {
        width: 30px;
        height: 30px;
        display: block;
    }

    .placeholder
    {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: url('~@/assets/icons/black/account.svg') center center no-repeat;
        background-size: 30px auto;
    }
</style>
