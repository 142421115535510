<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <FingerprintCircle class="icon" width="16" height="16" stroke-width="2" />
                    Data Privacy
                </div>
            </template>
        </breadcrumbs>
        <div>
            Upload DPA
            Download DPA
            Download SOC 2 reports
            Link to drata controls
            Link to privacy section of our blog
            List of fields that Edlink stores (in general and about their district)
            Exposure chart showing exposure to various application providers
            Information about audit logs
            Export data
            Data deletion request
        </div>
    </main>
</template>

<script>
    import { FingerprintCircle } from '@epiphany/iconoir';

    export default {
        name: 'Privacy',
        components: {
            FingerprintCircle
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>