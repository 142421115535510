<template>
    <div class="register flex">
        <div class="register-pane ff flex flex-align" :style="{top: offset + 'px'}">
            <a class="wordmark block" href="https://ed.link"></a>
            <div class="register-container bc">
                <h1>Get started</h1>
                <h3>Create a new account</h3>
                <div class="sso-options">
                    <div class="sso-option google white large button" @click="google">
                        Sign In With Google
                    </div>
                    <!-- <div class="sso-option microsoft white large button" @click="microsoft">
                        Sign In With Microsoft
                    </div> -->
                </div>
                <div class="or flex flex-align flex-center">
                    <div class="or-text">or</div>
                </div>
                <form @submit.prevent="register" autocomplete="off">
                    <label for="email">Email</label>
                    <input type="text" v-model="user.email" name="email" autocomplete="off" :class="{error: error.email}" :disabled="attempting" />
                    <label for="password">Password</label>
                    <password-strength type="password" v-model="user.password" name="password" autocomplete="off" :class="{error: error.password}" :disabled="attempting" />
                    <button type="submit" class="large" :disabled="attempting">Create Account</button>
                    <div class="form-error center" v-if="error.message">{{error.message}}</div>
                </form>
                <div class="form-disclaimer">
                    By creating an account, you are agreeing to our <a class="dashed-button" href="https://ed.link/docs/legal/terms" target="_blank">Terms of Service</a> and <a class="dashed-button" href="https://ed.link/docs/legal/privacy" target="_blank">Privacy Policy</a>.
                </div>
            </div>
        </div>
        <div class="register-background"></div>
    </div>
</template>

<script>
    import querystring from 'querystring';

    export default {
        name: 'Register',
        data(){
            return {
                error: {
                    message: '',
                    email: false,
                    password: false
                },
                attempting: false,
                user: {
                    email: '',
                    password: ''
                }
            };
        },
        created(){
            if(this.$route.query.email){
                this.user.email = this.$route.query.email;
            }
        },
        computed: {
            offset(){
                return this.$store.getters.banners.reduce((total, banner) => {
                    return total + banner.height;
                }, 0);
            }
        },
        methods: {
            register(){
                this.error.email = false;
                this.error.password = false;
                this.error.message = '';

                if(!this.user.email){
                    return this.error.email = true;
                }

                if(!this.user.password){
                    return this.error.password = true;
                }

                this.attempting = true;

                this.$http.post('/register', this.user)
                .then(response => this.$store.dispatch('user/login', response.$data.token))
                .then(() => this.$router.push('/onboard'))
                .catch(error => {
                    this.attempting = false;
                    this.error.message = 'There was an error creating your account.';
                });
            },
            google(){
                const properties = {
                    state: Math.random().toString(36).slice(2),
                    redirect_uri: process.env.NODE_ENV === 'production' ? 'https://ed.link/api/v1/sso/google' : 'http://localhost:9900/api/v1/sso/google',
                    client_id: '563820043496-o65vgllud5rrstbf8tg0rltlm5pbg868.apps.googleusercontent.com',
                    scope: 'email profile',
                    response_type: 'code'//,
                    // prompt: 'none'
                };

                window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${querystring.stringify(properties)}`;
            },
            microsoft(){

            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .wordmark
    {
        height: 25px;
        background: url('~@/assets/edlink-diamonds-black.svg') 0 0 no-repeat;
        background-size: 120px auto;
        color: transparent;
        width: 120px;
        position: absolute;
        top: 30px;
        left: 30px;
    }

    .register
    {
        min-height: 100vh;
    }

    .register-pane
    {
        background: @f;
        min-width: 400px;
        border-right: 1px solid @e;

        h1
        {
            font-size: 32px;
            line-height: 40px;
        }

        h3
        {
            margin-top: 5px;
            font-weight: 400;
        }

        .register-container
        {
            width: 100%;
            max-width: 400px;
        }
    }

    .register-background
    {
        background: @f8;
        flex: 1.5;
    }

    form
    {
        input
        {
            margin-bottom: 10px;
        }

        button.large
        {
            width: 100%;
            margin-top: 30px;
            height: 44px;
            line-height: 42px;
        }
    }

    .sso-options
    {
        margin-top: 40px;
    }
    
    .sso-option.white.large.button
    {
        height: 44px;
        border-radius: 5px;
        line-height: 44px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: @black;
        padding: 0;
        text-align: center;
        margin-bottom: 15px;

        &.google
        {
            background-image: url('~@/assets/icons/source/google.svg');
            background-position: 12px 11px;
            background-repeat: no-repeat;
            background-size: 21px auto;
        }

        &.microsoft
        {
            background-image: url('~@/assets/icons/source/microsoft.svg');
            background-position: -10px 11px;
            background-repeat: no-repeat;
            background-size: 62px auto;
        }
    }

    .or
    {
        height: 15px;
        margin: 25px 0;

        &::after
        {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: @black;
            opacity: 0.1;
            position: absolute;
            top: 8px;
        }

        .or-text
        {
            color: @grey;
            font-size: 12px;
            background: @f;
            padding: 0 6px;
            line-height: 15px;
            z-index: 2;
        }
    }

    .form-disclaimer
    {
        padding-bottom: 0;
    }
</style>