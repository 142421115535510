<template>
    <div class="entity-value flex flex-column">
        <div class="results card">
            <div class="add-entity flex flex-align" :class="{ empty: values_copy.length === 0 }">
                <Search class="icon" width="14" height="14" stroke-width="2" />
                <autocomplete
                    ref="input"
                    class="block ff"
                    :values="values_copy"
                    @input="updateValues"
                    :timeout="200"
                    :search="queueSearch"
                    :results.sync="search_results"
                    :format="format"
                    :max="max"
                    :placeholder="'Search for a ' + target"
                    :borderless="true"
                    :show_tokens="false"
                />
            </div>
            <template v-if="values_copy.length">
                <div v-if="loading" class="loading flex flex-align flex-center">
                    <spinner />
                </div>
                <template v-else>
                    <div class="entity flex flex-align" v-for="(entity, k) of selected_entities" :key="k">
                        <Check class="entity-selected icon" width="16" height="16" stroke-width="2" />
                        <div class="flex flex-column ff">
                            <div class="entity-title flex flex-align">
                                <div class="text-overflow">{{ name(entity) }}</div>
                                <div v-if="!entities[entity.id]" class="tooltip tooltip-top" :tooltip="`This ${target} may have been deleted or unshared`">
                                    <InfoCircle class="icon" width="12" height="12" stroke-width="2.5" />
                                </div>
                            </div>
                            <div class="entity-id text-overflow">{{ entity.id }}</div>
                        </div>
                        <div class="remove-entity" @click="remove(entity)">
                            <Cancel class="icon" width="16" height="16" stroke-width="2" />
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { InfoCircle, Search, Check, Cancel } from '@epiphany/iconoir';

const plural_map = {
    agent: 'agents',
    class: 'classes',
    course: 'courses',
    enrollment: 'enrollments',
    person: 'people',
    teacher: 'people',
    school: 'schools',
    section: 'sections',
    session: 'sessions',
    product: 'products'
};

export default {
    name: 'PreviewValue',
    props: {
        property: String,
        placeholder: String,
        array: Boolean,
        values: Array,
        target: String,
        schema: String
    },
    components: {
        InfoCircle,
        Search,
        Check,
        Cancel
    },
    created() {
        this.initialize();
    },
    mounted() {
        this.values_copy = this.values.slice();
    },
    destroyed() {
        if (this.load_timeout) {
            clearTimeout(this.load_timeout);
        }
    },
    data() {
        return {
            search_queue: [],
            search_results: [],
            entities: {},
            values_copy: [],
            load_timeout: null,
            loading: true
        };
    },
    computed: {
        max() {
            return this.array ? Infinity : 1;
        },
        team() {
            return this.$store.getters.team;
        },
        integration() {
            return this.$store.getters.integration;
        },
        selected_entities() {
            const selected = {};

            for (const value of this.values_copy) {
                if (this.entities[value]) {
                    Vue.set(selected, value, this.entities[value]);
                } else {
                    Vue.set(selected, value, { id: value });
                }
            }

            return selected;
        },
        parent(){
            return this.schema === 'enriched' ? `/teams/${this.team.id}/sources/${this.integration.source.id}` : `/teams/${this.team.id}/integrations/${this.integration.id}`;
        }
    },
    methods: {
        name(entity) {
            if (this.entities[entity.id]) {
                if (['person', 'teacher'].includes(this.target)) {
                    return entity.display_name ? entity.display_name : 'No Display Name';
                } else {
                    return entity.name ? entity.name : `Unnamed ${this.target}`;
                }
            } else {
                return `Unknown ${this.target}`;
            }
        },
        initialize() {
            // If we successfully load the entities, resolve the promise.
            this.loadEntities();
        },
        loadEntities() {
            const type = this.target === 'teachers' ? 'people' : plural_map[this.target];

            return this.$http
                .get(`${this.parent}/${type}`, {
                    baseURL: '/api/v2',
                    params: {
                        $filter: {
                            id: [{ operator: 'in', value: this.values.join(',') }]
                        },
                        $fields: type === 'people' ? 'id,display_name' : 'id,name',
                        $schema: this.schema
                    }
                })
                .then(({ $data: entities }) => {
                    for (const entity of entities) {
                        Vue.set(this.entities, entity.id, entity);
                    }

                    this.loading = false;
                    // The search queue may have been populated while we were loading.
                    this.flushQueue();
                });
        },
        async flushQueue(){
            // Execute the most recent search event
            this.executeSearch(...this.search_queue.pop());
            // Clear the search queue
            this.search_queue = [];
        },
        async executeSearch(input, values = this.values_copy){
            if (this.load_timeout) {
                clearTimeout(this.load_timeout);
            }

            const type = this.target === 'teachers' ? 'people' : plural_map[this.target];

            const $filter = {
                id: [{ operator: 'not in', value: values.join(',') }]
            };

            // This name changes for people.
            if (input && input.trim() !== '') {
                $filter[type === 'people' ? 'display_name' : 'name'] = [{ operator: 'contains', value: input }];
            }

            this.load_timeout = this.$http
                .get(`${this.parent}/${type}`, {
                    baseURL: '/api/v2',
                    params: {
                        $filter,
                        $fields: type === 'people' ? 'id,display_name' : 'id,name',
                        $schema: this.schema
                    }
                })
                .then(({ $data: entities }) => {
                    for (const entity of entities) {
                        Vue.set(this.entities, entity.id, entity);
                    }

                    this.search_results = entities.map((e) => e.id);
                })
                .catch(() => {
                    this.$toasted.error('There was an error loading data.');

                    // If we have an error, just return an empty array to be safe.
                    this.search_results = [];
                });

            return this.load_timeout;
        },
        async queueSearch(input, values = this.values_copy) {
            // If dataset not ready push search event to queue
            if (this.loading) {
                this.search_queue.push([input, values]);
            } else {
                // If dataset ready immediately search
                this.executeSearch(input, values);
                // Clear the search queue
                this.search_queue = [];
            }
        },
        remove(entity) {
            this.values_copy = this.values_copy.filter((v) => v !== entity.id);
        },
        updateValues(values) {
            this.values_copy = values.slice();
        },
        format(value) {
            const type = this.target === 'teachers' ? 'people' : plural_map[this.target];
            return this.entities[value][type === 'people' ? 'display_name' : 'name'];
        }
    },
    watch: {
        values_copy(updated) {
            this.$emit('update:values', updated);
            this.$emit('input', updated);
        }
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.results {
    border-radius: 6px;
}

.entity {
    padding: 5px 5px 5px 0;
    margin-left: 42px;
    height: 46px;
    border-bottom: 1px solid @e4;

    .remove-entity
    {
        color: @grey;
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 6px;
        border-radius: @border-radius - 2px;

        &:hover {
            background-color: @red;
            color: @f;
        }
    }

    .entity-selected
    {
        color: @grey;
        width: 16px;
        height: 16px;
        position: absolute;
        left: -28px;
    }

    &:last-child {
        border-bottom: none;
    }
}

.entity-title {
    font-size: 13px;
    line-height: 16px;
    height: 16px;
    margin-bottom: 2px;
    color: @black;
    font-weight: 500;

    .tooltip {
        margin-left: 4px;
        height: 12px;
    }

    .icon
    {
        width: 12px;
        height: 12px;
    }
}

.entity-id {
    font-size: 10px;
    line-height: 14px;
    color: @grey;
    border-radius: 4px;
}

.add-entity {
    color: @grey;
    transition: all ease-in-out 0.1s;
    font-size: 14px;
    padding: 5px 10px;
    border-bottom: 1px solid @e4;

    &.empty {
        border-bottom: 0;
    }

    .iconoir-search {
        display: inline-block;
        font-size: 18px;
        margin-right: 10px;
    }
}

.loading {
    padding: 10px;
}

.entity-value {
    .badge {
        max-width: 180px;
        margin: 2px;
    }
}

::v-deep .autocomplete {
    .autocomplete-search {
        border: 0;
        min-height: 30px;
        height: 30px;
        padding: 0;

        input {
            margin: 0;
            font-size: 13px;
            padding: 0;
            color: @black;
        }

        &:focus-within,
        &:active {
            box-shadow: none;
        }
    }
}

input.autocomplete-input {
    margin-left: 0;
    padding-left: 0;
}
</style>
