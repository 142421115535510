import Vue from 'vue';
import Router from 'vue-router';

import user from './middleware/user';
import authenticated from './middleware/authenticated';
import anonymous from './middleware/anonymous';
import teams from './middleware/teams';
import team from './middleware/team';
import developer from './middleware/developer';
import district from './middleware/district';
import write from './middleware/write';
import admin from './middleware/admin';
import providers from './middleware/providers';
import permissions from './middleware/permissions';

import { redirects } from './redirects';

import Login from './views/Login.vue';
import Register from './views/Register.vue';
import VerificationLanding from './views/VerificationLanding.vue';
import Forgot from './views/Forgot.vue';
import Reset from './views/Reset.vue';
import Onboard from './views/Onboard.vue';
import Dashboard from './views/Dashboard.vue';
import Team from './views/Team.vue';
import Applications from './views/Applications.vue';
import Integrations from './views/Integrations.vue';
import Onboardings from './views/Onboardings.vue';
import Products from './views/Products.vue';
import Sources from './views/Sources.vue';
import Documentation from './views/Documentation.vue';
import DocumentationIntroduction from './views/DocumentationIntroduction.vue';
import DocumentationChangelog from './views/DocumentationChangelog.vue';
import Document from './views/Document.vue';
import Logs from './views/Logs.vue';
import Request from './views/Request.vue';
import Settings from './views/Settings.vue';
import Billing from './views/Billing.vue';
import Privacy from './views/Privacy.vue';
import CreateSource from './views/CreateSource.vue';

import DemoApp from './views/demo/DemoApp.vue';
import DemoClasses from './views/demo/DemoClasses';
import DemoClass from './views/demo/DemoClass';
import DemoAuth from './views/demo/DemoAuth.vue';
import DemoLogin from './views/demo/DemoLogin.vue';
import DemoAssignment from './views/demo/DemoAssignment.vue';
import DemoSubmission from './views/demo/DemoSubmission.vue';

import Admin from './views/admin/Admin.vue';
import AdminOverview from './views/admin/AdminOverview.vue';
import AdminTeams from './views/admin/AdminTeams.vue';
import AdminApplications from './views/admin/AdminApplications.vue';
import AdminSources from './views/admin/AdminSources.vue';
import AdminIntegrations from './views/admin/AdminIntegrations.vue';
import AdminPeople from './views/admin/AdminPeople.vue';
import AdminPerson from './views/admin/AdminPerson.vue';
import AdminPersonOverview from './views/admin/AdminPersonFiles/AdminPersonOverview.vue';
import AdminPersonIntegrations from './views/admin/AdminPersonFiles/AdminPersonIntegrations.vue';
import AdminPersonLogins from './views/admin/AdminPersonFiles/AdminPersonLogins.vue';
import AdminPersonChanges from './views/admin/AdminPersonFiles/AdminPersonChanges.vue';
import AdminPersonChange from './views/admin/AdminPersonFiles/AdminPersonChange.vue';
import AdminPersonRequests from './views/admin/AdminPersonFiles/AdminPersonRequests.vue';
import AdminPersonDetail from './views/admin/AdminPersonFiles/AdminPersonDetail.vue';

import AdminSync from './views/admin/AdminSync.vue';
import AdminFailures from './views/admin/AdminFailures.vue';
import AdminFlow from './views/admin/AdminFlow.vue';
import AdminBlink from './views/admin/AdminBlink.vue';
import AdminQueue from './views/admin/AdminQueue.vue';

import AdminBilling from './views/admin/AdminBilling.vue';
import AdminInvoices from './views/admin/AdminInvoices.vue';
import AdminPlans from './views/admin/AdminPlans.vue';
import AdminContracts from './views/admin/AdminContracts.vue';

import Entities from './views/admin/Entities.vue';
// import Entity from './views/admin/entity/Entity.vue';
// import EntityOverview from './views/admin/entity/EntityOverview.vue';
// import EntityActivity from './views/admin/entity/EntityActivity.vue';
// import EntitySources from './views/admin/entity/EntitySources.vue';
// import EntityIntegrations from './views/admin/entity/EntityIntegrations.vue';
// import CreateEntity from './views/admin/entity/CreateEntity.vue';

import Source from './views/source/Source.vue';
import SourceOverview from './views/source/SourceOverview.vue';
import SourceSettings from './views/source/SourceSettings.vue';
import SourceMapping from './views/source/SourceMapping.vue';
import SourceSyncs from './views/source/SourceSyncs.vue';
import SourceEnrichments from './views/source/SourceEnrichments.vue';
import SourceSync from './views/source/SourceSync.vue';
import SourceDataset from './views/source/SourceDataset.vue';

import Integration from './views/integration/Integration.vue';
import IntegrationOverview from './views/integration/IntegrationOverview.vue';
import IntegrationDataset from './views/integration/IntegrationDataset.vue';
import IntegrationSharing from './views/integration/IntegrationSharing.vue';
import IntegrationLicensing from './views/integration/IntegrationLicensing.vue';
import IntegrationValidation from './views/integration/IntegrationValidation.vue';
import IntegrationFlowSettings from './views/integration/IntegrationFlowSettings.vue';
import IntegrationPrivacy from './views/integration/IntegrationPrivacy.vue';

import Application from './views/application/Application.vue';
import ApplicationOverview from './views/application/ApplicationOverview.vue';
import ApplicationFlags from './views/application/ApplicationFlags.vue';
import ApplicationFeeds from './views/application/ApplicationFeeds.vue';
import ApplicationSettings from './views/application/ApplicationSettings.vue';
import ApplicationSSO from './views/application/ApplicationSSO.vue';
import ApplicationCustomization from './views/application/ApplicationCustomization.vue';
import ApplicationValidation from './views/application/ApplicationValidation.vue';
import ApplicationWebhooks from './views/application/ApplicationWebhooks.vue';
import ApplicationProviders from './views/application/ApplicationProviders.vue';
import ApplicationSharing from './views/application/ApplicationSharing.vue';

import Integrate from './views/Integrate.vue';
import CustomAdminOnboarding from './views/custom/CustomAdminOnboarding.vue';
import Reconnect from './views/Reconnect.vue';
import IntegrationTransformations from './views/integration/IntegrationTransformations.vue';
import IntegrationOverrides from './views/integration/IntegrationOverrides.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: redirects.concat([
        {
            path: '',
            name: 'home',
            meta: {
                middleware: [user, teams, authenticated, team]
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                middleware: [user, anonymous]
            }
        },
        {
            path: '/login/:token',
            name: 'login.token',
            component: Login,
            meta: {
                middleware: [user, anonymous]
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                middleware: [user, anonymous]
            }
        },
        {
            path: '/forgot',
            name: 'forgot',
            component: Forgot,
            meta: {
                middleware: [user, anonymous]
            }
        },
        {
            path: '/reset/:code',
            name: 'reset',
            component: Reset,
            meta: {
                middleware: [user, anonymous]
            }
        },
        {
            path: '/onboard',
            name: 'onboard',
            component: Onboard,
            meta: {
                middleware: [user, authenticated]
            }
        },
        {
            path: '/verify/:code',
            name: 'verify.landing',
            component: VerificationLanding
        },
        {
            path: '/demo/code',
            name: 'demo.auth',
            component: DemoAuth,
            meta: {
                middleware: [user]
            }
        },
        {
            path: '/demo',
            component: DemoApp,
            children: [
                {
                    path: '/',
                    name: 'demo',
                    redirect: 'classes'
                },
                {
                    path: 'classes',
                    name: 'demo.classes',
                    component: DemoClasses
                },
                {
                    path: 'classes/:class',
                    name: 'demo.class',
                    component: DemoClass
                },
                {
                    path: 'classes/:class/assignments/:assignment',
                    name: 'demo.class.assignment',
                    component: DemoAssignment
                },
                {
                    path: 'classes/:class/assignments/:assignment/submissions/:submission',
                    name: 'demo.class.assignment.submission',
                    component: DemoSubmission
                }
            ]
        },
        {
            path: '/demo/login',
            name: 'demo.login',
            component: DemoLogin
        },
        {
            path: '/demo/:integration',
            name: 'demo.login.integration',
            component: DemoLogin,
            meta: {
                middleware: [user]
            }
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin,
            redirect: '/admin/overview',
            meta: {
                middleware: [providers, user, authenticated, admin]
            },
            children: [
                {
                    path: 'overview',
                    name: 'admin.overview',
                    component: AdminOverview
                },
                {
                    path: 'entities',
                    name: 'admin.entities',
                    component: Entities
                },
                {
                    path: 'sync',
                    name: 'admin.sync',
                    component: AdminSync
                },
                {
                    path: 'failures',
                    name: 'admin.failures',
                    component: AdminFailures
                },
                {
                    path: 'teams',
                    name: 'admin.teams',
                    component: AdminTeams
                },
                {
                    path: 'applications',
                    name: 'admin.applications',
                    component: AdminApplications
                },
                {
                    path: 'sources',
                    name: 'admin.sources',
                    component: AdminSources
                },
                {
                    path: 'integrations',
                    name: 'admin.integrations',
                    component: AdminIntegrations
                },
                {
                    path: 'people',
                    name: 'admin.people',
                    component: AdminPeople
                },
                {
                    path: 'billing',
                    name: 'admin.billing',
                    component: AdminBilling,
                    redirect: '/admin/billing/invoices',
                    children: [
                        {
                            path: 'invoices',
                            name: 'admin.billing.invoices',
                            component: AdminInvoices
                        },
                        {
                            path: 'invoices/:invoice',
                            name: 'admin.billing.invoice',
                            // redirect to invoices list with id as hash
                            redirect: (to) => {
                                return {
                                    name: 'admin.billing.invoices',
                                    hash: `#invoice/${to.params.invoice}`
                                };
                            }
                        },
                        {
                            path: 'plans',
                            name: 'admin.billing.plans',
                            component: AdminPlans
                        },
                        {
                            path: 'contracts',
                            name: 'admin.billing.contracts',
                            component: AdminContracts
                        }
                    ]
                },
                {
                    path: 'people/:person_id',
                    name: 'admin.person',
                    component: AdminPerson,
                    redirect: '/admin/people/:person_id/overview',
                    children: [
                        {
                            path: 'overview',
                            name: 'admin.person.overview',
                            component: AdminPersonOverview
                        },
                        {
                            path: 'integrations',
                            name: 'admin.person.integrations',
                            component: AdminPersonIntegrations
                        },
                        {
                            path: 'logins',
                            name: 'admin.person.logins',
                            component: AdminPersonLogins
                        },
                        {
                            path: 'changes',
                            name: 'admin.person.changes',
                            component: AdminPersonChanges
                        },
                        {
                            path: 'requests',
                            name: 'admin.person.requests',
                            component: AdminPersonRequests
                        },
                        {
                            path: 'changes/:change',
                            name: 'admin.person.change',
                            component: AdminPersonChange
                        },
                        {
                            path: 'detail',
                            name: 'admin.person.detail',
                            component: AdminPersonDetail
                        }
                    ]
                },
                {
                    path: 'flow',
                    name: 'admin.flow',
                    component: AdminFlow
                },
                {
                    path: 'queue',
                    name: 'admin.queue',
                    component: AdminQueue
                },
                {
                    path: 'blink',
                    name: 'admin.blink',
                    component: AdminBlink
                }
            ]
        },
        {
            path: '/integrate/:application',
            component: Integrate,
            name: 'integrate',
            meta: {
                middleware: [providers, permissions, user]
            }
        },
        {
            path: '/onboard/:onboarding',
            component: Integrate,
            name: 'integrate.onboarding',
            meta: {
                middleware: [providers, permissions, user]
            }
        },
        {
            path: '/reconnect/:parent',
            component: Reconnect,
            name: 'reconnect',
            meta: {
                middleware: [providers, permissions, user]
            }
        },
        {
            path: '/docs',
            component: Documentation,
            redirect: '/docs/introduction',
            meta: {
                middleware: [user, teams, providers, permissions]
            },
            children: [
                {
                    path: 'introduction',
                    name: 'docs.introduction',
                    component: DocumentationIntroduction
                },
                {
                    path: 'changelog',
                    name: 'docs.changelog',
                    component: DocumentationChangelog
                },
                {
                    path: 'administrators/:provider',
                    redirect: (to) => {
                        // This is to handle some legacy routes that may be linked to via some old documentation.
                        return `/docs/schools/connect/${to.params.provider}`;
                    }
                },
                {
                    path: 'api/v2.0/models/external/:model',
                    name: 'docs.document.models.education',
                    redirect: (to) => {
                        return 'api/v2.0/models/education/' + to.params.model;
                    },
                    component: Document
                },
                {
                    path: 'api/v2.0/models/internal/:model',
                    name: 'docs.document.models.meta',
                    redirect: (to) => {
                        return 'api/v2.0/models/meta/' + to.params.model;
                    },
                    component: Document
                },
                {
                    path: ':path(.*)',
                    name: 'docs.document',
                    component: Document
                }
            ]
        },
        {
            path: '/:application/onboard/:provider/:token',
            component: CustomAdminOnboarding,
            name: 'custom.onboard.login',
            meta: {
                middleware: []
            }
        },
        {
            path: '/:application/onboard/:provider',
            component: CustomAdminOnboarding,
            name: 'custom.onboard',
            meta: {
                middleware: []
            }
        },
        {
            path: '/:team',
            component: Team,
            redirect: '/:team/dashboard',
            meta: {
                middleware: [user, teams, providers, permissions, authenticated, team]
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'team.dashboard',
                    component: Dashboard,
                    meta: {
                        middleware: [developer]
                    }
                },
                {
                    path: 'applications',
                    name: 'team.applications',
                    component: Applications,
                    meta: {
                        middleware: [developer]
                    }
                },
                {
                    path: 'sources',
                    name: 'team.sources',
                    component: Sources,
                    meta: {
                        middleware: [district]
                    }
                },
                {
                    path: 'sources/create',
                    name: 'team.sources.create',
                    component: CreateSource,
                    meta: {
                        middleware: [district, write]
                    }
                },
                {
                    path: 'sources/:source',
                    name: 'team.sources.source',
                    redirect: '/:team/sources/:source/overview',
                    component: Source,
                    meta: {
                        middleware: [district]
                    },
                    children: [
                        {
                            path: 'overview',
                            name: 'team.sources.source.overview',
                            component: SourceOverview
                        },
                        {
                            path: 'syncs',
                            name: 'team.sources.source.syncs',
                            component: SourceSyncs
                        },
                        {
                            path: 'enrichments',
                            name: 'team.sources.source.enrichments',
                            component: SourceEnrichments
                        },
                        {
                            path: 'syncs/:sync',
                            name: 'team.sources.source.sync',
                            component: SourceSync
                        },
                        {
                            path: 'mapping',
                            name: 'team.sources.source.mapping',
                            component: SourceMapping
                        },
                        {
                            path: 'settings',
                            name: 'team.sources.source.settings',
                            component: SourceSettings
                        },
                        {
                            path: ':type',
                            name: 'team.sources.source.dataset',
                            component: SourceDataset
                        }
                    ]
                },
                {
                    path: 'applications/:application',
                    name: 'team.applications.application',
                    redirect: '/:team/applications/:application/overview',
                    component: Application,
                    meta: {
                        middleware: [developer]
                    },
                    children: [
                        {
                            path: 'overview',
                            name: 'team.applications.application.overview',
                            component: ApplicationOverview
                        },
                        {
                            path: 'sharing',
                            name: 'team.applications.application.sharing',
                            component: ApplicationSharing
                        },
                        {
                            path: 'customization',
                            name: 'team.applications.application.customization',
                            component: ApplicationCustomization
                        },
                        {
                            path: 'sso',
                            name: 'team.applications.application.sso',
                            component: ApplicationSSO
                        },
                        {
                            path: 'validation',
                            name: 'team.applications.application.validation',
                            component: ApplicationValidation
                        },
                        {
                            path: 'flags',
                            name: 'team.applications.application.flags',
                            component: ApplicationFlags
                        },
                        {
                            path: 'providers',
                            name: 'team.applications.application.providers',
                            component: ApplicationProviders
                        },
                        {
                            path: 'webhooks',
                            name: 'team.applications.application.webhooks',
                            component: ApplicationWebhooks
                        },
                        {
                            path: 'feeds',
                            name: 'team.applications.application.feeds',
                            component: ApplicationFeeds
                        },
                        {
                            path: 'settings',
                            name: 'team.applications.application.settings',
                            component: ApplicationSettings
                        }
                    ]
                },
                {
                    path: 'integrations/:integration',
                    name: 'team.integrations.integration',
                    redirect: '/:team/integrations/:integration/overview',
                    component: Integration,
                    meta: {
                        middleware: [authenticated, team]
                    },
                    children: [
                        {
                            path: 'overview',
                            name: 'team.integrations.integration.overview',
                            component: IntegrationOverview
                        },
                        {
                            path: 'sharing',
                            name: 'team.integrations.integration.sharing',
                            component: IntegrationSharing
                        },
                        {
                            path: 'licensing',
                            name: 'team.integrations.integration.licensing',
                            component: IntegrationLicensing
                        },
                        {
                            path: 'privacy',
                            name: 'team.integrations.integration.privacy',
                            component: IntegrationPrivacy
                        },
                        {
                            path: 'flow',
                            name: 'team.integrations.integration.flow',
                            component: IntegrationFlowSettings
                        },
                        {
                            path: 'validation',
                            name: 'team.integrations.integration.validation',
                            component: IntegrationValidation
                        },
                        {
                            path: 'transformations',
                            name: 'team.integrations.integration.transformations',
                            component: IntegrationTransformations
                        },
                        {
                            path: 'overrides',
                            name: 'team.integrations.integration.overrides',
                            component: IntegrationOverrides
                        },
                        {
                            path: ':type',
                            name: 'team.integrations.integration.dataset',
                            component: IntegrationDataset
                        }
                    ]
                },
                {
                    path: 'integrations',
                    name: 'team.integrations',
                    component: Integrations
                },
                {
                    path: 'onboardings',
                    name: 'team.onboardings',
                    component: Onboardings
                },
                {
                    path: 'products',
                    name: 'team.products',
                    component: Products,
                    meta: {
                        middleware: [developer]
                    }
                },
                {
                    path: 'logs',
                    name: 'team.logs',
                    component: Logs
                },
                {
                    path: 'logs/:request',
                    name: 'team.logs.request',
                    component: Request
                },
                {
                    path: 'logs/:inbound/outbound/:request',
                    name: 'team.logs.request.outbound',
                    component: Request
                },
                {
                    path: 'settings',
                    component: Settings,
                    name: 'team.settings'
                },
                {
                    path: 'privacy',
                    component: Privacy,
                    name: 'team.privacy'
                },
                {
                    path: 'billing',
                    component: Billing,
                    name: 'team.billing'
                }
            ]
        }
    ])
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        // context.next(...parameters);
        // Than run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

/**
 * Before the user loads the given route, run any middlewares set on it (mostly
 * to enforce various forms of authentication, to ensure that the current user
 * should have access to the given page).
 */
router.beforeEach((to, from, next) => {
    const middleware = [];

    for (const match of to.matched) {
        if (match.meta.middleware) {
            middleware.push(...(Array.isArray(match.meta.middleware) ? match.meta.middleware : [match.meta.middleware]));
        }
    }

    if (middleware.length) {
        const context = { from, next, router, to };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
