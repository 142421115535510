<template>
    <div class="application-settings scroll full" v-if="application">
        <section>
            <h2>Authorized Data Providers</h2>
            <h4>
                This section allows you to select specific data providers that you want a district to be able to connect.
                Removing a provider from this list will not affect any districts who are already integrated with {{application.name}}.
            </h4>
            <div class="flex card">
                <div class="ff column">
                    <h3>Learning Management Systems</h3>
                    <template v-for="provider of lms">
                        <div class="provider" :key="provider.id">
                            <label class="checkbox-group flex flex-align">
                                <input type="checkbox" class="block checkbox-input" :checked="has(provider.id)" @input="toggle(provider.id)" />
                                <div class="checkbox-title">{{provider.name}}</div>
                            </label>
                        </div>
                    </template>
                </div>
                <div class="ff column">
                    <h3>Student Information Systems</h3>
                    <template v-for="provider of sis">
                        <div class="provider" :key="provider.id">
                            <label class="checkbox-group flex flex-align">
                                <input type="checkbox" class="block checkbox-input" :checked="has(provider.id)" @input="toggle(provider.id)" />
                                <div class="checkbox-title">{{provider.name}}</div>
                            </label>
                        </div>
                    </template>
                </div>
                <div class="ff column">
                    <h3>Identity Management Systems</h3>
                    <template v-for="provider of idms">
                        <div class="provider" :key="provider.id">
                            <label class="checkbox-group flex flex-align">
                                <input type="checkbox" class="block checkbox-input" :checked="has(provider.id)" @input="toggle(provider.id)" />
                                <div class="checkbox-title">{{provider.name}}</div>
                            </label>
                            <label class="checkbox-group flex flex-align indent" v-if="provider.application === 'clever'">
                                <input class="checkbox-input" type="checkbox" :checked="application.valid_anonymous_providers.includes(provider.id)" @input="toggle_anonymous_provider(provider)" />
                                <div class="checkbox-title ff">Allow Clever Library Users</div>
                            </label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="form-options flex">
                <div class="button" @click="update">Save Configuration</div>
            </div>
        </section>
        <section>
            <h2>Clever Configuration</h2>
            <h4>
                This section allows you to associate this Edlink application with an existing Clever application.
                Edlink will automatically create and configure your integrated Clever districts.
                This configuration will enable both Clever Rostering (if you are an existing Clever development partner)
                and Clever Instant Login.
            </h4>
            <div class="card external-provider" :class="{active: application.clever_enabled}">
                <div class="external-provider-header">
                    <label class="flex flex-align">
                        <toggle :active="application.clever_enabled" @change="() => toggle_external_provider('clever')" />
                        <div class="external-provider-name ff">Enable Clever Sync</div>
                    </label>
                </div>
                <form autocomplete="off" @submit.prevent="save_clever" class="external-provider-configuration">
                    <label>Clever Client ID</label>
                    <input name="clever_client_id" type="password" class="large" v-model="clever_client_id" autocomplete="off" />
                    <label>Clever Client Secret</label>
                    <input name="clever_client_secret" type="password" class="large" v-model="clever_client_secret" autocomplete="off" />
                    <label>Clever API Version</label>
                    <select name="clever_api_version" class="small" v-model="clever_api_version">
                        <option value="2.1">API v2.1</option>
                        <option value="3.0" default>API v3.0</option>
                        <option value="3.1">API v3.1 (beta)</option>
                    </select>
                    <button type="submit">Save Changes</button>
                </form>
            </div>
        </section>
        <section>
            <h2>Classlink Configuration</h2>
            <h4>
                This section allows you to associate this Edlink application with an existing Classlink application.
                Edlink will automatically create and configure your integrated Classlink districts.
                This configuration will enable both Classlink Roster Server and Classlink Launchpad integration.
            </h4>
            <div class="card external-provider" :class="{active: application.classlink_enabled}">
                <div class="external-provider-header">
                    <label class="flex flex-align">
                        <toggle :active="application.classlink_enabled" @change="() => toggle_external_provider('classlink')" />
                        <div class="external-provider-name ff">Enable Classlink Sync</div>
                    </label>
                </div>
                <form autocomplete="off" @submit.prevent="save_classlink" class="external-provider-configuration">
                    <label>Classlink Client ID</label>
                    <input name="classlink_client_id" type="password"  class="large" v-model="classlink_client_id"  autocomplete="off" />
                    <label>Classlink Client Secret</label>
                    <input name="classlink_client_secret" type="password" class="large" v-model="classlink_client_secret" autocomplete="off" />
                    <label>Classlink OneRoster API Key</label>
                    <input name="classlink_oneroster_key" type="password" class="large" v-model="classlink_oneroster_key" autocomplete="off" />
                    <button type="submit">Save Changes</button>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'ApplicationProviders',
        methods: {
            toggle(provider){
                this.$store.commit('applications/toggle_source_provider', provider);
            },
            has(provider){
                return this.application.valid_source_providers.includes(provider);
            },
            toggle_anonymous_provider(provider){
                this.$store.commit('applications/toggle_anonymous_provider', provider);
            },
            toggle_external_provider(provider){
                this.$store.commit('applications/toggle_external_provider', provider);
            },
            save_clever(){
                this.$store.commit('applications/update', {
                    clever_client_id: this.clever_client_id,
                    clever_client_secret: this.clever_client_secret,
                    'properties.clever.default_api_version': this.clever_api_version
                });

                this.update();
            },
            save_classlink(){
                this.$store.commit('applications/update', {
                    classlink_client_id: this.classlink_client_id,
                    classlink_client_secret: this.classlink_client_secret,
                    classlink_oneroster_key: this.classlink_oneroster_key
                });

                this.update();
            },
            update(){
                if(this.application.clever_enabled){
                    for(const property of ['clever_client_id', 'clever_client_secret']){
                        if(!this.application[property]){
                            return this.$toasted.error('Please enter a valid Client ID and Client Secret before enabling Clever.');
                        }
                    }
                }

                if(this.application.classlink_enabled){
                    for(const property of ['classlink_client_id', 'classlink_client_secret', 'classlink_oneroster_key']){
                        if(!this.application[property]){
                            return this.$toasted.error('Please enter a valid Client ID, Client Secret, and OneRoster API Key before enabling Classlink.');
                        }
                    }
                }

                this.$store.dispatch('applications/update');
            }
        },
        data(){
            return {
                clever_client_id: '',
                clever_client_secret: '',
                clever_api_version: '3.0',
                classlink_client_id: '',
                classlink_client_secret: '',
                classlink_oneroster_key: ''
            };
        },
        watch: {
            application: {
                immediate: true,
                handler(){
                    if(this.application){
                        this.clever_client_id = this.application.clever_client_id;
                        this.clever_client_secret = this.application.clever_client_secret;
                        this.clever_api_version = this.application.properties?.clever?.default_api_version ?? '3.0';
                        this.classlink_client_id = this.application.classlink_client_id;
                        this.classlink_client_secret = this.application.classlink_client_secret;
                        this.classlink_oneroster_key = this.application.classlink_oneroster_key;
                    }
                }
            }
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            providers(){
                return this.$store.getters.providers.slice().sort((a, b) => a.name.localeCompare(b.name));;
            },
            team(){
                return this.$store.getters.team;
            },
            lms(){
                return this.providers.filter(p => p.categories.includes('lms'));
            },
            sis(){
                return this.providers.filter(p => p.categories.includes('sis'));
            },
            idms(){
                return this.providers.filter(p => p.categories.includes('sso'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 4px;
    }

    h4
    {
        color: @grey;
        max-width: 800px;
        margin-bottom: 20px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
    }

    .card
    {
        max-width: 1000px;

        h3
        {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            margin-bottom: 25px;
            border-bottom: 1px solid @e4;
            padding-bottom: 20px;
        }
    }
    
    .column
    {
        border-left: 1px solid @e4;
        padding: 20px;

        &:first-child
        {
            border-left: 0;
        }
    }

    label.checkbox-group
    {
        margin: 0;

        &.indent
        {
            padding-left: 28px;
            margin-top: 20px;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .checkbox-input
    {
        margin: 0;
    }

    .checkbox-title
    {
        font-size: 14px;
        color: @black;
        margin-left: 10px;
        font-weight: 400;
    }

    .provider
    {
        margin-bottom: 20px;
        margin-top: 0;
    }

    .beta
    {
        display: inline-block;
        background: fade(@base, 20%);
        color: @base;
        font-size: 11px;
        border-radius: 3px;
        padding: 1px 4px;
        line-height: 14px;
        margin-bottom: 2px;
        margin-left: 4px;
        vertical-align: middle;
    }

    .external-provider
    {
        .external-provider-header
        {
            border-bottom: 1px solid @e4;
            padding: 20px;

            label
            {
                margin: 0;
            }

            .external-provider-name
            {
                padding-left: 10px;
            }
        }

        .external-provider-configuration
        {
            padding: 20px;

            button
            {
                margin-top: 20px;
            }
        }

        &.active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;

            .external-provider-header
            {
                border-bottom-color: @base;
                background: fade(@base, 10%);
                color: @base;
            }

            .external-provider-name
            {
                color: @base;
            }
        }
    }

    .form-options
    {
        padding-bottom: 0;
    }
</style>