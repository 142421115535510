<template>
    <div class="entity flex flex-column">
        <header class="drawer-header flex flex-align">
            <div class="ff drawer-title flex flex-align">
                <Hexagon class="icon" width="20" height="20" stroke-width="2" />
                Materialization
            </div>
            <div class="button white" @click="close">Close</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{active: tab === 'details'}" @click="tab = 'details'">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Details
            </div>
            <div class="pill" :class="{active: tab === 'json'}" @click="tab = 'json'">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                JSON
            </div>
            <div class="pill" :class="{active: tab === 'logs'}" @click="tab = 'logs'">
                <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                Logs
            </div>
        </nav>
        <div class="drawer-content drawer-scroll" :class="{'drawer-logs': tab === 'logs'}">
            <component :is="tabs[tab]" />
        </div>
    </div>
</template>

<script>
    import EntityDetails from '@/components/drawers/entity/EntityDetails.vue';
    import EntityJSON from '@/components/drawers/entity/EntityJSON.vue';
    import EntityLogs from '@/components/drawers/entity/EntityLogs.vue';
    import { Hexagon, InfoEmpty, CodeBracketsSquare, AlignLeft } from '@epiphany/iconoir';

    export default {
        name: 'Materialization',
        components: {
            Hexagon,
            InfoEmpty,
            CodeBracketsSquare,
            AlignLeft
        },
        data(){
            return {
                tab: 'details',
                tabs: {
                    details: EntityDetails,
                    json: EntityJSON,
                    logs: EntityLogs
                },
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;

            &.drawer-logs
            {
                padding: 0;
            }
        }
    }

    .drawer-scroll
    {
        overflow: auto;
    }
</style>
