import { render, staticRenderFns } from "./IntegrateSourceProviderRapidIdentity.vue?vue&type=template&id=2dc5a3d6&scoped=true"
import script from "./IntegrateSourceProviderRapidIdentity.vue?vue&type=script&lang=js"
export * from "./IntegrateSourceProviderRapidIdentity.vue?vue&type=script&lang=js"
import style0 from "./IntegrateSourceProviderRapidIdentity.vue?vue&type=style&index=0&id=2dc5a3d6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc5a3d6",
  null
  
)

export default component.exports