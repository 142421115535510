import axios from 'axios';

const state = {
    loading: true,
    data: null,
    warnings: 0,
    errors: 0
};

const mutations = {
    data(state, data) {
        state.data = data;

        if (!data) {
            return;
        }

        // With the data, let's calculate errors and warnings.
        const types = ['syncs', 'enrichments', 'materializations', 'jobs', 'flows', 'previews'];

        const timestamp = new Date().getTime();
        const thirty_minutes_ago = new Date(timestamp - 30 * 60 * 1000);
        const two_hours_ago = new Date(timestamp - 2 * 60 * 60 * 1000);

        state.warnings = 0;
        state.errors = 0;

        for (const t of types) {
            const type = data[t];

            if (type.oldest_in_queue) {
                type.oldest_in_queue = new Date(type.oldest_in_queue);

                if (type.oldest_in_queue < two_hours_ago) {
                    state.errors++;
                } else if (type.oldest_in_queue < thirty_minutes_ago) {
                    state.warnings++;
                }
            }
        }
    },
    loading(state, value) {
        state.loading = value;
    }
};

const actions = {
    async load({ commit }) {
        commit('loading', true);

        await axios
            .get('/status')
            .then((response) => commit('data', response.$data))
            .catch((e) => {
                console.log(e);
                // commit('data', null)
            });

        commit('loading', false);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
