<template>
    <div class="create-person-entity">
        <div class="drawer-section">
            <h4>Class Details</h4>
            <div class="summary">
                <entity-field v-for="column of properties.class" :key="column.property" v-bind="column" />
            </div>
        </div>
    </div>
</template>

<script>
    import EntityField from '@/components/drawers/entity/EntityField.vue';

    export default {
        name: 'CreateClassEntity',
        components: {
            'entity-field': EntityField
        },
        data(){
            return {
                properties: {
                    class: [
                        {title: 'Name', property: 'name', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Description', property: 'description', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Picture URL', property: 'picture_url', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'Subjects', property: 'subjects', type: 'subject', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'Periods', property: 'periods', type: 'string', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'State', property: 'state', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Locale', property: 'locale', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Time Zone', property: 'time_zone', type: 'string', editable: true, placeholder: 'Click to Set'},
                        {title: 'Sessions', property: 'session_ids', type: 'session', array: true, editable: true, placeholder: 'Click to Set'},
                        {title: 'Course', property: 'course_id', type: 'course', editable: true, placeholder: 'Click to Set'},
                        {title: 'School', property: 'school_id', type: 'school', editable: true, placeholder: 'Click to Set'}
                    ]
                }
            };
        },
        created(){
            this.$store.commit('entities/create', {
                type: 'classes',
                source: this.source,
                integration: this.integration
            });
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            props(){
                return this.$store.state.drawer.props;
            },
            entity(){
                return this.props.entity;
            },
            integration(){
                return this.$store.getters.integration;
            },
            source(){
                return this.$store.getters.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
        padding: 0 25px;
    }

    .drawer-section
    {
        padding: 25px 0;
    }

    .nothing
    {
        margin: 0 25px;
        padding: 25px;
    }
</style>
