<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <DesignNib class="icon" width="16" height="16" stroke-width="2" />
                    Contracts
                </div>
            </template>
            <template v-slot:actions>
                <!-- <input class="filter search large" type="text" placeholder="Search by name" v-model="search" /> -->
                <div class="button has-icon" title="Export Plans" @click="openContractDrawer()">
                    <AddCircle class="icon" width="16" height="16" stroke-width="2" />
                    Create Contract
                </div>
                <!-- <div class="button white has-icon" title="Export Plans" @click="save()">
                    <span class="icon iconoir-database-export"></span>
                    Download
                </div> -->
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="teams.items" label="Billed Team" :active="billing_team" @search="team_source" :searchable="true" @activate="switch_billing_team">
                <template v-slot:icon>
                    <TwitterVerifiedBadge class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-name">{{item.name}}</div>
                        <Verified :verified="item.verified" />
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="teams.items" label="Contracted Team" :active="contracted_team" @search="team_source" :searchable="true" @activate="switch_contracted_team">
                <template v-slot:icon>
                    <TwitterVerifiedBadge class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-name">{{item.name}}</div>
                        <Verified :verified="item.verified" />
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="states" :active="state" @activate="switch_state" label="State">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <!-- <filter-dropdown :items="[]" label="Person">
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown> -->

            <filter-dropdown custom borderless :active="active_date" @activate="change_date" label="Date Range">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:custom="{ activate }">
                    <date-picker @input="activate" v-model="date" v-bind="{
                        type: 'date',
                        format: 'MM-DD-YYYY',
                        range: true,
                        inline: true,
                        'range-separator': '-',
                        shortcuts
                    }">
                    </date-picker>
                </template>
            </filter-dropdown>

            <!-- <filter-dropdown custom borderless :active="active_time" label="Time Range">
                <template v-slot:custom>
                    <date-picker @input="reset()" v-model="time" v-bind="{
                        type: 'time',
                        format: 'H:mm',
                        range: true,
                        'show-time-header': true,
                        'time-title-format': 'H:mm',
                        inline: true }">
                    </date-picker>
                </template>
            </filter-dropdown> -->

            <div class="ff"></div>
            <pages :all="all" :rows="requests" :more="more" :count="count" :page="page" @next="next" @previous="previous" />
        </filters>
        <datatable class="invoices full" :columns="columns" :rows="requests" :selectable="true" :header="true" @clicked="row => openContractDrawer(row)" :loading="loading" :clickable="true" />
    </main>
</template>

<script>
    import { TwitterVerifiedBadge, Calendar, WhiteFlag, Dollar, AddCircle, CreditCards, DesignNib } from '@epiphany/iconoir';
    import ContractDrawer from '@/components/drawers/Contract';
    import _ from 'lodash';
    import Verified from '@/components/chips/Verified.vue';

    export default {
        name: 'AdminContracts',
        components: {
            Calendar,
            WhiteFlag,
            Dollar,
            TwitterVerifiedBadge,
            AddCircle,
            CreditCards,
            DesignNib,
            Verified
        },
        data(){
            return {
                page: 0,
                count: 50,
                all: [],
                more: null,
                loading: false,
                date: null,
                time: null,
                search: '',
                search_timeout: null,
                shortcuts: [
                    { text: 'Today', onClick: () => {
                            const date = new Date();
                            date.setHours(0);
                            date.setMinutes(0);
                            return [date, new Date()]
                        }
                    },
                    {
                        text: 'Last 24 hrs',
                        onClick: () => {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Week',
                        onClick: () => {
                            const date = new Date();
                            date.setDate(date.getDate() - 7);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Month',
                        onClick: () => {
                            const date = new Date();
                            date.setMonth(date.getMonth() - 1);
                            return [date, new Date()];
                        },
                    },
                    {
                        text: 'Last Year',
                        onClick: () => {
                            const date = new Date();
                            date.setFullYear(date.getFullYear() - 1);
                            return [date, new Date()];
                        },
                    }
                ],
                state: null,
                states: [
                    {
                        id: 'inactive',
                        name: 'Inactive',
                        icon: 'yellow'
                    },
                    {
                        id: 'active',
                        name: 'Active',
                        icon: 'green'
                    },
                    {
                        id: 'ended',
                        name: 'Ended',
                        icon: 'grey'
                    },
                    {
                        id: 'paused',
                        name: 'Paused',
                        icon: 'blue'
                    },
                    {
                        id: 'canceled',
                        name: 'Canceled',
                        icon: 'red'
                    },
                ],
                billing_team: null,
                contracted_team: null,
                teams: {
                    all: [],
                    items: null,
                    promise: null,
                    query: ''
                },
                columns: [
                    {
                        id: 'billing_team.name',
                        name: 'Billed Team',
                        icon: TwitterVerifiedBadge,
                        width: '120px'
                    },
                    {
                        id: 'team.name',
                        name: 'Contracted Team',
                        icon: TwitterVerifiedBadge,
                        width: '120px'
                    },
                    {
                        id: 'plan.name',
                        name: 'Plan',
                        icon: Dollar,
                        width: '120px'
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '50px'
                    },
                    {
                        id: 'start_date',
                        name: 'Start Date',
                        icon: Calendar,
                        align: 'right',
                        width: '50px',
                        value: row => this.$options.filters.pretty(row.start_date, 'date')
                    },
                    {
                        id: 'end_date',
                        name: 'End Date',
                        icon: Calendar,
                        align: 'right',
                        width: '50px',
                        value: row => row.end_date ? this.$options.filters.pretty(row.end_date, 'date') : 'Indefinite'
                    }
                ]
            };
        },
        computed: {
            requests(){
                return this.all.slice(this.page * this.count, this.page * this.count + this.count);
            },
            active_date(){
                if (this.date && Array.isArray(this.date) && this.date.length) {
                    const [start, end] = this.date;
                    return {
                        name: `${this.$options.filters.pretty(start, 'short')} - ${this.$options.filters.pretty(end, 'short')}`
                    };
                } else {
                    return null;
                }
            },
            active_time(){
                if (this.time && this.time.length) {
                    return {
                        name: 'Range Selected'
                    };
                } else {
                    return null;
                }
            }
        },
        methods: {
            openContractDrawer(subscription) {
                this.$store.dispatch('drawer/open', {
                    key: 'admin-contract',
                    width: 450,
                    component: ContractDrawer,
                    props: {
                        subscription,
                        refreshParent: this.reset
                    }
                });
            },
            switch_billing_team(team){
                this.billing_team = team;
                this.reset();
            },
            switch_contracted_team(team){
                this.contracted_team = team;
                this.reset();
            },
            team_source(query){
                this.teams.query = query;

                if(!this.teams.promise){
                    this.teams.promise = this.$http.get(`/admin/teams`, {
                        params: {
                            $filter: {
                                type: [{
                                    operator: 'equals',
                                    value: 'developer'
                                }]
                            },
                            $first: 10000
                        }
                    }).then(response => {
                        this.teams.all = response.$data.map(team => {
                            return {
                                id: team.id,
                                name: team.name,
                                verified: team.verified,
                                picture: null
                            };
                        })
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
                
                return this.teams.promise.then(() => {
                    if(this.teams.query){
                        this.teams.items = this.teams.all.filter(team => {
                            return team.name.toLowerCase().indexOf(this.teams.query.toLowerCase()) > -1;
                        }).slice(0, 50);
                    }else{
                        this.teams.items = this.teams.all.slice(0, 50);
                    }
                });
            },
            switch_state(state){
                this.state = state;
                this.reset();
            },
            change_date(date){
                this.date = date;
                this.reset();
            },
            previous(){
                if(this.loading){
                    return;
                }

                this.page = this.page ? this.page - 1 : 0;
            },
            next(){
                if(this.loading){
                    return;
                }

                if(this.all.length <= (this.page + 1) * this.count){
                    // Wait until the page is done loading to increment the page number.
                    this.load().then(() => this.page += 1);
                }else{
                    this.page += 1;
                }
            },
            reset(){
                this.all = [];
                this.more = false;
                this.page = 0;
                this.load();
            },
            load(){
                if(this.loading){
                    return;
                }

                this.loading = true;

                const params = {
                    $last: this.count,
                    // $fields: 'id',
                    $filter: {
                    }
                };

                // if (this.search) {
                //     params.$filter.name = [{
                //         operator: 'contains',
                //         value: this.search
                //     }];
                // }

                if(this.state){
                    params.$filter.state = [{
                        operator: 'equals',
                        value: this.state.id
                    }];
                }

                if(this.billing_team){
                    params.$filter.billing_team_id = [{
                        operator: 'equals',
                        value: this.billing_team.id
                    }];
                }

                if(this.contracted_team){
                    params.$filter.team_id = [{
                        operator: 'equals',
                        value: this.contracted_team.id
                    }];
                }

                if(this.all.length){
                    params.$before = this.all[this.all.length - 1].id;
                }


                if(this.date && Array.isArray(this.date)) {
                    this.date = this.date.filter(el => el);

                    if (this.date.length) {
                        params.$filter.date = [
                            {
                                operator: 'gte',
                                value: this.date[0].toISOString()
                            },
                            {
                                operator: 'lte',
                                value: this.date[1].toISOString()
                            }
                        ];
                    }
                } else {
                    // TODO: Maybe filter by singular date gt/lt?
                }

                // Push the state to the browser history.
                let $filter = {};

                try {
                    $filter = JSON.parse(this.$route.query.$filter);
                }catch(error){
                    // Do nothing.
                }

                if(!_.isEqual($filter, params.$filter)){
                    this.$router.replace({
                        query: {
                            $filter: JSON.stringify(params.$filter)
                        }
                    });
                }

                return this.$http.get(`/admin/billing/subscriptions`, { params })
                    .then(response => {
                        // Add these new requests to the list of all requests.
                        this.all.push(...response.$data);

                        // We don't really care about the cursor right now, we just want to know if there are more results.
                        if (response.$data.length < this.count) {
                            this.more = false;
                        } else {
                            this.more = true;
                        }
                    })
                    .catch(error => this.$toasted.error('There was an error loading contracts.'))
                    .finally(() => this.loading = false);
            }
        },
        async created(){
            // Check to see if there are query parameters set before we load().
            if(this.$route.query.$filter){
                const params = JSON.parse(this.$route.query.$filter);

                if(params.state){
                    this.state = this.states.find(state => state.id === params.state[0].value);
                }

                if(params.date){
                    const gte = new Date(params.date.find(date => date.operator === 'gte')?.value);
                    const lte = new Date(params.date.find(date => date.operator === 'lte')?.value);

                    // TODO Support for single dates.
                    this.date = [gte, lte];
                }
            }

            this.load();
        },
        watch: {
            search(){
                this.loading = true;
                if (this.search_timeout) {
                    clearTimeout(this.search_timeout);
                }
                this.search_timeout = setTimeout(() => {
                    this.loading = false;
                    this.reset()
                }, 500);
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }

    .invoices.full
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .request
    {
        user-select: none;
        cursor: pointer;
    }

    .request-status
    {
        width: 90px;
    }

    .request-date
    {
        width: 300px;
        font-size: 12px;
    }

    .request-path
    {
        font-weight: 400;
        font-size: 12px;
        max-width: 400px;
        color: @grey;

        span
        {
            color: @black;
            font-weight: 500;
        }
    }

    .request-status-badge
    {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        color: @green;
        font-family: @monospace;

        &.error
        {
            color: @red;
        }
    }

    .request-log-options
    {
        padding: 20px 0 0;
        user-select: none;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .request-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;

        &.red
        {
            background: @red;
        }

        &.blue
        {
            background: @base;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.purple
        {
            background: @purple;
        }

        &.grey
        {
            background: @grey;
        }
    }

    .thumbnail
    {
        width: 16px;
        height: 16px;

        img
        {
            border-radius: 50%;
        }

        &.verified {
            background: url('~@/assets/icons/black/verified.svg') no-repeat;
            background-position: right 1px top 5px;
            background-size: 14px auto;
            background-position: left 1px top 1px;

            img {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        &::after
        {
            width: 16px;
            height: 16px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }

    .requests-loading
    {
        height: 200px;
    }

    .nothing
    {
        margin-top: 20px;
    }
</style>