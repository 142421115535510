<template>
    <div class="credit-card">
        <div class="card-list flex flex-center">
            <div class="card-item" :class="{flipped}">
                <div class="card-side front">
                    <div class="card-details flex flex-column">
                        <div class="card-icons flex flex-align">
                            <img src="@/assets/cards/chip.png" class="card-chip" />
                            <div class="ff"></div>
                            <div class="card-type">
                                <transition name="slide-fade-up">
                                    <img :src="'@/assets/cards/' + type + '.png'" v-if="type" :key="type" alt="" class="card-type-image">
                                </transition>
                            </div>
                        </div>
                        <div class="ff"></div>
                        <label for="number" class="card-number flex" ref="number">
                            <div class="card-digit" v-for="(n, $index) of mask" :key="$index" :class="{spacer: !n.trim()}">
                                <transition v-if="n.trim()" name="slide-fade-up">
                                    <!-- <div v-if="$index > 4 && $index < 14 && number.length > $index">*</div> -->
                                    <div :key="$index">{{(number.length > $index) ? number[$index] : '#'}}</div>
                                </transition>
                            </div>
                        </label>
                        <div class="card-meta flex flex-align">
                            <div class="card-meta-section">
                                <div class="card-meta-title">Card Holder</div>
                                <transition name="slide-fade-up">
                                    <label for="name" class="card-holder flex" v-if="name.length" key="1">
                                        <transition-group name="slide-fade-right">
                                            <div class="card-holder-character" v-for="(n, $index) of name.replace(/\s\s+/g, ' ')" v-if="$index === $index" :key="$index + 1">{{n}}</div>
                                        </transition-group>
                                    </label>
                                    <label for="name" class="card-holder" v-else key="2">Full Name</label>
                                </transition>
                            </div>
                            <div class="ff"></div>
                            <div class="card-meta-section">
                                <div class="card-meta-title">Expires</div>
                                <div class="flex card-date">
                                    <label for="month" class="card-date-item flex">
                                        <transition name="slide-fade-up">
                                            <div class="card-date-segment" v-if="month" :key="month">{{month}}</div>
                                            <div class="card-date-segment" v-else key="2">MM</div>
                                        </transition>
                                    </label>
                                    <div class="card-date-slash">/</div>
                                    <label for="year" class="card-date-item flex">
                                        <transition name="slide-fade-up">
                                            <div class="card-date-segment" v-if="year" :key="year">{{String(year).slice(2,4)}}</div>
                                            <div class="card-date-segment" v-else key="2">YY</div>
                                        </transition>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-side back">
                    <div class="card-band"></div>
                    <div class="card-item__cvv">
                        <div class="card-item__cvvTitle">CVV</div>
                        <div class="card-item__cvvBand">
                            <span v-for="(n, $index) of cvv" :key="$index">
                                *
                            </span>
                        </div>
                        <div class="card-item__type">
                            <img :src="'@/assets/cards/' + type + '.png'" v-if="type" class="card-item__typeImg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-form">
            <label for="number">Card Number</label>
            <input type="text" id="number" v-mask="mask" v-model="number" @focus="focusInput" @blur="blurInput" data-ref="number" autocomplete="off" />
            <label for="name">Name On Card</label>
            <input type="text" id="name" v-model="name" @focus="focusInput" @blur="blurInput" data-ref="name" autocomplete="off" />
            <div class="flex form-group">
                <div class="form-field expiration-date">
                    <label for="month">Expiration Date</label>
                    <div class="flex">
                        <select id="month" v-model="month" @focus="focusInput" @blur="blurInput">
                            <option value="" disabled selected>Month</option>
                            <option :value="n < 10 ? '0' + n : n" v-for="n of 12" :disabled="n < min_month" :key="n">
                                {{n < 10 ? '0' + n : n}}
                            </option>
                        </select>
                        <select id="year" v-model="year" @focus="focusInput" @blur="blurInput">
                            <option value="" disabled selected>Year</option>
                            <option :value="$index + min_year" v-for="(n, $index) of 12" :key="n">
                                {{$index + min_year}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-field ff">
                    <label for="cvv">CVV</label>
                    <input type="text" id="cvv" v-mask="'####'" maxlength="4" v-model="cvv" @focus="flipCard(true)" @blur="flipCard(false)" autocomplete="off">
                </div>
            </div>
            <button @click="done">Submit</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CreditCard',
        props: {
            number: String,
            name: String,
            month: String,
            year: String,
            cvv: String
        },
        data(){
            return {
                min_year: new Date().getFullYear(),
                amex_mask: '#### ###### #####',
                other_mask: '#### #### #### ####',
                flipped: false,
                focused: false
            };
        },
        mounted(){
            // document.getElementById("number").focus();
        },
        methods: {
            done(){
                this.$emit('close');
            },
            flipCard(status){
                this.flipped = status;
            },
            focusInput(e){
                this.focused = true;

                let targetRef = e.target.dataset.ref;
                let target = this.$refs[targetRef];
            },
            blurInput() {
                this.focused = false;
            }
        },
        watch: {
            year(){
                if(this.month < this.min_month){
                    this.month = '';
                }
            }
        },
        computed: {
            type(){
                if(!this.number){
                    return '';
                }

                if(this.number.match(new RegExp('^4'))) return 'visa';
                if(this.number.match(new RegExp('^(34|37)'))) return 'amex';
                if(this.number.match(new RegExp('^5[1-5]'))) return 'mastercard';
                if(this.number.match(new RegExp('^6011'))) return 'discover';

                return 'visa';
            },
            mask(){
                return this.type === 'amex' ? this.amex_mask : this.other_mask;
            },
            min_month(){
                return this.year === this.min_year ? new Date().getMonth() + 1 : 1;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    

    .card-form
    {
        background: @f;
        box-shadow: 0 20px 60px -2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        padding: 30px;
        padding-top: 160px;
    }

    button
    {
        width: 100%;
        margin-top: 30px;
    }

    .card-item
    {
        width: 100%;
        max-width: 380px;
        height: 240px;
        z-index: 2;

        &.flipped
        {
            .card-side
            {
                &.front
                {
                    transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
                }

                &.back
                {
                    transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
                }
            }
        }

        .card-side
        {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 20px 60px -2px rgba(0, 0, 0, 0.3);
            transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
            transform-style: preserve-3d;
            transition: all 0.4s cubic-bezier(0.71, 0.03, 0.56, 0.85);
            backface-visibility: hidden;
            height: 100%;
            background: linear-gradient(135deg, #00d4ff 0%, #006aff 100%);

            &.back
            {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
                z-index: 2;
                padding: 0;
                height: 100%;
            }
        }

        .card-icons
        {
            margin-bottom: 35px;
        }

        .card-chip
        {
            height: 41px;
        }

        .card-type
        {
            height: 41px;
            max-width: 100px;
        }

        .card-type-image
        {
            object-fit: contain;
            object-position: top right;
        }

        .card-meta-title
        {
            opacity: 0.7;
            font-size: 13px;
            margin: 0 0 6px;
            letter-spacing: -0.02rem;
        }

        .card-details
        {
            font-family: @monospace;
            padding: 25px;
            position: relative;
            z-index: 4;
            height: 100%;
            text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
            user-select: none;
        }

        .card-holder, .card-date
        {
            font-size: 18px;
            text-transform: uppercase;
        }

        .card-holder-character
        {

        }

        .card-number
        {
            font-weight: 500;
            color: @f;
            font-size: 24px;
            cursor: pointer;
            margin: 10px 0 30px;
        }

        .card-digit
        {
            flex: 1;

            &.spacer
            {
                flex: 2;
            }
        }

        .card-meta
        {
            color: @f;

            label
            {
                margin: 0;
                color: @f;
                font-weight: 500;
            }
        }

        .card-date-item
        {
            font-size: 18px;

            .card-date-segment
            {
                width: 22px;
            }
        }

        .card-date-title
        {
            opacity: 0.7;
            font-size: 13px;
            padding-bottom: 6px;
            width: 100%;
        }

        .card-band
        {
            background: rgba(0, 0, 19, 0.8);
            width: 100%;
            height: 46px;
            margin-top: 30px;
            position: relative;
            z-index: 2;
        }

        &__cvv
        {
            text-align: right;
            position: relative;
            z-index: 2;
            padding: 15px;

            .card-item__type
            {
                opacity: 0.7;
            }
        }

        &__cvvTitle
        {
            padding-right: 10px;
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 5px;
        }

        &__cvvBand
        {
            height: 45px;
            background: #fff;
            margin-bottom: 30px;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;
            color: #1a3b5d;
            font-size: 18px;
            border-radius: 4px;
            box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
        }
    }

    .card-list
    {
        margin-bottom: -120px;
    }

    .card-input
    {
        margin-bottom: 20px;

        .slide-fade-up-enter-active
        {
            transition: all 0.25s ease-in-out;
            transition-delay: 0.1s;
            position: relative;
        }

        .slide-fade-up-leave-active
        {
            transition: all 0.25s ease-in-out;
            position: absolute;
        }

        .slide-fade-up-enter
        {
            opacity: 0;
            transform: translateY(15px);
            pointer-events: none;
        }

        .slide-fade-up-leave-to
        {
            opacity: 0;
            transform: translateY(-15px);
            pointer-events: none;
        }

        .slide-fade-right-enter-active
        {
            transition: all 0.25s ease-in-out;
            transition-delay: 0.1s;
            position: relative;
        }

        .slide-fade-right-leave-active
        {
            transition: all 0.25s ease-in-out;
            position: absolute;
        }

        .slide-fade-right-enter
        {
            opacity: 0;
            transform: translateX(10px) rotate(45deg);
            pointer-events: none;
        }

        .slide-fade-right-leave-to
        {
            opacity: 0;
            transform: translateX(-10px) rotate(45deg);
            pointer-events: none;
        }
    }

    .expiration-date
    {
        flex: 2;

        select
        {
            margin-right: 30px;

            &:last-child
            {
                margin-right: 0;
            }
        }
    }
</style>