<template>
    <transition-group tag="div" name="staggered-transition-group" :css="false" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <slot></slot>
    </transition-group>
</template>

<script>
    import Velocity from 'velocity-animate';
    
    export default {
        name: 'StaggeredTransitionGroup',
        props: {
            
        },
        methods: {
            beforeEnter($el) {
                $el.style.opacity = 0
            },
            enter($el, complete) {
                var delay = $el.dataset.index * 1500;
                console.log('enter', $el);

                setTimeout(function () {
                    Velocity($el, { opacity: 1 }, { complete })
                }, delay)
            },
            leave($el, complete) {
                var delay = $el.dataset.index * 1500;
                console.log('leave', $el);

                setTimeout(function () {
                    Velocity($el, { opacity: 0 }, { complete })
                }, delay)
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
