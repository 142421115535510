<template>
    <div class="integrate-source-provider-google">
        <integrate-source-editor-step name="Service or Administrator Account" icon="iconoir-server-connection" :complete="completed.includes('mode')" v-if="!is_clever_onboarding">
            <div class="helptext">
                This will determine how Edlink connects to your Google Classroom.
            </div>
            <div class="flex flex-column connection-options">
                <div class="card integrate-radio flex flex-align" :class="{selected: source.configuration.use_service_account === true}" @click="send('UPDATE_SOURCE', { path: 'configuration.use_service_account', value: true })">
                    <div class="ff card-text">
                        <div>Connect to Google by creating a service account</div>
                        <!-- <div class="small-text">Select this option if you plan on using our Graph API v2.0.</div> -->
                    </div>
                    <div class="connection-radio-tag">Recommended</div>
                </div>
                <div class="card integrate-radio flex flex-align" :class="{selected: source.configuration.use_service_account === false}" @click="send('UPDATE_SOURCE', { path: 'configuration.use_service_account', value: false })">
                    <div class="ff card-text">
                        <div>Connect to Google using your administrator account</div>
                        <!-- <div class="small-text">Select this option if you do not plan to use our Graph API v2.0.</div> -->
                    </div>
                    <div class="connection-radio-tag legacy">Legacy</div>
                </div>
            </div>
        </integrate-source-editor-step>
        
        <template v-if="!is_mode_selected">
            <integrate-source-editor-step :loading="true" />
        </template>

        <integrate-source-editor-step key="use-service-account" v-if="source.configuration.use_service_account === true" name="Configure Google Workspace Delegated Authority" icon="iconoir-google-circled" :complete="completed.includes('delegation')">
            <integrate-source-editor-configuration>
                <label>Open Google Workspace Security</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" href="https://admin.google.com/ac/owl/domainwidedelegation">
                        Open The Domain-wide Delegation Settings Page
                    </a>
                </div>
                
                <label>Add Edlink Service Account</label>
                <div class="configuration-value">Click on <span class="badge">Add new</span></div>
                
                <label>Enter Edlink's Service Account Client ID</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" key="google-service-account-id" v-clipboard="() => '102054707346365691373'" v-clipboard:success="() => $toasted.info('Copied')">
                        102054707346365691373
                    </div>
                </div>
                
                <label>Enter Oauth Scopes</label>
                <div class="image-label labeled flex flex-align flex-wrap">
                    <div class="configuration-value copyable noshift" key="google-service-account-scopes" v-clipboard="() => scopes.join(',')" v-clipboard:success="() => $toasted.info('Copied')">
                        {{ scopes[0] + ',...' }}
                    </div>
                </div>

                <label>Authorize</label>
                <div class="configuration-value">
                    Click on <span class="badge">Authorize</span>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="delegation" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step key="use-administrator-account" name="Configure Google Workspace" icon="iconoir-google-circled" :complete="completed.includes('remote')">
            <div class="helptext">
                Click the link below to authorize SSO for your teachers and students.
            </div>
            <integrate-source-editor-configuration title="How to Configure Google" icon="iconoir-info-empty">
                <label>Open Settings Page</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" href="https://admin.google.com/ac/owl/list?tab=configuredApps">
                        Open App Access Control
                    </a>
                </div>
                <label>Add an Application</label>
                <div class="configuration-value">Select <span class="badge">Configure new app</span></div>
                <label>Search for Edlink by name or Client ID</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" key="google-client-id" v-clipboard="() => '563820043496-o65vgllud5rrstbf8tg0rltlm5pbg868.apps.googleusercontent.com'" v-clipboard:success="() => $toasted.info('Copied')">563820043496-o65vgllud5rrstbf8tg0rltlm5pbg868.apps.googleusercontent.com</div>
                </div>
                <label>Select a Scope</label>
                <div class="configuration-value">Select <span class="badge">All in [Your Organization]</span> or choose specific OUs</div>
                <label>Configure Access</label>
                <div class="configuration-value">On the <span class="badge">Access to Data</span> screen, select <span class="badge">Trusted</span>, then complete the final review screen</div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Connect Your Google Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
            <div class="helptext">
                Sign into your Google account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <token :write="destination" :configuration="configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
        </integrate-source-editor-step>

        <integrate-source-editor-step key="install-classroom-addon" v-if="is_roblox_onboarding" name="Install Google Classroom Addon" icon="iconoir-box-iso" :complete="completed.includes('addon')">
            <integrate-source-editor-configuration>
                <label>Open Google Classroom Addon Page</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" href="https://workspace.google.com/marketplace/app/roblox/145118426069">
                        Open the Store Page
                    </a>
                </div>
                
                <label>Install the Addon</label>
                <div class="configuration-value">Click <span class="badge">Admin Install</span></div>

                <label>Follow the Prompts</label>
                <div class="configuration-value">
                    Follow the prompts to install the addon for your domain and accept the requested permissions.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="addon" />
            </div>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import Vue from 'vue';
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';
    import _ from 'lodash';
    import { CLEVER_APPLICATION_IDS, ROBLOX_APPLICATION_IDS } from '@/constants';
    
    export default {
        name: 'IntegrateSourceProviderGoogle',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(!this.is_mode_selected){
                    return [];
                }

                if(this.source.configuration.use_service_account){
                    if(!this.delegation){
                        return ['mode'];
                    }

                    if(!this.remote){
                        return ['mode', 'delegation'];
                    }

                    // Validate that the token has been acquired.
                    if(!this.source.token && !this.source.id){
                        return ['mode', 'delegation', 'remote'];
                    }

                    if (this.is_roblox_onboarding) {
                        if(!this.addon){
                            return ['mode', 'delegation', 'remote', 'token'];
                        }
                    }

                    return ['mode', 'delegation', 'remote', 'token', 'addon'];
                }else{
                    if(!this.remote){
                        return ['mode'];
                    }

                    // Validate that the token has been acquired.
                    if(!this.source.token && !this.source.id){
                        return ['mode', 'remote'];
                    }

                    if (this.is_roblox_onboarding) {
                        if(!this.addon){
                            return ['mode', 'remote', 'token'];
                        }
                    }
                    
                    return ['mode', 'remote', 'token', 'addon'];
                }
            },
            is_mode_selected() {
                return _.isBoolean(this.source.configuration.use_service_account);
            },
            is_clever_onboarding() {
                if (this.state.context.custom && CLEVER_APPLICATION_IDS.includes(this.state.context.application.id)) {
                    return true;
                } else {
                    return false;
                }
            },
            is_roblox_onboarding() {
                return ROBLOX_APPLICATION_IDS.includes(this.state.context.application.id);
            }
        },
        methods: {
            image(src, config = { title: null, width: 700, height: 'auto' }){
                this.$modal.show(
                    HelpImage,
                    { src, title: config.title },
                    { width: config.width ?? 700, height: config.height ?? 'auto', classes: 'modal', reset: true }
                );
            }
        },
        mounted() {
            this.configuration = Object.assign({}, this.source.configuration);

            // This is to handle Clever GCRI districts.
            if(this.state.context.gcri){
                this.send('UPDATE_SOURCE', { path: 'properties.sync_course_aliases', value: true });
            }

            if (this.is_clever_onboarding) {
                this.send('UPDATE_SOURCE', { path: 'configuration.use_service_account', value: true });
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === (this.source.configuration.use_service_account ? 5 : 4));
            },
            'source.configuration'(){
                Vue.set(this.configuration, 'use_service_account', this.source.configuration.use_service_account);
            }
        },
        data(){
            return {
                configuration: null,
                remote: false,
                delegation: false,
                addon: false,
                scopes: [
                    'https://www.googleapis.com/auth/classroom.profile.emails',
                    'https://www.googleapis.com/auth/classroom.profile.photos',
                    'https://www.googleapis.com/auth/classroom.courses.readonly',
                    'https://www.googleapis.com/auth/classroom.rosters.readonly',
                    'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
                    'https://www.googleapis.com/auth/admin.directory.user.readonly',
                    'https://www.googleapis.com/auth/classroom.guardianlinks.students.readonly',
                    'https://www.googleapis.com/auth/classroom.push-notifications',
                    'https://www.googleapis.com/auth/classroom.coursework.students',
                    'https://www.googleapis.com/auth/classroom.courseworkmaterials',
                    'https://www.googleapis.com/auth/classroom.coursework.me',
                    'https://www.googleapis.com/auth/classroom.topics'
                ]
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .small-text {
        color: @grey;
        font-size: 12px;
    }

    .selected .small-text {
        color: @base;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }

    .badge
    {
        border-radius: 3px;
        padding: 2px 4px;
        color: @black;
        background: fade(@black, 10%);
        font-weight: 400;
    }

    .card-text {
        line-height: 20px;
    }

    .connection-options
    {
        align-items: flex-start;
        margin-top: 20px;
    }

    .connection-radio-tag
    {
        margin-left: 10px;
        padding: 2px 4px;
        border-radius: 3px;
        background: @base;
        color: @f;
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 14px;

        &.legacy
        {
            background: @grey;
        }
    }
</style>
