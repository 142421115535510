<template>
    <div class="autocomplete" :class="{disabled, error, borderless, inline}" @click="focus" @mousedown="mousedown">
        <div class="autocomplete-search flex flex-align flex-wrap" :class="{disabled, error, inline}" @click="focus" ref="search">
            <template v-if="show_tokens">
                <div class="token flex flex-align" v-for="(token, index) of tokens" :key="index" :class="{selected: token === selected}" @click="select(token, $event)">
                    <div class="token-name">{{format ? format(token) : (property ? token[property] : token)}}</div>
                    <div class="token-remove" @click="remove(token)">
                        <Cancel class="icon" width="16" height="16" stroke-width="2" />
                    </div>
                </div>
            </template>
            <input class="autocomplete-input ff" type="text" :disabled="disabled" v-model="input" @paste="didPaste" @keydown="keydown" ref="input" @focus="mousedown" :placeholder="tokens.length && show_tokens ? '' : placeholder"/>
        </div>
        <div class="autocomplete-results inline" v-if="inline" :style="{maxHeight: `calc(100% - ${results_offset}px)`}" @click.prevent="() => {}" ref="results">
            <div class="flex flex-align flex-center loading" v-if="loading">
                <spinner/>
            </div>
            <template v-else-if="calculated.length">
                <template v-for="(item, index) of calculated">
                    <component v-if="result" :is="result" class="autocomplete-result" :key="index" :class="{highlighted: item === highlighted}" :item="item" />
                    <div class="autocomplete-slot" v-else-if="$slots.default" :key="index" :class="{highlighted: item === highlighted}">
                        <slot :item="item" />
                    </div>
                    <div v-else class="autocomplete-result flex flex-align" :class="{highlighted: item === highlighted}" :key="index" @mousedown="() => choose(item)">
                        {{format ? format(item) : (property ? item[property] : item)}}
                    </div>
                </template>
            </template>
            <div class="nothing" v-else>No results.</div>
        </div>
        <div class="autocomplete-results" v-else-if="focused && !full" @click.prevent="() => {}" :style="{top: results_offset + 'px'}" ref="results">
            <div class="flex flex-align flex-center loading" v-if="loading">
                <spinner/>
            </div>
            <template v-else-if="calculated.length">
                <template v-for="(item, index) of calculated">
                    <component v-if="result" :is="result" class="autocomplete-result" :key="index" :class="{highlighted: item === highlighted}" :item="item" />
                    <div class="autocomplete-slot" v-else-if="$slots.default" :key="index" :class="{highlighted: item === highlighted}">
                        <slot :item="item" />
                    </div>
                    <div v-else class="autocomplete-result flex flex-align" :class="{highlighted: item === highlighted}" :key="index" @mousedown="() => choose(item)">
                        {{format ? format(item) : (property ? item[property] : item)}}
                    </div>
                </template>
            </template>
            <div class="nothing" v-else>No results.</div>
        </div>
    </div>
</template>

<script>
    import { isFunction, isBoolean } from 'lodash';
    import { Cancel } from '@epiphany/iconoir';

    export default {
        name: 'Autocomplete',
        props: {
            borderless: Boolean,
            inline: {
                type: Boolean,
                default: false
            },
            error: Boolean,
            placeholder: String,
            values: Array,
            disabled: Boolean,
            source: Function,
            search: Function,
            results: Array,
            format: Function,
            max: Number,
            result: Object,
            property: String,
            timeout: {
                type: Number,
                default: 250
            },
            show_tokens: {
                type: Boolean,
                default: true
            },
            paste: {
                type: Boolean | Function,
                default: false
            }
        },
        components: {
            Cancel
        },
        data(){
            return {
                input: '',
                selected: null,
                internal_results: [],
                throttle: false,
                highlighted: null,
                focused: false,
                results_offset: 40,
                loading: true
            };
        },
        created(){
            this.load();
            window.addEventListener('mousedown', this.blur);
            window.addEventListener('focusout', this.blur);
        },
        destroyed(){
            window.removeEventListener('mousedown', this.blur);
            window.removeEventListener('focusout', this.blur);
        },
        mounted(){
            this.$nextTick(() => {
                if (this.focused) {
                    this.focus();
                }
            });
        },
        watch: {
            calculated(){
                window.clearTimeout(this.throttle);
                this.throttle = null;

                if(this.calculated.length){
                    this.highlighted = this.calculated[0];
                }

                this.recalculate_results_offset();
                this.loading = false;
            },
            max(){
                this.$emit('update:values', this.values.slice(0, this.max));
                this.$emit('input', this.values.slice(0, this.max));
                this.load();
            },
            input(){
                if(this.source || this.search){
                    this.loading = true;

                    if(this.throttle){
                        window.clearTimeout(this.throttle);
                        this.throttle = null;
                    }

                    this.throttle = window.setTimeout(this.load, this.timeout);
                }
            },
            values(){
                if (!this.show_tokens) {
                    this.load();
                }
            }
        },
        computed: {
            calculated(){
                return (this.results ?? this.internal_results).filter(result => !this.values.includes(result));
            },
            full(){
                if(this.max){
                    return this.values.length >= this.max;
                }

                return false;
            },
            tokens(){
                return this.values;
            }
        },
        methods: {
            didPaste(e){
                if (isFunction(this.paste)) {
                    throw new Error('Paste function not implemented');
                    // return paste(e);
                } else if (isBoolean(this.paste)) {
                    if (this.paste) {
                        const value = e.clipboardData.getData('text')?.trim();
                        const items = value.split(/,|\n/g).map(item => item.trim());
                        if(items.length > 1){
                            e.preventDefault();
                            e.stopPropagation();
                            this.input = '';
                            const duplicate = this.values.slice();
                            duplicate.push(...items);
                            const deduped = Array.from(new Set(duplicate));
                            this.$emit('update:values', deduped);
                            this.$emit('input', deduped);
                            this.recalculate_results_offset();
                            this.load(deduped);
                        }
                    } else {
                        return;
                    }
                } else {
                    throw new Error('Paste must be a function or boolean');
                }
            },
            keydown(e){
                switch(e.which){
                    case 13:
                        // Only do this is we're about to select an item.
                        // Otherwise, we want to let this event propagate.
                        if(this.focused && !this.full){
                            e.preventDefault();
                            e.stopPropagation();

                            this.selected = null;

                            if(this.highlighted){
                                this.choose(this.highlighted);
                            }

                            if(this.calculated.length > 1){
                                if(this.calculated.indexOf(this.highlighted) === 0){
                                    this.highlighted = this.calculated[1];
                                }else{
                                    this.highlighted = this.calculated[0];
                                }
                            }else{
                                this.highlighted = null;
                            }
                        }else{
                            this.$emit('enter');
                        }

                        return;
                    case 8:
                    case 46:
                        if(this.input){
                            return;
                        }

                        if(this.selected){
                            this.remove(this.selected);
                        }else if(this.values.length && e.which === 8){
                            //NOTE DJG Don't run this interaction if the user pressed delete, only backspace
                            this.selected = this.values[this.values.length - 1];
                        }

                        this.focus();
                        this.recalculate_results_offset();
                        return;
                    case 38:
                        e.preventDefault();
                        this.up();
                        return;
                    case 40:
                        e.preventDefault();
                        this.down();
                        return;
                    case 27:
                        e.preventDefault();
                        
                        if(this.focused){
                            this.blur();
                        }else{
                            this.$emit('escape');
                        }

                        return;
                }

                if(this.full){
                    return e.preventDefault();
                }

                this.focus();
            },
            remove(token){
                const duplicate = this.values.slice();
                duplicate.splice(duplicate.indexOf(token), 1);
                this.selected = null;
                this.$emit('update:values', duplicate);
                this.$emit('input', duplicate);
                this.recalculate_results_offset();
                this.load(duplicate);
            },
            select(token, e){
                e.preventDefault();
                e.stopPropagation();

                this.selected = token;
                this.focus();
                this.recalculate_results_offset();
            },
            focus(){
                if (this.disabled) {
                    return;
                }
                this.$refs.input.focus();
                this.focused = true;
                this.recalculate_results_offset();
            },
            blur(){
                if(this.selected){
                    this.selected = null;
                }

                this.focused = false;
            },
            load(values = this.values){
                if(this.source){
                    this.source(this.input, values)
                    .then(results => {
                        this.internal_results = results;
                    });
                } else if (this.search) {
                    this.search(this.input, values);
                }
            },
            up(){
                if(this.highlighted){
                    const index = this.calculated.indexOf(this.highlighted);

                    if(index > 0){
                        this.highlighted = this.calculated[index - 1];
                    }

                    //Figure out if we need to scroll up.
                    const highlighted = this.$refs.results.querySelector('.highlighted');

                    if(highlighted){
                        const previous = highlighted.previousSibling;

                        if(previous){
                            previous.scrollIntoView({block: 'nearest'});
                        }
                    }
                }
            },
            down(){
                if(this.highlighted){
                    const index = this.calculated.indexOf(this.highlighted) + 1;

                    if(this.calculated.length > index){
                        this.highlighted = this.calculated[index];
                    }

                    //Figure out if we need to scroll down.
                    const highlighted = this.$refs.results.querySelector('.highlighted');

                    if(highlighted){
                        const next = highlighted.nextSibling;

                        if(next){
                            next.scrollIntoView({block: 'nearest'});
                        }
                    }
                }
            },
            mousedown(){
                this.focused = true;
            },
            choose(token){
                if(this.full){
                    return;
                }

                const duplicate = this.values.slice();
                duplicate.push(token);
                this.$emit('update:values', duplicate);
                this.$emit('input', duplicate);
                this.input = '';
                this.recalculate_results_offset();
                this.load(duplicate);
            },
            prefill(token){
                this.input = token.title;
                this.recalculate_results_offset();
            },
            recalculate_results_offset(){
                setTimeout(() => {
                    this.results_offset = this.$refs.search.clientHeight + (this.borderless || this.inline ? 0 : 8);

                    // Scroll the results into view if outside the viewport.
                    const results = this.$refs.results;
                    if (results) {
                        const rect = results.getBoundingClientRect();
                        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                        if (rect.bottom > viewHeight) {
                            results.scrollIntoView(false);
                        }
                    }
                }, 30);
            }
        }
    }
</script>

<style lang="less">
    @import "../~@/assets/less/variables";
    @import "../~@/assets/less/mixins";

    .autocomplete
    {
        .autocomplete-search
        {
            border: 1px solid @d;
            padding: 2px;
            min-height: 40px;
            line-height: 30px;
            font-size: 16px;
            color: @black;
            border-radius: 6px;
            text-transform: none;
            margin: 0;
            cursor: pointer;

            &:focus-within, &:active
            {
                border-color: @base;
                box-shadow: 0 0 0 1px @base;
            }

            &.inline
            {
                border-radius: 6px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border: 0;
                border-bottom: 1px solid @d;

                &:focus-within, &:active
                {
                    border-color: @d;
                    box-shadow: none;
                }
            }

            .token
            {
                background: @e4;
                color: @black;
                padding: 0 0 0 8px;
                font-size: 13px;
                border-radius: @border-radius - 2px;
                margin: 3px;
                line-height: 28px;
                height: 28px;
                white-space: no-wrap;

                &.selected
                {
                    background: @base;
                    color: @f;

                    .token-remove
                    {
                        background-image: url('~@/assets/icons/white/x-round.svg');
                        opacity: 1;

                        &:hover, &:active
                        {
                            opacity: 1;
                        }
                    }
                }
            }

            .token-remove
            {
                width: 28px;
                height: 28px;
                background: url('~@/assets/icons/black/x-round.svg') 5px 7px no-repeat;
                background-size: 18px auto;
                opacity: 0.3;
                transition: opacity ease 0.2s;

                &:hover, &:active
                {
                    opacity: 0.7;
                }
            }

            input.autocomplete-input
            {
                border-radius: 0;
                width: auto;
                margin: 3px;
                height: 28px;
                line-height: 28px;
                padding: 0 3px;
                border: 0;
                min-width: 150px;

                &:focus
                {
                    box-shadow: none;
                }

                &:disabled
                {
                    background: @f;
                }
            }
        }

        &.compact
        {
            .autocomplete-search
            {
                padding: 2px;
                min-height: 34px;
                line-height: 28px;

                .token
                {
                    margin: 2px;
                    height: 24px;
                    line-height: 24px;
                    padding: 0 0 0 6px;
                    font-size: 13px;
                    font-weight: 500;
                }

                input.autocomplete-input
                {
                    height: 24px;
                    line-height: 24px;
                    margin: 2px;
                    font-size: 13px;
                }

                .token-remove
                {
                    width: 24px;
                    height: 24px;
                    background-position: 3px 5px;
                }
            }
        }

        .autocomplete-results
        {
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            background: @f;
            z-index: 1000;
            border-radius: 5px;
            background-clip: padding-box;
            // border: 1px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 12px -2px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.1);
            max-height: 250px;
            overflow-y: auto;
            padding: 5px;

            .nothing, .loading
            {
                border: 0;
                border-radius: 0;
                font-size: 13px;
                padding: 0;
                line-height: 20px;
                padding: 10px 15px;
            }

            &.inline
            {
                position: relative;
                top: initial;
                left: 0;
                right: 0;
                box-shadow: none;
                border-radius: 6px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .autocomplete-result
            {
                font-size: 13px;
                color: @black;
                padding: 5px 10px;
                line-height: 20px;
                cursor: pointer;
                border-radius: 3px;
                font-weight: 500;

                &:hover
                {
                    background: @f8;
                }

                &.highlighted
                {
                    background: fade(@base, 20%);
                    color: @base;
                }
            }
        }

        .autocomplete-slot
        {
            &.highlighted
            {

            }
        }

        .borderless
        {
            .autocomplete-search
            {
                border: 0;
            }
        }

        &.disabled {
            pointer-events: none;

            .autocomplete-search .token {
                padding-right: 12px;
            }
            
            .token-remove {
                display: none;
            }
        }
    }
</style>
