<template>
    <div class="dropdown-item" :class="{active, muted}">
        <slot name="custom">
            <div class="dropdown-item-default-template flex flex-align">
                <div class="dropdown-icon flex flex-center flex-align">
                    <slot v-if="$slots.icon" name="icon" />
                </div>
                <div class="ff">
                    <div class="dropdown-title" v-if="$slots.title">
                        <slot name="title" />
                    </div>
                    <div class="dropdown-description" v-if="$slots.description">
                        <slot name="description" />
                    </div>
                </div>
                <slot v-if="$slots.badge" name="badge" />
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'DropdownItem',
    props: {
        active: Boolean,
        muted: Boolean
    },
    data() {
        return {};
    },
    methods: {
        
    }
}
</script>

<style lang="less">
@import "~@/assets/less/variables";

.dropdown-item
{
    padding: 4px 8px;
    cursor: pointer;
    border-radius: @border-radius - 2px;
    margin: 2px 4px;

    &:hover, &:active, &.highlighted, &.active
    {
        background: @f4;
    }

    &:first-child
    {
        margin-top: 0;
    }

    &:last-child
    {
        margin-bottom: 0;
    }

    .dropdown-icon
    {
        margin-right: @single-padding;
    }

    .icon
    {
        color: @black;
    }

    .dropdown-title
    {
        line-height: 22px;
        font-size: 13px;
        font-weight: 500;
        color: @black;
    }

    .dropdown-description
    {
        line-height: 15px;
        font-size: 12px;
        font-weight: 400;
        color: @grey;
    }

    .chip {
        .icon {
            margin-right: 0;
        }
    }

    &.muted {
        color: @black;

        &:hover, &:active, &.highlighted, &.active
        {
            color: @black;
            background: @e;
        }
    }
}
</style>