<template>
    <div class="source-links">
        <datatable :columns="columns" :rows="all" :header="true" @clicked="row => advanced(row)" :loading="loading" :clickable="({$row, $first}) => !$first" class="card oh" />
        <div class="flex flex-align">
            <div class="button" @click="$emit('create')">Create a Link</div>
        </div>
    </div>
</template>

<script>
    import { Bank, Svg3dSelectSolid, MacOsWindow } from '@epiphany/iconoir';
    import LinkConfiguration from '@/components/drawers/LinkConfiguration.vue';
    import _ from 'lodash';

    export default {
        name: 'SourceLinks',
        props: {
            loading: Boolean,
            error: Boolean,
            disabled: Boolean,
            source: Object,
            enrichment: Object,
            team: Object,
            source: Object,
            create: Boolean,
            sources: Array,
            links: Array
        },
        computed: {
            all(){
                const all = this.links.slice();

                // Unshift the primary source.
                all.unshift({
                    id: 'primary',
                    priority: 'Primary Source',
                    source: this.source,
                    provider: this.source.provider
                });

                return all;
            },
            admin(){
                return this.$store.getters.user?.admin;
            }
        },
        methods: {
            name(link){
                return this.sources.find(source => source.id === link.secondary.source_id)?.name;
            },
            advanced(link) {
                this.$store.dispatch('drawer/open', {
                    key: `links/${link.id}`,
                    width: 1000,
                    component: LinkConfiguration,
                    props: {
                        link: _.cloneDeep(link)
                    }
                });
            }
        },
        data() {
            return {
                columns: [
                    {
                        id: 'source',
                        name: 'Source Name',
                        icon: Bank,
                        width: '40%',
                        value: row => row.source.name
                    },
                    {
                        id: 'priority',
                        name: 'Priority',
                        icon: Svg3dSelectSolid,
                        width: '30%',
                        value: row => row.priority
                    },
                    {
                        id: 'provider',
                        name: 'Provider',
                        icon: MacOsWindow,
                        width: '20%',
                        value: row => row.provider.name
                    }
                ]
            };
        },
        watch: {
            source() {
                if(this.source.configuration.sso_provider) {
                    this.source = this.sources.find(source => source.id === source.configuration.sso_provider);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .datatable
    {
        margin-bottom: 20px;
    }




    thead
    {
        tr
        {
            border-top: 0;
        }
    }

    .sso-provider
    {
        border-bottom: 1px solid @e4;

        &:last-child
        {
            border-bottom: none;
        }
    }

    .sso-source
    {
        padding: 10px 15px 10px 10px;
    }

    .source-icon-cell
    {
        padding: 8px 12px;
        width: 48px;
        border-bottom-color: transparent;
    }

    .source-icon
    {
        img
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: @e;
            display: block;
        }

        &::after
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            content: "";
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .child-arrow
    {
        font-size: 20px;
        color: @grey;
        width: 32px;
        height: 32px;
        padding-left: 6px;

        .icon
        {
            width: 20px;
            height: 20px;
        }
    }

    .source-name
    {
        color: @black;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
    }

    .link
    {
        color: @grey;
    }

    .source-priority
    {
        text-align: center;
        width: 60px;

        .text-button.mini.border
        {
            padding: 1px 8px;
            color: @black;
            cursor: pointer;
            text-align: center;
            border-radius: 3px;
            font-size: 13px;
            line-height: 20px;
        }
    }

    .source-options
    {
        padding-right: 10px;

        .text-button.mini
        {
            margin-left: 10px;
            font-size: 13px;
            // font-weight: 400;
        }
    }

    .create-link
    {
        padding: 8px 12px;
        color: @base;

        .child-arrow
        {
            padding-left: 2px;
            margin-right: 12px;
        }

        .text-button.mini
        {
            font-size: 13px;
            line-height: 20px;
        }
    }
</style>
