<template>
    <div class="dataset full">
        <filters>
            <filter-custom :applied="filters" @apply="apply" :filterable="filterable" />
            <div class="tools flex flex-align">
                <div class="tool sort flex flex-align" @click="create" v-if="can_create_override">
                    <AddCircle class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Create</div>
                </div>
            </div>
            <div class="ff"></div>
            <pages :all="all" :rows="rows" :more="next_page_available" :count="count" :page="page" @next="next" @previous="previous" />
            <div class="button" @click="queue_flow" v-if="type === 'flows' && admin">Queue Flow</div>
            <div class="button" @click="queue_materialization" v-if="type === 'materializations'">Run Materialization</div>
            <div class="button white has-icon" @click="save" v-if="exportable">
                <span class="icon iconoir-database-export"></span>
                Download
            </div>
        </filters>
        <datatable class="dataset full" :columns="types[type]" :rows="rows" :selectable="true" :header="true" :loading="loading" :clickable="true" @clicked="(row) => focus(row)" />
    </div>
</template>

<script>
import _ from 'lodash';
import { Filter as FilterIcon, AddCircle, Cancel, FingerprintSquare, Svg3dSelectSolid, Calendar, CodeBracketsSquare } from '@epiphany/iconoir';

import CreateEntity from '@/components/drawers/entity/create/CreateEntity.vue';

import Entity from '@/components/drawers/Entity.vue';
import Override from '@/components/drawers/Override.vue';
import Event from '@/components/drawers/Event.vue';
import Materialization from '@/components/drawers/Materialization.vue';
import Request from '@/components/drawers/Request.vue';
import Job from '@/components/drawers/Job.vue';
import Flow from '@/components/drawers/Flow.vue';
import Options from '@/components/modals/Options.vue';

import parser from 'papaparse';
import { flatten } from 'flat';

const external_object_types = [
    'agents',
    'assets',
    'attendance',
    'calendars',
    'classes',
    'courses',
    'days',
    'departments',
    'districts',
    'enrollments',
    'facilities',
    'fees',
    'incidents',
    'meetings',
    'people',
    'periods',
    'rooms',
    'routes',
    'schools',
    'sections',
    'sessions',
    'stops',
    'subjects',
    'vehicles'
];

const valid_types = external_object_types.concat([
    // Custom types.
    'events',
    'materializations',
    'flows',
    'jobs'
]);

const components = {
    overrides: Override,
    requests: Request,
    events: Event,
    materializations: Materialization,
    jobs: Job,
    flows: Flow
};

export default {
    name: 'IntegrationDataset',
    components: {
        FilterIcon,
        AddCircle,
        Cancel
    },
    computed: {
        exportable() {
            return this.external_object_types.includes(this.type);
        },
        team() {
            return this.$store.getters.team;
        },
        integration() {
            return this.$store.getters.integration;
        },
        admin() {
            return this.$store.getters.user.admin;
        },
        type() {
            if (valid_types.includes(this.$route.params.type)) {
                return this.$route.params.type;
            }

            return null;
        },
        rows() {
            return this.all.slice(this.page * this.count, this.page * this.count + this.count);
        },
        filterable() {
            return this.type ? this.types[this.type].filter(property => property.filter) : [];
        },
        property() {
            return this.types[this.type].find(type => type.property === this.filter.property);
        },
        total() {
            if (!this.summary) {
                return 0;
            }

            if (!this.type) {
                return 0;
            }

            switch (this.type) {
                case 'people':
                    return this.summary.people.total;
                case 'sessions':
                    return this.summary.sessions.total;
                case 'schools':
                    return this.summary.organizations.schools;
                case 'classes':
                    return this.summary.organizations.classes;
                case 'courses':
                    return this.summary.courses.total;
                case 'districts':
                    return 1;
                case 'logs':
                    return 0;
                case 'events':
                    return 0;
                case 'materializations':
                    return 0;
                case 'overrides':
                    return 0;
            }
        },
        can_create_override(){
            return this.admin ? true : ['people', 'schools', 'classes', 'courses', 'sessions'].includes(this.type);
        }
    },
    watch: {
        type: {
            immediate: true,
            handler() {
                //Reset the page number and filters.
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filters = [];

                if (this.$route.query.filters) {
                    try {
                        const query = JSON.parse(this.$route.query.filters);

                        if(_.isArray(query)){
                            this.filters = query;
                        }
                    } catch (error) {
                        this.$toasted.error('Invalid filter.');
                    }
                }

                if (this.integration) {
                    this.load().then(() => {
                        if (this.page === 0 && _.isNil(this.$route.query.before) && _.isNil(this.$route.query.after)) {
                            this.$router.push({
                                query: {
                                    filters: JSON.stringify(this.filters),
                                    before: _.get(_.last(this.rows), 'id'),
                                    after: _.get(_.first(this.rows), 'id')
                                }
                            });
                        }
                    });
                }
            }
        },
        integration: {
            immediate: true,
            handler() {
                //Reset the page number and filters.
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filters = [];

                if (this.$route.query.filters) {
                    try {
                        const query = JSON.parse(this.$route.query.filters);

                        if(_.isArray(query)){
                            this.filters = query;
                        }
                    } catch (error) {
                        this.$toasted.error('Invalid filter.');
                    }
                }

                this.$http.get(`/teams/${this.team.id}/integrations/${this.integration.id}/analytics`).then(response => this.summary = response.$data);

                if (this.type) {
                    this.load();
                }
            }
        },
        page: {
            immediate: true,
            handler(new_page, old_page) {
                if (new_page === 0 && _.isNil(this.$route.query.before) && _.isNil(this.$route.query.after)) {
                    this.$router.push({
                        query: {
                            filters: JSON.stringify(this.filters),
                            before: _.get(_.last(this.rows), 'id'),
                            after: _.get(_.first(this.rows), 'id')
                        }
                    })

                    return;
                } else if (new_page === 0 && (!_.isNil(this.$route.query.before) || !_.isNil(this.$route.query.after))) {
                    this.$router.push({
                        query: {
                            filters: JSON.stringify(this.filters),
                            before: null,
                            after: null
                        }
                    })
                }

                if (new_page === old_page) {
                    return;
                }

                if (_.isNil(this.rows) || _.isEmpty(this.rows)) {
                    return;
                }

                this.$router.push({
                    query: {
                        filters: JSON.stringify(this.filters),
                        before: _.get(_.last(this.rows), 'id'),
                        after: _.get(_.first(this.rows), 'id')
                    }
                });
            }
        }
    },
    methods: {
        async export_to_csv(export_all = false) {
            const data = export_all ? await this.load_all() : this.rows;
            const date = new Date().toJSON().slice(0, 10);

            const filename = `${this.integration.id}_${this.type}_${date}.csv`;

            const headers = _.keys(flatten(_.first(data), {
                safe: true // Arrays will be preserved.
            }));

            const rows = [headers];

            for (const row of data) {
                const values = [];

                for (const header of headers) {
                    const value = _.get(flatten(row, { safe: true }), header);

                    if (_.isArray(value) && this.is_array_of_objects(value)) {
                        values.push(JSON.stringify(value));
                    } else if (_.isArray(value)) {
                        values.push(value.join(', '));
                    } else if (_.isObject(value)) {
                        values.push(JSON.stringify(value));
                    } else {
                        values.push(value);
                    }
                }

                rows.push(values);
            }

            const parsed_csv = parser.unparse(rows);

            const blob = new Blob([parsed_csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = filename;
            a.click();

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0);
        },
        save() {
            const _this = this;

            this.$modal.show(
                Options,
                {
                    title: 'Download Dataset',
                    description: 'These entities may contain sensitive student data. Please ensure you are downloading them to a secure location and handling them in accordance with your data protection policy. Note that only the first 10,000 items matching your current filters will be downloaded.',
                    options: [
                        {
                            title: 'Download All',
                            color: 'base',
                            async fn() {
                                await _this.export_to_csv(true);
                            }
                        },
                        {
                            title: 'Download Current Page',
                            color: 'base',
                            async fn() {
                                await _this.export_to_csv(false);
                            }
                        }
                    ]
                },
                { width: 300, height: 'auto', classes: 'modal' }
            );
        },
        /**
         * Check that the given array contains values that are
         * plain objects that are not properly serialized to
         * a string without JSON.stringify.
         */
        is_array_of_objects(value) {
            if (_.isArray(value) && value.length > 0) {
                return value.toString().includes('[object Object]');
            }

            return false;
        },
        load_all() {
            this.params.$filter = {};

            try {
                const query = JSON.parse(this.$route.query.filters);
                if (_.isArray(query)) {
                    this.filters = query;
                }
            } catch (error) {
                this.filters = [];
            }

            if (this.filters && this.filters.length > 0) {
                for (const { value, property, operator } of this.filters) {
                    if (value) {
                        this.params.$filter[property] = [{ operator, value }];
                    }
                }
            }

            /**
             * Fetch the last 10,000 records.
             */
            const params = this.params;

            if (this.type === 'people') {
                params.$expand = 'overrides,schools';
            } else {
                params.$expand = 'overrides';
            }

            params.$last = 10000;

            const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

            const config = {
                method: 'get',
                url: `${base}/api/v2/graph/${this.type}`,
                params,
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                }
            };

            return this.$http
            .request(config)
            .then((results) => results.$data)
            .catch((e) => this.$toasted.error('Unable to load dataset'));
        },
        overridden(row, field) {
            return _.has(row, `overrides.properties.${field.property}`);
        },
        load() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            // Apply our filter to the params array.
            this.params.$filter = {};

            for(const { value, property, operator } of this.filters) {
                if (value || ['is known', 'is unknown'].includes(operator)) {
                    this.params.$filter[property] = [{ operator, value }];
                }
            }

            const params = _.clone(this.params);
            /*
                The following types will be fetched in descending order.
            */
            if (['requests', 'events', 'materializations', 'flows', 'jobs'].includes(this.type)) {
                params.$last = 100;
            }

            if (this.type === 'people') {
                params.$expand = 'overrides,schools';
            }else{
                params.$expand = 'overrides';
            }

            const base = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

            const config = {
                method: 'get',
                url: `${base}/api/v2/graph/${this.type}`,
                params,
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                }
            };

            return this.$http.request(config)
            .then(results => {
                this.all.push(...results.$data);
                if(results.$next){
                    this.next_page_available = true;
                }else{
                    this.next_page_available = false;
                }
            })
            .catch(error => this.$toasted.error('There was an error loading results.'))
            .finally(() => this.loading = false);
        },
        focus(entity) {
            this.$store.dispatch('entities/activate', {
                id: entity.id,
                type: this.type,
                integration: this.integration
            })
            .then(() => {
                this.$store.dispatch('drawer/open', {
                    key: `${this.type}/${entity.id}`,
                    width: 800,
                    component: components.hasOwnProperty(this.type) ? components[this.type] : 'entity'
                });
            });
        },
        previous() {
            //We definitely have these results already.
            if (this.page) {
                this.page -= 1;
            }
        },
        next() {
            if((this.page + 1) * this.count < this.all.length || this.next_page_available){
                //If we already have these results, we can just swap them in.
                //Otherwise, we need to fetch from the server.
                if(this.all.length <= this.count * (this.page + 1)){
                    if (['requests', 'events', 'materializations', 'flows', 'jobs'].includes(this.type)) {
                        this.params.$before = this.all[this.all.length - 1].id;
                    } else {
                        this.params.$after = this.all[this.all.length - 1].id;
                    }
                    this.load().then(() => this.page += 1);
                }else{
                    this.page += 1;
                }
            }
        },
        apply(filters) {
            this.page = 0;
            this.all = [];
            this.params = {};
            this.filters = filters;

            if (this.filters.length) {
                this.$router.push({ query: { filters: JSON.stringify(this.filters) } });
            } else {
                this.$router.push({ query: null });
            }

            if (this.integration) {
                this.load();
            }
        },
        clear() {
            this.page = 0;
            this.all = [];
            this.params = {};
            this.filters = [];

            if (this.$route.query.filters) {
                this.$router.push({ query: null, before: null, after: null });
            }

            if (this.integration) {
                this.load();
            }
        },
        open(property, row) {
            //This method takes an object and opens the correct dataset with a filter applied.
            const types = {
                school: 'schools',
                course: 'courses',
                section: 'sections',
                district: 'districts'
            };

            this.$router.push({
                params: {
                    type: property === 'person' ? 'people' : types[row.organization.type]
                },
                query: {
                    filters: JSON.stringify([{
                        property: 'id',
                        operator: 'equals',
                        value: property === 'person' ? row.person.id : row.organization.id
                    }])
                }
            });
        },
        create() {
            this.$store.dispatch('drawer/open', {
                key: `create/${this.type}`,
                width: 800,
                component: CreateEntity,
                props: {
                    type: this.type,
                    integration: this.integration
                }
            });
        },
        queue_materialization(){
            this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/materializations`)
                .then(response => {
                    this.$toasted.success('Materialization queued.');
                })
                .catch(error => {
                    this.$toasted.error('There was an error queuing the materialization.');
                });
        },
        queue_flow(){
            this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/flows`)
                .then(response => {
                    this.$toasted.success('Flow queued.');
                })
                .catch(error => {
                    this.$toasted.error('There was an error queuing the flow.');
                });
        }
    },
    data() {
        return {
            loading: false,
            all: [],
            visible: [],
            filters: [],
            page: 0,
            count: 100,
            next_page_available: false,
            params: {},
            offset: 0,
            summary: null,
            operators: {
                'equals': 'Equals',
                'in': 'Is Any Of',
                'not in': 'Is None Of',
                'starts with': 'Starts With',
                'contains': 'Contains',
                'is known': 'Is Known',
                'is unknown': 'Is Unknown',
                'gt': 'Greater Than',
                'gte': 'Greater Than Or Equal To',
                'lt': 'Less Than',
                'lte': 'Less Than Or Equal To'
            },
            filter_options: {
                uuid: ['equals', 'in', 'not in'],
                string: ['equals', 'starts with', 'contains', 'in', 'not in', 'is known', 'is unknown'],
                date: ['equals', 'gt', 'lt', 'gte', 'lte'],
                enum: ['equals', 'in', 'not in'],
                array: ['equals', 'in', 'not in', 'is known', 'is unknown']
            },
            types: {
                people: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Person ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'first_name',
                        property: 'first_name',
                        name: 'First Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.first_name ? row.overrides.properties.first_name : row.first_name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'last_name',
                        property: 'last_name',
                        name: 'Last Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.last_name ? row.overrides.properties.last_name : row.last_name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'roles',
                        property: 'roles',
                        name: 'Roles',
                        type: 'array',
                        width: '140px',
                        sort: false,
                        filter: true,
                        array: true,
                        value: (row) => row.overrides?.properties?.roles ? row.overrides?.properties?.roles.join(', ') : row.roles.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'email',
                        property: 'email',
                        name: 'Email',
                        type: 'string',
                        width: '260px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.email ? row.overrides.properties.email : row.email,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                sessions: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Session ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Type',
                        type: 'session_type',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'start_date',
                        property: 'start_date',
                        name: 'Start Date',
                        type: 'date',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.start_date ? this.$options.filters.pretty(row.overrides.properties.start_date, 'date') : this.$options.filters.pretty(row.start_date, 'date'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'end_date',
                        property: 'end_date',
                        name: 'End Date',
                        type: 'date',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.end_date ? this.$options.filters.pretty(row.overrides.properties.end_date, 'date'): this.$options.filters.pretty(row.end_date, 'date'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                districts: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'District ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                schools: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'School ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                classes: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Class ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'enum',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'grade_levels',
                        property: 'grade_levels',
                        name: 'Grade Levels',
                        type: 'array',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.grade_levels ? row.overrides?.properties?.grade_levels.join(', ') : row.grade_levels.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'update_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                sections: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Section ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'enum',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                courses: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Course ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'grade_levels',
                        property: 'grade_levels',
                        name: 'Grade Levels',
                        type: 'array',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.grade_levels ? row.overrides?.properties?.grade_levels.join(', ') : row.grade_levels.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                enrollments: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Enrollment ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'person_id',
                        property: 'person_id',
                        name: 'Person ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'class_id',
                        property: 'class_id',
                        name: 'Class ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: false,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'start_date',
                        property: 'start_date',
                        name: 'Start Date',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: false,
                        value: (row, column) => row.overrides?.properties?.start_date ? this.$options.filters.pretty(row.overrides?.properties?.start_date, 'medium') : this.$options.filters.pretty(row.start_date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'end_date',
                        property: 'end_date',
                        name: 'End Date',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: false,
                        value: (row, column) => row.overrides?.properties?.end_date ? this.$options.filters.pretty(row.overrides?.properties?.end_date, 'medium') : this.$options.filters.pretty(row.end_date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                agents: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Agent ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'relationship',
                        property: 'relationship',
                        name: 'Relationship',
                        type: 'enum',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.relationship ? row.overrides.properties.relationship : row.relationship,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'flags',
                        property: 'flags',
                        name: 'Flags',
                        type: 'array',
                        width: '260px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.flags ? row.overrides.properties.flags.join(', ') : row.flags.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                assets: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Asset ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                attendance: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Attendance ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'Attendance State',
                        type: 'uuid',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'flags',
                        property: 'flags',
                        name: 'Attendance Flags',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true,
                        value: (row, column) => row.overrides?.properties?.flags ? row.overrides?.properties?.flags.join(', ') : row.flags.join(', '),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                calendars: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Calendar ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: 220,
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                days: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Day ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'date',
                        property: 'date',
                        name: 'Day Date',
                        type: 'date',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.date ? this.$options.filters.pretty(row.overrides.properties.date, 'medium') : this.$options.filters.pretty(row.date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                departments: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Department ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                facilities: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Facility ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                fees: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Fee ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'amount',
                        property: 'amount',
                        name: 'Amount',
                        type: 'string',
                        width: '100px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.amount ? row.overrides.properties.amount : row.amount,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                incidents: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Incident ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'title',
                        property: 'title',
                        name: 'Title',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.title ? row.overrides.properties.title : row.title,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'date',
                        property: 'date',
                        name: 'Incident Date',
                        type: 'date',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.date ? this.$options.filters.pretty(row.overrides.properties.date, 'medium') : this.$options.filters.pretty(row.date, 'medium'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                meetings: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Meeting ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'class_id',
                        property: 'class_id',
                        name: 'Class ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'period_id',
                        property: 'period_id',
                        name: 'Period ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                periods: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Period ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Period Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Period Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Period Type',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'start_time',
                        property: 'start_time',
                        name: 'Start Time',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row, column) => row.overrides?.properties?.start_time ? this.$options.filters.pretty(row.overrides.properties.start_time, 'short_time') : this.$options.filters.pretty(row.start_time, 'short_time'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'end_time',
                        property: 'end_time',
                        name: 'End Time',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row, column) => row.overrides?.properties?.end_time ? this.$options.filters.pretty(row.overrides.properties.end_time, 'short_time') : this.$options.filters.pretty(row.end_time, 'short_time'),
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: 220,
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                rooms: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Room ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'number',
                        property: 'number',
                        name: 'Room Number',
                        type: 'string',
                        width: '120px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.number ? row.overrides.properties.number : row.number,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'capacity',
                        property: 'capacity',
                        name: 'Room Capacity',
                        type: 'string',
                        width: '120px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.capacity ? row.overrides.properties.capacity : row.capacity,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'category',
                        property: 'category',
                        name: 'Category',
                        type: 'string',
                        width: 200,
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.category ? row.overrides.properties.category : row.category,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                routes: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Route ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Route Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'description',
                        property: 'description',
                        name: 'Description',
                        type: 'string',
                        width: '300px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.description ? row.overrides.properties.description : row.description,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'mileage',
                        property: 'mileage',
                        name: 'Mileage',
                        type: 'string',
                        width: '100px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.mileage ? row.overrides.properties.mileage : row.mileage,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                stops: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Stop ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Stop Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Stop Type',
                        type: 'string',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                subjects: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Subject ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Subject Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'code',
                        property: 'code',
                        name: 'Subject Code',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.code ? row.overrides.properties.code : row.code,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                vehicles: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Vehicle ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'name',
                        property: 'name',
                        name: 'Vehicle Name',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.name ? row.overrides.properties.name : row.name,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Type',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.type ? row.overrides.properties.type : row.type,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true,
                        value: (row) => row.overrides?.properties?.state ? row.overrides.properties.state : row.state,
                        classes: (row, column) => this.overridden(row, column) ? {override: true} : null
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                events: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Event ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true,
                        icon: FingerprintSquare
                    },
                    {
                        id: 'type',
                        property: 'type',
                        name: 'Type',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        icon: CodeBracketsSquare
                    },
                    {
                        id: 'target',
                        property: 'target',
                        name: 'Target',
                        type: 'string',
                        width: '200px',
                        sort: false,
                        filter: true,
                        icon: Svg3dSelectSolid
                    },
                    {
                        id: 'date',
                        property: 'date',
                        name: 'Event Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        icon: Calendar,
                        value: (row, column) => this.$options.filters.pretty(row.date, 'long')
                    }
                ],
                materializations: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Materialization ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'created_date',
                        property: 'created_date',
                        name: 'Created Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                flows: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Flow ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'created_date',
                        property: 'created_date',
                        name: 'Created Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ],
                jobs: [
                    {
                        id: 'id',
                        property: 'id',
                        name: 'Job ID',
                        type: 'uuid',
                        width: '260px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'state',
                        property: 'state',
                        name: 'State',
                        type: 'string',
                        width: '140px',
                        sort: false,
                        filter: true
                    },
                    {
                        id: 'created_date',
                        property: 'created_date',
                        name: 'Created Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.created_date, 'long')
                    },
                    {
                        id: 'updated_date',
                        property: 'updated_date',
                        name: 'Updated Date',
                        type: 'date',
                        width: '180px',
                        sort: false,
                        filter: true,
                        value: (row, column) => this.$options.filters.pretty(row.updated_date, 'long')
                    }
                ]
            },
            external_object_types
        };
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.dataset
{
    padding-top: @filters-height;
}

::v-deep
{
    .datatable-cell.override
    {
        padding-left: 24px;
        background: url('~@/assets/icons/base/pin.svg') 10px 15px no-repeat;
        background-size: 6px auto;
        color: @base;
    }
}

.filters
{
    .button
    {
        margin-left: 10px;
    }
}

.custom-filters
{
    width: 500px;
    min-height: 100px;
    background: @f8;
}

.filter-form
{
    width: 300px;
    height: 300px;
    padding: 20px;

    select, input
    {
        margin-top: 10px;
    }

    .button
    {
        margin: 10px 10px 0 0;
    }
}
</style>
