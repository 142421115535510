<template>
    <div class="image-modal">
        <div class="header flex flex-align">
            <div>{{ title ? title : 'Help Image' }}</div>
            <div class="ff"></div>
            <Cancel class="icon pointer" width="18" height="18" stroke-width="2" color="#001529" @click.native="$emit('close')" />
        </div>
        <div class="image">
            <spinner v-if="loading" />
            <img v-else :src="src">
        </div>
    </div>
</template>

<script>
    import { Cancel } from '@epiphany/iconoir';

    export default {
        name: 'HelpImage',
        components: {
            Cancel
        },
        props: {
            src: String,
            title: String
        },
        data(){
            return {
                loading: true
            };
        },
        methods: {
        },
        created(){
            const img = new Image();
            
            img.src = this.src;

            img.onload = () => {
                this.loading = false;
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .image-modal {
        .image {
            padding: 0 15px 15px 15px;
            width: 100%;

            img {
                // min-height: 400px;
                width: 100%;
                border-radius: 6px;
            }
        }

        .header {
            height: 45px;
            font-size: 14px;
            padding: 0 15px;
            color: @black;
        }
    }

</style>
