<template>
    <div class="share-products">
        <header class="drawer-header flex flex-align">
            <div class="ff drawer-title flex flex-align">
                <Svg3dSelectSolid class="icon" width="20" height="20" stroke-width="2" />
                Share Products
            </div>
            <div class="button white" @click="$store.dispatch('drawer/close')">Close</div>
            <div class="button share" @click="shareProducts()">Share</div>
        </header>
        <div class="drawer-content selection flex flex-column">
            <div class="selection-search">
                <input type="text" class="search" placeholder="Search Products" @input="v => loadProducts(v.target.value)" />
            </div>
            <div class="flex flex-align search-options">
                <checkbox label="Select All" :checked="hasAll()" @input="$value => toggleAll($value)"/>
            </div>
            <div v-if="loading" class="flex flex-center flex-align loading ff">
                <spinner />
            </div>
            <template v-else>
                <div class="selection-results scroll ff">
                    <div class="flex flex-align selection-result"
                        v-for="result of results" :key="result.id"
                        @click="toggle(result.id)"
                        :class="{ checked: has(result.id) }">
                        <input class="checkbox-input none" type="checkbox"
                            :checked="has(result.id)" />
                        <div class="ff result-details flex flex-align`">
                            <div class="flex flex-column">
                                <div class="result-name">{{ result.title }}</div>
                                <div class="result-id" v-if="result.subtitle">{{ result.subtitle }}</div>
                            </div>
                            <div class="ff"></div>
                            <div class="result-state" v-if="result.extra"><ProductState :state="result.extra" /></div>
                        </div>
                    </div>
                    <div v-if="false" class="text-button load-more"
                        @click="() => {}" :class="{ disabled: loading }">
                        Load More
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { Svg3dSelectSolid } from '@epiphany/iconoir';
    import ProductState from '@/components/chips/ProductState.vue';

    export default {
        name: 'ShareProducts',
        components: {
            Svg3dSelectSolid,
            ProductState
        },
        data() {
            return {
                results: [],
                selected: [],
                loading: false
            };
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            integration() {
                return this.$store.getters.integration;
            },
            products() {
                return this.$store.state.drawer.props.products;
            },
            refreshParent() {
                return this.$store.state.drawer.props.refreshParent;
            }
        },
        mounted() {
            this.loadProducts();
        },
        methods: {
            loadProducts(query) {
                this.loading = true;
                const params = {
                    $filter: {
                        id: [{ operator: 'not in', value: this.products.map(p => p.id).join(',') }]
                    },
                    $first: 1000
                };

                if (query) {
                    params.$filter.name = [{ operator: 'contains', value: query }];
                }

                this.results = [];

                this.fetch(params);
            },
            fetch(params){
                return this.$http.get(`/teams/${this.team.id}/products`, {
                    params,
                    baseURL: '/api/v2'
                }).then(response => {
                    this.results = this.results.concat(response.$data.map(p => {
                        return {
                            id: p.id,
                            title: p.name,
                            subtitle: p.code,
                            extra: p.state
                        }
                    }));

                    if (response.$next) {
                        params.$after = response.$data[response.$data.length - 1].id;
                        return this.fetch(params);
                    }
                })
                .catch(error => {
                    this.$toasted.error('There was an error loading your products.');
                })
                .finally(() => {
                    this.loading = false;
                })
            },
            shareProducts(){
                this.loading = true;
                this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/products`, {
                    products: this.selected.map(id => {
                        return {
                            id: id
                        }
                    })
                }, { baseURL: '/api/v2' })
                    .then(response => {
                        this.refreshParent().then(() => {
                            this.$store.dispatch('drawer/close');
                        });
                    })
                    .catch(error => {
                        this.$toasted.error('There was an error sharing your products.');
                        this.loading = false;
                    });
            },
            toggle(id){
                if(this.has(id)){
                    this.selected = this.selected.filter(s => s !== id);
                } else {
                    this.selected.push(id);
                }
            },
            has(id){
                return this.selected.includes(id);
            },
            toggleAll(){
                if(this.selected.length === this.results.length){
                    this.selected = [];
                } else {
                    this.selected = this.results.map(r => r.id);
                }
            },
            hasAll(){
                if (this.loading) {
                    return false;
                } else {
                    return this.selected.length === this.results.length;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-application-button
    {
        margin-top: 30px;
    }

    .share-products
    {
        height: 100%;

        .drawer-content
        {
            // padding: 20px;
        }
    }

    .drawer-scroll.drawer-content
    {
        top: 51px;
        overflow: auto;
    }

    .drawer-header {
        .button {
            margin-left: 8px;
        }
    }

.selection {
    .selection-search {
        padding: 10px 15px 0px;

        .search {
            width: 100%;
            background-color: @f;
        }
    }

    .selection-results {
        height: calc(100% - 51px);
        padding: 0 10px;
    }

    .selection-result {
        padding: 0 15px;
        cursor: pointer;

        .checkbox-input {
            margin: 0 15px 0 0;
        }

        .result-details {
            padding: 10px 0;
            border-bottom: 1px solid @e4;
            margin-bottom: -1px;
        }

        .result-name,
        .result-state {
            font-size: 13px;
            color: @black;
            line-height: 16px;
        }

        .result-id {
            font-size: 11px;
            color: @grey;
            line-height: 14px;
            margin-top: 2px;
        }

        &:last-child {
            .result-details {
                border-bottom: 0;
            }
        }
    }

    .search-options {
        padding: 10px 15px 10px;
        border-bottom: 1px solid @e4;

        .checkbox
        {
            height: 32px;
        }
    }
}
</style>
