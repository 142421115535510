<template>
    <div class="select-source">
        <h4 class="center">{{title}}</h4>
        <div class="loading flex flex-align flex-center" v-if="loading">
            <spinner />
        </div>
        <template v-else>
            <div class="options" v-if="applications.length">
                <div class="option flex flex-align" @click="activate(application)" v-for="application of applications" :key="application.id">
                    <div class="option-name ff text-overflow">{{application.name}}</div>
                    <div class="option-check" v-if="selected && application.id === application.id"></div>
                </div>
            </div>
            <div class="nothing" v-else>
                You do not have any applicatons connect.
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Select',
        props: {
            title: String,
            selected: Object,
            select: Function,
            filter: Function
        },
        data() {
            return {
                applications: [],
                loading: true
            };
        },
        created() {

            this.$http.get(`/teams/${this.$store.getters.team.id}/applications`)
                .then(response => {

                    this.applications = response.$data
                        .filter(application => this.filter(application))
                        .sort((a, b) => a.name.localeCompare(b.name));
                    this.loading = false;

                })
                .catch(error => this.$toasted.error('There was an error loading your team\'s applications.'));

        },
        methods: {
            activate(option) {
                if(this.select){
                    this.select(option);
                }

                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .select-source
    {
        h4
        {
            font-weight: 500;
            padding: 10px;
            font-size: 14px;
            color: @grey;
        }

        .option
        {
            border-top: 1px solid @e;
            padding: 15px;
            cursor: pointer;
            transition: background-color ease 0.2s;

            &:hover
            {
                background: @f8;
            }
        }

        .option-name
        {
            font-size: 16px;
            color: @black;
            line-height: 20px;
        }

        .option-membership
        {
            color: @grey;
            line-height: 20px;
            text-transform: capitalize;
        }

        .option-check
        {
            margin-left: 15px;
            background: url('~@/assets/icons/white/check.svg') 1px 3px no-repeat @base;
            background-size: 18px auto;
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
    }

    .loading
    {
        padding: 30px 0 50px;
    }

    .nothing
    {
        padding: 30px;
        border: 0;
        font-size: 13px;

        span
        {
            text-transform: uppercase;
        }
    }
</style>
