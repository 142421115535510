<template>
    <div class="admin-people">

        <section class="flex">
            <div class="section-content ff">
                <h2>Person Change Information (Click on row to access more change data)</h2>

          

    <table>
            <thead>
                <tr class="change">
                    <th class="change-status">Change Id</th>
                    <th>Sync Id</th>
                    <th>Description</th>
                    <th class="text-right">Change Date</th>
                </tr>
            </thead>
            <tbody>
               
                <router-link tag="tr" class="request selectable" v-for="change of changes" :key="change.id" :to="{name: 'admin.person.change', params: {change: change.id}}">
                    <td class="request-status">
                        <span>{{change.id}}</span>                                                
                    </td>
                 
                 
                 <td class="request-status">
                        <span>{{change.sync.id}}</span>                        
                    </td>
                    <td class="request-path text-overflow">
                        
                        {{change.type}}
                    </td>
                    <td class="text-overflow request-date text-right">{{change.date | pretty("long")}}</td> -->
                </router-link>

               
            </tbody>
        </table>
              
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'AdminPerson',
    data() {
        return {
            changes: [],
            search: ''

        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/changes`)
            .then((response) => {
                this.changes = response.$data;

            })
            .catch((error) => {
                this.$toasted.error(
                    'Failed to load change'
                );
            });
    },
    methods: {

    },
    computed: {

    },
};
</script>

<style scoped lang="less">

@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;
      
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;
    }
}

.ff {
    padding: 15px;
}

</style>
