<template>
    <chip class="provider" :color="providers[id].color" :thumbnail="providers[id].icon" :title="providers[id].name" />
</template>

<script>
    export default {
        name: 'Provider',
        props: {
            id: String
        },
        data(){
            return {
                providers: {
                    'a9cbd664-f12c-4627-a520-7582064f0ca4': { color: 'green', name: 'Infinite Campus', icon: '/source/infinitecampus.png' },
                    '10403531-2f6b-49c9-94b0-e23da2b25b78': { color: 'orange', name: 'Focus', icon: '/source/focus.png' },
                    'b187e934-4297-4ea6-86c3-bb81583b5009': { color: 'lightblue', name: 'Illuminate Education', icon: '/source/illuminate.png' },
                    'b69fb425-e42c-4f63-8d41-840ac4b4e03f': { color: 'darkblue', name: 'HelloID', icon: '/source/helloid.png' },
                    '5fe38a52-5c3a-4323-8f91-29327ae7c3ca': { color: 'darkgrey', name: 'Microsoft', icon: '/source/microsoft.png' },
                    '0e8c78a5-29eb-43e6-9c61-1f53b3cd5f53': { color: 'orange', name: 'Bromcom', icon: '/source/bromcom.png' },
                    '28f3beb9-a2af-46f6-ad31-1f10db7a7366': { color: 'lightblue', name: 'Classlink', icon: '/source/classlink.png' },
                    '07de3f01-6b1f-4ba2-9fc0-7d983fa53c9a': { color: 'green', name: 'CSV Upload', icon: '/source/csv.png' },
                    '55c78d6f-504b-4529-a655-b157f23c86f0': { color: 'red', name: 'RapidIdentity', icon: '/source/rapididentity.png' },
                    '0b70b3fc-994c-4641-b686-4e13b5adccae': { color: 'lightblue', name: 'Email & Password', icon: '/source/password.png' },
                    '2f648cbc-e231-44dc-9d73-50ff8a9ccf1b': { color: 'lightblue', name: 'FACTS', icon: '/source/facts.png' },
                    'f969915a-41cb-4875-8f9b-da6370e5a1b6': { color: 'lightblue', name: 'Powerschool', icon: '/source/powerschool.png' },
                    'ff2988d2-e83c-44c7-aa46-07e889c03e62': { color: 'red', name: 'SFTP', icon: '/source/sftp.png' },
                    'b588885a-41e7-4366-81ef-950194827b98': { color: 'lightblue', name: 'Seesaw', icon: '/source/seesaw.png' },
                    '57435673-2135-447c-988b-bb02190e0f61': { color: 'orange', name: 'ItsLearning', icon: '/source/itslearning.png' },
                    '4c318fcc-5639-45fc-b96a-90cfc54df660': { color: 'orange', name: 'Caliper', icon: '/source/kimono.png' },
                    '746abfcf-dab2-4079-a899-72433b0d7afb': { color: 'orange', name: 'LTI Advantage', icon: '/source/oneroster.png' },
                    '121480b2-65bc-4628-a535-843d70fe78dd': { color: 'darkblue', name: 'Skyward', icon: '/source/skyward.png' },
                    '68cc46aa-8463-4189-b8d3-fa2cc6748e92': { color: 'darkblue', name: 'Aspen', icon: '/source/aspen.png' },
                    'e1a5cbf8-b08d-4f88-bdd5-d908f3e5252c': { color: 'yellow', name: 'ClassGather', icon: '/source/classgather.png' },
                    'f78ddf8d-ace1-4b24-bb14-2e5c6704c76b': { color: 'darkblue', name: 'Clever', icon: '/source/clever.png' },
                    '050da476-d03a-41d9-8d6c-3462910d93ca': { color: 'orange', name: 'Moodle', icon: '/source/moodle.png' },
                    '97f551b3-6095-4eb7-88c3-780f17236429': { color: 'darkblue', name: 'Aeries', icon: '/source/aeries.png' },
                    'd2796388-ff1f-4726-89af-fb5e9416c5d6': { color: 'red', name: 'SAML', icon: '/source/saml.png' },
                    'd07c5f8c-e88c-4f70-b073-0f150b488db0': { color: 'orange', name: 'OneRoster', icon: '/source/oneroster.png' },
                    'd2eceb1e-ec5e-4853-9034-ca4a5d96e371': { color: 'purple', name: 'Banner', icon: '/source/banner.png' },
                    'b2039778-3403-4b11-baa9-829095a5da9f': { color: 'blue', name: 'Tyler Technologies', icon: '/source/tyler.png' },
                    '48ac5a15-43ef-49a3-bca0-831369c2bc70': { color: 'green', name: 'Ascender', icon: '/source/ascender.png' },
                    '135d8091-9773-4f21-acf6-43ed1a51647d': { color: 'darkblue', name: 'Veracross', icon: '/source/veracross.png' },
                    'b836c842-7d5f-4763-b7e4-2c69f6a981d6': { color: 'green', name: 'Blackbaud', icon: '/source/blackbaud.png' },
                    '07501a58-30ae-4b72-90be-756ce0f1107c': { color: 'grey', name: 'ManageBac', icon: '/source/managebac.png' },
                    '0ee5e450-4a9e-4b7d-bd30-62b33cdd5456': { color: 'grey', name: 'Google', icon: '/source/google.png' },
                    '47eda4bf-7440-4cd8-9ee7-44cafb98486b': { color: 'blue', name: 'Schoology', icon: '/source/schoology.png' },
                    'b7e1526e-f627-4d86-99c2-465f81a0f0f8': { color: 'black', name: 'Blackboard', icon: '/source/blackboard.png' },
                    'bbc0c87e-47bd-4762-9191-324c8a346bad': { color: 'red', name: 'Rediker', icon: '/source/rediker.png' },
                    'e39f07c1-62de-4f8a-93a2-320d2e1c216a': { color: 'darkblue', name: 'Qmlativ', icon: '/source/qmlativ.png' },
                    '400bee8a-a169-4f37-9de4-348dffd52593': { color: 'purple', name: 'Synergy', icon: '/source/synergy.png' },
                    '147d9b00-1b75-4915-8415-558af0c03b4b': { color: 'purple', name: 'Frontline', icon: '/source/frontline.png' },
                    '9a6e38ed-9070-437c-a624-a120c0d4511e': { color: 'darkgrey', name: 'Auth0', icon: '/source/auth0.png' },
                    '6324a831-e5b7-43e9-a76b-84e024731f94': { color: 'darkblue', name: 'Okta', icon: '/source/okta.png' },
                    '478d23ce-360a-4c4d-b462-dffec8e7dc94': { color: 'blue', name: 'OpenID Connect', icon: '/source/oauth2.png' },
                    '297222b8-13ad-48c3-89bf-44ab32f2a451': { color: 'darkgrey', name: 'XAPI', icon: '/source/oauth1.png' },
                    '5ab3b051-2976-442a-a60c-4cbee43968ca': { color: 'orange', name: 'Brightspace', icon: '/source/brightspace.png' },
                    '599e8990-5a81-435a-8493-de8069235412': { color: 'black', name: 'OneLogin', icon: '/source/onelogin.png' },
                    '035d6dd6-1569-47b2-8ed8-63435be58078': { color: 'red', name: 'Canvas', icon: '/source/canvas.png' },
                    'e3356832-81ba-4318-b1c1-8d3d82f3e575': { color: 'purple', name: 'GG4L', icon: '/source/gg4l.png' },
                    '8df529a7-7ee0-4fbb-85a0-3f6e6b57095b': { color: 'yellow', name: 'Edmodo', icon: '/source/edmodo.png' },
                    'c47c613b-29a3-4772-88ca-6de8c656d5c5': { color: 'purple', name: 'Ed-Fi', icon: '/source/edfi.png' },
                    'e7d1ad4b-65a8-4117-b9ba-f8ca945097e5': { color: 'blue', name: 'Genesis', icon: '/source/genesis.png' },
                    'f5e277c7-a798-47d7-b414-27deb5d7a07d': { color: 'red', name: 'Toddle', icon: '/source/toddle.png' },
                    'dd559a37-c888-4a53-8d3b-7dac0f3f6490': { color: 'blue', name: 'Sapphire', icon: '/source/sapphire.png' }
                }
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
