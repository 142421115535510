<template>
    <div class="entity-address flex">
        <div class="entity-address-header">
            <h4>Address</h4>
        </div>

        <div class="address-form" :class="{editing}" v-if="editing">
            <div class="flex">
                <input placeholder="Street" :value="address.street" @input="(v) => update('street', v.target.value)" @keyup.enter="save" @keyup.esc="cancel" ref="street" />
            </div>
            <div class="flex">
                <input placeholder="Unit" :value="address.unit" @input="(v) => update('unit', v.target.value)" @keyup.enter="save" @keyup.esc="cancel" />
            </div>
            <div class="flex">
                <input placeholder="City" :value="address.city" @input="(v) => update('city', v.target.value)" @keyup.enter="save" @keyup.esc="cancel" />
                <input placeholder="State" :value="address.state" @input="(v) => update('state', v.target.value)" @keyup.enter="save" @keyup.esc="cancel" />
            </div>
            <div class="flex">
                <input placeholder="Postal Code" :value="address.postal_code" @input="(v) => update('postal_code', v.target.value)" @keyup.enter="save" @keyup.esc="cancel" />
                <input placeholder="Country" :value="address.country" @input="(v) => update('country', v.target.value)" @keyup.enter="save" @keyup.esc="cancel" />
            </div>
            <div class="options flex" v-if="editing">
                <div class="text-button mini grey flex flex-align" @click="save">
                    <div>Save</div>
                    <div class="kbd">Enter</div>
                </div>
                <div class="text-button mini grey flex flex-align" @click="cancel">
                    <div>Cancel</div>
                    <div class="kbd">Esc</div>
                </div>
                <div class="ff"></div>
                <div class="text-button mini grey" @click="reset">Reset</div>
            </div>
        </div>

        <template v-else>
            <div class="entity-field summary-field">
                <div class="address-info summary-value editable" v-if="has_address" :class="{override: !!is_overriden}" @click="edit">
                    <div class="flex">{{ address.street }}</div>
                    <div class="flex" v-if="address.unit">{{ address.unit }}</div>
                    <div class="flex">
                        <div v-if="address.city">{{ address.city }}</div>
                        <div v-if="address.city && address.state">,&nbsp;</div>
                        <div>{{ address.state }}</div>
                        <div v-if="address.state">&nbsp;</div>
                        <div>{{ address.postal_code }}</div>
                    </div>
                    <div class="flex entity-value" v-if="address.country">{{ address.country }}</div>
                </div>
                <div class="nothing summary-value editable" @click="edit" v-else>
                    None
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import _ from 'lodash';

    const fields = ['street', 'unit', 'postal_code', 'city', 'state', 'country'];

    export default {
        name: 'EntityAddress',
        components: {
        },
        computed: {
            address() {
                return _.defaults({}, this.override, this.entity.address);
            },
            entity() {
                return this.$store.state.entities.active;
            },
            has_address(){
                for (const field of fields) {
                    if (this.address?.[field]) {
                        return true;
                    }
                }
                return false;
            },
            is_overriden() {
                for (const field of fields) {
                    if (this.override?.[field]) {
                        return true;
                    }
                }
                return false;
            },
            override: {
                get(){
                    const value = {};
                    for (const field of fields) {
                        value[field] = _.get(this.$store.state.entities.overrides, field);
                    }
                    return value;
                },
                set(value){
                    for (const field of fields) {
                        this.$store.commit('entities/override', {
                            property: `address.${field}`,
                            value: value[field]
                        });
                    }
                }
            }
        },
        data(){
            return {
                editing: false,
                pre_edit_address: null,
            }
        },
        methods: {
            edit(){
                this.editing = true;
                this.pre_edit_address = Object.assign({}, this.address);
                this.$nextTick(() => {
                    this.$refs.street.focus();
                });
            },
            save(){
                this.editing = false;
                this.$store.dispatch('entities/update');
            },
            cancel(){
                this.editing = false;
                for (const field of fields) {
                    this.$store.commit('entities/reset', `address.${field}`);
                }
                this.override = Object.assign({}, this.pre_edit_address);
            },
            reset(){
                this.editing = false;
                for (const field of fields) {
                    this.$store.commit('entities/clear', `address.${field}`);
                }
                this.$store.dispatch('entities/update');
            },
            update(prop, value){
                const copy = Object.assign({}, this.address);
                copy[prop] = value;
                this.override = copy;
            },
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    .entity-address
    {
        &-header
        {
            width: 240px;
            
            h4 {
                color: @black;
            }

            .edit
            {
                opacity: 0;
            }

            .edit, .option
            {
                color: @grey;
                font-size: 12px;
                line-height: 16px;
                transition: opacity ease 0.2s;
                margin-left: @single-padding;

                &:last-child
                {
                    margin-right: 0;
                }

                &:hover
                {
                    color: @base;
                }
            }

            .option
            {
                font-weight: 500;
            }

            &.override
            {
                h4
                {
                    color: @base;
                }
            }
        }

        &:hover {
            .entity-address-header .edit
            {
                opacity: 1;
                cursor: pointer;
            }
        }

        .entity-field.summary-field {
            .editable::after {
                top: -2px;
            }

            .nothing {
                height: 16px;
                line-height: 16px;
                font-weight: normal;
            }

            .summary-value::before {
                top: 2px;
            }
        }

        .address-form {
            > div
            {
                margin-bottom: 10px;
            }

            > div:nth-child(3),
            > div:nth-child(4)
            {
                input:first-child {
                    margin-right: 5px;
                }

                input:last-child {
                    margin-left: 5px;
                }
            }

            .options
            {
                position: relative;
                top: auto;
                opacity: 0;
                pointer-events: none;
                margin-bottom: 0px;
                width: 190px;

                .text-button
                {
                    margin-right: 15px;

                    .kbd
                    {
                        color: @base;
                        font-weight: 400;
                        margin-left: 4px;
                        border-radius: 2px;
                        background: fade(@base, 15%);
                        height: 16px;
                        line-height: 16px;
                        font-size: 10px;
                        padding: 0 3px;
                    }

                    &.grey
                    {
                        .kbd
                        {
                            color: @grey;
                            background: fade(@grey, 15%);
                        }
                    }

                    &:last-child
                    {
                        margin-right: 0;
                    }
                }
            }
        }

        .address-form.editing
        {
            .options
            {
                opacity: 1;
                pointer-events: all;
            }
        }

        .address-info {
            div {
                line-height: 16px;
            }
        }
    }
</style>