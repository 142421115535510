<template>
    <div class="entity-details">
        <datatable class="card" :columns="columns" :rows="enrollments" :loading="loading" :header="true" :clickable="true" @clicked="event => focus(event)" :min-height="enrollments.length ? 'auto' : '200px'">
            <template v-slot:empty-message>There are no enrollments for this {{ type === 'people' ? 'Person' : 'Class' }}.</template>
        </datatable>
    </div>
</template>

<script>
    import Entity from '@/components/drawers/Entity.vue';
    import { AlignLeft, CalendarPlus, AntennaSignal, LargeSuitcase } from '@epiphany/iconoir';

    export default {
        name: 'EntityEnrollments',
        data(){
            return {
                loading: true,
                enrollments: [],
                cursor: '',
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '27%',
                        value: row => this.type === 'people' ? row.class.name : row.person.display_name
                    },
                    {
                        id: 'section',
                        name: 'Section',
                        icon: CalendarPlus,
                        width: '45%',
                        value: row => row.section ? row.section.name : '—'
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: AntennaSignal,
                        width: '14%',
                        value: row => row.state
                    },
                    {
                        id: 'role',
                        name: 'Role',
                        icon: LargeSuitcase,
                        width: '14%',
                        value: row => row.role
                    }
                ]
            };
        },
        created(){
            this.loading = true;
            const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

            if(this.type === 'people'){
                //Load the enrollments / classes for this user to show in the sidebar.
                this.$http.get(`${url}/api/v2/graph/people/${this.entity.id}/enrollments`, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    },
                    params: {
                        $first: 1000,
                        $expand: 'class,section'
                    }
                })
                .then(response => {
                    this.enrollments = response.$data;
                    this.loading = false;
                })
                .catch(() => this.$toasted.error('There was an error loading the enrollments for this user.'));
            }else if(this.type === 'classes'){
                //Load the enrollments / people for this class to show in the sidebar.
                this.$http.get(`${url}/api/v2/graph/classes/${this.entity.id}/enrollments`, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    },
                    params: {
                        $first: 1000,
                        $expand: 'person,section'
                    }
                })
                .then(response => {
                    this.enrollments = response.$data;
                    this.loading = false;
                })
                .catch(() => this.$toasted.error('There was an error loading the enrollments for this class.'));
            }
        },
        methods: {
            add(){

            },
            next(){
                // It's possible that there are more than 100 enrollments.
                // If we receive a next cursor from the response, we should use it to fetch the next page.
            },
            focus(enrollment){
                // Enter loading state.
                this.$store.dispatch('drawer/loading');
                // Swap drawer component.
                this.$store.dispatch('drawer/replace', {
                    key: `entity/${enrollment.id}`,
                    width: 800,
                    component: Entity,
                    props: {
                        integration: this.integration,
                        entity: enrollment,
                        type: 'enrollments'
                    }
                });
                // Activate this entity.
                this.$store.dispatch('entities/activate', {
                    id: enrollment.id,
                    type: 'enrollments',
                    integration: this.integration
                })
                .then(() => {
                    // Exit loading state.
                    this.$store.dispatch('drawer/ready')
                });
            },
            status(enrollment){
                return {
                    red: ['dropped'].includes(enrollment.state),
                    yellow: ['upcoming'].includes(enrollment.state),
                    green: ['active'].includes(enrollment.state),
                    grey: ['pending', 'inactive'].includes(enrollment.state),
                    purple: ['completed'].includes(enrollment.state)
                };
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
