<template>
    <div class="admin-people">

        <section class="flex">
            <div class="section-content ff">
                <h2>Person Api Request Information</h2>

                <table>
                    <thead>
                        <tr class="request">
                            <th class="request-status">Status</th>
                            <th>Description</th>
                            <th class="text-right">Request Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <router-link tag="tr" class="request selectable" v-for="request of requests" :key="request.id"
                            :to="{name: 'team.logs.request', params: {team: team.alias, request: request.id}}">
                            <td class="request-status">
                                <div class="flex">
                                    <div class="request-status-badge" :class="{error: request.status > 300}">
                                        {{request.status}} {{request.status < 300 ? "OK" : "ERR" }}</div>
                                    </div>
                            </td>
                            <td class="request-path text-overflow">
                                <span>{{request.method}}</span>
                                {{request.path}}
                            </td>
                            <td class="text-overflow request-date text-right">{{request.date | pretty("long")}}</td>
                        </router-link>
                        <tr v-if="more">
                            <td colspan="3" class="table-load">
                                <div class="text-button" @click="load" :class="{disabled: loading}">Load More</div>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'AdminPerson',
    data() {

        return {
            requests: [],
            team: null,
            search: ''

        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/requests`)
            .then((response) => {

                this.requests = response.$data;
            })
            .catch((error) => {
                this.$toasted.error(
                    'Failed to load request information.'
                );
            });


        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/source`)
            .then((response) => {

                this.source = response.$data;
                this.$http
                    .get(`/admin/teams/${this.source.team.id}`)
                    .then((response) => {
                        this.team = response.$data;
                    })
                    .catch((error) => {
                        this.$toasted.error('Failed to load Teams.');
                    });
            })
            .catch((error) => {
                this.$toasted.error('Failed to load source.');
            });
    },
    methods: {

    },
    computed: {

    },
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;

    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;


    }
}

.request-status-badge {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    color: @green;
    font-family: @monospace;

    &.error {
        color: @red;
    }
}

.ff {
    padding: 15px;
}
</style>
