 <template>
    <div class="entity-value entity-value-boolean flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="max" />
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value.length">
                <template v-if="array">
                    <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{format(item)}}</div>
                </template>
                <div v-else class="badge text-overflow">{{format(value.pop())}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { 
        asset_flags,
        attendance_flags,
        attendance_states,
        calendar_flags,
        calendar_states,
        calendar_types,
        day_flags,
        facility_flags,
        facility_types,
        fee_flags,
        fee_types,
        relationship_types,
        room_flags,
        route_flags,
        stop_flags,
        stop_types,
        vehicle_flags,
        vehicle_fuel_types,
        vehicle_safety_inspection_states,
        vehicle_states,
        vehicle_types
    } from '@/enums';

    export default {
        name: 'EntityValueEnum',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            entityType: String,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                enums: {
                    assets: asset_flags,
                    attendance_flags,
                    attendance_states,
                    calendar_flags,
                    calendar_states,
                    calendar_types,
                    days: day_flags,
                    facility_flags,
                    facility_types,
                    fee_flags,
                    fee_types,
                    relationship_types,
                    rooms: room_flags,
                    route_flags,
                    stop_flags,
                    stop_types,
                    vehicle_flags,
                    vehicle_fuel_types,
                    vehicle_safety_inspection_states,
                    vehicle_states,
                    vehicle_types
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            enum() {
                let k = this.entityType;

                if(this.entityType === 'attendance'){
                    if(this.property === 'flags') {
                        k = 'attendance_flags';
                    }
                    if(this.property === 'state') {
                        k = 'attendance_states'
                    }
                }

                if(this.entityType === 'agents'){
                    if(this.property === 'relationship') {
                        k = 'relationship_types';
                    }
                }

                if(this.entityType === 'calendar'){
                    if(this.property === 'flags') {
                        k = 'calendar_flags';
                    }
                    if(this.property === 'state') {
                        k = 'calendar_states'
                    }
                    if(this.property === 'type') {
                        k = 'calendar_types'
                    }
                }

                if(this.entityType === 'facilities'){
                    if(this.property === 'flags') {
                        k = 'facility_flags';
                    }
                    if(this.property === 'type') {
                        k = 'facility_types'
                    }
                }

                if(this.entityType === 'fees'){
                    if(this.property === 'flags') {
                        k = 'fee_flags';
                    }
                    if(this.property === 'type') {
                        k = 'fee_types'
                    }
                }

                if(this.entityType === 'routes'){
                    if(this.property === 'flags') {
                        k = 'route_flags';
                    }
                }

                if(this.entityType === 'stops'){
                    if(this.property === 'flags') {
                        k = 'stop_flags';
                    }
                    if(this.property === 'type') {
                        k = 'stop_types'
                    }
                }

                if(this.entityType === 'vehicles'){
                    if(this.property === 'flags') {
                        k = 'vehicle_flags';
                    }
                    if(this.property === 'fuel_type') {
                        k = 'vehicle_fuel_types';
                    }
                    if(this.property === 'safety_inspection_state') {
                        k = 'vehicle_safety_inspection_states';
                    }
                    if(this.property === 'state') {
                        k = 'vehicle_states'
                    }
                    if(this.property === 'type') {
                        k = 'vehicle_types'
                    }
                }

                return this.enums[k] || {
                    'active': 'Active',
                    'inactive': 'Inactive'
                };
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                let value;
                if (Array.isArray(this.original)) {
                    value = this.override.length ? this.override : this.original;
                } else {
                    value = this.override.length ? this.override : [this.original];
                }
                return value;
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);

                    if (this.array) {
                        return Array.isArray(value) ? value : [];
                    } else {
                        if (value && this.enum[value] !== undefined) {
                            return [value];
                        } else {
                            return [];
                        }
                    }
                },
                set(value){
                    if (value.length) {
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : this.array ? value : value.pop()
                        });
                    } else {
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : null
                        });
                    }
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                return Promise.resolve(Object.keys(this.enum).filter(item => item.toLowerCase().indexOf(value.toLowerCase()) > -1));
            },
            format(value){
                return this.enum[value];
            },
            edit(){
                if(this.editable){
                    const override_value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);

                    let has_override = false;
                    if (this.array) {
                        if (Array.isArray(override_value) && override_value.length) {
                            has_override = true;
                        }
                    } else {
                        if (override_value) {
                            has_override = true;
                        }
                    }

                    if(!has_override){
                        if(this.original){
                            // Set the default override value to the current property value.
                            this.$store.commit('entities/override', {property: this.property, value: this.original});
                        } else if (this.array) {
                            // Set the default override value to the current property value.
                            this.$store.commit('entities/override', {property: this.property, value: []});
                        }
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-boolean
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
