<template>
    <div class="integrate-source-provider-brightspace">
        <integrate-source-editor-step name="Enter Your Brightspace URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers and student go to sign into Brightspace.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.brightspace.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Create Brightspace Developer Keys" icon="iconoir-code" :complete="completed.includes('remote')">
            <div class="helptext">
                Click the link below create developer keys for Edlink.
            </div>
            <integrate-source-editor-configuration title="How to Configure Brightspace" icon="iconoir-info-empty">
                <label>Open Settings Page</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/d2l/lp/extensibility/oauth2'">
                        Open OAuth 2.0 Applications
                    </a>
                </div>
                <label>Application Name</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => 'Edlink'" v-clipboard:success="() => $toasted.info('Copied')">Edlink</div>
                </div>
                <label>Redirect URI</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => 'https://ed.link/api/authentication/brightspace'" v-clipboard:success="() => $toasted.info('Copied')">https://ed.link/api/authentication/brightspace</div>
                </div>
                <label>Scope</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift brightspace-scopes" v-clipboard="() => scope" v-clipboard:success="() => $toasted.info('Copied')">{{scope}}</div>
                </div>
                <label>Access Token Lifetime</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => '3600'" v-clipboard:success="() => $toasted.info('Copied')">3600</div>
                </div>
                <label>Prompt for User Consent</label>
                <div class="configuration-value">No</div>
                <label>Enable Refresh Tokens</label>
                <div class="configuration-value">Yes</div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Add the Client ID and Secret Key From Brightspace" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <div class="helptext">
                The Client ID and Secret Key are provided by Brightspace after you create the developer keys following the steps above.
            </div>
            <label class="key-label">Client ID</label>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.application_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_id', value: e.target.value})" />
                <!-- <div class="integrate-input-help text-button mini has-icon">
                    <span class="icon iconoir-info-empty block"></span>
                    <div>See a Picture</div>
                </div> -->
            </div>
            <label class="key-label">Secret Key</label>
            <div class="flex flex-align">
                <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.application_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_secret', value: e.target.value})" />
                <!-- <div class="integrate-input-help text-button mini has-icon">
                    <span class="icon iconoir-info-empty block"></span>
                    <div>See a Picture</div>
                </div> -->
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Connect Your Brightspace Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
            <div class="helptext">
                Sign into your Brightspace account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <token :write="destination" :configuration="source.configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderBrightspace',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url || !this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Validate that the token has been acquired.
                if(!this.remote){
                    return ['url'];
                }

                // 3. Are the keys entered?
                if(!this.source.configuration.application_id || !this.source.configuration.application_secret){
                    return ['url', 'remote'];
                }

                // 4. Remote configuration done?
                if(!this.source.token && !this.source.id){
                    return ['url', 'remote', 'keys'];
                }

                return ['url', 'remote', 'keys', 'token'];
            }
        },
        methods: {
            cleanURL(str) {
                return str.replace(/\/+$/, '');
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        data(){
            return {
                remote: false,
                scope: 'orgunits:course:read orgunits:coursetemplate:read organizations:organization:read content:completions:read,write content:modules:read content:toc:read content:topics:read,write core:*:* enrollment:orgunit:read grades:*:* role:detail:* users:userdata:read dropbox:access:read dropbox:folders:*'
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }

    .brightspace-scopes
    {
        margin-right: 80px;
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
