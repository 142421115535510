var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"options"},[_c('div',{staticClass:"options-title description"},[_vm._v("Generate Invoice")]),_c('div',{staticClass:"options-description"},[_vm._v("Enter the period you would like to generate an invoice for.")]),_c('div',{staticClass:"options-input"},[_c('div',{staticClass:"option-fields flex flex-row"},[_c('div',{staticClass:"option-field flex flex-column"},[_c('label',{staticClass:"ff",attrs:{"for":"period_start_date"}},[_vm._v("Period Month")]),_c('date-picker',_vm._b({ref:"input",model:{value:(_vm.period_month),callback:function ($$v) {_vm.period_month=$$v},expression:"period_month"}},'date-picker',{
                    type: 'month',
                    format: 'MM'
                },false))],1),_c('div',{staticClass:"option-field flex flex-column"},[_c('label',{staticClass:"ff",attrs:{"for":"period_start_date"}},[_vm._v("Period Year")]),_c('date-picker',_vm._b({ref:"input",model:{value:(_vm.period_year),callback:function ($$v) {_vm.period_year=$$v},expression:"period_year"}},'date-picker',{
                    type: 'year',
                    format: 'YYYY'
                },false))],1)])]),_c('div',{staticClass:"options-list flex"},[(_vm.loading)?[_c('div',{staticClass:"option ff loading"},[_c('spinner')],1)]:[_c('a',{staticClass:"option ff base",on:{"click":_vm.confirm}},[_vm._v("Generate")]),_c('a',{staticClass:"option ff grey",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }