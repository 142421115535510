<template>
    <main class="verification flex flex-align flex-center flex-column">
        <div class="icon"></div>
        <div class="card" v-if="status === 'attempting'">
            <div class="verification-check flex flex-align flex-center">
                <spinner />
                <div>
                    Verifying Email...
                </div>
            </div>
        </div>
        <div class="card" v-if="status === 'success'">
            <h3 class="center">Email Verified</h3>
            <div class="verification-message center">
                Your email address has been successfully verified. You may now close this window and return to the onboarding process.
            </div>
        </div>
        <div class="card" v-if="status === 'error'">
            <h3 class="center">Verification Failure</h3>
            <div class="verification-message center">
                We could not verify your email address with the code provided. Please <router-link :to="{name: 'support'}" class="dashed-button">contact us</router-link> for assistance setting up your account.
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: 'VerificationLanding',
        data(){
            return {
                status: 'attempting'
            };
        },
        created(){
            this.$http.post('/verify_email', {email_verification_code: this.$route.params.code})
            .then(() => this.status = 'success')
            .catch(error => {
                this.status = 'error';

                if(error.response.data){
                    this.$toasted.error(error.response.data.message);
                }
            });
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    

    .verification
    {
        padding: 30px;
        width: 100vw;
        height: 100vh;
    }

    .icon
    {
        height: 50px;
        width: 60px;
        display: block;
        background: url('~@/assets/rings.svg') center center no-repeat;
        background-size: 40px auto;
    }

    .card
    {
        width: 100%;
        max-width: 400px;
        padding: 30px;
        margin-top: 30px;

        button
        {
            width: 100%;
            margin-top: 20px;
        }
    }

    .verification-message
    {
        color: @grey;
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
    }

    .verification-check
    {
        margin: 40px auto;
        font-size: 14px;
        color: @black;
        font-weight: 600;

        .spinner
        {
            margin-right: 10px;
        }
    }
</style>