<template>
    <div class="command-palette-container">
        <div class="command-palette-backdrop flex flex-center" @click="$store.dispatch('palette/close')" v-if="visible">
            <div class="command-palette" @click.stop="() => {}">
                <div class="search-options flex flex-column">
                    <div class="search-tooltip flex flex-align" v-if="caller">
                        <LongArrowDownRight class="icon" width="12" height="12" stroke-width="1.5" />
                        <div class="ff">{{ tooltip }}</div>
                    </div>
                    <input type="text" class="command-palette-search block" :placeholder="placeholder" ref="search" @input="search" />
                </div>
                <div class="command-palette-results">
                    <template v-for="category of categories">
                        <div class="category" :key="category.type">
                            <div class="category-title">{{category.title}}</div>
                            <div class="result" :ref="result.ref" @click="select(index)" v-for="(result, index) of filtered(category)" :key="result.id" :class="{active: selected === result.index}">
                                <component :is="result.component" :data="result.data" :index="result.index" :selected="selected === result.index" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { LongArrowDownRight } from '@epiphany/iconoir';

    export default {
        name: 'CommandPalette',
        components: {
            LongArrowDownRight
        },
        data(){
            return {
                kd: null,
                selected: 0
            };
        },
        created(){
            this.kd = this.keydown.bind(this);
        },
        mounted(){
            window.addEventListener('keydown', this.kd);
        },
        destroyed(){
            window.removeEventListener('keydown', this.kd);
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            palette(){
                return this.$store.state.palette;
            },
            visible(){
                return this.palette.visible;
            },
            caller(){
                return this.palette.caller;
            },
            defaults(){
                return this.palette.defaults;
            },
            placeholder(){
                return this.caller?.placeholder ? this.caller.placeholder : this.defaults.placeholder;
            },
            results(){
                return this.palette.result.items;
            },
            categories(){
                return this.palette.result.categories;
            },
            tooltip(){
                return this.caller?.tooltip ? this.caller.tooltip : 'Currently performing an action';
            }
        },
        watch: {
            visible(){
                if(this.visible){
                    setTimeout(() => this.$refs.search.focus(), 10);
                }
            }
        },
        methods: {
            search(e){
                this.$store.dispatch('palette/search', e.target.value);
            },
            filtered(category){
                return this.results.filter(result => result.category === category.type);
            },
            keydown(e){
                if(e.keyCode === 75){
                    if(e.metaKey){
                        // We need to do this to prevent Chrome on Windows from hijacking the event.
                        e.stopPropagation();
                        e.preventDefault();
                        
                        // Dispatch an event to our store to override an existing caller.
                        this.$store.dispatch('palette/open');
                    }
                }else if(this.visible){
                    if(e.keyCode === 27){
                        this.$store.dispatch('palette/close');
                    }else if(e.keyCode === 38){
                        e.preventDefault();
                        this.up();
                    }else if(e.keyCode === 40){
                        e.preventDefault();
                        this.down();
                    }else if(e.keyCode === 13){
                        e.preventDefault();
                        this.select();
                    }
                }
            },
            select(index = this.selected){
                const result = this.results[index];

                if(result){
                    if(this.caller){
                        if(_.isFunction(this.caller.didSelectItem)){
                            this.caller.didSelectItem(result.data);
                        }
                    }else{
                        this.$router.push({
                            name: result.data.type === 'developer' ? 'team.dashboard' : 'team.sources',
                            params: {
                                team: result.data.alias
                            }
                        });
                    }
                }

                this.$store.dispatch('palette/close');
            },
            down(){
                if(this.results.length - 1 > this.selected){
                    this.selected += 1;
                }
            },
            up(){
                if(this.results.length && this.selected > 0){
                    this.selected -= 1;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    @command-palette-background-color: #132434;

    .command-palette-backdrop
    {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
        padding-top: 180px;
        align-items: flex-start;
    }

    .command-palette
    {
        background-color: @command-palette-background-color;
        border-radius: @border-radius;
        width: 500px;
        min-height: 280px;
        box-shadow: 0 4px 30px -5px fade(@command-palette-background-color, 70%);
        border: 1px solid #000;
        overflow: hidden;
    }

    .search-options
    {
        background: desaturate(lighten(@command-palette-background-color, 6%), 10%);
        align-items: flex-start;
    }

    .search-tooltip
    {
        background: @purple;
        color: @f;
        font-weight: 400;
        font-size: 10px;
        margin: 15px 20px 0;
        padding: 4px 4px;
        border-radius: 3px;
        font-weight: 500;

        .icon
        {
            margin-right: 2px;
        }
    }

    .command-palette-search
    {
        background: transparent;
        color: @f;
        font-weight: 500;
        height: 50px;
        line-height: 30px;
        padding: 10px 20px;
        border: 0;
        font-size: 16px;
        border-radius: 0;

        &::placeholder
        {
            color: mix(@f, @command-palette-background-color, 50%);
            opacity: 1;
        }

        &:-ms-input-placeholder
        {
            color: mix(@f, @command-palette-background-color, 50%);
        }

        &::-ms-input-placeholder
        {
            color: mix(@f, @command-palette-background-color, 50%);
        }

        &:focus
        {
            box-shadow: none;
        }
    }

    .command-palette-results
    {
        padding: 10px 0;
        overflow: auto;
        max-height: 400px;

        .category-title
        {
            padding: 8px 20px;
            color: @grey;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 600;
        }

        .result::v-deep
        {
            padding: 10px 16px;
            cursor: pointer;
            margin: 2px 4px;
            border-radius: 6px;

            .icon
            {
                color: @grey;
                font-size: 20px;
                height: 20px;
                width: 20px;
                margin-right: 15px;
            }

            .result-title
            {
                color: lighten(@grey, 25%);
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 2px;
                font-weight: 500;
            }

            .result-description
            {
                color: lighten(@grey, 10%);
                font-size: 11px;
                height: 12px;
                line-height: 12px;
            }

            .kbd
            {
                pointer-events: none;
                color: @f;
                font-size: 11px;
                font-weight: 500;
                line-height: 16px;
                height: 19px;
                border: 1px solid fade(@f, 50%);
                border-bottom-width: 2px;
                border-radius: 3px;
                padding: 0 4px;
            }

            &:hover, &:active
            {
                background: lighten(@command-palette-background-color, 5%);
            }

            &.active
            {
                background: @base;

                .icon
                {
                    color: @f;
                }

                .result-title
                {
                    color: @f;
                }

                .result-description
                {
                    color: fade(@f, 70%);
                }
            }
        }
    }
</style>