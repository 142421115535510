<template>
    <div class="entity-field summary-field flex" :class="{editing}">
        <div class="summary-key">{{title}}</div>
        <div class="summary-value ff" :class="{override}">
            <component :is="component"
                @save="save"
                @cancel="cancel"
                @edit="edit"
                :copyable="copyable"
                :editing="editing"
                :entityType="entityType"
                :array="array"
                :property="property"
                :editable="editable"
                :monospace="monospace"
                :placeholder="placeholder" />
            <div class="options flex" v-if="editable">
                <div class="text-button mini grey flex flex-align" @click="save">
                    <div>Save</div>
                    <div class="kbd">Enter</div>
                </div>
                <div class="text-button mini grey flex flex-align" @click="cancel">
                    <div>Cancel</div>
                    <div class="kbd">Esc</div>
                </div>
                <div class="ff"></div>
                <div class="text-button mini grey" @click="clear">Reset</div>
            </div>
        </div>
    </div>
</template>

<script>
    import EntityValueString from '@/components/drawers/entity/values/EntityValueString.vue';
    import EntityValueRole from '@/components/drawers/entity/values/EntityValueRole.vue';
    import EntityValueRace from '@/components/drawers/entity/values/EntityValueRace.vue';
    import EntityValueGradeLevel from '@/components/drawers/entity/values/EntityValueGradeLevel.vue';
    import EntityValueGender from '@/components/drawers/entity/values/EntityValueGender.vue';
    import EntityValueBoolean from '@/components/drawers/entity/values/EntityValueBoolean.vue';
    import EntityValueResidenceStatus from '@/components/drawers/entity/values/EntityValueResidenceStatus.vue';
    import EntityValueFoodServiceProgram from '@/components/drawers/entity/values/EntityValueFoodServiceProgram.vue';
    import EntityValueDisability from '@/components/drawers/entity/values/EntityValueDisability.vue';
    import EntityValueSchool from '@/components/drawers/entity/values/EntityValueSchool.vue';
    import EntityValuePerson from '@/components/drawers/entity/values/EntityValuePerson.vue';
    import EntityValueCourse from '@/components/drawers/entity/values/EntityValueCourse.vue';
    import EntityValueSession from '@/components/drawers/entity/values/EntityValueSession.vue';
    import EntityValueSection from '@/components/drawers/entity/values/EntityValueSection.vue';
    import EntityValueClass from '@/components/drawers/entity/values/EntityValueClass.vue';
    import EntityValueDate from '@/components/drawers/entity/values/EntityValueDate.vue';
    import EntityValueTime from '@/components/drawers/entity/values/EntityValueTime.vue';
    import EntityValueTimestamp from '@/components/drawers/entity/values/EntityValueTimestamp.vue';
    import EntityValueEnum from '@/components/drawers/entity/values/EntityValueEnum.vue';
    import EntityValueAgentFlags from '@/components/drawers/entity/values/EntityValueAgentFlags.vue';
    import EntityValueLanguage from '@/components/drawers/entity/values/EntityValueLanguage.vue';
    import EntityValueMilitaryConnectedStatus from '@/components/drawers/entity/values/EntityValueMilitaryConnectedStatus.vue';
    import EntityValuePeriod from '@/components/drawers/entity/values/EntityValuePeriod.vue';
    import EntityValueSubject from '@/components/drawers/entity/values/EntityValueSubject.vue';
    import EntityValueSubjects from '@/components/drawers/entity/values/EntityValueSubjects.vue';

    export default {
        name: 'EntityField',
        props: {
            title: String,
            property: String,
            placeholder: String,
            type: String,
            array: Boolean,
            copyable: Boolean,
            editable: Boolean,
            entityType: String,
            monospace: Boolean
        },
        computed: {
            component(){
                return this.components.hasOwnProperty(this.type) ? this.components[this.type] : EntityValueString;
            },
            override(){
                if(this.editable){
                    return _.has(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                }else{
                    return false;
                }
            }
        },
        data(){
            return {
                editing: false,
                components: {
                    agent_flags: EntityValueAgentFlags,
                    string: EntityValueString,
                    boolean: EntityValueBoolean,
                    date: EntityValueDate,
                    time: EntityValueTime,
                    timestamp: EntityValueTimestamp,
                    role: EntityValueRole,
                    number: EntityValueString,
                    grade_level: EntityValueGradeLevel,
                    gender: EntityValueGender,
                    race: EntityValueRace,
                    class: EntityValueClass,
                    course: EntityValueCourse,
                    section: EntityValueSection,
                    school: EntityValueSchool,
                    session: EntityValueSession,
                    person: EntityValuePerson,
                    address: EntityValueString,
                    residence_status: EntityValueResidenceStatus,
                    food_service_program: EntityValueFoodServiceProgram,
                    disability: EntityValueDisability,
                    enum: EntityValueEnum,
                    primary_language: EntityValueLanguage,
                    military_connected_status: EntityValueMilitaryConnectedStatus,
                    period: EntityValuePeriod,
                    subject: EntityValueSubject,
                    subjects: EntityValueSubjects // Deprecated subjects field.
                }
            };
        },
        methods: {
            edit(){
                if(this.editable){
                    this.editing = true;
                }
            },
            save(){
                this.editing = false;

                // Emit our new value.
                this.$emit('override', this.edited);

                // Save the override.
                this.$store.dispatch('entities/update');
            },
            cancel(){
                this.editing = false;

                // Set the override property back to its original value, or clear it if there was no original value.
                this.$store.commit('entities/reset', this.property);
            },
            clear(){
                this.editing = false;

                // Clear the corresponding override property entirely.
                this.$store.commit('entities/clear', this.property);

                // Save the override.
                this.$store.dispatch('entities/update');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-field.summary-field
    {
        margin-bottom: 10px;
        font-size: 14px;
        padding: 0 50px 0 0;
        transition: all ease 0.2s;

        &:last-child
        {
            margin-bottom: 0;
        }

        &.editing
        {
            background: @f8;
            padding: 15px 25px 43px;
            margin: 10px 0;

            &:last-child
            {
                margin-bottom: 0;
            }

            .options
            {
                opacity: 1;
                pointer-events: all;
            }
        }

        .summary-key
        {
            color: @grey;
            width: 240px;
            flex-basis: 240px;
            flex-shrink: 0;
            height: 22px;
            line-height: 22px;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            &::before
            {
                width: 16px;
                height: 16px;
                background: url('~@/assets/icons/base/pin.svg') 4px 3px no-repeat;
                background-size: 7px auto;
                position: absolute;
                top: 4px;
                left: -20px;
                bottom: auto;
                right: auto;
                content: "";
                opacity: 0;
                transition: opacity ease 0.2s;
            }

            &.monospace
            {
                line-height: 22px;
                font-size: 14px;
            }

            &.none
            {
                color: @grey;
            }

            &.override
            {
                color: @base;

                &::before
                {
                    opacity: 1;
                }
            }
        }

        .editable
        {
            cursor: pointer;

            &::after
            {
                position: absolute;
                font-size: 11px;
                content: "Edit";
                font-family: @font;
                color: @grey;
                opacity: 0;
                transition: opacity ease 0.3s;
                width: 70px;
                top: 0;
                bottom: 0;
                right: -80px;
                left: auto;
                height: 22px;
                line-height: 22px;
            }

            &:hover, &:active
            {
                &::after
                {
                    opacity: 1;
                }
            }

            &:active
            {
                opacity: 0.6;
            }

            &.flex-wrap
            {
                &::after
                {
                    top: 2px;
                }
            }
        }

        .options
        {
            bottom: -28px;
            position: absolute;
            top: auto;
            opacity: 0;
            pointer-events: none;
            transition: opacity ease 0.2s;

            .text-button
            {
                margin-right: 15px;

                .kbd
                {
                    color: @base;
                    font-weight: 400;
                    margin-left: 4px;
                    border-radius: 2px;
                    background: fade(@base, 15%);
                    height: 16px;
                    line-height: 16px;
                    font-size: 10px;
                    padding: 0 3px;
                }

                &.grey
                {
                    .kbd
                    {
                        color: @grey;
                        background: fade(@grey, 15%);
                    }
                }

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }

        .placeholder
        {
            color: @grey;
        }

        .empty
        {
            color: @grey;
        }

        .autocomplete
        {
            margin: -2px;

            .autocomplete-search
            {
                border: 0;
                min-height: 22px;
                line-height: 22px;
                border-radius: 0;
                padding: 0;

                &:focus-within, &:active
                {
                    box-shadow: none;
                }

                .token
                {
                    padding: 2px 1px 2px 8px;
                    height: 22px;
                    line-height: 18px;
                    border-radius: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    white-space: nowrap;
                    background: rgba(0, 106, 255, 0.2);
                    color: #006aff;
                    margin: 2px;
                }

                .token-remove
                {
                    width: 22px;
                    height: 22px;
                    background: url('~@/assets/icons/base/x-round.svg') 4px 5px no-repeat;
                    background-size: 15px auto;
                    opacity: 0.6;

                    &:hover, &:active
                    {
                        opacity: 1;
                    }
                }

                .token.selected
                {
                    background: @base;
                    color: @f;
                }

                input.autocomplete-input
                {
                    margin: 2px;
                    height: 22px;
                    line-height: 22px;
                    padding: 0 3px;
                    font-size: 14px;
                    color: @base;
                    min-width: 80px;

                    &:focus
                    {
                        box-shadow: none;
                    }

                    &:disabled
                    {
                        background: transparent;
                    }
                }
            }
        }
    }
</style>
