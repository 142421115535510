<template>
    <div class="create-onboarding flex flex-column">
        <header class="drawer-header">
            <div class="flex flex-align">
                <div class="ff drawer-title flex flex-align">
                    <GridAdd class="icon" width="20" height="20" stroke-width="2" />
                    Create Onboarding Invitation
                </div>
                <div class="button has-icon white flex flex-align" @click="() => $store.dispatch('drawer/close')">
                    Close Window
                </div>
            </div>
        </header>
        <div class="ff drawer-scroll drawer-content">
            <div class="onboarding-step">
                <h3 class="flex flex-align">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Select an Application
                </h3>
                <placard v-if="application" :selectable="true" @select="() => select_application()">
                    <template v-slot:icon>
                        <img v-if="application.icon_url" :src="application.icon_url" />
                        <Svg3dSelectSolid v-else class="icon" width="16" height="16" stroke-width="2" />
                    </template>
                    <template v-slot:title>{{ application.name ? application.name : 'Untitled Application' }}</template>
                    <template v-slot:actions>
                        <a class="text-button mini none">Change</a>
                    </template>
                </placard>
                <template v-else>
                    <placard v-for="choice of applications" :key="choice.id" class="application" :selectable="true" @select="() => select_application(choice)">
                        <template v-slot:icon>
                            <img v-if="choice.icon_url" :src="choice.icon_url" />
                            <Svg3dSelectSolid v-else class="icon" width="16" height="16" stroke-width="2" />
                        </template>
                        <template v-slot:title>{{ choice.name ? choice.name : 'Untitled Application' }}</template>
                        <template v-slot:description>{{ choice.description ? choice.description : 'No description' }}</template>
                    </placard>
                </template>
            </div>
            <template v-if="application">
                <div class="onboarding-step">
                    <div class="district-search-header flex flex-align">
                        <h3 class="ff flex flex-align">
                            <City class="icon" width="16" height="16" stroke-width="2" />
                            Select a District or University
                        </h3>
                        <div class="text-button mini tooltip tooltip-top" @click="skip" tooltip="Click to continue without selecting an organization">Can't find something?</div>
                    </div>
                    <placard v-if="district" :selectable="true" class="district-result" @select="() => select_district()">
                        <template v-slot:icon>
                            <img v-if="district.icon_url" :src="district.icon_url" />
                            <City v-else class="icon" width="16" height="16" stroke-width="2" />
                        </template>
                        <template v-slot:title>{{ district.name ? district.name : 'Untitled District' }}</template>
                        <template v-slot:actions>
                            <a class="text-button mini none">Change</a>
                        </template>
                    </placard>
                    <template v-else>
                        <div class="district-search card flex flex-align">
                            <Search class="icon" width="20" height="20" stroke-width="2" />
                            <input class="district-search-input ff" v-model="query" @input="search" placeholder="Search for a district or university" />
                            <div class="district-search-loading">
                                <spinner v-if="loading.districts" />
                            </div>
                        </div>
                        <div class="district-results">
                            <placard v-for="result of districts" :key="result.id" class="district-result" :class="result.status" :selectable="true" @select="() => select_district(result)">
                                <template v-slot:icon>
                                    <img v-if="result.icon_url" :src="result.icon_url" />
                                    <City v-else class="icon" width="16" height="16" stroke-width="2" />
                                </template>
                                <template v-slot:title>
                                    <div class="flex flex-align">
                                        <div class="ff text-overflow">{{ result.name ? result.name : 'Untitled District' }}</div>
                                        <div class="district-capabilities flex flex-align">
                                            <div class="district-capability flex flex-align" :class="{enabled: lms(result)}">
                                                <Check v-if="lms(result)" class="icon" width="16" height="16" stroke-width="2" />
                                                <Cancel v-else class="icon" width="16" height="16" stroke-width="2" />
                                                LMS
                                            </div>
                                            <div class="district-capability flex flex-align" :class="{enabled: sis(result)}">
                                                <Check v-if="sis(result)" class="icon" width="16" height="16" stroke-width="2" />
                                                <Cancel v-else class="icon" width="16" height="16" stroke-width="2" />
                                                SIS
                                            </div>
                                            <div class="district-capability flex flex-align" :class="{enabled: sso(result)}">
                                                <Check v-if="sso(result)" class="icon" width="16" height="16" stroke-width="2" />
                                                <Cancel v-else class="icon" width="16" height="16" stroke-width="2" />
                                                SSO
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:description>{{ description(result) }}</template>
                            </placard>
                        </div>
                    </template>
                </div>
                <template v-if="district">
                    <!-- <div class="onboarding-step">
                        <h3 class="flex flex-align">Select Data Provider</h3>
                    </div>
                    <div class="onboarding-step">
                        <h3 class="flex flex-align">Select Products</h3>
                    </div> -->
                    <div class="onboarding-step">
                        <h3 class="flex flex-align">
                            <Timer class="icon" width="16" height="16" stroke-width="2" />
                            Set an Expiration Date
                        </h3>
                        <placard :selectable="true" class="expiration" v-if="expiration" @select="() => select_expiration()">
                            <template v-slot:icon>
                                <Clock class="icon" width="16" height="16" stroke-width="2" />
                            </template>
                            <template v-slot:title>{{ expiration.label }}</template>
                            <template v-slot:actions>
                                <a class="text-button mini none">Change</a>
                            </template>
                        </placard>
                        <template v-else>
                            <placard v-for="choice of expirations" :key="choice.value" class="expiration" :selectable="true" @select="() => select_expiration(choice)">
                                <template v-slot:icon>
                                    <Clock class="icon" width="16" height="16" stroke-width="2" />
                                </template>
                                <template v-slot:title>{{ choice.label }}</template>
                                <template v-slot:description v-if="choice.timestamp">Link expires {{ choice.timestamp | pretty('MMMM Do [at] h:mma') }}</template>
                                <template v-slot:description v-else>Link does not expire</template>
                            </placard>
                        </template>
                    </div>
                <template v-if="expiration">
                    <div class="onboarding-step create-invitation-link">
                        <h3 class="flex flex-align">
                            <SendMail class="icon" width="16" height="16" stroke-width="2" />
                            Create your Invitation Link
                        </h3>
                        <h4>
                            Click the button below to generate an invitation link that you can send to the district or university.
                            After generating the link, you will have the chance to copy it to your clipboard or notify the district or university admin directly via email.
                        </h4>
                        <div class="create-onboarding-options flex">
                            <div class="button has-icon" @click="create">
                                <GridAdd class="icon" width="16" height="16" stroke-width="2" />
                                Generate Invitation
                            </div>
                        </div>
                    </div>
                </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import { GridAdd, Svg3dSelectSolid, Search, City, Check, Cancel, Clock, Timer, SendMail } from '@epiphany/iconoir';
    import moment from 'moment';
    import _ from 'lodash';

    export default {
        name: 'CreateOnboarding',
        components: {
            GridAdd,
            Svg3dSelectSolid,
            Search,
            City,
            Check,
            Cancel,
            Clock,
            Timer,
            SendMail
        },
        props: {
            created: Function
        },
        data() {
            return {
                applications: [],
                application: null,
                districts: [],
                district: null,
                expirations: [
                    { label: 'Does Not Expire', value: 'never', timestamp: null },
                    { label: 'Expires In a Day', value: 'day', timestamp: null },
                    { label: 'Expires In a Week', value: 'week', timestamp: null },
                    { label: 'Expires In a Month', value: 'month', timestamp: null }
                ],
                expiration: null,
                loading: {
                    districts: false,
                    applications: false
                },
                throttle: {
                    districts: null
                },
                query: '',
                entities: []
            }
        },
        created() {
            this.search();

            for(const expiration of this.expirations){
                if(expiration.value === 'day'){
                    expiration.timestamp = moment().add(1, 'days').startOf('day');
                }

                if(expiration.value === 'week'){
                    expiration.timestamp = moment().add(1, 'weeks').startOf('day');
                }

                if(expiration.value === 'month'){
                    expiration.timestamp = moment().add(1, 'months').startOf('day');
                }
            }

            this.$http.get(`/teams/${this.team.id}/applications`)
            .then(response => {
                this.applications = response.$data;

                if(this.applications.length === 1){
                    this.application = this.applications[0];
                }
            })
            .catch(error => this.$toasted.error(`There was an error loading your team's applications.`));
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            admin(){
                return this.$store.getters.user.admin;
            }
        },
        methods: {
            skip(){
                this.district = {
                    id: null,
                    name: 'No District or University Selected'
                };
            },
            description(result){
                let descriptor = 'institution';
                let location = '';

                if(result.city){
                    location += ` in ${result.city}`;

                    if(result.state){
                        location += `, ${result.state}`;
                    }

                    if(result.country && result.country !== 'United States'){
                        location += `, ${result.country}`;
                    }
                }else if(result.state){
                    location += ` in ${result.state}`;

                    if(result.country && result.country !== 'United States'){
                        location += `, ${result.country}`;
                    }
                }else if(result.country && result.country !== 'United States'){
                    location += ` in ${result.country}`;
                }

                if(result.descriptor){
                    descriptor = result.descriptor;
                }

                // Uppercase the first character of the descriptor.
                descriptor = descriptor.charAt(0).toUpperCase() + descriptor.slice(1);

                return `${descriptor}${location}`;
            },
            lms(result){
                return result.provider_ids.some(id => {
                    return this.$store.state.providers.all[id]?.categories.includes('lms');
                });
            },
            sis(result){
                return result.provider_ids.some(id => {
                    return this.$store.state.providers.all[id]?.categories.includes('sis');
                });
            },
            sso(result){
                return result.provider_ids.some(id => {
                    return this.$store.state.providers.all[id]?.categories.includes('sso');
                });
            },
            select_application(application = null){
                this.application = application;
            },
            select_district(district = null){
                this.district = district;
            },
            select_expiration(expiration = null){
                this.expiration = expiration;
            },
            close() {
                this.$store.dispatch('drawer/close');
            },
            search(){
                this.loading.districts = true;

                if(this.throttle.districts){
                    clearTimeout(this.throttle.districts);
                }

                this.throttle.districts = setTimeout(() => {
                    const params = {};

                    if(this.query){
                        params.search = this.query;
                    }

                    this.throttle.districts = null;

                    this.$http.get(`/teams/${this.team.id}/institutions`, {params})
                    .then(response => {
                        this.districts = response.$data;
                    })
                    .catch(error => this.$toasted.error('There was an error loading the list of institutions.'))
                    .finally(() => this.loading.districts = false);
                }, 1000);
            },
            async create(){
                try {
                    const onboarding = await this.$http.post(`/teams/${this.team.id}/onboardings`, {
                        application_id: this.application.id,
                        team_id: this.district.id,
                        redirect_uri: this.application.setup_redirect_uri,
                        expiration_date: this.expiration.timestamp?.toISOString()
                    }).then(response => response.$data);

                    if(_.isFunction(this.created)){
                        this.created(onboarding);
                    }
                    
                    this.$store.dispatch('drawer/replace', {
                        key: `onboarding/${onboarding.id}`,
                        width: 800,
                        component: 'onboarding',
                        props: {
                            onboarding
                        }
                    });
                }catch(error){
                    this.$toasted.error('There was an error creating the onboarding invitation.');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-onboarding
    {
        height: 100%;
    }
    
    .onboarding-step
    {
        padding: @double-padding;

        h3
        {
            font-size: 14px;
            color: @grey;
            letter-spacing: -0.01rem;

            .icon
            {
                margin-right: 8px;
            }
        }

        .placard::v-deep
        {
            margin-top: @single-padding;
            padding: @single-padding @single-padding + 2;
            width: 400px;

            .placard-icon
            {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                margin-right: @single-padding + 2;
            }

            h3
            {
                font-size: 15px;
                letter-spacing: -0.01rem;
                line-height: 18px;
                margin-top: 2px;
            }

            .actions
            {
                margin-top: 2px;

                .text-button.mini
                {
                    line-height: 18px;
                    height: 18px;
                    font-weight: 400;
                }
            }

            &.inactive
            {
                .placard-icon
                {
                    background: @grey;
                }
            }
        }
    }

    .district-search-header
    {
        width: 550px;
    }
    
    .district-search
    {
        box-shadow: 0 1px 6px -2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.08);
        width: 550px;
        padding: @single-padding @single-padding + 5;
        border-bottom-color: @d;
        margin-top: @single-padding;
        margin-bottom: @double-padding;
        transition: all ease 0.2s;

        .district-search-input
        {
            border: 0;
            border-radius: 0;
            padding: 0;
            height: 28px;
            margin-left: @single-padding + 2px;
            box-shadow: none;
            font-size: 16px;

            &:focus
            {
                border: 0;
                outline: 0;
                box-shadow: none;
            }
        }

        &:focus-within
        {
            border-color: @base;
            box-shadow: 0 1px 12px -2px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.08);
        }

        .district-search-loading
        {
            width: 20px;
            height: 20px;
        }
    }

    .district-result.placard::v-deep
    {
        width: 550px;

        h4
        {
            margin-top: 4px;
            font-size: 13px;
        }
    }

    .district-capabilities
    {
        color: @lightgrey;
        font-size: 12px;
        line-height: 16px;
        height: 16px;

        .district-capability
        {
            margin-left: 15px;

            .icon
            {
                margin-right: 2px;
            }

            &.enabled
            {
                color: @grey;
            }
        }
    }

    .placard.expiration::v-deep, .placard.application::v-deep
    {
        .placard-icon
        {
            background: linear-gradient(0deg, @purple, lighten(@purple, 10%));
        }

        h4
        {
            margin-top: 4px;
            font-size: 13px;
        }
    }

    .verified
    {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: url('~@/assets/icons/white/check.svg') 1px 2px no-repeat @base;
        background-size: 10px auto;
        margin-right: 8px;
    }

    .create-invitation-link
    {
        h4
        {
            font-size: 13px;
            line-height: 18px;
            color: @black;
            max-width: 600px;
            font-weight: 400;
        }
    }

    .create-onboarding-options
    {
        margin-top: 15px;
    }
</style>
