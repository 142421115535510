<template>
    <div class="drawer-container" :class="{active}">
        <div class="drawer" :style="{width: width === 'full' ? '100%' : width + 'px'}">
            <transition name="component-fade" mode="out-in">
                <div v-if="loading" class="flex flex-align flex-center ff loading-state">
                    <spinner />
                </div>
                <component v-else-if="component" :is="component" :key="key" v-bind="props"/>
            </transition>
        </div>
        <div class="drawer-backdrop" @click="close"></div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'Drawer',
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            }
        },
        computed: {
            active(){
                return this.$store.state.drawer.active;
            },
            loading(){
                return this.$store.state.drawer.loading;
            },
            key(){
                return this.$store.state.drawer.key;
            },
            props() {
                return this.$store.state.drawer.props;
            },
            width(){
                return this.$store.state.drawer.width;
            },
            component(){
                if(this.$store.state.drawer.component){
                    // I know this is annoying, but it's because of Vuex.
                    // Apparently when the component is used directly from the store, Vue decides to mutate it slightly, which causes Vuex to freak out.
                    // The solution seems to be to clone the object before returning it.
                    return _.cloneDeep(this.$store.state.drawer.component);
                }
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .loading-state
    {
        height: 100%;
    }
    
    .drawer-container
    {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;

        &.active
        {
            .drawer-backdrop
            {
                opacity: 1;
                pointer-events: all;
            }

            .drawer
            {
                transition: all ease 0.35s;
                transform: translate3d(0, 0, 0);
                pointer-events: all;
            }
        }

        .drawer-backdrop
        {
            position: absolute;
            z-index: 1001;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0;
            pointer-events: none;
            transition: all 0.35s ease;
            color: @f;
            font-size: 40px;
        }

        .drawer
        {
            position: absolute;
            z-index: 1002;
            top: 0;
            right: 0;
            bottom: 0;
            background: @f;
            transform: translate3d(110%, 0, 0);
            transition: transform 0.35s ease;
            width: 600px;
            // Performance issues with this box shadow.
            // box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
            box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.1);
        }

        .drawer-header
        {
            padding: 10px;
            height: @drawer-header-height;
            border-bottom: 1px solid @e;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
            background: @f;
        }

        .drawer-navigation
        {
            padding: @single-padding;
            height: @drawer-navigation-height;
            border-bottom: 1px solid @e;
            position: absolute;
            top: @drawer-header-height - @drawer-navigation-offset;
            left: 0;
            right: 0;
            z-index: 10;
            background: @f;

            .destination
            {
                height: @drawer-navigation-height;
                line-height: @drawer-navigation-height;
            }
        }

        .drawer-content
        {
            position: absolute;
            top: @drawer-header-height;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .drawer-navigation + .drawer-content
        {
            top: @drawer-navigation-height + @drawer-header-height - @drawer-navigation-offset;
        }

        .drawer-scroll
        {
            overflow-y: auto;
            overflow-x: hidden;
        }

        .drawer-title
        {
            font-size: 16px;
            font-weight: 500;
            color: @black;
            height: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            margin-left: 10px;

            .icon
            {
                margin-right: @single-padding;
                margin-left: -2px;
            }
        }

        .drawer-section
        {
            border-bottom: 1px solid @e;
            padding: @section-padding 0;

            &:first-child
            {
                padding-top: 0;
            }

            &:last-child
            {
                border-bottom: 0;
                padding-bottom: 0;
            }

            h3
            {
                font-size: 14px;
                line-height: 18px;

                .icon
                {
                    margin-right: 6px;
                }
            }
        }

        .drawer-divider
        {
            height: 1px;
            margin: 0 25px;
            background: @e4;
        }

        .drawer-loading
        {
            padding: 100px 0;
        }

        .drawer-options
        {
            margin-top: @section-padding;
            
            .button
            {
                margin: 0 10px 0 0;
            }
        }

        .component-fade-enter-active, .component-fade-leave-active
        {
            transition: opacity .3s ease;
        }

        .component-fade-enter, .component-fade-leave-to
        {
            opacity: 0;
        }
    }
</style>
