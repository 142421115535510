<template>
    <div class="ff drawer-content drawer-scroll invoice-details">
        <div class="invoice-wrapper" v-if="invoice_copy">

            <div class="flex flex-row flex-align header">
                <h3>Details</h3>
                <div class="ff"></div>
            </div>

            <div class="summary">
                <div class="summary-field flex" v-for="data in metadata" :class="{editing: false}">
                    <div class="summary-key">{{ data.property }}</div>
                    <div class="summary-value" :class="{override: false}">
                        <component v-if="data.component" :is="data.component" v-bind="data.value" />
                        <template v-else>
                            {{ data.value }}
                        </template>
                        <!-- <div class="options flex" v-if="editable">
                            <div class="text-button mini grey flex flex-align" @click="save">
                                <div>Save</div>
                                <div class="kbd">Enter</div>
                            </div>
                            <div class="text-button mini grey flex flex-align" @click="cancel">
                                <div>Cancel</div>
                                <div class="kbd">Esc</div>
                            </div>
                            <div class="ff"></div>
                            <div class="text-button mini grey" @click="clear">Reset</div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="flex flex-row flex-align header">
                <h3>Line Items</h3>
                <div class="ff"></div>
                <div class="text-button red" v-if="isOverriden('line_items')" @click="resetLineItems">Reset</div>
                <div class="text-button" v-if="editing" @click="saveInvoice">Save</div>
                <div class="text-button" v-else-if="canEdit" @click="editing = true">Edit</div>
            </div>

            <div class="invoice-line-items flex flex-row flex-align">
                <datatable class="card" :columns="line_item_columns" :rows="line_items" :total="total_row" :header="true" :clickable="false" :editable="true" />
            </div>

            <div class="flex flex-row flex-align actions">
                <div class="ff"></div>
                <div class="text-button" v-if="editing" @click="addLineItem">Add Line Item</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import * as d3 from 'd3';
    import Decimal from 'decimal.js';
    import { BILLING_APPROVED_USERS } from '@/constants'

    import _ from 'lodash';
    
    import InvoiceState from '@/components/chips/InvoiceState.vue'
    import InvoiceXeroState from '@/components/chips/InvoiceXeroState.vue'
    import InvoiceTextInput from '@/components/table/InvoiceTextInput.vue';

    export default {
        name: 'InvoiceDetails',
        props: {
            invoice: Object
        },
        data(){
            return {
                drawer_loading: true,
                loading: true,
                invoice_copy: null,
                editing: false,
                states: [
                    {
                        id: 'voided',
                        name: 'Voided',
                        icon: 'grey'
                    },
                    {
                        id: 'draft',
                        name: 'Draft',
                        icon: 'yellow'
                    },
                    {
                        id: 'submitted',
                        name: 'Submitted',
                        icon: 'blue'
                    },
                    {
                        id: 'sent',
                        name: 'Sent',
                        icon: 'blue'
                    },
                    {
                        id: 'paid',
                        name: 'Paid',
                        icon: 'green'
                    },
                    {
                        id: 'overdue',
                        name: 'Overdue',
                        icon: 'red'
                    },
                    {
                        id: 'refunded',
                        name: 'Refunded',
                        icon: 'purple'
                    }
                ],
                line_item_columns: [
                    {
                        id: 'description',
                        name: 'Item',
                        width: '50%',
                        classes: 'raw',
                        focusable: () => this.editing,
                        component: InvoiceTextInput,
                        on: {
                            input: (e) => {
                                this.setDescription(e.$index, e.event);
                            }
                        },
                        value: row => ({
                            value: this.displayDescription(row.$index),
                            readonly: !this.editing,
                            overriden: this.isOverriden(`line_items[${row.$index}].description`),
                            $index: row.$index,
                            row
                        })
                    },
                    {
                        id: 'quantity',
                        name: 'Quantity',
                        width: '15%',
                        classes: 'raw',
                        focusable: () => this.editing,
                        component: InvoiceTextInput,
                        on: {
                            input: (e) => {
                                this.setQuantity(e.$index, e.event);
                            }
                        },
                        value: row => ({
                            value: this.displayQuantity(row.$index),
                            readonly: !this.editing,
                            overriden: this.isOverriden(`line_items[${row.$index}].quantity`),
                            $index: row.$index,
                            row
                        })
                    },
                    {
                        id: 'unit_cost',
                        name: 'Price',
                        width: '10%',
                        classes: 'raw',
                        focusable: () => this.editing,
                        component: InvoiceTextInput,
                        on: {
                            input: (e) => {
                                this.setUnitCost(e.$index, e.event);
                            }
                        },
                        value: row => ({
                            value: this.displayUnitCost(row.$index),
                            readonly: !this.editing,
                            overriden: this.isOverriden(`line_items[${row.$index}].unit_cost`),
                            money: true,
                            $index: row.$index,
                            row
                        })
                    },
                    {
                        id: 'discount',
                        name: 'Discount',
                        width: '10%',
                        classes: 'raw',
                        focusable: () => this.editing,
                        component: InvoiceTextInput,
                        on: {
                            input: (e) => {
                                this.setDiscount(e.$index, e.event);
                            }
                        },
                        value: row => ({
                            value: this.displayDiscount(row.$index),
                            readonly: !this.editing,
                            overriden: this.isOverriden(`line_items[${row.$index}].discount`),
                            $index: row.$index,
                            row
                        })
                    },
                    {
                        id: 'amount',
                        name: 'Amount',
                        width: '15%',
                        classes: 'raw',
                        component: InvoiceTextInput,
                        value: row => ({
                            value: this.displayAmount(row.$index),
                            readonly: true,
                            overriden: false,
                            money: true,
                            $index: row.$index,
                            row
                        })
                    }   
                ],
                total_row: [
                    null, null, null,
                    { value: 'Total', align: 'right', classes: 'bold' },
                    {
                        classes: 'raw bold',
                        component: InvoiceTextInput,
                        value: () => ({
                            value: this.getTotal(),
                            readonly: true,
                            overriden: false,
                            money: true,
                            $index: -1
                        })
                    }
                ]
            };
        },
        computed: {
            metadata() {
                return [
                    { property: 'Number', value: this.invoice_copy.number },
                    { property: 'State', value: { state: this.invoice_copy.state }, component: InvoiceState },
                    { property: 'Issued Date', value: this.invoice_copy.issued_date ? d3.utcFormat('%m/%d/%Y')(new Date(this.invoice_copy.issued_date)) : 'N/A' },
                    { property: 'Due Date', value: this.invoice_copy.due_date ? d3.utcFormat('%m/%d/%Y')(new Date(this.invoice_copy.due_date)) : 'N/A' },
                    { property: 'To', value: this.invoice_copy.team.name },
                    { property: 'Period', value: this.billing_period },
                    { property: 'Xero', value: { state: this.invoice_copy.xero_id ? 'yes' : 'no' }, component: InvoiceXeroState }
                ]
            },
            title() {
                if (this.invoice_copy) {
                    return `Invoice #${this.invoice_copy.number}`;
                }

                return 'Invoice';
            },
            modified() {
                if (_.has(this.invoice_copy, 'overrides') && !_.isEmpty(this.invoice_copy.overrides)) {
                    return true;
                } else {
                    return false;
                }
            },
            billing_period() {
                return `${d3.utcFormat('%m/%d/%Y')(new Date(this.invoice_copy.period_start_date))} - ${d3.utcFormat('%m/%d/%Y')(new Date(this.invoice_copy.period_end_date))}`
            },
            valid_states() {
                if (!this.invoice_copy.xero_id) {
                    return this.states.filter(state => state.id !== this.invoice_copy.state);
                } else {
                    if (this.invoice_copy.state === 'voided') {
                        return [];
                    }

                    return this.states.filter(state => ['sent', 'submitted', 'paid', 'overdue', 'voided', 'refunded'].includes(state.id) && state.id !== this.invoice_copy.state);
                }
            },
            line_items() {
                const overrides = this.invoice_copy.overrides?.line_items;
                const line_items = this.invoice_copy.line_items;

                const new_line_items = _.cloneDeep(overrides ?? line_items);

                for (const [index, item] of new_line_items.entries()) {
                    item.$index = index;
                }

                return new_line_items;
            },
            canEdit() {
                if (!BILLING_APPROVED_USERS.includes(this.$store.getters.user.id)) {
                    return false
                }

                if (!['draft', 'submitted', 'sent'].includes(this.invoice_copy.state)) {
                    return false;
                }

                return true;
            },
            admin() {
                return this.$store.getters.user.admin;
            },
            props() {
                return this.$store.state.drawer.props;
            }
        },
        mounted() {
            window.addEventListener('mousedown', this.click_outside);
            window.addEventListener('keydown', this.escape);
        },
        destroyed() {
            window.removeEventListener('mousedown', this.click_outside);
            window.removeEventListener('keydown', this.escape);
        },
        methods: {
            saveInvoice() {
                this.$store.dispatch('save/save', 'invoice');
                this.editing = false;

                let updates = _.cloneDeep(_.omit(this.invoice_copy, ['snapshot_ids']));

                if (updates?.overrides?.line_items) {
                    for (const item of this.invoice_copy.overrides.line_items) {
                        item.quantity = new Decimal(item.quantity.toString().replace(/,/g, '')).toDecimalPlaces(4).toNumber();
                        item.unit_cost = new Decimal(item.unit_cost.toString().replace(/,/g, '')).toDecimalPlaces(4).toNumber();
                    }
                }

                if (!BILLING_APPROVED_USERS.includes(this.$store.getters.user.id)) {
                    if (updates.state === 'draft') {
                        updates = _.pick(updates, ['state', 'overrides', 'properties']);
                    } else {
                        updates = _.pick(updates, ['state', 'properties']);
                    }
                }

                this.$http.patch(`/admin/billing/invoices/${this.invoice_copy.id}`, updates)
                    .then(response => {
                        response.$data.team = this.invoice_copy.team;
                        Vue.set(this, 'invoice', response.$data);
                        this.$store.dispatch('save/saved', 'invoice');
                    })
                    .catch(error => {
                        if (_.isString(error.$error)) {
                            this.$toasted.error(error.$error);
                        } else {
                            this.$toasted.error('There was an error saving this invoice.')
                        }
                        this.$store.dispatch('save/error', 'invoice');
                    });
            },
            // =========================================================
            focusInput(e) {
                // Move cursor to the end of the input
                setTimeout(() => {
                    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
                }, 10);
            },
            countCommas(value) {
                return Math.floor((value.toString().length - 1) / 3);
            },
            // =========================================================
            initOverrides() {
                if (!this.invoice_copy.overrides) {
                    Vue.set(this.invoice_copy, 'overrides', {});
                }

                if (!this.invoice_copy.overrides.line_items) {
                    Vue.set(this.invoice_copy.overrides, 'line_items', _.cloneDeep(this.invoice_copy.line_items));
                }
            },
            resetLineItems() {
                Vue.set(this.invoice_copy, 'overrides', _.omit(this.invoice_copy.overrides, 'line_items'));
                this.saveInvoice();
            },
            addLineItem() {
                this.initOverrides();

                this.invoice_copy.overrides.line_items.push({
                    code: 'custom',
                    description: `Custom Line Item ${this.invoice_copy.overrides.line_items.length + 1}`,
                    quantity: '0',
                    unit_cost: '0'
                });
            },
            // =========================================================
            displayDescription(index) {
                const override = this.invoice_copy.overrides?.line_items?.[index]?.description;
                const raw = override ? override : this.line_items[index].description;
                return raw;
            },
            setDescription(index, e) {
                const description = e.target.value;

                this.initOverrides();

                _.set(this.invoice_copy.overrides.line_items, `[${[index]}].description`, description);
            },
            // =========================================================
            getQuantity(index) {
                const override = this.invoice_copy.overrides?.line_items?.[index]?.quantity.toString();
                const raw = _.isNil(override) ? this.invoice_copy.line_items[index].quantity : override.trim() === '' ? 0 : override.replace(/,/g, '');
                return new Decimal(raw);
            },
            displayQuantity(index) {
                const override = this.invoice_copy.overrides?.line_items?.[index]?.quantity.toString();
                const raw = _.isNil(override) ? this.invoice_copy.line_items[index].quantity.toString() : override;

                const [int, dec] = raw.split('.');

                return int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (dec ? `.${dec}` : '');
            },
            setQuantity(index, e) {
                // Get raw value and save cursor position
                const value = e.target.value;
                const parsed = value.replace(/,/g, '');
                const quantity = Number(parsed);

                // Get current value
                const current = this.getQuantity(index).toDecimalPlaces(4).toNumber();

                // Save cursor position
                let start = e.target.selectionStart;
                let end = e.target.selectionEnd;
                
                // If this is not a number after parsing, return
                if (isNaN(quantity)) {
                    return;
                } else if (quantity === current) {
                    return;
                } else if (quantity < 0) {
                    this.$toasted.error('Quantity cannot be negative.');
                    e.target.value = this.displayQuantity(index);
                    // Set cursor position
                    this.$nextTick(() => {
                        e.target.setSelectionRange(start - 1, end - 1);
                    });
                    return;
                }

                this.initOverrides();
                
                // Set override
                _.set(this.invoice_copy.overrides.line_items, `[${[index]}].quantity`, parsed);
                
                // Correct cursor position if necessary
                // Check if we lost a comma
                if (this.countCommas(quantity) > this.countCommas(current)) {
                    start += 1;
                    end += 1;
                } else if (this.countCommas(quantity) < this.countCommas(current)) {
                    // Only adjust if we are not at the start of the number
                    if (quantity.toString().length > 1) {
                        start -= 1;
                        end -= 1;
                    }
                }

                // Set cursor position
                this.$nextTick(() => {
                    e.target.setSelectionRange(start, end);
                });
            },
            // =========================================================
            displayUnitCost(index) {
                return this.line_items[index].unit_cost;
            },
            setUnitCost(index, e) {
                const value = e.target.value;
                const unit_cost = Number(value);
                
                if (isNaN(unit_cost) || value.trim() === '') {
                    return;
                }

                this.initOverrides();

                _.set(this.invoice_copy.overrides.line_items, `[${index}].unit_cost`, value);
            },
            // =========================================================
            displayDiscount(index) {
                const override = this.invoice_copy.overrides?.line_items?.[index]?.discount;
                const raw = _.isNil(override) ? this.line_items[index].discount : override.trim() === '' ? 0 : override.replace(/,/g, '');
                return raw;
            },
            getDiscount(index){
                const override = this.invoice_copy.overrides?.line_items?.[index]?.discount;
                const raw = _.isNil(override) ? this.line_items[index].discount : override;

                return raw;
            },
            setDiscount(index, e) {
                let value = e.target.value;

                if (value.trim() === '') {
                    value = null;
                }

                this.initOverrides();

                const new_line_item = _.cloneDeep(this.line_items[index]);

                if (value) {
                    new_line_item.discount = value;
                } else {
                    delete new_line_item.discount;
                }

                Vue.set(this.invoice_copy.overrides.line_items, index, new_line_item);
            },
            // =========================================================
            displayAmount(index) {
                const text = this.getAmount(index).toDecimalPlaces(2).toString();
                return text.includes('.') ? text : `${text}.00`;
            },
            getAmount(index) {
                const item = this.line_items[index];
                const quantity = this.getQuantity(index);
                const price = this.getPrice(item);
                const total = quantity.mul(price);
                const discount = this.getDiscount(index);

                if (isNaN(quantity) || isNaN(price)) {
                    return 0;
                }

                if (discount) {
                    if (discount.includes('%')) {
                        const percent = new Decimal(discount.replace('%', ''));
                        const discountAmount = total.mul(percent.div(100));
                        return total.sub(discountAmount);
                    } else {
                        const discountAmount = new Decimal(discount);
                        return total.sub(discountAmount);
                    }
                } else {
                    return total;
                }
            },
            setAmount(index, value) {
                const amount = Number(value);
                
                if (isNaN(amount)) {
                    return;
                }

                if (!this.invoice_copy.overrides) {
                    Vue.set(this.invoice_copy, 'overrides', {});
                }

                if (!this.invoice_copy.overrides.line_items) {
                    Vue.set(this.invoice_copy.overrides, 'line_items', []);
                }

                Vue.set(this.invoice_copy.overrides.line_items, index, { amount: value });
            },
            // =========================================================
            deleteLineItem(index) {
                this.initOverrides();

                this.invoice_copy.overrides.line_items.splice(index, 1);
            },
            // =========================================================
            getTotal() {
                let total = new Decimal(0);

                for (const [index, item] of this.line_items.entries()) {
                    total = total.add(this.getAmount(index));
                }

                return Number(total.toFixed(2));
            },
            displayTotal() {
                const total = this.getTotal();
                return this.$options.filters.money(total);
            },
            // =========================================================
            isOverriden(path) {
                if (_.has(this.invoice_copy.overrides, path)) {
                    const original = _.get(this.invoice_copy, path);
                    const overriden = _.get(this.invoice_copy.overrides, path);
                    
                    if (_.isEqual(original, overriden)) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            },
            // =========================================================
            displayPrice(item) {
                const text = new Decimal(item.unit_cost).toDecimalPlaces(4).toString();
                return text.includes('.') ? text : `${text}.00`;
            },
            getPrice(item) {
                return item.unit_cost;
            },
            // =========================================================
        },
        created(){
            this.invoice_copy = _.cloneDeep(this.invoice);

            this.$watch('invoice_copy', (newVal, oldVal) => {
                this.$emit('update:invoice', newVal);
            }, { deep: true });
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .actions {
        margin-top: 20px;
    }

    pre
    {
        margin-bottom: 15px;

        &:last-of-type
        {
            margin-bottom: 0;
        }
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px;
    }

    
    .invoice-details
    {
        padding: 20px;
    }

    .drawer-container .drawer-navigation + .drawer-content
    {
        top: @drawer-navigation-height + @drawer-header-height;
    }

    .invoice-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }

    // ==== Invoice Styles ====

    .filters
    {
        top: @breadcrumbs-height;
    }

    // .invoice.full {
    //     top: @breadcrumbs-height;
    //     overflow-y: auto;
    // }

    .banner {
        border-radius: 0;
        margin: 0;

        .button {
            margin-left: 8px;
        }
    }

    .notes {
        height: 100%;

        .actions {
            margin-top: 10px;
        }
    }

    .option {
        margin-right: 5px;

        .button {
            margin-right: 0;
        }

        &.button {
            margin-right: 5px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .invoice-wrapper
    {
        .header {
            margin-top: 25px;
            margin-bottom: 20px;

            h3
            {
                margin: 0;
                padding: 0;
            }

            &:first-child {
                margin-top: 0;
            }

            .text-button {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .invoice-metadata {
            margin-bottom: 5px;
        }

        .metadata-link {
            font-size: 14px;
            font-weight: 500;
            color: @base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // ===== Summary Styles =====

    .summary-field
    {
        margin-bottom: 10px;
        font-size: 14px;
        padding: 0 50px 0 0;
        transition: all ease 0.2s;

        &:last-child
        {
            margin-bottom: 0;
        }

        &.editing
        {
            background: @f8;
            padding: 15px 25px 43px;
            margin: 10px 0;

            &:last-child
            {
                margin-bottom: 0;
            }

            .options
            {
                opacity: 1;
                pointer-events: all;
            }
        }

        .summary-key
        {
            color: @grey;
            width: 240px;
            flex-basis: 240px;
            flex-shrink: 0;
            height: 22px;
            line-height: 22px;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            .chip {
                margin-left: 0;
            }

            &::before
            {
                width: 16px;
                height: 16px;
                background: url('~@/assets/icons/base/pin.svg') 4px 3px no-repeat;
                background-size: 7px auto;
                position: absolute;
                top: 4px;
                left: -20px;
                bottom: auto;
                right: auto;
                content: "";
                opacity: 0;
                transition: opacity ease 0.2s;
            }

            &.monospace
            {
                line-height: 22px;
                font-size: 14px;
            }

            &.none
            {
                color: @grey;
            }

            &.override
            {
                color: @base;

                &::before
                {
                    opacity: 1;
                }
            }
        }

        .editable
        {
            cursor: pointer;

            &::after
            {
                position: absolute;
                font-size: 11px;
                content: "Edit";
                font-family: @font;
                color: @grey;
                opacity: 0;
                transition: opacity ease 0.3s;
                width: 70px;
                top: 0;
                bottom: 0;
                right: -80px;
                left: auto;
                height: 22px;
                line-height: 22px;
            }

            &:hover, &:active
            {
                &::after
                {
                    opacity: 1;
                }
            }

            &:active
            {
                opacity: 0.6;
            }

            &.flex-wrap
            {
                &::after
                {
                    top: 2px;
                }
            }
        }

        .options
        {
            bottom: -28px;
            position: absolute;
            top: auto;
            opacity: 0;
            pointer-events: none;
            transition: opacity ease 0.2s;

            .text-button
            {
                margin-right: 15px;

                .kbd
                {
                    color: @base;
                    font-weight: 400;
                    margin-left: 4px;
                    border-radius: 2px;
                    background: fade(@base, 15%);
                    height: 16px;
                    line-height: 16px;
                    font-size: 10px;
                    padding: 0 3px;
                }

                &.grey
                {
                    .kbd
                    {
                        color: @grey;
                        background: fade(@grey, 15%);
                    }
                }

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }

        .placeholder
        {
            color: @grey;
        }

        .empty
        {
            color: @grey;
        }

        .autocomplete
        {
            margin: -2px;

            .autocomplete-search
            {
                border: 0;
                min-height: 22px;
                line-height: 22px;
                border-radius: 0;
                padding: 0;

                &:focus-within, &:active
                {
                    box-shadow: none;
                }

                .token
                {
                    padding: 2px 1px 2px 8px;
                    height: 22px;
                    line-height: 18px;
                    border-radius: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    white-space: nowrap;
                    background: rgba(0, 106, 255, 0.2);
                    color: #006aff;
                    margin: 2px;
                }

                .token-remove
                {
                    width: 22px;
                    height: 22px;
                    background: url('~@/assets/icons/base/x-round.svg') 4px 5px no-repeat;
                    background-size: 15px auto;
                    opacity: 0.6;

                    &:hover, &:active
                    {
                        opacity: 1;
                    }
                }

                .token.selected
                {
                    background: @base;
                    color: @f;
                }

                input.autocomplete-input
                {
                    margin: 2px;
                    height: 22px;
                    line-height: 22px;
                    padding: 0 3px;
                    font-size: 14px;
                    color: @base;
                    min-width: 80px;

                    &:focus
                    {
                        box-shadow: none;
                    }

                    &:disabled
                    {
                        background: transparent;
                    }
                }
            }
        }
    }
</style>
