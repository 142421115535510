<template>
    <div class="entity flex flex-column">
        <header class="drawer-header">
            <div class="flex flex-align">
                <div class="entity-icon flex flex-align flex-center">
                    <span class="icon block iconoir-add-hexagon"></span>
                </div>
                <h2 class="ff drawer-title">Request</h2>
                <div class="button" @click="go_to" v-if="team.type === 'developer'">Go To Request</div>
                <div class="text-button border" @click="close">Close</div>
            </div>
            <nav class="flex">
                <div class="destination" :class="{active: tab === 'details'}" @click="tab = 'details'">Details</div>
                <div class="destination" :class="{active: tab === 'json'}" @click="tab = 'json'">JSON</div>
                <template v-if="type === 'people'">
                    <div class="destination" :class="{active: tab === 'enrollments'}" @click="tab = 'enrollments'">Enrollments</div>
                    <!-- <div class="destination" :class="{active: tab === 'requests'}" @click="tab = 'requests'">Requests</div>
                    <div class="destination" :class="{active: tab === 'logins'}" @click="tab = 'logins'">Logins</div> -->
                </template>
                <div class="ff"></div>
                <!-- <div class="destination right" :class="{active: tab === 'events'}" @click="tab = 'events'">Events</div> -->
            </nav>
        </header>
        <div class="ff drawer-scroll">
            <component :is="tabs[tab]" />
        </div>
    </div>
</template>

<script>
    import EntityDetails from '@/components/drawers/entity/EntityDetails.vue';
    import EntityJSON from '@/components/drawers/entity/EntityJSON.vue';

    export default {
        name: 'Request',
        data(){
            return {
                tab: 'details',
                tabs: {
                    details: EntityDetails,
                    json: EntityJSON
                },
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            },
            go_to(){
                this.$store.dispatch('drawer/close');
                this.$router.push({name: 'team.logs.request', params: {request: this.entity.id}});
            }
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            loading(){
                return this.$store.state.entities.loading;
            },
            entity(){
                return this.$store.state.entities.active;
            },
            type(){
                return this.$store.state.entities.type;
            },
            integration(){
                return this.$store.state.entities.integration;
            },
            source(){
                return this.$store.state.entities.source;
            },
            parent(){
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity
    {
        height: 100%;
    }

    .drawer-container
    {
        .drawer-header
        {
            padding-bottom: 0;
            
            .button
            {
                margin-right: 15px;
            }
        }
    }
    
    nav
    {
        border-bottom: 1px solid @e4;
        margin-top: 10px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px;
    }

    .entity-icon
    {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background: fade(@base, 20%);
        margin-right: 15px;

        .icon
        {
            height: 18px;
            width: 18px;
            font-size: 18px;
            margin: 0;
            color: @base;
        }
    }
</style>
