<template>
    <div class="integrate-source-provider-oneroster">
        <integrate-source-editor-step name="Enter Your Qmlativ URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your Qmlativ instance is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.skyward.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Add an Integration Access Record for Edlink" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="image-label flex flex-align">
                    <label>1. Navigate to the <code>API: Integration List</code> screen.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/qmlativ/onboarding/integration-list.png', { title: 'Integration List', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>2. Search for and select the Edlink integration.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>3. Select the <code>Integration Access</code> tab.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/qmlativ/onboarding/integration-details.png', { title: 'Integration Details', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>3. Click <code>Add Integration Access</code>.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>4. Add the integration. Use the key below.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/qmlativ/onboarding/add-integration.png', { title: 'Add Integration', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>           
                <div class="integration-details">
                    <div class="flex flex-align">
                        <span class="block icon iconoir-server-connection"></span>
                        <h2 class="ff">Integration Details</h2>
                    </div>
                    <label>Key</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => this.team.id" v-clipboard:success="() => $toasted.info('Copied')">{{ team.id }}</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>5. Navigate to the <code>API: Integration Access List</code> screen.</label>
                </div>
                <div class="image-label flex flex-align">
                    <label>6. Click <code>Generate Secret</code> for the new Edlink integration.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/qmlativ/onboarding/generate-secret.png', { title: 'Generate Secret', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>7. Copy <code>Secret</code> value. You will need it later</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/qmlativ/onboarding/credentials.png', { title: 'Credentials', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align">
                    <label>8. Click <code>Run Process</code>.</label>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter Your Qmlativ API Credentials" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <div class="helptext">
                Enter the values you copied from Qmlativ.
            </div>
            <label class="key-label">Key</label>
            <input class="medium" type="text" placeholder="Key" :value="source.configuration.key" @input="e => send('UPDATE_SOURCE', {path: 'configuration.key', value: e.target.value})" disabled />
            <label class="key-label">Secret</label>
            <input class="medium client-secret" type="text" placeholder="Secret" :value="source.configuration.secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.secret', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';

    export default {
        name: 'IntegrateSourceProviderQmlativ',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        mounted(){
            this.send('UPDATE_SOURCE', {path: 'configuration.key', value: this.team.id});
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            team(){
                return this.state.context.team;
            },
            destination(){
                // TODO Is this the destination or is it a source?
                return false;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(!this.source.configuration?.url?.match(url)){
                    return [];
                }

                // if(!this.remote){
                //     return ['url'];
                // }

                const properties = ['key', 'secret'];

                for(const property of properties){
                    if(!this.source.configuration?.[property]){
                        return ['url', 'remote'];
                    }
                }

                return ['url', 'remote', 'credentials'];
            }
        },
        methods: {
            image(src, config = { title: null, width: 700, height: 'auto' }){
                this.$modal.show(HelpImage, { src, title: config.title }, { width: config.width ?? 700, height: config.height ?? 'auto', classes: 'modal' });
            },
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .integration-details
    {
        background: @e;
        border-radius: 6px;
        padding: 15px;
        width: 100%;

        .icon
        {
            margin-right: 10px;
            color: @black;
            font-size: 18px;
        }

        h2
        {
            font-size: 13px;
            color: @black;
            font-weight: 400;
        }

        label
        {
            margin: 15px 0 4px;
            font-size: 12px;
            letter-spacing: 0;
            color: @grey;
        }

        .configuration-link
        {
            font-weight: 400;
            font-size: 14px;
            justify-content: flex-start;
            line-height: 16px;
            height: 16px;
            padding-right: 18px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -1px top 1px;
            background-size: 14px auto;

            &.disabled
            {
                background-image: url('~@/assets/icons/grey/external.svg');
            }
        }

        .configuration-value
        {
            color: @black;
            font-size: 14px;
            line-height: 16px;

            &.monospace
            {
                font-size: 13px;
            }

            &.copyable
            {
                &.noshift
                {
                    &::after
                    {
                        height: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
</style>
