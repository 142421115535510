export const redirects = [
    // Exact path matches.
    { path: '/docs/api/v2.0/user/profile', redirect: '/docs/api/v2.0/people/user-get-profile' },
    { path: '/docs/api/v2.0/models/education/district', redirect: '/docs/api/v2.0/districts/model' },
    { path: '/docs/api/v2.0/models/education/school', redirect: '/docs/api/v2.0/schools/model' },
    { path: '/docs/api/v2.0/models/education/class', redirect: '/docs/api/v2.0/classes/model' },
    { path: '/docs/api/v2.0/models/education/section', redirect: '/docs/api/v2.0/sections/model' },
    { path: '/docs/api/v2.0/models/education/course', redirect: '/docs/api/v2.0/courses/model' },
    { path: '/docs/api/v2.0/models/education/person', redirect: '/docs/api/v2.0/people/model' },
    { path: '/docs/api/v2.0/models/education/demographics', redirect: '/docs/api/v2.0/people/demographics' },
    { path: '/docs/api/v2.0/models/education/agent', redirect: '/docs/api/v2.0/agents/model' },
    { path: '/docs/api/v2.0/models/education/session', redirect: '/docs/api/v2.0/sessions/model' },
    { path: '/docs/api/v2.0/models/education/enrollment', redirect: '/docs/api/v2.0/enrollments/model' },
    { path: '/docs/api/v2.0/models/education/department', redirect: '/docs/api/v2.0/departments/model' },
    { path: '/docs/api/v2.0/models/education/subject', redirect: '/docs/api/v2.0/subjects/model' },
    { path: '/docs/api/v2.0/models/education/period', redirect: '/docs/api/v2.0/periods/model' },
    { path: '/docs/api/v2.0/models/education/incident', redirect: '/docs/api/v2.0/incidents/model' },
    { path: '/docs/api/v2.0/models/education/meeting', redirect: '/docs/api/v2.0/meetings/model' },
    { path: '/docs/api/v2.0/models/education/attendance', redirect: '/docs/api/v2.0/attendance/model' },
    { path: '/docs/api/v2.0/models/education/facility', redirect: '/docs/api/v2.0/facilities/model' },
    { path: '/docs/api/v2.0/models/education/room', redirect: '/docs/api/v2.0/rooms/model' },
    { path: '/docs/api/v2.0/models/education/asset', redirect: '/docs/api/v2.0/assets/model' },
    { path: '/docs/api/v2.0/models/education/fee', redirect: '/docs/api/v2.0/fees/model' },
    { path: '/docs/api/v2.0/models/education/stop', redirect: '/docs/api/v2.0/stops/model' },
    { path: '/docs/api/v2.0/models/education/route', redirect: '/docs/api/v2.0/routes/model' },
    { path: '/docs/api/v2.0/models/education/vehicle', redirect: '/docs/api/v2.0/vehicles/model' },
    { path: '/docs/api/v2.0/models/education/calendar', redirect: '/docs/api/v2.0/calendars/model' },
    { path: '/docs/api/v2.0/models/education/day', redirect: '/docs/api/v2.0/days/model' },
    { path: '/docs/api/v2.0/models/education/category', redirect: '/docs/api/v2.0/categories/model' },
    { path: '/docs/api/v2.0/models/education/assignment', redirect: '/docs/api/v2.0/assignments/model' },
    { path: '/docs/api/v2.0/models/education/submission', redirect: '/docs/api/v2.0/submissions/model' },
    { path: '/docs/api/v2.0/models/education/address', redirect: '/docs/api/v2.0/models/address' },
    { path: '/docs/api/v2.0/models/education/alias', redirect: '/docs/api/v2.0/models/alias' },
    { path: '/docs/api/v2.0/models/education/identifier', redirect: '/docs/api/v2.0/models/identifier' },
    { path: '/docs/api/v2.0/models/education/attachment', redirect: '/docs/api/v2.0/models/attachment' },
    { path: '/docs/api/v2.0/models/education/attempt', redirect: '/docs/api/v2.0/models/attempt' },
    { path: '/docs/api/v2.0/models/education/standard', redirect: '/docs/api/v2.0/models/standard' },
    { path: '/docs/api/v2.0/models/meta/event', redirect: '/docs/api/v2.0/events/model' },
    { path: '/docs/api/v2.0/models/meta/integration', redirect: '/docs/api/v2.0/integrations/model' },
    { path: '/docs/api/v2.0/models/meta/materialization', redirect: '/docs/api/v2.0/materializations/model' },
    { path: '/docs/api/v2.0/models/meta/source', redirect: '/docs/api/v2.0/sources/model' },
    { path: '/docs/api/v2.0/models/meta/product', redirect: '/docs/api/v2.0/products/model' },
    { path: '/docs/api/v2.0/models/meta/license', redirect: '/docs/api/v2.0/licenses/model' },
    { path: '/docs/api/v2.0/models/meta/job', redirect: '/docs/api/v2.0/jobs/model' },
    { path: '/docs/api/v2.0/models/meta/task', redirect: '/docs/api/v2.0/tasks/model' },

    // Redirect with parameters.
    { path: '/docs/api/v2.0/graph/:type', redirect: (to) => ({ path: `/docs/api/v2.0/${to.params.type}/overview` }) },
    { path: '/docs/api/v2.0/user/:type', redirect: (to) => ({ path: `/docs/api/v2.0/${to.params.type}/overview` }) },
    { path: '/docs/api/v2.0/meta/:type', redirect: (to) => ({ path: `/docs/api/v2.0/${to.params.type}/overview` }) },
    { path: '/docs/api/v2.0/models/education/enums/:type', redirect: (to) => ({ path: `/docs/api/v2.0/enums/${to.params.type}` }) },
    { path: '/docs/api/v2.0/models/meta/enums/:type', redirect: (to) => ({ path: `/docs/api/v2.0/enums/${to.params.type}` }) },
    { path: '/docs/api/v2.0/models/education/:type', redirect: (to) => ({ path: `/docs/api/v2.0/${to.params.type}/model` }) },
    { path: '/docs/api/v2.0/models/meta/:type', redirect: (to) => ({ path: `/docs/api/v2.0/${to.params.type}/model` }) }
];
