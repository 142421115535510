<template>
    <transformation-workspace :integration="integration" v-if="integration" />
</template>

<script>
    import TransformationWorkspace from '@/components/TransformationWorkspace.vue';

    export default {
        name: 'IntegrationTransformations',
        components: {
            TransformationWorkspace
        },
        computed: {
            integration(){
                return this.$store.getters.integration;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    
</style>