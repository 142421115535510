import { render, staticRenderFns } from "./DbCheck.vue?vue&type=template&id=66437580"
import script from "./DbCheck.vue?vue&type=script&lang=js"
export * from "./DbCheck.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports