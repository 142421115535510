<template>
    <div class="integrate-account-email" v-if="!loading">
        <label>What is your email address?</label>
        <input ref="email" type="text" placeholder="Enter your email address..." autocomplete="false" @input="event => send({type: 'EMAIL', data: event.target.value})" @keydown.enter="find" />
        <div class="options flex" key="email">
            <div class="button" kbd="Enter ⏎" @click="find" :class="{disabled: state.context.email.length === 0}">Continue</div>
            <div class="ff"></div>
            <div class="sso google text-button border" @click="google">Sign In With Google</div>
            <!-- <div class="sso microsoft text-button border">Sign In With Microsoft</div> -->
        </div>
    </div>
</template>

<script>
    import querystring from 'querystring';

    export default {
        name: 'IntegrateAccountEmail',
        props: {
            state: Object,
            send: Function
        },
        computed: {
            invitations(){
                return this.$store.getters.user?.invitations;
            }
        },
        data(){
            return {
                loading: true
            };
        },
        created(){
            if(this.$store.getters.user?.id){
                this.$parent.set_user(this.$store.getters.user);

                if(this.$store.getters.user.two_factor_enabled){
                    if(this.$store.getters.team){
                        if(this.invitations?.length){
                            return this.send('INVITES');
                        } else {
                            return this.send('SELECT_DISTRICT');
                        }
                    }else{
                        return this.send('TERMS');
                    }
                } else {
                    return this.send('TWO');
                }
            }else{
                this.$nextTick(() => this.$refs.email.focus());
            }

            this.loading = false;
        },
        destroyed(){        
            window.removeEventListener('message', this.login_event);
        },
        methods: {
            find(){
                if(this.state.context.email.length < 3){
                    return this.$toasted.error('Please enter a valid email address.');
                }

                this.$http.post('/check', {
                    email: this.state.context.email
                })
                .then(response => {
                    //Send the user to their SSO provider or the password screen.
                    const {method, user_first_name} = response.$data;

                    if(method === 'email'){
                        this.send('PASSWORD', {data: user_first_name});
                    }else{
                        this.send('SSO', {data: 'google'});
                    }
                })
                .catch(error => {
                    //We could not find an account matching this email address.
                    this.send('REGISTER');

                    return Promise.resolve();
                });
            },
            login_event(event) {
                if(event.origin !== window.origin && process.env.NODE_ENV === 'production'){
                    return;
                }

                if (event.data.type === 'login' && event.data.success) {
                    // Remove the listener
                    window.removeEventListener('message', this.login_event);
                    
                    // Attempt to fetch the user - we should be authenticated now
                    this.$store.dispatch('user/login', localStorage.getItem('edlink-user-token')).then(() => {
                        this.send({ type: 'SET_USER', data: this.$store.getters.user });

                        if(this.$store.getters.team){
                            if(this.invitations?.length){
                                this.send('INVITES');
                            } else {
                                this.send('SELECT_DISTRICT');
                            }
                        }else{
                            this.send('TERMS');
                        }
                    });   
                } else {
                    // TODO: error occurred
                }
            },
            google(){
                const properties = {
                    state: Math.random().toString(36).slice(2),
                    redirect_uri: process.env.NODE_ENV === 'production' ? 'https://ed.link/api/v1/sso/google' : 'http://localhost:9900/api/v1/sso/google',
                    client_id: '563820043496-o65vgllud5rrstbf8tg0rltlm5pbg868.apps.googleusercontent.com',
                    scope: 'email profile',
                    response_type: 'code'
                };

                window.addEventListener('message', this.login_event);
                
                window.open(`https://accounts.google.com/o/oauth2/v2/auth?${querystring.stringify(properties)}`, '_blank');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-account-email
    {
        .sso
        {
            background-repeat: no-repeat;
            margin-right: 10px;

            &.google
            {
                background-image: url('~@/assets/icons/source/google.svg');
                background-size: 18px;
                background-position: 8px 5px;
                padding-left: 34px;
            }

            &.microsoft
            {
                background-image: url('~@/assets/icons/source/microsoft.svg');
                background-size: 57px;
                background-position: -9px 7px;
                padding-left: 36px;
            }

            &:last-child
            {
                margin-right: 0;
            }
        }
    }
</style>
