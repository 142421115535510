import Vue from 'vue';
import Prism from 'prismjs';
import axios from 'axios';
import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import VueTheMask from 'vue-the-mask';
import VueI18n from 'vue-i18n';
import Clipboard from 'v-clipboard';

import Application from './Application.vue';
import store from './store';
import router from './router';

import Pretty from './filters/pretty';
import Money from './filters/money';
import Commas from './filters/commas';
import Password from './filters/password';

import Chip from './components/chips/Chip.vue';
import Provider from './components/chips/Provider.vue';
import Region from './components/chips/Region.vue';
import State from './components/chips/State.vue';

import Information from './components/Information.vue';
import PlacardGroup from './components/PlacardGroup.vue';
import Placard from './components/Placard.vue';

import Editor from './components/Editor.vue';
import Checkbox from './components/Checkbox.vue';
import Radio from './components/Radio.vue';
import Tokens from './components/Tokens.vue';
import Autocomplete from './components/Autocomplete.vue';
import Dropdown from './components/Dropdown.vue';
import DropdownItem from './components/DropdownItem.vue';
import Filters from './components/filters/Filters.vue';
import FilterDropdown from './components/filters/FilterDropdown.vue';
import FilterCustom from './components/filters/FilterCustom.vue';
import Pages from './components/Pages.vue';
import ProgressIndicator from './components/ProgressIndicator.vue';
import Spinner from './components/Spinner.vue';
import Upload from './components/Upload.vue';
import PasswordStrength from './components/PasswordStrength.vue';
import Account from './components/Account.vue';
import Token from './components/Token.vue';
import Toggle from './components/Toggle.vue';
import Consent from './components/Consent.vue';
import Breadcrumbs from './components/Breadcrumbs.vue';
import Navigation from './components/Navigation.vue';
import Invitations from './components/Invitations.vue';
import Configuration from './components/Configuration.vue';
import SourceLinks from './components/SourceLinks.vue';
import Profile from './components/Profile.vue';
import IntegrationSummary from './components/IntegrationSummary.vue';
import IntegrationPermissions from './components/IntegrationPermissions.vue';
import IntegrationDrawer from './components/IntegrationDrawer.vue';
import IntegrationRules from './components/IntegrationRules.vue';
import EntityDetails from './components/EntityDetails.vue';
import SourceConfigurationHelp from './components/SourceConfigurationHelp.vue';
import IntegrationConfigurationHelp from './components/IntegrationConfigurationHelp.vue';
import DocumentationTree from './components/DocumentationTree.vue';
import Guide from './components/Guide.vue';
import Graph from './components/Graph.vue';
import TransformationCard from './components/TransformationCard.vue';
import DatasetBrowser from './components/DatasetBrowser.vue';
import Info from './components/Info.vue';
import Banners from './components/Banners.vue';
import Datatable from './components/Datatable.vue';
import StaggeredTransitionGroup from './components/StaggeredTransitionGroup.vue';
import Mapping from './components/Mapping.vue';
import TeamMemberName from '@/components/TeamMemberName.vue';
import BlinkPreview from '@/components/BlinkPreview.vue';

import SyncList from './components/SyncList.vue';
import SyncDetails from './components/SyncDetails.vue';
import SyncStagedChanges from './components/SyncStagedChanges.vue';
import SyncStagedTotals from './components/SyncStagedTotals.vue';
import SyncChanges from './components/SyncChanges.vue';

import Licenses from './components/Licenses.vue';

import CommandPalette from './components/CommandPalette.vue';
import CommandPaletteIntegration from './components/commands/CommandPaletteIntegration.vue';
import CommandPalettePage from './components/commands/CommandPalettePage.vue';
import CommandPaletteTeam from './components/commands/CommandPaletteTeam.vue';
import CommandPaletteSource from './components/commands/CommandPaletteSource.vue';
import CommandPaletteApplication from './components/commands/CommandPaletteApplication.vue';

import Drawer from './components/drawers/Drawer.vue';

import Onboarding from './components/drawers/Onboarding.vue';
import CreateOnboarding from './components/drawers/CreateOnboarding.vue';
import EditRule from '@/components/drawers/EditRule.vue';
import Entity from '@/components/drawers/Entity.vue';
import Enrichment from '@/components/drawers/Enrichment.vue';

import TransformationConfiguration from './components/TransformationConfiguration.vue';
import TransformMapCustomRoles from './components/transform/TransformMapCustomRoles.vue';
import TransformNothing from './components/transform/TransformNothing.vue';
import TransformOpenAI from './components/transform/TransformOpenAI.vue';
import TransformClassStateBySession from './components/transform/TransformClassStateBySession.vue';
import TransformConstructEmailAddresses from './components/transform/TransformConstructEmailAddresses.vue';
import TransformHideClassesInactiveSessions from './components/transform/TransformHideClassesInactiveSessions.vue';
import TransformKebabCase from './components/transform/TransformKebabCase.vue';
import TransformNormalizePhoneNumbers from './components/transform/TransformNormalizePhoneNumbers.vue';
import TransformNormalizeStateNames from './components/transform/TransformNormalizeStateNames.vue';

import IntegrationDataFlow from './components/integration/IntegrationDataFlow.vue';

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import _ from 'lodash';

Vue.use(Clipboard);

Vue.config.productionTip = false;
Prism.manual = true;

Vue.demo = Vue.prototype.$demo = axios.create();

Vue.demo.interceptors.request.use(async (config) => {
    const token_set = await validate_demo_token();

    if (token_set && !config.headers.authorization) {
        config.headers.authorization = `Bearer ${token_set.access_token}`;
    } else {
        console.warn('Error in demo app: token_set invalid. Need to re-auth.');
    }

    return config;
});

Vue.demo.interceptors.response.use(
    (response) => {
        if (!response.data.$data) {
            console.warn(`Call to ${response.config.url} did not include $data.`);
        }

        return response.data;
    },
    (error) => Promise.reject(error)
);

function encode(val) {
    return encodeURIComponent(val).replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%20/g, '+').replace(/%5B/gi, '[').replace(/%5D/gi, ']');
}

// This is a custom serializer for axios that will handle nested objects and arrays.
// It is based on the existing version of https://github.com/axios/axios/pull/4734/files
axios.defaults.paramsSerializer = {
    serialize: (params) => {
        var parts = [];
        _.forEach(params, function serialize(val, key) {
            if (val === null || typeof val === 'undefined') {
                return;
            }

            if (Array.isArray(val)) {
                key = key + '[]';
            } else {
                val = [val];
            }

            _.forEach(val, function parseValue(v) {
                if (_.isDate(v)) {
                    v = v.toISOString();
                } else if (_.isObject(v)) {
                    v = JSON.stringify(v);
                }

                parts.push(encode(key) + '=' + encode(v));
            });
        });

        return parts.join('&');
    }
};

Vue.http = Vue.prototype.$http = axios;

Vue.http.defaults.baseURL = '/api/v1';

Vue.http.interceptors.request.use((config) => {
    if (config.url.startsWith('http')) {
        return config;
    }

    if (store.state.user.token && !config.headers.authorization) {
        config.headers.authorization = `Bearer ${store.state.user.token}`;
    }

    return config;
});

Vue.http.interceptors.response.use(
    (response) => {
        if (!response.data.$data) {
            console.warn(`Call to ${response.config.url} did not include $data.`);
        }

        return response.data;
    },
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject();
        }

        //TODO Handle this better
        if (error.request.responseURL.includes('/api/v1') && !error.response.data.$error) {
            console.warn(`Error from ${error.config.url} did not include $error.`);
        } else if (error.request.responseURL.includes('/api/v2') && !error.response.data.$errors) {
            console.warn(`Error from ${error.config.url} did not include $errors.`);
        }

        return Promise.reject(error.response.data);
    }
);

Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dynamicDefaults: { height: 'auto' } });
Vue.use(VueI18n);

Vue.use(Toasted, {
    position: 'top-center',
    duration: 5000,
    containerClass: 'toast-container',
    className: 'toast',
    theme: 'bubble'
});

Vue.use(VueTheMask);
Vue.use(FloatingVue, {
    placement: 'top',
    distance: 6,
    delay: 0,
    themes: {
        'info-tooltip': {
            $extend: 'tooltip',
            $resetCss: true
        }
    }
});

require('./assets/less/global.less');

Vue.component('chip', Chip);
Vue.component('provider', Provider);
Vue.component('region', Region);
Vue.component('state', State);

Vue.component('information', Information);
Vue.component('placard-group', PlacardGroup);
Vue.component('placard', Placard);

Vue.component('date-picker', DatePicker);
Vue.component('editor', Editor);
Vue.component('checkbox', Checkbox);
Vue.component('radio', Radio);
Vue.component('spinner', Spinner);
Vue.component('breadcrumbs', Breadcrumbs);
Vue.component('upload', Upload);
Vue.component('invitations', Invitations);
Vue.component('configuration', Configuration);
Vue.component('account', Account);
Vue.component('token', Token);
Vue.component('consent', Consent);
Vue.component('dropdown', Dropdown);
Vue.component('dropdown-item', DropdownItem);
Vue.component('integration-summary', IntegrationSummary);
Vue.component('integration-permissions', IntegrationPermissions);
Vue.component('integration-drawer', IntegrationDrawer);
Vue.component('integration-rules', IntegrationRules);
Vue.component('profile', Profile);
Vue.component('entity-details', EntityDetails);
Vue.component('tokens', Tokens);
Vue.component('autocomplete', Autocomplete);
Vue.component('integration-configuration-help', IntegrationConfigurationHelp);
Vue.component('source-configuration-help', SourceConfigurationHelp);
Vue.component('source-links', SourceLinks);
Vue.component('documentation-tree', DocumentationTree);
Vue.component('progress-indicator', ProgressIndicator);
Vue.component('guide', Guide);
Vue.component('graph', Graph);
Vue.component('toggle', Toggle);
Vue.component('transformation-card', TransformationCard);
Vue.component('dataset-browser', DatasetBrowser);
Vue.component('password-strength', PasswordStrength);
Vue.component('filters', Filters);
Vue.component('filter-dropdown', FilterDropdown);
Vue.component('filter-custom', FilterCustom);
Vue.component('pages', Pages);
Vue.component('info', Info);
Vue.component('banners', Banners);
Vue.component('navigation', Navigation);
Vue.component('datatable', Datatable);
Vue.component('staggered-transition-group', StaggeredTransitionGroup);
Vue.component('mapping', Mapping);
Vue.component('team-member-name', TeamMemberName);
Vue.component('transformation-configuration', TransformationConfiguration);
Vue.component('blink-preview', BlinkPreview);

Vue.component('sync-list', SyncList);
Vue.component('sync-details', SyncDetails);
Vue.component('sync-staged-changes', SyncStagedChanges);
Vue.component('sync-staged-totals', SyncStagedTotals);
Vue.component('sync-changes', SyncChanges);

Vue.component('licenses', Licenses);

Vue.component('command-palette', CommandPalette);
Vue.component('command-palette-source', CommandPaletteSource);
Vue.component('command-palette-application', CommandPaletteApplication);
Vue.component('command-palette-integration', CommandPaletteIntegration);
Vue.component('command-palette-page', CommandPalettePage);
Vue.component('command-palette-team', CommandPaletteTeam);

Vue.component('drawer', Drawer);

// Various drawer components
Vue.component('create-onboarding', CreateOnboarding);
Vue.component('onboarding', Onboarding);
Vue.component('edit-rule', EditRule);
Vue.component('entity', Entity);
Vue.component('enrichment', Enrichment);

Vue.component('transform-nothing', TransformNothing);
Vue.component('transform-openai', TransformOpenAI);
Vue.component('transform-map-custom-roles', TransformMapCustomRoles);
Vue.component('transform-class-state-by-session', TransformClassStateBySession);
Vue.component('transform-construct-email-addresses', TransformConstructEmailAddresses);
Vue.component('transform-hide-classes-inactive-sessions', TransformHideClassesInactiveSessions);
Vue.component('transform-kebab-case', TransformKebabCase);
Vue.component('transform-normalize-phone-numbers', TransformNormalizePhoneNumbers);
Vue.component('transform-normalize-state-names', TransformNormalizeStateNames);

Vue.component('integration-data-flow', IntegrationDataFlow);

Vue.filter('pretty', Pretty);
Vue.filter('money', Money);
Vue.filter('commas', Commas);
Vue.filter('password', Password);

// Basically, no matter what, we can always inititalize the system status widget.
store.dispatch('system/load');

// Every 5 minutes, we'll refresh the system status widget.
setInterval(() => store.dispatch('system/load'), 5 * 60 * 1000);

// Initialize a blink session, if we have one.
store.dispatch('blink/initialize');

new Vue({ router, store, render: (h) => h(Application) }).$mount('#app');

// THESE CREDENTIALS ARE FOR DEMO PURPOSES ONLY.
// THEY BELONG TO THE EDLINK DEMO APP AND SHOULD NEVER BE REPLACED BY REAL CREDENTIALS.
const client_id = '36ad8864-d789-45b6-9df8-334c2f32445b';
const client_secret = 'pT0wYC9rgaUdxgUw8PlFEP1ALeejZbJIemZ9u5VYTreyvEw0uDLevmCR3M9Zqhww';
const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';

async function validate_demo_token() {
    const local_data = localStorage.getItem('edlink-demo-app-token-set');

    if (local_data) {
        try {
            const token_set = JSON.parse(local_data);
            if (token_set.expires_at && new Date(token_set.expires_at).getTime() > Date.now()) {
                return token_set;
            } else if (token_set && token_set.refresh_token) {
                const res = await Vue.http
                    .post(`${base_url}/authentication/token`, {
                        refresh_token: token_set.refresh_token,
                        client_id: client_id,
                        client_secret: client_secret,
                        grant_type: 'refresh_token'
                    })
                    .catch(console.log);
                const refreshed_token_set = res.$data;
                // Calculate new expiration
                const t = new Date();
                t.setSeconds(t.getSeconds() + refreshed_token_set.expires_in);
                // Get current state
                const token_sets = JSON.parse(localStorage.getItem('edlink-demo-app-token-sets'));
                const old_token_set = JSON.parse(localStorage.getItem('edlink-demo-app-token-set'));
                const new_token_set = Object.assign({}, old_token_set, refreshed_token_set);
                // Set new expiration
                new_token_set.expires_at = t.toISOString();
                // Look for index of current refreshed token
                const search_index = token_sets.findIndex((it) => it.person_id === old_token_set.person_id);
                if (search_index !== -1) {
                    // Replace if found
                    token_sets[search_index] = new_token_set;
                }
                // Save 'state'
                localStorage.setItem('edlink-demo-app-token-set', JSON.stringify(new_token_set));
                localStorage.setItem('edlink-demo-app-token-sets', JSON.stringify(token_sets));
                return new_token_set;
            } else {
                // we have no refresh somehow, need to re auth
                return false;
            }
        } catch (e) {
            localStorage.removeItem('edlink-demo-app-token-set');
            // something is really wrong, just re auth
            return false;
        }
    } else {
        // we have nothing, need to re auth
        return false;
    }
}

// Allow the store to be accessed from the window context.
window.$store = store;