<template>
    <div class="flex flex-align">
        <template v-for="tag in tags">
            <chip class="tag" color="blue" :title="tag" />
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Tags',
        props: {
            tags: Array
        },
        data(){
            return {};
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .chip.tag
    {
        // padding-left: 4px;
        white-space: nowrap;

        .chip-thumbnail
        {
            // width: @chip-height - 2 * @chip-padding - 2px;
            // height: @chip-height - 2 * @chip-padding - 2px;
            border: 1px solid @e;
        }

        .chip-title
        {
            // padding-left: 5px;
        }
    }
</style>
