import { render, staticRenderFns } from "./IntegrateSourceProviderSkyward.vue?vue&type=template&id=c6938630&scoped=true"
import script from "./IntegrateSourceProviderSkyward.vue?vue&type=script&lang=js"
export * from "./IntegrateSourceProviderSkyward.vue?vue&type=script&lang=js"
import style0 from "./IntegrateSourceProviderSkyward.vue?vue&type=style&index=0&id=c6938630&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6938630",
  null
  
)

export default component.exports