<template>
    <div class="documentation-tree">
        <template v-for="(doc, $index) of filtered">
            <div v-if="doc.type === 'section'" class="tree-section" :key="$index">
                <div class="tree-title flex flex-align pointer" :class="doc.slug" @click="toggle(doc.slug)">
                    <div class="tree-toggle flex flex-align flex-center">
                        <component :is="expanded[doc.slug] ? 'NavArrowDown' : 'NavArrowRight'" class="icon block" width="12" height="12" stroke-width="3" />
                    </div>
                    <div class="ff">{{doc.title}}</div>
                </div>
                <div class="tree-entries" v-if="expanded[doc.slug]">
                    <documentation-tree :tree="doc.documents" :search="search" :path="doc.cosmetic ? path : path + '/' + doc.slug" :cosmetic="doc.cosmetic" />
                </div>
            </div>
            <div class="tree-entry-container flex flex-align" v-else :key="$index">
                <div class="tree-spacer" v-if="doc.type === 'spacer'">{{doc.title}}</div>
                <router-link v-else :to="'/docs/' + path + '/' + doc.slug" class="tree-entry pointer text-overflow">{{doc.title}}</router-link>
            </div>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { NavArrowRight, NavArrowDown } from '@epiphany/iconoir';

    export default {
        name: 'DocumentationTree',
        props: {
            tree: Array,
            search: String,
            path: String,
            cosmetic: Boolean
        },
        components: {
            NavArrowRight,
            NavArrowDown
        },
        data(){
            return {
                expanded: {}
            };
        },
        computed: {
            filtered(){
                if(this.tree){
                    return this.tree.filter(doc => this.matches(doc));
                }

                return [];
            }
        },
        methods: {
            toggle(slug){
                Vue.set(this.expanded, slug, !this.expanded[slug]);
            },
            matches(doc){
                if(this.search){
                    if(doc.type === 'spacer'){
                        return false;
                    }

                    if(doc.title.toLowerCase().includes(this.search)){
                        return true;
                    }

                    if(doc.documents){
                        return doc.documents.find(child => this.matches(child));
                    }

                    return false;
                }

                return true;
            },
            highlight(title){
                if(this.search){
                    return title.replace(this.search, `<span class="highlight">${this.search}</span>`);
                }

                return title;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .documentation-tree
    {
        font-size: 13px;
        color: @black;
        font-weight: 400;
        line-height: 24px;

        .tree-section
        {
            margin-top: 10px;

            &:first-child
            {
                margin-top: 0;
            }
        }

        .tree-toggle
        {
            width: 14px;
            height: 14px;
            display: block;
            margin: 0 7px 0 1px;
            border-radius: @border-radius - 2px;
            background: @e;

            .icon
            {
                margin: 1px;
            }
        }

        .tree-title
        {
            font-weight: 500;
            margin: 2px 0;
            transform: opacity ease 0.2s;

            &:hover, &:active
            {
                opacity: 0.7;
            }
        }

        .tree-entry-container
        {
            margin: 6px 0;

            &:first-child
            {
                .tree-spacer
                {
                    margin-top: 0;
                }
            }
        }

        .tree-entry
        {
            transform: opacity ease 0.2s;
            margin: 0;

            &::after
            {
                content: "";
                position: absolute;
                border-bottom: 1px solid @black;
                top: 0;
                left: 0;
                bottom: 2px;
                pointer-events: none;
                width: 0;
                transition: width .2s ease;
                opacity: .7;
            }

            &:hover, &:active
            {
                color: @black;

                &::after
                {
                    width: 100%;
                }
            }

            &.router-link-exact-active
            {
                color: @base;

                &::after
                {
                    width: 100%;
                    border-bottom-color: @base;
                }
            }
        }

        .tree-entries
        {
            margin: 4px 0 4px 7px;
            border-left: 1px solid @e;
            padding: 0 0 0 15px;
        }

        .tree-spacer
        {
            font-size: 11px;
            color: @grey;
            margin-top: 16px;
            font-weight: 500;
            line-height: 16px;
        }
    }
</style>
