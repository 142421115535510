<template>
    <div class="integration-rules">
        <template v-if="rules.length">
            <div class="rule card" v-for="rule of rules" :key="rule.id">
                <div class="rule-header flex flex-align">  
                    <h4 class="flex flex-align">
                        <component :is="icons[rule.owner]" class="icon" width="16" height="16" stroke-width="2" />
                        <template v-if="rule.name && rule.name.trim() !== ''">
                            {{ rule.name }}
                        </template>
                        <template v-else>
                            Untitled {{ rule.owner === 'district' ? 'District' : 'Developer' }} Rule
                        </template>
                    </h4>
                    <div class="ff"></div>
                    <div class="rule-state-message flex flex-align tooltip tooltip-top" :tooltip="tooltip(rule)" :class="state(rule)">
                        <EditPencil v-if="state(rule) === 'draft'" class="icon" width="14" height="14" stroke-width="2" />
                        <Calendar v-else-if="state(rule) === 'upcoming'" class="icon" width="14" height="14" stroke-width="2" />
                        <Timer v-else-if="state(rule) === 'expired'" class="icon" width="14" height="14" stroke-width="2" />
                        <Check v-else class="icon" width="15" height="15" stroke-width="2" />
                        <div class="text-overflow">{{ message(rule) }}</div>
                    </div>
                    <div class="button white has-icon flex flex-align" @click="edit(rule)" :class="{ 'disabled': owner !== rule.owner }">
                        <EditPencil class="icon" width="16" height="16" stroke-width="2" />
                        Edit
                    </div>
                    <div class="button white has-icon flex flex-align" @click="copy(rule)" :class="{ 'disabled': owner !== rule.owner }">
                        <Copy class="icon" width="16" height="16" stroke-width="2" />
                        Copy
                    </div>
                    <div class="button white has-icon flex flex-align tooltip tooltip-top" @click="remove(rule)" :class="{ 'disabled': owner !== rule.owner }">
                        <Trash class="icon" width="16" height="16" stroke-width="2" />
                        Delete
                    </div>
                </div>
                <div class="rule-logic flex" v-if="rule.conditions.length">
                    <div class="rule-target flex flex-align">
                        Share <chip class="rule-chip text-overflow" :title="rule.target" color="grey" />
                    </div>
                    <div class="rule-conditions ff">
                        <div class="condition flex flex-align" :key="index" v-for="(condition, index) of rule.conditions">
                            <div class="condition-metadata flex flex-align">
                                <div class="condition-and">{{ index === 0 ? 'Where' : 'And' }}</div>
                                <div class="condition-property"><chip class="rule-chip text-overflow" :title="property(rule, condition)" color="grey" /></div>
                                <div class="condition-operator">{{ operator(rule, condition) }}</div>
                            </div>
                            <div class="condition-values ff flex flex-wrap">
                                <chip class="rule-chip text-overflow condition-value" :title="value" v-for="(value, $index) of values(rule, condition)" :key="$index" color="grey" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="rule-no-conditions flex flex-align flex-center">
                    This rule has no conditions so it will match <chip class="rule-chip text-overflow" :title="`No ${rule.target}`" color="grey" />
                </div>
            </div>
        </template>
        <div class="nothing" v-else>{{ empty ?? 'There are no sharing rules created for this integration.' }}</div>
    </div>
</template>

<script>
    import Options from '@/components/modals/Options.vue';
    import { licensing } from '@/constants';
    import { City, EditPencil, Trash, Copy, Svg3dSelectSolid, Check, Timer, Calendar } from '@epiphany/iconoir';

    export default {
        name: 'IntegrationRules',
        components: {
            City,
            Svg3dSelectSolid,
            EditPencil,
            Trash,
            Copy,
            Check,
            Timer,
            Calendar
        },
        props: {
            rules: Array,
            integration: Object,
            licensing: Boolean,
            empty: String,
            owner: String,
            counts: Object
        },
        data(){
            return {
                properties: licensing.properties,
                operators: licensing.operators,
                types: licensing.types,
                icons: {
                    'developer': Svg3dSelectSolid,
                    'district': City
                }
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            }
        },
        methods: {
            tooltip(rule){
                const counts = this.counts?.[rule.id];

                if(counts){
                    return `This rule is ${rule.type} ${counts.people ? this.$options.filters.commas(counts.people) : 'no'} ${counts.people === 1 ? 'person' : 'people'} and ${counts.classes ? this.$options.filters.commas(counts.classes) : 'no'} class${counts.classes === 1 ? '' : 'es'}`;
                }
            },
            state(rule){
                if(rule.state === 'active'){
                    const start = rule.start_date ? new Date(rule.start_date) : null;
                    const end = rule.end_date ? new Date(rule.end_date) : null;
                    const now = new Date();

                    if(start && start > now){
                        return 'upcoming';
                    }

                    if(end && end <= now){
                        return 'expired';
                    }

                    return 'active';
                }else{
                    return 'draft';
                }
            },
            message(rule){
                if(rule.state === 'active'){
                    const start = rule.start_date ? new Date(rule.start_date) : null;
                    const end = rule.end_date ? new Date(rule.end_date) : null;
                    const now = new Date();

                    if(start && start > now){
                        return `This rule will take effect on ${this.$options.filters.pretty(rule.start_date, 'medium')}`;
                    }

                    if(end && end > now){
                        return `This rule is active and will expire on ${this.$options.filters.pretty(rule.end_date, 'medium')}`;
                    }else if(end && end <= now){
                        return `This rule expired on ${this.$options.filters.pretty(rule.end_date, 'medium')}`;
                    }

                    return 'This rule is active';
                }else{
                    return 'This rule is in draft mode';
                }
            },
            expanded(value){
                if (value.name) {
                    return value.name;
                } else if (value.display_name) {
                    return value.display_name;
                } else if (value.first_name) {
                    return `${value.first_name} ${value.last_name}`;
                } else {
                    return `Unknown ${value.id ? value.id : value}`;
                }
            },
            property(rule, condition){
                return this.properties[rule.target].find(property => property.value === condition.property).name;
            },
            operator(rule, condition){
                return this.operators.find(operator => operator.value === condition.operator).name;
            },
            change_state(rule){
                const _this = this;

                this.$modal.show(Options, {
                    title: 'Set Rule State',
                    description: 'This may disrupt live users.',
                    options: [
                        {
                            title: `Set to Active`,
                            color: 'base',
                            fn(){
                                _this.save_state(rule, 'active')
                            }
                        },
                        {
                            title: `Set to Draft`,
                            color: 'base',
                            fn(){
                                _this.save_state(rule, 'draft')
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            save_state(rule, state){
                this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}/rules/${rule.id}`, {state})
                .then(response => {
                    this.$store.dispatch('integrations/did_update_rule', response.$data);
                    this.$toasted.success(`The sharing rule has been updated to "${state}".`);
                })
                .catch(error => {
                    this.$toasted.error('There was an error updating the state of your rule.');
                })
                .finally(() => {
                    this.saving = false;
                });
            },
            values(rule, condition){
                const operator = this.operators.find(operator => operator.value === condition.operator);

                const values = condition.property.endsWith('_id') ? condition.values.map(this.expanded) : condition.values;

                if(operator.select === 'multiple'){
                    if(values.length === 0){
                        return ['(Values Not Set)'];
                    }

                    //If we're dealing with an enumerated type, these will be objects. If not, strings.
                    //For example, `course title IS ANY OF []` will be an array of strings.
                    //However, `course teacher IS ANY OF []` will be an array of objects.
                    
                    if(values.length > 4){
                        const statement = values.slice(0, 4);

                        //Append a suffix to the statement to imply that there are more items.
                        statement.push(`+ ${this.$options.filters.commas(values.length - 4)} more`);

                        return statement;
                    }

                    return values;
                }else if(operator.select === 'one'){
                    return values ? values : ['(Value Not Set)'];
                }else{
                    return [];
                }
            },
            edit(rule){
                this.$store.dispatch('drawer/open', {
                    key: 'edit-rule',
                    width: 800,
                    component: 'edit-rule',
                    props: {
                        rule,
                        licensing: (this.licensing || this.team.properties?.enable_sharing_rules_products)
                    }
                });
            },
            async copy(rule){
                try {
                    const copied = await this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/rules/${rule.id}/copy`);
                    
                    this.$store.dispatch('integrations/did_create_rule', copied.$data)
                    this.$toasted.success('Copy created successfully.');

                    const clone = _.cloneDeep(copied.$data);

                    this.$store.dispatch('drawer/open', {
                        key: 'edit-rule',
                        width: 800,
                        component: 'edit-rule',
                        props: {
                            rule: clone,
                            licensing: (this.licensing || this.team.properties?.enable_sharing_rules_products)
                        }
                    });
                } catch (error) {
                    this.$toasted.error('There was an error creating a new sharing rule.');
                }
            },
            remove(rule){
                const _this = this;

                this.$modal.show(Options, {
                    title: 'Remove Rule',
                    description: 'Are you sure you want to remove this rule? This may disrupt live users.',
                    options: [
                        {
                            title: `Remove Sharing Rule`,
                            color: 'red',
                            fn(){
                                _this.$http.delete(`/teams/${_this.team.id}/integrations/${_this.integration.id}/rules/${rule.id}`)
                                .then(() => {
                                    _this.$toasted.error('The sharing rule has been deleted.');
                                    _this.$store.dispatch('integrations/did_delete_rule', rule);
                                    _this.$emit('close');
                                })
                                .catch(error => {
                                    _this.$toasted.error('There was an error deleting your rule.');
                                })
                                .finally(() => {
                                    _this.saving = false;
                                });
                            }
                        }
                    ]
                }, {width: 300, height: 'auto', classes: 'modal'});
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integration-rules
    {
        .nothing
        {
            font-size: 13px;
        }
    }

    .rule-header
    {
        padding: 15px 15px 15px 20px;

        .button
        {
            margin-left: @single-padding;
        }

        h4
        {
            font-size: 14px;
            font-weight: 500;
            color: @black;
            cursor: default;
            height: 20px;

            .icon
            {
                margin-right: 6px;
            }
        }

        .badge
        {
            margin-left: 10px;
        }
    }

    .rule
    {
        margin-bottom: 20px;
    }

    .rule-state-message
    {
        font-size: 13px;
        color: darken(@green, 10%);
        margin-right: @single-padding;
        height: 16px;
        line-height: 16px;
        cursor: default;

        .icon
        {
            margin-right: 6px;
        }

        &.draft
        {
            color: darken(@yellow, 10%);
        }

        &.upcoming
        {
            color: @purple;
        }

        &.expired
        {
            color: @red;
        }
    }

    .rule-logic, .rule-no-conditions
    {
        border-top: 1px dashed @d;
        margin: 0 20px 15px;
        padding-top: 15px;
        font-size: 14px;
        color: @black;
        line-height: 20px;
        align-items: flex-start;
    }

    .rule-no-conditions
    {
        text-align: center;
        line-height: 30px;
        align-items: center;
    }

    .rule-target
    {
        line-height: 30px;
        width: 150px;
    }

    .rule-chip::v-deep
    {
        color: @black;
        font-weight: 500;
        margin: 0 5px;
        text-transform: capitalize;

        .chip-title
        {
            font-size: 12px;
        }
    }

    .condition
    {
        min-height: 30px;
        align-items: flex-start;

        .condition-metadata
        {
            width: 300px;
            line-height: 30px;
        }

        .condition-operator
        {
            
        }

        .condition-values
        {
            .condition-value
            {
                margin: 5px;
                text-transform: none;
            }
        }
    }

    .nothing
    {
        text-align: center;
        padding: @double-padding;
        border: 1px dashed @d;
        border-radius: @border-radius;
    }
</style>
