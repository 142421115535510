import { render, staticRenderFns } from "./ShareProducts.vue?vue&type=template&id=59705590&scoped=true"
import script from "./ShareProducts.vue?vue&type=script&lang=js"
export * from "./ShareProducts.vue?vue&type=script&lang=js"
import style0 from "./ShareProducts.vue?vue&type=style&index=0&id=59705590&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59705590",
  null
  
)

export default component.exports