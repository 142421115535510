<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <StatsUpSquare class="icon" width="16" height="16" stroke-width="2" />
                    Admin Overview
                </div>
            </template>
        </breadcrumbs>
        <div class="full admin-overview">
            <AdminPipelineFailures />
            <ul>
                <li>API request per day / per minute</li>
                <li>API request failure rate</li>
                <li>General overview of syncs (# in each phase)</li>
                <li>Sync job failure rate</li>
                <li>Recently added teams</li>
                <li>Recently added sources / integrations</li>
                <li>Developers waiting for approval on things</li>
                <li>System level counts (teams, sources, integrations, developers, shared users)</li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </main>
</template>

<script>
    import AdminPipelineFailures from './AdminPipelineFailures.vue';
    import { StatsUpSquare } from '@epiphany/iconoir';
    
    export default {
        components: {
            AdminPipelineFailures,
            StatsUpSquare
        },
        name: 'AdminOverview',
        computed: {
            verified(){
                return this.$store.state.user ? this.$store.state.user.email_verified : false;
            },
            user(){
                return this.$store.state.user;
            },
            name(){
                if(this.user.first_name){
                    return this.user.last_name ? this.user.first_name + ' ' + this.user.last_name : this.user.first_name;
                }else if(this.user.last_name){
                    return this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.last_name;
                }else{
                    return 'Anonymous User';
                }
            }
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .admin-overview
    {
        top: @breadcrumbs-height;
        overflow-y: auto;
    }
</style>
