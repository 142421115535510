<template>
    <div class="integrate-error">
        <h1>Something's not quite right</h1>
        <div class="integration-description">
            {{ error }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateInitializationError',
        props: {
            state: Object,
            send: Function
        },
        computed: {
            application() {
                return this.state.context.application;
            },
            team() {
                return this.state.context.team;
            },
            onboarding(){
                return this.$store.state.integrate.onboarding;
            },
            error(){
                return this.state.context.error;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integration-description
    {
        font-size: 14px;
        color: @grey;
        line-height: 18px;
        margin: 0 0 20px;
    }
</style>
