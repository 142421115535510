<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <NetworkLeft class="icon" width="16" height="16" stroke-width="2" />
                    Data Pipeline
                </div>
            </template>
        </breadcrumbs>
        <div class="pipeline full scroll">
            <div class="pipeline-view flex">
                <div class="pipeline-column ff flex flex-column" v-for="state of states" :key="state">
                    <div class="pipeline-state flex flex-align">
                        <div class="ff capitalize">{{ state }}</div>
                        <div class="pipeline-count" v-if="syncs[state].length">{{ syncs[state].length }}</div>
                    </div>
                    <div class="ff pipeline-cards">
                        <div class="pipeline-card flex flex-align" v-for="sync of syncs[state]" :key="sync.id"
                            :class="{ child: state === 'working' && child(sync) }">
                            <div class="source-icon" :title="title(sync).join('\n')">
                                <img :src="sync.provider.icon_url" />
                                <progress-indicator :progress="progress(state, sync)" :track="track(state, sync)" :width="40" :height="40" :stroke="2" />
                                <div v-if="sync.source?.internal?.validation?.passed === false" class="validation-warning flex flex-align flex-center tooltip tooltip-top" tooltip="The most recent validation for this source failed">
                                    <Cancel class="icon block" width="10" height="10" stroke-width="3" />
                                </div>
                            </div>
                            <div class="child-arrow">
                                <span class="icon iconoir-long-arrow-down-right"></span>
                            </div>
                            <div class="ff pipeline-metadata flex flex-align">
                                <!-- <div class="ff pointer" v-clipboard="() => sync.id" v-clipboard:success="() => $toasted.info('Copied Sync ID')"> -->
                                <div class="ff pointer" @click="show_sync_details(sync)">
                                    <div class="sync-title flex flex-align" :title="sync.application.name" v-if="state === 'materialization'">
                                        <div class="text-overflow">{{ sync.application.name }}</div>
                                    </div>
                                    <div class="sync-title flex flex-align" :title="sync.team.name" v-else>
                                        <div class="text-overflow">{{ sync.team.name }}</div>
                                        <div class="verified tooltip tooltip-top" tooltip="This team is verified" v-if="sync.team.verified"></div>
                                        <div class="inactive tooltip tooltip-top" tooltip="This team is currently inactive" v-if="sync.team.status === 'inactive'">
                                            <InfoCircle class="icon" width="14" height="14" stroke-width="2" />
                                        </div>
                                    </div>
                                    <div class="sync-district text-overflow">
                                        <span class="sync-tag integration-count" v-if="sync.integration_count">{{ sync.integration_count }} Integration{{ sync.integration_count === '1' ? '' : 's' }}</span>
                                        <span class="sync-tag pending capitalize" v-if="sync.status === 'pending'">Pending</span>
                                        <span class="sync-tag flushing capitalize" v-if="sync.status === 'flushing'">Flushing</span>
                                        <span class="sync-tag partial-phase capitalize" v-if="child(sync)" title="Partial sync phase">{{ sync.internal.partial.phase }}</span>
                                        <span class="sync-tag development-sync capitalize" v-if="sync.internal && sync.internal.development" title="Developer sync">{{ sync.internal.development }}</span>
                                        <span>{{ elapsed(sync) }}</span>
                                    </div>
                                </div>
                                <div class="options flex flex-align">
                                    <span class="icon iconoir-bell" v-if="sync.requires_attention"
                                        title="This sync has one or more children that are errored or canceled"></span>
                                    <span class="icon iconoir-git-merge" v-if="parent(sync)"
                                        title="This is a parent sync"></span>
                                    <span class="icon iconoir-message-text" v-if="(sync.internal.notes && sync.internal.notes.length > 0)"
                                        title="This sync has a note"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="sync-details" :width="560" :height="660" classes="modal flex flex-column">
            <div v-if="details" class="sync-details">
                <h2>Sync Summary</h2>
                <div class="summary">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Sync ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.id"
                            v-clipboard:success="() => $toasted.info('Copied')">{{ details.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Source ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.source.id"
                            v-clipboard:success="() => $toasted.info('Copied')">{{ details.source.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">State</div>
                        <div class="summary-value flex flex-align">
                            <div class="button-group flex flex-align">
                                <a class="text-button" :class="{ active: details.status === 'planned' }"
                                    @click="change_state('planned')">Planned</a>
                                <a class="text-button" :class="{ active: details.status === 'queued' }"
                                    @click="change_state('queued')">Queued</a>
                                <a class="text-button yellow" :class="{ active: details.status === 'canceled' }"
                                    @click="change_state('canceled')">Canceled</a>
                                <a class="text-button red" :class="{ active: details.status === 'killed' }"
                                    @click="change_state('killed')">Killed</a>
                            </div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">District ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.team.id"
                            v-clipboard:success="() => $toasted.info('Copied')">{{ details.team.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">District Name</div>
                        <div class="summary-value">{{ details.team.name }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Sync Type</div>
                        <div class="summary-value capitalize">{{ details.type }}</div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="details.type === 'partial'">
                        <div class="summary-key">Parent ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => details.parent.id"
                            v-clipboard:success="() => $toasted.info('Copied')">{{ details.parent.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Elapsed Time</div>
                        <div class="summary-value">{{ elapsed(details) }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Status</div>
                        <div class="summary-value">
                            <div class="flex flex-align">
                                <div class="standalone icon-status" :class="status(details)"></div>
                                <div class="capitalize">{{ details.status }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Created Date</div>
                        <div class="summary-value">{{ details.created_date | pretty('long') }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Updated Date</div>
                        <div class="summary-value">{{ details.updated_date | pretty('long') }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Heartbeat Date</div>
                        <div class="summary-value">{{ details.heartbeat_date | pretty('long') }}</div>
                    </div>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">Priority</div>
                        <div class="summary-value flex flex-align">
                            <div class="button-group flex flex-align">
                                <a class="text-button" :class="{ active: details.priority === null }"
                                    @click="change_priority(null)">Default</a>
                                <a class="text-button" :class="{ active: details.priority === 1 }"
                                    @click="change_priority(1)">1</a>
                                <a class="text-button" :class="{ active: details.priority === 10 }"
                                    @click="change_priority(10)">10</a>
                                <a class="text-button" :class="{ active: details.priority === 100 }"
                                    @click="change_priority(100)">100</a>
                            </div>
                        </div>
                    </div>
                    <h2>Actions</h2>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">Changes</div>
                        <div class="summary-value flex flex-align">
                            <a class="text-button red" @click="delete_staged_changes">Discard Staged Changes</a>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align action">
                        <div class="summary-key">View</div>
                        <div class="summary-value flex flex-align">
                            <router-link class="text-button"
                                :to="{ name: 'team.sources.source.sync', params: { team: details.team.alias, source: details.source.id, sync: details.id } }">Go
                                To Sync</router-link>
                        </div>
                    </div>
                    <h2 class="note-header">Notes</h2>
                    <div class="summary-field">
                        <p v-if="!(details.internal.notes)" class="note-list">This Sync has no notes</p>
                        <ul v-else class="note-list">
                            <li v-for="note_object in details.internal.notes">
                                <div class="summary-value"> {{ note_object.note }}</div>
                                <div class="summary-sub-value">
                                    {{ note_object.user_id }}:
                                    {{ note_object.created_date | pretty('long') }}
                                </div>
                            </li>
                        </ul>
                        <textarea v-model="note_input" placeholder="type note here">
                        </textarea>
                        <div class="flex flex-align note-label">
                            <button @click="make_sync_note()" class="note-button">save note</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </main>
</template>

<script>
import moment from 'moment';
import mdf from 'moment-duration-format';
import _ from 'lodash';
import { NetworkLeft, InfoCircle, Cancel } from '@epiphany/iconoir';

mdf(moment);

export default {
    name: 'AdminSync',
    components: {
        NetworkLeft,
        InfoCircle,
        Cancel
    },
    data() {
        return {
            interval: null,
            states: ['queued', 'working', 'staged', 'enrichment', 'materialization'],
            syncs: {
                queued: [],
                working: [],
                staged: [],
                enrichment: [],
                materialization: []
            },
            filter: {
                queued: '',
                working: '',
                staged: '',
                enrichment: '',
                materialization: ''
            },
            details: null,
            note_input: ''
        };
    },
    created() {
        this.load();

        this.interval = setInterval(() => this.load(), 4000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
    methods: {
        status(sync) {
            return {
                red: ['error', 'disabled', 'destroyed'].includes(sync.status),
                yellow: ['paused'].includes(sync.status),
                green: ['active'].includes(sync.status),
                grey: ['requested'].includes(sync.status),
                purple: ['inactive', 'scheduled', 'pending', 'flushing'].includes(sync.status)
            };
        },
        elapsed(sync) {
            return moment.duration(moment().diff(this.parent(sync) ? sync.created_date : sync.updated_date)).format('hh:mm:ss', { stopTrim: 'm' });
        },
        parent(sync) {
            return _.get(sync, 'source.data.use_partial_sync') && !this.child(sync);
        },
        child(sync) {
            return _.get(sync, 'parent.id');
        },
        track(state, sync) {
            if (['enrichment', 'materialization'].includes(state)) {
                return sync.state === 'working' ? 'blue' : 'none';
            }

            if (['staged', 'pending'].includes(state)) {
                return 'none';
            }

            if (['flushing'].includes(state)) {
                return 'blue';
            }

            if (['working'].includes(state)) {
                return 'grey';
            }

            return 'none';
        },
        show_sync_details(sync) {
            this.details = sync;
            this.$modal.show('sync-details');
        },
        hide_sync_details() {
            this.details = null;
            this.$modal.hide('sync-details');
        },
        progress(state, sync) {
            if (!sync.internal || !sync.internal.progress) {
                return 0;
            }

            if (['staged', 'pending'].includes(state)) {
                return 0;
            }

            return sync.internal.progress.total ? Math.min(Math.round(sync.internal.progress.complete / sync.internal.progress.total * 100), 100) : 0;
        },
        title(sync) {
            if (!sync.internal || !sync.internal.progress) {
                return ['Progress unknown'];
            }

            const messages = [];

            // There is a sync.internal.progress.log which shows the current status, i.e. 'Listing people' or 'Getting classes at school xyz'
            messages.push(sync.internal.progress.log);

            if (sync.internal.progress.caches) {
                for (const [key, cache] of Object.entries(sync.internal.progress.caches)) {
                    // Basically what it does is creates all the lines for the title tag and .joins them
                    // together at the end. sync id 298ff5b6-a70a-488e-aea4-572ce33e07a has a sample of the
                    // data format this expects, and you can also find it in the SyncProgress type in @epiphany/types.

                    // My original vision was a bootstrap style popover when hovering over a working sync, but I quickly
                    // realized that was out of my realm of knowledge and I didn't want to spend more than 15 minutes on this.


                    const { seen, known } = cache;

                    if (known) {
                        messages.push(`${seen} of ${known} (${Math.round(seen / known * 100)}%) ${key}`);
                    } else {
                        messages.push(`${seen} ${key}`);
                    }
                }
            }

            if (sync.internal.progress.total) {
                messages.push(`${sync.internal.progress.complete} / ${sync.internal.progress.total} (${Math.round(sync.internal.progress.complete / sync.internal.progress.total * 100)}%)`);
            }

            // Here we use moment.js to display 'started x minutes ago'
            if (sync.internal.progress.started) {
                messages.push(`Started ${moment(sync.internal.progress.started).fromNow()}`);
            }

            return messages;
        },
        load() {
            this.$http.get('/admin/pipeline')
                .then(response => {
                    this.reset();

                    for (const sync of response.$data.syncs) {
                        if (['pending', 'flushing'].includes(sync.status)) {
                            this.syncs.staged.push(sync);
                        } else {
                            this.syncs[sync.status].push(sync);
                        }
                    }

                    const working_syncs = this.syncs.working.filter(sync => !this.child(sync));

                    for (const sync of this.syncs.working) {
                        const parent_id = this.child(sync);

                        if (parent_id) {
                            const parent_sync_index = working_syncs.findIndex(s => s.id === parent_id);
                            working_syncs.splice(parent_sync_index + 1, 0, sync);
                        }
                    }

                    this.syncs.working = working_syncs;

                    for (const enrichment of response.$data.enrichments) {
                        this.syncs.enrichment.push(enrichment);
                    }

                    for (const materialization of response.$data.materializations) {
                        this.syncs.materialization.push(materialization);
                    }
                })
                .catch(error => this.$toasted.error(error));
        },
        reset() {
            this.syncs.queued = [];
            this.syncs.working = [];
            this.syncs.staged = [];
            this.syncs.enrichment = [];
            this.syncs.materialization = [];
        },
        change_state(state) {
            this.$http.put(`/admin/sources/${this.details.source.id}/syncs/${this.details.id}`, { sync_status: state })
                .then(response => {
                    console.log(response)
                    this.details.status = state;
                })
                .catch(error => {
                    this.$toasted.error('There was an error changing the state.');
                    console.error(error);
                });
        },
        change_priority(priority) {
            this.$http.put(`/admin/sources/${this.details.source.id}/syncs/${this.details.id}`, { sync_priority: priority })
                .then(response => {
                    console.log(response)
                    this.details.priority = priority;
                })
                .catch(error => {
                    this.$toasted.error('There was an error changing the priority.');
                    console.error(error);
                });
        },
        delete_staged_changes() {
            console.log
            this.$http.delete(`/admin/sources/${this.source.id}/syncs/${this.$route.params.sync}/staged`)
                .then(res => this.$toasted.info(`Staged changes for ${this.details.source.name} (${this.details.source.id}) deleted.`))
                .catch(error => {
                    this.$toasted.error('There was an error deleting the staged changes.');
                    console.error(error);
                });
        },
        make_sync_note() {
            if(!this.note_input){
                return;
            }

            if (!this.details.internal.notes) {
                //this code is slightly funky but i believe appropriate. If there are no notes, establish an empty notes array which I later push to and read from.           
                this.details.internal.notes = [];
            }

            const note_object = {
                created_date: new Date(),
                note: this.note_input,
                user_id: this.$store.state.user.email
            }

            this.details.internal.notes.push(note_object);

            this.$store.dispatch('save/save', 'sync/note/update');
            this.$http.put(`/admin/sources/${this.details.source.id}/syncs/${this.details.id}`, { sync_internal: this.details.internal, sync_priority: this.details.priority, sync_status: this.details.status })
                .then(response => {
                    this.details.internal = response.$data.sync_internal;
                    this.$store.dispatch('save/saved', 'sync/note/update');
                    this.note_input = '';
                })
                .catch(error => {                    
                    this.$toasted.error('There was an error making this note.');
                    this.$store.dispatch('save/saved', 'sync/note/update');
                    console.error(error);
                })              

        }
    }
}
</script>

<style scoped lang="less">

@import "~@/assets/less/variables";

.pipeline
{
    top: @breadcrumbs-height;
    align-items: stretch;
}

.pipeline-header {
    border-bottom: 1px solid @e4;
    height: 70px;
    padding: 20px;
}

.pipeline-column {
    border-right: 1px solid @e4;

    &:last-child {
        border-right: 0;
    }
}

.pipeline-state {
    font-weight: 500;
    color: @black;
    padding: 15px 10px 10px;
    font-size: 14px;
}

.pipeline-card {

    .child-arrow {
        display: none;
    }

    &.child {
        padding-left: 20px;

        .child-arrow {
            display: block;
            font-size: 20px;
            padding-right: 12px;
            color: @grey;
        }

        .source-icon {
            display: none;
        }
    }
}

.source-icon {
    width: 32px;
    padding-right: 0;
    margin: 0 10px;

    &::before {
        width: 32px;
        height: 32px;
        position: absolute;
        z-index: 2;
        content: "";
        top: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: @d;
        display: block;
    }

    .validation-warning
    {
        width: 12px;
        height: 12px;
        background: @red;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
        color: @f;
        border-radius: 50%;
        border: 1px solid darken(@red, 10%);
    }
}

.progress-indicator {
    position: absolute;
    top: -4px;
    left: -4px;
}

.pipeline-card:last-child {
    .pipeline-metadata {
        padding: 8px 10px 8px 0;
        border-bottom: none;
    }
}

.pipeline-metadata {
    padding: 8px 10px 8px 0;
    border-bottom: 1px solid @e4;
}

.sync-title {
    color: @black;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 2px;
    line-height: 18px;

    .verified
    {
        background: url('~@/assets/icons/black/verified.svg') no-repeat;
        background-position: left 1px top 1px;
        background-size: 13px auto;
        margin-left: 4px;
        width: 15px;
        min-width: 15px;
        height: 15px;
    }

    .inactive
    {
        width: 14px;
        height: 14px;
        margin-left: 4px;
    }
}

.sync-district {
    color: @grey;
    font-size: 12px;
    line-height: 14px;
}

.progress {
    font-size: 24px;
    margin-left: 10px;

    span {
        vertical-align: middle;
        color: @grey;
    }
}

.pipeline-count {
    color: @grey;
    font-weight: 400;
    font-size: 11px;
}

.sync-tag {
    margin-right: 5px;
    display: inline-block;
    border-radius: 3px;
    background: @base;
    padding: 0 3px;
    line-height: 14px;
    font-size: 10px;
    color: @f;
    font-weight: 500;

    &.retry {
        background: @red;
    }

    &.development-sync {
        background: @base;
    }

    &.partial-phase {
        background: @purple;
    }

    &.pending {
        background: @red;
    }

    &.flushing {
        background: @orange;
    }

    &.integration-count {
        background: @d;
        color: @black;
    }
}

.options {
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;

    .icon {
        vertical-align: middle;
        color: @grey;
        margin-left: 6px;
    }
}

.elapsed-time {
    font-size: 11px;
    color: @grey;
    margin-right: 6px;
}

.sync-details {
    padding: 20px;
    overflow-y: auto;

    h2 {
        color: @black;
        font-size: 16px;
        padding-top: 20px;
        border-top: 1px solid @e4;
        margin-top: 20px;
        margin-bottom: 20px;

        &:first-child {
            margin-top: 0;
            border-top: 0;
            padding-top: 0;
        }
    }

    .summary {
        font-size: 13px;

        .summary-field {
            height: 22px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .summary-key {
            color: @grey;
            width: 160px;
            flex-basis: 160px;
            flex-shrink: 0;
        }

        .summary-value {
            color: @black;
            line-height: 22px;

            &.monospace {
                line-height: 22px;
                font-size: 13px;
            }

            .icon-status {
                margin-right: 6px;
            }

            .text-button {
                & .active {
                    color: @base;
                    border-color: @base;
                }

            }
        }

        .summary-sub-value {
            font-size: 11px;
        }
    }
}

.note-label {
    margin-bottom: 15px;
    margin-top: 15px;
}

.note-button {
    margin-bottom: 10px;
}

.note-header {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #E4E4E4;
}
</style>
