<template>
    <div class="licenses">
        <template v-if="licenses.length" >
            <div class="license" v-for="license of licenses" :key="license.id">
                <div class="product-title flex flex-align">
                    {{ license.product ? license.product.name : 'Product not found' }}
                    <div class="assigned-total flex flex-align">
                        {{ license.school_count + license.class_count + license.person_count }} / ∞
                    </div>
                </div>
                <div class="license-counts">
                    Schools: {{ license.school_count }}
                    Classes: {{ license.class_count }}
                    People: {{ license.person_count }}
                </div>
            </div>
        </template>
        <div class="none flex flex-align flex-center" v-else>
            No licenses assigned.
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Licenses',
        data() {
            return {
                licenses: [],
                products: {}
            }
        },
        computed: {
            integration(){
                return this.$store.getters.integration;
            },
            team(){
                return this.$store.getters.team;
            },
            user() {
                return this.$store.getters.user;
            }
        },
        mounted(){
            this.getLicenses();
        },
        methods: {
            getLicenses() {
                this.$http.get('/graph/licenses', {
                    baseURL: '/api/v2',
                    params: {
                        $expand: 'product'
                    },
                    headers: {
                        authorization: `Bearer ${this.integration.access_token}`
                    }
                }).then(response => {
                    this.licenses = response.$data;
                });
            }
        }
    }

</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .licenses {
        border: 1px solid @e4;
        border-radius: 6px;
        max-width: 750px;
    }

    .none {
        padding: 25px;
        font-size: 12px;
        color: @grey;
    }

    .license {
        border-bottom: 1px solid @e4;
        padding: 10px 15px;

        .product-title {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
            color: @black;
            justify-content: space-between;
        }

        .assigned-total {
            font-size: 12px;
            color: @grey;
            font-weight: 400;

            .icon {
                height: 12px;
                width: 12px;
                font-size: 12px;
                margin-left: 3px;
            }
        }

        .license-counts {
            font-size: 12px;
            color: @grey;
        }

        &:last-child {
            border-bottom: none;
        }
    }

</style>