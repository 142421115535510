<template>
    <div class="integrate-source-provider-oneroster">
        <integrate-source-editor-step name="SAML Metadata" icon="iconoir-password-cursor" :complete="completed.includes('metadata')">
            <integrate-source-editor-configuration title="SAML Metadata" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                    click <a class="configuration-link inline-block text-button mini" target="_blank" href="#">here</a>.
                </div>
            </integrate-source-editor-configuration>
            <label class="key-label">SAML Metadata</label>
            <textarea class="medium" type="text" placeholder="SAML Metadata" :value="source.configuration.metadata" @input="e => send('UPDATE_SOURCE', {path: 'configuration.metadata', value: e.target.value})"></textarea>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderSAML',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                if(!this.source.configuration.metadata){
                    return ['remote'];
                }

                return ['remote', 'metadata'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 2);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
