<template>
    <main class="admin-entities">
        <header class="flex flex-align has-filters">
            <h1>Entities</h1>
            <div class="ff"></div>
            <input class="filter search" type="text" placeholder="Search by Name" v-model="query" @keypress.enter="search" />
            <a class="button" @click="create">Create Entity</a>
        </header>
        <filters>
            <filter-dropdown :items="types" :active="type" @activate="switch_type" label="Entity Type">
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
        </filters>
        <table v-if="entities.length">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th class="text-right">Updated</th>
                </tr>
            </thead>
            <tbody>
                <router-link v-for="entity of entities" :key="entity.id" tag="tr" class="selectable" :to="{name: 'admin.entities.entity', params: {entity: entity.id}}">
                    <td>{{entity.name}}</td>
                    <td class="capitalize">{{entity.type}}</td>
                    <td class="text-right">{{entity.updated_date | pretty('short')}}</td>
                </router-link>
            </tbody>
        </table>
        <div class="nothing" v-else>Nothing to see here.</div>
    </main>
</template>

<script>
    export default {
        name: 'Entities',
        data(){
            return {
                entities: [],
                query: '',
                status: 'all',
                match: 'all',
                type: null,
                types: [
                    {
                        id: 'college',
                        name: 'College',
                        icon: 'iconoir-linux'
                    },
                    {
                        id: 'district',
                        name: 'District',
                        icon: 'iconoir-bank'
                    },
                    {
                        id: 'school',
                        name: 'School',
                        icon: 'iconoir-bank'
                    },
                    {
                        id: 'other',
                        name: 'Other',
                        icon: 'iconoir-bank'
                    }
                ],
            };
        },
        created(){
            this.search();
        },
        methods: {
            switch_type(type){                
                this.type = type;               
            },
            search(){
                const params = {};

                if(this.query){
                    params.search = this.query;
                }

                this.$http.get('/entities', {params})
                .then(response => {
                    this.entities = response.$data;
                })
                .catch(error => this.$toasted.error('There was an error loading the list of entities.'));
            },
            create(){

            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .admin-entities
    {
        padding-bottom: 100px;
    }

    .search
    {
        margin-right: 15px;
    }

    td
    {
        padding: 6px 10px;
    }
</style>