<template>
    <div class="integrate-source-provider-microsoft">
        <integrate-source-editor-step name="Connect Your Microsoft Account" icon="iconoir-windows" :complete="completed.includes('tenant')">
            <div class="helptext">
                Sign into your Microsoft account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <consent :write="destination" :configuration="source.configuration" :provider="provider" :value="source.configuration.tenant_id || source.id"  @input="value => send('UPDATE_SOURCE', {path: 'configuration.tenant_id', value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderMicrosoft',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 2. Validate that the token has been acquired.
                if(!this.source.configuration.tenant_id){
                    return [];
                }

                return ['tenant'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 1);
            }
        },
        data(){
            return {};
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }

    .badge
    {
        border-radius: 3px;
        padding: 2px 4px;
        color: @black;
        background: fade(@black, 10%);
        font-weight: 400;
    }
</style>
