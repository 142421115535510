<template>
    <div class="class-list" v-if="found_submission">
        <div class="assignment" v-if="found_assignment">
            <div class="for">Submission for</div>
            <h3>{{found_assignment.title}}</h3>
            <div>from {{found_submission.person.display_name}}</div>

            <div class="submission-info flex flex-column">
                <div class="item status">{{found_submission.state}}</div>
                <!-- <div class="item" v-if="found_submission.updated_date">Last Updated: {{found_submission.updated_date | pretty('short')}}</div> -->
            </div>

            <div class="assignment-info flex flex-column">
                <div class="item" v-if="found_assignment.due_date">Due: {{found_assignment.due_date | pretty('short')}}</div>
                <div class="item"  v-else>Posted: {{found_assignment.created_date | pretty('short')}}</div>
            </div>
            <label>Description</label>
            <div v-html="found_assignment.description"></div>
            <div class="grade">
                <label>Grade</label>
                <div class="flex flex-align">
                    <input v-model="found_submission.grade_points" type="number" />
                    <button @click="grade(found_submission.id, found_submission.grade_points)" id="grade">Submit</button>
                </div>
            </div>

        </div>
        <div v-if="student">

        </div>
        <div v-else-if="teacher">
            <div class="submission" v-if="found_submission">
                <div class="answer" v-if="sorted_attempts[0] && sorted_attempts[0].attachments">
                    <h3>Submitted Work</h3>
                    <div class="form-field">
                        <label>What is 9 + 10?</label>
                        <input :value="sorted_attempts[0].attachments[0].text" readonly type="text" />
                    </div>
                </div>
                <div v-else class="nothing">
                    This student has not submitted any attempts
                </div>
            </div>
        </div>
    </div>
    <div v-else class="loading">
        <spinner />
    </div>
</template>

<script>
const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';

export default {
    name: 'DemoSubmission',
    props: {
        edlink_widget: Object,
        profile: Object,
        token_set: Object,
        lti_launch: Boolean
    },
    data() {
        return {
            found_assignment: null,
            found_submission: null,
            answer: '',
            submission_grade: null,
            submissions: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state?.user.admin;
        },
        integration() {
            return this.$store.state.integrations.active;
        },
        student() {
            return this.profile.roles.includes('student');
        },
        teacher() {
            return this.profile.roles.includes('teacher');
        },
        sorted_attempts() {
            return this.found_submission?.attempts.sort((a,b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            });

        }
    },
    async mounted() {
        // Fetch assignment
        this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}`).then(res => {
            this.found_assignment = res.$data;
        });
        // Fetch submission
        this.$demo.get(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submissions/${this.$route.params.submission}`, {
            params: {
                $expand: 'person'
            }
        }).then(res => {
            this.found_submission = res.$data;
        })
    },
    methods: {
        date_sort(attempts){
            return attempts.sort((a,b) => {
                return new Date(b.created_date) - new Date(a.created_date);
            });
        },
        submit(answer) {
            this.$demo.post(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submit`, {
                attachments: [
                    {
                        type: "text",
                        text: answer
                    }
                ]
            }).then(graded_submission => {
                console.log(graded_submission)
                this.$toasted.success('Successfully submitted');
            }).catch(() => {
                this.$toasted.error('Error submitting')
            });
        },
        grade(submission_id, grade_points) {
            this.$demo.patch(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submissions/${submission_id}`, {
                grade_points: parseInt(grade_points)
            }).then(graded_submission => {
                console.log(graded_submission)
                this.$toasted.success('Successfully graded');
                this.$demo.post(`${base_url}/v2/my/classes/${this.$route.params.class}/assignments/${this.$route.params.assignment}/submissions/${submission_id}/return`).then(() => {
                    this.$toasted.success('Successfully returned');
                }).catch((error) => {
                    const unsupported = error.response.data.$errors.find(it => it.code === 'UNSUPPORTED_OPERATION');
                    if (unsupported) {
                        this.$toasted.info(`Provider does not require submissions to be returned`)
                    } else {
                        this.$toasted.error('Error returning submission')
                    }
                })
            }).catch(() => {
                this.$toasted.error('Error grading submission')
            });
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

h2 {
    margin-bottom: 15px;
}

.class-list {
    width: 100%;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    // grid-template-rows: repeat(300px);
    grid-gap: 25px;
}

.assignment {
    .for {
        position: absolute;
        top: -16px;
    }
}

.assignment-info, .submission-info {
    color: fade(@black, 85%);
    margin-bottom: 8px;
    font-size: 14px;

    .item {
        background-color: @f8;
        border-radius: 6px;
        width: fit-content;
        padding: 5px 8px;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.submission-info {
    margin-top: 20px;

    .status {
        text-transform: capitalize;
    }
}

.grade {
    margin-top: 25px;
}

strong {
    color: @black;
}

#submit {
    margin-top: 15px;
}

#grade {
    margin-left: 10px;
}

// Demo Reset
button {
    background: @demo-base;
    border-color: darken(@demo-base, 10%);

    &:hover {
        background: darken(@demo-base, 10%);   
    }
}

input {
    background: @white;

    &:focus, &:focus-within {
        border-color: @demo-base;
        box-shadow: 0 0 0 3px fade(@demo-base, 30%);
    }
}
</style>
