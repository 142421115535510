<template>
    <div class="integration-data-flow flex">
        <div class="ff card phase oh">
            <div class="phase-header">
                <div class="primary-source flex">
                    <div class="phase-icon">
                        <img :src="integration.provider.icon_url" />
                    </div>
                    <div class="ff phase-details flex flex-center flex-column">
                        <h4 class="phase-title">{{integration.provider.name}}</h4>
                        <div class="phase-subtitle">
                            <template v-if="syncs.successful">
                                Last synced {{syncs.successful.updated_date | pretty('short')}}
                            </template>
                            <template v-else>
                                Never synced
                            </template>
                        </div>
                    </div>
                </div>
                <template v-if="integration.links.length">
                    <div class="enrichment-source flex flex-align" v-for="link of integration.links" :key="link.id">
                        <div class="phase-icon">
                            <img :src="icon(link.secondary.provider_id)" />
                        </div>
                        <div class="ff phase-details flex flex-center flex-column">
                            <h4 class="phase-title">{{link.secondary.name}}</h4>
                            <div class="phase-subtitle">{{name(link.secondary.provider_id)}}</div>
                        </div>
                    </div>
                </template>
                <div class="connect-enrichment-source enrichment-source flex flex-align" :class="{developer}" v-else>
                    <div class="phase-icon flex flex-center flex-align">
                        <Cancel v-if="developer" class="icon phase-block-icon" width="14" height="14" stroke-width="2.5" />
                        <Plus v-else class="icon phase-block-icon" width="14" height="14" stroke-width="2.5" />
                    </div>
                    <div class="ff phase-details flex flex-center flex-column">
                        <h4 class="phase-title">{{developer ? 'No Enrichment Source' : 'Connect an Enrichment Source'}}</h4>
                    </div>
                </div>
            </div>
            <div class="phase-blocks">
                <div class="flex flex-align phase-blocks-header">
                    <Bolt class="icon" width="16" height="16" stroke-width="2" />
                    <h5>Latest Enrichment</h5>
                </div>
                <template v-if="enrichments.loading">
                    <div class="phase-blocks-loading flex flex-align flex-center">
                        <spinner />
                    </div>
                </template>
                <template v-else>
                    <div class="ff phase-block flex flex-align" v-for="output of source_transformations" :key="output.transformation_id" :class="output.state"   @click="open('enrichments', enrichments.latest, output)">
                        <component :is="state_icon(output.state)" class="icon phase-block-icon" width="16" height="16" stroke-width="2" />
                        <h4 class="phase-block-title ff">{{output.block_title}}</h4>
                        <div class="phase-block-output flex flex-align">
                            <!-- <div>1,089</div> -->
                            <OpenInWindow class="icon" width="16" height="16" stroke-width="2" />
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="ff connection flex flex-align flex-column" :class="{error: false}">
            <div class="permissions flex flex-align">
                <div class="ff">SSO + Roster Data</div>
                <ArrowRight class="icon" width="11" height="11" stroke-width="2.5" />
            </div>
            <div class="permissions-feature flex flex-align">
                <Check class="icon" width="12" height="12" stroke-width="2" />
                14 of 15 Scopes
            </div>
        </div>
        <div class="ff card phase oh">
            <div class="flex phase-header">
                <div class="phase-icon">
                    <img :src="integration.application.icon_url ? integration.application.icon_url : 'https://ed.link/icon.png'" />
                </div>
                <div class="ff phase-details">
                    <h4 class="phase-title">{{integration.application.name}}</h4>
                    <div class="phase-subtitle">
                        <template v-if="materializations.successful">
                            Last materialized {{materializations.successful.updated_date | pretty('short')}}
                        </template>
                        <template v-else>
                            Never materialized
                        </template>
                    </div>
                </div>
            </div>
            <div class="phase-activity">
                <graph class="ff" :configuration="requests" :hover="date" />
            </div>
            <div class="phase-blocks">
                <div class="flex flex-align phase-blocks-header">
                    <Sparks class="icon" width="16" height="16" stroke-width="2" />
                    <h5>Latest Materialization</h5>
                </div>
                <template v-if="materializations.loading">
                    <div class="phase-blocks-loading flex flex-align flex-center">
                        <spinner />
                    </div>
                </template>
                <template v-else>
                    <div class="ff phase-block flex flex-align" v-for="output of shared_transformations" :key="output.transformation_id" :class="output.state"  @click="open('materializations', materializations.latest, output)">
                        <component :is="state_icon(output.state)" class="icon phase-block-icon" width="16" height="16" stroke-width="2" />
                        <h4 class="phase-block-title ff">{{output.block_title}}</h4>
                        <div class="phase-block-output flex flex-align">
                            <!-- <div>1,089</div> -->
                            <OpenInWindow class="icon" width="16" height="16" stroke-width="2" />
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="ff connection flex flex-align flex-column" :class="{error: false}">
            <div class="permissions flex flex-align">
                <div class="ff">Coursework + Grade Data</div>
                <ArrowRight class="icon" width="11" height="11" stroke-width="2.5" />
            </div>
            <div class="permissions-feature flex flex-align">
                <Check class="icon" width="12" height="12" stroke-width="2" />
                14 of 15 Scopes
            </div>
        </div>
        <div class="ff">
            <template v-if="integration.application.id === '979dde54-832a-4814-ad50-4b30d23f23c7'">
                <div class="card phase oh">
                    <div class="phase-header">
                        <div class="primary-source flex">
                            <div class="phase-icon">
                                <img :src="integration.destination.provider_icon_url" />
                            </div>
                            <div class="ff phase-details flex flex-center flex-column">
                                <h4 class="phase-title">{{integration.destination.provider_name}}</h4>
                                <div class="phase-subtitle">Last synced yesterday</div>
                            </div>
                        </div>
                    </div>
                    <div class="phase-blocks">
                        <div class="flex flex-align phase-blocks-header">
                            <DataTransferBoth class="icon" width="16" height="16" stroke-width="2" />
                            <h5>Latest Roster Syncs</h5>
                        </div>
                        <template v-if="jobs.destination.loading">
                            <div class="phase-blocks-loading flex flex-align flex-center">
                                <spinner />
                            </div>
                        </template>
                        <template v-else>
                            <div class="phase-block job flex flex-align" v-for="job of jobs.destination.all" :key="job.id" :class="job.state" @click="open('jobs', job)">
                                <component :is="state_icon(job.state)" class="icon phase-block-icon" width="16" height="16" stroke-width="2" />
                                <h4 class="phase-block-title ff capitalize">{{job.state}}</h4>
                                <div class="job-tasks">
                                    <template v-if="tasks(job)">{{progress(job)}} of {{tasks(job)}}</template>
                                    <template v-else>Not Ready</template>
                                </div>
                                <div class="job-progress oh flex">
                                    <div class="job-progress-bar" :style="{ width: complete(job) }"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="card phase oh">
                    <div class="phase-header">
                        <div class="primary-source flex">
                            <div class="phase-icon">
                                <img :src="integration.provider.icon_url" />
                            </div>
                            <div class="ff phase-details flex flex-center flex-column">
                                <h4 class="phase-title">{{integration.provider.name}}</h4>
                                <div class="phase-subtitle">Last synced yesterday</div>
                            </div>
                        </div>
                    </div>
                    <div class="phase-blocks">
                        <div class="flex flex-align phase-blocks-header">
                            <DataTransferBoth class="icon" width="16" height="16" stroke-width="2" />
                            <h5>Latest Grade Syncs</h5>
                        </div>
                        <template v-if="jobs.source.loading">
                            <div class="phase-blocks-loading flex flex-align flex-center">
                                <spinner />
                            </div>
                        </template>
                        <template v-else>
                            <div class="phase-block job flex flex-align" v-for="job of jobs.source.all" :key="job.id" :class="job.state" @click="open('jobs', job)">
                                <component :is="state_icon(job.state)" class="icon phase-block-icon" width="16" height="16" stroke-width="2" />
                                <h4 class="phase-block-title ff capitalize">{{job.state}}</h4>
                                <div class="job-tasks">
                                    <template v-if="tasks(job)">{{progress(job)}} of {{tasks(job)}}</template>
                                    <template v-else>Not Ready</template>
                                </div>
                                <div class="job-progress oh flex">
                                    <div class="job-progress-bar" :style="{ width: complete(job) }"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="card phase oh">
                    <div class="phase-header">
                        <div class="primary-source flex">
                            <div class="phase-icon">
                                <img :src="integration.provider.icon_url" />
                            </div>
                            <div class="ff phase-details flex flex-center flex-column">
                                <h4 class="phase-title">{{integration.provider.name}}</h4>
                                <div class="phase-subtitle">Last synced yesterday</div>
                            </div>
                        </div>
                    </div>
                    <div class="phase-blocks">
                        <div class="flex flex-align phase-blocks-header">
                            <DataTransferBoth class="icon" width="16" height="16" stroke-width="2" />
                            <h5>Latest Jobs</h5>
                        </div>
                        <template v-if="jobs.source.loading">
                            <div class="phase-blocks-loading flex flex-align flex-center">
                                <spinner />
                            </div>
                        </template>
                        <template v-else>
                            <div class="phase-block job flex flex-align" v-for="job of jobs.source.all" :key="job.id" :class="job.state" @click="open('jobs', job)">
                                <component :is="state_icon(job.state)" class="icon phase-block-icon" width="16" height="16" stroke-width="2" />
                                <h4 class="phase-block-title ff capitalize">{{job.state}}</h4>
                                <div class="job-tasks">
                                    <template v-if="tasks(job)">{{progress(job)}} of {{tasks(job)}}</template>
                                    <template v-else>Not Ready</template>
                                </div>
                                <div class="job-progress oh flex">
                                    <div class="job-progress-bar" :style="{ width: complete(job) }"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="card phase oh" v-for="link of destinations" :key="link.id">
                    <div class="phase-header">
                        <div class="primary-source flex">
                            <div class="phase-icon">
                                <img :src="icon(link.secondary.provider_id)" />
                            </div>
                            <div class="ff phase-details flex flex-center flex-column">
                                <h4 class="phase-title">{{link.secondary.name}}</h4>
                                <div class="phase-subtitle">{{name(link.secondary.provider_id)}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="phase-blocks">
                        <div class="flex flex-align phase-blocks-header">
                            <DataTransferBoth class="icon" width="16" height="16" stroke-width="2" />
                            <h5>Latest Jobs</h5>
                        </div>
                        <template v-if="jobs.source.loading">
                            <div class="phase-blocks-loading flex flex-align flex-center">
                                <spinner />
                            </div>
                        </template>
                        <template v-else>
                            <div class="phase-block job flex flex-align" v-for="job of jobs.source.all" :key="job.id" :class="job.state" @click="open('jobs', job)">
                                <component :is="state_icon(job.state)" class="icon phase-block-icon" width="16" height="16" stroke-width="2" />
                                <h4 class="phase-block-title ff capitalize">{{job.state}}</h4>
                                <div class="job-tasks">
                                    <template v-if="tasks(job)">{{progress(job)}} of {{tasks(job)}}</template>
                                    <template v-else>Not Ready</template>
                                </div>
                                <div class="job-progress oh flex">
                                    <div class="job-progress-bar" :style="{ width: complete(job) }"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { Cancel, Lock, Check, AlignLeft, Bolt, CheckCircle, Plus, InfoCircle, RefreshCircular, HelpCircle, WarningTriangle, ArrowRight, DataTransferBoth, Sparks, OpenInWindow, BookmarkCircle } from '@epiphany/iconoir';
    import { known_blocks } from '@/constants';
    import * as d3 from 'd3';
    import Vue from 'vue';

    import Entity from '@/components/drawers/Entity.vue';
    import Enrichment from '@/components/drawers/Enrichment.vue';
    import Materialization from '@/components/drawers/Materialization.vue';
    // import Request from '@/components/drawers/Request.vue';
    import Job from '@/components/drawers/Job.vue';
    import Flow from '@/components/drawers/Flow.vue';
    // import Options from '@/components/modals/Options.vue';

    // Calculate our start time for charts.
    const now = new Date();

    // Set the time to the nearest hour.
    now.setMinutes(0, 0, 0);

    const components = {
        enrichments: Enrichment,
        materializations: Materialization,
        jobs: Job,
        flows: Flow
    };

    export default {
        name: 'IntegrationDataFlow',
        components: {
            Cancel,
            Lock,
            Check,
            AlignLeft,
            Bolt,
            CheckCircle,
            Plus,
            InfoCircle,
            RefreshCircular,
            HelpCircle,
            DataTransferBoth,
            Sparks,
            OpenInWindow,
            BookmarkCircle,
            ArrowRight
        },
        computed: {
            providers(){
                return this.$store.state.providers.all;
            },
            team() {
                return this.$store.getters.team;
            },
            developer() {
                return this.team.id === this.integration.developer.id;
            },
            integration() {
                return this.$store.getters.integration;
            },
            shared_transformations(){
                if(!this.materializations.latest){
                    return;
                }

                return this.integration.transformation_ids.map(transformation_id => {
                    // Output is no longer an array. We'll have to adjust this code going forward.
                    const output = Array.isArray(this.materializations.latest.output) ? this.materializations.latest.output.find(output => output.transformation_id === transformation_id) : null;

                    if(output){
                        return Object.assign({
                            block_title: known_blocks[output.block_id] ?? 'Custom Transformation',
                        }, output);
                    }else{
                        return {
                            state: 'unknown',
                            result: {},
                            block_id: null,
                            block_title: 'Unknown Block',
                            end_date: null,
                            start_date: null,
                            transformation_id
                        };
                    }
                });
            },
            source_transformations(){
                if(!this.enrichments.latest){
                    return;
                }

                return this.integration.source.transformation_ids.map(transformation_id => {
                    // Output is no longer an array. We'll have to adjust this code going forward.
                    const output = Array.isArray(this.enrichments.latest.output) ? this.enrichments.latest.output.find(output => output.transformation_id === transformation_id) : null;

                    if(output){
                        return Object.assign({
                            block_title: known_blocks[output.block_id] ?? 'Custom Transformation',
                        }, output);
                    }else{
                        return {
                            state: 'unknown',
                            result: {},
                            block_id: null,
                            block_title: 'Unknown Block',
                            end_date: null,
                            start_date: null,
                            transformation_id
                        };
                    }
                });
            },
            destinations(){
                // This method returns any sources or links that have coursework or grade permissions to act as a destination.
                return this.integration?.links?.filter(link => {
                    return link.permissions.some(permission => {
                        // TODO This isn't real - replace these with real permissions.
                        return permission.scope === 'coursework' || permission.scope === 'grades' || true;
                    });
                });
            }
        },
        methods: {
            progress(job){
                if(job.progress){
                    return ['working', 'complete', 'error', 'canceled', 'skipped'].reduce((count, state) => count + job.progress[state], 0);
                }
            },
            tasks(job){
                if(job.progress){
                    return ['queued', 'working', 'complete', 'error', 'canceled', 'skipped'].reduce((count, state) => count + job.progress[state], 0);
                }
            },
            complete(job){
                const tasks = this.tasks(job);

                if(tasks){
                    return Math.round(this.progress(job) / tasks * 100) + '%';
                }else{
                    return '0%';
                }
            },
            icon(provider_id){
                return this.providers[provider_id] ? this.providers[provider_id].icon_url : '';
            },
            name(provider_id){
                return this.providers[provider_id] ? this.providers[provider_id].name : '';
            },
            state_icon(state){
                switch(state){
                    case 'succeeded':
                    case 'complete':
                        return CheckCircle;
                    case 'working':
                        return RefreshCircular;
                    case 'error':
                        return WarningTriangle;
                    case 'unknown':
                        return HelpCircle;
                    case 'draft':
                        return BookmarkCircle;
                    default:
                        return HelpCircle;
                }
            },
            open(type, entity, details){
                // Add some additional properties to the entity object.
                Vue.set(entity, 'integration', this.integration);
                Vue.set(entity, 'source', this.integration.source);

                let props = { details };

                if(type === 'enrichments'){
                    props = entity;
                }

                this.$store.dispatch('entities/activate', {
                    id: entity.id,
                    type,
                    integration: this.integration
                })
                .then(() => {
                    this.$store.dispatch('drawer/open', {
                        key: `${type}/${entity.id}`,
                        width: 800,
                        component: components.hasOwnProperty(type) ? components[type] : Entity,
                        props
                    });
                });
            }
        },
        data(){
            return {
                date: null,
                requests: {
                    ticks: d3.timeHour.every(1),
                    granularity: d3.timeHour,
                    // Two days ago.
                    start: new Date(now - 48 * 60 * 60 * 1000),
                    end: now,
                    height: 50,
                    header: {
                        visible: false
                    },
                    timestamp: {
                        visible: 'always',
                        format: 'LT',
                    },
                    delta: {
                        visible: 'sometimes'
                    },
                    series: [{
                        title: 'API Requests',
                        id: 'requests-total',
                        plot: 'bar',
                        color: '#006aff',
                        legend: 'sum',
                        data: []
                    }]
                },
                materializations: {
                    loading: true,
                    latest: null,
                    successful: null
                },
                enrichments: {
                    loading: true,
                    latest: null,
                    successful: null
                },
                syncs: {
                    loading: true,
                    latest: null,
                    successful: null
                },
                flows: {
                    loading: true,
                    latest: null,
                    successful: null
                },
                jobs: {
                    source: {
                        loading: true,
                        successful: null,
                        all: []
                    },
                    destination: {
                        loading: true,
                        successful: null,
                        all: []
                    },
                    links: {}
                }
            }
        },
        created(){
            const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

            for(const type of ['materializations', 'enrichments', 'syncs']){
                this.$http.get(`${url}/api/v2/graph/${type}`, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    },
                    params: {
                        $last: 1
                    }
                })
                .then(response => {
                    // If the most recent materialization is any status other than "complete",
                    // we need to make another call to load the most recent complete materialization.
                    if(response.$data.length === 0){
                        return;
                    }

                    this[type].latest = response.$data[0];

                    if(this[type].latest.state !== 'complete'){
                        return this.$http.get(`${url}/api/v2/graph/${type}`, {
                            headers: {
                                Authorization: `Bearer ${this.integration.access_token}`
                            },
                            params: {
                                $last: 1,
                                $filter: {
                                    state: [{ operator: 'equals', value: 'complete' }]
                                }
                            }
                        })
                        .then(response => {
                            this[type].successful = response.$data[0];
                        });
                    }else{
                        this[type].successful = this[type].latest;
                    }
                })
                .finally(() => {
                    this[type].loading = false;
                });
            }

            this.$http.get(`${url}/api/v2/graph/jobs`, {
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                },
                params: {
                    $last: 3,
                    $filter: {
                        // state: [{ operator: 'in', value: ['queued', 'working'].join(',') }],
                        target_id: [{ operator: 'equals', value: this.integration.source.id }]
                    }
                }
            })
            .then(response => {
                this.jobs.source.all = response.$data;

                for(const job of this.jobs.source.all){
                    if(!['queued', 'draft'].includes(job.state)){
                        // Make an additional API request to get the job summary / progress.
                        this.$http.get(`${url}/api/v2/graph/jobs/${job.id}/progress`, {
                            headers: {
                                Authorization: `Bearer ${this.integration.access_token}`
                            }
                        })
                        .then(response => Vue.set(job, 'progress', response.$data));
                    }
                }
            })
            .finally(() => this.jobs.source.loading = false);

            // We need to do a few special queries for Flow integrations, specifically.
            if(this.integration.application.id === '979dde54-832a-4814-ad50-4b30d23f23c7'){
                this.$http.get(`${url}/api/v2/graph/jobs`, {
                    headers: {
                        Authorization: `Bearer ${this.integration.access_token}`
                    },
                    params: {
                        $last: 3,
                        $filter: {
                            // state: [{ operator: 'in', value: ['queued', 'working'].join(',') }],
                            target_id: [{ operator: 'equals', value: this.integration.destination.id }]
                        }
                    }
                })
                .then(response => {
                    this.jobs.destination.all = response.$data;

                    for(const job of this.jobs.destination.all){
                        if(!['queued', 'draft'].includes(job.state)){
                            // Make an additional API request to get the job summary / progress.
                            this.$http.get(`${url}/api/v2/graph/jobs/${job.id}/progress`, {
                                headers: {
                                    Authorization: `Bearer ${this.integration.access_token}`
                                }
                            })
                            .then(response => Vue.set(job, 'progress', response.$data));
                        }
                    }
                })
                .finally(() => this.jobs.destination.loading = false);
            }else{
                for(const destination of this.destinations){
                    Vue.set(this.jobs.links, destination.id, {
                        loading: true,
                        successful: null,
                        all: []
                    });

                    this.$http.get(`${url}/api/v2/graph/jobs`, {
                        headers: {
                            Authorization: `Bearer ${this.integration.access_token}`
                        },
                        params: {
                            $last: 3,
                            $filter: {
                                target_id: [{ operator: 'equals', value: destination.id }]
                            }
                        }
                    })
                    .then(response => {
                        this.jobs.links[destination.id].all = response.$data;

                        for(const job of this.jobs.links[destination.id].all){
                            if(!['queued', 'draft'].includes(job.state)){
                                // Make an additional API request to get the job summary / progress.
                                this.$http.get(`${url}/api/v2/graph/jobs/${job.id}/progress`, {
                                    headers: {
                                        Authorization: `Bearer ${this.integration.access_token}`
                                    }
                                })
                                .then(response => Vue.set(job, 'progress', response.$data));
                            }
                        }
                    })
                    .finally(() => this.jobs.source.loading = false);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    @keyframes shift {
        0% {
            left: -100%;
        }
        
        15% {
            left: 0;
        }

        30% {
            left: 100%;
        }

        100% {
            left: 100%;
        }
    }

    @keyframes pulse-blue {
        0% {
            box-shadow: 0 0 0 fade(@base, 0%);
            border-color: @d;
        }

        15% {
            box-shadow: 0 0 5px fade(@base, 20%);
            border-color: mix(@d, @base, 70%);
        }

        30% {
            box-shadow: 0 0 0 fade(@base, 0%);
            border-color: @d;
        }

        100% {
            box-shadow: 0 0 0 fade(@base, 0%);
            border-color: @d;
        }
    }

    @keyframes pulse-red {
        0% {
            box-shadow: 0 0 0 fade(@red, 0%);
            border-color: @d;
        }

        15% {
            box-shadow: 0 0 5px fade(@red, 20%);
            border-color: mix(@d, @red, 70%);
        }

        30% {
            box-shadow: 0 0 0 fade(@red, 0%);
            border-color: @d;
        }

        100% {
            box-shadow: 0 0 0 fade(@red, 0%);
            border-color: @d;
        }
    }

    @phase-icon-size: 32px;

    .integration-data-flow
    {
        padding: 40px;
        align-items: flex-start;
        background: url('~@/assets/icons/grey/dot.svg') 8px 8px repeat @f;
        background-size: 12px 12px;
    }

    .phase.card
    {
        box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.1);
        border-radius: @border-radius;
        border-color: @d;
        border-bottom-color: #d4d4d4;

        + .phase.card
        {
            margin-top: 10px;
        }

        .phase-header
        {
            padding: @single-padding;
        }

        .phase-icon
        {
            width: @phase-icon-size;
            height: @phase-icon-size;
            margin-right: @single-padding;
            
            img
            {
                width: @phase-icon-size;
                height: @phase-icon-size;
                border-radius: @border-radius;
                box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.2);
                z-index: 2;
            }
        }

        .phase-title
        {
            color: @black;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 2px;
        }

        .phase-subtitle
        {
            color: @grey;
            font-size: 11px;
            line-height: 12px;
        }

        .primary-source
        {
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }

        .enrichment-source
        {
            padding: 6px 0 6px @single-padding - 2px;

            .phase-icon
            {
                width: @phase-icon-size / 2;
                height: @phase-icon-size / 2;
                margin-right: @phase-icon-size / 4 + @single-padding;
                
                img
                {
                    width: @phase-icon-size / 2;
                    height: @phase-icon-size / 2;
                    border-radius: @border-radius - 2px;
                    box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.2);
                }

                &::after
                {
                    content: '';
                    width: 0;
                    height: 20px;
                    position: absolute;
                    top: -22px;
                    left: @phase-icon-size / 4 - 1;
                    z-index: 1;
                    border-left: 2px dotted @grey;
                }
            }

            .phase-title
            {
                color: @black;
                font-size: 12px;
                line-height: 14px;
            }

            .phase-subtitle
            {
                color: @grey;
                font-size: 10px;
                line-height: 12px;
            }

            &:last-child
            {
                padding-bottom: 0;
            }

            &.connect-enrichment-source
            {
                .phase-icon
                {
                    background: @base;
                    border-radius: @border-radius - 2px;
                    color: @f;
                }

                .phase-title
                {
                    color: @base;
                    margin-bottom: 0;
                }

                &.developer
                {
                    .phase-icon
                    {
                        background: @grey;
                    }

                    .phase-title
                    {
                        color: @grey;
                    }
                }
            }
        }
    }

    .phase-blocks
    {
        padding: @single-padding;
        border-top: 1px solid @e4;
        background-color: @f8;

        .phase-blocks-header
        {
            color: @black;
            background: @f8;
            z-index: 2;
            margin: 0 0 8px;
            padding: 4px 10px 0 8px;

            .icon
            {
                margin-right: 18px;
            }

            &:last-child
            {
                margin-bottom: 4px;
            }
        }

        h5
        {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
        }

        .phase-block
        {
            margin-top: 2px;
            padding: 4px 8px;
            line-height: 20px;
            border-radius: @border-radius;
            cursor: pointer;

            &:hover, &:active
            {
                background-color: @e4;

                .phase-block-icon, .phase-block-title
                {
                    color: @black;
                }
            }

            .phase-block-icon
            {
                margin-right: 18px;
                color: @grey;
            }

            .phase-block-title
            {
                color: @grey;
                font-size: 12px;
                line-height: 20px;
            }

            .phase-block-output
            {
                color: @a;
                font-size: 11px;

                .icon
                {
                    margin-left: 5px;
                }
            }

            &.job
            {
                padding: 4px 8px;
                height: 28px;

                .job-progress
                {
                    height: 4px;
                    border-radius: 2px;
                    background-color: @d;
                    width: 60px;

                    .job-progress-bar
                    {
                        height: 100%;
                        background-color: @base;
                    }
                }

                .phase-block-title
                {
                    margin-right: 10px;
                }

                .job-tasks
                {
                    color: @grey;
                    font-size: 11px;
                    margin-right: @single-padding;
                }
            }
        }
    }

    .phase-blocks-loading
    {
        padding: 20px 0;
    }

    .phase-activity
    {
        padding: 0 @single-padding 4px;

        .graph::v-deep
        {
            height: 60px;

            .timeline
            {
                padding-top: 2px;
                font-size: 10px;
            }
        }
    }

    .connection
    {
        margin: 20px 0;
        overflow: hidden;

        &::before, &::after
        {
            content: '';
            height: 1px;
            background-color: @d;
            position: absolute;
            top: 13px;
            left: 0;
            width: 100%;
        }

        &::after
        {
            z-index: 3;
            background: linear-gradient(90deg, fade(@base, 0%) 0%, @base 50%, fade(@base, 0%) 100%);
            animation: shift 4s infinite linear;
        }

        &.error
        {
            .permissions
            {
                color: @red;
                animation: pulse-red 4s infinite linear;
            }

            &::after
            {
                background: linear-gradient(90deg, fade(@base, 0%) 0%, @red 50%, fade(@red, 0%) 100%);
            }
        }

        .permissions
        {
            z-index: 4;
            background-color: @f;
            color: @base;
            padding: 4px 6px 4px 7px;
            line-height: 15px;
            font-size: 12px;
            font-weight: 500;
            border-radius: @border-radius;
            border: 1px solid @d;
            animation: pulse-blue 4s infinite linear;

            .icon
            {
                margin-left: 6px;
            }
        }

        .permissions-feature
        {
            font-size: 11px;
            color: @grey;
            line-height: 14px;
            margin-top: 10px;
            border-radius: @border-radius - 2px;
            padding: 2px 5px;
            background: @e4;
            cursor: pointer;

            .icon
            {
                margin-right: 4px;
            }
        }
    }
</style>
