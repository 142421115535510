<template>
    <div class="help-video">
        <h4 class="center">Help Video</h4>
        <div class="video-container" v-if="video">
            <iframe :src="src" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HelpVideo',
        props: {
            video: String
        },
        computed: {
            src(){
                return `${window.location.protocol}//www.youtube.com/embed/${this.video}?rel=0&autoplay=1`;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    h4
    {
        font-weight: 500;
        padding: 10px;
        font-size: 14px;
        color: @grey;
    }

    .video-container
    {
        padding: 0 10px 10px;
        height: 400px;

        iframe
        {
            width: 100%;
            height: 100%;
        }
    }
</style>
