<template>
    <div class="team-navigation">
        <div class="sidebar-group">
            <div class="sidebar-header">Admins</div>
            <router-link class="destination flex flex-align" to="/admin/overview">
                <StatsUpSquare class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Overview</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/teams">
                <TwitterVerifiedBadge class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Teams</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/sources">
                <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Sources</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/applications">
                <GitMerge class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Applications</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/integrations">
                <Bolt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Integrations</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/people">
                <UserCircle class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">People</div>
            </router-link>
        </div>
        <div class="sidebar-group">
            <div class="sidebar-header">Billing</div>
            <router-link class="destination flex flex-align" to="/admin/billing/invoices">
                <PageFlip class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Invoices</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/billing/plans">
                <Dollar class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Plans</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/billing/contracts">
                <DesignNib class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Contracts</div>
            </router-link>
        </div>
        <div class="sidebar-group">
            <div class="sidebar-header">Developers</div>
            <router-link class="destination flex flex-align" to="/admin/sync">
                <NetworkLeft class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Sync Pipeline</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/failures">
                <WarningTriangle class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Failures</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/flow">
                <SeaWaves class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Flow</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/queue">
                <Wrench class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Queue</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/admin/blink">
                <EyeClose class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Blink</div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { MapsArrowDiagonal, StatsUpSquare, City, Bank, GitMerge, Code, SeaWaves, EyeClose, Wrench, WarningTriangle, NetworkLeft, UserCircle, Bolt, CloudSync, TwitterVerifiedBadge, PageFlip, Dollar, CreditCards, DesignNib } from '@epiphany/iconoir';

    export default {
        name: 'AdminNavigation',
        components: {
            UserCircle,
            MapsArrowDiagonal,
            GitMerge,
            Code,
            StatsUpSquare,
            City,
            Bank,
            SeaWaves,
            Wrench,
            EyeClose,
            WarningTriangle,
            NetworkLeft,
            Bolt,
            CloudSync,
            TwitterVerifiedBadge,
            PageFlip,
            Dollar,
            CreditCards,
            DesignNib
        },
        methods: {
            
        },
        computed: {
            team() {
                return this.$store.getters.team;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .documentation-navigation
    {
        width: 100%;
    }
</style>
