<template>
<div class="button" :class="classes" :title="title" @click="() => didClick()">
    <template v-if="loading">
        <div class="spinner-container">
            <Spinner style="{ color }" />
        </div>
    </template>
    <div class="button-content" :class="{ loading }">
        <slot name="icon" />
        <slot name="text">
            {{ text }}
        </slot>
    </div>
</div>
</template>
<script>
    import Spinner from '@/components/Spinner.vue'

    export default {
        name: 'Button',
        components: {
            Spinner
        },
        props: {
            color: {
                type: String,
                default: 'currecntColor'
            },
            text: String,
            title: String,
            loading: Boolean,
            onClick: Function
        },
        methods: {
            didClick() {
                if (this.loading) {
                    return;
                }

                if (this.onClick) {
                    this.onClick();
                }

                this.$emit('click');
            }
        },
        computed: {
            classes() {
                return {
                    loading: this.loading,
                    'has-icon': this.hasIcon
                }
            },
            hasIcon() {
                return !!this.$slots.icon
            }
        }
    }
</script>

<style scoped lang="less">
.button {
    .button-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.loading {
            color: transparent;
        }
    }

    &.loading {
        pointer-events: none;
        
    }

    .spinner-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;

        ::v-deep .spinner {
            color: currentColor;

            .rotation {
                .path {
                    stroke: currentColor;
                }
            }
        }
    }
}
</style>