<template>
    <div class="account flex flex-align" :class="[{disabled, error}, size]">
        <template v-if="false">
            <div class="profile-picture"></div>
            <div class="ff">
                sadfadsf
            </div>
            <div class="text-button red">Remove</div>
        </template>
        <template v-else>
            <div class="source-icon"></div>
            <div class="ff source-name">Connect Account</div>
            <div class="text-button">Connect</div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Account',
        props: {
            source: Object,
            error: Boolean,
            disabled: Boolean,
            size: String
        },
        methods: {
            update($event){
                this.$emit('update:checked', $event.target.checked);
                this.$emit('input', $event.target.checked);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .account
    {
        border: 1px solid @e4;
        border-radius: 3px;
        width: 100%;
        max-width: 320px;
        padding: 10px 15px 10px 10px;
        height: 54px;

        &.large
        {
            height: auto;
            padding: 15px 20px 15px 15px;
            max-width: none;

            .profile-picture, .source-icon
            {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: @e;
                margin-right: 15px;
            }
        }
    }

    .profile-picture, .source-icon
    {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: @e;
        margin-right: 10px;

        img
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    .source-name
    {
        color: @black;
        font-size: 14px;
        font-weight: 500;
    }
</style>
