import { render, staticRenderFns } from "./AlignLeftBox.vue?vue&type=template&id=32a7a7d8"
import script from "./AlignLeftBox.vue?vue&type=script&lang=js"
export * from "./AlignLeftBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports