<template>
    <main v-if="source">
        <breadcrumbs>
            <template v-slot:crumbs>
                <router-link :to="{name: 'team.sources'}" class="breadcrumb flex flex-align">
                    <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                    Sources
                </router-link>
                <div class="breadcrumb">{{source.name}}</div>
            </template>
            <template v-slot:actions>
                <div class="flex flex-align state pointer" @click="$modal.show('source-status')">
                    <div class="standalone icon-status" :class="status"></div>
                    <div>{{source.status}}</div>
                </div>
            </template>
        </breadcrumbs>
        <div class="source full">
            <router-view />
        </div>
        <modal name="source-status" :width="420" height="auto" classes="modal">
            <div class="source-status flex flex-column">
                <div class="flex flex-align create-status-options">
                    <h2 class="ff">Change Source Status</h2>
                    <div class="text-button" @click="$modal.hide('source-status')">Cancel</div>
                </div>
                <div class="source-status-message alert center" v-if="source.status === 'error'">
                    The configuration for this source may be invalid. Please try to correct it before you reenable sync.
                </div>
                <div class="status-type flex" @click="update_status('active')" :class="{active: source.status === 'active'}">
                    <div class="status-type-header flex flex-align flex-center none">
                        <input class="checkbox-input" type="checkbox" :checked="source.status === 'active'" />
                    </div>
                    <div class="ff">
                        <div class="status-type-title">Active</div>
                        <div class="status-type-description">
                            This source will sync normally. Roster data access is available and data will update regularly. Users are able to sign in and utilize LMS integration.
                        </div>
                    </div>
                </div>
                <div class="status-type flex" @click="update_status('disabled')" :class="{active: source.status === 'disabled'}">
                    <div class="status-type-header flex flex-align flex-center none">
                        <input class="checkbox-input" type="checkbox" :checked="source.status === 'disabled'" />
                    </div>
                    <div class="ff">
                        <div class="status-type-title">Disabled</div>
                        <div class="status-type-description">
                            Users will still be able to access connected applications, but roster data will not be synced from the source. Changes to transforms will still be applied.
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </main>
</template>

<script>
    import { CloudSync } from '@epiphany/iconoir';

    export default {
        name: 'Source',
        components: {
            CloudSync
        },
        data(){
            return {
                loading: true
            };
        },
        computed: {
            source(){
                return this.$store.getters.source;
            },
            admin(){
                return this.user.admin;
            },
            user(){
                return this.$store.getters.user;
            },
            status(){
                return {
                    red: ['error', 'destroyed'].includes(this.source.status),
                    yellow: ['disabled'].includes(this.source.status),
                    green: ['active'].includes(this.source.status),
                    grey: ['requested'].includes(this.source.status),
                    purple: ['inactive', 'scheduled', 'pending'].includes(this.source.status)
                };
            }
        },
        created(){
            this.$store.dispatch('sources/activate', {
                source: this.$route.params.source,
                team: this.$store.getters.team.id
            })
            .then(() => this.loading = false)
            .catch(error => this.$toasted.error('There was an error loading the selected source.'));
        },
        destroyed(){
            this.$store.dispatch('sources/deactivate');
        },
        methods: {
            toggle(){
                //Toggle the status of this source to inactive
                switch(this.source.status){
                    case 'active':
                        return this.update_status('disabled');
                    case 'inactive':
                        return this.update_status('active');
                    case 'error':
                        //TODO Inform the user of what's going to happen via an options modal
                        return this.update_status('active');
                    case 'disabled':
                        return this.update_status('active');
                }
            },
            update_status(status){
                this.$store.dispatch('sources/status', status)
                    .then(() => this.$toasted.success(status === 'disabled' ? 'Source disabled.' : 'Source activated.'))
                    .catch(error => this.$toasted.error('There was an error loading this source.'));
            },
            change_state(){

            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .source
    {
        top: @breadcrumbs-height;
    }

    .state
    {
        margin-left: 20px;
    }

    .source-error
    {
        padding: 20px;
        margin-top: 30px;

        h2
        {
            color: @black;
        }

        .source-error-message
        {
            margin: 20px 0;
            font-size: 15px;
            color: @grey;
            line-height: 20px;
        }

        .button
        {
            margin-right: 20px;
        }
    }

    .enrichment-status
    {
        margin: 2px 0 0 2px;
    }

    .state
    {
        margin-left: 20px;
    }

    .source-status
    {
        padding: 15px 15px 15px;

        h2
        {
            margin: 0;
            color: @black;
            font-size: 15px;
        }
    }

    .status-type
    {
        border: 1px solid @d;
        border-radius: 3px;
        text-transform: none;
        margin: 0;
        cursor: pointer;
        margin-top: 15px;

        .ff
        {
            padding: 15px;
        }

        .status-type-header
        {
            border-right: 1px solid @d;
            padding: 10px;
            background: @f8;
        }

        .status-type-title
        {
            font-size: 14px;
            font-weight: 500;
            color: @black;
            margin-bottom: 6px;
        }

        .status-type-description
        {
            color: @black;
            font-size: 12px;
            line-height: 16px;
        }

        &.active
        {
            border-color: @base;

            .status-type-header
            {
                border-right: 1px solid @base;
                background-color: fade(@base, 10%);
            }

            .status-type-title
            {
                color: @base;
            }
        }

        input.checkbox-input
        {
            background: @d;
            margin: 0;
            border: 0;
            transition: background-color ease 0.2s;

            &::after
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                background: url('~@/assets/icons/white/check.svg') 1px 3px no-repeat;
                background-size: 18px auto;
                transform: scale(0);
                transition: all ease 0.15s;
                opacity: 0;
            }

            &:checked
            {
                background: @base;

                &::after
                {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }

    .source-status-message
    {
        margin-top: 15px;
        font-size: 12px;
        line-height: 16px;
        padding: 15px;
        border-color: @red;
        color: @red;
        background-color: fade(@red, 20%);
    }
</style>
