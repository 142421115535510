import { render, staticRenderFns } from "./EntityPreview.vue?vue&type=template&id=8c38ef16&scoped=true"
import script from "./EntityPreview.vue?vue&type=script&lang=js"
export * from "./EntityPreview.vue?vue&type=script&lang=js"
import style0 from "./EntityPreview.vue?vue&type=style&index=0&id=8c38ef16&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c38ef16",
  null
  
)

export default component.exports