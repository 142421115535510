<template>
    <div class="team-member-name flex flex-align">
        <div>{{ name }}</div>
        <TwoFAEnabled :enabled="two_factor_enabled" v-tooltip.top="(first_name ? first_name : 'This user') + ' has two-factor authentication enabled on their account'" />
        <EdlinkStaff :enabled="admin" v-tooltip.top="(first_name ? first_name : 'This user') + ' is a member of the Edlink staff'" />
    </div>
</template>

<script>
    import TwoFAEnabled from '@/components/chips/TwoFAEnabled.vue';
    import EdlinkStaff from '@/components/chips/EdlinkStaff.vue';

    export default {
        name: 'TeamMemberName',
        components: {
            TwoFAEnabled,
            EdlinkStaff
        },
        props: {
            first_name: String,
            last_name: String,
            two_factor_enabled: Boolean,
            admin: Boolean
        },
        data(){
            return {};
        },
        computed: {
            name() {
                const first = this.first_name ? this.first_name : '';
                const last = this.last_name ? this.last_name : '';

                if(!first && !last){
                    return 'Anonymous User';
                }

                return (first + ' ' + last).trim();
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .chip
    {
        margin-left: @single-padding;
    }
</style>
