<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <CloudError class="icon" width="16" height="16" stroke-width="2" />
                    Recent Job Failures
                </div>
            </template>
            <template v-slot:actions>
            <div class="button" @click="load">Reload Jobs</div>
            </template>
        </breadcrumbs>
        <filters>
            <nav class="flex flex-align" style="z-index: 10;">
                <div class="pill" :class="{active: page === 'syncs'}" @click="changePage('syncs')">Syncs</div>
                <div class="pill" :class="{active: page === 'enrichments'}" @click="changePage('enrichments')">Enrichments</div>
                <div class="pill" :class="{active: page === 'materializations'}" @click="changePage('materializations')">Materializations</div>
            </nav>
        </filters>
        <div class="failures full">
            <datatable class="full" :columns="columns" :rows="all[page]" :selectable="true" :header="true" @clicked="job => show_job_details(job)" :clickable="true" />
        </div>

    </main>
</template>

<script>
    import moment from 'moment';
    import mdf from 'moment-duration-format';
    import Prism from 'prismjs';
    import Sync from '@/components/drawers/Sync.vue';
    import Enrichment from '@/components/drawers/Enrichment.vue';
    import Materialization from '@/components/drawers/Materialization.vue';
    import { CloudError, AlignLeft, WhiteFlag, Wrench, Calendar } from '@epiphany/iconoir';
    import { trimEnd } from 'lodash'

    mdf(moment);

    export default {
        name: 'AdminFailures',
        components: {
            CloudError
        },
        data(){
            return {
                details: null,
                page: 'syncs',
                pages: {
                    syncs: Sync,
                    enrichments: Enrichment,
                    materializations: Materialization
                },
                all: {
                    syncs: [],
                    enrichments: [],
                    materializations: []
                },
                search: '',
                results: [],
                show_json: false,
                requests: {
                    title: 'API Requests',
                    height: 250,
                    series: []
                },
                interval: null,
                loading: false,
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '50%',
                        value: row => row.source.name
                    },
                    {
                        id: 'team',
                        name: 'Team Name',
                        icon: WhiteFlag,
                        width: '50%',
                        value: row => row.team.name
                    },
                    {
                        id: 'heartbeat_date',
                        name: 'Last Heartbeat Date',
                        icon: Wrench,
                        width: '30%',
                        value: row => this.$options.filters.pretty(row.heartbeat_date, 'short')
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '30%',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    }
                ]
            };
        },
        created(){
            this.load();

            // this.interval = setInterval(() => this.load(), 4000);
        },
        destroyed(){
            // clearInterval(this.interval);
        },
        methods: {
            load(type) {
                this.loading = true;
                this.$http.get(`/admin/pipeline/failures/${type ?? this.page}`)
                .then((response) => {
                    this.all[this.page] = response.$data
                }).finally(() => {
                    this.loading = false;
                });
            },
            elapsed(job){
                return moment.duration(moment().diff(job.created_date)).format('hh:mm:ss', {stopTrim: 'm'});
            },
            format(data){
                try {
                    //Color and format the JSON that represents this user.
                    return Prism.highlight(JSON.stringify(data, null, 4), Prism.languages.json, 'json');
                }catch(error){
                    console.log(error);
                }
            },
            changePage(page){
                this.page = page;
                this.load(page);
            },
            show_job_details(job){
                this.$store.dispatch('drawer/open', {
                    key: `jobs/${job.id}`,
                    width: 800,
                    component: this.pages[this.page],
                    props: {
                        details: job,
                        type: trimEnd(this.page, 's')
                    }
                });
            },
            get_provider(provider_id){
                return this.$store.getters.provider(provider_id);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }

    .failures
    {
        top: @breadcrumbs-height + @filters-height;

        .datatable
        {
            width: 100%;
        }
    }

    .source-application
    {
        margin-left: 10px;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
    }

    .metrics
    {
        flex: 2;
    }

    .extra
    {
        margin: 20px 10px 0px 10px;

        h4
        {
            margin-bottom: 10px;
        }
    }

    .graph
    {
        border-bottom: 1px solid @e4;
        padding-bottom: 20px;
    }

    .pipeline-card:last-child
    {
        .pipeline-metadata
        {
            border-bottom: none;
        }
    }

    .pipeline-metadata
    {
        padding: 8px 10px;
        border-bottom: 1px solid @e4;
    }

    .job-title
    {
        color: @black;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
        line-height: 18px;

    }

    .job-district
    {
        color: @grey;
        font-size: 12px;
        line-height: 14px;
    }

    .options
    {
        font-size: 16px;
        cursor: pointer;
        margin-left: 10px;

        .icon
        {
            vertical-align: middle;
            color: @grey;
            margin-left: 6px;

            &:hover{
                opacity: 0.7;
            }
        }
    }

    .job-tag
    {
        margin-right: 5px;
        display: inline-block;
        border-radius: 3px;
        background: @grey;
        padding: 0 3px;
        line-height: 14px;
        font-size: 10px;
        color: @f;
        font-weight: 500;

        &.killed, &.error
        {
            background: @red;
        }

        &.queued
        {
            background: @base;
        }

        &.ready
        {
            background: @purple;
        }

        &.working, &.modified
        {
            background: @orange;
        }
    }

    .sub-text
    {
        color: @lightgrey;
        font-size: 12px;
    }

</style>
