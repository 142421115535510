import axios from 'axios';

const state = {
    sections: [],
    paths: {},
    manifest: null,
    team: null,
    application: null,
    token: null
};

const mutations = {
    initialize_sections(state, sections) {
        for (const section of sections) {
            state.sections.push(Object.assign({ expanded: false }, section));

            for (const { slug, title } of section.documents) {
                state.paths[`${section.slug}/${slug}`] = { section, slug, title };
            }
        }
    },
    save_manifest_promise(state, promise) {
        state.manifest = promise;
    },
    toggle(state, section) {
        section.expanded = !section.expanded;
    },
    application(state, application) {
        state.application = application;
    },
    logout(state) {
        state.token = null;
    },
    login(state, token) {
        state.token = token;
    }
};

const actions = {
    toggle({ commit }, section) {
        if (section) {
            commit('toggle', section);
        }
    },
    load({ commit, state }) {
        if (state.manifest) {
            return state.manifest;
        }

        const url = process.env.NODE_ENV === 'production' ? 'https://edlink.github.io/docs' : 'http://localhost:8082';

        const promise = axios.get(`${url}/manifest.json`);

        commit('save_manifest_promise', promise);

        promise.then((response) => {
            commit('initialize_sections', response.$data.sections);
        });

        return promise;
    },
    application({ commit }, application) {
        if (state.application && state.application.id === application.id) {
            return;
        }

        //Any user token that they have received will certainly be invalid now, so we will "log them out" as well.
        //We're just tossing away their LMS token, not their actual login token for Dashboard.
        commit('application', application);
        commit('logout');
    },
    login({ commit }, code) {
        return axios.post('/v1', { code }).then((response) => commit('login', response.$data.token));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
