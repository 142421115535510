<template>
    <div class="class-state-by-session center">
        <div class="mapping">
            <div class="header">
                <div class="row flex flex-align flex-center">
                    <div class="ff">Session</div>
                    <div class="ff">Class State</div>
                </div>
            </div>
            <div class="body">
                <div v-for="(map, index) of configuration.map" :key="index" class="row flex flex-align flex-center">
                    <div class="cell ff">
                        <search-select :values.sync="map.session_ids" :borderless="true" :format="format" :search="search" :array="true" :placeholder="'Select one or more sessions...'" />
                    </div>
                    <div class="cell">
                        <div class="becomes flex flex-align flex-center">
                            <span class="icon iconoir-arrow-right"></span>
                        </div>
                    </div>
                    <div class="cell ff">
                        <select class="borderless" v-model="map.class_state">
                            <option value="" hidden diabled>Select a state</option>
                            <option :value="null" hidden diabled>Select a state</option>
                            <option value="active">Active</option>
                            <option value="upcoming">Upcoming</option>
                            <option value="inactive">Inactive</option>
                            <option value="completed">Active</option>
                            <option value="archived">Archived</option>
                            <option value="template">Template</option>
                        </select>
                    </div>
                    <div class="icon-button muted small icon iconoir-trash" @click="remove(index)"></div>
                </div>
                <div>
                    <div class="flex flex-align options">
                        <div class="button" @click="add()">Add another</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchSelect from '@/components/SearchSelect.vue';
    import Vue from 'vue';
    import _ from 'lodash';

    export default {
        name: 'TransformClassStateBySession',
        props: {
            transformation: Object,
            source: Object,
            integration: Object,
            configuration: Object
        },
        components: {
            'search-select': SearchSelect
        },
        data(){
            return {
                session_ids: [],
                sessions: {}
            };
        },
        computed: {
            provider(){
                return this.source ? this.source.provider : this.integration.provider;
            },
            auth() {
                return `Bearer ${this.$store.getters.integration.access_token}`;
            }
        },
        methods: {
            add() {
                this.configuration.map.push({
                    session_ids: [],
                    class_state: ''
                });
            },
            remove(index) {
                if (this.configuration.map.length > 1) {
                    this.configuration.map.splice(index, 1);
                } else {
                    this.configuration.map[0].session_ids = [];
                    this.configuration.map[0].class_state = '';
                }
            },
            search(query){
                console.log(query)
                const config = {
                    headers: {
                        'Authorization': this.auth
                    }
                };
                if (query) {
                    config.params = {
                        $filter: {
                            name: [{ operator: 'contains', value: query }]
                        },
                        $fields: 'id,name'
                    };
                }
                return this.$http.get(`https://ed.link/api/v2/graph/sessions`, config)
                    .then(({ $data: sessions }) => {
                        for(const session of sessions){
                            Vue.set(this.sessions, session.id, session.name);
                        }

                        return sessions.map(s => s.id);
                    })
                    .catch(console.error)
            },
            format(id){
                return this.sessions[id];
            }
        },
        watch: {
            transformation: {
                immediate: true,
                handler() {
                    if (this.transformation.configuration.hasOwnProperty('map')) {
                        this.configuration = _.cloneDeep(this.transformation.configuration);
                    } else {
                        this.configuration = {
                            map: [
                                {
                                    session_ids: [],
                                    class_state: ''
                                }
                            ]
                        };
                    }
                }
            },
            configuration: {
                deep: true,
                handler() {
                    this.$emit('input', this.configuration);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

.class-state-by-session
{
    margin-top: -1px;
}

.headers
{
    padding: 15px 15px 0;
    font-size: 14px;
    color: @black;
    line-height: 18px;
    font-weight: 500;
}

.mapping
{
    // padding: 15px 15px 0;
    color: @black;

    .row
    {
        border-bottom: 1px solid @e4;
        padding: 10px 0px;

        &:hover
        {
            .icon-button.muted
            {
                color: @red
            }
        }
    }

    .header
    {
        font-size: 15px;
        .row
        {
            border-top: 1px solid @e4;
        }
    }
}

.options
{
    padding-top: 15px;
}

.becomes
{
    color: @grey;
    font-size: 13px;
    line-height: 18px;
    width: 50px;

    .icon
    {
        font-size: 22px;
        color: @black;
    }
}

.icon-button
{
    font-size: 28px;
    color: @base;
    cursor: pointer;
    transition: color linear 0.1s;

    &.small
    {
        font-size: 20px;
    }

    &.red
    {
        color: @red;

        &:hover
        {
            color: fade(@red, 70%);
        }
    }

    &:hover
    {
        color: fade(@base, 70%);
    }

    &.floating
    {
        position: absolute;
        right: -40px;
        top: 8px;
    }

    &.muted
    {
        color: @c;
        padding: 10px;
        transition: color linear 0.1s;
        &:hover
        {
            color: @red
        }
    }
}

.borderless
{
    border: 0;
}
</style>