<template>
    <div class="team-name flex flex-align">
        <div>{{ name }}</div>
        <Verified :verified="verified" />
    </div>
</template>

<script>
    import Verified from '@/components/chips/Verified.vue';

    export default {
        name: 'TeamName',
        components: {
            Verified
        },
        props: {
            name: String,
            verified: Boolean
        },
        data(){
            return {};
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    .team-name {
        > div:first-child {
            margin-right: @single-padding;
        }
    }
</style>
