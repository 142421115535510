<template>
    <div class="team-navigation">
        <div class="sidebar-group">
            <div class="sidebar-header">Technical</div>
            <router-link class="destination flex flex-align" to="/docs/introduction">
                <Map class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Introduction</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/docs/guides">
                <MapsArrowDiagonal class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Developer Guides</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/docs/api">
                <Code class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">API Reference</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/docs/changelog">
                <GitMerge class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Changelog</div>
            </router-link>
        </div>
        <div class="sidebar-group">
            <div class="sidebar-header">Other</div>
            <!-- <router-link class="destination flex flex-align" to="/docs/dashboard">
                <StatsUpSquare class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Edlink Dashboard</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/docs/schools">
                <City class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">School Districts</div>
            </router-link> -->
            <router-link class="destination flex flex-align" to="/docs/schools">
                <City class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">For Schools</div>
            </router-link>
            <router-link class="destination flex flex-align" to="/docs/legal">
                <Bank class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Legal Documents</div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { MapsArrowDiagonal, StatsUpSquare, City, Bank, Map, GitMerge, Code } from '@epiphany/iconoir';

    export default {
        name: 'DocumentationNavigation',
        components: {
            MapsArrowDiagonal,
            Map,
            GitMerge,
            Code,
            StatsUpSquare,
            City,
            Bank
        },
        methods: {
            
        },
        computed: {
            team() {
                return this.$store.getters.team;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .documentation-navigation
    {
        width: 100%;
    }
</style>
