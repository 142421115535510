<template>
    <main v-if="application">
        <breadcrumbs>
            <template v-slot:crumbs>
                <router-link :to="{name: 'team.applications'}" class="breadcrumb flex flex-align">
                    <Svg3dSelectSolid class="icon" width="16" height="16" stroke-width="2" />
                    Applications
                </router-link>
                <div class="breadcrumb">{{application.name}}</div>
            </template>
            <template v-slot:actions>
                <template v-if="$route.name === 'team.applications.application.feeds'">
                    <div class="button has-icon" @click="openSubscriberDrawer()">
                        <AddCircle class="icon" width="16" height="16" stroke-width="2" />
                        Create Subscriber
                    </div>
                </template>
                <template v-else>
                    <div class="button disabled" v-if="application.status === 'destroyed'">Application Destroyed</div>
                    <div v-else class="integration-link button has-icon base flex flex-align" v-clipboard="`https://ed.link/integrate/${application.id}`" v-clipboard:success="() => $toasted.success('Copied integration link to clipboard')">
                        <span class="icon iconoir-copy"></span>
                        <div class="ff">Copy Integration Link</div>
                        <!-- <div class="extras flex flex-align"><NavArrowDown class="icon" width="16" height="16" stroke-width="2" /></div> -->
                    </div>
                    <div class="button white create-sample-integration has-icon" @click="createSampleIntegration" v-if="user.admin">
                        <span class="icon iconoir-fingerprint-circled-lock"></span>
                        Create Sandbox Integration
                    </div>
                </template>
            </template>
        </breadcrumbs>
        <router-view class="full application" :key="application.id" />
    </main>
</template>

<script>
    import { Svg3dSelectSolid, NavArrowDown, AddCircle } from '@epiphany/iconoir';
    import CreateOnboarding from '@/components/drawers/CreateOnboarding';
    import CreateSampleIntegration from '@/components/drawers/CreateSampleIntegration';
    import SubscriberDrawer from '@/components/drawers/Subscriber';

    export default {
        name: 'Application',
        components: {
            Svg3dSelectSolid,
            NavArrowDown,
            AddCircle
        },
        data(){
            return {
                loading: true
            };
        },
        created(){
            this.load();
        },
        destroyed(){
            this.$store.dispatch('applications/deactivate');
        },
        computed: {
            application(){
                return this.$store.getters.application;
            },
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.getters.user;
            },
            admin(){
                return this.user?.admin;
            }
        },
        methods: {
            load(){
                const {application} = this.$route.params;

                this.$store.dispatch('applications/activate', {application, team: this.team.id})
                .then(() => this.loading = false)
                .catch(error => this.$toasted.error('There was an error loading this application.'));
            },
            createSampleIntegration(){
                this.$store.dispatch('drawer/open', { 
                    key: 'create-sample-integration',
                    component: CreateSampleIntegration,
                    width: 800,
                    props: {
                        application: this.application
                    }
                })
            },
            openSubscriberDrawer(subscriber) {
                this.$store.dispatch('drawer/open', {
                    key: 'subscriber-create-edit',
                    width: 550,
                    component: SubscriberDrawer,
                    props: {
                        subscriber,
                        refreshParent: () => {}
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .application
    {
        top: @breadcrumbs-height;
    }

    .integration-link {

        .extras {
            height: 100%;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            background-color: darken(@base, 10%);
            margin-left: 5px;
            margin-right: -9px;

            .icon {
                margin: 0;
            }

        }
    }
</style>