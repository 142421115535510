import { render, staticRenderFns } from "./SourceDataset.vue?vue&type=template&id=c534337c&scoped=true"
import script from "./SourceDataset.vue?vue&type=script&lang=js"
export * from "./SourceDataset.vue?vue&type=script&lang=js"
import style0 from "./SourceDataset.vue?vue&type=style&index=0&id=c534337c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c534337c",
  null
  
)

export default component.exports