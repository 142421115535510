<template>
    <div class="select">
        <h4 class="center">{{title}}</h4>
        <div class="options" v-if="options.length">
            <div class="option flex flex-align" @click="activate(option)" v-for="option of options" :key="option.id">
                <div class="option-name ff text-overflow">{{option.name}}</div>
                <div class="option-check" v-if="selected && option.id === selected.id"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Select',
        props: {
            title: String,
            options: Array,
            selected: Object,
            select: Function
        },
        methods: {
            activate(option){
                if(this.select){
                    this.select(option);
                }

                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .select
    {
        h4
        {
            font-weight: 500;
            padding: 10px;
            font-size: 14px;
            color: @grey;
        }

        .option
        {
            border-top: 1px solid @e;
            padding: 15px;
            cursor: pointer;
            transition: background-color ease 0.2s;

            &:hover
            {
                background: @f8;
            }
        }

        .option-name
        {
            font-size: 16px;
            color: @black;
            line-height: 20px;
        }

        .option-membership
        {
            color: @grey;
            line-height: 20px;
            text-transform: capitalize;
        }

        .option-check
        {
            margin-left: 15px;
            background: url('~@/assets/icons/white/check.svg') 1px 3px no-repeat @base;
            background-size: 18px auto;
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
    }
</style>
