const state = {
    expanded: false,
    loading: false
};

const mutations = {
    open() {
        // Set the loading state for UI purposes only.
        state.loading = true;

        // Load the widget.
        window.HubSpotConversations?.widget?.load({ widgetOpen: true });
    },
    close() {
        state.expanded = false;

        // Just in case we're still loading, set the loading state to false.
        state.loading = false;

        // Remove the widget because closing it is not enough.
        window.HubSpotConversations?.widget?.remove();
    },
    loaded() {
        state.loading = false;

        // Set the expanded state to true because the widget is now loaded.
        state.expanded = true;
    }
};

const actions = {
    open({ commit }) {
        commit('open');
    },
    close({ commit }) {
        commit('close');
    },
    toggle({ commit, state }) {
        state.expanded ? commit('close') : commit('open');
    },
    loaded({ commit }) {
        commit('loaded');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
