import axios from 'axios';

const state = {
    id: null,
    initialize: null,
    token: null,
    error: false,
    user_id: null,
    created_date: null,
    email: null,
    email_verified: false,
    first_name: null,
    last_name: null,
    phone: null,
    phone_verified: false,
    picture_url: null,
    two_factor_enabled: false,
    updated_date: null,
    admin: false,
    invitations: []
};

const mutations = {
    initialize(state, promise) {
        state.initialize = promise;
    },
    token(state, token) {
        state.initialize = null;
        state.token = token;
    },
    logout(state) {
        state.initialize = null;
        state.token = null;
        state.error = false;
        state.id = null;
        state.created_date = null;
        state.email = null;
        state.email_verified = false;
        state.first_name = null;
        state.last_name = null;
        state.phone = null;
        state.phone_verified = false;
        state.picture_url = null;
        state.two_factor_enabled = false;
        state.updated_date = null;
        state.admin = false;
    },
    data(state, data) {
        Object.assign(state, data);
    },
    update(state, { field, value }) {
        state[field] = value;
    },
    error(state) {
        state.error = true;
    },
    invitations(state, invitations) {
        state.invitations = invitations;
    }
};

const actions = {
    token({ commit }, token) {
        // Save the token in our store, but don't save it to local storage yet.
        commit('token', token);
    },
    login({ commit, dispatch, state }, token = state.token) {
        localStorage.setItem('edlink-user-token', token);
        commit('token', token);

        return dispatch('load')
            .then(() => dispatch('teams/clear', null, { root: true }))
            .then(() => dispatch('teams/load', null, { root: true }));
    },
    logout({ commit, dispatch }) {
        localStorage.removeItem('edlink-user-token');
        commit('logout');
        return dispatch('teams/clear', null, { root: true });
    },
    async load({ commit, dispatch, state }) {
        try {
            const response = await axios.get('/me');

            commit('data', response.$data);

            // This is intentionally awaited because of a fun edge case in the onboarding flow.
            await dispatch('invitations');

            // If the user is an admin, we should load the command palette.
            if (state.admin) {
                dispatch('palette/initialize', null, { root: true });
            }

            // If their email address is not validated, we should show them a banner (for now).
            // Eventually, we probably want to log them out or something.
            if (state?.id && !state.email_verified) {
                const that = this;

                dispatch(
                    'banners/show',
                    {
                        id: 'email-verification',
                        theme: 'black',
                        type: 'system',
                        height: 48,
                        description: 'Please verify your email address to continue using Edlink.',
                        icon: 'iconoir-shield-alert',
                        buttons: [
                            {
                                text: 'Resend Verification Email',
                                icon: 'iconoir-send-mail',
                                props: {
                                    class: 'button white has-icon'
                                },
                                action() {
                                    axios
                                        .get('/verify_email')
                                        .then(() => {
                                            that._vm.$toasted.success('Verification email sent.');
                                        })
                                        .catch(() => {
                                            that._vm.$toasted.error('There was an error sending the verification email.');
                                        });
                                }
                            }
                        ]
                    },
                    { root: true }
                );
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                //This is an invalid token and we should log the user out
                dispatch('logout');
            } else {
                commit('error');
            }
        }
    },
    invitations({ commit }) {
        return axios
            .get('/invitations')
            .then((response) => {
                commit(
                    'invitations',
                    response.$data.map((invite) => Object.assign({ attempting: false, accepted: false, rejected: false }, invite))
                );
            })
            .catch(() => {});
    },
    update({ commit, state }, { field, value }) {
        commit('update', { field, value });
    },
    initialize({ commit, dispatch }) {
        if (state.initialize) {
            return state.initialize;
        }

        const token = localStorage.getItem('edlink-user-token');

        if (token) {
            const promise = dispatch('login', token);

            commit('initialize', promise);

            return promise;
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
