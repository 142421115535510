<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <router-link :to="{ name: 'team.sources' }" class="breadcrumb flex flex-align">
                    <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                    Sources
                </router-link>
                <div class="breadcrumb">Create Source</div>
            </template>
        </breadcrumbs>
        <div class="full create-source scroll">
            <div class="constrain bc">
                <div class="create-source-region-select">
                    <h1 class="ff">Select a Storage Region</h1>
                    <div class="helptext ff">
                        Edlink allows you to choose where your data will be stored in order to comply with local laws and institutional policies.
                        This decision will not affect API performance.
                    </div>
                    <div class="flex flex-wrap region-options">
                        <div class="card region-option flex flex-column flex-center" @click="selectRegion('8c5475f1-32d3-479d-8a6a-3c6b6e524f49')" :class="{ selected: source.region_id === '8c5475f1-32d3-479d-8a6a-3c6b6e524f49' }">
                            <div class="flag-icon flex flex-align flex-center">
                                <div class="flag-icon-wrapper">
                                    <img src="/flags/us.svg" />
                                </div>
                            </div>
                            <div class="region-name text-overflow center">United States</div>
                        </div>
                        <div class="card region-option flex flex-column flex-center" @click="selectRegion('2a5d4855-bd8d-4f4f-bd66-5180430c0ebd')" :class="{ selected: source.region_id === '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd' }">
                            <div class="flag-icon flex flex-align flex-center">
                                <div class="flag-icon-wrapper">
                                    <img src="/flags/ca.svg" />
                                </div>
                            </div>
                            <div class="region-name text-overflow center">Canada</div>
                        </div>
                        <div class="card region-option flex flex-column flex-center" @click="selectRegion('2572d321-6d8b-458b-8024-8e5ab6bad7b6')" :class="{ selected: source.region_id === '2572d321-6d8b-458b-8024-8e5ab6bad7b6' }">
                            <div class="flag-icon flex flex-align flex-center">
                                <div class="flag-icon-wrapper">
                                    <img src="/flags/de.svg" />
                                </div>
                            </div>
                            <div class="region-name text-overflow center">Germany</div>
                        </div>
                        <div class="card region-option flex flex-column flex-center" @click="selectRegion('2104f649-fe39-4fa1-96be-8682e9840dcf')" :class="{ selected: source.region_id === '2104f649-fe39-4fa1-96be-8682e9840dcf' }">
                            <div class="flag-icon flex flex-align flex-center">
                                <div class="flag-icon-wrapper">
                                    <img src="/flags/au.svg" />
                                </div>
                            </div>
                            <div class="region-name text-overflow center">Australia</div>
                        </div>
                    </div>
                </div>
                <h1>Select a Data Provider</h1>
                <div class="helptext ff">
                    Edlink allows you to connect a number of different LMS, SIS, and SSO providers.
                </div>
                <nav class="flex flex-align">
                    <div class="pill" :class="{ active: category === 'lms' }" @click="filter('lms')">
                        <MultiWindow class="icon" width="16" height="16" stroke-width="2" />
                        LMS Providers
                    </div>
                    <div class="pill" :class="{ active: category === 'sis' }" @click="filter('sis')">
                        <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                        SIS Providers
                    </div>
                    <div class="pill" :class="{ active: category === 'sso' }" @click="filter('sso')">
                        <PeopleTag class="icon" width="16" height="16" stroke-width="2" />
                        SSO Providers
                    </div>
                </nav>
                <div class="flex flex-wrap source-options">
                    <div class="card source-option flex flex-column flex-center" v-for="provider of filtered" :key="provider.id" :class="{ selected: selected === provider }" @click="select(provider)">
                        <div class="source-icon flex flex-align flex-center">
                            <div class="source-icon-wrapper">
                                <img :src="provider.icon_url" />
                            </div>
                        </div>
                        <div class="source-name text-overflow center">{{ provider.name }}</div>
                    </div>
                    <div class="source-option placeholder none" v-for="i of [1, 2, 3, 4, 5]" :key="i"></div>
                </div>
                <template v-if="selected">
                    <h1>Configuration Your Selected Provider</h1>
                    <form class="source-configuration" @submit.prevent="create">
                        <div class="form-field">
                            <label :class="{ help: selected.help.nickname }" @click="watch(selected.help.nickname)">Source Nickname</label>
                            <input type="text" v-model="source.name" class="large" @focus="focus('nickname')" @blur="blur('nickname')" />
                        </div>
                        <div class="form-field" v-for="(configuration, property) of selected.configuration" :key="property">
                            <label :class="{ help: selected.help[property] }" @click="watch(selected.help[property])">{{ configuration.title }}</label>
                            <div class="form-helptext" v-if="configuration.description">{{ configuration.description }}</div>
                            <template v-if="configuration.type === 'string'">
                                <select v-if="configuration.enum" :class="configuration.size" v-model="source.configuration[property]">
                                    <option v-for="option of configuration.enum" :key="option.value" :value="option.value">{{ option.title }}</option>
                                </select>
                                <select :class="configuration.size" v-model="source.configuration[property]" v-else-if="property === 'timezone'">
                                    <option value="">Select a Timezone</option>
                                    <option v-for="timezone in timezones" :value="timezone" :key="timezone">{{ timezone }}</option>
                                </select>
                                <template v-else>
                                    <textarea v-if="configuration.size === 'textarea'" v-model="source.configuration[property]" :disabled="configuration.disabled"></textarea>
                                    <input v-else type="text" :class="configuration.size" v-model="source.configuration[property]" :disabled="configuration.disabled" />
                                </template>
                            </template>
                            <template v-if="configuration.type === 'number'">
                                <input type="number" :class="configuration.size" v-model="source.configuration[property]" :disabled="configuration.disabled" />
                            </template>
                            <template v-else-if="configuration.type === 'boolean'">
                                <div class="flex">
                                    <checkbox :label="configuration.label" :checked.sync="source.configuration[property]" @focus="focus(property)" @blur="blur(property)" />
                                </div>
                            </template>
                            <template v-else-if="configuration.type === 'file'">
                                <div class="flex">
                                    <upload class="image-upload" :url.sync="source.configuration[property]" type="zip" />
                                </div>
                            </template>
                        </div>
                        <!-- <div class="form-field" v-if="selected.allows_external_sso">
                            <label :class="{help: selected.help.consent}" @click="watch(selected.help.consent)">Single Sign On</label>
                            <div class="form-helptext">Select an SSO provider if you want users to be able to sign in.</div>
                            <consent :configuration="source.configuration" :provider="selected" :value.sync="source.configuration.tenant_id" />
                        </div> -->
                        <div class="form-field" v-if="selected.requires_administrator_consent">
                            <label :class="{ help: selected.help.consent }" @click="watch(selected.help.consent)">Administrator Consent</label>
                            <div class="form-helptext">Please click the link below to provide administrative content to Edlink.</div>
                            <consent :configuration="source.configuration" :provider="selected" :value.sync="source.configuration.tenant_id" />
                        </div>
                        <div class="form-field" v-if="selected.requires_administrator_login">
                            <label :class="{ help: selected.help.account }" @click="watch(selected.help.account)">Administrator Account</label>
                            <div class="form-helptext">To complete setup, log in with an administrator account.</div>
                            <token :configuration="source.configuration" :provider="selected" :value.sync="source.token" />
                        </div>
                        <button type="submit">Connect Source</button>
                    </form>
                </template>
            </div>
        </div>
    </main>
</template>

<script>
    import { CloudSync, PeopleTag, MultiWindow } from '@epiphany/iconoir';
    import HelpVideo from '@/components/modals/HelpVideo.vue';
    import moment from 'moment-timezone';

    export default {
        name: 'CreateSource',
        components: {
            CloudSync,
            PeopleTag,
            MultiWindow
        },
        data() {
            return {
                selected: null,
                focused: null,
                providers: [],
                source: {
                    name: '',
                    import_interval: '86400',
                    token: '',
                    configuration: {},
                    region_id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49'
                },
                category: 'lms'
            };
        },
        created() {
            this.$http.get('/providers')
                .then(response => this.providers = response.$data)
                // .then(() => this.select(this.providers[0]))
                .catch(error => this.$toasted.error('There was an error loading the available providers.'));
        },
        computed: {
            admin() {
                return this.$store.getters.user?.admin;
            },
            filtered() {
                return this.providers.filter(provider => {
                    if(['clever', 'classlink'].includes(provider.application)){
                        if(!this.admin){
                            return false;
                        }
                    }

                    return provider.categories.includes(this.category);
                })
                .sort((a, b) => a.name.localeCompare(b.name));
            },
            /**
             * Get a list of timezones for the user to select from.
             *
             * Supports Australia, Canada, United States, and Hungary
             *
             * @see https://momentjs.com/timezone/docs/#/using-timezones/getting-country-zones/
             */
            timezones() {
                return _.uniq(['AU', 'CA', 'US', 'HU'].map(country => moment.tz.zonesForCountry(country).map(zone => zone)).flat()).sort((a, b) => a.localeCompare(b));
            }
        },
        methods: {
            filter(category) {
                this.category = category;
            },
            select(provider) {
                //TODO Warn if this change will clear form fields
                //TODO Change the instructions
                this.selected = provider;
                this.$set(this.source, 'provider_id', provider.id);
                for (const [key, value] of Object.entries(provider.configuration)) {
                    this.$set(this.source.configuration, key, (typeof value.default !== 'undefined') ? value.default : null);
                }
            },
            selectRegion(region_id) {
                this.$set(this.source, 'region_id', region_id);
            },
            create() {
                this.$http.post(`/teams/${this.$store.getters.team.id}/sources`, this.source)
                    .then(response => this.$router.push({ name: 'team.sources.source', params: { source: response.$data.id } }))
                    .catch(error => this.$toasted.error('There was an error creating your new source.'));
            },
            focus(field) {
                this.focused = field;
            },
            blur(field) {
                this.focused = null;
            },
            watch(video) {
                if (video) {
                    this.$modal.show(HelpVideo, { video }, { width: 600, height: 'auto', classes: 'modal' });
                }
            }
        },
        watch: {
            source: {
                handler() {
                    console.log('source changed');
                    console.log(this.source)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/mixins";
    @import "~@/assets/less/variables";

    h1
    {
        margin-top: 50px;
    }

    .create-source
    {
        top: @breadcrumbs-height;
        padding: @section-padding;
    }

    nav
    {
        margin: 30px 0;
    }

    .create-source-instructions
    {
        width: 400px;
    }

    .source-configuration
    {
        margin-top: 20px;
        max-width: 600px;
        padding-bottom: 100px;

        button
        {
            margin-top: 30px;
        }
    }

    .source-remote-connection
    {
        color: @black;
        font-size: 16px;
        line-height: 26px;
        margin-right: 100px;

        .card
        {
            padding: 30px;
        }

        .source-remote-links
        {
            margin-top: 20px;

            .button.clever
            {
                background: #0a487e;

                &:hover,
                &:active
                {
                    background: darken(#0a487e, 10%);
                }
            }

            .button.classlink
            {
                background: #34abce;

                &:hover,
                &:active
                {
                    background: darken(#34abce, 10%);
                }
            }
        }
    }

    .create-source-region-select
    {
        align-items: flex-start;
    }

    .helptext
    {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: @grey;
        margin: 10px 0 20px;
        max-width: 700px;
    }

    .region-options,
    .source-options
    {
        margin: 10px 0 30px;
    }

    .region-option,
    .source-option
    {
        margin: 0 @section-padding @section-padding 0;
        width: 108px;
        height: 108px;
        cursor: pointer;
        user-select: none;
        border-color: fade(@black, 15%);
        border-bottom-color: fade(@black, 20%);
        box-shadow: 0 1px 4px 0 fade(@black, 8%);
        overflow: hidden;
        transition: all ease 0.2s;

        &::after
        {
            content: "";
            position: absolute;
            top: 6px;
            left: 7px;
            width: 11px;
            height: 11px;
            background: url('~@/assets/icons/base/check.svg');
            background-size: 15px auto;
            background-position: -2px 0;
            background-repeat: no-repeat;
            transition: all ease 0.15s;
            display: block;
            transform: scale(0);
            z-index: 2;
        }

        &.selected
        {
            color: @base;
            border-color: @base;
            border-bottom-color: darken(@base, 5%);
            box-shadow: 0 2px 8px -1px fade(@base, 25%);

            .region-name,
            .source-name
            {
                color: @base;
            }

            &::after
            {
                transform: scale(1);
            }

            img
            {
                box-shadow: 0 2px 8px -1px fade(@black, 25%);
            }
        }

        &.placeholder
        {
            margin: 0;
            height: 0;
        }
    }

    .flag-icon,
    .source-icon
    {
        // pointer-events: none;

        img
        {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            transition: all ease 0.2s;
        }

        .flag-icon-wrapper,
        .source-icon-wrapper
        {
            width: 44px;
            height: 64px;
            padding: 8px 0 12px;

            &::after
            {
                width: 44px;
                height: 44px;
                box-sizing: border-box;
                position: absolute;
                z-index: 2;
                content: "";
                top: 8px;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
    }

    .region-name,
    .source-name
    {
        height: 18px;
        transition: all ease 0.2s;
    color: @black;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 0 10px;
}
</style>
