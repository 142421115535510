import { render, staticRenderFns } from "./Documentation.vue?vue&type=template&id=494e625d&scoped=true"
import script from "./Documentation.vue?vue&type=script&lang=js"
export * from "./Documentation.vue?vue&type=script&lang=js"
import style0 from "./Documentation.vue?vue&type=style&index=0&id=494e625d&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494e625d",
  null
  
)

export default component.exports