import { render, staticRenderFns } from "./TeamMemberName.vue?vue&type=template&id=e5cbd514"
import script from "./TeamMemberName.vue?vue&type=script&lang=js"
export * from "./TeamMemberName.vue?vue&type=script&lang=js"
import style0 from "./TeamMemberName.vue?vue&type=style&index=0&id=e5cbd514&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports