<template>
    <div class="progress-indicator flex flex-center flex-align">
        <svg :width="width" :height="height" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle class="track" :class="track" :r="(width - (stroke / 2) - 1) / 2" :cx="width / 2" :cy="height / 2" fill="transparent" stroke-dasharray="0" stroke-dashoffset="0" :stroke-width="stroke"></circle>
            <circle v-if="progress" class="progress" :r="(width - (stroke / 2) - 1) / 2" :cx="width / 2" :cy="height / 2" fill="transparent" stroke-dasharray="100" :stroke-width="stroke" pathLength="100" :stroke-dashoffset="100 - progress"></circle>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'ProgressIndicator',
        props: {
            progress: Number,
            width: Number,
            height: Number,
            stroke: Number,
            track: {
                type: String,
                default: 'grey'
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .progress-indicator
    {
        svg
        {
            transform: rotate(-90deg);
        }
    }

    .track
    {
        stroke-dashoffset: 0;
        transition: all ease 0.2s;
        stroke: @e4;

        &.red
        {
            stroke: @red;
        }

        &.green
        {
            stroke: @green;
        }

        &.blue
        {
            stroke: @blue;
        }

        &.purple
        {
            stroke: @purple;
        }

        &.yellow
        {
            stroke: @yellow;
        }

        &.orange
        {
            stroke: @orange;
        }

        &.none
        {
            stroke: transparent;
        }
    }

    .progress
    {
        transition: all ease 0.2s;
        stroke: @base;
        stroke-linecap: round;
    }
</style>
