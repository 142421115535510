import axios from 'axios';

const state = {
    loading: false,
    source: null,
    team: null,
    integration: null,
    person: null
};

const mutations = {
    loading(state) {
        state.loading = !state.loading;
    },
    activate_source(state, source) {
        state.source = source;
    },
    activate_team(state, team) {
        state.team = team;
    },
    activate_integration(state, integration) {
        state.integration = integration;
    },
    activate_person(state, person) {
        state.person = person;
    }
};

const actions = {
    activate_source({ commit }, source_id) {
        commit('loading');
        if (source_id) {
            axios.get(`/admin/sources/${source_id}`).then((source) => {
                commit('activate_source', source.$data);
            });
        } else {
            commit('activate_source', null);
        }
        commit('loading');
    },
    activate_integration({ commit }, integration_id) {
        commit('loading');
        if (integration_id) {
            axios.get(`/admin/integrations/${integration_id}`).then((source) => {
                commit('activate_integration', source.$data);
            });
        } else {
            commit('activate_integration', null);
        }
        commit('loading');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
