<template>
    <label class="checkbox flex flex-align" :class="{disabled, error, checked, borderless}">
        <template v-if="prevent">
            <input class="checkbox-input" type="checkbox" :disabled="disabled" :checked="checked" @click.prevent="update" />
            <div class="checkbox-label" v-if="label">{{label}}</div>
        </template>
        <template v-else>
            <input class="checkbox-input" type="checkbox" :disabled="disabled" :checked="checked" @input="update" />
            <div class="checkbox-label" v-if="label">{{label}}</div>
        </template>
    </label>
</template>

<script>
    export default {
        name: 'Checkbox',
        props: {
            label: String,
            error: Boolean,
            checked: Boolean,
            disabled: Boolean,
            prevent: Boolean,
            borderless: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            update($event){
                this.$emit('update:checked', $event.target.checked);
                this.$emit('input', $event.target.checked);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    label.checkbox
    {
        border: 1px solid @d;
        padding: 4px 10px;
        height: 40px;
        line-height: 30px;
        border-radius: 6px;
        text-transform: none;
        margin: 0;
        cursor: pointer;

        &.borderless {
            border: none;
            padding: 4px;

            &:focus-within, &:active
            {
                border-color: none;
                box-shadow: none;
            }
        }

        input
        {
            margin: 0;
        }

        &:focus-within, &:active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;
        }

        .checkbox-label
        {
            margin: 0 0 0 10px;
            user-select: none;
            white-space: nowrap;
            font-size: 14px;
            color: @black;
        }

        &.disabled
        {
            pointer-events: none;
            
            .checkbox-label
            {
                color: @grey;
            }

            input:checked
            {
                background-color: @grey;
                border-color: @grey;
            }
        }
    }
</style>
