<template>
    <div class="entity-identifiers">
        <div class="identifiers-header flex flex-align" :class="{override: !!override}">
            <h4>External Identifiers</h4>
            <div class="override-pin"></div>
            <div class="options flex flex-align" v-if="editing">
                <div class="option pointer" @click="save()">Save</div>
                <div class="option pointer" @click="cancel()">Cancel</div>
                <div class="option pointer" @click="reset()">Reset</div>
            </div>
            <div class="edit" @click="edit()" v-else>Edit</div>
            <div class="ff"></div>
            <div class="add flex flex-align text-button mini" v-if="editing" @click="add()">
                <Plus class="icon" width="18" height="18" stroke-width="2" />
                <div>Add Identifier</div>
            </div>
        </div>
        <div class="summary identifiers" v-if="identifiers && identifiers.length > 0">
            <!-- Identifier mapping -->
            <div v-for="(identifier, $index) of identifiers" :key="identifier.key" class="identifier card flex flex-align">
                <FingerprintLockCircle class="icon" width="20" height="20" stroke-width="1.5" />
                <div class="identifier-type">
                    <SearchSelect :compact="true" v-if="editing" :values="identifier.type ? [identifier.type] : []" @input="(v) => update($index, 'type', v[0])" :borderless="true" :enum="identifer_type" :array="false" placeholder="Identifier Type" />
                    <template v-else>{{identifer_type[identifier.type]}}</template>
                </div>
                <div class="ff">
                    <input v-if="editing" class="borderless" placeholder="Identifier Value" :value="identifier.value" @input="(v) => update($index, 'value', v.target.value)" />
                    <div v-else class="copyable identifier-value" v-clipboard="() => identifier.value" v-clipboard:success="() => $toasted.info('Copied')">{{identifier.value}}</div>
                </div>
                <div class="delete flex flex-align text-button red" v-if="editing" @click="_delete($index)">
                    Delete
                </div>
            </div>
        </div>
        <div class="nothing" v-else>None.</div>
    </div>
</template>

<script>
    import SearchSelect from '@/components/SearchSelect.vue';
    import { FingerprintLockCircle, Plus, Trash } from '@epiphany/iconoir';
    import { cloneDeep } from 'lodash';
    import { IDENTIFIER_TYPES } from '@/constants';

    export default {
        name: 'EntityIdentifiers',
        components: {
            SearchSelect,
            FingerprintLockCircle,
            Plus,
            Trash
        },
        data(){
            return {
                editing: false,
                identifer_type: IDENTIFIER_TYPES,
                identifiers: []
            };
        },
        mounted(){
            this.identifiers = cloneDeep(this.override ?? this.entity.identifiers);
            this.identifiers.forEach((identifier) => {
                identifier.key = this.generate_key();
            });
        },
        computed: {
            override: {
                get() {
                    return _.get(this.$store.state.entities.overrides, 'identifiers');
                },
                set(value) {
                    this.identifiers = cloneDeep(value);
                    value.forEach((identifier) => {
                        delete identifier.key;
                    });
                    this.$store.commit('entities/override', {property: 'identifiers', value});
                }
            },
            entity() {
                return this.$store.state.entities.active;
            }
        },
        methods: {
            generate_key(len) {
                var arr = new Uint8Array((len || 40) / 2);
                window.crypto.getRandomValues(arr);
                return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join('');
            },
            edit(){
                this.editing = true;
            },
            update(index, prop, value){
                const copy = cloneDeep(this.identifiers);
                copy[index][prop] = value;
                this.override = copy;
            },
            save(){
                this.editing = false;
                this.$store.dispatch('entities/update');
            },
            cancel(){
                this.editing = false;
                this.$store.commit('entities/reset', 'identifiers');
                this.identifiers = cloneDeep(this.override ?? this.entity.identifiers);
            },
            reset(){
                this.editing = false;
                this.$store.commit('entities/clear', 'identifiers');
                this.$store.dispatch('entities/update');
                this.identifiers = cloneDeep(this.override ?? this.entity.identifiers);
            },
            add() {
                const copy = cloneDeep(this.identifiers);
                copy.push({type: null, value: null, key: this.generate_key()});
                this.override = copy;
            },
            _delete(index) {
                const copy = cloneDeep(this.identifiers);
                copy.splice(index, 1);
                this.override = copy;
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    
    .entity-identifiers
    {
        .identifiers-header
        {
            margin-bottom: @double-padding;
            height: 18px;
            
            h4 {
                color: @black;
                margin-bottom: 0;
                padding: 0;
            }

            .override-pin
            {
                width: 16px;
                height: 16px;
                background: url('~@/assets/icons/base/pin.svg') 4px 3px no-repeat;
                background-size: 7px auto;
                background-position: center;
                margin-top: 1px;
                display: none;
                margin-left: 2px;
            }

            .edit
            {
                // color: @base;
                opacity: 0;
            }

            .edit, .option
            {
                color: @grey;
                font-size: 12px;
                line-height: 16px;
                transition: opacity ease 0.2s;
                margin-left: @single-padding;

                &:last-child
                {
                    margin-right: 0;
                }

                &:hover
                {
                    color: @base;
                }
            }

            .option
            {
                font-weight: 500;
            }

            &.override
            {
                h4
                {
                    color: @base;
                }

                .override-pin
                {
                    display: block;
                }
            }
        }

        &:hover {
            .identifiers-header .edit
            {
                opacity: 1;
                cursor: pointer;
            }
        }
        
        .identifiers
        {
            .identifier
            {
                margin-bottom: @single-padding;
                padding: 0 @single-padding;
                height: 48px;
                box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1);
                border-bottom-color: @d;

                .icon {
                    margin-right: @single-padding - 2px;
                    color: @grey;
                }

                .borderless {
                    border: 0;
                    outline: 0;
                }

                .identifier-type {
                    color: @grey;
                    font-size: 14px;
                    line-height: 18px;
                    width: 201px;
                }

                .identifier-value
                {
                    font-size: 14px;
                    line-height: 42px;
                    color: @black;
                }

                input {
                    font-size: 14px;

                    &:focus, :focus-visible, &:focus-within {
                        outline: 0;
                        box-shadow: none;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .autocomplete.compact .autocomplete-search input.autocomplete-input
                {
                    min-width: 20px;
                }

                .copyable
                {
                    &::after
                    {
                        bottom: 0;
                    }

                    &:hover
                    {
                        transform: translate3d(0, -7px, 0);

                        &::after
                        {
                            bottom: -1px;
                        }
                    }
                }
            }
        }

        .add {
            .icon {
                margin-right: 5px;
            }
        }

        .delete {
            color: @grey;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
            margin-left: 5px;

            .icon {
            }

            &:hover {
                color: @red;
            }
        }
    }
</style>
