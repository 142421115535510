<template>
    <div class="consent flex flex-align">
        <div class="source-icon" :class="{connected: value}">
            <img :src="provider.icon_url" />
        </div>
        <div class="ff source-name">{{value ? 'Consent Granted' : 'Grant Consent'}}</div>
        <div class="text-button red" v-if="value" @click="remove">Change</div>
        <div class="text-button" v-else @click="connect">Grant</div>
    </div>
</template>

<script>
    const sso_url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

    export default {
        name: 'Consent',
        props: {
            configuration: Object,
            provider: Object,
            value: String,
            write: Boolean
        },
        methods: {
            serialize(object){
                const str = [];

                for(const [key, value] of Object.entries(object)){
                    str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
                }

                return str.join('&');
            },
            connect(){
                //These are the parameters we need to send to the SSO server, depending on which LMS we're authorizing
                const required_source_parameters = {
                    microsoft: []
                };

                if(!this.provider.requires_administrator_consent){
                    return this.$toasted.error('This provider does not require an administrator consent.');
                }

                if(!required_source_parameters.hasOwnProperty(this.provider.application)){
                    return this.$toasted.error('There was an error allowing administrator consent for this provider.');
                }

                //Start by opening the popup window with a state variable and the required parameters
                const parameters = {
                    state: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                };

                for(const parameter of required_source_parameters[this.provider.application]){
                    if(this.configuration[parameter]){
                        parameters[parameter] = this.configuration[parameter];
                    }else{
                        return this.$toasted.error('You must fill out the other fields before allowing consent.');
                    }
                }

                const popup = window.open(`${sso_url}/api/authentication/consent/${this.provider.application}?${this.serialize(parameters)}`);

                const listener = event => {
                    console.log('ORIGIN', event.origin, window.origin);
                    console.log(event);

                    if(event.origin !== window.origin && process.env.NODE_ENV === 'production'){
                        return;
                    }

                    if(event.data.state !== parameters.state){
                        return;
                        // return this.$toasted.error('Invalid state parameter.');
                    }

                    popup.close();
                    window.removeEventListener('message', listener);

                    this.$emit('update:value', event.data.tenant_id);
                    this.$emit('input', event.data.tenant_id);
                };

                //TODO Eventually make sure this listener is removed when the component is destroyed
                window.addEventListener('message', listener);
            },
            remove(){
                this.$emit('update:value', '');
                this.$emit('input', '');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .consent
    {
        border: 1px solid @d;
        border-radius: 6px;
        width: 100%;
        max-width: 320px;
        padding: 10px 15px 10px 10px;
        height: 54px;
    }

    .source-icon
    {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: @e;
        margin-right: 10px;

        &::before
        {
            width: 32px;
            height: 32px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        &.connected
        {
            &::after
            {
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: url('~@/assets/icons/white/check.svg') 1px 2px no-repeat @green;
                background-size: 12px auto;
                position: absolute;
                content: "";
                right: 0;
                bottom: 0;
                z-index: 2;
                box-shadow: 0 0 0 1px @f;
            }
        }

        img
        {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    .source-name
    {
        color: @black;
        font-size: 14px;
        font-weight: 500;
    }
</style>
