<template>
    <div class="placard flex flex-align" :class="{selectable}" @click="select">
        <div class="placard-icon flex flex-align flex-center" v-if="$slots.icon">
            <slot name="icon" />
        </div>
        <div class="ff">
            <h3 class="text-overflow" v-if="$slots.title">
                <slot name="title" />
            </h3>
            <h4 class="text-overflow" v-if="$slots.description">
                <slot name="description" />
            </h4>
            <div class="actions flex flex-align" v-if="$slots.actions">
                <slot name="actions" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Placard',
        props: {
            selectable: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            select(){
                if(this.selectable){
                    this.$emit('select');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
    
    .placard
    {
        border-radius: @border-radius;
        padding: @double-padding;
        border: @border-width solid @e;
        background: @f8;

        &.selectable
        {
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover
            {
                background: @e;
                border-color: @d;
            }
        }

        .placard-icon
        {
            margin-right: @single-padding;
            width: 36px;
            height: 36px;
            background: linear-gradient(0deg, @base, lighten(@base, 10%));
            border-radius: 18px;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
            color: @white;

            img
            {
                width: 100%;
                height: 100%;
                border-radius: 18px;
            }
        }

        h3
        {
            font-size: 16px;
            line-height: 18px;
            color: @black;
        }

        h4
        {
            font-size: 14px;
            line-height: 16px;
            color: @grey;
            font-weight: 400;
            margin-top: 6px;
        }

        .actions
        {
            margin-top: @double-padding;
        }

        &.purple
        {
            .placard-icon
            {
                background: linear-gradient(0deg, @purple, lighten(@purple, 10%));
            }
        }
    }
</style>