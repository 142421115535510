<template>
    <div class="team-navigation">
        <div class="sidebar-group">
            <div class="sidebar-header">Manage</div>
            <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.overview', params: {source: source.id}}">
                <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Summary</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.mapping', params: {source: source.id}}" v-if="['07de3f01-6b1f-4ba2-9fc0-7d983fa53c9a', 'ff2988d2-e83c-44c7-aa46-07e889c03e62', '0b70b3fc-994c-4641-b686-4e13b5adccae'].includes(source.provider.id)">
                <DatabaseScript class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">CSV Mapping</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.syncs', params: {source: source.id}}">
                <DatabaseBackup class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Syncs</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.enrichments', params: {source: source.id}}">
                <Bolt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Enrichments</div>
            </router-link>
            <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.settings', params: {source: source.id}}">
                <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
                <div class="ff">Settings</div>
            </router-link>
        </div>
        <template v-if="admin">
            <div class="sidebar-group">
                <div class="sidebar-header">Browse Data</div>
                <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.dataset', params: {type: 'schools'}}">
                    <City class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Schools</div>
                </router-link>
                <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.dataset', params: {type: 'classes'}}">
                    <Apple class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Classes</div>
                </router-link>
                <router-link class="destination flex flex-align" :to="{name: 'team.sources.source.dataset', params: {type: 'people'}}">
                    <Group class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">People</div>
                </router-link>
                <router-link v-if="other" class="destination flex flex-align" :to="{name: 'team.sources.source.dataset', params: {type: other.type}}">
                    <component :is="other.icon" class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">{{other.name}}</div>
                </router-link>
                <div class="more flex">
                    <a class="destination flex flex-align" @click="more = !more" :class="{active: more}">
                        <GridAdd class="icon" width="16" height="16" stroke-width="2" />
                        <div class="ff">All Types</div>
                        <NavArrowDown class="more-icon" width="12" height="12" stroke-width="2.5" />
                    </a>
                    <dropdown :open="more" left="20px" width="240px" height="320px" padding="0 0 4px" :scrollable="true">
                        <template v-if="populated.length">
                            <div class="dropdown-spacer dropdown-header">Populated Types</div>
                            <dropdown-item v-for="model of populated" :key="'populated-' + model.type" @click.native="go(model)">
                                <template v-slot:icon>
                                    <component :is="model.icon" class="icon" width="16" height="16" stroke-width="2" />
                                </template>
                                <template v-slot:title>
                                    {{model.name}}
                                </template>
                                <template v-slot:badge>
                                    <div class="badge grey">{{ source.total[model.type] | commas(true) }}</div>
                                </template>
                            </dropdown-item>
                        </template>
                        <div class="dropdown-spacer dropdown-header">All Types</div>
                        <dropdown-item v-for="model of models" :key="'all-' + model.type" @click.native="go(model)">
                            <template v-slot:icon>
                                <component :is="model.icon" class="icon" width="16" height="16" stroke-width="2" />
                            </template>
                            <template v-slot:title>
                                {{model.name}}
                            </template>
                            <template v-slot:badge>
                                <div class="badge grey">{{ source.total[model.type] | commas(true) }}</div>
                            </template>
                        </dropdown-item>
                    </dropdown>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {
        CloudSync,
        DatabaseBackup,
        SettingsAlt,
        Bolt,
        ShopAlt,
        Group,
        Apple,
        City,
        SeaWaves,
        NetworkLeft,
        GridAdd,
        AlignLeft,
        DataTransferBoth,
        Sparks,
        NavArrowDown,
        Bank,
        PinAlt,
        Map,
        Truck,
        Packages,
        SineWave,
        Calendar,
        WarningCircle,
        Swimming,
        UserLove,
        Clock,
        MathBook,
        Presentation,
        Desk,
        Asana,
        CalendarPlus,
        ListSelect,
        Calculator,
        DesignPencil,
        Coin,
        BookmarkCircle,
        FingerprintSquare,
        HealthShield,
        ShieldCheck,
        DatabaseScript,
        Filter as FilterIcon,
        PageFlip,
        TransitionRight
    } from '@epiphany/iconoir';

    export default {
        name: 'SourceNavigation',
        components: {
            CloudSync,
            DatabaseBackup,
            SettingsAlt,
            Bolt,
            ShopAlt,
            Group,
            Apple,
            City,
            SeaWaves,
            NetworkLeft,
            GridAdd,
            AlignLeft,
            DataTransferBoth,
            Sparks,
            NavArrowDown,
            Bank,
            PinAlt,
            Map,
            Truck,
            Packages,
            SineWave,
            Calendar,
            WarningCircle,
            Swimming,
            UserLove,
            Clock,
            MathBook,
            Presentation,
            Desk,
            Asana,
            CalendarPlus,
            ListSelect,
            Calculator,
            DesignPencil,
            Coin,
            BookmarkCircle,
            FingerprintSquare,
            HealthShield,
            ShieldCheck,
            DatabaseScript,
            FilterIcon,
            PageFlip,
            TransitionRight
        },
        methods: {
            go(model){
                // Close the dropdown.
                this.more = false;

                // If we're already there, do nothing.
                if(this.$route.name === 'team.sources.source.dataset'){
                    if(model.type === this.$route.params.type){
                        return;
                    }
                }

                // Go to the dataset browser for this model.
                this.$router.push({name: 'team.sources.source.dataset', params: {type: model.type}})
            }
        },
        data() {
            return {
                more: false,
                models: [
                    { type: 'agents', name: 'Agents', icon: UserLove },
                    { type: 'assets', name: 'Assets', icon: Packages },
                    { type: 'attendance', name: 'Attendance', icon: ListSelect },
                    { type: 'calendars', name: 'Calendars', icon: Calendar },
                    { type: 'classes', name: 'Classes', icon: Apple },
                    { type: 'courses', name: 'Courses', icon: Calculator },
                    { type: 'days', name: 'Days', icon: BookmarkCircle },
                    { type: 'departments', name: 'Departments', icon: DesignPencil },
                    { type: 'districts', name: 'Districts', icon: Bank },
                    { type: 'enrollments', name: 'Enrollments', icon: Desk },
                    { type: 'facilities', name: 'Facilities', icon: Swimming },
                    { type: 'fees', name: 'Fees', icon: Coin },
                    { type: 'incidents', name: 'Incidents', icon: WarningCircle },
                    { type: 'meetings', name: 'Meetings', icon: Presentation },
                    { type: 'people', name: 'People', icon: Group },
                    { type: 'periods', name: 'Periods', icon: Clock },
                    { type: 'rooms', name: 'Rooms', icon: MathBook },
                    { type: 'routes', name: 'Routes', icon: Map },
                    { type: 'schools', name: 'Schools', icon: City },
                    { type: 'sections', name: 'Sections', icon: Asana },
                    { type: 'sessions', name: 'Sessions', icon: CalendarPlus },
                    { type: 'stops', name: 'Stops', icon: PinAlt },
                    { type: 'subjects', name: 'Subjects', icon: SineWave },
                    { type: 'vehicles', name: 'Vehicles', icon: Truck }
                ]
            };
        },
        computed: {
            populated(){
                return this.models.filter(model => this.source.total[model.type]);
            },
            admin() {
                return this.$store.getters.user.admin;
            },
            source() {
                return this.$store.getters.source;
            },
            team() {
                return this.$store.getters.team;
            },
            other(){
                // This returns the model for a type that doesn't appear in the menu by default.
                // Pretty much anything other than schools, classes, and people.
                if(this.$route.name === 'team.sources.source.dataset'){
                    const type = this.$route.params.type;

                    if(!['schools', 'classes', 'people'].includes(type)){
                        return this.models.find(model => model.type === type);
                    }
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .source-navigation
    {
        width: 100%;
    }

    .more
    {
        .more-icon
        {
            display: block;
            margin-left: 4px;
            margin-top: 1px;
        }

        .badge
        {
            height: 16px;
            border-radius: @border-radius - 2px;
            font-size: 11px;
            line-height: 16px;
            padding: 0 4px;
        }
    }
</style>
