<template>
    <div class="filter filter-dropdown" :class="{open, active, searchable}" @mousedown.stop="() => {}">
        <div class="filter-badge flex flex-align" @mousedown.stop="toggle">
            <slot name="badge" v-bind="active">
                <slot name="icon" />
                <div class="filter-label text-overflow ff">{{active ? active.name : label}}</div>
                <div class="filter-action" v-if="required">
                    <NavArrowDown class="required-filter icon" width="16" height="16" stroke-width="2" />
                </div>
                <div class="filter-action" v-else-if="active" @mousedown.stop="clear">
                    <Cancel class="clear-filter icon" width="16" height="16" stroke-width="2" />
                </div>
            </slot>
        </div>
        <div class="filter-items" :class="{ borderless }" v-if="open">
            <div class="filter-search flex flex-align" v-if="searchable">
                <Search class="filter-search-icon icon block" width="16" height="16" stroke-width="2" />
                <input type="text" class="filter-search-input ff block" placeholder="Search" v-model="query" @input="search" ref="search">
            </div>
            <template v-if="items">
                <div class="filter-items-scroll" v-if="items.length">
                    <template v-for="(item, index) of items">
                        <div v-if="item.spacer" :key="index" class="filter-spacer" :class="{header: item.header}">{{item.header}}</div>
                        <div v-else class="filter-item" :key="index" @click="activate(item)">
                            <slot name="item" v-bind="item" />
                        </div>
                    </template>
                </div>
                <div class="filter-items-empty" v-else>
                    No results found.
                </div>
            </template>
            <template v-else-if="custom">
                <div class="filter-items-custom">
                    <slot name="custom" v-bind:activate="activate" />
                </div>
            </template>
            <div class="filter-searching flex flex-align flex-center" v-else>
                <spinner />
            </div>
        </div>
    </div>
</template>

<script>
    import { NavArrowDown, Cancel, Search } from '@epiphany/iconoir';

    export default {
        name: 'FilterDropdown',
        components: {
            NavArrowDown,
            Cancel,
            Search
        },
        props: {
            label: [String, Number],
            items: Array,
            active: Object,
            source: Function,
            searchable: Boolean,
            type: String,
            required: Boolean,
            custom: Boolean,
            borderless: Boolean
        },
        data(){
            return {
                open: false,
                query: ''
            };
        },
        computed: {
            
        },
        methods: {
            toggle(){
                this.open = !this.open;

                if(this.open){
                    this.search();

                    if(this.searchable){
                        setTimeout(() => {
                            this.$refs.search.focus();
                        }, 30);
                    }
                }
            },
            close(){
                this.open = false;
            },
            activate(item){
                // Close the dropdown.
                this.close();

                // Clear the search field.
                this.query = '';

                // Emit the item to the parent.
                this.$emit('activate', item);
            },
            search(){
                if(this.searchable){
                    this.$emit('search', this.query);
                }
            },
            clear($event){
                if(this.required){
                    return;
                }
                
                if(this.active){
                    $event.stopPropagation();
                    this.activate();
                }
            }
        },
        mounted(){
            document.addEventListener('mousedown', this.close);
        },
        destroyed(){
            document.removeEventListener('mousedown', this.close);
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filter
    {
        &.open
        {
            .filter-badge
            {
                background-color: @e;
                color: @black;
            }

            .filter-items
            {
                display: block;
            }
        }

        &.active
        {
            .filter-badge
            {
                border-color: fade(@base, 60%);
                background-color: fade(@base, 15%);
                color: @base;
            }
        }

        &.searchable
        {
            .filter-items
            {
                width: 320px;
            }

            .filter-items-scroll
            {
                max-height: 340px;
                overflow-y: auto;
            }
        }
    }

    .filter-searching
    {
        height: 80px;
    }

    .filter-items
    {
        position: absolute;
        top: 36px;
        background: @f;
        border-radius: 6px;
        z-index: 15;
        min-width: 140px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background-clip: padding-box;
        box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        &.borderless
        {
            border: none;
        }
    }

    .filter-items-scroll
    {
        padding: 4px 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .filter-item
    {
        cursor: pointer;
        border-radius: 4px;
        margin: 2px 4px;

        &:hover, &:active, &.highlighted
        {
            background: @f4;
        }

        &:first-child
        {
            margin-top: 0;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .filter-search
    {
        padding: 10px 14px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .filter-search-icon
    {
        color: @grey;
        margin-right: 10px;
    }

    .filter-items-empty
    {
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 12px;
        color: @grey;
    }

    .filter-search-input
    {
        width: auto;
        border: none;
        outline: none;
        background: transparent;
        color: @black;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        padding: 0;
        border-radius: 0;

        &:focus
        {
            box-shadow: none;
        }
    }

    .filter-spacer
    {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin: 4px 0;

        &.header
        {
            font-size: 11px;
            color: @grey;
            font-weight: 500;
            padding: 10px 10px 2px;
            margin: 4px 0 2px;
        }

        &:first-child
        {
            margin-top: 0;
            border-top: 0;
            padding-top: 5px;
        }
    }
</style>
