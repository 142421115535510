<template>
    <div class="editor full" ref="editor"></div>
</template>

<script>
    import * as monaco from 'monaco-editor';
    
    // Note to future selves:
    // These types are built with the command `yarn build:types`.
    // Every time there is a substantive change to client-facing types, we should run this command.
    // The actual common_types file gets committed to the repo.
    import { common_types } from '@/common_types';

    let timeout = null;

    export default {
        name: 'Editor',
        props: {
            readonly: Boolean,
            value: String,
            range: Array,
            throttle: {
                default: 150,
                type: Number
            },
            block: {
                type: Boolean,
                default: true
            }
        },
        data(){
            return {
                editor: null,
                timeout: null,
            }
        },
        destroyed(){
            if (this.editor) {
                this.editor.dispose();
            }
        },
        mounted(){
            monaco.languages.typescript.typescriptDefaults.addExtraLib(EdlinkTypesLib);
            monaco.languages.typescript.typescriptDefaults.addExtraLib(unescape(common_types));

            let value = this.value ?? '';

            if (this.block) {
                value = `import { Dataset } from 'index';\n\nfunction transform($ctx: Context, $config: Record<string, any>, $input: Dataset): Dataset {\n${value}\n\treturn $input;\n}`;
            }

            this.editor = monaco.editor.create(this.$refs.editor, {
                minimap: {
                    enabled: false
                },
                readOnly: this.readonly,
                scrollbar: {
                    vertical: 'hidden',
                    horizontal: 'hidden'
                },
                fixedOverflowWidgets: true,
                value: value,
                language: 'typescript',
                automaticLayout: true
            });
                
            this.editor.onDidChangeModelContent(() => {
                // Throttle input event
                if(timeout){
                    clearTimeout(timeout);
                }

                timeout = setTimeout(() => {
                    if (this.block) {
                        // Slice and format function
                        const editor_value = this.editor.getValue().split('\n');
                        const function_signature_index = editor_value.indexOf('function transform($ctx: Context, $config: Record<string, any>, $input: Dataset): Dataset {');
                        const formatted_function = editor_value.slice(function_signature_index + 1, editor_value.length - 2).join('\n');
                        this.$emit('input', formatted_function);
                    } else {
                        this.$emit('input', this.editor.getValue());
                    }
                }, 150);
            });
        },
        watch: {
            value(value){
                if(this.editor) {
                    if (this.block) {
                        value = `import { Dataset } from 'index';\n\nfunction transform($ctx: Context, $config: Record<string, any>, $input: Dataset): Dataset {\n${value}\n\treturn $input;\n}`;
                    }
                    this.editor.getModel().setValue(value);
                }
            }
        }
    }

    const EdlinkTypesLib = `
    declare type UUID = string;

    declare enum ContextType {
        Materialization = 'materialization',
        Enrichment = 'enrichment',
        Preview = 'preview'
    }

    declare enum ContextState {
        Queued = 'queued',
        Working = 'working',
        Complete = 'complete',
        Error = 'error'
    }

    declare type Context = {
        type: ContextType;
        state?: ContextState;
        priority?: number;

        transformation_configurations?: TransformationConfiguration;

        source_id: string;
        // Only present in Materializations
        integration_id: string;
        
        source?: Source;
        // Only present in Materializations
        integration?: Integration;
    }

    declare type Data = {
        district: Map<UUID, District>;
        schools: Map<UUID, School>;
        sessions: Map<UUID, Session>;
        courses: Map<UUID, Course>;
        people: Map<UUID, Person>;
        classes: Map<UUID, Class>;
        sections: Map<UUID, Section>;
        enrollments: Map<UUID, Enrollment>;
        agents: Map<UUID, Agent>;
    };
    `
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
</style>