<template>
    <div class="dataset">
        <filters>
            <filter-dropdown :items="types" :active="type" @activate="switch_type" :required="true" label="Entity Type">
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <component :is="item.icon" class="block icon" width="16" height="16" stroke-width="2" />
                        </div>
                        <div class="filter-item-name">{{ item.name }}</div>
                    </div>
                </template>
            </filter-dropdown>
            <input class="filter search large" type="text" placeholder="Search" v-model="search" @input="query" />
            <div class="ff"></div>
            <pages :all="all" :rows="rows" :more="more" :count="count" :page="page" @next="next" @previous="previous" />
        </filters>
        <div class="datatable">
            <div class="row header">
                <div class="header-scroll flex" ref="header">
                    <div class="cell flex flex-align text-overflow" v-for="field of types[type]" :style="{ flexBasis: field.width + 'px' }" :key="field.property">
                        <div class="value ff text-overflow">{{ field.name }}</div>
                    </div>
                </div>
            </div>
            <div class="body ff" ref="table">
                <div v-if="loading" class="flex flex-center flex-align loading">
                    <spinner />
                </div>
                <div v-else-if="rows.length">
                    <template v-for="row of rows">
                        <component v-if="rows.length && tooltip(row) && licensing" @click.native="json(row)" :is="entities[type.id]" :key="row.id" :entity="row" v-tooltip.bottom="tooltip(row)" />
                        <component v-else @click.native="json(row)" :is="entities[type.id]" :key="row.id" :entity="row" />
                    </template>
                </div>
                <div v-else class="flex flex-align flex-center no-results">
                    <div v-if="lifecycle === 'killed'">Your preview is expired, please start a new one to fetch {{type.id}}.</div>
                    <div v-else>No results.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Entity from '@/components/drawers/Entity.vue';
import PreviewEntityAgent from '@/components/preview/entities/PreviewEntityAgent.vue';
import PreviewEntityClass from '@/components/preview/entities/PreviewEntityClass.vue';
import PreviewEntityCourse from '@/components/preview/entities/PreviewEntityCourse.vue';
import PreviewEntityDistrict from '@/components/preview/entities/PreviewEntityDistrict.vue';
import PreviewEntityEnrollment from '@/components/preview/entities/PreviewEntityEnrollment.vue';
import PreviewEntityPerson from '@/components/preview/entities/PreviewEntityPerson.vue';
import PreviewEntitySchool from '@/components/preview/entities/PreviewEntitySchool.vue';
import PreviewEntitySection from '@/components/preview/entities/PreviewEntitySection.vue';
import PreviewEntitySession from '@/components/preview/entities/PreviewEntitySession.vue';
import PreviewEntityOther from '@/components/preview/entities/PreviewEntityOther.vue';
import EntityPreview from '@/components/drawers/EntityPreview.vue';
import {
    Group,
    Apple,
    City,
    Bank,
    PinAlt,
    Map,
    Truck,
    Packages,
    SineWave,
    Calendar,
    WarningCircle,
    Swimming,
    UserLove,
    Clock,
    MathBook,
    Presentation,
    Desk,
    Asana,
    CalendarPlus,
    ListSelect,
    Calculator,
    DesignPencil,
    Coin,
    BookmarkCircle
} from '@epiphany/iconoir';


export default {
    name: 'DatasetBrowser',
    computed: {
        team() {
            return this.$store.getters.team;
        },
        integration() {
            return this.$store.getters.integration;
        },
        parent() {
            return this.$store.state.blink.parent;
        },
        admin() {
            return this.$store.getters.user.admin;
        },
        rows() {
            return this.all.slice(this.page * this.count, this.page * this.count + this.count);
        },
        loading() {
            return this.$store.state.blink.loading || this.$store.state.blink.locked || this.internal_loading;
        },
        lifecycle() {
            return this.$store.state.blink.lifecycle;
        },
        transformation() {
            return this.$store.state.blink.transformation;
        },
        licensing(){
            return this.transformation?.block.id === 'd1df7a33-c5f1-49d7-850d-d03c7ecb335f';
        }
    },
    mounted() {
        this.$refs.table.addEventListener('scroll', (e) => {
            this.$refs.header.style.left = -1 * e.target.scrollLeft + 'px';
        });
    },
    watch: {
        lifecycle(val) {
            if (val === 'ready') {
                this.clear();
            } else if (val !== 'killed') {
                this.internal_loading = true;
            }
        }
    },
    methods: {
        tooltip(entity){
            if (entity.rules && entity.rules.length > 0) {
                const related = new Set();
                for (const rule of entity.rules) {
                    if (rule.target === this.type.id) {
                        return `Shared by passing a ${rule.target} rule`;
                    }

                    related.add(rule.target);
                }

                const types = Array.from(related);
                return `Shared because related ${types.length === 2 ? `${types[0]} and ${types[1]}` : types.join(', ')} were shared`
            }

            return null;
        },
        switch_type(type) {
            this.type = type;
            this.clear();
        },
        query() {
            this.internal_loading = true;
            if (this.search_throttle) {
                clearTimeout(this.search_throttle);
                this.search_throttle = null;
            }
            this.search_throttle = setTimeout(() => {
                this.clear();
            }, 500);
        },
        async load() {
            // Apply our filter to the params array.
            this.params.$filter = {};

            if (this.search) {
                // TODO This will be wrong
                this.params.$filter.id = [
                    {
                        operator: 'equals',
                        value: [this.search]
                    }
                ];

                this.params.$filter[this.type.id === 'people' ? 'display_name' : 'name'] = [
                    {
                        operator: 'contains',
                        value: [this.search]
                    }
                ];
                
                this.params.$any = true;
            }

            const results = await this.$store.dispatch('blink/search', {
                params: _.clone(this.params),
                type: this.type.id
            });

            if (results) {
                this.all.push(...results.$data);

                if (results.$next) {
                    this.more = true;
                } else {
                    this.more = false;
                }
            }

            this.internal_loading = false;
        },
        focus(entity) {
            this.$store
                .dispatch('entities/activate', {
                    id: entity.id,
                    type: this.type,
                    integration: this.integration
                })
                .then(() => {
                    this.$store.dispatch('drawer/open', {
                        key: `entity/${entity.id}`,
                        width: 800,
                        component: components.hasOwnProperty(this.type) ? components[this.type] : Entity
                    });
                });
        },
        previous() {
            //We definitely have these results already.
            if (this.page) {
                this.page -= 1;
            }
        },
        next() {
            if ((this.page + 1) * this.count < this.all.length || this.more) {
                //If we already have these results, we can just swap them in.
                //Otherwise, we need to fetch from the server.
                if (this.all.length <= this.count * (this.page + 1)) {
                    this.params.$pivot = this.all[this.all.length - 1].id;
                    this.load().then(() => (this.page += 1));
                } else {
                    this.page += 1;
                }
            }
        },
        apply() {
            this.page = 0;
            this.all = [];
            this.params = {};
            this.filter.visible = false;
            this.filter.applied = true;
            this.active = null;

            if (this.parent) {
                this.load();
            }
        },
        clear() {
            this.page = 0;
            this.all = [];
            this.params = {};
            this.active = null;

            this.filter = {
                visible: false,
                property: 'id',
                operator: 'equals',
                value: '',
                applied: false
            };
            
            this.load();
        },
        updated(row, field) {
            return false;
        },
        json(json){
            // this.$store.dispatch('drawer/open', {
            //     key: 'json-viewer',
            //     width: 800,
            //     component: EntityPreview,
            //     props: {
            //         json,
            //         licensing: true
            //     }
            // });
            this.$emit('peek', json);
        }
    },
    props: {
        refresh: Boolean,
        readonly: {
            type: Boolean,
            default: false
        }
    },
    created() {
        // Set the default entity type to "People"
        this.type = this.types.slice().find((t) => t.id === 'people');

        // Reset everything like our paging params back to the defaults.
        this.clear();
    },
    data() {
        return {
            search: '',
            all: [],
            visible: [],
            filter: {},
            page: 0,
            count: 100,
            more: false,
            params: {},
            offset: 0,
            summary: null,
            internal_loading: false,
            entities: {
                agents: PreviewEntityAgent,
                classes: PreviewEntityClass,
                courses: PreviewEntityCourse,
                districts: PreviewEntityDistrict,
                enrollments: PreviewEntityEnrollment,
                people: PreviewEntityPerson,
                schools: PreviewEntitySchool,
                sections: PreviewEntitySection,
                sessions: PreviewEntitySession,
                departments: PreviewEntityOther,
                subjects: PreviewEntityOther,
                periods: PreviewEntityOther,
                incidents: PreviewEntityOther,
                meetings: PreviewEntityOther,
                attendance: PreviewEntityOther,
                facilities: PreviewEntityOther,
                rooms: PreviewEntityOther,
                assets: PreviewEntityOther,
                fees: PreviewEntityOther,
                stops: PreviewEntityOther,
                routes: PreviewEntityOther,
                vehicles: PreviewEntityOther,
                calendars: PreviewEntityOther,
                days: PreviewEntityOther
            },
            operators: {
                equals: 'Equals',
                in: 'Is Any Of',
                'not in': 'Is None Of',
                'starts with': 'Starts With',
                contains: 'Contains',
                'is known': 'Is Known',
                'is unknown': 'Is Unknown',
                gt: 'Greater Than',
                gte: 'Greater Than Or Equal To',
                lt: 'Less Than',
                lte: 'Less Than Or Equal To'
            },
            type: null,
            types: [
                { id: 'agents', name: 'Agents', icon: UserLove },
                { id: 'assets', name: 'Assets', icon: Packages },
                { id: 'attendance', name: 'Attendance', icon: ListSelect },
                { id: 'calendars', name: 'Calendars', icon: Calendar },
                { id: 'classes', name: 'Classes', icon: Apple },
                { id: 'courses', name: 'Courses', icon: Calculator },
                { id: 'days', name: 'Days', icon: BookmarkCircle },
                { id: 'departments', name: 'Departments', icon: DesignPencil },
                { id: 'districts', name: 'Districts', icon: Bank },
                { id: 'enrollments', name: 'Enrollments', icon: Desk },
                { id: 'facilities', name: 'Facilities', icon: Swimming },
                { id: 'fees', name: 'Fees', icon: Coin },
                { id: 'incidents', name: 'Incidents', icon: WarningCircle },
                { id: 'meetings', name: 'Meetings', icon: Presentation },
                { id: 'people', name: 'People', icon: Group },
                { id: 'periods', name: 'Periods', icon: Clock },
                { id: 'rooms', name: 'Rooms', icon: MathBook },
                { id: 'routes', name: 'Routes', icon: Map },
                { id: 'schools', name: 'Schools', icon: City },
                { id: 'sections', name: 'Sections', icon: Asana },
                { id: 'sessions', name: 'Sessions', icon: CalendarPlus },
                { id: 'stops', name: 'Stops', icon: PinAlt },
                { id: 'subjects', name: 'Subjects', icon: SineWave },
                { id: 'vehicles', name: 'Vehicles', icon: Truck }
            ]
        };
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.datatable {
    margin-top: 50px;
}

.toggle-title {
    font-size: 13px;
    color: @black;
    // margin-left: 8px;
    margin-right: 8px;
}

.tools {
    padding: 0 25px;
}

.dataset {
    padding: 0;
}

.dataset-tools {
    padding: 15px 25px;
    border-bottom: 1px solid @e4;
    height: 44px;
}

.dataset-tools {
    .btn {
        white-space: nowrap;
        // font-weight: 500;
        font-size: 13px;
        font-family: inherit;
        line-height: 15px;
        color: #8395a7;
        cursor: pointer;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;

        &-entity {
            margin-left: 25px;

            &:first-of-type {
                margin-left: 0;
            }
        }

        &-action {
            margin-left: 15px;

            &:last-of-type {
                color: @base;
            }
        }

        &:hover,
        &.active {
            color: #333;
        }
    }
}

.header {
    border-bottom: 1px solid @e4;

    .cell {
        .value {
            font-size: 11px;
            font-weight: 500;
            color: @black;
            line-height: 28px;
        }
    }
}

.cell {
    flex-grow: 1;
    flex-shrink: 0;

    .value {
        padding: 0 20px 0 0;
        color: @black;
        font-size: 12px;

        .text-button {
            font-size: 12px;
            line-height: 20px;
        }

        .value-type {
            color: @grey;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
            margin-left: 4px;
        }

        &.empty {
            color: @grey;
        }
    }

    &:last-child {
        .value {
            text-align: right;
            padding-right: 0;
        }
    }

    &.updated {
        .value {
            color: @base;
        }
    }

    &.override {
        .value {
            color: @base;
        }

        &::before {
            width: 16px;
            height: 16px;
            background: url('~@/assets/icons/base/pin.svg') 5px 4px no-repeat;
            background-size: 6px auto;
            position: absolute;
            top: 8px;
            left: -16px;
            bottom: auto;
            right: auto;
            content: '';
        }
    }
}

.row {
    min-width: 0;

    &.deleted {
        .cell {
            .value {
                color: @red;
                // text-decoration: line-through;
            }
        }
    }
}

.body {
    .loading {
        padding: 100px 0;
    }

    .cell {
        border-bottom: 1px solid @e4;
        background: @f;
        cursor: default;
        margin-top: -1px;
        border-top: 1px solid @e4;
        min-width: 0;

        .value {
            line-height: 31px;
            height: 30px;
        }
    }

    .row.focused {
        z-index: 5;

        .cell {
            // background: @base;
            // border-color: darken(@base, 10%);

            .value {
                // color: @f;

                .text-button {
                    // color: @f;
                }
            }

            .value-type {
                color: fade(@f, 60%);
            }
        }
    }

    .row:last-child {
        .cell {
            border-bottom: 0;
        }
    }
}

.no-results {
    padding: 50px;
}

.clear-filter {
    margin-left: 15px;
}
</style>
