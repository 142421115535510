<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <Bolt class="icon" width="16" height="16" stroke-width="2" />
                    Integrations
                </div>
            </template>
            <template v-slot:actions>
                <input class="filter search large" type="text" placeholder="Search by Integration ID, Team, Application, or Source" v-model="search"/>
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="states" :active="state" @activate="switch_state" label="State">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown> 
            <filter-dropdown :items="dates" :active="updated_date" @activate="switch_updated_date" label="Updated Date">
                <template v-slot:icon>
                    <Calendar class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="dates" :active="created_date" @activate="switch_created_date" label="Created Date">
                <template v-slot:icon>
                    <CalendarPlus class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="date-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="providers" :active="provider" @activate="switch_provider" label="Providers">
                <template v-slot:icon>
                    <MacOsWindow class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <div class="thumbnail">
                                <img class="block" :src="item.picture" />
                            </div>                            
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <div class="ff"></div>
        </filters>
        <datatable class="admin-integrations full" :columns="columns" :rows="results" :selectable="true" :header="true" @clicked="source => open(source)" :clickable="true" :loading="loading" />
    </main>
</template>

<script>
    import moment from 'moment';
    import { Bolt, AlignLeft, WhiteFlag, Calendar, CloudSync, CalendarPlus, MacOsWindow, Dollar } from '@epiphany/iconoir';
    import IntegrationBillingCategory from '@/components/chips/IntegrationBillingCategory.vue';

    export default {
        name: 'AdminIntegrations',
        components: {
            Bolt,
            AlignLeft,
            WhiteFlag,
            Calendar,
            CloudSync,
            CalendarPlus,
            MacOsWindow
        },
        data(){
            return {
                loading: true,
                integrations: [],
                search: '',
                state: null,
                columns: [
                    {
                        id: 'name',
                        name: 'District',
                        icon: AlignLeft,
                        width: '40%',
                        value: row => row.team.name
                    },
                    {
                        id: 'application',
                        name: 'Application',
                        icon: WhiteFlag,
                        width: '40%',
                        value: row => row.application.name
                    },
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '120px',
                        value: row => row.status
                    },
                    {
                        id: 'billing_category',
                        name: 'Billing',
                        icon: Dollar,
                        component: IntegrationBillingCategory,
                        value: row => ({ category: row.billing_category }),
                        width: '120px'
                    },
                    {
                        id: 'updated_date',
                        name: 'Updated Date',
                        icon: Calendar,
                        align: 'right',
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.updated_date, 'short')
                    }
                ],
                states: [
                    {
                        id: 'active',
                        name: 'Active',
                        icon: 'green'
                    },                    
                    {
                        id: 'paused',
                        name: 'Paused',
                        icon: 'green'
                    },
                    {
                        id: 'destroyed',
                        name: 'Destroyed',
                        icon: 'dark-red'
                    },
                    {
                        id: 'error',
                        name: 'Error',
                        icon: 'red'
                    },
                    {
                        id: 'disabled',
                        name: 'Disabled',
                        icon: 'yellow'
                    },
                    {
                        id: 'pending',
                        name: 'Pending',
                        icon: 'yellow'
                    },
                    {
                        id: 'inactive',
                        name: 'Inactive',
                        icon: 'grey'
                    },
                    {
                        id: 'requested',
                        name: 'Requested',
                        icon: 'blue'
                    },                    
                    {
                        id: 'scheduled',
                        name: 'Scheduled',
                        icon: 'blue'
                    }
                ],
                updated_date: null,
                created_date: null,
                dates: [
                    {
                        id: 'last-hour',
                        name: 'Last Hour',
                        icon: 'iconoir-wristwatch'
                    },
                    {
                        id: 'last-day',
                        name: 'Last Day',
                        icon: 'iconoir-sun-light'
                    },
                    {
                        id: 'last-week',
                        name: 'Last Week',
                        icon: 'iconoir-send-mail'
                    },
                    {
                        id: 'last-month',
                        name: 'Last Month',
                        icon: 'iconoir-calendar'
                    },
                    {
                        id: 'last-year',
                        name: 'Last Year',
                        icon: 'iconoir-cinema-old'
                    }
                ],
                provider: null,           
                providers: [
                    {
                        id: '97f551b3-6095-4eb7-88c3-780f17236429',
                        name: 'Aeries',
                        picture: '/source/aeries.png'
                    },
                    {
                        id: '68cc46aa-8463-4189-b8d3-fa2cc6748e92',
                        name: 'Aspen',
                        picture: '/source/aspen.png'
                    },
                    {
                        id: 'b836c842-7d5f-4763-b7e4-2c69f6a981d6',
                        name: 'Blackbaud',
                        picture: '/source/blackbaud.png'
                    },
                    {
                        id: 'b7e1526e-f627-4d86-99c2-465f81a0f0f8',
                        name: 'Blackboard',
                        picture: '/source/blackboard.png'
                    },
                    {
                        id: '5ab3b051-2976-442a-a60c-4cbee43968ca',
                        name: 'Brightspace',
                        picture: '/source/brightspace.png'
                    },
                    {
                        id: '0e8c78a5-29eb-43e6-9c61-1f53b3cd5f53',
                        name: 'Bromcom',
                        picture: '/source/bromcom.png'
                    },    
                    {
                        id: '07de3f01-6b1f-4ba2-9fc0-7d983fa53c9a',
                        name: 'CSV',
                        picture: '/source/csv.png'
                    },
                    {
                        id: '035d6dd6-1569-47b2-8ed8-63435be58078',
                        name: 'Canvas',
                        picture: '/source/canvas.png'
                    },         
                    {
                        id: '28f3beb9-a2af-46f6-ad31-1f10db7a7366',
                        name: 'Classlink',
                        picture: '/source/classlink.png'
                    },
                    {
                        id: 'f78ddf8d-ace1-4b24-bb14-2e5c6704c76b',
                        name: 'Clever',
                        picture: '/source/clever.png'
                    },
                    {
                        id: '2f648cbc-e231-44dc-9d73-50ff8a9ccf1b',
                        name: 'FACTS',
                        picture: '/source/facts.png'
                    },        
                    {
                        id: '0ee5e450-4a9e-4b7d-bd30-62b33cdd5456',
                        name: 'Google Classroom',
                        picture: '/source/google.png'
                    },
                    {
                        id: 'b69fb425-e42c-4f63-8d41-840ac4b4e03f',
                        name: 'HelloID',
                        picture: '/source/helloid.png'
                    },
                    {
                        id: 'b187e934-4297-4ea6-86c3-bb81583b5009',
                        name: 'Illuminate',
                        picture: '/source/illuminate.png'
                    },
                    {
                        id: 'a9cbd664-f12c-4627-a520-7582064f0ca4',
                        name: 'Infinite Campus',
                        picture: '/source/infinitecampus.png'
                    },
                    {
                        id: '746abfcf-dab2-4079-a899-72433b0d7afb',
                        name: 'LTI 1.3',
                        picture: '/source/oneroster.png'
                    },
                    {
                        id: '5fe38a52-5c3a-4323-8f91-29327ae7c3ca',
                        name: 'Microsoft Teams',
                        picture: '/source/microsoft.png'
                    },
                    {
                        id: '050da476-d03a-41d9-8d6c-3462910d93ca',
                        name: 'Moodle',
                        picture: '/source/moodle.png'
                    },
                    {
                        id: 'd07c5f8c-e88c-4f70-b073-0f150b488db0',
                        name: 'OneRoster',
                        picture: '/source/oneroster.png'
                    },
                    {
                        id: 'f969915a-41cb-4875-8f9b-da6370e5a1b6',
                        name: 'Powerschool',
                        picture: '/source/powerschool.png'
                    },
                    {
                        id: '55c78d6f-504b-4529-a655-b157f23c86f0',
                        name: 'RapidIdentity',
                        picture: '/source/rapididentity.png'
                    },
                    {
                        id: 'ff2988d2-e83c-44c7-aa46-07e889c03e62',
                        name: 'SFTP',
                        picture: '/source/sftp.png'
                    },
                    {
                        id: '47eda4bf-7440-4cd8-9ee7-44cafb98486b',
                        name: 'Schoology',
                        picture: '/source/schoology.png'
                    },                                    
                    {
                        id: '121480b2-65bc-4628-a535-843d70fe78dd',
                        name: 'Skyward',
                        picture: '/source/skyward.png'
                    },
                    {
                        id: '135d8091-9773-4f21-acf6-43ed1a51647d',
                        name: 'Veracross',
                        picture: '/source/veracross.png',
                    }
                ],
                region: null,
                regions: [
                    {
                        id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                        name: 'USA',
                        picture: '/flags/us.svg'
                    },
                    {
                        id: '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd',
                        name: 'Canada',
                        picture: '/flags/ca.svg'
                    },
                    {
                        id: '2104f649-fe39-4fa1-96be-8682e9840dcf',
                        name: 'Australia',
                        picture: '/flags/au.svg'
                    },
                    {
                        id: '2572d321-6d8b-458b-8024-8e5ab6bad7b6',
                        name: 'Germany',
                        picture: '/flags/de.svg'
                    }                    
                ]
            };
        },
        created(){
            this.load();
        },
        computed: {
            results(){
                const search_val = this.search?.toLowerCase();
                const reg_exp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                const is_uuid = reg_exp.test(search_val);

                return this.integrations.filter(integration => {                    
                    if(this.state){                             
                        if(integration.status !== this.state.id){
                            //delete the integration from the map if the states dont match
                            return false;
                        }                                                         
                    }

                    if(this.search){
                        if(is_uuid){
                            if(integration.team.id !== search_val && integration.id !== search_val && integration.source.id !== search_val && integration.application.id !== search_val){
                                return false;
                            }
                        } else {        
                            const team_name = integration.team.name?.toLowerCase();
                            const source_name = integration.source.name?.toLowerCase();                    
                            const application_name = integration.application.name?.toLowerCase();                    
                            if(!team_name.includes(search_val) && !source_name.includes(search_val) && !application_name.includes(search_val)){
                                return false;
                            }
                        }
                    }

                    if(this.updated_date){                                 
                        const adjusted_time_updated = moment();                                                                                     
                        if(this.updated_date.id === 'last-hour'){                                                   
                            adjusted_time_updated.subtract(1, 'hour');                           
                        } else if(this.updated_date.id === 'last-day'){               
                            adjusted_time_updated.subtract(1, 'day');                          
                        } else if(this.updated_date.id === 'last-week'){              
                            adjusted_time_updated.subtract(1, 'week');                           
                        } else if(this.updated_date.id === 'last-month'){                   
                            adjusted_time_updated.subtract(1, 'month');                            
                        } else if(this.updated_date.id === 'last-year'){                            
                            adjusted_time_updated.subtract(1, 'year');
                        }                                               
                        if(moment(integration.updated_date) < adjusted_time_updated){                        
                            return false;
                        }                                            
                    }

                    if(this.created_date){       
                        const adjusted_time_created = moment();              
                        if(this.created_date.id === 'last-hour'){
                            adjusted_time_created.subtract(1, 'hour');
                        } else if(this.created_date.id === 'last-day'){                        
                            adjusted_time_created.subtract(1, 'day');
                        } else if(this.created_date.id === 'last-week'){
                            adjusted_time_created.subtract(1, 'week');
                        } else if(this.created_date.id === 'last-month'){
                            adjusted_time_created.subtract(1, 'month');
                        } else if(this.created_date.id === 'last-year'){
                            adjusted_time_created.subtract(1, 'year');
                        }                        
                        if(moment(integration.created_date) < adjusted_time_created){
                            return false;
                        }                                            
                    }

                    if(this.region){
                        if(this.region.id !== integration.region.id){
                            return false;
                        }    
                    }

                    if(this.provider){                                               
                        if(this.provider.id !== integration.provider.id){
                            return false;
                        }                   
                    }

                    return true;
                }).slice(0,100);
            }
        },
        methods: {      
            open(integration){
                this.$router.push({name: 'team.integrations.integration', params: {team: integration.team.alias, integration: integration.id}});
            },  
            status(integration){
                return {
                    red: ['error', 'destroyed'].includes(integration.status),
                    yellow: ['disabled'].includes(integration.status),
                    green: ['active'].includes(integration.status),
                    grey: ['inactive'].includes(integration.status)
                };
            },
            switch_state(state){                
                this.state = state;               
            },
            switch_provider(provider){                
                this.provider = provider;               
            },
            switch_updated_date(updated_date){                
                this.updated_date = updated_date;               
            },
            switch_created_date(created_date){                
                this.created_date = created_date;               
            },
            switch_region(region){                
                this.region = region;               
            },
            load(){
                const params = {
                    //the total (maximum) amount of integrations loaded                    
                    $first: 10000
                };
                
                this.$http.get('/admin/integrations', {params})
                .then(response => this.integrations = response.$data)
                .then(() => this.loading = false)
                .catch(error => this.$toasted.error('There was an error loading integrations.'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }
    
    .admin-integrations
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .integration-icon
    {
        width: 40px;
        padding-right: 0;
        img
        {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: @d;
            display: block;
            margin: 8px auto;
        }
    }
    .icon-status
    {
        margin-right: 8px;
    }
    .integration-name
    {
        line-height: 16px;
    }
    .enrichment-status
    {
        margin-top: 4px;
    }
    .integration-team
    {
        .team-name
        {
            color: @black;
        }
        .unknown
        {
            color: @grey;
        }
    }

    .thumbnail
    {
        width: 16px;
        height: 16px;

        img
        {
            border-radius: 50%;
        }

        &::after
        {
            width: 16px;
            height: 16px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;
        &.red
        {
            background: @red;
        }
        &.blue
        {
            background: @base;
        }
        &.purple
        {
            background: @purple;
        }
        &.yellow
        {
            background: @yellow;
        }
        &.dark-red
        {
            background: @hover;
        }
        &.grey
        {
            background: @c;
        }
    }
</style>