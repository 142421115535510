<template>
    <div class="integrate-district-claimed">
        <h1>{{ team.name }} is already set up on Edlink</h1>
        <div class="helptext">
            It looks like someone else at your <span class="lowercase">{{ team.descriptor }}</span> has already set up a connection to Edlink in the past.
            There are a few options to proceed with the {{ application.name }} onboarding process.
        </div>
        <placard :selectable="selectable && !requested" @select="request" v-if="owners.length" :class="{purple: requested}">
            <template v-slot:icon>
                <spinner class="white mini" v-if="requesting" />
                <Check v-else-if="requested" class="icon" width="16" height="16" stroke-width="2" />
                <Crown v-else class="icon" width="16" height="16" stroke-width="2" />
            </template>
            <template v-slot:title>
                <template v-if="requested">Requested access</template>
                <template v-else>Request access from {{ names }}</template>
            </template>
            <template v-slot:description>
                <template v-if="requested">We'll notify you if your request is accepted</template>
                <template v-else>This will send an email to {{ emails }}</template>
            </template>
        </placard>
        <placard :selectable="selectable" @select="create" v-if="!onboarding?.team?.id">
            <template v-slot:icon>
                <City class="icon" width="16" height="16" stroke-width="2" />
            </template>
            <template v-slot:title>Create a new organization</template>
            <template v-slot:description>
                This option will allow you to continue setup with a new institution
            </template>
        </placard>
        <placard :selectable="selectable" @select="help">
            <template v-slot:icon>
                <HeadsetHelp class="icon" width="16" height="16" stroke-width="2" />
            </template>
            <template v-slot:title>Believe there was an error? Get in touch.</template>
            <template v-slot:description>
                We can help you get added to the correct organization
            </template>
        </placard>
        <placard :selectable="selectable" @select="() => {}" v-if="false">
            <template v-slot:icon>
                <PeopleTag class="icon" width="16" height="16" stroke-width="2" />
            </template>
            <template v-slot:title>Authenticate via [SSO Provider]</template>
            <template v-slot:description>
                Authenticate as an administrator via [SSO Provider] to continue setup
            </template>
        </placard>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { City, HeadsetHelp, PeopleTag, Crown, Check } from '@epiphany/iconoir';
    import { ROBLOX_APPLICATION_IDS } from '@/constants';
    
    export default {
        name: 'IntegrateDistrictClaimed',
        props: {
            state: Object,
            send: Function
        },
        components: {
            City,
            HeadsetHelp,
            PeopleTag,
            Crown,
            Check
        },
        data(){
            return {
                owners: [],
                requesting: false,
                creating: false,
                requested: false
            }
        },
        computed: {
            selectable(){
                return !this.requesting && !this.creating;
            },
            onboarding(){
                return this.$store.state.integrate.onboarding;
            },
            team(){
                return this.state.context.team;
            },
            application(){
                return this.state.context.application;
            },
            license_key_required(){
                return ROBLOX_APPLICATION_IDS.includes(this.state.context?.application?.id);
            },
            names(){
                const names = [];

                for(const owner of this.owners){
                    if (owner.first_name) {
                        names.push(`${owner.first_name} ${owner.last_name}`);
                    }
                }

                if(names.length === 0){
                    return 'the team owner';
                }

                return names.join(', ');
            },
            emails(){
                for(const owner of this.owners){
                    if(owner.email){
                        return owner.email;
                    }
                }

                return 'the owner requesting access to the team';
            }
        },
        created(){
            this.$http.get(`/institutions/${this.team.id}/owners`).then(response => {
                this.owners = response.$data;
            });
        },
        methods: {
            async create(){
                // Create a duplicate district with the same name and add this person to it.
                // If this is an onboarding with an existing team_id set, just throw an error.
                if(this.onboarding?.team?.id){
                    return this.$toasted.error('You cannot create a new organization to complete this onboarding.');
                }

                try {
                    const { $data: team } = await this.$http.post('/teams', { type: 'district', name: this.team.name });

                    // Set this new team as the active team.
                    await this.$store.dispatch('teams/add', team);
                    await this.$store.dispatch('teams/activate', team);

                    // Check to see if we need to mark the onboarding as "started".
                    if(this.onboarding?.state === 'created'){
                        await this.$http.post(`/teams/${response.$data.id}/onboardings/${this.onboarding.id}/claim`);
                    }

                    this.send({ type: 'CONFIRM_DISTRICT', data: team });

                    // If integrating with Roblox we want to collect the license key
                    if (this.license_key_required) {
                        return this.send('LICENSE');
                    }

                    this.send('CREATE_SOURCE');
                } catch (error) {
                    this.$toasted.error(error?.response?.$data ? error.response.$data.details : 'There was an error creating your organization.');
                }
            },
            help(){
                this.$store.dispatch('hubspot/open');
            },
            request(){
                this.$http.post(`/institutions/${this.team.id}/request`)
                .then(response => {
                    this.requested = true;
                })
                .catch(error => {
                    this.$toasted.error(error?.response?.$data ? error.response.$data.details : 'There was an error requesting access to the organization.');
                })
                .finally(() => {
                    this.requesting = false;
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-district-claimed
    {
        h1
        {
            line-height: 30px;
            max-width: 550px;
            margin-bottom: @single-padding;
        }

        .helptext
        {
            margin-bottom: @double-padding;
        }

        .placard::v-deep
        {
            width: 550px;
            margin-top: @single-padding;
            padding: @single-padding @single-padding + 2;

            .placard-icon
            {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                margin-right: @single-padding + 2;
            }

            h4
            {
                margin-top: 4px;
                font-size: 13px;
            }

            h3
            {
                font-size: 15px;
                letter-spacing: -0.01rem;
                line-height: 18px;
                margin-top: 2px;
            }
        }
    }
</style>