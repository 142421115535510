<template>
    <div class="forgot">
        <div class="forgot-container constrain bc flex flex-align flex-column">
            <a class="flex flex-align wordmark block" href="https://ed.link"></a>
            <div class="alert center">
                This page is for only school administrators and product developers.
                If you are trying to sign into your account on a different platform, please visit their website instead.
                <div class="flex flex-center">
                    <a class="text-button learn-more block" href="/docs/schools/what-is-edlink" target="_blank">Learn More</a>
                </div>
            </div>
            <div class="sent card" v-if="sent">
                <div class="sent-icon bc"></div>
                <h4 class="center">Recovery Email Sent</h4>
                <div class="description">
                    A recovery email was sent to your inbox. Follow the instructions to finish resetting your password.
                    This process will not reset your two-factor authorization code.
                </div>
            </div>
            <template v-else>
                <form class="card" @submit.prevent="recover" autocomplete="off">
                    <h3 class="center">Forgot Your Password</h3>
                    <label for="email">Email Address</label>
                    <input type="text" v-model="user.email" name="email" autocomplete="off" :class="{error: error.email}" />
                    <div class="form-error center" v-if="error.message">{{error.message}}</div>
                    <button type="submit">Send Recovery Email</button>
                </form>
            </template>
            <div class="form-disclaimer">For additional help, please <a href="https://ed.link/support" class="dashed-button" target="_blank">contact us</a>.</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Forgot',
        data(){
            return {
                sent: false,
                error: {
                    message: '',
                    email: false
                },
                user: {
                    email: ''
                }
            };
        },
        methods: {
            recover(){
                this.error.email = false;
                this.error.message = '';

                if(!this.user.email){
                    this.error.message = 'Please enter a valid email address.';
                    this.error.email = true;
                    return;
                }

                this.$http.post('/forgot', this.user)
                .then(() => this.sent = true)
                .catch(error => {
                    this.$toasted.error('There was an error sending a recovery email.');
                    console.log(error);
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .forgot
    {
        min-height: 100vh;
    }

    .wordmark
    {
        height: 50px;
        background: url('~@/assets/edlink-diamonds-black.svg') 0 0 no-repeat;
        background-size: 160px auto;
        color: transparent;
        width: 174px;
    }

    .alert
    {
        max-width: 400px;
        padding: 20px 15px 15px;
        margin-top: 30px;
        font-size: 12px;
        line-height: 16px;

        .learn-more
        {
            margin-top: 10px;
            font-size: 12px;
            padding-right: 20px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -1px top 2px;
            background-size: 16px auto;
        }
    }

    .forgot-container
    {
        padding: 80px 50px;
    }

    form
    {
        width: 100%;
        max-width: 400px;
        padding: 30px;
        margin-top: 30px;

        button
        {
            width: 100%;
            margin-top: 30px;
        }
    }

    h3
    {
        font-weight: 500;
    }

    .sent
    {
        max-width: 400px;
        padding: 50px;
        margin-top: 30px;
        text-align: center;

        h4
        {
            font-size: 18px;
            font-weight: 500;
        }

        .description
        {
            font-size: 14px;
            color: @grey;
            margin-top: 10px;
            line-height: 18px;
        }

        .sent-icon
        {
            width: 48px;
            height: 48px;
            background: url('~@/assets/icons/base/send.svg') center center no-repeat;
            background-size: 48px auto;
            margin-bottom: 30px;
        }
    }
</style>