import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';

Vue.use(Vuex);

const getters = {
    user: (state) => state.user,
    team: (state) => state.teams.active,
    source: (state) => state.sources.active,
    entity: (state) => state.entities.active,
    location: (state) => state.locations.active,
    organization: (state) => state.organizations.active,
    integration: (state) => state.integrations.active,
    migration: (state) => state.migrations.active,
    application: (state) => state.applications.active,
    providers: (state) => Object.values(state.providers.all),
    provider: (state) => (id) => state.providers.all[id],
    permission: (state) => (id) => state.permissions.all[id],
    permissions: (state) => Object.values(state.permissions.all),
    banners: (state) =>
        state.banners.all.filter((banner) => {
            if (state.banners.dismissed.includes(banner.id)) {
                return false;
            }

            if (_.has(banner, 'visibility.start')) {
                if (new Date(banner.visibility.start) > new Date()) {
                    return false;
                }
            }

            if (_.has(banner, 'visibility.end')) {
                if (new Date(banner.visibility.end) < new Date()) {
                    return false;
                }
            }

            return true;
        })
};

export default new Vuex.Store({
    modules,
    getters,
    strict: process.env.NODE_ENV !== 'production'
});
