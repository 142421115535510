<template>
    <div class="custom-admin-source-select flex flex-align flex-center">
        <div class="custom-admin-select-list integrate-state">
            <h1>Select or Create a Source</h1>
            <div class="helptext">
                Looks like you have already connected {{ state.context.provider.name }} to Edlink. You can select an existing source or create a new one.
            </div>
            <div class="integration-options">
                <transition-group name="slide-in" tag="div" class="integration-options">
                    <div class="integration-option flex flex-align" v-for="source of filtered" :key="source.id" @click="setSource(source)">
                        <div class="option-icon">
                            <img :src="source.provider.icon_url" />
                        </div>
                        <div class="option-name text-overflow">{{ source.name }}</div>
                    </div>
                    <div class="integration-option flex flex-align pointer" key="create-source" @click="send('CREATE_SOURCE')">
                        <span class="icon iconoir-plus block"></span>
                        <div class="option-name">Create a new source</div>
                        <div class="caret"></div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomAdminSourceSelect',
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            application() {
                return this.state.context.application;
            },
            sources() {
                return this.state.context.sources;
            },
            filtered() {
                return this.sources?.filter(source => {
                    if (this.state.context.provider.application !== source.provider.application) {
                        return false;
                    }

                    if (source.status === 'destroyed') {
                        return false;
                    }

                    return true;
                })
            }
        },
        methods: {
            setSource(source) {
                this.send({ type: 'SOURCE', data: source });
                this.send('NEXT');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .custom-admin-select.integrate-state
    {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        padding: 80px 0;
        max-width: none;

        h1
        {
            line-height: 36px;
        }

        .integration-options
        {
            margin-top: 6px;
        }

        .integration-option
        {
            &:last-child
            {
                color: @grey;
            }
        }

        .disabled {
            color: @lightgrey;
            cursor: auto;
        }
    }

    .integration-option {
        padding: 10px 0;
        cursor: pointer;
        font-size: 14px;
        color: @base;
        line-height: 18px;

        .icon
        {
            font-size: 20px;
            margin: 0 10px 0 0;
        }

        &:hover,
        &:active {
            .caret {
                .translate3d(6px, 0, 0);
            }
        }

        .caret {
            width: 14px;
            height: 14px;
            background: url('~@/assets/icons/grey/chevron-right.svg') -2px -1px no-repeat;
            background-size: 18px auto;
            display: block;
            content: "";
            margin-left: 10px;
            transition: all ease 0.2s;
        }

        .option-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: -4px 10px -4px 0;

            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }

            &::before {
                width: 24px;
                height: 24px;
                position: absolute;
                z-index: 2;
                content: "";
                top: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }

            .icon {
                color: @grey;
                font-size: 24px;
                display: inline-block;
                height: 24px;
                width: 24px;
            }
        }

        .icon.iconoir-plus {
            padding: 2px;
        }
    }
</style>
