<template>
    <div class="admin-billing">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'AdminBilling',
        computed: {
            
        },
        methods: {

        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .admin-billing
    {
        width: 100%;
        height: 100%;
    }
</style>
