const state = {
    actions: [],
    visible: false,
    error: false,
    contracted: true
};

const mutations = {
    save(state, action) {
        if (!state.actions.includes(action)) {
            state.actions.push(action);
        }

        state.visible = true;
        state.contracted = false;
    },
    saved(state, action) {
        state.actions.splice(state.actions.indexOf(action), 1);
    },
    hide(state) {
        if (state.actions.length === 0) {
            state.visible = false;
        }
    },
    error(state, action = true) {
        state.error = action;
        state.actions = [];
    },
    contract(state) {
        if (state.visible === false) {
            state.contracted = true;
        }
    }
};

const actions = {
    save({ commit }, action) {
        commit('save', action);
    },
    error({ commit }) {
        commit('error', true);

        // Show the "error" status message for 3 extra seconds.
        setTimeout(() => commit('hide'), 3000);
        setTimeout(() => commit('contract'), 3250);
        setTimeout(() => commit('error', false), 3250);
    },
    saved({ commit }, action) {
        commit('saved', action);

        // Show the "saved" status message for 3 extra seconds.
        setTimeout(() => commit('hide'), 3000);
        setTimeout(() => commit('contract'), 3250);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
