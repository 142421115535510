import { render, staticRenderFns } from "./TwoFactorSetup.vue?vue&type=template&id=5c568b8c&scoped=true"
import script from "./TwoFactorSetup.vue?vue&type=script&lang=js"
export * from "./TwoFactorSetup.vue?vue&type=script&lang=js"
import style0 from "./TwoFactorSetup.vue?vue&type=style&index=0&id=5c568b8c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c568b8c",
  null
  
)

export default component.exports