<template>
    <div class="options">
        <div class="options-title" :class="{description}">{{title ? title : 'Select an Option'}}</div>
        <div class="options-description" v-if="description">{{description}}</div>
        <div class="options-list">
            <a class="option" v-for="(option, index) of options" :key="index" @click="select(option)" :class="option.color">{{option.title}}</a>
            <a class="option grey" @click="$emit('close')">Cancel</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Options',
        props: {
            title: String,
            description: String,
            options: Array
        },
        methods: {
            select(option){
                option.fn();
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    @option_height: 44px;

    .options
    {
        width: 300px;
    }

    .options-title
    {
        font-size: 14px;
        color: @grey;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        text-align: center;

        &.description
        {
            line-height: 24px;
            padding-top: 15px;
            height: 39px;
        }
    }

    .options-description
    {
        font-size: 13px;
        color: @black;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        padding: 6px 15px 15px;
    }

    .option
    {
        display: block;
        height: @option_height;
        line-height: @option_height;
        font-weight: 500;
        border-top: 1px solid @e;
        text-align: center;
        font-size: 16px;
        color: @base;
        transition: background-color ease 0.2s;
        cursor: pointer;
        user-select: none;

        &.grey
        {
            color: @grey;
        }

        &.red
        {
            color: @red;
        }

        &:hover, &:active
        {
            background: @f8;
        }
    }
</style>
