<template>
    <div class="information">
        <div class="bc constrain">
            <h1>
                <slot name="title" />
            </h1>
            <h2>
                <slot name="description" />
            </h2>
            <placard-group class="placards" v-if="$slots.placards">
                <slot name="placards" />
            </placard-group>
            <div class="details">
                <slot name="details" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Information',
        props: {
            
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .information
    {
        .bc.constrain
        {
            max-width: 1400px;
            padding: 100px 120px;
        }
    }
    
    h1
    {
        font-size: 44px;
        line-height: 50px;
        max-width: 700px;
    }
    
    h2
    {
        font-size: 18px;
        color: @grey;
        font-weight: 400;
        max-width: 800px;
        line-height: 24px;
    }

    h1 + h2
    {
        margin-top: @single-padding;
    }

    .placards
    {
        margin-top: @double-padding;
    }

    .details
    {
        margin-top: @single-padding * 10;
        // padding-top: @double-padding * 3;
        // border-top: 1px solid @e;
    }

    h1 + .details, h2 + .details
    {
        margin-top: @single-padding * 10;
    }
</style>