<template>
    <div class="integrate-source-provider-powerschool">
        <integrate-source-editor-step name="Enter Your Powerschool URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your Powerschool server is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.powerschool.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Download the Edlink Plugin" icon="iconoir-download"  :complete="completed.includes('plugin')">
            <div class="helptext">
                Download the Edlink Powerschool plugin.
            </div>
            <div class="flex">
                <div class="button has-icon" :class="{disabled: !completed.includes('url')}" @click="download()">
                    <Download class="icon" width="16" height="16" stroke-width="2" /> Download Powerschool Plugin
                </div>
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Install the Edlink Plugin" icon="iconoir-ev-plug" :complete="completed.includes('remote')">
            <integrate-source-editor-configuration title="How to install the Edlink Plugin" icon="iconoir-info-empty">
                <div class="image-label flex flex-align flex-wrap">
                    <div v-tooltip.top="!completed.includes('url') ? 'Enter your Powerschool URL' : ''">
                        1. Go to the Powerschool <a class="configuration-link inline-block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="settings_url">
                            Plugin Installation Page
                        </a>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Click <code>Choose File</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/choose-file.png', { title: 'Plugin Installation Page', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Select the Edlink Powerschool Plugin that you downloaded.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/select-zip.png', { title: 'Select Edlink Plugin', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>4. Click <code>Install</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/install-plugin.png', { title: 'Install Plugin', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <div v-tooltip.top="!completed.includes('url') ? 'Enter your Powerschool URL' : ''">
                        5. Go to the Powerschool <a class="configuration-link inline-block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="plugin_url">
                            Plugin Configuration Page
                        </a>
                    </div>
                    <!-- <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/.png', { title: 'Mike', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div> -->
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>6. Enable the Plugin.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/enable-plugin.png', { title: 'Enable Plugin', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter the OAuth Client ID and Secret" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <integrate-source-editor-configuration title="How to find the Edlink Plugin Credentials" icon="iconoir-info-empty">
                <div class="image-label flex flex-align flex-wrap">
                    <div v-tooltip.top="!completed.includes('url') ? 'Enter your Powerschool URL' : ''">
                        1. Go to the Powerschool <a class="configuration-link inline-block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="plugin_url">
                            Plugin Configuration Page
                        </a>
                    </div>
                    <!-- <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/.png', { title: 'Mike', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div> -->
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Click on <code>Edlink PowerQueries</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/plugin-setup.png', { title: 'Enable Plugin', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Click on <code>Data Provider Configuration</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/plugin-config.png', { title: 'Enable Plugin', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>4. Copy the value labeled <code>Client ID</code> and paste it below.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/client-id.png', { title: 'Certificate Value', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.application_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_id', value: e.target.value})" />

                <div class="image-label flex flex-align flex-wrap">
                    <label>5. Copy the value labeled <code>Client Secret</code> and paste it below.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/powerschool/onboarding/client-secret.png', { title: 'Certificate Value', width: 1000 })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <input class="medium" type="text" placeholder="Client Secret" :value="source.configuration.application_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_secret', value: e.target.value})" />

            </integrate-source-editor-configuration>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';
    import { Download } from '@epiphany/iconoir'

    export default {
        name: 'IntegrateSourceProviderSchoology',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration,
            Download
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url || !this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Confirm plugin was downloaded.
                if(!this.plugin){
                    return ['url'];
                }

                // 3. Confirm remote configuration is completed.
                if(!this.remote){
                    return ['url', 'plugin'];
                }

                // 4. Confirm that keys are set
                if(!this.source.configuration.application_id?.trim().length || !this.source.configuration.application_secret?.trim().length){
                    return ['url', 'plugin', 'remote'];
                }

                return ['url', 'plugin', 'remote', 'keys'];
            },
            settings_url(){
                return this.completed.includes('url') ? new URL('/admin/pluginconsole/importNewPluginInput.action', this.source.configuration.url).href : false;
            },
            plugin_url(){
                return this.completed.includes('url') ? new URL('/admin/pluginconsole/plugInConsole.action', this.source.configuration.url).href : false;
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        data(){
            return {
                remote: false,
                plugin: false
            };
        },
        methods: {
            download() {
                window.open(`https://edlink.github.io/docs/media/plugins/powerschool/${this.state.context.essential ? 'edlink-essential' : 'edlink'}.zip`, '_blank');
                this.plugin = true;
            },
            image(src, config = { title: null, width: 700, height: 'auto' }){
                this.$modal.show(HelpImage, { src, title: config.title }, { width: config.width ?? 700, height: config.height ?? 'auto', classes: 'modal' });
            },
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }
</style>
