import { render, staticRenderFns } from "./Healthcare.vue?vue&type=template&id=6b64197c"
import script from "./Healthcare.vue?vue&type=script&lang=js"
export * from "./Healthcare.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports