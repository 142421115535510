<template>
    <div class="subscription-state flex flex-align" :class="state">
        <Calendar class="icon" width="14" height="14" stroke-width="2" />
        <div class="capitalize">{{ title }}</div>
    </div>
</template>

<script>
    import { Calendar } from '@epiphany/iconoir';

    export default {
        name: 'SubscriptionState',
        components: {
            Calendar
        },
        props: {
            state: String,
            start_date: String,
            end_date: String
        },
        computed: {
            title(){
                if(this.state === 'active'){
                    if(this.end_date){
                        return `Active until ${this.$options.filters.pretty(this.end_date, 'MMM Do, YYYY')}`;
                    }
                }

                if(this.state === 'inactive'){
                    if(this.start_date){
                        return `Starts on ${this.$options.filters.pretty(this.start_date, 'MMM Do, YYYY')}`;
                    }
                }

                if(this.state === 'ended'){
                    if(this.end_date){
                        return `Ended on ${this.$options.filters.pretty(this.end_date, 'MMM Do, YYYY')}`;
                    }
                }

                return this.state;
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .subscription-state
    {
        font-size: 12px;
        font-weight: 500;
        color: @grey;

        .icon
        {
            margin-right: 6px;
        }

        // &.active
        // {
        //     color: @green;
        // }

        // &.inactive
        // {
        //     color: @purple;
        // }

        // &.canceled
        // {
        //     color: @grey;
        // }

        // &.paused
        // {
        //     color: @yellow;
        // }

        // &.ended
        // {
        //     color: @red;
        // }
    }
</style>
