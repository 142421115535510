<template>
    <div class="integrate-district-create full scroll">
        <div class="district-search-container bc">
            <template v-if="onboarding?.team?.id">
                <h1>Confirm Your District or University</h1>
                <div class="helptext">
                    <template v-if="district.status === 'inactive'">
                        By continuing the onboarding process and claiming this organization, you confirm that
                        you are an administrator or authorized representative of {{ district.name ? district.name : 'Untitled District' }}.
                    </template>
                    <template v-else>
                        This onboarding link was created for {{ district.name ? district.name : 'Untitled District' }} by {{ state.context.application.team.name }}.
                    </template>
                </div>
            </template>
            <template v-else>
                <h1>Find Your District or University</h1>
                <div class="helptext">
                    Search for your school, district, or university by name, city, or ZIP code.
                    If you can't find it, create a new one by choosing the option at the bottom of the list.
                </div>
            </template>
            <template v-if="district">
                <placard :selectable="!onboarding?.team?.id" class="selected-district district-result" @select="change">
                    <template v-slot:icon>
                        <img v-if="district.icon_url" :src="district.icon_url" />
                        <City v-else class="icon" width="16" height="16" stroke-width="2" />
                    </template>
                    <template v-slot:title>
                        <div class="flex flex-align">
                            <div class="district-title ff text-overflow flex flex-align">
                                {{ district.name ? district.name : 'Untitled District' }}
                                <div class="verified" v-if="district.verified"></div>
                            </div>
                            <div class="district-capabilities flex flex-align">
                                <div class="district-capability flex flex-align enabled">
                                    {{ description(district) }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <a class="text-button mini none disabled" v-if="onboarding?.team?.id">Invited by {{ state.context.application.team.name }}</a>
                        <a class="text-button mini none" @click="change" v-else>Change</a>
                    </template>
                </placard>
                <div class="claim-warning helptext" v-if="district.status === 'inactive'">
                    By claiming this organization, you confirm that you are an administrator or authorized representative of {{ district.name ? district.name : 'Untitled District' }}.
                </div>
            </template>
            <template v-else>
                <div class="district-search card flex flex-align">
                    <Search class="icon" width="20" height="20" stroke-width="2" />
                    <input class="district-search-input ff" v-model="query" @input="search" placeholder="Search for a district or university" autocomplete="false" />
                    <div class="district-search-loading">
                        <spinner v-if="loading" />
                    </div>
                </div>
                <div class="district-results">
                    <placard v-for="result of districts" :key="result.id" class="district-result" :class="result.status" :selectable="true" @select="() => select_district(result)">
                        <template v-slot:icon>
                            <img v-if="result.icon_url" :src="result.icon_url" />
                            <City v-else class="icon" width="16" height="16" stroke-width="2" />
                        </template>
                        <template v-slot:title>
                            <div class="flex flex-align">
                                <div class="district-title ff text-overflow flex flex-align">
                                    {{ result.name ? result.name : 'Untitled District' }}
                                    <div class="verified" v-if="result.verified"></div>
                                </div>
                                <div class="district-capabilities flex flex-align">
                                    <div class="district-capability flex flex-align enabled" v-if="result.status === 'active'">
                                        <Check class="icon" width="16" height="16" stroke-width="2" />
                                        Claimed
                                    </div>
                                    <div class="district-capability flex flex-align" v-else>
                                        <Cancel class="icon" width="16" height="16" stroke-width="2" />
                                        Unclaimed
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:description>{{ description(result) }}</template>
                    </placard>
                    <placard key="create-district" class="district-result inactive" :selectable="true" @select="() => create_district()" v-if="!loading && query">
                        <template v-slot:icon>
                            <Plus class="icon" width="16" height="16" stroke-width="2" />
                        </template>
                        <template v-slot:title>
                            <div class="flex flex-align">
                                <div class="district-title ff text-overflow">Create <span class="underline">{{ query }}</span></div>
                                <div class="district-capabilities flex flex-align">
                                    <div class="district-capability flex flex-align enabled">
                                        <Plus class="icon" width="16" height="16" stroke-width="2" />
                                        New Organization
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:description>
                            Select this option to create a new institution.
                        </template>
                    </placard>
                </div>
            </template>
            <div class="options flex">
                <div class="button has-icon has-icon-right" kbd="Enter ⏎" :class="{disabled: !district, loading: creating}" @click="next">
                    {{ district?.status === 'inactive' ? 'Claim Organization' : 'Continue' }}
                    <spinner v-if="creating" class="white" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Search, City, Plus, Check, Cancel } from '@epiphany/iconoir';
    import { ROBLOX_APPLICATION_IDS } from '@/constants';

    export default {
        name: 'IntegrateDistrictCreate',
        props: {
            state: Object,
            send: Function
        },
        components: {
            Search,
            City,
            Plus,
            Check,
            Cancel
        },
        data(){
            return {
                query: '',
                loading: false,
                throttle: null,
                districts: [],
                district: null,
                creating: false
            };
        },
        computed: {
            onboarding(){
                return this.$store.state.integrate.onboarding;
            },
            teams(){
                return this.$store.state.teams.all;
            },
            license_key_required(){
                return ROBLOX_APPLICATION_IDS.includes(this.state.context?.application?.id);
            }
        },
        async created(){
            // It's possible that we've landed here from a situation in which the team is auto-selected because we're in an onboarding.
            // In that case, we should load the insitution from the API and set it as the district.
            if(this.onboarding?.team?.id){
                try {
                    this.district = await this.$http.get(`/institutions/${this.onboarding.team.id}`).then(response => response.$data);
                } catch (error) {
                    this.$toasted.error('There was an error loading your organization.');
                }
            }
        },
        methods: {
            change(){
                // Can't change the district if we're in an onboarding that has a team ID already set.
                if(this.onboarding?.team?.id){
                    return;
                }

                this.district = null;
            },
            description(result){
                let descriptor = 'institution';
                let location = '';

                if(result.city){
                    location += ` in ${result.city}`;

                    if(result.state){
                        location += `, ${result.state}`;
                    }

                    if(result.country && result.country !== 'United States'){
                        location += `, ${result.country}`;
                    }
                }else if(result.state){
                    location += ` in ${result.state}`;

                    if(result.country && result.country !== 'United States'){
                        location += `, ${result.country}`;
                    }
                }else if(result.country && result.country !== 'United States'){
                    location += ` in ${result.country}`;
                }

                if(result.descriptor){
                    descriptor = result.descriptor;
                }

                // Uppercase the first character of the descriptor.
                descriptor = descriptor.charAt(0).toUpperCase() + descriptor.slice(1);

                return `${descriptor}${location}`;
            },
            search(){
                this.loading = true;

                if(this.throttle){
                    clearTimeout(this.throttle);
                }

                this.throttle = setTimeout(() => {
                    const params = {};

                    if(this.query){
                        params.search = this.query;
                    }

                    this.throttle = null;

                    this.$http.get(`/institutions`, { params })
                    .then(response => {
                        this.districts = response.$data;
                    })
                    .catch(error => this.$toasted.error('There was an error loading the list of institutions.'))
                    .finally(() => this.loading = false);
                }, 1000);
            },
            select_district(district = null){
                this.district = district;
            },
            async create_district() {
                if(this.creating){
                    return;
                }

                try {
                    this.creating = true;

                    const { $data: team } = await this.$http.post('/teams', { type: 'district', name: this.query });

                    // Set this new team as the active team.
                    await this.$store.dispatch('teams/add', team);
                    await this.$store.dispatch('teams/activate', team);

                    // Show this district in the UI.
                    this.district = team;
                } catch (error) {
                    this.$toasted.error(error?.response?.$data ? error.response.$data.details : 'There was an error creating your organization.');
                } finally {
                    this.creating = false;
                }
            },
            async next(){
                if(this.creating){
                    return;
                }
                
                try {
                    this.creating = true;

                    // Triple check that this isn't a district that we're already a member of.
                    const membership = this.teams.find(team => team.id === this.district.id);

                    // If it is, we should just select it normally.
                    if(membership){
                        return this.confirm();
                    }

                    // If it is not, we need to check if the district is `active` or `inactive`.
                    // If it is `active`, we should show the CLAIMED_DISTRICT screen.
                    if(this.district.status === 'active'){
                        return this.send({ type: 'CLAIMED_DISTRICT', data: this.district });
                    }

                    // If it is `inactive`, we should add the person to the team and mark it as claimed.
                    const { $data: team } = await this.$http.post(`/teams/${this.district.id}/claim`);

                    // Add the team to the store.
                    await this.$store.dispatch('teams/add', team);
                    await this.$store.dispatch('teams/activate', team);

                    // Then we can confirm.
                    this.confirm();
                } catch (error) {
                    this.$toasted.error(error?.response?.$data ? error.response.$data.details : 'There was an error claiming this organization.');

                    // We do NOT need to set creating back to false in the FINALLY block.
                    // It will be immediately set back to true in when we call this.confirm();
                    this.creating = false;
                }
            },
            async confirm(){
                try {
                    this.creating = true;

                    // Check to see if we need to mark the onboarding as "started".
                    if(this.onboarding?.state === 'created'){
                        await this.$http.post(`/teams/${this.district.id}/onboardings/${this.onboarding.id}/claim`);
                    }

                    this.send({ type: 'CONFIRM_DISTRICT', data: this.district });

                    // If integrating with Roblox we want to collect the license key
                    if (this.license_key_required) {
                        return this.send('LICENSE');
                    }

                    this.send('CREATE_SOURCE');
                } catch (error){
                    this.$toasted.error(error?.response?.$data ? error.response.$data.details : 'There was an error proceeding with the onboarding process.');

                    // We only need to do this on catch. If we succeed, we'll be navigating away.
                    this.creating = false;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-district-create.integrate-state
    {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        max-width: none;
    }

    .district-search-container
    {
        padding: 200px 0 100px;
        max-width: 600px;
    }
    
    .district-search
    {
        box-shadow: 0 1px 6px -2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.08);
        width: 550px;
        padding: @single-padding @single-padding + 5;
        border-bottom-color: @d;
        margin-top: @double-padding;
        margin-bottom: @double-padding;
        transition: all ease 0.2s;

        .district-search-input
        {
            border: 0;
            border-radius: 0;
            padding: 0;
            height: 28px;
            margin-left: @single-padding + 2px;
            box-shadow: none;
            font-size: 16px;

            &:focus
            {
                border: 0;
                outline: 0;
                box-shadow: none;
            }
        }

        &:focus-within
        {
            border-color: @base;
            box-shadow: 0 1px 12px -2px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.08);
        }

        .district-search-loading
        {
            width: 20px;
            height: 20px;
        }
    }

    .district-result.placard::v-deep
    {
        width: 550px;
        margin-top: @single-padding;
        padding: @single-padding @single-padding + 2;

        &.selected-district
        {
            margin-top: @double-padding;
        }

        .placard-icon
        {
            width: 32px;
            height: 32px;
            border-radius: 16px;
            margin-right: @single-padding + 2;
        }

        .district-title
        {
            .underline
            {
                &::after
                {
                    content: "";
                    position: absolute;
                    border-bottom: 1px solid @grey;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    pointer-events: none;
                    width: 100%;
                }
            }

            .verified
            {
                background: url('~@/assets/icons/black/verified.svg') no-repeat;
                background-position: left 1px top 1px;
                background-size: 13px auto;
                margin-left: 4px;
                width: 15px;
                height: 15px;
            }
        }

        h4
        {
            margin-top: 4px;
            font-size: 13px;
        }

        h3
        {
            font-size: 15px;
            letter-spacing: -0.01rem;
            line-height: 18px;
            margin-top: 2px;
        }

        .actions
        {
            margin-top: 2px;

            .text-button.mini
            {
                line-height: 18px;
                height: 18px;
                font-weight: 400;
            }
        }

        &.inactive
        {
            .placard-icon
            {
                background: @grey;
            }
        }
    }

    .district-capabilities
    {
        color: @lightgrey;
        font-size: 12px;
        line-height: 16px;
        height: 16px;

        .district-capability
        {
            margin-left: 15px;

            .icon
            {
                margin-right: 2px;
            }

            &.enabled
            {
                color: @grey;
            }
        }
    }

    .claim-warning
    {
        margin: @double-padding 0;
        color: @black;
    }
</style>