<template>
    <div class="table-request-status flex flex-align" :class="{ error: row[column.id] > 300 }">
        <Cancel class="icon" width="14" height="14" stroke-width="2" v-if="row[column.id] > 300" />
        <Check class="icon" width="14" height="14" stroke-width="2" v-else />
        {{ row[column.id] }}
    </div>
</template>

<script>
    import { Check, Cancel } from '@epiphany/iconoir';

    export default {
        name: 'TableRequestStatus',
        components: {
            Check,
            Cancel
        },
        props: {
            row: Object,
            column: Object
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
    
    .table-request-status
    {
        border-radius: @border-radius - 2px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        height: 20px;
        padding: 0 5px 0 4px;
        margin: 0 8px;
        background: fade(@green, 20%);
        color: @green;

        .icon
        {
            margin-right: 2px;
        }

        &.error
        {
            background: fade(@red, 20%);
            color: @red;
        }

        // &::after
        // {
        //     content: '';
        //     width: 4px;
        //     height: 4px;
        //     border-radius: 2px;
        //     position: absolute;
        //     top: 8px;
        //     left: 8px;
        //     background: @grey;
        // }
    }
</style>
