<template>
    <div class="map-custom-roles">
        <div class="mapping">
            <div class="header">
                <div class="row flex flex-align flex-center">
                    <div class="ff">Roles In {{ provider.name }}</div>
                    <div class="ff">Roles In Edlink</div>
                </div>
            </div>
            <div class="body">
                <div v-for="(map, index) in configuration.map" class="row flex flex-align flex-center">
                    <div class="cell ff">
                        <custom-select :values.sync="map.source_roles" :borderless="true" :array="true" :placeholder="'Source role names...'" />
                    </div>
                    <div class="cell">
                        <div class="becomes flex flex-align flex-center">
                            <span class="icon iconoir-arrow-right"></span>
                        </div>
                    </div>
                    <div class="cell ff">
                        <search-select :values.sync="map.edlink_role" :borderless="true" :enum="edlink_roles_enum" :array="true" :placeholder="'Edlink roles...'" />
                    </div>
                    <div class="icon-button muted small icon iconoir-trash" @click="remove(index)"></div>
                    <!-- <search-select :options="edlink.roles" :value="map.class_state" @input="e => commit(index, {class_state: e.target.value})"></search-select> -->
                </div>
                <div>
                    <div class="flex flex-align options">
                        <div class="button" @click="add()">Add another</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import _ from 'lodash';
export default {
    name: 'TransformMapCustomRoles',
    props: {
        transformation: Object
    },
    components: {
        'custom-select': CustomSelect,
        'search-select': SearchSelect
    },
    computed: {
        source() {
            return this.$store.getters.source;
        },
        integration() {
            return this.$store.getters.integration;
        },
        provider() {
            return this.source ? this.source.provider : this.integration.provider;
        },
        edlink_roles_enum() {
            const roles = {};
            this.edlink_roles.map(role => {
                roles[role.value] = role.title
            });
            return roles;
        }
    },
    data() {
        return {
            configuration: null,
            values: [],
            edlink_roles: [
                {value: 'student', title: 'Student'},
                {value: 'district-administrator', title: 'District Administrator'},
                {value: 'staff', title: 'Staff'},
                {value: 'administrator', title: 'Administrator'},
                {value: 'teacher', title: 'Teacher'},
                {value: 'observer', title: 'Observer'},
                {value: 'parent', title: 'Parent'},
                {value: 'guardian', title: 'Guardian'},
                {value: 'ta', title: 'Teaching Assistant'},
                {value: 'aide', title: 'Aide'},
                {value: 'designer', title: 'Designer'}
            ]
        };
    },
    methods: {
        add() {
            this.configuration.map.push({
                source_roles: [],
                edlink_role: []
            });
        },
        remove(index) {
            if (this.configuration.map.length > 1) {
                this.configuration.map.splice(index, 1);
            } else {
                this.configuration.map[0].source_roles = [];
                this.configuration.map[0].edlink_role = [];
            }
        }
    },
    watch: {
        transformation: {
            immediate: true,
            handler() {
                if (this.transformation.configuration.hasOwnProperty('map')) {
                    this.configuration = _.cloneDeep(this.transformation.configuration);
                } else {
                    this.configuration = {
                        map: [
                            {
                                source_roles: [],
                                edlink_role: []
                            }
                        ]
                    };
                }
            }
        },
        configuration: {
            deep: true,
            handler() {
                this.$emit('input', this.configuration);
            }
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";
@import "~@/assets/less/mixins";

.map-custom-roles
{
    // margin-top: -1px;
    // padding: 0 15px;
}

.headers
{
    padding: 15px 15px 0;
    font-size: 14px;
    color: @black;
    line-height: 18px;
    font-weight: 500;
}

.mapping
{
    // padding: 15px 15px 0;
    color: @black;

    .row
    {
        border-bottom: 1px solid @e4;
        padding: 10px 0px;

        &:hover
        {
            .icon-button.muted
            {
                color: @red
            }
        }
    }

    .header
    {
        font-size: 15px;
        .row
        {
            border-top: 1px solid @e4;
        }
    }
}

.options
{
    padding-top: 15px;
}

.becomes
{
    color: @grey;
    font-size: 13px;
    line-height: 18px;
    width: 50px;

    .icon
    {
        font-size: 22px;
        color: @black;
    }
}

.icon-button
{
    font-size: 28px;
    color: @base;
    cursor: pointer;
    transition: color linear 0.1s;

    &.small
    {
        font-size: 20px;
    }

    &.red
    {
        color: @red;

        &:hover
        {
            color: fade(@red, 70%);
        }
    }

    &:hover
    {
        color: fade(@base, 70%);
    }

    &.floating
    {
        position: absolute;
        right: -40px;
        top: 8px;
    }

    &.muted
    {
        color: @c;
        padding: 10px;
        transition: color linear 0.1s;
        &:hover
        {
            color: @red
        }
    }
}
</style>