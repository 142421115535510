<template>
    <div class="info tooltip" v-tooltip.top="tooltip" >
        <svg width="14px" height="14px" stroke-width="2.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor">
            <path d="M12 11.5v5M12 7.51l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Info',
        props: {
            tooltip: String
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
    
    .info
    {
        width: 14px;
        height: 14px;

        svg
        {
            display: block;
            pointer-events: none;
            position: relative;
        }
    }
</style>