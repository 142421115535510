<template>
    <div class="full flex flex-column" v-if="rule">
        <header class="drawer-header flex flex-align">
            <div class="drawer-title flex flex-align ff tooltip" @click="focus_name" tooltip="Click to edit the name of this rule">
                <Svg3dSelectSolid class="icon" width="20" height="20" stroke-width="2" />
                <div class="text-overflow">Edit</div>
                <input v-if="rule" class="rule-name ff" ref="name" v-model="nickname" @focus="focus_name" @blur="blur_name" placeholder="Rule Nickname"></input>
            </div>

            <div class="flex flex-align rule-state pointer" @click="change_state(rule)" v-if="rule">
                <div class="standalone icon-status" :class="status(rule.state)"></div>
                <div>{{ rule.state }}</div>
            </div>

            <div class="button save has-icon" @click="save">
                <Check class="icon" width="16" height="16" stroke-width="2" />
                Save Changes
            </div>
            <div class="button white" @click="close">Cancel</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{ active: tab === 'edit' }" @click="tab = 'edit'">
                <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                Edit
            </div>
            <div class="pill" :class="{ active: tab === 'preview' }" @click="tab = 'preview'">
                <GraphUp class="icon" width="16" height="16" stroke-width="2" />
                Preview
            </div>
            <div class="pill" :class="{ active: tab === 'peek' }" @click="tab = 'peek'">
                <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                Peek
            </div>
            <div class="ff"></div>
            <checkbox v-if="tab === 'edit' && rule && rule.target === 'people'" class="share-related-entities borderless" label="Share Class Enrollments" :checked.sync="rule.share_related_entities" />
        </nav>
        <KeepAlive>
            <div class="ff drawer-content drawer-scroll flex flex-align flex-center" v-if="drawer_loading">
                <spinner />
            </div>
            <div class="rule-wrapper ff drawer-content drawer-scroll flex flex-column"  v-else-if="tab === 'edit' && rule">
                <div class="rule">
                    <h3 class="rule-target">{{ props.licensing ? 'License' : 'Share' }} <span class="capitalize">{{ rule.target }}</span></h3>
                    <div class="rule-metadata flex flex-align">
                        <div class="rule-and">from</div>
                        <date-picker v-model="rule.start_date"
                            :open.sync="start_datepicker_open" v-bind="{
                                type: 'date',
                                format: 'MM-DD-YYYY',
                                'range-separator': '-',
                                'append-to-body': true,
                                clearable: false,
                                placeholder: 'Start Date'
                            }">
                            <template v-slot:input>
                                <div class="button condition-button flex flex-align" :class="{ active: rule.start_date }"
                                    @click="() => start_datepicker_open = !start_datepicker_open">
                                    <template v-if="rule.start_date">
                                        {{ rule.start_date | pretty('short') }}
                                        <div class="clear" @click.stop="() => rule.start_date = null">
                                            <Cancel class="icon block" width="16" height="16" stroke-width="2.5" />
                                        </div>
                                    </template>
                                    <template v-else>
                                        Set Start Date
                                    </template>
                                </div>
                            </template>
                            <template v-slot:icon-calendar>
                                <span></span>
                            </template>
                        </date-picker>
                        <div class="dash">to</div>
                        <date-picker :disabled="!!duration" v-model="rule.end_date" :open.sync="end_datepicker_open" v-bind="{
                            type: 'date',
                            format: 'MM-DD-YYYY',
                            'range-separator': '-',
                            'append-to-body': true,
                            clearable: false,
                            placeholder: 'End Date'
                        }">
                            <template v-slot:input>
                                <div class="button condition-button flex flex-align" :class="{ active: end_date }"
                                    @click="() => !duration ? end_datepicker_open = !end_datepicker_open : null">
                                    <template v-if="end_date">
                                        {{ end_date | pretty('short') }}
                                        <div v-if="!duration" class="clear" @click.stop="() => rule.end_date = null">
                                            <Cancel class="icon block" width="16" height="16" stroke-width="2.5" />
                                        </div>
                                        <div class="pin flex flex-align flex-center" v-else>
                                            <Pin class="icon" width="10" height="10" stroke-width="2" />
                                        </div>
                                    </template>
                                    <template v-else>
                                        Set End Date
                                    </template>
                                </div>
                            </template>
                            <template v-slot:icon-calendar>
                                <span></span>
                            </template>
                        </date-picker>
                    </div>
                    <template v-if="props.licensing === true">
                        <div class="rule-metadata flex flex-align">
                            <div class="rule-and">for</div>
                            <div class="add-license flex flex-align flex-center" @click="() => selecting_products = true">
                                <template v-if="rule.product_ids && rule.product_ids.length">
                                    <div class="button condition-button flex flex-align active">
                                        {{ rule.product_ids.length }} Products Assigned
                                        <div class="clear" @click.stop="() => update_licenses(rule, null)">
                                            <Cancel class="icon block" width="16" height="16" stroke-width="2.5" />
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="button condition-button flex flex-align">Select Products</div>
                                </template>
                                <div v-if="duration" class="duration">Expires after {{ duration }} days</div>
                            </div>

                            <drop-down :open="selecting_products" :scrollable="false" left="82px" width="350px" height="300px" padding="0" ref="products">
                                <preview-product :values="rule.product_ids" :array="true" height="300px" :placeholder="'Select one or more...'"
                                    :key="('products' + rule.id)" @input="(v) => update_licenses(rule, v)" />
                            </drop-down>
                        </div>
                    </template>
                    <div class="rule-logic" :set="limit = 2">
                        <template v-if="rule.conditions.length">
                            <div class="rule-condition flex" :key="index" v-for="(condition, index) of rule.conditions">
                                <div class="rule-and">{{ index === 0 ? 'Where' : 'And' }}</div>
                                <div class="condition-wrapper flex flex-column">
                                    <div class="condition-metadata flex flex-align ff">
                                        <div class="condition-property">{{ property(rule, condition) }}</div>
                                        <select class="condition-operator" v-model="condition.operator">
                                            <option :key="operator" v-for="operator in valid_operators(rule, condition)"
                                                :value="operator"
                                                :set="found_operator = operators.find(it => it.value === operator)">
                                                {{ found_operator ? found_operator.name : 'Unknown operator' }}
                                            </option>
                                        </select>
                                        <div class="delete flex flex-align flex-center" @click="delete_condition(rule, condition)">
                                            <Trash class="icon" width="16" height="16" stroke-width="2" />
                                        </div>
                                        <div class="ff"></div>
                                    </div>
                                    <div class="condition-values ff"
                                        v-if="operators.find(it => it.value === condition.operator).select !== 'none'">
                                        <preview-value v-if="searchable(rule, condition)" :values.sync="condition.values"
                                            :array="true" :key="('searchable' + condition.property)"
                                            :target="condition.target" :schema="schema" />
                                        <search-select v-else-if="enumerated(rule, condition)" :values.sync="condition.values"
                                            :compact="true" :enum="condition_enum(rule, condition)" :array="true"
                                            :placeholder="'Select one or more...'" :key="('enumerated' + condition.property)" />
                                        <custom-select v-else class="block" :values.sync="condition.values" :compact="true" :paste="true"
                                            :array="true" :placeholder="'Enter one or more...'"
                                            :key="('custom' + condition.property)" />
                                    </div>
                                </div>
                                <div class="ff"></div>
                            </div>
                        </template>
                        <div class="rule-condition flex" v-else>
                            <div class="rule-and">Where</div>
                            <div class="rule-no-conditions">
                                This rule has no conditions so it will match <span class="capitalize">No {{ rule.target
                                }}</span>
                            </div>
                        </div>
                        <div class="rule-condition flex">
                            <div class="rule-add"></div>
                            <!-- <div class="rule-and">Filter By</div> -->
                            <div class="rule-add-condition flex flex-align flex-wrap">

                                <div class="button condition-button flex flex-align nowrap" v-for="prop of common_properties"
                                    @click="add_condition(rule, prop)" :key="prop.value">
                                    {{ prop.name }}
                                </div>

                                <div>
                                    <div class="button condition-button flex flex-align nowrap" @click="() => other = true" v-if="other_properties.length">
                                        More
                                    </div>
                                    <drop-down ref="other" :open="other" height="unset" maxHeight="200px" left="4px" padding="0">
                                        <div class="property-option" v-for="prop of other_properties" :key="prop.value"
                                            @click="add_condition(rule, prop)">
                                            {{ prop.name }}
                                        </div>
                                    </drop-down>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <blink-preview class="drawer-content drawer-scroll flex flex-column" v-else-if="tab === 'preview'" @peek="(val) => { peek = val; tab = 'peek'; }" />
            <entity-preview v-else-if="tab === 'peek'" :entity="peek" :licensing="true" />
        </KeepAlive>
    </div>
</template>

<script>
    import Vue from 'vue';
    
    import SearchSelect from '@/components/SearchSelect.vue';
    import CustomSelect from '@/components/CustomSelect.vue';

    import Dropdown from '@/components/Dropdown.vue';

    import PreviewValue from '@/components/preview/PreviewValue.vue';
    import PreviewValueProduct from '@/components/preview/PreviewValueProduct.vue';

    import EntityPreview from '@/components/drawers/EntityPreview.vue';

    import { InfoEmpty, AlignLeft, DatabaseExport, CodeBracketsSquare, GraphUp, Pin, EditPencil, Svg3dSelectSolid, Check, Trash, Cancel } from '@epiphany/iconoir';
    import _ from 'lodash';

    import { licensing } from '@/constants';

    const PATCHABLE = ['start_date', 'end_date', 'soft_cap', 'hard_cap', 'product_ids', 'share_related_entities', 'conditions'];

    export default {
        name: 'EditRule',
        components: {
            InfoEmpty,
            AlignLeft,
            DatabaseExport,
            CodeBracketsSquare,
            Svg3dSelectSolid,
            Check,
            GraphUp,
            'custom-select': CustomSelect,
            'search-select': SearchSelect,
            'preview-value': PreviewValue,
            'preview-product': PreviewValueProduct,
            'drop-down': Dropdown,
            'entity-preview': EntityPreview,
            Pin,
            EditPencil,
            Trash,
            Cancel
        },
        data(){
            return {
                tab: 'edit',
                drawer_loading: true,
                loading: true,
                // editing
                start_datepicker_open: false,
                end_datepicker_open: false,
                selecting_products: false,
                other: false,
                renaming: false,
                entities: {
                    people: [],
                    classes: [],
                    schools: []
                },
                products: {},
                properties: licensing.properties,
                operators: licensing.operators,
                types: licensing.types,
                rule: null,
                peek: null
            };
        },
        computed: {
            developer(){
                return this.team.type === 'developer';
            },
            district(){
                return this.team.type === 'district';
            },
            schema(){
                if(this.rule?.type === 'licensing'){
                    return 'transformed';
                }

                return this.rule?.owner === 'district' ? 'enriched' : 'filtered';
            },
            preview(){
                return this.$store.state.blink.preview;
            },
            previewable(){
                // Developers cannot preview district rules because the privacy mask hasn't been applied yet.
                return this.district || this.rule.owner === 'developer';
            },
            title() {
                return 'Edit Sharing Rule';
            },
            admin() {
                return this.$store.getters.user.admin;
            },
            props() {
                return this.$store.state.drawer.props;
            },
            providers() {
                return this.$store.state.providers.all;
            },
            team() {
                return this.$store.getters.team;
            },
            integration() {
                return this.$store.getters.integration;
            },
            common_properties() {
                return Object.values(this.properties[this.rule.target]).filter(it => it.common);
            },
            other_properties() {
                return Object.values(this.properties[this.rule.target]).filter(it => !it.common);
            },
            duration() {
                if (this.rule?.product_ids?.length) {
                    if (this.rule.product_ids.some(id => this.products?.[id]?.license_duration)) {
                        const id = this.rule.product_ids.find(id => this.products?.[id]?.license_duration);
                        return this.products[id].license_duration;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
            end_date() {
                if (this.duration) {
                    const start = this.rule.start_date ? new Date(this.rule.start_date) : new Date();
                    start.setDate(start.getDate() + this.duration);
                    return start.toISOString();;
                } else if (this.rule.end_date) {
                    return this.rule.end_date
                } else {
                    return null;
                }
            },
            nickname: {
                get() {
                    if (this.renaming) {
                        return this.rule?.name;
                    } else {
                        if (this.rule?.name && this.rule.name.trim().length > 0) {
                            return this.rule?.name;
                        } else {
                            const owner = this.rule?.owner ? this.rule.owner === 'developer' ? 'Developer' : 'District' : '';
                            return `Untitled ${owner} Rule`;
                        }
                    }
                },
                set(value) {
                    if (this.renaming) {
                        this.rule.name = value;
                    }
                }
            }
        },
        async mounted() {
            try {
                // Attach some listeners.
                window.addEventListener('mousedown', this.click_outside);
                window.addEventListener('keydown', this.escape);

                // Load products.
                this.load_products();

                // Fetch the rule we're trying to preview.
                const { $data: rule } = await this.$http.get(`/teams/${this.team.id}/integrations/${this.integration.id}/rules/${this.props.rule.id}`);

                this.rule = rule;
                this.rule.state = this.props.rule.state ?? rule.state;
                this.drawer_loading = false;
                this.loading = false;

                if(this.previewable){
                    if(this.preview){
                        if(this.preview.integration.id === this.integration.id){
                            return this.$store.dispatch('blink/patch', {
                                rule_id: this.rule.id,
                                transformation_id: null,
                                configuration: _.pick(this.rule, PATCHABLE)
                            });
                        }else{
                            await this.$store.dispatch('blink/kill');
                        }
                    }

                    await this.$store.dispatch('blink/preview', {
                        integration_id: this.integration.id,
                        rule_id: this.rule.id,
                        transformation_id: null,
                        configuration: _.pick(this.rule, PATCHABLE)
                    });
                }
            }catch(error){
                this.$toasted.error('There was an error loading your rule.')
            }
        },
        destroyed() {
            window.removeEventListener('mousedown', this.click_outside);
            window.removeEventListener('keydown', this.escape);
        },
        watch: {
            rule: {
                handler(){
                    if(this.preview){
                        this.$store.dispatch('blink/patch', {
                            rule_id: this.rule.id,
                            transformation_id: null,
                            configuration: _.pick(this.rule, PATCHABLE)
                        });
                    }
                },
                deep: true
            }
        },
        methods: {
            focus_name() {
                if(this.$refs.name !== document.activeElement){
                    this.$nextTick(() => this.$refs.name.focus());
                }

                this.renaming = true;
            },
            blur_name() {
                this.renaming = false;
            },
            close() {
                this.$store.dispatch('drawer/close');
            },
            save() {
                this.drawer_loading = true;
                this.loading = true;

                setTimeout(() => {
                    this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}/rules/${this.props.rule.id}`, this.rule).then(({ $data: rule }) => {
                        this.$store.dispatch('integrations/reload').then(() => {
                            this.$store.dispatch('drawer/close');
                            this.$toasted.success('Rule saved successfully.');
                        });
                    }).catch(() => {
                        this.drawer_loading = false;
                        this.loading = false;
                        this.$toasted.error('There was an error loading your rule.')
                    });
                }, 500);
            },
            click_outside(e) {
                if (this.selecting_products) {
                    if (this.$refs.products && !this.$refs.products.$el.contains(e.target)) {
                        this.selecting_products = false;
                    }
                }
                if (this.other) {
                    if (this.$refs.other && !this.$refs.other.$el.contains(e.target)) {
                        this.other = false;
                    }
                }
            },
            escape(e) {
                if (e.key === 'Escape') {
                    this.selecting_products = false;
                    this.other = false;
                }
            },
            load_products() {
                this.products = {};
                this.fetch_products();
            },
            fetch_products(params = {}) {
                params.$fields = 'id,name,license_duration';
                params.$first = 1000;

                this.$http.get(`/teams/${this.team.id}/integrations/${this.integration.id}/products`, {
                    baseURL: '/api/v2',
                    params
                })
                .then((response) => {
                    const products = response.$data;

                    for (const product of products) {
                        Vue.set(this.products, product.id, product);
                    }

                    const last = products[products.length - 1];

                    if (response.$next && last) {
                        this.fetch_products({ $after: last.id });
                    }
                })
                .catch(() => this.$toasted.error('There was an error loading your available products.'));
            },
            update_licenses(rule, licenses) {
                Vue.set(rule, 'product_ids', licenses ?? []);
                if (this.duration) {
                    this.rule.end_date = this.end_date;
                } else {
                    this.rule.end_date = null;
                }
            },
            property(rule, condition) {
                return this.properties[rule.target].find(property => property.value === condition.property).name;
            },
            operator(rule, condition) {
                return this.operators.find(operator => operator.value === condition.operator).name;
            },
            valid_operators(rule, condition) {
                return this.properties[rule.target].find(it => it.value === condition.property).operators
            },
            searchable(rule, condition) {
                const property = this.properties[rule.target].find(it => it.value === condition.property);
                return property.type === 'entity';
            },
            enumerated(rule, condition) {
                const property = this.properties[rule.target].find(it => it.value === condition.property);
                return property.type === 'enum';
            },
            condition_enum(rule, condition) {
                const property = this.properties[rule.target].find(it => it.value === condition.property);
                return property.enum;
            },
            delete_condition(rule, condition) {
                const index = rule.conditions.indexOf(condition);
                return rule.conditions.splice(index, 1);
            },
            add_condition(rule, property) {
                rule.conditions.push({
                    property: property ? property.value : this.properties[rule.target][0].value,
                    target: property ? property.target : this.properties[rule.target][0].target,
                    values: [],
                    values_expanded: [],
                    operator: 'IN'
                });
            },
            status(state) {
                return {
                    green: ['active'].includes(state),
                    yellow: ['draft'].includes(state),
                    purple: ['suggested'].includes(state)
                };
            },
            change_state() {
                Vue.set(this.rule, 'state', this.rule.state === 'active' ? 'draft' : 'active');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .rule-state {
        margin-right: 10px;
    }

    .drawer-scroll
    {
        overflow: auto;
    }

    .drawer-section
    {
        padding: 25px;
    }

    // ==== Invoice Styles ====
    .notes {
        // height: 100%;
        padding: 20px;

        textarea {
            min-height: 300px;
        }

        .actions {
            margin-top: 10px;
        }
    }

    .option {
        margin-right: 8px;

        .button {
            margin-right: 0;
        }

        &.button {
            margin-right: 8px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;
        margin-right: 10px;

        &.red
        {
            background: @red;
        }

        &.blue
        {
            background: @base;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.purple
        {
            background: @purple;
        }

        &.grey
        {
            background: @grey;
        }
    }

    .button.save {
        margin-right: @single-padding;
    }

    .rule-name {
        line-height: 30px;
        font-size: 16px;
        font-weight: 500;
        margin: 0 10px 0 4px;
        padding: 0;
        height: 30px;
        border: none;
        outline: none;
        box-shadow: none;

        &:focus, &:focus-visible, &:active {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

    // ==== Copied Rule Styles ====
    @rule-padding: 40px;

    .icon.edit {
        margin-left: 5px;
        color: @lightgrey;
        opacity: 0;
    }
    .condition-wrapper {
        width: 100%;
        max-width: 500px;
    }

    .mx-datepicker,
    .condition-button {
        width: inherit;

        .clear, .pin {
            margin-right: -6px;
            margin-left: 4px;
        }

        .pin {
            width: 16px;
            height: 16px;

            svg {
                width: 11px;
                height: 11px;
            }
        }

        .clear {
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: @border-radius - 2px;

            .icon
            {
                width: 16px;
                height: 16px;
            }

            &:hover {
                background: @base;
                color: @f;
            }
        }
    }

    .duration {
        margin-left: 10px;
        font-size: 10px;
        padding: 3px 4px;
        border-radius: 3px;
        background: fade(@grey, 20%);
        color: @grey;
        font-weight: 500;
    }

    .sharing-rules {
        margin-bottom: 25px;
        align-items: flex-start;

        .description {
            font-size: 13px;
            margin-bottom: 0;
        }
    }

    .add-rule-container {
        margin-top: 25px;
    }

    .add-rule {
        transition: background ease-in-out 0.1s;
        background: @d;
        border-radius: 9999px;
        display: inline-block;
        cursor: pointer;

        .icon {
            font-size: 20px;
            color: @white;
        }
    }

    .add-rule-container {
        cursor: pointer;

        &:hover {
            .add-rule {
                background: @base;
            }
        }
    }


    .integration-rules {
        padding-bottom: 200px;

        .nothing {
            font-size: 13px;
        }
    }

    .back {
        cursor: pointer;

        .icon {
            color: @lightgrey;
            margin-right: 2px;
        }

        color: @lightgrey;
        font-size: 16px;
        padding: 0 22px;
    }

    .rule-header {
        padding: 0 (@rule-padding - 10px);
        background: @f;
        z-index: 5;

        input {
            height: 36px;
            padding: 0;
            font-size: 16px;
            color: @black;
            font-weight: 500;
            border: 0;
            background: transparent;
            outline: none;
            border: none;
            box-shadow: none;
            line-height: 36px;
            letter-spacing: -0.03rem;
        }

        h3 {
            line-height: 36px;
            height: 36px;
        }

        .badge {
            margin-left: 10px;
        }

        .rename-rule:hover {
            .edit {
                opacity: 1;
            }
        }
    }

    .rule-metadata {
        padding: 0 @rule-padding;
        margin: 0 0 30px;
    }

    .dash {
        color: @grey;
        margin: 0 10px;
        text-transform: capitalize;
        font-size: 12px;
        color: @grey;
        font-weight: 500;

        &:first-child {
            margin-left: 0;
        }
    }

    .rule
    {
        &::after {
            content: "";
            position: absolute;
            top: 50px;
            bottom: 45px;
            left: @rule-padding + 4px;
            width: 1px;
            border-left: 1px dashed @c;
            z-index: 0;
        }
    }

    .rule-target
    {
        padding: 0 30px;
        line-height: 36px;
        height: 36px;
        background: @f;
        margin: 20px 0 30px;
        z-index: 2;
    }

    .condition-button,
    .rule-wrapper::v-deep .condition-button {
        height: 26px;
        line-height: 24px;
        background-color: @f;
        border-color: @d;
        box-shadow: none;
        color: @grey;
        padding: 0 10px;
        // white-space: nowrap;

        &:hover,
        &:active {
            border-color: @c;
            background: @f8;
        }

        &.active {
            color: @base;
            border-color: @base;
            background-color: fade(@base, 20%);
            padding: 0 10px;

            .iconoir-cancel,
            .iconoir-key-alt-plus {
                color: @base;
            }

            &:hover,
            &:active {
                color: @base;
                background-color: fade(@base, 20%);
            }
        }
    }

    .helptext {
        font-size: 13px;
        line-height: 16px;
        color: @grey;
        margin-right: 20px;
    }

    .rule-logic {
        padding: 0 @rule-padding;
        font-size: 13px;
        color: @black;
        line-height: 20px;

        .add-condition,
        .add-license,
        ::v-deep .add-license {
            color: @c;
            cursor: pointer;
            margin-top: 15px;
            margin-right: 15px;
            transition: color ease-in-out 0.1s;
            opacity: 1;

            .icon {
                margin-right: 5px;
            }

            &:hover {
                color: @base;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .add-condition {
            margin-left: -2px;
            z-index: 5;

            .icon {
                background: @d;
                color: @white;
                border-radius: 9999px;
                margin-right: 5px;
                transition: background ease-in-out 0.1s;
            }

            &:hover {

                .icon {
                    background: @base;
                }
            }
        }
    }

    .rule-delete {
        width: 18px;
        height: 18px;
        background: url('~@/assets/icons/black/x-round.svg') 0 2px no-repeat;
        background-size: 18px auto;
        opacity: 0.5;
        transition: opacity ease 0.2s;
        transition: opacity ease 0.2s;
        margin-left: 15px;

        &:hover,
        &:active {
            opacity: 1;
        }
    }

    .rule-and {
        line-height: 18px;
        width: 41px;
        height: 18px;
        margin: 7px 18px 7px -16px;
        z-index: 5;
        text-align: center;
        text-transform: capitalize;
        font-size: 12px;
        color: @grey;
        font-weight: 500;
        background: @f;
    }

    .rule-add {
        z-index: 5;
        margin: 7px 18px 7px -5px;
        width: 19px;
        height: 19px;
        border-radius: 10px;
        border: 1px solid @d;
        background: url('~@/assets/icons/grey/plus.svg') 1px 1px no-repeat @white;
        background-size: 15px 15px;
        min-width: 19px;
    }

    .rule-add-condition {
        padding-left: 11px;
        margin: 0 -4px;

        .button {
            margin: 4px;
        }
    }

    .rule-dot {}

    .condition-metadata {
        padding-top: 3px;
        margin-bottom: 15px;

        .delete
        {
            color: @grey;
            width: 24px;
            height: 24px;
            border-radius: @border-radius;
            cursor: pointer;
            margin-left: @single-padding;

            &:hover
            {
                color: @f;
                background: @red;
            }
        }
    }

    .rule-condition {
        // margin-top: 20px;
        align-items: flex-start;
        // padding-top: 20px;
        margin: 30px 0;

        &:first-child {
            margin-top: 0;
            border-top: 0;
            padding-top: 0;

            &::after {
                display: none;
            }
        }

        &.custom {
            margin-top: 10px;
            border-top: 0;
            padding-top: 0;

            font-size: 10px;
            color: @grey;
            font-weight: 600;

            &:after {
                // content: var(--custom-content);
                display: none;
            }
        }
    }

    .condition-property {
        color: @base;
        font-weight: 500;
        font-size: 13px;
        background-position: right 5px top 5px;
        border-radius: 3px;
        padding: 0 10px;
        height: 26px;
        line-height: 24px;
        white-space: nowrap;
        background-color: fade(@base, 20%);
        border: 1px solid @base;
        border-right: 0;
        border-radius: 6px 0 0 6px;
    }

    select.condition-operator {
        border-radius: 6px;
        padding: 0 27px 0 10px;
        height: 26px;
        line-height: 24px;
        font-size: 13px;
        background-image: url('~@/assets/icons/base/caret-down.svg');
        background-position: right 4px top 2px;
        background-size: 20px auto;
        cursor: pointer;
        max-width: 150px;
        font-weight: 500;
        border-color: @base;
        border-radius: 0 6px 6px 0;
        color: @base;
        width: auto;
        margin: 0;

        &:hover,
        &:focus,
        &:active {
            border-color: @base;
            box-shadow: none;
        }
    }

    .condition-value {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }

        &.token {
            background: @e4;
            color: @black;
            padding: 0 12px 0 12px;
            font-size: 13px;
            border-radius: 12px;
            margin: 3px;
            line-height: 24px;
            height: 24px;
            white-space: no-wrap;
        }
    }

    .rule-no-conditions {
        color: @black;
        padding: 6px 0;

        span {
            display: inline-block;
            padding: 0 5px;
            font-size: 12px;
            background: @f4;
            border-radius: 3px;
            font-weight: 500;
        }
    }

    .rule-state {
        margin-left: 15px;
        font-size: 12px;
        color: @black;
        height: 24px;
        line-height: 22px;
        border: 1px solid @d;
        padding: 0 8px 0 6px;
        border-radius: 12px;
        background: @f;
        text-transform: capitalize;
        transition: all ease 0.2s;

        .icon-status {
            margin-right: 6px;
        }

        &:hover,
        &:active {
            border-color: @grey;
        }
    }

    label.checkbox.share-related-entities
    {
        padding: 4px 6px;
        height: 30px;

        .checkbox-label
        {
            font-size: 13px;
            margin-left: 6px;
        }
    }

    .property-option,
    ::v-deep .property-option {
        padding: 5px 8px;
        cursor: pointer;

        &:hover {
            color: @base;
            background-color: fade(@base, 20%);
        }

    }

    .terminal {
        border-radius: 3px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        line-height: 18px;
        max-width: 160px;
        border-radius: 12px;
        height: 24px;
        margin-left: -16px;

        border-radius: 18px;
        background-color: @f;
        // background-image: url('~@/assets/icons/grey/arrow-down-right.svg');
        // background-repeat: no-repeat;
        // background-position: top 4px left 6px;
        // background-size: 14px auto;
        z-index: 5;
        border: 1px solid @d;
        overflow: hidden;
        width: fit-content;

        color: @grey;
    }

    ::v-deep .autocomplete-search {
        border-radius: 6px;
        padding: 3px;

        input.autocomplete-input {
            font-size: 13px;
            margin: 0 3px;
        }

        .token {
            line-height: 24px;
            height: 24px;
            padding: 0 0 0 10px;
        }
    }
</style>
