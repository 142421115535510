<template>
    <div class="integration-permissions">
        <template v-if="permissions">
            <label class="permission flex flex-align" v-if="has('0a2ff39e-ca16-458f-98c4-a58a6766c356')">
                <div class="ff">
                    <div class="permission-name">Parents &amp; Guardians</div>
                    <div class="permission-description">Parents, guardians, and other agents will be shared with the application.</div>
                </div>
            </label>
            <label class="permission flex flex-align" v-if="has('796d4633-c262-49d4-8e5c-b801a6a1c053')">
                <div class="ff">
                    <div class="permission-name">Modify Coursework &amp; Grades</div>
                    <div class="permission-description">This application can create, update, and delete coursework and grades.</div>
                </div>
            </label>
            <label class="permission flex flex-align" v-if="has('d55c86bf-5caa-4fb1-a3cb-58d513b08460')">
                <div class="ff">
                    <div class="permission-name">Modify Roster Data</div>
                    <div class="permission-description">This application can create, update, and delete users, classes, and enrollments.</div>
                </div>
            </label>
            <label class="permission flex flex-align" v-if="demographics.length">
                <div class="ff">
                    <div class="permission-name">Selected Demographic Data</div>
                    <div class="permission-description">This application can access certain demographic data if it is available.</div>
                </div>
                <!-- <div class="text-button mini" @click="details">View Details</div> -->
            </label>
        </template>
        <template v-else>
            <div class="nothing">
                No special permissions are required by this application.
            </div>
        </template>
        <modal name="demographic-details" :width="600" height="auto" classes="modal">
            <div class="demographic-details flex flex-column">
                details
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        name: 'IntegrationPermissions',
        props: {
            requested: Array,
            permissions: Array,
            editable: Boolean,
            scoped: Boolean
        },
        data(){
            return {
                
            };
        },
        computed: {
            demographics(){
                const demographics = {
                    'b6355c7b-56bb-48a4-a5be-0712cc0f35ea': 'First and Last Name',
                    '8aa73a24-c279-4a45-9123-9cda31cc316b': 'Email Address',
                    '739d6139-abcf-4217-b0a9-bc11764c3ead': 'Phone Number',
                    '9e1ed7ac-a871-44c4-a355-0517b4217ecb': 'Profile Picture',
                    'fe75b1b2-2c56-4c4b-a7a1-80048da6b24f': 'Roles',
                    '1240bc10-4d5b-48c5-b967-6e7e778dcc48': 'Grade Levels',
                    '7d6ea160-04ad-4c9c-92d2-ce961a982d68': 'School Identifiers',
                    '045646ca-2d44-4216-a548-19a6e72b35a0': 'Birthday',
                    '4c89756d-7794-465b-9e96-8439be8304e7': 'Country of Birth',
                    '4260b47f-78a6-4616-8ebd-35f884aa6a01': 'State of Birth',
                    '5f767a1e-8bf7-4ffc-8a45-6a8ffa63f130': 'City of Birth',
                    '7abe0353-34b7-48f7-84c9-7ae6c92d67e8': 'Gender Identity',
                    '89619c30-9712-42aa-80f9-67d26408f9f5': 'Race',
                    '2b109f34-debb-4394-9d3f-4029ac1d0cbb': 'Hispanic or Latino Ethnicity',
                    '0e9ad589-6678-482a-b740-b2ce697ff8fb': 'Graduation Year',
                    'd3521f56-2dfa-4760-9677-872d663ae5eb': 'Residence Status',
                    '1e5607b6-20ad-4b51-9aec-03ca23419b1b': 'English Language Learner',
                    '98f24bda-60a0-41fb-84c8-befcccd1f8d8': 'Individualized Education Plan',
                    '202a32ef-868e-49da-b793-551716e88c85': 'Food Service Program Eligibility',
                    '14b1db59-dbff-4cb5-b2d2-bf8614f09cbe': 'Homeless',
                    'bceb0443-fc5b-4d4f-bfda-e382f813cb97': 'Disability',
                    '078df3a3-7198-4090-821a-d11d73e04ef6': 'Gifted Talented',
                    '13c6bab8-ded4-4662-8332-69bba47f6e5b': 'Economically Disadvantaged',
                    '117a8ccf-b822-415e-b3cc-ceeb1b6edce9': 'Migrant',
                    'd4f6d025-a6ae-4135-8618-1a373751f47d': 'PublicAssistance'
                };

                const result = [];

                for(const permission of this.permissions){
                    if(demographics.hasOwnProperty(permission)){
                        result.push(demographics[permission]);
                    }
                }

                return result;
            }
        },
        methods: {
            details(){
                this.$modal.show('demographic-details');
            },
            has(permission){
                return this.permissions.includes(permission);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .permission
    {
        border: 1px solid @e4;
        padding: 10px 13px;
        margin: 0;
        cursor: pointer;
        user-select: none;
        margin-top: -1px;
        width: 600px;

        &:first-child
        {
            margin-top: 0;
            border-radius: 3px 3px 0 0;
        }

        &:last-child
        {
            border-radius: 0 0 3px 3px;
        }

        &:focus-within, &:active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;
            z-index: 3;
        }

        input.checkbox-input
        {
            background: @e;
            margin-right: 15px;
            border: 0;
            transition: background-color ease 0.2s;

            &::after
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                background: url('~@/assets/icons/white/check.svg') 1px 3px no-repeat;
                background-size: 18px auto;
                transform: scale(0);
                transition: all ease 0.15s;
                opacity: 0;
            }

            &:checked
            {
                background: @base;

                &::after
                {
                    transform: scale(1);
                    opacity: 1;
                }
            }

        	&[disabled]
        	{
                &:checked
                {
                    background-color: @grey;
                }
        	}
        }

        &.disabled
        {
            pointer-events: none;
        }

        .text-button
        {
            margin-left: 20px;
        }
    }

    .permission-name
    {
        color: @black;
        font-size: 15px;
        line-height: 16px;
    }

    .permission-description
    {
        font-size: 12px;
        color: #888;
        line-height: 14px;
        margin-top: 4px;
        font-weight: 400;
    }
</style>