<template>
    <div class="integrate-account-password">
        <label>Welcome back<span v-if="state.context.name">, {{state.context.name}}</span>.</label>
        <input ref="password" type="password" placeholder="Enter your password..." autocomplete="false" @input="event => send({type: 'PASSWORD', data: event.target.value})" @keydown.enter="login" />
        <transition name="fade">
            <!-- TODO This is where we'll put a magic link button -->
            <div class="options flex" key="email">
                <div class="button" kbd="Enter ⏎" @click="login">Sign In</div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateAccountLoginPassword',
        props: {
            state: Object,
            send: Function
        },
        created(){
            this.$nextTick(() => this.$refs.password.focus());
        },
        computed: {
            invitations(){
                return this.$store.getters.user?.invitations;
            }
        },
        methods: {
            login(){
                this.$http.post('/login', {
                    email: this.state.context.email,
                    password: this.state.context.password,
                    two: this.state.context.two
                })
                .then(response => this.$store.dispatch('user/token', response.$data.token))

                // Try to get the user profile.
                // If this call fails, we probably need to 2FA.
                .then(() => this.$http.get('/me'))
                .then(() => this.$store.dispatch('user/login'))
                .then(() => {
                    this.send({ type: 'SET_USER', data: this.$store.getters.user });
                    
                    if(this.$store.getters.team){
                        if(this.invitations?.length){
                            this.send('INVITES');
                        } else {
                            this.send('SELECT_DISTRICT');
                        }
                    }else{
                        this.send('TERMS');
                    }
                })
                .catch(response => {
                    // It's possible that we'll have more token activation conditions in the future so we should observe the response.
                    if(response?.$details?.includes('mfa_required')){
                        this.send('TWO');
                    }else{
                        this.$toasted.error(response.$error ? response.$error : 'There was an error logging you in.');
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-account-email
    {
        .sso
        {
            background-repeat: no-repeat;
            margin-right: 10px;

            &.google
            {
                background-image: url('~@/assets/icons/source/google.svg');
                background-size: 18px;
                background-position: 9px 7px;
                padding-left: 34px;
            }

            &.microsoft
            {
                background-image: url('~@/assets/icons/source/microsoft.svg');
                background-size: 57px;
                background-position: -9px 7px;
                padding-left: 36px;
            }
        }
    }
</style>
