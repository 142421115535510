<template>
    <chip class="region" :color="providers[id].color" :thumbnail="providers[id].icon" :title="providers[id].name" />
</template>

<script>
    export default {
        name: 'Region',
        props: {
            id: String
        },
        data(){
            return {
                providers: {
                    '8c5475f1-32d3-479d-8a6a-3c6b6e524f49': { color: 'lightgrey', name: 'United States', icon: '/flags/us.svg' },
                    '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd': { color: 'lightgrey', name: 'Canada', icon: '/flags/ca.svg' },
                    '2572d321-6d8b-458b-8024-8e5ab6bad7b6': { color: 'lightgrey', name: 'Germany', icon: '/flags/de.svg' },
                    '2104f649-fe39-4fa1-96be-8682e9840dcf': { color: 'lightgrey', name: 'Australia', icon: '/flags/au.svg' }
                }
            };
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .chip.region
    {
        // padding-left: 4px;

        .chip-thumbnail
        {
            // width: @chip-height - 2 * @chip-padding - 2px;
            // height: @chip-height - 2 * @chip-padding - 2px;
            border: 1px solid @e;
        }

        .chip-title
        {
            // padding-left: 5px;
        }
    }
</style>
