<template>
    <chip :color="states[state].color" :title="states[state].name" />
</template>

<script>
    export default {
        name: 'ProductState',
        props: {
            state: String
        },
        data(){
            return {
                states: {
                    'active': { color: 'green', name: 'Active' },
                    'upcoming': { color: 'purple', name: 'Upcoming' },
                    'development': { color: 'grey', name: 'Development' },
                    'inactive': { color: 'grey', name: 'Inactive' },
                    'sunsetting': { color: 'yellow', name: 'Sunsetting' },
                    'deprecated': { color: 'red', name: 'Deprecated' },
                }
            };
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
