<template>
    <div class="create-subscriber">
        <div class="flex flex-align drawer-header">
            <h2 class="ff drawer-title text-overflow">{{ drawer_title }}</h2>
            <div class="button red delete" v-if="props.subscriber" @click="delete_subscriber">Delete Subscriber</div>
            <div class="button base"  :class="{ loading: saving }" @click="save">
                <template v-if="saving">
                    <div class="spinner-container">
                        <spinner />
                    </div>
                </template>
                {{ drawer_button_text }}
            </div>
            <div class="button white base" @click="close">Close</div>
        </div>
        <div class="drawer-content drawer-scroll" :class="{ loading }">
            <div class="spinner-container" v-if="loading">
                <spinner />
            </div>
            <div class="drawer-section" v-else>
                <form autocomplete="off">
                    <label for="subscriber_name">Name</label>
                    <div class="form-helptext">The human readable name for this Subscriber.</div>
                    <input type="text" name="subscriber_name" v-model="subscriber.name" placeholder="Subscriber..." />

                    <label for="subscriber_state">Type</label>
                    <div class="form-helptext">The type of reciever for this Subscriber.</div>
                    <select class="block" v-model="subscriber.type" name="subscriber_state">
                        <option value="google_pubsub">Google Pub/Sub</option>
                        <!-- <option value="webhook">Webhook</option> -->
                    </select>

                    <label for="subscriber_state">State</label>
                    <div class="form-helptext">The state of this Subscriber.</div>
                    <select class="block" v-model="subscriber.state" name="subscriber_state">
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>

                    <label for="subscriber_events">Events</label>
                    <div class="form-helptext">The events to subscribe to.</div>
                    <search-select name="subscriber_events" :values.sync="subscriber.events" :enum="event_enum" :array="true" placeholder="Select one or more..." />

                    <template v-if="subscriber.type === 'google_pubsub'">
                        <label for="config">Google Project ID</label>
                        <div class="form-helptext">The Google Cloud Project ID.</div>
                        <input type="text" v-model="subscriber.configuration.project_id" name="config" placeholder="Project ID..." />
                        <label for="config">Google Pub/Sub Topic</label>
                        <div class="form-helptext">The Google Cloud Pub/Sub Topic.</div>
                        <input type="text" v-model="subscriber.configuration.topic" placeholder="Topic..." />
                    </template>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { Trash } from '@epiphany/iconoir';
    import SearchSelect from '@/components/SearchSelect.vue';


    export default {
        name: 'SubscriberDrawer',
        components: {
            'search-select': SearchSelect,
            Trash
        },
        data(){
            return {
                loading: true,
                saving: false,
                subscriber: {
                    id: null,
                    state: 'active',
                    name: [],
                    type: null,
                    configuration: {},
                    events: []
                },
                event_enum: {
                    'person.login': 'Person Login',
                    'person.login.instant': 'Instant Login',
                    'person.login.scoped': 'Scoped Token',
                    'person.login.lti': 'LTI Login',
                    'integration.created': 'Integration Created',
                    'integration.updated': 'Integration Updated',
                    'integration.marked_for_deletion': 'Integration Marked for Deletion',
                    'integration.destroyed': 'Integration Destroyed',
                    'application.secret.created': 'Application Secret Created',
                    'application.secret.deleted': 'Application Secret Deleted',
                    // 'sharing_rule.created': 'Sharing Rule Created',
                    // 'sharing_rule.updated': 'Sharing Rule Updated',
                    // 'sharing_rule.deleted': 'Sharing Rule Deleted',
                    // 'transformation.created': 'Transformation Created',
                    // 'transformation.updated': 'Transformation Updated',
                    // 'transformation.deleted': 'Transformation Deleted',
                    'materialization.scheduled': 'Materialization Scheduled',
                    // 'materialization.completed': 'Materialization Completed',
                    // 'materialization.error': 'Materialization Error',
                    // 'materialization.canceled': 'Materialization Canceled'
                }
            };
        },
        async mounted() {
            if(this.props.subscriber) {
                const raw =  _.cloneDeep(this.props.subscriber);
                this.subscriber = {
                    id: raw.id,
                    state: raw.state,
                    name: raw.name,
                    type: raw.type,
                    configuration: raw.configuration,
                    events: raw.events
                };
            }

            this.loading = false;
        },
        methods: {
            save(){
                const new_subscriber = _.cloneDeep(this.subscriber);

                if (!new_subscriber.type) {
                    this.$toasted.error('Please select a type.');
                    return;
                }

                this.saving = true;

                setTimeout(() => {
                    if(this.creating) {
                        this.$http.post(`/teams/${this.team.id}/applications/${this.application.id}/subscribers`, new_subscriber, { baseURL: '/api/v1' })
                            .then(_ => {
                                // Close the drawer.
                                this.close();
                                // Refresh the list of products
                                if (this.props.refreshParent) {
                                    this.props.refreshParent();
                                }
                                this.$toasted.success('Subscriber successfully created')
                            })
                            .catch(error => this.$toasted.error(error?.$error ? error.$error : 'There was an error creating your Subscriber.'))
                            .finally(() => this.saving = false);
                    } else {
                        this.$http.patch(`/teams/${this.team.id}/applications/${this.application.id}/subscribers/${this.subscriber.id}`, new_subscriber, { baseURL: '/api/v1' })
                            .then(_ => {
                                // Close the drawer.
                                this.close();
                                // Refresh the list of products
                                if (this.props.refreshParent) {
                                    this.props.refreshParent();
                                }
                                this.$toasted.success('Subscriber successfully updated')
                            })
                            .catch(error => this.$toasted.error(error?.$error ? error.$error : 'There was an error updating your Subscriber.'))
                            .finally(() => this.saving = false);
                    }
                }, 500);
            },
            delete_subscriber(){
                this.$http.delete(`/teams/${this.team.id}/applications/${this.application.id}/subscribers/${this.subscriber.id}`, { baseURL: '/api/v1' })
                    .then(_ => {
                        // Close the drawer.
                        this.close();
                        // Refresh the list of products
                        if (this.props.refreshParent) {
                            this.props.refreshParent();
                        }
                        this.$toasted.success('Subscriber successfully deleted')
                    })
                    .catch(error => this.$toasted.error(error?.$error ? error.$error : 'There was an error deleting your Subscriber.'))
                    .finally(() => this.saving = false);
            },
            close(){
                this.$store.dispatch('drawer/close');
            },
        },
        computed: {
            creating(){
                return this.props.subscriber === null || this.props.subscriber === undefined;
            },
            team(){
                return this.$store.getters.team;
            },
            application(){
                return this.$store.getters.application;
            },
            drawer_button_text() {
                if(this.creating) {
                    return 'Create Subscriber'
                } else {
                    return 'Save Subscriber'
                }
            },
            drawer_title() {
                if(this.creating) {
                    return 'Create a Subscriber'
                } else {
                    return 'Edit Subscriber'
                }
            },
            props(){
                return this.$store.state.drawer.props;
            },
        },
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    .create-subscriber
    {
        height: 100%;
    }

    .drawer-header {
        .button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    header
    {
        border-bottom: 1px solid @e4;

        .button
        {
            margin-left: 20px;
        }
    }

    .drawer-content
    {
        .spinner-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    .drawer-scroll
    {
        overflow: auto;

        .drawer-section:last-child
        {
            padding: @double-padding;

            .form-options
            {
                padding-bottom: 0;
            }
        }

        &.loading {
            height: 100%;
        }
    }

    .button {
        &.loading {
            pointer-events: none;
            color: transparent;
        }

        .spinner-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            ::v-deep .spinner {
                color: @f8;

                .rotation {
                    .path {
                        stroke: @f8;
                    }
                }
            }
        }
    }
</style>
