<template>
    <div class="configuration" :class="{disabled}">
        <div class="form-field" v-for="key of keys">
            <template v-if="fields[key].type === 'string'">
                <label>{{fields[key].title}}</label>
                <div class="form-helptext" v-if="fields[key].description">{{fields[key].description}}</div>
                <select v-if="fields[key].enum" :class="fields[key].size">
                    <option v-for="option of fields[key].enum" :key="option.value" :value="option.value">{{option.title}}</option>
                </select>
                <input v-else type="text" :class="fields[key].size" />
            </template>
            <template v-else-if="fields[key].type === 'boolean'">
                <label>{{fields[key].title}}</label>
                <div class="form-helptext" v-if="fields[key].description">{{fields[key].description}}</div>
                <div class="flex">
                    <checkbox :label="fields[key].label" />
                </div>
            </template>
            <!-- <template v-else-if="fields[key].type === 'account'">
                <label>{{fields[key].title}}</label>
                <div class="form-helptext" v-if="fields[key].description">{{fields[key].description}}</div>
                <div class="flex">
                    <account :application="fields[key].application" :properties="fields[key].properties" />
                </div>
            </template> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Configuration',
        props: {
            fields: Object,
            values: Object,
            disabled: Boolean
        },
        data(){
            return {};
        },
        computed: {
            keys(){
                return Object.keys(this.fields);
            }
        },
        methods: {
            update(field, $event){
                this.$emit('update:values', Object.assign);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .configuration
    {
        margin-top: 30px;

        &:first-child
        {
            margin-top: 0;
        }
    }
</style>
