import axios from 'axios';

let throttle = null;

const state = {
    // This variable is to be able to use the command palette from other contexts and not use the default behavior.
    // For example, we want to call it from the "Merge Teams" function, but we don't want to redirect to the selected team.
    caller: null,
    loading: false,
    visible: false,
    result: {
        categories: [],
        items: []
    },
    defaults: {
        results: [],
        categories: [
            {
                type: 'team',
                title: 'Teams',
                component: 'command-palette-team'
            }
        ],
        placeholder: 'Search For Anything...'
    }
};

const mutations = {
    open(state, caller) {
        state.visible = true;
        state.caller = caller;
    },
    close(state) {
        state.visible = false;
        state.results = [];
        state.sorted = [];
        state.selected = 0;

        if (state.caller) {
            state.caller = null;
        }
    },
    teams(state, teams) {
        state.defaults.results = teams;
    },
    categories(state, categories) {
        state.result.categories = categories;
    },
    selected(state, index) {
        state.selected = index;
    },
    loading(state, loading) {
        state.loading = loading;
    },
    items(state, items) {
        state.result.items = items;
    }
};

const actions = {
    initialize({ commit }) {},
    open({ commit }, caller) {
        commit('open', caller);
    },
    close({ commit }) {
        commit('close');
    },
    search({ commit, state }, query) {
        // Set the state to loading.
        commit('loading', true);

        if (throttle) {
            clearTimeout(throttle);
        }

        throttle = setTimeout(async () => {
            let results = null;

            if (state.caller?.search) {
                // Call an arbitrary search function that returns a list of results.
                results = await state.caller.search(query);
            } else {
                // The default is just to search our teams endpoint.
                const params = {
                    $first: 50,
                    $filter: {
                        name: [{ operator: 'contains', value: query }],
                        status: [{ operator: 'in', value: 'active,inactive' }]
                    }
                };

                results = await axios
                    .get('/admin/teams', { params })
                    .then((response) => response.$data)
                    .then((teams) => {
                        return teams.map((team) => {
                            return {
                                item: team,
                                matches: [],
                                category: 'team',
                                score: 1
                            };
                        });
                    });
            }

            if (!_.isArray(results)) {
                return reject('Results must be an array.');
            }

            const categories = state.caller?.categories ? state.caller.categories : state.defaults.categories;

            // Filter out the categories that don't have any results.
            // Sort the categories by result score.
            const buckets = {};

            // Group the results into buckets (by result category).
            for (const result of results) {
                if (!buckets[result.category]) {
                    buckets[result.category] = [];
                }

                buckets[result.category].push(result);
            }

            // Create a sorted list of categories.
            const sorted = [];

            for (const category of categories) {
                if (buckets[category.type]?.length) {
                    sorted.push({
                        type: category.type,
                        min: Math.min(...buckets[category.type].map((i) => i.score))
                    });
                }
            }

            // Sort the CATEGORIES by the relevance of their most relevant ITEM.
            sorted.sort((a, b) => a.min - b.min);

            // Commit the categories to the state.
            commit('categories', sorted);

            // Sort the results array and build result objects.
            const entries = results.map((result) => {
                return {
                    data: result.item,
                    matches: result.matches,
                    category: result.category,
                    ref: result.item.id,
                    component: 'command-palette-team'
                };
            });

            // Sort entries appropriately and then attach an index to each item.
            const indices = sorted.map((category) => category.type);

            entries.sort((a, b) => {
                // If they are in different categories, sort by category.
                if (a.category !== b.category) {
                    return indices.indexOf(a.category) - indices.indexOf(b.category);
                }

                // Otherwise, sort by score.
                return a.score - b.score;
            });

            // Attach an index to each item.
            entries.forEach((entry, index) => (entry.index = index));

            // Commit our results.
            commit('items', entries);

            // Set loading to false.
            commit('loading', false);
        }, 200);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
