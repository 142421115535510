<template>
    <chip color="blue" title="Verified" v-if="verified">
        <template #icon>
           <VerifiedBadgeAlt class="icon" width="12" height="12" />
        </template>
    </chip>
</template>

<script>
    import { VerifiedBadgeAlt } from '@epiphany/iconoir';

    export default {
        name: 'Verified',
        components: {
            VerifiedBadgeAlt
        },
        props: {
            verified: Boolean
        },
        data(){
            return {};
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .icon
    {
        margin: 1px;
    }
</style>
