import axios from 'axios';

const state = {
    active: null
};

let throttle = null;

const mutations = {
    activate(state, transformation) {
        state.active = transformation;
    },
    deactivate() {
        state.active = null;
    },
    update(state, updated) {
        const valid_fields = ['updated_date', 'configuration', 'description', 'title', 'function'];

        for (const field of valid_fields) {
            if (updated.hasOwnProperty(field)) {
                state.active[field] = updated[field];
            }
        }
    }
};

const actions = {
    activate({ commit }, transformation) {
        commit('activate', transformation);
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit, state, dispatch, rootGetters }) {
        if (state.active) {
            if (throttle) {
                clearTimeout(throttle);
            }

            // Dispatch a change to our save service.
            dispatch('save/save', 'blocks/update', { root: true });

            throttle = setTimeout(() => {
                throttle = null;

                axios
                    .put(`/teams/${rootGetters.team.id}/blocks/${state.active.id}`, state.active)
                    .then((response) => commit('update', response.$data))
                    .finally(() => dispatch('save/saved', 'blocks/update', { root: true }));
            }, 500);
        } else {
            return Promise.reject('There is no active block.');
        }
    },
    destroy({ commit, state, rootGetters }) {
        if (state.active) {
            return axios.delete(`/teams/${rootGetters.team.id}/blocks/${state.active.id}`).then(() => commit('deactivate'));
        }

        return Promise.reject('There is no active block.');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
