<template>
    <article class="changelog constrain bc">
        <div class="hero">
            <h1>Changelog</h1>
            <h2>Learn about what's new in the Edlink API and Dashboard.</h2>
            <!-- <div class="flex">
                <div class="button" @change="subscribe" :class="{disabled: false, loading: false}">Subscribe for Updates</div>
            </div> -->
        </div>
        <section class="flex" id="28-Aug-2023">
            <div class="date-container flex">
                <a class="date" href="#28-Aug-2023">August 28th, 2023</a>
            </div>
            <div class="ff">
                <h1>Bug Fixes &amp; Quality of Life Improvements</h1>
                <img class="cover-image" src="/changelog/28-aug-2023.png" />
                <p>
                    Back to school is in full swing and so is our engineering team.
                    Here's a list of the recent changes and bug fixes to ensure that clients & their districts get up and running smoothly.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue that was causing some joins to fail during data enrichment.
                    </li>
                    <li>
                        Fixed an internal logging issue that led to degraded performance.
                    </li>
                    <li>
                        Outbound requests on graph calls were not being properly logged.
                    </li>
                    <li>
                        Fixed an issue with `assign_date` when returning grades to OneRoster sources.
                    </li>
                    <li>
                        Fixed an issue with sending personalized assignments to Brightspace.
                    </li>
                    <li>
                        Added support for sending personalized assignments to secondary sources.
                    </li>
                    <li>
                        Upgraded Clever Library support to v3.0.
                    </li>
                    <li>
                        Fixed an issue where we were not returning the `id` of LTI submissions.
                    </li>
                    <li>
                        Updated our Veracross SIS sync to pull in additional models.
                    </li>
                    <li>
                        Updated Powerschool sync to add more helpful names to school calendars.
                    </li>
                    <li>
                        Check Blackboard Ultra settings correctly in API v1.
                    </li>
                    <li>
                        Return a more helpful status code than `500` when token grant fails.
                    </li>
                    <li>
                        Added support for Clever extension fields.
                    </li>
                    <li>
                        Added support for additional Clever demographics.
                    </li>
                    <li>
                        Corrected missing `subject_id` and `department_id` fields on `Course` model.
                    </li>
                    <li>
                        Expose announcement created and updated dates.
                    </li>
                    <li>
                        Added District Office to Powerschool integrations.
                    </li>
                    <li>
                        Correctly remove parents and guardians, even when licensing rules are not set.
                    </li>
                    <li>
                        Skip failed Aeries API calls when they fail for no reason.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Updated the look and functionality of the data filtering component.
                    </li>
                    <li>
                        Fixed an issue with scrolling in the Eddie Lynx AI chat window.
                    </li>
                    <li>
                        Fixed an issue with text overflowing in the entity drawer.
                    </li>
                    <li>
                        Added administrator onboarding flow for OIDC sources.
                    </li>
                    <li>
                        Properly lower-case email addresses when sharing classes by teacher email.
                    </li>
                    <li>
                        Added link to Skyward instructions page in the admin onboarding flow.
                    </li>
                    <li>
                        Fixed the "Remove" button on overrides in the Data Pipeline view.
                    </li>
                    <li>
                        Added a warning banner to prevent users from modifying blocks that are not owned by their team.
                    </li>
                    <li>
                        Correctly show boolean values on the entity drawer when setting overrides.
                    </li>
                    <li>
                        Added integration-level data validation so you can add additional validation rules that are unique to specific districts.
                    </li>
                    <li>
                        Added the ability to modify permissions for existing district connections.
                    </li>
                    <li>
                        Fixed a navigation issue when in the Data Pipeline previewer.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/say-hey-to-eddie-lynx-our-new-ai-chatbot/" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="21-Aug-2023">
            <div class="date-container flex">
                <a class="date" href="#21-Aug-2023">August 21st, 2023</a>
            </div>
            <div class="ff">
                <h1>Say hey to Eddie Lynx, our new AI chatbot</h1>
                <img class="cover-image" src="/changelog/21-aug-2023.png" />
                <p>
                    We've trained up a chatbot called Eddie Lynx using the entire corpus of data about Edlink.
                    This includes our technical documentation, our blog, and our knowledge base.
                    You can access Eddie by clicking the "Ask the AI" button in the top-right corner of the Edlink dashboard.
                </p>
                <p>
                    Eddie can answer many questions that you have about Edlink (both technical, and non-technical).
                    If you ask, Eddie can even write code in your language of choice.
                </p>
                <p>
                    He is still a little bit rough around the edges (for a number of reasons), but we're working actively on tightening up his responses.
                    Please let us know if you see anything out of the ordinary!
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Edlink will now return `null` for assignment due date when it is null in the LMS. Some LMSs allow the due date field to be null, but the Edlink API does not. Please note, this does not mean you can send Edlink null values in a request to create an assignment in the LMS.
                    </li>
                    <li>
                        We added support for the other_id field in Skyward SMS.
                    </li>
                    <li>
                        We fixed an issue that was preventing periods and subject names to propagate down the pipeline for Classlink integrations (please note, these are different than our new Period and Subject models).
                    </li>
                    <li>
                        We smoothed out an unusual edge case where the Schoology would ignore your chosen assignee_mode when none of the assignee_ids were valid for an assignment.
                    </li>
                    <li>
                        Fixed an issue that was affecting incidents without a `reporter_id`.
                    </li>
                    <li>
                        Fixed a 500 error that would occur occasionally when you tried to list announcements from Google Classroom.
                    </li>
                    <li>
                        We've made a formal enum for timezone values, instead of allowing them to remain random strings.
                    </li>
                    <li>
                        We've made some upgrades to improve the stability of our sync pipeline.
                    </li>
                    <li>
                        We made facility_id a required field for rooms in our data model.
                    </li>
                    <li>
                        Creating v2 assignments will now properly create a gradebook column in the Brightspace gradebook. This was working OK in API v1.
                    </li>
                    <li>
                        Fixed an issue where we were trying to create facilities before rooms in our Veracross sync (which was an issue now that facility_id is required.
                    </li>
                    <li>
                        Fixed an issue with user impersonation when your primary redirect URI contains query params (we were not correctly appending the code).
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        We updated the types library attached to the code editor embedded into the Edlink dashboard so it will now contain the latest versions of all of our new models (and suggest them to you as you code)!
                    </li>
                    <li>
                        We added support for different versions of authentication when onboarding a new OneRoster connection. We now support OAuth 1.0 and OAuth 2.0.
                    </li>
                    <li>
                        We added similar support for our Infinite Campus onboarding flow.
                    </li>
                    <li>
                        We added the `staff` role to various role dropdowns in our UI. They were previously missing (but available via the API).
                    </li>
                    <li>
                        Fixed an issue where the administrator onboarding flow would not correctly save your changes if the source did not validate successfully on the first try.
                    </li>
                    <li>
                        We added some new options so you can customize the behavior of your LTI 1.1 and LTI 1.3 applications in the LMS.
                    </li>
                    <li>
                        You can now view and edit the code that the AI block outputs.
                    </li>
                    <li>
                        We replaced the "Request Integration" drawer (which was pretty useless) with a button to copy your integration link.
                    </li>
                    <li>
                        Added support for escaped column names in our Lynx programming language so you can now import CSV columns with special characters in the name.
                    </li>
                    <li>
                        Added some new filters on the integrations page.
                    </li>
                    <li>
                        Made some improvements to the "chips" on the Edlink dashboard.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/say-hey-to-eddie-lynx-our-new-ai-chatbot/" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="14-Aug-2023">
            <div class="date-container flex">
                <a class="date" href="#14-Aug-2023">August 14th, 2023</a>
            </div>
            <div class="ff">
                <h1>Check out Edlink's new data validation capabilities</h1>
                <img class="cover-image" src="/changelog/14-aug-2023.png" />
                <p>
                    We're thrilled to release an oft requested feature: Data Validation.
                    Developers ask us all the time if we're able to ensure that school data is in the correct format to be imported into their platform.
                    As of today, the answer is yes!
                </p>
                <ul>
                    <li>
                        Data validation runs every time school data changes.
                    </li>
                    <li>
                        You can define specific rules for the format of data you want.
                    </li>
                    <li>
                        For example, you can require that all users have an email address.
                    </li>
                    <li>
                        When a particular entity fails your validation rule, you can decide whether or not you want to delete the item from the dataset or record it as a "warning".
                    </li>
                    <li>
                        Edlink will report the total number of notable entities (i.e. entities that have a validation status of warning or error).
                    </li>
                </ul>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue with Canvas source validation when there is a root account ID other than the default ID of "1".
                    </li>
                    <li>
                        We can now sync Canvas terms (sessions) from a different root account ID than the root account ID for classes and people.
                    </li>
                    <li>
                        Fixed an LTI 1.3 bug where we were overwriting the Canvas speedgrader info when you attempted to pass back a grade for a submission.
                    </li>
                    <li>
                        We are now pulling in the Clever 3.0 `staff_id`.
                    </li>
                    <li>
                        On that note, we've bumped up the default Clever version to 3.0 for all new applications. We still support 2.1 if needed, but you'll have to contact your Edlink Client Success Manager to have it downgraded.
                    </li>
                    <li>
                        We were not correctly exposing school and district phone numbers in the address field.
                    </li>
                    <li>
                        We are starting to returning more accurate HTTP status codes. Most requests were returning 400, which was generally incorrect or unhelpful.
                    </li>
                    <li>
                        Fixed an issue with correctly identifying staff members in a specific build of Powerschool SIS.
                    </li>
                    <li>
                        Fixed an issue with sending Canvas percentage grades.
                    </li>
                    <li>
                        Pushed a fix so that Google Classroom announcements will now correctly return their associated materials (attachments).
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        You can now paste lists of items (either new line or comma separated) into the Product Licensing rules input. The items will be automatically split and formatted.
                    </li>
                    <li>
                        You can now directly edit the code produced by the AI block.
                    </li>
                    <li>
                        We've added new permissions to the application Data Sharing screen.
                    </li>
                    <li>
                        We're releasing "Essential" versions of some of our SIS integrations to support clients who do not need the full data model that Edlink offers.
                    </li>
                    <li>
                        You can now override periods on the Edlink dashboard.
                    </li>
                    <li>
                        Corrected an issue in the onboarding flow that was preventing the data source from validating successfully.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/announcing-data-validation/" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="25-Jul-2023">
            <div class="date-container flex">
                <a class="date" href="#25-Jul-2023">July 25th, 2023</a>
            </div>
            <div class="ff">
                <h1>Rolling out support for Renweb FACTS and OneLogin SSO</h1>
                <img class="cover-image" src="/changelog/25-jul-2023.png" />
                <p>
                    The engineering team has been busy lately. This week, we're proud to announce support for two new systems: Renweb FACTS and OneLogin.
                    FACTS is a popular student information system in K-12. We've implemented support for our new extended rostering features, incident reports, and grade syncing.
                    OneLogin is an enterprise SSO platform that helps teachers and students sign into their apps.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Added support for FACTS.
                    </li>
                    <li>
                        Added support for OneLogin SSO.
                    </li>
                    <li>
                        Powerschool will no longer sync schools with an ID of `0` and we have corrected an issue with conversion from integers to strings.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Simplified the screen that allows you to copy your application's Integration Link, removing a click.
                    </li>
                    <li>
                        Added an LTI 1.1 XML setup URL to your dashboard to make it easier for districts to add LTI 1.1 links to their LMS.
                    </li>
                    <li>
                        Updated administrator onboarding flows for FACTS and OneLogin.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/rolling-out-support-for-renweb-facts-integration/" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="21-Jul-2023">
            <div class="date-container flex">
                <a class="date" href="#21-Jul-2023">July 21st, 2023</a>
            </div>
            <div class="ff">
                <h1>Announcing Support for LMS Resources & Modules</h1>
                <img class="cover-image" src="/changelog/21-jul-2023.png" />
                <p>
                    Today, we're going back to our roots and announcing the first expansion to our LMS data model in over a year.
                    I'm happy to announce that we're releasing several new models and endpoints for developers to interact with content in the LMS.
                </p>
                <p>
                    You can now send and retrieve non-assignment resources from classes in the LMS. The first type of resources we're supporting are `modules` and `pages`.
                    Modules are a way for teachers to organize content in the LMS. They can be used to group assignments and pages into an ordered structure.
                    Pages are a way for teachers to create and share content with students. They can be used to share text, images, videos, and links with their classes.
                </p>
                <p>
                    We've also released limited support for our next LMS data model, `announcements`.
                    Announcements are a way for teachers to share information with their classes.
                    We are currently only supporting the ability to retrieve announcements from the LMS, but we will be adding the ability to create and update announcements in the future.
                    The first system that supports announcements is Google Classroom.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Added support for creating, updating, listing, and fetching `modules`.
                    </li>
                    <li>
                        Added support for creating, updating, listing, and fetching `pages`.
                    </li>
                    <li>
                        Added support for inserting new pages and assignments into an existing `module`.
                    </li>
                    <li>
                        Added support for listing and fetching `announcements` from Google Classroom.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Added documentation for `modules`, `pages`, and `announcements`.
                    </li>
                    <li>
                        We've also updated the integrations page so you can filter your connected schools by region, application, and state.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/announcing-support-for-lms-resources-and-modules/" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="18-Jul-2023">
            <div class="date-container flex">
                <a class="date" href="#18-Jul-2023">July 18th, 2023</a>
            </div>
            <div class="ff">
                <h1>Beefing up our support for Skyward SMS and Qmlativ</h1>
                <img class="cover-image" src="/changelog/18-jul-2023.png" />
                <p>
                    We've rewritten our Skyward SMS integration to provide support for our extensive new data models.
                    In addition to releasing this updated integration, we've also added support for Skyward's next-generation SIS, Qmlativ (pronounced: cumulative).
                </p>
                <p>
                    Of course, to go along with these changes, we've also updated our administrator onboarding flow for Skyward SMS and introduced a new flow for Skyward Qmlativ.
                    This onboarding flow contains all of the instructions that administrators need for a quick and painless setup process.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Added SMS & Qmlativ support for our extended rostering models (e.g. periods, room numbers, facilities).
                    </li>
                    <li>
                        Added SMS & Qmlativ support for coursework and grade sync.
                    </li>
                    <li>
                        Added SMS & Qmlativ support for calendars and attendance.
                    </li>
                    <li>
                        Added SMS & Qmlativ support for behavior / incident reports.
                    </li>
                    <li>
                        We fixed an issue with upcoming sessions in Schoology. They were previously being imported as `inactive`, which was technically correct, but not what we were expecting to happen.
                    </li>
                    <li>
                        We can now process custom roles that are set in Brightspace.
                    </li>
                    <li>
                        Google Classroom classes will now have a primary teacher (who is the one marked as the owner of the class). Previously, we imported all teachers (including co-teachers) but we did not mark anyone as the "primary" teacher.
                    </li>
                    <li>
                        We've exposed a new property on assignments called url. This property is read-only and contains a URL that points back to the assignment within the LMS.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Updated the administrator onboarding flows for Skyward SMS and Qmlativ.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/upgrading-our-support-for-skyward-sms-and-qmlativ/" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="20-Jun-2023">
            <div class="date-container flex">
                <a class="date" href="#20-Jun-2023">June 20th, 2023</a>
            </div>
            <div class="ff">
                <h1>Near-Realtime Attendance, Updated Docs, and SSO Error Flows</h1>
                <img class="cover-image" src="/changelog/20-jun-2023.png" />
                <p>
                    For a lot of products, knowing the current location of a particular student is critical to their core experience. We're happy to announce the first of a series of experiments with near-realtime attendance data. For Powerschool SIS integrations, you can now receive period-level attendance data that is refreshed every 15 minutes.
                </p>
                <p>
                    Along with our slew of new models from a few weeks back, we've pushed a major update to our developer documentation. The biggest part of this update was a reorganization of the API Reference structure. Instead of being grouped by user / graph API, models and endpoints are now grouped by functional area.
                </p>
                <p>
                    The last major release this week is a new feature flag that will redirect users back to your application if they encounter an error while trying to sign in.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Added support for near-realtime attendance data for Powerschool SIS integrations.
                    </li>
                    <li>
                        Added a feature flag to redirect users back to your application if they encounter an error while trying to sign in.
                    </li>
                    <li>
                        Updated our Skybuild plugin for Skyward to fix a few issues.
                    </li>
                    <li>
                        Upgraded our Blackbaud sync to add support for our new models.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Updated the organizational structure of our API Reference documentation.
                    </li>
                    <li>
                        Fixed two of the person override fields on the Edlink dashboard (military connected status and primary language).
                    </li>
                    <li>
                        Added back the Run Sync button for sources that you control (e.g. sandboxes) so you can force Edlink to sync from the source.
                    </li>
                    <li>
                        Added back shared people counts to the integrations page
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/near-realtime-attendance-updated-docs-and-sso-error-flows" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="15-Jun-2023">
            <div class="date-container flex">
                <a class="date" href="#15-Jun-2023">June 15th, 2023</a>
            </div>
            <div class="ff">
                <h1>Integration Analytics, Aeries Sync Upgrade, and Dashboard Improvements</h1>
                <img class="cover-image" src="/changelog/15-jun-2023.png" />
                <p>
                    It's shipping season here at Edlink, and this week is no exception. We have some cool stuff on the docket this week, so without further ado:
                </p>
                <p>
                    The biggest (and most obvious) change today is the release of our integration analytics dashboard. Once your team is a verfied client, it will
                    replace the Getting Started section on the Edlink Dashboard. On this screen you can see some statistics about your requests, logins, and shared users.
                    We'll be adding more to this dashboard in the future, so stay tuned.
                </p>
                <p>
                    We've also beefed up our Aeries sync to support our new models. This includes support for the following types:
                    meetings, attendance, calendars, days, periods, fees, and incidents.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Upgraded our Aeries sync to support our new models.
                    </li>
                    <li>
                        Improved the speed at which our pipeline flushes changes to our database.
                    </li>
                    <li>
                        Fixed an issue with the endpoint to list submissions from Brightspace.
                    </li>
                    <li>
                        Fixed an issue launching from the Clever dashboard when Clever API V2.1 was disabled for your application.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        After your team is verified, you'll now see a new dashboard screen that shows some basic analytics about your integrations.
                    </li>
                    <li>
                        You can now name and duplicate licensing rules.
                    </li>
                    <li>
                        Added 5 new Lynx functions to the CSV parsing screen.
                    </li>
                    <li>
                        Fixed an issue that was causing the counts of our new models to appear as zero, regardless of the actual count.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/integration-analytics-and-aeries-sync-improvements" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="07-Jun-2023">
            <div class="date-container flex">
                <a class="date" href="#07-Jun-2023">June 7th, 2023</a>
            </div>
            <div class="ff">
                <h1>Extended Rostering, Attendance, Behavior, and More</h1>
                <img class="cover-image" src="/changelog/7-jun-2023.png" />
                <p>
                    Our mission at Edlink has always been to improve the quality of education through the use of technology.
                    We envision a world in which the story of a student's educational experience can be summarized and visualized
                    cleanly and simply. This way, anyone involved in that student's development can quickly and effectively meet
                    that student where they are.
                </p>
                <p>
                    Today, we're driving that mission forward in a big way.
                </p>
                <p>
                    Our engineering team has been hard at work designing and implementing 15 new models that cover six key areas:
                    Extended Rostering, Behavior, Attendance, Facility Management, Calendars, and Transportation.
                </p>
                <p>
                    This update represents the single largest change to our API and data model since the launch of Edlink and we're
                    really excited to see how developers use this new data to improve their platforms and the lives of students around the world.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Added 15 new models to the API: 
                    </li>
                    <ul>
                        <li>Extended Rostering: <code>Departments</code>, <code>Subjects</code>, <code>Periods</code>, <code>Incidents</code></li>
                        <li>Behavior: <code>Incidents</code></li>
                        <li>Attendance: <code>Meetings</code>, <code>Attendance</code></li>
                        <li>Facility Management: <code>Facilities</code>, <code>Rooms</code>, <code>Assets</code>, <code>Fees</code></li>
                        <li>Transportation: <code>Stops</code>, <code>Routes</code>, <code>Vehicles</code></li>
                        <li>Calendars: <code>Calendars</code>, <code>Days</code></li>
                    </ul>
                    <li>
                        Added approximately 30 new API endpoints to support the new models.
                    </li>
                    <li>
                        Updated a number of existing models to include new fields and relationships.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        The most obvious change is that we've adjusted the color of the left-hand sidebar and the navigation links.
                    </li>
                    <li>
                        The navigation has now changed to be context-specific. That is, the navigation options will now change depending on your page within the dashboard.
                    </li>
                    <li>
                        This new navigation scheme was created for two reasons: There were getting to be too many horizontal tabs; and the sidebar was a poor use of valuable space that we wanted to reclaim in order to de-clutter our data views.
                    </li>
                    <li>
                        We've added breadcrumbs to the top bar to help you navigate back to where you came from.
                    </li>
                    <li>
                        We're making improvements to our data tables (as there are many) to make them more readable and organized. Expect to see more changes around these elements in the future.
                    </li>
                    <li>
                        You can now preview all types of data in the dashboard, including our new models. You used to only be able to preview a handful (e.g. schools, people).
                    </li>
                    <li>
                        Product Licenses and Data Pipeline have been split into their own tabs. This is to make room for more features coming in the near future.
                    </li>
                    <li>
                        The Data Pipeline preview fits more seamlessly into the overall UI now and will observe forward and back buttons on your browser.
                    </li>
                    <li>
                        Updated the API documentation and the Postman collection to include the new data models and API endpoints.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/attendance-behavior-calendars-and-more" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="04-Apr-2023">
            <div class="date-container flex">
                <a class="date" href="#04-Apr-2023">April 4th, 2023</a>
            </div>
            <div class="ff">
                <h1>Sign into the Edlink Dashboard with Google SSO</h1>
                <img class="cover-image" src="/changelog/4-apr-2023.png" />
                <p>
                    Edlink already enables millions of teachers and students to sign in with Google and we wanted to extend
                    the same convenience and security to companies as well. Now, users can sign in to Edlink dashboard using
                    their Google credentials, eliminating the need to remember and manage separate login information.
                </p>
                <p>
                    We understand that security and ease of use are crucial for our users, and we are constantly working to
                    improve our platform. To that end, Google SSO works seamlessly with our existing two-factor authentication
                    flow, providing an extra layer of security for all users.
                </p>
                <!-- <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue with the `/v2/graph/districts/:district_id` endpoint where it was failing to return the requested district.
                    </li>
                    <li>
                        Fixed an issue with `school` licensing rules where they were missing data that should have been shared by reference.
                    </li>
                    <li>
                        All entity types will now be validated when they are added or modified in the `$input` dataset during transformation.
                    </li>
                    <li>
                        Our entity IDs are now type-safe when you're writing custom transformations.
                        For example, you can no longer accidentally try to access a class with a course ID.
                    </li>
                    <li>
                        Fixed an issue with Canvas identifiers when the Canvas entity ID was greater than `MAX_INT`.
                    </li>
                    <li>
                        Edlink can now sync additional properties from Microsoft AD that are not typically available in Microsoft Teams for Education.
                    </li>
                </ul> -->
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Made the font size a little bigger on our developer documentation.
                    </li>
                    <li>
                        Change the style of our code blocks to be more readable.
                    </li>
                    <li>
                        Added a new section under developer guides for LTI 1.3 implementation.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="21-Mar-2023">
            <div class="date-container flex">
                <a class="date" href="#21-Mar-2023">March 21st, 2023</a>
            </div>
            <div class="ff">
                <h1>Bug Fixes &amp; User Experience Improvements</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue with the `/v2/graph/districts/:district_id` endpoint where it was failing to return the requested district.
                    </li>
                    <li>
                        Fixed an issue with `school` licensing rules where they were missing data that should have been shared by reference.
                    </li>
                    <li>
                        All entity types will now be validated when they are added or modified in the `$input` dataset during transformation.
                    </li>
                    <li>
                        Our entity IDs are now type-safe when you're writing custom transformations.
                        For example, you can no longer accidentally try to access a class with a course ID.
                    </li>
                    <li>
                        Fixed an issue with Canvas identifiers when the Canvas entity ID was greater than `MAX_INT`.
                    </li>
                    <li>
                        Edlink can now sync additional properties from Microsoft AD that are not typically available in Microsoft Teams for Education.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed an issue with boolean overrides in the dashboard (e.g. `english_language_learner` overrides).
                    </li>
                    <li>
                        Fixed the CSV source validation function so it will now properly validate custom CSV files.
                    </li>
                    <li>
                        Fixed an issue with the login error state value where the state was not being properly URL encoded.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="14-Mar-2023">
            <div class="date-container flex">
                <a class="date" href="#14-Mar-2023">March 14th, 2023</a>
            </div>
            <div class="ff">
                <h1>Transform Data With AI</h1>
                <img class="cover-image" src="/changelog/14-mar-2023.png" />
                <p>
                    Sometimes schools share messy data and getting into the right format can be challenging. Historically,
                    this has always been the task of engineers working on data pipelines. These transformations typically
                    added technical debt to the codebase and made it difficult to maintain as schools came and went.
                    We wanted to extend the power of a good engineer and a solid data pipeline to all users of any technical ability.
                    To do this, we've built a new feature that allows you to transform your data with AI.
                </p>
                <p>
                    Prompt Edlink with your desired transformation, and it will automatically transform the data. You can be as specific
                    as you need to be, and you can even specify conditional logic (e.g. if they're a teacher, then modify their name).
                </p>
                <h2>The New Login Error Page</h2>
                <p>
                    Sometimes debugging SSO errors can be tricky. We've made some updates to the page users land on when
                    they encounter an SSO error. It has been revamped to offer more solutions to common problems and to
                    provide more context for engineers to debug client issues.
                </p>
                <p>
                    Starting with LTI 1.1 and 1.3 launches,
                    our error messages will (over time) be more specific and actionable. In addition, we've added a
                    "Copy Error Details" button that will copy the error details to your clipboard. This will make it
                    easier to share the error details with your engineering team or ours.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue where section IDs were not properly returning with enrollments in V2.
                    </li>
                    <li>
                        Made some improvements to ensure that we were not pushing any incorrect references to the dataset.
                        Previously, you would occasionally see some data that was in a bad state, for example, an enrollment
                        that was not associated with a person or a class.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        We made some updates to the page users land on when they encounter an SSO error.
                        It has been revamped to offer more solutions to common problems and to provide more context for engineers to debug client issues.
                    </li>
                    <li>
                        We've made some performance updates to the Data Pipeline UI so data should load and calculate faster.
                    </li>
                    <li>
                        Added some missing person demographics fields to the documentation.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/product-update-licensing" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="7-Mar-2023">
            <div class="date-container flex">
                <a class="date" href="#7-Mar-2023">March 7th, 2023</a>
            </div>
            <div class="ff">
                <h1>Product Licensing</h1>
                <img class="cover-image" src="/changelog/7-mar-2023.png" />
                <p>
                    A common problem amongst edtech companies and publishers is how to manage access to their products.
                    This problem is doubly hard when companies have multiple products or titles to manage.
                </p>
                <p>
                    After a number of conversations with clients (and some deep thought), we realized that licensing
                    was a natural extension of our existing "sharing rules" feature. In essence, they aimed to solve a
                    similar problem: filtering down users by specific criteria.
                </p>
                <p>
                    Now, when you're setting up a rule on one of your integrations, you'll have the ability to specify
                    one or more products to assign to that group of users. Licenses can be assigned at the school,
                    class, or individual person level, and can optionally include a start and/or end date. As teachers
                    and students move around and rosters change, your licenses will always be applied to the most up to
                    date set of users.
                </p>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Released more fixes to prevent any future dangling enrollments, agents, classes, etc.
                    </li>
                    <li>
                        Made performance improvements to our "meta" endpoints (e.g. SSO).
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        We made some major improvements to the UI for creating and editing licenses (sharing rules).
                    </li>
                    <li>
                        You can now filter your rules by more properties than before.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/product-update-licensing" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="2-Mar-2023">
            <div class="date-container flex">
                <a class="date" href="#2-Mar-2023">March 2nd, 2023</a>
            </div>
            <div class="ff">
                <h1>Frontend Javascript Widgets</h1>
                <img class="cover-image" src="/changelog/2-mar-2023.png" />
                <p>
                    Building great user interfaces can be just as hard as building the technical infrastructure behind them.
                    We've learned that despite our data model and API endpoints looking pretty simple on the surface, it can
                    be a fair bit of work for our clients to fully implement the functionality that they are looking for.
                </p>
                <p>
                    That's why I'm excited to announce the launch of the Edlink Javascript Widget library.
                    This library will become a collection of frontend UI components to make improve the speed of
                    implementation and let developers focus on their core product experience.
                </p>
                <p>
                    Today, we're releasing the first two widgets:
                </p>
                <ul>
                    <li>The <a class="link" href="https://ed.link/docs/api/v2.0/sdk/widgets/authentication" target="_blank">SSO Widget</a> provides an incredible simple experience for users returning to your platform.</li>
                    <li>The <a class="link" href="https://ed.link/docs/api/v2.0/sdk/widgets/assignment-builder" target="_blank">Assignments Widget</a> makes it easy for developers to add Edlink's full range of assignment functionality to their application.</li>
                </ul>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed some issues with dangling enrollments and agents causing materializations to fail.
                    </li>
                    <li>
                        Fixed an issue with listing and fetching submissions in Schoology (both API V1 and V2).
                    </li>
                    <li>
                        Fixed an issue with LTI assignment listing calls caused by our release of opt-in custom properties.
                    </li>
                    <li>
                        Fixed an issue with creating overrides that modified a person's demographics data.
                    </li>
                    <li>
                        Fixed an issue with our Clever sync to continue syncing districts when v2.1 is entirely disabled.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed an issue with the `NOT IN` operator when creating sharing rules for an integration.
                    </li>
                    <li>
                        Fixed an issue that was preventing developers from deleting a transformation via the UI.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/product-update-javascript-widgets" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="21-Feb-2023">
            <div class="date-container flex">
                <a class="date" href="#13-Feb-2023">February 21st, 2023</a>
            </div>
            <div class="ff">
                <h1>Germany &amp; Australia Data Storage Regions</h1>
                <img class="cover-image" src="/changelog/21-feb-2023.png" />
                <p>
                    In order to comply with data sovereignty laws, some schools and universities will require vendors
                    to store student data in a particular region. Even if it's not the law of the land,
                    many schools will adopt this policy as they believe it will provide more security in the long term.
                    After a data storage region is selected, all personally-identifiable information for that school or university is
                    stored in the correct region - geographically isolated from data in other regions.
                </p>
                <ul>
                    <li>We have introduced two new data storage regions: Germany and Australia.</li>
                    <li>There are now four regions total: US, Canada, Germany, and Australia.</li>
                    <li>Germany is in the EU so it provides a good option for EU schools and universities.</li>
                    <li>Administrators can select their desired region during the administrator onboarding flow.</li>
                </ul>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed some issues with how Schoology submissions were handled in V1 linked sections.
                    </li>
                    <li>
                        Fixed an issue with LTI 1.3 submissions paging.
                    </li>
                    <li>
                        Improved a really slow database query when assigning to individual students in V1.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Added better compression to dashboard static assets to improve load times.
                    </li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/germany-and-australia-regions" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="13-Feb-2023">
            <div class="date-container flex">
                <a class="date" href="#13-Feb-2023">February 13th, 2023</a>
            </div>
            <div class="ff">
                <h1>Data Enrichment Just Got a Lot Cooler</h1>
                <p>
                    Sometimes you just need to join data from multiple sources. If you've ever tried to do this in practice though, you know it's tough.
                    We developed data enrichment to make this process quick and easy. Now, we've made it even better.
                </p>
                <ul>
                    <li>Schools can now attach many additional sources of data, rather than just one.</li>
                    <li>We improved our algorithm for more reliable matching of data.</li>
                    <li>You can now manually match and unmatch items.</li>
                    <li>Edlink can now send assignments and grades to secondary data sources.</li>
                </ul>
                <div class="flex">
                    <a class="button block white" href="https://ed.link/community/product-update-improvements-to-data-enrichment" target="_blank">Read Full Update</a>
                </div>
            </div>
        </section>
        <section class="flex" id="09-Feb-2023">
            <div class="date-container flex">
                <a class="date" href="#09-Feb-2023">February 9th, 2023</a>
            </div>
            <div class="ff">
                <h1>Changing the Changelog</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <p>
                    We really wanted to make the changelog more useful and approachable for all users, not just developers.
                    To that end, we've revamped the design and done some reorganization to make it easier to understand what's changed
                    and how that may affect you.
                </p>
                <ul>
                    <li>We merged the API &amp; Dashboard sections of the changelog.</li>
                    <li>From here on out, the changelog will get a little less technical.</li>
                    <li>We plan to start linking out to longer written pieces about our updates.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="09-Feb-2023">
            <div class="date-container flex">
                <a class="date" href="#09-Feb-2023">February 8th, 2023</a>
            </div>
            <div class="ff">
                <h1>Mind the Gap</h1>
                <p>
                    We're still working on backfilling some of our changes from September 2022 - February 2023.
                    That's why it looks like there is an unusually large gap in the changelog.
                </p>
            </div>
        </section>
        <div class="changelog-gap"></div>
        <!-- <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">December 2nd, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added a V2 meta endpoint for logged API requets so you can better filter them.</li>
                    <li>
                        Fixed an issue where LTI 1.3 sync was broken for schools that multiple different Canvas domains.
                    </li>
                </ul>
            </div>
        </section> -->
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 12th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Improvements to many of the source validation functions.</li>
                    <li>Fixed an issue with Brightspace LTI class syncing.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 5th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed the constructed URL for the LTI error page.</li>
                    <li>Return the expanded (human readable) names of courses & sessions for the sharing rules UI.</li>
                    <li>Added more helpful error messages on the remaining V1 endpoints.</li>
                    <li>Expanded the class state by session block to include arrays of each.</li>
                    <li>
                        We fixed an issue where we were not properly calculating school grade levels during the sharing
                        rule block.
                    </li>
                    <li>Added support for LTI 1.3 assignments, submissions, and grades.</li>
                    <li>Fixed an issue with syncing improperly formatted OneRoster booleans.</li>
                    <li>You can now filter by <code>observer_id</code> or <code>target_id</code> on <code>/api/v2/graph/agents</code> requests.</li>
                    <li>Added more helpful error messages on a number of additional V1 endpoints.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 29th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added a User API endpoint to retrieve information about your integration.</li>
                    <li>
                        Fixed an issue where the Google submission endpoint was modifying the response body before
                        logging the outbound request.
                    </li>
                    <li>
                        Added support for navigating situations where there is more than one OneRoster district within a
                        single source.
                    </li>
                    <li>
                        Fixed an issue with syncing Schoology districts where (for some reason) a particular section
                        returns a 403 error.
                    </li>
                    <li>We are now returning more helpful error messages on V1 submissions endpoints.</li>
                    <li>
                        Added two new properties to the <code>class.properties</code> hash for Google Classroom classes (<code>section</code>
                        and <code>room</code>).
                    </li>
                    <li>Automatically trim Google Classroom category names.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 22nd, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Edlink will now return a <code>400</code> error when you send an invalid UUID to any V1 endpoints.</li>
                    <li>Edlink will return an empty array when we get no topics back from Google Classroom.</li>
                    <li>
                        Edlink will now throw an error when the correct submission type is not provided in Brightspace.
                    </li>
                    <li>
                        Improved the loading time of the integration overview page on the dashboard by improving an API
                        request.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 8th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed an issue with Schoology returning a grade 16 class.</li>
                    <li>Fixed an issue with instant links that pointed to paused integrations.</li>
                    <li>
                        Added a better warning message for when you try to update the attachments on a Google
                        assignment.
                    </li>
                </ul>
                <ul>
                    <li>Improved the UI to add linked sources.</li>
                    <li>Added a loading state to the drawer overlay.</li>
                    <li>Fixed some ugly UI on the Forgot Password page.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 1st, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed a Moodle login bug.</li>
                    <li>Fixed an issue with the Google source validation function.</li>
                    <li>
                        Added an option to handle LTI sourcedIds in a case-insensitive way (on a district-by-district
                        basis).
                    </li>
                    <li>We now capture the <code>identifier</code> field on OneRoster orgs and people.</li>
                    <ul>
                        <li>You can find it marked as <code>school_id</code> in the Edlink identifiers array.</li>
                    </ul>
                    <li>Fixed an issue with sharing rules when you select to share users by email address.</li>
                    <li>
                        Fixed an issue that would cause Google syncs to fail when there are duplicate guardian
                        relationships.
                    </li>
                    <li>Fixed an issue with LTI launching from the Schoology app center.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added a delete button to transformation cards.</li>
                    <li>Fixed a bug that was preventing users from changing the status of an integration.</li>
                    <li>Updated the SSO error landing page to be more informative.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 25th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added support for SSH keys on SFTP sources.</li>
                    <li>Added support for SFTPing ZIP files, instead of several individual CSV documents.</li>
                    <li>
                        Added support for districts who ship a new SFTP file each day, rather than replacing the old
                        one.
                    </li>
                    <li>Added support for the <code>race</code> property in Clever.</li>
                    <li>
                        Fix an issue where <code>create</code> overrides were not properly creating entities in the result dataset.
                    </li>
                    <li>
                        Fixed an issue where outbound request bodies were not being logged correctly for v2 requests.
                    </li>
                    <li>Released a bugfix to our Moodle plugin.</li>
                    <li>
                        Added a nicer error message for when you are sending an assignment to an unpublished Canvas
                        course.
                    </li>
                    <li>Fixed an issue with syncing agents (parents) from Google Classroom.</li>
                    <li>Ported our Canvas attachment shim backwards into API v1.</li>
                    <li>Added Adult Learning and Continued Learning grade levels to our grade level enum.</li>
                    <li>Fixed an issue with v2 Canvas submissions.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        We've fixed some issues with changing team member permissions and removing members from your
                        team.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 18th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Enabled custom transformations.</li>
                    <li>Fixed a meta API call that was used by the dashboard to list matches for a given entity.</li>
                    <li>
                        Added <code>school_id</code>, <code>course_id</code>, and <code>session_ids</code> as fields that can be overridden for a class.
                    </li>
                    <li>Enabled RapidIdentity as a top-level provider in Edlink.</li>
                    <li>
                        Fixed an issue that was resulting in an error when you specified a <code>$fields</code> query parameter and
                        the result was an empty array.
                    </li>
                    <li>Added an endpoint to list integrations by source.</li>
                    <li>
                        Fixed an issue with the <code>/v1/my/profile</code> call for users outside of the United States region.
                    </li>
                    <li>
                        Fixed an issue where a school's profile picture was not being returned in some v1 API calls.
                    </li>
                    <li>Added support for Moodle LTI 1.3 SSO.</li>
                    <li>
                        Fixed a Canvas attachment issue where the API request could fail if the description was null.
                    </li>
                    <li>
                        Enabled outbound request logging for v2 user endpoints (to match v1 support of this feature).
                    </li>
                    <li>
                        Fixed an issue where materializations would fail due to an override that would point to an
                        object that no longer exists.
                    </li>
                    <li>Added endpoints to retrieve staged changes for a sync (prior to the changes being flushed).</li>
                    <li>
                        Fixed an error that would cause an API request to hang on the <code>/api/v1/organizations/:id</code> call
                        when you sent an invalid organization ID.
                    </li>
                    <li>Improved the visual style of attachments added to Canvas assignments.</li>
                    <li>
                        Improved the error message users receive when they attempt to create a category in a Google
                        Classroom class that does not exist upstream.
                    </li>
                    <li>Added validation for CSV sources.</li>
                    <li>Fixed a sync error that was causing some Microsoft jobs to fail.</li>
                    <li>Fixed a couple of issues in the LTI 1.3 launch process.</li>
                    <li>Added support for Canvas submission extensions.</li>
                    <li>Corrected a time zone issue with some Schoology assignment due dates.</li>
                    <li>Improved the "assignment not found" error from Canvas.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>You can now write your own custom transformation functions.</li>
                    <li>Reenabled the tab that lists class enrollments.</li>
                    <li>Clicking on a class or person enrollment will now expand details about that enrollment.</li>
                    <li>You can now override school, session, class, and section values.</li>
                    <li>Fixed a bug that was preventing entity matches from correctly loading.</li>
                    <li>Removed a number of old files to reduce build size and page load times.</li>
                    <li>Added more details about a sync including information about staged changes.</li>
                    <li>Fixed the command palette. It was previously not loading due to a failing API call.</li>
                    <li>
                        Added a new tab to the source page that allows you to view all of the integrations for a given
                        source.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 11th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Restored proper error messages for invalid routes in API v2.</li>
                    <li>When searching through objects for sharing rules, search is now case-insensitive.</li>
                    <li>Teacher names are now expanded to be human-readable in the sharing rules UI.</li>
                    <li>Fixed a number of issues with our new sharing rule preview system (codenamed <code>Blink</code>).</li>
                    <li>
                        Added a number of endpoints to support the retrieval of matches coming from our data enrichment
                        engine.
                    </li>
                    <ul>
                        <li>You can now manually add or remove matches if they are incorrect.</li>
                    </ul>
                    <li>Fixed a bad database query for LTI 1.3 launching into our Toronto region.</li>
                    <li>Fixed an issue where we were accidentally rematerializing some paused syncs.</li>
                    <li>Fixed an issue with Microsoft assignment attachments in API v2.</li>
                    <li>Increased support for Clever admins and contacts.</li>
                    <li>
                        You can now make roster requests against a person in the v2 user API, even if that person has
                        invalid LMS tokens (e.g., because you impersonated them).
                    </li>
                    <ul>
                        <li>This brings v2 into line with v1.</li>
                    </ul>
                    <li>Provide additional details in the API response when you receive a 500 error from Edlink.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added support for adding multiple enrichment sources to a primary source.</li>
                    <ul>
                        <li>You can set the priority of these sources to influence how data is merged.</li>
                    </ul>
                    <li>Added UI to visualize data matches made by our Data Enrichment engine.</li>
                    <ul>
                        <li>You can manually delete matches that are incorrect.</li>
                        <li>
                            You can manually assign new matches if you know the corresponding entity in the secondary
                            source.
                        </li>
                        <li>Edlink will provide some details as to how it arrived at a particular match.</li>
                    </ul>
                    <li>
                        Added a visual progress meter to indicate the loading progress of your Sharing Rule previews.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 4th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Improved our Blackboard validation function to better understand if the source has been
                        configured correctly.
                    </li>
                    <li>Added support for agents (i.e. parental contacts) in Google Classroom.</li>
                    <li>Fixed our autofill defaults feature for Schoology in API v2.</li>
                    <li>
                        Added several new warning / error codes for situations that can arise around Microsoft
                        attachments.
                    </li>
                    <li>
                        Improved our logic that deals with Schoology rate limits to make faster requests (on average).
                    </li>
                    <li>Fixed a few issues related to assignment attachment support in Schoology and Canvas.</li>
                    <li>Properly parse OneRoster course subject codes during syncs.</li>
                    <li>Added a number of new identifiers across almost all supported systems.</li>
                    <ul>
                        <li>
                            You can find these identifiers (when they are available) in the <code>entity.identifiers</code> array.
                        </li>
                    </ul>
                    <li>You can now see your position in the queue when you're waiting to preview a sharing rule.</li>
                    <li>Added support for agents (e.g., parental relationships) in Schoology.</li>
                    <li>Changed the URL structure for our LTI 1.3 configuration JSON / JWK endpoints.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Sources connected to Flow will now show the destination source ID and name.</li>
                    <li>
                        Sources that are tied to a specific application (e.g., Clever, Classlink, and LTI sources) will
                        now show that application in the UI.
                    </li>
                    <li>
                        Sources that are tied to a specific application will no longer be selectable during the
                        administrator onboarding flow.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">June 20th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Released our new system for previewing sharing rules (codenamed <code>Blink</code>).</li>
                    <ul>
                        <li>This should offer a way faster and more accurate experience for creating sharing rules.</li>
                        <li>It takes into account transformations and overrides, which our old system did not.</li>
                    </ul>
                    <li>Improved OneRoster sync performance.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed a bug that caused a bunch of unneccesary network requests after you left the Sharing Rule
                        preview screen.
                    </li>
                    <li>Added team ID to the settings page for convenience.</li>
                    <li>Added Jobs and Flows tabs to Flow integration UI.</li>
                    <ul>
                        <li>You can now preview changes that Flow is going to make to the destination source.</li>
                        <li>You can monitor task progress by clicking on a given Job.</li>
                    </ul>
                    <li>Fixed a number of page numbering bugs in the integration data browser.</li>
                    <li>Fixed a number of bugs with the preview sharing rule UI.</li>
                    <li>Updated the LTI 1.3 launch link.</li>
                    <ul>
                        <li>
                            The old launch link will still work, but it is highly recommended that you use the new one
                            going forward.
                        </li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">June 13th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Released LTI 1.3 support for launches in Canvas, Blackboard, and Brighspace.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">June 6th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Improved error message when you send an invalid session_id while creating an assignment.</li>
                    <li>Added a warning message in v2 when the autofill features fills out a missing field for you.</li>
                    <li>Fixed a bug that was preventing standard API requests against paused integrations.</li>
                    <ul>
                        <li>Note, only <code>disabled</code> integrations should return an error.</li>
                    </ul>
                    <li>
                        Fixed an issue in Schoology & Canvas assignments where we were not properly handling
                        individualized assignments.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed a bug that was preventing administrators from updating their LMS tokens on the source
                        settings page.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 30th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added a set of mirror endpoints for the Clever v2.1 API.</li>
                    <li>Entity identifiers will now be returned in API v1.</li>
                    <ul>
                        <li>They were previously only returned in v2.</li>
                    </ul>
                    <li>Fixed an issue with Canvas assignment <code>points_possible</code> not updating correctly.</li>
                    <li>Fixed an issue with listing integrations when you have none to list.</li>
                    <li>Improved some sync code to map things to only <code>CEDS</code> grade levels.</li>
                    <li>Fixed our import of Clever student grade levels and class subjects.</li>
                    <li>
                        Fixed an API v1 issue preventing submission feedback from correctly submitting to Brightspace.
                    </li>
                    <li>Updated v2 Google draft submission grade behavior to match v1.</li>
                    <li>Added <code>gender_identity</code> to the list of valid person overrides.</li>
                    <li>Fixed a couple of issues related to grading LTI-based assignments in Canvas.</li>
                    <ul>
                        <li>
                            Canvas only allows LTI grading of LTI assignments so a special case needed to be
                            implemented.
                        </li>
                    </ul>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Added a bunch of transformation card templates to show more useful information on the source and
                        integration transformation tabs.
                    </li>
                    <li>Fixed a bug with nested properties on the entity details drawer.</li>
                    <li>Fixed a number of autocomplete issues with demographics overrides.</li>
                    <li>Added a new field that allows administrators to specify alternative login URLs.</li>
                    <ul>
                        <li>
                            This is primarily used in situations where teachers and students sign in through different
                            entrypoints.
                        </li>
                    </ul>
                    <li>Greatly improved the UI to create overrides.</li>
                    <ul>
                        <li>You can now insert arbitrary items into the dataset or update existing items.</li>
                        <li>Changes will not be overridden when data sources sync.</li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 23rd, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Removed our <code>properties.identifiers</code> backwards compatibility in lieu of the new <code>identifiers</code>
                        array.
                    </li>
                    <ul>
                        <li>Any affected clients were transitioned to the new structure.</li>
                    </ul>
                    <li>Person races array in v2 will now default to an empty array instead of <code>null</code>.</li>
                    <li>Fixed an issue that affected the $expand parameter's handling of array expansions.</li>
                    <li>
                        Fixed an issue with the login history method that was caused by a person being unshared or
                        deleted from an upstream source.
                    </li>
                    <li>Fixed an issue that prevented changes to the identifiers arrays from being staged.</li>
                    <li>
                        Updated Google Classroom integration to support multiple attachment objects on an assignment.
                    </li>
                    <li>We're now syncing various new demographics fields.</li>
                    <li>Added support for alternative login URLs for unusual Schoology setups.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added a new set of tabs on the logs page to toggle between geographic regions.</li>
                    <li>Added several new autocomplete boxes for the creation of person overrides.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 16th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Released broader 2FA support for Edlink dashboard users.</li>
                    <li>Newly created applications will automatically have an LTI 1.3 JWK created for them.</li>
                    <li>Application <code>anonymous_providers</code> is now an array of provider UUIDs and not a boolean.</li>
                    <ul>
                        <li>This will be more flexible as we expand support for anonymous providers.</li>
                    </ul>
                    <li>Introduced multiregion support for compliance with data sovereignty laws.</li>
                    <ul>
                        <li>With this, we released our first region, Toronto.</li>
                    </ul>
                    <li>Fixed an issue with Clever anonymous user logins.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Enabled two-factor authentication.</li>
                    <ul>
                        <li>You can set up 2FA for yourself by clicking your name in the bottom-left.</li>
                        <li>On the team page, you can quickly see which team members have 2FA enabled.</li>
                    </ul>
                    <li>Added a new password strength component.</li>
                    <li>You can now separately specify if you want to allow Clever, Google, (etc.) anonymous users.</li>
                    <ul>
                        <li>Formerly, it was a single setting to allow / deny anonymous users.</li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 9th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Clever sync will now include contacts.</li>
                    <li>Fixed an issue with Canvas assignment grading types in v2.</li>
                    <li>Added match introspection capabilities.</li>
                    <li>
                        Created an <code>/api/v2/my/source</code> endpoint for loading information about your own district and LMS.
                    </li>
                    <li>Fixed some issues with Canvas assignment states in API v2.</li>
                    <li>
                        Created a Clever compatibility block to make any data backwards compatible with what comes out
                        of Clever API v2.1.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Fixing issues with Chrome autocompleting certain key fields.</li>
                    <li>Added two new types of sharing rules (share by school and share by person).</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 2nd, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Improvements to our data enrichment matching algorithm.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">April 25th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added <code>starts with</code> and <code>ends with</code> to <code>course_code</code> sharing rule field.</li>
                    <li>Added support for Clever roster sync lite rostering.</li>
                    <ul>
                        <li>Previously, we only supported full roster syncing with Clever and Clever library.</li>
                    </ul>
                    <li>Added kebab case and phone number formatting transform blocks.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">April 18th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Significantly expanded our Clever identifier syncing.</li>
                    <li>Performance improvements for when you use the <code>$expand</code> parameter.</li>
                    <li>
                        Edlink will no longer share observer enrollments to classes if we are not sharing guardians with
                        your application.
                    </li>
                    <li>Released an intial version of integration overrides.</li>
                    <ul>
                        <li>
                            You can manually insert, update, and delete data from the Edlink dataset without it being
                            clobbered by future syncs.
                        </li>
                    </ul>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added the ability to override data in the integration data browser.</li>
                    <li>Fixed a bug that was preventing source settings from saving properly.</li>
                    <li>Added migrations tab.</li>
                    <ul>
                        <li>You can now have Edlink help map IDs from Clever or Classlink to a district's LMS.</li>
                        <li>
                            This makes it easy to migrate districts to an LMS integration without losing existing data.
                        </li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">April 11th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added <code>rural_residency</code> permission.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added integration transformations UI.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">April 4th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Added a privacy block that can conceal certain fields in the dataset if they are unused by the
                        developer.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">March 28th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Simplified the Edlink permission to give broader permissions around entities and more specific
                        permissions around user data.
                    </li>
                    <li>Expanded the number of available demographic fields.</li>
                    <li>Fixed an issue with Brightspace assignment submission types.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Changed the Clever & Classlink secret key fields to a password type.</li>
                    <li>Simplified the integration permissions screen.</li>
                    <li>Fixed an issue that was preventing users from selecting text on the request log screen.</li>
                    <li>
                        Updated some links to the documentation to point to API V2 by default now, following the release
                        of User API V2.
                    </li>
                    <li>Changed several UIs to autosave and removed their corresponding save buttons.</li>
                    <ul>
                        <li>There is now a "saving" indicator in the bottom right.</li>
                    </ul>
                    <li>Updated the integration overview page to handle Flows.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">March 21st, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Edlink will now properly update source states to reflect their respective Clever / Classlink
                        integration states.
                    </li>
                    <li>Fixed a couple of issues with the API endpoints to create transformations.</li>
                    <li>Released a number of improvements to the data enrichment algorithm.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Added outbound request visibility to see what requests Edlink is making to the upstream data
                        source.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">March 14th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Implemented the correct Microsoft submissions lifecycle in API v2.</li>
                    <li>Fixed a couple of issues related to Brightspace assignment submissions.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">March 7th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Made improvements to our OneRoster CSV sync.</li>
                    <ul>
                        <li>
                            These improvements are mostly dealing with the scale of massive districts (250k+ users).
                        </li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">February 28th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Switched the <code>PROVISIONED</code> state in Google Classroom to map to <code>inactive</code> in Edlink.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">February 14th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>V1 endpoints will now log outbound requests made from Edlink to the LMS.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 31st, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Changed the style of the "beta" alert banner on the V2 documentation.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 24th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Beta release of user API v2.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Updated the OIDC connection JSON URL for LTI 1.3 integrations.</li>
                    <li>Fixed the broken help link on the district admin onboarding flow.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 10th, 2022</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Beta release of new data enrichment engine (codenamed <code>Tango</code>).</li>
                    <li>Fixed a couple of LTI launch issues.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Fixed the broken help link on the district admin onboarding flow.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">December 27th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed a bug in assignee aliasing.</li>
                    <li>Added <code>/my/classes/:id/enrollments</code> endpoint.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">December 13th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Beta release of Graph API v2.</li>
                    <li>Fixed an issue that was causing Blackboard syncs to fail after an hour.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Fixed a few broken documentation links caused by a URL restructuring a few months back.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">December 6rd, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed a few Classlink syncs that were not comforming to the OneRoster specification.</li>
                    <li>Corrected some empty string situations in Classlink syncs.</li>
                    <li>Fixed an issue with Brightspace user roles.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">November 29th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Set Brightspace default points possible.</li>
                    <li>Properly parse person roles in <code>/v1/my/profile</code>.</li>
                    <ul>
                        <li>
                            This isn't strictly a feature in our documentation, but we wanted it to be available
                            nonetheless.
                        </li>
                    </ul>
                    <li>Fixed some missing agent roles in the Bromcom SIS sync.</li>
                    <li>Reenabled Edlink SFTP provider.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed the button to delete an integration (it was previously erroring if you clicked it as a
                        developer).
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">November 22nd, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added a command palette available with <code>Ctrl+K</code> or <code>CMD+K</code>.</li>
                    <li>Fixed a couple of broken links on the account settings, login, and register pages.</li>
                    <li>Added the guide documentation panel.</li>
                    <li>Added a guide button to every page that needed one.</li>
                    <li>Fixed the source validation error message issue.</li>
                    <li>Quality of life fixes.</li>
                    <ul>
                        <li>Made a few things click-to-copy that were previously annoying to highlight.</li>
                        <li>Integration status is now available and changeable from the header.</li>
                        <li>Added information about the enrichment status of sources / integrations.</li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">October 25th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Minor change to Illuminate Education to add addtional standards info.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">October 18th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Assign a default grading period and category to Schoology assignments.</li>
                    <li>Send a 200 instead of a 204 for delete assignment endpoint.</li>
                    <li>Fixed an Illuminate Education issue (race condition with token refresh).</li>
                    <li>Expanded our demographics models.</li>
                    <li>Send a 200 instead of a 204 for delete submission endpoint.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">October 11th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added Bromcom roster sync support.</li>
                    <li>Updated a few OneRoster role mappings.</li>
                    <li>Sync classes and enrollments for Clever Library users.</li>
                    <li>Google assignment submission can now contain a URL.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">October 4th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>OneRoster courses will now properly sync subjects.</li>
                    <li>Added HelloID as a top-level SSO provider.</li>
                    <li>Fixed OneRoster subject codes when they don't match CEDS standard.</li>
                    <li>Greatly improved Canvas sync speed.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 27th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed Clever sync validation.</li>
                    <li>Fixed an issue with Moodle login.</li>
                    <li>Added support for Clever identifiers and grade levels.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 20th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added a few endpoints to handle sharing rule previews.</li>
                    <li>Added GraphQL pagination for Canvas syncs.</li>
                    <li>We are now tracking the number of sync attempts in the event that a job fails.</li>
                    <li>Implemented a cleaner solution for validating Schoology sources with custom URLs.</li>
                    <li>Patched a v1 submissions list issue in Canvas.</li>
                    <li>Return enrollment state wherever we retrieve enrollments.</li>
                    <li>Add source validation for some additional sources.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 13th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed enrollments paging issue.</li>
                    <li>Fixed an issue with fetching single Schoology submissions.</li>
                    <li>The <code>state</code> parameter is no longer a required parameter for Edlink SSO.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        The SSO login page will now handle edge cases better (e.g., when no redirect_uri is specified by
                        the developer).
                    </li>
                    <li>Fixed a bug in the sharing rules creation UI.</li>
                    <li>Added some additional sharing rules options (share by course or course code).</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 6th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Also retrieve the support website URL when you load the public details for an application.</li>
                    <li>Fixed request filtering on v2.</li>
                    <li>Fixed an issue with Microsoft assignments and added a few sharing rules helper methods.</li>
                    <li>Add structured response to pagination decorator.</li>
                    <li>Added more Open API documentation.</li>
                    <li>Allow creating assignments for a particular section in API v1.</li>
                    <li>
                        Bugfixes for creating and updating assignments assigned to a specific Schoology section's
                        students.
                    </li>
                    <li>Allow updating assignments to be assigned to a particular section in API v1.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 30th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Better logging on failed logins.</li>
                    <li>Submissions bug fix.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 23th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added more UUID validation for better error messages.</li>
                    <li>Fixed an issue with the v1 events API endpoint.</li>
                    <li>Added an email & password sign in method.</li>
                    <li>Fixed a couple of issues with assignments.</li>
                    <li>Fixed an issue with organization expansion.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 16th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed a couple of rules endpoints.</li>
                    <li>Filter people by role.</li>
                    <li>List child syncs method for new partial sync strategy.</li>
                    <li>Adding endpoints for Google launches.</li>
                    <li>Fixed the expand sections issue.</li>
                    <li>Fixed a schoology personalized assignment issue.</li>
                    <li>Added organization ancestry back into the events call.</li>
                    <li>Fixed the API endpoint to update a personalized assignment.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Fixed a number of small issues and began rework of the sharing rules UI.</li>
                    <li>
                        Fixed a bug with enrolllments not appearing in the details pane of the integration data browser.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 9th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed middleware to fetch a graph organization without a specified type.</li>
                    <li>Fixed an issue with creating new schoology assignments.</li>
                    <li>Handling Google anonymous users login requests.</li>
                    <li>Enrichment SSO changes.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added class state as a property available to use in sharing rules.</li>
                    <li>Fixed a few bugs in the creation of sharing rules.</li>
                    <li>Slightly changed the visual styles of the administrator onboarding flow.</li>
                    <li>
                        Removed the integration ID from the Integrations page (you can now find it by clicking on the
                        integration itself).
                    </li>
                    <li>Updated the applications page to the new visual styles.</li>
                    <li>Moved the documentation to a new Github repo.</li>
                    <li>Account settings is now a modal instead of its own page.</li>
                    <li>
                        After going through the administrator onboarding flow, you can now have administrators be
                        redirected to a URL of your choosing.
                    </li>
                    <li>
                        Fixed an issue with enrollments not appearing in the details pane of the integration data
                        browser.
                    </li>
                    <li>Fixed a few bugs with the logged request viewer.</li>
                    <li>Renamed <code>Imports</code> to <code>Syncs</code>.</li>
                    <li>Added page title to the login screen.</li>
                    <li>Added some customization options to the team settings page (e.g., setting a primary color).</li>
                    <li>There is now a bolt icon that appears next to enriched sources.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 2nd, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed an issue with loading school child courses.</li>
                    <li>Few bugfixes in rule creation.</li>
                    <li>Fixed a few authentication errors.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 26th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue with a few of the Graph API calls where searching for users in a school with a
                        specific role was not functioning correctly.
                    </li>
                    <li>
                        Fixed an issue where a person's birthday was also being returned in their <code>updated_date</code>
                        property.
                    </li>
                    <li>Fixed a paging issue when loading district administrators from the Graph API.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 19th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Initial release of 2.0.0.</li>
                    <ul>
                        <li>We **do not** have plans to deprecate v1 at this time.</li>
                        <li>We will continue to make improvements and fix issues in the v1 API.</li>
                    </ul>
                    <li>Lexical updates:</li>
                    <ul>
                        <li>
                            Organizations have been split into several new types: <code>Districts</code>, <code>Schools</code>, <code>Classes</code>, and
                            <code>Sections</code>.
                        </li>
                        <li>
                            v1.0 <code>Courses</code> have been renamed to <code>Classes</code> to fall more in line with industry
                            expectations.
                        </li>
                        <li>
                            We have added a brand new type <code>Courses</code> to represent the concept of a course "template".
                        </li>
                        <li>
                            Courses are designed to capture the concept of "Algebra 1", rather than a specific
                            instantiation of a <code>class</code>.
                        </li>
                        <li>
                            Classes differ as they are comprised of teachers and students in a room (i.e. at a specific
                            time).
                        </li>
                        <li>Classes may reference a course.</li>
                        <li>
                            We have added the type <code>agents</code> to describe the parent/guardian to student relationship.
                        </li>
                        <li>
                            We have renamed v1.0 <code>Terms</code> to v2.0 <code>Sessions</code> in order to represent a wider array of
                            time-bound objects.
                        </li>
                        <li>Enrollments are now only between a Person and a corresponding Class.</li>
                        <li>
                            They may optionally include a reference to a <code>section</code> which is a group of students within a
                            class.
                        </li>
                        <li>All sources will now have a single <code>district</code> object and one or more <code>school</code> objects.</li>
                        <li>
                            This was not necessarily the case in v1.0 where this was determined by the LMS that the
                            district used.
                        </li>
                        <li>A number of name changes were made to improve consistency across the API.</li>
                    </ul>
                    <li>
                        You will now find a reference to the district and zero or more schools on each person object.
                    </li>
                    <ul>
                        <li>
                            This replaced the old style of having enrollments connect users to their school or district.
                        </li>
                    </ul>
                    <li>
                        We have introduced the global role of <code>district-administrator</code> as it was a source of confusion
                        to represent all administrators as simply <code>administrator</code>.
                    </li>
                    <li>People may now have more than one global <code>role</code>.</li>
                    <li>We have improved the way we handle our event deltas to make them more clear for developers.</li>
                    <li>Additional notes:</li>
                    <ul>
                        <li>
                            Authentication and authorization have not changed. You can use the same tokens to call v1
                            and v2 endpoints.
                        </li>
                        <li>
                            At the end of the day, you should receive the same information from v1 as v2, just in a
                            considerably different format.
                        </li>
                        <li>New features like agents or courses will not be available through the v1 API.</li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 12th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Internal changes.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">April 8th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added a new SSO route for launching when you have a previously saved session cookie.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">February 22nd, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue where Edlink was trying to send an email to no contacts after a new integration
                        was created.
                    </li>
                    <li>
                        Reduced the amount of information exposed publicly about certain applications and integrations
                        to only what was 100% necessary.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 25th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Integration analytics endpoint is now significantly faster and contains details about terms and
                        enrollments.
                    </li>
                    <li>Internal improvements for sharing rules bugfixes.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>A number of sharing rules UI updates.</li>
                    <li>There were several bugs fixed with the sharing rules autocomplete field.</li>
                    <li>
                        The integration data pages (e.g., people, courses, etc.) will now show a full count of the
                        number of available items.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 11th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>SAML added as a login method for OneRoster sources.</li>
                    <li>Fixed a bug that was preventing courses from being previewed.</li>
                    <li>Some User Data API endpoints were not properly observing sharing rules.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 4th, 2021</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Data can now be shared via rules.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>There is now a <code>Sharing</code> tab added to the integration settings screen.</li>
                    <li>You can define rules by which data will be shared with the developer.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">December 14th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Edlink will now pre-handle a several common Microsoft Teams issues.</li>
                    <ul>
                        <li>
                            When you try to create an assignment with a title greater than 70 characters, Edlink will
                            throw a <code>400</code> error.
                        </li>
                        <li>
                            When you try to update an assignment and set a title greater than 70 characters, Edlink will
                            throw a <code>400</code> error.
                        </li>
                        <li>
                            When you try to create an assignment without a due date, Edlink will throw a <code>400</code> error.
                        </li>
                    </ul>
                    <li>
                        A bug has been fixed that was causing an inaccurate <code>organization.ancestry</code> array to be returned
                        by the <code>/api/v1/my/courses</code> endpoint (and similar endpoints).
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">October 26th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed an issue where expired access tokens were not being properly refreshed before making a
                        GraphQL request.
                    </li>
                    <li>You can now expand the details about course sections in the <code>/v1/my/courses</code> call.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        There is a new integration overview page containing relevant details about the given
                        integration.
                    </li>
                    <li>
                        This page also includes LTI keys so that LTI launches can be configured in the district's LMS.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">October 5th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Made improvements to the number of fields upon which you can filter your integration data.</li>
                    <li>The request method in request logs will now display the correct value.</li>
                    <li>There is now a back button to return to your list of integrations.</li>
                    <li>We have added an integration overview page to the dashboard.</li>
                    <ul>
                        <li>This page contains details about the integration as well as the source data provider.</li>
                        <li>
                            You may also find the access token and LTI configuration information for this integration,
                            but please be aware that these are extremely sensitive.
                        </li>
                    </ul>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 21st, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        You can now launch into an integration with your default application parameters already set.
                    </li>
                    <ul>
                        <li>This is primarily targeted at use cases such as Microsoft MyApps tiles.</li>
                        <li>While it is not true "SSO", it can streamline the process for teachers and students.</li>
                    </ul>
                    <li>The Edlink Dashboard will no longer display teams that are marked as <code>inactive</code>.</li>
                    <li>
                        The sync date will now be returned when you fetch or list enrollments through the Graph API.
                    </li>
                    <li>
                        The entity and team IDs will now be properly removed when you fetch details about a single
                        integration by ID.
                    </li>
                    <li>You are now able to filter Graph API data by a number of different parameters.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>You can now resize the "Valid Redirect URIs" field.</li>
                    <li>The Edlink Dashboard will no longer display teams that are marked as <code>inactive</code>.</li>
                    <li>Disabled sources will now have an icon indicating their status in the integration flow.</li>
                    <li>Teams will now be sorted in alphabetical order on the teams menu.</li>
                    <li>Sources will now be sorted in alphabetical order on the "Manage Sources" page.</li>
                    <li>We have added a new page in order to browse your integrations.</li>
                    <li>
                        We have added a basic read-only data browser for you to have better visibility into the data
                        that comes from our API.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 14th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Listing student submissions from Schoology will now return the entire class, rather than just
                        students who have submitted the item.
                    </li>
                    <ul>
                        <li>
                            Students who have not submitted the item will be returned, but most properties will be
                            <code>null</code>.
                        </li>
                        <li>
                            This change should bring Schoology more into line with the behavior of other learning
                            management systems.
                        </li>
                    </ul>
                    <li>
                        The maximum page size on Graph API calls has been increased to 10,000. It was previously 1,000.
                    </li>
                    <li>Dramatic performance and scalability improvements for the impending back-to-school tsunami.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">September 7th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        You will no longer receive student submissions for those students who are "invalid" within our
                        system (i.e. due to a faulty data sync).
                    </li>
                    <li>
                        We will now return a <code>404</code> error if you attempt to retrieve a submission from an "invalid"
                        student.
                    </li>
                    <li>Fixed assignment timezone issues with both Google Classroom and Schoology.</li>
                    <li>Fixed an assignment submission bug in Google Classroom.</li>
                    <li>
                        Simplified the Google Classroom display date logic. Google Classroom assignments are now
                        automatically marked as "unpublished" when a display date is provided, because Classroom will
                        default to a <code>PUBLISHED</code> state otherwise (ignoring display date altogether).
                    </li>
                    <li>
                        Made dramatic improvements to performance on calls to retrieve student enrollments through the
                        User Data API.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 24th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Brightspace assignments will now properly create a gradebook column.</li>
                    <li>Brightspace feedback will now be properly posted to the aforementioned gradebook column.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed a bug that caused imports to double-load if you navigated away from and then back to the
                        imports page.
                    </li>
                    <li>Fixed a bug that caused some error messages to appear after switching teams.</li>
                    <li>
                        We are no longer requiring administrators to create a team <code>alias</code> (nickname) when they are
                        going through the integration onboarding flow.
                    </li>
                    <li>The list of integrated schools will now be sorted alphabetically by district name.</li>
                    <li>
                        We will no longer show "Clever" or "Classlink" in the integration flow as these will have to be
                        configured externally.
                    </li>
                    <li>
                        The integration flow will now filter the dropdown list of acceptable sources based on the
                        developers preferences. Previously, only the landing page was filtering properly.
                    </li>
                    <li>The integrate account screen will no longer say "null null" if you have not set your name.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 17th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Added a new <code>Logs</code> page for developers to inspect detailed information about all requests that
                        flow through our system.
                    </li>
                    <li>
                        Integrations will now have the district's <code>Entity ID</code> displayed below as well as the provider
                        icon to the left.
                    </li>
                    <li>
                        We've simplified the school admin onboarding process further and added additional help videos to
                        make the experience clearer.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 10th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Clever integrations will no longer try to fetch data from scopes that are unauthorized.</li>
                    <li>All LMS requests will now retry up to 5 times upon <code>5XX</code> failure.</li>
                    <li>Classlink and OneRoster sources will no longer provide <code>inactive</code> items in API results.</li>
                    <li>
                        Listing enrollments by organization via the Graph API will now return the correct results given
                        enrollments in sub-organizations.
                    </li>
                    <li><code>person.properties</code> is now sent with the enrollment call described above.</li>
                    <li>We are now properly saving the Clever user role.</li>
                    <li>We removed the <code>district</code> object from Clever imports.</li>
                    <ul>
                        <li>
                            District admins will now receive individual <code>administrator</code> enrollments to all of the
                            component schools.
                        </li>
                    </ul>
                    <li>People will now have an enrollment object that associates them with their Classlink school.</li>
                    <li>
                        Google Classroom assignments will now properly default to <code>description_plaintext</code> instead of the
                        full HTML <code>description</code> property.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">August 3rd, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        The API will no longer import the school district from Clever as this was the only data source
                        for which district information is readily available.
                    </li>
                    <li>
                        All sources (even external sources automatically created from Clever or Classlink) will now be
                        tied to a team.
                    </li>
                    <ul>
                        <li>External sources will have a team automatically generated for them.</li>
                        <li>
                            External sources may be moved over to the school district's "real" team upon request by an
                            Edlink administrator.
                        </li>
                        <li>Any existing external sources have had a team created for them.</li>
                    </ul>
                    <li>
                        Schoology API maximum page sizes changed and as a result, some of our imports had triggered
                        sources to become disabled.
                    </li>
                    <li>Clever sources will now be disabled when they are disabled in Clever.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 27th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Organizations of type <code>template</code> will now be returned by default when you call <code>/my/courses</code>.
                    </li>
                    <li>
                        Blackboard organization states are now decided more intelligently, instead of just relying on
                        Blackboard.
                    </li>
                    <li>
                        Technical contacts for the school and developer will now receive a notification email when a new
                        integration is created.
                    </li>
                    <li><code>Events</code> will no longer be generated for integrations that are paused.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 20th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Made significant performance improvements to the login page (in particular the routing process
                        whereby users are routed to their school's LMS to authenticate).
                    </li>
                    <li>Source configuration forms will now have improved help experiences.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 13th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Made significant performance improvements to API call to list accounts by application & email
                        address (for the SSO routing process).
                    </li>
                    <li>The Brightspace sync process will assign more accurate user roles at the system level.</li>
                    <li>
                        User access tokens are now invalidated (paused) when an integration is disabled for any reason.
                        They are reactivated if an integration is reenabled.
                    </li>
                    <li>
                        Graph access tokens are now invalidated (paused) when an integration is disabled for any reason.
                        They are reactivated if an integration is reenabled.
                    </li>
                    <li>Several new email notifications are now available:</li>
                    <ul>
                        <li>Developers will receive an email when an integration has been requested.</li>
                        <li>Schools will receive an email when their integration has been approved.</li>
                        <li>Schools and developers will receive an email when a scheduled integration goes live.</li>
                    </ul>
                    <li>
                        Classes synced from OneRoster will now include the <code>identifier</code> property in the <code>properties</code>
                        object, if it is available.
                    </li>
                    <li>
                        <code>Provider</code> objects will now contain a <code>help</code> object that may contain a series of configuration
                        help videos.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Documentation updates.</li>
                    <li>
                        Starting to integration help videos and additional documentation into the source configuration
                        process.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">July 6th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        <code>application/x-www-form-urlencoded</code> requests will now be accepted for all requests in addition
                        to the standard JSON body type.
                    </li>
                    <li>Several new properties have been added to the <code>/my/profile</code> user data API request.</li>
                    <li>Fixed a bug that caused Microsoft submission scores to be returned in an incorrect format.</li>
                    <li>Improved permissions handling for Graph API endpoints.</li>
                    <li>
                        Two new endpoints have been added to allow you to retrieve terms (grading periods) through the
                        User Data API.
                    </li>
                    <li>
                        Blackboard, Canvas, Schoology, and Brightspace custom URLs are now better able to handle
                        trailing slashes or improper URL formats.
                    </li>
                    <li>General improvements to OneRoster sync.</li>
                    <ul>
                        <li>
                            The OneRoster organization identifier is now available as
                            <code>organization.properties.identifier</code>.
                        </li>
                    </ul>
                    <li>
                        The Canvas sync process will now handle some rather unusual edge cases with regard to
                        enrollments.
                    </li>
                    <ul>
                        <li>There can now be multiple enrollments between a user and an organization.</li>
                        <li>
                            There can be multiple enrollments *of the same enrollment type* between a user and an
                            organization (yes, for some reason this happens in practice).
                        </li>
                    </ul>
                    <li>
                        Although our database will now sync invalid items (e.g., an <code>enrollment</code> with no corresponding
                        <code>person</code>), these items will not be returned through our API.
                    </li>
                    <li>Google Classroom will now sync archived and provisioned courses.</li>
                    <li>
                        A new endpoint was added to retrieve basic analytics about an integration. You can retrieve the
                        number of schools, courses, and people shared by the school district.
                    </li>
                    <li>
                        Fixed a bug with the Microsoft Teams sync where teachers would also be enrolled as students in
                        Edlink.
                    </li>
                    <li>
                        The endpoint to list your application's integrations now follows standard pagination rules and
                        allows for the <code>$first</code>, <code>$after</code>, <code>$last</code>, and <code>$before</code> parameters.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Documentation fixes.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">June 29th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>All cookies are now set to <code>SameSite=true</code> and <code>Secure</code>.</li>
                    <li>
                        The <code>/my/profile</code> call will now optionally return expanded data for the person's <code>Source</code>,
                        <code>Provider</code>, <code>District</code>, and <code>Integration</code>.
                    </li>
                    <li>Microsoft users will now be imported with the correct system-level role.</li>
                    <li>
                        Fixed a bug in Microsoft submission grading that would have prevented the API from submitting a
                        grade of "0".
                    </li>
                    <li>All API responses will now contain the HTTP Strict Transport Security (HSTS) header.</li>
                    <ul>
                        <li>
                            You were not previously able to make non-HTTPs requests, but this header was not present.
                        </li>
                    </ul>
                    <li>People may now contain a properties object which may contain LMS-specific properties.</li>
                    <li>
                        Organizations may now contain a properties object which may contain LMS-specific properties.
                    </li>
                    <li>Major improvements to the LMS sync process.</li>
                    <ul>
                        <li>Targeted updates can be made on-the-fly for more timely data syncing.</li>
                    </ul>
                    <li>Major internal changes have been made to the Events API.</li>
                    <ul>
                        <li>Externally, the API is very slightly different.</li>
                    </ul>
                    <li>
                        Schoology assignment dates (e.g., due date, display date) will now correctly adjust for the time
                        zone.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">June 1st, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed a bug that was preventing the proper import of data from Brightspace. Certain
                        organizations would not have their ancestry properly set, which would cause a number of errors
                        later down the road (e.g., they would not appear when you asked to list a user's courses).
                    </li>
                    <li>
                        Fixed a bug with listing or fetching assignments from Brightspace. Items with no availability
                        data were not being handled properly.
                    </li>
                    <li>Properly processing Brightspace submissions with multiple attempts.</li>
                    <li>Force submissions method implemented for Schoology.</li>
                    <li>Fixed a bug that prevented initial login webhooks from firing properly.</li>
                    <li>
                        Fixed a bug that was causing Schoology assignments to fail to create if they did not include a
                        due date.
                    </li>
                    <li>Improvements to Brightspace assignments, submissions, and grades.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 25th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Added webhook functionality.</li>
                    <ul>
                        <li>Webhooks can be configured on your application page.</li>
                        <li>
                            Webhooks will be attempted up to 10 times (with exponential time decay) in the event of
                            failure.
                        </li>
                    </ul>
                    <li>Added first two webhook events.</li>
                    <ul>
                        <li>A webhook can be fired whenever a user signs into your application.</li>
                        <li>
                            A webhook can be fired whenever a user has signed into your application for the first time.
                        </li>
                    </ul>
                    <li>Added a new endpoint to retrieve details about a user's data source.</li>
                    <ul>
                        <li>
                            The endpoint can be found at <code>/v1/my/source</code> and contains details about the source, as well
                            as the provider application.
                        </li>
                    </ul>
                    <li>Fixed a bug where whitespace was improperly handled within users' names in Canvas.</li>
                    <li>Added a new endpoint to retrieve details about a single integration.</li>
                    <li>Switched to updated entities table which includes far more detailed information.</li>
                    <ul>
                        <li>All entities will now include their full address and NCES IDs.</li>
                    </ul>
                    <li>
                        Added a new endpoint to the Graph API to list the schools that are ancestors to the requested
                        organization.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 11th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed several bugs related to Blackboard submissions and grades.</li>
                    <ul>
                        <li>Assignments are now properly deleted from Blackboard.</li>
                    </ul>
                    <li>
                        Fixed a bug where Blackboard refresh tokens were improperly saved, as apparently they can
                        change.
                    </li>
                    <li>
                        The Blackboard refresh process now happens without a time buffer because Blackboard will not
                        issue new access tokens until the very moment that the old one is expired (they have no issue
                        changing the refresh token every time though, the *exact opposite* of what is supposed to
                        happen) ¯\\\_(ツ)\_/¯.
                    </li>
                    <li>Improved the Moodle import with the help of the new Moodle plugin.</li>
                    <li>
                        Fixed a login bug with anonymous Clever users who initiate the login process from the
                        developer's application (as opposed to Clever Library).
                    </li>
                    <li>
                        Made improvements to the Moodle Sync and released a new version of the Edlink Moodle plugin.
                    </li>
                    <li>
                        Launch context is now available as a part of the OAuth 2.0 response when launching from an LTI
                        application.
                    </li>
                    <ul>
                        <li>
                            Launch context includes the organization (e.g., course) in which the LTI application was
                            opened.
                        </li>
                        <li>
                            It also includes one or more enrollment objects that relate the user to the organization.
                        </li>
                        <li>
                            Users are prevented from launching within contexts to which you (the developer) were not
                            granted access by the school admin.
                        </li>
                    </ul>
                    <li>
                        A bug with Schoology LTI launch was fixed. Schoology LTI user IDs are different from their
                        standard user IDs.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Documentation updates.</li>
                    <li>
                        Removed the <code>Data Sync Interval</code> field from the source creation and settings pages. It may be
                        added back in the future as an advanced option, but only served as a source of confusion for
                        administrators.
                    </li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">May 4th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Changes were made to the organizations data model.</li>
                    <ul>
                        <li>
                            <code>street_address</code>, <code>unit_number</code>, <code>city</code>, <code>state</code>, <code>zip</code>, <code>website</code>, and <code>banner_url</code> have
                            been removed.
                        </li>
                        <li>
                            Address information (when available) will now be contained exclusively on the organization's
                            <code>Entity</code> object.
                        </li>
                        <li>
                            Organizations now have a new property called <code>state</code> which indicates the organization's
                            lifecycle state.
                        </li>
                        <li>
                            At present, valid organization states are: <code>template</code>, <code>upcoming</code>, <code>inactive</code>, <code>active</code>,
                            <code>completed</code>, and <code>archived</code>.
                        </li>
                    </ul>
                    <li>
                        Performance improvements were made to the Canvas sync. Integrations should now be up to 10 times
                        faster.
                    </li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed a bug with creating a new source via the integrate page when you are a part of multiple
                        teams.
                    </li>
                    <li>
                        Updated the SSO login flow to include a more informative error page when your account is not
                        found.
                    </li>
                    <li>Fixed several bugs in the integration flow.</li>
                    <li>Added new team settings.</li>
                    <ul>
                        <li>
                            You can now specify one or more technical email addresses to receive important
                            notifications.
                        </li>
                        <li>
                            You can now specify one or more support email addresses which will be displayed on error
                            pages.
                        </li>
                        <li>You can now specify a website and support website URL for users.</li>
                    </ul>
                    <li>Added some basic statistics on the source overview page.</li>
                    <li>The email address search is now case insensitive.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">April 13th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Dramatically simplified the permissions structure for both schools and developers.</li>
                    <ul>
                        <li>
                            The create, update, and delete permissions have been merged for each resource type into a
                            single <code>write</code> type.
                        </li>
                        <li>Read-only permissions remain the same across the board.</li>
                        <li>
                            Scoped and unscoped permissions have been merged for clarity (and because they were largely
                            redundant).
                        </li>
                    </ul>
                    <li>Added <code>StudentViewEnrollment</code> type handler for Canvas.</li>
                    <li>
                        Adjusted the internal expiration dates on source access tokens to provide a longer buffer.
                        Access tokens are now refreshed earlier than their expiration date.
                    </li>
                    <li>
                        Additional endpoints were added to fetch various enrollment types for individual users
                        requesting information about their organizations.
                    </li>
                    <ul>
                        <li>
                            The endpoint has been generalized to
                            <code>/:organization_type/:organization_id/:enrollment_type</code>.
                        </li>
                    </ul>
                    <li>
                        Updated the Canvas sync for performance improvements and to filter out unnecessary courses and
                        enrollments.
                    </li>
                    <li>Added a mechanism to activate scheduled integrations.</li>
                    <li>
                        Both developers and school admins will now receive an email confirmation when an integration is
                        created.
                    </li>
                    <ul>
                        <li>Parties will receive a second email when scheduled integrations are activated.</li>
                    </ul>
                    <li>
                        Some internal improvements were made with regard to the expiry of events and requests, as per
                        our data retention policy.
                    </li>
                    <li>Added an additional method to fetch Canvas submissions by ID.</li>
                    <li>Added support for teacher submission feedback for Canvas.</li>
                    <li>Added support for teacher submission feedback for Schoology.</li>
                    <li>Better support for Microsoft Teams assignment submissions / grades.</li>
                    <li>Better support for Google Classroom assignment submissions / grades.</li>
                    <li>
                        Google Classroom now requests an additional permission when teachers and students sign on for
                        the first time.
                    </li>
                    <li>Made improvements to the automatic entity matching system.</li>
                    <li>Expanded entities to provide array types for standard ID numbers.</li>
                    <ul>
                        <li>Some systems have duplicate entries for schools.</li>
                        <li>Sometimes schools are combined or merged and we wish to preserve both numbers.</li>
                    </ul>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        New flow added for SSO logins. Users can be directed straight to Clever sign in by sending them
                        to <code>/sso/login/clever</code>.
                    </li>
                    <li>Permissions have been simplified.</li>
                    <li>Permissions will now be sorted by type (alphabetically).</li>
                    <ul>
                        <li>
                            This change applies on both the integration page, as well as the application configuration
                            page.
                        </li>
                    </ul>
                    <li>Administrative entity and organization management.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">March 30th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Google Classroom assignments that have been deleted will now return a 404 error if they are
                        accessed through our API.
                    </li>
                    <ul>
                        <li>
                            Google Classroom typically only performs a "soft delete" and still allows items to be
                            accessed.
                        </li>
                        <li>
                            This behavior is unique to their platform, so we will emulate a "hard delete" for API
                            consistency.
                        </li>
                    </ul>
                    <li>Improvements to API consistency with Schoology and Canvas assignment submissions.</li>
                    <li>Stable organization entities are now returned with teams and organizations.</li>
                    <li>Changed import queueing strategies for improved reliability and scalability.</li>
                    <li>There is now a method to cancel queued imports before they begin.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>You can now properly scroll on the SSO Login page when you have too many existing accounts.</li>
                    <li>There is now an SSO flow for users who do not use an email address.</li>
                    <li>Sitemaps were added for the main site and documentation.</li>
                    <li>There is now a button to cancel queued imports before they begin.</li>
                    <li>Added mobile support for the home page, support page, and documentation.</li>
                    <li>Fixed a couple of dead links in the documentation.</li>
                    <li>The documentation menu will now open to the active section upon page load.</li>
                    <li>Updated sitemaps to reflect changes to the documentation.</li>
                    <li>The SSO login screen will now show the loading icon when waiting for the LMS flow to begin.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">March 23rd, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Backward paging is now implemented for the Graph API.</li>
                    <li>Integrations now have a status and can be scheduled to start and end at a specific date.</li>
                    <ul>
                        <li>
                            Integrations will now have the property <code>integration_status</code> which is set to either
                            <code>inactive</code>, <code>active</code>, <code>error</code>, or <code>disabled</code>.
                        </li>
                        <li>
                            Integrations will now have the property <code>integration_start_date</code> which is an ISO-8601
                            timestamp.
                        </li>
                        <li>
                            Integrations will now have the optional property <code>integration_end_date</code> which is an ISO-8601
                            timestamp.
                        </li>
                        <li>Attempts to make API calls to inactive sources will result in a <code>403 Forbidden</code> error.</li>
                    </ul>
                    <li>
                        Integrations now have a configuration object of <code>integration_configuration</code>. This is unused at
                        present, but in the future may store specific configuration data for your application.
                    </li>
                    <li>Canvas email address support has been improved.</li>
                    <li>
                        Calls to retrieved other organizations for an authenticated user have been added (e.g.,
                        schools).
                    </li>
                    <li>
                        All calls to retrieve information about a user's organizations will now include an enrollments
                        array.
                    </li>
                    <ul>
                        <li>
                            For example, users may have more than one enrollment to a course as (in some LMS systems)
                            they can be a teacher in one section and a student in another.
                        </li>
                    </ul>
                    <li>
                        Calls to organizations endpoints will now include information about the real world entity that
                        relates to the object (if available).
                    </li>
                    <ul>
                        <li>
                            For example, schools in Canvas or Schoology generally correspond to real-world locations.
                        </li>
                        <li>
                            Entity IDs are stable and can be relied upon for managing school, district, or university
                            objects.
                        </li>
                        <li>
                            They provide a great way to handle schools who are transitioning between two different LMS
                            systems.
                        </li>
                    </ul>
                    <li>Internal endpoints were added to list, fetch, and manage entities.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Added several pages to browse the data that is imported from a source.</li>
                    <li>Fixed an issue with the create team modal.</li>
                    <li>Source imports will now be listed in reverse chronological order.</li>
                    <li>Internal pages were added for the management of entities.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">February 24th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>The API call to retrieve a team was not returning data in the proper format.</li>
                    <li>Fixed several endpoints that were not returning the $data property correctly.</li>
                    <li>
                        SSO is now largely decoupled from the Dashboard and is self-contained within the API
                        application.
                    </li>
                    <li>
                        The <code>edlink-api-key</code> header is no longer required if you are making calls using a user or
                        integration access token.
                    </li>
                    <li>Classlink and Clever are now able to perform full roster sync.</li>
                    <li>Clever Instant Login is now available as an authentication method.</li>
                    <li>OneRoster is now available as a data source provider.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed an issue where you would be directed to onboarding after logging in (even if you were
                        already onboarded).
                    </li>
                    <li>Fixed an issue with accepting invitations during the onboarding flow.</li>
                    <li>The onboarding flow will now redirect you if you have already onboarded.</li>
                    <li>
                        Fixed an issue where the permissions of a newly invited team member were always set to <code>read</code>.
                    </li>
                    <li>
                        Fixed an issue where Read / Write members of a team would have no label appear next to their
                        name.
                    </li>
                    <li>The integration flow has been better split into discrete steps for simplicity.</li>
                    <li>OneRoster is now available as a data source provider on the <code>Create Source</code> page.</li>
                    <li>
                        The Dashboard no longer functions as a privileged application, in fact it is totally stateless
                        and no longer requires any API keys at all.
                    </li>
                    <li>
                        All requests are now routed directly to the Edlink API and are no longer proxied through the
                        Dashboard.
                    </li>
                    <li>The backend for the Dashboard has been eliminated entirely.</li>
                    <li>Frontend files are now properly gzipped and have a new caching policy.</li>
                    <li>The above changes should result in drastic speed improvements.</li>
                    <li>The documentation has been updated to reflect a number of changes to the API.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">February 17th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>
                        Fixed a bug in the password reset process that was preventing the new password from being saved
                        properly.
                    </li>
                    <li>Made several minor bugfixes.</li>
                    <li>
                        All endpoints will now return an object with a <code>$data</code> property, and potentially information
                        about pagination (when it is applicable).
                    </li>
                    <li>You can now configure Clever and Classlink keys on an application.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Made requests conform to the new backend <code>$data</code> requirement.</li>
                    <li>You can now add sources from several new providers.</li>
                    <li>Made some visual updates to the SSO login screen.</li>
                    <li>Made some visual updates on the application keys page.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 31st, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="api">API Updates</h3>
                <ul>
                    <li>Fixed a bug that was preventing applications from being created.</li>
                </ul>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Updated several documents in the documentation section.</li>
                    <li>Moved these change logs into their own section of the documentation.</li>
                    <li>Fixed some broken links on the home page.</li>
                    <li>Improvements to the Graph API documentation.</li>
                    <li>
                        Users can now access their individual account settings by clicking on their name in the header.
                    </li>
                    <li>Users can now force sign out of all of their accounts from the account settings page.</li>
                    <li>The page will now properly scroll to the top upon navigation.</li>
                    <li>GDPR warning banner added.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 7th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>
                        Fixed a bug on the documentation page that caused the interactivity settings to be visible even
                        when the user was not logged in.
                    </li>
                    <li>Corrected a margin issue on documentation pages.</li>
                    <li>Updated the link to the Edlink community page.</li>
                </ul>
            </div>
        </section>
        <section class="flex" id="">
            <div class="date-container flex">
                <a class="date" href="#">January 6th, 2020</a>
            </div>
            <div class="ff">
                <h1>Platform Updates</h1>
                <h3 class="dashboard">Dashboard Updates</h3>
                <ul>
                    <li>Initial release of Edlink dashboard.</li>
                </ul>
            </div>
        </section>
    </article>
</template>

<script>
export default {
    name: 'DocumentationChangelog',
    data() {
        return {};
    },
    created() {},
    computed: {
        sections() {
            return this.$store.state.documentation.sections;
        },
        team() {
            return this.$store.getters.team;
        },
        user() {
            return this.$store.state.user;
        },
        application() {
            return this.$store.state.documentation.application;
        }
    },
    watch: {},
    methods: {
        subscribe(){
            // this.$store.dispatch('subscribe', this.user.email);
        }
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.changelog {
    padding: 50px 30px;
}

.changelog-gap
{
    margin: 100px 0;
    height: 8px;
    background: url('~@/assets/zigzag.svg') top left repeat-x;
    background-size: auto 8px;
    opacity: 0.2;
}

.hero {
    background: url('~@/assets/topography.svg') top left repeat @f4;
    border-radius: 10px;
    padding: 80px;

    h1 {
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.05rem;
        font-weight: 600;
        color: @base;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.05rem;
        font-weight: 500;
        color: @black;
    }

    .button {
        margin-top: 100px;
    }
}

section {
    padding: 50px 80px;

    .date-container {
        width: 250px;
    }

    a.date {
        display: block;
        font-size: 16px;
        line-height: 40px;
        font-weight: 500;
        color: @grey;
        letter-spacing: -0.03rem;
    }

    h2 {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.05rem;
        font-weight: 500;
        color: @black;
        margin-top: 30px;
    }

    h3
    {
        margin-top: 20px;

        &::before
        {
            width: 20px;
            height: 20px;
            content: '🎉';
            line-height: 20px;
            display: inline-block;
            margin-right: 8px;
            margin-left: -3px;
        }

        &.dashboard
        {
            &::before
            {
                content: '👨‍💻';
            }
        }
    }

    p
    {
        margin: 15px 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: @black;
    }

    h3 + p, h1 + p
    {
        margin-top: 10px;
    }

    ul
    {
        margin: 15px 0 30px 15px;
        list-style: disc;

        li
        {
            margin: 15px 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: @black;
            padding-left: 10px;
        }

        ul
        {
            margin: 0 0 0 30px;
        }
    }

    code
    {
        background: @f4;
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: @black;
    }

    img.cover-image
    {
        border-radius: 10px;
        margin: 10px 0 0;
        max-width: 100%;
    }

    a.link
    {
        color: @base;
        text-decoration: underline;
    }
}
</style>