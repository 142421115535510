<template>
    <div class="demo" :class="{iframe}">
        <template v-if="!iframe">
            <div class="side-nav">
                <div class="token flex flex-column flex-align" v-for="(token_set, index) of token_sets" @click="select_token_set(token_set.person_id)" :key="index" :class="{active: is_active(token_set)}">
                    <div class="token-picture">
                        <!-- <img :src="token_set.person_picture_url" /> -->
                        <div class="tooltip-right">
                            {{ token_tooltip_string(token_set) }}
                        </div>
                    </div>
                    <div class="token-name center text-overflow">
                        {{token_set.person_first_name ? token_set.person_first_name : 'Unknown'}}
                    </div>
                </div>
            </div>
            <nav>
                <div class="flex flex-align">
                    <a class="nav-wordmark block" href="https://ed.link"></a>
                    <div class="demo-breadcrumbs flex flex-align">
                        <router-link :to="{name: 'demo.classes'}" class="text-button">All Classes</router-link>
                        <template v-if="$route.params.class">
                            <div class="crumb"> > </div>
                            <router-link :to="{name: 'demo.class', params: {class: $route.params.class}}" class="text-button">Class</router-link>
                        </template>
                        <template v-if="$route.params.assignment">
                            <div class="crumb"> > </div>
                            <router-link :to="{name: 'demo.class.assignment', params: {assignment: $route.params.assignment}}" class="text-button">Assignment</router-link>
                        </template>
                        <template v-if="$route.params.submission">
                            <div class="crumb"> > </div>
                            <router-link :to="{name: 'demo.class.assignment.submission', params: {submission: $route.params.submission}}" class="text-button">Submission</router-link>
                        </template>
                    </div>
                </div>
                <div class="links">
                    <a class="text-button flex flex-align" v-if="name">
                        {{name}}
                        <span class="icon iconoir-profile-circled"></span>
                    </a>
                </div>
            </nav>
        </template>
        <div class="role" :class="{[role.toLowerCase()]: true}" v-if="profile">You are currently acting as a {{role}}</div>
        <div class="content">
            <div class="body">
                <template v-if="profile && !loading">
                    <router-view v-bind="state" :lti_launch="lti_launch"
                        :key="state.token_set ? state.token_set.access_token : null" />
                </template>
                <div class="loading" v-else>
                    <spinner />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const base_url = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://ed.link') + '/api';

export default {
    name: 'DemoApp',
    data() {
        return {
            classes: [],
            client_id: '36ad8864-d789-45b6-9df8-334c2f32445b',
            client_secret: 'pT0wYC9rgaUdxgUw8PlFEP1ALeejZbJIemZ9u5VYTreyvEw0uDLevmCR3M9Zqhww',
            state: {
                edlink_widget: null,
                profile: null,
                token_set: null,
                integration: null,
                source: null
            },
            loading: true
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state?.user.admin;
        },
        profile() {
            return this.state.profile;
        },
        student() {
            return this.profile && this.profile.roles.includes('student');
        },
        teacher() {
            return this.profile && this.profile.roles.includes('teacher');
        },
        role() {
            return this.teacher ? 'Teacher' : 'Student';
        },
        lti_launch() {
            return this.$root.$data.lti_launch ?? false;
        },
        iframe () {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
        name() {
            if (this.profile) {
                if (this.profile.first_name) {
                    return this.profile.last_name ? this.profile.first_name + ' ' + this.profile.last_name : this.profile.first_name;
                } else if (this.profile.last_name) {
                    return this.profile.first_name ? this.profile.first_name + ' ' + this.profile.last_name : this.profile.last_name;
                } else if (this.profile.display_name) {
                    return this.profile.display_name.trim();
                } else {
                    return 'Anonymous User';
                }
            } else {
                return 'Anonymous User';
            }
        },
        integration() {
            return this.$store.state.integrations.active;
        },
        token_sets() {
            const local_data = localStorage.getItem('edlink-demo-app-token-sets');

            if (local_data) {
                try {
                    return JSON.parse(local_data);
                } catch {
                    localStorage.removeItem('edlink-demo-app-token-sets');
                    
                    return [];
                }
            } else {
                return [];
            }
        },
        token_set: {
            get: function () {
                const local_data = localStorage.getItem('edlink-demo-app-token-set');
                if (local_data) {
                    try {
                        const value = JSON.parse(local_data);
                        this.state.token_set = value;
                        return value;
                    } catch {
                        localStorage.removeItem('edlink-demo-app-token-set')
                        return null;
                    }
                } else {
                    return null;
                }
            },
            set: function (value) {
                localStorage.setItem('edlink-demo-app-token-set', JSON.stringify(value));
                this.state.token_set = value;
            }
        }
    },
    mounted() {
        if (this.token_set) {
            this.initialize();
        }
    },
    destroyed() {
        this.unmount_widget();
    },
    methods: {
        token_tooltip_string(token_set){
            if (token_set.person_last_name) {
                return `Login as ${token_set.person_first_name}  ${token_set.person_last_name} (${ token_set.person_roles.includes('teacher') ? 'Teacher' : 'Student' })`
            } else {
                return `Login as ${token_set.person_first_name} (${ token_set.person_roles.includes('teacher') ? 'Teacher' : 'Student' })`
            }
        },
        unmount_widget(){
            if (this.state.edlink_widget) {
                this.state.edlink_widget.destroy();
                this.state.edlink_widget = null;
            }
        },
        select_token_set(person_id) {
            if (person_id) {
                const search = this.token_sets.find(it => it.person_id === person_id);
                if (search) {
                    this.loading = true;
                    this.state.profile = null;
                    this.token_set = search;
                    if (this.$route.name !== 'demo.classes') {
                        this.$router.push('/demo');
                    }
                    this.initialize();
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        is_active(token_set) {
            if (token_set?.person_id && this.state.token_set?.person_id) {
                return token_set.person_id === this.state.token_set.person_id;
            } else {
                return false;
            }
        },
        initialize() {
            this.unmount_widget();
            const wait = [];
            wait.push(
                this.$demo.get(`${base_url}/v2/my/profile`).then(res => {
                    // Save this person to 'state'
                    this.state.profile = res.$data;
                    if (window.EdlinkWidget) {
                        // Initialize widget with this person
                        this.state.edlink_widget = new window.EdlinkWidget({
                            development: process.env.NODE_ENV === 'development',
                            demo: true,
                            person_id: this.profile.id,
                            client_id: this.client_id,
                            rounded: 5,
                            colors: {
                                primary: '#e63946',
                                background: '#fff',
                                background_secondary: '#f7f7f7',
                                background_tertiary: '#e4e4e4',
                            },
                            background_alpha: 0.3,
                            background_blur: 5
                        });
                    }
                })
            );
            wait.push(
                this.$demo.get(`${base_url}/v2/my/integration`).then(res => {
                    this.state.integration = res.$data;
                })
            );
            wait.push(
                this.$demo.get(`${base_url}/v2/my/source`).then(res => {
                    this.state.source = res.$data;
                })
            );
            Promise.all(wait).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.nav-wordmark {
    height: 24px;
    width: 80px;
    mask-image: url('~@/assets/acme.svg');
    mask-size: contain;
    mask-position: center center;
    background-color: @demo-base;
    color: transparent;
}

.demo {
    padding-left: 81px;
    min-height: 100vh;

    &.iframe
    {
        padding-left: 0;
    }
}

.demo-breadcrumbs {
    margin-left: 25px;
    color: @demo-alt;

    .crumb
    {
        margin: 0 5px;
    }
}

.side-nav {
    height: 100vh;
    width: 81px;
    position: fixed;
    left: 0;
    top: 0;
    padding: 15px;
    background-color: @f8;
    border-right: 1px solid @e;
    z-index: 100;
}


    
.tooltip-right
{
    position: relative;
    left: 130%;
    bottom: -6px;
    width: fit-content;
    white-space: nowrap;
    pointer-events: none;
    font-size: 12px;
    padding: 0 8px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid @c;
    letter-spacing: 0;
    background: @f4;
    color: @grey;
    height: 28px;
    line-height: 26px;
    display: none;
}

.content {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 0 40px;
    overflow-y: auto;
    max-height: calc(100vh - 80px);

    &.with-nav {
        margin-left: 100px;
    }

    .body {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }

    .loading {
        height: calc(100vh - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.token {
    margin-bottom: 20px;

    .token-picture
    {
        cursor: pointer;
        width: 48px;
        background-color: @d;
        border-radius: 50%;
        height: 48px;
        padding: 2px;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    .token-name
    {
        font-size: 10px;
        margin-top: 5px;
        color: @grey;
    }

    &.active
    {
        .token-picture
        {
            border: 2px solid @demo-base;
        }

        .token-name
        {
            color: @black;
        }
    }

    &:hover
    {
        .tooltip-right {
            display: block;
        }
    }
}

.class-list {
    width: 100%;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(300px);
    grid-gap: 25px;

    .card {
        .bg {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 150px;
            width: 100%;
        }

        .body {
            padding: 20px 25px 20px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    border-bottom: 2px solid @f8;
    height: 80px;
    z-index: 99;
    background-color: @white;

    a {
        color: @demo-alt
    }

    .links {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .text-button {
            margin-left: 15px;

            .icon {
                margin-left: 5px;
            }
        }
    }
}

.role {
    color: @white;
    background: @demo-base;
    border: 1px solid darken(@demo-base, 10%);
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 400px;
    z-index: 999;
    text-align: center;
    font-weight: 600;

    &.teacher {
        background: @purple;
        border-color: darken(@purple, 10%);
    }

    &.student {
        background: @base;
        border-color: darken(@base, 10%);
    }
}

.source-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: @e;
    margin-left: 10px;

    &::before {
        width: 32px;
        height: 32px;
        position: absolute;
        z-index: 2;
        content: "";
        top: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}

// Demo Reset
button {
    background: @demo-base;
    border-color: darken(@demo-base, 10%);

    &:hover {
        background: darken(@demo-base, 10%);   
    }
}
</style>
