import store from '../store';

export default ({ next, router }) => {
    if (store.state.user.token) {
        if (store.getters.team) {
            return router.push({ name: 'team.dashboard', params: { team: store.getters.team.alias } });
        }

        if (store.state.teams.all.length) {
            return router.push({ name: 'home' });
        }

        return router.push({ name: 'onboard' });
    }

    return next();
};
