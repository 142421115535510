<template>
    <div class="integrate-initializing flex flex-center">
        <spinner />
    </div>
</template>

<script>
    export default {
        name: 'IntegrateInitializing',
        props: {
            state: Object,
            send: Function
        },
        created(){
            if(this.state.context.application){
                return this.send('APPLICATION', this.state.context.application);
            }

            this.$http.get(`/marketplace/${this.$route.params.application}`).then(response => {
                this.send({type: 'APPLICATION', data: response.$data});
            });
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
