<template>
    <div class="integrate-source-provider-oneroster">

        <integrate-source-editor-step name="Select Your SIS" icon="iconoir-select-window" :complete="completed.includes('provider')">
            <div class="helptext">
                Please select your SIS, or choose `other` if you do not see it on the list.
            </div>
            <div class="flex provider-options">
                <div class="card integrate-radio flex flex-align" :class="{ selected: source.configuration.provider === provider }" v-for="(provider, key) of oneroster_variants" :key="key" @click="select_provider(provider)">
                    <div class="thumbnail provider-icon">
                        <img :src="provider === 'Other' ? `/source/default.png` : `/source/${key}.png`" />
                    </div>
                    <div class="ff capitalize">{{ provider }}</div>
                </div>
            </div>

        </integrate-source-editor-step>

        <integrate-source-editor-step v-if="choose_another_source" name="Check Your Previous Selection" icon="iconoir-info-empty" :complete="completed.includes('proceed')">
            <div class="helptext">
                Verify that you have selected the correct SIS in the previous steps.
            </div>

            <!-- v-if="choose_another_source" -->
            <integrate-source-editor-configuration   title="Are you sure?" icon="iconoir-info-empty" >
                <div class="configuration-value keys">
                    Edlink has a specific provider for <strong>{{ source.configuration.provider }}</strong>. Please return to the previous step and select it. The specific provider will sync data more accurately and with additional properties that are not available through the generic OneRoster API. Unless there are exceptional circumstances, we recommend using the specific provider.
                </div>

            </integrate-source-editor-configuration>
            <div class="flex flex-align">
                <checkbox label="Proceed Anyway?" :checked.sync="proceed" />
                <div class="text-button return-to" @click="send('CREATE_SOURCE')">Return to <em>Select Your Data Source</em></div>
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step v-if="!completed.includes('provider')" :loading="true" />
        <div v-if="(!choose_another_source || proceed) && completed.includes('provider')">
            <integrate-source-editor-step name="Generate OneRoster Keys" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
                <div class="helptext">
                    Use your source system to generate a new set of keys.
                </div>
                <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                    <div class="configuration-value keys">
                        This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                        click <a class="configuration-link inline-block text-button mini" target="_blank" href="https://ed.link/docs/schools/connect/oneroster">here</a>.
                    </div>
                </integrate-source-editor-configuration>
                <div class="flex">
                    <checkbox label="Mark as Complete" :checked.sync="remote" />
                </div>
            </integrate-source-editor-step>

            <one-roster-oauth-steps :completed="completed" :state="state" :type="type" :send="send" />
        </div>

    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import OneRosterOAuthSteps from './components/OneRosterOAuthSteps.vue';
    import { timezone_identifiers, oneroster_variants } from '@/enums';


    export default {
        name: 'IntegrateSourceProviderOneRoster',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration,
            'one-roster-oauth-steps': OneRosterOAuthSteps
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider() {
                return this.$parent.provider;
            },
            source() {
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed() {
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if (!this.source) {
                    return [];
                }

                if (!this.source.configuration.provider) {
                    return [];
                }

                if (this.choose_another_source && !this.proceed) {
                    return ['provider'];
                }

                if (!this.remote) {
                    return ['provider', 'proceed'];
                }

                if (!this.version) {
                    return ['provider', 'proceed', 'remote'];
                }

                if (!this.source.configuration.oneroster_server_url || !this.source.configuration.oneroster_server_url.match(url)) {
                    return ['provider', 'proceed', 'remote', 'version'];
                }

                if (!this.source.configuration.oneroster_client_id?.trim().length || !this.source.configuration.oneroster_client_secret?.trim().length) {
                    return ['provider', 'proceed', 'remote', 'version'];
                }

                if (this.version === '2.0' && (!this.source.configuration.oauth_token_url || !this.source.configuration.oauth_token_url.match(url))) {
                    return ['provider', 'proceed', 'remote', 'version'];
                }

                if (!this.source.configuration.timezone) {
                    return ['provider', 'proceed', 'remote', 'version', 'keys'];
                }

                return ['provider', 'proceed', 'remote', 'version', 'keys', 'timezone'];
            },
            version() {
                return this.source.configuration.version;
            },
            choose_another_source() {
                return ['qmlativ', 'skyward', 'classgather', 'infinitecampus', 'facts', 'classgather', 'managebac', 'rediker', 'synergy', 'genesis', 'sapphire'].includes(this.source.configuration.provider?.toLowerCase())
            }
        },
        watch: {
            completed() {
                console.log('completed', this.completed);
                this.$emit('update:complete', ( !this.choose_another_source || this.proceed) && this.completed.length === 6);
            }
        },
        data() {
            return {
                remote: false,
                proceed: false,
                timezones: timezone_identifiers,
                oneroster_variants: oneroster_variants,
            };
        },
        methods: {
            select_provider(provider) {
                this.send('UPDATE_SOURCE', { path: 'configuration.provider', value: provider });
            }
        }
    }
</script>


<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext+input
    {
        margin-top: 15px;
    }

    .return-to
    {
        margin-left: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;

        &.first
        {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .provider-options
    {
        flex-wrap: wrap;

        .integrate-radio
        {
            margin-bottom: 15px;
            padding: 8px;

            &:last-child
            {
                margin-bottom: 15px;
            }
        }
    }

    .thumbnail
    {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        box-shadow: 0 0 0 2px @f;
        margin-right: 6px;

        &::before
        {
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 2;
            content: "";
            top: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        img
        {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: block;
        }
    }
</style>
