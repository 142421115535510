<template>
    <div class="additional-properties">
        <div class="additional-properties-header flex" :class="{override: !!override}">
            <div class="flex ff">
                <h4>Additional Properties</h4>
                <div class="override-pin"></div>
            </div>
            <div class="flex toolbar" v-if="entity.properties">
                <div class="text-button mini" @click="toggle_add_prop_form">Add Property</div>
                <div class="modal add-prop-form" v-if="show_add_prop_form" @click.stop="">
                    <div>
                        <label for="propname">Property Name</label>
                        <input type="text" id="propname" v-model.trim="prop_name">
                    </div>
                    <div class="prop-type-select">
                        <label>Property Type</label>
                        <div
                            :class="{ button: true, mini: true, white: selected_type !== 'boolean' }" 
                            @click="set_prop_type('boolean')">
                            Boolean
                        </div>
                        <div
                            :class="{ button: true, mini: true, white: selected_type !== 'number' }" 
                            @click="set_prop_type('number')">
                            Number
                        </div>
                        <div
                            :class="{ button: true, mini: true, white: selected_type !== 'string' }" 
                            @click="set_prop_type('string')">
                            String
                        </div>
                        <div 
                            :class="{ button: true, mini: true, white: selected_type !== 'array' }" 
                            @click="set_prop_type('array')">
                            Array
                        </div>
                        <div 
                            :class="{ button: true, mini: true, white: selected_type !== 'object' }" 
                            @click="set_prop_type('object')">
                            Object
                        </div>
                    </div>
                    <div v-if="selected_type && selected_type !== 'array' && selected_type !== 'object'">
                        <div>
                            <label for="propvalue">
                                <span v-if="selected_type === 'number'">Enter Number</span>
                                <span v-else-if="selected_type === 'string'">Enter String</span>
                                <span v-else>Select Value</span>
                            </label>
                            <div class="flex bool-select" v-if="selected_type === 'boolean'">
                                <div
                                    :class="{ button: true, ff: true, mini: true, white: prop_value !== true }"
                                    @click="set_bool_value(true)">
                                    True
                                </div>
                                <div 
                                    :class="{ button: true, ff: true, mini: true, white: prop_value !== false }"
                                    @click="set_bool_value(false)">
                                    False
                                </div>
                            </div>
                            <input type="text" id="propvalue" v-model.trim="prop_value" v-else>
                        </div>
                    </div>
                    <div class="flex flex-end">
                        <div class="button mini" @click="add_prop_to_root" :disabled="!is_valid">Add Property</div>
                    </div>
                </div>
                <div class="text-button mini grey" :class="{disabled: !is_overridden}" @click="reset">Reset</div>
            </div>
        </div>
        <div class="entity-value entity-value-custom-props" v-if="entity.properties && Object.keys(custom_props).length > 0">
            <div>
                <object-prop-edit v-for="(val, i) in custom_props" 
                    :key="i" 
                    @add-prop="add_prop" 
                    @remove-prop="remove_prop" 
                    @update-prop="update_prop"
                    :label="i"
                    :level="0"
                    :node-path="[]"
                    parent-type="object"
                    :value="val" />
            </div>
        </div>
        <div class="nothing" v-else>None.</div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ObjectPropEdit from '@/components/ObjectPropEdit.vue';

    export default {
        name: 'EntityValueCustomProps',
        components: {
            'object-prop-edit': ObjectPropEdit
        },
        props: {
            property: String
        },
        data(){
            return {
                custom_props: {},
                prop_name: '',
                prop_value: '',
                selected_type: null,
                show_add_prop_form: false
            };
        },
        computed: {
            override() {
                return _.get(this.$store.state.entities.overrides, 'properties');
            },
            entity() {
                return this.$store.state.entities.active;
            },
            is_overridden() {
                const overridden = JSON.stringify(this.custom_props) !== JSON.stringify(this.entity.properties);
                return overridden;
            },
            is_valid() {
                if (!this.prop_name) {
                    return false;
                }

                if (!this.selected_type) {
                    return false;
                }

                if (this.selected_type === 'boolean') {
                    if (typeof this.prop_value !== 'boolean') {
                        return false;
                    }
                }

                if (this.selected_type === 'number') {
                    if (isNaN(parseFloat(this.prop_value))) {
                        return false;
                    }
                }

                if (this.selected_type === 'string' && !this.prop_value) {
                    return false
                }

                return true;
            }
        },
        methods: {
            add_prop(params) {
                const {
                    prop_name,
                    prop_value,
                    parent_node
                } = params;
                if (parent_node) {
                    if (Array.isArray(parent_node)) {
                        parent_node.push(prop_value);
                    } else {
                        if (typeof parent_node === 'object') {
                            this.$set(parent_node, prop_name, prop_value);
                        }
                    }
                    this.save();
                }
            },
            add_prop_to_root() {
                let prop_value = this.prop_value;

                if (this.selected_type === 'number') {
                    prop_value = parseFloat(this.prop_value);
                }

                const value = this.selected_type === 'object' ? {} : (this.selected_type === 'array' ? [] : prop_value);
                
                if (!_.isNil(value)) {
                    this.custom_props = Object.assign({}, this.custom_props, { [this.prop_name]: value });
                    this.save();
                    this.prop_name = '';
                    this.prop_value = '';
                    this.selected_type = null;
                    this.show_add_prop_form = false;
                }
            },
            remove_prop(params) {
                const {
                    index,
                    parentPath,
                    parentType
                } = params;
                
                if (parentPath && Array.isArray(parentPath) && parentType) {
                    let node = this.custom_props;

                    if (parentPath.length) {
                        const tokens = parentPath.map(item => {
                            if (typeof item === 'number') {
                                return `[${item}]`;
                            } else {
                                return `.${item}`;
                            }
                        });

                        node = _.get(this.custom_props, tokens.join('').replace(/\./, ''));
                    }

                    if (parentType === 'array') {
                        node.splice(index, 1);
                    }

                    if (parentType === 'object') {
                        this.$delete(node, index);
                    }

                    this.save();
                }
            },
            reset() {
                this.$store.commit('entities/clear', 'properties');
                this.$store.dispatch('entities/update').then(() => this.set_custom_props());
            },
            save() {
                this.$store.commit('entities/override', { property: 'properties', value: Object.assign({}, this.custom_props) });
                this.$store.dispatch('entities/update');
            },
            set_bool_value(val) {
                this.prop_value = val;
            },
            set_custom_props() {
                const override = _.get(this.$store.state.entities.overrides, 'properties');
                if (override && typeof override === 'object' && Object.keys(override).length) {
                    this.custom_props = _.cloneDeep(override);
                } else {
                    this.custom_props = Object.assign({}, this.entity.properties);
                }
            },
            set_prop_type(type) {
                this.prop_value = '';
                this.selected_type = type;
            },
            toggle_add_prop_form() {
                this.show_add_prop_form = !this.show_add_prop_form;
            },
            update_prop(params) {
                const {
                    index,
                    parentPath,
                    prop_value
                } = params;

                let node = this.custom_props;

                if (parentPath.length) {
                    const tokens = parentPath.map(item => {
                        if (typeof item === 'number') {
                            return `[${item}]`;
                        } else {
                            return `.${item}`;
                        }
                    });
                    node = _.get(this.custom_props, tokens.join('').replace(/\./, ''));
                }

                this.$set(node, index, prop_value);
                this.save();
            }
        },
        mounted() {
            this.set_custom_props();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .additional-properties-header {
        margin-bottom: 10px;

        .text-button
        {
            margin-left: @single-padding;
        }

        .override-pin
        {
            width: 16px;
            height: 16px;
            background: url('~@/assets/icons/base/pin.svg') 4px 3px no-repeat;
            background-size: 7px auto;
            background-position: center;
            margin-top: 1px;
            display: none;
            margin-left: 2px;
        }

        &.override
        {
            h4
            {
                color: @base;
            }

            .override-pin
            {
                display: block;
            }
        }
    }

    .modal {
        background: @f;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.1);
        position: absolute;
        overflow: hidden;
        padding: 10px;
        text-align: left;
        top: 28px;
        z-index: 15;

        label, input {
            font-size: 13px;
        }

        input[type="text"] {
            height: 24px;
            line-height: 24px;
            margin-bottom: 15px;
            padding: 8px 4px;
        }

        .button {
            margin-bottom: 5px;
        }
    }

    .add-prop-form {
        left: -160px;
        min-width: 210px;

        .prop-type-select {
            > div:last-child {
                margin-bottom: 15px;
            }
        }

        .bool-select {
            gap: 6px;
            margin-bottom: 10px;
        }

        > div:last-child {
            .button {
                margin-bottom: 0;
            }
        }
    }

    .toolbar {
        position: relative;
        top: -4px;

        > button {
            margin-left: 10px;
        }
    }
</style>