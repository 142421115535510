<template>
    <chip :color="states[category].color" :title="states[category].name" />
</template>

<script>
    export default {
        name: 'IntegrationBillingCategory',
        props: {
            category: String
        },
        data(){
            return {
                states: {
                    'standard': { color: 'blue', name: 'Standard' },
                    'development': { color: 'purple', name: 'Development' }
                }
            };
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
</style>
