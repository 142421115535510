<template>
    <div class="placard-group flex flex-align">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'PlacardGroup',
        props: {
            
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
    
    .placard-group
    {
        .placard::v-deep
        {
            flex: 1;
            max-width: 320px;
            margin-right: @double-padding;

            &:nth-child(1)
            {
                .placard-icon
                {
                    background: linear-gradient(0deg, darken(@base, 5%), lighten(@base, 15%));
                }
            }

            &:nth-child(2)
            {
                .placard-icon
                {
                    background: linear-gradient(0deg, darken(@grey, 10%), lighten(@grey, 10%));
                }
            }

            &:nth-child(3)
            {
                .placard-icon
                {
                    background: linear-gradient(0deg, darken(@purple, 15%), lighten(@purple, 5%));
                }
            }
        }
    }
</style>