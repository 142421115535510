<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <router-link :to="{name: 'team.logs'}" class="breadcrumb flex flex-align">
                    <TerminalCircled class="icon" width="16" height="16" stroke-width="2" />
                    Request Logs
                </router-link>
                <template v-if="parent">
                    <router-link :to="{name: 'team.logs.request', params: {request: parent}}" class="breadcrumb flex flex-align">
                        Inbound Request
                    </router-link>
                    <div class="breadcrumb">
                        Outbound Request
                    </div>
                </template>
                <template v-else>
                    <div class="breadcrumb">
                        Inbound Request
                    </div>
                </template>
            </template>
            <template v-slot:actions>
                <div class="button white has-icon" title="Export Logs" @click="save()">
                    <span class="icon iconoir-database-export"></span>
                    Download
                </div>
            </template>
        </breadcrumbs>
        <div v-if="loading" class="request full flex flex-center flex-align">
            <spinner />
        </div>
        <div v-else class="request full scroll">
            <section v-if="request">
                <h3>Request Summary</h3>
                <div class="summary ff">
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Request ID</div>
                        <div class="summary-value monospace copyable" v-clipboard="() => request.id" v-clipboard:success="() => $toasted.info('Copied')">{{request.id}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Type</div>
                        <div class="summary-value capitalize">{{parent ? 'Outbound' : 'Inbound'}}</div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="parent">
                        <div class="summary-key">Parent Request</div>
                        <div class="summary-value">
                            <router-link :to="{name: 'team.logs.request', params: {request: parent}}" class="text-button">{{parent}}</router-link>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Start Date</div>
                        <div class="summary-value">{{request.start_date | pretty('long')}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">End Date</div>
                        <div class="summary-value">{{request.end_date | pretty('long')}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Duration</div>
                        <div class="summary-value">{{duration}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Method</div>
                        <div class="summary-value">{{request.method}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Endpoint</div>
                        <div class="summary-value">{{request.path}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Status</div>
                        <div class="summary-value">{{request.status}}</div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="inbound">
                        <div class="summary-key">IP Address</div>
                        <div class="summary-value monospace">{{request.properties.ip}}</div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="inbound">
                        <div class="summary-key">User Agent</div>
                        <div class="summary-value text-overflow" v-if="request.properties.user_agent">{{request.properties.user_agent}}</div>
                        <div class="summary-value" v-else>Not Specified</div>
                    </div>
                    <template v-if="request.provider">
                        <div class="summary-field flex flex-align">
                            <div class="summary-key">Provider</div>
                            <div class="summary-value">{{request.provider.name}}</div>
                        </div>
                    </template>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Source</div>
                        <div class="summary-value" :class="{empty: !request.source.id}">{{request.source.id ? request.source.name : '—'}}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">End User</div>
                        <div class="summary-value" v-if="request.person">
                            <router-link :to="{name: 'team.integrations.integration.dataset', params: {type: 'people', integration: request.integration.id}, query: {filters: JSON.stringify([{property: 'id', operator: 'equals', value: request.person.id}])}}" class="text-button">
                                {{request.person.display_name}}
                            </router-link>
                        </div>
                        <div class="summary-value empty" v-else>&mdash;</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Application</div>
                        <div class="summary-value" :class="{empty: !request.application.id}">
                            <router-link v-if="request.application.id" :to="{name: 'team.applications.application', params: {application: request.application.id}}" class="text-button">
                                {{request.application.name}}
                            </router-link>
                            <template v-else>&mdash;</template>
                        </div>
                    </div>
                    <div class="summary-field flex flex-align" v-if="request.integration">
                        <div class="summary-key">District</div>
                        <div class="summary-value">
                            <router-link :to="{name: 'team.integrations.integration.overview', params: {integration: request.integration.id}}" class="text-button">
                                {{request.team.name}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="children.length">
                <h3>Outbound Requests</h3>
                <datatable class="children card" :columns="columns" :rows="children" :header="true" @clicked="row => open(row)" :clickable="true" />
            </section>
            <section v-if="input">
                <h3>Request Body</h3>
                <pre class="line-numbers monospace" v-html="input"></pre>
            </section>
            <section v-if="output">
                <h3>Response Body</h3>
                <div class="error card" v-for="(error, index) of errors" :key="index">
                    <div class="error-header flex flex-align">
                        <div class="badge error">{{request.status}}</div>
                        <div class="error-code">{{error.code}}</div>
                    </div>
                    <article class="error-body">
                        <v-runtime-template :template="error.description" />
                    </article>
                </div>
                <div class="warning card" v-for="(warning, index) of warnings" :key="index">
                    <div class="warning-header flex flex-align">
                        <div class="badge warning">{{request.status}}</div>
                        <div class="warning-code">{{warning.code}}</div>
                    </div>
                    <article class="warning-body">
                        <v-runtime-template :template="warning.description" />
                    </article>
                </div>
                <pre class="line-numbers monospace" v-html="output"></pre>
            </section>
        </div>
    </main>
</template>

<script>
    import moment from 'moment';
    import Prism from 'prismjs';
    import VRuntimeTemplate from 'v-runtime-template';
    import showdown from 'showdown';
    import { TerminalCircled, DataTransferBoth, GitFork, WhiteFlag, Calendar } from '@epiphany/iconoir';
    import TableRequestStatus from '@/components/table/TableRequestStatus.vue';

    const KNOWN_ERRORS = [
        'ASSIGNMENT_CANNOT_HAVE_ZERO_POINTS',
        'ENTITY_NOT_PRESENT_IN_SOURCE',
        'EXPIRED_TOKEN',
        'IDEMPOTENCY_KEY_TOO_LONG',
        'ID_NOT_PROVIDED',
        'INCORRECT_STATE',
        'INTERNAL',
        'INVALID_ASSIGNEES',
        'INVALID_CURSOR',
        'INVALID_DATE_STRING',
        'INVALID_INTEGRATION_STATE',
        'INVALID_PAGE_SIZE',
        'INVALID_PROVIDER_TOKEN',
        'INVALID_STATE_TRANSITION',
        'INVALID_SUBMISSION_TYPE',
        'INVALID_TASK_TYPE',
        'INVALID_TOKEN',
        'INVALID_UUID',
        'UNSUPPORTED_OPERATION'
    ];

    const KNOWN_WARNINGS = [];

    export default {
        name: 'Request',
        data(){
            return {
                request: null,
                loading: true,
                input: '',
                output: '',
                children: [],
                errors: [],
                warnings: [],
                columns: [
                    {
                        id: 'method',
                        name: 'Method',
                        icon: DataTransferBoth,
                        width: '120px'
                    },
                    {
                        id: 'path',
                        name: 'Path',
                        icon: GitFork,
                        width: '70%'
                    },
                    {
                        id: 'status',
                        name: 'Status',
                        icon: WhiteFlag,
                        component: TableRequestStatus,
                        classes: 'raw',
                        width: '120px'
                    },
                    {
                        id: 'date',
                        name: 'Date',
                        icon: Calendar,
                        align: 'right',
                        width: '30%',
                        value: row => this.$options.filters.pretty(row.date, 'long')
                    }
                ]
            };
        },
        components: {
            VRuntimeTemplate,
            TerminalCircled
        },
        computed: {
            duration(){
                if(this.request){
                    const duration = moment(this.request.end_date).diff(this.request.start_date);

                    return `${duration} millisecond${duration === 1 ? '' : 's'}`;
                }

                return '–';
            },
            user(){
                return this.$store.state.user;
            },
            parent(){
                return this.$route.params.inbound;
            },
            inbound(){
                return !this.parent;
            }
        },
        watch: {
            '$route.params.request': {
                immediate: true,
                handler(){
                    this.request = null;
                    this.loading = true;
                    this.children = [];

                    if(this.$route.params.inbound){
                        this.$http.get(`/teams/${this.$store.getters.team.id}/requests/${this.$route.params.inbound}/children/${this.$route.params.request}`)
                        .then(response => {
                            this.request = response.$data;
                            this.highlight();
                        })
                        .then(() => this.explain())
                        .catch(error => this.$toasted.error('There was an error loading this request.'))
                        .finally(() => this.loading = false);
                    }else if(this.$route.params.request){
                        this.$http.get(`/teams/${this.$store.getters.team.id}/requests/${this.$route.params.request}`)
                        .then(response => {
                            this.request = response.$data;

                            this.highlight();

                            return this.$http.get(`/teams/${this.$store.getters.team.id}/requests/${this.$route.params.request}/children`);
                        })
                        .then(response => this.children = response.$data)
                        .then(() => this.explain())
                        .catch(error => this.$toasted.error('There was an error loading this request.'))
                        .finally(() => this.loading = false);
                    }
                }
            }
        },
        methods: {
            open(child){
                this.$router.push({name: 'team.logs.request.outbound', params: {request: child.id, inbound: this.request.id}});
            },
            explain(){
                // Load information about the errors and warnings.
                const errors = _.intersection(_.get(this.request, 'output.$errors', []).map(error => error.code), KNOWN_ERRORS);

                // Reset the errors array.
                this.errors = [];

                for(const code of errors){
                    const error = {
                        code,
                        description: null
                    };

                    this.errors.push(error);

                    this.$http.get(`https://edlink.github.io/docs/snippets/errors/${code}.md`)
                    .then(response => {
                        const container = document.createElement('section');
                        const converter = new showdown.Converter();

                        container.innerHTML = converter.makeHtml(response);
                        error.description = container.outerHTML;
                    })
                    .catch(() => {});
                }

                const warnings = _.intersection(_.get(this.request, 'output.$warnings', []).map(warning => warning.code), KNOWN_WARNINGS);

                for(const code of warnings){
                    const warning = {
                        code,
                        description: null
                    };

                    this.warnings.push(warning);

                    this.$http.get(`https://edlink.github.io/docs/snippets/warnings/${code}.md`)
                    .then(response => {
                        const container = document.createElement('section');
                        const converter = new showdown.Converter();

                        container.innerHTML = converter.makeHtml(response);
                        warning.description = container.outerHTML;
                    })
                    .catch(() => {});
                }
            },
            highlight(){
                try {
                    if(this.request.input && Object.keys(this.request.input).length){
                        this.input = Prism.highlight(JSON.stringify(this.request.input, null, 4), Prism.languages.json, 'json');
                    }else{
                        this.input = '';
                    }

                    this.output = this.request.output ? Prism.highlight(JSON.stringify(this.request.output, null, 4), Prism.languages.json, 'json') : '';
                }catch(error){
                    console.log(error);
                }
            },
            download(){
                // var text = 'Some data I want to export';
                // var data = new Blob([text], {type: 'text/plain'});
                // var url = window.URL.createObjectURL(data);
                // document.getElementById('download_link').href = url;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .request
    {
        top: @breadcrumbs-height;
    }

    section
    {
        border-top: 1px solid @e;
        padding: @section-padding;

        h3
        {
            margin-bottom: @section-padding;
        }

        &:first-child
        {
            border-top: 0;
        }
    }

    .summary
    {
        font-size: 14px;

        .summary-field
        {
            height: 24px;
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }

        .summary-key
        {
            color: @grey;
            width: 220px;
            flex-basis: 220px;
            flex-shrink: 0;
        }

        .summary-value
        {
            color: @black;
            line-height: 22px;

            &.monospace
            {
                line-height: 22px;
                font-size: 13px;
            }

            &.empty
            {
                color: @grey;
            }
        }
    }

    .request-status
    {
        width: 90px;
    }

    .request-log-options
    {
        padding: 20px 0 0;

        .text-button
        {
            margin-left: 20px;
        }
    }

    .request-results
    {
        font-size: 14px;
        color: @grey;

        span
        {
            color: @lightgrey;
            margin: 0 4px;
        }
    }

    pre {
        margin: @section-padding 0 0;
        border-radius: @border-radius;
        background: @f8;
        line-height: 20px;
        font-size: 13px;
        display: block;
        padding: @section-padding;
    }

    .request-date
    {
        width: 300px;
        font-size: 12px;
    }

    .request-path
    {
        font-weight: 400;
        font-size: 12px;
        max-width: 400px;
        color: @grey;

        span
        {
            color: @black;
            font-weight: 500;
        }
    }

    .request-status-badge
    {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        color: @green;
        font-family: @monospace;

        &.error
        {
            color: @red;
        }
    }

    .error.card, .warning.card
    {
        border-color: @red;
        margin-bottom: 25px;
        padding: 20px;

        .error-header, .warning-header
        {
            margin-bottom: 20px;
            font-size: 13px;
            font-weight: 500;
            font-family: @monospace;
            color: @red;

            .badge
            {
                margin: 0 10px 0 0;
            }
        }
    }

    .warning.card
    {
        border-color: @orange;
        
        .warning-header
        {
            color: @orange;
        }
    }

    ::v-deep .error-body, ::v-deep .warning-body
    {
        section
        {
            padding: 0;
            border: 0;

            :last-child
            {
                margin-bottom: 0;
            }
        }

        h1
        {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.03rem;
            line-height: 24px;
        }

        h2
        {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0;
            border: 0;
            padding: 0;
            color: @grey;
            margin-top: 20px;
        }

        h4
        {
            color: @black;
            letter-spacing: 0;
        }

        p, ul, ol
        {
            margin: 20px 0;
            font-size: 14px;
            color: @black;
            line-height: 20px;
        }

        ul, ol
        {
            margin-left: 14px;
        }

        ul
        {
            list-style: disc;
        }

        li
        {
            padding-left: 5px;
        }

        h1 + p, h2 + p, h3 + p, h4 + p, h4 + ul, h4 + ol
        {
            margin-top: 6px;
        }

        pre, code
        {
            font-size: 12px;
            font-weight: 500;
            background: @f4;
            border: 1px solid @e4;
            border-radius: 4px;
            padding: 1px 4px;
        }
    }
</style>