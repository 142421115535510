<template>
    <filter-dropdown class="filter-custom" custom ref="dropdown" :active="applied.length ? { name: `${applied.length} Filter${applied.length === 1 ? '' : 's'} Applied` } : null" label="Filters" @activate="clear">
        <template v-slot:icon>
            <FilterIcon class="icon" width="16" height="16" stroke-width="2" />
        </template>
        <template v-slot:custom>
            <div class="filter-custom-dropdown">
                <div class="filter-custom-title flex flex-align">
                    <AlignLeft class="icon" width="16" height="16" stroke-width="2" />
                    <div class="ff">Only Display Items</div>
                </div>
                <form class="filter-custom-items" @submit.prevent="apply">
                    <div class="filter-custom-item card flex flex-align" v-for="(filter, index) of filters" :key="index">
                        <div class="filter-custom-item-prefix">{{ index ? 'And' : 'Where' }}</div>
                        <div class="filter-custom-item-label">
                            <select v-model="filter.property" @change="e => did_change_property(filter, e)">
                                <option v-for="field of filterable" :value="field.property" :key="field.property">{{ field.name }}</option>
                            </select>
                        </div>
                        <div class="filter-custom-item-operator" @change="e => did_change_operator(filter, e)">
                            <select v-model="filter.operator">
                                <option :value="operator" v-for="operator of types[selected(filter.property).type] ?? types.default" :key="operator">{{ operators[operator] }}</option>
                            </select>
                        </div>
                        <div class="filter-custom-item-value ff">
                            <select v-model="filter.value" v-if="enums[selected(filter.property).type]">
                                <option :value="key" v-for="(value, key) in enums[selected(filter.property).type]" :key="key">{{ value }}</option>
                            </select>
                            <input v-else type="text" v-model="filter.value" placeholder="Value..." @keypress.enter="apply" />
                        </div>
                        <div class="tools">
                            <div class="filter-custom-item-remove tool flex flex-align flex-center" @click="filters.splice(index, 1)">
                                <Trash class="icon" width="16" height="16" stroke-width="2" />
                            </div>
                        </div>
                    </div>
                </form>
                <div class="filter-custom-options tools flex flex-align">
                    <div class="filter-custom-apply button transparent tool base flex flex-align" @click="apply" kbd="Enter ⏎">
                        <FilterIcon class="icon" width="16" height="16" stroke-width="2" />
                        <div class="ff">Apply Filter</div>
                    </div>
                    <div class="ff"></div>
                    <div class="tool flex flex-align" @click="create">
                        <AddCircle class="icon" width="16" height="16" stroke-width="2" />
                        <div class="ff">Add Condition</div>
                    </div>
                </div>
            </div>
        </template>
    </filter-dropdown>
</template>

<script>
    import _ from 'lodash';
    import { Filter as FilterIcon, NavArrowDown, Cancel, Search, AddCircle, AlignLeft, Trash } from '@epiphany/iconoir';

    import {
        attendance_flags,
        day_flags,
        timezone_identifiers,
        gender_identities,
        races,
        relationship_types,
        role,
        session_types,
        agent_flags
    } from '@/enums';

    export default {
        name: 'FilterCustom',
        components: {
            FilterIcon,
            NavArrowDown,
            Cancel,
            Search,
            AddCircle,
            AlignLeft,
            Trash
        },
        props: {
            applied: Array,
            filterable: Array
        },
        data(){
            return {
                filters: [],
                operators: {
                    'equals': 'Equals',
                    'in': 'Is Any Of',
                    'not in': 'Is None Of',
                    'starts with': 'Starts With',
                    'contains': 'Contains',
                    'is known': 'Is Known',
                    'is unknown': 'Is Unknown',
                    'gt': 'Greater Than',
                    'gte': 'Greater Than Or Equal To',
                    'lt': 'Less Than',
                    'lte': 'Less Than Or Equal To'
                },
                types: {
                    default: ['equals', 'in', 'not in'],
                    uuid: ['equals', 'in', 'not in'],
                    string: ['equals', 'starts with', 'contains', 'in', 'not in', 'is known', 'is unknown'],
                    date: ['equals', 'gt', 'lt', 'gte', 'lte'],
                    array: ['equals', 'in', 'not in', 'is known', 'is unknown']
                },
                enums: {
                    attendance_type: attendance_flags,
                    day_flag: day_flags,
                    timezone: timezone_identifiers,
                    gender_identity: gender_identities,
                    race: races,
                    relationship_type: relationship_types,
                    role: role,
                    session_type: session_types,
                    agent_flag: agent_flags
                }
            };
        },
        computed: {
        },
        watch: {
            applied: {
                immediate: true,
                handler(){
                    this.filters = _.isArray(this.applied) ? _.cloneDeep(this.applied) : [];

                    if(this.filters.length === 0){
                        this.create();
                    }
                }
            }
        },
        methods: {
            selected(property) {
                return this.filterable.find(column => column.property === property);
            },
            did_change_property(filter, e){
                const property = e.target.value;
                const valid_operators = this.types[this.selected(property).type] ?? this.types.default;

                if(!valid_operators.includes(filter.operator)){
                    filter.operator = 'equals';
                }

                if(this.enums[this.selected(filter.property).type]){
                    filter.value = Object.keys(this.enums[this.selected(filter.property).type]).shift();
                }
            },
            did_change_operator(filter, e){
                const operator = e.target.value;

                // TODO We're going to have to change the input from a value / select to an autocomplete component, depending on the operator.
                // When it is "equals", "starts with", or "contains", we should use a text or select input.
                // When it is "in" or "not in", we should use an autocomplete input.
            },
            create(){
                this.filters.push({
                    property: 'id',
                    operator: 'equals',
                    value: ''
                });
            },
            apply(){
                // Remove any bad filters (i.e. where the value is not set property).
                const applied = this.filters.slice();

                for(const filter of applied){
                    if(!filter.value && !['is known', 'is unknown'].includes(filter.operator)){
                        applied.splice(applied.indexOf(filter), 1);
                    }
                }

                // Close the dropdown.
                this.$refs.dropdown.close();

                this.$emit('apply', applied);
            },
            clear(){
                this.$emit('apply', []);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .filter-custom
    {

    }

    .filter-custom-dropdown
    {
        width: 600px;
        min-height: 100px;
        padding: @single-padding;
    }

    .filter-custom-title
    {
        font-size: 13px;
        height: 30px;
        color: @grey;
        padding: 0 @single-padding - 2px;
        font-weight: 500;

        .icon
        {
            margin-right: 6px;
        }
    }

    .filter-custom-options.tools
    {
        .tool
        {
            margin: 0;
        }
    }

    .filter-custom-items
    {
        
    }

    .filter-custom-item
    {
        z-index: 2;
        background-color: @f;
        margin: @single-padding 0;
        padding: 0 6px 0 @single-padding;
        height: 42px;
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1);
        border-bottom-color: @d;
        font-size: 13px;
        color: @grey;

        .filter-custom-item-prefix
        {
            width: 50px;
            height: 20px;
            line-height: 20px;
            border-right: 1px solid @d;
        }

        select, input
        {
            border: 0;
            outline: 0;
            padding: 0 @single-padding;
            font-size: 13px;
            height: 20px;
            line-height: 20px;
            border-right: 1px solid @d;
            border-radius: 0;
            background-size: 18px;
            background-position: right 4px top 2px;

            &:focus
            {
                box-shadow: none;
                color: @base;
            }
        }

        input
        {

        }

        .filter-custom-item-label
        {
            width: 120px;
        }

        .filter-custom-item-operator
        {
            width: 120px;
        }

        .filter-custom-item-remove.tool
        {
            width: @button-height;
            height: @button-height;
            margin-left: 6px;

            .icon
            {
                margin: 0;
            }
        }
    }

    .filter-custom-apply
    {
        &[kbd]::after
        {
            color: @a;
            margin-left: 0;
        }
    }
</style>
