<template>
    <div class="create-plan">
        <div class="flex flex-align drawer-header">
            <h2 class="ff drawer-title text-overflow">{{ drawer_title }}</h2>
            <!-- <div class="button white red delete" v-if="props.product" @click="delete_product">Delete Product</div> -->
            <div class="button base"  :class="{ loading: saving }" @click="save">
                <template v-if="saving">
                    <div class="spinner-container">
                        <spinner />
                    </div>
                </template>
                {{ drawer_button_text }}
            </div>
            <div class="button white base" @click="close">Close</div>
        </div>
        <div class="drawer-content drawer-scroll">
            <div class="drawer-section">
                <form autocomplete="off">
                    <label for="from">Plan Name</label>
                    <div class="form-helptext">The display name for this plan. This will appear when searching for this plan.</div>
                    <input type="text" class="block" v-model="plan.name" name="plan_name" />
                    <!-- <label for="from">Plan Code</label>
                    <div class="form-helptext">This code should be an internal identifier or SKU used to programmatically identify products within your system.</div>
                    <input type="text" class="block" v-model="plan.code" name="plan_code" /> -->
                    <label for="from">State</label>
                    <div class="form-helptext">The state of your plan in its lifecycle.</div>
                    <select class="block" v-model="plan.state" name="plan_state">
                        <option value="draft">Draft</option>
                        <option value="active">Active</option>
                        <option value="deprecated">Deprecated</option>
                        <option value="archived">Archived</option>
                    </select>
                    <!-- <label for="from">Tags</label>
                    <div class="form-helptext">Tags associated with your product.</div>
                    <custom-select class="block" :values.sync="product.tags" :paste="true" :array="true" ref="input" /> -->
                    <label for="from">Plan Description</label>
                    <div class="form-helptext">An optional description of the plan.</div>
                    <textarea class="block" v-model="plan.description" name="plan_description"></textarea>

                    <label for="from">Platform Fee</label>
                    <div class="form-helptext">Define a monthly platform fee that is always charged.</div>
                    <div class="flex flex-align form-inputs">
                        <input v-if="!!plan_minimum" type="number" class="block" v-model.number="plan_minimum" name="plan_minimum" />
                        <input v-else type="number" class="block" v-model.number="platform_fee" name="platform_fee" />
                        <checkbox label="Minimum" :checked="!!plan_minimum" @input="toggleMinimum"/>
                    </div>
                    
                    <!-- <label for="from">Minimum</label>
                    <div class="form-helptext">Optionally define a monthly minimum for each invoice.</div>
                    <input type="number" class="block" v-model.number="plan_minimum" name="plan_name" /> -->
                    
                    <label for="from">Unit Cost Matrix</label>
                    <div class="form-helptext">Define the unit costs by volume for each synced entity.</div>
                    <div class="tabs">
                        <div class="headers">
                            <div class="header" :class="{active: tab === 'people.total'}" @click="change_tab('people.total')">People</div>
                            <div class="header" :class="{active: tab === 'people.demographics'}" @click="change_tab('people.demographics')">Extended Rostering</div>
                            <div class="header" :class="{active: tab === 'people.daily_attendance'}" @click="change_tab('people.daily_attendance')">Daily Attendance</div>
                            <div class="header" :class="{active: tab === 'people.period_attendance'}" @click="change_tab('people.period_attendance')">Period Attendance</div>
                            <div class="header" :class="{active: tab === 'people.coursework'}" @click="change_tab('people.coursework')">Grades & Assignments</div>
                        </div>
                        <div class="tab">
                            <div class="cost-matrix-wrapper">
                                <table class="cost-matrix">
                                    <thead>
                                        <tr>
                                            <th>First Unit</th>
                                            <th>Last Unit</th>
                                            <th class="cost-cell">Cost</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="plan.unit_cost_matrix[tab].tiers.length === 0">
                                            <div class="empty">
                                                No tiers defined.
                                            </div>
                                        </tr>
                                        <template v-else>
                                            <tr v-for="(tier, index) in plan.unit_cost_matrix[tab].tiers">
                                                <td>
                                                <input :class="{readonly: true}" :readonly="true" :value="displayTierRange(tier.range[0])" @input="(v) => setTierRange(tier, 0, v.target.value)" />
                                                </td>
                                                <td>
                                                    <input :value="displayTierRange(tier.range[1])" @input="(v) => setTierRange(tier, 1, v.target.value)" placeholder="∞" />
                                                </td>
                                                <td class="cost-cell">
                                                    <div class="cost">
                                                        <span>$</span>
                                                        <input type="number" v-model.number="tier.cost" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="actions">
                                                        <div class="button red has-icon" @click="deleteTier(tab, index)">
                                                            <Trash class="icon" width="16" height="16" stroke-width="2" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="add-tier text-button" @click="add_tier()">+ Add another tier</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CustomSelect from '@/components/CustomSelect.vue';
    import { Trash } from '@epiphany/iconoir';
    import Vue from 'vue';

    export default {
        name: 'PlanDrawer',
        components: {
            'custom-select': CustomSelect,
            Trash
        },
        data(){
            return {
                tab: 'people.total',
                saving: false,
                plan: {
                    id: null,
                    name: '',
                    description: '',
                    state: 'draft',
                    minimum: null,
                    unit_cost_matrix: {
                        'people.total': {
                            'tiers': [{
                                'range': [1, null],
                                'cost': 0.00
                            }]
                        }
                    }
                }
            };
        },
        mounted() {
            if(this.props.plan) {
                Vue.set(this, 'plan', _.cloneDeep(this.props.plan));
            }
        },
        methods: {
            save(){
                if(this.plan.name.length === 0) {
                    return this.$toasted.error('Please input a plan name.')
                }

                this.saving = true;

                setTimeout(() => {
                    if(this.creating) {
                        this.$http.post(`/admin/billing/plans`, this.plan, { baseURL: '/api/v1' })
                            .then(response => {
                                // Close the drawer.
                                this.close();
                                // Refresh the list of products
                                if (this.props.refreshParent) {
                                    this.props.refreshParent();
                                }
                                this.$toasted.success('Plan successfully created')
                            })
                            .catch(error => this.$toasted.error(error?.$error ? error?.$error : 'There was an error creating your new plan.'))
                            .finally(() => this.saving = false);
                    } else {
                        this.$http.patch(`/admin/billing/plans/${this.plan.id}`, this.plan, { baseURL: '/api/v1' })
                            .then(response => {
                                // Close the drawer.
                                this.close();
                                // Refresh the list of products
                                if (this.props.refreshParent) {
                                    this.props.refreshParent();
                                }
                                this.$toasted.success('Plan successfully updated')
                            })
                            .catch(error => this.$toasted.error(error?.$error ? error?.$error : 'There was an error updating your plan.'))
                            .finally(() => this.saving = false);
                    }
                }, 500);
            },
            delete_product() {
                this.$http.delete(`/teams/${this.team.id}/products/${this.product.id}`, { baseURL: '/api/v2' })
                    .then(response => {
                        // Close the drawer.
                        this.close();
                        // Refresh the list of products
                        this.props.refreshParent();
                    })
                    .catch(error => this.$toasted.error('There was an error deleting your product.'));
            },
            change_tab(tab) {
                const matrix = this.plan.unit_cost_matrix[tab];
                if (!matrix) {
                    Vue.set(this.plan.unit_cost_matrix, tab, {
                        tiers: [
                            // {
                            //     range: [0, null],
                            //     cost: 0.00
                            // }
                        ]
                    });
                }

                this.tab = tab;
            },
            add_tier() {
                const matrix = this.plan.unit_cost_matrix[this.tab];
                const last_tier = matrix.tiers.slice().pop();
                const new_tier = {
                    range: [1, null],
                    cost: 0.00
                };

                if (!last_tier) {
                    this.plan.unit_cost_matrix[this.tab].tiers.push(new_tier);
                } else if (last_tier.range[1] === null) {
                    return this.$toasted.error('Please define a last unit for the previous tier.')
                } else {
                    new_tier.range[0] = last_tier.range[1] + 1;
                    this.plan.unit_cost_matrix[this.tab].tiers.push(new_tier);
                }
            },
            displayTierRange(value) {
                if (value === null) {
                    return '';
                }

                return this.$options.filters.commas(value);
            },
            setTierRange(tier, index, value) {
                value = value.replace(/,/g, '');

                if (value === '') {
                    tier.range[index] = null;
                } else {
                    const num = Number(value);
                    
                    if (isNaN(num)) {
                        return;
                    }

                    if (num <= 0 && index === 0) {
                        Vue.set(tier.range, index, 1);
                    } else {
                        Vue.set(tier.range, index, num);
                    }
                }
            },
            deleteTier(tab, index) {
                this.plan.unit_cost_matrix[tab].tiers.splice(index, 1);
            },
            close(){
                this.$store.dispatch('drawer/close');
            },
            toggleMinimum() {
                if (this.plan.minimum) {
                    this.platform_fee = this.plan.minimum;
                    this.plan.minimum = null;
                } else {
                    this.plan.minimum = this.platform_fee ?? 0;
                    this.platform_fee = null;
                }
            }
        },
        computed: {
            creating(){
                return _.isNil(this.props.plan);
            },
            team(){
                return this.$store.getters.team;
            },
            drawer_button_text() {
                if(this.creating) {
                    return 'Create Plan'
                } else {
                    return 'Save Plan'
                }
            },
            drawer_title() {
                if(this.creating) {
                    return 'Create a Plan'
                } else {
                    return 'Edit Plan'
                }
            },
            props(){
                return this.$store.state.drawer.props;
            },
            platform_fee: {
                get() {
                    return this.plan.unit_cost_matrix['platform_fee']?.flat_fee;
                },
                set(value) {
                    if ([null, undefined, ''].includes(value)){
                        Vue.delete(this.plan.unit_cost_matrix, 'platform_fee');
                    } else {
                        Vue.set(this.plan.unit_cost_matrix, 'platform_fee', { flat_fee: value });
                    }
                }
            },
            plan_minimum: {
                get() {
                    return this.plan.minimum;
                },
                set(value) {
                    if ([null, undefined, ''].includes(value)){
                        this.plan.minimum = null;
                    } else {
                        this.plan.minimum = value;       
                    }
                }
            }
        },
        watch: {
            'plan.unit_cost_matrix': {
                handler: function (val, oldVal) {
                    for (const matrix in val) {
                        if (matrix === 'platform_fee') {
                            continue;
                        }

                        const tiers = val[matrix].tiers;

                        for(let i = 0; i < tiers.length; i++) {
                            const last = tiers[i - 1];
                            if (last) {
                                tiers[i].range[0] = last.range[1] + 1;
                            }
                        }
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .form-inputs {
        input {
            margin-right: 10px;
        }
    }

    .button {
        &.loading {
            pointer-events: none;
            color: transparent;
        }

        .spinner-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            ::v-deep .spinner {
                color: @f8;

                .rotation {
                    .path {
                        stroke: @f8;
                    }
                }
            }
        }
    }

    .tabs {
        .headers {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;

            .header {
                color: @grey;
                background: @e;
                border-radius: 6px;
                padding: 6px 8px;
                margin-right: 10px;
                font-size: 14px;
                cursor: pointer;

                &.active {
                    background: @base;
                    color: @white;
                }
            }
        }

        .tab {
        }
    }

    tr {
        .empty {
            padding: 10px;
            display: flex;
            align-items: center;
            color: @grey;
            font-size: 14px;
        }
    }
    
    .cost-matrix-wrapper {
        border: 1px solid @e4;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .cost-matrix {
        width: 100%;

        input {
            border: none;
            outline: none;
            height: 30px;
            line-height: 30px;
            padding: 0;

            &.readonly {
                color: @grey;
            }

            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }

        thead {
            font-weight: 600;
            font-size: 14px;
            background: @f8;
            color: @black;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            text-align: left;
        }
        
        td {

            .cost {
                display: flex;
                align-items: center;
            }

            .actions {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .button {
                    margin-right: 5px;

                    &.has-icon {
                        margin-right: 0;
                        .icon {
                            margin-right: 0;
                        }
                    }
                }
            }

            &:focus-within {
                outline: 1px solid @base;
            }
        }

        td, th {
            border-bottom: 1px solid @e4;
            border-right: 1px solid @e4;
            padding: 4px 6px;
            
            &:last-child {
                border-right: none;
            }
        }

        .cost-cell {
            border-right: none;   
        }

        th {
            padding: 8px;

            &:first-child {
                border-top-left-radius: 6px;
            }

            &:last-child {
                border-top-right-radius: 6px;
            }
        }

        tr:last-child td {
            border-bottom: none;

            &:first-child {
                border-bottom-left-radius: 6px;
            }
            
            &:last-child {
                border-bottom-right-radius: 6px;
            }
        }
    }

    .create-plan
    {
        height: 100%;
    }

    .drawer-header {
        .button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    header
    {
        border-bottom: 1px solid @e4;

        .button
        {
            margin-left: 20px;
        }
    }

    .drawer-scroll
    {
        overflow: auto;

        .drawer-section:last-child
        {
            padding: @double-padding;

            .form-options
            {
                padding-bottom: 0;
            }
        }
    }

    ::v-deep .autocomplete .autocomplete-search {
        border-radius: 6px;
    }
</style>
