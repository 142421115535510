<template>
    <div class="create-team" :class="{typed: type}">
        <h4 class="center">Create A Team</h4>
        <form @submit.prevent="create">
            <template v-if="!type">
                <label class="team-type flex flex-align" :class="{checked: team.type === 'district'}">
                    <div class="ff">
                        <div class="team-title">School, District, or University</div>
                        <div class="team-description">
                            Select this option if you are a school, district, university, or department, and you want to integrate your team's data.
                        </div>
                    </div>
                    <input class="checkbox-input" type="checkbox" :checked="team.type === 'district'" @input="select_type('district')" />
                </label>
                <label class="team-type flex flex-align" :class="{checked: team.type === 'developer'}">
                    <div class="ff">
                        <div class="team-title">Software Developer</div>
                        <div class="team-description">
                            Select this option if you are a publisher or technology company, and you want to integrate your platform with school data.
                        </div>
                    </div>
                    <input class="checkbox-input" type="checkbox" :checked="team.type === 'developer'" @input="select_type('developer')" />
                </label>
            </template>
            <label for="team_name">{{team.type ? (team.type === 'district' ? 'District' : 'Company') : 'Organization'}} Name</label>
            <div class="form-helptext">The name of your {{team.type ? (team.type === 'developer' ? 'company' : 'district, school, or university') : 'organization'}}.</div>
            <input type="text" v-model="team.name" />
            <button type="submit">Create {{team.type ? (team.type === 'district' ? 'District' : 'Company') : 'Organization'}}</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'CreateTeam',
        props: {
            type: String,
            redirect: Boolean,
            created: Function
        },
        data(){
            return {
                team: {
                    type: '',
                    name: ''
                }
            };
        },
        watch: {
            type: {
                immediate: true,
                handler(){
                    this.team.type = this.type ? this.type : '';
                }
            }
        },
        methods: {
            create(){
                this.$http.post('/teams', this.team)
                .then(response => response.$data)
                .then(team => {
                    //Basically just add this team into the vuex store and send them to the dashboard
                    this.$store.dispatch('teams/add', team)
                    .then(() => this.$store.dispatch('teams/activate', team))
                    .then(() => {
                        this.$toasted.success('You have created a new team.');

                        if(this.redirect){
                            this.$router.push({name: 'team.dashboard', params: {team: team.alias}});
                        }

                        if(this.created){
                            this.created(team);
                        }

                        this.$emit('close');
                    });
                })
                .catch(error => this.$toasted.error('There was an error creating your team.'));
            },
            select_type(type){
                this.team.type = type;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .create-team
    {
        &.typed
        {
            h4
            {
                display: none;
            }

            padding-top: 20px;
        }
    }

    h4
    {
        font-weight: 500;
        padding: 10px;
        font-size: 14px;
        color: @grey;
    }

    form
    {
        padding: 0 20px 20px;

        button
        {
            margin-top: 20px;
            width: 100%;
        }
    }

    .team-type
    {
        border: 1px solid @d;
        padding: 15px;
        border-radius: 3px;
        text-transform: none;
        margin: 0;
        cursor: pointer;
        margin-top: 20px;

        &:focus-within, &:active
        {
            border-color: @base;
            box-shadow: 0 0 0 1px @base;
        }

        &:first-child
        {
            margin-top: 10px;
        }

        input.checkbox-input
        {
            background: @e;
            margin: 0 0 0 15px;
            border: 0;
            transition: background-color ease 0.2s;

            &::after
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                background: url('~@/assets/icons/white/check.svg') 1px 3px no-repeat;
                background-size: 18px auto;
                transform: scale(0);
                transition: all ease 0.15s;
                opacity: 0;
            }

            &:checked
            {
                background: @base;

                &::after
                {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }

    .team-title
    {
        line-height: 16px;
        font-size: 14px;
        color: @black;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .team-description
    {
        font-size: 12px;
        line-height: 14px;
        color: @grey;
        font-weight: 400;
    }
</style>
