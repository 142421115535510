<template>
    <div class="rule card">
        <div class="rule-header flex flex-align">
            <div>Validate</div>
            <!-- Entity select -->
            <select v-model="rule.entity" placeholder="Select entity..." :disabled="readonly">
                <option :value="''" hidden>Entity</option>
                <option :value="undefined" hidden>Entity</option>
                <option :value="null" hidden>Entity</option>
                <option v-for="(k, v) in entities" :value="v">{{ k }}</option>
            </select>
            <!-- Property select -->
            <select v-model="rule.property" placeholder="Select property..." :disabled="readonly">
                <option :value="undefined" hidden>Property</option>
                <option :value="null" hidden>Property</option>
                <template v-for="(v, k) in getProperties(rule.entity)">
                    <option v-if="v.path" :value="v.path">{{ v.title }}</option>
                    <option v-else :value="v.property">{{ v.title }}</option>
                </template>
            </select>
            <div>using</div>
            <!-- Definition select -->
            <select v-model="rule.definition" :disabled="readonly">
                <option v-for="(v, k) in definitions" :value="k">{{ v }}</option>
            </select>
            <div class="ff"></div>
            <div class="text-button red" @click="$emit('remove')" v-if="!readonly">Delete</div>
        </div>
        <div class="rule-logic flex flex-align ff">
            <!-- Definition config -->
            <template v-if="rule.definition === 'not_null'">
                <div>
                    If <span class="rule-property">{{ rule.property ? rule.property : 'Property' }}</span> is NULL
                </div>
            </template>
            <template v-else-if="rule.definition === 'email'">
                <div>
                    If <span class="rule-property">{{ rule.property ? rule.property : 'Property' }}</span> is not a valid email
                </div>
            </template>
            <template v-else-if="rule.definition === 'lynx'">
                <div>If</div>
                <input v-model="rule.function" class="ff" placeholder="Lynx Function" :disabled="readonly">
                <div>fails</div>
            </template>
            <template v-else-if="rule.definition === 'custom'">
                <div>If</div>
                <input v-model="rule.function" class="ff" placeholder="JS Function" :disabled="readonly">
                <div>fails</div>
            </template>
            <template v-else>
                <div>
                    If <span class="rule-property">{{ rule.property ? rule.property : 'Property' }}</span> fails validation
                </div>
            </template>
            <!-- Log select -->
            <div>log</div>
            <select v-model="rule.type" :disabled="readonly">
                <option value="info">Info</option>
                <option value="warning">Warning</option>
                <option value="error">Error</option>
            </select>
            <!-- Action -->
            <div>and</div>
            <select v-model="rule.action" :disabled="readonly">
                <option value="ignore">Do Nothing</option>
                <option value="remove">Delete</option>
                <option value="modify">Update</option>
            </select>
            <template v-if="rule.action === 'remove'">
                <div>
                    the <span class="rule-property">{{ rule.entity ? entities[rule.entity] : 'Entity' }}</span>
                </div>
            </template>
            <!-- Replace with default -->
            <template v-if="rule.action === 'modify'">
                <div>it to</div>
                <input v-model="rule.default_value" class="ff" placeholder="Default Value" :disabled="readonly">
            </template>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { entities, entity_properties } from '@/constants';

    export default {
        name: 'ValidationRule',
        props: {
            rule: Object,
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                definitions: {
                    not_null: 'Not Null',
                    email: 'Email',
                    lynx: 'Lynx',
                    custom: 'Custom'
                },
                entities: entities,
                properties: entity_properties
            }
        },
        methods: {
            getProperties(entity) {
                const excluded_properties = ['id', 'created_date', 'updated_date'];
                return this.properties[entity]?.filter((p) => !excluded_properties.includes(p.property)) ?? [];
            }
        },
        computed: {
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .rule
    {
        margin: 25px 0;

        &:first-child
        {
            margin-top: 0;
        }
    }

    .rule-header,
    .rule-logic
    {

        select,
        input
        {
            height: 25px;
            line-height: 15px;
            font-size: 13px;
            background-position: right 2px top 1px;
            background-size: 22px auto;
            color: @black;
            font-weight: 500;
            margin-left: 5px;
            display: inline-block;
        }

        &>div
        {
            margin-left: 5px;

            &:first-child
            {
                margin-left: 0;
            }
        }
    }

    .rule-header
    {
        padding: 10px 15px;
        color: @black;
        // font-weight: 500;
        background: @f8;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 1px solid @e4;
        font-size: 14px;

        select,
        input
        {
            max-width: 150px;
        }

        h4
        {
            font-size: 14px;
            font-weight: 500;
            color: @black;
        }

        .text-button
        {
            margin-left: 15px;
        }

        &:hover
        {
            .edit
            {
                opacity: 1;
            }
        }
    }

    .rule-logic
    {
        padding: 15px;
        font-size: 13px;
        color: @black;
        line-height: 20px;

        select
        {
        max-width: 120px;
    }
}

.rule-property
{
    color: @black;
    font-weight: 500;
    font-size: 13px;
    background-color: @e4;
    background-position: right 5px top 5px;
    border-radius: 3px;
    padding: 0 5px;
    width: fit-content;
    height: fit-content;
}
</style>
