<template>
    <div class="integrate-license-key">
        <h1>Enter Your {{ state.context.application.name }} License Key</h1>
        <input ref="license_key" type="text" placeholder="Enter your license key..." autocomplete="false" v-model="license_key" @keydown.enter="save" />
        <div class="options flex" key="license_key">
            <div class="button" kbd="Enter ⏎" @click="save" :class="{disabled: license_key_clean.length === 0}">Continue</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'IntegrateLicense',
        props: {
            state: Object,
            send: Function
        },
        data(){
            return {
                license_key: ''
            };
        },
        computed: {
            license_key_clean(){
                return this.license_key.trim();
            }
        },
        created(){
            this.$nextTick(() => this.$refs.license_key.focus());
        },
        methods: {
            async save(){
                const old_team = this.state.context.team;

                _.set(old_team, 'properties.roblox.license_key', this.license_key_clean);

                await this.$http.put(`/teams/${this.state.context.team.id}`, old_team);

                if(this.$parent.sources.length < 1) {
                    this.send('CREATE_SOURCE');
                } else {
                    this.send('SELECT_SOURCE');
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
