<template>
    <div class="entity-json">
        <pre class="card ff monospace" v-html="output" v-if="output"></pre>
    </div>
</template>

<script>
    import Prism from 'prismjs';

    export default {
        name: 'EntityJSON',
        props: {
            input_obj: Object
        },
        data(){
            return {
                output: ''
            };
        },
        created(){
            try {
                //Color and format the JSON that represents this user.
                this.output = this.input_obj ? Prism.highlight(JSON.stringify(this.input_obj, null, 4), Prism.languages.json, 'json') : null;
            } catch(error) {
                console.log(error);
            }
        },
        methods: {

        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            loading() {
                return this.$store.state.entities.loading;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .drawer-section
    {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
    }

    pre
    {
        font-size: 12px;
        overflow: auto;
        line-height: 16px;
        border: 0;
        border-radius: 0;
    }
</style>
