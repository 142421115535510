<template>
    <div class="integrate-source-create-configure-region flex flex-column">
        <div class="flex flex-align integrate-video" @click="$store.commit('integrate/video', 'data-sovereignty')">
            <span class="icon iconoir-media-video-list"></span> Watch: Data Sovereignty
        </div>
        <h1>Where Will Your Data Be Stored</h1>
        <div class="helptext">
            Edlink allows you to choose where your data will be stored in order to comply with local laws and institutional policies.
            This decision will not affect API performance. That is, storing data closer to you will not necessarily make Edlink faster.
        </div>
        <div class="flex flex-wrap region-options">
            <div class="card region-option flex flex-column flex-center" @click="send('UPDATE_SOURCE', {path: 'region_id', value: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49'})" :class="{selected: region_id === '8c5475f1-32d3-479d-8a6a-3c6b6e524f49'}">
                <div class="flag-icon flex flex-align flex-center">
                    <div class="flag-icon-wrapper">
                        <img src="/flags/us.svg" />
                    </div>
                </div>
                <div class="region-name text-overflow center">United States</div>
            </div>
            <div class="card region-option flex flex-column flex-center" @click="send('UPDATE_SOURCE', {path: 'region_id', value: '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd'})" :class="{selected: region_id === '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd'}">
                <div class="flag-icon flex flex-align flex-center">
                    <div class="flag-icon-wrapper">
                        <img src="/flags/ca.svg" />
                    </div>
                </div>
                <div class="region-name text-overflow center">Canada</div>
            </div>
            <div class="card region-option flex flex-column flex-center" @click="send('UPDATE_SOURCE', {path: 'region_id', value: '2572d321-6d8b-458b-8024-8e5ab6bad7b6'})" :class="{selected: region_id === '2572d321-6d8b-458b-8024-8e5ab6bad7b6'}">
                <div class="flag-icon flex flex-align flex-center">
                    <div class="flag-icon-wrapper">
                        <img src="/flags/de.svg" />
                    </div>
                </div>
                <div class="region-name text-overflow center">Germany</div>
            </div>
            <div class="card region-option flex flex-column flex-center" @click="send('UPDATE_SOURCE', {path: 'region_id', value: '2104f649-fe39-4fa1-96be-8682e9840dcf'})" :class="{selected: region_id === '2104f649-fe39-4fa1-96be-8682e9840dcf'}">
                <div class="flag-icon flex flex-align flex-center">
                    <div class="flag-icon-wrapper">
                        <img src="/flags/au.svg" />
                    </div>
                </div>
                <div class="region-name text-overflow center">Australia</div>
            </div>
            <div class="region-option placeholder none" v-for="i of [1, 2, 3, 4, 5]" :key="i"></div>
        </div>
        <div class="flex">
            <div class="button" @click="next" kbd="Enter ⏎">Confirm</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateSourceConfigureRegion',
        props: {
            state: Object,
            send: Function,
            type: String
        },
        mounted(){
            window.addEventListener('keydown', this.keydown);
        },
        destroyed(){
            window.removeEventListener('keydown', this.keydown);
        },
        computed:{
            user() {
                return this.$store.state.user;
            },
            region_id(){
                return this.type === 'destination' ? this.state.context.destination_source.region_id : this.state.context.source.region_id;
            },
            provider(){
                return this.type === 'destination' ? this.state.context.destination_provider : this.state.context.provider;
            }
        },
        methods: {
            next(){
                this.send('NEXT_STEP');
            },
            keydown(e){
                if(e.code === 'Enter'){
                    this.next();
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .integrate-source-create-configure-region
    {
        align-items: flex-start;
    }

    .region-options
    {
        justify-content: space-between;
        margin: 10px 0 25px;
    }

    .region-option
    {
        margin: 0 0 15px 0;
        width: 108px;
        height: 108px;
        cursor: pointer;
        user-select: none;
		border-color: fade(@black, 15%);
		border-bottom-color: fade(@black, 20%);
		box-shadow: 0 1px 4px 0 fade(@black, 8%);
        overflow: hidden;
        transition: all ease 0.2s;

        &::after
        {
            content: "";
            position: absolute;
            top: 6px;
            left: 7px;
            width: 11px;
            height: 11px;
            background: url('~@/assets/icons/base/check.svg');
            background-size: 15px auto;
            background-position: -2px 0;
            background-repeat: no-repeat;
            transition: all ease 0.15s;
            display: block;
            transform: scale(0);
            z-index: 2;
        }

        &.selected
        {
            color: @base;
            border-color: @base;
            border-bottom-color: darken(@base, 5%);
            box-shadow: 0 2px 8px -1px fade(@base, 25%);

            .region-name
            {
                color: @base;
            }

            &::after
            {
                transform: scale(1);
            }

            img
            {
                box-shadow: 0 2px 8px -1px fade(@black, 25%);
            }
        }

        &.placeholder
        {
            margin: 0;
            height: 0;
        }
    }

    .flag-icon
    {
        // pointer-events: none;

        img
        {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            transition: all ease 0.2s;
        }

        .flag-icon-wrapper
        {
            width: 44px;
            height: 64px;
            padding: 8px 0 12px;

            &::after
            {
                width: 44px;
                height: 44px;
                box-sizing: border-box;
                position: absolute;
                z-index: 2;
                content: "";
                top: 8px;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
    }

    .region-name
    {
        height: 18px;
        transition: all ease 0.2s;
        color: @black;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        padding: 0 10px;
    }
</style>
