import axios from 'axios';

const state = {
    active: null,
    updating: false
};

let throttle = null;

const mutations = {
    activate(state, source) {
        state.active = source;
    },
    deactivate() {
        state.active = null;
    },
    update(state, updated) {
        const valid_fields = ['name', 'import_interval', 'configuration', 'status', 'enrichment_provider', 'transformations', 'token', 'transformation_ids', 'properties'];

        for (const field of valid_fields) {
            if (updated.hasOwnProperty(field)) {
                state.active[field] = updated[field];
            }
        }
    },
    splice(state, transformation) {
        if (state.active) {
            // If this transformation is active, remove it from the config.
            const configuration = state.active.transformations.find((config) => config.id === transformation.id);

            if (configuration) {
                // Remove the transformation from the config array.
                state.active.transformations.splice(state.active.transformations.indexOf(configuration), 1);

                // Find any transformations that are dependent on this one and update their respective inputs.
                for (const t of state.active.transformations) {
                    if (t.type === 'enrich') {
                        if (t.primary === configuration.id) {
                            t.primary = configuration.input;
                        } else if (t.secondary === configuration.id) {
                            t.secondary = configuration.input;
                        }
                    } else if (t.input === configuration.id) {
                        t.input = configuration.input;
                    }
                }
            }
        }
    },
    after(state, { transformation, after }) {
        if (state.active) {
            console.log(transformation, after);
            // Find the config that accepts the input ID.
            for (const t of state.active.transformations) {
                if (t.primary === after) {
                    t.primary = transformation;
                } else if (t.secondary === after) {
                    t.secondary = transformation;
                } else if (t.input === after) {
                    t.input = transformation;
                }
            }

            // Push this new transformation into the configuration array.
            state.active.transformations.push({
                id: transformation,
                type: 'function',
                input: after
            });
        }

        console.log(JSON.stringify(state.active.transformations, null, 4));
    },
    before(state, { transformation, before }) {
        if (state.active) {
            const configuration = {
                id: transformation,
                type: 'function',
                input: null
            };

            // Find the config that accepts the input ID.
            // We can only end up with a "before" situation on enrichment's primary input.
            for (const t of state.active.transformations) {
                if (t.id === before) {
                    if (t.type === 'enrich') {
                        configuration.input = t.primary;
                        t.primary = transformation;
                    } else {
                        configuration.input = t.input;
                        t.input = transformation;
                    }
                }
            }

            // Push this new transformation into the configuration array.
            state.active.transformations.push(configuration);
        }
    }
};

const actions = {
    activate({ commit, state }, { team, source }) {
        if (state.active && state.active.id === source) {
            return;
        }

        return axios.get(`/teams/${team}/sources/${source}`).then((response) => commit('activate', response.$data));
    },
    deactivate({ commit }) {
        commit('deactivate');
    },
    update({ commit, state, dispatch }) {
        if (state.active) {
            if (throttle) {
                clearTimeout(throttle);
            }

            // Dispatch a change to our save service.
            dispatch('save/save', 'sources/update', { root: true });

            return new Promise((resolve, reject) => {
                throttle = setTimeout(() => {
                    throttle = null;

                    axios
                        .put(`/teams/${state.active.team.id}/sources/${state.active.id}`, state.active)
                        .then((response) => {
                            commit('update', response.$data);
                            resolve(response.$data);
                        })
                        .catch(reject)
                        .finally(() => dispatch('save/saved', 'sources/update', { root: true }));
                }, 1000);
            });
        } else {
            return Promise.reject('There is no active source.');
        }
    },
    destroy({ commit }) {
        if (state.active) {
            return axios.delete(`/teams/${state.active.team.id}/sources/${state.active.id}`).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active source.');
    },
    status({ commit }, status) {
        if (state.active) {
            return axios.put(`/teams/${state.active.team.id}/sources/${state.active.id}/status`, { status }).then((response) => commit('update', response.$data));
        }

        return Promise.reject('There is no active source.');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
