<template>
    <div class="reconnect-step login">
        <h1>Enter Your Email Address</h1>
        <input ref="email" class="large" type="text" placeholder="Enter your email address..." autocomplete="false" v-model="email" @keydown.enter="find" />
        <transition name="fade">
            <div class="options flex" key="email">
                <div class="button" kbd="Enter ⏎" @click="find" :class="{disabled: email.length === 0}">Continue</div>
            </div>
            <!-- <div class="options flex" key="sso" v-else>
                <div class="sso google text-button border">Sign In With Google</div>
                <div class="sso microsoft text-button border">Sign In With Microsoft</div>
            </div> -->
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'ReconnectEmail',
        data() {
            return {}
        },
        computed: {
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            email: {
                get(){
                    return this.$store.state.reconnect.login.email;
                },
                set(value){
                    this.$store.commit('reconnect/state', { path: 'login.email', value })
                }
            }
        },
        methods: {
            send(page){
                this.$store.commit('reconnect/page', page);
            },
            next(){
                this.$store.dispatch('reconnect/next');
            },
            find(){
                this.$store.commit('reconnect/loading', true);
                if(this.email.length < 3){
                    return this.$toasted.error('Please enter a valid email address.');
                }

                this.$http.post('/check', {
                    email: this.email
                })
                .then(response => {
                    //Send the user to their SSO provider or the password screen.
                    const {method, user_first_name} = response.$data;

                    if(method === 'email'){
                        this.$store.commit('reconnect/state', { path: 'first_name', value: user_first_name })
                        this.send('password');
                    }else{
                        this.send('sso');
                    }
                })
                .catch(error => {
                    //We could not find an account matching this email address.
                    this.send('register');

                    return Promise.resolve();
                })
                .finally(() => {
                    setTimeout(() => {
                        this.$store.commit('reconnect/loading', false);
                    }, 700);
                })
            }
        },
        mounted(){
            this.$refs.email.focus();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    h1
    {
        line-height: 50px;
    }

    .sso
    {
        background-repeat: no-repeat;
        margin-right: 10px;

        &.google
        {
            background-image: url('~@/assets/icons/source/google.svg');
            background-size: 18px;
            background-position: 9px 7px;
            padding-left: 34px;
        }

        &.microsoft
        {
            background-image: url('~@/assets/icons/source/microsoft.svg');
            background-size: 57px;
            background-position: -9px 7px;
            padding-left: 36px;
        }
    }
</style>
