<template>
    <div class="integrate flex flex-column">
        <div v-if="loading" class="integrate-loading flex flex-align flex-center ff">
            <spinner />
        </div>

        <template v-else>
            <header class="flex flex-align">
                <a class="flex flex-align wordmark" href="https://ed.link" target="_blank">
                    <div class="diamonds"></div>
                    <div class="letters"></div>
                </a>
                <div class="ff"></div>
                <div class="button has-icon" title="Chat With Us" @click="help" :class="{orange: hubspot.expanded, base: !hubspot.expanded, disabled: hubspot.loading}">
                    <spinner class="mini lightgrey" v-if="hubspot.loading" />
                    <AntennaSignal v-else-if="hubspot.expanded" class="icon" width="16" height="16" stroke-width="2" />
                    <MultiBubble v-else class="icon" width="16" height="16" stroke-width="2" />
                    {{ hubspot.expanded ? 'Chatting' : 'Live Chat Help' }}
                </div>
            </header>
            
            <div class="steps flex flex-column flex-center" v-if="!state.matches('error')">
                <div class="step" @click="send('APPLICATION')" :class="{ active: state.matches('application') }">Overview</div>
                <div class="step" @click="send('ACCOUNT')" :class="{ active: state.matches('account') }">Account</div>
                <div class="step" @click="send('DISTRICT')" :class="{ active: state.matches('district') }">District</div>
                <div class="step" @click="send('LICENSE')" :class="{ active: state.matches('license') }" v-if="license_key_required">License</div>
                <div class="step" @click="send('SOURCE')" :class="{ active: state.matches('source') }">Sources</div>
                <div class="step" @click="send('DESTINATION_SOURCE')" :class="{ active: state.matches('destination_source') }" v-if="state.context.destination_source_required">Destination</div>
                <div class="step" @click="send('INTEGRATION')" :class="{ active: state.matches('integration') }">Integration</div>
                <div class="step" @click="send('COMPLETE')" :class="{ active: state.matches('complete') }">Finished</div>
            </div>
            
            <main class="flex flex-column flex-align flex-center ff enhanced">
                <integrate-initializing class="integrate-state" v-if="state.matches('initializing')" :send="send" :state="state" />
                <integrate-initialization-error class="integrate-state" v-if="state.matches('error')" :send="send" :state="state" />
                <transition appear name="slide" :duration="400">
                    <integrate-application class="integrate-state" key="application" v-if="state.matches('application')" :send="send" :state="state" />
                    <integrate-account-email class="integrate-state" key="account-email" v-if="state.matches('account.email')" :send="send" :state="state" />
                    <integrate-account-login-password class="integrate-state" key="account-login-password" v-if="state.matches('account.login.password')" :send="send" :state="state" />
                    <integrate-account-login-two class="integrate-state" key="account-login-two" v-if="state.matches('account.login.two')" :send="send" :state="state" />
                    <integrate-account-sso class="integrate-state" key="account-sso" v-if="state.matches('account.sso')" :send="send" :state="state" />
                    <integrate-account-register-password class="integrate-state" key="account-register-password" v-if="state.matches('account.register.password')" :send="send" :state="state" />
                    <integrate-account-two-factor-setup class="integrate-state" key="account-account-two-factor-setup" v-if="state.matches('account.two.setup')" :send="send" :state="state" />
                    <integrate-account-two-factor-confirmation class="integrate-state" key="account-account-two-factor-confirmation" v-if="state.matches('account.two.confirmation')" :send="send" :state="state" />
                    <integrate-account-terms class="integrate-state" key="account-terms" v-if="state.matches('account.terms')" :send="send" :state="state" />
                    <integrate-account-invitations class="integrate-state" key="account-invitations" v-if="state.matches('account.invitations')" :send="send" :state="state" />
                    <integrate-district-select class="integrate-state" key="district-select" v-if="state.matches('district.select')" :send="send" :state="state" />
                    <integrate-district-create class="integrate-state" key="district-create" v-if="state.matches('district.create')" :send="send" :state="state" />
                    <integrate-district-claimed class="integrate-state" key="district-claimed" v-if="state.matches('district.claimed')" :send="send" :state="state" />
                    <integrate-license class="integrate-state" key="license" v-if="state.matches('license')" :send="send" :state="state" />
                    <integrate-source-select class="integrate-state" key="source-select" v-if="state.matches('source.select')" :send="send" :state="state" type="primary" />
                    <integrate-source-create-provider class="integrate-state" key="source-create-provider" v-if="state.matches('source.create.set_provider')" :send="send" :state="state" type="primary" />
                    <integrate-source-create-configure-region class="integrate-state" key="source-create-configure-region" v-if="state.matches('source.create.set_region')" :send="send" :state="state" type="primary" />
                    <integrate-source-editor class="integrate-state" key="source-editor" v-if="state.matches('source.create.edit_config')" :send="send" :state="state" :next-step="primary_source_editor_next_step" type="primary" />
                    <integrate-source-select class="integrate-state" key="destination-select" v-if="state.matches('destination_source.select')" :send="send" :state="state" type="destination" />
                    <integrate-source-create-provider class="integrate-state" key="destination-create-provider" v-if="state.matches('destination_source.create.set_provider')" :send="send" :state="state" type="destination" />
                    <integrate-source-create-configure-region class="integrate-state" key="destination-create-configure-region" v-if="state.matches('destination_source.create.set_region')" :send="send" :state="state" type="destination" />
                    <integrate-source-editor class="integrate-state" key="destination-editor" v-if="state.matches('destination_source.create.edit_config')" :send="send" :state="state" next-step="integration" type="destination" />
                    <integrate-enrich-prompt class="integrate-state" key="enrich-prompt" v-if="state.matches('source.enrich.prompt')" :send="send" :state="state" />
                    <integrate-source-select class="integrate-state" key="enrich-source-select" v-if="state.matches('source.enrich.select')" :send="send" :state="state" type="enrich" />
                    <integrate-sharing-rules class="integrate-state" key="sharing-rules" v-if="state.matches('integration.sharing.enable')" :send="send" :state="state" />
                    <integrate-complete class="integrate-state" key="complete" v-if="state.matches('complete')" :send="send" :state="state" />
                    <integrate-schedule class="integrate-state" key="schedule" v-if="state.matches('schedule')" :send="send" :state="state" />
                    <integrate-lti-setup class="integrate-state" key="lti-setup" v-if="state.matches('lti.setup')" :send="send" :state="state" />
                </transition>
            </main>

            <integration-configuration-help class="config-help flex flex-column flex-center" :provider="provider" v-if="state.matches('source.create.configure.keys')" />
            
            <div class="integration-configuration-video" v-if="video">
                <div class="flex flex-align video-options">
                    <div class="video-title ff">Help Video</div>
                    <!-- <div class="ff"></div> -->
                    <!-- <div class="text-button">Watch on YouTube</div> -->
                    <div class="text-button red" @click="$store.commit('integrate/video')">Close</div>
                </div>
                <iframe width="360" height="203" :src="video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </template>
    </div>
</template>

<script>
import { MultiBubble, AntennaSignal } from '@epiphany/iconoir';
import { useMachine } from 'xstate-vue2';
import { integrate } from '@/machines/integrate';

import IntegrateInitializing from '@/components/integrate/IntegrateInitializing.vue';
import IntegrateApplication from '@/components/integrate/IntegrateApplication.vue';
import IntegrateInitializationError from '@/components/integrate/IntegrateInitializationError.vue';
import IntegrateAccountEmail from '@/components/integrate/account/IntegrateAccountEmail.vue';
import IntegrateAccountSSO from '@/components/integrate/account/IntegrateAccountSSO.vue';
import IntegrateAccountLoginPassword from '@/components/integrate/account/IntegrateAccountLoginPassword.vue';
import IntegrateAccountRegisterPassword from '@/components/integrate/account/IntegrateAccountRegisterPassword.vue';
import IntegrateAccountTwoFactorSetup from '@/components/integrate/account/IntegrateAccountTwoFactorSetup.vue';
import IntegrateAccountTwoFactorConfirmation from '@/components/integrate/account/IntegrateAccountTwoFactorConfirmation.vue';
import IntegrateAccountTerms from '@/components/integrate/account/IntegrateAccountTerms.vue';
import IntegrateAccountInvitations from '@/components/integrate/account/IntegrateAccountInvitations.vue';
import IntegrateDistrictCreate from '@/components/integrate/district/IntegrateDistrictCreate.vue';
import IntegrateDistrictSelect from '@/components/integrate/district/IntegrateDistrictSelect.vue';
import IntegrateDistrictClaimed from '@/components/integrate/district/IntegrateDistrictClaimed.vue';
import IntegrateLicense from '@/components/integrate/IntegrateLicense.vue';
import IntegrateSourceSelect from '@/components/integrate/source/IntegrateSourceSelect.vue';
import IntegrateSourceCreateProvider from '@/components/integrate/source/IntegrateSourceCreateProvider.vue';
import IntegrateSourceCreateConfigureRegion from '@/components/integrate/source/IntegrateSourceCreateConfigureRegion.vue';
import IntegrateSourceEditor from '@/components/integrate/source/IntegrateSourceEditor.vue';
import IntegrateAccountLoginTwoFactor from '@/components/integrate/account/IntegrateAccountLoginTwoFactor.vue';
import IntegrateLicensing from '@/components/integrate/integration/IntegrateLicensing.vue';
import IntegrateEnrichPrompt from '@/components/integrate/source/IntegrateEnrichPrompt.vue';
import IntegrateDestinationPrompt from '@/components/integrate/flow/IntegrateDestinationPrompt.vue';
import IntegrateDestinationConfigure from '@/components/integrate/flow/IntegrateDestinationConfigure.vue';
import IntegrateSchedule from '@/components/integrate/integration/IntegrateSchedule.vue';
import IntegrateLTISetup from '@/components/integrate/integration/IntegrateLTISetup.vue';
import IntegrateComplete from '@/components/integrate/integration/IntegrateComplete.vue';

import { FLOW_APPLICATION_ID, ROBLOX_APPLICATION_IDS } from '@/constants';

export default {
    name: 'Integrate',
    components: {
        'integrate-initializing': IntegrateInitializing,
        'integrate-initialization-error': IntegrateInitializationError,
        'integrate-application': IntegrateApplication,
        'integrate-account-email': IntegrateAccountEmail,
        'integrate-account-sso': IntegrateAccountSSO,
        'integrate-account-login-password': IntegrateAccountLoginPassword,
        'integrate-account-login-two': IntegrateAccountLoginTwoFactor,
        'integrate-account-register-password': IntegrateAccountRegisterPassword,
        'integrate-account-two-factor-setup': IntegrateAccountTwoFactorSetup,
        'integrate-account-two-factor-confirmation': IntegrateAccountTwoFactorConfirmation,
        'integrate-account-terms': IntegrateAccountTerms,
        'integrate-account-invitations': IntegrateAccountInvitations,
        'integrate-district-select': IntegrateDistrictSelect,
        'integrate-district-create': IntegrateDistrictCreate,
        'integrate-district-claimed': IntegrateDistrictClaimed,
        'integrate-license': IntegrateLicense,
        'integrate-source-select': IntegrateSourceSelect,
        'integrate-source-create-provider': IntegrateSourceCreateProvider,
        'integrate-source-create-configure-region': IntegrateSourceCreateConfigureRegion,
        'integrate-source-editor': IntegrateSourceEditor,
        'integrate-sharing-rules': IntegrateLicensing,
        'integrate-enrich-prompt': IntegrateEnrichPrompt,
        'integrate-destination-prompt': IntegrateDestinationPrompt,
        'integrate-destination-configure': IntegrateDestinationConfigure,
        'integrate-schedule': IntegrateSchedule,
        'integrate-lti-setup': IntegrateLTISetup,
        'integrate-complete': IntegrateComplete,
        'integrate-source-editor': IntegrateSourceEditor,

        MultiBubble,
        AntennaSignal
    },
    setup() {
        const { state, send } = useMachine(integrate, {
            actions: {

            },
            services: {

            }
        });

        return {
            state,
            send
        };
    },
    async created() {
        this.loading = true;

        try {
            if(this.$route.name === 'integrate.onboarding'){
                await this.$store.dispatch('integrate/onboarding', this.$route.params.onboarding);
            }else{
                await this.$store.dispatch('integrate/application', this.$route.params.application);
            }

            await this.send({type: 'APPLICATION', data: this.$store.state.integrate.application});

            if (this.$route?.query?.rostering === 'essential') {
                this.state.context.essential = true;
            }
        }catch(error){
            if(error?.$error){
                await this.send({ type: 'ERROR', data: error.$error });
            }else{
                await this.send({ type: 'ERROR', data: `We're not exactly sure what went wrong.` });
            }
        }finally {
            this.loading = false;
        }
    },
    data() {
        return {
            loading: true,
            steps: ['overview', 'anonymous', 'two', 'authenticated', 'sources', 'connect', 'done'],
            step: 'overview',
            sources: [],
            authentication_form: 'register',
            error: {
                message: '',
                email: false,
                password: false
            },
            attempting: false,
            two: false,
            expanded: false,
            validating: false,
            source_type: 'primary', // this property gets set to "destination" in IntegrateSourceSelect.vue
            validation: [],
            features: [],
            registration_details: {
                user: {
                    email: '',
                    password: ''
                },
                organization: {
                    type: 'district',
                    name: '',
                    alias: ''
                }
            },
            login_details: {
                user: {
                    email: '',
                    password: '',
                    two: ''
                }
            }
        };
    },
    computed: {
        video() {
            return this.$store.state.integrate.video;
        },
        user() {
            return this.$store.state.user;
        },
        teams() {
            return this.$store.state.teams.all;
        },
        application() {
            return this.state.context.application;
        },
        provider() {
            return this.state.context.provider;
        },
        source() {
            return this.state.context.source;
        },
        primary_source() {
            return this.state.context.primary_source;
        },
        district() {
            return this.state.context.team;
        },
        enrichment_source() {
            return this.state.context.enrichment_source;
        },
        flow_source() {
            return this.state.context.flow_source;
        },
        integration() {
            return this.state.context.integration;
        },
        sorted() {
            return this.teams.slice()
                .filter(t => t.type === 'district')
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        providers() {
            return this.$store.getters.providers
                .filter(provider => {
                    if (!this.state.context.application) {
                        return true;
                    }

                    //Only return providers that this application supports.
                    if (!this.state.context.application.valid_source_providers.includes(provider.id)) {
                        return false;
                    }

                    // Remove internal providers.
                    if (['clever', 'classlink'].includes(provider.application)) {
                        return false;
                    }

                    return true;
                })
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        login_type() {
            return this.state.matches('source.create.configure.login') ? 'connect' : 'grant';
        },
        primary_source_editor_next_step() {
            return this.state.context.destination_source_required ? 'destination_source' : 'integration';
        },
        license_key_required(){
            return ROBLOX_APPLICATION_IDS.includes(this.state.context?.application?.id);
        },
        hubspot(){
            return this.$store.state.hubspot;
        }
    },
    methods: {
        help(){
            this.$store.dispatch('hubspot/toggle');
        },
        login() {
            this.$http.post('/login', this.login_details.user)
                .then(response => {
                    this.set_user(response.$data)
                    if (response.$data.token) {
                        this.$store.dispatch('user/login', response.$data.token).then(() => this.next());
                    } else if (response.$data.two) {
                        this.step = 'two';
                    }
                })
                .catch(error => this.$toasted.error(error.response.$data ? error.response.$data.details : 'There was an error logging you in.'));
        },
        register() {
            this.$http.post('/register', this.registration_details.user)
                .then(response => this.$store.dispatch('user/login', response.$data.token))
                .then(() => {
                    this.$http.post('/teams', this.registration_details.organization)
                        .then(response => {
                            this.$store.dispatch('teams/add', response.$data)
                                .then(() => this.$store.dispatch('teams/activate', response.$data))
                                .then(() => this.next());
                        })
                        .catch(error => this.$toasted.error(error.response.$data ? error.response.$data.details : 'There was an error creating your organization.'));
                })
                .catch(error => this.$toasted.error(error.response.$data ? error.response.$data.details : 'There was an error creating your account.'));
        },
        logout() {
            this.$store.dispatch('user/logout').then(() => this.step = 'anonymous');
        },
        next() {
            if (this.step === 'overview') {
                this.step = this.user.token ? 'authenticated' : 'anonymous';
            } else if (this.step === 'anonymous') {
                this.step = this.teams.length > 1 ? 'authenticated' : 'sources';
            }
        },
        set_source(source) {
            if (
                this.application.id === FLOW_APPLICATION_ID &&
                this.source_type === 'destination' &&
                source.region.id !== this.state.context.source.region?.id
            ) {
                return;
            }
            
            const event = {
                primary: 'SET_PRIMARY_SOURCE',
                'new': 'SET_PRIMARY_SOURCE',
                enrich: 'SELECT_ENRICH_SOURCE',
                destination: 'SET_DESTINATION_SOURCE'
            }
           
            let integration = {
                id: null,
                start_date: null,
                permissions: this.application.permissions.slice(),
                scope: 'all',
                application_id: this.state.context.application.id,
                target_id: null,
                targets: []
            };

            if (this.source_type === 'primary') {
                integration.source_id = source.id;
            }

            if (this.source_type === 'destination') {
                integration.source_id = this.state.context.source.id;
                integration.destination_id = source.id;
            }

            this.send(event[this.source_type], {
                data: {
                    source,
                    integration
                }
            });

            if (this.state.context.destination_source_required) {
                if (this.source_type === 'destination') {
                    this.send('INTEGRATION');
                } else {
                    this.send('DESTINATION_SOURCE');
                }
            } else {
                this.send('INTEGRATION');
            }
        },
        enrich_source() {
            this.$http.put(`/teams/${this.district.id}/sources/${this.primary_source.id}/enrich`, {
                source_id: this.enrichment_source ? this.enrichment_source.id : null
            })
                .catch(error => this.$toasted.error('There was an error adding enrichment to the source.'));
        },
        add_enrichment() {
            const new_link = {
                link_priority: 1,
                link_properties: {},
                primary_source_id: this.primary_source.id,
                secondary_source_id: this.enrichment_source.id
            };
            this.$http.post(`/teams/${team_id}/sources/${source_id}/links`, new_link)
                .then(() => { })
                .catch(error => this.$toasted.error('There was an error linking the sources.'));
        },
        create_sftp_creds() {
            this.$http.post(`/teams/${this.district.id}/sources/sftp/users`, {})
            .then(response => {
                this.send('UPDATE_SOURCE', {
                    path: 'configuration',
                    value: {
                        host: 'sftp.ed.link',
                        path: '/OneRoster',
                        port: '2022',
                        username: response.$data.username,
                        password: response.$data.password,
                    }
                });
            });
        },
        set_user(value) {
            this.send({ type: 'SET_USER', data: value });
        }
    }
}
</script>

<style lang="less">
@import "~@/assets/less/variables";
@import "~@/assets/less/mixins";

.config-help {
    position: fixed;
    pointer-events: all;
    top: 0;
    bottom: 0;
    right: 25px;
    z-index: 10;
    max-width: 30%;
}

.integrate
{
    width: 100vw;
    min-height: 100vh;
    
    h1
    {
        line-height: 44px;
    }

    header
    {
        z-index: 20;
        padding: 10px 15px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        .wordmark {
            height: 30px;
            pointer-events: all;

            .diamonds {
                background: url('~@/assets/edlink-diamonds.svg') center center no-repeat;
                background-size: 30px auto;
                color: transparent;
                width: 30px;
                height: 30px;
                margin-right: 12px;
            }

            .letters {
                background: url('~@/assets/edlink.svg') left center no-repeat;
                background-size: 75px auto;
                color: transparent;
                width: 150px;
                height: 30px;
            }
        }

        .text-button {
            margin-left: 20px;
        }

        .integrate-video {
            pointer-events: all;
        }
    }

    .steps {
        position: fixed;
        pointer-events: none;
        top: 0;
        bottom: 0;
        left: 25px;
        z-index: 10;

        .step {
            font-size: 12px;
            color: transparent;
            line-height: 26px;
            cursor: pointer;
            pointer-events: all;
            border-left: 2px solid lighten(@grey, 20%);
            margin-bottom: 10px;
            width: 120px;
            transition: all ease 0.2s;
            padding-left: 5px;

            &:hover,
            &:active {
                padding-left: 10px;
                color: @grey;
            }

            &.active,
            &.router-link-active {
                color: @black;
                padding-left: 10px;
                border-left-color: @base;
            }
        }

        &.done {
            .step {
                border-left-color: @grey;
            }
        }
    }

    .integrate-loading {
        width: 100vw;
        min-height: 100vh;
    }

    .integrate-state {
        width: 100%;
        max-width: 600px;
    }

    .integration-options {
        margin-top: 10px;
    }

    .integration-option {
        padding: 10px 0;
        cursor: pointer;
        font-size: 14px;
        color: @base;
        line-height: 18px;

        .icon
        {
            font-size: 20px;
            margin: 0 10px 0 0;
        }

        &:hover,
        &:active {
            .caret {
                .translate3d(6px, 0, 0);
            }
        }

        .caret {
            width: 14px;
            height: 14px;
            background: url('~@/assets/icons/grey/chevron-right.svg') -2px -1px no-repeat;
            background-size: 18px auto;
            display: block;
            content: "";
            margin-left: 20px;
            transition: all ease 0.2s;
        }

        .option-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: -4px 10px -4px 0;

            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }

            &::before {
                width: 24px;
                height: 24px;
                position: absolute;
                z-index: 2;
                content: "";
                top: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }

            .icon {
                color: @grey;
                font-size: 24px;
                display: inline-block;
                height: 24px;
                width: 24px;
            }
        }
    }

    .helptext {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: @grey;
        max-width: 550px;
        margin-bottom: 10px;
    }

    .integrate-video
    {
        font-weight: 400;
        font-size: 12px;
        color: @base;
        line-height: 24px;
        background: fade(@base, 20%);
        height: 24px;
        padding: 0 6px 0 10px;
        border-radius: 12px;
        transition: background-color ease 0.2s;
        cursor: pointer;

        .icon
        {
            font-size: 16px;
            margin-right: 4px;
        }

        &::after
        {
            width: 14px;
            height: 14px;
            background: url('~@/assets/icons/base/chevron-right.svg') -2px -1px no-repeat;
            background-size: 16px auto;
            display: block;
            content: "";
            margin-left: 5px;
            transition: all ease 0.2s;
        }

        &:hover, &:active
        {
            background: fade(@base, 30%);

            &::after
            {
                .translate3d(4px, 0, 0);
            }
        }
    }
}

main.enhanced
{
    padding: 0;
    z-index: 2;

    label {
        font-size: 16px;
        font-weight: 400;
        color: @black;
        letter-spacing: -0.01rem;
    }

    select {
        margin-top: 15px;
    }

    // input {
    //     font-size: 22px;
    //     font-weight: 400;
    //     color: @base;
    //     letter-spacing: -0.02rem;
    //     border: 0;
    //     padding: 0;
    //     outline: 0;

    //     &::placeholder {
    //         color: @c;
    //         opacity: 1;
    //     }

    //     &:-ms-input-placeholder {
    //         color: @c;
    //     }

    //     &::-ms-input-placeholder {
    //         color: @c;
    //     }

    //     &:focus {
    //         box-shadow: none;
    //     }
    // }

    .options {
        margin-top: 25px;
    }

    .integrate-radio, .integrate-radio-row
    {
        padding: 10px 12px;
        cursor: pointer;
        user-select: none;
        margin-right: 15px;
        font-size: 13px;
        font-weight: 500;
        color: @black;
        margin-bottom: 10px;

		background: @f;
		color: @black;
		border-color: fade(@black, 15%);
		border-bottom-color: fade(@black, 20%);
		box-shadow: 0 1px 4px 0 fade(@black, 8%);

        &:last-child
        {
            margin-bottom: 0;
        }

        &-row {
            margin-bottom: 0;
        }

        .icon
        {
            font-size: 20px;
            margin-right: 10px;
        }

        &.selected
        {
            // border-color: @base;
            // box-shadow: 0 0 0 1px @base;
            color: @base;
            border-color: @base;
            border-bottom-color: darken(@base, 5%);
            box-shadow: 0 2px 5px -1px fade(@base, 25%);
        }

        &.placeholder
        {
            margin: 0;
            height: 0;
        }
    }

    .integrate-input-help
    {
        margin-left: 10px;
    }
}

.integration-configuration-video
{
    position: fixed;
    bottom: 40px;
    right: 40px;
    border-radius: 8px;
    background: @f;
    width: 360px;
    height: 243px;
    z-index: 100;
    box-shadow: 0 2px 20px -2px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    .video-title
    {
        font-size: 14px;
        font-weight: 500;
        color: @black;
    }

    .video-options
    {
        height: 40px;
        z-index: 2;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        padding: 0 8px 0 10px;
        margin: 0 1px;

        .text-button
        {
            margin-left: 8px;
        }
    }

    iframe
    {
        border: 0;
    }

    &::after
    {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        content: "";
        top: 0;
        left: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        pointer-events: none;
    }
}
</style>