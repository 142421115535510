<template>
    <div class="integrate-source-provider-schoology">
        <integrate-source-editor-step name="Enter Your Bromcom URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your Bromcom server is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.bromcom.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
                <!-- <checkbox class="skip-custom-url" label="No Custom URL" :checked.sync="url" @input="() => send('UPDATE_SOURCE', {path: 'configuration.url', value: ''})" /> -->
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Create Login" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to create a login for Edlink to access your Bromcom server.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                    click <a class="configuration-link inline-block text-button mini" target="_blank">here</a>.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Input the Login You Created" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <div class="helptext">
                The username that you have created for Edlink to access the server.
                The password that you have created for Edlink to access the server.
                Something about the Site ID
            </div>
            <label class="key-label">Username</label>
            <input class="medium" type="text" placeholder="Username" :value="source.configuration.username" @input="e => send('UPDATE_SOURCE', {path: 'configuration.username', value: e.target.value})" />
            <label class="key-label">Password</label>
            <input class="medium client-secret" type="text" placeholder="Password" :value="source.configuration.password" @input="e => send('UPDATE_SOURCE', {path: 'configuration.password', value: e.target.value})" />
            <label class="key-label">Site ID</label>
            <input class="medium" type="text" placeholder="Site ID" :value="source.configuration.site_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.site_id', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderBromcom',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(this.url && !this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Confirm remote configuration is completed.
                if(!this.remote){
                    return ['url'];
                }

                // 3. Confirm that keys are set
                if(!this.source.configuration.username?.trim().length || !this.source.configuration.password?.trim().length || !this.source.configuration.site_id?.trim().length){
                    return ['url', 'remote'];
                }

                return ['url', 'remote', 'keys'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                url: true,
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }
</style>
