<template>
    <main class="admin-people">
        <header class="flex flex-align">
            <h1>People</h1>
            <div class="ff"></div>
            <input class="filter search medium" type="text" placeholder="Search People" v-model="search"
                @keydown.enter="go" />
        </header>
       
       <!--
       <ul>
            <li>Info about their source</li>
            <li>List of integrations they're a part of</li>
            <li>List of integrations their district is a part of, but they're not shared in</li>
            <li>Secondary source matches</li>
            <li>API requests made</li>
            <li>Last login date</li>
            <li>Changes to their person entity</li>
            <li>Source overrides</li>
            <li>Link to the most recent sync that changed this person</li>
        </ul>
-->
        <h2>Enter a person Id or email to fetch data about them</h2>
        <br>
        <div v-for="(person, index) of people" class="summary">
            <div class="summary-field flex flex-align" @click="redirect(person.id)">
                <div class="summary-key">Person Id</div>
                <div class="summary-value">{{ person.id }}</div>
            </div>
            <div class="summary-field flex flex-align" @click="redirect(person.id)">
                <div class="summary-key">Display Name</div>
                <div class="summary-value">{{ person.display_name }}</div>
            </div>
            <div class="summary-field flex flex-align" @click="redirect(person.id)">
                <div class="summary-key">Source Id</div>
                <div class="summary-value">{{ person.source.id }}</div>
            </div>
            <br>
            <hr>

        </div>

<h4> If you enter an email and there are multiple results, click on the text of the one you would like to view</h4>

    </main>
</template>

<script>
export default {
    name: 'AdminPeople',
    data() {
        return {
            people: [],
            search: '',
            results: []
        };
    },
    methods: {
        go() {
            const params = {};

            if (this.search) {
                params.$filter = {
                    team_name: [{ operator: "contains", value: this.search }],
                };
            } else {
                params.$last = 100;
            }
            const search_input = params.$filter.team_name[0].value;

            console.log("in load(), params - ", search_input); //this line works
            console.log("string length = ", search_input.length);

            if (search_input.includes("@")) {
                console.log("in the is email section");
                this.$http
                    .post(`/admin/people/`, { email: search_input })
                    .then((response) => {
                        if (response.$data.length === 1) {
                            this.$router.push(`/admin/people/${response.$data[0].id}`)
                        } else if (response.$data.length > 1) {
                            this.people = response.$data;
                            console.log('this.person', this.people);
                        } else {
                            this.$toasted.error("Email not found!");
                        }
                    })
                    .catch((error) => {
                        this.$toasted.error("Failed to load person.");
                    });
            } else if (search_input.length === 36) {
                this.$router.push(`/admin/people/${search_input}`);
            } else {

                this.$toasted.error("Bad input");

            }
        },       
        redirect(id_num) {
            this.$router.push(`/admin/people/${id_num}`);
        }

    },
    computed: {
        verified() {
            return this.$store.state.user ? this.$store.state.user.email_verified : false;
        },
        user() {
            return this.$store.state.user;
        },
        name() {
            if (this.user.first_name) {
                return this.user.last_name ? this.user.first_name + ' ' + this.user.last_name : this.user.first_name;
            } else if (this.user.last_name) {
                return this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.last_name;
            } else {
                return 'Anonymous User';
            }
        }
    },
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";


h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }

    &:first-child {
        .section-content {
            border-top: 0;
        }
    }

    .documentation {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;

        &.monospace {
            line-height: 22px;
            font-size: 13px;
        }

        .flag-icon {
            width: 12px;
            height: 12px;
            margin-right: 8px;
        }
    }
}

h4 {
    color: @black;
    font-size: 15px;
    margin-bottom: 6px;
}

.disconnect-warning {
    font-size: 13px;
    line-height: 18px;
    color: @grey;
    max-width: 700px;
}

.launch-url {
    font-weight: 400;
}

.icon-status {
    margin-right: 8px;
}

.source-application {
    margin-left: 8px;
    padding-left: 23px;
    background-image: url('~@/assets/icons/base/link.svg');
    background-size: auto 22px;
    background-position: -6px 2px;
    background-repeat: no-repeat;
    font-weight: 400;
}


header {
    border-bottom: 1px solid @e4;
    height: 70px;
}

.button-item {
    margin-top: 1em;
    margin-left: 0.5em;
    display: inline-block;
}

.ff {
    padding: 15px;
}

.data-list {
    //color: red;
    font-size: 1.25em;
}

.list-item {
    //padding: 5px;
    margin-top: 0.5em;
}
</style>
