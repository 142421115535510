<template>
    <div class="integrate-source-provider-classgather">
        <integrate-source-editor-step name="Enter Your ClassGather URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your ClassGather server is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.classgather.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Generate OneRoster Keys" icon="iconoir-key-alt-plus" :complete="completed.includes('remote')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                    click <a class="configuration-link inline-block text-button mini" target="_blank" href="https://ed.link/docs/schools/connect/oneroster">here</a>.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <div class="helptext">
                The Client ID can be found in the "Details" column and typically starts with the number 1 followed by a bunch of zeros.
                The Secret Key can be found in the same column by clicking the "Show Key" button.
            </div>
            <label class="key-label">Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.username" @input="e => send('UPDATE_SOURCE', {path: 'configuration.username', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.password" @input="e => send('UPDATE_SOURCE', {path: 'configuration.password', value: e.target.value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderClassGather',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url || !this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Confirm remote configuration is completed.
                if(!this.remote){
                    return ['url'];
                }

                // 3. Confirm that keys are set
                if(!this.source.configuration.username?.trim().length || !this.source.configuration.password?.trim().length){
                    return ['url', 'remote'];
                }
                

                return ['url', 'remote', 'keys'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 3);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
