<template>
    <div class="command-palette-person flex flex-align">
        <span class="block icon iconoir-combine"></span>
        <div class="ff">
            <div class="result-title">{{data.team.name}}</div>
            <div class="result-description flex flex-align">
                <img class="provider-icon block" :src="provider.icon_url" />
                <div class="ff">Integrated with {{data.application.name}}</div>
            </div>
        </div>
        <div class="kbd" v-if="selected">Enter ⏎</div>
    </div>
</template>

<script>
    export default {
        name: 'CommandPaletteIntegration',
        props: {
            index: Number,
            data: Object,
            selected: Boolean
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            provider(){
                return this.$store.state.providers.all[this.data.provider.id];
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .provider-icon
    {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 4px;
        box-shadow: 0 0 0 1px fade(@black, 50%);
    }
</style>
