<template>
    <div class="oneroster-oauth-steps">
        <integrate-source-editor-step 
            name="Authentication Method" 
            icon="iconoir-key-alt-plus" 
            :complete="completed.includes('version')">
            <div class="helptext">
                What type of authentication did you select in the previous step?
            </div>
            <div class="flex flex-row sftp-options">
                <!-- div class="card integrate-radio-row flex flex-align" :class="{selected: version === 'basic'}" @click="auth('basic')">
                    <div class="ff">
                        Basic Auth
                    </div>
                </div -->
                <div class="card integrate-radio-row flex flex-align" :class="{selected: version === '1.0'}" @click="auth('1.0')">
                    <div class="ff">
                        OAuth 1.0
                    </div>
                </div>
                <div class="card integrate-radio-row flex flex-align" :class="{selected: version === '2.0'}" @click="auth('2.0')">
                    <div class="ff">
                        OAuth 2.0
                    </div>
                </div>
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step v-if="!version" :loading="true" />

        <integrate-source-editor-step v-else 
            name="Enter the OneRoster Details You Generated" 
            icon="iconoir-password-cursor" 
            :complete="completed.includes('keys')">
            <div class="helptext">
                Enter the OneRoster keys you generated and server information.
            </div>
            <template v-if="version === 'basic'"> <!-- basic auth inputs here -->
            </template>
            <template v-else> <!-- 1.0 & 2.0 inputs here -->
                <label class="key-label">Client ID</label>
                <input type="text" 
                    class="medium" 
                    placeholder="Client ID" 
                    :value="source.configuration.oneroster_client_id" 
                    @input="e => send('UPDATE_SOURCE', { path: 'configuration.oneroster_client_id', value: e.target.value })" />
                <label class="key-label">Client Secret</label>
                <input type="text"
                    class="medium client-secret"  
                    placeholder="Client Secret" 
                    :value="source.configuration.oneroster_client_secret" 
                    @input="e => send('UPDATE_SOURCE', { path: 'configuration.oneroster_client_secret', value: e.target.value })" />
                <label class="key-label">Base URL</label>
                <input type="text"
                    class="large base-url"     
                    placeholder="Base URL" 
                    :value="source.configuration.oneroster_server_url" 
                    @input="e => send('UPDATE_SOURCE', { path: 'configuration.oneroster_server_url', value: e.target.value })" />
                
                <template v-if="version === '1.0'">
                </template>
                
                <template v-if="version === '2.0'">
                    <label class="key-label">Token URL</label>
                    <input type="text"
                        class="large token-url"
                        placeholder="Token URL"
                        :value="source.configuration.oauth_token_url"
                        @input="e => send('UPDATE_SOURCE', { path: 'configuration.oauth_token_url', value: e.target.value })" />
                </template>
            </template>
        </integrate-source-editor-step>

        <integrate-source-editor-step name="Select Timezone" icon="iconoir-clock-outline" :complete="completed.includes('timezone')">
            <div class="helptext">
                Select the timezone that your district is in.
            </div>
            <select class="medium" :value="source.configuration.timezone" @input="e => send('UPDATE_SOURCE', { path: 'configuration.timezone', value: e.target.value })">
                <option :value="undefined" hidden>Select a timezone</option>
                <option :value="null" hidden>Select a timezone</option>
                <option v-for="(key, value) of timezones" :value="key" :key="key">{{ value }}</option>
            </select>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import { timezone_identifiers } from '@/enums';

    export default {
        name: 'OneRosterOAuthSteps',
        props: {
            completed: Array,
            state: Object,
            send: Function,
            type: String
        },
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep
        },
        computed: {
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            version(){
                return this.source.configuration.version;
            },
        },
        data() {
            return {
                timezones: timezone_identifiers
            }
        },
        methods: {
            auth(mode){
                if (this.version === '1.0'){
                    this.send('UPDATE_SOURCE', {
                        path: 'configuration.oauth_token_url',
                        value: ''
                    });
                }
                this.send('UPDATE_SOURCE', {
                    path: 'configuration.version',
                    value: mode
                });
            }
        }
    }
</script>

