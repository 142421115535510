<template>
    <div class="integrate-source-provider-blackbaud">
        <integrate-source-editor-step name="Enable Assignment & Grade Sync" icon="iconoir-key-alt-plus" :complete="completed.includes('form_select')">
            <div class="helptext">
                Do you want to allow assignments and grades to be synced to Blackbaud?
            </div>
            <div class="flex flex-row sftp-options">
                <div class="card integrate-radio-row flex flex-align" :class="{selected: show_one_roster_settings}" @click="showFullForm(true)">
                    <div class="ff">
                        Yes
                    </div>
                </div>
                <div class="card integrate-radio-row flex flex-align" :class="{selected: show_one_roster_settings === false}" @click="showFullForm(false)">
                    <div class="ff">
                        No
                    </div>
                </div>
            </div>
        </integrate-source-editor-step>


        <integrate-source-editor-step name="Add the Edlink Application in the Blackbaud Marketplace" icon="iconoir-box-iso" :complete="completed.includes('add_application')">
            <integrate-source-editor-configuration title="Add the Edlink Application" icon="iconoir-info-empty">
                <div class="image-label flex flex-align">
                    <label>1. Go to the Blackbaud Marketplace:&nbsp;</label>
                    <a href="https://app.blackbaud.com/marketplace" target="_blank">https://app.blackbaud.com/marketplace</a>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>2. Click on <code>Manage</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/blackbaud/onboarding/manage.png', { title: 'Click Manage' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>3. Click on <code>Connect app</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/blackbaud/onboarding/click_connect_app.png', { title: 'Click Connect app' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>4. Enter the Edlink Application ID:&nbsp;<span class="badge monospace copyable"  v-clipboard="() => '9fc0ae52-978a-4d6c-a361-508ea012ce85'" v-clipboard:success="() => $toasted.info('Copied')" >9fc0ae52-978a-4d6c-a361-508ea012ce85</span></label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/blackbaud/onboarding/enter_application_id.png', { title: 'Enter the Edlink Application ID' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
                <div class="image-label flex flex-align flex-wrap">
                    <label>5. Click on <code>Connect</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/blackbaud/onboarding/click_connect.png', { title: 'Click Connect' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>                
                <div class="image-label flex flex-align flex-wrap">
                    <label>6. Click on <code>Close</code>.</label>
                    <div class="integrate-input-help text-button mini has-icon" @click="image('https://edlink.github.io/docs/media/images/blackbaud/onboarding/now_connected.png', { title: 'Click Close' })">
                        <span class="icon iconoir-info-empty block"></span>
                        <div>See a Picture</div>
                    </div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="add_application" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step v-if="loading" :loading="true" />

        <template v-else>
            <integrate-source-editor-step v-if="show_one_roster_settings" name="Provide Your OneRoster OAuth 2.0 Configuration Settings" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
                <div class="helptext">
                    Enter the keys
                </div>
                <label class="key-label">Client ID</label>
                <input type="text" class="medium" placeholder="Client ID" :value="source.configuration.oneroster_client_id" @input="e => send('UPDATE_SOURCE', { path: 'configuration.oneroster_client_id', value: e.target.value })" />
                <label class="key-label">Client Secret</label>
                <input type="text" class="medium client-secret" placeholder="Client Secret" :value="source.configuration.oneroster_client_secret" @input="e => send('UPDATE_SOURCE', { path: 'configuration.oneroster_client_secret', value: e.target.value })" />
            </integrate-source-editor-step>

            <integrate-source-editor-step name="Connect Your Blackbaud Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
                <div class="helptext">
                    Sign into your Blackbaud account to complete the connection process.
                </div>
                <!-- This component can spoof having a value in the event the token has already been exchanged. -->
                <token :write="destination" :configuration="source.configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
            </integrate-source-editor-step>
        </template>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import HelpImage from '@/components/modals/HelpImage.vue';

    export default {
        name: 'IntegrateSourceProviderBlackbaud',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination'
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(typeof this.show_one_roster_settings !== 'boolean'){
                    return [];
                }

                if(!this.add_application){
                    return ['form_select' ];
                }

                if(this.show_one_roster_settings) {
                    if(!this.source.configuration.oneroster_client_id?.trim().length || !this.source.configuration.oneroster_client_secret?.trim().length){
                        return ['form_select','add_application'];
                    }
                }

                // 2. Validate that the token has been acquired.
                if(!this.source.token && !this.source.id){
                    return this.show_one_roster_settings ? ['form_select', 'keys','add_application'] : ['form_select','add_application'];
                }
                
                return this.show_one_roster_settings ? ['form_select', 'keys', 'token', 'add_application'] : ['form_select', 'token', 'add_application'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === (this.show_one_roster_settings ? 4 : 3));
            }
        },
        data(){
            return {
                loading: true,
                show_one_roster_settings: null,
                add_application: false,
                url: true
            };
        },
        methods: {
            image(src, config = { title: null, width: 700, height: 'auto' }){
                this.$modal.show(HelpImage, { src, title: config.title }, { width: config.width ?? 700, height: config.height ?? 'auto', classes: 'modal' });
            },
            showFullForm(answer) {
                this.show_one_roster_settings = answer;
                this.loading = false;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    label.checkbox.skip-custom-url
    {
        margin-left: 15px;
    }

    .badge
    {
        border-radius: 3px;
        padding: 2px 4px;
        color: @black;
        background: fade(@black, 10%);
        font-weight: 400;
    }
</style>
