<template>
    <div class="reset">
        <div class="reset-container constrain bc flex flex-align flex-column">
            <h3>Reset Your Password</h3>
            <form class="card" @submit.prevent="reset" autocomplete="off">
                <label for="password">Create Password</label>
                <input type="password" v-model="user.password" name="password" autocomplete="off" :class="{error: error.password}" />
                <label for="confirmation">Confirm Password</label>
                <input type="password" v-model="user.confirmation" name="confirmation" autocomplete="off" :class="{error: error.confirmation}" />
                <div class="form-error center" v-if="error.message">{{error.message}}</div>
                <button type="submit">Reset Password</button>
            </form>
            <div class="form-disclaimer">For additional help, please <a href="/support" class="dashed-button">contact us</a>.</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Reset',
        data(){
            return {
                error: {
                    message: '',
                    confirmation: false,
                    password: false
                },
                user: {
                    confirmation: '',
                    password: ''
                }
            };
        },
        methods: {
            reset(){
                this.error.password = false;
                this.error.confirmation = false;
                this.error.message = '';

                if(!this.user.password){
                    return this.error.password = true;
                }

                if(!this.user.confirmation){
                    return this.error.confirmation = true;
                }

                if(this.user.confirmation !== this.user.password){
                    this.error.message = 'Passwords do not match.';

                    return this.error.confirmation = true;
                }

                this.$http.post('/reset', {
                    password: this.user.password,
                    code: this.$route.params.code
                })
                .then(() => this.$toasted.success('Your password has been successfully reset.'))
                .then(() => this.$router.push({name: 'home'}))
                .catch(error => this.$toasted.error('There was an error resetting your password.'));
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";


    .reset
    {
        min-height: 100vh;
    }

    .reset-container
    {
        padding: 80px 50px;
    }

    form
    {
        width: 100%;
        max-width: 400px;
        padding: 30px;
        margin-top: 30px;

        input
        {
            margin-bottom: 10px;
        }

        button
        {
            width: 100%;
            margin-top: 20px;
        }
    }

    .disclaimer
    {
        padding: 30px 50px;
        max-width: 400px;
        font-size: 12px;
        text-align: center;
        line-height: 16px;

        a
        {
            border-bottom: 1px dashed @black;
            color: @black;
        }
    }
</style>