<template>
    <div class="integrate-lti-setup">
    
        <label class="center">Would you like us to configure LTI for you?</label>


        <div class="flex flex-center">
            <div class="button" @click="" kbd="Enter ⏎">Confirm</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegrateLTISetup',
        props: {
            state: Object,
            send: Function
        },
        created(){
            
        },
        methods: {
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>