<template>
    <div class="requests">
        <template v-if="requests.length">
            <div class="row flex flex-align">
                <!-- <td>{{request.id}}</td> -->
                <div class="cell request-status">Status</div>
                <div class="cell request-method">Method</div>
                <div class="cell ff text-overflow">Path</div>
            </div>
            <div class="row request flex flex-align" v-for="request in requests" :key="request.id"
                @click="go_to(request.id)">
                <!-- <td>{{request.id}}</td> -->
                <div class="cell request-status">{{request.status}}</div>
                <div class="cell request-method">{{request.method}}</div>
                <div class="cell ff text-overflow">{{request.path}}</div>
                <div class="cell" @click="close">
                    <router-link :to="{ name: 'team.logs.request', params: { request: request.id } }" tag="div"
                        class="text-button mini view-button">
                        View
                    </router-link>
                </div>
            </div>
        </template>
        <div class="nothing" v-else>
            No requests found.
        </div>
    </div>
</template>

<script>
import Request from '@/components/drawers/Request.vue';

export default {
    name: 'EntityRequests',
    data() {
        return {
            requests: []
        }
    },
    created() {
        this.getRequests();
    },
    methods: {
        getRequests() {
            console.log(this.integration)

            const url = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:9900';

            this.$http.get(`${url}/api/v2/graph/requests`, {
                params: {
                    $filter: {
                        person_id: [{
                            operator: 'equals',
                            value: this.entity.id
                        }]
                    },
                    $first: 250,
                    $fields: ['id', 'status', 'method', 'path'].join(',')
                },
                headers: {
                    Authorization: `Bearer ${this.integration.access_token}`
                }
            })
                .then(response => {
                    this.requests = response.$data;
                })
                .catch(err => {
                    console.log(err);
                    this.$toasted.error(`Unable to retrieve requests.`);
                });
        },
        go_to(id) {
            this.$store.dispatch('drawer/loading');
            this.$store.dispatch('entities/activate', {
                id: id,
                type: 'requests',
                integration: this.integration
            })
                .then(() => {
                    this.$store.dispatch('drawer/replace', {
                        key: `entity/${id}`,
                        width: 800,
                        component: Request
                    }).then(() => {
                        this.$store.dispatch('drawer/ready');
                    });
                });
        },
        close() {
            this.$store.dispatch('drawer/close');
        }
    },
    computed: {
        team() {
            return this.$store.getters.team;
        },
        loading() {
            return this.$store.state.entities.loading;
        },
        entity() {
            return this.$store.state.entities.active;
        },
        type() {
            return this.$store.state.entities.type;
        },
        integration() {
            return this.$store.state.entities.integration;
        },
        source() {
            return this.$store.state.entities.source;
        },
        parent() {
            return this.integration ? this.integration : this.source;
        },
        title() {
            return this.type === 'people' ? this.entity.display_name : this.entity.name;
        }
    }
}
</script>

<style scoped lang="less">
@import "~@/assets/less/variables";

.requests
{
    padding: 0 20px !important;
    color: @black;
    font-size: 12px;
}

.request
{
    cursor: pointer;

    &:hover
    {
        background-color: @f8;
    }

    .view-button
    {
        margin-right: 5px;
    }
}

.request-status,
.request-method
{
    width: 50px;
}

.row
{
    // border-top: 1px solid @e4;
    border-bottom: 1px solid @e4;
    // padding: 10px 0;
    height: 30px;

    .cell
    {
        margin: 0 5px;

        &:first-child
        {
            margin-left: 0;
        }

        &:last-child
        {
            margin-right: 0;
        }
    }
}

.nothing
{
    padding: 40px 0;
    text-align: center;
}
</style>
