<template>
    <div class="entity-value entity-value-status flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="max" />
            <!-- <input type="text" class="block editable-input" v-model="override" @keyup.enter="save" @keyup.esc="cancel" ref="input" /> -->
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="value">
                <template v-if="array">
                    <template v-if="value.length">
                        <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{langs[item]}}</div>
                    </template>
                    <div v-else class="empty">&mdash;</div>
                </template>
                <div v-else class="badge text-overflow">{{langs[value]}}</div>
            </template>
            <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
            <div v-else class="empty">&mdash;</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueLanguage',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                langs: {
                    'aar':'Afar',
                    'abk':'Abkhaz',
                    'ave':'Avestan',
                    'afr':'Afrikaans',
                    'aka':'Akan',
                    'amh':'Amharic',
                    'arg':'Aragonese',
                    'ara':'Arabic',
                    'asm':'Assamese',
                    'ava':'Avaric',
                    'aym':'Aymara',
                    'aze':'Azerbaijani',
                    'azb':'SouthAzerbaijani',
                    'bak':'Bashkir',
                    'bel':'Belarusian',
                    'bul':'Bulgarian',
                    'bih':'Bihari',
                    'bis':'Bislama',
                    'bam':'Bambara',
                    'ben':'Bengali',
                    'tib':'TibetanStandard',
                    'bre':'Breton',
                    'bos':'Bosnian',
                    'cat':'Catalan',
                    'che':'Chechen',
                    'cha':'Chamorro',
                    'cos':'Corsican',
                    'cre':'Cree',
                    'cze':'Czech',
                    'chu':'ChurchSlavonic',
                    'chv':'Chuvash',
                    'wel':'Welsh',
                    'dan':'Danish',
                    'ger':'German',
                    'div':'Divehi',
                    'dzo':'Dzongkha',
                    'ewe':'Ewe',
                    'gre':'Greek',
                    'eng':'English',
                    'epo':'Esperanto',
                    'spa':'Spanish',
                    'est':'Estonian',
                    'baq':'Basque',
                    'per':'Persian',
                    'ful':'Fula',
                    'fin':'Finnish',
                    'fij':'Fijian',
                    'fao':'Faroese',
                    'fre':'French',
                    'fry':'WesternFrisian',
                    'gle':'Irish',
                    'gla':'Gaelic',
                    'glg':'Galician',
                    'grn':'Guaran',
                    'guj':'Gujarati',
                    'glv':'Manx',
                    'hau':'Hausa',
                    'heb':'Hebrew',
                    'hin':'Hindi',
                    'hmo':'HiriMotu',
                    'hrv':'Croatian',
                    'hat':'Haitian',
                    'hun':'Hungarian',
                    'arm':'Armenian',
                    'her':'Herero',
                    'ina':'Interlingua',
                    'ind':'Indonesian',
                    'ile':'Interlingue',
                    'ibo':'Igbo',
                    'iii':'Nuosu',
                    'ipk':'Inupiaq',
                    'ido':'Ido',
                    'ice':'Icelandic',
                    'ita':'Italian',
                    'iku':'Inuktitut',
                    'jpn':'Japanese',
                    'jav':'Javanese',
                    'geo':'Georgian',
                    'kon':'Kongo',
                    'kik':'Kikuyu',
                    'kua':'Kuanyama',
                    'kaz':'Kazakh',
                    'kal':'Kalaallisut',
                    'khm':'Khmer',
                    'kan':'Kannada',
                    'kor':'Korean',
                    'kau':'Kanuri',
                    'kas':'Kashmiri',
                    'kur':'Kurdish',
                    'kom':'Komi',
                    'cor':'Cornish',
                    'kir':'Kyrgyz',
                    'lat':'Latin',
                    'ltz':'Luxembourgish',
                    'lug':'Ganda',
                    'lim':'Limburgish',
                    'lin':'Lingala',
                    'lao':'Lao',
                    'lit':'Lithuanian',
                    'lub':'LubaKatanga',
                    'lav':'Latvian',
                    'mlg':'Malagasy',
                    'mah':'Marshallese',
                    'mao':'Maori',
                    'mac':'Macedonian',
                    'mal':'Malayalam',
                    'mon':'Mongolian',
                    'mar':'Marathi',
                    'may':'Malay',
                    'mlt':'Maltese',
                    'bur':'Burmese',
                    'nau':'Nauru',
                    'nob':'NorwegianBokmal',
                    'nde':'NorthNdebele',
                    'nep':'Nepali',
                    'ndo':'Ndonga',
                    'dut':'Dutch',
                    'nno':'NorwegianNynorsk',
                    'nor':'Norwegian',
                    'nbl':'SouthNdebele',
                    'nav':'Navajo',
                    'nya':'Nyanja',
                    'oci':'Occitan',
                    'oji':'Ojibwa',
                    'orm':'Oromo',
                    'ori':'Oriya',
                    'oss':'Ossetic',
                    'pan':'Panjabi',
                    'pli':'Pali',
                    'pol':'Polish',
                    'pus':'Pushto',
                    'por':'Portuguese',
                    'que':'Quechua',
                    'roh':'Romansh',
                    'run':'Kirundi',
                    'rum':'Romanian',
                    'rus':'Russian',
                    'kin':'Kinyarwanda',
                    'san':'Sanskrit',
                    'srd':'Sardinian',
                    'snd':'Sindhi',
                    'sme':'NorthernSami',
                    'sag':'Sango',
                    'sin':'Sinhalese',
                    'slo':'Slovak',
                    'slv':'Slovene',
                    'smo':'Samoan',
                    'sna':'Shona',
                    'som':'Somali',
                    'alb':'Albanian',
                    'srp':'Serbian',
                    'ssw':'Swati',
                    'sot':'SouthernSotho',
                    'sun':'Sundanese',
                    'swe':'Swedish',
                    'swa':'Swahili',
                    'tam':'Tamil',
                    'tel':'Telugu',
                    'tgk':'Tajik',
                    'tha':'Thai',
                    'tir':'Tigrinya',
                    'tuk':'Turkmen',
                    'tgl':'Tagalog',
                    'tsn':'Tswana',
                    'ton':'Tonga',
                    'tur':'Turkish',
                    'tso':'Tsonga',
                    'tat':'Tatar',
                    'twi':'Twi',
                    'tah':'Tahitian',
                    'uig':'Uyghur',
                    'ukr':'Ukrainian',
                    'urd':'Urdu',
                    'uzb':'Uzbek',
                    'ven':'Venda',
                    'vie':'Vietnamese',
                    'vol':'Volapuk',
                    'wln':'Walloon',
                    'wol':'Wolof',
                    'xho':'Xhosa',
                    'yid':'Yiddish',
                    'yor':'Yoruba',
                    'zha':'Zhuang',
                    'chi':'Chinese',
                    'zul': 'Zulu'
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);

                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return value ? this.override : this.original;
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                    
                    if (this.array) {
                        return value;
                    } else {
                        return value ? [value] : [];
                    }
                },
                set(value){
                    if(value.length){
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : this.array ? value : value.pop()
                        });
                    } else {
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value : null
                        });
                    }
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            source(value){
                return Promise.resolve(Object.entries(this.langs).filter(([code, status]) => {
                    return value ? status.toLowerCase().indexOf(value.toLowerCase()) > -1 : true;
                }))
                .then(langs => langs.map(([code]) => code).sort());
            },
            format(value){
                return this.langs[value];
            },
            edit(){
                if(this.editable){
                    if(this.original && !this.override){
                        // Set the default override value to the current property value.
                        this.$store.commit('entities/override', {property: this.property, value: this.original});
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-status
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
