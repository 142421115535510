<template>
    <div class="blink-preview full">
        <template v-if="preview">
            <div class="blink-loading ff flex flex-align flex-center capitalize" v-if="loading">
                <spinner class="blink-preview-step-spinner" :classes="{ slow: preview.state === 'queued' || patched }" />
                <template v-if="patched">Configuration Changed...</template>
                <template v-else-if="preview.state === 'queued'">Queued...</template>
                <template v-else>Working...</template>
            </div>
            <div class="nothing full flex flex-center flex-align" v-else-if="preview.state === 'error'">
                The preview server experienced an error.<br />Please go back and modify your configuration.
            </div>
            <div class="nothing full flex flex-column flex-center flex-align" v-else-if="preview.state === 'killed'">
                Your preview has expired.
                <div class="button has-icon white requeue" @click="requeue">
                    <RefreshDouble class="icon" width="16" height="16" stroke-width="2" /> Re-queue
                </div>
            </div>
            <dataset-browser v-else :readonly="true" @peek="val => $emit('peek', val)" />
        </template>
        <div class="nothing full flex flex-center flex-align" v-else>
            There is no active preview.
        </div>
    </div>
</template>

<script>
    import { RefreshDouble } from '@epiphany/iconoir';
    import _ from 'lodash';

    export default {
        name: 'BlinkPreview',
        components: {
            RefreshDouble
        },
        computed: {
            preview(){
                return this.$store.state.blink.preview;
            },
            patched(){
                return !_.isNil(this.$store.state.blink.throttle);
            },
            loading(){
                // We are "patched" when the user has modified the configuration but we haven't yet sent it to the server.
                // We add an artificial delay (i.e. throttle) here to prevent overloading the server.
                return this.patched || ['queued', 'working', 'modified'].includes(this.preview.state);
            }
        },
        methods: {
            requeue(){
                this.$store.dispatch('blink/requeue');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .blink-loading
    {
        font-size: 13px;
        color: @black;
        font-weight: 500;

        .blink-preview-step-spinner
        {
            margin-right: @double-padding;
        }
    }
</style>
