<template>
    <div class="entity-details">
        <div class="drawer-section">
            <h4>{{entities[type]}} Details</h4>
            <div class="summary">
                <template v-for="column of properties[type]">
                    <entity-field :entityType="type" :key="column.property" v-bind="column" v-if="column.deprecated !== true" />
                </template>
            </div>
        </div>
        <div class="drawer-section" v-if="has_address">
            <entity-address />
        </div>
        <template v-if="type === 'people'">
            <div class="drawer-section">
                <h4>Demographics</h4>
                <div class="summary">
                    <entity-field v-for="column of properties.demographics" :key="column.property" v-bind="column" />
                </div>
            </div>
        </template>
        <template v-if="type === 'classes' || type === 'courses'">
            <div class="drawer-section">
                <h4>Deprecated Properties</h4>
                <div class="summary">
                    <template v-for="column of properties[type]">
                        <entity-field :entityType="type" :key="column.property" v-bind="column" v-if="column.deprecated === true" />
                    </template>
                </div>
            </div>
        </template>
        <template v-if="type === 'flows' && entity.job_id">
            <div class="drawer-section job-info">
                <div class="flex" @click="open_job_drawer">
                    <h4>Job Information</h4>
                    <OpenInBrowser class="icon" width="16" height="16" stroke-width="2" />
                </div>
                <div class="summary">
                    <div class="entity-field summary-field flex">
                        <div class="summary-key">Job ID</div>
                        <div class="summary-value ff">
                            <entity-value-string property="job_id" copyable monospace />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="drawer-section" v-if="entity.identifiers">
            <entity-identifiers />
        </div>
        <div class="drawer-section">
            <entity-custom-props />
        </div>
        <div class="drawer-section" v-if="!['districts', 'materializations', 'requests', 'flows', 'jobs'].includes(type)">
            <div class="section-content">
                <h4>Delete {{entities[type]}} From the Dataset</h4>
                <div class="disconnect-warning">
                    This will create a deletion override removing the {{entities[type].toLowerCase()}} from the shared dataset.
                    It will not affect the {{entities[type]}} in the school data source; it is only deleted from your application's point of view.
                </div>
                <div class="form-options flex">
                    <div class="red button" @click="delete_entity">Delete {{entities[type]}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EntityValueString from '@/components/drawers/entity/values/EntityValueString.vue';
    import EntityCustomProps from '@/components/drawers/entity/values/EntityValueCustomProps.vue';
    import EntityField from '@/components/drawers/entity/EntityField.vue';
    import EntityAddress from '@/components/drawers/entity/EntityAddress.vue';
    import EntityIdentifiers from '@/components/drawers/entity/EntityIdentifiers.vue';
    import Job from '@/components/drawers/Job.vue';
    import { OpenInBrowser } from '@epiphany/iconoir';
    import {  ENTITY_PROPERTIES, ENTITIES } from '@/constants';
    import _ from 'lodash';

    export default {
        name: 'EntityDetails',
        components: {
            'entity-custom-props': EntityCustomProps,
            'entity-field': EntityField,
            'entity-address': EntityAddress,
            'entity-identifiers': EntityIdentifiers,
            'entity-value-string': EntityValueString,
            OpenInBrowser
        },
        data(){
            return {
                properties: ENTITY_PROPERTIES,
                entities: ENTITIES
            };
        },
        methods: {
            close(){
                this.$store.dispatch('drawer/close');
            },
            name(integration){
                return integration.team.name;
            },
            delete_entity(){
                const override = Object.assign({}, this.$store.state.entities.active.overrides);
                
                if (override.id) {
                    override.action = 'deleted'
                    
                    this.$http.put(`/teams/${this.team.id}/integrations/${this.integration.id}/overrides/${override.id}`, override)
                    .then(() => {
                        this.$toasted.success('Deleted entity from the dataset.');
                        this.close();
                    })
                    .catch(() => this.$toasted.error('There was an error changing the override to a deletion.'));
                } else {
                    this.$http.post(`/teams/${this.team.id}/integrations/${this.integration.id}/overrides`, {
                        type: this.entities[this.type].toLowerCase(),
                        entity_id: this.entity.id,
                        action: 'deleted',
                        properties: {}
                    })
                    .then(() => {
                        this.$toasted.success('Deleted entity from the dataset.');
                        this.close();
                    })
                    .catch(() => this.$toasted.error('There was an error creating a deletion override.'));
                }
            },
            open_job_drawer(){
                const entity = this.$store.state.entities.active;

                this.$store.dispatch('entities/activate', {
                    id: entity.job_id,
                    type: 'jobs',
                    integration: this.integration
                })
                .then(() => {
                    const entity = this.$store.state.entities.active;
                    this.$store.dispatch('drawer/open', {
                        key: `job/${entity.job_id}`,
                        width: 800,
                        component: Job
                    });
                });
            }
        },
        computed: {
            team() {
                return this.$store.getters.team;
            },
            loading() {
                return this.$store.state.entities.loading;
            },
            entity() {
                return this.$store.state.entities.active;
            },
            type() {
                return this.$store.state.entities.type;
            },
            integration() {
                return this.$store.state.entities.integration;
            },
            source() {
                return this.$store.state.entities.source;
            },
            parent() {
                return this.integration ? this.integration : this.source;
            },
            title() {
                return this.type === 'people' ? this.entity.display_name : this.entity.name;
            },
            has_address() {
                return _.has(this.entity, 'address');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .entity-details {
        .mx-datepicker {
            position: fixed;
        }
    }

    h4
    {
        font-weight: 500;
        margin-bottom: 25px;
        font-size: 14px;
        color: @black;
        padding: 0 25px;
    }

    .drawer-header
    {
        .button
        {
            margin-right: 15px;
        }
    }

    .nothing
    {
        padding: 0;
    }

    .disconnect-warning
    {
        font-size: 13px;
        line-height: 18px;
        color: @grey;
        max-width: 700px;
    }

    .drawer-section
    {
        padding: 20px 0;

        h4
        {
            margin: 0 0 20px;
            padding: 0;
        }

        &:first-child
        {
            padding-top: 0;
        }
    }

    .icon {
        color: @base;
        cursor: pointer;
        margin-left: 8px;
    }
</style>
