const state = {
    expanded: localStorage.getItem('edlink-guide-expanded') === 'true'
};

const mutations = {
    toggle(state) {
        state.expanded = !state.expanded;
        localStorage.setItem('edlink-guide-expanded', state.expanded);
    },
    force(state, value) {
        state.expanded = value;
        localStorage.setItem('edlink-guide-expanded', state.expanded);
    }
};

const actions = {
    initialize({ commit }) {
        commit('force', !!localStorage.getItem('edlink-guide-expanded'));
    },
    toggle({ commit }) {
        commit('toggle');
    },
    show({ commit }) {
        commit('force', true);
    },
    hide({ commit }) {
        commit('force', false);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
