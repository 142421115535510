<template>
    <div class="enrichments full" v-if="source">
        <filters>
            <div class="ff"></div>
            <div class="page">
                <template v-if="all.length">
                    Results <span>{{page * count + 1}}</span> &ndash; <span>{{page * count + rows.length}}</span> of
                    <span v-if="next_page_available">Many</span>
                    <span v-else>{{all.length | commas}}</span>
                </template>
                <template v-else>
                    No Results
                </template>
            </div>
            <a class="arrow previous flex flex-align flex-center" @click="previous" :class="{ active: page }">
                <span class="icon iconoir-nav-arrow-left"></span>
            </a>
            <a class="arrow next flex flex-align flex-center" @click="next" :class="{active: (page + 1) * count < all.length || next_page_available}">
                <span class="icon iconoir-nav-arrow-right"></span>
            </a>
            <div class="button" @click="run" v-if="admin">Run Enrichment</div>
        </filters>
        <datatable class="enrichments full" :columns="columns" :rows="rows" :selectable="true" :header="true" :loading="loading" :clickable="true" @clicked="row => focus(row)" />
    </div>
</template>

<script>
    import { WhiteFlag, Calendar, CalendarPlus, CodeBracketsSquare } from '@epiphany/iconoir';

    export default {
        name: 'SourceEnrichments',
        components: {
            WhiteFlag,
            Calendar,
            CalendarPlus,
            CodeBracketsSquare
        },
        data(){
            return {
                loading: true,
                all: [],
                visible: [],
                filter: {
                    visible: false,
                    property: 'id',
                    operator: 'equals',
                    value: ''
                },
                page: 0,
                count: 100,
                next_page_available: true,
                params: {},
                offset: 0,
                total: 0,
                columns: [
                    {
                        id: 'state',
                        name: 'State',
                        icon: WhiteFlag,
                        width: '160px'
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: CalendarPlus,
                        align: 'right',
                        width: '40%'
                    },
                    {
                        id: 'updated_date',
                        name: 'Updated Date',
                        icon: Calendar,
                        align: 'right',
                        width: '40%'
                    }
                ]
            }
        },
        watch: {
            source: {
                immediate: true,
                handler(){
                    this.page = 0;
                    this.all = [];
                    this.params = {};
                    this.next_page_available = true;
                    if (this.source) {
                        this.load();
                    }
                }
            }
        },
        methods: {
            load(){
                if(this.source){
                    this.loading = true;
                    const params = _.clone(this.params);
                    params.$last = this.count;
                    return this.$http.get(`/teams/${this.$store.getters.team.id}/sources/${this.source.id}/enrichments`, {params})
                    .then(response => {
                        if (response.$data.length) {
                            this.all.push(...response.$data);
                            if (response.$data.length < this.count) {
                                this.next_page_available = false;
                            }
                        } else {
                            this.next_page_available = false;
                        }
                    })
                    .catch(error => this.$toasted.error('There was an error loading the enrichments for this source.'))
                    .finally(() => this.loading = false);
                }
            },
            previous(){
                if(this.page){
                    this.page -= 1;
                }
            },
            next(){
                if((this.page + 1) * this.count < this.all.length || this.next_page_available){
                    if(this.all.length <= this.count * (this.page + 1)){
                        this.params.$before = this.all[this.all.length - 1].id;
                        this.load().then(() => this.page += 1);
                    }else{
                        this.page += 1;
                    }
                }
            },
            apply(){
                this.page = 0;
                this.all = [];
                this.params = {};
                this.filter.visible = false;
                this.filter.applied = true;
                this.active = null;
                if (this.source) {
                    this.load();
                }
            },
            clear(){
                this.page = 0;
                this.all = [];
                this.params = {};
                this.active = null;

                this.filter = {
                    visible: false,
                    property: 'id',
                    operator: 'equals',
                    value: '',
                    applied: false
                };

                if(this.$route.query.filter){
                    this.$router.push({query: null});
                }

                if (this.source) {
                    this.load();
                }
            },
            run() {
                this.$http.post(`/teams/${this.$store.getters.team.id}/sources/${this.source.id}/enrichments`)
                .then((rsp) => {
                    if(rsp.$data?.enrichment) {
                        this.all.unshift(rsp.$data?.enrichment);
                        this.$toasted.success('Enrichment Queued');
                    } else {
                        return this.$toasted.error('There was an error queueing an enrichment.')
                    }
                })
                .catch(error => {
                    return this.$toasted.error('There was an error queueing an enrichment.');
                });
            },
            focus(enrichment){
                this.$store.dispatch('drawer/open', {
                    key: `enrichments/${enrichment.id}`,
                    width: 900,
                    props: enrichment,
                    component: 'enrichment'
                });
            }
        },
        computed: {
            admin(){
                return this.user.admin;
            },
            rows() {
                return this.all.slice(this.page * this.count, this.page * this.count + this.count);
            },
            source(){
                return this.$store.getters.source;
            },
            user(){
                return this.$store.getters.user;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    .enrichments {
        padding-top: @filters-height;
    }
    
    .filters {
        .button {
            margin-left: 10px;
        }
    }
</style>