<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <Wrench class="icon" width="16" height="16" stroke-width="2" />
                    Queue
                </div>
            </template>
        </breadcrumbs>
        <div class="admin-queue flex">
            <div class="extra running-jobs ff">
                <h4 class="flex flex-align">
                    All Jobs
                </h4>

                <datatable class="results-table card" :columns="system_table.columns" :rows="system_table.rows" :loading="system_table.loading" :header="true" :clickable="false" :min-height="system_table.rows.length ? 'auto' : '200px'" >
                    <template v-slot:empty-message>There are no metrics available for the system.</template>
                </datatable>
            </div>
        </div>

        <div class="admin-queue flex">
            <div class="extra running-jobs ff">
                <h4 class="flex flex-align">
                    Jobs by Integration
                </h4>
                <datatable class="results-table card" :columns="integration_table.columns" :rows="integration_table.rows" :loading="integration_table.loading" :header="true" :clickable="true" :min-height="integration_table.rows.length ? 'auto' : '200px'" @clicked="(row) => open(row)">
                    <template v-slot:empty-message>There are no metrics available for the integrations.</template>
                </datatable>
            </div>
        </div>
    </main>
</template>

<script>
    import { Wrench } from '@epiphany/iconoir';
    import Entity from '@/components/drawers/Entity.vue';


    export default {
        name: 'AdminQueue',
        components: {
            Wrench,
            Entity
        },
        data() {
            return {
                system_table: {
                    loading: true,
                    columns: [],
                    rows: []
                },
                integration_table: {
                    loading: true,
                    columns: [],
                    rows: []
                },
                integrations: []
            };
        },
        methods: {
            async fetch_integrations(integration_ids) {
                const results = [];

                for await (const integration_id of integration_ids) {
                    try {
                        const integration = await this.$http.get(`/admin/integrations/${integration_id}`)

                        results.push(integration);
                    } catch (error) {
                        this.$toasted.error(`There was an error loading integration ${integration_id}.`);
                    } finally {
                        continue;
                    }

                }

                return results;
            },
            open(row) {
                this.$router.push({
                    name: 'team.integrations.integration',
                    params: {
                        team: this.integrations.find(integration => integration.id === row.integration_id).team.alias,
                        integration: row.integration_id
                    }
                })
            },
        },
        computed: {

        },
        mounted() {
            this.$http.get('/admin/queue/metrics')
                .then(results => {
                    this.system_table.columns = [
                        "Failed", "Queued", "Successful"
                    ].map(header => ({
                        id: header.toLowerCase(),
                        name: header,
                        width: '10%',
                        value: row => row[header.toLowerCase()]
                    }))

                    this.system_table.rows = [results.$data]
                })
                .catch(() => {
                    this.$toasted.error('There was an error loading teams.')
                })
                .finally(() => {
                    this.system_table.loading = false;
                });

            this.$http.get('/admin/queue/metrics/integrations')
                .then(results => {
                    if (!results.$data) {
                        return;
                    }

                    this.fetch_integrations(
                        _.map(results.$data, 'integration_id')
                    ).then(
                        integrations => {
                            this.integrations = integrations.map(integration => integration.$data);

                            for (const result of results.$data) {
                                const integration = this.integrations.find(integration => integration.id === result.integration_id);

                                if (integration) {
                                    this.integration_table.columns = [
                                        "Integration ID", "Application ID", "Source ID",
                                        "Failed", "Queued", "Successful", "Retried",
                                    ].map(header => ({
                                        id: header.toLowerCase().replace(' ', '_'),
                                        name: header,
                                        width: '10%',
                                        value: row => row[header.toLowerCase().replace(' ', '_')]
                                    }));

                                    this.integration_table.rows.push({
                                        integration_id: integration.id,
                                        application_id: result.application_id,
                                        source_id: result.source_id,
                                        failed: result.failed,
                                        queued: result.queued,
                                        successful: result.successful,
                                        retried: result.retried
                                    });
                                } else {
                                    continue;
                                }
                            }
                        }
                    ).finally(() => {
                        this.integration_table.loading = false;
                    });
                })
                .catch(() => {
                    this.$toasted.error('There was an error loading teams.');
                });
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .admin-queue
    {
        top: @breadcrumbs-height;
        overflow-y: auto;
    }

    .extra
    {
        margin: 20px 10px 0px 10px;

        h4
        {
            margin-bottom: 10px;
        }
    }
</style>
