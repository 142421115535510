<template>
    <div class="integrate-source-provider-oidc">
        <integrate-source-editor-step name="Application Details" icon="iconoir-key-alt-plus"  :complete="completed.includes('remote')">
            <div class="helptext">
                Enter the following details into your identity management platform.
            </div>
            <integrate-source-editor-configuration title="Identity Provider Configuration" icon="iconoir-info-empty">
                <label>Display Name</label>
                <div class="flex">
                    <div
                        class="configuration-value copyable noshift"
                        v-clipboard="() => state.context.application.name"
                        v-clipboard:success="() => $toasted.info('Copied')">
                        {{  state.context.application.name }}
                    </div>
                </div>
                <template v-if="state.context.application.icon_url">
                    <label>Application Icon</label>
                    <div class="flex">
                        <div class="text-button" @click="download">
                            Download Icon
                        </div>
                    </div>
                </template>
                <label>Redirect URI</label>
                <div class="flex configuration-value">
                    <div
                        class="configuration-value redirect-uri-value copyable noshift"
                        v-clipboard="() => redirect_uri"
                        v-clipboard:success="() => $toasted.info('Copied')">
                        <div class="text-overflow">{{ redirect_uri }}</div>
                    </div>
                </div>
                <label>Launch URL</label>
                <div class="flex configuration-value">
                    Provided later in the process
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>

        <integrate-source-editor-step 
            name="Enter Your OIDC Credentials"
            icon="iconoir-password-cursor"
            :complete="completed.includes('credentials')">
            <div class="helptext">
                These values should be generated by your identity provider.
            </div>
            <label class="key-label">Well-Known Configuration URL</label>
            <input 
                class="large" 
                type="text"
                placeholder="https://example.com/oidc/2"
                :value="source.configuration.oidc_configuration_url" 
                @input="e => send('UPDATE_SOURCE', {path: 'configuration.oidc_configuration_url', value: e.target.value})" />
            <label class="key-label">Client ID</label>
            <input 
                class="medium" 
                type="text" 
                placeholder="Client ID" 
                :value="source.configuration.client_id"
                @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_id', value: e.target.value})" />
            <label class="key-label">Client Secret</label>
            <input 
                class="medium client-secret" 
                type="text" 
                placeholder="Client Secret" 
                :value="source.configuration.client_secret" 
                @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_secret', value: e.target.value})" />
            <label class="key-label">Authentication Method</label>
            <select class="small" :value="source.configuration.authentication_method" @input="e => send('UPDATE_SOURCE', { path: 'configuration.authentication_method', value: e.target.value })">
                <option disabled selected value="">Select a Method</option>
                <option value="post">Post</option>
                <option value="basic">Basic</option>
                <option value="pkce">PKCE</option>
            </select>
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';
    import { Download } from '@epiphany/iconoir'

    export default {
        name: 'IntegrateSourceProviderOIDC',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration,
            Download
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        methods: {
            download() {
                window.open(this.state.context.application.icon_url, '_blank');
            }
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            redirect_uri(){
                return `https://ed.link/api/authentication/oidc/${this.state.context.application.id}`;
            },
            source(){
                // OIDC can never be a destination provider, only a source provider.
                return this.state.context.source;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                if(!this.remote){
                    return [];
                }

                const properties = ['client_id', 'client_secret', 'oidc_configuration_url', 'authentication_method'];

                for(const property of properties){
                    if(!this.source.configuration?.[property]){
                        return ['remote'];
                    }
                }

                if(!this.source.configuration?.oidc_configuration_url?.match(url)){
                    return ['remote'];
                }

                return ['remote', 'credentials'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 2);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .flex.checkbox {
        margin-top: 15px;
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .redirect-uri-value.copyable.noshift {
        max-width: 495px;
    }

    .integrate-source-provider-oidc
    {
        select
        {
            margin-top: 0;
        }
    }
</style>
