<template>
    <div class="reconnect-step login">
        <label>Two-Factor Authentication</label>
        <input ref="two" type="text" placeholder="2FA Code..." autocomplete="false" v-model="two" @keydown.enter="activate" />
        <div class="options flex" key="email">
            <div class="button" kbd="Enter ⏎" @click="activate">Confirm 2FA Code</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReconnectEmail',
        data() {
            return {}
        },
        computed: {
            loading(){
                return this.$store.state.reconnect.loading;
            },
            error(){
                return this.$store.state.reconnect.error;
            },
            page(){
                return this.$store.state.reconnect.page;
            },
            two: {
                get(){
                    return this.$store.state.reconnect.login.two;
                },
                set(value){
                    this.$store.commit('reconnect/state', { path: 'login.two', value })
                }
            }
        },
        methods: {
            send(page){
                this.$store.commit('reconnect/page', page);
            },
            next(){
                this.$store.dispatch('reconnect/next');
            },
            activate() {
                this.$store.commit('reconnect/loading', true);
                this.$http.post('/challenge', {code: this.two})
                .then(response => {
                    this.$store.dispatch('user/login')
                    .then(() => {
                        this.$store.dispatch('reconnect/login', this.$route.params.parent);
                        this.send('configuration');
                        setTimeout(() => {
                            this.$store.commit('reconnect/loading', false);
                        }, 500);
                    });
                })
                .catch(({ $error }) => {
                    this.$toasted.error($error ? $error : 'There was an error logging you in.');
                    setTimeout(() => {
                        this.$store.commit('reconnect/loading', false);
                    }, 500);
                });
            }
        },
        mounted(){
            this.$refs.two.focus();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";
</style>