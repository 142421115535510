<template>
    <div class="admin-people">
       
       

 <section class="flex">
            <div class="section-content ff">
                <h2>Person Login Information</h2>
               <div v-for="login in logins" class="summary">
                   
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Refresh Id</div>
                        <div  class="summary-value">{{ login.refresh.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Refresh Created Date</div>
                        <div  class="summary-value">{{ login.refresh.created_date }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Refresh Sequence</div>
                        <div  class="summary-value">{{ login.refresh.sequence }}</div>
                    </div>                    
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Integration Id</div>
                        <div  class="summary-value">{{ login.integration.id }}</div>
                    </div>
                    <div class="summary-field flex flex-align">
                        <div class="summary-key">Refresh Token</div>
                        <div  class="summary-value">{{ login.refresh.token }}</div>
                    </div>
                    
                    <hr>
                    <br>
                </div> 
            </div>
        </section>

            
    </div>
</template>

<script>

export default {
    name: 'AdminPerson',
    data() {
       
        return {
            logins:[],
            search: '',

        };
    },
    created() {
        this.$http
            .get(`/admin/people/${this.$route.params.person_id}/logins`)  
            .then((response) => {
                
                this.logins = response.$data;                
            })
            .catch((error) => {
                this.$toasted.error(
                    'Failed to load login information.'
                );
            });
    },
    methods: {

    },
    computed: {

    },
};
</script>



<style scoped lang="less">



@import '~@/assets/less/variables';

h2 {
    color: @black;
    font-size: 16px;
    margin-bottom: 25px;
}

section {
    .section-content {
        border-top: 1px solid @e4;
        padding: 25px 0;
    }
}

.summary {
    font-size: 13px;

    .summary-field {
        height: 22px;
        margin-bottom: 10px;
      
    }

    .summary-key {
        color: @grey;
        width: 180px;
        flex-basis: 180px;
        flex-shrink: 0;
    }

    .summary-value {
        color: @black;
        line-height: 22px;

       
    }
}



.ff {
    padding: 15px;
}

</style>
