import { render, staticRenderFns } from "./Flow.vue?vue&type=template&id=71c0fef0&scoped=true"
import script from "./Flow.vue?vue&type=script&lang=js"
export * from "./Flow.vue?vue&type=script&lang=js"
import style0 from "./Flow.vue?vue&type=style&index=0&id=71c0fef0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c0fef0",
  null
  
)

export default component.exports