<template>
    <div class="integrate-source-provider-canvas">
        <integrate-source-editor-step name="Enter Your Canvas URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your teachers and students go to sign into Canvas.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.instructure.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Create Canvas Developer Keys" icon="iconoir-code" :complete="completed.includes('remote')">
            <div class="helptext">
                Click the link below create developer keys for Edlink.
            </div>
            <integrate-source-editor-configuration title="How to Configure Canvas" icon="iconoir-info-empty">
                <label>Open Settings Page</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/accounts/1/developer_keys#api_key_modal_opened'">
                        Open Canvas Developer Keys
                    </a>
                </div>
                <label>Key Name</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => 'Edlink'" v-clipboard:success="() => $toasted.info('Copied')">Edlink</div>
                </div>
                <label>Owner Email</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => 'accounts@ed.link'" v-clipboard:success="() => $toasted.info('Copied')">accounts@ed.link</div>
                </div>
                <label>Redirect URIs (One Per Line)</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => uris.join('\n')" v-clipboard:success="() => $toasted.info('Copied')">
                        <div class="uri" v-for="(uri, $index) of uris" :key="$index">{{uri}}</div>
                    </div>
                </div>
                <label>Icon URL</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => 'https://ed.link/icon.png'" v-clipboard:success="() => $toasted.info('Copied')">https://ed.link/icon.png</div>
                </div>
            </integrate-source-editor-configuration>
            <integrate-source-editor-configuration title="Advanced Options" icon="iconoir-code-brackets-square" :collapsable="true">
                <div class="configuration-value scopes">
                    Edlink does support the use of API scopes, but it is not recommended at this time due to constraints in the Canvas API.
                    If required by institutional policy, please read more about our recommended scopes <a class="configuration-link inline-block text-button mini" target="_blank">here</a>.
                    <div class="flex flex-align enable-scopes">
                        <input class="checkbox-input" type="checkbox" :checked="source.configuration.scopes" @input="e => send('UPDATE_SOURCE', {path: 'configuration.scopes', value: !!e.target.checked})" />
                        <div class="checkbox-label">Enable Scopes</div>
                    </div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Add the Client ID and Secret Key From Canvas" icon="iconoir-password-cursor" :complete="completed.includes('keys')">
            <div class="helptext">
                The Client ID can be found in the "Details" column and typically starts with the number 1 followed by a bunch of zeros.
                The Secret Key can be found in the same column by clicking the "Show Key" button.
            </div>
            <label class="key-label">Client ID</label>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.application_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_id', value: e.target.value})" />
                <!-- <div class="integrate-input-help text-button mini has-icon">
                    <span class="icon iconoir-info-empty block"></span>
                    <div>See a Picture</div>
                </div> -->
            </div>
            <label class="key-label">Secret Key</label>
            <div class="flex flex-align">
                <input class="medium client-secret" type="text" placeholder="Secret Key" :value="source.configuration.application_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.application_secret', value: e.target.value})" />
                <!-- <div class="integrate-input-help text-button mini has-icon">
                    <span class="icon iconoir-info-empty block"></span>
                    <div>See a Picture</div>
                </div> -->
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Connect Your Canvas Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
            <div class="helptext">
                Sign into your Canvas account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <token :write="destination" :configuration="source.configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderCanvas',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url.match(url)){
                    return [];
                }

                // 2. Is the remote configuration done?
                if(!this.remote){
                    return ['url'];
                }

                // 3. Are the keys entered?
                if(!this.source.configuration.application_id || !this.source.configuration.application_secret){
                    return ['url', 'remote'];
                }

                // 3. Validate that the token has been acquired.
                if(!this.source.token && !this.source.id){
                    return ['url', 'remote', 'keys'];
                }

                return ['url', 'remote', 'keys', 'token'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        data(){
            return {
                remote: false,
                uris: [
                    'https://ed.link/api/authentication/canvas',
                    'https://ed.link/sso/administrator'
                ]
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .uri
    {
        margin-bottom: 4px;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .configuration-value.scopes
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .enable-scopes
    {
        margin-top: 10px;

        .checkbox-input
        {
            margin: 0 10px 0 0;
        }
    }
</style>
