<template>
    <main v-if="integration">
        <breadcrumbs>
            <template v-slot:crumbs>
                <router-link :to="{ name: 'team.integrations' }" class="breadcrumb flex flex-align">
                    <Bolt class="icon" width="16" height="16" stroke-width="2" />
                    Integrations
                </router-link>
                <div class="breadcrumb">
                    <template v-if="district">
                        {{ integration.application.name }}
                    </template>
                    <template v-else>
                        {{ integration.team.name }}
                    </template>
                </div>
            </template>
            <template v-slot:actions>
                <div class="button white flex flex-align has-icon" @click="$modal.show('integration-status')">
                    <div class="standalone icon-status icon" :class="status"></div>
                    <div>Integration <span class="capitalize">{{ integration.status }}</span></div>
                </div>
                <div class="button white flex flex-align has-icon" @click="$modal.show('integration-billing')" v-if="user.admin">
                    <Coins class="icon" width="16" height="16" stroke-width="2" v-if="integration.billing_category === 'standard'" />
                    <Code class="icon" width="16" height="16" stroke-width="2" v-else />
                    <div>{{ integration.billing_category === 'standard' ? 'Standard Billing' : 'Development' }}</div>
                </div>
            </template>
        </breadcrumbs>
        <div class="integration full">
            <router-view />
            <modal name="integration-status" :width="420" height="auto" classes="modal">
                <div class="integration-status flex flex-column">
                    <div class="flex flex-align create-status-options">
                        <h2 class="ff">Change Integration Status</h2>
                        <div class="text-button" @click="$modal.hide('integration-status')">Cancel</div>
                    </div>
                    <div class="integration-status-message alert center" v-if="integration.status === 'scheduled'">
                        This integration is currently scheduled to start on {{ integration.start_date | pretty('long') }}. If you change the state manually, it will override the default behavior.
                    </div>
                    <div class="status-type flex" @click="update('active')" :class="{ active: integration.status === 'active', disabled }">
                        <div class="status-type-header flex flex-align flex-center none">
                            <input class="checkbox-input" type="checkbox" :checked="integration.status === 'active'" />
                        </div>
                        <div class="ff">
                            <div class="status-type-title">Active</div>
                            <div class="status-type-description">This integration will function normally. Roster data access is available and data will update regularly. Users are able to sign in and utilize LMS integration.</div>
                        </div>
                    </div>
                    <div class="status-type flex" @click="update('paused')" :class="{ active: integration.status === 'paused', disabled }">
                        <div class="status-type-header flex flex-align flex-center none">
                            <input class="checkbox-input" type="checkbox" :checked="integration.status === 'paused'" />
                        </div>
                        <div class="ff">
                            <div class="status-type-title">Paused</div>
                            <div class="status-type-description">Users will still be able to access {{ integration.application.name }}, but roster data will not be updated. New sharing rules or transforms will not be applied.</div>
                        </div>
                    </div>
                    <div class="status-type flex" @click="update('disabled')" :class="{ active: integration.status === 'disabled', disabled: integration.status === 'pending' }">
                        <div class="status-type-header flex flex-align flex-center none">
                            <input class="checkbox-input" type="checkbox" :checked="integration.status === 'disabled'" />
                        </div>
                        <div class="ff">
                            <div class="status-type-title">Disabled</div>
                            <div class="status-type-description">All access to school data will be eliminated and users will no longer be able to sign in. The integration will not be destroyed and may be resumed later by the district.</div>
                        </div>
                    </div>
                </div>
            </modal>
            <modal name="integration-billing" :width="420" height="auto" classes="modal billing-settings">
                <div class="integration-status flex flex-column">
                    <div class="flex flex-align create-status-options">
                        <h2 class="ff">Integration Billing Category</h2>
                        <!-- <h2 class="ff">Integration Billing Settings</h2> -->
                        <div class="text-button" @click="save_billing">Save</div>
                        <div class="text-button red" @click="$modal.hide('integration-billing')">Cancel</div>
                    </div>
                    <div class="integration-status-message alert center" v-if="updates.billing_category === 'development'">
                        This integration is currently in development mode. If you change the state it will become billable.
                    </div>


                    <div class="form-field">
                        <div class="status-type flex" @click="update_billing('development')" :class="{ active: updates.billing_category === 'development', disabled }">
                            <div class="status-type-header flex flex-align flex-center none">
                                <input class="checkbox-input" type="checkbox" :checked="updates.billing_category === 'development'" />
                            </div>
                            <div class="ff">
                                <div class="status-type-title">Development</div>
                                <div class="status-type-description">This integration will not be billed for.</div>
                            </div>
                        </div>
                        <div class="status-type flex" @click="update_billing('standard')" :class="{ active: updates.billing_category === 'standard', disabled }">
                            <div class="status-type-header flex flex-align flex-center none">
                                <input class="checkbox-input" type="checkbox" :checked="updates.billing_category === 'standard'" />
                            </div>
                            <div class="ff">
                                <div class="status-type-title">Standard</div>
                                <div class="status-type-description">This integration will be billed for.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
        </div>
    </main>
</template>

<script>
import { Bolt, Coins, Code } from '@epiphany/iconoir';
import Entity from '@/components/drawers/Entity.vue';
import { VALID_DRAWER_ENTITIES } from '@/constants';
import Event from '@/components/drawers/Event.vue';
import Materialization from '@/components/drawers/Materialization.vue';
import Job from '@/components/drawers/Job.vue';
import Flow from '@/components/drawers/Flow.vue';
import Options from '@/components/modals/Options.vue';
import parser from 'papaparse';

const components = {
    event: Event,
    materialization: Materialization,
    job: Job,
    flow: Flow
};

export default {
    name: 'Integration',
    components: {
        Bolt,
        Code,
        Coins
    },
    data() {
        return {
            loading: true,
            statuses: {
                active: 'Integration Active',
                inactive: 'Not Yet Activated',
                disabled: 'Integration Disabled',
                error: 'Integration Error'
            },
            today_datepicker_open: false,
            params: {
                $filter: {},
                $expand: 'overrides'
            },
            updates: {
                billing_category: null,
                properties: {
                    fixed_synced_people: null
                }
            }
        };
    },
    computed: {
        district(){
            return this.team.id === this.integration.team.id;
        },
        entity_type() {
            return this.$route.params.type;
        },
        parent() {
            if (this.$store.getters.integration) {
                return this.$store.getters.integration;
            }

            if (this.$store.getters.source) {
                return this.$store.getters.source;
            }
        },
        type() {
            if (this.$store.getters.integration) {
                return 'integrations';
            }

            if (this.$store.getters.source) {
                return 'sources';
            }
        },
        team() {
            return this.$store.getters.team;
        },
        user() {
            return this.$store.state.user;
        },
        integration() {
            return this.$store.getters.integration;
        },
        status() {
            return {
                red: ['error', 'destroyed', 'disabled'].includes(this.integration.status),
                yellow: ['paused'].includes(this.integration.status),
                green: ['active'].includes(this.integration.status),
                grey: ['requested'].includes(this.integration.status),
                purple: ['inactive', 'scheduled', 'pending', 'requested'].includes(this.integration.status)
            };
        },
        billing_category() {
            return {
                blue: ['pilot', 'standard'].includes(this.integration.billing_category),
                purple: ['development'].includes(this.integration.billing_category),
            };
        },
        disabled() {
            if (this.integration.status === 'pending') {
                return true;
            }

            if (this.team.type === 'developer') {
                return this.integration.status === 'disabled';
            }

            return false;
        },
        banner_text() {
            if (this.$route.params.type === 'overrides') {
                return 'overrides';
            } else if (this.$route.name === 'team.integrations.integration.sharing') {
                return 'rules';
            } else if (this.$route.name === 'team.integrations.integration.transformations') {
                return 'transformations';
            } else {
                return null;
            }
        },
        filters() {
            try {
                const query = JSON.parse(this.$route.query.filters);

                if (_.isArray(query)) {
                    return query;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        }
    },
    created() {
        this.$store
            .dispatch('integrations/activate', {
                integration: this.$route.params.integration,
                team: this.$store.getters.team.id
            })
            .then(() => {
                this.loading = false;

                if (window.location.hash) {
                    const [type, entity_id] = window.location.hash.slice(1).split('/');

                    if (Object.values(VALID_DRAWER_ENTITIES).includes(type)) {
                        // Find the plural version of our singular object type.
                        const [plural] = Object.entries(VALID_DRAWER_ENTITIES).find(([key, value]) => value === type);

                        this.$store
                            .dispatch('entities/activate', {
                                id: entity_id,
                                type: plural,
                                integration: this.$store.getters.integration
                            })
                            .then(() => {
                                this.$store.dispatch('drawer/open', {
                                    key: `${plural}/${entity_id}`,
                                    width: 800,
                                    component: components.hasOwnProperty(type) ? components[type] : Entity
                                });
                            })
                            .catch((ex) => this.$toasted.error(`Could not find specified ${type}`));
                    }
                }

                this.updates.billing_category = this.integration.billing_category;
                this.updates.properties.fixed_synced_people = this.integration.properties?.fixed_synced_people;
            })
            .catch((error) => this.$toasted.error('There was an error loading the selected integration.'));
    },
    destroyed() {
        this.$store.dispatch('integrations/deactivate');
    },
    methods: {
        update(status) {
            this.$store
                .dispatch('integrations/status', status)
                .then(() => this.$toasted.success(`Integration ${this.integration.status}.`))
                .catch((error) => this.$toasted.error('There was an error updating the integration status.'));
        },
        save_billing() {
            const updates = {};

            if (this.updates.billing_category) {
                updates.billing_category = this.updates.billing_category;
            }
            

            if (this.updates.properties.fixed_synced_people){
                const parsed = this.updates.properties.fixed_synced_people;
                if (isNaN(parsed) || parsed < 0) {
                    this.$toasted.error('Fixed synced people must be a positive number');
                    return;
                }

                updates.properties = Object.assign({}, this.integration.properties, {
                    fixed_synced_people: this.updates.properties.fixed_synced_people
                });
            } else {
                updates.properties = Object.assign({}, this.integration.properties, {
                    fixed_synced_people: null
                });
            }

            this.$store.dispatch('save/save', 'integration_billing_config');

            if (_.isEmpty(updates)) {
                // If no changes fake a save
                setTimeout(() => {
                    this.$store.dispatch('save/saved', 'integration_billing_config');
                    this.$modal.hide('integration-billing');
                }, 500);
                // Exit so we don't make an unnecessary request
                return;
            }

            this.$http.patch(`/admin/integrations/${this.integration.id}`, updates)
                .then(() => this.$store.dispatch('save/saved', 'integration_billing_config'))
                .then(() => this.$modal.hide('integration-billing'))
                .then(() => this.$store.dispatch('integrations/activate', {
                    integration: this.$route.params.integration,
                    team: this.$store.getters.team.id
                }))
                .catch(() => this.$store.dispatch('save/error', 'integration_billing_config'));
        },
        update_billing(billing_category) {
            if (this.updates.billing_category === billing_category) {
                return;
            }

            this.updates.billing_category = billing_category;
        }
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.mx-datepicker,
.condition-button {
    width: inherit;

    .clear {
        margin-right: -4px;
        margin-left: 4px;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.integration {
    top: @breadcrumbs-height;
}

.state {
    margin-left: 10px;
}

.integration-status {
    padding: 15px 15px 15px;

    h2 {
        margin: 0;
        color: @black;
        font-size: 15px;
    }
}

.status-type {
    border: 1px solid @d;
    border-radius: 3px;
    text-transform: none;
    margin: 0;
    cursor: pointer;
    margin-top: 15px;

    .ff {
        padding: 15px;
    }

    .status-type-header {
        border-right: 1px solid @d;
        padding: 10px;
        background: @f8;
    }

    .status-type-title {
        font-size: 14px;
        font-weight: 500;
        color: @black;
        margin-bottom: 6px;
    }

    .status-type-description {
        color: @black;
        font-size: 12px;
        line-height: 16px;
    }

    &.active {
        border-color: @base;

        .status-type-header {
            border-right: 1px solid @base;
            background-color: fade(@base, 10%);
        }

        .status-type-title {
            color: @base;
        }
    }

    input.checkbox-input {
        background: @d;
        margin: 0;
        border: 0;
        transition: background-color ease 0.2s;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
            background: url('~@/assets/icons/white/check.svg') -1px 1px no-repeat;
            background-size: 18px auto;
            transform: scale(0);
            transition: all ease 0.15s;
            opacity: 0;
        }

        &:checked {
            background: @base;

            &::after {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
}

.integration-status-message {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    padding: 15px;
    border-color: @purple;
    color: @purple;
    background-color: fade(@purple, 20%);
}

.billing-settings {
    .create-status-options {
        // margin-bottom: 25px;

        .text-button {
            margin-right: 8px;
            
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .helptext {
        font-size: 12px;
        color: @grey;
        margin-top: 8px;
        line-height: 16px;
        margin-bottom: 10px;

        & + .status-type {
            margin-top: 0;
        }
    }

    h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
    }

    .form-field {
        margin-top: 0;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
