/* eslint-disable no-undef */
export const sampleSourceId = {
    blackboard: process.env.VUE_APP_SAMPLE_BLACKBOARD_SOURCE_ID ?? '8f4600aa-7cbe-4607-a3ec-7f3122a9a3b9',
    brightspace: process.env.VUE_APP_SAMPLE_BRIGHTSPACE_SOURCE_ID ?? 'ea77592b-531e-4f28-b183-196da308d4f7',
    canvas: process.env.VUE_APP_SAMPLE_CANVAS_SOURCE_ID ?? '12feb383-7455-4a3e-8da9-0851d77779cc', // https://ed.link/SampleDeveloperSources/sources/12feb383-7455-4a3e-8da9-0851d77779cc/overview
    google: process.env.VUE_APP_SAMPLE_GOOGLE_SOURCE_ID ?? 'eaf1ab46-98b6-416a-a14c-769ee96d202d',
    moodle: process.env.VUE_APP_SAMPLE_MOODLE_SOURCE_ID ?? '509e7282-f80d-4d50-a478-9a10e1b0409d',
    rapididentity: process.env.VUE_APP_SAMPLE_RAPIDIDENTITY_SOURCE_ID ?? '0da13823-cc44-4df1-a083-7fca6325e638',
    schoology: process.env.VUE_APP_SAMPLE_SCHOOLOGY_SOURCE_ID ?? 'e9f362e2-723a-4bd0-9dc7-06daacd282da',
    skyward: process.env.VUE_APP_SAMPLE_SKYWARD_SOURCE_ID ?? '1cd8dffc-f9cf-4e81-b072-db8c9bc67268',
    microsoft: process.env.VUE_APP_SAMPLE_MICROSOFT_SOURCE_ID ?? '1b0686ba-2528-43db-a9fd-bf42e0e25674',
    aeries: process.env.SAMPLE_AERIES_SOURCE_ID ?? '2a5f254a-fb96-4d5d-8e83-8669c8579716',
    aspen: process.env.SAMPLE_ASPEN_SOURCE_ID ?? '4f797200-8e6a-4a6e-85c7-01c51e3e6a31',
    bromcom: process.env.SAMPLE_BROMCOM_SOURCE_ID ?? '7d30d79e-bcdc-418e-b976-92a384b15697',
    powerschool: process.env.SAMPLE_POWERSCHOOL_SOURCE_ID ?? 'ff3753d1-b5e7-4e3d-af0f-8fc46a5dddc5'
};

/* eslint-enable no-undef */
export const entities = {
    people: 'Person',
    classes: 'Class',
    sections: 'Section',
    courses: 'Course',
    schools: 'School',
    districts: 'District',
    enrollments: 'Enrollment',
    agents: 'Agent',
    sessions: 'Session',
    requests: 'Request',
    materializations: 'Materialization',
    resources: 'Resource'
};

export const BILLING_APPROVED_USERS = [
    // --- Dev ---
    // jaron@ed.link
    '096ee6ad-8ae5-4c70-96a9-aec1f1ce98c0',
    // -- Prod ---
    // jaron@ed.link
    '8d861cb8-e057-4e57-8379-208955770e30',
    // amanda@ed.link
    '6d3c5e36-bc6d-4fc1-8eb1-dc8628b4fccd',
    // dakota@ed.link
    'be5a023f-0c69-4e71-832b-75cd83f9512c',
    // alex@ed.link
    '9555f410-f440-49c2-8bf5-35642f0dd135'
];

export const known_blocks = {
    '91822395-5809-4ea2-96f6-c853341cbcc9': 'Data Enrichment',
    'fcf52502-89da-4fd3-bb67-10afba97f29c': 'Shared Data Overrides',
    'dbced7ec-f955-46b0-b4fa-607a5eead3af': 'Privacy Mask',
    '12be6249-5407-4e50-a331-ac305b70d108': 'Source Data Overrides',
    '595259cf-552a-4103-8dea-ffb3a6234037': 'Convert Grade Levels to UK',
    '8f8a3e02-3ae2-4e27-95f4-40136450f696': 'Class State By Session',
    '20c46eca-d261-4acc-a65a-a464fdf84e1f': 'Infer Roles',
    '50060470-b7bd-439c-a40f-819b3d6fa9cf': 'Map Custom Roles',
    'a6c5b3df-c684-4575-adc3-bb19cc9b3ae3': 'Kebab Case',
    '6ab5c8a0-23cc-46f1-8173-292e50a46218': 'Normalize Phone Number',
    '578bda1a-f540-4dd2-9819-5fdf509e9638': 'Normalize State Names',
    '09082509-62b3-46da-b6a7-9a48cba0a880': 'Construct Email',
    'c489379d-a3c0-4158-98a4-665eed1aa717': 'Hide Classes in Inactive Sessions',
    '0e82fe15-55ab-4cae-95fb-63955fcc8c93': 'Normalize Locale and Time Zone',
    '1b238886-9b17-425e-b223-84d8b282043e': 'Clever Backwards Compatibility',
    '44787c37-4069-4e35-bf0b-c67d349601db': 'Remove Inactive Users',
    '1355e04b-d2f6-4d19-acae-5ff411ca8108': 'Data Validation',
    'd1df7a33-c5f1-49d7-850d-d03c7ecb335f': 'Product Licensing',
    '448974ff-7238-4e0c-a429-ba69e91c7ccf': 'AI Prompt',
    'b2b9952a-b793-4e4c-ba3d-93d478617258': 'Import CSV Data',
    '61c6c477-ec33-4e5e-ba54-51849310dd31': 'Split Sections Into Classes',
    'dfc54590-b8b3-4a35-9c26-b6ef3e3d45b5': 'Data Validation'
};

export const csv_headers = {
    people: ['id', 'created_date', 'updated_date', 'first_name', 'last_name', 'display_name', 'picture_url', 'roles', 'email', 'phone', 'locale', 'time_zone', 'graduation_year', 'grade_levels', 'school_ids', 'identifiers'],
    enrollments: ['id', 'created_date', 'updated_date', 'person_id', 'class_id', 'section_id', 'role', 'primary', 'state', 'start_date', 'end_date'],
    schools: ['id', 'created_date', 'updated_date', 'name', 'picture_url', 'grade_levels', 'locale', 'time_zone', 'location'],
    districts: ['id', 'created_date', 'updated_date', 'name', 'picture_url', 'locale', 'time_zone', 'location'],
    classes: ['id', 'created_date', 'updated_date', 'name', 'description', 'picture_url', 'grade_levels', 'subjects', 'periods', 'state', 'locale', 'time_zone', 'session_ids', 'course_id', 'school_id'],
    sections: ['id', 'created_date', 'updated_date', 'name', 'description', 'picture_url', 'periods', 'state', 'locale', 'time_zone', 'class_id'],
    courses: ['id', 'created_date', 'updated_date', 'name', 'code', 'grade_levels', 'subjects', 'session_id', 'school_id'],
    sessions: ['id', 'created_date', 'updated_date', 'name', 'start_date', 'end_date', 'state', 'type', 'school_id'],
    agents: ['id', 'created_date', 'updated_date', 'relationship_type', 'flags', 'observer_id', 'target_id'],
    days: ['id', 'created_date', 'updated_date', 'date'],
    calendars: ['id', 'created_date', 'updated_date', 'name', 'code'],
    facilities: ['id', 'created_date', 'updated_date', 'name', 'description', 'type', 'flags'],
    rooms: ['id', 'created_date', 'updated_date', 'name', 'number', 'description', 'capacity'],
    subjects: ['id', 'created_date', 'updated_date', 'name', 'code', 'ceds_code', 'description'],
    meetings: ['id', 'day_id', 'created_date', 'updated_date'],
    periods: ['id', 'created_date', 'updated_date', 'name', 'description', 'start_time', 'end_time', 'type', 'flags'],
    fees: [
        'id',
        'created_date',
        'updated_date',
        'amount',
        'balance',
        'currency',
        'amount_charged',
        'amount_paid',
        'original_amount',
        'adjustment_amount',
        'prorated',
        'payment_schedule',
        'comment',
        'description',
        'creation_date',
        'date_charged',
        'date_due',
        'priority',
        'category',
        'type',
        'flags'
    ],
    incidents: ['id', 'created_date', 'updated_date', 'title', 'description', 'updates', 'date', 'cost']
};

export const entity_properties = {
    people: [
        { title: 'Person ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'First Name', property: 'first_name', type: 'string', editable: true },
        { title: 'Last Name', property: 'last_name', type: 'string', editable: true },
        { title: 'Display Name', property: 'display_name', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Roles', property: 'roles', type: 'role', array: true, editable: true },
        { title: 'Email Address', property: 'email', type: 'string', editable: true },
        { title: 'Phone Number', property: 'phone', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Graduation Year', property: 'graduation_year', type: 'number', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Schools', property: 'school_ids', type: 'school', array: true, editable: true },
        { title: 'Identifiers', property: 'identifiers', type: 'identifier', path: 'value', array: true, editable: true }
    ],
    demographics: [
        { title: 'Birthday', property: 'demographics.birthday', type: 'date', editable: true },
        { title: 'Country of Birth', property: 'demographics.country_of_birth', type: 'string', editable: true },
        { title: 'State of Birth', property: 'demographics.state_of_birth', type: 'string', editable: true },
        { title: 'City of Birth', property: 'demographics.city_of_birth', type: 'string', editable: true },
        { title: 'Gender Identity', property: 'demographics.gender', type: 'gender', editable: true },
        { title: 'Races', property: 'demographics.races', type: 'race', array: true, editable: true },
        { title: 'Hispanic or Latino', property: 'demographics.hispanic_or_latino_ethnicity', type: 'boolean', editable: true },
        { title: 'English Language Learner', property: 'demographics.english_language_learner', type: 'boolean', editable: true },
        { title: 'Individualized Education Plan', property: 'demographics.individualized_education_plan', type: 'boolean', editable: true },
        { title: 'Primary Language', property: 'demographics.primary_language', type: 'primary_language', editable: true },
        { title: 'Special Accommodations', property: 'demographics.special_accommodations', type: 'boolean', editable: true },
        { title: 'Military Connected', property: 'demographics.military_connected', type: 'military_connected_status', editable: true },
        { title: 'Food Service Program Eligibility', property: 'demographics.food_service_program_eligibility', type: 'food_service_program', editable: true },
        { title: 'Residence Status', property: 'demographics.residence_status', type: 'residence_status', editable: true },
        { title: 'Homeless', property: 'demographics.homeless', type: 'boolean', editable: true },
        { title: 'Migrant Status', property: 'demographics.migrant', type: 'boolean', editable: true },
        { title: 'Disability', property: 'demographics.disability', type: 'disability', editable: true },
        { title: 'Gifted or Talented', property: 'demographics.gifted_talented', type: 'boolean', editable: true },
        { title: 'Economically Disadvantaged', property: 'demographics.economically_disadvantaged', type: 'boolean', editable: true },
        { title: 'Public Assistance', property: 'demographics.public_assistance', type: 'boolean', editable: true },
        { title: 'Rural Residency', property: 'demographics.rural_residency', type: 'boolean', editable: true }
    ],
    address: [
        { title: 'Street Address', property: 'address.street', type: 'string', editable: true },
        { title: 'Unit Number', property: 'address.unit', type: 'string', editable: true },
        { title: 'City', property: 'address.city', type: 'string', editable: true },
        { title: 'State', property: 'address.state', type: 'string', editable: true },
        { title: 'Postal Code', property: 'address.postal_code', type: 'string', editable: true },
        { title: 'Country', property: 'address.country', type: 'string', editable: true }
    ],
    enrollments: [
        { title: 'Enrollment ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Person ID', property: 'person_id', type: 'person', monospace: true, editable: true },
        { title: 'Class ID', property: 'class_id', type: 'class', monospace: true, editable: true },
        { title: 'Section ID', property: 'section_id', type: 'section', monospace: true, editable: true },
        { title: 'Role', property: 'role', type: 'role', editable: true },
        { title: 'Primary', property: 'primary', type: 'boolean', editable: true },
        { title: 'State', property: 'state', type: 'enum', editable: true },
        { title: 'Start Date', property: 'start_date', type: 'date', editable: true },
        { title: 'End Date', property: 'end_date', type: 'date', editable: true }
    ],
    schools: [
        { title: 'School ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Address', property: 'location', type: 'address', monospace: true }
    ],
    districts: [
        { title: 'District ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Address', property: 'location', type: 'address', monospace: true }
    ],
    classes: [
        { title: 'Class ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Subjects', property: 'subjects', type: 'subject', array: true, editable: true },
        { title: 'Periods', property: 'periods', type: 'string', array: true, editable: true },
        { title: 'State', property: 'state', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Sessions', property: 'session_ids', type: 'session', array: true, editable: true },
        { title: 'Course', property: 'course_id', type: 'course', editable: true },
        { title: 'School', property: 'school_id', type: 'school', editable: true }
    ],
    sections: [
        { title: 'Section ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Periods', property: 'periods', type: 'string', array: true, editable: true },
        { title: 'State', property: 'state', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Class', property: 'class_id', type: 'class', editable: true }
    ],
    courses: [
        { title: 'Course ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Code', property: 'code', type: 'string', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Subjects', property: 'subjects', type: 'subject', array: true, editable: true },
        { title: 'Session', property: 'session_id', type: 'session', editable: true },
        { title: 'School', property: 'school_id', type: 'school', editable: true }
    ],
    sessions: [
        { title: 'Session ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Start Date', property: 'start_date', type: 'date', editable: true },
        { title: 'End Date', property: 'end_date', type: 'date', editable: true },
        { title: 'State', property: 'state', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'string', editable: true },
        { title: 'School', property: 'school_id', type: 'school', editable: true }
    ],
    agents: [
        { title: 'Agent ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'Relationship', property: 'relationship_type', type: 'string', editable: true },
        { title: 'Flags', property: 'flags', type: 'agent_flags', array: true, editable: true },
        { title: 'Observer', property: 'observer_id', type: 'person', editable: true },
        { title: 'Target', property: 'target_id', type: 'person', editable: true }
    ],
    requests: [
        { title: 'Request ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Start Date', property: 'date', type: 'date' },
        { title: 'End Date', property: 'end_date', type: 'date' },
        { title: 'Method', property: 'method', type: 'string' },
        { title: 'Endpoint', property: 'path', type: 'string' },
        { title: 'Status', property: 'status', type: 'number' },
        { title: 'Person', property: 'person_id', type: 'person' }
    ],
    materializations: [
        { title: 'Materialization ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'State', property: 'state', type: 'string' }
    ]
};

export const BlockIDs = {
    VALIDATION: 'dfc54590-b8b3-4a35-9c26-b6ef3e3d45b5'
};

export const KnownBlock = {
    EnrichedInput: '91822395-5809-4ea2-96f6-c853341cbcc9',
    Licensing: 'd1df7a33-c5f1-49d7-850d-d03c7ecb335f',
    SourceOverrides: '12be6249-5407-4e50-a331-ac305b70d108',
    SharedOverrides: 'fcf52502-89da-4fd3-bb67-10afba97f29c',
    PrivacyMask: 'dbced7ec-f955-46b0-b4fa-607a5eead3af',
    InferRoles: '20c46eca-d261-4acc-a65a-a464fdf84e1f',
    MapCustomRoles: '50060470-b7bd-439c-a40f-819b3d6fa9cf',
    ClassStateBySession: '8f8a3e02-3ae2-4e27-95f4-40136450f696',
    ConvertToUKGradeLevels: '595259cf-552a-4103-8dea-ffb3a6234037',
    InactiveSession: 'c489379d-a3c0-4158-98a4-665eed1aa717',
    NormalizeStateNames: '578bda1a-f540-4dd2-9819-5fdf509e9638',
    KebabCase: 'a6c5b3df-c684-4575-adc3-bb19cc9b3ae3',
    PhoneNumberFormatter: '6ab5c8a0-23cc-46f1-8173-292e50a46218',
    DeleteInactivePeople: '44787c37-4069-4e35-bf0b-c67d349601db',
    OpenAI: '448974ff-7238-4e0c-a429-ba69e91c7ccf',
    Validate: 'dfc54590-b8b3-4a35-9c26-b6ef3e3d45b5',
    CustomCSV: 'b2b9952a-b793-4e4c-ba3d-93d478617258',
    SplitSectionsIntoClasses: '61c6c477-ec33-4e5e-ba54-51849310dd31'
};

export const licensing = {
    properties: {
        classes: [
            { name: 'Class', value: 'class_id', type: 'entity', target: 'class', common: true, path: 'id', operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'Class Title',
                value: 'class_name',
                type: 'string',
                common: true,
                target: 'class',
                path: 'name',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            },
            {
                name: 'Subject',
                value: 'class_subjects',
                type: 'enum',
                enum: {
                    'CEDS.01': 'Language Arts',
                    'CEDS.02': 'Mathematics',
                    'CEDS.03': 'Life & Physical Sciences',
                    'CEDS.04': 'Social Sciences & History',
                    'CEDS.05': 'Visual & Performing Arts',
                    'CEDS.07': 'Religious Education & Theology',
                    'CEDS.08': 'Physical Health & Safety Education',
                    'CEDS.09': 'Military Science',
                    'CEDS.10': 'Information Technology',
                    'CEDS.11': 'Communication & Audio Visual Technology',
                    'CEDS.12': 'Business & Marketing',
                    'CEDS.13': 'Manufacturing',
                    'CEDS.14': 'Health Care Sciences',
                    'CEDS.15': 'Public, Protective & Government Service',
                    'CEDS.16': 'Hospitality & Tourism',
                    'CEDS.17': 'Architecture & Construction',
                    'CEDS.18': 'Agriculture Food & NaturalResources',
                    'CEDS.19': 'Human Services',
                    'CEDS.20': 'Transportation, Distribution & Logistics',
                    'CEDS.21': 'Engineering & Technology',
                    'CEDS.22': 'Miscellaneous',
                    'CEDS.23': 'Not Subject Specific',
                    'CEDS.24': 'World Languages',
                    'EL.01': 'Special Education',
                    'EL.02': 'Professional Development'
                },
                target: 'class',
                path: 'subjects',
                property: 'title',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'IS KNOWN', 'IS UNKNOWN']
            },
            {
                name: 'Grade Level',
                value: 'class_grade_levels',
                type: 'enum',
                enum: {
                    Birth: 'Birth',
                    Prenatal: 'Prenatal',
                    IT: 'Infant or Toddler',
                    PR: 'Preschool',
                    PK: 'Prekindergarten',
                    TK: 'Transitional Kindergarten',
                    KG: 'Kindergarten',
                    '01': '1st Grade',
                    '02': '2nd Grade',
                    '03': '3rd Grade',
                    '04': '4th Grade',
                    '05': '5th Grade',
                    '06': '6th Grade',
                    '07': '7th Grade',
                    '08': '8th Grade',
                    '09': '9th Grade',
                    10: '10th Grade',
                    11: '11th Grade',
                    12: '12th Grade',
                    13: 'Grade Thirteen',
                    PS: 'Postsecondary',
                    UG: 'Ungraded',
                    Other: 'Other'
                },
                target: 'class',
                path: 'grade_levels',
                property: 'title',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'IS KNOWN', 'IS UNKNOWN']
            },
            { name: 'Period', value: 'class_periods', type: 'string', target: 'class', path: 'periods', operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'State',
                value: 'class_state',
                type: 'enum',
                enum: { template: 'Template', upcoming: 'Upcoming', inactive: 'Inactive', active: 'Active', completed: 'Completed', archived: 'Archived' },
                target: 'class',
                path: 'state',
                property: 'title',
                operators: ['IN', 'NOT IN']
            },
            { name: 'Teacher', value: 'person_id', type: 'entity', common: true, target: 'teacher', path: 'teacher_id', property: 'display_name', operators: ['IN', 'NOT IN'] },
            {
                name: 'Teacher Email',
                value: 'person_email',
                type: 'string',
                target: 'teacher',
                path: 'teacher.email',
                property: 'email',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS NONE OF', 'MATCHES ANY OF']
            },
            { name: 'School', value: 'school_id', type: 'entity', common: true, target: 'school', path: 'school_id', property: 'name', operators: ['IN', 'NOT IN'] },
            { name: 'Session', value: 'session_id', type: 'entity', target: 'session', path: 'session_id', property: 'name', operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'Session State',
                value: 'session_state',
                type: 'enum',
                enum: { upcoming: 'Upcoming', active: 'Active', completed: 'Completed' },
                target: 'session',
                path: 'session_state',
                property: 'state',
                operators: ['IN', 'NOT IN']
            },
            { name: 'Course', value: 'course_id', type: 'entity', target: 'course', path: 'course_id', property: 'name', operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'Course Code',
                value: 'course_code',
                type: 'string',
                target: 'course',
                path: 'course_code',
                property: 'code',
                operators: ['IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            },
            {
                name: 'Course Name',
                value: 'course_name',
                type: 'string',
                target: 'course',
                path: 'course_name',
                property: 'name',
                operators: ['IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            }
        ],
        schools: [
            { name: 'School', value: 'school_id', type: 'entity', common: true, target: 'school', path: 'id', operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'School Name',
                value: 'school_name',
                type: 'string',
                common: true,
                target: 'school',
                path: 'name',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'MATCHES ANY OF']
            },
            {
                name: 'Grade Level',
                value: 'school_grade_levels',
                type: 'enum',
                common: true,
                enum: {
                    Birth: 'Birth',
                    Prenatal: 'Prenatal',
                    IT: 'InfantToddler',
                    PR: 'Preschool',
                    PK: 'Prekindergarten',
                    TK: 'TransitionalKindergarten',
                    KG: 'Kindergarten',
                    '01': 'FirstGrade',
                    '02': 'SecondGrade',
                    '03': 'ThirdGrade',
                    '04': 'FourthGrade',
                    '05': 'FifthGrade',
                    '06': 'SixthGrade',
                    '07': 'SeventhGrade',
                    '08': 'EighthGrade',
                    '09': 'NinthGrade',
                    10: 'TenthGrade',
                    11: 'EleventhGrade',
                    12: 'TwelfthGrade',
                    13: 'GradeThirteen',
                    PS: 'Postsecondary',
                    UG: 'Ungraded',
                    Other: 'Other'
                },
                target: 'school',
                path: 'grade_levels',
                property: 'title',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'IS KNOWN', 'IS UNKNOWN']
            }
        ],
        people: [
            { name: 'Person', value: 'person_id', type: 'entity', common: true, target: 'person', path: 'id', operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'First Name',
                value: 'person_first_name',
                type: 'string',
                target: 'person',
                path: 'first_name',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            },
            {
                name: 'Last Name',
                value: 'person_last_name',
                type: 'string',
                target: 'person',
                path: 'last_name',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            },
            {
                name: 'Display Name',
                value: 'person_display_name',
                common: true,
                type: 'string',
                target: 'person',
                path: 'display_name',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            },
            // { name: 'Primary Role', value: 'person_role', type: 'enum', target: 'person', path: 'role', property: 'title', operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'IS KNOWN', 'IS UNKNOWN'] },
            {
                name: 'Email Address',
                value: 'person_email',
                common: true,
                type: 'string',
                target: 'person',
                path: 'email',
                operators: ['IN', 'NOT IN', 'STARTS WITH ANY OF', 'ENDS WITH ANY OF', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF', 'IS KNOWN', 'IS UNKNOWN', 'MATCHES ANY OF']
            },
            {
                name: 'Role',
                value: 'person_role',
                common: true,
                type: 'enum',
                enum: {
                    student: 'Student',
                    'district-administrator': 'District Administrator',
                    administrator: 'Administrator',
                    teacher: 'Teacher',
                    observer: 'Observer',
                    parent: 'Parent',
                    guardian: 'Guardian',
                    ta: 'Teaching Assistant',
                    aide: 'Aide',
                    designer: 'Designer',
                    staff: 'Staff'
                },
                target: 'person',
                path: 'roles',
                property: 'name',
                operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN']
            },
            { name: 'School', value: 'school_id', type: 'entity', target: 'school', path: 'school.id', property: 'name', operators: ['IN', 'NOT IN', 'IS KNOWN', 'IS UNKNOWN'] }
        ]
    },
    operators: [
        // {name: 'Equals', value: 'EQUALS', select: 'one', valid: ['classes', 'people']},
        // {name: 'Does Not Equal', value: 'NOT EQUALS', select: 'one', valid: ['classes', 'people']},
        { name: 'Is Any Of', value: 'IN', select: 'multiple', valid: ['classes', 'people'] },
        { name: 'Is None Of', value: 'NOT IN', select: 'multiple', valid: ['classes', 'people'] },
        // {name: 'Starts With', value: 'STARTS WITH', select: 'one', valid: ['classes', 'people']},
        // {name: 'Ends With', value: 'ENDS WITH', select: 'one', valid: ['classes', 'people']},
        { name: 'Starts With Any Of', value: 'STARTS WITH ANY OF', select: 'multiple', valid: ['classes', 'people'] },
        { name: 'Ends With Any Of', value: 'ENDS WITH ANY OF', select: 'multiple', valid: ['classes', 'people'] },
        { name: 'Contains Any Of', value: 'CONTAINS ANY OF', select: 'multiple', valid: ['classes', 'people'] },
        { name: 'Contains All Of', value: 'CONTAINS ALL OF', select: 'multiple', valid: ['classes', 'people'] },
        { name: 'Contains None Of', value: 'CONTAINS NONE OF', select: 'multiple', valid: ['classes', 'people'] },
        { name: 'Is Known', value: 'IS KNOWN', select: 'none', valid: ['classes', 'people'] },
        { name: 'Is Unknown', value: 'IS UNKNOWN', select: 'none', valid: ['classes', 'people'] },
        { name: 'Matches Any Of', value: 'MATCHES ANY OF', select: 'mutiple', valid: ['classes', 'people'] },
        { name: 'Belongs To', value: 'BELONGS TO', select: 'multiple', valid: ['classes'] },
        { name: 'Contains Any Of', value: 'CONTAINS ANY OF', select: 'multiple', valid: ['classes'] }
    ],
    types: {
        people: [
            {
                name: 'First Name',
                property: 'first_name',
                type: 'string'
            },
            {
                name: 'Last Name',
                property: 'last_name',
                type: 'string'
            },
            {
                name: 'Email',
                property: 'email',
                type: 'string'
            },
            {
                name: 'Role',
                property: 'role',
                type: 'string'
            }
        ],
        sessions: [
            {
                name: 'Name',
                property: 'name',
                type: 'string'
            },
            {
                name: 'Start Date',
                property: 'start_date',
                type: 'date'
            },
            {
                name: 'End Date',
                property: 'end_date',
                type: 'date'
            }
        ],
        schools: [
            {
                name: 'Name',
                property: 'name',
                type: 'string'
            }
        ],
        classes: [
            {
                name: 'Name',
                property: 'name',
                type: 'string'
            },
            {
                name: 'Grades',
                property: 'grade_levels',
                type: 'string'
            },
            {
                name: 'Subjects',
                property: 'subjects',
                type: 'string'
            },
            {
                name: 'Periods',
                property: 'periods',
                type: 'string'
            },
            {
                name: 'State',
                property: 'state',
                type: 'string'
            }
        ]
    }
};
export const SUPPORTED_FLOW_SOURCES = ['veracross', 'blackbaud'];
export const SUPPORTED_FLOW_DESTINATIONS = ['google'];
export const FLOW_APPLICATION_ID = '979dde54-832a-4814-ad50-4b30d23f23c7';

export const VALID_DRAWER_ENTITIES = {
    // ** Standard **
    agents: 'agent',
    assets: 'asset',
    attendance: 'attendance',
    calendars: 'calendar',
    classes: 'class',
    courses: 'course',
    days: 'day',
    departments: 'department',
    districts: 'district',
    enrollments: 'enrollment',
    facilities: 'facility',
    fees: 'fee',
    incidents: 'incident',
    meetings: 'meeting',
    people: 'person',
    periods: 'period',
    rooms: 'room',
    routes: 'route',
    schools: 'school',
    sections: 'section',
    sessions: 'session',
    stops: 'stop',
    subjects: 'subject',
    vehicles: 'vehicle',
    // ** Custom **
    events: 'event',
    materializations: 'materialization',
    flows: 'flow',
    jobs: 'job'
};

export const IDENTIFIER_TYPES = {
    sis_id: 'SIS ID',
    state_id: 'State ID',
    state_testing_id: 'State Testing ID',
    federal_id: 'Federal ID',
    school_id: 'School ID',
    username: 'Username',
    email: 'Email Address',
    ldap: 'LDAP ID',
    lti: 'LTI ID',
    edlink_id: 'Edlink ID',
    nces_id: 'NCES ID',
    mdr_id: 'MDR ID',
    ipeds_id: 'IPEDS ID',
    aeries_id: 'Aeries ID',
    blackbaud_id: 'Blackbaud ID',
    blackboard_id: 'Blackboard ID',
    brightspace_id: 'Brightspace ID',
    bromcom_id: 'Bromcom ID',
    canvas_id: 'Canvas ID',
    canvas_lti_id: 'Canvas LTI UUID',
    classlink_tenant_id: 'Classlink Tenant ID',
    clever_id: 'Clever ID',
    clever_legacy_id: 'Clever Legacy ID',
    facts_id: 'FACTS ID',
    facts_student_id: 'FACTS Student ID',
    google_id: 'Google ID',
    google_org_unit_id: 'Google Org Unit ID',
    illuminate_id: 'Illuminate ID',
    microsoft_id: 'Microsoft ID',
    moodle_id: 'Moodle ID',
    oneroster_id: 'OneRoster Sourced ID',
    powerschool_id: 'PowerSchool ID',
    powerschool_dc_id: 'PowerSchool DCID',
    schoology_id: 'Schoology ID',
    skyward_id: 'Skyward ID',
    skyward_other_id: 'Skyward Other ID',
    toddle_id: 'Toddle ID',
    veracross_id: 'Veracross ID'
};

export const CLEVER_APPLICATION_IDS = ['70c0bc3e-116d-47a4-ad9d-261b47cd00a0', '92a3c51f-90db-4936-8974-c6cc5819cf20'];
export const ROBLOX_APPLICATION_IDS = [
    // Production
    'fc4fbea3-12fd-4616-8c34-d9f80c93807f',
    // Staging
    '279664cd-056c-45cb-8dfd-980643423067',
    // Development
    'ef84ba7f-304b-4e82-986b-7445fd1b3b30'
];

export const MATCH_TYPES_SUPPORTED = ['schools', 'classes', 'people', 'enrollments'];

export const PermissionScope = {
    CreateAssignment: '93d8f393-9ba8-4de4-a215-2ec07503146a',
    UpdateAssignment: 'a4fcb528-2642-4c7b-8a83-46bc4348713d',
    DeleteAssignment: 'b5d4dd88-03b9-43ca-ab33-1ecb3377311b',
    CreateSubmission: '25391849-e898-46f2-bb11-1a727aa3c231',
    UpdateSubmission: 'eece0ca0-2559-42c8-ae07-ffdf0a6bef38',
    DeleteSubmission: 'f7a292c8-1ee5-41b2-a71d-fdae1fe9028b'
};

export const EXTERNAL_OBJECT_TYPES = [
    'agents',
    'assets',
    'attendance',
    'calendars',
    'classes',
    'courses',
    'days',
    'departments',
    'districts',
    'enrollments',
    'facilities',
    'fees',
    'incidents',
    'meetings',
    'people',
    'periods',
    'rooms',
    'routes',
    'schools',
    'sections',
    'sessions',
    'stops',
    'subjects',
    'vehicles'
];

export const ENTITY_PROPERTIES = {
    people: [
        { title: 'Person ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'First Name', property: 'first_name', type: 'string', editable: true },
        { title: 'Last Name', property: 'last_name', type: 'string', editable: true },
        { title: 'Display Name', property: 'display_name', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Roles', property: 'roles', type: 'role', array: true, editable: true },
        { title: 'Email Address', property: 'email', type: 'string', editable: true },
        { title: 'Phone Number', property: 'phone', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Graduation Year', property: 'graduation_year', type: 'number', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Schools', property: 'school_ids', type: 'school', array: true, editable: true }
    ],
    demographics: [
        { title: 'Birthday', property: 'demographics.birthday', type: 'date', editable: true },
        { title: 'Country of Birth', property: 'demographics.country_of_birth', type: 'string', editable: true },
        { title: 'State of Birth', property: 'demographics.state_of_birth', type: 'string', editable: true },
        { title: 'City of Birth', property: 'demographics.city_of_birth', type: 'string', editable: true },
        { title: 'Gender Identity', property: 'demographics.gender', type: 'gender', editable: true },
        { title: 'Races', property: 'demographics.races', type: 'race', array: true, editable: true },
        { title: 'Hispanic or Latino', property: 'demographics.hispanic_or_latino_ethnicity', type: 'boolean', editable: true },
        { title: 'English Language Learner', property: 'demographics.english_language_learner', type: 'boolean', editable: true },
        { title: 'Individualized Education Plan', property: 'demographics.individualized_education_plan', type: 'boolean', editable: true },
        { title: 'Primary Language', property: 'demographics.primary_language', type: 'primary_language', editable: true },
        { title: 'Special Accommodations', property: 'demographics.special_accommodations', type: 'boolean', editable: true },
        { title: 'Military Connected', property: 'demographics.military_connected', type: 'military_connected_status', editable: true },
        { title: 'Food Service Program Eligibility', property: 'demographics.food_service_program_eligibility', type: 'food_service_program', editable: true },
        { title: 'Residence Status', property: 'demographics.residence_status', type: 'residence_status', editable: true },
        { title: 'Homeless', property: 'demographics.homeless', type: 'boolean', editable: true },
        { title: 'Migrant Status', property: 'demographics.migrant', type: 'boolean', editable: true },
        { title: 'Disability', property: 'demographics.disability', type: 'disability', editable: true },
        { title: 'Gifted or Talented', property: 'demographics.gifted_talented', type: 'boolean', editable: true },
        { title: 'Economically Disadvantaged', property: 'demographics.economically_disadvantaged', type: 'boolean', editable: true },
        { title: 'Public Assistance', property: 'demographics.public_assistance', type: 'boolean', editable: true },
        { title: 'Rural Residency', property: 'demographics.rural_residency', type: 'boolean', editable: true }
    ],
    address: [
        { title: 'Street Address', property: 'address.street', type: 'string', editable: true },
        { title: 'Unit Number', property: 'address.unit', type: 'string', editable: true },
        { title: 'City', property: 'address.city', type: 'string', editable: true },
        { title: 'State', property: 'address.state', type: 'string', editable: true },
        { title: 'Postal Code', property: 'address.postal_code', type: 'string', editable: true },
        { title: 'Country', property: 'address.country', type: 'string', editable: true }
    ],
    enrollments: [
        { title: 'Enrollment ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Person ID', property: 'person_id', type: 'person', monospace: true, editable: true },
        { title: 'Class ID', property: 'class_id', type: 'class', monospace: true, editable: true },
        { title: 'Section ID', property: 'section_id', type: 'section', monospace: true, editable: true },
        { title: 'Role', property: 'role', type: 'role', editable: true },
        { title: 'Primary', property: 'primary', type: 'boolean', editable: true },
        { title: 'State', property: 'state', type: 'enum', editable: true },
        { title: 'Start Date', property: 'start_date', type: 'timestamp', editable: true },
        { title: 'End Date', property: 'end_date', type: 'timestamp', editable: true }
    ],
    schools: [
        { title: 'School ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true }
    ],
    districts: [
        { title: 'District ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true }
    ],
    classes: [
        { title: 'Class ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Subject', property: 'subject_id', type: 'subject', editable: true },
        { title: 'Subjects', deprecated: true, property: 'subjects', type: 'subjects', array: true, editable: true },
        { title: 'Periods', property: 'period_ids', type: 'period', array: true, editable: true },
        { title: 'Periods', deprecated: true, property: 'periods', type: 'string', array: true, editable: true },
        { title: 'State', property: 'state', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Sessions', property: 'session_ids', type: 'session', array: true, editable: true },
        { title: 'Course', property: 'course_id', type: 'course', editable: true },
        { title: 'School', property: 'school_id', type: 'school', editable: true }
    ],
    sections: [
        { title: 'Section ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Picture URL', property: 'picture_url', type: 'string', editable: true },
        { title: 'Periods', property: 'periods', type: 'string', array: true, editable: true },
        { title: 'State', property: 'state', type: 'string', editable: true },
        { title: 'Locale', property: 'locale', type: 'string', editable: true },
        { title: 'Time Zone', property: 'time_zone', type: 'string', editable: true },
        { title: 'Class', property: 'class_id', type: 'class', editable: true }
    ],
    courses: [
        { title: 'Course ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Code', property: 'code', type: 'string', editable: true },
        { title: 'Grade Levels', property: 'grade_levels', type: 'grade_level', array: true, editable: true },
        { title: 'Subject', property: 'subject_id', type: 'subject', editable: true },
        { title: 'Subjects', deprecated: true, property: 'subjects', type: 'subjects', array: true, editable: true },
        { title: 'Session', property: 'session_id', type: 'session', editable: true },
        { title: 'School', property: 'school_id', type: 'school', editable: true }
    ],
    sessions: [
        { title: 'Session ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Start Date', property: 'start_date', type: 'timestamp', editable: true },
        { title: 'End Date', property: 'end_date', type: 'timestamp', editable: true },
        { title: 'State', property: 'state', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'string', editable: true },
        { title: 'School', property: 'school_id', type: 'school', editable: true }
    ],
    agents: [
        { title: 'Agent ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Relationship', property: 'relationship', type: 'enum', editable: true },
        { title: 'Flags', property: 'flags', type: 'agent_flags', array: true, editable: true },
        { title: 'Observer', property: 'observer_id', type: 'person', editable: true },
        { title: 'Target', property: 'target_id', type: 'person', editable: true }
    ],
    periods: [
        { title: 'Period ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Start Time', property: 'start_time', type: 'time', editable: true },
        { title: 'End Time', property: 'end_time', type: 'time', editable: true },
        { title: 'Sessions', property: 'session_ids', type: 'session', array: true, editable: false },
        { title: 'Schools', property: 'school_ids', type: 'school', array: true, editable: true }
    ],
    requests: [
        { title: 'Request ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Start Date', property: 'date', type: 'timestamp' },
        { title: 'End Date', property: 'end_date', type: 'timestamp' },
        { title: 'Method', property: 'method', type: 'string' },
        { title: 'Endpoint', property: 'path', type: 'string' },
        { title: 'Status', property: 'status', type: 'number' },
        { title: 'Person', property: 'person_id', type: 'person' }
    ],
    materializations: [
        { title: 'Materialization ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'State', property: 'state', type: 'string' }
    ],
    assets: [
        { title: 'Asset ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Make', property: 'make', type: 'string', editable: true },
        { title: 'Model', property: 'model', type: 'string', editable: true },
        { title: 'Manufacturer', property: 'manufacturer', type: 'string', editable: true },
        { title: 'Serial Number', property: 'serial_number', type: 'string', editable: true },
        { title: 'Purchase Date', property: 'purchase_date', type: 'timestamp', editable: true },
        { title: 'Expiration Date', property: 'expiration_date', type: 'timestamp', editable: true },
        { title: 'Cost', property: 'cost', type: 'number', editable: true },
        { title: 'Currency', property: 'currency', type: 'string', editable: true },
        { title: 'Life Span In Days', property: 'life_span_in_days', type: 'number', editable: true },
        { title: 'Invoice Number', property: 'invoice_number', type: 'string', editable: true },
        { title: 'Comments', property: 'comments', type: 'string', editable: true },
        { title: 'Priority', property: 'priority', type: 'number', editable: true },
        { title: 'Category', property: 'Category', type: 'string', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true }
    ],
    attendance: [
        { title: 'Attendance ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true },
        { title: 'State', property: 'state', type: 'enum', editable: true },
        { title: 'Comment', property: 'comment', type: 'string', editable: true },
    ],
    calendars: [
        { title: 'Calendar ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Code', property: 'code', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'enum', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true },
        { title: 'State', property: 'state', type: 'enum', editable: true },
        { title: 'Start Date', property: 'start_date', type: 'timestamp', editable: true },
        { title: 'End Date', property: 'end_date', type: 'timestamp', editable: true },
    ],
    days: [
        { title: 'Day ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Date', property: 'date', type: 'timestamp', editable: true },
        { title: 'Comments', property: 'comments', type: 'string', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true },
        { title: 'Start Time', property: 'start_time', type: 'time', editable: true },
        { title: 'End Time', property: 'end_time', type: 'time', editable: true }
    ],
    departments: [
        { title: 'Department ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true }
    ],
    facilities: [
        { title: 'Facility ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'enum', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true }
    ],
    fees: [
        { title: 'Fee ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Amount', property: 'amount', type: 'number', editable: true },
        { title: 'Balance', property: 'balance', type: 'number', editable: true },
        { title: 'Currency', property: 'currency', type: 'string', editable: true },
        { title: 'Amount Charged', property: 'amount_charged', type: 'number', editable: true },
        { title: 'Amount Paid', property: 'amount_paid', type: 'number', editable: true },
        { title: 'Original Amount', property: 'original_amount', type: 'number', editable: true },
        { title: 'Adjustment Amount', property: 'adjustment_amount', type: 'number', editable: true },
        { title: 'Prorated', property: 'prorated', type: 'boolean', editable: true },
        { title: 'Payment Schedule', property: 'payment_schedule', type: 'string', editable: true },
        { title: 'Comment', property: 'comment', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Creation Date', property: 'creation_date', type: 'timestamp', editable: true },
        { title: 'Charged Date', property: 'charged_date', type: 'timestamp', editable: true },
        { title: 'Due Date', property: 'due_date', type: 'timestamp', editable: true },
        { title: 'Priority', property: 'priority', type: 'number', editable: true },
        { title: 'Category', property: 'category', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'enum', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true }
    ],
    incidents: [
        { title: 'Incident ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Title', property: 'title', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Updates', property: 'updates', type: 'string', array: true, editable: true },
        { title: 'Date', property: 'date', type: 'timestamp', editable: true },
        { title: 'Cost', property: 'cost', type: 'number', editable: true }
    ],
    meetings: [
        { title: 'Meeting ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' }
    ],
    rooms: [
        { title: 'Room ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Number', property: 'number', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Capacity', property: 'capacity', type: 'number', editable: true },
        { title: 'Category', property: 'category', type: 'string', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true }
    ],
    routes: [
        { title: 'Route ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Route Code', property: 'code', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'State', property: 'state', type: 'enum', editable: true },
        { title: 'Mileage', property: 'mileage', type: 'number', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true }
    ],
    stops: [
        { title: 'Stop ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true },
        { title: 'Comments', property: 'comments', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'enum', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true }
    ],
    subjects: [
        { title: 'Subject ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Code', property: 'code', type: 'string', editable: true },
        { title: 'Ceds Code', property: 'ceds_code', type: 'string', editable: true },
        { title: 'Description', property: 'description', type: 'string', editable: true }
    ],
    vehicles: [
        { title: 'Vehicle ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'timestamp' },
        { title: 'Updated Date', property: 'updated_date', type: 'timestamp' },
        { title: 'Name', property: 'name', type: 'string', editable: true },
        { title: 'Type', property: 'type', type: 'enum', editable: true },
        { title: 'State', property: 'state', type: 'enum', editable: true },
        { title: 'Capacity', property: 'capacity', type: 'number', editable: true },
        { title: 'Fuel Type', property: 'fuel_type', type: 'enum', editable: true },
        { title: 'Code', property: 'code', type: 'string', editable: true },
        { title: 'Flags', property: 'flags', type: 'enum', array: true, editable: true },
        { title: 'Comments', property: 'comments', type: 'string', editable: true },
        { title: 'Vehicle Identification Number', property: 'vehicle_identification_number', type: 'string', editable: true },
        { title: 'License Number', property: 'license_number', type: 'string', editable: true },
        { title: 'Last Operation Date', property: 'last_operation_date', type: 'timestamp', editable: true },
        { title: 'Last Safety Inspection Date', property: 'last_safety_inspection_date', type: 'timestamp', editable: true },
        { title: 'Safety Inspection State', property: 'safety_inspection_state', type: 'enum', editable: true }
    ],
    flows: [
        { title: 'Flow ID', property: 'id', type: 'string', monospace: true, copyable: true },
        { title: 'Created Date', property: 'created_date', type: 'date' },
        { title: 'Updated Date', property: 'updated_date', type: 'date' },
        { title: 'State', property: 'state', type: 'string' },
        { title: 'Integration ID', property: 'integration_id', type: 'string', monospace: true, copyable: true }
    ]
};

export const ENTITIES = {
    people: 'Person',
    periods: 'Period',
    classes: 'Class',
    sections: 'Section',
    courses: 'Course',
    schools: 'School',
    districts: 'District',
    enrollments: 'Enrollment',
    agents: 'Agent',
    sessions: 'Session',
    requests: 'Request',
    materializations: 'Materialization',
    resources: 'Resource',
    assets: 'Asset',
    attendance: 'Attendance',
    calendars: 'Calendar',
    days: 'Day',
    departments: 'Department',
    facilities: 'Facility',
    fees: 'Fee',
    incidents: 'Incident',
    meetings: 'Meeting',
    rooms: 'Room',
    routes: 'Route',
    stops: 'Stop',
    subjects: 'Subject',
    vehicles: 'Vehicle',
    flows: 'Flow'
}