<template>
    <div class="integrate-source-provider-rapididentity">
        <integrate-source-editor-step name="Enter Your RapidIdentity URL" icon="iconoir-www" :complete="completed.includes('url')">
            <div class="helptext">
                This is the URL where your RapidIdentity server is located.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="https://example.rapididentity.com" :value="source.configuration.url" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Add Edlink to RapidIdentity" icon="iconoir-settings" :complete="completed.includes('rostering')">
            <div class="helptext">
                Use your source system to generate a new set of keys.
            </div>
            <integrate-source-editor-configuration title="Generating Keys" icon="iconoir-info-empty">
                <div class="configuration-value keys">
                    This step will vary by system. If you're interested in learning more about what we're looking for, specifically, please
                    click <a class="configuration-link inline-block text-button mini" target="_blank">here</a>.
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="rostering" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter the Keys You Generated" icon="iconoir-password-cursor" :complete="completed.includes('credentials')">
            <div class="helptext">
                These keys will be found on the RapidIdentity configuration page.
            </div>
            <label class="key-label">OAuth 2.0 Client ID</label>
            <input class="medium" type="text" placeholder="Client ID" :value="source.configuration.client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_id', value: e.target.value})" />
            <label class="key-label">OAuth 2.0 Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="Client Secret" :value="source.configuration.client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.client_secret', value: e.target.value})" />
            <label class="key-label">OneRoster Client ID</label>
            <input class="medium client-secret" type="text" placeholder="OneRoster Client ID" :value="source.configuration.oneroster_client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_id', value: e.target.value})" />
            <label class="key-label">OneRoster Client Secret</label>
            <input class="medium client-secret" type="text" placeholder="OneRoster Client Secret" :value="source.configuration.oneroster_client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.oneroster_client_secret', value: e.target.value})" />
        </integrate-source-editor-step>
        <!-- <integrate-source-editor-step name="Add a RapidIdentity SSO Shortcut" icon="iconoir-key-alt-plus" :complete="completed.includes('sso')">
            <div class="helptext">
                Add an application icon for users to access {{application.name}}.
            </div>
            <integrate-source-editor-configuration title="Adding an Application Icon" icon="iconoir-info-empty">
                <label>Open Settings Page</label>
                <div class="flex">
                    <a class="configuration-link block text-button mini" target="_blank" :class="{disabled: !completed.includes('url')}" :href="source.configuration.url + '/ui/applications'">
                        RapidIdentity Applications Catalog
                    </a>
                </div>
                <label>Name</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => application.name" v-clipboard:success="() => $toasted.info('Copied')">{{application.name}}</div>
                </div>
                <template v-if="application.icon_url">
                    <label>Icon</label>
                    <div class="flex">
                        <div class="configuration-value copyable noshift" v-clipboard="() => application.icon_url" v-clipboard:success="() => $toasted.info('Copied')">{{application.icon_url}}</div>
                    </div>
                </template>
                <label>Status</label>
                <div class="configuration-value">Active</div>
                <label>Application URL</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift monospace" v-clipboard="() => `${}`" v-clipboard:success="() => $toasted.info('Copied')">db1de065-8d3a-4641-9b2b-e3d8b5fbd92a</div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="sso" />
            </div>
        </integrate-source-editor-step> -->
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderRapidIdentity',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            application(){
                return this.state.context.application;
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the URL.
                if(!this.source.configuration.url.match(url)){
                    return [];
                }

                if(!this.rostering){
                    return ['url'];
                }

                const properties = ['client_id', 'client_secret', 'oneroster_client_id', 'oneroster_client_secret'];

                for(const property of properties){
                    if(!this.source.configuration[property]){
                        return ['url', 'rostering'];
                    }
                }

                // if(!this.sso){
                //     return ['url', 'rostering', 'credentials'];
                // }

                return ['url', 'rostering', 'credentials', 'sso'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        data(){
            return {
                rostering: false,
                sso: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .configuration-value.keys
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }
</style>
