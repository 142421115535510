<template>
    <div class="full" v-if="parent">
        <div class="drag-flag flex-align" ref="flag" :class="{visible: drag.active, nowhere: !drag.validTarget}">
            <LongArrowUpLeft class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">
                <div class="drag-action capitalize" v-if="drag.active">Move {{drag.selected.block.title}}</div>
                <div class="drag-destination" v-if="drag.active">{{drag.destination}}</div>
            </div>
        </div>
        <div class="transformation-unsaved-changes banner flex flex-align warning" v-if="unsaved">
            <InfoCircle class="icon" width="16" height="16" stroke-width="2" />
            <div class="ff">You have unsaved changes that will be lost if you navigate away from this page.</div>
            <div class="button" @click="save">Save Changes</div>
            <div class="button" @click="revert">Revert</div>
        </div>
        <div class="transformation-workspace full flex" :class="{unsaved}">
            <div class="transformations-sidebar scroll">
                <div class="flex flex-align">
                    <h3 class="ff">Custom Transformations</h3>
                    <div class="button has-icon flex flex-align" @click="add">
                        <AddDatabaseScript class="icon" width="16" height="16" stroke-width="2" />
                        Create
                    </div>
                </div>
                <div class="helptext">
                    This page contains custom data transformations that will be applied to {{source ? source.name : 'this integration'}}.
                    Transformations are run in the order that they are listed.
                    You can change the order by dragging them into the desired position.
                </div>
                <div class="transformations-list">
                    <transformation-card :active="t.id === active" :integration="integration" :source="source" :transformation="t" v-for="t of transformations" :key="t.id" @delete="remove" @mousedown.native="e => mousedown(t, e)" @mouseover.native="e => mouseover(t, e)" @mouseout.native="e => mouseout(t, e)" />
                </div>
            </div>
            <div class="transformation-settings ff">
                <template v-if="active">
                    <nav class="transformation-navigation flex flex-align">
                        <div class="pill" :class="{active: tab === 'configuration'}" @click="tab = 'configuration'">
                            <InfoEmpty class="icon" width="16" height="16" stroke-width="2" />
                            Configuration
                        </div>
                        <div class="pill" :class="{active: tab === 'preview'}" @click="tab = 'preview'">
                            <CodeBracketsSquare class="icon" width="16" height="16" stroke-width="2" />
                            Preview
                        </div>
                        <div class="ff"></div>
                        <div class="button white has-icon flex flex-align" @click="status">
                            <div class="standalone icon-status icon" :class="transformation.state"></div>
                            <div class="capitalize">{{ transformation.state }}</div>
                        </div>
                        <div class="button white has-icon flex flex-align" @click="rename">
                            <EditPencil class="icon" width="16" height="16" stroke-width="2" />
                            Rename
                        </div>
                        <div class="button white has-icon flex flex-align" @click="remove">
                            <Trash class="icon" width="16" height="16" stroke-width="2" />
                            Delete
                        </div>
                    </nav>
                    <div class="transformation-tab scroll">
                        <transformation-configuration v-if="tab === 'configuration'" class="full" :key="transformation.id" :transformation="transformation" :debug="debug" @input="patch" />
                        <blink-preview v-if="tab === 'preview'" class="drawer-content drawer-scroll flex flex-column" />
                    </div>
                </template>
                <div class="nothing full flex flex-column flex-align flex-center" v-else>
                    <PeaceHand class="icon" width="24" height="24" stroke-width="1.5" />
                    Create or select a transformation to configure.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateTransformation from '@/components/drawers/CreateTransformation';
    import Prompt from '@/components/modals/Prompt';
    import { InfoEmpty, CodeBracketsSquare, AddDatabaseScript, PeaceHand, EditPencil, Trash, InfoCircle, LongArrowUpLeft } from '@epiphany/iconoir';

    const PATCHABLE = ['configuration', 'block'];

    export default {
        name: 'TransformationWorkspace',
        props: {
            source: Object,
            integration: Object
        },
        components: {
            InfoEmpty,
            InfoCircle,
            CodeBracketsSquare,
            AddDatabaseScript,
            PeaceHand,
            EditPencil,
            Trash,
            LongArrowUpLeft
        },
        data(){
            return {
                tab: 'configuration',
                original: {
                    transformation_ids: [],
                    transformations: []
                },
                modified: {
                    transformation_ids: [],
                    transformations: []
                },
                active: null,
                loading: true,
                drag: {
                    validTarget: false,
                    destination: 'Nowhere',
                    selected: null,
                    over: null,
                    overElement: null,
                    timeout: null,
                    active: false,
                    waiting: false,
                    start: {
                        x: null,
                        y: null
                    }
                }
            };
        },
        created(){
            // Attach some drag-related events to the window object.
            window.addEventListener('mousemove', this.mousemove);
            window.addEventListener('mouseup', this.mouseup);

            // Clone our transformation_ids so we can compare them later.
            this.original.transformation_ids = _.cloneDeep(this.parent.transformation_ids);
            this.modified.transformation_ids = _.cloneDeep(this.parent.transformation_ids);

            // Fetch the existing transformations from the server and clone them.
            this.$http.get(`/teams/${this.team.id}/${this.type}/${this.parent.id}/transformations`)
            .then(response => {
                // Add the block ID as a parameter to each transformation.
                // This is to make life a lot more convenient with Blink.
                for(const transformation of response.$data){
                    transformation.block_id = transformation.block?.id;
                }
                
                this.original.transformations = _.cloneDeep(response.$data);
                this.modified.transformations = _.cloneDeep(response.$data);
            })
            .catch(() => this.$toasted.error('There was an error loading transformations.'))
            .finally(() => this.loading = false);
        },
        destroyed(){
            window.removeEventListener('mousemove', this.mousemove);
            window.removeEventListener('mouseup', this.mouseup);
        },
        computed: {
            developer(){
                return this.team.type === 'developer';
            },
            district(){
                return this.team.type === 'district';
            },
            preview(){
                return this.$store.state.blink.preview;
            },
            saving(){
                return this.$store.state.save.actions.includes('transformations/update');
            },
            error() {
                return false;
                // if (!this.transformation) {
                //     return false;
                // }

                // const end = this.transform_ids.indexOf(this.transformation.id);

                // return this.transform_ids.slice(0, end + 1).some((transformation_id) => {
                //     const debug = this.debug[transformation_id];
                //     if (debug && debug.error) {
                //         return true;
                //     }
                // });
            },
            debug() {
                return this.$store.state.blink.debug;
            },
            unsaved(){
                return !_.isEqual(this.original, this.modified);
            },
            type(){
                return this.source ? 'sources' : 'integrations';
            },
            parent(){
                return this.source ? this.source : this.integration;
            },
            team(){
                return this.$store.getters.team;
            },
            user(){
                return this.$store.getters.user;
            },
            transformations(){
                return this.modified.transformations.slice()
                    .filter(transformation => {
                        // TODO Remove this once we remove immutable transformations from the transformation_ids array.
                        if(!transformation.mutable){
                            return false;
                        }

                        // We want to filter out any that have been removed from the transformation_ids array.
                        // This is how we are indicating that a transformation should be deleted.
                        return this.modified.transformation_ids.includes(transformation.id);
                    })
                    .sort((a, b) => this.modified.transformation_ids.indexOf(a.id) - this.modified.transformation_ids.indexOf(b.id));
            },
            transformation(){
                return this.transformations.find(t => t.id === this.active);
            }
        },
        methods: {
            patch(){
                if(this.preview){
                    this.$store.dispatch('blink/patch', {
                        rule_id: null,
                        transformation_id: this.active,
                        configuration: this.transformations
                    });
                }
            },
            activate(transformation){
                // Start previewing the newly selected transformation, if it's a shared transformation.
                if(this.type === 'integrations'){
                    if(this.preview){
                        if(this.preview.integration.id === this.parent.id){
                            this.active = transformation.id;
                            
                            this.patch();
                        }else{
                            this.$store.dispatch('blink/preview', {
                                integration_id: this.parent.id,
                                rule_id: null,
                                transformation_id: transformation.id,
                                configuration: this.transformations
                            });
                        }
                    }else if(this.active){
                        // We've already created a preview but we're switching to a different transformation before it's returned from the server.
                        // NOTE DJG - This could cause a race condition where we create two previews, but I think it's sufficiently unlikely to be a problem.
                        // The race is that we have to create a preview and receive the response before the person switches to a different transformation.
                        this.$store.dispatch('blink/preview', {
                            integration_id: this.parent.id,
                            rule_id: null,
                            transformation_id: transformation.id,
                            configuration: this.transformations
                        });
                    }else{
                        // If there's no currently active transformation and no preview, we should create a new preview.
                        this.$store.dispatch('blink/preview', {
                            integration_id: this.parent.id,
                            rule_id: null,
                            transformation_id: transformation.id,
                            configuration: this.transformations
                        });
                    }
                }

                this.active = transformation.id;
            },
            mousedown(transformation, e){
                this.drag.waiting = true;
                this.drag.start.x = e.clientX;
                this.drag.start.y = e.clientY;
                this.drag.selected = transformation;

                // Activate the selected transformation.
                this.activate(transformation);
            },
            mousemove(e){
                if(this.drag.waiting){
                    // Mouse is down on a draggable item and we want to see if it is going to be dragged or if this is just a click.
                    const hypotenuse = Math.sqrt(Math.pow(this.drag.start.x - e.clientX , 2) + Math.pow(this.drag.start.y - e.clientY , 2));
                    
                    if(hypotenuse > 6){
                        this.drag.active = true;
                        this.drag.waiting = false;
                    }
                }

                if(this.drag.active){
                    this.drag.validTarget = this.validDropTarget(e);
                    this.drag.destination = this.calculateDestinationString(e);

                    // Move the flag to the cursor.
                    window.requestAnimationFrame(() => {
                        this.$refs.flag.style.transform = `translate3d(${e.clientX + 10}px, ${e.clientY + 10}px, 0)`;(e.clientY + 10) + 'px';
                    });
                }
            },
            async mouseup(e){
                if(this.drag.active){
                    if(this.validDropTarget(e)){
                        // We want to see if they intend to drop the item before or after the overElement.
                        // This element could be null if we've just started a drag.
                        const {y, height} = this.drag.overElement.closest('.transformation').getBoundingClientRect();

                        // Splice the dragging item out of the transformation_ids array.
                        this.modified.transformation_ids.splice(this.modified.transformation_ids.indexOf(this.drag.selected.id), 1);

                        // Figure out where our target block is, then decide what index to insert the dragged block at.
                        const index = this.modified.transformation_ids.indexOf(this.drag.over.id);

                        if((height / 2 + y) > e.clientY){
                            // We're going to drop before the over element.
                            this.modified.transformation_ids.splice(index, 0, this.drag.selected.id);
                        }else{
                            // We're going to drop after the over element.
                            this.modified.transformation_ids.splice(index + 1, 0, this.drag.selected.id);
                        }

                        this.patch();
                    }
                }

                this.drag.over = null;
                this.drag.active = false;
                this.drag.waiting = false;
                this.drag.selected = null;
                this.drag.validTarget = false;
                this.drag.destination = 'Nowhere';
            },
            mouseover(item, e){
                if(this.drag.active){
                    this.drag.over = item;
                    this.drag.overElement = e.target;
                }
            },
            mouseout(item, e){
                this.drag.over = null;
                this.drag.overElement = null;
            },
            validDropTarget(e){
                if(this.drag.over){
                    // If we're over the same block, return false.
                    if(this.drag.over.id === this.drag.selected.id){
                        return false;
                    }

                    // If we're over a different block, but we'd land in the same place, return false.
                    const {y, height} = this.drag.overElement.closest('.transformation').getBoundingClientRect();
                    const dragging_index = this.modified.transformation_ids.indexOf(this.drag.selected.id);
                    const over_index = this.modified.transformation_ids.indexOf(this.drag.over.id);

                    if((height / 2 + y) > e.clientY){
                        // We're going to land before the over element.
                        if(dragging_index === over_index - 1){
                            return false;
                        }
                    }else{
                        // We're going to land after the over element.
                        if(dragging_index === over_index + 1){
                            return false;
                        }
                    }

                    return true;
                }

                return false;
            },
            calculateDestinationString(e){
                if(!this.validDropTarget(e)){
                    return 'Nowhere';
                }

                if(this.drag.over){
                    // We want to see if they intend to drop the item before or after the overElement.
                    // This element could be null if we've just started a drag.
                    const {y, height} = this.drag.overElement.closest('.transformation').getBoundingClientRect();

                    return ((height / 2 + y) > e.clientY ? 'Before ' : 'After ') + this.drag.over.block.title;
                }
            },
            add(){
                this.$store.dispatch('drawer/open', {
                    key: 'create-transformation',
                    width: 450,
                    component: CreateTransformation,
                    props: {
                        source: this.source,
                        integration: this.integration,
                        create: this.create.bind(this)
                    }
                });
            },
            create(block){
                this.$http.post(`/teams/${this.team.id}/${this.type}/${this.parent.id}/transformations`, {
                    block: block ? block.id : null,
                    state: 'inactive'
                })
                .then(response => {
                    const { $data: transformation } = response;
                    
                    // Add the block_id to the new transformation.
                    transformation.block_id = transformation.block?.id;

                    // Push the new transformation onto the end of the list.
                    // This more accurately reflects the state of the database.
                    this.original.transformations.push(_.cloneDeep(transformation));

                    // Same with the modified transformation, but set the transformation to be `active`.
                    // This will (intentionally) trigger the unsaved changes banner.
                    this.modified.transformations.push(_.assign(transformation, { state: 'active' }));

                    // Push the new transformation_id onto the end of the list.
                    this.original.transformation_ids.push(transformation.id);
                    this.modified.transformation_ids.push(transformation.id);

                    // Activate the new transformation.
                    this.activate(transformation);
                })
                .catch(() => this.$toasted.error('There was an error creating your new transformation.'));
            },
            async save(){
                try {
                    // Set the saving flag.
                    await this.$store.dispatch('save/save', 'transformations/update');

                    // Check to see if our transformation_ids array has changed (and we've therefore added, removed, or reordered a transformation).
                    // We do not need to worry about creating transformations in this function. For simplicity, we do that on the fly.
                    const transformation_ids = _.cloneDeep(this.modified.transformation_ids);

                    if(!_.isEqual(this.original.transformation_ids, this.modified.transformation_ids)){
                        // Set the updated transformation_ids array in the store, just in case we reference it later.
                        this.$store.commit(`${this.type}/update`, { transformation_ids });

                        // Save the updated transformation_ids array.
                        await this.$http.put(`/teams/${this.team.id}/${this.type}/${this.parent.id}`, { transformation_ids });
                    }

                    // Check to see if each given transformation has changed (and we've therefore updated a transformation).
                    for(const transformation of this.original.transformations){
                        if(this.modified.transformation_ids.includes(transformation.id)){
                            const modified = this.modified.transformations.find(t => t.id === transformation.id);

                            if(modified){
                                if(_.isEqual(transformation, modified)){
                                    continue;
                                }

                                // Determine if the transformation itself has been updated or only the block.
                                if(!_.isEqual(transformation.block, modified.block)){
                                    // If the transformation has been updated, save it.
                                    await this.$http.put(`/teams/${this.team.id}/blocks/${modified.block.id}`, _.pick(modified.block, ['function', 'title', 'description']));
                                }

                                // These are the only two mutable properties right now.
                                const mutable = ['configuration', 'state'];
                                
                                if(!_.isEqual(_.pick(transformation, mutable), _.pick(modified, mutable))){
                                    await this.$http.put(`/teams/${this.team.id}/${this.type}/${this.parent.id}/transformations/${modified.id}`, _.pick(modified, ['configuration', 'state']))
                                }
                            }else{
                                throw new Error('This transformation has been deleted. This should never happen.');
                            }
                        }else{
                            // This transformation has been deleted.
                            await this.$http.delete(`/teams/${this.team.id}/${this.type}/${this.parent.id}/transformations/${transformation.id}`);
                        }
                    }

                    // Finally, overwrite the original transformations with the modified transformations.
                    this.original.transformations = _.cloneDeep(this.modified.transformations);
                    this.original.transformation_ids = _.cloneDeep(this.modified.transformation_ids);

                    // Set the saved flag.
                    this.$store.dispatch('save/saved', 'transformations/update');
                } catch(error){
                    this.$store.dispatch('save/error', 'transformations/update');
                }
            },
            revert(){
                this.modified.transformation_ids = _.cloneDeep(this.original.transformation_ids);
                this.modified.transformations = _.cloneDeep(this.original.transformations);

                // Update the preview.
                this.patch();
            },
            rename(){
                const block = this.transformation.block;

                this.$modal.show(Prompt, {
                    title: 'Rename Block',
                    description: 'Renaming this block will affect all integrations where this block is used.',
                    value: this.transformation.block.title,
                    done: 'Rename',
                    placeholder: 'Transformation Name',
                    select(value){
                        block.title = value;
                    }
                }, {width: 300, height: 'auto', classes: 'modal'});
            },
            status(){
                this.transformation.state = this.transformation.state === 'active' ? 'inactive' : 'active';

                // Update the preview.
                this.patch();
            },
            remove(){
                this.modified.transformation_ids.splice(this.modified.transformation_ids.indexOf(this.active), 1);
                this.modified.transformations.splice(this.modified.transformations.findIndex(t => t.id === this.active), 1);

                // Clear the active transformation.
                this.active = null;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .transformation-workspace
    {
        &.unsaved
        {
            bottom: @banner-height;
        }
    }

    .transformation-unsaved-changes
    {
        .absolute(auto, 0, 0, 0);
        height: @banner-height;
    }
    
    .transformations-sidebar
    {
        width: 420px;
        border-right: 1px solid @e;
        padding: @double-padding;

        h3
        {
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
        }

        .helptext
        {
            font-size: 14px;
            line-height: 20px;
            color: @grey;
            margin: @double-padding 0 0;
        }
    }

    .drag-flag
    {
		background: @black;
		color: @f;
        font-size: 12px;
        width: 240px;
		box-shadow: 0 2px 8px 0 fade(@black, 10%);
        padding: 6px 10px;
        line-height: 16px;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        display: none;
        border-radius: @border-radius;
        font-weight: 500;
        white-space: nowrap;
        pointer-events: none;

        .drag-destination
        {
            color: @green;
        }

        .icon
        {
            margin-right: @single-padding;
        }

        &.nowhere
        {
            .drag-destination
            {
                color: @lightgrey;
            }
        }

        &.visible
        {
            display: flex;
        }
    }

    .transformations-list
    {
        margin-top: 25px;
        transition: all ease 0.2s;

        &::before
        {
            border-radius: 3px;
            border: 1px dashed fade(@base, 60%);
            color: @base;
            content: "Move to Inactive";
            font-size: 12px;
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
            height: 40px;
            line-height: 38px;
            text-align: center;
            transition: all ease 0.2s;
        }

        &.over
        {
            padding-top: 65px;

            &::before
            {
                opacity: 1;
            }
        }
    }
    
    .transformation-settings
    {
        .transformation-navigation
        {
            .absolute(0, 0, auto, 0);
            height: @button-height + ( 2 * @double-padding );
            padding: @double-padding;

            .button
            {
                margin-left: @single-padding;
            }
        }

        .transformation-tab
        {
            .absolute(@button-height + ( 2 * @double-padding ), 0, 0, 0);
        }
    }
</style>
