module.exports = (string) => {
    if (string) {
        return string
            .split('')
            .map(() => '•')
            .join('');
    }

    return '';
};
