<template>
    <div class="integrate-editor-step" :class="{complete, loading}">
        <div class="integrate-editor-step-track">
            <div class="integrate-editor-step-icon flex flex-align flex-center">
                <spinner v-if="loading" class="slow step-loading" />
                <span v-else class="block icon" :class="icon"></span>
            </div>
        </div>
        <div class="integrate-editor-step-name">{{name}}</div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'IntegrateSourceEditorStep',
        props: {
            name: String,
            icon: String,
            complete: Boolean,
            loading: Boolean
        },
        mounted(){
            
        },
        destroyed(){

        },
        computed: {
            
        },
        methods: {
            next(){
                
            },
            keydown(e){
                
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .integrate-editor-step
    {
        padding-bottom: 100px;

        &.validation
        {
            .integrate-editor-step-track
            {
                &::after
                {
                    display: none;
                }
            }
        }

        &.complete
        {
            .integrate-editor-step-track
            {
                &::after
                {
                    border-color: @base;
                }

                &::before
                {
                    transform: scale(1);
                }
            }
            
            .integrate-editor-step-icon
            {
                background: @base;
                color: @f;
            }
        }

        &.loading
        {
            padding-bottom: 131px;
        }
    }

    .integrate-editor-step-track
    {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -50px;
        width: 31px;

        &::after
        {
            content: "";
            position: absolute;
            top: 41px;
            bottom: 10px;
            left: 15px;
            width: 0px;
            border-left: 1px dashed @e4;
            // background: @e4;
            transition: all ease 0.2s;
        }

        &::before
        {
            content: "";
            position: absolute;
            top: 10px;
            left: -20px;
            width: 11px;
            height: 11px;
            background: url('~@/assets/icons/base/check.svg');
            background-size: 15px auto;
            background-position: -2px 0;
            background-repeat: no-repeat;
            transition: all ease 0.2s;
            display: block;
            transform: scale(0);
        }
    }
    
    .integrate-editor-step-icon
    {
        width: 31px;
        height: 31px;
        background: @e;
        border-radius: 50%;
        color: @grey;
        font-size: 17px;
        transition: all ease 0.2s;

        .spinner.slow.step-loading
        {
            transform: scale(0.8);

            .path
            {
                stroke: @grey;
            }
        }
    }
    
    .integrate-editor-step-name
    {
        line-height: 31px;
        font-size: 15px;
        color: @black;
        font-weight: 500;

        + .integrate-source-editor-configuration
        {
            margin-top: 5px;
        }
    }
</style>