 <template>
    <div class="entity-value entity-value-boolean flex">
        <div v-if="editing" class="ff" key="editing">
            <autocomplete ref="input" class="block" :values.sync="override" :timeout="1" :source="source" :format="format" @escape="cancel" @enter="save" :max="1" />
        </div>
        <div v-else :class="{editable}" @click="edit" key="editable" class="flex flex-wrap">
            <template v-if="array">
                <template v-if="value.length">
                    <div v-for="(item, index) of value" :key="index" class="badge text-overflow">{{booleans[item]}}</div>
                </template>
                <div v-else class="empty">&mdash;</div>
            </template>
            <template>
                <div v-if="!isNil(value)" class="badge text-overflow">{{booleans[value]}}</div>
                <div v-else-if="placeholder" class="placeholder">{{placeholder}}</div>
                <div v-else class="empty">&mdash;</div>
            </template>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'EntityValueBoolean',
        props: {
            property: String,
            placeholder: String,
            array: Boolean,
            editing: Boolean,
            editable: Boolean,
            copyable: Boolean,
            monospace: Boolean
        },
        data(){
            return {
                booleans: {
                    true: 'Yes',
                    false: 'No'
                }
            };
        },
        computed: {
            entity(){
                return this.$store.getters.entity;
            },
            original(){
                return _.get(this.entity, this.property);
            },
            value(){
                // If there is an override, use that. Otherwise, use the original.
                // They should always be the same, except during the brief period of time where we're saving the override.
                return this.override && this.override.length ? this.override : this.original;
            },
            override: {
                get(){
                    const value = _.get(this.$store.state.entities.overrides, this.property.startsWith('demographics.') ? this.property.slice(13) : this.property);
                    
                    if (this.array) {
                        return value;
                    } else {
                        return _.isBoolean(value) ? [ value.toString() ] : [];
                    }
                },
                set(value){
                    if(value.length){
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value: this.array ? value : value.pop() === 'true'
                        });
                    }else{
                        this.$store.commit('entities/override', {
                            property: this.property,
                            value: null
                        });
                    }
                }
            },
            max(){
                return this.array ? Infinity : 1;
            }
        },
        methods: {
            isNil(v){
                return _.isNil(v);
            },
            source(value){
                return Promise.resolve(Object.entries(this.booleans).filter(([boolean, english]) => {
                    return value ? english.toLowerCase().indexOf(value.toLowerCase()) > -1 : true;
                }))
                .then(results => results.map(([boolean]) => boolean));
            },
            format_bool(value){
                if (value) {
                    return 'Yes'
                } else {
                    return 'No'
                }
            },
            format(value){
                return this.booleans[value];
            },
            edit(){
                if(this.editable){
                    if(this.original && !this.override){
                        // Set the default override value to the current property value.
                        // this.$store.commit('entities/override', {property: this.property, value: this.original});
                    }

                    this.$emit('edit');

                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 30);
                }
            },
            save(){
                this.$emit('save');
            },
            cancel(){
                this.$emit('cancel');
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";

    .entity-value-boolean
    {
        .editable
        {
            margin: -2px;

            &::after
            {
                top: 2px;
            }

            .placeholder, .empty
            {
                padding: 2px;
            }
        }

        .badge
        {
            max-width: 180px;
            margin: 2px;
        }
    }
</style>
