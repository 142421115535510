<template>
    <div class="custom-admin-initializing flex flex-center">
        <spinner />
    </div>
</template>

<script>
    import _ from 'lodash';
    import { CLEVER_APPLICATION_IDS } from '@/constants';

    export default {
        name: 'CustomAdminInitializing',
        props: {
            state: Object,
            send: Function
        },
        created(){
            let next = 'NEXT';
            let payload = null;

            const requests = [];

            // Clever Dev and Prod LMS connect applications
            if(!CLEVER_APPLICATION_IDS.includes(this.$route.params.application)) {
                this.send('ERROR', { data: 'INVALID_APPLICATION' })
                return;
            }

            if(this.state.context.application){
                requests.push(this.send('APPLICATION', this.state.context.application));
            } else {
                requests.push(this.$http.get(`/marketplace/${this.$route.params.application}`).then(response => {
                    this.send({type: 'APPLICATION', data: response.$data});
                }));
                // this.$store.dispatch('integrate/application', this.$route.params.application);
            }

            if (this.$route.query.team_id) {
                requests.push(this.send({type: 'TEAM_ID', data: this.$route.query.team_id}));
            } else {
                this.send('ERROR', { data: 'MISSING_TEAM_ID' })
                return;
            }

            if (this.$route.query.gcri) {
                requests.push(this.send({type: 'GCRI', data: this.$route.query.gcri}));
            } 

            if (this.$route.query.clever_idm) {
                // Currently unused.
            }

            if (this.$route.params.token) {
                // Extract and save token
                const token = this.$route.params.token;
                // Remove from url
                this.$router.replace({ path: `/${this.$route.params.application}/onboard/${this.$route.params.provider}` });
                // Attempt to login
                requests.push(
                    this.$store.dispatch('user/login', token)
                        .catch((err) => {
                            if (_.intersection(err.$details, ['login_required', 'mfa_required']).length) {
                                next = 'CHALLENGE';
                                payload = err.$details;
                            } else {
                                this.$toasted.error(err.$error);
                            }
                        })
                );
            } else {
                requests.push(
                    this.$store.dispatch('user/initialize')
                        .catch((err) => {
                            if (_.intersection(err.$details, ['login_required', 'mfa_required']).length) {
                                next = 'CHALLENGE';
                                payload = err.$details;
                            } else {
                                this.$toasted.error(err.$error);
                            }
                        })
                );
            }

            Promise.all(requests).then(async () => {
                if (next === 'NEXT') {
                    await this.$http.get(`/teams/${this.state.context.team_id}`).then(response => {
                        this.send({type: 'TEAM', data: response.$data});
                    });
                    await this.$http.get(`/teams/${this.state.context.team.id}/sources`).then(response => {
                        this.send({type: 'SOURCES', data: response.$data});
                    });
                }

                this.send({type: next, data: payload});
            });
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
</style>
