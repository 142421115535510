<template>
    <div class="command-palette-application flex flex-align">
        <span class="block icon iconoir-combine"></span>
        <div class="ff">
            <div class="result-title">{{data.name}}</div>
            <div class="result-description flex flex-align">
                <div class="ff">{{data.team.name}}</div>
            </div>
        </div>
        <div class="kbd" v-if="selected">Enter ⏎</div>
    </div>
</template>

<script>
    export default {
        name: 'CommandPaletteApplication',
        props: {
            index: Number,
            data: Object,
            selected: Boolean
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    
    .provider-icon
    {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 4px;
        box-shadow: 0 0 0 1px fade(@black, 50%);
    }
</style>
