<template>
    <div class="application-webhooks full scroll" v-if="application">
        <section>
            <h2>Webhook Configuration</h2>
            <form @submit.prevent="update_url">
                <div class="form-field">
                    <label>Webhook Signing Secret</label>
                    <div class="form-helptext">This key helps you confirm that webhook requests came from Edlink.</div>
                    <input readonly :value="application.webhook_secret" class="medium" />
                </div>
                <div class="form-field">
                    <label>Webhook Endpoint</label>
                    <div class="form-helptext">This is the endpoint at which you wish to receive webhook requests.</div>
                    <input type="text" class="medium" v-model="webhook_endpoint" />
                </div>
                <div class="form-options flex">
                    <div class="button" @click="update_url">Save Webhook Endpoint</div>
                </div>
            </form>
        </section>
        <section>
            <h2>Webhook Events</h2>
            <!-- <h4>You will receive HTTP requests sent to your webhook endpoint when the following events take place.</h4> -->
            <form @submit.prevent="update_events">
                <div class="webhooks">
                    <label
                        class="webhook flex flex-align"
                        v-for="webhook of webhooks"
                        :key="webhook.key"
                        :class="{ checked: webhook_events.includes(webhook.id) }"
                    >
                        <input
                            class="checkbox-input"
                            type="checkbox"
                            :checked="webhook_events.includes(webhook.id)"
                            @input="toggle(webhook)"
                        />
                        <div class="ff">
                            <div class="webhook-name">{{ webhook.name }}</div>
                            <div class="webhook-description">{{ webhook.description }}</div>
                        </div>
                    </label>
                </div>
                <div class="form-options flex">
                    <div class="button" @click="update_events">Save Webhook Events</div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ApplicationWebhooks',
    data() {
        return {
            webhook_events: [],
            webhooks: [
                {
                    id: 'login.first',
                    key: 1,
                    name: 'First Login',
                    description:
                        'Fired when a user logins into your application for the first time under a given integration.'
                },
                {
                    id: 'login',
                    key: 2,
                    name: 'Login Success',
                    description:
                        'Fired when a user succesfully authenticates and a new access token is issued to your application.'
                },
                {
                    id: 'events.created',
                    key: 3,
                    name: 'Events Created',
                    description:
                        'Fired when a materialization finishes (the final step of the pipeline) and something in the dataset changed. New data is available to be queried from the API.'
                }
            ],
            webhook_endpoint: ''
        };
    },
    created() {},
    watch: {
        application: {
            immediate: true,
            handler() {
                this.webhook_events = [];

                if (this.application) {
                    this.webhook_events.push(...this.application.webhook_events);
                    this.webhook_endpoint = this.application.webhook_endpoint;
                }
            }
        }
    },
    computed: {
        team() {
            return this.$store.getters.team;
        },
        application() {
            return this.$store.getters.application;
        }
    },
    methods: {
        toggle(webhook) {
            if (this.webhook_events.includes(webhook.id)) {
                this.webhook_events.splice(this.webhook_events.indexOf(webhook.id), 1);
            } else {
                this.webhook_events.push(webhook.id);
            }
        },
        update_url() {
            this.$store.commit('applications/update', { webhook_endpoint: this.webhook_endpoint });
            this.$store
                .dispatch('applications/update')
                    .then(() => this.$toasted.success('Webhook endpoint saved.'))
                    .catch(error => this.$toasted.error('There was an error updating your webhook endpoint.'));
        },
        update_events() {
            this.$store.commit('applications/update', { webhook_events: this.webhook_events });
            this.$store.dispatch('applications/update')
                .then(() => this.$toasted.success('Webhook events saved.'))
                .catch(error => this.$toasted.error('There was an error updating your webhook events.'));
        }
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

    section
    {
        border-top: 1px solid @e;
        padding: 20px;

        &:first-child
        {
            border-top: 0;
        }
    }

    h2
    {
        color: @black;
        font-size: 16px;
        margin-bottom: 25px;
    }

    .webhook {
        border: 1px solid @d;
        padding: 10px 13px;
        margin: 0;
        cursor: pointer;
        user-select: none;
        margin-top: -1px;

        &:first-child {
            margin-top: 0;
            border-radius: 3px 3px 0 0;
        }

        &:last-child {
            border-radius: 0 0 3px 3px;
        }

        &:focus-within,
        &:active {
            border-color: @base;
            box-shadow: 0 0 0 2px fade(@base, 30%);
            z-index: 3;
        }
    }

    .webhook-name {
        color: @black;
        font-size: 15px;
    }

    .webhook-description {
        font-size: 12px;
        color: #888;
        line-height: 14px;
        margin-top: 4px;
        font-weight: 400;
    }
</style>
