<template>
    <div class="source-configuration-help">
        <template v-if="template">            
            <article class="document" :focus="focus" v-html="template" @click="clicked" />
            <div class="flex">
                <a class="text-button" :href="'/docs/administrators/' + provider.application" target="_blank">Open Documentation</a>
            </div>
        </template>
        <template v-else>
            <article class="document">
                On this page, you will create a connection with {{provider.name}}.
                
                <p class="nickname">
                    The first field on this page asks that you create a nickname for this {{provider.name}} connection. It doesn't have to refer to anything in particular, just choose a name that teachers and students would recognize (e.g. LAUSD {{provider.name}}).
                </p>
            </article>
        </template>
    </div>
</template>

<script>
    import showdown from 'showdown';
    import HelpVideo from '@/components/modals/HelpVideo.vue';

    export default {
        name: 'SourceConfigurationHelp',
        props: {
            provider: Object,
            focus: String
        },
        data(){
            return {
                template: ''
            };
        },
        methods: {
            clicked(e){
                const target = e.target.closest('[video]');

                if(target){
                    this.watch(target.getAttribute('video'));
                }
            },
            watch(video){
                if(video){
                    this.$modal.show(HelpVideo, {video}, {width: 600, height: 'auto', classes: 'modal'});
                }
            }
        },
        watch: {
            provider: {
                immediate: true,
                handler(){
                    if(!this.provider){
                        return;
                    }

                    this.template = '';

                    this.$http.get(`https://edlink.github.io/docs/snippets/onboarding/${this.provider.application}-configuration.md`, {baseURL: '/'})
                    .then(response => {
                        const container = document.createElement('section');
                        const converter = new showdown.Converter({tables: true, customizedHeaderId: true});

                        try {
                            container.innerHTML = converter.makeHtml(response);

                            for(const block of container.querySelectorAll('pre')){
                                block.classList.add('line-numbers');
                            }

                            this.template = container.outerHTML;
                        }catch(error){
                            this.$toasted.error('The configuration help could not be loaded.');
                        }
                    })
                    .catch(error => {});
                }
            }
        }
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .source-configuration-help
    {
        h4
        {
            font-size: 18px;
            font-weight: 600;
            color: @black;
        }

        .alert
        {
            padding: 20px 15px 15px;
            margin-top: 20px;
            font-size: 12px;
            line-height: 16px;

            .learn-more
            {
                margin-top: 10px;
                font-size: 12px;
                padding-right: 20px;
                background: url('~@/assets/icons/base/external.svg') no-repeat;
                background-position: right -1px top 2px;
                background-size: 16px auto;
            }
        }

        .text-button
        {
            padding-right: 24px;
            background: url('~@/assets/icons/base/external.svg') no-repeat;
            background-position: right -2px top 1px;
            background-size: 19px auto;
            // margin-top: 20px;
        }

        .document
        {
            p
            {
                line-height: 20px;
                margin: 20px 0;
                font-size: 15px;
            }

            h4 + p
            {
                margin-top: 10px;
            }

            p, ol, ul, div
            {
                transition: all ease 0.2s;
            }

            &[focus]
            {
                p, ol, ul, div
                {
                    color: @lightgrey;
                }
            }

            &[focus="nickname"] .nickname,
            &[focus="application_id"] .application_id,
            &[focus="application_secret"] .application_secret,
            &[focus="url"] .url
            {
                color: @black;
            }
        }

        .watch-video
        {
            padding: 10px 15px;
            margin: 20px 0;

            .video-icon
            {
                width: 24px;
                height: 24px;
                background: url('~@/assets/icons/base/play.svg') center center no-repeat;
                margin-right: 15px;
            }

            .video-title
            {
                font-size: 14px;
                color: @black;
                font-weight: 500;
                line-height: 18px;
                margin-bottom: 2px;
            }

            .video-time
            {
                font-size: 13px;
                color: @grey;
                line-height: 14px;
            }

            &:hover, &:active
            {
                cursor: pointer;

                .video-title
                {
                    color: @base;
                }
            }
        }
    }
</style>