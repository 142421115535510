<template>
    <div class="toggle" :class="{active, error, disabled}" @click="toggle">
        <div class="bubble"></div>
    </div>
</template>

<script>
    export default {
        name: 'Toggle',
        props: {
            error: Boolean,
            active: Boolean,
            disabled: Boolean
        },
        methods: {
            toggle(){
                if(!this.disabled){
                    this.$emit('change', !this.active);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../~@/assets/less/variables";

    .toggle
    {
        height: 22px;
        border-radius: 11px;
        user-select: none;
        width: 40px;
        padding: 2px;
        transition: all ease 0.2s;
        cursor: pointer;
        border: 1px solid @d;

        &:hover, &:active
        {
            border-color: #AAA;
        }

        &.active
        {
            border-color: @base;
            background-color: @base;

            .bubble
            {
                transform: translate(18px, 0);
                background-color: @f;
            }
        }
    }

    .bubble
    {
        height: 16px;
        width: 16px;
        border-radius: 8px;
        background-color: @e4;
        transition: all ease 0.2s;
    }
</style>
