<template>
    <div class="link-configuration">
        <header class="drawer-header flex flex-align">
            <div class="ff drawer-title flex flex-align">
                <UnionAlt class="icon" width="20" height="20" stroke-width="2" />
                Link Configuration
            </div>
            <div class="button" @click="save">Save Changes</div>
            <div class="button white" @click="close">Close</div>
        </header>
        <nav class="drawer-navigation flex flex-align">
            <div class="pill" :class="{active: tab === 'general'}" @click="tab = 'general'">
                <SettingsAlt class="icon" width="16" height="16" stroke-width="2" />
                General
            </div>
            <div class="pill" :class="{active: tab === 'merging'}" @click="tab = 'merging'">
                <Combine class="icon" width="16" height="16" stroke-width="2" />
                Merging Data
            </div>
            <template v-if="link.provider.application === 'sftp'">
                <div class="pill" :class="{active: tab === 'exporting'}" @click="tab = 'exporting'">
                    <DataTransferUp class="icon" width="16" height="16" stroke-width="2" />
                    Exporting Data
                </div>
            </template>
        </nav>
        <div class="drawer-content drawer-scroll">
            <template v-if="tab === 'general'">
                <div class="drawer-section">
                    <h3>Link Priority</h3>
                    <div class="helptext">
                        Set the priority of this link. Sources with higher priority will be processed first.
                    </div>
                    <div class="drawer-options flex flex-align">
                        <input type="text" class="medium" placeholder="1" v-model="priority" />
                    </div>
                </div>
                <div class="drawer-section">
                    <h3>Scheduler Time Delay</h3>
                    <div class="helptext">
                        The amount of time in seconds that Edlink will wait before attempting to start a series of tasks. Set to zero to start immediately after receiving a new task.
                    </div>
                    <div class="drawer-options flex flex-align">
                        <input type="text" class="medium" placeholder="0" v-model="scheduler_throttle_delay" />
                    </div>
                </div>
                <!-- <div class="drawer-section">
                    <h3>SSO Settings</h3>
                    <div class="helptext">
                        This controls how the secondary source connects with the primary source for SSO.
                    </div>
                </div> -->
                <div class="drawer-section">
                    <h3>Delete Link</h3>
                    <div class="helptext">
                        Delete this link entirely. Proceed with caution as you'll have to recreate this link after it is deleted.
                    </div>
                    <div class="drawer-options flex flex-align">
                        <div class="button red">Delete Link</div>
                    </div>
                </div>
            </template>
            <template v-if="tab === 'merging'">
                <div class="helptext">
                    These options will allow you to tune the data enrichment algorithm.
                    Adjusting these functions will affect how Edlink matches data between sources.
                    For details on using match weight configuration, check out
                    <a href="https://ed.link/docs/guides/v2.0/data-model/working-with-enriched-data" target="_blank">
                        Working With Enriched Data.
                    </a>
                </div>
                <div v-for="(configuration, entity) of configuration.entities" class="entity-configuration" :key="entity">
                    <div class="entity-configuration-header flex flex-align pointer no-select" @click="expand(entity)">
                        <span class="icon" :class="{'iconoir-nav-arrow-right': !expanded[entity], 'iconoir-nav-arrow-down': expanded[entity]}"></span>
                        <div class="entity-title capitalize">{{entity}}</div>
                    </div>
                    <div class="entity-configuration-options" v-if="expanded[entity]">
                        <div class="entity-configuration-option">
                            <h4>Join Strategy</h4>
                            <div class="flex flex-align join-strategies">
                                <div class="pointer no-select card join-strategy center left-join" @click="join(configuration, 'left')" :class="{selected: configuration.join === 'left'}">Left</div>
                                <div class="pointer no-select card join-strategy center inner-join" @click="join(configuration, 'inner')" :class="{selected: configuration.join === 'inner'}">Inner</div>
                                <div class="pointer no-select card join-strategy center right-join" @click="join(configuration, 'right')" :class="{selected: configuration.join === 'right'}">Right</div>
                                <div class="pointer no-select card join-strategy center full-join" @click="join(configuration, 'full')" :class="{selected: configuration.join === 'full'}">Full</div>
                                <div class="pointer no-select card join-strategy center no-join" @click="join(configuration, 'none')" :class="{selected: configuration.join === 'none'}">None</div>
                            </div>
                        </div>
                        <h4>Join Condition</h4>
                        <div class="join-conditions" v-for="(condition, index) of configuration.conditions" :key="index">
                            <div class="join-condition">
                                <div class="join-condition-name monospace text-overflow">primary</div>
                                <input type="text" class="join-condition-value monospace" v-model="condition.primary" />
                            </div>
                            <div class="equals">=</div>
                            <div class="join-condition">
                                <div class="join-condition-name monospace text-overflow">secondary</div>
                                <input type="text" class="join-condition-value monospace" v-model="condition.secondary" />
                            </div>
                        </div>
                        <!-- <h4>Merge Settings</h4>
                        <div class="merge-settings">
                            <div class="merge-setting" v-for="field of merge[entity]" :key="field">
                                <div class="merge-setting-name monospace text-overflow">{{ field }}</div>
                                <input type="text" class="merge-setting-value monospace" />
                            </div>
                        </div> -->
                    </div>
                </div>
            </template>
            <template v-if="tab === 'exporting'">
                <div class="helptext">
                    These settings control the flow of data from {{ source.name }} to {{ link.source.name }}.
                    When assignments, submissions, resources, or grades are created in {{ source.name }}, Edlink can also create them in {{ link.source.name }}, depending on the permissions you set here.
                </div>
                <div class="drawer-section">
                    <h3>Export Directory</h3>
                    <div class="helptext">
                        Choose where Edlink should export Event Deltas to.
                    </div>
                    <div class="drawer-options flex flex-align">
                        <input type="text" class="medium" placeholder="/" v-model="configuration.scheduler.directory" />
                    </div>
                </div>
                <!-- <div class="drawer-section">
                    <h3>Timestamp Exports</h3>
                    <div class="helptext">
                        Should Edlink export to new files with timestamps or append to a single file.
                    </div>
                    <div class="drawer-options flex flex-align">
                        <checkbox label="Enabled" :checked.sync="configuration.scheduler.timestamped" />
                    </div>
                </div> -->
                <div v-for="(configuration, entity) of configuration.scheduler.entities" class="entity-configuration" :key="entity">
                    <div class="entity-configuration-header flex flex-align pointer no-select" @click="expand(entity)">
                        <span class="icon" :class="{'iconoir-nav-arrow-right': !expanded[entity], 'iconoir-nav-arrow-down': expanded[entity]}"></span>
                        <div class="entity-title capitalize">{{entity}}</div>
                    </div>
                    <div class="entity-configuration-options" v-if="expanded[entity]">
                        <h4>Enabled Events</h4>
                        <div class="flex flex-align enable">
                            <checkbox label="Create" :checked.sync="configuration.created" />
                            <checkbox label="Update" :checked.sync="configuration.updated" />
                            <checkbox label="Delete" :checked.sync="configuration.deleted" />
                        </div>
                        <h4>Property Settings</h4>
                        <div class="props flex flex-align">
                            <div class="title">Available Properties: </div>
                            <div class="prop pointer" v-for="prop in properties[entity]" :key="prop" v-clipboard="() => prop" v-clipboard:success="() => $toasted.info('Copied')">
                                {{ prop }}
                            </div>
                        </div>
                        <div class="prop-settings">
                            <div class="prop-setting flex flex-align">
                                <input type="text" disabled :value="`${singular_map[entity]}_id`" class="prop-setting-name monospace text-overflow" />
                                <div class="dash">=</div>
                                <input type="text" disabled value="id" class="prop-setting-value monospace" placeholder="value" />
                            </div>
                            <div class="prop-setting flex flex-align">
                                <input type="text" disabled value="event_type" class="prop-setting-name monospace text-overflow" />
                                <div class="dash">=</div>
                                <input type="text" disabled value="event_type" class="prop-setting-value monospace" placeholder="value" />
                            </div>
                            <div class="prop-setting flex flex-align" v-for="(prop, $index) of configuration.mappings" :key="$index + 'mappings'">
                                <input type="text" v-model="prop.column" class="prop-setting-name monospace text-overflow" placeholder="column_name" />
                                <div class="dash">=</div>
                                <input type="text" v-model="prop.function" class="prop-setting-value monospace" placeholder="Lynx Function..." />
                                <div class="flex flex-align delete" @click="deleteProperty(entity, $index)">
                                    <Trash class="icon" width="16" height="16" stroke-width="2" />
                                </div>
                            </div>
                            <div class="flex flex-align text-button add-prop" @click="addProperty(entity)">
                                <AddCircle class="icon" width="16" height="16" stroke-width="2" />
                                <div class="ff text-overflow">Add Property</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { Bolt, SettingsAlt, UnionAlt, DataTransferUp, ShieldBroken, Combine, AddCircle, Trash } from '@epiphany/iconoir';
    import Vue from 'vue';
    import { PermissionScope } from '@/constants';

    export default {
        name: 'LinkConfiguration',
        components: {
            Bolt,
            SettingsAlt,
            UnionAlt,
            DataTransferUp,
            ShieldBroken,
            Combine,
            AddCircle,
            Trash
        },
        data() {
            return {
                tab: 'general',
                merge: {
                    people: [],
                    districts: [],
                    schools: [],
                    sessions: [],
                    classes: [],
                    courses: [],
                    sections: [],
                    enrollments: [],
                    agents: [],
                    meetings: [],
                    attendance: [],
                    departments: [],
                    subjects: [],
                    periods: [],
                    incidents: [],
                    facilities: [],
                    rooms: [],
                    assets: [],
                    fees: [],
                    stops: [],
                    routes: [],
                    vehicles: [],
                    calendars: [],
                    days: []
                },
                schedule: {
                    assignments: [],
                    resources: [],
                    submissions: []
                },
                expanded: {
                    people: false,
                    districts: false,
                    schools: false,
                    sessions: false,
                    classes: false,
                    courses: false,
                    sections: false,
                    enrollments: false,
                    agents: false,
                    meetings: false,
                    attendance: false,
                    departments: false,
                    subjects: false,
                    periods: false,
                    incidents: false,
                    facilities: false,
                    rooms: false,
                    assets: false,
                    fees: false,
                    stops: false,
                    routes: false,
                    vehicles: false,
                    calendars: false,
                    days: false,

                    // These types are for scheduling tasks.
                    assignments: false,
                    resources: false,
                    submissions: false
                },
                // Settings for this link.
                priority: 0,
                scheduler_throttle_delay: 0,
                configuration: {
                    entities: {},
                    scheduler: {
                        directory: 'edlink-exports',
                        timestamped: true,
                        entities: {
                            assignments: {
                                created: false,
                                updated: false,
                                deleted: false,
                                mappings: []
                            },
                            resources: {
                                created: false,
                                updated: false,
                                deleted: false,
                                mappings: []
                            },
                            submissions: {
                                created: false,
                                updated: false,
                                deleted: false,
                                mappings: []
                            }
                        }
                    }
                },
                properties: {
                    assignments: ['id', 'created_date', 'updated_date', 'title', 'description', 'description_plaintext', 'url', 'attachments', 'state', 'display_date', 'start_date', 'due_date', 'end_date', 'assignee_mode', 'assignee_ids', 'section_ids', 'points_possible', 'grading_type', 'grading_scale', 'submission_types', 'max_attempts', 'session_id', 'category_id', 'properties'],
                    resources: ['id', 'type', 'target_id', 'module_id', 'properties'],
                    submissions: ['id', 'created_date', 'updated_date', 'state', 'flags', 'attempts', 'grade_comment', 'grade_points', 'grade', 'extra_attempts', 'override_due_date', 'grader_id', 'person_id', 'properties']
                },
                singular_map: {
                    'assignments': 'assignment',
                    'submissions': 'submission',
                    'resources': 'resource'
                }
            };
        },
        created() {
            // Set the priority and scheduler throttle delay.
            this.priority = this.link.priority;
            this.scheduler_throttle_delay = this.link.scheduler_throttle_delay;

            // Set the entity merge values.
            for(const entity of Object.keys(this.merge)) {
                Vue.set(this.configuration.entities, entity, {
                    join: 'left',
                    conditions: [{
                        strategy: 'hash',
                        primary: '',
                        secondary: ''
                    }],
                    merge: {}
                });

                if(this.entities){
                    const config = this.entities[entity];

                    if(config){
                        if(config.join){
                            this.configuration.entities[entity].join = config.join;
                        }

                        if(config.conditions){
                            this.configuration.entities[entity].conditions = config.conditions;
                        }

                        if(config.merge){
                            this.configuration.entities[entity].merge = config.merge;
                        }
                    }
                }
            }

            // Initialize the scheduler entity values.
            for(const entity of ['assignments', 'submissions', 'resources']) {
                if(this.scheduler){
                    const config = this.scheduler.entities?.[entity] ?? {};
                    
                    if (!config.mappings) {
                        config.mappings = [];
                    }

                    if (_.isNil(config.created)) {
                        config.created = false;
                    }

                    if (_.isNil(config.updated)) {
                        config.updated = false;
                    }

                    if (_.isNil(config.deleted)) {
                        config.deleted = false;
                    }

                    if(config){
                        Vue.set(this.configuration.scheduler.entities, entity, _.cloneDeep(config));
                    }
                }
            }

            // Set the scheduler config values.
            if(this.scheduler?.directory){
                this.configuration.scheduler.directory = this.scheduler.directory;
            }

            if(_.isBoolean(this.scheduler?.timestamped)){
                this.configuration.scheduler.timestamped = this.scheduler.timestamped;
            }
        },
        methods: {
            join(configuration, join) {
                configuration.join = join;
            },
            close() {
                this.$store.dispatch('drawer/close');
            },
            remove(link) {
                this.$http.delete(`/teams/${this.team.id}/sources/${this.source.id}/links/${link.id}`)
                .then(() => {
                    this.$toasted.success(`Link removed.`);
                    this.close();
                })
                .catch(error => {
                    this.$toasted.error(`There was an error removing this link.`);
                    console.log(error);
                });
            },
            save() {
                this.$store.commit('drawer/setPropsField', (props) => {
                    props.link.properties = this.configuration;
                });

                let permissions = this.link.permissions.slice();

                if (this.configuration.scheduler.entities.assignments.created) {
                    permissions.push(PermissionScope.CreateAssignment);
                } else {
                    permissions = permissions.filter(permission => permission !== PermissionScope.CreateAssignment);
                }

                if (this.configuration.scheduler.entities.assignments.updated) {
                    permissions.push(PermissionScope.UpdateAssignment);
                } else {
                    permissions = permissions.filter(permission => permission !== PermissionScope.UpdateAssignment);
                }

                if (this.configuration.scheduler.entities.assignments.deleted) {
                    permissions.push(PermissionScope.DeleteAssignment);
                } else {
                    permissions = permissions.filter(permission => permission !== PermissionScope.DeleteAssignment);
                }

                if (this.configuration.scheduler.entities.submissions.created) {
                    permissions.push(PermissionScope.CreateSubmission);
                } else {
                    permissions = permissions.filter(permission => permission !== PermissionScope.CreateSubmission);
                }

                if (this.configuration.scheduler.entities.submissions.updated) {
                    permissions.push(PermissionScope.UpdateSubmission);
                } else {
                    permissions = permissions.filter(permission => permission !== PermissionScope.UpdateSubmission);
                }

                if (this.configuration.scheduler.entities.submissions.deleted) {
                    permissions.push(PermissionScope.DeleteSubmission);
                } else {
                    permissions = permissions.filter(permission => permission !== PermissionScope.DeleteSubmission);
                }

                // if (this.configuration.scheduler.entities.resources.created) {
                //     permissions.push(PermissionScope.CreateResource);
                // }

                // if (this.configuration.scheduler.entities.resources.updated) {
                //     permissions.push(PermissionScope.UpdateResource);
                // }

                // if (this.configuration.scheduler.entities.resources.deleted) {
                //     permissions.push(PermissionScope.DeleteResource);
                // }

                const new_link_data = {
                    priority: this.priority,
                    scheduler_throttle_delay: this.scheduler_throttle_delay,
                    properties: this.configuration,
                    permissions: _.uniq(permissions)
                };

                this.$http.put(`/teams/${this.team.id}/sources/${this.source.id}/links/${this.link.id}`, new_link_data)
                    .then(response => this.$toasted.success('Link configuration saved'))
                    .catch(error => this.$toasted.error(`There was an error saving your link configuration`));
            },
            expand(entity) {
                this.expanded[entity] = !this.expanded[entity];
            },
            addProperty(entity){
                this.configuration.scheduler.entities[entity].mappings.push({
                    strategy: 'overwrite',
                    column: '',
                    function: ''
                });
            },
            deleteProperty(entity, index){
                this.configuration.scheduler.entities[entity].mappings.splice(index, 1);
            }
        },
        computed: {
            link() {
                return this.$store.state.drawer.props.link;
            },
            team() {
                return this.$store.getters.team;
            },
            source() {
                return this.$store.getters.source;
            },
            entities() {
                return _.get(this.link, 'properties.entities');
            },
            scheduler() {
                return _.get(this.link, 'properties.scheduler');
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .add-prop {

        .icon {
            margin-right: 5px;
        }
    }

    .prop-setting {
        margin-bottom: 10px;

        .delete {
            margin-left: 10px;

            &:hover {
                cursor: pointer;
                color: @red;
            }
        }

        .prop-setting-name {
            max-width: 200px;
        }
        .prop-setting-value {
        }

        .dash {
            color: @grey;
            margin: 0 10px;
            text-transform: capitalize;
            font-size: 14px;
            color: @grey;
            font-weight: 500;
        }
    }

    .enable {
        .checkbox {
            margin-right: 10px;
    
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .link-configuration
    {
        height: 100%;

        .drawer-content
        {
            padding: 20px;
        }
    }

    .entity-configuration
    {
        margin: 25px 0;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .entity-configuration-header
    {
        .entity-title
        {
            line-height: 22px;
            font-weight: 500;
            font-size: 15px;
            color: @black;
        }

        .icon
        {
            background: fade(@base, 10%);
            // background: darken(@f4, 5%);
            border-radius: 4px;
            color: @base;
            font-size: 21px;
            width: 21px;
            height: 21px;
            line-height: 21px;
            display: block;
            margin: 0 10px 0 0;
        }
    }
    
    .entity-configuration-options
    {
        padding: 0px 0 0px 20px;
        border-left: 1px solid @e4;
        margin: 25px 0 0 10px;

        h4
        {
            margin: 20px 0 10px;

            &:first-child
            {
                margin-top: 0;
            }
        }
    }

    .button
    {
        margin-left: 10px;
    }

    .helptext
    {
        font-size: 14px;
        color: @grey;
        line-height: 20px;
        max-width: 800px;
        margin-top: 5px;

        a
        {
            color: @base;
        }
    }

    .join-strategy
    {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        border: 1px solid @d;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 39px 0 3px;
        margin: 0 10px 0 0;
        color: @black;
        background-position: 10px 10px;
        background-size: auto 24px;
        background-repeat: no-repeat;

        &.inner-join
        {
            background-image: url('~@/assets/icons/joins/inner-join.svg');
        }

        &.left-join
        {
            background-image: url('~@/assets/icons/joins/left-join.svg');
        }

        &.right-join
        {
            background-image: url('~@/assets/icons/joins/right-join.svg');
        }

        &.full-join
        {
            background-image: url('~@/assets/icons/joins/full-join.svg');
        }

        &.no-join
        {
            background-image: url('~@/assets/icons/joins/no-join.svg');
        }

        &.selected
        {
            border-color: @base;
            box-shadow: 0 0 0 2px fade(@base, 30%);
            color: @base;

            &.inner-join
            {
                background-image: url('~@/assets/icons/joins/inner-join-selected.svg');
            }

            &.left-join
            {
                background-image: url('~@/assets/icons/joins/left-join-selected.svg');
            }

            &.right-join
            {
                background-image: url('~@/assets/icons/joins/right-join-selected.svg');
            }

            &.full-join
            {
                background-image: url('~@/assets/icons/joins/full-join-selected.svg');
            }

            &.no-join
            {
                background-image: url('~@/assets/icons/joins/no-join-selected.svg');
            }
        }
    }

    .merge-settings, .join-conditions
    {
        width: 750px;

        .merge-setting-name, .join-condition-name
        {
            width: 180px;
            color: @grey;
            font-size: 13px;
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 10;
            height: 16px;
            line-height: 16px;
            pointer-events: none;
            padding-right: 20px;

            &::after
            {
                content: '=';
                position: absolute;
                right: 0;
                top: 0;
                width: 10px;
                height: 14px;
                line-height: 14px;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .merge-setting, .join-condition
        {
            .merge-setting-value, .join-condition-value
            {
                padding-left: 200px;
                font-size: 13px;
                color: @black;
                font-weight: 500;
                margin-bottom: -1px;
                border-radius: 0;

                &:focus
                {
                    z-index: 2;
                }
            }

            &:first-child .merge-setting-value, &:first-child .join-condition-value
            {
                border-radius: 6px 6px 0 0;
            }

            &:last-child .merge-setting-value, &:last-child .join-condition-value
            {
                border-radius: 0 0 6px 6px;
                margin-bottom: 0;
            }
        }
    }

    .join-conditions
    {
        width: 750px;

        .equals
        {
            width: 19px;
            height: 19px;
            line-height: 19px;
            border-radius: 4px;
            position: absolute;
            top: 30px;
            left: -9px;
            z-index: 4;
            background: @white;
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            color: @grey;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1);
        }

        .join-condition-name
        {
            height: 24px;
            border-radius: 4px;
            background-color: fade(@base, 10%);
            padding: 0 6px;
            color: @base;
            right: 8px;
            left: auto;
            top: 8px;
            line-height: 24px;
            width: auto;

            &::after
            {
                display: none;
            }
        }

        .join-condition
        {
            .join-condition-value
            {
                padding-left: 15px;
                padding-right: 120px;
            }
            
            &:focus
            {
                z-index: 2;
            }
        }
    }


    .props {
        flex-wrap: wrap;
        // margin: -3px;
        margin-bottom: 10px;
        padding: 5px;
        border-radius: 6px;
        background-color: @f8;

        .title {
            margin: 3px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-weight: 500;
            color: @black;
        }
    }

    .prop {
        margin: 3px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        color: @grey;
        background-color: @e4;
        border-radius: 4px;
        padding: 0 4px;

        &:hover {
            color: @base;
            background: fade(@base, 20%);
        }
    }
</style>
