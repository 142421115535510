<template>
    <div class="integrate-source-provider-toddle">
        <integrate-source-editor-step name="Enter Your Toddle Client ID" icon="iconoir-code" :complete="completed.includes('client_id')">
            <div class="helptext">
                This is the Client ID provided to you by Toddle.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="21312123" :value="source.configuration.client_id" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter Your Toddle Client Secret" icon="iconoir-code" :complete="completed.includes('client_secret')">
            <div class="helptext">
                This is the Client Secret provided to you by Toddle.
            </div>
            <div class="flex flex-align">
                <input class="medium" type="text" placeholder="2412sadas" :value="source.configuration.client_secret" @input="e => send('UPDATE_SOURCE', {path: 'configuration.url', value: e.target.value})" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Enter Edlink's Redirect URI" icon="iconoir-code" :complete="completed.includes('remote')">
            <div class="helptext">
                Click the link to enter a redirect URI to Toddle.
            </div>
            <integrate-source-editor-configuration title="How to Configure Toddle" icon="iconoir-info-empty">
                <label>Redirect URI</label>
                <div class="flex">
                    <div class="configuration-value copyable noshift" v-clipboard="() => 'https://ed.link/api/authentication/toddle'" v-clipboard:success="() => $toasted.info('Copied')">https://ed.link/api/authentication/toddle</div>
                </div>
            </integrate-source-editor-configuration>
            <div class="flex">
                <checkbox label="Mark as Complete" :checked.sync="remote" />
            </div>
        </integrate-source-editor-step>
        <integrate-source-editor-step name="Connect Your Toddle Account" icon="iconoir-people-rounded" :complete="completed.includes('token')">
            <div class="helptext">
                Sign into your Toddle account to complete the connection process.
            </div>
            <!-- This component can spoof having a value in the event the token has already been exchanged. -->
            <token :write="destination" :configuration="source.configuration" :provider="provider" :value="source.token || source.id" @input="value => send('UPDATE_SOURCE', {path: 'token', value})" />
        </integrate-source-editor-step>
    </div>
</template>

<script>
    import IntegrateSourceEditorStep from '@/components/integrate/source/editor/IntegrateSourceEditorStep.vue';
    import IntegrateSourceEditorConfiguration from '@/components/integrate/source/editor/IntegrateSourceEditorConfiguration.vue';

    export default {
        name: 'IntegrateSourceProviderToddle',
        components: {
            'integrate-source-editor-step': IntegrateSourceEditorStep,
            'integrate-source-editor-configuration': IntegrateSourceEditorConfiguration
        },
        props: {
            state: Object,
            send: Function,
            type: String
        },
        computed: {
            provider(){
                return this.$parent.provider;
            },
            source(){
                return this.type === 'destination' ? this.state.context.destination_source : this.state.context.source;
            },
            destination(){
                return this.type === 'destination';
            },
            completed(){
                // We are going to calculate which steps are completed.
                const url = new RegExp(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

                // If there is no source for some reason, just return an empty array.
                if(!this.source){
                    return [];
                }

                // 1. Validate the region & URL. (url)
                // const regions = ['eu-west-1', 'us-east-1', 'me-central-1', 'ap-southeast-2', 'ap-southeast-1', 'cn-north-1'];
                // const urls = regions.map(region_name => `https://${region_name}-production-apis.toddleapp.com`);

                // source.configuration.url = urls[1];

                // if(!this.source.configuration.url.match(url)){
                //     return [];
                // }

                // 1. Are the keys entered? (keys)
                if(!this.source.configuration.client_id) {
                    return [];
                }

                if (!this.source.configuration.client_secret){
                    return ['client_id'];
                }

                // 2. Validate that the token has been acquired. (token)
                if(!this.source.token && !this.source.id) {
                    return ['client_id', 'client_secret'];
                }

                return ['client_id', 'client_secret', 'token'];
            }
        },
        watch: {
            completed(){
                this.$emit('update:complete', this.completed.length === 4);
            }
        },
        data(){
            return {
                remote: false
            };
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .helptext + input
    {
        margin-top: 15px;
    }

    .integrate-source-editor-configuration
    {
        margin-bottom: 15px;
    }

    .uri
    {
        margin-bottom: 4px;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .configuration-value.scopes
    {
        margin-top: 10px;
        line-height: 18px;

        .text-button.mini.configuration-link
        {
            background-position: right 0 top 3px;
            background-size: 12px auto;
            padding-right: 15px;
        }
    }

    .key-label
    {
        font-size: 12px;
        color: @black;
        line-height: 16px;
        font-weight: 500;
        margin: 20px 0 5px;
    }

    .enable-scopes
    {
        margin-top: 10px;

        .checkbox-input
        {
            margin: 0 10px 0 0;
        }
    }
</style>
