import { render, staticRenderFns } from "./TransformationConfiguration.vue?vue&type=template&id=0b76f829&scoped=true"
import script from "./TransformationConfiguration.vue?vue&type=script&lang=js"
export * from "./TransformationConfiguration.vue?vue&type=script&lang=js"
import style0 from "./TransformationConfiguration.vue?vue&type=style&index=0&id=0b76f829&prod&lang=less"
import style1 from "./TransformationConfiguration.vue?vue&type=style&index=1&id=0b76f829&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b76f829",
  null
  
)

export default component.exports