<template>
    <main>
        <breadcrumbs>
            <template v-slot:crumbs>
                <div class="breadcrumb">
                    <CloudSync class="icon" width="16" height="16" stroke-width="2" />
                    Sources
                </div>
            </template>
            <template v-slot:actions>
                <router-link class="button" :to="{name: 'team.sources.create'}">
                    Connect Data Source
                </router-link>
            </template>
        </breadcrumbs>
        <filters>
            <filter-dropdown :items="statuses" :active="status" @activate="switch_status" label="Status">
                <template v-slot:icon>
                    <WhiteFlag class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon flex flex-align flex-center">
                            <div class="status-icon" :class="item.icon"></div>
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="regions" :active="region" @activate="switch_region" label="Region">
                <template v-slot:icon>
                    <PinAlt class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <img class="block" :src="item.picture" />
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
            <filter-dropdown :items="providers" :active="provider" @activate="switch_provider" label="Providers" :searchable="true" @search="provider_source">
                <template v-slot:icon>
                    <MacOsWindow class="icon" width="16" height="16" stroke-width="2" />
                </template>
                <template v-slot:item="item">
                    <div class="flex flex-align">
                        <div class="filter-item-icon">
                            <div class="thumbnail">
                                <img class="block" :src="item.icon_url" />
                            </div>                            
                        </div>
                        <div class="filter-item-name">{{item.name}}</div>
                    </div>
                </template>
            </filter-dropdown>
        </filters>
        <datatable class="sources full" :columns="columns" :rows="sources" :selectable="true" :header="true" @clicked="row => open(row)" :loading="loading" :clickable="true">
            <template v-slot:empty-message>You have not yet created any sources.</template>
        </datatable>
    </main>
</template>

<script>
    import { CloudSync, AlignLeft, WhiteFlag, Calendar, MacOsWindow, PinAlt } from '@epiphany/iconoir';
    import Provider from '@/components/chips/Provider.vue';
    import Region from '@/components/chips/Region.vue';
    import State from '@/components/chips/State.vue';

    export default {
        name: 'Sources',
        components: {
            CloudSync,
            AlignLeft,
            WhiteFlag,
            Calendar,
            MacOsWindow,
            PinAlt
        },
        data(){
            return {
                all: [],
                loading: true,
                region: null,
                regions: [
                    {
                        id: '8c5475f1-32d3-479d-8a6a-3c6b6e524f49',
                        name: 'United States',
                        picture: '/flags/us.svg'
                    },
                    {
                        id: '2a5d4855-bd8d-4f4f-bd66-5180430c0ebd',
                        name: 'Canada',
                        picture: '/flags/ca.svg'
                    },
                    {
                        id: '2572d321-6d8b-458b-8024-8e5ab6bad7b6',
                        name: 'Germany',
                        picture: '/flags/de.svg'
                    },
                    {
                        id: '2104f649-fe39-4fa1-96be-8682e9840dcf',
                        name: 'Australia',
                        picture: '/flags/au.svg'
                    }
                ],
                provider: null,
                provider_query: '',
                status: null,
                statuses: [
                    {
                        id: 'requested',
                        name: 'Requested',
                        icon: 'purple'
                    },
                    {
                        id: 'active',
                        name: 'Active',
                        icon: 'green'
                    },
                    {
                        id: 'paused',
                        name: 'Paused',
                        icon: 'yellow'
                    },
                    {
                        id: 'disabled',
                        name: 'Disabled',
                        icon: 'red'
                    },
                    {
                        spacer: true
                    },
                    {
                        id: 'upcoming',
                        name: 'Upcoming',
                        icon: 'purple'
                    },
                    {
                        id: 'destroyed',
                        name: 'Destroyed',
                        icon: 'grey'
                    }
                ],
                columns: [
                    {
                        id: 'name',
                        name: 'Name',
                        icon: AlignLeft,
                        width: '80%',
                        value: row => row.name
                    },
                    {
                        id: 'status',
                        name: 'State',
                        icon: WhiteFlag,
                        component: State,
                        value: row => ({ state: row.status }),
                        width: '120px'
                    },
                    {
                        id: 'provider',
                        name: 'Provider',
                        icon: MacOsWindow,
                        width: '150px',
                        component: Provider,
                        value: row => row.provider
                    },
                    {
                        id: 'region',
                        name: 'Region',
                        icon: PinAlt,
                        component: Region,
                        value: row => row.region,
                        width: '150px'
                    },
                    {
                        id: 'created_date',
                        name: 'Created Date',
                        icon: Calendar,
                        align: 'right',
                        width: '20%',
                        value: row => this.$options.filters.pretty(row.created_date, 'short')
                    }
                ]
            };
        },
        computed: {
            team(){
                return this.$store.getters.team;
            },
            providers(){
                return this.$store.getters.providers
                    .filter(provider => {
                        if(this.provider_query){
                            return provider.name.toLowerCase().indexOf(this.provider_query.toLowerCase()) > -1;
                        }

                        return true;
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            },
            sources(){
                return this.all.filter(source => {
                    if(this.region && source.region.id !== this.region.id){
                        return false;
                    }

                    if(this.status && source.status !== this.status.id){
                        return false;
                    }

                    if(this.provider){                                                        
                        if(this.provider.id !== source.provider.id){
                            return false;
                        }                   
                    }

                    return true;
                });
            }
        },
        methods: {
            open(source){
                this.$router.push({name: 'team.sources.source', params: {source: source.id}});
            },
            fetch_sources(){
                this.$http.get(`/teams/${this.$store.getters.team.id}/sources`)
                .then(response => this.all = response.$data.sort((a, b) => a.name.localeCompare(b.name)))
                .then(() => this.loading = false)
                .catch(error => this.$toasted.error('There was an error loading your team\'s sources.'));
            },
            switch_region(region){
                this.region = region;
            },
            switch_status(status){
                this.status = status;
            },
            switch_provider(provider){        
                // Clear provider query (the search bar).
                this.provider_query = '';        
                this.provider = provider;              
            },
            provider_source(query){
                this.provider_query = query;
            }
        },
        watch: {
            'team.id'(){
                this.fetch_sources();
            }   
        },
        created(){
            this.fetch_sources();
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .filters
    {
        top: @breadcrumbs-height;
    }

    .sources.full
    {
        top: @breadcrumbs-height + @filters-height;
        overflow-y: auto;

        .datatable
        {
            width: 100%;
        }
    }

    .status-icon
    {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: @green;

        &.red
        {
            background: @red;
        }

        &.purple
        {
            background: @purple;
        }

        &.yellow
        {
            background: @yellow;
        }

        &.grey
        {
            background: @grey;
        }
    }
</style>